
    <div
      *ngIf="shouldRender"
      nz-tooltip
      nzPlacement="bottom"
      [nzTitle]="message"
      nzOverlayClassName="tooltip-container"
    >
      <span [innerHTML]="content | safeHtml"></span>
    </div>
  
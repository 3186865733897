import { Pipe, PipeTransform } from '@angular/core';
import { APPROVAL_TYPES } from '../constants/common';
import * as _ from 'lodash';
import { getApprovalTypeText } from '../utils/common';

@Pipe({ name: 'approvalText' })
export class ApprovalTextPipe implements PipeTransform {
  transform(currentApprovalApplication) {
    const FIRST_VERSION = 1;
    const modificationData = _.get(currentApprovalApplication, 'modification_data');
    const version = _.get(currentApprovalApplication, 'version');
    const approvalType = _.get(currentApprovalApplication, 'approval_type');
    const currentApprovalType = getApprovalTypeText(approvalType);

    if (_.isNil(version)) {
      return _.isNil(modificationData) ? currentApprovalType : APPROVAL_TYPES.MODIFY.text;
    }
    if (version === FIRST_VERSION) {
      return currentApprovalType;
    }
    if (!_.isNil(modificationData) || (currentApprovalType === APPROVAL_TYPES.CONTRACT.text ||
      currentApprovalType === APPROVAL_TYPES.LOI_REFUNDABLE.text ||
      currentApprovalType === APPROVAL_TYPES.LOI_NONREFUNDABLE.text)) {
      return APPROVAL_TYPES.MODIFY.text;
    }
    return currentApprovalType;

  }
}

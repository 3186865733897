import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import * as _ from 'lodash';

export interface IMenuItem {
  id: number;
  level: number;
  title: string;
  titlePrefix?: string;
  open: boolean;
  icon?: string;
  disabled?: boolean;
  paddingLeft?: number;
  children?: IMenuItem[];
  [key: string]: any;
}

@Component({
  selector: 'app-recursive-menu',
  templateUrl: './recursive-menu.component.html',
  styleUrls: ['./recursive-menu.component.scss'],
})
export class RecursiveMenuComponent implements OnInit {
  @Input() menuItems: IMenuItem[];
  @Input() style: { [key: string]: string } = {};
  @Input() collapseTrigger: 'row' | 'button' = 'button';
  @Input() mode: 'default' | 'checkbox' | 'radio' = 'default';
  @Input() highlightOnActive: boolean = true;
  @Input() menuItemActionBar: TemplateRef<void> | null;
  @Input() menuHeader: TemplateRef<void> | null;
  @Input() activeMenuItem: IMenuItem;
  @Output() activeMenuItemChange = new EventEmitter<IMenuItem>();
  @Output() menuSelected = new EventEmitter<IMenuItem[]>();
  selectedMenus: IMenuItem[] = [];

  ngOnInit(): void {
  }

  onCollapseButtonClick(event: Event, menu: IMenuItem): void {
    if (this.collapseTrigger === 'button') {
      menu.open = !menu.open;
      event.stopPropagation();
    }
  }

  onRowClick(menu: IMenuItem): void {
    if (this.highlightOnActive) {
      this.activeMenuItemChange.emit(menu);
    }
    if (this.collapseTrigger === 'row') {
      menu.open = !menu.open;
    }
  }

  handleCheckboxChange(selectedMenu: IMenuItem): void {
    selectedMenu.selected = !selectedMenu.selected;
    if (selectedMenu.selected) {
      this.selectedMenus.push(selectedMenu);
    } else {
      _.remove(this.selectedMenus, menu => menu.id === selectedMenu.id);
    }
    this.menuSelected.emit(this.selectedMenus);
  }

  handleRadioChange(selectedMenu: IMenuItem): void {
    this.activeMenuItemChange.emit(selectedMenu);
  }
}

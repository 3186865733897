import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ScrollControlDirective } from '../scroll-control/scroll-control.directive';
import { AutoUnsubscribe } from '../../_common/utils/autoUnsubscribe';
import { CurrentUserService, PromptBarService } from '@otr/website-common';
import { LeadService } from '../../customer-lead-management/lead-management/sales-lead/services/lead.service';
import { EditLeadComponent } from '../../customer-lead-management/lead-management/sales-lead/edit-lead/edit-lead.component';
import { CtiCallService } from '../../_common/services/cti-call.service';
import { PromptLevel } from '../../_common/constants/common';
import { SalesLeadModalComponent } from '../../customer-lead-management/lead-management/sales-lead/sales-lead-modal/sales-lead-modal.component';
import * as _ from 'lodash';
import { TabSwitcherService } from '../../_common/services/tab-switcher.service';
import { Router } from '@angular/router';
import { SelectType } from '../cti-main/cti-main.component';
import { zip } from 'rxjs';
import { phoneNumFormat } from '../cti-main/utils';
import { RolePrivilege } from '../../_common/constants/role-privilege.constant';

@AutoUnsubscribe()
@Component({
  selector: 'app-layout-mini',
  styleUrls: ['./mini.component.scss'],
  templateUrl: './mini.component.html',
})
export class MiniComponent implements OnInit {
  @ViewChild(ScrollControlDirective)
  mainContent: ScrollControlDirective;
  @ViewChild('addCustomerAndLeadModal', { read: ViewContainerRef })
  addCustomerAndLeadModal: ViewContainerRef;

  shouldShowPromptBar = false;
  showDealerDragBar = false;

  constructor(
    private tabSwitcherService: TabSwitcherService,
    private leadService: LeadService,
    private ctiCallService: CtiCallService,
    private promptBarService: PromptBarService,
    private currentUserService: CurrentUserService,
    private router: Router,
  ) {
    this.showDealerDragBar = this.checkShowDealerDargBarOrNot();
    this.handleDuplicateLead = this.handleDuplicateLead.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  ngOnInit(): void {
    this.ctiCallService.getAddCustomerAndLeadSubject().subscribe((res) => {
      if (this.currentUserService.canAccess([RolePrivilege.ROLE_GROUP_SALES_LEADS, RolePrivilege.ROLE_MY_SALES_LEADS])) {
        this.showAddCustomerAndLeadModal(res);
      } else {
        this.ctiCallService.setShouldShowAddCustomerConfirm(true);
      }
    });

    this.ctiCallService.getAddAfterSalesAndLeadSubject().subscribe((res) => {
      if (this.currentUserService.userPrivileges.some((role) => role === 'ROLE_AFTER_SALES_LEAD_CONFIG')) {
        if (res.type) {
          this.router.navigate(['/customer-lead-management/lead-management/after-sales/management'], {
            queryParams: {
              mobile: phoneNumFormat(res.mobile),
            },
          });
          this.ctiCallService.setShouldAddAfterSalesWithPhone(true);
        }
      } else {
        this.ctiCallService.setShouldShowAddAfterSalesConfirm(true);
      }
    });
  }

  get isVanType() {
    return this.tabSwitcherService.isVanType;
  }

  get isPartsPurchaseRoute() {
    return this.tabSwitcherService.isPartsPurchaseRoute;
  }

  checkShowDealerDargBarOrNot() {
    // TODO check
    return true;
  }

  showAddCustomerAndLeadModal(mobile) {
    this.ctiCallService.setShouldShowAddCustomerBtn(false);
    const formattedMobile = phoneNumFormat(mobile);
    const modalProperties = {
      modalTitle: '新建客户/线索',
    };
    const contentProperties = {
      initialLeadId: null,
      isLeadEditable: true,
      isEditModeForVisit: false,
      selectedPerson: {
        mobile: formattedMobile,
      },
      sharingLeadId: null,
    };
    const modalContentType = EditLeadComponent;
    this.initModal(modalContentType, contentProperties, modalProperties);
  }

  initModal(
    modalContentType,
    contentProperties,
    modalProperties,
    modalHandler = {
      handleResponseSuccess: () => {
        this.promptBarService.show('客户/线索创建成功！', PromptLevel.Info);
      },
      handleResponseError: () => {
        this.promptBarService.show('客户/线索创建失败！', PromptLevel.Error);
      },
    },
  ) {
    this.leadService.initModalWith(
      { type: modalContentType, properties: contentProperties },
      modalProperties,
      this.addCustomerAndLeadModal,
      this.handleDuplicateLead,
      this.handleSave,
      modalHandler,
    );
  }

  handleDuplicateLead(person) {
    if (_.size(person)) {
      this.promptBarService.show('客户/线索创建失败！', PromptLevel.Error);
    }
  }

  handleSave(modalInstance, contentInstance) {
    modalInstance.close();
    this.ctiCallService.setShouldShowAddCustomerBtn(true);
  }
}

import { Injectable } from '@angular/core';
import { Body, GET, Headers, Path, POST, PUT, Query, RebirthHttp, RequestOptions } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Offer } from '../models/retention';
import { DropdownOption } from '../models/common';

export enum AppointmentType {
  ASSIGN_SC = 'ASSIGN_SC',
  APPOINTMENT = 'APPOINTMENT',
}

export interface CDOFollowUpRequest {
  leadId: string;
  followUpTime: Date;
  followUpComments: string;
  nextFollowUpDate?: Date;
  isChangeToLead?: boolean;
}

export interface AssignScRequest {
  leadIds: Array<number>;
  followUpTime?: Date;
  scUserId: number;
  scGroupId: number;
  appointmentTime: number;
  comments: string;
  isBatchAssign: boolean;
  isOnlyAppointment: boolean;
  actionType: AppointmentType;
}

export interface CloseLeadRequest {
  leadIds: Array<number>;
  closeLeadReason: string;
  comments: string;
  followUpTime: Date;
}

export interface AcceptLeadRequest {
  leadIds: Array<string>;
  nextFollowUpDate: number;
}

export interface BatchAssignCdoRequest {
  leadIds: Array<string>;
  nextFollowUpDate: number;
  assignedCdoId: number;
}

export interface BatchAssignUsedCarRequest {
  leadIds: Array<string>;
  nextFollowUpDate: number;
  assignedUserId: number;
  roleCode: string;
}

export interface ILeadsResponse {
  data: ILeadDetail[];
  total_number: number;
  es: boolean;
}

export interface ILeadsCount {
  total_number: number;
  es: boolean;
}

export interface ILeadDetail {
  leadId: string;
  dealerId: string;
  dealerName: string;
  fullName: string;
  lastName: string;
  firstName: string;
  gender: string;
  mobile: string;
  interestedVehicle: IInterestedVehicle;
  grade: string;
  leadStatus: string;
  channel: string;
  currentChannel: string;
  leadSource: string;
  leadType: string;
  leadCreateTime: number;
  nextFollowUpDate: number;
  cdoName: string;
  cdoId: number;
  scName: string;
  customerId: string;
  ownerSalesConsultantId: string;
  vip: boolean;
  updatedTime: number;
}

export interface IInterestedVehicle {
  vehicleClassDescriptionEN: string;
  vehicleClassDescriptionZH: string;
  brandDescriptionEN: string;
  brandDescriptionZH: string;
  modelDescriptionEN: string;
  modelDescriptionZH: string;
  variantDescriptionEN: string;
  variantDescriptionZH: string;
  index: number;
}

export interface IBatchFollowUpListRequest {
  leadIds: string[];
  cecId?: number;
  leadTab: string;
}

export interface IDealerQuote {
  gcCode: string;
  gsCode: string;
  isInsightLead: boolean;
  baumusterNst: string;
  modelYear: number;
  changeYear?: string;
  modelName: string;
  transactionPrice?: INumber;
  term?: IOptions<string>;
  fpGroup: IOptions<IFPGroupOption>;
  quotations: IOtrQuotation[];
}

interface IOptions<T> {
  options: T[];
  selected: string;
}

export interface IFPGroupOption {
  groupName: string;
  groupNameCn: string;
  financialProducts: IOptions<IFinancialProduct>;
}

export interface IFinancialProduct {
  financialProductId: number;
  companyId: string;
  productName: string;
  productFullName: string;
  rentalMode: string;
  promotionType: string;
  promotionTypeCn: string;
  vapEligible: boolean;
  term: string[];
  mileage: IOptions<string>;
  paymentFrequency: IOptions<string>;
}

export interface IOtrQuotation {
  financialProductId: number;
  companyId: number;
  financeAmount: INumber;
  financeAmountPer: INumber;
  downPayment: INumber;
  downPaymentPer: INumber;
  frequency: INumber;
  totalPurchaseTax: INumber;
  prePurchaseTax: INumber;
  totalRvBalloonAmt: INumber;
  repayment: number;
  bankRateEquivalent: number;
  validationResults: [];
  [key: string]: unknown;
}

export interface INumber {
  value: number;
  changed: boolean;
}

@Injectable()
export class LeadApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/lead_sources')
  getLeadSources(@Query('type') type): Observable<any> {
    return null;
  }

  @GET('/api/mod_lead_sources')
  getAgentLeadSources(@Query('type') type): Observable<any> {
    return null;
  }

  @GET('/api/leads/:leadId')
  getLead(@Path('leadId') leadId): Observable<any> {
    return null;
  }

  @GET('/api/used-car-leads/:leadId')
  getUsedCarLead(@Path('leadId') leadId): Observable<any> {
    return null;
  }
  @GET('/api/sales-website/used-car-leads/:leadId')
  getUsedCarLeadWithOrderedVehicles(@Path('leadId') leadId): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/leads/:leadId')
  getLeadWithOrderedVehicles(@Path('leadId') leadId): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/mod-leads/:leadId')
  getMDLeadWithOrderedVehicles(@Path('leadId') leadId): Observable<any> {
    return null;
  }

  @POST('/api/leads/lead-merge/customer-development')
  cdoMergeLead(@Body mergeRequest: Object) {
    return null;
  }

  @POST('/api/sales-website/mod-leads/cdo/follow-up/combine/:followUpType')
  addFollowUp(@Path('followUpType') followUpType: string, @Body mergeRequest: Array<any>): Observable<any> {
    return null;
  }

  @POST('/api/leads/mod-lead-merge/customer-development')
  modMergeLead(@Body mergeRequest: Object) {
    return null;
  }

  @POST('/api/leads/merge/used_car')
  usedCarLeadMerge(@Body mergeRequest: Object) {
    return null;
  }
  @POST('/api/leads/merge/used_car/pre_check')
  usedCarLeadMergeCheck(@Query('sourceLeadId') sourceLeadId: string, @Query('targetLeadId') targetLeadId: string) {
    return null;
  }
  @POST('/api/leads/lead-merge/reception')
  reMergeLead(@Body mergeRequest: Object) {
    return null;
  }

  @POST('/api/leads/lead-merge/model_d/reception')
  remModelDMergeLead(@Body mergeRequest: Object) {
    return null;
  }

  @GET('/api/brands')
  getBrandsData() {
    return null;
  }

  @GET(`/api/brands/:brandId/classes/models`)
  getClassesByBrandId(@Path('brandId') brandId) {
    return null;
  }

  @GET('/api/interested-brands')
  getReleasedVehicleBrands() {
    return null;
  }

  @GET('/api/persons')
  getPersons(@Query('phones') phones: string, @Query('dealerId') dealerId: string) {
    return null;
  }

  /**
   * 因OTR-19142卡，换调用 /api/persons 接口
   * @param phones
   * @param dealerId
   * 之前: /api/persons/find/by-persons
   */
  @GET('/api/persons')
  getModPersons(@Query('phones') phones: string, @Query('dealerId') dealerId: string) {
    return null;
  }

  @GET('api/used-car-leads/persons')
  getUsedCarPersons(@Query('phones') phones: string, @Query('dealerId') dealerId: string) {
    return null;
  }

  @POST('api/leads/lead-addition')
  operateLeadAddition(@Body data: Object) {
    return null;
  }

  @POST('/api/sales-website/customer-development/today')
  getFollowUpList(@Body data: Object): Observable<ILeadsResponse> {
    return null;
  }

  @POST('/api/sales-website/customer-development/today/count')
  getFollowUpListCount(@Body data: Object): Observable<ILeadsCount> {
    return null;
  }

  @POST('/api/sales-website/mod-leads/cdo/today')
  getAgentFollowUpList(@Body data: Object) {
    return null;
  }

  @POST(' /api/sales-website/used-car-leads/cdo/today')
  getUsedCarsFollowUpList(@Body data: Object) {
    return null;
  }

  @GET('/api/customer-development/leads/import-progress')
  cdoLeadImportRequest(@Query('cdoLeadImportRequestId') cdoLeadImportRequestId: string) {
    return null;
  }

  @GET('/api/lead/lead-common-dict')
  getOptions() {
    return null;
  }

  @POST('/api/customer-development/appointment')
  assignSc(@Body data: AssignScRequest): Observable<any> {
    return null;
  }

  @POST('/api/mod_leads/cdo/appointment')
  assignCdo(@Body data: AssignScRequest): Observable<any> {
    return null;
  }

  @POST('/api/customer-development/leads/assign-cdo')
  acceptLeads(@Body data: AcceptLeadRequest): Observable<any> {
    return null;
  }

  @POST('api/customer-development/batch-get')
  getBatchFollowUpListByIds(@Body data: IBatchFollowUpListRequest): Observable<ILeadDetail[]> {
    return null;
  }

  @POST('/api/sales-website/mod-leads/cdo/assign-cdo')
  acceptModelDLeads(@Body data: AcceptLeadRequest): Observable<any> {
    return null;
  }
  @POST('/api/used-car-leads/assign')
  receptionUsedCarLeads(@Body data: AcceptLeadRequest): Observable<any> {
    return null;
  }
  @POST('/api/used-car-leads/assign')
  acceptUsedCarLeads(@Body data: BatchAssignUsedCarRequest): Observable<any> {
    return null;
  }
  @POST('/api/sales-website/customer-development')
  getAllLeads(@Body data: Object): Observable<ILeadsResponse> {
    return null;
  }
  @POST('/api/sales-website/customer-development/all/count')
  getAllLeadsCount(@Body data: Object): Observable<ILeadsCount> {
    return null;
  }
  @POST('/api/sales-website/mod-leads/cdo')
  getAgentAllLeads(@Body data: Object) {
    return null;
  }
  @POST('/api/sales-website/used-car-leads/cdo')
  getUsedCarsAllLeads(@Body data: Object) {
    return null;
  }
  @GET('/api/customer-development/:cdoLeadImportRequestId')
  getImportLeadIds(@Path('cdoLeadImportRequestId') cdoLeadImportRequestId) {
    return null;
  }

  @GET('/api/mod_leads/cdo/:cdoLeadImportRequestId')
  getImportModLeadIds(@Path('cdoLeadImportRequestId') cdoLeadImportRequestId) {
    return null;
  }

  @POST('/api/sales-website/customer-development/pending')
  getPendingLeadList(@Body data: Object): Observable<ILeadsResponse> {
    return null;
  }
  @POST('/api/sales-website/customer-development/pending/count')
  getPendingLeadListCount(@Body data: Object): Observable<ILeadsCount> {
    return null;
  }

  @POST('/api/sales-website/mod-leads/cdo/pending')
  getAgentPendingLeads(@Body data: Object) {
    return null;
  }
  @POST(' /api/sales-website/used-car-leads/cdo/pending')
  getUsedCarsPendingLeadList(@Body data: Object) {
    return null;
  }

  @GET('/api/sales-website/customer-vehicles')
  getCustomersWithCurrentVehicle(
    @Query('filters') filters: string,
    @Query('page') pageNumber: number,
    @Query('size') pageSize: number,
    @Query('cecId') cecId: number,
  ) {
    return null;
  }

  @PUT('/api/customer-development/recall')
  recallLead(@Query('leadId') leadId: string) {
    return null;
  }

  @PUT('api/sales-website/mod-leads/cdo/recall')
  recallModelDLead(@Query('leadId') leadId: string) {
    return null;
  }

  @GET('/api/sales-website/customer-development/count')
  countSalesLeads(@Query('type') type: string, @Query('value') value: string) {
    return null;
  }

  @PUT('/api/leads/reactivate')
  reactivateLead(@Query('leadId') leadId: string) {
    return null;
  }

  @PUT('/api/model-d-lead/reactivate')
  reactivateAgentLead(@Query('leadId') leadId: string) {
    return null;
  }

  @PUT('/api/customer-development/close-leads')
  closeLead(@Body closeLeadRequest: CloseLeadRequest): Observable<any> {
    return null;
  }

  @PUT('/api/used-car-leads/cdo/close-leads')
  closeUsedCarLead(@Body closeLeadRequest: CloseLeadRequest): Observable<any> {
    return null;
  }

  @PUT('/api/mod_leads/cdo/close-leads')
  closeAgentLead(@Body closeLeadRequest: CloseLeadRequest): Observable<any> {
    return null;
  }

  @GET('/api/leads/:id/last-created-dealer')
  getLastCreatedDealer(@Path('id') leadId, @Query('cecId') cecId): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/customers/:customerId')
  getCustomer(@Path('customerId') customerId, @Query('cecId') cecId?: number): Observable<any> {
    return null;
  }

  @POST('/api/customer-development/follow-up')
  followUp(@Body data: CDOFollowUpRequest): Observable<any> {
    return null;
  }

  @POST('/api/used-car-leads/follow-up')
  followUpUsedCarLead(@Body data: CDOFollowUpRequest): Observable<any> {
    return null;
  }

  @POST('/api/mod_leads/cdo/follow-up')
  followUpAgentLead(@Body data: CDOFollowUpRequest): Observable<any> {
    return null;
  }

  @POST('/api/customer-development/leads')
  cdoLeadCreate(@Body data): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/mod-leads/cdo/leads')
  cdoAgentLeadCreate(@Body data): Observable<any> {
    return null;
  }

  @PUT('/api/used-car-leads')
  usedCarLeadCreate(@Body data): Observable<any> {
    return null;
  }

  @POST('/api/leads/:id/servicemore')
  updateLeadService(@Path('id') leadId, @Body data): Observable<any> {
    return null;
  }
  @GET('/api/used-car-leads/:customerId/leads')
  getCustomerRelatedUsedCarLeadList(@Path('customerId') customerId): Observable<any> {
    return null;
  }

  @GET('/api/persons/:customerId/leads')
  getCustomerRelatedLeadList(@Path('customerId') customerId): Observable<any> {
    return null;
  }

  @GET('/api/model-d-lead/:customerId/mod-leads')
  getCustomerRelatedLeadListMod(@Path('customerId') customerId): Observable<any> {
    return null;
  }

  @GET('/api/regions')
  fetchRegionsData(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/used-car-leads/customer-activities')
  getCustomerUsedCarLeadActivity(@Query('customer_id') customerId): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/customers/customer-activities')
  getCustomerActivity(@Query('customer_id') customerId): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/customers/customer-activities')
  getTaskHistoryActivities(@Query('customer_id') customerId, @Query('request_source') request_source): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/mod-leads/customer-activities')
  getCustomerActivityMod(@Query('customer_id') customerId): Observable<any> {
    return null;
  }

  @GET('/api/customer-development/lead/template')
  @Headers({ isExportFile: true })
  downloadTemplate(): Observable<any> {
    return null;
  }

  @POST('/api/customer-development/leads/export')
  @Headers({ isExportFile: true })
  exportLeadData(@Body body): Observable<any> {
    return null;
  }

  @POST('/api/used-car-leads/cdo/export')
  @Headers({ isExportFile: true })
  exportUsedCarLeadData(@Body body): Observable<any> {
    return null;
  }

  @GET('/api/customer-development/failed-template')
  @Headers({ isExportFile: true })
  getFailedExcel(@Query('import_request_id') cdoLeadImportRequestId: number, @Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/customers/referrals')
  getReferrals(@Query('keyword') keyword: string,
               @Query('dealer_id') dealerId: string,
               @Query('leadType') leadType: string,
  ): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/customers/:customerId/served-sc')
  getCustomerServedScs(@Path('customerId') customerId): Observable<any> {
    return null;
  }

  @GET('/api/campaign-members/order-count')
  getGeneratedOrdersNumber(@Query('campaignId') campaignId: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/insight-offers/:customerId')
  getInsightOffers(@Path('customerId') customerId: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/mod-insight-offers/:customerId')
  getModInsightOffers(@Path('customerId') customerId: string): Observable<any> {
    return null;
  }

  @GET('/api/mbafc/model-year-mappings')
  getFsModelYear(@Query('otrModelYear') otrModelYear: number | string): Observable<number> {
    return null;
  }

  @POST('/api/mbafc/dealer-quote')
  fetchFinanceNew(@Body data: IDealerQuote): Observable<IDealerQuote> {
    return null;
  }

  @POST('/api/mbafc/find/dealer-quote-data-r3')
  fetchFinance(@Body data: any): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/cdo-lead-messages')
  getSmsTemplates(@Query('leadId') leadId: string, @Query('isUsedCarLead') isUsedCarLead: boolean): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/cdo-lead-messages')
  sendMessage(
    @Query('type') type: string,
    @Query('leadId') leadId: string,
    @Query('phoneNumber') phoneNumber: string,
    @Query('isUsedCarLead') isUsedCarLead: boolean,
  ) {
    return null;
  }

  @POST('/api/retentions/saved-offer')
  saveRetentionSavedOffer(@Body data: Offer): Observable<any> {
    return null;
  }

  @POST('/api/mod-retentions/saved-offer')
  saveModRetentionSavedOffer(@Body data: Offer): Observable<any> {
    return null;
  }

  @GET('/api/leads/reservation')
  getLeadReservationList(@Query('startTime') startTime: number, @Query('endTime') endTime: number) {
    return null;
  }

  @GET('/api/sales-website/customers')
  getSearchResult(
    @Query('type') type: string,
    @Query('content') content: string,
    @Query('cecId') cecId: number,
    @Query('leadType') leadType: string,
  ): Observable<any> {
    return null;
  }

  @POST('/api/archived/leads/activities')
  loadArchiveActivity(@Body leadIds: Array<string>): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/customers/offers')
  getCustomersOffers(@Query('customerId') customerId: string, @Query('fin') fin: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/customers/lead-offers')
  getLeadOffersByLeadId(@Query('leadId') leadId: string, @Query('customerId') customerId: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/customers/mod-lead-offers')
  getModLeadOffersByLeadId(@Query('leadId') leadId: string, @Query('customerId') customerId: string): Observable<any> {
    return null;
  }

  @GET('/api/options/occupation')
  loadOccupationOption(): Observable<Array<DropdownOption>> {
    return null;
  }

  @GET('/api/vehicles/stock')
  @RequestOptions({ responseType: 'text' })
  getStockStatus(@Query('baumusterNst') baumusterNst: string): Observable<'期货' | '现货' | '无库存'> {
    return null;
  }

  @GET('/api/pre_orders/model-year')
  getRealModelYear(@Query('baumusterNst') baumusterNst: string): Observable<any> {
    return null;
  }

  @POST('/api/customer-tasks/complete')
  completeTask(@Body completeTask: Object): Observable<any> {
    return null;
  }

  @POST('/api/customer-tasks/update')
  updatedTaskByTaskManagementPrivilege(@Body updatedTask: Object): Observable<any> {
    return null;
  }

  @POST('/api/customer-tasks/update-operation')
  updatedTask(@Body updatedTask: Object): Observable<any> {
    return null;
  }

  @GET('/api/customer-tasks/:customerId/done-history')
  getHistoryTasks(@Path('customerId') customerId): Observable<any> {
    return null;
  }

  @GET('/api/website/lead/open-lead-asLead')
  hasOpenLead(@Query('customerId') customerId, @Query('fin') fin): Observable<any> {
    return null;
  }

  @POST('/api/customer-questionnaires/task')
  updateCustomerComments(@Body query: Object): Observable<any> {
    return null;
  }
}

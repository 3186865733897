import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { ClockOffIdleData, IdleInfo, IdlingType, TechnicianTeamIdleInfo } from '../models/technician-team';
import { TechnicianIdleApi } from '../api/technician-idle';
import { TechnicianResourceManagementService } from './technician-resource-management.service';
import { SpinnerService } from '@otr/website-common';

@Injectable()
export class TechnicianIdleService {
  currentIdleInfo: IdleInfo;
  showClockOffModal: Subject<ClockOffIdleData> = new Subject();

  constructor(private technicianIdleApi: TechnicianIdleApi,
              private technicianResourceManagementService: TechnicianResourceManagementService,
              private spinnerService: SpinnerService,
              ) {
  }

  get currentTechnician() {
    return this.technicianResourceManagementService.technicianClockingInfo;
  }

  getIdlingTypes(): Observable<IdlingType[]> {
    return this.technicianIdleApi.getIdlingTypes();
  }

  startIdle(idleTypeId: number, technicianId: number) {
    return this.technicianIdleApi.startIdle({
      idleTypeId,
      technicianId,
    });
  }

  batchStartIdle(technicianIdleInfo: any) {
    return this.technicianIdleApi.batchStartIdle(technicianIdleInfo);
  }

  getIdleInfo(technicianId: number) {
    return this.technicianIdleApi
      .getIdleInfo(technicianId)
      .pipe(tap(idleInfo => this.currentIdleInfo = idleInfo));
  }

  getTeamIdleInfo(date): Observable<TechnicianTeamIdleInfo[]> {
    const startOfDay = moment(date).startOf('day');
    return startOfDay.isValid() ? this.technicianIdleApi.getTeamIdleInfo(startOfDay.toISOString()) : EMPTY;
  }

  stopIdleAndStartIdle(idleTypeId: number, technicianId: number) {
    return this.technicianIdleApi.stopIdleAndStartIdle(this.currentIdleInfo.id, {
      idleTypeId,
      technicianId,
    });
  }

  stopIdleAndSignOut(technicianId: number) {
    return this.technicianIdleApi.stopIdleAndSignOut(this.currentIdleInfo.id, {
      technicianId,
    });
  }

  stopIdleAndSignOutWithModal(technicianId: number) {
    return this.getIdleInfo(technicianId)
      .pipe(mergeMap(idleInfo => {
        if (idleInfo.inIdle) {
          const confirmSubject: Subject<number> = new Subject();
          this.showClockOffModal.next({
            title: '下班',
            idleInfo,
            confirmSubject,
            shouldDoubleConfirm: true,
          });
          return confirmSubject.pipe(
            tap(() => this.spinnerService.run()),
            mergeMap(idleId =>
              this.technicianIdleApi.stopIdleAndSignOut(idleId, { technicianId })),
            catchError(() => throwError('error')),
          );
        }
        return EMPTY;
      }));
  }

  updateIdleInfo(idleInfoId: number, body: any) {
    return this.technicianIdleApi.updateIdleInfo(idleInfoId, body);
  }
}

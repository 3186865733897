import { AfterViewChecked, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ImagePreviewerComponent } from '../image-previewer/image-previewer.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit {
  @Input() photos = [];
  @Input() styleOptions: any;
  @Input() showEmptyTemplate: boolean = true;
  @Input() imgUrl: string = '/assets/common/empty-image-icon.svg';
  @Input() emptyText: string = '无上传照片';
  @ViewChild('imagePreviewer') imagePreviewer: ImagePreviewerComponent;

  constructor() { }

  ngOnInit() {
  }

  get previewPhotos(): string[] { return _.map(this.photos, 'uri'); }

  showCarousel(index): void {
    this.imagePreviewer.show(index);
  }

}

import { toEnum } from './enum';
import * as _ from 'lodash';
import { setVehicleBusinessType } from '@otr/website-common';
import { USER_ROLE } from '../constants/user-role.constant';

export const VEHICLE_BUSINESS_TYPE = toEnum({
  PC: { text: '', value: 'PC' },
  VAN: { text: '商用车', value: 'VAN' },
  ALL: { text: '全部', value: 'ALL' },
});

export function isVan(vehicleBusinessType) {
  return _.isEqual(VEHICLE_BUSINESS_TYPE.VAN.value, vehicleBusinessType);
}

export function setInitVehicleBusinessType(currentUserService, tokenService, isTab = false) {
  if (currentUserService.isRole(USER_ROLE.OEM)) {
    setVehicleBusinessType(VEHICLE_BUSINESS_TYPE.PC.value);
    return;
  }
  if (currentUserService.isRoleCodeIn([USER_ROLE.VANOEM.value, USER_ROLE.VAN_OEM_ACCIDENT_LEADS.value])) {
    setVehicleBusinessType(VEHICLE_BUSINESS_TYPE.VAN.value);
    return;
  }
  if (currentUserService.isRoleCodeIn([USER_ROLE.REGION_ACCIDENT_LEADS.value])) {
    setVehicleBusinessType(VEHICLE_BUSINESS_TYPE.ALL.value);
    return;
  }
  if (tokenService.isVanPc('AS')) {
    const vehicleBusinessType = isTab ? VEHICLE_BUSINESS_TYPE.PC.value : VEHICLE_BUSINESS_TYPE.ALL.value;
    setVehicleBusinessType(vehicleBusinessType);
    return;
  }
  const authType = _.find(tokenService.getAuthTypes(), { squad: 'AS' });
  const businessType = _.get(authType, 'business_type', '');
  setVehicleBusinessType(businessType);
}
export function getBusinessTypeFromToken(tokenService) {
  const authType = _.find(tokenService.getAuthTypes(), { squad: 'AS' });
  return  _.get(authType, 'business_type', '');
}

import { Injectable } from '@angular/core';
import { GET, RebirthHttp, Body, PATCH, Path, Query, Headers } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LaborRateListItem } from '../../system-config/model/labor-rate';

@Injectable()
export class LaborRateApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/aftermarket-product/labors/labor-rates')
  getLaborRates(): Observable<Array<LaborRateListItem>> {
    return null;
  }

  @PATCH('/api/sales-website/aftermarket-product/labors/labor-rates/:id')
  updateLaborRate (
    @Path('id') id: string,
    @Body data): Observable<LaborRateListItem> {
    return null;
  }

  @GET('/api/sales-website/aftermarket-product/labor-rates/dealer-operation-log/pageable')
  getLaborRateRecord (@Query('filter') filter: any,
                      @Query('businessType') businessType: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftermarket-product/labor-rates/dealer-operation-log')
  @Headers({ isExportFile: true })
  exportComplaints (@Query('filter') filter: any,
                    @Query('businessType') businessType: string): Observable<any> {
    return null;
  }

}

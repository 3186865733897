import * as _ from 'lodash';
import { silentParseJSON } from '../../_common/utils/common';
import { MbeInfo } from '../../_common/models/order';

function getDepositInfo(mbeInfo, isMBEPaymentType) {
  return _.isNumber(mbeInfo.deposit)
    ? {
      subtitle: !isMBEPaymentType ? mbeInfo.paymentType === 'DEPOSIT' ? '电商已付定金' : '电商已付意向金' : '电商已付意向金',
      type: 'currency',
      text: mbeInfo.deposit,
    }
    : undefined;
}

function formatMBEInfoAsText(subtitle, text) {
  return text
    ? {
      subtitle,
      text,
      type: 'text',
    }
    : undefined;
}

function getFpInfo(mbeInfo) {
  if (_.isEmpty(mbeInfo.productName)) {
    return;
  }
  const items = [
    { label: '金融产品名称', text: mbeInfo.productName, type: 'long-text' },
    { label: '预审批状态', text: mbeInfo.approveStatus, type: 'text' },
    { label: '首付', text: mbeInfo.downPayment, type: 'currency' },
    { label: '月供', text: mbeInfo.monthlyPayment, type: 'currency' },
    { label: '期末车辆保值金额', text: mbeInfo.rvAmount, type: 'currency' },
    { label: '期数', text: mbeInfo.term, type: 'text' },
    { label: '保证金', text: mbeInfo.fpDeposit, type: 'currency' },
  ].filter(item => !_.isEmpty(item.text) || _.isNumber(item.text));
  return {
    subtitle: '已选金融产品',
    type: 'key-values',
    items,
  };
}

function getMBEAccessories(mbeInfo) {
  const technicalAccessories = mbeInfo.technicalAccessories;

  if (!technicalAccessories) {
    return;
  }

  const accessories = silentParseJSON(technicalAccessories);
  if (_.isEmpty(accessories)) {
    return;
  }
  return {
    subtitle: '已选精品',
    type: 'accessories',
    items: accessories,
  };
}

export const getMBEInfoData = (mbeInfo: MbeInfo, isMBEPaymentType: boolean = false, isChangeVehicleOrder: boolean = true) => {
  if (_.isEmpty(mbeInfo)) {
    return null;
  }

  const content = isChangeVehicleOrder ? _.compact([
    formatMBEInfoAsText('电商订单编号', mbeInfo.mbeOrderId),
    formatMBEInfoAsText('勘测地址', mbeInfo.installmentAddress),
    formatMBEInfoAsText('电商分配车辆VIN码', mbeInfo.vin),
    getDepositInfo(mbeInfo, isMBEPaymentType),
    getFpInfo(mbeInfo),
    formatMBEInfoAsText('电商礼包', mbeInfo.gift),
    getMBEAccessories(mbeInfo),
  ])
:
  _.compact([
    formatMBEInfoAsText('电商订单编号', mbeInfo.mbeOrderId),
    formatMBEInfoAsText('勘测地址', mbeInfo.installmentAddress),
    formatMBEInfoAsText('电商分配车辆VIN码', mbeInfo.vin),
    getDepositInfo(mbeInfo, isMBEPaymentType),
    getFpInfo(mbeInfo),
    formatMBEInfoAsText('电商礼包', mbeInfo.gift),
    formatMBEInfoAsText('线上销售专属活动编号', mbeInfo.campaignCode),
    formatMBEInfoAsText('线上销售专属活动名称', mbeInfo.campaign),
    getMBEAccessories(mbeInfo),
  ]);

  return _.isEmpty(content)
    ? null
    : {
      title: '电商信息',
      content,
    };
};

export const orderUtils = {
  getMBEInfoData,
};

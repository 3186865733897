import * as _ from 'lodash';

import { getMonthListByYearsFromNow, priceFormatter } from '../utils/utils';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import {
  FILTER_INPUT_TYPES,
  SA_NAME_WITH_ALL_AGGREGATED_OPTIONS,
  SERVICE_RECORD_CATEGORY_WITH_ALL_AGGREGATED_OPTIONS,
  VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';

export const MONTHLY_REVENUE_STATISTICS_CONFIG = {
  name: 'monthly-revenue-statistics',
  enablePagination: false,
  exportedFileName: '当月营业统计.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '日期',
      field: 'dayOfMonth',
      width: 100,
      valueFormatter: ({ value }) => `${value}号`,
    },
    {
      headerName: '日进车辆数',
      field: 'checkedInCount',
      width: 100,
    },
    {
      headerName: '日出车辆数',
      field: 'checkedOutCount',
      width: 100,
    },
    {
      headerName: '工时销售-零售 (¥)',
      field: 'laborAmountRetail',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '工时销售-内部 (¥)',
      field: 'laborAmountInternal',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '工时销售-保修 (¥)',
      field: 'laborAmountClaim',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '零件销售(车间)-零售 (¥)',
      field: 'partAmountRetail',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '零件销售(车间)-内部 (¥)',
      field: 'partAmountInternal',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '零件销售(车间)-保修 (¥)',
      field: 'partAmountClaim',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '零件外销 (零件部门) (¥)',
      field: 'partSalesAmount',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
      hide: true,
    },
    {
      headerName: '杂费-零售 (¥)',
      field: 'sundryAmountRetail',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '杂费-内部 (¥)',
      field: 'sundryAmountInternal',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '杂费-保修 (¥)',
      field: 'sundryAmountClaim',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '外加工-零售 (¥)',
      field: 'outsourcedLaborAmountRetail',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '外加工-内部 (¥)',
      field: 'outsourcedLaborAmountInternal',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: 'VAT (¥)',
      field: 'taxAmount',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '销售总计 (含税¥)',
      field: 'totalAmountIncludingTax',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'month',
      label: '月份',
      options: getMonthListByYearsFromNow(3),
      defaultValue: _.head(getMonthListByYearsFromNow(3)),
    },
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '服务人员',
      options: SA_NAME_WITH_ALL_AGGREGATED_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_AGGREGATED_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'serviceRecordCategory',
      label: '订单类型',
      options: SERVICE_RECORD_CATEGORY_WITH_ALL_AGGREGATED_OPTIONS.values,
      defaultValue: SERVICE_RECORD_CATEGORY_WITH_ALL_AGGREGATED_OPTIONS.get('ALL'),
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型 :',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
  ],
};

<div
  #elem
  (mousedown)="onMousedown($event)"
  class="component__dealer-for-report-drag-bar">
  <button nz-button 
    class="c-btn"
    nzType="primary"
    
    [style.display]="expand?'none':'block'"
    [tooltip]="current_dealer_name"
    placement="top"
    (click)="expandPanelHandler($event)" nzSize="normal">
    <i nz-icon nzType="shop"></i>
    {{current_dealer_name}}
  </button>

  <div [style.display]="!expand?'none':'block'" class="c-panel">
    <span class="c-close" (click)="expandPanelHandler($event)"><i nz-icon nzType="close"></i></span>

    <!-- <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
      <input type="text" [(ngModel)]="search_text" nz-input placeholder="请输入搜索内容..." />
    </nz-input-group>
    <ng-template #suffixIconButton>
      <button class="c-search-btn" (click)="searchButtonHandler()" nz-button nzType="primary" nzSearch><i nz-icon nzType="search"></i></button>
    </ng-template> -->

    <div class="c-select-area">
      <label>区域</label>
      <nz-select [(ngModel)]="select_area" nzPlaceHolder="请选择区域" (ngModelChange)="selectAreaChangeHandler($event)">
        <nz-option-group *ngFor="let x of select_area_options" [nzLabel]="x.name">
          <nz-option *ngFor="let item of x.list" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
        </nz-option-group>
        
      </nz-select>
    </div>

    <div class="c-result-list">
      <label>选择经销商</label>
      <span (click)="selectDealerItemHandler($event, item)" 
        [tooltip]="item.name_dealer_branch"
        placement="top"
        *ngFor="let item of dealer_list" class="c-item">{{item.name_dealer_branch}}</span>
    </div>
  </div>
</div>
import { EventEmitter, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { ShowroomVisitApi } from '../api/showroom-visit';
import { LeadReservation, LeadSource, ShowroomVisit, ShowroomVisitForHall } from '../models/lead';
import { LEAD_SOURCE_FILTER_TYPE, SHOWROOM_VISIT } from '../constants/showroom-visit';
import { getDateAtZero, getDateAtZeroOfNextDay } from '../utils/date';
import { exportExcelOnDifferentBrowsers } from '../utils/download';
import { dateParse } from '../utils/date-parse';
import {
  StoreService,
  SpinnerService,
} from '@otr/website-common';

export enum VisitStatus {
  Today,
  Unfinished,
  Reservation,
}

@Injectable()
export class ShowroomVisitService {
  leadSources: Array<LeadSource> = [];
  visitStatusSubject: BehaviorSubject<VisitStatus> = new BehaviorSubject(VisitStatus.Today);
  lastLoadDate: Date;
  loadShowroomVisit$ = new EventEmitter();
  loadLeadReservationList$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentDateShowroomVisitCount = 0;
  unfinishedCount = 0;
  reservationsCount = 0;
  constructor(
    private showroomVisitApi: ShowroomVisitApi,
    private storeService: StoreService,
    private spinnerService: SpinnerService,
  ) {}

  get isTodayVisit() {
    return this.visitStatusSubject.getValue() === VisitStatus.Today;
  }

  get isUnfinishedVisit() {
    return this.visitStatusSubject.getValue() === VisitStatus.Unfinished;
  }

  set visitStatus(status: VisitStatus) {
    this.visitStatusSubject.next(status);
  }

  loadShowroomVisit(date: Date) {
    this.lastLoadDate = date;
    this.storeService.update(SHOWROOM_VISIT.LIST, []);
    this.showroomVisitApi
      .getShowroomVisitListData(getDateAtZero(date), getDateAtZeroOfNextDay(date))
      .subscribe((showroomVisitList: Array<ShowroomVisit>) => {
        const preparedData = this.prepareData(showroomVisitList);
        this.storeService.update(SHOWROOM_VISIT.LIST, preparedData.data);
        this.storeService.update(SHOWROOM_VISIT.COUNTS_FOR_SELECTED_DATE, preparedData);
        this.currentDateShowroomVisitCount = preparedData.data.length;
        this.loadShowroomVisit$.emit();
      });
  }

  loadStaticShowroomVisitData(date: Date) {
    // tslint:disable-next-line:max-line-length
    return this.showroomVisitApi.getShowroomVisitListData(getDateAtZero(date), getDateAtZeroOfNextDay(date));
  }

  exportShowroomVisit(from: number, to: number) {
    exportExcelOnDifferentBrowsers(
      this.showroomVisitApi.exportShowroomVisitListData(from, to),
      `展厅流量日志 ${dateParse(from, 'yyyy.MM.dd')}`,
    );
  }

  loadUnfinishedShowroomVisit() {
    if (this.isUnfinishedVisit) {
      this.storeService.update(SHOWROOM_VISIT.LIST, []);
    }
    this.showroomVisitApi.getListDataByStatus('unfinished').subscribe((showroomVisitList: Array<ShowroomVisit>) => {
      const preparedData = this.prepareData(showroomVisitList);
      if (this.isUnfinishedVisit) {
        this.storeService.update(SHOWROOM_VISIT.LIST, preparedData.data);
      }
      this.unfinishedCount = preparedData.data.length;
      this.storeService.update(SHOWROOM_VISIT.COUNTS_FOR_UNFINISHED, preparedData.showroomVisitsCount);
      this.loadShowroomVisit$.emit();
    });
  }

  refreshShowroomVisit() {
    this.isUnfinishedVisit ? this.loadUnfinishedShowroomVisit() : this.loadShowroomVisit(this.lastLoadDate);
  }

  prepareData(showroomVisitList): ShowroomVisitForHall {
    const sortedShowroomVisitList = this.sortShowroomVisitList(showroomVisitList);
    const leadCount = this.getLeadCount(showroomVisitList);
    const purposeCount = this.getPurposeCount(showroomVisitList);
    const testDriveCount = this.getTestDriveCount(showroomVisitList);
    return {
      data: sortedShowroomVisitList,
      showroomVisitsCount: showroomVisitList.length,
      leadsCount: leadCount,
      purposeCount,
      testDriveCount,
    };
  }

  getLeadCount(showroomVisitList) {
    return _.chain(showroomVisitList)
      .filter(
        (showroomVisit: ShowroomVisit) =>
          !_.isEmpty(showroomVisit.lead.last_name) &&
          this.isPhonesValid(showroomVisit.lead) &&
          this.isInterestedVehiclesValid(showroomVisit.lead.interested_vehicles),
      )
      .size()
      .value();
  }

  isPhonesValid(lead) {
    return !_.isEmpty(lead.mobile) || !_.isEmpty(lead.home_phone) || !_.isEmpty(lead.work_phone);
  }

  isInterestedVehiclesValid(interestedVehicles) {
    return _.some(
      interestedVehicles,
      vehicle => !_.isEmpty(_.get(vehicle, 'class.description_en') || _.get(vehicle, 'class.description_zh')),
    );
  }

  getPurposeCount(showroomVisitList) {
    return _.filter(showroomVisitList, showroomVisit =>
      _.includes(['看车比价', '订单', '现订现交'], showroomVisit.purpose),
    ).length;
  }

  getTestDriveCount(showroomVisitList) {
    return _.sumBy(showroomVisitList, 'lead.test_drive_count') || 0;
  }

  sortShowroomVisitList(showroomVisitList) {
    if (this.isTodayVisit) {
      return _.orderBy(
        showroomVisitList,
        [this.getOrderByIsVisitFinished, this.getOrderBySalesConsultantAssigned, this.sortByArrivalTime],
        ['asc', 'asc', 'desc'],
      );
    }
    return _.orderBy(showroomVisitList, this.sortByArrivalTime, 'desc');
  }

  finishShowroomVisit(showroomVisit, patch: any) {
    return this.showroomVisitApi
      .finishShowRoomVisit({
        id: showroomVisit.id,
        leadId: showroomVisit.lead_id,
        finish_reason_within_three_minutes: showroomVisit.endReason,
        ..._.omit(patch, ['time']),
        leaveTime: new Date(showroomVisit.arrival_time).setHours(patch.time.hour, patch.time.minute),
      })
      .pipe(this.spinnerService.loading());
  }

  testDrivePreAssign(assignData): Observable<any> {
    return this.showroomVisitApi.testDrivePreAssign(assignData);
  }

  assignSalesConsultantToVisit(showroomVisit, salesConsultantId) {
    return this.showroomVisitApi.updateShowRoomVisit(showroomVisit.id, {
      sales_consultant_id: salesConsultantId,
    });
  }

  getShowroomVisitsByLeadId(leadId) {
    return this.showroomVisitApi.getShowroomVisitsByLeadId(leadId);
  }

  getProductExpert(leadId) {
    return this.showroomVisitApi.getProductExpert(leadId);
  }

  getLeadReservationList(date: Date): Observable<LeadReservation[]> {
    return this.showroomVisitApi.getLeadReservationList(getDateAtZero(date), getDateAtZeroOfNextDay(date));
  }

  fetchLeadSources(type: string = LEAD_SOURCE_FILTER_TYPE.OTR) {
    if (type === LEAD_SOURCE_FILTER_TYPE.ALL) {
      return this.showroomVisitApi.getLeadSources(type);
    }
    return _.isEmpty(this.leadSources) && this.showroomVisitApi.getLeadSources(type);
  }

  private getOrderByIsVisitFinished = (showroomVisit: ShowroomVisit) => (_.isNumber(showroomVisit.leave_time) ? 1 : 0);

  private getOrderBySalesConsultantAssigned = (showroomVisit: ShowroomVisit) =>
    _.isEmpty(showroomVisit.sales_consultant_id) ? 0 : 1;

  private sortByArrivalTime = (showroomVisit: ShowroomVisit) => showroomVisit.arrival_time;
}

export const MESSAGE_TYPE = {
  ORDER_PENDING_APPROVE: {
    value: 'ORDER_PENDING_APPROVE',
    text: '订单',
    preLink: '/order/detail/',
  },
  WHOLESALE_INVOICE: {
    value: 'WHOLESALE_INVOICE',
    text: '车辆',
    preLink: '/vehicle/detail/',
  },
  PREORDER_PENDING_APPROVE: {
    value: 'PREORDER_PENDING_APPROVE',
    text: '订单',
    preLink: '/preorder/detail/',
  },
  DMS: {
    value: 'DMS',
    text: '订单',
    preLink: '/order/detail/',
  },
  ORDER_TO_PREORDER: {
    value: 'ORDER_TO_PREORDER',
    text: '订单',
    preLink: '/preorder/detail/',
  },
  COMPLAINTS_NOTICE: {
    value: 'COMPLAINTS_NOTICE',
    text: '投诉',
    preLink: '/case/',
  },
  CUSTOMER_ENTER_STORE: {
    value: 'CUSTOMER_ENTER_STORE',
    text: '进店客户通知',
    preLink: '/insurance/customer-detail/',
  },
};

export const MESSAGE = {
  DOWNLOAD_SUCCESS: '下载成功',
  IMPORT_SUCCESS: '导入完成',
  OPERATION_SUCCESS: '操作成功',
};

export const VIN_FOR_REDIRECTION = 'vinForRedirection';
export const UUID_FOR_REDIRECTION = 'uuidForRedirection';
export const POLICY_CYCLE_ID_FOR_REDIRECTION = 'policyCycleIdForRedirection';

import { Component } from '@angular/core';
import * as _ from 'lodash';
import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Subject } from 'rxjs';

@Component({
  template: `<span class="edit-row-button" (click)="onStartEditing()" *ngIf="!disabled">
    <i class="fa antd-form-outlined edit-icon" aria-hidden="true" [tooltip]="tooltip" placement="bottom"></i>
    <span *ngIf="shouldShowText">{{ editText }}</span></span>`,
  styleUrls: ['edit-row.style.scss'],
})
export class EditRowRenderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  disabled: boolean = false;
  shouldShowText: boolean = false;
  tooltip: string = '编辑';
  editText: string = '编辑';

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.disabled = this.getDisabled();
    this.tooltip = this.getTooltip();
    this.shouldShowText = this.getShouldShowText();
    this.editText = this.getEditText();
  }

  refresh(params: any): boolean {
    return false;
  }

  getDisabled() {
    if (this.cellRendererParams.disabled instanceof Subject) {
      return this.cellRendererParams.disabled.getValue();
    }

    return _.isFunction(this.cellRendererParams.disabled) ?
      this.cellRendererParams.disabled(this.params) : this.cellRendererParams.disabled;
  }

  getTooltip() {
    if (_.isNil(this.cellRendererParams.shouldShowTooltip) || this.cellRendererParams.shouldShowTooltip) {
      return '编辑';
    }
    return null;
  }

  getShouldShowText() {
    return _.isNil(this.cellRendererParams.shouldShowText) ? false : this.cellRendererParams.shouldShowText;
  }

  getEditText() {
    return !_.isNil(this.cellRendererParams.editText) ? this.cellRendererParams.editText : '编辑';
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onStartEditing() {
    this.cellRendererParams.onStartEditing(this.params);
  }
}

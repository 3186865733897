import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-order-approval-process-detail',
  templateUrl: './order-approval-process-detail.component.html',
  styleUrls: ['./order-approval-process-detail.component.scss'],
})
export class OrderApprovalProcessDetailComponent implements OnInit {
  @Input() order;
  approvalUsers: any;

  ngOnInit(): void {
    this.approvalUsers = this.getApprovalUsers();
  }

  getApprovalUsers() {
    const approvalFlows = _.get(this.order, 'approval_flows');
    const rejectApproved = _.filter(approvalFlows,
      (flow: any) => flow.is_approved === false);
    if (!_.isEmpty(rejectApproved)) {
      return [];
    }
    const notFinishedApprovalFlows = _.filter(approvalFlows, (flow: any) => !flow.is_finished);
    return _.map(notFinishedApprovalFlows, approvalFlow => {
      const role = _.get(approvalFlow, 'role') ? _.join(approvalFlow.role, '或') : '';
      const users = _.map(approvalFlow.approval_users, (user: any) => user.full_name);
      return `${role}：${users.join('，')}`;
    });
  }
}

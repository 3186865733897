import { Injectable } from '@angular/core';
import { Body, GET, POST, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OutletSettingsApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/website/dealers/settings')
  getOutletSettingsByOutletId(): Observable<any> {
    return null;
  }

  @POST('/api/website/dealers/settings')
  updateOutletSettings(@Body data): Observable<any> {
    return null;
  }

  @GET('/api/website/dealers/pilot-dealers')
  getPilotDealers(): Observable<Array<string>> {
    return null;
  }

  @GET('/api/website/dealers/settings/dealer-info')
  getBankSettings(): Observable<any> {
    return null;
  }

  @POST('/api/website/dealers/settings/dealer-info')
  createOrUpdateBankSettings(@Body data): Observable<any> {
    return null;
  }

  @GET('/api/website/dealers/as-lead-pilot-dealers/validation')
  getIsASLeadPilotDealer(): Observable<any> {
    return null;
  }
}

import { PositionedModal, ModalPosition } from './position';
import { Subject } from 'rxjs';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  templateUrl: './confirm-service-modal.template.html',
  styleUrls: ['./confirm-service-modal.style.scss'],
})
export class ConfirmServiceModalComponent implements PositionedModal {

  @ViewChild('modal', { read: ElementRef }) modal: ElementRef;

  title: string;
  description: string;
  confirmText: string;
  cancelText: string;
  left: number;
  top: number;
  private subject: Subject<boolean>;

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this._eref.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.cancel();
    }
  }

  getClientHeight(): number {
    const modal = <HTMLElement> this.modal.nativeElement;
    const { height } = modal.getBoundingClientRect();
    return height;
  }

  getClientWidth(): number {
    const modal = <HTMLElement> this.modal.nativeElement;
    const { width } = modal.getBoundingClientRect();
    return width;
  }

  setProperties(options: any): void {
    this.title = options.title;
    this.description = options.description;
    this.confirmText = options.confirmText || '确认';
    this.cancelText = options.cancelText || '取消';
  }

  setPosition(position: ModalPosition): void {
    this.left = position.left;
    this.top = position.top;
  }

  constructor(private _eref: ElementRef) {
    this.subject = new Subject<boolean>();
  }

  getSubject(): Subject<boolean> {
    return this.subject;
  }

  confirm() {
    this.subject.next(true);
  }

  cancel() {
    this.subject.next(false);
  }
}

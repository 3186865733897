import { AutoHeightDirective } from './auto-height.directive';
import { AutoLocationDirective } from './auto-location.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { MousedownOutsideDirective } from './mousedown-outside.directive';
import { DebounceClickDirective } from './debounceClick.directive';
import { DebounceInputControlValueAccessorDirective } from './debounceTime.directive';
import { IsVisibleForCampaignActionsDirective } from './is-visible-for-campaign-actions.directive';
import { NumberFormatDirective } from './numberFormat.directive';
import { NumberInputDirective } from './number-input.directive';
import { InputPatternDirective } from './input-pattern.directive';
import { DisableControlDirective } from './disable-control.directive';
import { TextTooltipDirective } from './text-tooltip.directive';
import { IsEllipsisActiveDirective } from './is-ellipsis-active/is-ellipsis-active.directive';
import { AutoChangeHrefRouteDirective } from './auto-change-href-route.directive';
import { ThrottleClickDirective } from './throttleClick.directive';
import { WatermarkDirective } from './watermark.directive';
import { DecimalInputDirective } from './decimal-input.directive';

export const DIRECTIVE = [
  AutoHeightDirective,
  AutoLocationDirective,
  ClickOutsideDirective,
  MousedownOutsideDirective,
  DebounceClickDirective,
  ThrottleClickDirective,
  DebounceInputControlValueAccessorDirective,
  IsVisibleForCampaignActionsDirective,
  NumberFormatDirective,
  NumberInputDirective,
  InputPatternDirective,
  DisableControlDirective,
  TextTooltipDirective,
  IsEllipsisActiveDirective,
  AutoChangeHrefRouteDirective,
  WatermarkDirective,
  DecimalInputDirective
];

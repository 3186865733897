import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';

const getValue = (value, params) => {
  if (value instanceof Function) {
    return value.call(null, params);
  }
  return value;
};

@Component({
  template: `
    <a [title]="text" [routerLink]="link" [queryParams]="queryParams" *ngIf="link">{{ text }}</a>
    <a [title]="text" class = "disable-link" href="javascript:void(0)" *ngIf="displayPlaceholderIfEmpty && !link">{{ text }}</a>
    <span *ngIf="displayLabelName">{{labelName}}</span>
  `,
  styleUrls: ['./link.style.scss'],
})
export class LinkComponent implements AgRendererComponent {
  text: string = '';
  link: string;
  displayPlaceholderIfEmpty: boolean = false;
  labelName: string;
  displayLabelName: boolean = false;
  queryParams: Object;

  agInit(params: ICellRendererParams): void {
    const cellRendererParams = params.colDef.cellRendererParams;
    this.text = getValue(cellRendererParams.text, params);
    this.link = getValue(cellRendererParams.link, params);
    this.queryParams = getValue(cellRendererParams.queryParams, params) || {};
    this.displayPlaceholderIfEmpty = getValue(cellRendererParams.displayPlaceholderIfEmpty, params);
    this.labelName = getValue(cellRendererParams.labelName, params);
    this.displayLabelName = getValue(cellRendererParams.displayLabelName, params);
  }

  refresh(params: any): boolean {
    return false;
  }
}

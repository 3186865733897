import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ModalPosition, PositionedModal } from '../../_common/components/modal/services/position';
import { Subject } from 'rxjs';
import { MultipleDatePickerComponent } from 'multiple-date-picker-angular/dist';
import * as _ from 'lodash';

export interface MultipleDatesPickerOptions {
  selectedDates: Array<any>;
  highlightDays: Array<any>;
  disableDaysBefore?: any;
}

@Component({
  selector: 'app-multiple-date-picker-modal',
  templateUrl: './multiple-date-picker-modal.component.html',
  styleUrls: ['./multiple-date-picker-modal.component.scss'],
})
export class MultipleDatePickerModalComponent implements PositionedModal, AfterViewInit {
  left: number = 0;
  top: number = 0;
  selectedDates: Array<any>;
  highlightDays: Array<any>;
  disableDaysBefore: any;

  subject: Subject<any> = new Subject<any>();

  @ViewChild(MultipleDatePickerComponent, { read: ElementRef }) pickerElement: ElementRef;
  @ViewChild(MultipleDatePickerComponent) pickerComponent: MultipleDatePickerComponent;

  getClientHeight(): number {
    const pickerElement = <HTMLElement> this.pickerElement.nativeElement;
    const { height } = pickerElement.getBoundingClientRect();
    return height;
  }

  getClientWidth(): number {
    const pickerElement = <HTMLElement> this.pickerElement.nativeElement;
    const { width } = pickerElement.getBoundingClientRect();
    return width;
  }

  getSubject(): Subject<any> {
    return this.subject;
  }

  setPosition(position: ModalPosition): void {
    this.left = position.left;
    this.top = position.top;
  }

  setProperties(options: MultipleDatesPickerOptions): void {
    this.selectedDates = options.selectedDates || [];
    this.highlightDays = options.highlightDays || [];
    this.disableDaysBefore = options.disableDaysBefore || null;
  }

  confirm() {
    this.subject.next(_.uniq(_.map(this.selectedDates, '_d')));
  }

  onPickerClick(event) {
    event.stopPropagation();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.pickerComponent.generate());
  }
}

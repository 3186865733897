import {
  DedicatedTechnicalReminderStatus,
  dedicatedTechnicalReminderStatusOptions,
  statusStyleMapping
} from '../constant/dedicatedTechnicalReminderConstant';
import * as _ from 'lodash';

export const matchDedicatedTechnicalReminderStatus = (status: DedicatedTechnicalReminderStatus) =>
  _.get(_.find(dedicatedTechnicalReminderStatusOptions, {value: status}), 'text', '');

export const matchDedicatedTechnicalReminderClass = (status: DedicatedTechnicalReminderStatus) =>
  _.get(_.find(statusStyleMapping, {value: status}), 'className', '');

export const isSupersededReminder = status => _.isEqual(status, DedicatedTechnicalReminderStatus.SUPERSEDED);

export const isInitialReminder = status => _.isEqual(status, DedicatedTechnicalReminderStatus.INITIAL);

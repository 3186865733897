import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

interface Option {
  text: string;
  value: string;
}

@Component({
  selector: 'app-multiple-dropdown',
  templateUrl: './multiple-dropdown.component.html',
  styleUrls: ['./multiple-dropdown.component.scss'],
})
export class MultipleDropdownComponent implements OnInit, OnDestroy {
  @Input() placeholder = '请选择';
  @Input() disabled = false;
  @Input() placeholderFlag = false;
  @Input() options = [] as Option[] | string[];
  @Input() allValue = '';
  @Input() selectedValue = [] as string[];
  @Input() separator = ' + ';
  @Output() select = new EventEmitter<string[]>();
  @Output() onClose = new EventEmitter();

  open = false;

  private isSelfClick = false;

  close = () => {
    if (this.isSelfClick) {
      this.isSelfClick = false;
      return;
    }
    this.open = false;
    if (this.onClose) {
      this.onClose.emit();
    }
  };

  ngOnInit() {
    document.addEventListener('click', this.close);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.close);
  }

  get isAllSelected() {
    if (this.allValue) {
      const values = typeof this.options[0] === 'string' ?
        this.options as string[] :
        _.map(this.options as Option[], ({ value }) => value);
      if (_.includes(values, this.allValue) && this.selectedValue.length === values.length - 1) {
        return true;
      }
    }
    return false;
  }

  get text() {
    if (!this.selectedValue.length) {
      return this.placeholder;
    }

    const isStringArray = typeof this.options[0] === 'string';
    if (this.isAllSelected) {
      return isStringArray ?
        this.allValue :
        _.find(this.options as Option[], ({ value }) => value === this.allValue).text;
    }

    return isStringArray ?
      this.selectedValue.join(this.separator) :
      _.filter(this.options as Option[], ({ value }) => _.includes(this.selectedValue, value)).map(({ text }) => text).join(this.separator);
  }

  markSelfClick() {
    this.isSelfClick = true;
  }

  toggle() {
    this.open = !this.open;
  }

  isSelected(value: string) {
    if (this.isAllSelected) {
      return true;
    }
    return _.includes(this.selectedValue, value);
  }

  onSelect(value: string) {
    if (value === this.allValue) {
      const values = typeof this.options[0] === 'string' ?
        this.options as string[] :
        _.map(this.options as Option[], ({ value: v }) => v);
      this.select.emit(this.selectedValue.length === values.length - 1 ?
        [] as string[] :
        values.filter(v => v !== this.allValue));
      return;
    }
    this.select.emit(_.includes(this.selectedValue, value) ?
      this.selectedValue.filter(v => v !== value) :
      [...this.selectedValue, value]);
  }
}

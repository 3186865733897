import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import {
  DROPDOWN_DATE_RANGE_OPTION_HALF_YEAR,
  DROPDOWN_DATE_RANGE_OPTION_MONTH,
  DROPDOWN_DATE_RANGE_OPTION_QUARTER,
  DROPDOWN_DATE_RANGE_OPTION_YEAR,
  DROPDOWN_DATE_RANGE_OPTIONS_DAY,
  FILTER_INPUT_TYPES,
  MONTH_SEASON_AND_HALF_YEAR_REFRESH_TIME,
  REFRESH_TIME, VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import * as moment from 'moment';
import { DATE_FORMAT } from '../../_common/constants/common';
import {
  amountFormatter,
  getAccidentCarSettingByEnvironment,
  percentageFormatter,
  reportDateFormatter,
} from '../utils/utils';
import { USER_ROLE } from '../../_common/constants/user-role.constant';

export const ACCIDENT_CAR_LEAD_KPI_OPERATION_CONFIG = {
  name: 'accident-car-lead-kpi-operation-report',
  enablePagination: true,
  pageSize: 50,
  filterThreeColumn: true,
  filterFourColumn: true,
  exportedFileName: '线索运营KPI报表.xlsx',
  timeFormatter: 'YYYYMMDDHHmmss',
  isNameWithDate: true,
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  refreshTime: REFRESH_TIME,
  monthAndQuarterAndYearRefreshTime: MONTH_SEASON_AND_HALF_YEAR_REFRESH_TIME,
  defaultSearchWhenEnterReport: true,
  vertical: true,
  showSummaryLine: true,
  newLineOperation: true,
  isNameWithVehicleBusinessType: true,
  column: [
    {
      headerName: '时间',
      field: 'reportDate',
      width: 250,
      pinned: true,
      valueFormatter: reportDateFormatter,
    },
    {
      headerName: '经销商代码',
      field: 'gsCode',
      width: 120,
    },
    {
      headerName: '经销商名称',
      field: 'dealerName',
      width: 120,
    },
    {
      headerName: '线索总量（去重后）',
      field: 'totalCount',
      width: 160,
    },
    {
      headerName: '线索总量（不去重）',
      field: 'totalCountWithRepeat',
      width: 160,
    },
    {
      headerName: '有效线索率',
      field: 'validLeadRate',
      width: 140,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '3分钟及时跟进率',
      field: 'followRateInThreeMinutes',
      width: 140,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '24小时留修率',
      field: 'arrivedRateInTwentyFourHours',
      width: 140,
      valueFormatter: ({ value }) => value == '-'  ? value : percentageFormatter({value}),
    },
    {
      headerName: '48小时留修率',
      field: 'arrivedRateInFortyEightHours',
      width: 140,
      valueFormatter: ({ value }) => value == '-'  ? value : percentageFormatter({value}),
    },
    {
      headerName: '72小时留修率',
      field: 'arrivedRateInSeventyTwoHours',
      width: 140,
      valueFormatter: ({ value }) => value == '-'  ? value : percentageFormatter({value}),
    },
    {
      headerName: '30天留修率',
      field: 'arrivedRateInThirtyDays',
      width: 140,
      valueFormatter: ({ value }) => value == '-'  ? value : percentageFormatter({value}),
    },
    {
      headerName: '60天留修率',
      field: 'arrivedRateInSixtyDays',
      width: 140,
      valueFormatter: ({ value }) => value == '-'  ? value : percentageFormatter({value}),
    },
    {
      headerName: '有效线索量',
      field: 'validLeadCount',
      width: 160,
    },
    {
      headerName: '3分钟及时跟进量',
      field: 'followCountInThreeMinutes',
      width: 160,
    },
    {
      headerName: '24小时留修量',
      field: 'arrivedCountInTwentyFourHours',
      width: 160,
    },
    {
      headerName: '48小时留修量',
      field: 'arrivedCountInFortyEightHours',
      width: 160,
    },
    {
      headerName: '72小时留修量',
      field: 'arrivedCountInSeventyTwoHours',
      width: 160,
    },
    {
      headerName: '30天留修量',
      field: 'arrivedCountInThirtyDays',
      width: 160,
    },
    {
      headerName: '60天留修量',
      field: 'arrivedCountInSixtyDays',
      width: 160,
    },
    {
      headerName: '跟进量',
      field: 'followCount',
      width: 100,
    },
    {
      headerName: '未跟进量',
      field: 'unFollowCount',
      width: 120,
    },
    {
      headerName: '外勤量',
      field: 'fieldworkCount',
      width: 100,
    },
    {
      headerName: '外勤现场到达量',
      field: 'fieldworkArrivedCount',
      width: 150,
    },
    {
      headerName: '外勤未现场到达量',
      field: 'fieldworkUnArrivedCount',
      width: 150,
    },
    {
      headerName: '留修量',
      field: 'arrivedCount',
      width: 100,
    },
    {
      headerName: '未留修量',
      field: 'unArrivedCount',
      width: 120,
    },
    {
      headerName: '维修量',
      field: 'repairedCount',
      width: 100,
    },
    {
      headerName: '战败量',
      field: 'defeatedCount',
      width: 100,
    },
    {
      headerName: '到店未维修量',
      field: 'arrivedUnrepairedCount',
      width: 110,
    },
    {
      headerName: '待确定量',
      field: 'pendingCount',
      width: 100,
    },
    {
      headerName: '总产值（含税）',
      field: 'totalGrossAmount',
      width: 130,
      valueFormatter: amountFormatter,
    },
    {
      headerName: '保险公司付费金额（含税）',
      field: 'totalInsurancePurchaseAmount',
      width: 160,
      valueFormatter: amountFormatter,
    },
    {
      headerName: '客户付费金额（含税）',
      field: 'totalCustomerPurchaseAmount',
      width: 160,
      valueFormatter: amountFormatter,
    },
    {
      headerName: '单均线索产值（含税）',
      field: 'avgGrossAmount',
      width: 150,
      valueFormatter: amountFormatter,
    },
    {
      headerName: '跟进率',
      field: 'followRate',
      width: 100,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '外勤率',
      field: 'fieldworkRate',
      width: 100,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '外勤现场到达率',
      field: 'fieldworkArrivedRate',
      width: 120,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '留修率',
      field: 'arrivedRate',
      width: 100,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '维修率',
      field: 'repairedRate',
      width: 100,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '战败率',
      field: 'defeatedRate',
      width: 100,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '钣喷专享礼线索占比',
      field: 'mbSmsTypeRate',
      width: 150,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '钣喷专享礼线索维修率',
      field: 'mbSmsTypeRepairedRate',
      width: 150,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '钣喷专享礼线索产值占比',
      field: 'mbSmsTypeGrossAmountRate',
      width: 160,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '区域平均线索量',
      field: 'regionAvgLeadCount',
      width: 140,
      valueFormatter: ({ value }) => (value == '-' ? ' ' : amountFormatter({ value })),
    },
    {
      headerName: '区域平均3分钟及时跟进率',
      field: 'regionAvgFollowRateInThreeMinutes',
      width: 160,
      valueFormatter: ({ value }) => (value == '-' ? ' ' : percentageFormatter({ value })),
    },
    {
      headerName: '区域平均跟进率',
      field: 'regionAvgFollowRate',
      width: 140,
      valueFormatter: ({ value }) => (value == '-' ? ' ' : percentageFormatter({ value })),
    },
    {
      headerName: '区域平均留修率',
      field: 'regionAvgArrivedRate',
      width: 140,
      valueFormatter: ({ value }) => (value == '-' ? ' ' : percentageFormatter({ value })),
    },
    {
      headerName: '区域平均维修率',
      field: 'regionAvgRepairedRate',
      width: 140,
      valueFormatter: ({ value }) => (value == '-' ? ' ' : percentageFormatter({ value })),
    },
    {
      headerName: '区域平均线索产值（含税）',
      field: 'regionAvgGrossAmount',
      width: 160,
      valueFormatter: ({ value }) => (value == '-' ? ' ' : amountFormatter({ value })),
    },
    {
      headerName: '区域单均线索产值（含税）',
      field: 'regionAvgGrossAmountPerLead',
      width: 160,
      valueFormatter: ({ value }) => (value == '-' ? ' ' : amountFormatter({ value })),
    },
    {
      headerName: '全国平均线索量',
      field: 'nationalAvgLeadCount',
      width: 140,
      valueFormatter: amountFormatter,
    },
    {
      headerName: '全国平均3分钟及时跟进率',
      field: 'nationalAvgFollowRateInThreeMinutes',
      width: 160,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '全国平均跟进率',
      field: 'nationalAvgFollowRate',
      width: 140,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '全国平均留修率',
      field: 'nationalAvgArrivedRate',
      width: 140,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '全国平均维修率',
      field: 'nationalAvgRepairedRate',
      width: 140,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '全国平均产值（含税）',
      field: 'nationalAvgGrossAmount',
      width: 160,
      valueFormatter: amountFormatter,
    },
    {
      headerName: '全国单均线索产值（含税）',
      field: 'nationalAvgGrossAmountPerLead',
      width: 160,
      valueFormatter: amountFormatter,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DROPDOWN_DATE_RANGE,
      title: '选择时间',
      optionName: 'dimension',
      options: {
        ...DROPDOWN_DATE_RANGE_OPTIONS_DAY,
        ...DROPDOWN_DATE_RANGE_OPTION_MONTH,
        ...DROPDOWN_DATE_RANGE_OPTION_QUARTER,
        ...DROPDOWN_DATE_RANGE_OPTION_HALF_YEAR,
        ...DROPDOWN_DATE_RANGE_OPTION_YEAR,
      },
      defaultOptionValue: DROPDOWN_DATE_RANGE_OPTIONS_DAY.DAY.value,
      resetWhenChangeOption: true,
      name: 'createdTime',
      isRangeLimited: true,
      allRequired: true,
      limitValueRange: 60,
      startMinLimitValue: getAccidentCarSettingByEnvironment('startMinLimitValue'),
      startLimitValue: moment(),
      endLimitValue: moment(),
      endMinLimitValue: getAccidentCarSettingByEnvironment('endMinLimitValue'),
      endMaxLimitValue: moment(),
      defaultBeginDate: moment().format(DATE_FORMAT),
      defaultEndDate: moment().format(DATE_FORMAT),
      itemWidth: 430,
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleBusinessType',
      label: '车辆类型',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
      className: 'vehicle-business-type',
      visible: (currentUserService) => !currentUserService.isRoleCodeIn([USER_ROLE.VAN_OEM_ACCIDENT_LEADS.value,
        USER_ROLE.REGION_ACCIDENT_LEADS.value]),
    },
  ],
};

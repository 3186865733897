import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BasicEditorComponent } from '../basic-editor.component';
import { ICellEditorParams } from 'ag-grid';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxInnerCellComponent extends BasicEditorComponent {
  label: string;
  shouldShowCheckbox: boolean;
  shouldShowLabel: boolean;
  params;

  agInit(params: ICellEditorParams): void {
    super.agInit(params);
    this.params = params;
    this.label = this.getCheckboxLabel();
    this.shouldShowCheckbox = this.getShouldShowCheckbox();
    this.shouldShowLabel = this.getShouldShowLabel();
    _.set(this.params.node.data, `${this.colId}Original`, this.params.node.data[this.colId]);
  }

  refresh(params: any): boolean {
    return false;
  }

  get checked() {
    return this.params.node.data[this.colId];
  }

  toggle(event) {
    _.set(this.params.node.data, this.colId, event.target.checked);
  }

  private get selectedRows() {
    return this.params.node.selectionController.selectedNodes;
  }

  getShouldShowCheckbox() {
    return _.get(this.cellEditorParams, 'shouldShowCheckbox', true);
  }

  getShouldShowLabel() {
    return _.get(this.cellEditorParams, 'shouldShowLabel', false);
  }

  getCheckboxLabel() {
    return _.get(this.cellEditorParams, 'checkboxLabel', '');
  }
}

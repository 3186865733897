import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
})
export class CustomCheckboxRenderComponent {
  @Output() onChange: EventEmitter<void> = new EventEmitter<void>();
  @Input() checked = false;

  handleChange(event): void {
    this.onChange.emit(event);
  }
}

import { Injectable } from '@angular/core';
import { Body, GET, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DutyApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/duty-schedule')
  getAllDutySchedule(@Query('date') date: number): Observable<any> {
    return null;
  }

  @PUT('/api/duty-schedule')
  updateDuty(@Query('user_id') userId: number, @Query('date') date: number, @Body body: UpdateDutyBody): Observable<any> {
    return null;
  }

}

export interface UpdateDutyBody {
  status: string;
}

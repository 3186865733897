import { Injectable } from '@angular/core';
import { Body, DELETE, GET, Headers, PATCH, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InsuranceCustomer, InsuranceCustomerPageResponse } from '../insurance-saas';
import {
  AssignFollowUpUsersRequest,
  FollowUpUser, HistoryOrderListResponse,
  InsuranceCompanyDTO,
  InsuranceCustomerContactInfoDTO,
  InsuranceCustomerDetailPlateSprayDTO,
  InsuranceCustomerDTO,
  NewCarCustomerListRequest,
  NewCarCustomerListResponse,
  QueryDealerCustomersRequestDTO,
} from '../model/insuranceModel';
import { PageResponse } from '../../_common/models/page';
import { FileUploadType } from '../constant/insurance';

export interface RecordsQuery {
  startTime: string;
  endTime: string;
  operationUsers: string[];
  statuses: string[];
  type: string;
}

export interface DownloadFileParams {
  importRecordType: string;
  importFileResultType: string;
  fileName: string;
  id: number;
}

const BASIC_URL = '/api/insurance-sales';
const IMPORT_URL = `${BASIC_URL}/import`;

@Injectable({
  providedIn: 'root',
})
export class InsuranceApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @POST(`${IMPORT_URL}/records`)
  getInsuranceRecords(@Query('page') page, @Query('size') size, @Body queryParams: RecordsQuery): Observable<any> {
    return null;
  }

  @GET('/api/insurance-integration/users')
  getOperationUsers(@Query('roles') roles: string[], @Query('dealerId') dealerId?: string): Observable<any> {
    return null;
  }

  @GET(`${IMPORT_URL}/template/download`)
  @Headers({ isExportFile: true })
  getImportRecordsDownloadTemplate(@Query('templateType') templateType: string): Observable<ArrayBuffer> {
    return null;
  }

  @POST(`${IMPORT_URL}/download`)
  @Headers({ isExportFile: true })
  downloadImportRecordFile(@Body params: DownloadFileParams): Observable<ArrayBuffer> {
    return null;
  }

  @GET(`${BASIC_URL}/customers/query`)
  getInsuranceCustomers(
    @Query('page') page: number,
    @Query('size') size: number,
    @Query('sort') sort: string,
    @Query() queryDealerCustomersRequestDTO: QueryDealerCustomersRequestDTO,
  ): Observable<InsuranceCustomerPageResponse<InsuranceCustomer>> {
    return null;
  }

  @GET(`${IMPORT_URL}/sales-company`)
  getInsuranceCompany(): Observable<Array<InsuranceCompanyDTO>> {
    return null;
  }

  @POST(`${BASIC_URL}/customers-and-record-confirmation`)
  createCustomerAndRecordConfirmation(@Body insuranceCustomerDTO): Observable<any> {
    return null;
  }

  @GET(`${BASIC_URL}/dealers/vehicles/:vin`)
  findCustomerByVin(@Path('vin') vin: string): Observable<InsuranceCustomerDTO> {
    return null;
  }

  @GET(`${BASIC_URL}/customers/:customerId/sold-mb`)
  getPlateSpray(@Path('customerId') customerId: string): Observable<InsuranceCustomerDetailPlateSprayDTO> {
    return null;
  }

  @GET(`${BASIC_URL}/customers/:customerId/basic`)
  getCustomerBasicInfo(@Path('customerId') customerId: string): Observable<any> {
    return null;
  }

  @GET(`${BASIC_URL}/customers/:customerId/sales-insurance-support`)
  getSalesInsuranceSupports(@Path('customerId') customerId: string): Observable<Array<any>> {
    return null;
  }

  @GET(`${BASIC_URL}/customers/:customerId/contact`)
  getCustomerContactInfo(@Path('customerId') customerId: string): Observable<InsuranceCustomerContactInfoDTO[]> {
    return null;
  }

  @GET(`${BASIC_URL}/insurance-policy-records/:customerId`)
  getHistoryOrderList(
    @Query('page') page: number,
    @Query('size') size: number,
    @Path('customerId') customerId: string): Observable<HistoryOrderListResponse> {
    return null;
  }

  @GET(`${BASIC_URL}/customers/:customerId/vehicle-underwriting`)
  getVehicleUnderwriting(@Path('customerId') customerId: string): Observable<any> {
    return null;
  }

  @GET(`${BASIC_URL}/insurance-policy-records/:id/insurance-policy-record`)
  getPolicyDetail(@Path('id') policyId: string): Observable<any> {
    return null;
  }

  @GET(`${BASIC_URL}/customers/:customerId/vehicle`)
  getVehicleInfo(@Path('customerId') customerId: string): Observable<any> {
    return null;
  }

  @PATCH(`${BASIC_URL}/customers/:customerId/vehicle`)
  updateVehicleInfo(@Path('customerId') customerId: string, @Body vehicleInfo: any): Observable<any> {
    return null;
  }

  @PATCH(`${BASIC_URL}/customers/:customerId/contact`)
  updateCustomerContactInfo(@Path('customerId') customerId: string, @Body customerContactInfo: any): Observable<any> {
    return null;
  }

  @PATCH(`${BASIC_URL}/customers/:customerId/basic`)
  updateCustomerBasicInfo(@Path('customerId') customerId: string, @Body data): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/customers/arrived-customer`)
  getIncomingCustomerList(@Query('page') page, @Query('size') size, @Body queryParams: any): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/export-records`)
  getExportRecords(@Query('page') page, @Query('size') size, @Body queryParams: any): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/export-records/download`)
  @Headers({ isExportFile: true })
  downloadExportRecordsFile(@Body exportedFileDownloadRequestDTO: any): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/customers/:customerId/contacts`)
  updateCustomerContacts(@Path('customerId') customerId: string, @Body customerContacts: any): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/customers/manual-refresh`)
  refreshCustomerList(@Body vins: string[]): Observable<any> {
    return null;
  }

  @GET(`${BASIC_URL}/customers/customize-grid-header`)
  getCustomizeGridHeaders(): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/customers/customize-grid-header`)
  updateCustomizeGridHeaders(@Body headers: any): Observable<any> {
    return null;
  }

  @GET(`${BASIC_URL}/customers/export`)
  exportCustomers(@Query() queryParams: any): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/customers/quotation/confirmation`)
  saveQuotationConfirmation(@Body confirmationRequest: any): Observable<any> {
    return null;
  }

  @GET(`${BASIC_URL}/customers/confirmed-notification`)
  getInformedWording(): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/new-car-customer/pageable`)
  getNewCarCustomerList(
    @Query('page') page: number,
    @Query('size') size: number,
    @Body params: NewCarCustomerListRequest,
  ): Observable<PageResponse<NewCarCustomerListResponse>> {
    return null;
  }

  @POST(`${BASIC_URL}/customer/follow-up`)
  saveFollowUp(@Body followUpRequest): Observable<void> {
    return null;
  }

  @GET(`${BASIC_URL}/customer/follow-up/:customerId`)
  searchCustomerFollowUpWithInThreeMonths(@Path('customerId') customerId: string, @Query('monthsRange') monthsRange): Observable<void> {
    return null;
  }

  @GET(`${BASIC_URL}/customer/follow-up-users`)
  getFollowUpUsers(): Observable<FollowUpUser[]> {
    return null;
  }

  @POST(`${BASIC_URL}/customer/follow-up/pageable/:customerId`)
  getFollowUps(@Path('customerId') customerId: string, @Query('page') page: number, @Body requestBody = {}): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/customer/follow-up-users/assign`)
  assignFollowUpUsers(@Body params: AssignFollowUpUsersRequest): Observable<boolean> {
    return null;
  }

  @POST(`${BASIC_URL}/insurance-policy-records`)
  @Headers({ 'No-Content-Type': 1 })
  saveInsurancePolicyRecords(@Body policy: FormData): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/insurance-policy-records/update`)
  @Headers({ 'No-Content-Type': 1 })
  updateInsurancePolicyRecords(@Body policy: FormData): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/insurance-policy-records/pageable`)
  getInsurancePolicyRecords(@Query('page') page, @Query('size') size, @Body queryParams: any): Observable<any> {
    return null;
  }

  @POST(`${BASIC_URL}/insurance-policy-records/validate`)
  validateInsurancePolicyRecords(
    @Query('needDoubleCheck') needDoubleCheck,
    @Body params: any,
    @Query('id') id?
  ): Observable<any> {
    return null;
  }

  @DELETE(`${BASIC_URL}/customers`)
  deleteCustomer(@Body vins: string[]) {
    return null;
  }

  @GET(`${BASIC_URL}/insurance-policy-records/e-insurance-policy/download`)
  @Headers({ isExportFile: true })
  downloadFilesByFileUploadType(@Query('fileUploadType') fileUploadType: FileUploadType,
                                @Query('fileName') fileName: string): Observable<ArrayBuffer> {
    return null;
  }

  @POST(`${BASIC_URL}/insurance-policy-records/export`)
  exportInsurancePolicyRecords(@Body params: any): Observable<void> {
    return null;
  }

  @GET('/api/reports/insurance/follow-up-monthly-report')
  getMonthlyReport(@Query() queryParams): Observable<void> {
    return null;
  }

  @GET('/api/reports/insurance/follow-up-daily-report')
  getDailyReport(@Query() queryParams): Observable<void> {
    return null;
  }

  @POST('/api/reports/insurance/download')
  @Headers({ isExportFile: true })
  downloadExportFollowUpReport(@Body exportRequest): Observable<ArrayBuffer> {
    return null;
  }

  @GET('/api/insurance-sales/annual-statistic-indicator')
  getAnnualStatisticIndicator(
    @Query('year') year,
    @Query('type') type,
    @Query('gsCode') gsCode,
    @Query('dealerId') dealerId?,
  ): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/insurance/outlets')
  getDealerName(): Observable<any> {
    return null;
  }

  @GET('/api/insurance-accident/accessory-discount-result/latest')
  getDealerGroup(
    @Query('year') year,
  ): Observable<any> {
    return null;
  }

  @GET('/api/3rd-party/insurance-accident/accessory-discount-result/latest')
  getDealerGroupFromThirdParty(
    @Query('dealerId') dealerId,
    @Query('year') year,
  ): Observable<any> {
    return null;
  }

  @GET('/api/reports/insurance/next-year-renewal-support-achievement-report')
  getFollowingYearRenewalReport(@Query('year') year): Observable<any> {
    return null;
  }

  @GET('/api/reports/insurance/third-party/next-year-renewal-support-achievement-report')
  getFollowingYearRenewalReportThirdParty(@Query('year') year): Observable<any> {
    return null;
  }

  @GET('/api/reports/insurance/next-year-renewal-support-achievement-report')
  @Headers({ isExportFile: true })
  downloadFollowingYearRenewalReport(@Query('year') year, @Query('export') shouldExport: boolean): Observable<ArrayBuffer> {
    return null;
  }

  @GET('/api/reports/insurance/third-party/next-year-renewal-support-achievement-report')
  @Headers({ isExportFile: true })
  downloadFollowingYearRenewalReportThirdParty(@Query('year') year, @Query('export') shouldExport: boolean): Observable<ArrayBuffer> {
    return null;
  }
}

import { map } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { CHINESE_CALENDAR_CONFIG } from '../../../../_common/constants/calendar';
import { dateParse } from '../../../../_common/utils/date-parse';
import { ServerTimeService } from '../../../../_common/services/server-time.service';
import {
  appointmentDateAndTimeValidator,
  getEightToTwenty,
  getNextDay,
  getSelectedTimestamp,
  getTenAtToday,
  scGroupAndScUserIdValidator,
} from './helper';
import { SalesLeadModalContent } from '../sales-lead-modal/sales-lead-modal.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { LeadService } from '../services/lead.service';
import { FormUtilService } from '../../../../_common/services/form-util.service';
import { isOpenLead } from 'app/_common/utils/lead';
import { SpinnerService } from '@otr/website-common';

const getDefaultDate = getNextDay;

const getDefaultTime = getTenAtToday;

@Component({
  selector: 'app-assign-sc-modal',
  styleUrls: ['./assign-sc-modal.style.scss'],
  templateUrl: './assign-sc-modal.template.html',
})
export class AssignScModalComponent implements SalesLeadModalContent, OnInit {
  @Input() leadIds = [];
  @Input() customerId = null;
  @Input() leadStatus = null;
  @Input() isAssignSC = false;
  @Input() isBatchAssign = false;
  @Input() isFollowUp = false;
  @Input() scGroupInfos: Array<any> = [];
  @Input() enableSelectSC = true;
  @Input() ownerSalesConsultantId: string = null;

  scFormControl: FormGroup = new FormGroup({
    scGroup: new FormControl(null),
    scUserId: new FormControl(null),
  }, (control: FormGroup) => scGroupAndScUserIdValidator(control, this.isFollowUp));

  scGroupOptions$: BehaviorSubject<any> = new BehaviorSubject([]);
  scOptions$: BehaviorSubject<any> = new BehaviorSubject([]);

  appointmentDateAndTime: FormGroup = new FormGroup({
    appointmentDate: new FormControl(getDefaultDate()),
    appointmentTime: new FormControl(getDefaultTime()),
  }, (control: FormGroup) => appointmentDateAndTimeValidator(control, this.isAssignSC));

  assignScFormGroup: FormGroup = new FormGroup({
    scUser: this.scFormControl,
    appointmentDateAndTime: this.appointmentDateAndTime,
    comments: new FormControl(null),
  });

  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;
  invalidStyle = { 'border-color': '#F00' };
  minDate: Date = this.serverTimeService.currentDate;
  isInvalid$: Subject<any> = new BehaviorSubject(true);
  isExistingDefaultOwner: boolean = false;

  constructor(private serverTimeService: ServerTimeService,
              private leadService: LeadService,
              private spinnerService: SpinnerService,
              private formUtilService: FormUtilService) {
  }

  ngOnInit(): void {
    this.scGroupOptions$.next(_.map(this.scGroupInfos, 'scGroup'));
    this.updateForFollowUp();
    this.subscribeFormValueChange();
    this.setDefaultOwnerSalesConsultant();
    this.setBatchAssignScAppointmentDateAndTime();
  }

  updateForFollowUp() {
    if (this.isFollowUp) {
      this.scGroupOptions$.next([{ id: null, name: '请选择销售小组' }, ...this.scGroupOptions$.getValue()]);
      this.isInvalid$ = new BehaviorSubject(false);
      this.scFormControl.setValidators((control: FormGroup) =>
        scGroupAndScUserIdValidator(control, this.isFollowUp));
      this.scFormControl.updateValueAndValidity();
    }
  }

  subscribeFormValueChange() {
    this.assignScFormGroup
      .valueChanges
      .subscribe(() => this.isInvalid$.next(this.assignScFormGroup.invalid));
    this.formUtilService.markAsTouched$.subscribe(value => value ? this.markControlsAsTouched() : this.markControlsAsUnTouched());
  }

  getScValue = sc => _.get(sc, 'user_id');

  getScGroupValue = scGroup => _.get(scGroup, 'id');

  getTimeText = timeOption => timeOption.text;

  getTimeValue = timeOption => timeOption.value;

  getScGroupDisplayText = scGroup => _.get(scGroup, 'name');

  getScOptionDisplayText = sc => _.get(sc, 'sales_division') ? `${sc.full_name}（${sc.sales_division}）` : sc.full_name;

  getSelectedScGroup() {
    const scGroupId = _.get(this.scFormControl.value, 'scGroup');
    const scGroup = _.find(this.scGroupInfos, item => item.scGroup.id === scGroupId);
    return scGroupId ? {
      value: scGroupId,
      text: scGroup.scGroup.name,
    } : null;
  }

  getSelectSCError() {
    const selectScControl = this.assignScFormGroup.controls.scUser;
    return selectScControl.invalid && selectScControl.touched;
  }

  getSelectedSc() {
    const userId = _.get(this.scFormControl.value, 'scUserId');
    const scGroupId = _.get(this.scFormControl.value, 'scGroup');
    const sc = userId && scGroupId ? _.find(this.scOptions$.value, item => item.user_id === userId) : null;
    return userId && scGroupId ? {
      value: userId,
      text: this.getScOptionDisplayText(sc),
    } : null;
  }

  getSelectedTime() {
    const selectedTime = _.get(this.appointmentDateAndTime.value, 'appointmentTime');
    return selectedTime ? {
      value: selectedTime,
      text: dateParse(selectedTime, 'HH:mm'),
    } : null;
  }

  getTimeOptions() {
    const dates = getEightToTwenty();
    return this.isAssignSC ? [{ text: '请选择', value: null }, ...dates] : dates;
  }

  getFormValue() {
    const { scUser, comments, appointmentDateAndTime } = this.assignScFormGroup.value;
    const scUserId = _.get(scUser, 'scUserId');
    const scGroupId = _.get(scUser, 'scGroup');
    return {
      scGroupId,
      scUserId,
      comments,
      appointmentTime: getSelectedTimestamp(appointmentDateAndTime),
      leadIds: this.leadIds,
      isBatchAssign: this.isBatchAssign,
      isOnlyAppointment: !this.enableSelectSC || _.isNil(scUserId),
    };
  }

  setDefaultOwnerSalesConsultant() {
    if (!this.leadStatus || !this.customerId) {
      return;
    }
    if (this.ownerSalesConsultantId) {
      return this.setConsultantValueByGemsUserId(this.ownerSalesConsultantId);
    }
    if (isOpenLead({ status: this.leadStatus })) {
      return this.leadService.getCustomerServedScs(this.customerId).pipe(
        (this.spinnerService.loading()),
        map(users => _.get(users, '[0].gems_user_id')))
        .subscribe(consultantGemsUserId => this.setConsultantValueByGemsUserId(consultantGemsUserId));
    }
  }

  setConsultantValueByGemsUserId(consultantGemsUserId) {
    const [ownerConsultantUserId, scGroupId] = this.findLeadOwner(consultantGemsUserId);
    this.isExistingDefaultOwner = true;
    this.scFormControl.patchValue({ scGroup: scGroupId, scUserId: ownerConsultantUserId });
    this.setScOptionsByGroupId();
  }

  setBatchAssignScAppointmentDateAndTime() {
    if (this.isAssignSC) {
      this.appointmentDateAndTime.setValue({
        appointmentDate: null,
        appointmentTime: null,
      });
      this.appointmentDateAndTime.setValidators((control: FormGroup) =>
        appointmentDateAndTimeValidator(control, this.isAssignSC));
    }
  }

  onScGroupSelect(salesTeam) {
    this.scFormControl.setValue({ scGroup: salesTeam, scUserId: null });
    this.setScOptionsByGroupId();
  }

  setScOptionsByGroupId() {
    const scGroup = _.find(this.scGroupInfos, item => item.scGroup.id === _.get(this.scFormControl.value, 'scGroup'));
    this.scOptions$.next(_.get(scGroup, 'scs'));
  }

  onScSelect(sc) {
    this.scFormControl.setValue({ scUserId: sc, scGroup: _.get(this.scFormControl.value, 'scGroup') });
  }

  findLeadOwner(ownerSalesConsultantId) {
    const scs = _.flattenDeep(_.map(this.scGroupInfos, 'scs'));
    const userId = _.get(_.find(scs, { gems_user_id: ownerSalesConsultantId }), 'user_id');
    const scGroup = _.find(this.scGroupInfos, item => _.includes(_.map(item.scs, 'user_id'), userId));
    const groupId = _.get(scGroup, 'scGroup.id');
    return [userId, groupId];
  }

  markControlsAsTouched() {
    this.assignScFormGroup.controls.scUser.markAsTouched();
  }

  markControlsAsUnTouched() {
    this.assignScFormGroup.controls.scUser.markAsUntouched();
  }
}

import {
  Component, Input, Output, EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-radio-dropdown',
  styleUrls: ['./radio-dropdown.style.scss'],
  templateUrl: './radio-dropdown.template.html',
})
export class RadioDropdownComponent {
  selectInput: FormControl = new FormControl('');
  @Input() applyText: string;
  @Input() options: Array<any>;
  @Output() onSubmit = new EventEmitter();
  @Output() onClick = new EventEmitter();

  onItemClick(event) {
    this.onClick.emit(event);
  }

  apply() {
    if (_.isEmpty(this.selectInput.value)) {
      return;
    }
    this.onSubmit.emit(this.selectInput.value);
  }

  resetSelectedValue() {
    this.selectInput.setValue('');
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-tab',
  template: `
    <div class="tab">
      <ul>
        <ng-container *ngFor="let tab of tabs; let i = index" >
          <li *ngIf="!tab.isHide" [ngClass]="{ 'active-tab': tab.active }" (click)="changeTab(i)">
            {{ tab.name }}
          </li>
        </ng-container>
      </ul>
    </div>
  `,
  styleUrls: ['./tab.style.scss'],
})

export class TabComponent implements OnInit {
  @Input() tabs: {name: string, type: string, active: boolean, isHide: boolean}[];

  @Output() onTabChange = new EventEmitter();

  ngOnInit() {}

  changeTab(index: number) {
    this.onTabChange.emit(index);
  }
}

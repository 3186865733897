import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CHINESE_CALENDAR_CONFIG } from '../../constants/calendar';
import { AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit, OnDestroy {
  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;

  @Input() dateControl: AbstractControl;
  @Input() maxLimitDate?: Date;
  @Input() minLimitDate?: Date;
  @Input() disabled?: boolean;
  @Input() yearNavigator?: boolean = false;
  @Input() yearRange?: string;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur?: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('date') dateCalendar;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnInit() {
    this.initSubscriber();
  }

  initSubscriber() {
    this.dateControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.dateControl.updateValueAndValidity({ emitEvent: false });
      });
  }

  onDateBlur(event) {
    if (_.isEmpty(event.target.value) && (this.dateCalendar._isValid === false)) {
      this.dateCalendar._isValid = true;
      this.dateControl.setValue(null);
    }

    if (this.onBlur && moment(event.target.value, 'YYYY-MM-DD', true).isValid()) {
      this.onBlur.emit(event.target.value);
    }
  }

  onDateSelect(event) {
    this.onSelect.emit(event);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

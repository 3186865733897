import { Component } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

@Component({
  templateUrl: './nz-date-calendar.html',
  styleUrls: ['./nz-date-calendar.scss'],
})
export class NzDateCalendarComponent implements AgRendererComponent {
  public date: any;
  params;
  disabled: boolean = false;
  disabledDate = (endDate: Date): boolean => {
    return moment(endDate) > moment(new Date());
  };
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.date = this.params.value;
    this.disabled = this.cellRendererParams.disabled;
  }
  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }
  onChange(event) {
    _.set(this.params, 'value', moment(event).format('YYYY-MM-DD'));
    this.cellRendererParams.updateDealerActualArrivedDate(this.params);
  }
  refresh(params: any): boolean {
    return false;
  }
}

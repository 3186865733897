import { from as observableFrom, of as observableOf, Subject } from 'rxjs';

import { mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BranchConfigApi } from '../api/branch-config';

import * as _ from 'lodash';
import { BranchConfig, BranchLocationDescription } from '../models/branch';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VehicleApi } from '../api/vehicle';
import { CurrentUserService } from '@otr/website-common';

@Injectable()
export class BranchConfigService {
  branchConfigs: BranchConfig[];
  vehicleLocationsSubject = new Subject();
  vehicleLocations = [];
  vehicleLocationDescriptionMaxLength = 6;
  locationDescriptionsCache = [];
  private shouldUseLocationDescriptionCache = false;
  private form: FormGroup;

  constructor(private currentUserService: CurrentUserService, private branchConfigApi: BranchConfigApi, private vehicleApi: VehicleApi) {
  }

  getBranchConfigDataList() {
    return this.branchConfigApi
      .getBranchConfig(this.currentUserService.getCurrentUser().dealerId).pipe(
      tap(this.handleBranchConfigResponse.bind(this)));
  }

  getLocationDescriptionsIntelligently(): BranchLocationDescription[] {
    if (this.shouldUseLocationDescriptionCache && !_.isEmpty(this.locationDescriptionsCache)) {
      return this.locationDescriptionsCache;
    }
    this.shouldUseLocationDescriptionCache = true;
    this.locationDescriptionsCache = _.compact(this.branchConfigs.map(config => {
      if (!_.isEmpty(config.vehicle_location)) {
        return {
          vehicleLocation: config.vehicle_location,
          description: config.description,
        };
      }
      return null;
    }));
    return this.locationDescriptionsCache;
  }

  getLocationDescriptions() {
    return observableFrom(this.getBranchConfigDataList()).pipe(
      mergeMap(() => observableOf(this.getLocationDescriptionsIntelligently())));
  }

  getDisplayLocationDescriptions(location) {
    const locationDescriptions = this.getLocationDescriptionsIntelligently();
    const displayLocationDescriptions = _.filter<BranchLocationDescription>(locationDescriptions, locationDescription => {
      return !_.isEmpty(_.trim(locationDescription.vehicleLocation)) && locationDescription.vehicleLocation !== location;
    });
    displayLocationDescriptions.unshift(_.find<BranchLocationDescription>(locationDescriptions, { vehicleLocation: location }));
    return displayLocationDescriptions;
  }

  updateBranchConfig(data) {
    return this.branchConfigApi.updateBranchConfig(data);
  }

  private handleBranchConfigResponse(response) {
    this.branchConfigs = response;
    this.shouldUseLocationDescriptionCache = false;
    this.vehicleLocations = this.getAllVehicleLocationByConfig(response);
    this.vehicleLocationsSubject.next(this.vehicleLocations);
  }

  generateForm(branchConfigs: BranchConfig[]) {
    const formGroup = {};
    _.range(branchConfigs.length).forEach(index => {
      const config = branchConfigs[index];
      formGroup[config.id] = new FormControl(config.vehicle_location,
        Validators.compose([this.uniqueLocation.bind(this)]));
    });
    this.form = new FormGroup(formGroup);
    this.initForm();
    return this.form;
  }

  initForm() {
    const initialForm = this.branchConfigs.reduce((previous, config) => {
      previous[config.id] = config.vehicle_location;
      return previous;
    }, {});
    this.form.patchValue(initialForm);
  }

  uniqueLocation(control: FormControl) {
    const length = (_.filter(this.branchConfigs, { vehicle_location: control.value })).length;
    return length >= 2 ? { hasDuplicateLocation: true } : null;
  }

  fetchLocationDescriptions() {
    return this.branchConfigApi.getLocationDescriptions(this.currentUserService.getCurrentUser().dealerId);
  }

  updateLocationDescriptions(data) {
    return this.vehicleApi.updateLocationDescriptions(data);
  }

  private getAllVehicleLocationByConfig(configs) {
    return _.chain(configs).flatMap('vehicle_location').compact().value();
  }
}

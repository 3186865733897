import { Injectable } from '@angular/core';
import { GET, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AsVehicleApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/website/vehicles')
  searchVehicleInfoByVinOrFin(@Query('vinOrFin') vinOrFin: string): Observable<any> {
    return null;
  }

  @GET('/api/as-vehicle/circuit-breaker')
  getServiceCircuitBreaker(): Observable<any> {
    return null;
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalPosition, PositionedModal } from '../../../_common/components/modal/services/position';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { TimePickerModel } from '../../../_common/components/time-picker/time-picker.component';
import * as _ from 'lodash';
import { markAllFieldsAsTouched } from '../../../_common/utils/form';

export interface FinishTestDriveOption {
  minTime?: TimePickerModel;
  maxTime?: TimePickerModel;
  initialTime?: TimePickerModel;
}

@Component({
  styleUrls: ['finish-test-drive.style.scss'],
  templateUrl: 'finish-test-drive.component.html',
})
export class FinishTestDriveComponent implements PositionedModal, OnInit {
  left: number = 0;
  top: number = 0;
  minTime: TimePickerModel = { hour: 0, minute: 0 };
  maxTime: TimePickerModel = { hour: 23, minute: 59 };
  initialTime: TimePickerModel;

  finishTimeFormGroup: FormGroup = new FormGroup({
    hour: new FormControl('00'),
    minute: new FormControl('00'),
  }, this.hourMinuteValidator.bind(this));

  testDriveFormGroup: FormGroup = new FormGroup({
    mileage: new FormControl(0, Validators.required),
    comment: new FormControl(''),
  });

  @ViewChild('finishTestDriveModal', { read: ElementRef }) finishTestDriveModal: ElementRef;
  private subject: Subject<any>;

  constructor() {
    this.subject = new Subject();
  }

  hourMinuteValidator(finishTestDriveForm: FormGroup) {
    const hourControl = finishTestDriveForm.controls['hour'];
    const minuteControl = finishTestDriveForm.controls['minute'];
    if (hourControl && minuteControl && this.beforeMinTime(hourControl, minuteControl)) {
      return { beforeMinTime: true };
    }
    if (hourControl && minuteControl && this.afterMaxTime(hourControl, minuteControl)) {
      return { afterMaxTime: true };
    }
    return null;
  }

  // Override
  getClientHeight(): number {
    const finishTestDriveModal = <HTMLElement> this.finishTestDriveModal.nativeElement;
    const { height } = finishTestDriveModal.getBoundingClientRect();
    return height;
  }

  getClientWidth(): number {
    const finishTestDriveModal = <HTMLElement> this.finishTestDriveModal.nativeElement;
    const { width } = finishTestDriveModal.getBoundingClientRect();
    return width;
  }

  setProperties(options: FinishTestDriveOption): void {
    if (options.initialTime) {
      this.initialTime = options.initialTime;
    }
    if (options.minTime) {
      this.minTime = options.minTime;
    }
    if (options.maxTime) {
      this.maxTime = options.maxTime;
    }
  }

  setPosition(position: ModalPosition): void {
    this.left = position.left;
    this.top = position.top;
  }

  getSubject(): Subject<any> {
    return this.subject;
  }

  save() {
    markAllFieldsAsTouched(this.finishTimeFormGroup);
    markAllFieldsAsTouched(this.testDriveFormGroup);
    if (this.finishTimeFormGroup.valid && this.testDriveFormGroup.valid) {
      this.subject.next({
        endTime: this.buildEndTime(),
        mileage: this.testDriveFormGroup.controls['mileage'].value,
        comment: this.testDriveFormGroup.controls['comment'].value,
      });
    }
  }

  cancel() {
    this.subject.next(null);
  }

  isFinishTimeInvalid(): boolean {
    return this.finishTimeFormGroup.touched && this.finishTimeFormGroup.invalid;
  }

  isMileageInvalid(): boolean {
    const mileageControl = this.testDriveFormGroup.controls['mileage'];
    return (mileageControl.touched && mileageControl.invalid);
  }

  getErrorMessage(): string {
    if (this.finishTimeFormGroup.hasError('beforeMinTime')) {
      return '不能早于开始时间';
    }
    if (this.finishTimeFormGroup.hasError('afterMaxTime')) {
      return '不能晚于当前时间';
    }
  }

  // Override
  ngOnInit(): void {
    const hourControl = this.finishTimeFormGroup.controls['hour'];
    const minuteControl = this.finishTimeFormGroup.controls['minute'];
    hourControl.setValue(`${this.initialTime.hour}`);
    minuteControl.setValue(`${this.initialTime.minute}`);
  }

  private buildEndTime() {
    const hour: number = _.toNumber(this.finishTimeFormGroup.controls['hour'].value);
    const minute: number = _.toNumber(this.finishTimeFormGroup.controls['minute'].value);
    return { hours: hour, minutes: minute };
  }

  private beforeMinTime(hourControl, minuteControl): boolean {
    const hour: number = _.toNumber(hourControl.value);
    const minute: number = _.toNumber(minuteControl.value);
    const totalMinutes: number = this.getTotalMinutes(hour, minute);
    const beforeMinTime: boolean = totalMinutes < this.getTotalMinutes(this.minTime.hour, this.minTime.minute);
    return _.isNaN(hour) || _.isNaN(minute) || hour < 0 || hour > 23 || minute < 0 || minute > 59 || beforeMinTime;
  }

  private afterMaxTime(hourControl, minuteControl): boolean {
    const hour: number = _.toNumber(hourControl.value);
    const minute: number = _.toNumber(minuteControl.value);
    const totalMinutes: number = this.getTotalMinutes(hour, minute);
    const afterMaxTime: boolean = totalMinutes > this.getTotalMinutes(this.maxTime.hour, this.maxTime.minute);
    return _.isNaN(hour) || _.isNaN(minute) || hour < 0 || hour > 23 || minute < 0 || minute > 59 || afterMaxTime;
  }

  private getTotalMinutes(hour: number, minute: number): number {
    return hour * 60 + minute;
  }
}

<div class="cell-container" (click)="clickMode()">
  <div *ngIf="!isEditMode" class="text-wrapper">
    <span class="display-text" [ngStyle]="displayTextStyle">{{displayText}}</span>
    <em class="fa antd-edit-filled" *ngIf="!editIcon"></em>
    <img *ngIf="editIcon" style="width: 16px;" [src]="editIcon" class="navigation-icon">
  </div>
  <select [ngModel]="value" *ngIf="isEditMode" autofocus (blur)="onBlur($event.target.value)" [disabled]="disabled">
    <option *ngFor="let option of options" [value]="option.value">{{option.text}}</option>
  </select>
</div>

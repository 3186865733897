import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';

interface ICellButton {
  buttonText: string;
  buttonClass: string;
  onClick: Function;
}

@Component({
  selector: 'app-customized-action-cell-editor',
  templateUrl: './customized-action-cell-editor.component.html',
  styleUrls: ['./customized-action-cell-editor.component.scss'],
})
export class CustomizedActionCellEditorComponent implements ICellEditorAngularComp {
  params: any;
  cellButtons: Array<ICellButton> = [];

  agInit(params: any): void {
    this.params = params;
    this.cellButtons = this.getCellButtons();
  }

  getValue(): any {
    return;
  }

  onEnterKeyDown(event) {
    event.stopPropagation();
  }

  getCellButtons(): Array<ICellButton> {
    const cellButtonsParam = _.get(this.params, 'column.colDef.cellEditorParams.cellButtons');
    const cellButtons = _.isFunction(cellButtonsParam) ? cellButtonsParam() : cellButtonsParam;
    return  _.map(cellButtons, button => ({
      ...button,
      onClick: () => button.onClick(this.params),
    }));
  }
}

export const DEPARTMENT = {
  SALES_DEPARTMENT: {
    text: '销售部门',
    value: 'SALES_DEPARTMENT',
  },
  CUSTOMER_DEVELOP_DEPARTMENT: {
    text: '客户发展(CDO)',
    value: 'CUSTOMER_DEVELOP_DEPARTMENT',
  },
  CUSTOMER_RELATIONSHIP_DEPARTMENT: {
    text: '客户关系(CRM)',
    value: 'CUSTOMER_RELATIONSHIP_DEPARTMENT',
  },
  MARKETING_DEPARTMENT: {
    text: '市场部门',
    value: 'MARKETING_DEPARTMENT',
  },
  OTHER_DEPARTMENT: {
    text: '其他部门',
    value: 'OTHER_DEPARTMENT',
  },
};

export const EDITABLE_DEPARTMENT = [
  DEPARTMENT.CUSTOMER_RELATIONSHIP_DEPARTMENT.value,
  DEPARTMENT.OTHER_DEPARTMENT.value,
];

import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { SalesLeadModalContent } from '../../../customer-lead-management/lead-management/sales-lead/sales-lead-modal/sales-lead-modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CHINESE_CALENDAR_CONFIG } from '../../../_common/constants/calendar';
import { getDateAtZero } from '../../../_common/utils/date';
import { BehaviorSubject, Subject } from 'rxjs';
import { isEmptyValue } from '../../../_common/utils/common';
import { KeyValue } from '../../../_common/models/common';
import { LEAD_TYPES } from '../../../customer-lead-management/lead-management/shared/constant/lead';
import { FormUtilService } from '../../../_common/services/form-util.service';

@Component({
  selector: 'app-cti-keep-follow-up',
  templateUrl: './keep-follow-up.component.html',
  styleUrls: ['./keep-follow-up.component.scss'],
})
export class CTIKeepFollowUpComponent implements SalesLeadModalContent, OnInit {
  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;
  nextFollowUpDate: FormControl = new FormControl(null, [
    Validators.required, control => (getDateAtZero(new Date(control.value)) < getDateAtZero(new Date()) ? { nextFollowUpDate: '' } : null),
  ]);

  changeStatus: Array<any> = [
    { value: true, text: '是' },
    { value: false, text: '否' },
  ];

  validLeadStatus: KeyValue[] = [
    { value: LEAD_TYPES.LEAD_CANDIDATE, text: '变更为线索' },
    { value: LEAD_TYPES.LOST_SALES, text: '激活为线索' },
  ];
  followUpComments = new FormControl(null, Validators.required);
  changeStatusControl: FormControl = new FormControl('notB');
  changeStatusControlMod: FormControl = new FormControl('notD');
  keepFollowUpForm: FormGroup = new FormGroup({
    followUpComments: this.followUpComments,
    nextFollowUpDate: this.nextFollowUpDate,
    isChangeToLead: this.changeStatusControl,
    isChangeToLeadMod: this.changeStatusControlMod,
  });

  isInvalid$: Subject<any> = new BehaviorSubject(true);

  minDate: Date = new Date();
  invalidStyle = { 'border-color': '#E84522' };

  @Input() leads = null;

  constructor(private formUtilService: FormUtilService) {}

  get leadLabel() {
    const lead = this.leads;
    const interestedVehicle = _.get(lead, 'interested_vehicles[0]');
    const brand = _.get(interestedVehicle, 'brand.description_zh');
    const iClass = _.get(interestedVehicle, 'class.description_zh');
    const variant = _.get(interestedVehicle, 'variant.description_zh');
    const desc = `${brand ? brand : ''}${iClass ? ` ${iClass}` : ''}${variant ? ` ${variant}` : ''}`;
    return desc || '暂无意向车';
  }

  ngOnInit(): void {
    this.keepFollowUpForm
      .valueChanges
      .subscribe(() => this.isInvalid$.next(this.keepFollowUpForm.invalid || isEmptyValue(this.nextFollowUpDate.value)));
    this.formUtilService.markAsTouched$.subscribe(value => value ? this.markControlsAsTouched() : this.markControlsAsUnTouched());
  }

  getFormValue() {
    const formValue = this.keepFollowUpForm.value;
    return {
      ...formValue,
      nextFollowUpDate: getDateAtZero(new Date(formValue.nextFollowUpDate)),
      isChangeToLead: this.shouldShowToBeLead(this.leads) ? _.get(formValue, 'isChangeToLead') === 'B' : null, // todo isChangeToLead
      isChangeToLeadMod: null,
    };
  }

  getLabelTitle(lead) {
    return _.find<any>(this.validLeadStatus, { value: _.get(lead, 'status', '').toLowerCase() }).text;
  }

  shouldShowToBeLead(lead) {
    return _.includes(_.map(this.validLeadStatus, 'value'), _.get(lead, 'status', '').toLowerCase());
  }

  markControlsAsTouched() {
    this.keepFollowUpForm.controls['followUpComments'].markAsTouched();
    this.keepFollowUpForm.controls['nextFollowUpDate'].markAsTouched();
  }

  markControlsAsUnTouched() {
    this.keepFollowUpForm.controls['followUpComments'].markAsUntouched();
    this.keepFollowUpForm.controls['nextFollowUpDate'].markAsUntouched();
  }
}

import { ICellRendererParams } from 'ag-grid';
import { Component, HostBinding } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICONS } from '../../../../constants/icons';
import * as _ from 'lodash';

export const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  template: `
    <button class="btn icon-text-cell-container" [class.icon-right]="iconRight" [class.icon-disable]="disabled" [disabled]="disabled" [ngStyle]="width"
            (click)="onClick($event)">
      <img *ngIf="iconUrl" style="width: 22px;" [src]="iconUrl" class="navigation-icon">
      <i [ngClass]="classNames"></i>
      <span [ngStyle]="spanStyle"
            class="display-text"
            [isDisabled]="!showToolTip"
            [tooltip]="text"
            placement="right"
      >{{ text }}</span>
    </button>
  `,
  styleUrls: ['./icon.style.scss'],
})
export class IconTextComponent implements ICellRendererAngularComp {
  classNames: any;
  text: string;
  spanStyle: any;
  iconRight: boolean;
  btnWidth: any;
  width: any;
  showToolTip: boolean;
  disabled: boolean;
  hidden: boolean;
  iconUrl: any;

  @HostBinding('class') hostClassName: string;

  private params: ICellRendererParams;
  cellRendererParams: any;

  get title() {
    return this.showToolTip ? this.text : '';
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellRendererParams = params.colDef.cellRendererParams;
    this.iconUrl = getValue(this.cellRendererParams.iconUrl, params);
    const iconType = getValue(this.cellRendererParams.iconType, params);
    this.hostClassName = getValue(this.cellRendererParams.hostClassName, params);
    const iconClass = ICONS[iconType];
    this.classNames = { fa: true, [iconClass]: true, ...getValue(this.cellRendererParams.classNames, params) };
    this.text = getValue(this.cellRendererParams.text, params);
    this.disabled = getValue(this.cellRendererParams.disabled, params);
    this.iconRight = getValue(this.cellRendererParams.iconRight, this.params);
    this.showToolTip = getValue(this.cellRendererParams.showToolTip, this.params);
    if (this.iconRight) {
      this.spanStyle = { width: `${params.colDef.width}px` };
    }
    this.btnWidth = getValue(this.cellRendererParams.btnWidth, this.params);
    if (this.btnWidth) {
      this.width = { width: `${this.btnWidth}px` };
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onClick(event) {
    (this.cellRendererParams.onClick || _.noop)(this.params.data, event);
  }
}

<button [ngClass]="{'delete-button': true, 'delete-button-disable': isDisable}"
        [disabled]="isDisable"
        (click)="onStartDeleting()"
        *ngIf="!isDeleting">
  <i class="fa antd-minus-circle-filled" aria-hidden="true"></i>
</button>
<div *ngIf="isDeleting">
  <button class="btn-cancel" (click)="onCancel()">取消</button>
  <button class="btn-delete" (click)="onDelete()">删除</button>
</div>

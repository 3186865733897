import { Injectable } from '@angular/core';
import { VanBrandOrderApi } from '../apis/van-brand-order';

@Injectable()
export class VanBrandOrderService {

  constructor(private vanBrandOrderApi: VanBrandOrderApi) {
  }

  getVanDmsStatus(orderId: string) {
    return this.vanBrandOrderApi.getVanDmsStatus(orderId);
  }

  updateVanDmsStatus(orderId: string) {
    return this.vanBrandOrderApi.updateVanDmsStatus(orderId);
  }
}

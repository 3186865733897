import * as moment from 'moment';
import * as _ from 'lodash';
import { formatAmount } from '../../_common/utils/format-string';
import { InsuranceCustomerContactInfoDTO } from '../model/insuranceModel';
import { ExportInsuranceStatuses, InsuranceIssueType, insuranceTypeEnum } from '../constant/insurance';
import { DATE_FORMAT } from '../../_common/constants/common';
import { KeyValue } from '../../_common/models/common';

export const VALIDATE_WIDTH = {
  contactName: 120,
  mobile: 50,
  idCardIdentityNumber: 18,
  otherIdentityNumber: 50,
  address: 100,
};

export const ValidationMessage = {
  contactName: '已超出120字符长度',
  mobile: '已超出50字符长度',
  idCardIdentityNumber: '已超出18字符长度',
  otherIdentityNumber: '已超出50字符长度',
  address: '已超出100字符长度',
};

export const IDENTIFY = {
  IDENTITY_CARD: 'IDENTITY_CARD',
  PASSPORT: 'PASSPORT',
  UNIFORM_SOCIAL_CREDIT_CODE: 'UNIFORM_SOCIAL_CREDIT_CODE',
};

export const IDENTIFY_TYPE = {
  [IDENTIFY.IDENTITY_CARD]: '身份证',
  [IDENTIFY.PASSPORT]: '护照',
  [IDENTIFY.UNIFORM_SOCIAL_CREDIT_CODE]: '统一社会信用代码/营业执照',
};

export const IDENTIFY_REVERSE_TYPE = {
  [IDENTIFY_TYPE[IDENTIFY.IDENTITY_CARD]]: IDENTIFY.IDENTITY_CARD,
  [IDENTIFY_TYPE[IDENTIFY.PASSPORT]]: IDENTIFY.PASSPORT,
  [IDENTIFY_TYPE[IDENTIFY.UNIFORM_SOCIAL_CREDIT_CODE]]: IDENTIFY.UNIFORM_SOCIAL_CREDIT_CODE,
};

export const getTableIndex = (currentPage: number, pageSize: number) => (currentPage - 1) * pageSize + 1;

export const dateFormatter = ({ value }: { value: string }) => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '');

export function formatCustomerAddressLocation(value: InsuranceCustomerContactInfoDTO): string {
  const province = _.get(value, 'province');
  const city = _.get(value, 'city');
  const district = _.get(value, 'district');
  const address1 = _.get(value, 'address1');
  const address2 = _.get(value, 'address2');
  return _.join(_.compact([province, city, district, address1, address2]), '');
}

export const validateCustomerForm = (formGroup, formErrors): void => {
  Object.keys(formErrors).forEach((field) => {
    formErrors[field] = '';
    const control = formGroup.get(field);
    if (field === 'identityNumber') {
      const type = formGroup.get('identityType').value;
      const isInvalid = isInvalidIdentityNumber(type, control.value);
      if (isInvalid) {
        formErrors[field] = getIdentityNumberErrorMessage(type);
      }
    } else if (invalidValueLength(control.value, VALIDATE_WIDTH[field])) {
      formErrors[field] = ValidationMessage[field];
    }
  });
};

export const getIdentityNumberErrorMessage = (type) =>
  type === IDENTIFY.IDENTITY_CARD ? ValidationMessage.idCardIdentityNumber : ValidationMessage.otherIdentityNumber;

export const isInvalidIdentityNumber = (type, value) => {
  if (type === IDENTIFY.IDENTITY_CARD) {
    return invalidValueLength(value, VALIDATE_WIDTH.idCardIdentityNumber);
  }
  return invalidValueLength(value, VALIDATE_WIDTH.otherIdentityNumber);
};

export function invalidValueLength(value: string, maxLength: number): boolean {
  return !_.isNil(value) && value.replace(/\s*/g, '').length > maxLength;
}

export function formatBooleanValue(value: boolean, emptyWord = '未知'): string {
  return _.isNil(value) ? emptyWord : value ? '是' : '否';
}

export function formatBooleanValueWithAll(value: boolean): string {
  return _.isNil(value) ? '全部' : value ? '是' : '否';
}

export function formatCustomerType(value: string): string {
  return _.isNil(value) ? '全量客户' : '续保期客户';
}

export function formatNil(value, format = null) {
  if (_.isNil(value) || value === '') {
    return '';
  }
  return format ? format(value) : value;
}

export const formatStartOfDay = (date: string): string => {
  return moment(date).startOf('day').toISOString();
};

export const formatEndOfDay = (date: string): string => {
  return moment(date).endOf('day').toISOString();
};

export const formatPrice = (value): string => {
  return value && formatAmount(value);
};

export const formatFieldsValueByFormatFun = (formKeys: string[], formValue: any, formatFun: Function) => {
  return {
    ..._.reduce(
      formKeys,
      (obj, key) => {
        const value = _.get(formValue, key);
        return {
          ...obj,
          [key]: value ? formatFun(value) : null,
        };
      },
      {},
    ),
  };
};

export function getMultiSelectValue(formValue, formKey: string) {
  const selectValue = _.get(formValue, formKey, '');
  const selectALLRegex = new RegExp(/SELECT_ALL|全部/gm);
  return (selectValue === '' || selectALLRegex.test(selectValue)) ? null : _.split(selectValue, ',');
}

export const exportStatusFormatter = ({ value }) => ExportInsuranceStatuses[value];

export const calculateDueDate = (dueDate, currentDate= new Date()) => {
  if (_.isNil(dueDate)) {
    return '';
  }

  let diffDays = moment(dueDate).startOf('day').diff(moment(currentDate).startOf('day'), 'days');
  // Business requirements: If the time is not at zero, the number of expiry days should be increased by one day.
  if (moment(dueDate).hour() !== 0 || moment(dueDate).minute() !== 0 || moment(dueDate).second() !== 0) {
    diffDays += 1;
  }
  return diffDays.toString();
};

export const calculateFutureDaysFromToday = (date: string | Date | moment.Moment) => {
  if (_.isNil(date)) {
    throw new TypeError('Arguments [date] is not an instance of Date, or could not covert to a Date Object');
  }

  const current = moment();

  return moment(date).startOf('day').diff(current.startOf('day'), 'days');
};

export function getCurrentDateString(): string {
  return moment().format(DATE_FORMAT);
}

export function getCurrentDateMonthsBefore(month: number): string {
  return moment().subtract(month, 'month').format(DATE_FORMAT);
}

export function formatDateString(time, isTimeStamp = true): string {
  if (isTimeStamp) {
    return _.isNil(time) ? '-' : moment.unix(time).format(DATE_FORMAT);
  }
  return _.isNil(time) ? '-' : moment(time).format(DATE_FORMAT);
}

export const formatEnumString = (item, path, enumValue) => {
  return _.get(item, path) ?
    _.get(item, path).toString().split(',').map(source => enumValue[source]).join('，') : '全部';
};

export const formatTextValueString = (item, path, textValueOptions: KeyValue[]) => {
  return _.get(item, path) ?
    _.map(_.filter(textValueOptions, obj => _.get(item, path).toString().split(',').includes(obj.value)), 'text').join('，') : '全部';
};

export const getCurrentDateBeforeMonthString = (month: number): string => {
  return moment().subtract(month, 'month').add(1, 'day').format(DATE_FORMAT);
};

export const getCurrentDateBeforeYearString = (year: number): string => {
  return moment().subtract(year, 'year').add(1, 'day').format(DATE_FORMAT);
};

export const getCurrentDateStartOfMonth = (): string => {
  return moment().startOf('month').format(DATE_FORMAT);
};

export const commercialInsuranceCouldEdit = (data): boolean => {
  return (data.commercialInsuranceIssueType === InsuranceIssueType.DEALER_REPORT ||
      data.commercialInsuranceIssueType === InsuranceIssueType.UNDERWRITING_REGISTRATION) &&
    calculateFutureDaysFromToday(data.commercialInsurancePolicyEffectiveDate) >= -89;
};

export const statutoryAutomobileLiabilityInsuranceCouldEdit = (data): boolean => {
  return (data.statutoryAutomobileLiabilityInsurancePolicyIssueType === InsuranceIssueType.DEALER_REPORT ||
      data.statutoryAutomobileLiabilityInsurancePolicyIssueType === InsuranceIssueType.UNDERWRITING_REGISTRATION) &&
    calculateFutureDaysFromToday(data.statutoryAutomobileLiabilityInsurancePolicyEffectiveDate) >= -89;
};

export const isPolicyCouldEdit = (data): boolean => {
  switch (data.insuranceType) {
    case insuranceTypeEnum.COMMERCIAL_INSURANCE:
      return commercialInsuranceCouldEdit(data);
    case insuranceTypeEnum.STATUTORY_AUTOMOBILE_LIABILITY_INSURANCE:
      return statutoryAutomobileLiabilityInsuranceCouldEdit(data);
    case insuranceTypeEnum.COMMERCIAL_AND_STATUTORY_AUTOMOBILE_LIABILITY_INSURANCE:
      return commercialInsuranceCouldEdit(data) || statutoryAutomobileLiabilityInsuranceCouldEdit(data);
    default:
      return false;
  }
};

export const formatUsernameAndD8Account = (account: string, username: string) => `${username || ''} ${account || ''}`;

import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import * as moment from 'moment';
import { AppComponent } from './app.component';
import { APP_PROVIDERS, COMPONENT_PROVIDERS } from './_module-config/providers';
import {
  TimePickerServiceModalComponent,
} from './_common/components/modal/services/time-picker-service-modal.component';
import { ConfirmServiceModalComponent } from './_common/components/modal/services/confirm-service-modal.component';
import {
  SalesPlanEditModalComponent,
} from './sales-performance/sales-strategy/plan-modal/edit-modal/sales-plan-edit-modal.component';
import {
  SalesPlanDetailModalComponent,
} from './sales-performance/sales-strategy/plan-modal/detail-modal/sales-plan-detail-modal.component';
import { DealerNodeComponent } from './_common/components/mind-map-nodes/dealer-node/dealer-node.component';
import {
  MultipleDatePickerModalComponent,
} from './test-drive-vehicle/multiple-date-picker-modal/multiple-date-picker-modal.component';
import {
  OrderApprovalProcessDetailComponent,
} from './order/components/order-approval-process-detail/order-approval-process-detail.component';
import { FinishTestDriveComponent } from './lead/create/modal/finish-test-drive.component';
import {
  FinishReceptionFormModalComponent,
} from './hall/modal/finish-reception-form-modal/finish-reception-form-modal.component';
import { BrowserModule } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule as HttpModule } from '@angular/common/http';
import { CalendarModule, ChartModule, CheckboxModule, DataTableModule } from 'primeng/primeng';
import {
  BsDropdownModule,
  CollapseModule,
  ModalModule,
  PaginationModule,
  TimepickerModule,
  TooltipModule,
} from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { ROUTES, USE_PART_MODULE_TO_COMPILE } from './_module-config/routes';
import { GridModule } from './_common/components/grid';
import { MultipleDatePickerModule } from 'multiple-date-picker-angular';
import { RebirthHttpModule } from 'rebirth-http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InlineSVGModule } from 'ng-inline-svg';
import { LayoutModule } from './_layout/layout.module';
import { CommonComponentModule } from './_common/common-component.module';
import { AppFormModule } from './_common/components/form/app.form.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TriggerHttpHeadersInterceptor } from './_common/utils/trigger-http-headers';
import { OtrNgComponentsModule } from '@otr/ng-components';
import {
  DownPaymentFormModalComponent,
} from './customer-lead-management/lead-management/sales-lead/lead-information/lead-retention/down-payment-modal/down-payment-form-modal.component';
import { environment } from '../environments/environment';
import { ConfigParams, WebsiteCommonModule } from '@otr/website-common';
import { TabConfigService } from './_common/constants/tab-config';
import { HttpTimeoutInterceptor } from './_common/utils/http-timeout-interceptor';
import { DealerForReportDragBarModule } from './_layout/dealer-for-report-drag-bar/dealer-for-report-drag-bar.module';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CtiMainModule } from './_layout/cti-main/cti-main.module';
import { FileUploadModule } from 'ng2-file-upload';

moment.locale('zh-cn');

const commonModuleConfig: ConfigParams = {
  environment,
  tabConfigService: TabConfigService,
};

const httpInterceptorProviders = [
  // Ugly hack to trigger fetching headers as some APIs might need them, otherwise they'll always be empty even when the request ist
  // set to observer the whole response
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TriggerHttpHeadersInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: HttpTimeoutInterceptor, multi: true },
];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, ...COMPONENT_PROVIDERS],
  entryComponents: [
    TimePickerServiceModalComponent,
    ConfirmServiceModalComponent,
    SalesPlanEditModalComponent,
    SalesPlanDetailModalComponent,
    DealerNodeComponent,
    MultipleDatePickerModalComponent,
    OrderApprovalProcessDetailComponent,
    FinishTestDriveComponent,
    FinishReceptionFormModalComponent,
    DownPaymentFormModalComponent,
  ],
  imports: [
    BrowserModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    CalendarModule,
    ChartModule,
    CollapseModule,
    DataTableModule,
    CheckboxModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TimepickerModule.forRoot(),
    PaginationModule.forRoot(),
    RouterModule.forRoot(ROUTES, { useHash: true, onSameUrlNavigation: 'reload' }),
    GridModule,
    MultipleDatePickerModule,
    RebirthHttpModule,
    BrowserAnimationsModule,
    InlineSVGModule,
    LayoutModule,
    CommonComponentModule,
    AppFormModule,
    ScrollingModule,
    WebsiteCommonModule.forRoot(commonModuleConfig),
    OtrNgComponentsModule,
    DealerForReportDragBarModule,
    DeviceDetectorModule.forRoot(),
    CtiMainModule,
    FileUploadModule
  ],
  providers: [
    // expose our Services and Providers into Angular's dependency injection
    ...APP_PROVIDERS,
    httpInterceptorProviders,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {
}

import { Injectable } from '@angular/core';

@Injectable()
export class FlashService {
  private _message: string = '';

  get message() {
    return this._message;
  }

  set message(message: string) {
    this._message = message;
  }

  clear() {
    this._message = '';
  }
}

<div class='form-container' #finishReceptionForm [style.top.px]='top' [style.left.px]='left'>
  <p class='warning-message'>请确认以下接待信息，结束接待后，到店目的与销售顾问将不可更改</p>
  <form [formGroup]='form'>
    <div class='form-row'>
      <div class='form-column' formGroupName='time'>
        <label>结束时间</label>
        <div class='finish-time-input-wrapper'>
          <input class='time-input' [class.error]="shouldShowError('time')" id='finishHourInput' type='number'
                 formControlName='hour'>
          :
          <input class='time-input' [class.error]="shouldShowError('time')" id='finishMinuteInput' type='number'
                 formControlName='minute'>
        </div>
        <p *ngIf="shouldShowError('time')" class='error-message'>{{options.errorMessages.min}}</p>
      </div>
      <div class='form-column'>
        <label>到店目的</label>
        <app-dropdown
          [class.error]="shouldShowError('purpose')"
          [options]='purposeOptions'
          class='form-column'
          [selectedValue]="{ text: form.controls['purpose'].value}"
          (select)="form.controls['purpose'].setValue($event)"
        ></app-dropdown>
      </div>
      <div class='form-column'>
        <label>本次接待销售顾问</label>
        <app-dropdown
          [class.error]="shouldShowError('salesConsultantId')"
          [options]='scOptions'
          [selectedValue]="{text:getScName(form.controls['salesConsultantId'].value)}"
          (select)="form.controls['salesConsultantId'].setValue($event)"
          [getValue]='getScValue'
          [getText]='getScText'
        ></app-dropdown>
      </div>
    </div>
    <label>意向车</label>
    <div class='form-row'>
      <app-dropdown
        [class.error]="shouldShowError('brand')"
        class='form-column'
        placeholder='品牌'
        [options]='brandOptions'
        [selectedValue]="{ text: getVehicleDescription(form.controls['brand'].value) }"
        [getText]='getVehicleDescription'
        (select)="form.controls['brand'].setValue($event)"
      >
      </app-dropdown>
      <app-dropdown
        [class.error]="shouldShowError('class')"
        class='form-column'
        placeholder='车系'
        [optionSubject]='classes$'
        [selectedValue]="{ text: getVehicleDescription(form.controls['class'].value) }"
        [getText]='getVehicleDescription'
        (select)="form.controls['class'].setValue($event)"
      >
      </app-dropdown>
      <app-dropdown
        [class.error]="shouldShowError('variant')"
        class='form-column'
        placeholder='车型'
        [optionSubject]='variants$'
        [selectedValue]="{ text: formatModelWithBaumusterNstControl(form.controls['variant'].value,form.controls['baumusterNst'].value) }"
        [getText]='getVehicleDescriptionWithBaumuster'
        (select)='onSelectModelWithBaumusterNst($event)'
      >
      </app-dropdown>
    </div>
  </form>
  <div class='btn-panel'>
    <div class='btn btn-outline-primary' (click)='cancel()'>取消</div>
    <div class='btn btn-primary' (click)='confirm()'>确定</div>
    <div></div>
  </div>
</div>

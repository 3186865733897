<div class="toolTip-icon" (mouseover)="stopMousePropagation($event)">
    <span class="text">
      {{shouldShowText ? text : ''}}
      <span class="icon" *ngIf="shouldShowIcon" [tooltip]="isTooltipValuesArray() ? tooltipTemplate: tooltipNewLineTemplate" [placement]="placement" container="body">
      <img [src]="imageSrc" alt="info">
    </span>
    </span>
  </div>

  <ng-template #tooltipTemplate>
    <div class='tooltip-container'>
      <div class="tooltip-row" *ngFor="let tooltipValue of tooltipValues">
        <p class="title">{{tooltipValue.title}}</p>
        <p class='content' [ngClass]="tooltipValue.styleClass">{{tooltipValue.value}}</p>
      </div>
    </div>
  </ng-template>

  <ng-template #tooltipNewLineTemplate>
    <div *ngFor="let text of toolTipArray">{{text}}</div>
  </ng-template>

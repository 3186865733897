import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalPosition, PositionedModal } from '../../../_common/components/modal/services/position';
import { BehaviorSubject, Subject } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PURPOSES, PURPOSES_REQUIRE_INTERESTED_VEHICLE } from '../../constants/lead';
import { ScScheduleService } from '../../../_common/services/sc-schedule.service';
import * as _ from 'lodash';
import { VehicleService } from '../../../_common/services/vehicle.service';
import { getModelOptionsFromClass } from '../../../_common/utils/vehicle';
import * as moment from 'moment';
import { getScDisplayText } from '../../../_common/utils/lead';
import { markAllFieldsAsTouched } from '../../../_common/utils/form';

export interface FinishReceptionFormModalOptions {
  minTime: { hour: number, minute: number };
  initialTime: { hour: number, minute: number };
  errorMessages: { min: string };
  purpose: string;
  sales_consultant_id: string;
  interestedVehicle: any;
}

@Component({
  selector: 'app-finish-reception-form-modal',
  templateUrl: './finish-reception-form-modal.component.html',
  styleUrls: ['./finish-reception-form-modal.component.scss'],
})
export class FinishReceptionFormModalComponent implements PositionedModal {
  subject: Subject<any>;
  left: number = 0;
  top: number = 0;

  @ViewChild('finishReceptionForm') finishReceptionForm: ElementRef;

  options: FinishReceptionFormModalOptions;

  form: FormGroup;

  purposeOptions: Array<string> = PURPOSES;
  scOptions: Array<any>;

  classes$: BehaviorSubject<any> = new BehaviorSubject([]);
  variants$: BehaviorSubject<any> = new BehaviorSubject([]);

  getScValue: Function = sc => _.get(sc, 'gems_user_id');
  getScText: Function = sc => getScDisplayText(sc);
  getScName: Function = scId => scId && getScDisplayText(_.find(this.scOptions, { gems_user_id: scId }));
  getVehicleDescription: Function = option => _.get(option, 'description_zh') || _.get(option, 'description_en') || '';
  getVehicleDescriptionWithBaumuster: Function = option => _.get(option, 'baumuster') ?
    `${_.get(option, 'description_zh') || _.get(option, 'description_en')}\t(${_.get(option, 'baumuster')}-${_.get(option, 'nst')})`
    : _.get(option, 'description_zh') || _.get(option, 'description_en') || '';

  constructor(private formBuilder: FormBuilder,
              private scScheduleService: ScScheduleService,
              private vehicleService: VehicleService) {
    this.subject = new Subject();
  }

  get brandOptions(): Array<any> {
    const interestedBrandsData = this.vehicleService.interestedBrandsData;
    return interestedBrandsData || [];
  }

  formatModelWithBaumusterNstControl = (model, nstDescription) => {
    let suffix = '';
    if (_.get(nstDescription, 'baumuster') && _.get(model, 'description_zh') !== '其他') {
      suffix = `\t
        (${_.get(nstDescription, 'baumuster')}-${_.get(nstDescription, 'nst')})`;
    }
    if (_.get(model, 'description_zh') || _.get(model, 'description_en')) {
      return `${_.get(model, 'description_zh') || _.get(model, 'description_en')}${suffix}`;
    }
    return '';
  };

  getClientHeight(): number {
    const nativeElement = this.finishReceptionForm.nativeElement;
    return nativeElement.getBoundingClientRect().height;
  }

  getClientWidth(): number {
    const nativeElement = this.finishReceptionForm.nativeElement;
    return nativeElement.getBoundingClientRect().width;
  }

  getSubject(): Subject<any> {
    return this.subject;
  }

  setPosition(position: ModalPosition): void {
    this.left = position.left;
    this.top = position.top;
  }

  shouldShowError(controlPath) {
    const control = this.form.controls[controlPath];
    return control.touched && control.invalid;
  }

  setProperties(options: FinishReceptionFormModalOptions): void {
    this.options = options;
    this.scOptions = this.scScheduleService.getScOptions(this.options.sales_consultant_id);
    this.initForm();
  }

  cancel() {
    this.subject.next(null);
  }

  confirm() {
    markAllFieldsAsTouched(this.form);
    if (this.form.valid) {
      const interestedVehicle: any = _.pick(this.form.value, ['brand', 'class', 'variant', 'baumusterNst']);
      this.subject.next({
        ..._.pick(this.form.value, ['purpose', 'time', 'salesConsultantId']),
        interestedVehicle: _.isNil(interestedVehicle.variant) ? null : interestedVehicle,
      });
    }
  }

  onSelectModelWithBaumusterNst(value) {
    this.form.controls.variant.setValue(value);
    this.form.controls.baumusterNst.setValue({ baumuster: _.get(value, 'baumuster'), nst: _.get(value, 'nst') });
  }

  finishTimeValidator = (control: AbstractControl) => {
    if (moment().hour(_.get(control.value, 'hour', 0)).minute(_.get(control.value, 'minute', 0))
      .isBefore(moment().hour(_.get(this.options, 'minTime.hour')).minute(_.get(this.options, 'minTime.minute')))) {
      return { time: _.get(this.options, 'errorMessages.min') };
    }
    return null;
  };

  formValidator = (control: FormGroup) => {
    const vehicleControlErrors =
      Validators.required(control.get('brand')) ||
      Validators.required(control.get('class')) ||
      Validators.required(control.get('variant'));
    if (_.includes(PURPOSES_REQUIRE_INTERESTED_VEHICLE, control.get('purpose').value)) {
      this.setVehicleControlError(control, vehicleControlErrors);
      return vehicleControlErrors;
    }
    if (_.isNil(control.controls.brand.value)) {
      this.setVehicleControlError(control, null);
      return null;
    }
    this.setVehicleControlError(control, vehicleControlErrors);
    return vehicleControlErrors;
  };

  private initForm() {
    this.form = this.formBuilder.group({
      time: this.formBuilder.group({
        hour: [this.options.initialTime.hour, Validators.required],
        minute: [this.options.initialTime.minute, Validators.required],
      }, {
        validator: this.finishTimeValidator,
      }),
      purpose: [this.options.purpose || null, Validators.required],
      salesConsultantId: [this.options.sales_consultant_id || null, Validators.required],
      brand: null,
      class: null,
      variant: null,
      baumusterNst: null,
    }, {
      validator: this.formValidator,
    });
    this.form.controls.brand.valueChanges.subscribe(brand => {
      this.classes$.next(_.get(brand, 'classes', []));
      this.form.controls.class.setValue(null);
      this.form.controls.variant.setValue(null);
      this.form.controls.baumusterNst.setValue(null);
    });
    this.form.controls.class.valueChanges.subscribe(classOption => {
      if (!_.get(classOption, 'models')) {
        const brandValue = this.form.controls.brand.value;
        const classValue = _.find(_.get(brandValue, 'classes'), classOption);
        this.variants$.next(getModelOptionsFromClass(classValue));
      } else {
        this.variants$.next(getModelOptionsFromClass(classOption));
      }
      this.form.controls.variant.setValue(null);
      this.form.controls.baumusterNst.setValue(null);
    });
    this.populateInitialInterestedVehicleIntoForm();
  }

  private setVehicleControlError(control: FormGroup, error) {
    control.get('brand').setErrors(error);
    control.get('class').setErrors(error);
    control.get('variant').setErrors(error);
  }

  private populateInitialInterestedVehicleIntoForm() {
    const initialInterestedVehicle = _.get(this.options, 'interestedVehicle') as any;
    if (!_.has(initialInterestedVehicle, 'variant')) {
      return;
    }
    const brandValue = _.find(this.brandOptions, _.get(initialInterestedVehicle, 'brand'));
    this.form.patchValue({
      brand: brandValue || null,
      class: _.get(initialInterestedVehicle, 'class', null),
      variant: _.get(initialInterestedVehicle, 'variant', null),
      baumusterNst: _.get(initialInterestedVehicle, 'baumusterNst', null),
    });
  }
}

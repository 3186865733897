import * as _ from 'lodash';
import {compoundSurnameList} from '../../../assets/compound-surname/compound-surname';
export enum IdentityType {
  IDENTITY_CARD = 'IDENTITY_CARD',
  PASSPORT = 'PASSPORT',
  UNIFORM_SOCIAL_CREDIT_CODE = 'UNIFORM_SOCIAL_CREDIT_CODE',
  BUSINESS_LICENSE = 'BUSINESS_LICENSE',
}

export const IDENTIFY = {
  IDENTITY_CARD: 'IDENTITY_CARD',
  PASSPORT: 'PASSPORT',
  UNIFORM_SOCIAL_CREDIT_CODE: 'UNIFORM_SOCIAL_CREDIT_CODE',
};

export function encryptedCustomer(insuranceCustomers: Array<any>): Array<any> {
  const encryptedCustomerData = _.cloneDeep(insuranceCustomers || []);
  encryptedCustomerData.forEach(customer => {
    customer.name = _.eq(customer.identityType, IdentityType.UNIFORM_SOCIAL_CREDIT_CODE) ? customer.name : encryptedUserName(customer.name);
    customer.identityNumber = encryptedIdentityNumber(customer.identityType, customer.identityNumber);
    customer.mobile = encryptedMobile(customer.mobile);
    customer.province = encryptedAddress(customer.province);
    customer.city = encryptedAddress(customer.city);
    customer.district = encryptedAddress(customer.district);
    customer.address1 = encryptedAddress(customer.address1);
    customer.address2 = encryptedAddress(customer.address2);
    customer.engineNumber = encryptedEngineNumber(customer.engineNumber);
    customer.carPlateNumber = encryptedCarPlateNumber(customer.carPlateNumber);
    customer.vin = encryptedVin(customer.vin);

    Object.keys(customer).forEach((key) =>
      (_.isNil(customer[key]) || _.isEmpty(customer[key].toString()) ? _.unset(customer, key) : _.noop()));
  });
  return encryptedCustomerData;
}

export const encryptedUserName = (userName: string) => {
  let surname = 1;
  if (_.isNil(userName) || _.isEmpty(userName)) {
    return '';
  }
  if (userName.length >= 2 && _.includes(compoundSurnameList, userName.slice(0, 2))) {
    surname = 2;
  }
  return userName.slice(0, surname).concat(generateStart(userName.slice(surname)));
};

export const encryptedMobile = (mobileNumber: string) =>
  !_.isNil(mobileNumber) && !_.isEmpty(mobileNumber) && mobileNumber.length === 11
    ? splicingStr(mobileNumber, 0, 3, 7, 4)
    : mobileNumber;

export const encryptedFin = (fin: string) => {
  if (fin && fin.length >= 4) {
    return fin.substring(0, 2) + '*'.repeat(fin.length - 4) + fin.substring(fin.length - 2);
  }
  return fin;
};

export const encryptedEndFin = (fin: string) => splicingStr(fin, 0, 11);

export const encryptedIdentityNumber = (identityType: string, identityNumber: string) => {
  if (_.isNil(identityNumber)) {
    return identityNumber;
  }
  if (identityType === IDENTIFY.IDENTITY_CARD) {
    return encryptedIdentityCard(identityNumber);
  }
  if (identityType === IDENTIFY.PASSPORT) {
    return _.isEmpty(identityNumber) ? '' : encryptedPassport(identityNumber);
  }
  return identityNumber;
};

const encryptedIdentityCard = (identityNumber: string) => splicingStr(identityNumber, 0, 6, 14, 4);

const encryptedPassport = (identityNumber: string) => '*'.concat(
  splicingStr(identityNumber,
    1,
    1,
    !_.isNil(identityNumber) && identityNumber.length > 3 ? Math.max(identityNumber.length - 2, 2) : null,
    !_.isNil(identityNumber) && identityNumber.length > 3 ? Math.min(identityNumber.length - 2, 2)  : 0));

export const encryptedAddress = (address: string) => generateStart(address);

export const encryptedEngineNumber = (engineNumber: string) => splicingStr(engineNumber,
  0,
  1,
  !_.isNil(engineNumber) && engineNumber.length > 1 ? engineNumber.length - 1 : null, 1);

export const encryptedCarPlateNumber = (carPlateNumber: string) => splicingStr(carPlateNumber, 0, 1);

export const encryptedVin = (vin: string) => splicingStr(vin, 0, 2, 15, 2);

const splicingStr = (str: string, startIndex: number, startLength: number, endIndex: number|null = null, endLength: number = 0) => {
  if (_.isNil(str) || _.isEmpty(str)) {
    return '';
  }

  const endSpliceIndex = _.isNil(endIndex) ? str.length : endIndex;
  return str.slice(startIndex, startIndex + startLength)
    .concat(
      generateStart(str.slice(startIndex + startLength, endSpliceIndex)),
      str.slice(endSpliceIndex, endSpliceIndex + endLength),
      generateStart(str.slice(endSpliceIndex + endLength, str.length)));
};

const generateStart = str => _.isEmpty(str) ? '' : Array(str.length + 1).join('*').toString();

import { Injectable } from '@angular/core';
import { Body, GET, POST, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BrochureOrderingApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @POST('/api/website/brochure-ordering/brochures:submit')
  submitBrochure(@Body details: any): Observable<any> {
    return null;
  }

  @GET('/api/website/brochure-ordering/brochures/address')
  getAddress(): Observable<any> {
    return null;
  }

  @GET('/api/website/brochure-ordering/brochures')
  loadBrochure(@Query('yearlyTargetId') yearlyId: string): Observable<any> {
    return null;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AsVehicleApi } from '../api/as-vehicle';

@Injectable()
export class AsVehicleService {
  constructor(private asVehicleApi: AsVehicleApi) {
  }

  searchVehicleInfoByVinOrFin(vinOrFin): Observable<any> {
    return this.asVehicleApi.searchVehicleInfoByVinOrFin(vinOrFin);
  }

  getServiceCircuitBreaker(): Observable<any> {
    return this.asVehicleApi.getServiceCircuitBreaker();
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

const getValue = (value, params) =>
  _.isFunction(value) ? value.call(null, params) : value;

@Component({
  template: `
    <span>{{text}}</span>
    <span class='custom-label' *ngIf='displayLabelName'>{{labelName}}</span>
  `,
  styleUrls: ['../custom-label-cell/custom-label-cell.component.scss'],
})
export class CustomLabelCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  text: string;
  displayLabelName: boolean;
  labelName: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.text = getValue(this.cellRendererParams.text, params) || '';
    this.displayLabelName = getValue(this.cellRendererParams.displayLabelName, params) || '';
    this.labelName = getValue(this.cellRendererParams.labelName, params) || '';
  }

  refresh(params: any): boolean {
    return false;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onClick() {
    this.cellRendererParams.onClick(this.params.node.data);
  }
}

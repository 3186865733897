import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import {
  ACCIDENT_CAR_LEAD_TYPE_MUl_INIT,
  ACCIDENT_CAR_LEAD_TYPE_MUl_OPTIONS,
  DROPDOWN_DATE_RANGE_OPTION_MONTH,
  DROPDOWN_DATE_RANGE_OPTION_QUARTER,
  DROPDOWN_DATE_RANGE_OPTION_YEAR,
  DROPDOWN_DATE_RANGE_OPTIONS_DAY,
  FILTER_INPUT_TYPES,
  MONTH_SEASON_AND_YEAR_REFRESH_TIME,
  REFRESH_TIME, VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import * as moment from 'moment';
import { DATE_FORMAT } from '../../_common/constants/common';
import {
  amountFormatter,
  findTextByValue,
  getAccidentCarSettingByEnvironment,
  percentageFormatter,
} from '../utils/utils';
import { PrivilegesEnum } from '../../_common/services/user-privilege.service';

export const MB_PRODUCT_CAR_INSURANCE_LEAD_KPI_REPORT_CONFIG = {
  name: 'mb-product-car-insurance-lead-kpi-report',
  enablePagination: true,
  pageSize: 50,
  filterThreeColumn: true,
  filterFourColumn: true,
  exportedFileName: '钣喷专享礼线索与车险线索KPI报表.xlsx',
  timeFormatter: 'YYYYMMDDHHmmss',
  isNameWithDate: true,
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  refreshTime: REFRESH_TIME,
  monthAndQuarterAndYearRefreshTime: MONTH_SEASON_AND_YEAR_REFRESH_TIME,
  defaultSearchWhenEnterReport: true,
  showSummaryLine: true,
  nowrap:  true,
  vertical: true,
  newLineOperation: true,
  isNameWithVehicleBusinessType: true,
  column: [
    {
      headerName: '时间',
      field: 'reportDate',
      width: 230,
      pinned: true,
    },
    {
      headerName: '经销商代码',
      field: 'gsCode',
      width: 120,
      showByPrivilege: PrivilegesEnum.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT
    },
    {
      headerName: '经销商名称',
      field: 'dealerName',
      width: 150,
      showByPrivilege: PrivilegesEnum.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT
    },
    {
      headerName: '线索类别',
      field: 'leadType',
      width: 210,
      valueFormatter: ({ value }) => findTextByValue(ACCIDENT_CAR_LEAD_TYPE_MUl_OPTIONS)(value),
    },
    {
      headerName: '线索总量（去重后）',
      field: 'totalCount',
      width: 140,
    },
    {
      headerName: '线索总量（去重后）占比',
      field: 'totalCountRate',
      width: 160,
      valueFormatter: percentageFormatter
    },
    {
      headerName: '钣喷专享礼线索先于车险线索量',
      field: 'mbProductFirstTotalCount',
      width: 200,
      valueFormatter: ({ value }) => value == '-'  ? ' ' : value ,
    },
    {
      headerName: '钣喷专享礼线索先于车险线索占比',
      field: 'mbProductFirstTotalCountRate',
      width: 210,
      valueFormatter: ({ value }) => value == '-'  ? ' ' : percentageFormatter({value}),
    },
    {
      headerName: '留修量',
      field: 'totalArrivedCount',
      width: 140,
    },
    {
      headerName: '留修率',
      field: 'totalArrivedCountRate',
      width: 140,
      valueFormatter: percentageFormatter
    },
    {
      headerName: '维修量',
      field: 'totalRepairedCount',
      width: 140,
    },
    {
      headerName: '维修率',
      field: 'totalRepairedCountRate',
      width: 140,
      valueFormatter: percentageFormatter
    },
    {
      headerName: '产值（含税）',
      field: 'totalGrossAmount',
      width: 220,
      valueFormatter: amountFormatter
    },
    {
      headerName: '产值占比',
      field: 'totalGrossAmountRate',
      width: 200,
      valueFormatter: percentageFormatter
    },
  ],

  filter: [
    {
      type: FILTER_INPUT_TYPES.DROPDOWN_DATE_RANGE,
      title: '选择时间',
      optionName: 'dimension',
      options: {
        ...DROPDOWN_DATE_RANGE_OPTIONS_DAY,
        ...DROPDOWN_DATE_RANGE_OPTION_MONTH,
        ...DROPDOWN_DATE_RANGE_OPTION_QUARTER,
        ...DROPDOWN_DATE_RANGE_OPTION_YEAR,
      },
      defaultOptionValue: DROPDOWN_DATE_RANGE_OPTIONS_DAY.DAY.value,
      resetWhenChangeOption: true,
      name: 'createdTime',
      isRangeLimited: true,
      allRequired: true,
      limitValueRange: 60,
      startMinLimitValue: getAccidentCarSettingByEnvironment('startMinLimitValue'),
      startLimitValue: moment(),
      endLimitValue: moment(),
      endMinLimitValue: getAccidentCarSettingByEnvironment('endMinLimitValue'),
      endMaxLimitValue: moment(),
      itemWidth: 430,
      defaultBeginDate: moment().format(DATE_FORMAT),
      defaultEndDate: moment().format(DATE_FORMAT),
    },
    {
      type: FILTER_INPUT_TYPES.MULTI_DROPDOWN,
      name: 'leadTypes',
      label: '线索类别',
      options: ACCIDENT_CAR_LEAD_TYPE_MUl_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_TYPE_MUl_INIT,
      itemWidth: 205,
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleBusinessType',
      label: '车辆类型',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
      className: 'vehicle-business-type',
      visible: true,
    },
  ],
};

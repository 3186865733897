import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

interface ICellAction {
  handler: Function;
  tooltip?: string;
  iconClass?: string;
  isVisible?: boolean | Function;
  iconText?: string;
}

@Component({
  templateUrl: './action-cell-render.template.html',
  styleUrls: ['./action-cell-render.style.scss'],
})
export class ActionCellRenderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  cellIcons: Array<ICellAction> = [];

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellIcons = this.getCellIcons();
  }

  refresh(params: any): boolean {
    return false;
  }

  getCellIcons(): Array<ICellAction> {
    return <Array<ICellAction>>_.chain(this.params.colDef.cellRendererParams)
      .map((icon: any) => {
        const isVisible = _.isFunction(icon.isVisible) ? icon.isVisible(this.params) : _.get(icon, 'isVisible', true);
        return { ...icon, isVisible };
      })
      .filter({ isVisible: true } as any)
      .value();
  }
}

import { Events, GridApi, IHeaderParams, RowNode } from 'ag-grid';
import * as _ from 'lodash';
import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { Subject, BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CheckboxStrategy } from './checkbox-strategy';

const STATUS_EVENTS = new Set([
  Events.EVENT_GRID_READY,
  Events.EVENT_MODEL_UPDATED,
  Events.EVENT_ROW_DATA_CHANGED,
]);

@Component({
  template: `
    <input
      type="checkbox"
      [checked]="checked$ | async"
      [disabled]="disabled$| async"
      (click)="onChange($event.target.checked)">
      <span class="custom-checkbox-label">&nbsp;</span>
    `,
  styleUrls: ['./custom-checkbox-header.component.scss'],
})
export class CheckBoxHeader2Component implements IHeaderAngularComp {
  selectableNodesCount$: Observable<number>;
  checked$: Observable<boolean>;
  disabled$: Observable<boolean>;

  private isDisabled: (data: any) => boolean;
  private event$: Subject<string> = new BehaviorSubject<string>(null);
  private params: IHeaderParams;
  private strategy: CheckboxStrategy;

  agInit(params: IHeaderParams): void {
    this.params = params;
    const colDef = params.column.getColDef();
    this.isDisabled = _.get(colDef, 'isDisabled') || _.get(colDef, 'cellRendererParams.isDisabled');
    const rendererParams = colDef.cellRendererParams;
    this.strategy = _.get(rendererParams, 'strategy') || new CheckboxStrategy();
    if (_.get(rendererParams, 'strategy') === undefined) {
      _.set(rendererParams, 'strategy', this.strategy);
    }

    this.listenEvents(params.api);

    this.selectableNodesCount$ = this.event$.pipe(
      filter(e => STATUS_EVENTS.has(e)),
      map(e => this.countSelectableRows(params.api)),
      distinctUntilChanged(),
    );

    this.disabled$ = combineLatest(rendererParams.globalDisabled$ || of(false), this.selectableNodesCount$,
    ).pipe(
      map(([globalDisabled, selectableNodesCount]) => {
        if (globalDisabled) {
          return true;
        }
        return selectableNodesCount === 0;
      }),
    );

    this.checked$ = combineLatest(
      this.selectableNodesCount$,
      this.disabled$,
      this.strategy.watchAll().pipe(map(set => set.size)),
    ).pipe(
      map(([selectableCount, disabled, selectedCount]) => !disabled && selectableCount === selectedCount),
      distinctUntilChanged(),
    );
  }

  onChange(checked) {
    checked ? this.selectAll() : this.unselectAll();
  }

  private listenEvents(api: GridApi): void {
    api.addGlobalListener(event => this.event$.next(event));
  }

  private countSelectableRows(api): number {
    return _.size(this.getSelectableRows(api));
  }

  private getSelectableRows(api): RowNode[] {
    const disabledList: { node: RowNode, disabled: boolean }[] = [];
    api.forEachNodeAfterFilter((node: RowNode) => {
      const disabled: boolean = this.isDisabled(node.data);
      disabledList.push({ node, disabled });
    });
    return _.chain(disabledList)
      .filter(({ disabled }) => !disabled)
      .map(v => v.node)
      .value();
  }

  get api() {
    return this.params.api;
  }

  getIsDisabled() {
    return _.get(this.column.getColDef(), 'isDisabled', (() => false));
  }

  get column() {
    return this.params.column;
  }

  private selectAll(): void {
    this.getSelectableRows(this.params.api).forEach(rowNode => {
      this.strategy.select(rowNode.id);
    });
  }

  private unselectAll(): void {
    this.getSelectableRows(this.params.api).forEach(rowNode => {
      this.strategy.unselect(rowNode.id);
    });
  }
}

import { dateInterval } from '../../../_common/utils/date';
import { dateParse } from '../../../_common/utils/date-parse';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';

export const getTenAtToday = () => new Date().setHours(10, 0, 0, 0);

export const getNextDay = () => {
  const dateNow = new Date();
  return new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate() + 1);
};

export const getEightToTwenty = () => {
  const timeValues = dateInterval(new Date().setHours(8, 0, 0, 0), 25, 1800000);
  return _.map(timeValues, value => ({ text: dateParse(value, 'HH:mm'), value }));
};

export const getSelectedTimestamp = DateTimeValue => {
  const { appointmentTime, appointmentDate } = DateTimeValue;
  if (!appointmentDate && !appointmentTime) {
    return null;
  }
  const selectedTime = new Date(appointmentTime);
  return new Date(appointmentDate).setHours(selectedTime.getHours(), selectedTime.getMinutes(), 0, 0);
};

export const appointmentDateAndTimeValidator = (control: FormGroup, isAssignSC: boolean) => {
  const { appointmentTime, appointmentDate } = control.value;
  const selectedTimestamp = getSelectedTimestamp(control.value);
  const isValid = isAssignSC
    ? (!appointmentDate && !appointmentTime) || (appointmentDate && appointmentTime && selectedTimestamp >= Date.now())
    : (appointmentDate && appointmentTime && selectedTimestamp >= Date.now());
  return isValid ? null : { errorMessage: '' };
};

export const scGroupAndScUserIdValidator = (control: FormGroup, isFollowUp: boolean) => {
  const { scGroup, scUserId } = control.value;
  const isValid = isFollowUp
    ? scGroup
    : scGroup && scUserId;
  return isValid ? null : { errorMessage: '' };
};

import { Component, EventEmitter, Output, Input, OnInit, ViewChild } from '@angular/core';
import {
  CurrentUserService,
  DealerIntegrationToggleService,
  TokenService,
} from '@otr/website-common';
import { USER_ROLE } from '../../constants/user-role.constant';
import { setInitVehicleBusinessType } from '../../utils/vehicle-business-type';

@Component({
  selector: 'app-vehicle-business-type-filter',
  templateUrl: './vehicle-business-type-filter.component.html',
})
export class VehicleBusinessTypeFilterComponent implements OnInit {
  @Output() onChange = new EventEmitter();
  @Input() initType: string;
  canFilterByVehicleType: boolean = false;
  @ViewChild('commonVehicleBusinessTypeFilter') commonVehicleBusinessTypeFilter;

  constructor(private dealerIntegrationToggleService: DealerIntegrationToggleService,
              private tokenService: TokenService,
              private currentUserService: CurrentUserService) {
  }

  ngOnInit(): void {
    this.getCanFilterByVehicleType();
    setInitVehicleBusinessType(this.currentUserService, this.tokenService);
  }

  getCanFilterByVehicleType(): void {
    const isOem = this.currentUserService.isRole(USER_ROLE.OEM) || this.currentUserService.isRole(USER_ROLE.VANOEM);
    this.canFilterByVehicleType = !isOem && this.tokenService.isVanPc('AS');
  }

  onTypeChange(value): void {
    this.onChange.emit(value);
  }

  set(vehicleBusinessType?: string): void {
    this.commonVehicleBusinessTypeFilter.setType(vehicleBusinessType);
  }
}

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import { Component, ViewChild } from '@angular/core';
import * as _ from 'lodash';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  templateUrl: './hover-follow-ups.template.html',
  styleUrls: ['./hover-follow-ups.style.scss'],
})
export class HoverFollowUpsComponent implements ICellRendererAngularComp {

  isHoverIn: boolean = false;
  followUpCount: string;
  followUpList: Array<any>;
  popToTop: boolean = false;
  @ViewChild('countBox') countBox;

  agInit(params: ICellRendererParams): void {
    const cellRendererParams = params.colDef.cellRendererParams;
    this.followUpCount = getValue(cellRendererParams.followUpCount, params);
    this.followUpList = _.reverse(getValue(cellRendererParams.followUpList, params));
    this.isHoverIn = false;
  }

  onMouseEnter() {
    const countBoxRect = this.countBox.nativeElement.getBoundingClientRect();

    if (window.innerHeight) {
      const { bottom } = countBoxRect;
      this.popToTop = window.innerHeight - bottom < 200;
    }

    this.isHoverIn = true;
  }

  onMouseLeave() {
    this.isHoverIn = false;
  }

  refresh(params: any): boolean {
    return false;
  }
}

import { Injectable } from '@angular/core';
import { Body, GET, Path, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GroupApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/groups')
  getGroupTree(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @PUT('/api/groups/:group_id')
  updateGroups(@Path('group_id') groupId: string, @Body data: Object): Observable<any> {
    return null;
  }

  @GET('/api/groups/detail')
  getGroupsByDealerId(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/groups/sales-team')
  getSalesTeam(): Observable<any> {
    return null;
  }

  @GET('/api/groups/sales-team')
  getSalesTeamByDealerId(@Query('dealerId') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/groups/mod/sales-team')
  getModelDSalesTeamByDealerId(@Query('dealerId') dealerId: string): Observable<any> {
    return null;
  }
}

import * as _ from 'lodash';

export const replaceUrlForVan = (pcUrl: string) => {
  const vanStrIndex = pcUrl.startsWith('/#') || pcUrl.startsWith('#')  ? 2 : 1;
  const urlArray = _.compact(pcUrl.split('/'));
  let vanUrl = '';
  _(urlArray).forEach((value: string, index) => {
    index === vanStrIndex ? vanUrl += `/van/${value}` : vanUrl += `/${value}`;
  });
  return vanUrl;
};

export const changeRouterForVan = (currentUrl, redirectToUrl: string) =>
  currentUrl.includes('/van/') ? replaceUrlForVan(redirectToUrl) : redirectToUrl;

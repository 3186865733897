import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import {
  ACCIDENT_CAR_LEAD_CONSOLIDATION,
  ACCIDENT_CAR_LEAD_CONSOLIDATION_INIT,
  ACCIDENT_CAR_LEAD_CONSOLIDATION_OPTIONS,
  ACCIDENT_CAR_LEAD_CREATE_TYPE,
  ACCIDENT_CAR_LEAD_CUSTOMER_TYPE_OPTIONS,
  ACCIDENT_CAR_LEAD_DISCARD,
  ACCIDENT_CAR_LEAD_DISCARD_OPTIONS,
  ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_OPTIONS,
  ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE,
  ACCIDENT_CAR_LEAD_LOST_REASON_OPTIONS,
  ACCIDENT_CAR_LEAD_MB_SMS_TAG_OPTIONS,
  ACCIDENT_CAR_LEAD_SELF_INSURANCE,
  ACCIDENT_CAR_LEAD_SELF_INSURANCE_OPTIONS,
  ACCIDENT_CAR_LEAD_SOURCE_OPTIONS,
  ACCIDENT_CAR_LEAD_STATUS,
  ACCIDENT_CAR_LEAD_STATUS_OPTIONS,
  ACCIDENT_CAR_THIRD_TYPE,
  FILTER_INPUT_TYPES, VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import {
  displayAnotherBooleanFormatter,
  displayBooleanFormatter,
  findTextByValue,
  localDateTimeFormatter,
  priceFormatter,
  localDateTimeListFormatter,
  findAbbreviationByValue, displayBooleanWithNilFormatter,
  defaultFormatter,
} from '../utils/utils';
import * as moment from 'moment';
import { MultipleLinesEllipsisRenderComponent } from '../../_common/components/grid/cell-renders/multiple-lines-ellipsis/multiple-lines-ellipsis.component';
import { USER_ROLE } from '../../_common/constants/user-role.constant';
export const ACCIDENT_CAR_LEAD_LIST_REPORT_CONFIG = {
  name: 'accident-car-lead-list-report',
  enablePagination: true,
  pageSize: 50,
  filterThreeColumn: true,
  filterFourColumn: true,
  exportedFileName: '线索列表.xlsx',
  isNameWithVehicleBusinessType: true,
  timeFormatter: 'YYYYMMDDHHmmss',
  isNameWithDate: true,
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  newLineOperation: true,
  column: [
    {
      headerName: '序号',
      field: 'index',
      width: 80,
      suppressFilter: true,
      suppressSorting: true,
    },
    {
      headerName: '线索ID',
      field: 'id',
      width: 150,
    },
    {
      headerName: '经销商代码',
      field: 'gsCode',
      width: 120,
    },
    {
      headerName: '经销商名称',
      field: 'dealerName',
      width: 150,
    },
    {
      headerName: '线索创建方式',
      field: 'combinedCreateType',
      width: 112,
      valueFormatter: ({ value }) => findTextByValue(ACCIDENT_CAR_LEAD_CREATE_TYPE)(value, '自动'),
    },
    {
      headerName: '线索创建时间',
      field: 'createdTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '线索渠道',
      field: 'leadSource',
      width: 140,
      valueFormatter: ({ value }) => findTextByValue(ACCIDENT_CAR_LEAD_SOURCE_OPTIONS)(value),
    },
    {
      headerName: '推修部门',
      field: 'recommendedDepartment',
      width: 110,
    },
    {
      headerName: '推修人',
      field: 'recommender',
      width: 110,
    },
    {
      headerName: '备注信息',
      field: 'remarks',
      width: 200,
    },
    {
      headerName: '车辆损伤详情',
      field: 'damageDescription',
      width: 300,
    },
    {
      headerName: '是否有车主上报的事故照片',
      field: 'isHasMystarPhoto',
      width: 200,
    },
    {
      headerName: '是否为事故相关报案',
      field: 'isAccidentRelatedReport',
      width: 200,
    },
    {
      headerName: '报案人',
      field: 'customerFullName',
      width: 110,
    },
    {
      headerName: '手机号',
      field: 'customerPhoneNumber',
      width: 130,
    },
    {
      headerName: '车牌号',
      field: 'plateNo',
      width: 110,
    },
    {
      headerName: '底盘号',
      field: 'vin',
      width: 180,
    },
    {
      headerName: '首登日期',
      field: 'evaFirstRegistrationDate',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '车型',
      field: 'vehicleType',
      width: 125,
    },
    {
      headerName: '保险公司',
      field: 'insuranceCompanyName',
      width: 100,
    },
    {
      headerName: '保险类别',
      field: 'selfInsurance',
      width: 98,
      valueFormatter: ({ value }) => findTextByValue(ACCIDENT_CAR_LEAD_SELF_INSURANCE)(value),
    },
    {
      headerName: '客户类别',
      field: 'customerType',
      width: 98,
    },
    {
      headerName: '标的车/三者车',
      field: 'isThirdType',
      width: 100,
      valueFormatter: ({ value }) => findTextByValue(ACCIDENT_CAR_THIRD_TYPE)(value),
    },
    {
      headerName: '报案号',
      field: 'insuranceCaseNo',
      width: 150,
    },
    {
      headerName: '上次服务人员',
      field: 'lastServiceOwner',
      width: 112,
    },
    {
      headerName: '销售顾问',
      field: 'salesConsultant',
      width: 112,
    },
    {
      headerName: '事故地点',
      field: 'accidentLocation',
      width: 300,
    },
    {
      headerName: '事故时间',
      field: 'accidentTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '钣喷维修专享礼',
      field: 'mbSmsTag',
      width: 124,
      valueFormatter: displayAnotherBooleanFormatter,
    },
    {
      headerName: '初始经销商',
      field: 'originalDealerName',
      width: 300,
      valueFormatter: defaultFormatter,
      cellRendererFramework: MultipleLinesEllipsisRenderComponent,
    },
    {
      headerName: '合并情况',
      field: 'consolidation',
      width: 120,
      valueFormatter: ({ value }) => findTextByValue(ACCIDENT_CAR_LEAD_CONSOLIDATION)(value),
    },
    {
      headerName: '合并时间',
      field: 'mergedTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '线索状态',
      field: 'status',
      width: 88,
      valueFormatter: ({ value }) => findTextByValue(ACCIDENT_CAR_LEAD_STATUS)(value),
    },
    {
      headerName: '最后更新时间',
      field: 'updatedTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '留修专员',
      field: 'retentionSpecialistOwner',
      width: 110,
    },
    {
      headerName: '外勤专员',
      field: 'fieldSpecialistOwner',
      width: 112,
    },
    {
      headerName: '首次跟进时间',
      field: 'firstFollowUpTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '是否3分钟跟进',
      field: 'isFollowedInThreeMinutes',
      width: 124,
      valueFormatter: displayBooleanWithNilFormatter,
    },
    {
      headerName: '跟进次数',
      field: 'followUpCount',
      width: 100,
    },
    {
      headerName: '跟进内容',
      field: 'followUpContents',
      width: 300,
    },
    {
      headerName: '是否分配外勤专员',
      field: 'isFieldSpecialist',
      width: 136,
      valueFormatter: displayBooleanFormatter,
    },
    {
      headerName: '首次分配外勤专员时间',
      field: 'firstFieldAssignedTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '上传外勤照片时间',
      field: 'fieldPhotoUploadedTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '上传外勤照片人员',
      field: 'fieldPhotoUploadedUser',
      width: 136,
    },
    {
      headerName: '首次已预约时间',
      field: 'firstReservedTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '是否已到店',
      field: 'isArrived',
      width: 100,
      valueFormatter: displayBooleanFormatter,
    },
    {
      headerName: '到店时间',
      field: 'arrivedTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '是否已维修',
      field: 'isRepaired',
      width: 100,
      valueFormatter: displayBooleanFormatter,
    },
    {
      headerName: '首次结账时间',
      field: 'repairedTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '订单号',
      field: 'orderNumber',
      width: 150,
    },
    {
      headerName: '客户付费金额',
      field: 'customerPurchaseAmount',
      width: 130,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '保险公司付费金额',
      field: 'insurancePurchaseAmount',
      width: 136,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '总金额',
      field: 'grossAmount',
      width: 130,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '创建当月总金额',
      field: 'currentMonthCreatedTotalAmount',
      width: 136,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '总金额更新时间',
      field: 'totalAmountUpdateTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '流失原因',
      field: 'lostReasonDescription',
      width: 125,
    },
    {
      headerName: '流失原因备注',
      field: 'lostDetail',
      width: 240,
    },
    {
      headerName: '流失时间',
      field: 'lostTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '自动关闭时间',
      field: 'autoClosedTime',
      width: 160,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '短信内容',
      field: 'smsContent',
      width: 280,
    },
    {
      headerName: '短信接收时间',
      field: 'smsCreatedTimes',
      width: 184,
      valueFormatter: localDateTimeListFormatter,
      cellRendererFramework: MultipleLinesEllipsisRenderComponent,
      cellRendererParams: {
        getContent: localDateTimeListFormatter,
        lineClamp: 1,
      },
    },
    {
      headerName: '作废情况',
      field: 'discardApplicationStatus',
      width: 125,
      valueFormatter: ({ value }) => findAbbreviationByValue(ACCIDENT_CAR_LEAD_DISCARD)(value),
    },
    {
      headerName: '作废原因',
      field: 'discardApplicationReason',
      width: 180,
    },
    {
      headerName: '审核备注',
      field: 'discardApplicationComment',
      width: 180,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'customerFullName',
      label: '报案人',
      placeholder: '',
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'plateNo',
      label: '车牌号',
      placeholder: '',
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'vin',
      label: '底盘号',
      maxLength: 17,
      placeholder: '请输入完整的底盘号',
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'insuranceCaseNo',
      label: '报案号',
      placeholder: '至少输入后四位',
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'orderNumber',
      label: '订单号',
      placeholder: '',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'leadSource',
      label: '线索渠道',
      options: ACCIDENT_CAR_LEAD_SOURCE_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_SOURCE_OPTIONS.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'insuranceCompanyName',
      label: '保险公司',
      options: ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_OPTIONS.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'selfInsurance',
      label: '保险类别',
      options: ACCIDENT_CAR_LEAD_SELF_INSURANCE_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_SELF_INSURANCE_OPTIONS.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'customerType',
      label: '客户类别',
      options: ACCIDENT_CAR_LEAD_CUSTOMER_TYPE_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_CUSTOMER_TYPE_OPTIONS.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'status',
      label: '线索状态',
      options: ACCIDENT_CAR_LEAD_STATUS_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_STATUS_OPTIONS.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.LEAD_LOST_REASON_DROPDOWN,
      name: 'lostReason',
      label: '流失原因',
      options: ACCIDENT_CAR_LEAD_LOST_REASON_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_LOST_REASON_OPTIONS.get('ALL'),
      keyForOptionText: 'lostReasonDescription',
      keyForOptionValue: 'lostReason',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'mbSmsTag',
      label: '钣喷维修专享礼',
      options: ACCIDENT_CAR_LEAD_MB_SMS_TAG_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_MB_SMS_TAG_OPTIONS.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.MULTI_DROPDOWN,
      name: 'consolidation',
      label: '合并情况',
      options: ACCIDENT_CAR_LEAD_CONSOLIDATION_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_CONSOLIDATION_INIT,
      placeholder: '请选择',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'discardApplicationStatus',
      label: '作废情况',
      options: ACCIDENT_CAR_LEAD_DISCARD_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_DISCARD_OPTIONS.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'isFieldSpecialist',
      label: '是否分配外勤专员',
      options: ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE.values,
      defaultValue: ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'isArrived',
      label: '是否已到店',
      options: ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE.values,
      defaultValue: ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'isRepaired',
      label: '是否已维修',
      options: ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE.values,
      defaultValue: ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'createdTime',
      label: '线索创建时间',
      isRangeLimited: true,
      limitValueRange: 12,
      startLimitValue: moment(),
      endLimitValue: moment(),
      endMaxLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'arrivedTime',
      label: '到店时间',
      isRangeLimited: true,
      limitValueRange: 12,
      startLimitValue: moment(),
      endLimitValue: moment(),
      endMaxLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleBusinessType',
      label: '车辆类型',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
      className: 'vehicle-business-type',
      visible: (currentUserService) => !currentUserService.isRoleCode(USER_ROLE.VAN_OEM_ACCIDENT_LEADS.value),
    },
  ],
};

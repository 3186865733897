import { SupplierNo } from '../models/supplier-no';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GET, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SupplierNoApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/accounting/masterdata/supplier-nos/with-dealer-auth')
  getSupplierNos(@Query('dealerId') dealerId: string): Observable<SupplierNo[]> {
    return null;
  }
}

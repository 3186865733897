import { Injectable } from '@angular/core';
import { OrderCancelCommentApi } from '../api/order-cancel-comment';
import { Observable } from 'rxjs';
import { TokenService } from '@otr/website-common';

@Injectable()
export class OrderCancelCommentService {
  constructor(private orderCancelCommentApi: OrderCancelCommentApi,
              private tokenService: TokenService) {
  }
  getOrderCancelComments(): Observable<any> {
    this.tokenService.accessToken = this.tokenService.superAdminToken ? this.tokenService.superAdminToken : this.tokenService.accessToken;
    return this.orderCancelCommentApi.getOrderCancelComments();
  }

  saveCancelComments(orderCancelComments) {
    return this.orderCancelCommentApi.saveCancelComments({
      cancelComments: orderCancelComments,
    });
  }

  saveGroupCancelComments(orderCancelComments) {
    return this.orderCancelCommentApi.saveGroupCancelComments({
      cancelComments: orderCancelComments,
    });
  }
}

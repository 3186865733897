import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

const getValue = (value, params) =>
  _.isFunction(value) ? value.call(null, params) : value;

@Component({
  template: `
    <div class="custom-link-list">
      <div class="custom-link-list-item" *ngFor="let item of data[columnName]" (click)="onClick(item)">
        <span class="clickable-text" [title]="displayKey ? item[displayKey] : item">{{displayKey ? item[displayKey] : item}}</span>
      </div>
    </div>
  `,
  styleUrls: ['./custom-link-list-cell.style.scss'],
})
export class CustomLinkListCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  data: any[];
  columnName: string;
  displayKey: string | boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.displayKey = getValue(this.cellRendererParams.displayKey, params) || false;
    this.data = params.node.data;
    this.columnName = params.colDef.field;
  }

  refresh(params: any): boolean {
    return false;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onClick(item: any): void {
    this.cellRendererParams.onClick(this.params.node.data, item);
  }
}

import { OrderStatusPipe } from './order-status.pipe';
import { OrderHistoryStatusPipe } from './order-history-status.pipe';
import {
  AbsolutePipe,
  DividePipe,
  RoundingPipe,
  NumberPipe,
  PercentagePipe,
  CalcPercentagePipe,
  PricePipe,
  CostPipe,
  EmptyOrZeroPipe,
  FixNumberPipe,
  EmptyToDashPipe,
  PercentageOrEmptyPipe,
  PriceOrEmptyPipe
} from './number.pipe';
import { ConflictTextPipe } from './conflict-text.pipe';
import { ApprovalTextPipe } from './approval-text.pipe';
import { PostfixPipe } from './postfix.pipe';
import {
  DefaultToPipe,
  EveryPipe,
  FindAndGetPipe,
  FindPipe,
  GetPipe,
  IsEmptyPipe,
  IsNilPipe,
  KeysPipe,
  PickPipe,
  TruncatePipe,
} from './lodash.pipe';
import { DateObjectPipe } from './date-object.pipe';
import { ErrorMessagesPipe } from './error-message.pipe';
import { CustomizeDatePipe } from './customize-date.pipe';
import { StayTimePipe } from './stay-time.pipe';
import { DateFormatPipe, MinutesPipe } from './minutes.pipe';
import { LeadChannelPipe } from './lead-channel.pipe';
import { FirstVisitTextPipe } from './first-visit-text.pipe';
import { DateParserPipe } from './date-parser.pipe';
import { PackageDescriptionPipe } from './package-description.pipe';
import { MessageTypePipe } from './message-type.pipe';
import { MessagePreLinkPipe } from './message-prelink.pipe';
import { BranchLocationDescriptionPipe } from './branch-location-description.pipe';
import { VehicleLocationDescriptionPipe } from './vehicle-location-description.pipe';
import { VehicleAgePipe, VehicleUnitPipe } from './vehicle-age.pipe';
import { MatchingTextDisplayPipe } from './matching-text-display.pipe';
import { ToFixedPipe } from './to-fixed.pipe';
import { GroupTypePipe } from './group-type.pipe';
import { LowercaseBrand } from 'app/_common/pipes/lowercase-smart.pipe';
import { DealerRangePipe } from './dealer-range.pipe';
import { LeadStatusPipe } from './lead-status.pipe';
import { GenderPipe } from './gender.pipe';
import { CertificateTypePipe } from './certificate-type.pipe';
import {
  EncryptedVinOrCarPlateNumberPipe,
  FullNameCombiner,
  StringDisplayFormatPipe,
  StringEmptyToDash,
} from './string-display-format.pipe';
import { OptionPipe } from './option.pipe';
import { HighlightPipe } from './highlight-text.pipe';
import { JobCardStatusPipe } from './job-card.pipe';
import { FormatDealerPipe } from './format-dealer.pipe';
import { DecimalToPercentPipe } from './decimal-to-percent.pipe';
import { AmountFormatPipe } from './amount-format.pipe';
import { vehicleFilterPipe } from './vehicle-filter-show.pipe';
import { ScoringFormatPipe } from './scoring-format.pipe';
import { DistanceFormatPipe } from './distance-format.pipe';
export const PIPE_PROVIDERS = [
  FirstVisitTextPipe,
  LeadChannelPipe,
  MinutesPipe,
  DateFormatPipe,
  StayTimePipe,
  CustomizeDatePipe,
  LowercaseBrand,
  ErrorMessagesPipe,
  DateObjectPipe,
  PickPipe,
  GetPipe,
  TruncatePipe,
  PostfixPipe,
  ApprovalTextPipe,
  ConflictTextPipe,
  NumberPipe,
  PercentagePipe,
  CalcPercentagePipe,
  OrderStatusPipe,
  OrderHistoryStatusPipe,
  DateParserPipe,
  PackageDescriptionPipe,
  MessageTypePipe,
  MessagePreLinkPipe,
  BranchLocationDescriptionPipe,
  VehicleLocationDescriptionPipe,
  VehicleAgePipe,
  VehicleUnitPipe,
  ToFixedPipe,
  MatchingTextDisplayPipe,
  EveryPipe,
  GroupTypePipe,
  KeysPipe,
  FindPipe,
  FindAndGetPipe,
  DefaultToPipe,
  IsEmptyPipe,
  IsNilPipe,
  DividePipe,
  RoundingPipe,
  DealerRangePipe,
  LeadStatusPipe,
  GenderPipe,
  CertificateTypePipe,
  StringDisplayFormatPipe,
  FullNameCombiner,
  StringEmptyToDash,
  EncryptedVinOrCarPlateNumberPipe,
  OptionPipe,
  AbsolutePipe,
  PricePipe,
  HighlightPipe,
  CostPipe,
  EmptyOrZeroPipe,
  FixNumberPipe,
  JobCardStatusPipe,
  EmptyToDashPipe,
  FormatDealerPipe,
  DecimalToPercentPipe,
  AmountFormatPipe,
  vehicleFilterPipe,
  ScoringFormatPipe,
  DistanceFormatPipe,
  PercentageOrEmptyPipe,
  PriceOrEmptyPipe
];

import * as _ from 'lodash';
import { ORDER_STATUS_WITH_EDITABLE_TEMPORARY_RESERVED, VEHICLE_CHANNEL_STATUS } from './constants/vehicle';
const getMBEStatusAndMessage = vehicle => {
  const channelStatus = _.get(
    VEHICLE_CHANNEL_STATUS,
    _.get(vehicle, 'channelStatus.mbeChannelStatus'),
    VEHICLE_CHANNEL_STATUS.IS_UNAVAILABLE,
  );
  const errorMessage = _.get(vehicle, 'channelStatus.mbeFailureMessage', '');
  return {
    status: channelStatus,
    message: errorMessage,
  };
};

const isEditableOrder = vehicle => {
  const orderStatus = _.get(vehicle, 'order.order_status', '');
  return _.includes(ORDER_STATUS_WITH_EDITABLE_TEMPORARY_RESERVED, orderStatus && orderStatus.toLowerCase());
};

export const vehicleUtils = {
  getMBEStatusAndMessage,
  isEditableOrder,
};

export const formatFilterValue = (v, list) => {
  const commissionArr = [];
  v.forEach(item => {
    for (const i in list) {
      if (item === list[i].value) {
        commissionArr.push(list[i].text);
      }
    }
  });
  return commissionArr;
};

export const formatFilterText = (v, obj) => {
  const commissionArr = [];
  v.forEach(item => {
    commissionArr.push(obj[item]);
  });
  return commissionArr;
};

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { PromptBarService } from '@otr/website-common';
import { ERROR_MESSAGES } from '../pipes/error-message.pipe';
import { PromptLevel } from '../constants/common';
import { isNil, toNumber } from 'lodash';

@Injectable()
export class HttpTimeoutInterceptor implements HttpInterceptor {
  private DEFAULT_TIMEOUT = 3 * 60 * 1000;
  private TIMEOUT_ERROR = 'TimeoutError';

  constructor(private promptBarService: PromptBarService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const shouldIgnoreTimeout = request.headers.get('isExportFile')
      || request.headers.get('x-ignore-timeout');
    const timeoutValue = isNil(request.headers.get('timeout')) ? this.DEFAULT_TIMEOUT : toNumber(request.headers.get('timeout'));
    return shouldIgnoreTimeout
      ? next.handle(request)
      : next.handle(request).pipe(
      timeout(timeoutValue),
      catchError(err => {
        if (err.name === this.TIMEOUT_ERROR) {
          this.promptBarService.show(ERROR_MESSAGES.gateway_timeout, PromptLevel.Warning);
        }
        return throwError(err);
      }),
    );
  }
}

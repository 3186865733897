import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  templateUrl: 'header-editor.template.html',
  styleUrls: ['header-editor.style.scss'],
})
export class HeaderEditorComponent implements IHeaderAngularComp {
  params: IHeaderParams;
  headerEditorParams: any;

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.headerEditorParams = _.get(this.params.column.getColDef(), 'headerEditorParams');
  }

  onChange(event) {
    this.headerEditorParams.onChange(event.target.value);
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';
import { ACCEPTABLE_LEAD_TYPE, LEAD_TYPES } from '../../../../constants/lead';

import { DORMANT_CHANNEL } from '../../../../utils/lead-detail-helper';
import { CurrentUserService } from '@otr/website-common';

@Component({
  templateUrl: './sales-lead-list-action-cell-render.template.html',
  styleUrls: ['./sales-lead-list-action-cell-render.style.scss'],
})
export class AgentLeadListActionCellRenderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  operationOptions: Array<any>;

  actionsConfig = [
    {
      icon: () => '/assets/common/edit-blue.svg',
      visible: () => this.isMyOpenLeadHasNoOwnerSC,
      onClick: () => this.cellRendererParams.editLead(this.params.data),
      hoverText: '编辑',
    },
    {
      icon: () => '/assets/lead-management/recall.svg',
      visible: () => this.isMyLeadLeadHasOwnerSC || this.isMyDormantLeadHasOwnerSC,
      onClick: () => this.cellRendererParams.onRecallLead(this.params.data),
      hoverText: '撤回',
    },
    {
      icon: () => '/assets/common/calendar-blue.svg',
      visible: () => this.isMyOpenLeadHasNoOwnerSC || this.isMyDormantLeadHasOwnerSC,
      onClick: () => this.cellRendererParams.addFollowUpWithLeadId(this.params.data),
      hoverText: '跟进',
    },
  ];

  constructor(private currentUserService: CurrentUserService) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.operationOptions = [
      {
        text: '分配销售顾问',
        isVisible: this.isMyOpenLeadHasNoOwnerSC,
        handler: () => this.cellRendererParams.onAssignSc(this.params.data),
      },
      {
        text: '激活线索',
        isVisible: this.isMyLostSalesLead,
        handler: () => this.cellRendererParams.onReactivateLead(this.params.data),
      },
      {
        text: '关闭线索',
        isVisible: this.isMyOpenLeadHasNoOwnerSC,
        handler: () => this.cellRendererParams.onCloseLead(this.params.data),
      },
      {
        text: '分享线索',
        isVisible: this.isMyOwnOpenCecLead,
        handler: () => this.cellRendererParams.onShareLead(this.params.data, true),
      },
      { text: '分配客户发展专员', isVisible: false, handler: () => {} },
    ];
    this.actionsConfig = _.filter(this.actionsConfig, ({ visible }) => visible());
  }

  refresh(params: any): boolean {
    return false;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  get isOpenLead() {
    return _.includes(ACCEPTABLE_LEAD_TYPE, _.lowerCase(this.params.data.leadStatus));
  }

  get scName() {
    return this.params.data.scName;
  }

  get hasAssignedSC() {
    return !_.isEmpty(_.get(this.params.data, 'ownerSalesConsultantId', ''));
  }

  get isOwnerCDO() {
    return this.params.data.cdoId === this.currentUserService.getCurrentUser().userId;
  }

  get leadStatusIsLead() {
    return _.lowerCase(this.params.data.leadStatus) === LEAD_TYPES.LEAD;
  }

  get isLostSalesLead() {
    return _.lowerCase(_.get(this.params, 'data.leadStatus', '')) === LEAD_TYPES.LOST_SALES;
  }

  get isDormantLead() {
    return _.get(this.params.data, 'currentChannel') === DORMANT_CHANNEL.value;
  }

  get isMyOpenLeadHasNoOwnerSC() {
    return this.isOpenLead && this.isOwnerCDO && !this.hasAssignedSC;
  }

  get isMyOwnOpenCecLead() {
    return this.isMyOpenLeadHasNoOwnerSC
      && this.currentUserService.getBranchDealerIdOrDealerId() === this.params.data.dealerId
      && !_.isNil(this.currentUserService.getCurrentUser().cecId);
  }

  get isMyDormantLeadHasOwnerSC() {
    return this.isOpenLead && this.isDormantLead && this.isOwnerCDO && this.hasAssignedSC;
  }

  get isMyLeadLeadHasOwnerSC() {
    return this.leadStatusIsLead && this.isOwnerCDO && this.hasAssignedSC;
  }

  get isMyLostSalesLead() {
    return this.isLostSalesLead && this.isOwnerCDO;
  }

  showMoreOperation() {
    return _.some(this.operationOptions, 'isVisible');
  }
}

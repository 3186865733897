import * as _ from 'lodash';
import { Component } from '@angular/core';
import { IAfterGuiAttachedParams, IFilterParams } from 'ag-grid';

import { BasePaginationFilterComponent } from '../base-pagination-filter.component';

interface IOption {
  text: string;
  value: any;
}

@Component({
  selector: 'app-pagination-select',
  templateUrl: './pagination-select.component.html',
  styleUrls: ['../pagination-grid-filter.style.scss', './pagination-select.component.scss'],
})
export class PaginationSelectComponent extends BasePaginationFilterComponent {
  options: IOption[] = [];
  autoOpen = false;
  filters = [];
  savedFilters = [];

  agInit(params: IFilterParams) {
    super.agInit(params);
    const { options, autoOpen } = this.params.colDef.filterParams;
    this.options = options;
    if (autoOpen) {
      this.autoOpen = autoOpen;
    }
  }

  afterGuiAttached(params: IAfterGuiAttachedParams) {
    super.afterGuiAttached(params);
    this.filters = [...this.savedFilters];
  }

  setDefaultValue() {
    super.setDefaultValue();
    this.filters = [];
    this.savedFilters = [];
  }

  getValues(options: IOption[]) {
    return _.flattenDeep(_.map(options, option => {
      if (_.isArray(option.value)) {
        return this.getValues(option.value);
      }
      return option.value;
    }));
  }

  onSubmit() {
    super.hideFilterMenu();
    this.savedFilters = [...this.filters];
    this.params.filterChangedCallback();
  }

  onCancel() {
    super.hideFilterMenu();
    this.filters = [...this.savedFilters];
  }

  isFilterActive() {
    return this.savedFilters.length && this.savedFilters.length < this.getValues(this.options).length;
  }

  onSelect(event: any, value?: any) {
    if (event.target.tagName.toLowerCase() !== 'input') {
      event.preventDefault();
    }
    const checked = !this.isChecked(value);
    if (value === undefined) {
      this.filters = checked ? this.getValues(this.options) : [];
      return;
    }
    if (_.isArray(value)) {
      const values = this.getValues(value);
      this.filters = checked ? _.concat(this.filters, values) : _.without(this.filters, ...values);
      return;
    }
    this.filters = checked ? _.concat(this.filters, value) : _.without(this.filters, value);
  }

  isChecked(value?: any) {
    if (value === undefined) {
      return this.filters.length === this.getValues(this.options).length;
    }
    if (_.isArray(value)) {
      return _.every(this.getValues(value), item => _.includes(this.filters, item));
    }
    return _.includes(this.filters, value);
  }

  isArray(value: any) {
    return _.isArray(value);
  }

  setModel(model: any) {
    this.savedFilters = _.isArray(model) ? [...model] : [];
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    return this.modelFormatter([...this.savedFilters]);
  }
}

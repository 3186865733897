import * as _ from 'lodash';
import { USER_ROLE } from './user-role.constant';
import { OrderDocument } from '../models/order';
import { KeyValue } from '../models/common';
import { toEnum } from '../utils/enum';

export const DEFAULT_DOCUMENTS: OrderDocument[] = [
  { type: 'LOI_REFUNDABLE', text: '预览订购意向书', active: false },
  { type: 'LOI_NONREFUNDABLE', text: '预览定金合同', active: false },
  { type: 'CONTRACT', text: '预览销售合同', active: false },
];

export const APPROVAL_TYPES = {
  CONTRACT: { value: 'CONTRACT', text: '销售合同' },
  LOI_REFUNDABLE: { value: 'LOI_REFUNDABLE', text: '订购意向书' },
  LOI_NONREFUNDABLE: { value: 'LOI_NONREFUNDABLE', text: '定金合同' },
  INVOICE: { value: 'INVOICE', text: '开票' },
  DELIVERY: { value: 'DELIVERY', text: '交车' },
  MODIFY: { value: 'MODIFY', text: '修改' },
  CANCEL_BEFORE_INVOICED: { value: 'CANCEL_BEFORE_INVOICED', text: '取消（开票前）' },
  CANCEL_AFTER_INVOICED: { value: 'CANCEL_AFTER_INVOICED', text: '取消（开票后）' },
};

export const APPROVAL_CANCEL_TYPES = [
  APPROVAL_TYPES.CANCEL_BEFORE_INVOICED.value,
  APPROVAL_TYPES.CANCEL_AFTER_INVOICED.value,
];

export const CHANNELS = {
  WALK_IN: { value: 'Walk-in', text: '散客' },
  INBOUND: { value: 'Inbound', text: '呼入' },
  WEB: { value: 'Web', text: '网络' },
  MARKETING_ACQUISITION: { value: 'Marketing Acquisition', text: '主动集客' },
  REPURCHASE: { value: 'Repurchase', text: '再购' },
  REFERRAL: { value: 'Referral', text: '推荐' },
  ACTIVE: { value: 'Active', text: '活跃' },
  DORMANT: { value: 'Dormant', text: '休眠' },
};

export const LEAD_CHANNELS: KeyValue[] = _.values(CHANNELS);

export const LEAD_CHANNELS_OPTIONS: KeyValue[] = [CHANNELS.WALK_IN, CHANNELS.REPURCHASE];

export const CDO_LEAD_CHANNELS_OPTIONS: KeyValue[] = [
  CHANNELS.INBOUND,
  CHANNELS.REFERRAL,
  CHANNELS.REPURCHASE,
  CHANNELS.WEB,
];

export const E_COMMERCE_LEAD_TYPE = {
  type: 'E-Commerce',
};

export const INVOICE_TYPE = {
  PERSON: { text: '个人', value: 'PERSON' },
  COMPANY: { text: '公司', value: 'COMPANY' },
};

export const VEHICLE_VISIBILITY = {
  VISIBLE: 'Yes',
  INVISIBLE: 'No',
};

export const APPROVAL_STATUS = {
  PENDING: { value: 'PENDING', text: '待审批' },
  APPROVED: { value: 'APPROVED', text: '已批准' },
  REJECTED: { value: 'REJECTED', text: '已驳回' },
};

export const CONTRACT_TYPE: KeyValue[] = [
  { value: 'contract', text: '销售合同' },
  { value: 'loi_refundable', text: '订购意向书' },
  { value: 'loi_nonrefundable', text: '定金合同' },
];

export const FREEBIE_TYPE = {
  SALES: {
    value: 'Sales',
    text: '销售',
  },
  MARKETING: {
    value: 'Marketing',
    text: '市场',
  },
};
export const FINANCIAL_PRODUCT_TYPE = {
  BANKING: {
    value: 'BANKING',
    text: '银行产品',
  },
  MERCEDES: {
    value: 'MERCEDES',
    text: '奔驰金融',
  },
  DEALER_GROUP: {
    value: 'DEALER_GROUP',
    text: '经销商集团银行',
  },
};

export const INSURANCE_TYPE = {
  COMPULSORY: {
    value: 'COMPULSORY',
    text: '交强险',
  },
  COMMERCIAL: {
    value: 'COMMERCIAL',
    text: '商业险',
  },
};

export const FINANCIAL_PRODUCT_PURPOSE = {
  VEHICLE: {
    value: 'VEHICLE',
    text: '车辆',
  },
  NON_VEHICLE: {
    value: 'NON_VEHICLE',
    text: '非车',
  },
};

export const YEAR_VALUE = [
  {
    extensionYear: 1,
    text: '第一年',
  },
  {
    extensionYear: 2,
    text: '第二年',
  },
  {
    extensionYear: 3,
    text: '第三年',
  },
];

export const FILTER_COLLAPSE_TEXT = {
  COLLAPSED: '展开',
  EXPANDED: '收起',
};

export interface ModalConfig {
  ignoreBackdropClick: boolean;
  keyboard: boolean;
  ignoreMessage: boolean;
}

export const MODAL_CONFIG: ModalConfig = {
  ignoreBackdropClick: true,
  keyboard: false,
  ignoreMessage: false,
};

export const TOKEN_NAME = 'token';

export const TEST_DRIVE_STATUS = {
  FINISHED: 'FINISHED',
  UNFINISHED: 'UNFINISHED',
  NONE: 'NONE',
};

export const enum PromptLevel {
  Success,
  Info,
  Warning,
  Error,
  Emergency,
}

export const enum CtiLoginType {
  Mobile,
  Sip,
  WebCall,
}

export const SATISFACTION = [
  { text: '5. Very Satisfied', value: '5. 非常满意' },
  { text: '4. Satisfied', value: '4. 满意' },
  { text: '3. Neutral', value: '3. 一般' },
  { text: '2. Not Satisfied', value: '2. 不满意' },
  { text: '1. Severely Unsatisfied', value: '1. 非常不满意' },
  { text: 'No Answer', value: '无评分' },
];

export const YESORNO = [
  { text: '是', value: 'Yes' },
  { text: '否', value: 'No' },
];

export const ONE_DAY_MILLISECONDS = 86400000;

export const NUMBER_KEY_CODE_RANGE = _.range(48, 58);

export const ARROW_KEY_CODE_RANGE = _.range(37, 41);

export const BACKSPACE_KEY_CODE = 8;

export const ENTER_KEY_CODE = 13;

export const TAB_KEY_CODE = 9;

export const TIME_PICKER_SUPPORTED_KEY_CODE = [
  ...NUMBER_KEY_CODE_RANGE,
  ...ARROW_KEY_CODE_RANGE,
  BACKSPACE_KEY_CODE,
  ENTER_KEY_CODE,
  TAB_KEY_CODE,
];

export const ARROW_DOWN = 'ArrowDown';
export const ARROW_UP = 'ArrowUp';

export const INTEGRATION_FINANCIAL_STATUS = [
  { text: '已批复', value: 'Approved' },
  { text: '条件批复关闭', value: 'Conditioned Closed' },
  { text: '已拒绝', value: 'Declined' },
  { text: '已撤回', value: 'Withdrawn' },
  { text: '已激活', value: 'Activated' },
  { text: '取消申请', value: 'Cancelled' },
  { text: '贷款已到账', value: 'Loan Received' },
  { text: '条批完成', value: 'Condition Fulfilled/Waived' },
  { text: '条批拒绝', value: 'ConditionedFailed' },
];

export const NUMBER_VALID_RANGE_REGEXP = /^\d{0,8}\.?\d{0,2}$/;
export const NUMBER_VALID_INTEGER_RANGE_REGEXP = /^\d{0,8}(\.\d+)?$/;
export const NUMBER_REGEXP = /^\d+(\.\d+)?$/;
export const NEGATIVE_NUMBER_REGEXP = /^-?\d+(\.\d+)?$/;
export const NUMBER_ONE_DECIMAL_REGEXP = /^(0|[1-9]\d*)(\.\d)?$/;
export const NUMBER_TWO_DECIMAL_REGEXP = /^(0|[1-9]\d*)(\.\d{1,2})?$/;
export const NUMBER_WITHOUT_ZERO_TWO_DECIMAL_REGEXP = /^-?(?!0\d|[0.]+$)\d+(\.\d{1,2})?$/;
export const NUMBER_ALLOWED_KEY = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace'];
export const NUMBER_INTEGER_ALLOWED_KEY = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
export const MINUS_NUMBER_ALLOWED_KEY = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', '-'];
export const NUMBER_INTEGER_FROM_1_TO_99 = /^(0?[1-9]|0?[1-9][0-9])$/;
export const PERCENTAGE_REGEXP = /^(100(\.0*)?|([0-9]\d?)(\.\d*)?|(0\.\d*[0-9]+\d*))$/;
export const BAUMUSTER_NST_REGEXP = /^\d{7}-[a-zA-Z\d]{3}$/;
export const CHANGE_YEAR_REGEXP = /^\d{3}$/;
export const NATURAL_NUMBER_REGEXP = /^\d+(\.0*)?$/;
export const CERTIFICATE_NUMBER_REGEXP = /^\d{17}[\d|x|X]$/;
export const HK_IDENTITY_CARD_REGEXP = /^[a-zA-Z0-9()（）]{1,11}$/;
export const FILE_SUFFIX_REGEXP = /(\.[^/.]+)$/;
export const EMAIL_REGEXP = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.' +
  '[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
);
export const MOBILE_REGEXP = /^1\d{10}$/;
export const CHINESE_REGEXP = /[^\x00-\xff]/;
export const STRING_AND_NUMBER_REGEXP = /^[a-zA-Z0-9]$/;
export const STRING_AND_NUMBER_REGEXP_ALL = /^[a-zA-Z0-9]*/;
export const STRING_START_WITH_CHINESE_REGEXP = /^[\u4E00-\u9FFF]/;

export const LEAD_VALID_MONTH = toEnum({
  TWO_MONTHS: { value: 2, text: '2个月' },
  THREE_MONTHS: { value: 3, text: '3个月' },
  FOUR_MONTHS: { value: 4, text: '4个月' },
  FIVE_MONTHS: { value: 5, text: '5个月' },
  SIX_MONTHS: { value: 6, text: '6个月' },
  FOREVER: { value: 0, text: '永不关闭' },
});

export const LEAD_FOLLOW_UP_MONTH = toEnum({
  SIX_MONTHS: { value: 6, text: '6个月' },
  SEVEN_MONTHS: { value: 7, text: '7个月' },
  EIGHT_MONTHS: { value: 8, text: '8个月' },
  NINE_MONTHS: { value: 9, text: '9个月' },
  TEN_MONTHS: { value: 10, text: '10个月' },
  ELEVEN_MONTHS: { value: 11, text: '11个月' },
  TWELVE_MONTHS: { value: 12, text: '12个月' },
  FOREVER: { value: 0, text: '永不关闭' },
});

export const BOOLEAN_OPTIONS = toEnum({
  TRUE: { text: '是', value: true },
  FALSE: { text: '否', value: false },
});

export const SUPPORT_OPTIONS = toEnum({
  TRUE: { text: '支持', value: true },
  FALSE: { text: '不支持', value: false },
});

export const EMPTY_OPTION = { text: '(无)', value: null };

/* tslint:disable */
export const EMOJI_REGEXP: RegExp = new RegExp(
  '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])',
  'g',
);
/* tslint:enable */

export const FILE_CATEGORY = {
  NORMAL: 'NORMAL',
  MEDIA: 'MIDEA',
};

export const LEAD_STATUS = toEnum({
  LEAD_CANDIDATE: { value: 'Lead Candidate', text: '潜在线索' },
  LEAD: { value: 'Lead', text: '未关闭' },
  LOST_SALES: { value: 'Lost Sales', text: '失销' },
  UNSUCCESSFUL: { value: 'Unsuccessful', text: '战败关闭' },
  SUCCESSFUL: { value: 'Successful', text: '成功关闭' },
});

export const USED_CAR_LEAD_STATUS = toEnum({
  LEAD: { value: 'Lead', text: '未关闭' },
  UNSUCCESSFUL: { value: 'Unsuccessful', text: '战败关闭' },
  SUCCESSFUL: { value: 'Successful', text: '成功关闭' },
});

export const CERTIFICATE_TYPE = {
  null: { text: '', value: null },
  IDENTITY_CARD: { text: '身份证', value: 'IDENTITY_CARD' },
  PASSPORT: { text: '护照', value: 'PASSPORT' },
  OFFICER_CERTIFICATE: { text: '军官证', value: 'OFFICER_CERTIFICATE' },
  HK_IDENTITY_CARD: { text: '港澳居住证', value: 'HK_IDENTITY_CARD' },
  TW_IDENTITY_CARD: { text: '台湾居住证', value: 'TW_IDENTITY_CARD' },
};

export const SETTLEMENT_TYPE = {
  SERVICE_FEE: { text: '服务费', value: 'SERVICE_FEE' },
  COMMISSION_FEE: { text: '佣金', value: 'COMMISSION_FEE' },
};

export const CERTIFICATE_NUMBER_VALIDATOR = {
  [CERTIFICATE_TYPE.IDENTITY_CARD.value]: value => CERTIFICATE_NUMBER_REGEXP.test(value),
  [CERTIFICATE_TYPE.HK_IDENTITY_CARD.value]: value => HK_IDENTITY_CARD_REGEXP.test(value),
};

export const MOD_CERTIFICATE_NUMBER_VALIDATOR = {
  [CERTIFICATE_TYPE.IDENTITY_CARD.value]: value => CERTIFICATE_NUMBER_REGEXP.test(value),
};

export const DUPLICATE_LEAD_MSG = {
  SC_MSG: { text: '已分配销售顾问', value: '已有所属销售顾问，如需继续跟进，请撤回后再操作。' },
  CDO_MSG: { text: '已分配客户发展专员', value: '已有所属客户发展专员，如需继续跟进，请联系客户发展经理重新分配。' },
  UE_MSG: { text: '已分配二手车评估师', value: '已有所属已分配二手车评估师，如需继续跟进，请联系二手车经理重新分配。' },
};

export const FILTER_TYPE = {
  NONE: 'NONE',
  NORMAL: 'NORMAL',
  // Bad filter type, because calendar filter should be independent， should not merged into other field.
  CALENDAR_RANGE: 'CALENDAR_RANGE',
};

export const VAT_INCLUDED_PRICE_RATE = 0.13;

const DIVISION_AND_GROUP_UNEDITABLE_ROLE = [
  USER_ROLE.UE.value,
  USER_ROLE.UM.value,
  USER_ROLE.SMMO.value,
  USER_ROLE.ASMO.value,
  USER_ROLE.CMO.value,
  USER_ROLE.SCDO.value,
  USER_ROLE.CDM.value,
  USER_ROLE.CRM.value,
  USER_ROLE.DGMM.value,
];

export const GROUP_UNEDITABLE_ROLE = [
  ...DIVISION_AND_GROUP_UNEDITABLE_ROLE,
];

export const DIVISION_UNEDITABLE_ROLE = [
  ...DIVISION_AND_GROUP_UNEDITABLE_ROLE,
  USER_ROLE.CO.value,
  USER_ROLE.IK.value,
];

export const DEALER_ROLE = [
  USER_ROLE.SCDO.value,
  USER_ROLE.CDM.value,
  USER_ROLE.CO.value,
  USER_ROLE.CRM.value,
];

export const CASE_ROLE = [
  USER_ROLE.RE.value,
  USER_ROLE.CO.value,
];

export const DEALER_GROUP = [
  'dealer',
  'branch',
];

export const GROUP_NON_SALES_ROLE = [
  USER_ROLE.UE.value,
  USER_ROLE.UM.value,
  USER_ROLE.SMMO.value,
  USER_ROLE.ASMO.value,
  USER_ROLE.CMO.value,
];

export const NON_DIVISION_ROLE = [
  USER_ROLE.IK.value,
];

export const REDIRECTION_URLS = {
  DOCTOR_SYSTEM: {
    url: 'https://daimler.service-now.com/',
    urlText: '提票',
  },
};

export const SALUTATION = toEnum({
  Mr: { text: '先生' },
  Ms: { text: '女士' },
});

export const PREFERRED_CONTACT_TIME = toEnum({
  WORKDAY: { text: '工作日' },
  WEEKEND: { text: '周末' },
  ANYTIME: { text: '随时联络' },
  ON_HOLD: { text: '暂不联络' },
});

export const CURRENT_VEHICLE_ASSESSMENT_OPTION = toEnum({
  TRUE: { value: '1', text: '是' },
  FALSE: { value: '0', text: '否' },
});

export const CURRENT_VEHICLE_EDIT_OPTION = toEnum({
  TRUE: { value: true, text: '是' },
  FALSE: { value: false, text: '否' },
});

export const PREFERRED_CONTACT_CHANNEL = toEnum({
  TEL: { text: '电话' },
  SMS: { text: '短信' },
  WECHAT: { text: '微信' },
});

export const FAMILY_MEMBER_COUNT = toEnum({
  1: { text: '1位' },
  2: { text: '2位' },
  3: { text: '3位' },
  4: { text: '4位' },
  5: { text: '5位' },
  '>5': { text: '5位以上' },
});

export const ZERO_TIME_ZONE_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ssZ';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const MONTH_FORMAT = 'YYYY-MM';
export const TIME_FORMAT = 'HH:mm';

export const ALL_OPTION: KeyValue = {
  value: 'ALL',
  text: '全部',
};

export const CLIENT_TYPE_WEB = 'web';

export const DELETE_CUSTOMER_TYPE = [
  {
    text: '无未关闭线索',
  },
  {
    text: '无新车开票记录',
  },
  {
    text: '无未完成跟进任务',
  },
  {
    text: '无售后工单',
  },
  {
    text: '客户不是车辆的车主、送修人或司机',
  },
];

export const MODAL_SIZE = {
  X_SMALL: 'x-small-modal',
  SMALL: 'small-modal',
  MEDIUM: 'medium-modal',
  LARGE: 'large-modal',
};
export const VEHICLE_PURPOSE = {
  EXHIBIT_VEHICLE: '展车',
  ORDER_VEHICLE: '订单车辆',
  IN_STORE_VEHICLE: '店内车辆',
  SPECIAL_VEHICLE: '特殊车辆',
};
export const SALES_CHANNELS = {
  'Online Sales': '线上销售',
  'Offline to Online Sales': '线下转线上',
};
export const SALES_STATUS = {
  Sold: '订单',
  Unsold: '未售',
};

export const EXCEL_FILE_CONTENT_TYPE = 'application/vnd.ms-excel';

export const KILOMETERS_PER_DAY_KEY = {
  LESS_THAN_TEN: '小于10公里',
  BETWEEN_TEN_AND_TWENTY: '10-20公里',
  BETWEEN_TWENTY_AND_THIRTY: '20-30公里',
  GREATER_THAN_THIRTY: '大于30公里',
};

export const HAS_OR_NOT = {
  TRUE: { value: true, text: '有' },
  FALSE: { value: false, text: '无' },
};

export const PAGE_SIZES = [
  {
    label: '20条/页',
    value: 20,
  },
  {
    label: '50条/页',
    value: 50,
  },
  {
    label: '100条/页',
    value: 100,
  },
];

export interface SelectionOption {
  text: string;
  value: string;
}

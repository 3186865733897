import { take } from 'rxjs/operators';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

import { LeadService } from '../../../customer-lead-management/lead-management/sales-lead/services/lead.service';
import { KeyValue } from '../../models/common';

@Component({
  selector: 'app-region-select',
  templateUrl: './region-select.component.html',
  styleUrls: ['./region-select.component.scss'],
})
export class RegionSelectComponent implements OnInit, OnChanges {

  @Input() regionForm: FormGroup;
  @Input() shouldShowDistrict: boolean = true;
  @Input() required: boolean = false;
  @Input() shouldDisable: boolean = false;

  provinceControl: AbstractControl;
  cityControl: AbstractControl;
  districtControl: AbstractControl;
  selectorClass: any;
  disabled: boolean = false;

  getDistrictText = _.identity;
  provinceOptions$: BehaviorSubject<any> = new BehaviorSubject([]);
  cityOptions$: BehaviorSubject<any> = new BehaviorSubject([]);
  districtOptions$: BehaviorSubject<any> = new BehaviorSubject([]);
  getText = option => option.name;

  constructor(private leadService: LeadService) {
  }

  ngOnInit() {
    this.provinceControl = this.regionForm.controls.province;
    this.cityControl = this.regionForm.controls.city;
    if (this.shouldShowDistrict) {
      this.districtControl = this.regionForm.controls.district;
    }
    this.leadService.fetchRegionsData().pipe(take(1)).subscribe(regionsData => {
      this.provinceOptions$.next(regionsData);
    });
    this.regionForm.controls.province.valueChanges.subscribe(provinceText => this.setCityOptions(provinceText));
    this.regionForm.controls.city.valueChanges.subscribe(cityText => this.setDistrictOptions(cityText));
    if (this.shouldDisable) {
      this.selectorClass = { width: '151px', height: '32px' };
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }

  ngOnChanges() {
    if (this.shouldDisable) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }
  setCityOptions(province) {
    const cityOptions: any = _.chain(this.provinceOptions$.value).find({ name: province }).get('cities', []).value();
    this.cityOptions$.next(cityOptions);
  }

  setDistrictOptions(city) {
    if (this.shouldShowDistrict) {
      const districtOptions = _.chain(this.cityOptions$.value).find({ name: city }).get('districts', []).value();
      this.districtOptions$.next(districtOptions);
    }
  }

  onProvinceSelect(provinceData) {
    this.provinceControl.setValue(provinceData);
    this.cityControl.setValue(null);
    if (this.shouldShowDistrict) {
      this.districtControl.setValue(null);
    }
  }

  onCitySelect(cityData) {
    this.cityControl.setValue(cityData);
    if (this.shouldShowDistrict) {
      this.districtControl.setValue(null);
    }
  }

  onDistrictSelect(cityData) {
    this.districtControl.setValue(cityData);
  }

  selectedOption(value): KeyValue {
    return {
      value,
      text: value,
    };
  }

  isError(control) {
    return this.required && (control.touched || control.dirty) && control.invalid;
  }
}

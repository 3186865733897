import { Injectable } from '@angular/core';
import { GET, Path, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RetentionOfferApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/retention-lead-message/h5/:messageId')
  getRetentionOffer(@Path('messageId') messageId: string): Observable<any> {
    return null;
  }
}

import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'amountFormat' })
export class AmountFormatPipe implements PipeTransform {
  transform(_amount: number): string {
    if (_.isUndefined(_amount) || _.isNull(_amount)) {
      return '';
    }

    return  _amount.toLocaleString('zh', {
      style: 'currency',
      currency: 'CNY',
    });
  }
}

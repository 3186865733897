import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LaborRateApi } from '../apis/labor-rate';
import { LaborRateListItem } from '../../system-config/model/labor-rate';

@Injectable()
export class LaborRateService {
  constructor(private configureLaborRateApi: LaborRateApi) {
  }

  getLaborRates(): Observable<Array<LaborRateListItem>> {
    return this.configureLaborRateApi.getLaborRates();
  }

  updateLaborRate(id: string, data: LaborRateListItem): Observable<LaborRateListItem> {
    return this.configureLaborRateApi.updateLaborRate(id, data);
  }

  getLaborRateRecord(filter: any): Observable<any> {
    return this.configureLaborRateApi.getLaborRateRecord(filter, 'labor-rate-maintain');
  }

  exportComplaints(filter: any): Observable<any> {
    return this.configureLaborRateApi.exportComplaints(filter, 'labor-rate-maintain');
  }
}

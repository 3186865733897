import { Injectable } from '@angular/core';
import { CurrentUserService } from '@otr/website-common';

@Injectable()
export class UserPrivilegeService {
  constructor(private currentUserService: CurrentUserService) {}

  hasPrivilege(privilege: PrivilegesEnum) {
    return this.currentUserService.userPrivileges.some(userPrivilege => userPrivilege === privilege);
  }
}

export enum PrivilegesEnum {
  ROLE_DEALER_DISCOUNT_MANAGEMENT_EDIT= 'ROLE_DEALER_DISCOUNT_MANAGEMENT_EDIT' ,
  ROLE_OEM_PART_MANAGEMENT= 'ROLE_OEM_PART_MANAGEMENT',
  ROLE_VAN_OEM_PART_MANAGEMENT = 'ROLE_VAN_OEM_PART_MANAGEMENT',
  ROLE_PARTS_SALES_SALES_ORDER= 'ROLE_PARTS_SALES_SALES_ORDER',
  ROLE_PARTS_SALES_SALES_ICT= 'ROLE_PARTS_SALES_SALES_ICT',
  ROLE_PARTS_SALES_NEGATIVE_QUANTITY_PARTS= 'ROLE_PARTS_SALES_NEGATIVE_QUANTITY_PARTS',
  ROLE_PARTS_SALES_BLOCK_SALES_PARTS_ADD= 'ROLE_PARTS_SALES_BLOCK_SALES_PARTS_ADD',
  ROLE_RETURN_NOT_INVOICED_PARTS= 'ROLE_RETURN_NOT_INVOICED_PARTS',
  ROLE_RETURN_INVOICED_PARTS= 'ROLE_RETURN_INVOICED_PARTS',
  ROLE_PARTS_REFUND_REQUEST= 'ROLE_PARTS_REFUND_REQUEST',
  ROLE_PARTS_INVOICE_REQUEST= 'ROLE_PARTS_INVOICE_REQUEST',
  ROLE_CAN_BE_SOLD_BELOW_COST= 'ROLE_CAN_BE_SOLD_BELOW_COST',
  ROLE_VIEW_PARTS_SALES_COST= 'ROLE_VIEW_PARTS_SALES_COST',
  ROLE_EDIT_PARTS_SALES_SELLING_PRICE= 'ROLE_EDIT_PARTS_SALES_SELLING_PRICE',
  ROLE_EDIT_PARTS_SALES_DESCRIPTION= 'ROLE_EDIT_PARTS_SALES_DESCRIPTION',
  ROLE_EDIT_PARTS_SALES_DISCOUNT_RATE= 'ROLE_EDIT_PARTS_SALES_DISCOUNT_RATE',
  ROLE_B_O_PARTS_CAN_BE_DELETED_LOST= 'ROLE_B_O_PARTS_CAN_BE_DELETED_LOST',
  ROLE_BREAKING_MINIMUM_PROFIT= 'ROLE_BREAKING_MINIMUM_PROFIT',
  ROLE_PARTS_DISCOUNT_MAX_RATE_EDIT= 'ROLE_PARTS_DISCOUNT_MAX_RATE_EDIT',
  ROLE_PARTS_DISCOUNT_MAX_RATE_VIEW= 'ROLE_PARTS_DISCOUNT_MAX_RATE_VIEW',
  ROLE_PARTS_DEALER_MENU_PRICING_SPPS_EDIT= 'ROLE_PARTS_DEALER_MENU_PRICING_SPPS_EDIT',
  ROLE_DEALER_PART_MANAGEMENT = 'ROLE_DEALER_PART_MANAGEMENT',
  ROLE_DEALER_GROUP_PARTS_SALES = 'ROLE_DEALER_GROUP_PARTS_SALES',
  ROLE_DEALER_GROUP_DEALER_PART_MANAGEMENT = 'ROLE_DEALER_GROUP_DEALER_PART_MANAGEMENT',
  ROLE_DEALER_GROUP_PARTS_DEALER_MENU_PRICING_SPPS = 'ROLE_DEALER_GROUP_PARTS_DEALER_MENU_PRICING_SPPS',
  ROLE_DEALER_GROUP_STOCK_CHECKING = 'ROLE_DEALER_GROUP_STOCK_CHECKING',
  ROLE_DEALER_GROUP_PURCHASE_ORDER_LINE_SEARCH = 'ROLE_DEALER_GROUP_PURCHASE_ORDER_LINE_SEARCH',
  ROLE_DEALER_GROUP_PURCHASE_ORDER_SEARCH = 'ROLE_DEALER_GROUP_PURCHASE_ORDER_SEARCH',
  ROLE_DEALER_GROUP_PURCHASE_ORDER_SUBMISSION = 'ROLE_DEALER_GROUP_PURCHASE_ORDER_SUBMISSION',
  ROLE_DEALER_GROUP_GOODS_RECEIVE = 'ROLE_DEALER_GROUP_GOODS_RECEIVE',
  ROLE_DEALER_GROUP_CONFI_GOODS_RECEIVE = 'ROLE_DEALER_GROUP_CONFI_GOODS_RECEIVE',
  ROLE_DEALER_GROUP_INVOICE_VERIFICATION = 'ROLE_DEALER_GROUP_INVOICE_VERIFICATION',
  ROLE_DEALER_GROUP_DEALER_SETTING = 'ROLE_DEALER_GROUP_DEALER_SETTING',
  ROLE_DEALER_GROUP_DEALER_PURCHASE_PARAMETER = 'ROLE_DEALER_GROUP_DEALER_PURCHASE_PARAMETER',
  ROLE_DEALER_GROUP_DEALER_DISCOUNT_CODE_VIEW = 'ROLE_DEALER_GROUP_DEALER_DISCOUNT_CODE_VIEW',
  ROLE_DEALER_GROUP_DEALER_DISCOUNT_MANAGEMENT_VIEW = 'ROLE_DEALER_GROUP_DEALER_DISCOUNT_MANAGEMENT_VIEW',
  ROLE_DEALER_GROUP_PARTS_DISCOUNT_MAX_RATE_VIEW = 'ROLE_DEALER_GROUP_PARTS_DISCOUNT_MAX_RATE_VIEW',
  ROLE_PART_CLERK = 'ROLE_PART_CLERK',
  ROLE_PART_MANAGER = 'ROLE_PART_MANAGER',
  ROLE_READ_PARTS_SALES = 'ROLE_READ_PARTS_SALES',
  ROLE_SERVICE_RECORD_IN_ALL_DEALER = 'ROLE_SERVICE_RECORD_IN_ALL_DEALER',
  ROLE_VEHICLE_MANAGEMENT = 'ROLE_VEHICLE_MANAGEMENT',
  ROLE_AGENT_INVENTORY = 'ROLE_AGENT_INVENTORY',
  ROLE_READ_VEHICLE_MANAGEMENT = 'ROLE_READ_VEHICLE_MANAGEMENT',
  ROLE_DEALER_GROUP_SERVICE_CODE_MANAGEMENT = 'ROLE_DEALER_GROUP_SERVICE_CODE_MANAGEMENT',
  ROLE_DIGITAL_SERVICE_ORDER_CENTER_APPLY_SERVICE_FEE_COMMISSION_FEE = 'ROLE_DIGITAL_SERVICE_ORDER_CENTER_APPLY_SERVICE_FEE_COMMISSION_FEE',
  ROLE_SALES_SUPPORT_SERVICE_CONTRACT = 'ROLE_SALES_SUPPORT_SERVICE_CONTRACT',
  ROLE_INSURANCE_RETENTION_LIST_EXPORT = 'ROLE_INSURANCE_RETENTION_LIST_EXPORT',
  ROLE_AS_ALLOCATE_ACCIDENT_CAR_LEAD_WEB = 'ROLE_AS_ALLOCATE_ACCIDENT_CAR_LEAD_WEB',
  ROLE_AS_FOLLOW_UP_ACCIDENT_CAR_LEAD_WEB = 'ROLE_AS_FOLLOW_UP_ACCIDENT_CAR_LEAD_WEB',
  ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_WEB = 'ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_WEB',
  ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT = 'ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT',
  ROLE_RESERVATION_LIST_READ = 'ROLE_RESERVATION_LIST_READ'
}

import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import { delay, tap } from 'rxjs/operators';

@Component({
  templateUrl: './multi-header.template.html',
  styleUrls: ['multi-header.style.scss'],
})
export class MultiHeaderComponent implements IHeaderAngularComp {
  params;
  header: Array<Object> = [
    { text: '责任部门', style: 'header-1' },
    { text: '负责人', style: 'header-2' },
    { text: '方案', style: 'header-3' },
  ];
  oneCloumnWidth: number = 336;
  allRowColumnNumber: Object = { default: 0 };
  headers: Array<Array<string>> = [];

  agInit(params): void {
    this.params = params;
    this.subscribeWidthChange();
  }

  private subscribeWidthChange() {
    this.column.colDef.columnNumber$.pipe(
      tap(number => (this.allRowColumnNumber = { ...this.allRowColumnNumber, ...number })),
      delay(0),
      tap(() => this.setColumnWidth()))
      .subscribe(() => {
        this.headers = Array(this.nextColumnNumber).fill(this.header);
      });
  }

  get column() {
    return this.params.column;
  }

  get nextColumnNumber() {
    const allColumnNumber = _.values(this.allRowColumnNumber);
    return Number(_.max(allColumnNumber));
  }

  get nextColumnWidth() {
    return this.nextColumnNumber ? this.nextColumnNumber * this.oneCloumnWidth : this.oneCloumnWidth;
  }

  setColumnWidth() {
    this.params.columnApi.setColumnWidth(this.column, this.nextColumnWidth, true);
  }

}

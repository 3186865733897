import { Injectable } from '@angular/core';
import { GET, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OutletShareStockApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/dealers/check-sharable')
  getShareStockConfig(): Observable<any> {
    return null;
  }
}

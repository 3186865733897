export const canvasWatermark = ({
                        width = '400px',
                        height = '200px',
                        font = 'normal 400 13px PingFang SC',
                        fillStyle = 'rgba(0, 0, 0, 0.2)',
                        content = '',
                        rotate = -20,
                      } = {}) => {
  const canvas = document.createElement('canvas');
  canvas.setAttribute('width', width);
  canvas.setAttribute('height', height);
  const ctx = canvas.getContext('2d');

  ctx.font = font;
  ctx.fillStyle = fillStyle;
  ctx.rotate(Math.PI / 180 * rotate);
  textPrewrap(ctx, content, -40, 130, 20, 350, 3);

  const base64Url = canvas.toDataURL();
  const watermarkDiv = document.createElement('div');
  watermarkDiv.setAttribute('style', `
          z-index:10;
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          pointer-events:none;
          background-repeat:repeat;
          background-image:url('${base64Url}')`);

  return watermarkDiv;
};

function textPrewrap(ctx, content, drawX, drawY, lineHeight, lineMaxWidth, lineNum) {
  let drawTxt = '';
  let drawLine = 1;
  let drawIndex = 0;
  let drawYTemp = drawY;

  if (ctx.measureText(content).width <= lineMaxWidth) {
    ctx.fillText(content, drawX, drawYTemp);
  } else {
    for (let i = 0; i < content.length; i++) {
      drawTxt += content[i];
      if (ctx.measureText(drawTxt).width >= lineMaxWidth) {
        if (drawLine >= lineNum) {
          ctx.fillText(content.substring(drawIndex, i), drawX, drawYTemp);
          break;
        } else {
          ctx.fillText(content.substring(drawIndex, i + 1), drawX, drawYTemp);
          drawIndex = i + 1;
          drawLine += 1;
          drawYTemp += lineHeight;
          drawTxt = '';
        }
      } else {
        if (i === content.length - 1) {
          ctx.fillText(content.substring(drawIndex), drawX, drawYTemp);
        }
      }
    }
  }
}

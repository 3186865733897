import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import { FILTER_INPUT_TYPES } from '../constants/report';
import * as moment from 'moment';
import {
  dateFormatter,
  dateTimeFormatter,
  displayBooleanFormatter, isFullDataRange
} from '../utils/utils';
import { DATE_FORMAT } from '../../_common/constants/common';

export const REPAIR_PACKAGE_USAGE_RATE_REPORT_CONFIG = {
  name: 'repair-package-usage-rate-report',
  enablePagination: true,
  exportedFileName: '保修快选使用率报表.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '创建日期',
      field: 'createdDate',
      width: 180,
      valueFormatter: dateFormatter,
    },
    {
      headerName: '订单号',
      field: 'orderNumber',
      width: 180,
    },
    {
      headerName: '登记进厂日期',
      field: 'checkedInTime',
      width: 180,
      valueFormatter: dateTimeFormatter,
    },
    {
      headerName: '车牌号',
      field: 'carPlateNumber',
      width: 180,
    },
    {
      headerName: '查询次数',
      field: 'count',
      width: 180,
    },
    {
      headerName: '保修套餐号',
      field: 'repairPackageNumber',
      width: 180,
    },
    {
      headerName: '是否添加购物车',
      field: 'isAddedShoppingCart',
      width: 180,
      valueFormatter: displayBooleanFormatter,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'createdTime',
      label: '创建日期',
      isRangeLimited: true,
      limitValueRange: 12,
      endLimitValue: moment(),
      defaultBeginDate: moment().startOf('month').format(DATE_FORMAT),
      defaultEndDate: () => {
        const startOfMonth = moment().startOf('month');
        const today = moment();
        if (today.isSame(startOfMonth, 'day')) {
          return today.format(DATE_FORMAT);
        }
        return today.subtract(1, 'days').format(DATE_FORMAT);
      },
    },
  ],
  validators: [
    {
      fieldName: 'createdTime',
      errorMsg: '请选择创建日期',
      isValid: isFullDataRange,
    }
  ],
};

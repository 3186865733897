import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  selector: 'app-text-input-switch-renderer',
  templateUrl: './text-input-switch-renderer.component.html',
  styleUrls: ['./text-input-switch-renderer.component.scss'],
})
export class TextInputSwitchRendererComponent implements ICellRendererAngularComp {
  isEditMode: boolean = false;
  value: string;
  params: ICellRendererParams;
  disabled: boolean;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.params = params;
    this.disabled = getValue(this.cellRendererParams.disabled, params);
  }

  refresh(params: any): boolean {
    return false;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  get textStyle() {
    return {
      width: `${this.params.colDef.width - 10}px`,
    };
  }

  onBlur(value: string) {
    this.isEditMode = false;
    this.cellRendererParams.onSave(value, this.params.node.data);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Body, DELETE, GET, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { AdvancedFilter, ICustomerConfig } from '../customer-management/shared/customer-management.model';
import { AdvancedFilterPagingResponse, ICriteriaApiBody, ICriteriaRes } from './customer-criteria-config.model';

/* istanbul ignore next */
@Injectable()
export class CustomerCriteriaConfigApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/customer-info/config')
  getCustomerConfig(): Observable<ICustomerConfig> {
    return null;
  }

  @GET('/api/advanced-filters')
  getCustomerCriteriaConfig(@Query('page') page = 0, @Query('pageSize') pageSize = 9): Observable<AdvancedFilterPagingResponse> {
    return null;
  }

  @GET('/api/advanced-filters/after-sales')
  getCustomerFlagConfig(@Query('page') page = 0, @Query('pageSize') pageSize = 9): Observable<AdvancedFilterPagingResponse> {
    return null;
  }

  @DELETE('/api/advanced-filters/:id')
  softDeleteCustomerCriteriaConfig(@Path('id') id: number): Observable<void> {
    return null;
  }

  @GET('/api/advanced-filters/:dealerId/dealerId/:type')
  getCustomerCriteria(@Path('dealerId') dealerId: string, @Path('type') type: string): Observable<void> {
    return null;
  }

  @GET('/api/advanced-filters/:type')
  getAdvancedFilterByType(@Path('type') type: string): Observable<Array<AdvancedFilter>> {
    return null;
  }

  @POST('/api/advanced-filters')
  addCustomerCriteria(@Body params: ICriteriaApiBody): Observable<ICriteriaRes> {
    return null;
  }

  @DELETE('/api/advanced-filters/delete/filters')
  deleteFilterCerteria(@Query('filterIds') dealerId: Array<any>): Observable<void> {
    return null;

  }
}

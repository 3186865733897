import * as _ from 'lodash';

export type InputValue = string | boolean | number | undefined | null;
export type EnumInput = Record<string, { text: string, value?: InputValue }>;

type InputItem<T> = T extends Record<string, infer U> ? U : never;

export type EnumItem<T> = InputItem<T> & { value: EnumItemValue<T> };

type EnumItemValue<T> = T extends Record<string, { value?: infer U }> ? U : string;

interface EnumType<T> {
  values: EnumItem<T>[];

  keys: string[];

  getTextByValue(value: string): string;

  getValueByText(text: string): string;

  get(key: keyof T): EnumItem<T>;

  getByValue(value: EnumItemValue<T>): EnumItem<T>;
}

export type EnumValueOf<T> = Exclude<keyof T, keyof EnumType<T>>;

export type Enum<T> = Record<keyof T, EnumItem<T>> & EnumType<T>;

// @ts-ignore
export const toEnum = <T extends EnumInput>(map: T): Enum<T> => {
  const enumMap: any = Object.keys(map)
    .reduce((eMap, key) => ({ ...eMap, [key]: { value: key, ...map[key] } }), {});
  enumMap.values = Object.keys(map).map(key => enumMap[key]);
  enumMap.keys = Object.keys(map).map(key => key);
  enumMap.getTextByValue = (value: string) => _.chain(enumMap).find({ value }).get('text', '').value();
  enumMap.getValueByText = (text: string) => _.chain(enumMap).find({ text }).get('value', '').value();
  enumMap.get = (key: keyof T) => enumMap[key];
  enumMap.getByValue = (value: EnumItemValue<T>) => _.find(enumMap, { value });
  return enumMap as Enum<T>;
};

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'conflictText' })
export class ConflictTextPipe implements PipeTransform {
  transform(value: string|string[]) {
    let targetText;
    if (Array.isArray(value)) {
      targetText = value.join(',');
    } else {
      targetText = value;
    }
    return `DMS ${targetText}不匹配`;
  }
}

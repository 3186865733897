import { Events, IHeaderParams } from 'ag-grid';
import * as _ from 'lodash';
import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  template: `
    <input
      type="checkbox"
      [checked]="checked$ | async"
      [disabled]="!selectableNodesCount"
      (click)="onChange($event.target.checked)"
    />
    <span class="custom-checkbox-label">&nbsp;</span>
  `,
  styleUrls: ['./custom-checkbox-header.component.scss'],
})
export class CustomCheckBoxHeaderComponent implements IHeaderAngularComp {
  selectableNodesCount: number;

  checked$: Subject<boolean> = new BehaviorSubject(false);

  private params: IHeaderParams;
  private isDisabled: (data: any) => boolean;

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.isDisabled = this.getIsDisabled();
    this.listenEvents(params.api);
    this.countSelectableRows(params.api);
  }

  onChange(checked) {
    if (this.column && this.column.getColDef() && _.get(this.column.getColDef(), 'selectAllCustom')) {
      const selectAllCustom = _.get(this.column.getColDef(), 'selectAllCustom');
      if (_.isFunction(selectAllCustom)) {
        selectAllCustom(checked, this.api);
      }
      return;
    }

    if (this.column && this.column.getColDef() && _.get(this.column.getColDef(), 'checkAll')) {
      const checkAll = _.get(this.column.getColDef(), 'checkAll');
      if (_.isFunction(checkAll)) {
        checkAll.apply(null, [checked]);
      }
    }
    if (!checked) {
      return this.api.deselectAll();
    }
    this.api.selectAll();
    _(this.api.getSelectedNodes())
      .filter(node => this.isDisabled(node.data))
      .forEach(node => node.setSelected(false));
  }

  private listenEvents(api): void {
    api.addEventListener(Events.EVENT_ROW_SELECTED, () => this.toggle());

    api.addEventListener(Events.EVENT_ROW_DATA_CHANGED, () => {
      this.countSelectableRows(api);
      this.toggle();
    });

    api.addEventListener(Events.EVENT_GRID_READY, () => this.countSelectableRows(api));
    api.addEventListener(Events.EVENT_MODEL_UPDATED, () => this.countSelectableRows(api));
  }

  private countSelectableRows(api) {
    const rows = api.rowModel.rowsToDisplay;
    this.selectableNodesCount = _.countBy(rows, (node: any) => !this.isDisabled(node.data)).true;
  }

  private toggle() {
    this.checked$.next(!!this.selectableNodesCount && this.api.getSelectedNodes().length === this.selectableNodesCount);
  }

  get api() {
    return this.params.api;
  }

  getIsDisabled() {
    return _.get(this.column.getColDef(), 'isDisabled', () => false);
  }

  get column() {
    return this.params.column;
  }
}

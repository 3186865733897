import { ICellRendererParams } from 'ag-grid';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';

const getValue = (value, params) =>
  _.isFunction(value) ? value.call(null, params) : value;

@Component({
  template: `
    <div [class]="'tag-cell-container ' + hostClassName" *ngIf="visible">
      <span [title]="tooltipText" class="tag-text clearfix"
      >{{ text }}</span>
    </div>
  `,
  styleUrls: ['./tag-renderer.component.scss'],
})
export class TagRendererComponent implements ICellRendererAngularComp {
  text: string;
  tooltipText: string;
  visible: boolean;
  hostClassName: string;

  agInit(params: ICellRendererParams): void {
    const cellRendererParams = (params.colDef.cellRendererParams || _.noop)(params.data);
    this.hostClassName = getValue(cellRendererParams.hostClassName, params) || '';
    this.text = getValue(cellRendererParams.text, params);
    this.tooltipText = getValue(cellRendererParams.tooltipText, params) || '';
    this.visible = !_.isEmpty(this.text);
  }

  refresh(params: any): boolean {
    return false;
  }
}

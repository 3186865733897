import { Component, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  templateUrl: 'tooltips-header.template.html',
  styleUrls: ['tooltips-header.style.scss'],
})
export class TooltipsHeaderComponent implements IHeaderAngularComp {
  headerParams: IHeaderParams;
  headerTooltipText: string;
  hideMenu: boolean;
  pullTooltipLeft: boolean;
  useFixedWidthTooltip: boolean;

  @ViewChild('headerMenu') headerMenu;

  agInit(params: IHeaderParams): void {
    this.headerParams = params;
    const colDef = params.column.getColDef();
    this.headerTooltipText =  _.get(colDef, 'headerTooltipText');
    this.pullTooltipLeft = _.get(colDef, 'pullTooltipLeft');
    this.hideMenu = _.get(colDef, 'hideHeaderMenu');
    this.useFixedWidthTooltip = _.get(colDef, 'useFixedWidthTooltip');

  }

  handleMenuIconClick() {
    this.headerParams.showColumnMenu(this.headerMenu.nativeElement);
  }
}

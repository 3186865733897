
    <div class="activateStatusTooltip">
      <div class='status-tip-wrapper'>
        <span>{{statusCn}}</span>
        <ng-template #iconTooltipTemplate>
          <span class="tooltipText" *ngIf="!iconErrorMessage">该车已有同类礼包被激活，请在EVA中确认</span>
          <span class="tooltipText" *ngIf="iconErrorMessage">{{iconErrorMessage}}</span>
        </ng-template>
        <ng-template #dscTooltipTemplate>
          <span class="tooltipText" *ngIf="!iconErrorMessage">激活失败，请在DCS中确认</span>
          <span class="tooltipText" *ngIf="iconErrorMessage">{{iconErrorMessage}}</span>
        </ng-template>
        <ng-template #lpoTooltipTemplate>
          <span class="tooltipText" *ngIf="!lpoActivateFailedMessage">激活失败，请在LPO中确认</span>
          <span class="tooltipText" *ngIf="lpoActivateFailedMessage">{{lpoActivateFailedMessage}}</span>
        </ng-template>
        <i *ngIf="shouldDisplayTooltip"
           class="fa antd-exclamation-circle-filled"
           placement="bottom"
           [tooltip]="isLpo? lpoTooltipTemplate : isVan ? dscTooltipTemplate : iconTooltipTemplate"
           container="body">
        </i>
      </div>
    </div>
  
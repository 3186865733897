import { Component, EventEmitter, Input, OnInit, Output, ContentChild, TemplateRef } from '@angular/core';
import * as _ from 'lodash';

export interface SectionTab {
  key: any;
  text: string;
  subText?: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-section-tabs',
  templateUrl: './section-tabs.template.html',
  styleUrls: ['./section-tabs.style.scss'],
})
export class SectionTabsComponent implements OnInit {
  @Input() sections: SectionTab[];
  @Input() withCustomFunction: boolean = false;
  @Input() textWidth: number = 70;
  @Input() customFunctionWidth: number = 80;

  @Output() change = new EventEmitter();

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  currentKey: string;

  get tabWidth(): number {
    return this.withCustomFunction
      ? this.textWidth + this.customFunctionWidth : this.textWidth;
  }

  get currentSectionIndex() {
    return _.findIndex(this.sections, section => section.key === this.currentKey);
  }

  ngOnInit() {
    this.currentKey = _.chain(this.sections).find(section => !!!section.disabled).get('key').value();
  }

  onChange(key) {
    if (this.currentKey === key) {
      return;
    }
    // the tab can't be select, if tab data's property disabled is true.
    if (!!!_.chain(this.sections).find(section => section.key === key).get('disabled', false).value()) {
      this.currentKey = key;
      this.change.emit(key);
    }
  }
}

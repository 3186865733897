import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { AsOrderService } from '../../_common/services/as-order.service';
import { PromptLevel } from '../../_common/constants/common';
import {
  ERROR_MESSAGES,
  ORDER_DOWNLOAD_TIMES_OVERFLOW,
  RESOURCE_NOT_FOUND,
} from '../../_common/constants/error-messages';

import { HttpErrorResponse } from '@angular/common/http';
import { PromptBarService } from '@otr/website-common';

@Injectable()
export class CanAccessOrderDetail implements CanActivate {
  constructor(private promptBarService: PromptBarService,
              private asOrderService: AsOrderService) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const { dealerId, claimNumber } = route.params;
    const claimNumbers: string[] = claimNumber.split(',');
    return this.asOrderService.validateOrderExceedLimit(dealerId, claimNumbers).toPromise().then(
      () => {
        return true;
      },
      (error: HttpErrorResponse) => {
        const errorInfo = error.error;
        if (_.get(errorInfo, 'error_code') === ORDER_DOWNLOAD_TIMES_OVERFLOW) {
          this.promptBarService.show(ERROR_MESSAGES.EXCEED_MAX_LIMIT, PromptLevel.Error);
          return false;
        }
        if (_.get(errorInfo, 'error_code') === RESOURCE_NOT_FOUND) {
          this.promptBarService.show(ERROR_MESSAGES.ORDER_NOT_FOUND);
          return false;
        }
        this.promptBarService.show(ERROR_MESSAGES.BAD_RESPONSE, PromptLevel.Error);
        return false;
      },
    );
  }

}

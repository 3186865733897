import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTableTemplate]',
})
export class TableTemplateDirective {

  @Input() type: string;

  constructor(public template: TemplateRef<any>) {
  }
}

<div class='date'>
  <nz-date-picker
    [nzDisabled]="disabled"
    [(ngModel)]="date"
    [nzAllowClear]="false"
    [nzDisabledDate]="disabledDate"
    [nzPlaceHolder]='"YYYY-MM-DD"'
    [nzShowToday]='false'
    (ngModelChange)="onChange($event)"
  ></nz-date-picker>
</div>

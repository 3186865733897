import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';
import { FormControl } from '@angular/forms';
import { IAfterGuiAttachedParams } from 'ag-grid';
import { isEmptyValue, isNumericOrEmptyString } from '../../../../../utils/common';

@Component({
  selector: 'app-pagination-range-filter-cell',
  templateUrl: 'pagination-range-filter.template.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationRangeFilterComponent extends BasePaginationFilterComponent {
  startInput: FormControl = new FormControl('');
  endInput: FormControl = new FormControl('');
  savedStartValue: string = '';
  savedEndValue: string = '';

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    super.afterGuiAttached(params);
    this.startInput.setValue(this.savedStartValue);
    this.endInput.setValue(this.savedEndValue);
  }

  onSubmit() {
    super.hideFilterMenu();
    this.savedStartValue = this.startInput.value;
    this.savedEndValue = this.endInput.value;
    this.params.filterChangedCallback();
  }

  isFilterActive(): boolean {
    return !isEmptyValue(this.savedStartValue) || !isEmptyValue(this.savedEndValue);
  }

  onCancel() {
    super.hideFilterMenu();
    this.startInput.setValue(this.savedStartValue);
    this.endInput.setValue(this.savedEndValue);
  }

  public setDefaultValue() {
    super.setDefaultValue();
    this.startInput.setValue('');
    this.endInput.setValue('');
    this.savedStartValue = '';
    this.savedEndValue = '';
  }

  get filterValue() {
    return {
      from: this.savedStartValue ? Number(this.savedStartValue) : null,
      to: this.savedEndValue ? Number(this.savedEndValue) : null,
    };
  }

  isEmptyFilter() {
    return isEmptyValue(this.savedStartValue) && isEmptyValue(this.savedEndValue);
  }

  isFilterValid() {
    return isNumericOrEmptyString(this.startInput.value)
      && isNumericOrEmptyString(this.endInput.value)
      && (isEmptyValue(this.endInput.value) || Number(this.startInput.value) <= Number(this.endInput.value));
  }

  setModel(model: any): void {
    this.savedStartValue = _.get(model, 'from', '');
    this.savedEndValue = _.get(model, 'to', '');
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    const model = {} as { from?: string; to?: string; };
    if (this.savedStartValue) {
      model.from = this.savedStartValue;
    }
    if (this.savedEndValue) {
      model.to = this.savedEndValue;
    }
    return this.modelFormatter(model);
  }
}

import * as _ from 'lodash';
import * as moment from 'moment';
import { CDO_LEAD_CHANNELS_OPTIONS, CHANNELS, DUPLICATE_LEAD_MSG, LEAD_CHANNELS } from '../constants/common';
import { dateParse } from './date-parse';
import { ACCEPTABLE_LEAD_TYPE, LEAD_TYPES } from '../constants/lead';
import { DORMANT_CHANNEL } from './lead-detail-helper';
import { DISABLE_ACTIVITIES } from '../../customer-lead-management/lead-management/sales-lead/lead-information/activity-history/activity-history-helper';
import { DateFormat } from '../constants/date';
import { Retention } from '../models/retention';

export const ACTIVITY_TYPE = {
  VISIT: 'ShowroomVisit',
};

export function getInterestedVehicleText(lead) {
  return (
    (lead.interested_vehicles &&
      lead.interested_vehicles.length &&
      lead.interested_vehicles[0].variant &&
      lead.interested_vehicles[0].variant.description_zh) ||
    '暂无意向车'
  );
}

export function getChannel(channel) {
  return _.get(
    _.find(LEAD_CHANNELS, item => item.value === channel),
    'text',
  );
}

export const formatSalesDivisionString = divisions => _.replace(divisions, 'SMART', 'smart');

export const formatSalesConsultantDivisions = divisions =>
  _.map(divisions, division => formatSalesDivisionString(division));

export function getSalesConsultantInfo(lead, hiddenDivision = false) {
  const leadOwner = lead.owner_sales_consultant_detail;
  const divisions = (leadOwner &&
    leadOwner.sales_division &&
    ` (${formatSalesDivisionString(leadOwner.sales_division)})`) ||
    '';
  return (
    `${(leadOwner && leadOwner.full_name) || ''}${hiddenDivision ? '' : divisions}` || '无'
  );
}

export const isOpenLead = lead => _.includes(ACCEPTABLE_LEAD_TYPE, _.lowerCase(_.get(lead, 'status')));

export const isDormantLead = currentChannel => currentChannel === DORMANT_CHANNEL.value;

export const isLeadStatusLead = lead => _.lowerCase(_.get(lead, 'status')) === LEAD_TYPES.LEAD;

export const isOpenLeadByStatus = leadStatus => _.includes(ACCEPTABLE_LEAD_TYPE, _.lowerCase(leadStatus));

export const getFollowUpDate = lead => isOpenLead(lead) ? dateParse(lead.nextFollowUpDate) : null;

export const getOpenLeadId = lead => isOpenLead(lead) ? _.get(lead, '_id', '#') : null;

export const getOpenLeads = leads => _.filter(leads, isOpenLead);

export const isOpenLeadExisting = leads => _.some(leads, isOpenLead);

export function shouldShowCreateLeadLink(person) {
  return _.isEmpty(getOpenLeads(person.leads));
}

export function getLastShowroomVisitArrivalTimeOfLeadText(lead) {
  const lastShowroomVisitActivity = _(lead.activities)
    .filter({ activity_type: ACTIVITY_TYPE.VISIT })
    .orderBy('arrival_time', 'desc')
    .head();
  if (_.isUndefined(lastShowroomVisitActivity)) {
    return '无';
  }
  return dateParse(_.get(lastShowroomVisitActivity, 'arrival_time'), DateFormat.DATE_TIME_MINUTE);
}

export const getScDisplayText = sc => `${sc.full_name}（${_.join(sc.division, ',')}）`;

export const filterAssignableSc = scs => _.filter(scs, sc => sc.is_reception);

export function getDuplicateLeadMsg(lead, userId) {
  if (lead.owner_ueum_detail && lead.owner_ueum_detail.user_id !== userId) {
    return DUPLICATE_LEAD_MSG.UE_MSG.value;
  }
  if (lead.cdoId && lead.cdoId !== userId) {
    return DUPLICATE_LEAD_MSG.CDO_MSG.value;
  }
  if (lead.owner_sales_consultant_id) {
    return DUPLICATE_LEAD_MSG.SC_MSG.value;
  }
}

export const loadLeadChannelsOptionByCurrentChannel = channel => {
  const existingChannelOption = _.find(CDO_LEAD_CHANNELS_OPTIONS, {
    value: channel,
  });
  return _.unionBy([existingChannelOption], [CHANNELS.REFERRAL, CHANNELS.REPURCHASE], 'value');
};

export const hasOwnerSc = lead => !_.isNil(lead.owner_sales_consultant_id);

export const getFinanceInsuranceProcess = expirationDate => {
  const startDate = moment(expirationDate).subtract(1, 'years');
  const processedMonth = moment().diff(startDate, 'months');
  return processedMonth < 0 ? 0 : _.round(_.min([processedMonth / 12, 1]), 4);
};

const MAX_WARRANTY_MILEAGE = 50000;
const GUARANTEE_YEARS = 2;
const isMileageInWarranty = mileage => _.isNil(mileage) || mileage < MAX_WARRANTY_MILEAGE;

export const isInGuaranteePeriod = currentVehicle => {
  const { evaFirstRegistrationDate, guaranteeStartDate, firstRegistrationDate, mileage } = currentVehicle;
  const startDate = evaFirstRegistrationDate || guaranteeStartDate || firstRegistrationDate;
  return (
    startDate && moment().isBefore(moment(startDate).add(GUARANTEE_YEARS, 'years')) && isMileageInWarranty(mileage)
  );
};

export const isCustomerActivity = ({ customer_id, task_type }) => customer_id && !task_type;

export const isCustomerTaskActivity = ({ customer_id, task_type }) => customer_id && task_type;

export const isDisableActivities = ({ activity_type }) => _.includes(DISABLE_ACTIVITIES, activity_type);

export const filterOfferByOfferId = (retentions: Array<Retention>, offerId): any =>
  _.defaultTo(_.find(retentions, { newOffer: { offerId } }), _.get(retentions, '[0]'));

export const filterScByBusinessModelTypeAndOrderScs = (scs) => {
  return sortScByRoleCode(scs);
};

export const sortScByRoleCode = (scs) => {
  return _.concat(_.filter(scs, item => _.get(item, 'role_code') === 'MODEL_D_SC'),
    _.filter(scs, item => _.get(item, 'role_code') !== 'MODEL_D_SC'));
};

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  template: `<div><a [routerLink]="linkPartials" [state]="{ isFromLink: true }">{{ value }}</a></div>`,
  styles: [],
})
export class ManufacturerPartNumberCellRenderComponent implements ICellRendererAngularComp {
  value: string;
  linkPartials: Array<string>;

  agInit({ value }): void {
    this.value = value.value;
    value.linkPartials[1] = value.linkPartials[1].replace(/\s/g, '').replace(/\//g, '');
    this.linkPartials = value.linkPartials;
  }

  refresh(params: any): boolean {
    return false;
  }
}

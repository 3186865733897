import { Directive, HostListener, Input } from '@angular/core';
import { isKeyBackspace, isKeyDecimalPoint, isKeyNumeric } from '../utils/common';
import * as _ from 'lodash';

const NAVIGATION_KEYS = [13, 37, 38, 39, 40];

@Directive({
  selector: '[appNumberInput]',
})
export class NumberInputDirective {

  @Input() allowDecimal: boolean = false;

  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    if (_.includes(NAVIGATION_KEYS, this.getCharCodeFromEvent(event))) {
      event.stopPropagation();
    } else {
      this.onOtherKeyDown(event);
    }
  }

  getCharCodeFromEvent(event): any {
    const newEvent = event || window.event;
    return (newEvent.which === undefined) ? newEvent.keyCode : newEvent.which;
  }

  onOtherKeyDown(event): void {
    if (!this.isKeyPressedNumericOrBackspace(event)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  isKeyPressedNumericOrBackspace(event): boolean {
    const key = this.getKeyFromEvent(event);
    return isKeyBackspace(key) || isKeyNumeric(key) || (this.allowDecimal && isKeyDecimalPoint(key));
  }

  getKeyFromEvent(event): any {
    return (event || window.event).key;
  }
}

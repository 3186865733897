<div class="plan-container">
  <div class="plus-icon-container">
    <app-icon-button
      *ngIf="!plansFormArray.controls.length"
      (onClick)="addPlan($event)"
      [iconType]="plusIconType"
      [text]="displayedText"
      hostClassName="plus-icon-button">
    </app-icon-button>
  </div>
  <div *ngIf="plansFormArray.controls.length">
    <div class="plan-wrapper" *ngFor="let plan of plansFormArray.controls; let index=index; let isLast=last;">
      <div class="select-wrapper">
        <select class="input-control" [formControl]="plan.controls.department">
          <option *ngFor="let department of departments" [value]="department.value">{{department.text}}</option>
        </select>
      </div>
      <div class="input-wrapper">
        <input type="text" class="input-control" [formControl]="plan.controls.person">
      </div>
      <div class="plan-detail">
        <a (mouseenter)="showPlanDetail($event,plan)"
           (mouseleave)="hidePlanDetail()"
           (click)="showModal($event,index,plan)">{{isPlanSummaryEmpty(plan) ? '添加' : '详情'}}</a>
      </div>
      <div class="operation">
        <app-icon-button [iconType]="minusIconType"
                         (onClick)="minusPlan(index)"
                         hostClassName="minus-icon-button"
        ></app-icon-button>
        <app-icon-button *ngIf="isLast" [iconType]="plusIconType"
                         (onClick)="addPlan($event)"
                         hostClassName="plus-icon-button"></app-icon-button>
      </div>
    </div>
  </div>
</div>


    <button class="btn icon-text-cell-container" [class.icon-right]="iconRight" [class.icon-disable]="disabled" [disabled]="disabled" [ngStyle]="width"
            (click)="onClick($event)">
      <img *ngIf="iconUrl" style="width: 22px;" [src]="iconUrl" class="navigation-icon">
      <i [ngClass]="classNames"></i>
      <span [ngStyle]="spanStyle"
            class="display-text"
            [isDisabled]="!showToolTip"
            [tooltip]="text"
            placement="right"
      >{{ text }}</span>
    </button>
  
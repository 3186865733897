import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { KeyValue } from '../models/common';

@Pipe({
  name: 'matchingTextDisplay',
})
export class MatchingTextDisplayPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const matchingData = _.find(args, { value }) as KeyValue;
    if (!matchingData) {
      return '';
    }
    return matchingData.text;
  }

}

import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { TechnicianResourceManagementService } from '../../_common/services/technician-resource-management.service';

@Injectable()
export class CanAccessJobCard implements CanActivate {
  constructor(private technicianResourceManagementService: TechnicianResourceManagementService,
              private router: Router) {}

  canActivate() {
    if (!_.isEmpty(this.technicianResourceManagementService.technicianClockingInfo)) {
      return true;
    }

    this.router.navigate(['/technician-clocks/teams']);
    return false;
  }
}

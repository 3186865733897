import { FILTER_INPUT_TYPES, VEHICLE_BUSINESS_TYPE_OPTION } from '../constants/report';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import * as moment from 'moment';

export const NOT_CHECKEDIN_VEHICLE_STATISTICS_CONFIG = {
  name: 'non-checked-in-vehicle-statistics',
  enablePagination: true,
  exportedFileName: '未进厂车辆统计.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '订单号',
      field: 'orderNumber',
      width: 160,
    }, {
      headerName: '账单号',
      field: 'invoiceNumber',
      width: 160,
    }, {
      headerName: '牌照号',
      field: 'carPlateNumber',
      width: 120,
    }, {
      headerName: '底盘号',
      field: 'fin',
      width: 200,
    }, {
      headerName: '品牌',
      field: 'brand',
      width: 150,
    }, {
      headerName: '车型',
      field: 'carModel',
      width: 220,
    }, {
      headerName: '客户姓名',
      field: 'customerName',
      width: 150,
    }, {
      headerName: '服务类型',
      field: 'serviceType',
      width: 150,
    }, {
      headerName: '首登日期',
      field: 'firstRegistrationDate',
      width: 120,
    }, {
      headerName: '最近一次进厂日期',
      field: 'lastCheckedInDate',
      width: 120,
    }, {
      headerName: '最近一次进厂里程 (km)',
      field: 'lastCheckedInMileage',
      width: 150,
    },
    {
      headerName: '电话号码',
      field: 'customerPhone',
      width: 150,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedInDate',
      label: '最近一次进厂日期',
      startMinLimitValue: moment().subtract(5, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型 :',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
  ],
};

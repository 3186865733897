import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomLaborManagementApi } from '../apis/custom-labor-management';
import { CustomLaborManagementListItem } from '../models/custom-labor-management';
import { ResponsePage } from '../../_common/models/sssc-collection';

@Injectable()
export class CustomLaborManagementService {
  constructor(private customLaborManagementApi: CustomLaborManagementApi) {
  }

  getCustomLabors(pageNumber, pageSize): Observable<ResponsePage<CustomLaborManagementListItem>> {
    return this.customLaborManagementApi.getCustomLabors(pageNumber, pageSize);
  }

  getCustomLaborsManagement(pageNumber, pageSize): Observable<ResponsePage<CustomLaborManagementListItem>> {
    return this.customLaborManagementApi.getCustomLaborsManagement(pageNumber, pageSize);
  }

  getCustomLaborsGroup(CustomLaborDTO): Observable<ResponsePage<CustomLaborManagementListItem>> {
    return this.customLaborManagementApi.getCustomLaborsGroup(CustomLaborDTO);
  }

  getLaborGroups(): Observable<any> {
    return this.customLaborManagementApi.getLaborGroups();
  }

  createOrUpdateCustomLabor(data: CustomLaborManagementListItem): Observable<CustomLaborManagementListItem> {
    return this.customLaborManagementApi.createOrUpdateCustomLabor(data);
  }

  createOrEditCustomLabor(data: CustomLaborManagementListItem): Observable<CustomLaborManagementListItem> {
    return this.customLaborManagementApi.createOrEditCustomLabor(data);
  }

  createOrUpdateCustomLaborGroup(data: CustomLaborManagementListItem): Observable<CustomLaborManagementListItem> {
    return this.customLaborManagementApi.createOrUpdateCustomLaborGroup(data);
  }

  deleteCustomLabor(id: string): Observable<any> {
    return this.customLaborManagementApi.deleteCustomLabor(id);
  }

  deleteCustomLaborGroup(id: string): Observable<any> {
    return this.customLaborManagementApi.deleteCustomLaborGroup(id);
  }

  getCustomerLaborsPermissionAccount() {
    return this.customLaborManagementApi.getCustomerLaborsPermissionAccount();
  }

  customLaborAllocation(groupId: number, data: Observable<any>) {
    return this.customLaborManagementApi.customLaborAllocation(groupId, data);
  }
  customLaborUnAllocation(groupId: number, data: Observable<any>) {
    return this.customLaborManagementApi.customLaborUnAllocation(groupId, data);
  }
}

import * as _ from 'lodash';
import { NUMBER_REGEXP } from '../constants/common';
import { DIGIT_NUMBER_RULE } from '../constants/rules';

export function formatDateString(dateString) {
  const numbers = dateString.replace(/[^\d]/g, '');
  const year = numbers.slice(0, 4);
  const month = numbers.slice(4, 6);
  const day = numbers.slice(6, 8);
  const monthDash = month.length ? '-' : '';
  const dayDash = day.length ? '-' : '';
  return `${year}${monthDash}${month}${dayDash}${day}`;
}

export function formatAmountString(amount, precision = 0, seperator = ',') {
  const numberStr = `${amount}`;
  const regexp = /^(\d+)(?:\.(\d+))?$/;
  const matchResult = numberStr.match(regexp);
  if (!matchResult) {
    return '0';
  }

  const integer = matchResult[1];
  const decimal = matchResult[2];
  const formattedInteger = _.chunk(integer.split('').reverse(), 3)
    .map(threeDigits => {
      threeDigits.reverse();
      return threeDigits.join('');
    })
    .reverse()
    .join(seperator);
  const formattedDecimal = _.padEnd(decimal, precision, '0').substring(0, precision);
  return precision ? `${formattedInteger}.${formattedDecimal}` : formattedInteger;
}

export function getAmountForDisplay(amount: any, precision = 2) {
  return !_.isNumber(amount) && _.isEmpty(amount) ? null : formatAmountString(amount, precision, '');
}

export const isValidDateString = dateString => /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12]\d|3[01])$/.test(dateString);

export function fixNumber(value, precision = 2) {
  if (_.isNil(value)) {
    return '';
  }
  const roundedValue = NUMBER_REGEXP.test(value) ? _.round(Number(value), precision).toFixed(precision) : value;
  if (_.isFunction(roundedValue.toFixed)) {
    return roundedValue.toFixed(precision);
  }
  return roundedValue;
}

export function getOrDefault(value, defaultValue = '') {
  return _.isNil(value) ? defaultValue : value;
}

export function formatBrand(brand) {
  return formatSmart(brand.value);
}

export function formatBrandClassModel(data) {
  return _.isEmpty(data) || data === 'null' ? null : data;
}

export const formatSmart = value => _.replace(value, /SMART/, 'smart');

export function fixNumberIntegerComma(value, precision = 2) {
  // wrong comma position for fraction part
  const valueArr = (Number(value) || 0).toFixed(precision).split('.');
  const valueIngeger = valueArr[0].replace(DIGIT_NUMBER_RULE, ',');
  return precision ? `${valueIngeger}.${valueArr[1]}` : valueIngeger;
}

export function fixNumberComma(value, precision = 2) {
  // wrong comma position for fraction part
  return (Number(value) || 0).toFixed(precision).replace(DIGIT_NUMBER_RULE, ',');
}

export function fixValueWithLimit(value, length) {
  if (_.isNil(value)) {
    return '';
  }
  return value.slice(0, length);
}

export function fixNullValue(value: number | null | undefined, precision = 2) {
  return (_.isNil(value) ? 0 : value).toFixed(precision);
}

export function fixRoundNumber(value) {
  if (_.isNil(value)) {
    return '0';
  }
  // wrong comma position for fraction part
  return value.toString().replace(DIGIT_NUMBER_RULE, ',');
}

export function formatPartNo(data) {
  return _.trim(data);
}

export function formatNst(data) {
  return _.isEmpty(data) || data === 'null' ? null : data;
}

export const formatPrice = ({ value }) => fixNumberComma(value);

export function formatAmount(amount, precision = 2) {
  const valueArr = _.round(Number(amount) || 0, precision).toFixed(precision).toString().split('.');
  const valueInteger = valueArr[0].replace(DIGIT_NUMBER_RULE, ',');
  return precision === 0 ? `${valueInteger}` : `${valueInteger}.${valueArr[1]}`;
}

export const formatSpace = (value: string) => _.replace(value, /\s*/g, '');

import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import {
  displayBooleanFormatter,
  displayBooleanWithNilFormatter,
  getAccidentCarSmsListSettingByEnvironment,
  localDateTimeFormatter,
} from '../utils/utils';
import {
  ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_IN_SMS_LIST_OPTIONS,
  ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE,
  ACCIDENT_CAR_SMS_SOURCE_OPTIONS,
  DROPDOWN_DATE_RANGE_OPTIONS_DAY,
  FILTER_INPUT_TYPES,
} from '../constants/report';
import * as moment from 'moment';
import { DATE_FORMAT } from '../../_common/constants/common';

export const ACCIDENT_CAR_SMS_LIST_REPORT_CONFIG = {
  name: 'accident-car-sms-list-report',
  enablePagination: true,
  pageSize: 50,
  filterFourColumn: true,
  exportedFileName: '短信列表.xlsx',
  timeFormatter: 'YYYYMMDDHHmmss',
  isNameWithDate: true,
  isNameWithVehicleBusinessType: false,
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  vertical: true,
  newLineOperation: true,
  column: [
    {
      headerName: '序号',
      field: 'index',
      width: 80,
      suppressFilter: true,
      suppressSorting: true,
    },
    {
      headerName: '经销商代码',
      field: 'gsCode',
      width: 180,
    },
    {
      headerName: '经销商名称',
      field: 'dealerName',
      width: 180,
    },
    {
      headerName: '短信接收时间',
      field: 'createdDate',
      width: 180,
      valueFormatter: localDateTimeFormatter,
    },
    {
      headerName: '短信来源',
      field: 'leadSource',
      width: 140,
    },
    {
      headerName: '保险公司',
      field: 'insuranceCompany',
      width: 180,
    },
    {
      headerName: '短信内容',
      field: 'originalSms',
      width: 440,
      cellClass: 'two-line-ellipsis',
    },
    {
      headerName: '是否生成线索',
      field: 'isCreatedAccident',
      width: 210,
      valueFormatter: displayBooleanFormatter,
    },
    {
      headerName: '是否为异常流失线索',
      field: 'discarded',
      width: 210,
      valueFormatter: displayBooleanWithNilFormatter,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DROPDOWN_DATE_RANGE,
      title: '短信接收时间',
      datepickerTip: '最多可选区间为一年',
      name: 'createdTime',
      optionName: 'dimension',
      defaultOptionValue: DROPDOWN_DATE_RANGE_OPTIONS_DAY.DAY.value,
      resetWhenChangeOption: true,
      isRangeLimited: true,
      allRequired: true,
      limitValueRange: 12,
      startMinLimitValue: getAccidentCarSmsListSettingByEnvironment('startMinLimitValue'),
      startLimitValue: moment(),
      endLimitValue: moment(),
      endMinLimitValue: getAccidentCarSmsListSettingByEnvironment('endMinLimitValue'),
      endMaxLimitValue: moment(),
      itemWidth: 288,
      defaultBeginDate: moment().format(DATE_FORMAT),
      defaultEndDate: moment().format(DATE_FORMAT),
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'leadSource',
      label: '短信来源',
      options: ACCIDENT_CAR_SMS_SOURCE_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_SMS_SOURCE_OPTIONS.get('ALL'),
      itemWidth: 288,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'insuranceCompanyCodes',
      label: '保险公司',
      options: ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_IN_SMS_LIST_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_IN_SMS_LIST_OPTIONS.get('ALL'),
      itemWidth: 288,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'isCreatedAccident',
      label: '是否生成线索',
      options: ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE.values,
      defaultValue: ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE.get('ALL'),
      itemWidth: 288,
    },
  ],
};

import { cellRenders } from '../../_common/components/grid';
import { LinkComponent } from '../../_common/components/grid/cell-renders/link/link.component';
import { dateFormatter, getDayBeforeYear, getMonthBeforeYear, monthFormatter } from '../utils';
import { TagRendererComponent } from '../../_common/components/grid/cell-renders/tag/tag-renderer.component';
import { applyStatusStyleClass, applyStatusTranslation } from '../constant';
import { GridOptions } from 'ag-grid';
import { getDefaultGridOptions } from '../../_common/components/grid/pagination-grid/grid.component';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

const FILE_COLUMN_WIDTH = 360;
const LINE_HEIGHT = 18;
const LEFT_PADDING = 20;
const RIGHT_PADDING = 20;
const PRE_LINE_HEIGHT = 18;
const DEFAULT_HEIGHT = 50;
const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');
context.font = 'normal 12px "微软雅黑", "Microsoft Yahei", "Hiragino Sans GB", Helvetica, Arial, sans-serif';
export const getHeightByText = (text) => {
  const metrics = context.measureText(text);
  const lines = Math.ceil(metrics.width / (FILE_COLUMN_WIDTH - LEFT_PADDING - RIGHT_PADDING));
  const rowHeight = lines * LINE_HEIGHT + PRE_LINE_HEIGHT;
  return rowHeight > DEFAULT_HEIGHT ? rowHeight : DEFAULT_HEIGHT;
};

export const gridOptions: GridOptions = {
  ...getDefaultGridOptions(),
  defaultColDef: {
    cellClass: 'center-box cell-align-left',
    headerClass: 'center-box header-align-left',
    editable: false,
  },
  getRowHeight: ({ data }) => getHeightByText(data.dealerName),
};

const ACCESSORY_DISCOUNT_ROUTER_PREFIX = '/accessory-discount';

export const getColumn = () => {
  return [
    {
      field: 'checkbox',
      headerName: '',
      pinned: true,
      width: 56,
      headerComponentFramework: cellRenders.CheckBoxHeaderComponent,
      cellRendererFramework: cellRenders.CheckboxCellRenderComponent,
      suppressMenu: true,
      headerComponentParams: {
        showCustomLabel: true,
      },
      cellRendererParams: {
        showCustomLabel: true,
      },
    },
    {
      headerName: '经销商代码',
      field: 'gsCode',
      width: 150,
    },
    {
      headerName: '经销商名称',
      field: 'dealerName',
      width: FILE_COLUMN_WIDTH,
      cellStyle: { 'white-space': 'normal', 'text-align': 'left', 'word-break': 'break-all' },
    },
    {
      headerName: '统计月份',
      field: 'statisticalMonth',
      width: 180,
      valueFormatter: monthFormatter,
    },
    {
      headerName: '配件折扣支持数据',
      width: 150,
      field: 'accessoryDiscountSupportData',
      cellRendererFramework: LinkComponent,
      cellRendererParams: {
        text: () => '配件折扣申请信息',
        link: ({ data }) => [ACCESSORY_DISCOUNT_ROUTER_PREFIX, 'apply-info', data.id],
        queryParams: ({ data }) => ({
          dealerId: _.get(data, 'dealerId'),
        }),
      },
    },
    {
      headerName: '',
      width: 260,
      field: 'incidentBusinessManagementData',
      cellRendererFramework: LinkComponent,
      cellRendererParams: {
        text: () => '事故业务管理数据',
        link: ({ data }) => [ACCESSORY_DISCOUNT_ROUTER_PREFIX, 'business-management', data.id],
        queryParams: ({ data }) => ({
          dealerId: _.get(data, 'dealerId'),
          gsCode: _.get(data, 'gsCode'),
          dealerName: _.get(data, 'dealerName'),
          statisticalMonth: _.get(data, 'statisticalMonth')
        }),
      },
    },
    {
      headerName: '状态',
      field: 'status',
      width: 140,
      headerClass: 'center-box',
      cellClass: 'center-box',
      cellRendererFramework: TagRendererComponent,
      cellRendererParams: () => ({
        text: ({ data }) => applyStatusTranslation[data.status],
        hostClassName: ({ data }) => `apply-status ${applyStatusStyleClass[data.status]}`,
      }),
    },
    {
      headerName: '提交时间',
      field: 'submittedTime',
      valueFormatter: dateFormatter,
    },
  ];
};

export const currentDate = moment().toDate();
export const currentMonthBeforeYearDate = getMonthBeforeYear(1).toDate();

export const queryFormControls = {
  statisticalStartMonth: new FormControl(null),
  statisticalEndMonth: new FormControl(null),
  statuses: new FormControl(null),
  gsCode: new FormControl(null),
  submitStartDate: new FormControl(null),
  submitEndDate: new FormControl(null),
  monthDimension: new FormControl('month'),
  dayDimension: new FormControl('day'),
};

export const getStatusOptions = () => {
  return _.chain(applyStatusTranslation).keys().reduce((options, key) => {
    return [...options, {
      text: applyStatusTranslation[key],
      value: key,
    }];
  }, []).value();
};

const datePickConfig = {
  offsetRangeNumber: 1,
  isRangeLimited: true,
  limitValueRangeForDealer: 12 * 100,
  limitValueRange: 12,
  startLimitValue: currentDate,
  endLimitValue: currentDate,
  endMaxLimitValue: currentDate,
  startTimeRequired: false,
  endTimeRequired: false
};

const monthBeforeYear = getMonthBeforeYear(100);
export const monthDatePicker = {
  ...datePickConfig,
  offsetRangeUnit: 'month',
  startMinLimitValue: monthBeforeYear,
  endMinLimitValue: monthBeforeYear,
};

const dayBeforeFiveYear = getDayBeforeYear(5);
export const dayDatePicker = {
  ...datePickConfig,
  startMinLimitValue: dayBeforeFiveYear,
  endMinLimitValue: dayBeforeFiveYear,
};

export const defaultConfirmedNotification = {
  confirmed: false,
  content: '',
  title: '',
};

export const defaultTipsInfo = {
  show: false,
  content: '',
};

import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appMousedownOutside]',
})
export class MousedownOutsideDirective {

  @Output() mousedownOutside = new EventEmitter<MouseEvent>();

  constructor(private _elementRef: ElementRef) {
  }

  @HostListener('document:mousedown', ['$event', '$event.target'])
  public onMousedown(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const mousedownInside = this._elementRef.nativeElement.contains(targetElement);
    if (!mousedownInside) {
      this.mousedownOutside.emit(event);
    }
  }
}

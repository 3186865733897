import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

const getValue = (value, params, currentValue) => (_.isFunction(value) ? value.call(null, params, currentValue) : value);

@Component({
  template: `
    <div *ngIf="!editing && !submitting" (click)="onClick()">
      <span>{{ value }}</span>
    </div>
    <div *ngIf="editing && !submitting" (click)="onClickSubmit()">
      <span>{{ value }}</span>
    </div>
    <i *ngIf="submitting" class="fa antd-loading-outlined antd-pulse antd-3x antd-fw"></i>
    <span class="visually-hidden">Loading...</span>
  `,
  styles: [],
})
export class GoodsReceiveActionLinkComponent implements ICellRendererAngularComp {
  params;
  value: string;
  editing: boolean = false;
  submitting: boolean = false;

  agInit(params: ICellRendererParams): void {
    const cellRendererParams = params.colDef.cellRendererParams;
    this.params = params;
    this.value = getValue(cellRendererParams.value, params, this.editing) || '';
  }

  onClick() {
    'onClick' in this.params.column.colDef.cellRendererParams
      ? this.params.column.colDef.cellRendererParams.onClick(this.params)
      : ((): void => {})();

    this.setEditingAndLabelValue();
  }

  onClickSubmit() {
    const rowNode = this.params.node;

    if (this.validateMandatoryFields(this.params.data) && this.validateReceiveQuantity(this.params.data)) {
      this.submitting = true;

      this.params.data.editing = false;
      this.params.api.stopEditing(false);

      rowNode.setData(this.params.data);
      this.restoreEditingState();
      this.params.column.colDef.cellRendererParams.onClickSubmit(this.params);
    } else if (!this.validateMandatoryFields(this.params.data)) {
      this.params.data.invalid = true;
      rowNode.setData(this.params.data);

      this.params.api.redrawRows();
      this.restoreEditingState();
    } else if (!this.validateReceiveQuantity(this.params.data)) {
      this.params.data.invalid = true;
      rowNode.setData(this.params.data);

      this.params.api.redrawRows({ rowNodes: [rowNode] });
      this.restoreEditingState();
    } else if (!this.isReceivedQuantityZero(this.params.data)) {
      this.params.data.invalid = true;
      rowNode.setData(this.params.data);

      this.params.api.redrawRows({ rowNodes: [rowNode] });
      this.restoreEditingState();
      this.params.column.colDef.cellRendererParams.promptError('Receive quantity cannot be 0');
    }
  }

  private validateMandatoryFields({ receiveQuantity, receivingNumber }) {
    return receiveQuantity && receivingNumber;
  }

  public validateReceiveQuantity({ receiveQuantity }) {
    const integersOnly = new RegExp(`[1-9][0-9]*$`);
    return integersOnly.test(receiveQuantity);
  }

  private isReceivedQuantityZero({ receivedQuantity }) {
    return receivedQuantity === 0;
  }

  private restoreEditingState() {
    const allEditingNodes = this.params.api.getRenderedNodes().filter(node => node.data.editing);
    allEditingNodes.map(node => {
      const startEditingParams = {
        rowIndex: node.rowIndex,
        colKey: this.params.column.getId(),
      };
      this.params.api.startEditingCell(startEditingParams);
      return node;
    });
  }

  private setEditingAndLabelValue() {
    this.editing = !this.editing;
    this.value = getValue(this.params.colDef.cellRendererParams.value, this.params, this.editing) || '';
  }

  refresh(params: any): boolean {
    return false;
  }
}

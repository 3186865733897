import { switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SimilarVehicleApi } from '../../_common/api/similar-vehicle';
import { OrdersService } from '../../_common/services/orders.service';

@Injectable()
export class SimilarVehicleService {
  similarVehicles: Array<any>;

  constructor(private similarVehicleApi: SimilarVehicleApi,
              private orderService: OrdersService) {
  }

  fetchSimilarVehicleList(orderId: string) {
    return this.similarVehicleApi.getSimilarVehiclesV2(orderId).pipe(tap(similarVehicles => {
      this.similarVehicles = similarVehicles;
    }));
  }

  getSimilarVehicleList() {
    return this.similarVehicles;
  }

  changeSimilarVehicle(orderId: string, commissionId: string, comment: string) {
    return this.similarVehicleApi.changeSimilarVehicle({ orderId, commissionId, comment }).pipe(
      switchMap(order => this.orderService.getOrder(order.id)),
    );
  }
}

import { Injectable } from '@angular/core';
import {
  Body,
  DELETE,
  GET,
  Header,
  Headers,
  PATCH,
  Path,
  POST,
  PUT,
  Query,
  RebirthHttp,
  RequestOptions,
} from 'rebirth-http';
import { Observable } from 'rxjs';
import {
  ActivateSsscResponse,
  AutoActiveProducts,
  ProductResponse,
  ProductWithDealersResponse,
  QuotationHistory,
  ResponsePage,
  SsscPackage,
  SsscRequest,
} from '../models/sssc-collection';
import { ServiceContractDocumentRequest } from '../models/service-contract-documents';
import {
  BasicCarInfo,
  HistoryOrdersQueryFilter,
  SalesRecordQueryFilter,
  ServiceContractProductResponse,
} from '../models/quotation-collection';
import {
  LpoOrderLinkRequest,
  UpdateProductInfoWithDealersDto,
  UsedCarRelatedDate,
} from '../models/service-contract-product';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { LocalQuotation, LocalQuotationWithCoupon } from '../models/service-contract';

@Injectable()
export class ServiceContractApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @POST('/api/website/service-contract/quotations')
  saveQuotationInfo(@Body data: Object): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/quotations/:id')
  getQuotation(@Path('id') id: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/lpo-service-contract/orders/detail')
  getLpoOrderDetail(@Query('gsCode') gsCode: string,
                    @Query('lpoOrderNo') lpoOrderNo: string,
                    @Query('isExtend') isExtend: boolean): Observable<any> {
    return null;
  }

  @POST('/api/website/service-contract/sales-records/batch')
  getSsscSalesRecordDetail(@Body queryDto): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/quotations/silver-history')
  getSilverQuotationHistory(@Query('fin') fin: string): Observable<QuotationHistory> {
    return null;
  }

  @GET('/api/website/service-contract/quotations/extend-warranty-history')
  getExtendQuotationHistory(@Query('fin') fin: string): Observable<QuotationHistory> {
    return null;
  }

  @GET('/api/website/service-contract/quotations')
  getQuotationsBySa(
    @Query('filter') filter: string,
    @Query('pageSize') pageSize: number,
    @Query('pageNumber') pageNumber: number,
  ): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/oem/quotations')
  getQuotationsForOem(
    @Query('filter') filter: string,
    @Query('pageSize') pageSize: number,
    @Query('pageNumber') pageNumber: number,
  ): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/quotations/history')
  getGeneratedQuotationsByFinOrVin(@Query('fin') fin: string, @Query('vin') vin: string) {
    return null;
  }

  @POST('/api/website/service-contract/quotations/:id/active')
  activateQuotation(@Path('id') id: string): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/quotations/:id/pdf')
  @Headers({ isExportFile: true })
  printQuotation(@Path('id') id: string, @Query('type') type: string): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/local-quotations/:otrQuotationNumber/pdf')
  @Headers({ isExportFile: true })
  printLpoQuotation(@Path('otrQuotationNumber') otrQuotationNumber: string): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/quotations/excel')
  @Headers({ isExportFile: true })
  exportQuotationExcel(@Query('filter') filter: string): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/products/available')
  getVehicleServiceProducts(
    @Query('fin') fin: string,
    @Query('vin') vin: string,
    @Query('mileage') currentMile: string,
    @Query('dealerId') dealerId: string,
    @Query('gsCode') gsCode: string,
    @Query('guaranteeStartDate') guaranteeStartDate?: string,
    @Query('firstRegistrationDate') firstRegistrationDate?: string
  ): Observable<ServiceContractProductResponse> {
    return null;
  }

  @GET('/api/website/service-contract/products/bi-warranty')
  getVehicleServiceBiWarrantyProducts(
    @Query('fin') fin: string,
    @Query('firstRegistrationDate') firstRegistrationDate: string,
    @Query('mileage') mileage: string,
    @Query('dealerId') dealerId: string,
  ): Observable<any> {
    return null;
  }

  @POST('/api/website/service-contract/sales-support/sssc-quotations/package')
  saveSsscQuotationInfo(@Header('Vehicle-Business-Type') vehicleBusinessType: string, @Body data: SsscRequest[]): Observable<any> {
    return null;
  }

  @PUT('/api/website/service-contract/sales-support/sssc-quotations/package')
  updateSsscQuotationInfo(@Header('Vehicle-Business-Type') vehicleBusinessType: string, @Body data: SsscRequest[]): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/sssc-quotations')
  getSsscQuotations(
    @Query('filter') filter: string,
    @Query('pageSize') pageSize: number,
    @Query('pageNumber') pageNumber: number,
  ): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/sssc-quotations/detail/:id')
  getSsscQuotationById(@Path('id') id: number): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/sssc-quotations/:fin')
  getSsscQuotationDetailsByFin(@Path('fin') fin: string) {
    return null;
  }

  @GET('/api/website/service-contract/oem/sssc-quotations/:fin')
  getOemSsscQuotationDetailsByFin(@Path('fin') fin: string) {
    return null;
  }

  @GET('/api/website/service-contract/sssc-quotations/histories')
  getHistorySsscQuotations(
    @Query('filter') filter: string,
    @Query('pageSize') pageSize: number,
    @Query('pageNumber') pageNumber: number,
  ): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/oem/sssc-quotations')
  getSsscQuotationsForOem(
    @Query('filter') filter: string,
    @Query('pageSize') pageSize: number,
    @Query('pageNumber') pageNumber: number,
  ): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/sssc-products')
  getSsscProducts(
    @Header('Vehicle-Business-Type') vehicleBusinessType: string,
    @Query('fin') fin: string,
    @Query('firstRegistrationDate') firstRegistrationDate: string,
  ) {
    return null;
  }

  @GET('/api/website/service-contract/sales-support/sssc-product')
  getLpoSsscProducts(@Header('Vehicle-Business-Type') vehicleBusinessType: string, @Query('fin') fin: string) {
    return null;
  }

  @DELETE('/api/website/service-contract/sssc-quotations')
  deleteSsscQuotations(@Body ids: Array<string>) {
    return null;
  }

  @DELETE('/api/website/service-contract/sssc-quotations/:ssscQuotationId')
  deleteSsscQuotation(@Path('ssscQuotationId') ssscQuotationId: number) {
    return null;
  }

  @DELETE('/api/website/service-contract/auto-active-product')
  deleteAutoActiveProduct(@Query('id') id: any) {
    return null;
  }

  @POST('/api/website/service-contract/sssc-quotations/activate')
  activateSsscQuotations(@Body ids: Array<string>): Observable<ActivateSsscResponse> {
    return null;
  }

  @POST('/api/website/service-contract/sssc-quotations/validate:package')
  validateSsscQuotation(@Header('Vehicle-Business-Type') vehicleBusinessType: string, @Body data: SsscRequest[]): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/sssc-quotations/excel')
  @Headers({ isExportFile: true })
  exportSsscQuotationExcel(@Query('filter') filter: string): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/service-contract-documents')
  @Headers({ isExportFile: true })
  getServiceContractDocument(@Query('name') name): Observable<any> {
    return null;
  }

  @POST('/api/website/service-contract/service-contract-documents')
  updateAndCreateServiceContractDocument(@Body data: Array<ServiceContractDocumentRequest>): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/service-contract-documents')
  getAllServiceContractDocuments(): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/products')
  getAllServiceContractProducts(): Observable<Array<ProductResponse>> {
    return null;
  }

  @GET('/api/website/service-contract/products/getNonBiWarrantyProductInfos')
  getNonBiWarrantyProductInfos(): Observable<Array<ProductResponse>> {
    return null;
  }

  @POST('/api/website/service-contract/products/:productCode/inactive')
  postInactiveBiWarrantyProduct(@Path('productCode') productCode: string): Observable<any> {
    return null;
  }

  @POST('/api/website/service-contract/products/save')
  postAddWarrantyProductInfos(@Body data: any) {
    return null;
  }

  @GET('/api/website/service-contract/products')
  getServiceContractProductsByProductName(
    @Query('productName') productName: string,
    @Query('pageNumber') pageNumber: number,
    @Query('pageSize') pageSize: number,
  ): Observable<ResponsePage<ProductWithDealersResponse>> {
    return null;
  }

  @POST('/api/website/service-contract/products')
  @Headers({ 'X-OTR-AS-COMMAND': 'updateDealerProducts' })
  updateProductInfoWithDealers(@Body updateData: UpdateProductInfoWithDealersDto) {
    return null;
  }

  @DELETE('/api/website/service-contract/service-contract-documents/:id')
  deleteServiceContractDocument(@Path('id') id: number): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/sssc-packages')
  getSsscPackages(
    @Query('filter') filter: string,
    @Query('pageSize') pageSize: number,
    @Query('pageNumber') pageNumber: number,
  ): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/auto-active-product')
  getAutoActiveProduct(
    @Query('filter') filter: string,
    @Query('pageSize') pageSize: number,
    @Query('pageNumber') pageNumber: number,
  ): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/auto-active-product/getStandardProductInfos')
  getStandardProductInfos(): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/auto-active-product/lpo-product')
  getStandardLpoProductInfos(): Observable<any> {
    return null;
  }

  @POST('/api/website/service-contract/sssc-packages')
  createSsscPackages(@Body ssscPackage: SsscPackage[]) {
    return null;
  }

  @POST('/api/website/service-contract/auto-active-product')
  createAutoActiveProducts(@Body autoActiveProducts: AutoActiveProducts) {
    return null;
  }

  @PATCH('/api/website/service-contract/sssc-packages/:packageId')
  updateSsscPackages(@Path('packageId') packageId: string, @Body data: SsscPackage[]): Observable<any> {
    return null;
  }

  @PATCH('/api/website/service-contract/auto-active-product/:id')
  updateAutoActiveProducts(@Path('id') id: number, @Body data: AutoActiveProducts): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/sssc-quotations/dict/status')
  getSsscQuotationStatus(): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/oem/sssc-quotations/dict/status')
  getSsscQuotationStatusForOem(): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/quotations/dict/status')
  getStandaloneQuotationStatus(): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/oem/quotations/dict/status')
  getStandaloneQuotationStatusForOem(): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/pilot-dealers')
  getPilotDealers(): Observable<any> {
    return null;
  }

  @POST('/api/website/service-contract/products')
  @Headers({ 'X-OTR-AS-COMMAND': 'syncProducts' })
  syncProducts(): Observable<any> {
    return null;
  }

  @POST('/api/website/service-contract/oem/export/quotation')
  exportQuotationsForOem(@Body filter: HistoryOrdersQueryFilter) {
    return null;
  }

  @GET('/api/website/service-contract/oem/export/task-status')
  getExportQuotationsTaskStatus(@Query('taskId') taskId: string) {
    return null;
  }

  @GET('/api/website/service-contract/oem/export/excel/:name')
  @Headers({ isExportFile: true })
  exportQuotationsFile(@Path('name') name: string) {
    return null;
  }

  @POST('/api/website/service-contract/oem/export/sssc-quotation')
  exportSsscQuotationForOem(@Body filter: any) {
    return null;
  }

  @GET('/api/website/service-contract/oem/quotations/:id')
  getQuotationForOem(@Path('id') id: string) {
    return null;
  }

  @PUT('/api/website/service-contract/quotations/:id/customer-info')
  updateCustomerInfo(@Path('id') id: string, @Body customerData: object) {
    return null;
  }

  @PATCH('/api/website/service-contract/quotations/:id')
  @Headers({ 'X-OTR-AS-COMMAND': 'updateRetailPrice' })
  updateQuotationRetailPrice(@Path('id') id: number, @Body retailPriceInfo: object) {
    return null;
  }

  @PATCH('/api/website/service-contract/quotations/:id')
  @Headers({ 'X-OTR-AS-COMMAND': 'updateSalesComment' })
  updateQuotationSalesComment(@Path('id') id: number, @Body salesCommentInfo: object) {
    return null;
  }

  @GET('/api/website/service-contract/quotations/dict/sales-channels')
  getStandaloneQuotationSalesChannels(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/lpo-service-contract/download/dealer-price-excel-template')
  @Headers({ isExportFile: true })
  getServiceContractPriceConfigFile(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/lpo-service-contract/download/dealer-price-configuration-excel/extend')
  @Headers({ isExportFile: true })
  getExtendServiceContractPriceConfigFile(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/lpo-service-contract/download/dealer-price-excel')
  @RequestOptions({ responseType: 'blob', observe: 'response' })
  getLatestUploadServiceContractPriceFile(): Observable<HttpResponse<any>> {
    return null;
  }

  @GET('/api/sales-website/lpo-service-contract/download/dealer-price-configuration-excel')
  @Headers({ isExportFile: true })
  getServiceContractRetailPriceConfigFile(): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/lpo-service-contract/car-info/qrcode-content')
  @RequestOptions({ responseType: 'text' })
  getLpoQrcodeWithCarInfo(@Body carInfo: BasicCarInfo): Observable<any> {
    return null;
  }

  @POST('/api/website/service-contract/sales-records')
  getLpoSalesRecords(
    @Body salesRecordQueryDTO: SalesRecordQueryFilter,
    @Query('size') pageSize: number,
    @Query('page') pageNumber: number,
  ): Observable<any> {
    return null;
  }

  @GET('/api/website/dealers/roles/:privilegeCode')
  getRolesByPrivilegeCode(@Path('privilegeCode') privilegeCode: string): Observable<any> {
    return null;
  }

  @POST('/api/website/service-contract/sales-records/export')
  @Headers({ isExportFile: true })
  exportCouponSalesHistoryExcel(@Body salesRecordQueryWithExportDTO: SalesRecordQueryFilter): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/lpo-service-contract/lpo-order-links')
  @RequestOptions({ responseType: 'text' })
  createLpoOrderLink(@Body qrCodeRequest: LpoOrderLinkRequest): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/lpo-service-contract/lpo-online-orders/:lpoOrderNumber/link')
  @RequestOptions({ responseType: 'text' })
  getLpoOrderLink(@Path('lpoOrderNumber') lpoOrderNumber: string): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/lpo-service-contract/local-quotations/lpo-orders')
  @RequestOptions({ responseType: 'text' })
  saveLocalQuotationInfo(@Body data: LocalQuotation): Observable<string> {
    return null;
  }

  @GET('/api/sales-website/lpo-service-contract/local-quotations/:otrQuotationNumber')
  fetchLocalQuotationInfo(@Path('otrQuotationNumber') otrQuotationNumber: string): Observable<LocalQuotationWithCoupon> {
    return null;
  }

  @POST('/api/sales-website/lpo-service-contract/local-quotations/:otrQuotationNumber/lpo-orders:create')
  createLpoOrder(@Path('otrQuotationNumber') otrQuotationNumber: string): Observable<any> {
    return null;
  }

  @PATCH('/api/sales-website/lpo-service-contract/local-quotations/:otrQuotationNumber:active')
  activeLpoOrder(@Path('otrQuotationNumber') otrQuotationNumber: string): Observable<any> {
    return null;
  }

  @PATCH('/api/sales-website/lpo-service-contract/local-quotations/:otrQuotationNumber:cancel')
  cancelLpoOrder(@Path('otrQuotationNumber') otrQuotationNumber: string): Observable<any> {
    return null;
  }

  @PATCH('/api/sales-website/lpo-service-contract/lpo-online-orders/:lpoOrderNumber:cancel')
  cancelOnlineOrder(@Path('lpoOrderNumber') lpoOrderNumber: string, @Query('isExtend') isExtend): Observable<any> {
    return null;
  }

  @PATCH('/api/website/service-contract/local-quotations/:otrQuotationNumber/customer-info')
  updateLocalQuotationCustomerInfo(@Path('otrQuotationNumber') otrQuotationNumber: string, @Body customerData: object): Observable<any> {
    return null;
  }

  @PATCH('/api/website/service-contract/local-quotations/update-price-tax-free/:otrQuotationNumber')
  updatePriceTaxFreeAndFinancialChannel(
    @Path('otrQuotationNumber') otrQuotationNumber: string,
    @Body priceTaxFreeAndFinancialChannelInfo: object,
  ): Observable<any> {
    return null;
  }

  @PATCH('/api/website/service-contract/local-quotations/update-sales-comment/:otrQuotationNumber')
  updateLocalQuotationSalesComment(
    @Path('otrQuotationNumber') otrQuotationNumber: string,
    @Body salesCommentInfo: object,
  ): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/refundable-extended-warranty/van/quotations')
  retrieveVanQuotationByFin(@Query('fin') fin: string) {
    return null;
  }

  @GET('/api/website/service-contract/refundable-extended-warranty/van/quotations/:externalId')
  retrieveVanQuotationsByExternalId(@Query('fin') fin: string, @Path('externalId') externalId: string) {
    return null;
  }

  @GET('/api/website/service-contract/refundable-extended-warranty/customized-gift-package/contract-template')
  @Headers({ isExportFile: true })
  downloadCustomizePackageContractTemplate(@Query('fileName') fileName: string) {
    return null;
  }

  @POST('/api/website/service-contract/refundable-extended-warranty/customized-gift-package')
  @Headers({ 'No-Content-Type': 1 })
  createCustomizedGiftPackage(@Body createRefundPackageRequest: FormData) {
    return null;
  }

  @GET('/api/website/service-contract/refundable-extended-warranty/customized-gift-package')
  searchCustomizedGiftPackage(@Query('pageNumber') pageNumber, @Query('pageSize') pageSize, @Query('filter') filter) {
    return null;
  }

  @GET('/api/website/service-contract/refundable-extended-warranty/customized-gift-package/contract')
  @Headers({ isExportFile: true })
  downloadCustomizePackageContract(@Query('packageContractFileId') packageContractFileId,
                                   @Query('packageContractFileName') packageContractFileName: string) {
    return null;
  }

  @PUT('/api/website/service-contract/refundable-extended-warranty/customized-gift-package/:packageNumber')
  @Headers({ 'No-Content-Type': 1 })
  updateCustomizedGiftPackage(@Path('packageNumber') packageNumber, @Body createRefundPackageRequest: FormData) {
    return null;
  }

  @GET('/api/sales-website/lpo-service-contract/online-orders/:orderNumber/link')
  getOnlineOrderLink(@Path('orderNumber') orderNumber: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/lpo-service-contract/used-car/contract-effective-date')
  getUsedCarRelatedDate(@Query('categoryId') categoryId: string,
                        @Query('productId') productId: string,
                        @Query('usedCarInvoiceDate') usedCarInvoiceDate: string,
                        @Query('evaFirstRegistrationDate') firstRegistrationDate: string,
                        @Query('fin') fin: string): Observable<UsedCarRelatedDate> {
    return null;
  }

  @DELETE('/api/website/service-contract/local-quotations/used-car/certificate-file')
  removeUploadFile(@Query('fileName') fileName: string): Observable<any> {
    return null;
  }

  @GET('/api/website/service-contract/local-quotations/used-car/certificate-file')
  @Headers({ isExportFile: true })
  previewUploadFile(@Query('fileName') fileName: string): Observable<string> {
    return null;
  }
}

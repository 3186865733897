import * as _ from 'lodash';
import { NzCheckBoxOptionInterface } from 'ng-zorro-antd';
import { PREFERRED_CONTACT_CHANNEL } from '../../../../_common/constants/common';

export const preferredContactChannelUtils = {

  buildOptionsFromValue(values: string) {
    const channels = _.split(values, ',');
    return _.chain(PREFERRED_CONTACT_CHANNEL.values)
      .map(option => ({
        value: option.value,
        label: option.text,
        checked: _.includes(channels, _.get(option, 'value')),
      }))
      .value();
  },

  buildValueFromOptions(options: Array<NzCheckBoxOptionInterface>) {
    return _.chain(options)
      .filter('checked')
      .map('value')
      .join(',')
      .value();
  },
};

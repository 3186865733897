import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OutletShareStockApi } from '../api/outlet-share-stock';

@Injectable()
export class OutletShareStockService {
  constructor(private outletShareStockApi: OutletShareStockApi) {
  }

  getShareStockConfig(): Observable<any> {
    return this.outletShareStockApi.getShareStockConfig();
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-simple-tab',
  template: `
    <ng-content *ngIf="active"></ng-content>
  `,
})
export class SimpleTabComponent {
  @Input() title = '';
  @Input() active = false;
  @Input() disabled = false;
  @Input() dot = false;
}

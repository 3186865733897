import { Injectable } from '@angular/core';
import { Body, GET, POST, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OrderCancelCommentApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/website/dealers/settings/cancel-comments')
  getOrderCancelComments(): Observable<any> {
    return null;
  }

  @POST('/api/website/dealers/settings/cancel-comments')
  saveCancelComments(@Body data): Observable<any> {
    return null;
  }

  @POST('/api/website/dealers/settings/cancel-comments/role-group')
  saveGroupCancelComments(@Body data): Observable<any> {
    return null;
  }
}

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { PasswordResetService } from '../services/password-reset.service';

@Component({
  selector: 'app-password-reset-validate-form',
  templateUrl: './validate-form.component.html',
  styleUrls: ['./validate-form.component.scss'],
})
export class PasswordResetValidateFormComponent {
  form: FormGroup;

  constructor(private service: PasswordResetService) {
    this.form = service.form;
  }

  submit() {
    this.service.validate()
      .subscribe();
  }
}

import { filter, takeUntil } from 'rxjs/operators';
import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

import { PromptLevel } from '../../constants/common';
import { TabForPartsPurchaseService } from 'app/_layout/tab-for-parts-purchase/tab.service';

import { NavigationStart, Router } from '@angular/router';
import { AutoUnsubscribe } from 'app/_common/utils/autoUnsubscribe';
import { Observable, queueScheduler, Subscription } from 'rxjs';
import { PromptBarConfig, TabVehicleBusinessTypeService } from '@otr/website-common';

const containerClassMap = {
  [PromptLevel.Success]: 'normal',
  [PromptLevel.Info]: 'normal',
  [PromptLevel.Warning]: 'error',
  [PromptLevel.Error]: 'error',
  [PromptLevel.Emergency]: 'emergency',
};

@AutoUnsubscribe()
@Component({
  selector: 'app-prompt-bar',
  styleUrls: ['./prompt-bar.style.scss'],
  templateUrl: './prompt-bar.template.html',
})
export class PromptBarComponent implements OnInit, OnChanges {
  content: string;
  level: number;

  @Input() config: PromptBarConfig;

  successLevel: number = PromptLevel.Success;
  infoLevel: number = PromptLevel.Info;
  warningLevel: number = PromptLevel.Warning;
  errorLevel: number = PromptLevel.Error;
  emergencyLevel: number = PromptLevel.Emergency;
  private componentDestroy$: Observable<any>;
  @HostBinding('class.shown')
  private shown: boolean = false;
  containerClass: string = '';
  private subscription: Subscription = Subscription.EMPTY;

  constructor(
    private tabVehicleBusinessTypeService: TabVehicleBusinessTypeService,
    private tabForPartsPurchaseService: TabForPartsPurchaseService,
    private router: Router) {}

  @HostBinding('class.has-tab')
  get hasTab(): boolean {
    return !_.isEmpty(this.tabVehicleBusinessTypeService.getTabs())
      || !_.isEmpty(this.tabForPartsPurchaseService.getTabs())
      || this.config.hasTab;
  }

  ngOnInit() {
    this.router.events.pipe(
      takeUntil(this.componentDestroy$),
      filter(e => e instanceof NavigationStart))
      .subscribe(() => this.hide());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const config = _.get(changes, 'config.currentValue', null) as PromptBarConfig;
    if (config) {
      this.configMessage(config);
    }
  }

  private configMessage(config) {
    if (!config.content) {
      return this.hide();
    }
    this.content = config.content;
    this.level = config.level;
    this.containerClass = containerClassMap[this.level];
    this.show(config);
  }

  private hide() {
    this.shown = false;
  }

  private show(config) {
    this.shown = true;
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
    if (!config.isPreserved) {
      const duration = config.duration || 40000;
      this.subscription = queueScheduler.schedule(() => this.hide(), duration);
    }
  }
}

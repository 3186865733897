import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-delete-with-disable',
  templateUrl: './delete-with-disable.component.html',
  styleUrls: ['./delete-with-disable.component.scss'],
})
export class DeleteWithDisableComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  isDeleting: boolean = false;
  isDisable: boolean = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isDisable = this.params.colDef.cellRendererParams.isDisable(params);
  }

  refresh(params: any): boolean {
    return false;
  }

  onCancel() {
    this.isDeleting = false;
  }

  onDelete() {
    this.params.colDef.cellRendererParams.onDelete(this.params.node.data);
  }

  onStartDeleting() {
    this.isDeleting = true;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

import { ORDER_STATUS } from '../../vehicle/list/fields-config';

@Pipe({ name: 'orderHistoryStatus' })
export class OrderHistoryStatusPipe implements PipeTransform {
  transform(status: string): string {
    return _.get(_.find(ORDER_STATUS, { value: status }), 'text', '');
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CurrentUserService, TokenService } from '@otr/website-common';
import { setInitVehicleBusinessType } from '../../utils/vehicle-business-type';

@Component({
  selector: 'app-vehicle-business-type-switch-tab',
  templateUrl: './vehicle-business-type-switch-tab.component.html',
})
export class VehicleBusinessTypeSwitchTabComponent implements OnInit {
  @Output() onTabChanged: EventEmitter<string> = new EventEmitter();
  isVanPc = false;
  constructor(private tokenService: TokenService,
              private currentUserService: CurrentUserService) { }

  ngOnInit(): void {
    this.isVanPc = this.tokenService.isVanPc('AS');
    setInitVehicleBusinessType(this.currentUserService, this.tokenService);
  }

  onTabChange(value: string): void {
    this.onTabChanged.emit(value);
  }
}

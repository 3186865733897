<form class="form-container">
  <div class="form-group">
    <label class="required form-label">本次跟进记录</label>
    <textarea class="form-control comment"
              placeholder="本次跟进的情况"
              maxlength="200"
              [formControl]="followUpComments">
    </textarea>
  </div>
  <div class="form-group">
    <label class="required form-label">下次跟进日期</label>
    <p-calendar
      [locale]="chineseCalendarConfig"
      dataType="string"
      dateFormat="yy-mm-dd"
      placeholder="跟进日期"
      [formControl]="nextFollowUpDate"
      [minDate]="minDate"
      [inputStyle]="nextFollowUpDate.invalid && nextFollowUpDate.touched ? invalidStyle : {}"
    >
    </p-calendar>
  </div>
  <div *ngFor="let lead of leads">
    <div class="form-group" *ngIf='shouldShowToBeLead(lead)'>
      <div class="lead-label" *ngIf='leads.length > 1'>
        <span class='business-model-type'>
          经销商：
        </span>
          <span class='lead-desc'>
          {{leadLabel}}
        </span>
      </div>
      <label>{{getLabelTitle(lead)}}</label>
      <ng-container>
        <div class="radio-control-group" >
          <span class="radio-control">
            <input
              type="radio"
              value="B"
              name="B-select"
              [formControl]="changeStatusControl"
            />是
          </span>
        </div>
        <div class="radio-control-group" >
          <span class="radio-control">
            <input
              type="radio"
              value="notB"
              name="B-select"
              [formControl]="changeStatusControl"
            />否
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</form>

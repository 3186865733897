import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

import { BMBS_DEALER_TYPE } from '../constants/option-management';

@Pipe({ name: 'formatDealer' })
export class FormatDealerPipe implements PipeTransform {
  transform(dealer: string): string {
    const type = _.find(BMBS_DEALER_TYPE, item => item.value === dealer);
    return type && type.text ? type.text : '';
  }
}

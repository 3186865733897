import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ModalPosition, PositionedModal } from '../../../../../../_common/components/modal/services/position';
import * as _ from 'lodash';
import { formatNumberWithDigits } from '../../../../../../_common/utils/format-number';
import { markAllFieldsAsTouched } from '../../../../../../_common/utils/form';

export interface DownPaymentFormOptions {
  downPaymentSum: number;
  downPaymentPercentage: number;
  transactionPrice: number;
}

@Component({
  selector: 'app-down-payment-form-modal',
  templateUrl: './down-payment-form-modal.component.html',
  styleUrls: ['./down-payment-form-modal.component.scss'],
})
export class DownPaymentFormModalComponent implements PositionedModal {
  subject: Subject<any>;
  left: number;
  top: number;
  externalLeft: number = -235;
  externalTop: number = 10;
  form: FormGroup;
  options: DownPaymentFormOptions;

  @ViewChild('downPayment') downPaymentForm: ElementRef;

  constructor(private formBuilder: FormBuilder) {
    this.subject = new Subject();
  }

  getClientHeight(): number {
    const nativeElement = this.downPaymentForm.nativeElement;
    return nativeElement.getBoundingClientRect().height;
  }

  getClientWidth(): number {
    const nativeElement = this.downPaymentForm.nativeElement;
    return nativeElement.getBoundingClientRect().width;
  }

  getSubject(): Subject<any> {
    return this.subject;
  }

  setPosition(position: ModalPosition): void {
    this.left = position.left + this.externalLeft;
    this.top = position.top + this.externalTop;
  }

  shouldShowError(controlPath) {
    const control = this.form.controls[controlPath];
    return control.touched && control.invalid;
  }

  setProperties(options: DownPaymentFormOptions): void {
    this.options = options;
    this.initForm();
  }

  cancel() {
    this.subject.next(null);
  }

  confirm() {
    markAllFieldsAsTouched(this.form);
    if (this.form.valid) {
      this.subject.next({
        downPaymentSum: this.form.controls.downPaymentSum.value,
        downPaymentPercentage: this.form.controls.downPaymentPercentage.value,
        transactionPrice: this.options.transactionPrice,
      });
    }
  }

  private initForm() {
    const downPaymentSum = formatNumberWithDigits(this.options.downPaymentSum, 2);
    const downPaymentPercentage = formatNumberWithDigits(this.options.downPaymentPercentage, 2);
    this.form = this.formBuilder.group({
      downPaymentSum: [downPaymentSum,
        [Validators.required, Validators.min(0)]],
      downPaymentPercentage: [downPaymentPercentage,
        [Validators.required, Validators.min(0), Validators.max(100)]],
    });
  }

  handleDownPaymentChange(field: string) {
    if (_.isEqual(field, 'downPaymentSum')) {
      this.form.patchValue({
        downPaymentPercentage: (this.form.controls[field].value / this.options.transactionPrice * 100).toFixed(2),
      });
    } else {
      this.form.patchValue({
        downPaymentSum: (this.form.controls[field].value * this.options.transactionPrice / 100).toFixed(2),
      });
    }
  }

  onEnterPress(field: string) {
    this.handleDownPaymentChange(field);
    this.confirm();
  }
}

import { Observable, of as observableOf, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LeadApi } from '../api/lead';

import { VehicleApi } from '../api/vehicle';
import { exportOnDifferentBrowsers } from '../utils/download';
import { ERROR_MESSAGES } from '../constants/error-messages';
import * as _ from 'lodash';
import { BranchLocationDescription } from '../models/branch';
import { confirmedStockOrderStatus, TABLE_FIELDS_FILTER } from '../../vehicle/list/fields-config';
import { fixNumber, formatAmountString } from '../utils/format-string';
import { PromptLevel, VEHICLE_VISIBILITY } from '../constants/common';

import { wrapBrandsWithOther, wrapBrandsWithOtherForCurrentVehicles } from '../utils/intrested-vehicle';
import { deleteHashCharacter } from '../utils/common';
import { SimpleModel } from '../models/variant-parameter';
import { dateParse } from '../utils/date-parse';
import { formatNumberWithCommas } from '../utils/format-number';
import { EXCEL_FILE_CONTENT_TYPE } from '../utils/campaign';
import {
  IGetVehicleAccessoryPostBody,
  IUpdateVehicleAccessoryPostBody,
  IVehicleAccessory,
} from '../models/vehicle-accessory';
import {
  CurrentUserService,
  DealerIntegrationToggleService,
  FeatureToggleService,
  PromptBarService,
  SpinnerService,
} from '@otr/website-common';
import * as moment from 'moment';

@Injectable()
export class VehicleService {
  brandsData: Object;
  interestedBrandsData: any;
  testDriveBrandsData: any;
  onlineFilter: any = {
    filter: null,
    filterForm: null,
  };
  locationDescriptions: BranchLocationDescription[] = [];
  models: SimpleModel[] = [];
  vehicleUpdatedSubject = new Subject();
  pageSize = 50;
  _dealerFlag: boolean = false;
  private _vanClasses: string[] = [];
  modelDFlag: any = '';
  nzOptionsWithCities: any;
  nzOptionsWithDistrict: any;
  cityTemplateOptions: any;
  cityTemplateConfigsFieldsName: any;
  _onlineSalesParams = {};

  set onlineSalesParams(data) {
    this._onlineSalesParams = data;
  }

  get onlineSalesParams() {
    return this._onlineSalesParams;
  }

  set dealerFlag(flag) {
    this._dealerFlag = flag;
  }

  get dealerFlag() {
    return this._dealerFlag;
  }

  async initVanClasses() {
    this._vanClasses = await this.vehicleApi.getVanClasses().toPromise();
  }

  constructor(
    private leadApi: LeadApi,
    private vehicleApi: VehicleApi,
    private spinnerService: SpinnerService,
    private currentUserService: CurrentUserService,
    private promptBarService: PromptBarService,
    private featureToggleService: FeatureToggleService,
    private dealerIntegrationToggleService: DealerIntegrationToggleService,
  ) {
  }

  refreshDataFromRTM(vin: string) {
    return this.vehicleApi.refreshDataFromRTM(vin);
  }

  getClaimExist(vinNo: string) {
    return this.vehicleApi.getClaimExist(vinNo);
  }

  getVehicle(commissionId: number): Observable<any> {
    return this.vehicleApi.getVehicle(commissionId, true);
  }

  getVanClasses() {
    return JSON.parse(sessionStorage.getItem('vanClasses'));
  }

  async setVanClasses() {
    if (_.isNil(this.getVanClasses())) {
      this.initVanClasses().then(() => {
        sessionStorage.setItem('vanClasses', JSON.stringify(this._vanClasses));
      });
    }
  }

  getShareVehicle(commissionId: number): Observable<any> {
    return this.vehicleApi.getShareVehicle(commissionId, true);
  }

  getVechileSeries() {
    return this.vehicleApi.getExtendedReleasedBrands();
  }

  setOnlineSalesFilter(filterItem) {
    this.onlineFilter = {
      ...this.onlineFilter,
      ...filterItem,
    };
  }

  getOnlineSalesFilter() {
    return this.onlineFilter;
  }

  getVechileSeriesModelD() {
    return this.vehicleApi.getExtendedReleasedBrands();
  }
  bookMbeCentralVehicle(quotationId: string): Observable<any> {
    return this.vehicleApi.bookMbeCentralVehicle(quotationId);
  }

  get isModelD() {
    return this.featureToggleService.isModelDFeatureToggleEnable && this.dealerIntegrationToggleService.isModelDEnabled();
  }

  filterBrand = (list) => _.filter(list, item => item.description_en !== 'DENZA' && item.description_en !== 'smart');

  getModelData() {
    this.getDataIfNotInCache(this.brandsData, this.leadApi.getBrandsData(),
      data => (this.brandsData = wrapBrandsWithOtherForCurrentVehicles(data)));
    this.getDataIfNotInCache(this.testDriveBrandsData, this.leadApi.getReleasedVehicleBrands(),
      data => (this.testDriveBrandsData = data));
    this.getDataIfNotInCache(this.interestedBrandsData, this.vehicleApi.getExtendedReleasedBrands(),
      data => (this.interestedBrandsData = wrapBrandsWithOther(this.filterBrand(data))));
    if (this.modelDFlag !== this.isModelD) {
      this.modelDFlag = this.isModelD;
      this.vehicleApi.getExtendedReleasedBrands()
        .subscribe(data => {
          this.interestedBrandsData = wrapBrandsWithOther(this.filterBrand(data));
        });
    }
  }

  getDataIfNotInCache(cachedData, getDataObservable, setFunction) {
    if (_.isEmpty(cachedData)) {
      getDataObservable.subscribe(setFunction, error => null);
    }
  }

  getDataSynchronization() {
    return this.vehicleApi.getDataSynchronizationByDealerId(this.currentUserService.getBranchDealerIdOrDealerId());
  }

  getDMSMatchData() {
    return this.vehicleApi.getDMSMatchDataByDealerId(this.currentUserService.getBranchDealerIdOrDealerId());
  }

  importDMSDataToStock(commissionNos: string[]) {
    return this.vehicleApi.importStockFromDMSManually(commissionNos);
  }

  deleteVehicle(commissionIds: number[]) {
    return this.vehicleApi.deleteVehicle(commissionIds);
  }

  updateVehicleByCommissionId(commissionId: number, data: Object) {
    const getPromptMessage = (status, message) => {
      const statusMessageMap = {
        500: ERROR_MESSAGES.UPDATE_FAILED,
        406: ERROR_MESSAGES.VEHICLE_CAN_NOT_EDIT,
        403: message,
      };
      return statusMessageMap[status] || '车辆信息保存失败';
    };
    return this.vehicleApi.updateVehicleByCommissionId(commissionId, data, true)
      .pipe(
        switchMap(() => {
          return this.getVehicle(commissionId).pipe(
            tap(response => this.vehicleUpdatedSubject.next([response])),
            catchError(error => {
              const errorBody = JSON.parse(error._body);
              this.promptBarService.show(getPromptMessage(error.status, errorBody.message));
              return observableThrowError(error);
            }),
          );
        }),
        catchError(error => {
          const errorBody = JSON.parse(error._body);
          this.promptBarService.show(getPromptMessage(error.status, errorBody.message));
          return observableThrowError(error);
        }),
      );
  }

  updateVehicleVisibilityByCommissionNos(commissionNos: string[], visibility: string) {
    const data = {
      commission_nos: commissionNos,
      dealer_id: this.currentUserService.getBranchDealerIdOrDealerId(),
      visibility,
    };
    return this.vehicleApi.updateVehicleVisibility(data);
  }

  exportReport(searchValue, filters) {
    return this.vehicleApi.getVehicleExcelReport(
      JSON.stringify(deleteHashCharacter(searchValue)),
      JSON.stringify(deleteHashCharacter(filters)),
    ).pipe(
      tap(response => exportOnDifferentBrowsers(response,
          EXCEL_FILE_CONTENT_TYPE, 'stock_list.xls'),
        () => this.promptBarService.show('数据导出失败', PromptLevel.Error),
      ));
  }

  paginateGetAllSharedVehicles(pageNumber, dealerId) {
    return this.vehicleApi.paginateGetAllSharedVehicles(pageNumber, this.pageSize, dealerId);
  }

  paginateSearchVehicles(page, searchValue, filters, dealerId, privilegeCode) {
    return this.vehicleApi.paginateSearchVehicles(
      page,
      this.pageSize,
      dealerId,
      privilegeCode,
      JSON.stringify(deleteHashCharacter(this.encodeURIChangeYear(searchValue))),
      JSON.stringify(deleteHashCharacter(filters)),
    );
  }

  paginateSearchShareVehicles(page, searchValue, filters) {
    return this.vehicleApi.paginateSearchVehicles(
      page,
      this.pageSize,
      null,
      null,
      JSON.stringify(deleteHashCharacter(this.encodeURIChangeYear(searchValue))),
      JSON.stringify(filters),
      true,
    );
  }

  encodeURIChangeYear(searchValue) {
    if (searchValue && searchValue.change_year) {
      searchValue.change_year = _.map(searchValue.change_year, item => encodeURIComponent(encodeURIComponent(item)));
    }
    return searchValue;
  }

  encodeURIModelName(name) {
    if (name) {
      return encodeURIComponent(name);
    }
    return name;
  }

  getReleasedBrands() {
    return this.vehicleApi.getReleasedVehicleBrands();
  }

  getReleasedBrandswithoutVariantDistinct() {
    return this.vehicleApi.getReleasedVehicleBrandsWithoutVariantDistinct();
  }

  getAllDealerMapping() {
    return this.vehicleApi.getAllDealerMappings();
  }

  getLogisticsInformation(vinNo) {
    return this.vehicleApi.getLogisticsInformation(vinNo);
  }

  formatVehicles(vehicles, currentPage) {
    return vehicles.map((vehicle, index) => this.formatVehicle(vehicle, index, currentPage));
  }

  formatVehicle(vehicle, index, currentPage) {
    const isConfirmedStockOrderStatus = _.includes(confirmedStockOrderStatus, vehicle.order.order_status);
    const formattedDepositAmount = vehicle.order.deposit_amount && formatAmountString(vehicle.order.deposit_amount, 2);
    const groups = ['detail', 'stock', 'order', 'price', 'packages'];
    const formattedFlattenFields = groups.reduce((result, group) => _.merge(result, vehicle[group]), {});
    const number = currentPage - 1;
    return _.mergeWith(
      vehicle,
      {
        order: {
          deposit_amount: formattedDepositAmount,
        },
      },
      {
        flattenFieldsForDisplay: _.mapValues(formattedFlattenFields, (value, key) => {
          if (TABLE_FIELDS_FILTER[key]) {
            return TABLE_FIELDS_FILTER[key](value, vehicle.order);
          }
          return value;
        }),
      },
      { flattenFields: formattedFlattenFields },
      { hasBeenSelected: false },
      {
        isDisabledCheckbox:
          isConfirmedStockOrderStatus ||
          vehicle.order.temporary_reserved === VEHICLE_VISIBILITY.VISIBLE ||
          vehicle.temporary_reserved,
      },
      { index: number * this.pageSize + index + 1 },
    );
  }

  setDefaultShareStatus(requestBody: Object) {
    return this.vehicleApi.setDefaultShareStatus(requestBody);
  }

  getDefaultShareRange() {
    return this.vehicleApi.getDefaultShareRange();
  }

  setShareRangeFor(selectedCommissionNumbers: string[], selectedRange: string, selectedDealersId?, groupName?) {
    const data = {
      commission_nos: selectedCommissionNumbers,
      share_range: selectedRange,
      share_dealers: selectedDealersId,
      group_name: groupName,
    };
    return this.vehicleApi.setShareRangeForSelectedVehicles(data);
  }

  setDefaultVisibleRoles(requestBody: Object) {
    return this.vehicleApi.setDefaultVisibleRoles(requestBody);
  }

  getDefaultVisibleRoles() {
    return this.vehicleApi.getDefaultVisibleRoles();
  }

  setSpecificShareVisibleRoles(commissionIds, visibleRoles) {
    return this.vehicleApi.setSpecificShareVisibleRoles({
      commission_ids: commissionIds,
      visible_roles: visibleRoles,
    });
  }

  removeUnUpdatedOptionalOptionProposalPrice(data) {
    const proposalPrice = data.price;
    const updatedProposalPrice = _.chain(proposalPrice)
      .keys()
      .map(key => ({ [key]: fixNumber(proposalPrice[key], 2) }))
      .reduce((sum, next) => ({ ...sum, ...next }))
      .value();
    return { ...data, price: updatedProposalPrice };
  }

  getAllVehicleBrand(allInterestedBrandsData) {
    return _.map(allInterestedBrandsData, (vehicleData: any) => ({
      text: vehicleData.brand_short_name,
      value: vehicleData.brand_short_name,
    }));
  }

  getAllVehicleClass(allInterestedBrandsData) {
    return this.formatVehicleData(allInterestedBrandsData, 'classes', 'class_short_name');
  }

  getAllVehicleModel(allInterestedBrandsData) {
    const vehicleClasses = _.flatMap(allInterestedBrandsData, (brand: any) => brand.classes);
    return this.formatVehicleData(vehicleClasses, 'models', 'model_long_name');
  }

  setSalesChannel(data) {
    return this.vehicleApi.setSalesChannel(data);
  }

  private formatVehicleData(rawData, valuePath, field) {
    return _.chain(rawData)
      .flatMap(data => data[valuePath])
      .uniqBy(field)
      .map(data => ({ value: data[field], text: data[field] }))
      .sortBy(data => _.lowerCase(data.text))
      .value();
  }

  getVehicleStatus(commissionNo): Observable<any> {
    return this.vehicleApi.getVehicleStatus(commissionNo);
  }

  batchUpdateActualArrivedDate(selectedCommissionNumbers: string[], actualArrivedDate: string, topping: string,
                               dealerId?: string, isShow?: boolean, isAgent?: boolean) {
    const data = {
      commission_nos: selectedCommissionNumbers,
      actual_arrived_date: actualArrivedDate,
      topping,
      current_display_branch_or_dealer_id: dealerId,
      is_show: isShow,
    };
    return this.vehicleApi.batchUpdateActualArrivedDate(data);
  }

  getAllModel(force = false): Observable<SimpleModel[]> {
    if (!_.isEmpty(this.models) && !force) {
      return observableOf(this.models);
    }
    return this.vehicleApi.getAllModels().pipe(
      tap((models: SimpleModel[]) => {
        this.models = models;
      }),
    );
  }

  updateVehicle(commissionNo, dealerId) {
    return this.vehicleApi.updateVehicle(commissionNo, dealerId).pipe(this.spinnerService.loading());
  }

  getVehicleImageByModels(models) {
    return this.vehicleApi.getVehicleImageByModels(models);
  }

  getFinancialVehicle(classId: string, modelName: string, extendReleased: boolean = false, businessModelType: string = 'B') {
    let _modelName = modelName;
    if (businessModelType === 'D') {
      _modelName = this.encodeURIModelName(modelName);
    }
    return this.vehicleApi.getFinancialVehicle(classId, _modelName, extendReleased, businessModelType);
  }

  getServiceHistories(fin: string, dealerId: string) {
    return this.vehicleApi.getServiceHistories(fin, dealerId);
  }

  getFormattedServiceHistories(serviceHistories: any) {
    return _.chain(serviceHistories)
      .groupBy(serviceHistory => dateParse(serviceHistory.repairDate, 'yyyy'))
      .reduce(
        (groupedServiceHistories, serviceHistoryList, groupKey) =>
          _.concat(groupedServiceHistories, {
            key: groupKey,
            value: _.map(serviceHistoryList, serviceHistory => ({
              ...serviceHistory,
              repairDate: dateParse(serviceHistory.repairDate, 'MM-dd'),
              mileage: formatNumberWithCommas(serviceHistory.mileage),
              amount: !_.isNumber(serviceHistory.amount) && _.isEmpty(serviceHistory.amount) ? '' : fixNumber(serviceHistory.amount),
            })),
          }),
        [],
      )
      .orderBy('key', 'desc')
      .value();
  }

  searchAccessoryList = (bodyData: IGetVehicleAccessoryPostBody, keyword: string = '') => {
    return this.vehicleApi.searchVehicleAccessoryList(keyword, '', [], 100, 1, bodyData)
      .pipe(map(data => this.handleSearchAccessoryListResponse(data)));
  };

  handleSearchAccessoryListResponse(response): IVehicleAccessory[] {
    const list = _.reduce(response, (sum, current) => _.concat(sum, _.get(current, 'content', [])), []);
    return _.map(list, (item): IVehicleAccessory => ({
      aftermarketProductId: item.aftermarket_product_id,
      accessoryId: item.id,
      bmbsDealer: item.bmbs_dealer,
      productName: item.product_name,
      retailPrice: item.retail_price,
      optionCode: item.dms_option_code,
      estimatedCost: item.estimated_cost,
      purchasePriceExcludingVat: item.purchase_price_excluding_vat,
    }));
  }

  updateVehicleAccessory(id: number, data: IUpdateVehicleAccessoryPostBody) {
    return this.vehicleApi.updateVehicleAccessory(id, data)
      .pipe(
        map(res => res),
        catchError(error => observableThrowError(_.get(error, 'error.error_code'))));
  }

  createShareScopeGroup(params) {
    return this.vehicleApi.createShareScopeGroup(params);
  }

  getShareScopeGroups() {
    return this.vehicleApi.getShareScopeGroups();
  }

  delShareScopeGroups(id) {
    return this.vehicleApi.delShareScopeGroups(id);
  }

  downloadStockTemplate() {
    return this.vehicleApi.getStockTemplate();
  }

  downloadStockTemplateFailedData(failedId) {
    return this.vehicleApi.getStockTemplateFailedData(failedId);
  }

  fetchGetVehicleBusinessType(typeClass) {
    return this.vehicleApi.getVehicleBusinessType(typeClass);
  }

  fetchSaveVehicleDamage(isSubmit, damageClaim) {
    return this.vehicleApi.saveVehicleDamage(isSubmit, damageClaim);
  }

  fetchDownloadQualityClaimAttachment(fileId) {
    return this.vehicleApi.downloadQualityClaimAttachment(fileId);
  }

  fetchDeleteQualityClaimAttachment(fileIds) {
    return this.vehicleApi.deleteQualityClaimAttachment(fileIds);
  }

  getDamageSelections() {
    return this.vehicleApi.getDamageSelections();
  }

  uploadDamageImageFile(file, vinNo): Observable<any> {
    return this.vehicleApi.uploadDamageImageFile(file, vinNo);
  }

  downloadDamageImageFile(fileId) {
    return this.vehicleApi.downloadDamageImageFile(fileId);
  }

  deleteDamageImageFile(fileIds) {
    return this.vehicleApi.deleteDamageImageFile(fileIds);
  }

  getSubmittedInfo(claimId, status) {
    return this.vehicleApi.getSubmittedInfo(claimId, status);
  }

  getNoSubmittedInfo(id, status) {
    return this.vehicleApi.getSubmittedInfo(id, status);
  }

  getDamageClaimNoSubmitList(params) {
    return this.vehicleApi.getDamageClaimNoSubmitList(params);
  }

  getDamageClaimSubmittedList(params) {
    return this.vehicleApi.getDamageClaimSubmittedList(params);
  }

  fetchDeleteDamageClaim(id): Observable<any> {
    return this.vehicleApi.fetchDeleteDamageClaim(id);
  }

  fetchCloseDamageClaim(claimId): Observable<any> {
    return this.vehicleApi.fetchCloseDamageClaim(claimId);
  }

  getDealerCode(dealerId) {
    return this.vehicleApi.getDealerCode(dealerId);
  }
}

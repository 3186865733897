import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-encrypted-button',
  templateUrl: './encrypted-button.component.html',
  styleUrls: ['./encrypted-button.component.scss'],
})
export class EncryptedButtonComponent {
  showEncryptedData: boolean = true;
  @Output()
  isShowEncryptedData: EventEmitter<boolean> = new EventEmitter<boolean>(this.showEncryptedData);

  get imgSrc(): string {
    return this.showEncryptedData ? '/assets/common/vector.svg' : '/assets/common/hide-vector.svg';
  }

  get text(): string {
    return this.showEncryptedData ? '查看原文' : '隐藏原文';
  }

  changeEncryptedSelected(): void {
    this.showEncryptedData = !this.showEncryptedData;
    this.isShowEncryptedData.emit(this.showEncryptedData);
  }
}

import { Injectable } from '@angular/core';
import { DutyApi } from '../api/duty';

@Injectable()
export class DutyService {

  constructor(private dutyApi: DutyApi) {
  }

  updateDuty(userId: number, date: number, status: string) {
    return this.dutyApi.updateDuty(userId, date, { status });
  }

}

import { Body, GET, POST, Query, RebirthHttp } from 'rebirth-http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SalesPerformanceUser, SalesPerformanceValue } from '../models/sales-performance-user';
import { SalesPerformanceWeeklyRange } from '../models/sales-performance-weekly-range';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SalesPerformanceApi extends RebirthHttp {
  static SALES_PERFORMANCE_API_PREFIX = '/api/reports';

  constructor(http: HttpClient) {
    super(http);
  }
  @GET(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/users`)
  getSalesPerformanceUsersByTime(@Query('year') year,
                                 @Query('month') month): Observable<SalesPerformanceUser[]> {
    return null;
  }

  @GET(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/monthly-dividing-rules`)
  getMonthlyDividingRulesByTime(@Query('year') year,
                                @Query('month') month): Observable<SalesPerformanceWeeklyRange[]> {
    return null;
  }

  @POST(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/users`)
  saveAndDeleteSalesPerformanceUsers(@Body users,
                                     @Query('year') year,
                                     @Query('month') month): Observable<any> {
    return null;
  }

  @GET(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-strategy/dates`)
  getDates(): Observable<any> {
    return null;
  }

  @GET(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-strategy`)
  getSalesStrategyTotal(@Query('year') year,
                        @Query('month') month): Observable<any> {
    return null;
  }

  @GET(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/monthly-user-value`)
  getSalesPerformanceValueByTime(@Query('year') year, @Query('month') month): Observable<any> {
    return null;
  }

  @GET(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/monthly-user-value/previous`)
  getCopiedValueFromLastMonth(@Query('year') year, @Query('month') month): Observable<SalesPerformanceValue> {
    return null;
  }

  @GET(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/weekly-ratio`)
  getWeeklyRatio(@Query('year') year, @Query('month') month): Observable<any> {
    return null;
  }

  @POST(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/weekly-ratio`)
  saveWeeklyRatio(@Body weeklyRatio, @Query('year') year, @Query('month') month): Observable<any> {
    return null;
  }

  @GET(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/weekly-user-value`)
  getWeeklyUserValue(@Query('year') year, @Query('month') month, @Query('week') week): Observable<any> {
    return null;
  }

  @GET(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/weekly-user-value/all`)
  getWeeklyDealerValue(@Query('year') year, @Query('month') month, @Query('week') week): Observable<any> {
    return null;
  }

  @POST(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/monthly-user-value`)
  saveSalesPerformanceUserValueByTime(@Body userValue, @Query('year') year, @Query('month') month): Observable<any> {
    return null;
  }

  @POST(`${SalesPerformanceApi.SALES_PERFORMANCE_API_PREFIX}/sales-performance/weekly-user-value`)
  saveWeeklyUserValue(@Body userValue, @Query('start') start, @Query('end') end): Observable<any> {
    return null;
  }

  @GET('/api/reports/sales-performance/exporting/url')
  exportExcel(@Query('year') year,
              @Query('month') month,
              @Query('week') week): Observable<any> {
    return null;
  }
}

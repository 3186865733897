import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  selector: 'app-full-width-row-cell',
  templateUrl: './full-width-row-renderer.template.html',
  styleUrls: ['./full-width-row-renderer.style.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FullWidthRowRendererComponent implements ICellRendererAngularComp {
  public template: string;

  agInit(params: ICellRendererParams): void {
    this.template = _.get(params, 'data.template', '');
  }

  refresh(params: any): boolean {
    return false;
  }

}

<form (ngSubmit)="submit()" [formGroup]="form" novalidate>
  <div class="input-area">
    <p>
      <label for="username">用户名</label>
    </p>
    <input id="username" name="username" type="text" formControlName="username">
  </div>
  <div class="input-area">
    <p>
      <label for="phone-number">手机号</label>
    </p>
    <input id="phone-number" name="phone-number" type="number" formControlName="phone_number">
  </div>
  <div class="input-area">
    <p>
      <label for="new-password">新密码</label>
    </p>
    <input id="new-password" name="new-password"
           type="password" autocomplete="off"
           formControlName="new_password">
    <p class="input-prompt">
      密码长度为10-12位，包括大小写，特殊字符和数字
    </p>
  </div>
  <button id="validate-submit" class="btn btn-block"
          [class.btn-primary]="form.valid"
          [class.btn-default]="form.invalid"
          [disabled]="form.invalid">下一步
  </button>
  <div id="prompt-area" class="prompt-area">
    <p>忘记用户名或需改电话号码，<br>请联系经销商IT部门查询或修改</p>
  </div>
</form>

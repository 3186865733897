import { toEnum } from 'app/_common/utils/enum';
import { AsFollowUpRemindOption } from '../as-lead/model';
import { CacheFilter, FilterOptions } from './list/list.type';

export const NO_ANSWER = 'NO_ANSWER';
export const BUSY = 'BUSY';
export const TRAVEL = 'TRAVEL';
export const UNCERTAIN_ARRIVAL_TIME = 'UNCERTAIN_ARRIVAL_TIME';
export const NON_LOCAL_VEHICLE = 'NON_LOCAL_VEHICLE ';
export const MILEAGE_NOT_REACHED = 'MILEAGE_NOT_REACHED';
export const INVENTORY_SHORTAGE = 'INVENTORY_SHORTAGE';
export const EVENT_NOTIFICATION = 'EVENT_NOTIFICATION';
export const OTHERS = 'OTHERS';

export const NEXT_FOLLOWUP_REASON_MAPPING = {
  [NO_ANSWER]: '客户未接听',
  [BUSY]: '客户正忙，不方便沟通',
  [TRAVEL]: '客户外地出差中',
  [UNCERTAIN_ARRIVAL_TIME]: '客户到店时间不确定，需再跟进',
  [NON_LOCAL_VEHICLE]: '车辆暂时在外地',
  [MILEAGE_NOT_REACHED]: '客户里程数未到，需再跟进',
  [INVENTORY_SHORTAGE]: '配件库存/订货到货通知',
  [EVENT_NOTIFICATION]: '近期店内活动通知',
  [OTHERS]: '其他',
};

export enum AsLeadListTabType {
  ALL = 'ALL',
  TODAY = 'TODAY',
  MINE = 'MINE',
}

export const AsLeadTabs = [
  { text: '我的线索', value: AsLeadListTabType.MINE },
  { text: '本店线索', value: AsLeadListTabType.ALL },
];

export const CEC_AS_LEAD_TABS = [
  { text: '我的线索', value: AsLeadListTabType.MINE },
  { text: '全部线索', value: AsLeadListTabType.ALL },
];

export const NEXT_FOLLOWUP_REASON = Object.keys(NEXT_FOLLOWUP_REASON_MAPPING).map(key => ({
  name: key,
  description: NEXT_FOLLOWUP_REASON_MAPPING[key],
}));

export const DEFAULT_FORMAT = 'YYYY-MM-DD';

export const INVALID_CALENDAR_STYLE = { 'border-color': '#FF3267', width: '100%' };

export const AS_LEAD_TYPE_ENUM = toEnum({
  EMERGENCY: { text: '紧急外呼' },
  TG: { text: '紧急外呼' },
  TCC: { text: 'TCC' },
  COMMON: { text: '绑定推送' },
  NORMAL: { text: '常规保养' },
  FIX_INTERVAL: { text: '固定周期配件' },
  RECALL: { text: '召回' },
  TYRE: { text: '轮胎' },
  TYRE_PREDICTION: { text: '轮胎预测' },
  PWEI: { text: '客户保留' },
  TYRE_LOST_SALES: { text: '轮胎失销' },
  LOST_SALE: { text: '失销' },
  CAMPAIGN: { text: '主动服务措施' },
  OEM_SPECIAL: { text: '厂家特殊线索' },
  SAN: { text: '改装产品线索' },
  DIGITAL_SERVICE: { text: '数字化服务' },
  SERVICE_MEASURE: { text: '一般服务措施' },
  SERVICE_CONTRACT: { text: '服务合约' },
  CUSTOMER_CARE: { text: '客户关怀' },
  MANUAL: { text: '人工创建' },
});

export const RECENT_ARRIVED_STATUS_ENUM = toEnum({
  SUCCESS: { text: '成功' },
  RESERVED_PENDING_CONFIRM: { text: '待确认' },
  RESERVED_FAILED: { text: '预约失败' },
  CANCELLED_CLOSED: { text: '取消(已关闭)' },
  CANCELLED_PENDING: { text: '取消(待跟进)' },
});

export const FOLLOWUP_TIMES = [
  '首次跟进',
  '二次跟进',
  '三次跟进',
  '四次跟进',
  '五次跟进',
];

export const AS_OFFER_TYPE_ENUM = toEnum({
  EMERGENCY: {
    text: '紧急外呼', icon: '/assets/as-lead/emergency.svg', color: '#fb6041', defaultServiceType: '维修', order: 1,
    src: '/assets/as-lead/offer-background/emergency.jpg' },
  TCC: {
    text: 'TCC', icon: '/assets/as-lead/tcc.svg', color: '#f9be05', order: 2,
    src: '/assets/as-lead/offer-background/tcc.jpg' },
  COMMON: { text: '绑定推送', icon: '/assets/as-lead/common.svg', color: '#02adf0', order: 3, src: '' },
  NORMAL: {
    text: '常规保养', icon: '/assets/as-lead/normal.svg', color: '#02adf0', defaultServiceType: '保养', order: 4,
    src: '/assets/as-lead/offer-background/normal.jpg' },
  FIX_INTERVAL: {
    text: '固定周期配件', icon: '/assets/as-lead/fix_interval.svg', color: '#02adf0', defaultServiceType: '保养', order: 4,
    src: '/assets/as-lead/offer-background/fix_interval.png' },
  RECALL: {
    text: '召回及服务措施', icon: '/assets/as-lead/recall.svg', color: '#b370d5', order: 6,
    src: '/assets/as-lead/offer-background/recall.jpg' },
  TYRE: {
    text: '轮胎', icon: '/assets/as-lead/tyre.svg', color: '#04ADEF', order: 8,
    src: '/assets/as-lead/offer-background/tyre.jpg' },
  TYRE_PREDICTION: {
    text: '轮胎预测', icon: '/assets/as-lead/tyre_prediction.svg', color: '#04ADEF', order: 8,
    src: '/assets/as-lead/offer-background/tyre_prediction.png' },
  PWEI: {
    text: '客户保留', icon: '/assets/as-lead/pwei.png', color: '#02adf0', defaultServiceType: 'PWEI', order: 5,
    src: '/assets/as-lead/offer-background/pwei.jpg' },
  TYRE_LOST_SALES: {
    text: '轮胎失销', icon: '/assets/as-lead/tyre_lost_sales.svg', color: '#02adf0', defaultServiceType: 'PWEI', order: 5,
    src: '/assets/as-lead/offer-background/tyre_lost_sales.png' },
  LOST_SALE: {
    text: '失销', icon: '/assets/as-lead/banner.svg', color: '#04ADEF', order: 7,
    src: '/assets/as-lead/offer-background/banner.jpg' },
  OEM_SPECIAL: {
    text: '厂家特殊线索', icon: '/assets/as-lead/oem_special.svg', color: '#04ADEF', order: 9,
    src: '/assets/as-lead/offer-background/oem_special.jpg' },
  SAN: {
    text: '改装产品线索', icon: '/assets/as-lead/san.svg', color: '#04ADEF', order: 9,
    src: '/assets/as-lead/offer-background/san.jpg' },
  DIGITAL_SERVICE: {
    text: '数字化服务', icon: '/assets/as-lead/tg.svg', color: '#02adf0', defaultServiceType: '保养', order: 10,
    src: '/assets/as-lead/offer-background/promotion.jpg' },
  SERVICE_CONTRACT: { text: '服务合约', icon: '/assets/as-lead/service_contract.png', color: '#02adf0', order: 11, src: '' },
  CUSTOMER_CARE: {
    text: '客户关怀', icon: '/assets/as-lead/customercare.png', color: '#02adf0', order: 12,
    src: '/assets/as-lead/offer-background/promotion.jpg' },
  MANUAL: {
    text: '人工创建', icon: '/assets/as-lead/manual.svg', color: '#02adf0', order: 12,
    src: '/assets/as-lead/offer-background/manual.jpg' },
});

export const RECALL_OFFERS_ORDER = toEnum({
  RECALL: { text: '召回服务措施', order: 1 },
  CAMPAIGN: { text: '主动服务措施', order: 2 },
  SERVICE_MEASURE: { text: '一般服务措施', order: 3 },
});

export const LEAD_STATUS_ORDER = toEnum({
  PENDING: { text: '待跟进', order: 1 },
  ORDERED: { text: '已预约', order: 2 },
});

export const AS_FOLLOW_UP_UNDONE_REASON = [
  '车辆已报废',
  '车主信息已失效，无法联系到车主',
  '因车辆转售，而无法获得新车主信息',
  '车主拒绝接收',
  '车辆已维修',
  '其他',
];

export enum AS_LEAD_SOURCE_TYPE {
  DMP = 'DMP',
}

export enum AsLeadTopic {
  MMoV = 'MMoV',
  MMmV = 'MMmV',
  TG = 'TG',
}

export const LEAD_CAN_FOLLOW_UP_STATUSES = [
  'PENDING',
  'FOLLOWED_UP',
];

export const KPI_CUSTOMER_CATEGORY_FILTER_ITEMS = toEnum({
  ALL: { text: '全部', selected: true },
  LOYAL: { text: '忠诚' },
  ACTIVE: { text: '活跃' },
  POSSIBLY_LOSS: { text: '准流失' },
  POTENTIAL_LOSS: { text: '疑似流失' },
  DEALER_LOSS: { text: '本店流失' },
});

export const KPI_ISSUE_CATEGORY_FILTER_ITEMS = toEnum({
  ALL: { text: '全部', selected: true },
  NEW_ISSUE: { text: '新下发' },
  STOCK_NOT_PREFERRED: { text: '存量未首次跟进' },
  STOCK_FOLLOWED_UP: { text: '存量待继续跟进' },
});

export const KPI_BUILD_FILTER_ITEMS = toEnum({
  SELF_CREATE: { text: '人工创建' },
  NON_SELF_CREATE: { text: '厂家下发', selected: true },
});

export const KPI_VEHICLE_FILTER_ITEMS = toEnum({
  PC: { text: '乘用车', selected: true },
  VAN: { text: '商务车' },
});

export const CUSTOMER_CATEGORY = toEnum({
  LOYAL: { text: '忠诚' },
  ACTIVE: { text: '活跃' },
  POSSIBLY_LOSS: { text: '准流失' },
  POTENTIAL_LOSS: { text: '疑似流失' },
  DEALER_LOSS: { text: '本店流失' },
});

export const LEAD_TYPE = toEnum({
  NOT_SELF_CREATE: { text: '厂家下发' },
  SELF_CREATE: { text: '人工创建' },
});

export const CUSTOMER_CATEGORY_LABEL = toEnum({
  LOYAL: { text: '忠诚', color: 'white', backgroundColor: '#028b18' },
  ACTIVE: { text: '活跃', color: 'white', backgroundColor: '#04c179' },
  POSSIBLY_LOSS: { text: '准流失', color: 'white', backgroundColor: '#f9be05' },
  POTENTIAL_LOSS: { text: '疑似流失', color: '#94989b', backgroundColor: '#dee2e6' },
  DEALER_LOSS: { text: '本店流失', color: '#94989b', backgroundColor: '#dee2e6' },
});

export const KPI_PERIOD_FILTER_ITEMS = {
  key: 'period',
  value: toEnum({
    DAY: { text: '本日', selected: true },
    MONTH: { text: '本月' },
    YEAR: { text: '本年' },
  }).values,
};

export const KPI_FOLLOW_UP_CATEGORY_COLOR = {
  一次跟进: '#0198d4',
  二次跟进: '#0079a9',
  其他: '#0d698c',
};

export enum KpiPeriodType {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const KPI_FIELD_TITLE_MAPPING = {
  followUpRate: '跟进率',
  reservationRate: '预约率',
  enteringRate: '进店率',
};

export const LEAD_STATUS_ENUM = toEnum({
  PENDING: { text: '待跟进' },
  FOLLOWED_UP: { text: '已跟进' },
  ORDERED: { text: '已预约' },
  CLOSED: { text: '已关闭' },
});

export const ISSUE_CATEGORY_MAPPING = toEnum({
  NEW_ISSUE: { text: '新下发' },
  STOCK_NOT_PREFERRED: { text: '存量未首次跟进' },
  STOCK_FOLLOWED_UP: { text: '存量待继续跟进' },
});

export const AS_LEAD_FILTER_STATUS = toEnum({
  PENDING_TODAY: { text: '待跟进(本日)', selected: true },
  PENDING_OTHERDAY: { text: '待跟进(非本日)' },
  ORDERED: { text: '已预约' },
  CLOSED: { text: '已关闭', hasDot: true },
});

export const RESERVATION_STATUS = toEnum({
  ARRIVED: { text: '已进店' },
  NOTARRIVAL: { text: '未进店' },
  NOINFO: { text: '无数据' },
});

export const VEHICLE_CATEGORY_MAPPING = toEnum({
  MMC_2: { text: 'Mmc' },
  MBC_1X: { text: 'MBC1.X' },
  NON_MMC: { text: '非Mmc' },
});

export const FOLLOWUP_CHANNEL = toEnum({
  EMAIL: { value: 'EMAIL', text: '电子通知' },
  SMS: { value: 'SMS', text: '移动短信（个性化）' },
  PHONE: { value: 'PHONE', text: '个人接触' },
  OTHERS: { value: 'OTHERS', text: '其他渠道' },
});

export const STATUS_OPTION = toEnum({
  YES: { text: '有' },
  NO: { text: '无' },
});

export const WECHAT_NOTIFICATION_STATUS_ENUM = toEnum({
  PUSH_SKIPPED_OR_FAILED: { text: '无推送' },
  PUSH_PENDING: { text: '待推送' },
  PUSHED_CLIENT_PENDING: { text: '已推送 - 待触达' },
  PUSHED_CLIENT_OPENED: { text: '已推送 - 待选择' },
  PUSHED_CLIENT_CONFIRMED: { text: '已推送 - 待预约' },
  PUSHED_CLIENT_ORDERED: { text: '已推送 - 待支付' },
  PUSH_DONE: { text: '已推送 - 已下单' },
});

export const REGEX_VEHICLE =
  /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})|([临]([A-Z0-9]|[\u4e00-\u9fa5]){6})$/;

export const AFTER_SALES_DEFAULT_FILTER: CacheFilter.TabFilter = {
  distributedType: '',
  leadStatus: 'PENDING_TODAY',
  vehicleCategory: '',
  customerCategory: '',
  leadType: '',
};

export const FILTER_OPTIONS: FilterOptions.Type = [
  {
    title: '下发类型',
    key: 'distributedType',
    options: [{ text: '全部', value: '', selected: true }, ...ISSUE_CATEGORY_MAPPING.values],
  },
  {
    title: '线索状态',
    key: 'leadStatus',
    options: [{ text: '全部', value: 'ALL' }, ...AS_LEAD_FILTER_STATUS.values],
  },
  {
    title: '互联类型',
    key: 'vehicleCategory',
    options: [{ text: '全部', value: '', selected: true }, ...VEHICLE_CATEGORY_MAPPING.values],
  },
  {
    title: '客户类型',
    key: 'customerCategory',
    options: [{ text: '全部', value: '', selected: true }, ...CUSTOMER_CATEGORY.values],
  },
  {
    title: '线索类型',
    key: 'leadType',
    options: [{ text: '全部', value: '', selected: true }, ...LEAD_TYPE.values],
  },
];

export const DEFAULT_AS_LEAD_LIST_TAB = AsLeadListTabType.MINE;

export enum KpiType {
  DEALER = 'DEALER',
  CDO = 'CDO',
}

export const CUSTOMER_REMIND: AsFollowUpRemindOption = {
  leadType: AS_LEAD_TYPE_ENUM.CAMPAIGN.value,
  statusTitle: '是否已电话告知',
  optionTitle: '未告知原因',
  statusOptions: [{ text: '已告知', value: true }, { text: '未告知', value: false }],
};

export const REGISTERED_LETTER_REMIND: AsFollowUpRemindOption = {
  leadType: AS_LEAD_TYPE_ENUM.CAMPAIGN.value,
  statusTitle: '挂号信发送状态',
  optionTitle: '未发信原因',
  statusOptions: [{ text: '已发送', value: true }, { text: '未发送', value: false }],
};

export const FOLLOW_UP_STEPS = {
  FIRST_MMC_STEP: 'FIRST_MMC_STEP',
  SECOND_CAMPAIGN_STEP: 'SECOND_CAMPAIGN_STEP',
  FOLLOW_UP_STEP: 'FOLLOW_UP_STEP',
  REGISTERED_LETTER_STEP: 'REGISTERED_LETTER_STEP',
};

export const RESERVATION_DATE_RANGE = 89;

export const MMC_2 = 'MMC_2';

export const CURRENT_LEAD_VEHICLE_ROLE = {
  CONTACT: '联系人',
  VEHICLE_OWNER: '车主',
  VEHICLE_OWNER_RECOMMEND: '车主(推荐)',
  WECHAT_SUBSCRIBER: '微信订阅人',
};

export const MMC_CHANNELS = [
  { key: 'ME', value: 'Mercedes Me' },
  { key: 'EMAIL', value: '电子邮件' },
  { key: 'FAX', value: '传真' },
  { key: 'PHONE', value: '电话' },
  { key: 'SMS', value: '短信' },
];

export const CONTACT_SALUTATION = [
  { text: '先生', url: '/assets/as-lead/male.png' },
  { text: '女士', url: '/assets/as-lead/female.png' },
];

export const STRATEGY_STATUS = [
  { value: '1', text: '已生效' },
  { value: '0', text: '未生效' },
  { value: '2', text: '已失效' },
];

export const RESERVATION_SUCCESS_STATUS = toEnum({
  FALSE: { text: 'FALSE', value: '/assets/as-lead/fail.svg' },
  PENDING: { text: 'PENDING', value: '/assets/as-lead/pending-confirm.svg' },
});

export const RESERVATION_STYLE = {
  FALSE: { title: '预约状态', value: '预约失败', styleClass: 'error' },
  PENDING: { title: '预约状态', value: '预约待确认', styleClass: 'warning' },
};

export const FAILED_VEHICLE_FILE_NAME = '人车关系导入失败列表.xlsx';

export const CDO_CVR_IMPORT_TEMPLATE = 'CDO CVR import template.xlsx';

export const DEALER_NAME_IS_NULL = 'dealer_name_is_null';

export const CURRENT_CAR_TAB = {
  CARSANDINFO: '车辆及售后信息',
  PRODUCTS: '互联产品',
};

export const TYRE_BRAND = toEnum({
  MICHELEN: { text: '米其林', value: '米其林' },
  BRIDGESTONE: { text: '普利司通', value: '普利司通' },
  PIRELLI: { text: '倍耐力', value: '倍耐力' },
  CONTINENTAL: { text: '马牌', value: '马牌' },
  GOODYEAR: { text: '固特异', value: '固特异' },
  DUNLOP: { text: '邓禄普', value: '邓禄普' },
  HANKOOK: { text: '韩泰', value: '韩泰' },
  YOKOHAMA: { text: '优客豪马', value: '优客豪马' },
  MAXXIS: { text: '玛吉斯', value: '玛吉斯' },
  OTHER: { text: '其他', value: '其他' },
});

export const TREAD_WIDTH = toEnum({
  W145: { text: '145', value: '145' },
  W155: { text: '155', value: '155' },
  W165: { text: '165', value: '165' },
  W175: { text: '175', value: '175' },
  W185: { text: '185', value: '185' },
  W195: { text: '195', value: '195' },
  W205: { text: '205', value: '205' },
  W215: { text: '215', value: '215' },
  W225: { text: '225', value: '225' },
  W235: { text: '235', value: '235' },
  W245: { text: '245', value: '245' },
  W255: { text: '255', value: '255' },
  W265: { text: '265', value: '265' },
  W275: { text: '275', value: '275' },
  W285: { text: '285', value: '285' },
  W295: { text: '295', value: '295' },
  W305: { text: '305', value: '305' },
  W315: { text: '315', value: '315' },
  W325: { text: '325', value: '325' },
  W335: { text: '335', value: '335' },
  W345: { text: '345', value: '345' },
  W355: { text: '355', value: '355' },
});

export const FLATNESS_RATIO = toEnum({
  R15: { text: '15', value: '15' },
  R20: { text: '20', value: '20' },
  R25: { text: '25', value: '25' },
  R30: { text: '30', value: '30' },
  R35: { text: '35', value: '35' },
  R40: { text: '40', value: '40' },
  R45: { text: '45', value: '45' },
  R50: { text: '50', value: '50' },
  R55: { text: '55', value: '55' },
  R60: { text: '60', value: '60' },
  R65: { text: '65', value: '65' },
  R70: { text: '70', value: '70' },
  R75: { text: '75', value: '75' },
  R80: { text: '80', value: '80' },
  R85: { text: '85', value: '85' },
});

export const WHEEL_HUB_DIAMETER = toEnum({
  D12: { text: '12', value: '12' },
  D13: { text: '13', value: '13' },
  D14: { text: '14', value: '14' },
  D15: { text: '15', value: '15' },
  D16: { text: '16', value: '16' },
  D17: { text: '17', value: '17' },
  D18: { text: '18', value: '18' },
  D19: { text: '19', value: '19' },
  D20: { text: '20', value: '20' },
  D21: { text: '21', value: '21' },
  D22: { text: '22', value: '22' },
  D23: { text: '23', value: '23' },
});

export const TYRE_TAGS = {
  APPEARANCE_NORMAL: '正常',
  TIRE_SURFACE_NAIL: '胎面扎钉',
  CRACKED_AGING: '龟裂老化',
  MINOR_TRAUMA: '轻微外伤',
  TIRE_SHOULDER_OR_TIRE_SIDE_NAIL: '胎肩/胎侧扎钉',
  CRACKING_AGING: '开裂老化',
  TIRE_BULGE: '轮胎鼓包',
  SEVERE_TRAUMA: '严重外伤',
  ECCENTRIC_WEAR_NORMAL: '正常',
  INSIDE: '内侧',
  OUTSIDE: '外侧',
  BOTH_SIDES: '两侧',
  BETWEEN: '中间',
};

export const ORDER_ON_CUSTOMER_PRODUCT = toEnum({
  SC: { text: '保养套餐', value: 'SC' },
  TYREPACKAGE: { text: '轮胎套餐', value: 'TYREPACKAGE' },
  LOYALTY: { text: '致友计划', value: 'LOYALTY' },
});

<div class="cti-login-wrapper">
  <ng-container *ngIf="shouldShowComponent">
    <div class="cti-login-button" (click)="toggleMenuVisibility()">
      <img [src]="'/assets/layout/'+currentCtiStatus.icon" class="cti-login-icon">
      <span>坐席状态：{{currentCtiStatus.statusText}}</span>
    </div>
    <div class="cti-login-menu" (click)="$event.stopPropagation();" *ngIf="shouldShowMenu"
         [style]="menuItems.length === 1?'bottom: -52px':'bottom: -104px'">
      <ul class="nav nav-pills nav-stacked">
        <ng-container *ngFor="let item of menuItems">
          <li class="nav-item" (click)="handleStatusItemClick(item)">
            <img [src]="'/assets/layout/'+item.icon" alt="" class="icon">
            <span>{{item.buttonText}}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</div>

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

@Component({
  template: `
    <div>
      <img *ngIf="value==='DIDI'" src="../../../../../../assets/common/didi-driving.png">
      <img *ngIf="value==='EDAIJIA'" src="../../../../../../assets/common/e-driving.png">
    </div>
  `,
})
export class DrivingServiceComponent implements ICellRendererAngularComp {
  public value: string;
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}

<div class="modal-container" (click)="confirm()">
  <div id="multiple-date-picker-wrapper" (click)="onPickerClick($event)"
       [style.left.px]="left"
       [style.top.px]="top"
  >
    <multiple-date-picker
      [(ngModel)]="selectedDates"
      [ngModelOptions]="{standalone: true}"
      [highlightDays]="highlightDays"
      [sundayFirstDay]="true"
      [disableDaysBefore]="disableDaysBefore"
      [showDaysOfSurroundingMonths]="true">
    </multiple-date-picker>
  </div>
</div>

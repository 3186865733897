import * as moment from 'moment';
import * as _ from 'lodash';
import {
  ACCIDENT_CAR_REPORT_DROPDOWN_DATE_RANGE_SETTING_BY_ENVIRONMENT,
  ACCIDENT_CAR_SMS_LIST_REPORT_DROPDOWN_DATE_RANGE_SETTING_BY_ENVIRONMENT,
  DROPDOWN_DATE_RANGE_OPTION_HALF_YEAR,
  DROPDOWN_DATE_RANGE_OPTION_MONTH,
  DROPDOWN_DATE_RANGE_OPTION_QUARTER,
  DROPDOWN_DATE_RANGE_OPTION_YEAR,
  DROPDOWN_DATE_RANGE_OPTIONS_DAY,
  FILTER_INPUT_TYPES,
  INSPECTION_ITEM_STATUS_DESCRIPTION,
  RWO_REPORT_DATA_SIZE_EXCEEDS_LIST,
  TYRE_APPEARANCE_INSPECTION_DESCRIPTION,
  TYRE_DEPTH_SOURCE_DESCRIPTION,
  TYRE_ECCENTRIC_WEAR_DESCRIPTION,
} from '../constants/report';
import {
  formatLocalTimeToEndOfDayTime,
  formatLocalTimeToStartOfDayTime,
  formatMomentByFormatString,
} from '../../_common/utils/date';
import { fixNumber, fixNumberIntegerComma, formatAmount } from '../../_common/utils/format-string';
import { isEmptyValue } from '@otr/website-common';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';
import { VEHICLE_FILTER_TYPE } from '../../courtesy-car/constants';
import { isVanForSameDealer } from '../../_common/utils/vanEnable';
import { IPreInspectionKpiRate } from '../models/pre-inspection-report';
import { environment } from '../../../environments/environment';
import { DIGIT_NUMBER_RULE } from '../../_common/constants/rules';
import { AbstractControl } from '@angular/forms';

export const getMonthListByYearsFromNow = (years) => {
  return getMonthListByMonthsFromNow(years * 12);
};

export const getMonthListByMonthsFromNow = (months) => {
  const endDate = moment().endOf('month');
  let startDate = moment().subtract(months, 'months').endOf('month');
  const result = [];
  while (startDate.isBefore(endDate)) {
    startDate = startDate.add(1, 'months').endOf('month');
    result.push({ text: startDate.format('YYYY年MM月'), value: startDate.format('YYYY-MM') });
  }
  return _.reverse(result);
};

export const isAllOption = (value) =>
  _.isEqual(value, 'ALL') || _.isEqual(value, ['全部']) || (typeof value === 'string' && value.includes('SELECT_ALL'));

export const replaceNilGridCellDataWithSymbol = (gridData: object[], symbol: string = '-') =>
  gridData.map((rowData) => _.mapValues(rowData, (val) => (isEmptyValue(val) ? symbol : val)));

export const getTableDataFromResponse = (resp, paginationEnabled) => {
  const rawTableData = paginationEnabled ? resp.content : _.isArray(resp) ? resp : [resp];
  return replaceNilGridCellDataWithSymbol(rawTableData);
};

export const getTableSummaryFromResponse = (resp, paginationEnabled) => {
  const rawTableData = paginationEnabled ? resp.summaryLines : _.isArray(resp) ? resp : [resp];
  return replaceNilGridCellDataWithSymbol(rawTableData);
};

export const getFilterValues = (filterFormGroup, reportConfig) => {
  const filterValues = _.get(filterFormGroup, 'value');
  const filterConfigs = _.get(reportConfig, 'filter');
  const filterConfigMap = _.keyBy(filterConfigs, 'name');

  const getDateRangeValue = (filterValue, dateType: string = 'day', optionName: string = null) => {
    const result: { [k: string]: any } = {};
    const fillValueForDateRange = (
      formatBeginDateFunc: (date: Date, formatString?: string) => string,
      formatEndDateFunc: (date: Date, formatString?: string) => string,
      formatString?: string,
    ): void => {
      result.beginDate = formatBeginDateFunc(filterValue.beginDate, formatString);
      result.endDate = formatEndDateFunc(filterValue.endDate, formatString);
    };

    const hasPrefixZero = (str: string) => {
      return str ? str.startsWith('0') : str;
    };

    const fillValueForHalfYearRange = () => {
      const beginDateArray = filterValue.beginDate.split('-');
      const endDateArray = filterValue.endDate.split('-');
      result.beginDate = `${beginDateArray[0]}-H${hasPrefixZero(beginDateArray[1]) ? beginDateArray[1].slice(1) : beginDateArray[1]}`;
      result.endDate = `${endDateArray[0]}-H${hasPrefixZero(endDateArray[1]) ? endDateArray[1].slice(1) : endDateArray[1]}`;
    };

    if (_.isNil(filterValue.beginDate) || _.isNil(filterValue.endDate)) {
      return null;
    }

    switch (dateType) {
      case DROPDOWN_DATE_RANGE_OPTIONS_DAY.DAY.value: {
        fillValueForDateRange(formatLocalTimeToStartOfDayTime, formatLocalTimeToEndOfDayTime);
        break;
      }
      case DROPDOWN_DATE_RANGE_OPTION_MONTH.MONTH.value: {
        fillValueForDateRange(formatMomentByFormatString, formatMomentByFormatString, 'YYYY-MM');
        break;
      }
      case DROPDOWN_DATE_RANGE_OPTION_QUARTER.QUARTER.value: {
        fillValueForDateRange(formatMomentByFormatString, formatMomentByFormatString, 'YYYY-MM');
        break;
      }
      case DROPDOWN_DATE_RANGE_OPTION_HALF_YEAR.HALF_YEAR.value: {
        fillValueForHalfYearRange();
        break;
      }
      case DROPDOWN_DATE_RANGE_OPTION_YEAR.YEAR.value: {
        fillValueForDateRange(formatMomentByFormatString, formatMomentByFormatString, 'YYYY');
        break;
      }
    }
    if (!_.isNil(optionName)) {
      result[optionName] = dateType;
    }
    return result;
  };

  const filterValuesForBackend = _.mapValues(filterValues, (filterValue, filterName) => {
    const filterType = _.get(filterConfigMap[filterName], 'type');
    if (filterType === FILTER_INPUT_TYPES.DATE_RANGE) {
      return getDateRangeValue(filterValue);
    }

    if (filterType === FILTER_INPUT_TYPES.DROPDOWN_DATE_RANGE) {
      const optionName = _.get(filterConfigMap[filterName], 'optionName', null);
      if (_.isNil(optionName)) {
        return null;
      }

      const dateType = _.get(filterValue, `${optionName}`);
      return getDateRangeValue(filterValue, dateType, optionName);
    }

    if (filterType === FILTER_INPUT_TYPES.DROPDOWN_INPUT) {
      if (_.isEmpty(filterValue.value)) {
        return null;
      }
      return filterValue.type === VEHICLE_FILTER_TYPE.FIN.value
        ? {
          fin: filterValue.value,
        }
        : {
          vin: filterValue.value,
        };
    }

    if (_.has(filterValue, 'value')) {
      return filterValue.value;
    }
    return filterValue;
  });
  return _.omitBy(filterValuesForBackend, (value) => isEmptyValue(value) || isAllOption(value));
};

export const defaultValueOrPriceFormatter = ({ value }, precision = 2) => (value === '-' ? value : priceFormatter({ value }, precision));
export const priceFormatter = ({ value }, precision = 2) => {
  return _.isNil(value) || value === '-' ? '-' : fixNumberIntegerComma(value, precision);
};
export const rateFormatter = ({ value }) => (_.isNil(value) ? '-' : fixNumber(value));
export const booleanFormatter = ({ value }) => (value === false ? 'N' : value === true ? 'Y' : value);

export const displayBooleanFormatter = ({ value }) => (value === false ? '否' : value === true ? '是' : value);

export const displayAnotherBooleanFormatter = ({ value }) => (value === false ? '无' : value === true ? '有' : value);

export const displayBooleanWithNilFormatter = ({ value }) => (_.isNil(value) ? '-' : displayBooleanFormatter({ value }));

export const prefixInteger = (num, length) => (Array(length).join('0') + num).slice(-length);

export const percentageValueFormatter = (params: ValueFormatterParams) => {
  return _.isNil(params.value) ? '-' : (Math.round(params.value * 10000) / 100).toFixed(2);
};

export const percentValueFormatter = (params: ValueFormatterParams) => {
  return _.isNil(params.value) ? '-' : `${(Math.round(params.value * 10000) / 100).toFixed(2)}%`;
};

export const decimalValueFormatter = (params: ValueFormatterParams) => {
  return _.isNil(params.value) ? '-' : (Math.round(params.value * 100) / 100).toFixed(2);
};

export const exportExcelValidationCondition = (totalItems, reportName, maxItems) => {
  return totalItems > maxItems && _.includes(RWO_REPORT_DATA_SIZE_EXCEEDS_LIST, reportName);
};

export const sortByZh = (data: Array<any>, sortKey: string = 'full_name') => {
  data.sort((a, b) => a[sortKey].localeCompare(b[sortKey], 'zh'));
  return data;
};

export const formatLineStatus = (data: Array<any>) => {
  const result = data.map((statusData) => ({
    ...statusData,
    textOption: `${statusData.code}-${statusData.description}`,
  }));
  const reg = /\d/;
  const wordsCode = result.filter((statusData) => !reg.test(statusData.code)).sort((a, b) => a.code.localeCompare(b.code));
  const numberCode = result.filter((statusData) => reg.test(statusData.code)).sort();
  return [...wordsCode, ...numberCode];
};

export const arrayBufferToObject = (arrayBuffer: ArrayBuffer) => {
  const uintArray = new Uint8Array(arrayBuffer);
  const encodedString = String.fromCharCode.apply(null, uintArray);
  const decodedString = decodeURIComponent(escape(encodedString));
  let result = {};
  try {
    result = JSON.parse(decodedString);
  } catch (e) {
  }
  return result;
};
export const defaultFormatter = ({ value }) => (_.isNil(value) ? '-' : value);

export const dateFormatter = ({ value }, format = 'YYYY/MM/DD') => (_.isNil(value) || value === '-' ? '-' : moment(value).format(format));

export const dateTimeFormatter = ({ value }) => (_.isNil(value) || value === '-' ? '-' : moment(value).format('YYYY-MM-DD HH:mm'));

export const localDateTimeFormatter = ({ value }) => (_.isNil(value) || value === '-' ? '-' : moment(value).format('YYYY-MM-DD HH:mm:ss'));

export const smsTemplateDisabledFormatter = ({ value }) => (value ? '已禁用' : '正常');

export const smsTemplateDisabledOperationFormatter = (value) => (value ? '解禁' : '禁用');

export const localDateTimeListFormatter = ({ value }) => {
  if (_.isNil(value) || value.length === 0 || value === '-') {
    return '-';
  }
  return value.map((localDateTime) => moment(localDateTime).format('YYYY-MM-DD HH:mm:ss')).join(';\n');
};

export const vehicleBusinessTypeFormatter = ({ value }) => {
  switch (value) {
    case 'PC':
      return '乘用车';
    case 'VAN':
      return '商务车';
    default:
      return '乘用车';
  }
};

export const getReportFilterConfig = (config, vanFilterOptions, filterName) => () => {
  if (isVanForSameDealer()) {
    return {
      ...config,
      filter: _.map(config.filter, (item) => (item.name === filterName ? {
        ...item,
        options: vanFilterOptions,
      } : item)),
    };
  }
  return config;
};

export const percentageFormatter = ({ value }, precision = 2) => {
  if (value == '0') {
    return '0';
  }
  return `${fixNumberIntegerComma(value, precision)}%`;
};

export const inspectionItemStatusFormatter = ({ value }) => INSPECTION_ITEM_STATUS_DESCRIPTION[value];

export const tyreDepthSourceFormatter = ({ value }) => TYRE_DEPTH_SOURCE_DESCRIPTION[value];

export const vehicleAgeFormatter = ({ value }) => {
  if (_.isNil(value) || value === '-') {
    return value;
  }
  return `${parseFloat(value).toFixed(2)}`;
};

export const tyreAgeFormatter = ({ value }) => {
  if (_.isNil(value) || value === '不适用' || value === '-') {
    return value;
  }
  return `${parseFloat(value).toFixed(1)}`;
};

export const operatorInfoFormatter = ({ value }) => _.join(value, '，');

export const tyreAppearanceInspectionFormatter = ({ value }) =>
  _.map(value, (item) => TYRE_APPEARANCE_INSPECTION_DESCRIPTION[item] || item).join('，');

export const tyreEccentricWearFormatter = ({ value }) => _.map(value, (item) => TYRE_ECCENTRIC_WEAR_DESCRIPTION[item] || item).join('，');

export const isFullDataRange = (dataRange) => !!(dataRange && dataRange.beginDate && dataRange.endDate);

export const findTextByValue =
  (valueTextArray) =>
    (value, defaultValue: string = '') =>
      _.chain(valueTextArray).find({ value }).get('text', defaultValue).value();

export const findAbbreviationByValue =
  (valueAbbreviationArray) =>
    (value, defaultValue: string = '') =>
      _.chain(valueAbbreviationArray).find({ value }).get('abbreviation', defaultValue).value();

export const findTextByCode = (valueTextArray) => (code) => _.chain(valueTextArray).find({ code }).get('text', '').value();

export const findValueByText = (valueTextArray) => (text) => _.chain(valueTextArray).find({ text }).get('value', '').value();

const getPreValue = (value) => {
  const emptyValue = '--';
  return _.isNil(value) ? emptyValue : value;
};

const getPreInspectionValue = (resp: any = {}, hasOem: boolean = false) => {
  const returnValue: IPreInspectionKpiRate = {
    actual: getPreValue(resp.actual),
    ytd: getPreValue(resp.ytd),
  };
  if (hasOem) {
    returnValue.oem = getPreValue(resp.oem);
  }
  return returnValue;
};
export const getPreInspectionRate = (res: any = {}): IPreInspectionKpiRate[] => {
  return [
    {
      name: '合格预检转化率（审计后）',
      ...getPreInspectionValue(res.qualifiedPreInspectionCheckConversionRateAudited, true),
    },
    {
      name: '合格预检转化率（未审计）',
      ...getPreInspectionValue(res.qualifiedPreInspectionCheckConversionRate),
    },
    {
      name: '合格预检率（未审计）',
      ...getPreInspectionValue(res.qualifiedPreInspectionRate),
    },
    {
      name: '预检转化率',
      ...getPreInspectionValue(res.preInspectionConversionRate),
    },
  ];
};
export const getPreConversionRate = (res: any = {}): IPreInspectionKpiRate[] => {
  return [
    {
      name: '空调滤清器转化率',
      ...getPreInspectionValue(res.airConditioningFilterConversionRate),
    },
    {
      name: '火花塞转化率',
      ...getPreInspectionValue(res.sparkPlugConversionRate),
    },
    {
      name: '制动液转化率',
      ...getPreInspectionValue(res.brakeFluidConversionRate),
    },
    {
      name: '变速箱油转化率',
      ...getPreInspectionValue(res.transmissionFluidConversionRate),
    },
    {
      name: '轮胎转化率',
      ...getPreInspectionValue(res.tyreConversionRate),
    },
    {
      name: '制动片转化率',
      ...getPreInspectionValue(res.brakePieceConversionRate),
    },
  ];
};

export const amountFormatter = ({ value }) => {
  if (value == 0) {
    return '0';
  }
  return _.isNil(value) || value === '-' ? '-' : formatAmount(value, 2);
};

export const formatterAmount = ({ value }) => {
  return _.isNil(value) || value === '-' ? '-' : formatAmount(value, 2);
};

export const getAccidentCarSettingByEnvironment = (
  key: string,
  map: any = ACCIDENT_CAR_REPORT_DROPDOWN_DATE_RANGE_SETTING_BY_ENVIRONMENT,
) => {
  const currentEnvironment = _.get(environment, 'envName');
  return _.get(map, `${currentEnvironment}.${key}`, null);
};

export const getAccidentCarSmsListSettingByEnvironment = (key: string) => {
  const currentEnvironment = _.get(environment, 'envName');
  return _.get(ACCIDENT_CAR_SMS_LIST_REPORT_DROPDOWN_DATE_RANGE_SETTING_BY_ENVIRONMENT, `${currentEnvironment}.${key}`, null);
};

export const numberFormatter = ({ value }) => {
  if (value == 0) {
    return '0';
  }
  return _.isNil(value) || value === '-' ? '-' : value.toString().replace(DIGIT_NUMBER_RULE, ',');
};

export const reportDateFormatter = ({ value }) => {
  if (_.includes(value, 'H1') || _.includes(value, 'H2')) {
    return value.replace(/H1/g, '上半年').replace(/H2/g, '下半年');
  }
  return value;
};

export const vehicleAgeValidator = (control: AbstractControl) => {
  if (control.value.begin >= control.value.end) {
    return { message: '起始值不得大于或等于终止值' };
  }
  return null;
};

export const displayBooleanWithNullFormatter = ({ value }) => (value === false ? '否' : value === true ? '是' : value || '-');

export const emptyTextConverter = (value: string) => {
  if (_.isEmpty(value)) {
    return '-';
  }
  return value;
};

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

@Component({
  selector: 'app-ssc-lead-auto-transfer-action-cell',
  templateUrl: './ssc-lead-auto-transfer-action-cell.component.html',
  styleUrls: ['./ssc-lead-auto-transfer-action-cell.component.scss'],
})
export class SccLeadAutoTransferActionCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  dealerList: Array<string>;
  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.dealerList = this.params.data.oldDealerList;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onEditing(i) {
    this.cellRendererParams.onEditing(this.getRowData(i));
  }

  onDeleting(i) {
    this.cellRendererParams.onDeleting(this.getRowData(i));
  }

  getRowData(i) {
    const { oldDealerList, shareCenterInfo, transferDealerList, ruleId } = this.params.data;
    return {
      ruleId: ruleId[i],
      initialDealerId: oldDealerList[i].dealerId,
      initialDealerName: oldDealerList[i].name,
      finalDealerId: transferDealerList[i].dealerId,
      finalDealerName: transferDealerList[i].name,
      shareCenterId: shareCenterInfo[i].id,
      shareCenterName: shareCenterInfo[i].name,
    };
  }

}

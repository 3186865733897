import { Injectable } from '@angular/core';
import { Body, GET, Headers, Path, POST, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserManagementApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/users/cec')
  getUsersByDealer(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/users/download')
  @Headers({ isExportFile: true })
  getExistingData(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @POST('/api/users/sms')
  sendSMSToUsers(@Query('dealer_id') dealerId: string, @Body userIds: Array<number>): Observable<any> {
    return null;
  }

  @GET('/api/users/sms')
  getUserSMSStatus(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/users')
  getUsersByGroupId(@Query('group_id') groupId: number): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/dealer-groups/:id/users')
  getUsersByDealerId(@Path('id') dealerId: string, @Body data: any): Observable<any> {
    return null;
  }

  @GET('api/users/validation')
  validateUserFields(
    @Query('gems_user_id') gemsUserId: string,
    @Query('dms_user_id') dmsUserId: string,
    @Query('dms_operator_id') dmsOperatorId: string,
    @Query('dms_operator_dealer_id') dmsOperatorDealerId: string,
    @Query('groups') groups: string,
  ): Observable<any> {
    return null;
  }

  @POST('/api/users')
  createUser(@Body user: Object): Observable<any> {
    return null;
  }

  @PUT('/api/users/:id')
  updateUser(@Body user: Object, @Path('id') userId: string): Observable<any> {
    return null;
  }

  @GET('api/users/sales')
  getSalesConsultantsByGroupId(@Query('group_id') groupId: number): Observable<any> {
    return null;
  }

  @GET('/api/users')
  getUsersByPrivileges(@Query('privileges') privileges, @Query('cdmDealerId') dealerId: string): Observable<Array<any>> {
    return null;
  }

  @GET('/api/users/mod')
  getUsersModelDByPrivileges(@Query('privileges') privileges, @Query('cdmDealerId') dealerId: string): Observable<Array<any>> {
    return null;
  }

  @GET('/api/users/used-car-lead')
  getUsedCarUsersByPrivileges(@Query('privileges') privileges, @Query('cdmDealerId') dealerId: string): Observable<Array<any>> {
    return null;
  }
  @GET('/api/users')
  getUsersByGemsId(@Query('gems_user_id') gemsUserId: string): Observable<Array<any>> {
    return null;
  }

  @GET('/api/users/fetch-operator-id')
  getUserCtiInfo(): Observable<any> {
    return null;
  }

  @GET('/api/users/:dealerId')
  getUsersByRoles(@Path('dealerId') dealerId: string, @Query('isActive') isActive: boolean, @Query('roles') roles): Observable<any> {
    return null;
  }
}

import { Component } from '@angular/core';
import * as _ from 'lodash';
import { IFilterParams } from 'ag-grid';
import { FILTER_TYPE } from '../../../../../constants/common';
import { PaginationSearchSelectFilterComponent } from '../pagination-search-select-filter/pagination-search-select-filter.component';

@Component({
  selector: 'app-pagination-search-select-filter-cell',
  templateUrl: '../pagination-search-select-filter/pagination-search-select-filter.template.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationSearchSelectNullFilterComponent extends PaginationSearchSelectFilterComponent {
  private noneValue;

  agInit(params: IFilterParams): void {
    super.agInit(params);
    this.noneValue = _.get(params.colDef.filterParams, 'noneValue', 'NONE');
  }

  setModel(model: any): void {
    this.radio.setValue(model === this.noneValue ? FILTER_TYPE.NONE : FILTER_TYPE.NORMAL);
    this.text = model === this.noneValue ? '' : model;
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }

    return this.radio.value === FILTER_TYPE.NONE ? this.noneValue : this.text;
  }
}

import { FormControl } from '@angular/forms';
import { GridOptions } from 'ag-grid';
import { TextEllipsisRenderComponent } from 'app/_common/components/grid/cell-renders/text-ellipsis/text-ellipsis.component';
import { getDefaultGridOptions } from 'app/_common/components/grid/pagination-grid/grid.component';
import {
  dateFormatter,
  dayFormatter,
  exportStatusFormatter,
  getAfterYears,
  getCellValue,
  getCurrentDateString
} from '../utils';
import { cellRenders } from '../../_common/components/grid';
import { CustomLinkCellComponent } from '../../_common/components/grid/cell-renders/custom-link-cell/custom-link-cell';

export const queryFormControls = {
  dealerIds: new FormControl(null),
  ruleStartDate: new FormControl(getCurrentDateString()),
  ruleEndDate: new FormControl(getAfterYears()),
  createStartDate: new FormControl(null),
  createEndDate: new FormControl(null),
  operator: new FormControl(null),
  source: new FormControl(null),
};

export const gridOptions: GridOptions = {
  ...getDefaultGridOptions(),
  headerHeight: 40,
  rowHeight: 50,
  defaultColDef: {
    cellClass: 'center-box cell-align-left',
    headerClass: 'center-box header-align-left',
  },
};

export const SOURCES_TYPES = [
  {
    text: '全部',
    value: '',
  },
  {
    text: '系统生成',
    value: 'SYSTEM_GENERATED',
  },
  {
    text: '手动创建',
    value: 'MANUAL_CREATED',
  },
];

export const getExportColumns = (component) => [
  {
    field: 'checkbox',
    headerName: '',
    pinned: true,
    width: 56,
    headerComponentFramework: cellRenders.CheckBoxHeaderComponent,
    cellRendererFramework: cellRenders.CheckboxCellRenderComponent,
    suppressMenu: true,
    headerComponentParams: {
      showCustomLabel: true,
    },
    cellRendererParams: {
      showCustomLabel: true,
    },
  },
  {
    headerName: '序号',
    field: 'rowIndex',
    width: 58,
  },
  {
    headerName: '配置生效时间',
    field: 'ruleStartDate',
    valueFormatter: dayFormatter,
    width: 204,
  },
  {
    headerName: '配置结束时间',
    field: 'ruleEndDate',
    valueFormatter: dayFormatter,
    width: 204,
  },
  {
    headerName: '经销商范围',
    field: 'scope',
    width: 284,
    cellRendererFramework: TextEllipsisRenderComponent,
    cellRendererParams: {
      getContent: getCellValue,
    },
    cellStyle: { 'text-align': 'left' },
  },
  {
    headerName: '操作人',
    field: 'operator',
    width: 175,
    cellRendererFramework: TextEllipsisRenderComponent,
    cellRendererParams: {
      getContent: getCellValue,
    },
    cellStyle: { 'text-align': 'left' },
  },
  {
    headerName: '来源',
    field: 'source',
    valueFormatter: exportStatusFormatter,
    width: 130,
  },
  {
    headerName: '创建时间',
    field: 'createdTime',
    valueFormatter: dateFormatter,
    width: 284,
  },
  {
    headerName: '操作',
    field: 'exportFinishedTime',
    width: 76,
    pinned: 'right',
    cellRendererFramework: CustomLinkCellComponent,
    cellRendererParams: {
      text: '编辑',
      onClick: (data) => component.showEditModal(data),
    },
  },
];

export const NATIONWIDE_FOR_LABOR = {
  laborOptionCode: 'NATIONWIDE',
  laborOptionDescription: '全国',
  selected: true,
};

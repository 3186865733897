import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import * as _ from 'lodash';

const NON_NUMERIC_EXCEPT_POINT_RULE = /[^\d.]+/g;
const NON_NUMERIC_RULE = /[^\d]+/g;
const NON_NUMERIC_EXCEPT_SEMICOLON_POINT_RULE = /[^\d.;]+/g;
const LAST_POINT_RULE = /\.([^.]*)$/;

@Directive({
  selector: '[appNumberFormat]',
})
export class NumberFormatDirective {
  @Input() maxIntegerDigits: number;
  @Input() maxFractionDigits: number = 0;
  @Input() isMultiple: boolean = false;

  constructor(private ngControl: NgControl) {
  }

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const trimmedValue = this.trim(input.value);
    const truncatedValue = this.truncate(trimmedValue);
    this.ngControl.control.patchValue(truncatedValue);
  }

  private trim(value: string): string {
    let trimmed = '';
    const replaceRule = this.generateReplaceRule();
    if (value.indexOf('.') !== 0) {
      trimmed = value.replace(replaceRule, '');
      if (trimmed.split('.').length > 2) {
        trimmed = trimmed.replace(LAST_POINT_RULE, '$1');
      }
    }
    return trimmed;
  }

  private truncate(value: string): string {
    const [integer, fraction] = value.split('.');
    const truncatedInteger = integer.substr(0, this.maxIntegerDigits);
    return !_.isNil(fraction) && this.maxFractionDigits > 0
      ? `${truncatedInteger}.${fraction.substr(0, this.maxFractionDigits)}`
      : integer;
  }

  private generateReplaceRule() {
    if (this.maxFractionDigits === 0) {
      return NON_NUMERIC_RULE;
    }
    return this.isMultiple ? NON_NUMERIC_EXCEPT_SEMICOLON_POINT_RULE : NON_NUMERIC_EXCEPT_POINT_RULE;
  }
}

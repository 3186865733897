import { Component } from '@angular/core';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';
import { CHINESE_CALENDAR_CONFIG } from '../../../../../constants/calendar';
import { FormControl } from '@angular/forms';
import { FILTER_TYPE } from '../../../../../constants/common';
import { isEmptyValue } from '../../../../../utils/common';
import { getDateAtLastSecond, getDateAtZero } from '../../../../../utils/date';
import { dateRegularParse } from '../../../../../utils/date-parse';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IAfterGuiAttachedParams } from 'ag-grid';

@Component({
  selector: 'app-followup-search-calendar-range-filter-cell',
  templateUrl: './followup-search-calendar-range-filter.template.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class FollowupSearchCalendarRangeFilterComponent extends BasePaginationFilterComponent {
  readonly RANGE_REG = new RegExp(/^Range:(\d*),(\d*)$/);
  startDateControl: FormControl = new FormControl('');
  endDateControl: FormControl = new FormControl('');
  savedStartValue: string = '';
  savedEndValue: string = '';
  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;
  radio = new FormControl(FILTER_TYPE.NORMAL);

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    super.afterGuiAttached(params);
    this.startDateControl.setValue(this.savedStartValue);
    this.endDateControl.setValue(this.savedEndValue);
  }

  onCalendarBlur(control) {
    if (control === this.startDateControl) {
      this.endDateControl.updateValueAndValidity();
    } else {
      this.startDateControl.updateValueAndValidity();
    }
  }

  stopPropagation() {
    event.stopPropagation();
  }

  onCancel() {
    super.hideFilterMenu();
    this.searchInput.setValue(this.text);
    this.startDateControl.setValue(this.savedStartValue);
    this.endDateControl.setValue(this.savedEndValue);
  }

  onSubmit() {
    super.hideFilterMenu();
    this.text = this.isRangeSelected() ? '' : this.searchInput.value;
    this.savedStartValue = this.isRangeSelected() ? this.startDateControl.value : '';
    this.savedEndValue = this.isRangeSelected() ? this.endDateControl.value : '';
    this.params.filterChangedCallback();
  }

  private isRangeSelected() {
    return this.radio.value === FILTER_TYPE.CALENDAR_RANGE;
  }

  isFilterActive() {
    return this.isFilterValid() && !this.isEmptyFilter();
  }

  public setDefaultValue() {
    super.setDefaultValue();
    this.startDateControl.setValue('');
    this.endDateControl.setValue('');
  }

  setModel(model: any): void {
    this.RANGE_REG.test(model) ? this.setModelForCalendarRange(model) : this.setModelForSearchInput(model);
  }

  private setModelForSearchInput(model: any) {
    this.savedStartValue = null;
    this.savedEndValue = null;
    this.text = model;
    this.radio.setValue(FILTER_TYPE.NORMAL);
  }

  private setModelForCalendarRange(model: any) {
    const [from, to] = _.chain(this.RANGE_REG.exec(model))
      .slice(1, 3)
      .map(item => dateRegularParse(isEmptyValue(item) ? null : _.toNumber(item)))
      .value();
    this.savedStartValue = from;
    this.savedEndValue = to;
    this.text = null;
    this.radio.setValue(FILTER_TYPE.CALENDAR_RANGE);
  }

  getModel() {
    return this.isFilterActive() ? this.filterValue : null;
  }

  isFilterValid() {
    return !this.isRangeSelected() || this.isDateRangeValid();
  }

  private isDateRangeValid() {
    return isEmptyValue(this.savedStartValue) || isEmptyValue(this.savedEndValue)
      || moment(this.savedStartValue).isSameOrBefore(this.savedEndValue, 'day');
  }

  isEmptyFilter() {
    return this.isRangeSelected() ?
      isEmptyValue(this.savedStartValue) && isEmptyValue(this.savedEndValue) : isEmptyValue(this.text);
  }

  get filterValue() {
    if (this.radio.value === FILTER_TYPE.NORMAL) {
      return this.text;
    }
    return 'Range:'
      + `${isEmptyValue(this.savedStartValue) ? '' : getDateAtZero(new Date(this.savedStartValue))},`
      + `${isEmptyValue(this.savedEndValue) ? '' : getDateAtLastSecond(new Date(this.savedEndValue))}`;
  }

  changeRatio(ratio: string) {
    this.radio.setValue(ratio);
  }
}

import * as _ from 'lodash';
import { getVehicleBusinessType, VehicleBusinessType } from '@otr/website-common';

export const vanFeatureToggle = dealerIntegrationToggles => {
  return !_.isNil(_.find(dealerIntegrationToggles, { name: 'VAN_OTR_PLUS', enabled: true }));
};

export function isVanEnabled(dealerIntegrationToggles, tokenService) {
  return  vanFeatureToggle(dealerIntegrationToggles) && tokenService.isVanAuthorised();
}

export function isVanForSameDealer() {
  return getVehicleBusinessType() === VehicleBusinessType.VAN.value;
}

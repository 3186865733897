import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-fixed-head',
  templateUrl: './table-fixed-head.component.html',
  styleUrls: ['./table-fixed-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFixedHeadComponent implements OnInit {

  @Input() column;
  @Input() isCore;

  ngOnInit() {
  }

  get shouldLoadTemplate() {
    if (this.isCore) {
      return this.column.headerTemplate;
    }
    return !this.column.fixed && this.column.headerTemplate;
  }

}

import {
  FILTER_INPUT_TYPES,
  SA_NAME_WITH_ALL_OPTIONS,
  SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS,
  VEHICLE_BUSINESS_TYPE_OPTION,
  WAIT_FOR_PAYMENT_STATUS_OPTIONS,
} from '../constants/report';
import { priceFormatter } from '../utils/utils';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import * as moment from 'moment';

export const WAIT_FOR_PAYMENT_ORDER_CONFIG = {
  name: 'wait-for-payment-order',
  enablePagination: true,
  isRealTimeReport: true,
  exportedFileName: '未结清的订单（实时）',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '订单号',
      field: 'orderNumber',
      width: 160,
    },
    {
      headerName: '订单类型',
      field: 'serviceRecordCategory',
      width: 100,
    },
    {
      headerName: '车牌号',
      field: 'carPlateNumber',
      width: 120,
    },
    {
      headerName: '服务人员',
      field: 'assaFullName',
      width: 150,
    },
    {
      headerName: '进厂日期',
      field: 'checkedInDate',
      width: 120,
    },
    {
      headerName: '出厂日期',
      field: 'checkedOutDate',
      width: 120,
    },
    {
      headerName: '上次结账日期',
      field: 'latestInvoicedDate',
      width: 120,
    },
    {
      headerName: '预计未结金额-工时 (¥)',
      field: 'unpaidAmountLabor',
      width: 140,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '预计未结金额-杂费 (¥)',
      field: 'unpaidAmountSundry',
      width: 140,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '未结出库零件 (¥)',
      field: 'unpaidStockOutPart',
      width: 140,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '未结预留零件 (¥)',
      field: 'unpaidReservedPart',
      width: 140,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '其他 (零件) (¥)',
      field: 'otherAmountPart',
      width: 140,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '预计未结总额-零件 (¥)',
      field: 'unpaidSumAmountPart',
      width: 140,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '客户',
      field: 'customerName',
      width: 150,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '服务人员',
      options: SA_NAME_WITH_ALL_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'serviceRecordCategory',
      label: '订单类型',
      options: SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS.values,
      defaultValue: SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS.get('ALL'),
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedInDate',
      label: '登记进厂日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型：',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.GROUP_BUTTON,
      name: 'unpaidStatus',
      label: '未结清状态：',
      options: WAIT_FOR_PAYMENT_STATUS_OPTIONS.values,
      defaultValue: WAIT_FOR_PAYMENT_STATUS_OPTIONS.get('IS_ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.TEXT,
      name: 'date',
      label: '查询日期',
      content: () => moment(new Date()).format('YYYY-MM-DD HH:mm'),
      tip: '*报表数据更新延迟最长15分钟',
    },
  ],
};

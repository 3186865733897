<div class="form-container" #downPayment
     [style.top.px]="top"
     [style.left.px]="left"
>
  <form [formGroup]="form" appClickOutside (clickOutside)="confirm()">
    <div class="d-flex">
      <label>首付金额</label>
      <div class="input-wrapper">
        <input class="down-payment-input"
               [class.error]="shouldShowError('downPaymentSum')"
               appNumberFormat
               [maxFractionDigits]="2"
               [maxIntegerDigits]="10"
               formControlName="downPaymentSum"
               (change)="handleDownPaymentChange('downPaymentSum')"
               (keyup.enter)="onEnterPress('downPaymentSum')"
        >
      </div>
    </div>
    <div class="d-flex">
      <label>首付比例</label>
      <div class="input-wrapper">
        <input class="down-payment-input percentage-input"
               [class.error]="shouldShowError('downPaymentPercentage')"
               appNumberFormat
               [maxFractionDigits]="2"
               [maxIntegerDigits]="3"
               formControlName="downPaymentPercentage"
               (change)="handleDownPaymentChange('downPaymentPercentage')"
               (keyup.enter)="onEnterPress('downPaymentPercentage')"
        >
        <div class="percentage-symbol">%</div>
      </div>
    </div>
  </form>
</div>

import * as _ from 'lodash';
import * as moment from 'moment';
import {
  FILTER_INPUT_TYPES, SA_NAME_WITH_ALL_OPTIONS, DEPARTMENT_CODE_OPTIONS, VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';

import { priceFormatter } from '../utils/utils';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
const formatDateValue = ({ value }) => {
  return value === '-' ? '-' : moment(value).local().format('YYYY-MM-DD');
};

const formatDepartmentValue = ({ value }) => {
  switch (value) {
    case 'W':
      return '维修部门';
    case 'B':
      return '钣金部门';
    case 'S':
      return '喷漆部门';
    case 'Q':
      return '快修部门';
    case 'P':
      return '配件部门';
    default:
      return value;
  }
};

const numFix = ({ value }) => {
  return parseFloat(value).toFixed(2);
};

const conversionPercentage = ({ value }) => {
  if (value === '-') {
    return value;
  }
  return `${(parseFloat(value) * 100).toFixed(2).toString()} %`;
};

export const ORDER_DETAIL_REPORT_CONFIG = {
  name: 'order-line-item-detail-statistics',
  enablePagination: true,
  exportedFileName: '订单行项目报表.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '订单号',
      field: 'orderNumber',
      pinned: 'left',
      width: 200,
    },
    {
      headerName: '车牌号',
      field: 'carPlateNumber',
      pinned: 'left',
      width: 150,
    },
    {
      headerName: '送修人',
      field: 'customerName',
      pinned: 'left',
      width: 150,
    },
    {
      headerName: '车型',
      field: 'carModel',
      width: 150,
    },
    {
      headerName: 'FIN',
      field: 'fin',
      width: 200,
    },
    {
      headerName: 'VIN',
      field: 'vin',
      width: 200,
    },
    {
      headerName: '上牌日期',
      field: 'registrationDate',
      valueFormatter: formatDateValue,
      width: 200,
    },
    {
      headerName: '首登日期',
      field: 'firstRegistrationDate',
      valueFormatter: formatDateValue,
      width: 200,
    },
    {
      headerName: '服务备注',
      field: 'orderComments',
      width: 150,
    },
    {
      headerName: '服务人员',
      field: 'saName',
      width: 150,
    },
    {
      headerName: '登记进厂日期',
      field: 'occurredTime',
      valueFormatter: formatDateValue,
      width: 150,
    },
    {
      headerName: '项目类型',
      field: 'type',
      width: 150,
    },
    {
      headerName: '服务项目',
      field: 'serviceProject',
      width: 150,
    },
    {
      headerName: '项目描述',
      field: 'description',
      width: 250,
    },
    {
      headerName: '数量',
      field: 'quantity',
      width: 150,
      valueFormatter: numFix,
    },
    {
      headerName: '工时班组',
      field: 'workshop',
      width: 150,
    },
    {
      headerName: '技师姓名',
      field: 'technicianName',
      width: 150,
    },
    {
      headerName: '实际工时',
      field: 'actualTime',
      width: 150,
    },
    {
      headerName: '部门代码',
      field: 'departmentCode',
      valueFormatter: formatDepartmentValue,
      width: 150,
    },
    {
      headerName: '付款类型',
      field: 'paymentType',
      width: 150,
    },
    {
      headerName: '客户类型编号',
      field: 'accountCode',
      width: 150,
    },
    {
      headerName: '分摊比例',
      field: 'splitRatio',
      width: 150,
      valueFormatter: conversionPercentage,
    },
    {
      headerName: '成本（￥）',
      field: 'partsCost',
      width: 150,
      valueFormatter: numFix,
    },
    {
      headerName: '折扣',
      field: 'discountRate',
      width: 150,
    },
    {
      headerName: '含税单价（￥）',
      field: 'unitGrossPrice',
      width: 150,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '含税总价（￥）',
      field: 'totalGrossPrice',
      width: 150,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '状态',
      field: 'lineItemStatus',
      width: 150,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'orderNumber',
      label: '订单号',
      placeholder: '请输订单号搜索',
    },
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '服务人员',
      options: SA_NAME_WITH_ALL_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedInDate',
      label: '登记进厂日期',
      isRangeLimited: true,
      endLimitValue: moment(),
      limitValueRange: 1,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'departmentCode',
      label: '部门代码',
      options: DEPARTMENT_CODE_OPTIONS.values,
      defaultValue: DEPARTMENT_CODE_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'createDate',
      label: '创建日期',
      isRangeLimited: true,
      endLimitValue: moment(),
      limitValueRange: 1,
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型：',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
  ],
};

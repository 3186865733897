<div [ngClass]="{'input-error': errorMessage}">
  <p-calendar
    [(ngModel)]="value"
    [locale]="chineseCalendarConfig"
    (ngModelChange)="onChange()"
    (keydown)="onKeyDown($event)"
    [disabled]="isSelectDisabled"
    [showTime]="isTimePicker"
    dataType="string"
    dateFormat="yy-mm-dd"
    [hourFormat]="24"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [placeholder]="placeholder">
  </p-calendar>
  <div *ngIf="errorMessage" class="error-message" [ngStyle]="errorMessageStyle">
    {{errorMessage}}
  </div>
</div>


import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  empty as observableEmpty,
  Observable,
  of as observableOf,
} from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { QRCode } from '../models/qrcode';
import { QRCodeApi } from '../api/qrcode';

import { HttpErrorResponse } from '@angular/common/http';
import { PromptBarService } from '@otr/website-common';

@Injectable()
export class QRCodeService {
  qrCodes: BehaviorSubject<QRCode[]>;
  qrCodeIcons: BehaviorSubject<any[]>;

  constructor(private qrCodeApi: QRCodeApi,
              private promptBarService: PromptBarService) {
    this.qrCodes = new BehaviorSubject([]);
    this.qrCodeIcons = new BehaviorSubject([]);
  }

  qrCodeWithIcons(): Observable<QRCode[]> {
    return observableCombineLatest(this.qrCodes, this.qrCodeIcons).pipe(
      map(([qrCodes, icons]) => qrCodes.map((qrCode: any) => ({
        ...qrCode,
        iconData: _.get(_.find(icons, { id: qrCode.iconIndex }), 'data'),
      } as QRCode))));
  }

  fetchQRCodes(): Observable<any> {
    return this.qrCodeApi.getQRCodes().pipe(
      tap(response => this.qrCodes.next(response)),
      catchError(response => this.handleErrorResponse(response)));
  }

  fetchQRCodeIcons(): Observable<any> {
    return this.qrCodeApi.getQRCodeIcons().pipe(
      tap(response => this.qrCodeIcons.next(response)),
      catchError(response => this.handleErrorResponse(response)));
  }

  getQRCodes(): Observable<QRCode[]> {
    return this.qrCodes.pipe(take(1),
      switchMap(qrCodes => (_.isEmpty(qrCodes) ? this.fetchQRCodes() : observableOf(qrCodes))));
  }

  getQRCodeIcons(): Observable<QRCode[]> {
    return this.qrCodeIcons.pipe(take(1),
      switchMap(qrCodes => (_.isEmpty(qrCodes) ? this.fetchQRCodeIcons() : observableOf(qrCodes))));
  }

  getQRCodeFile(filePath: string): Observable<any> {
    return this.qrCodeApi.getQRCodeFile(filePath).pipe(
      catchError(response => this.handleErrorResponse(response)));

  }

  createQRCode(formData: FormData): Observable<any> {
    return this.qrCodeApi.postQRCode(formData).pipe(
      catchError(response => this.handleErrorResponse(response)));

  }

  updateQRCode(id: number, formData: any): Observable<any> {
    formData.append('id', id);
    return this.qrCodeApi.postQRCode(formData).pipe(
      catchError(response => this.handleErrorResponse(response)));

  }

  removeQRCode(id: number): Observable<any> {
    return this.qrCodeApi.removeQRCode(id).pipe(
      catchError(response => this.handleErrorResponse(response)));
  }

  handleErrorResponse(errorResponse: HttpErrorResponse) {
    try {
      this.promptBarService.show(errorResponse.error.message);
    } catch (e) {
      this.promptBarService.show('未知错误');
    }
    return observableEmpty();
  }
}

export const REGULAR_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_TEXT_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_MINUTE_FORMAT = 'YYYYMMDDHHmm';
export const DEFAULT_VAT_RATE: number = 0.13;
export const ORDER_CATEGORY = {
  EXTEND: 'extend',
  MAINTENANCE: 'maintenance',
};

export const OBSOLETE_VAT_INFO = [
  {
    DUE_DATE: '2018-04-30T16:00:00.000Z',
    VAT_RATE: 0.17,
  },
  {
    DUE_DATE: '2019-03-31T16:00:00.000Z',
    VAT_RATE: 0.16,
  },
];
export const USED_CARD_WARRANTY_PRODUCT_CODE = 'ADV_P_USED_C';

export const OFFLINE_SALES_CHANNEL = ['OTR', 'IQUOTE'];

export const TITLE_OPTIONS = [
  { value: 'MALE', text: '先生' },
  { value: 'FEMALE', text: '女士' },
];

export const BRAND_OPTIONS = [
  { value: 'AMG', code: 'AMG' },
  { value: '梅赛德斯-奔驰乘用车', code: 'MB-PKW' },
  { value: 'smart', code: 'SMART' },
];

export const VAN_BRAND_OPTIONS = [{ value: '梅赛德斯-奔驰乘用车', code: 'MB-PKW' }];

export const INVOICE_STATUS = {
  accepted: '已开票',
  exported: '准备结账',
};

export const ACTIVATE_STATUS = {
  ACTIVATE_SUCCESS: 'ACTIVATE_SUCCESS',
  GENERATED_QUOTATION: 'GENERATED_QUOTATION',
  READY_FOR_ACTIVATE: 'READY_FOR_ACTIVATE',
};

export const NO_DATA: string = '暂无数据';
export const APPLY_TO_ALL_DEALER: string = '已升级为全国通用';

export const EXCEL_FILE_CONTENT_TYPE = 'application/vnd.ms-excel';
export const EXPORT_EXCEL_FOR_OEM_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const PDF_FILE_CONTENT_TYPE = 'application/pdf';

export const SSSC_SEARCH_DATE_TYPES = [
  {
    value: 'firstRegistrationDate',
    name: '首登日期',
    startDateKey: 'frdBeginDate',
    endDateKey: 'frdEndDate',
  },
  {
    value: 'activateDate',
    name: '激活时间',
    startDateKey: 'activateBeginDate',
    endDateKey: 'activateEndDate',
  },
  {
    value: 'creationDate',
    name: '创建时间',
    startDateKey: 'creationBeginDate',
    endDateKey: 'creationEndDate',
  },
];

export const SSSC_SEARCH_DATE_TYPES_FOR_LPO = [
  {
    value: 'firstRegistrationDate',
    name: '首登日期',
    startDateKey: 'firstRegistrationDateFrom',
    endDateKey: 'firstRegistrationDateTo',
  },
  {
    value: 'activateDate',
    name: '激活时间',
    startDateKey: 'activeStartTime',
    endDateKey: 'activeEndTime',
  },
  {
    value: 'creationDate',
    name: '创建时间',
    startDateKey: 'orderStartTime',
    endDateKey: 'orderEndTime',
  },
];

export const QUOTATION_STATUS = {
  GENERATED_QUOTATION: '报价单已创建',
  READY_FOR_ACTIVATE: '等待激活',
  QUOTATION_CANCELLED: '已取消',
  WAIT_FOR_ACTIVATE_BY_MANUAL: '等待手动激活',
  PROPOSAL_CANCELED: '合同已拒绝',
  CONTRACT_SUSPENDED_INOPERATIVE: '合同已挂起',
};

export const EXPORT_EXCEL_TASK_STATUS = {
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
};

export const QUOTATION_SEARCH_DATE_TYPES = [
  {
    value: 'customerSignatureDate',
    name: '签字时间',
    startDateKey: 'signBeginDate',
    endDateKey: 'signEndDate',
  },
  {
    value: 'activateDate',
    name: '激活时间',
    startDateKey: 'activateBeginDate',
    endDateKey: 'activateEndDate',
  },
  {
    value: 'creationDate',
    name: '创建时间',
    startDateKey: 'creationBeginDate',
    endDateKey: 'creationEndDate',
  },
];

export const LPO_QUOTATION_STATUS = {
  CREATED: { code: 'CREATED', text: '订单已创建' },
  PRINTED: { code: 'PRINTED', text: '待激活' },
  ACTIVATED: { code: 'ACTIVATED', text: '已激活' },
};

export const ORDER_NUMBER_QUOTATION_STATUS = {
  CREATED: { code: 'CREATED', text: '已创建' },
  TO_ACTIVATE: { code: 'TO_ACTIVATE', text: '待激活' },
  ACTIVATED: { code: 'ACTIVATED', text: '已激活' },
  OTR_CANCELED: { code: 'OTR_CANCELED', text: '已取消' },
};

export const LPO_COUPON_STATUS = {
  AVAILABLE: '已生效',
  CANCELLED: '已取消',
  CREATED: '已创建',
  EFFECTIVE: '已生效',
  FROZEN: '已冻结',
  OVERDUE: '已超期',
  TO_BE_ACTIVATED: '待激活',
  TO_BE_EFFECTIVE: '待生效',
  REFUNDED: '已退款',
  REFUNDING: '退款中',
  RETURNED: '已退货',
  UNAVAILABLE: '暂不可用',
  USED: '已使用',
  USING: '使用中',
  TO_APPROVAL: '待审批',
};

export const LPO_ORDER_STATUS = {
  UNPAID: '待支付',
  CANCELED: '已取消',
  TO_ACTIVATE: '待激活',
  ACTIVATED: '已激活',
  REFUNDING: '退款中',
  REFUNDED: '已退款',
  SALES_RETURN: '已退货',
  CREATED: '已创建',
  OTR_CANCELED: '已取消',
  EXPIRED: '已过期',
};

export const LPO_PRODUCT_STATUS = {
  CREATED: '已创建',
  CANCELED: '已取消',
  TO_ACTIVATE: '待激活',
  UNUSED: '未使用',
  PAIR_UNUSED: '部分使用',
  USED: '已用完',
  REFUNDING: '退款中',
  REFUNDED: '已退款',
  RETURNED: '已退货',
};

export const LPO = 'LPO';

export const ICON = 'ICON';

export const EDIT_OPTION = {
  CUSTOMER: 'CUSTOMER',
  VEHICLE: 'VEHICLE',
  PRICE: 'PRICE',
};

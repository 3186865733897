<div class="operation-container">
  <img alt="" *ngFor="let action of actionsConfig"
        class="operation-button"
        (click)="action.onClick()"
        [src]="action.icon()"
       [tooltip]="action.hoverText"
       placement="bottom"
  >
  <div class="operation-button" dropdown [ngStyle]="{'visibility':showMoreOperation() ? 'visible' : 'hidden'}">
    <span [inlineSVG]="'/assets/common/more.svg'" dropdownToggle tooltip="更多" placement="bottom"></span>
    <div *dropdownMenu class="dropdown-menu">
      <div *ngFor="let operation of operationOptions" class="dropdown-item">
        <span *ngIf="operation.isVisible" class="operation" (click)="operation.handler()">{{ operation.text }}</span>
      </div>
    </div>
  </div>
</div>

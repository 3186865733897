import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { CurrentUserService } from '@otr/website-common';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  templateUrl: './arrange-icon-cell.template.html',
  styleUrls: ['./arrange-icon-cell.style.scss'],
})
export class ArrangeIconCellComponent implements ICellRendererAngularComp {
  iconConfig: any;

  params: ICellRendererParams;
  cellRendererParams: any;
  completeButtonDisabled: boolean;

  constructor(private currentUserService: CurrentUserService) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const { user_id } = params.data;
    this.cellRendererParams = params.colDef.cellRendererParams;
    this.iconConfig = getValue(this.cellRendererParams.iconConfig, params);
    const currentUserId = this.currentUserService.getCurrentUser().userId;
    this.completeButtonDisabled = currentUserId !== user_id;
  }

  editTask() {
    this.cellRendererParams.iconConfig.editTask(this.params.data);
  }

  completeTask() {
    if (this.completeButtonDisabled) {
      return false;
    }
    this.cellRendererParams.iconConfig.completeTask(this.params.data);
  }

  refresh(params: any): boolean {
    return false;
  }
}

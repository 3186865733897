import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit {
  @Input() callHistorySrc: any;
  errMsg: boolean = false;
  ngOnInit() {
    // @ts-ignore
    const audio = document.getElementById('audio-player');
    // tslint:disable-next-line:no-this-assignment
    const that = this;
    // tslint:disable-next-line:only-arrow-functions typedef
    audio.onstalled = function() {
      that.errMsg = true;
    };
    // tslint:disable-next-line:only-arrow-functions typedef
    audio.ondurationchange = function() {
      that.errMsg = false;

    };
  }
  // reload() {
  //   // @ts-ignore
  //   // tslint:disable-next-line:no-unused-expression
  //   this.audioSrc && document.getElementById('audio-player').load();
  // }
}

import { Injectable } from '@angular/core';
import { OutletSettingsApi } from '../api/outlet-settings';

import { CurrentUserService } from '@otr/website-common';

@Injectable()
export class OutletSettingsService {
  outletPilotDealers: Array<string>;

  constructor(private outletSettingsApi: OutletSettingsApi, private currentUserService: CurrentUserService) {
  }

  getOutletSettingsByOutletId() {
    return this.outletSettingsApi.getOutletSettingsByOutletId();
  }

  updateOutletSettings(settingName, settingValue) {
    return this.outletSettingsApi.updateOutletSettings({
      name: settingName,
      value: settingValue.toString(),
    });
  }

  getBankSettings() {
    return this.outletSettingsApi.getBankSettings();
  }

  createOrUpdateBankSettings(bankSettings) {
    return this.outletSettingsApi.createOrUpdateBankSettings({
      ...bankSettings,
      dealerId: this.currentUserService.getBranchDealerIdOrDealerId(),
    });
  }
}

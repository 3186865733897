import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MODAL_CONFIG } from '../../constants/common';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  styleUrls: ['confirm-modal.style.scss'],
  templateUrl: 'confirm-modal.template.html',
})
export class ConfirmModalComponent {
  modalConfig = Object.assign({}, MODAL_CONFIG, { keyboard: false });
  @ViewChild('bsModal') bsModal: ModalDirective;
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() message;
  @Input() errorMessage;
  @Input() shouldHideCancelButton: boolean = false;
  @Input() confirmButtonText = '确定';
  @Input() cancelButtonText = '取消';
  @Input() shouldShowCloseButton: boolean = false;
  @Input() leftAlignBody: boolean = false;
  @Input() modalTitle;
  @Input() shouldConfirmButtonDisabled: boolean = false;
  @Input() theme = 'primary';
  @Input() headerTheme = 'pattens-blue';
  @Input() htmlTemplate;

  protected _cssClass: {[key: string]: string} = {};
  @Input('class') public set cssClass(value: {[key: string]: string}) {
    this._cssClass = value;
  }
  public get cssClass(): {[key: string]: string} {
    return this._cssClass;
  }
  protected _cssStyle: {[key: string]: string} = {};
  @Input('style') public set cssStyle(value: {[key: string]: string}) {
    this._cssStyle = value;
  }
  public get cssStyle(): {[key: string]: string} {
    return this._cssStyle;
  }

  show() {
    this.bsModal.show();
  }

  hide() {
    this.bsModal.hide();
  }

  handleCancel() {
    this.hide();
    this.onCancel.emit();
  }

  handleConfirm() {
    this.hide();
    this.onConfirm.emit();
  }

  handleClose() {
    this.hide();
    this.onClose.emit();
  }
}

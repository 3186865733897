import * as _ from 'lodash';
import { formatterNumberWithPercent } from './utils';
import { formatPointNumberWithCommas } from '../_common/utils/format-number';
import {KeyValue} from '../_common/models/common';

export enum ApplyStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED'
}

export const applyStatusTranslation = {
  [ApplyStatus.DRAFT]: '草稿',
  [ApplyStatus.SUBMITTED]: '已提交',
  [ApplyStatus.REJECTED]: '已退回',
};

export const applyStatusStyleClass = {
  [ApplyStatus.DRAFT]: 'status-draft',
  [ApplyStatus.SUBMITTED]: 'status-submitted',
  [ApplyStatus.REJECTED]: 'status-rejected',
};

export const ACCESSORY_DISCOUNT_ERROR_CODE = {
  STATICAL_MONTH_EXISTS: 'statical_month_exists',
  DATA_TOO_MANY: 'data_too_many',
};

export enum ExportStatuses {
  PROCESSING = '处理中',
  SUCCESS = '成功',
  FAILURE = '失败',
}

export const PAGE_SIZES = [
  {
    label: '20条/页',
    value: 20,
  },
  {
    label: '50条/页',
    value: 50,
  },
  {
    label: '100条/页',
    value: 100,
  },
];
export const positionOptions = [
  {
    text: '配件折扣申请列表',
    value: '配件折扣申请列表',
  }
];

export const getPositionOptionsByToggleAndPrivilege = (toggleOn: boolean, hasPrivilege: boolean): KeyValue[] => {
  const options = _.cloneDeep(positionOptions);
  if (toggleOn) {
    options.push({
      text: '配件折扣申请信息核查',
      value: '配件折扣申请信息核查',
    });
    if (hasPrivilege) {
      options.push({
        text: '新车次年政策支持清单',
        value: '新车次年政策支持清单',
      }, {
        text: '配件折扣结果展示',
        value: '配件折扣结果展示',
      });
    }
  }
  return options;
};

export enum ImportRecordType {
  NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DATA = 'NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DATA',
  NEW_CAR_SALES_INSURANCE_SUPPORT_DATA = 'NEW_CAR_SALES_INSURANCE_SUPPORT_DATA',
  THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_VERIFICATION_RESULT = 'THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_VERIFICATION_RESULT',
  OEM_INSURANCE_DATA = 'OEM_INSURANCE_DATA',
  OEM_SALES_INSURANCE_SUPPORT_DATA = 'OEM_SALES_INSURANCE_SUPPORT_DATA',
  THIRD_PARTY_ACCESSARY_DISCOUNT_APPLICATION_DETAIL_MOTIVATION_RESULT = 'THIRD_PARTY_ACCESSARY_DISCOUNT_APPLICATION_DETAIL_MOTIVATION_RESULT',
  INSURANCE_SALES_SUPPORT_POLICY_CODE = 'INSURANCE_SALES_SUPPORT_POLICY_CODE',
  THIRD_PARTY_ACCESSORY_DISCOUNT_RESULT = 'THIRD_PARTY_ACCESSORY_DISCOUNT_RESULT',
  NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DATA_APPEAL_RESULT = 'NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DATA_APPEAL_RESULT',
  THIRD_PARTY_ACCIDENT_VEHICLE_PARTS_PURCHASES = 'THIRD_PARTY_ACCIDENT_VEHICLE_PARTS_PURCHASES',
}

export enum ExcelTemplateType {
  DEALER_ACCESSORY_DISCOUNT_APPLICATION_RESULT = 'DEALER_ACCESSORY_DISCOUNT_APPLICATION_RESULT',
  THIRD_PARTY_ACCESSORY_DISCOUNT_RESULT = 'THIRD_PARTY_ACCESSORY_DISCOUNT_RESULT',
  ACCIDENT_BUSINESS_RENEWAL_BASE_DATA = 'ACCIDENT_BUSINESS_RENEWAL_BASE_DATA',
  NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DATA_APPEAL_RESULT = 'NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DATA_APPEAL_RESULT',
}

export enum HandlingOfComplaintsViewType {
  INFO,
  IMAGE,
  PDF,
}

export const DRAWER_HEADER_TYPE = {
  [HandlingOfComplaintsViewType.INFO]: { title: '处理申诉', nzClosable: true },
  [HandlingOfComplaintsViewType.IMAGE]: { title: '申诉材料预览', nzClosable: false },
  [HandlingOfComplaintsViewType.PDF]: { title: '申诉材料预览', nzClosable: false },
};

export enum ThirdPartyExportTypes {
  THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_VERIFICATION_DETAIL = 'THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_VERIFICATION_DETAIL',
  THIRD_PARTY_APPEAL_MATERIALS_DETAIL = 'THIRD_PARTY_APPEAL_MATERIALS_DETAIL',
  THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_DETAIL = 'THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_DETAIL',
  THIRD_PARTY_NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DETAIL = 'THIRD_PARTY_NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DETAIL',
  THIRD_PARTY_NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_APPEAL_MATERIALS = 'THIRD_PARTY_NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_APPEAL_MATERIALS',
  THIRD_PARTY_NEW_CAR_FIRST_YEAR_INSURANCE_SUPPORT_DETAIL = 'THIRD_PARTY_NEW_CAR_FIRST_YEAR_INSURANCE_SUPPORT_DETAIL',
}

export enum DealerExportTypes {
  DEALER_ACCESSORY_DISCOUNT_APPLICATION_VERIFICATION_DETAIL = 'DEALER_ACCESSORY_DISCOUNT_APPLICATION_VERIFICATION_DETAIL',
  DEALER_ACCESSORY_DISCOUNT_APPLICATION_DETAIL = 'DEALER_ACCESSORY_DISCOUNT_APPLICATION_DETAIL',
  DEALER_NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DETAIL = 'DEALER_NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DETAIL',
  DEALER_NEW_CAR_FIRST_YEAR_INSURANCE_SUPPORT_DETAIL = 'DEALER_NEW_CAR_FIRST_YEAR_INSURANCE_SUPPORT_DETAIL',
}

export enum ExcelTemplateTypes {
  THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_VERIFICATION_RESULT = 'THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_VERIFICATION_RESULT',
  THIRD_PARTY_INSURANCE_DATA = 'THIRD_PARTY_INSURANCE_DATA',
  THIRD_PARTY_SALE_INSURANCE_DATA = 'THIRD_PARTY_SALE_INSURANCE_DATA',
  NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DATA = 'NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_DATA',
  THIRD_PARTY_ACCIDENT_VEHICLE_PARTS_PURCHASES = 'THIRD_PARTY_ACCIDENT_VEHICLE_PARTS_PURCHASES',
}

export const groupDApplyResultMapper = [
  { text: '有效支付保单数', key: 'effectivePaidPolicyCount', format: (item) => _.get(item, 'effectivePaidPolicyCount') },
  {
    text: '车间部分客户付费事故车配件采购金额（a）',
    key: 'workshopPartCustomerPaidPartsPurchaseAmount',
    format: (item) => formatPointNumberWithCommas(_.get(item, 'workshopPartCustomerPaidPartsPurchaseAmount'), 2),
  },
  {
    text: '折扣率（b）',
    key: 'discountRate',
    format: (item) => formatterNumberWithPercent(_.get(item, 'discountRate')),
  },
  { text: '支付金额（c=a*b）', key: 'workshopPartPaymentAmount',
    format: (item) => formatPointNumberWithCommas(_.get(item, 'workshopPartPaymentAmount'), 2) },
];

export const groupABCApplyResultTableDefinition = [
  [
    {colspan: 2, text: '客户付费事故车配件采购总金额'},
    {
      colspan: 2, text: '',
      key: 'totalPartsPurchaseAmount',
      valueFormatter: (value) => formatPointNumberWithCommas(value, 2),
    },
    {colspan: 1, text: '有效保单数' },
    {colspan: 1, text: '', key: 'effectivePolicyCount', valueFormatter: null},
    {colspan: 1, text: '', key: 'nextYearEffectivePolicyCount', valueFormatter: null},
    {colspan: 1, text: '', key: 'underMaintenanceNotInsuredActivatedCount', valueFormatter: null},
  ],
  [
    {colspan: 2, text: '配件外销部分客户付费事故车配件采购金额'},
    {colspan: 2, text: '', key: 'outsourcedPartsPurchaseAmount', valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
    {colspan: 1, text: '续保基数' },
    {colspan: 1, text: '', key: 'renewalBaseNumber', valueFormatter: null},
    {colspan: 1, text: '', key: 'nextYearRenewalBaseNumber', valueFormatter: null},
    {colspan: 1, text: '', key: 'underMaintenanceNotInsuredRenewalBaseNumber', valueFormatter: null},
  ],
  [
    {colspan: 2, text: '车间部分客户付费事故车配件采购金额'},
    {colspan: 2, text: '', key: 'workshopPartsPurchaseAmount', valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
    {colspan: 1, text: '续保渗透率'},
    {colspan: 1, text: '', key: 'renewalPenetrationRate', valueFormatter: (value) => formatterNumberWithPercent(value)},
    {colspan: 1, text: '', key: 'nextYearPenetrationRate', valueFormatter: (value) => formatterNumberWithPercent(value)},
    {colspan: 1, text: '', key: 'underMaintenanceNotInsuredActivationRate', valueFormatter: (value) => formatterNumberWithPercent(value)},
  ],
  [
    {colspan: 2, text: '同期客户付费事故车配件采购总金额'},
    {colspan: 2, text: '', key: 'currentPeriodTotalPurchaseAmount', valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
    {colspan: 1, text: '续保业务管理得分'},
    {colspan: 1, text: '', key: 'renewalBusinessManagementScore1', valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
    {colspan: 1, text: '', key: 'renewalBusinessManagementScore2', valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
    {colspan: 1, text: '', key: 'renewalBusinessManagementScore3', valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
  ],
  [
    {colspan: 2, text: '同期配件外销部分客户付费事故车配件采购金额'},
    {
      colspan: 2,
      text: '',
      key: 'currentPeriodOutsourcedPartsPurchaseAmount',
      valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
    {colspan: 2, text: '续保业务管理总得分'},
    {
      colspan: 2,
      text: '',
      key: 'renewalBusinessManagementTotalScore',
      valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
  ],
  [
    {colspan: 2, text: '同期车间部分客户付费事故车配件采购金额'},
    {
      colspan: 2,
      text: '', key: 'currentPeriodWorkshopPartsPurchaseAmount',
      valueFormatter: (value) => formatPointNumberWithCommas(value, 2),
    },
    {colspan: 2, text: '留修调研成绩'},
    {colspan: 2, text: '', key: 'retentionResearchScore', valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
  ],
  [
    {colspan: 2, text: '事故车配件销售同比增长比率'},
    {colspan: 2, text: '', key: 'partsSalesYoyGrowthRate', valueFormatter: (value) => formatterNumberWithPercent(value)},
    {colspan: 2, text: '附加折扣率 (b)'},
    {colspan: 2, text: '', key: 'additionalDiscountRate', valueFormatter: (value) => formatterNumberWithPercent(value)},
  ],
  [
    {colspan: 2, text: '基础折扣率 (a)'},
    {colspan: 2, text: '', key: 'baseDiscountRate', valueFormatter: (value) => formatterNumberWithPercent(value)},
    {colspan: 4, text: ''},
  ],
  [
    {colspan: 2, text: '总折扣率 (c=a+b)'},
    {colspan: 6, text: '', key: 'totalDiscountRate', valueFormatter: (value) => formatterNumberWithPercent(value)},
  ],
  [
    {colspan: 2, text: '计入事故车配件销售折扣体系的采购金额 (d)'},
    {
      colspan: 6,
      text: '',
      key: 'purchaseAmountInPartsSalesDiscountSystem',
      valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
  ],
  [
    {colspan: 2, text: '支付金额 (e=c*d)'},
    {colspan: 6, text: '', key: 'partsSalesDiscountPaymentAmount', valueFormatter: (value) => formatPointNumberWithCommas(value, 2)},
  ],
];

export const SHOULD_DOWNLOAD_ZIP_FILE_TYPES = [
  ThirdPartyExportTypes.THIRD_PARTY_NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_APPEAL_MATERIALS,
  ThirdPartyExportTypes.THIRD_PARTY_APPEAL_MATERIALS_DETAIL,
];

import { Injectable } from '@angular/core';
import { Body, DELETE, GET, PATCH, Path, POST, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { SubSalesType } from '../models/sub-sales-type';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SubSalesTypeApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sub-sales-type')
  getSubSalesTypes(): Observable<SubSalesType[]> {
    return null;
  }

  @POST('/api/sub-sales-type')
  createSubSalesType(@Body data): Observable<SubSalesType> {
    return null;
  }

  @PATCH('/api/sub-sales-type/:id')
  updateSubSalesType(@Path('id') id: number,
                     @Body data) {
    return null;
  }

  @DELETE('/api/sub-sales-type/:id')
  deleteSubSalesType(@Path('id') id: number) {
    return null;
  }
}

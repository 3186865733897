import { Injectable } from '@angular/core';
import { Body, GET, Headers, PATCH, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { LeadReservation } from '../models/lead';
import { Observable } from 'rxjs';

@Injectable()
export class ShowroomVisitApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/showroom-visits')
  getShowroomVisitListData(@Query('start_time') from: number,
                           @Query('end_time') duration: number): Observable<any> {
    return null;
  }

  @PATCH('/api/showroom-visit/:id')
  updateShowRoomVisit(@Path('id') showroomVisitId: string, @Body data: Object) {
    return null;
  }

  @GET('/api/sales-website/showroom-visits')
  getListDataByStatus(
    @Query('status') status: string
  ) {
    return null;
  }

  @GET('/api/showroom-visit')
  getShowroomVisitsByLeadId(@Query('lead_id') leadId: string) {
    return null;
  }

  @GET('/api/sales-website/showroom-visits/download')
  @Headers({ isExportFile: true })
  exportShowroomVisitListData(@Query('start_time') from: number,
                              @Query('end_time') duration: number
                              ) {
    return null;
  }

  @POST('/api/showroom-visit/finish')
  finishShowRoomVisit(@Body data: Object) {
    return null;
  }

  @POST('/api/test-drive-pre-assign')
  testDrivePreAssign(@Body data: Object) {
    return null;
  }

  @GET('/api/leads/reservation')
  getLeadReservationList(
    @Query('startTime') startTime: number,
    @Query('endTime') endTime: number
  ): Observable<LeadReservation[]> {
    return null;
  }

  @GET('/aftersales/tablet/product-experts')
  getProductExpert(@Query('leadId') leadId): Observable<any> {
    return null;
  }

  @GET('/api/lead_sources')
  getLeadSources(@Query('type') type): Observable<any> {
    return null;
  }
}

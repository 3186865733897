import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid';
import {findTextByValue} from '../../../../../report-center/utils/utils';
import {OAB_STATUS} from '../../../../../oab/d2d/const';
import * as _ from 'lodash';

@Component({
  template: `
    <div>
      <i class="antd-circle-filled-black-supplement" [class.disabled]="!isShow" [class.red]="isRed"></i>
      {{status}}
    </div>
    <div *ngIf="isShowConfirmationCode()" [ngClass]="{'highlighted': isHovered}" class="confirmation-code" (mouseenter) = "onMouseEnter()" (mouseleave)="onMouseLeave()">
      {{confirmationCode != null ? confirmationCode : '&nbsp;'}}
    </div>
  `,
  styleUrls: ['./oab-order-status-cell.style.scss'],
})
export class OabOrderStatusCellComponent implements ICellRendererAngularComp {
  public status: string;
  public isShow: boolean = false;
  public isRed: boolean = false;
  public confirmationCode: string;
  public isHovered: boolean = false;
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.status = _.get(params.node.data, 'statusMaster.deliveryNameCn', '-');
    this.isShow = this.status !== '-';
    this.params = params;
    this.confirmationCode = '接车验证码';

    const redStatusList = [OAB_STATUS.NO_CONFIRM.text, OAB_STATUS.WAIT_ASSIGN_SA.text, OAB_STATUS.NO_ASSIGN_SA.text,
      OAB_STATUS.WAIT_CONFIRM_SENT.text, OAB_STATUS.NO_CONFIRM_SENT.text];
    this.isRed = _.includes(redStatusList, this.status);
  }

  refresh(params: any): boolean {
    return false;
  }

  onMouseEnter() {
    this.isHovered = true;
    this.confirmationCode = _.get(this.params.node.data, 'driverDetail.driveConfirmationCode', null);
  }

  onMouseLeave() {
    this.isHovered = false;
    this.confirmationCode = '接车验证码';
  }

  isShowConfirmationCode(): boolean {
    const isShowConfirmationCodeStatus = [OAB_STATUS.E_DRIVE_OPENED.text, OAB_STATUS.E_DRIVE_READY.text,
      OAB_STATUS.E_DRIVE_DRIVING.text, OAB_STATUS.E_DRIVE_ARRIVED.text];
    const serviceProvider = (_.get(this.params.node.data, 'serviceProviderMasterDTO.codeEn', '-'));

    return _.includes(isShowConfirmationCodeStatus, this.status) && 'EDAIJIA' === serviceProvider;
  }
}

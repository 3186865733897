export const NON_NUMERIC_RULE = /\D+/g;
export const NON_NUMERIC_AND_NON_LETTER_RULE = /[^\dA-Za-z]/g;
export const DIGIT_NUMBER_RULE = /\B(?=(\d{3})+(?!\d))/g;
export const NON_NUMERIC_WITH_POINT_RULE = /[^\d.]/g;
export const NUMBER_WITH_POINT_RULE = /^[0-9.]*$/;
export const PHONE_NUMBER_WITH_LINE_RULE = /^[\d\s\-]+$/;
export const LONGITUDE_RULE = /^-?(0(\.\d{1,6})?|([1-9](\d)?)(\.\d{1,6})?|1[0-7]\d{1}(\.\d{1,6})?|180(\.0{1,6})?)$/;
export const LATITUDE_RULE = /^-?((0|([1-8]\d?))(\.\d{1,6})?|90(\.0{1,6})?)$/;
export const PHONE_NUMBER_RULE = /^(((1[3456789][0-9]{1})|(15[0-9]{1}))+\d{8})$/;
export const NUMBER_OR_LETTER_RULE = /^[0-9a-zA-Z]+$/;
export const CAR_PLATE_NUMBER_RULE =  {
  MAINLAND:
      /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼][A-Za-z](-)?[A-Za-z0-9]{5}$/,
  HK_MACAO_TW: /^([a-zA-Z]{2}[0-9]{4})$/,
  HK_MACAO_TW2: /^([0-9]{4}[a-zA-Z]{2})$/,
  TRAM: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼][A-Za-z](-)?[A-Za-z0-9]{6}$/,
  ARMED_POLICE: /^([wW][jJ][A-Za-z0-9]{7})$/,
  POLICE_CONSULATE:
      /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼][A-Za-z][A-Za-z0-9]{4}[警领]$/,
  EMBASSY: /^[使][0-9]{6}$/,
  EMBASSY2: /^[0-9]{6}[使]$/,
  MAINLAND_HK_MACAO: /^粤[Zz][A-Za-z0-9]{4}[港澳]$/,
  TEMPORARY: /([临]([A-Z0-9]|[\u4e00-\u9fa5]){6})$/,
};

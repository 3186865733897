import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { OtrMapOptions } from './models/Map';
import { OtrMapService } from './service/map.service';

@Component({
  providers: [OtrMapService],
  // tslint:disable-next-line:component-selector
  selector: 'otr-baidu-map',
  styles: [
    `
      .otr-baidu-map-instance {
        width: 100%;
        height: 100%;
        display: none;
        svg {
          * {
            fill: none !important;
          }
        }
      }

      .otr-baidu-map-offline {
        width: 100%;
        height: 100%;
        background-color: #E6E6E6;
        position: relative;
        display: none;
      }

      .otr-baidu-map-offline label {
        font-size: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0 -50% 0 0;
        transform: translate(-50%, -50%);
      }
    `,
  ],
  template: `
    <div #otrmapinstance class="otr-baidu-map-instance"></div>
    <div class="otr-baidu-map-offline">
      <label>{{ 'NO_NETWORK' }}</label>
    </div>
  `,
})
export class MapComponent implements OnInit {
  @Input() private options: OtrMapOptions;

  @Output() private loaded = new EventEmitter();

  @ViewChild('otrmapinstance') private mapInstance: ElementRef;

  constructor(private _service: OtrMapService) {
  }

  public ngOnInit() {
    this._service
      .createOtrMap(this.mapInstance.nativeElement, this.options, (map) => {
        this.loaded.emit(map);
      });
  }
}

export const STOCK_ORDER_STATUS = {
  AVAILABLE: { value: 'Available', text: '可用' },
  OFFERED: { value: 'Offered', text: '可用' },
  ORDERED: { value: 'Ordered', text: '已创建' },
  INVOICING: { value: 'Invoicing', text: '已确认' },
  CONFIRMED: { value: 'Confirmed', text: '已确认' },
  INVOICED_INVALIDATED: { value: 'Invoiced_invalidated', text: '已开票' },
  INVOICED: { value: 'Invoiced', text: '已开票' },
  CANCEL_WAIT_CREDIT: { value: 'CancelWaitCredit', text: '已开票' },
  MODIFIED_WAIT_CREDIT: { value: 'ModifiedWaitCredit', text: '已开票' },
  DELIVERED: { value: 'Delivered', text: '已交车' },
};

export const SHARE_RANGE = [
  { value: 'NO_SHARE', text: '不共享', default: true },
  { value: 'PMA', text: '所属PMA' },
  { value: 'REGION', text: '所属区域' },
  { value: 'DEALER_GROUP', text: '所属集团' },
  { value: 'SHARE_DEALER', text: '经销商' },
  { value: 'WHOLE_COUNTRY', text: '全国' },
  { value: 'DEALER', text: '总公司/分公司' },
];

export const SHARE_VISIBLE_ROLES = [
  { value: 'SC', text: '销售顾问' },
  { value: 'SS', text: '销售主管' },
  { value: 'SRM', text: '展厅经理' },
  { value: 'SM', text: '销售经理' },
  { value: 'GM', text: '总经理' },
];

export const VAN_CLASS_DISPLAY_MAPPING = {
  V: 'V-Class',
  VT: 'Vito',
  Viano: 'Viano',
  Sprinter: 'Sprinter',
};

export const BRANDS = {
  SMART: 'SMART',
  AMG: 'AMG',
  MB: 'MB',
  DENZA: 'DENZA',
};

export const VAN_TYPE_CLASS: string[] = ['V', 'Vito'];

export const MAX_ACCESSORY_AMOUNT = 99;
export const MIN_ACCESSORY_AMOUNT = 1;

import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-edit-delete-row',
  templateUrl: './edit-delete-row.component.html',
  styleUrls: ['./edit-delete-row.component.scss'],
})
export class EditDeleteRowRenderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  disabled: boolean = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.disabled = this.getDisabled();
  }

  refresh(params: any): boolean {
    return false;
  }

  getDisabled() {
    if (this.cellRendererParams.disabled instanceof Subject) {
      return this.cellRendererParams.disabled.getValue();
    }

    return _.isFunction(this.cellRendererParams.disabled) ?
      this.cellRendererParams.disabled(this.params) : this.cellRendererParams.disabled;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onStartEditing() {
    this.cellRendererParams.onStartEditing(this.params);
  }

  onStartDeleting() {
    this.cellRendererParams.onStartDeleting(this.params);
  }

  get shouldHiddenDeleting() {
    return _.isFunction(this.cellRendererParams.onHiddenDeleting) ?
      this.cellRendererParams.onHiddenDeleting(this.params) : false;
  }

  get shouldHiddenEditing() {
    return _.isFunction(this.cellRendererParams.onHiddenEditing) ?
      this.cellRendererParams.onHiddenEditing(this.params) : false;
  }
}

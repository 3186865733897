import * as moment from 'moment';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import {
  FILTER_INPUT_TYPES,
  VEHICLE_BUSINESS_TYPE_OPTION, COMPLETION_STATUS
} from '../constants/report';
import { dateFormatter } from '../utils/utils';
import {USER_ROLE} from '../../_common/constants/user-role.constant';

export const SERVICE_MEASURE_RECALL_COMPLETION_DETAIL_REPORT = {
  name: 'service-measure-recall-completion-detail-report',
  enablePagination: true,
  pageSize: 20,
  enableCustomizedExportedFileName: true,
  getCustomizedExportedFileName({dealerName}) {
    const fileName = `召回和服务措施月度目标车数完成率（TAR_CR）明细_${moment().format('YYYYMMDDHHmm')}.xlsx`;
    return dealerName ? `${dealerName}_${fileName}` : fileName;
  },
  isNameWithDate: true,
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  isNameWithVehicleBusinessType: true,
  disableCollapsed: true,
  column: [
    {
      headerName: 'Damage code',
      field: 'damageCode',
      width: 150,
    },
    {
      headerName: 'FIN号',
      field: 'fin',
      width: 200,
    },
    {
      headerName: '描述',
      field: 'fileDescription',
      width: 180,
    },
    {
      headerName: '服务类型',
      field: 'serviceType',
      width: 180,
    },
    {
      headerName: '开始考核时间',
      field: 'tarStartDate',
      width: 150,
      valueFormatter: (params) => dateFormatter(params, 'YYYY-MM-DD'),
    },
    {
      headerName: '完成状态',
      field: 'serviceStatus',
      width: 150,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'damageCode',
      label: 'DC号',
      maxLength: 7,
      placeholder: '请输入完整的DC号',
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'fin',
      label: 'FIN号',
      placeholder: '请输入完整的FIN号',
    },
    {
      type: FILTER_INPUT_TYPES.MULTI_DROPDOWN,
      name: 'serviceStatus',
      label: '完成状态',
      options: COMPLETION_STATUS.map((status) => ({ text: status, value: status})),
      defaultValue:  ['SELECT_ALL', ...COMPLETION_STATUS],
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车辆类型',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
      visible: (currentUserService, tokenService) => currentUserService.isRoleCode(USER_ROLE.DGA.value) ||
        (currentUserService.isRoleCodeIn([USER_ROLE.ASM.value, USER_ROLE.ASSA.value]) && tokenService.isVanPc('AS')),
    },
  ],
};

<div class="plan-detail-container"
     [style.left.px]="left"
     [style.top.px]="top"
     #planDetailModal>
  <app-label-text label="提升行动方案" [text]="salesPlan.description"></app-label-text>
  <app-label-text label="时间" [text]="planDateSpan"></app-label-text>
  <div class="plan-container" *ngFor="let week of weeklyPlanTitle">
    <app-label-text [label]="week.text" [text]="salesPlan[week.value]"></app-label-text>
  </div>
</div>

import { Injectable } from '@angular/core';
import { GET, Path, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class YearlyTargetApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/website/yearly-targets/sales')
  getSalesYearlyTarget(): Observable<any> {
    return null;
  }

  @GET('/api/website/yearly-targets/after-sales')
  getAfterSalesYearlyTarget(): Observable<any> {
    return null;
  }

  @GET('/api/website/yearly-targets/after-sales/:id')
  getAfterSalesYearlyTargetDetail(@Path('id') id): Observable<any> {
    return null;
  }

  @GET('/api/website/yearly-targets/sales/:id')
  getSalesYearlyTargetDetail(@Path('id') id): Observable<any> {
    return null;
  }
}

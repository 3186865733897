import { Component, OnInit } from '@angular/core';

import { PasswordResetService } from '../services/password-reset.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  isInVerification: Observable<boolean>;
  errorMessage: Observable<string>;

  constructor(private service: PasswordResetService) {
  }

  ngOnInit() {
    this.isInVerification = this.service.isInVerification;
    this.errorMessage = this.service.errorMessage;
    this.service.reset();
  }
}

import { Injectable } from '@angular/core';
import { GET, RebirthHttp, Path, Body, PATCH, POST, Query, Headers } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CtiApi extends RebirthHttp {

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/customers/mobile/:mobile')
  getCustomerListByMobile(@Path('mobile') mobile: string): Observable<any> {
    return null;
  }

  @PATCH('/api/cti-call-history')
  saveCustomerOrPhoneNumber(@Body data): Observable<any> {
    return null;
  }

  @POST('/api/cti-call-history')
  addCtiCallHistory(@Body data): Observable<any> {
    return null;
  }

  @GET('/api/cti-call-history/record/download')
  @Headers({ isExportFile: true })
  downloadRecordAdioById(@Query('id') id: string, @Query('isFrontOrBack') isFrontOrBack: boolean): Observable<any> {
    return null;
  }

  @GET('/api/cti/companies/:companyId')
  getCompanyNameById(@Path('companyId') companyId: string): Observable<any> {
    return null;
  }

  @GET('/api/leads/customer/open-lead')
  getNotClosedSalesLeadsById(@Query('customerId') id: string): Observable<any> {
    return null;
  }
}

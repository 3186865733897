import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, Events } from 'ag-grid';
import { Subject, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { CustomCheckboxHeaderService } from '../../../../services/cumtom-checkbox-header.service';

export interface HeaderComponentParams {
  checkAllService: CustomCheckboxHeaderService;
}

@Component({
  selector: 'app-custom-checkbox-header',
  templateUrl: './custom-checkbox-header.component.html',
  styleUrls: ['./custom-checkbox-header.component.scss'],
})
export class CustomCheckboxHeaderRenderComponent implements IHeaderAngularComp {

  private params: IHeaderParams;
  columnParams: HeaderComponentParams;
  checkAllService: CustomCheckboxHeaderService;
  checked$: Subject<boolean> = new BehaviorSubject(false);
  isDisabled: boolean = false;

  agInit(params: IHeaderParams) {
    this.params = params;
    this.listenEvents(params.api);
    this.columnParams = params.column.getColDef().headerComponentParams;
    this.checkAllService = this.columnParams.checkAllService;
    _.get(this.checkAllService, 'checked$')
      .subscribe(isChecked => this.checked$.next(isChecked));
  }

  onToggle(checked) {
    if (!checked) {
      this.api.dispatchEvent({ type: 'HEADER_UNCHECKED' });
      return this.api.deselectAll();
    }
    this.api.dispatchEvent({ type: 'HEADER_CHECKED' });
    this.api.selectAll();
  }

  private listenEvents(api): void {
    api.addEventListener(Events.EVENT_ROW_SELECTED, () => {});

    api.addEventListener(Events.EVENT_GRID_READY, () => {});

    api.addEventListener(Events.EVENT_MODEL_UPDATED, () => {});
  }

  get api() {
    return this.params.api;
  }
}

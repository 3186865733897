import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

@Component({
  selector: 'app-training-material-management-action-cell',
  templateUrl: './training-material-management-action-cell.component.html',
  styleUrls: ['./training-material-management-action-cell.component.scss'],
})
export class TrainingMaterialManagementActionCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onEditing() {
    this.cellRendererParams.onEditing(this.params);
  }

  onDeleting() {
    this.cellRendererParams.onDeleting(this.params);
  }

}

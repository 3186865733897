import { AfterContentInit, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { TableTemplateDirective } from './tableTemplate.component';

@Component({
  selector: 'app-table-column',
  template: ``,
})
export class ColumnComponent implements AfterContentInit {

  @Input() title: string;
  @Input() key: any;
  @Input() renderer: Function;

  @ContentChildren(TableTemplateDirective) templates: QueryList<any>;

  public headerTemplate: TemplateRef<any>;
  public bodyTemplate: TemplateRef<any>;

  public render: Function = (data: any, index: number) => {
    if (_.isFunction(this.renderer)) {
      return this.renderer(data, index);
    }

    if (_.isFunction(this.key)) {
      return this.key(data);
    }

    const value = this.key ? _.get(data, this.key, '') : '';
    return value;
  }

  ngAfterContentInit(): void {
    this.templates.forEach(item => {
      switch (item.type) {
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'body':
          this.bodyTemplate = item.template;
          break;
        default:
          this.bodyTemplate = item.template;
          break;
      }
    });
  }

}

import { Component } from '@angular/core';
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid';
import { IFilterAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { ExtendedGridEvents } from '../../event/events';
import { isEmptyValue } from '../../../../utils/common';
import { isMobileField } from '../../../../utils/format-phone-number';

@Component({
  selector: 'app-pagination-search-filter-cell',
  templateUrl:
    'pagination-search-filter/pagination-search-filter.template.html',
  styleUrls: ['pagination-grid-filter.style.scss'],
})
export class BasePaginationFilterComponent implements IFilterAngularComp {
  public params: IFilterParams;
  public searchInput: FormControl = new FormControl('');
  public text: string = '';
  private hidePopup;
  modelFormatter: (map: any) => any = value => value;
  placeholder: string = '搜索本列数据';
  hasResetBtn: boolean = false;
  isNumber: boolean = false;
  inputWidth: number = 118;
  isVinOrPlateNumberVerify: boolean = false;
  label: '';

  agInit(params: IFilterParams): void {
    this.params = params;
    const filterParams = params.colDef.filterParams;
    this.modelFormatter = _.get(
      filterParams,
      'modelFormatter',
      this.modelFormatter,
    );
    this.placeholder = _.get(filterParams, 'placeholder', '搜索本列数据');
    this.isNumber = _.get(filterParams, 'isNumber', false);
    this.inputWidth = _.get(filterParams, 'inputWidth', 118);
    this.label = _.get(filterParams, 'label', '未填写');
    this.hasResetBtn = _.get(filterParams, 'hasResetBtn', false);
    this.isVinOrPlateNumberVerify = _.get(filterParams, 'isVinOrPlateNumberVerify', false);
    this.eventEmitter.on(ExtendedGridEvents.CLEAR_PAGINATION_FILTER, () => {
      this.setDefaultValue();
    });
  }

  get isMobile(): boolean {
    return isMobileField(this.params.colDef.field);
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    this.hidePopup = params.hidePopup;
    this.searchInput.setValue(this.text);
  }

  get eventEmitter() {
    return this.params.context.eventEmitter;
  }

  hideFilterMenu() {
    this.hidePopup();
  }

  public setDefaultValue() {
    this.searchInput.setValue('');
    this.text = '';
  }

  public onSubmit() {
    if (this.isVinOrPlateNumberVerify && this.searchInput.value.length < 4 && this.searchInput.value.length > 0) {
      this.onReset();
    } else {
      this.text = this.searchInput.value;
      this.params.filterChangedCallback();
      this.hideFilterMenu();
    }
  }

  onCancel() {
    this.searchInput.setValue(this.text);
    this.hideFilterMenu();
  }

  onReset() {
    this.searchInput.setValue('');
  }

  isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true;
  }

  getModel(): any {
    if (!this.isFilterActive()) {
      return null;
    }
    return this.modelFormatter(this.text);
  }

  setModel(model: any, itself: boolean = true): void {
    if (model instanceof Array) {
      const name = model[0];
      this.text = itself ? name :  _.get(name, 'value', '');
    } else {
      this.text = itself ? model : _.get(model, 'value', '');
    }
  }

  get filterValue(): any {
    return _.chain(this.text)
      .split(';')
      .compact()
      .uniq()
      .map(value => this.filterFormatter(value))
      .value();
  }

  isEmptyFilter() {
    return isEmptyValue(this.filterValue);
  }

  isFilterValid() {
    const testRegExp = _.get(
      this.params.colDef.filterParams,
      'regExp',
      new RegExp(''),
    );
    return !_.trim(this.searchInput.value) || testRegExp.test(_.trim(this.searchInput.value));
  }

  get filterFormatter() {
    return _.get(
      this.params.colDef.filterParams,
      'filterFormatter',
      _.identity,
    );
  }
}

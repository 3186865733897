import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

@Component({
  selector: 'app-delete-row-cell',
  templateUrl: 'delete-row.template.html',
  styleUrls: ['delete-row.style.scss'],
})
export class DeleteRowRenderComponent implements ICellRendererAngularComp {
  params;
  isVisible: boolean = true;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params.column.colDef.cellRendererParams) {
      this.isVisible = this.params.column.colDef.cellRendererParams.isVisible(this.params);
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onStartDeleting() {
    this.params.column.colDef.cellRendererParams.onStartDeleting(this.params);
  }
}

import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApplyListComponentService } from '../../accessory-discount/apply-list/apply-list.component.service';
import { AccessoryDiscountService } from '../../accessory-discount/service/accessory-discount.service';
import { filter } from 'rxjs/operators';

@Injectable()
export class ApplyListGuard implements CanActivate {
  constructor(
    private accessoryDiscountService: AccessoryDiscountService,
    private applyListComponentService: ApplyListComponentService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    this.accessoryDiscountService.getConfirmedNotification().subscribe(
      res => {
        this.applyListComponentService.showConfirmedNotification$.next(res);
      },
    );

    this.accessoryDiscountService.getTipsInformation('配件折扣申请列表').subscribe(
      res => {
        this.applyListComponentService.showTipsInfo$.next(res);
      }
    );
    return true;
  }
}

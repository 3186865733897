import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-checkbox-popup',
  styleUrls: ['./checkbox-popup.style.scss'],
  templateUrl: './checkbox-popup.template.html',
})
export class CheckboxPopupComponent {
  isAllChecked: boolean = false;
  @Input() applyText: string;
  @Input() options: Array<any>;
  @Output() onSubmit = new EventEmitter();
  @Output() onClick = new EventEmitter();

  onItemClick(event) {
    this.onClick.emit(event);
  }

  toggleAllChecked($event) {
    this.isAllChecked = $event.target.checked;
    this.options.forEach(option => option.checked = this.isAllChecked);
  }

  toggleChecked($event) {
    const targetValue = $event.target.value;
    const targetOption = _.find(this.options, { value: targetValue });
    targetOption.checked = $event.target.checked;
    this.isAllChecked = this.options.every(option => option.checked);
  }

  apply() {
    const checkedOptions = this.options.filter(option => option.checked);
    const checkedValues = checkedOptions.map(option => option.value);
    this.onSubmit.emit(checkedValues);
  }

  resetCheckedValue() {
    this.options.forEach(option => option.checked = false);
    this.isAllChecked = false;
  }
}

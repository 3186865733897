import { MONTHLY_REVENUE_STATISTICS_CONFIG } from '../config/monthly-revenue-statistics';
import { MONTHLY_ORDER_OVERVIEW_CONFIG } from '../config/monthly-order-overview';
import { SERVICE_ORDER_GENERAL_QUERY_CONFIG } from '../config/serivce-order-general-query';
import { CHECKEDIN_VEHICLE_STATISTICS_CONFIG } from '../config/checkedin-vehicle-statistics';
import { CLOCKOFF_STATISTICS_CONFIG } from '../config/clockoff-statistics';
import { SERVICE_MEASURE_T1_COMPLETION_RATE_REPORT_CONFIG } from '../config/service-measure-t1-completion-rate-report';
import { LOST_SALE_REPORT_CONFIG } from '../config/lost-sale-report';
import { NOT_CHECKEDIN_VEHICLE_STATISTICS_CONFIG } from '../config/not_checkedin_vehicle_statistics';
import { WAIT_FOR_PAYMENT_ORDER_CONFIG } from '../config/wait-for-payment-order';
import { REPAIR_SALES_RECORD_GENERAL_QUERY_CONFIG } from '../config/repair-sales-record-general-query';
import { SERVICE_MEASURE_COMPLETION_STATUS_QUERY_CONFIG } from '../config/service-measure-completion-status-query';
import { WARRANTY_ORDER_STATUS_QUERY_CONFIG } from '../config/warranty-order-status-query';
import { PARTS_RESERVATION_REPORT_CONFIG } from '../config/parts-reservation-report';
import { DAILY_ORDER_DETAIL_STATISTICS_CONFIG } from '../config/daily-order-detail-statistics';
import { TODAY_REVENUE_STATISTICS_CONFIG } from '../config/today-revenue-statistics';
import { ORDER_DETAIL_REPORT_CONFIG } from '../config/order-detail-report';
import { getReportFilterConfig } from '../utils/utils';
import { PARTS_SALES_ORDER_REPORT_CONFIG } from '../config/parts-sales-order-details';
import { ORDER_SOURCE_OPTIONS_FOR_VAN, PARTS_RESERVATION_SERVICE_RECORD_CATEGORY_FOR_VAN } from './report';
import { PRE_INSPECTION_CHECK_RATE_REPORT_CONFIG } from '../config/pre-inspection-check-rate-report';
import { REPAIR_PACKAGE_USAGE_RATE_REPORT_CONFIG } from '../config/repair-package-usage-rate-report';
import { ANALYSIS_REPORT_CENTER_SUB_TAB_ROUTER } from '../../workshop/report/analysis-report/analysis-report-config-map';
import { ACCIDENT_CAR_LEAD_LIST_REPORT_CONFIG } from '../config/accident-car-lead-list-report';
import { ACCIDENT_CAR_LEAD_KPI_OPERATION_CONFIG } from '../config/accident-car-lead-kpi-operation';
import { ACCIDENT_CAR_INSURANCE_LEADS_ANALYZE_CONFIG } from '../config/accident-car-insurance-leads-analyze';
import { ACCIDENT_CAR_INSURANCE_CATEGORY_ANALYZE_CONFIG } from '../config/accident-car-insurance-category-analyze';
import { ACCIDENT_CAR_RETENTION_SPECIALIST_OWNER_MANAGEMENT_CONFIG } from '../config/retention-specialist-owner-management';
import { ACCIDENT_CAR_LEAD_SOURCES_ANALYSIS_CONFIG } from '../config/accident-car-lead-sources-analyze';
import { MB_PRODUCT_CAR_INSURANCE_LEAD_KPI_REPORT_CONFIG } from '../config/mb-product-car-insurance-lead-kpi-report';
import { ACCIDENT_CAR_INSURANCE_GROSS_AMOUNT_ANALYZE_REPORT_CONFIG } from '../config/accident-car-insurance-gross-amount-analyze-report';
import { ACCIDENT_CAR_SMS_LIST_REPORT_CONFIG } from '../config/accident-car-sms-list-report';
import { FeatureToggle } from '../../_common/constants/feature-toggle';
import {
  SERVICE_MEASURE_RECALL_COMPLETION_DETAIL_REPORT
} from '../config/service-measure-recall-completion-detail-report';

export const REPORT_CENTER_SUB_TAB_ROUTER = {
  MONTHLY_REVENUE_STATISTICS: '/report-center/finance/monthly-revenue-statistics',
  REPAIR_SALES_RECORD_GENERAL_QUERY: '/report-center/finance/repair-sales-record-general-query',
  NOT_CHECKED_IN_VEHICLE_STATISTICS: '/report-center/customer/not-checked-in-vehicle-statistics',
  SERVICE_FOLLOW_UP_REPORT: '/report-center/customer/service-follow-up-report',
  REGULAR_MAINTENANCE_REMINDER: '/report-center/customer/regular-maintenance-reminder',
  MONTHLY_ORDER_OVERVIEW: '/report-center/front-desk/monthly-order-overview',
  SERVICE_ORDER_GENERAL_QUERY: '/report-center/front-desk/service-order-general-query',
  CHECKED_IN_VEHICLE_STATISTICS: '/report-center/front-desk/checked-in-vehicle-statistics',
  CLOCK_OFF_STATISTICS: '/report-center/front-desk/clock-off-statistics',
  WAIT_FOR_PAYMENT_ORDER: '/report-center/front-desk/wait-for-payment-order',
  LOST_SALE_REPORT: '/report-center/front-desk/lost-sale-report',
  INCOMPLETE_PAYMENT_WARRANTY_ORDER: '/report-center/front-desk/incomplete-payment-warranty-order',
  PWEI_CHECKING: '/report-center/front-desk/pwei-checking',
  SERVICE_MEASURE_T1_COMPLETION_RATE_REPORT: '/report-center/front-desk/service-measure-t1-completion-rate-report',
  SERVICE_MEASURE_COMPLETION_STATUS_QUERY: '/report-center/front-desk/service-measure-completion-status-query',
  PARTS_RESERVATION_REPORT: '/report-center/parts/parts-reservation-report',
  WARRANTY_ORDER_STATUS_QUERY: '/report-center/warranty/warranty-order-status-query',
  CH5_CUSTOMER_PAY_LABOR_RATE_EVALUATION_REPORT: '/report-center/others/ch5-customer-pay-labor-rate-evaluation-report',
  PARTS_MOVEMENT_DETAIL_REPORT: '/report-center/parts/movement-detail',
  PARTS_MOVEMENT_OVERVIEW_REPORT: '/report-center/parts/movement-overview',
  PARTS_OBSOLETE_PARTS_REPORT: '/report-center/parts/obsolete-parts',
  PARTS_OUT_STOCK: '/report-center/parts/parts-out-stock',
  PARTS_SETTLE_ACCOUNTS: '/report-center/parts/parts-settle-accounts',
  PARTS_UN_SETTLE_ACCOUNTS: '/report-center/parts/parts-unsettle-accounts',
  PARTS_DAILY_OPERATION: '/report-center/parts/parts-daily-operation',
  PARTS_OLD_PARTS_INVENTORY: '/report-center/parts/old-parts-inventory',
  PARTS_SALES_ORDER_DETAILS_OVERVIEW: '/report-center/parts/sales-order-details',
  PARTS_SALES_ANALYSIS: '/report-center/parts/sales-analysis',
  PARTS_SALES_ANALYSIS_REPORT: '/report-center/parts/market-sales-analysis',
  PARTS_SALES_SUMMARY_REPORT: '/report-center/parts/sales-summary',
  PARTS_BILL_SUMMARY_REPORT: '/report-center/parts/bill-summary',
  PARTS_DIMS_BUYBACK_ORDER_REPORT: '/report-center/parts/dims-buyback-order',
  PARTS_DIMS_BUYBACK_REPORT: '/report-center/parts/dims-buyback',
  PARTS_DISCOUNT_RETAIL_REPORT: '/report-center/parts/discount-parts-retail',
  PARTS_OLD_PARTS_RETURN_REPORT: '/report-center/parts/old-parts-return',
  PARTS_STOCK_AGING_REPORT: '/report-center/parts/stock-aging',
  PARTS_STOCK_REPORT: '/report-center/parts/stock',
  PARTS_PURCHASE_ORDER_REPORT: '/report-center/parts/purchase-order',
  PARTS_INVOICE_INSPECTION_REPORT: '/report-center/parts/invoice-inspection',
  PARTS_GOODS_COLLECTION_REPORT: '/report-center/parts/goods-collection',
  PARTS_GOODS_RECEIVE_REPORT: '/report-center/parts/goods-receive',
  PARTS_ORDERS_REPORT: '/report-center/parts/orders',
  PARTS_PURCHASE_REMANS_REPORT: '/report-center/parts/purchase-remans',
  PARTS_LOST_SALES_REPORT: '/report-center/parts/lost-sales',
  PARTS_KPI_REPORT: '/report-center/parts/parts-kpi',
  ADDON_DETAIL_REPORT: '/report-center/parts/addon-detail',
  ROLE_PARTS_FFR_REPORT: '/report-center/parts/ffr-report',
  TODAY_REVENUE_STATISTICS: '/report-center/front-desk/today-revenue-statistics',
  DAILY_ORDER_DETAIL_STATISTICS: '/report-center/front-desk/daily-order-detail-statistics',
  ORDER_DETAIL_REPORT: '/report-center/front-desk/order-detail-report',
  TECHNICIAN_EFFICIENCY_ANALYSIS: '/report-center/workshop/technician-efficiency-analysis',
  LABOR_ANALYSIS: '/report-center/workshop/labor-analysis',
  WORKSHOP_EFFICIENCY_ANALYSIS: '/report-center/workshop/workshop-efficiency-analysis',
  PRE_INSPECTION_CHECK_RATE_REPORT: '/report-center/front-desk/pre-inspection-check-rate-report',
  REPAIR_PACKAGE_USAGE_RATE_REPORT: '/report-center/front-desk/repair-package-usage-rate-report',
  SERVICE_RECALL_SUMMARY_COMPLETION_RATE: '/report-center/front-desk/service-recall-summary-completion-rate',
  SERVICE_MEASURE_RECALL_COMPLETION_DETAIL_REPORT: '/report-center/front-desk/service-measure-recall-completion-detail-report',
  RESERVATION_RATE_REPORT: '/report-center/front-desk/reservation-rate-report',
  ACCIDENT_CAR_LEAD_LIST_REPORT: '/report-center/accident-car-lead/lead-list-report',
  ACCIDENT_CAR_LEAD_KPI_OPERATION: '/report-center/accident-car-lead/lead-kpi-operation',
  ACCIDENT_CAR_INSURANCE_LEADS_ANALYZE: '/report-center/accident-car-lead/insurance-leads-analyze',
  ACCIDENT_CAR_INSURANCE_CATEGORY_ANALYZE: '/report-center/accident-car-lead/insurance-category-analyze',
  ACCIDENT_CAR_RETENTION_SPECIALIST_OWNER_MANAGEMENT: '/report-center/accident-car-lead/retention-specialist-owner-management',
  ACCIDENT_CAR_LEAD_SOURCES_ANALYZE: '/report-center/accident-car-lead/lead-source-analyze',
  ACCIDENT_CAR_MB_PRODUCT_CAR_INSURANCE_LEAD_KPI_REPORT: '/report-center/accident-car-lead/mb-product-car-insurance-lead-kpi-report',
  ACCIDENT_CAR_INSURANCE_GROSS_AMOUNT_ANALYZE_REPORT: '/report-center/accident-car-lead/accident-car-insurance-gross-amount-analyze-report',
  ACCIDENT_CAR_LEAD_ANALYSIS_DASHBOARD: '/report-center/accident-car-lead/accident-car-lead-analysis-dashboard',
  ACCIDENT_CAR_SMS_LIST_REPORT: '/report-center/accident-car-lead/accident-car-sms-list-report',
  PARTS_ADD_ON_SUMMARY_REPORT: '/report-center/parts/add-on-summary-report',
  DEALER_ALLOWANCE_REPORT: '/report-center/front-desk/dealer-allowance-report',
  ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT: '/report-center/parts/lost-sales-win-back',
  ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT: '/report-center/parts/lost-sales-detail',
  PAPERLESS_USAGE_REPORT: '/report-center/paperless/usage-report',
  INSURANCE_FOLLOW_UP_REPORT: '/insurance-report/follow-up-report',
  INSURANCE_ANNUAL_STATISTICS_REPORT: '/insurance-report/annual-statistic-report',
  INSURANCE_FOLLOWING_YEAR_RENEWAL_REPORT: '/insurance-report/following-year-renewal-report',
  PARTS_MAINTENANCE_RATE_REPORT: '/report-center/parts/parts_maintenance_rate_report',
  PARTS_MAINTENANCE_RATE_REPORT_FOR_FRONT_DESK: '/report-center/front-desk/maintenance_rate_report',
};

const REPORT_PRIVILEGE = {
  ROLE_REPORT_CENTER_FINANCIAL_MANAGEMENT: 'ROLE_REPORT_CENTER_FINANCIAL_MANAGEMENT',
  ROLE_REPORT_CENTER_CUSTOMER_MANAGEMENT: 'ROLE_REPORT_CENTER_CUSTOMER_MANAGEMENT',
  ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT: 'ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT',
  ROLE_REPORT_CENTER_WAIT_FOR_PAYMENT_ORDER: 'ROLE_REPORT_CENTER_WAIT_FOR_PAYMENT_ORDER',
  ROLE_REPORT_CENTER_LOST_SALE_REPORT: 'ROLE_REPORT_CENTER_LOST_SALE_REPORT',
  ROLE_REPORT_CENTER_INCOMPLETE_PAYMENT_WARRANTY_ORDER: 'ROLE_REPORT_CENTER_INCOMPLETE_PAYMENT_WARRANTY_ORDER',
  ROLE_REPORT_CENTER_OTHER_MANAGEMENT: 'ROLE_REPORT_CENTER_OTHER_MANAGEMENT',
  ROLE_REPORT_CENTER_WARRANTY_MANAGEMENT_REPORT: 'ROLE_REPORT_CENTER_WARRANTY_MANAGEMENT_REPORT',
  ROLE_REPORT_CENTER_WORKSHOP_MANAGEMENT_REPORT: 'ROLE_REPORT_CENTER_WORKSHOP_MANAGEMENT_REPORT',
  ROLE_REPORT_CENTER_PARTS_RESERVATION_REPORT: 'ROLE_REPORT_CENTER_PARTS_RESERVATION_REPORT',
  ROLE_PARTS_REPORT_MOVEMENT_DETAIL: 'ROLE_PARTS_REPORT_MOVEMENT_DETAIL',
  ROLE_PARTS_REPORT_MOVEMENT_OVERVIEW: 'ROLE_PARTS_REPORT_MOVEMENT_OVERVIEW',
  ROLE_PARTS_REPORT_OBSOLETE_PARTS: 'ROLE_PARTS_REPORT_OBSOLETE_PARTS',
  ROLE_PARTS_DETAIL_REPORT_SALES_PARTS: 'ROLE_PARTS_DETAIL_REPORT_SALES_PARTS',
  ROLE_PARTS_OUT_STOCK_DETAIL_REPORT: 'ROLE_PARTS_OUT_STOCK_DETAIL_REPORT',
  ROLE_PARTS_SETTLE_ACCOUNTS_DETAIL_REPORT: 'ROLE_PARTS_SETTLE_ACCOUNTS_DETAIL_REPORT',
  ROLE_PARTS_UN_SETTLE_ACCOUNTS_DETAIL_REPORT: 'ROLE_PARTS_UN_SETTLE_ACCOUNTS_DETAIL_REPORT',
  ROLE_PARTS_DAILY_OPERATION_REPORT: 'ROLE_PARTS_DAILY_OPERATION_REPORT',
  ROLE_OLD_PARTS_INVENTORY_REPORT: 'ROLE_OLD_PARTS_INVENTORY_REPORT',
  ROLE_SALES_ORDER_DETAILS_OVERVIEW_REPORT: 'ROLE_SALES_ORDER_DETAILS_OVERVIEW_REPORT',
  ROLE_SALES_ANALYSIS: 'ROLE_SALES_ANALYSIS',
  ROLE_SALES_ANALYSIS_REPORT: 'ROLE_SALES_ANALYSIS_REPORT',
  ROLE_SALES_SUMMARY_REPORT: 'ROLE_SALES_SUMMARY_REPORT',
  ROLE_BILL_SUMMARY_REPORT: 'ROLE_BILL_SUMMARY_REPORT',
  ROLE_DIMS_BUYBACK_ORDER_DELETE_REPORT: 'ROLE_DIMS_BUYBACK_ORDER_DELETE_REPORT',
  ROLE_DIMS_BUYBACK_REPORT: 'ROLE_DIMS_BUYBACK_REPORT',
  ROLE_DISCOUNT_PARTS_RETAIL_REPORT: 'ROLE_DISCOUNT_PARTS_RETAIL_REPORT',
  ROLE_OLD_PARTS_RETURN_MONTHLYKNOTS_REPORT: 'ROLE_OLD_PARTS_RETURN_MONTHLYKNOTS_REPORT',
  ROLE_PARTS_REPORT_STOCK_AGING: 'ROLE_PARTS_REPORT_STOCK_AGING',
  ROLE_PARTS_REPORT_STOCK: 'ROLE_PARTS_REPORT_STOCK',
  ROLE_PARTS_REPORT_PURCHASE_ORDER: 'ROLE_PARTS_REPORT_PURCHASE_ORDER',
  ROLE_PARTS_REPORT_INVOICE_INSPECTION: 'ROLE_PARTS_REPORT_INVOICE_INSPECTION',
  ROLE_PARTS_REPORT_GOODS_COLLECTION: 'ROLE_PARTS_REPORT_GOODS_COLLECTION',
  ROLE_PARTS_REPORT_GOODS_RECEIVE: 'ROLE_PARTS_REPORT_GOODS_RECEIVE',
  ROLE_PARTS_REPORT_ORDERS: 'ROLE_PARTS_REPORT_ORDERS',
  ROLE_PARTS_REPORT_PURCHASE_REMANS: 'ROLE_PARTS_REPORT_PURCHASE_REMANS',
  ROLE_PARTS_REPORT_LOST_SALES: 'ROLE_PARTS_REPORT_LOST_SALES',
  ROLE_REPORT_CENTER_CHECKED_IN_VEHICLE_STATISTICS: 'ROLE_REPORT_CENTER_CHECKED_IN_VEHICLE_STATISTICS',
  ROLE_PARTS_KPI_REPORT: 'ROLE_PARTS_KPI_REPORT',
  ROLE_PARTS_FFR_REPORT: 'ROLE_PARTS_FFR_REPORT',
  ROLE_REPORT_CENTER_ORDER_COMPREHENSIVE_QUERY: 'ROLE_REPORT_CENTER_ORDER_COMPREHENSIVE_QUERY',
  ROLE_REPORT_CENTER_COMPLETION_STATISTICS: 'ROLE_REPORT_CENTER_COMPLETION_STATISTICS',
  ROLE_REPORT_CENTER_PRE_INSPECTION_CHECK_RATE: 'ROLE_REPORT_CENTER_PRE_INSPECTION_CHECK_RATE',
  ROLE_REPORT_CENTER_REPAIR_PACKAGE_USAGE_RATE: 'ROLE_REPORT_CENTER_REPAIR_PACKAGE_USAGE_RATE',
  ROLE_REPORT_CENTER_RESERVATION_REPORT: 'ROLE_REPORT_CENTER_RESERVATION_REPORT',
  ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT: 'ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT',
  ROLE_PARTS_ADDON_DETAIL_REPORT: 'ROLE_PARTS_ADDON_DETAIL_REPORT',
  ROLE_PARTS_ADDON_SUMMARY_REPORT: 'ROLE_PARTS_ADDON_SUMMARY_REPORT',
  DEALER_ALLOWANCE_REPORT: 'DEALER_ALLOWANCE_REPORT',
  ROLE_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT: 'ROLE_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT',
  ROLE_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT: 'ROLE_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT',
  ROLE_AS_VIEW_ACCIDENT_CAR_RETENTION_SPECIALIST_OWNER_MANAGEMENT_REPORT:
    'ROLE_AS_VIEW_ACCIDENT_CAR_RETENTION_SPECIALIST_OWNER_MANAGEMENT_REPORT',
  ROLE_AS_ARS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT: 'ROLE_AS_ARS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT',
  ROLE_AS_OEM_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT: 'ROLE_AS_OEM_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT',
  ROLE_DEALER_E_SIGNATURE_USAGE_RATE: 'ROLE_DEALER_E_SIGNATURE_USAGE_RATE',
  ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT: 'ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT',
  ROLE_VIEW_FOLLOW_UP_REPORT: 'ROLE_VIEW_FOLLOW_UP_REPORT',
  ACCESSORY_DISCOUNT_CHART_RESULT: 'ACCESSORY_DISCOUNT_CHART_RESULT',
  ROLE_OEM_THIRD_PARTY_ACCESSORY_DISCOUNT_CHART_RESULT: 'ROLE_OEM_THIRD_PARTY_ACCESSORY_DISCOUNT_CHART_RESULT',
  ROLE_DEALER_NEXT_YEAR_RENEWAL_ACCESS_CHART_RESULT: 'ROLE_DEALER_NEXT_YEAR_RENEWAL_ACCESS_CHART_RESULT',
  ROLE_OEM_THIRD_PARTY_NEXT_YEAR_RENEWAL_ACCESS_CHART_RESULT: 'ROLE_OEM_THIRD_PARTY_NEXT_YEAR_RENEWAL_ACCESS_CHART_RESULT',
  ROLE_VIEW_MB_SALES_REGION_E_REPORT: 'ROLE_VIEW_MB_SALES_REGION_E_REPORT',
  ROLE_VIEW_MB_SALES_REGION_S_REPORT: 'ROLE_VIEW_MB_SALES_REGION_S_REPORT',
  ROLE_VIEW_MB_SALES_REGION_W_REPORT: 'ROLE_VIEW_MB_SALES_REGION_W_REPORT',
  ROLE_VIEW_MB_SALES_REGION_N_REPORT: 'ROLE_VIEW_MB_SALES_REGION_N_REPORT',
  ROLE_PARTS_MAINTENANCE_RATE_REPORT: 'ROLE_PARTS_MAINTENANCE_RATE_REPORT',
  ROLE_FRONT_DESK_MAINTENANCE_RATE_REPORT: 'ROLE_FRONT_DESK_MAINTENANCE_RATE_REPORT',
  ROLE_DEALER_GROUP_VIEW_RECALL_REPORT: 'ROLE_DEALER_GROUP_VIEW_RECALL_REPORT',
  ROLE_DEALER_VIEW_RECALL_REPORT: 'ROLE_DEALER_VIEW_RECALL_REPORT',
  ROLE_RESERVATION_RATE_REPORT: 'ROLE_RESERVATION_RATE_REPORT',
};

const OPEN_TYPE = {
  BLANK: '_blank',
};

export const REPORT_LIST = {
  FINANCE_MANAGEMENT_REPORTING_OVERVIEW: {
    title: '财务管理报表总览',
    list: [
      {
        name: '当月营业统计',
        link: REPORT_CENTER_SUB_TAB_ROUTER.MONTHLY_REVENUE_STATISTICS,
        config: MONTHLY_REVENUE_STATISTICS_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FINANCIAL_MANAGEMENT,
        exceptRole: ['DG_AFTERSALES', 'DG_FINANCE'],
      },
      {
        name: '维修销售业绩综合查询（实时）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.REPAIR_SALES_RECORD_GENERAL_QUERY,
        config: REPAIR_SALES_RECORD_GENERAL_QUERY_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FINANCIAL_MANAGEMENT,
      },
    ],
  },
  CUSTOMER_MANAGEMENT_REPORTING_OVERVIEW: {
    title: '客户管理报表总览',
    list: [
      {
        name: '未进厂车辆统计',
        link: REPORT_CENTER_SUB_TAB_ROUTER.NOT_CHECKED_IN_VEHICLE_STATISTICS,
        config: NOT_CHECKEDIN_VEHICLE_STATISTICS_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_CUSTOMER_MANAGEMENT,
      },
      // {
      //   name: 'SFU客户服务跟踪报表',
      //   link: REPORT_CENTER_SUB_TAB_ROUTER.SERVICE_FOLLOW_UP_REPORT,
      //   config: NOT_CHECKEDIN_VEHICLE_STATISTICS_CONFIG,
      //   privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_CUSTOMER_MANAGEMENT,
      // },
      // {
      //   name: '定期保养提醒',
      //   link: REPORT_CENTER_SUB_TAB_ROUTER.REGULAR_MAINTENANCE_REMINDER,
      //   config: NOT_CHECKEDIN_VEHICLE_STATISTICS_CONFIG,
      //   privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_CUSTOMER_MANAGEMENT,
      // },
    ],
  },
  ACCIDENT_CAR_REPORTING_OVERVIEW: {
    title: '事故车线索报表总览',
    list: [
      {
        name: '线索分析仪表盘',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ACCIDENT_CAR_LEAD_ANALYSIS_DASHBOARD,
        privilege: REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
      },
      {
        name: '线索列表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ACCIDENT_CAR_LEAD_LIST_REPORT,
        config: ACCIDENT_CAR_LEAD_LIST_REPORT_CONFIG,
        privilege: [
          REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_ARS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_OEM_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT,
        ],
      },
      {
        name: '线索运营KPI报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ACCIDENT_CAR_LEAD_KPI_OPERATION,
        config: ACCIDENT_CAR_LEAD_KPI_OPERATION_CONFIG,
        privilege: [
          REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_OEM_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT,
          REPORT_PRIVILEGE.ROLE_VIEW_MB_SALES_REGION_E_REPORT,
          REPORT_PRIVILEGE.ROLE_VIEW_MB_SALES_REGION_S_REPORT,
          REPORT_PRIVILEGE.ROLE_VIEW_MB_SALES_REGION_W_REPORT,
          REPORT_PRIVILEGE.ROLE_VIEW_MB_SALES_REGION_N_REPORT,
        ],
      },
      {
        name: '保险公司车险线索分析报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ACCIDENT_CAR_INSURANCE_LEADS_ANALYZE,
        config: ACCIDENT_CAR_INSURANCE_LEADS_ANALYZE_CONFIG,
        privilege: [
          REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT,
        ],
      },
      {
        name: '线索渠道来源分析报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ACCIDENT_CAR_LEAD_SOURCES_ANALYZE,
        config: ACCIDENT_CAR_LEAD_SOURCES_ANALYSIS_CONFIG,
        privilege: [
          REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT,
        ],
      },
      {
        name: '线索保险类别分析报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ACCIDENT_CAR_INSURANCE_CATEGORY_ANALYZE,
        config: ACCIDENT_CAR_INSURANCE_CATEGORY_ANALYZE_CONFIG,
        privilege: [
          REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT,
        ],
      },
      {
        name: '留修专员管理报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ACCIDENT_CAR_RETENTION_SPECIALIST_OWNER_MANAGEMENT,
        config: ACCIDENT_CAR_RETENTION_SPECIALIST_OWNER_MANAGEMENT_CONFIG,
        privilege: [
          REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_RETENTION_SPECIALIST_OWNER_MANAGEMENT_REPORT,
        ],
      },
      {
        name: '保险公司产值分析报表（全渠道）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ACCIDENT_CAR_INSURANCE_GROSS_AMOUNT_ANALYZE_REPORT,
        config: ACCIDENT_CAR_INSURANCE_GROSS_AMOUNT_ANALYZE_REPORT_CONFIG,
        privilege: [
          REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT,
        ],
      },
      {
        name: '钣喷专享礼线索与车险线索KPI报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ACCIDENT_CAR_MB_PRODUCT_CAR_INSURANCE_LEAD_KPI_REPORT,
        config: MB_PRODUCT_CAR_INSURANCE_LEAD_KPI_REPORT_CONFIG,
        privilege: [
          REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT,
        ],
      },
      {
        name: '短信列表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ACCIDENT_CAR_SMS_LIST_REPORT,
        config: ACCIDENT_CAR_SMS_LIST_REPORT_CONFIG,
        privilege: [
          REPORT_PRIVILEGE.ROLE_AS_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
          REPORT_PRIVILEGE.ROLE_AS_OEM_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT,
        ],
      },
    ],
  },
  FRONT_DESK_MANAGEMENT_REPORTING_OVERVIEW: {
    title: '前台报表总览',
    list: [
      {
        name: '订单行项目报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ORDER_DETAIL_REPORT,
        config: ORDER_DETAIL_REPORT_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT,
      },
      {
        name: '月度工单状态总览',
        link: REPORT_CENTER_SUB_TAB_ROUTER.MONTHLY_ORDER_OVERVIEW,
        config: MONTHLY_ORDER_OVERVIEW_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT,
      },
      {
        name: '订单综合查询',
        link: REPORT_CENTER_SUB_TAB_ROUTER.SERVICE_ORDER_GENERAL_QUERY,
        config: SERVICE_ORDER_GENERAL_QUERY_CONFIG,
        privilege: [
          REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT,
          REPORT_PRIVILEGE.ROLE_REPORT_CENTER_ORDER_COMPREHENSIVE_QUERY,
        ],
      },
      {
        name: '进厂车辆统计',
        link: REPORT_CENTER_SUB_TAB_ROUTER.CHECKED_IN_VEHICLE_STATISTICS,
        config: CHECKEDIN_VEHICLE_STATISTICS_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_CHECKED_IN_VEHICLE_STATISTICS,
      },
      {
        name: '完工统计',
        link: REPORT_CENTER_SUB_TAB_ROUTER.CLOCK_OFF_STATISTICS,
        config: CLOCKOFF_STATISTICS_CONFIG,
        privilege: [REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT, REPORT_PRIVILEGE.ROLE_REPORT_CENTER_COMPLETION_STATISTICS],
      },
      {
        name: '未结清的订单（实时）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.WAIT_FOR_PAYMENT_ORDER,
        config: WAIT_FOR_PAYMENT_ORDER_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_WAIT_FOR_PAYMENT_ORDER,
      },
      {
        name: '维修失销报告',
        link: REPORT_CENTER_SUB_TAB_ROUTER.LOST_SALE_REPORT,
        config: LOST_SALE_REPORT_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_LOST_SALE_REPORT,
      },
      // {
      //   name: '未结账保修工单',
      //   link: REPORT_CENTER_SUB_TAB_ROUTER.INCOMPLETE_PAYMENT_WARRANTY_ORDER,
      //   privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_INCOMPLETE_PAYMENT_WARRANTY_ORDER,
      // },
      // {
      //   name: 'PWEI CHECKING',
      //   link: REPORT_CENTER_SUB_TAB_ROUTER.PWEI_CHECKING,
      //   privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT,
      // },
      {
        name: '服务措施完成率T1报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.SERVICE_MEASURE_T1_COMPLETION_RATE_REPORT,
        config: SERVICE_MEASURE_T1_COMPLETION_RATE_REPORT_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT,
      },
      {
        name: '服务措施完成情况查询',
        link: REPORT_CENTER_SUB_TAB_ROUTER.SERVICE_MEASURE_COMPLETION_STATUS_QUERY,
        config: SERVICE_MEASURE_COMPLETION_STATUS_QUERY_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT,
      },
      {
        name: '当日营业统计报表（实时）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.TODAY_REVENUE_STATISTICS,
        config: TODAY_REVENUE_STATISTICS_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT,
      },
      {
        name: '当日订单明细统计报表（实时）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.DAILY_ORDER_DETAIL_STATISTICS,
        config: DAILY_ORDER_DETAIL_STATISTICS_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_FRONT_DESK_MANAGEMENT,
      },
      {
        name: '客户到店检查率报告',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PRE_INSPECTION_CHECK_RATE_REPORT,
        config: PRE_INSPECTION_CHECK_RATE_REPORT_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_PRE_INSPECTION_CHECK_RATE,
      },
      {
        name: '机修保持率报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_MAINTENANCE_RATE_REPORT_FOR_FRONT_DESK,
        privilege: REPORT_PRIVILEGE.ROLE_FRONT_DESK_MAINTENANCE_RATE_REPORT,
        role: ''
      },
      {
        name: '经销商补贴查询报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.DEALER_ALLOWANCE_REPORT,
        privilege: REPORT_PRIVILEGE.DEALER_ALLOWANCE_REPORT,
      },
      {
        name: '保修快选使用率报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.REPAIR_PACKAGE_USAGE_RATE_REPORT,
        config: REPAIR_PACKAGE_USAGE_RATE_REPORT_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_REPAIR_PACKAGE_USAGE_RATE,
      },
      {
        name: '召回和服务措施月度目标车数完成率（TAR_CR）汇总报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.SERVICE_RECALL_SUMMARY_COMPLETION_RATE,
        privilege: [REPORT_PRIVILEGE.ROLE_DEALER_VIEW_RECALL_REPORT, REPORT_PRIVILEGE.ROLE_DEALER_GROUP_VIEW_RECALL_REPORT]
      },
      {
        name: '召回和服务措施月度目标车数完成率（TAR_CR）明细报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.SERVICE_MEASURE_RECALL_COMPLETION_DETAIL_REPORT,
        config: SERVICE_MEASURE_RECALL_COMPLETION_DETAIL_REPORT,
        privilege: [REPORT_PRIVILEGE.ROLE_DEALER_VIEW_RECALL_REPORT, REPORT_PRIVILEGE.ROLE_DEALER_GROUP_VIEW_RECALL_REPORT]
      },
      {
        name: '预约率报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.RESERVATION_RATE_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_RESERVATION_RATE_REPORT
      },
    ],
  },
  WORKSHOP_MANAGEMENT_REPORTING_OVERVIEW: {
    title: '车间报表总览',
    list: [
      // {
      //   name: '车间效率分析',
      //   link: REPORT_CENTER_SUB_TAB_ROUTER.WORKSHOP_EFFICIENCY_ANALYSIS,
      //   privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_WORKSHOP_MANAGEMENT_REPORT,
      // },
      {
        name: '技师效率分析',
        link: REPORT_CENTER_SUB_TAB_ROUTER.TECHNICIAN_EFFICIENCY_ANALYSIS,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_WORKSHOP_MANAGEMENT_REPORT,
      },
      {
        name: '订单工时分析',
        link: REPORT_CENTER_SUB_TAB_ROUTER.LABOR_ANALYSIS,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_WORKSHOP_MANAGEMENT_REPORT,
      },
      {
        name: '流程效率分析',
        link: ANALYSIS_REPORT_CENTER_SUB_TAB_ROUTER.EFFICIENCY_ANALYSIS_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_WORKSHOP_MANAGEMENT_REPORT,
        openType: OPEN_TYPE.BLANK,
      },
      // {
      //   name: '进出厂产值分析',
      //   link: ANALYSIS_REPORT_CENTER_SUB_TAB_ROUTER.THROUGHPUT_ANALYSIS_REPORT,
      //   privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_WORKSHOP_MANAGEMENT_REPORT,
      //   openType: OPEN_TYPE.BLANK,
      // },
    ],
  },
  WARRANTY_MANAGEMENT_REPORTING_OVERVIEW: {
    title: '保修报表总览',
    list: [
      {
        name: '保修订单状态查询',
        link: REPORT_CENTER_SUB_TAB_ROUTER.WARRANTY_ORDER_STATUS_QUERY,
        config: WARRANTY_ORDER_STATUS_QUERY_CONFIG,
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_WARRANTY_MANAGEMENT_REPORT,
      },
    ],
  },
  PARTS_MANAGEMENT_REPORTING_OVERVIEW: {
    title: '配件报表总览',
    list: [
      {
        sequence: 1,
        name: '库存报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_STOCK_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_STOCK,
      },
      {
        sequence: 2,
        name: '零件流动报表（明细）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_MOVEMENT_DETAIL_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_MOVEMENT_DETAIL,
      },
      {
        sequence: 3,
        name: '零件流动报表（概要）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_MOVEMENT_OVERVIEW_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_MOVEMENT_OVERVIEW,
      },
      {
        sequence: 4,
        name: '库存帐龄报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_STOCK_AGING_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_STOCK_AGING,
      },
      {
        sequence: 5,
        name: '滞销报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_OBSOLETE_PARTS_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_OBSOLETE_PARTS,
      },
      {
        sequence: 6,
        name: '采购订单总览',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_PURCHASE_ORDER_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_PURCHASE_ORDER,
      },
      {
        sequence: 7,
        name: '订货单',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_ORDERS_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_ORDERS,
      },
      {
        sequence: 8,
        name: '到货分配清单',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_GOODS_COLLECTION_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_GOODS_COLLECTION,
      },
      {
        sequence: 9,
        name: '发票检验报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_INVOICE_INSPECTION_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_INVOICE_INSPECTION,
      },
      {
        sequence: 10,
        name: '收货报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_GOODS_RECEIVE_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_GOODS_RECEIVE,
      },
      {
        sequence: 11,
        name: '销售订单细节总览',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_SALES_ORDER_DETAILS_OVERVIEW,
        privilege: REPORT_PRIVILEGE.ROLE_SALES_ORDER_DETAILS_OVERVIEW_REPORT,
        config: getReportFilterConfig(PARTS_SALES_ORDER_REPORT_CONFIG, ORDER_SOURCE_OPTIONS_FOR_VAN, 'orderSource'),
      },
      {
        sequence: 12,
        name: '零件失销报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_LOST_SALES_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_LOST_SALES,
      },
      {
        sequence: 13,
        name: '零件预留报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_RESERVATION_REPORT,
        config: getReportFilterConfig(
          PARTS_RESERVATION_REPORT_CONFIG,
          PARTS_RESERVATION_SERVICE_RECORD_CATEGORY_FOR_VAN.values,
          'serviceRecordCategory',
        ),
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_PARTS_RESERVATION_REPORT,
      },
      {
        sequence: 14,
        name: '零件已结账详情报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_SETTLE_ACCOUNTS,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_SETTLE_ACCOUNTS_DETAIL_REPORT,
      },
      {
        sequence: 15,
        name: '零件已出库详情报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_OUT_STOCK,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_OUT_STOCK_DETAIL_REPORT,
      },
      {
        sequence: 16,
        name: '零件未结账详情报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_UN_SETTLE_ACCOUNTS,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_UN_SETTLE_ACCOUNTS_DETAIL_REPORT,
      },
      {
        sequence: 17,
        name: '日常运作统计表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_DAILY_OPERATION,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_DAILY_OPERATION_REPORT,
      },
      {
        sequence: 18,
        name: '销售分析报表(by市场代码)',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_SALES_ANALYSIS_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_SALES_ANALYSIS_REPORT,
      },
      {
        sequence: 19,
        name: '销售分析报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_SALES_ANALYSIS,
        privilege: REPORT_PRIVILEGE.ROLE_SALES_ANALYSIS,
      },
      {
        sequence: 20,
        name: '账单摘要报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_BILL_SUMMARY_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_BILL_SUMMARY_REPORT,
      },
      {
        sequence: 21,
        name: '销售摘要报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_SALES_SUMMARY_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_SALES_SUMMARY_REPORT,
      },
      {
        sequence: 22,
        name: '惠选配件零售报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_DISCOUNT_RETAIL_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_DISCOUNT_PARTS_RETAIL_REPORT,
      },
      {
        sequence: 23,
        name: '惠选配件采购报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_PURCHASE_REMANS_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_PURCHASE_REMANS,
      },
      {
        sequence: 24,
        name: '旧件返还月结报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_OLD_PARTS_RETURN_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_OLD_PARTS_RETURN_MONTHLYKNOTS_REPORT,
      },
      {
        sequence: 25,
        name: 'DIMS回购报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_DIMS_BUYBACK_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_DIMS_BUYBACK_REPORT,
      },
      {
        sequence: 26,
        name: 'DIMS回购订单删除报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_DIMS_BUYBACK_ORDER_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_DIMS_BUYBACK_ORDER_DELETE_REPORT,
      },
      {
        sequence: 27,
        name: '服务水平报告',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ROLE_PARTS_FFR_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_FFR_REPORT,
      },
      {
        sequence: 28,
        name: '配件KPI报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_KPI_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_KPI_REPORT,
      },
      {
        sequence: 29,
        name: '增项明细报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ADDON_DETAIL_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_ADDON_DETAIL_REPORT,
      },
      {
        sequence: 30,
        name: '增项概览报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_ADD_ON_SUMMARY_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_ADDON_SUMMARY_REPORT,
      },
      {
        sequence: 31,
        name: '失销明细报表（预检、增项）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT,
      },
      {
        sequence: 32,
        name: '失销赢回报表（预检、增项）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT,
      },
      {
        sequence: 33,
        name: '机修保持率报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_MAINTENANCE_RATE_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_MAINTENANCE_RATE_REPORT,
      },
    ],
  },
  PARTS_MANAGEMENT_REPORTING_OVERVIEW_VAN: {
    title: '商务车配件报表总览',
    list: [
      {
        sequence: 1,
        name: '库存报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_STOCK_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_STOCK,
      },
      {
        sequence: 2,
        name: '零件流动报表（明细）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_MOVEMENT_DETAIL_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_MOVEMENT_DETAIL,
      },
      {
        sequence: 3,
        name: '零件流动报表（概要）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_MOVEMENT_OVERVIEW_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_MOVEMENT_OVERVIEW,
      },
      {
        sequence: 4,
        name: '库存帐龄报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_STOCK_AGING_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_STOCK_AGING,
      },
      {
        sequence: 5,
        name: '滞销报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_OBSOLETE_PARTS_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_OBSOLETE_PARTS,
      },
      {
        sequence: 6,
        name: '采购订单总览',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_PURCHASE_ORDER_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_PURCHASE_ORDER,
      },
      {
        sequence: 7,
        name: '订货单',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_ORDERS_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_ORDERS,
      },
      {
        sequence: 8,
        name: '到货分配清单',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_GOODS_COLLECTION_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_GOODS_COLLECTION,
      },
      {
        sequence: 9,
        name: '发票检验报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_INVOICE_INSPECTION_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_INVOICE_INSPECTION,
      },
      {
        sequence: 10,
        name: '收货报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_GOODS_RECEIVE_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_GOODS_RECEIVE,
      },
      {
        sequence: 11,
        name: '销售订单细节总览',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_SALES_ORDER_DETAILS_OVERVIEW,
        privilege: REPORT_PRIVILEGE.ROLE_SALES_ORDER_DETAILS_OVERVIEW_REPORT,
        config: getReportFilterConfig(PARTS_SALES_ORDER_REPORT_CONFIG, ORDER_SOURCE_OPTIONS_FOR_VAN, 'orderSource'),
      },
      {
        sequence: 12,
        name: '零件失销报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_LOST_SALES_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_REPORT_LOST_SALES,
      },
      {
        sequence: 13,
        name: '零件预留报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_RESERVATION_REPORT,
        config: getReportFilterConfig(
          PARTS_RESERVATION_REPORT_CONFIG,
          PARTS_RESERVATION_SERVICE_RECORD_CATEGORY_FOR_VAN.values,
          'serviceRecordCategory',
        ),
        privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_PARTS_RESERVATION_REPORT,
      },
      {
        sequence: 14,
        name: '零件已结账详情报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_SETTLE_ACCOUNTS,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_SETTLE_ACCOUNTS_DETAIL_REPORT,
      },
      {
        sequence: 15,
        name: '零件已出库详情报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_OUT_STOCK,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_OUT_STOCK_DETAIL_REPORT,
      },
      {
        sequence: 16,
        name: '零件未结账详情报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_UN_SETTLE_ACCOUNTS,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_UN_SETTLE_ACCOUNTS_DETAIL_REPORT,
      },
      {
        sequence: 17,
        name: '日常运作统计表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_DAILY_OPERATION,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_DAILY_OPERATION_REPORT,
      },
      {
        sequence: 18,
        name: '销售分析报表(by市场代码)',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_SALES_ANALYSIS_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_SALES_ANALYSIS_REPORT,
      },
      {
        sequence: 19,
        name: '销售分析报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_SALES_ANALYSIS,
        privilege: REPORT_PRIVILEGE.ROLE_SALES_ANALYSIS,
      },
      {
        sequence: 20,
        name: '账单摘要报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_BILL_SUMMARY_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_BILL_SUMMARY_REPORT,
      },
      {
        sequence: 21,
        name: '销售摘要报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_SALES_SUMMARY_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_SALES_SUMMARY_REPORT,
      },
      {
        sequence: 22,
        name: '服务水平报告',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ROLE_PARTS_FFR_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_FFR_REPORT,
      },
      {
        sequence: 23,
        name: '配件KPI报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_KPI_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_KPI_REPORT,
      },
      {
        sequence: 24,
        name: '增项明细报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ADDON_DETAIL_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_ADDON_DETAIL_REPORT,
      },
      {
        sequence: 25,
        name: '增项概览报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PARTS_ADD_ON_SUMMARY_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_ADDON_SUMMARY_REPORT,
      },
      {
        sequence: 26,
        name: '失销明细报表（预检、增项）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT,
      },
      {
        sequence: 27,
        name: '失销赢回报表（预检、增项）',
        link: REPORT_CENTER_SUB_TAB_ROUTER.ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT,
      },
    ],
  },
  PAPERLESS_MANAGEMENT_REPORTING_OVERVIEW: {
    title: '无纸化管理',
    list: [
      {
        sequence: 1,
        name: '电子签使用率月报表统计',
        link: REPORT_CENTER_SUB_TAB_ROUTER.PAPERLESS_USAGE_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_DEALER_E_SIGNATURE_USAGE_RATE,
      },
    ],
  },
  INSURANCE_MANAGEMENT_REPORTING_OVERVIEW: {
    title: '报表总览',
    list: [
      {
        sequence: 1,
        name: '保险专员跟进表现报表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.INSURANCE_FOLLOW_UP_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_VIEW_FOLLOW_UP_REPORT,
        dealerToggleKey: FeatureToggle.INSURANCE_SALES
      },
      {
        sequence: 4,
        name: '事故车配件折扣申请信息-年度统计图表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.INSURANCE_ANNUAL_STATISTICS_REPORT,
        privilege: [REPORT_PRIVILEGE.ROLE_OEM_THIRD_PARTY_ACCESSORY_DISCOUNT_CHART_RESULT],
        toggleKey: FeatureToggle.APPEAL_RESULT_AND_REPORT_THIRD_PARTY
      },
      {
        sequence: 4,
        name: '事故车配件折扣申请信息-年度统计图表',
        link: REPORT_CENTER_SUB_TAB_ROUTER.INSURANCE_ANNUAL_STATISTICS_REPORT,
        privilege: [REPORT_PRIVILEGE.ACCESSORY_DISCOUNT_CHART_RESULT],
        dealerToggleKey: FeatureToggle.INSURANCE_ACCIDENT_APPEAL_RESULT_AND_REPORT
      },
      {
        sequence: 3,
        name: '次年续保支持达成表现报告',
        link: REPORT_CENTER_SUB_TAB_ROUTER.INSURANCE_FOLLOWING_YEAR_RENEWAL_REPORT,
        privilege: [REPORT_PRIVILEGE.ROLE_OEM_THIRD_PARTY_NEXT_YEAR_RENEWAL_ACCESS_CHART_RESULT],
        toggleKey: FeatureToggle.INSURANCE_ACCIDENT_APPEAL_THIRD_PARTY
      },
      {
        sequence: 3,
        name: '次年续保支持达成表现报告',
        link: REPORT_CENTER_SUB_TAB_ROUTER.INSURANCE_FOLLOWING_YEAR_RENEWAL_REPORT,
        privilege: [REPORT_PRIVILEGE.ROLE_DEALER_NEXT_YEAR_RENEWAL_ACCESS_CHART_RESULT],
        dealerToggleKey: FeatureToggle.INSURANCE_ACCIDENT_APPEAL
      },
    ],
  },
  OTHERS_MANAGEMENT_REPORTING_OVERVIEW: {
    title: '其它报表总览',
    list: [
      // {
      //   name: 'CH5客户付费工时费率评估报表',
      //   link: REPORT_CENTER_SUB_TAB_ROUTER.CH5_CUSTOMER_PAY_LABOR_RATE_EVALUATION_REPORT,
      //   privilege: REPORT_PRIVILEGE.ROLE_REPORT_CENTER_OTHER_MANAGEMENT,
      // },
    ],
  },
};

import { Injectable } from '@angular/core';
import { CustomerManagementApi } from './customer-management.api';
import { Observable } from 'rxjs';
import { AdvancedFilterParams, BatchAssignCdoParams, IQueryParams } from './customer-management.model';
import { PromptBarService, PromptLevel } from '@otr/website-common';
import { RebirthHttpProvider } from 'rebirth-http';

@Injectable()
export class CustomerManagementService {
  constructor(
    private customerManagementApi: CustomerManagementApi,
    private rebirthHttpProvider: RebirthHttpProvider,
    private promptBarService: PromptBarService,
  ) {
    this.rebirthHttpProvider.addInterceptor({
      response: (response: any) => {
        if (response.error) {
          const { error: { error_code } } = response;
          if (error_code === 'DMP_UNAVAILABLE') {
            this.promptBarService.show('DMP数据接口连接失败，后台正在加紧处理，请稍后再试', PromptLevel.Error);
            response.error.message = 'DMP数据接口连接失败，后台正在加紧处理，请稍后再试';
          }
        }
        return response;
      },
    });
  }

  customerTabSelectDic;
  showFlag: boolean = false;
  keyword: string = '';
  selectItem = {};
  currentFilter = [];
  totalCount: number = 0;
  currentFilterType;

  getCurrentFilterType() {
    return this.currentFilterType;
  }

  setCurrentFilterType(param) {
    this.currentFilterType = param;
  }

  getKeyword() {
    return this.keyword;
  }

  setKeyword(params: string) {
    this.keyword = params;
  }

  getTotalCount() {
    return this.totalCount;
  }

  setTotalCount(params) {
    this.totalCount = params;
  }

  getCurrentFilter() {
    return this.currentFilter;
  }

  setCurrentFilter(params) {
    this.currentFilter = params;
  }

  getDataQualityFilter() {
    return this.selectItem;
  }

  setDataQualityFilter(params) {
    this.selectItem = params;
  }

  setShowFlag(params: boolean) {
    this.showFlag = params;
  }

  getShowFlag() {
    return this.showFlag;
  }

  setCustomerTabSelectDic(params) {
    this.customerTabSelectDic = params;
  }

  getCustomerTabSelectDic() {
    return this.customerTabSelectDic;
  }

  getCustomerConfig() {
    return this.customerManagementApi.getCustomerConfig();
  }

  getCustomers(params: IQueryParams) {
    return this.customerManagementApi.getCustomers(params);
  }

  getCustomersInfoCount(params: IQueryParams) {
    return this.customerManagementApi.getCustomersInfoCount(params);
  }

  getCustomersByAppliedCriteria(advancedFilterParams: AdvancedFilterParams) {
    return this.customerManagementApi.getCustomersByAppliedCriteria(advancedFilterParams);
  }

  getCustomersByAppliedCriteriaAndDateCount(advancedFilterParams: AdvancedFilterParams) {
    return this.customerManagementApi.getCustomersByAppliedCriteriaAndDateCount(advancedFilterParams);
  }

  getCustomerDataQualityCount(advancedFilterParams) {
    return this.customerManagementApi.getCustomerDataQualityCount(advancedFilterParams);
  }

  getCustomersByAppliedCriteriaAndDate(advancedFilterParams: AdvancedFilterParams) {
    return this.customerManagementApi.getCustomersByAppliedCriteriaAndDate(advancedFilterParams);
  }

  getCustomersDataQuality(advancedFilterParams) {
    return this.customerManagementApi.getCustomersDataQuality(advancedFilterParams);
  }

  getAllCdoInDealers(cecId: number) {
    return this.customerManagementApi.getAllCdoInDealers(cecId);
  }

  getDealerAscdos() {
    return this.customerManagementApi.getDealerAscdos();
  }

  getAssignCdoName(customerIds: Array<string>) {
    return this.customerManagementApi.getAssignCdoName(customerIds);
  }

  batchAssignCdo(params: BatchAssignCdoParams) {
    return this.customerManagementApi.batchAssignCdo(params);
  }

  export(params): Observable<any> {
    return this.customerManagementApi.export(params);
  }

  exportByAdvanced(params: any) {
    return this.customerManagementApi.exportByAdvanced(params);
  }

  exportByDataQuality(params: any) {
    return this.customerManagementApi.dataQualityExport(params);
  }

  getExportReportTableData(params: any) {
    return this.customerManagementApi.getExportReportTableData(params);
  }

  getTaskAllPerson() {
    return this.customerManagementApi.getTaskAllPerson();
  }

  createCustomerTask(params) {
    return this.customerManagementApi.createCustomerTask(params);
  }

  assignCustomerTask(params) {
    return this.customerManagementApi.assignCustomerTask(params);
  }

}

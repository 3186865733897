import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-list-select',
  styleUrls: ['./list-select.style.scss'],
  templateUrl: './list-select.template.html',
  animations: [
    trigger('filterAnimation', [
      transition(':enter', [
        style({ width: 0 }),
        animate('0.3s ease-in', style({ width: '600px' })),
      ]),
      transition(':leave', [
        style({ width: '600px' }),
        animate('0.3s ease-out', style({ width: 0 })),
      ]),
    ]),
  ],
})
export class ListSelectComponent {
  @Input() title: string = '';
  @Input() hasTitle: boolean = true;
  @Input() filterOption: Array<any> = [];
  @Input() selectedValue;
  @Input() panelStyle: Object = {};
  @Input() count: Object = {};
  @Input() titleStyle: Object = {};
  @Input() optionStyle: Object = {};
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  onOptionClick(value) {
    if (value !== this.selectedValue) {
      this.onClick.emit(value);
    }
  }
}

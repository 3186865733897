import { Component, EventEmitter, Input, Output} from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-file-form',
  templateUrl: './file-form.component.html',
  styleUrls: ['./file-form.component.scss'],
})
export class FileFormComponent {
  @Input() fileControl: AbstractControl;
  @Input() showDelete: boolean = true;
  @Output() onUpload?: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemove?: EventEmitter<any> = new EventEmitter<any>();
  @Output() onView: EventEmitter<any> = new EventEmitter<any>();

  canUpload(): boolean {
    return !this.fileControl.value;
  }

  onUploadClick($event: MouseEvent): void {
    this.onUpload.emit($event);
  }

  canView(): boolean {
    return !!this.fileControl.value;
  }

  onViewClick($event: MouseEvent): void {
    this.onView.emit($event);
  }

  onRemoveClick($event: MouseEvent): void {
    this.onRemove.emit($event);
  }

  getFileName(): string {
    return this.fileControl.value ? this.fileControl.value : '';
  }
}

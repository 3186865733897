import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';
import { isSupersededReminder } from '../../../../../technical-and-maintenance/utils/dedicatedTechnicalReminderUtils';
import {
  eventOrderMapping
} from '../../../../../technical-and-maintenance/constant/dedicatedTechnicalReminderConstant';

@Component({
  selector: 'app-cell-dropdown',
  template: `
    <img [src]="imgUrl" *ngIf="!showDropdown"/>
    <nz-dropdown [nzTrigger]="'click'" *ngIf="showDropdown">
      <a nz-dropdown><img [src]="imgUrl"/> </a>
      <ul nz-menu nzSelectable>
        <li nz-menu-item *ngFor="let item of selectionData; let i = index;" [nzDisabled]="disabledData[i]" (click)="onClick(disabledData[i], item)">{{item}}</li>
      </ul>
    </nz-dropdown>
  `,
})

export class CellDropdownComponent implements ICellRendererAngularComp {
  selectionData: any;
  params: ICellRendererParams;
  imgUrl: string;
  showDropdown: boolean = false;
  inactiveUrl: string = '../../../../../assets/bmbs-technical-service/more-inactive.svg';
  activeUrl: string = '../../../../../assets/bmbs-technical-service/more-active.svg';
  status: string;
  disabledData: boolean[];

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.status = this.params.data.status;
    this.imgUrl = isSupersededReminder(this.status) ? this.inactiveUrl : this.activeUrl;
    this.selectionData = _.get(params, 'selectionData', []);
    this.disabledData = this.getSelectionData();
    this.showDropdown = this.getShowDropdown(this.status);
  }

  refresh(params: any): boolean {
    return false;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  getSelectionData(): any {
    return _.get(_.find(eventOrderMapping, {status: this.status}), 'next');
  }

  getShowDropdown(status: string): boolean {
    return !isSupersededReminder(status);
  }

  onClick(disabled: boolean, selected: string): void {
    if (disabled) {
      return;
    }
    this.cellRendererParams.onClick(this.params, selected);
  }
}

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CtiCallService } from '../../../_common/services/cti-call.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { EVENT_STATUS_OPTIONS, TELEPHONIST_STATUS_OPTIONS } from '../../../_common/constants/cti';

@Component({
  selector: 'app-telephonist-status',
  templateUrl: './telephonist-status.component.html',
  styleUrls: ['./telephonist-status.component.scss'],
  providers: [],
})
export class TelephonistStatusComponent implements OnInit, OnDestroy {
  @Input() type;
  @Output() setExitStatus = new EventEmitter();
  @Output() setCallStatus = new EventEmitter();
  transferOverTimer;

  agentconsultListenerId: string = '';
  transferingListenerId: string = '';
  consultationcallsListenerId: string = '';
  transfercallListenerId: string = '';
  agentconsultbackListenerId: string = '';
  afterListenerId: string = '';

  constructor(private ctiCallService: CtiCallService) {}

  ngOnInit(): void {
    this.initStatus();
    // 点击咨询
    this.agentconsultListenerId = CallCenter.addEventListener('agentconsult', json => {
      this.ctiCallService.setTelephonistStatus(TELEPHONIST_STATUS_OPTIONS.CONSULT_CALL_OUT.value);
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.RINGING.value);
      // 咨询失败
      if (json.reason !== 'Success' && this.isDialing) {
        this.ctiCallService.setTelephonistListStatus({
          isDialing: false,
          isFailed: true,
        });
        this.setExitStatus.emit(true);
      } else {
        this.ctiCallService.setTelephonistListStatus({
          isDialing: true,
          isFailed: false,
        });
      }
    });
    // 点击转接
    this.transferingListenerId = CallCenter.addEventListener('transfering', json => {
      this.ctiCallService.setTelephonistStatus(TELEPHONIST_STATUS_OPTIONS.TRANSFER_CALL_OUT.value);
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.RINGING.value);
      this.ctiCallService.setTelephonistListStatus({
        isDialing: true,
        isFailed: false,
      });
      this.ctiCallService.setIsTransferOver(true);
    });
    // 咨询坐席B摘机
    this.consultationcallsListenerId = CallCenter.addEventListener('consultationcalls', json => {
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.CALLING.value);
      this.ctiCallService.setTelephonistListStatus({
        isDialing: false,
        isCalling: true,
        callStartAt: moment(),
      });
      this.setCallStatus.emit('consultincall');
    });
    // 转接话后
    this.transfercallListenerId = CallCenter.addEventListener('transfercall', json => {
      this.ctiCallService.setTelephonistStatus('');
      // 转接失败
      if (json.reason !== 'Success') {
        this.ctiCallService.setTelephonistListStatus({
          isFailed: true,
          isDialing: false,
        });
        this.ctiCallService.setIsTransferOver(false);
        this.setExitStatus.emit(true);
      } else {
        this.initStatus();
      }
    });
    // 咨询话后
    this.agentconsultbackListenerId = CallCenter.addEventListener('agentconsultback', json => {
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.CALLING.value);
      this.ctiCallService.setTelephonistStatus('');
      // 咨询失败
      if (json.reason !== 'Success') {
        this.ctiCallService.setTelephonistListStatus({
          isFailed: true,
          isDialing: false,
        });
        this.setExitStatus.emit(true);
      }
      // 咨询完成
      if (json.reason === 'Success' || this.callStartAt) {
        this.initStatus();
      }
      this.setCallStatus.emit('agentconsultback');
    });
    // 客户话后
    this.afterListenerId = CallCenter.addEventListener('after', json => {
      this.ctiCallService.setTelephonistListStatus({
        isDialing: false,
        transferOverLeft: 3,
      });
      if (this.ctiCallService.getIsTransferOver() && json.hangupdir !== 1) {
        this.ctiCallService.setTelephonistListStatus({
          isCalling: true,
        });
        this.transferOverTimer = setInterval(() => {
          this.ctiCallService.setTelephonistListStatus({
            transferOverLeft: this.transferOverLeft - 1,
          });
          if (this.transferOverLeft === 0) {
            this.initStatus();
            clearInterval(this.transferOverTimer);
          }
        }, 1000);
      } else {
        this.initStatus();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.agentconsultListenerId) {
      CallCenter.removeEventListener('agentconsult', this.agentconsultListenerId);
      this.agentconsultListenerId = '';
    }
    if (this.transferingListenerId) {
      CallCenter.removeEventListener('transfering', this.transferingListenerId);
      this.transferingListenerId = '';
    }
    if (this.consultationcallsListenerId) {
      CallCenter.removeEventListener('consultationcalls', this.consultationcallsListenerId);
      this.consultationcallsListenerId = '';
    }
    if (this.transfercallListenerId) {
      CallCenter.removeEventListener('transfercall', this.transfercallListenerId);
      this.transfercallListenerId = '';
    }
    if (this.agentconsultbackListenerId) {
      CallCenter.removeEventListener('agentconsultback', this.agentconsultbackListenerId);
      this.agentconsultbackListenerId = '';
    }
    if (this.afterListenerId) {
      CallCenter.removeEventListener('after', this.afterListenerId);
      this.afterListenerId = '';
    }
  }

  initStatus() {
    this.ctiCallService.setTelephonistListStatus({
      isDialing: false,
      isFailed: false,
      isCalling: false,
      calledId: '',
      callStartAt: null,
    });
    this.setExitStatus.emit(true);
  }

  handleConsultClicked({ operatorid, agentKey, nowgroupid, name = '' }) {
    if (this.hasActiveStatus) {
      return;
    }
    this.ctiCallService.setTelephonistListStatus({
      isDialing: true,
      calledId: operatorid,
      isFailed: false,
    });
    this.setExitStatus.emit(false);
    CallCenter.agentconsult(agentKey, nowgroupid, {
      userData: {
        origcaller: this.ctiCallService.getCallInPhoneNum() || this.ctiCallService.getCallOutPhoneNum(),
        caller: _.get(this.ctiCallService.getOperatorInfo(), 'operatorId'),
        name: _.get(this.ctiCallService.getOperatorInfo(), 'name'),
      },
      uuiData: '',
    });
  }

  handleCancelTransfer() {
    CallCenter.canceltransfercall();
  }

  handleCancelConsult() {
    CallCenter.agentconsultback();
  }

  handleTransferClicked({ operatorid, agentKey, nowgroupid, name = '' }) {
    if (this.hasActiveStatus) {
      return;
    }
    this.ctiCallService.setTelephonistListStatus({
      isDialing: true,
      calledId: operatorid,
      isFailed: false,
    });
    this.setExitStatus.emit(false);
    CallCenter.transfercall(agentKey, nowgroupid, {
      userData: {
        origcaller: this.ctiCallService.getCallInPhoneNum() || this.ctiCallService.getCallOutPhoneNum(),
        caller: _.get(this.ctiCallService.getOperatorInfo(), 'operatorId'),
        name: _.get(this.ctiCallService.getOperatorInfo(), 'name'),
      },
      uuiData: '',
    });
  }

  get hasActiveStatus() {
    return this.isDialing || this.isCalling;
  }

  get getCallDuration() {
    const currentTime = moment();
    const time = moment.duration(currentTime.diff(this.callStartAt));
    const hour = time.hours() < 10 ? `0${time.hours()}` : time.hours();
    const minute = time.minutes() < 10 ? `0${time.minutes()}` : time.minutes();
    const second = time.seconds() < 10 ? `0${time.seconds()}` : time.seconds();
    return `${hour}:${minute}:${second}`;
  }

  get availableList() {
    return _.filter(this.ctiCallService.getAllTelephonist(), item => item.status === 'free' || item.operatorid === this.calledId);
  }

  get busyList() {
    return _.filter(this.ctiCallService.getAllTelephonist(), item => item.status !== 'free' && item.operatorid !== this.calledId);
  }

  get isDialing() {
    return _.get(this.ctiCallService.getTelephonistListStatus(), 'isDialing');
  }
  get isCalling() {
    return _.get(this.ctiCallService.getTelephonistListStatus(), 'isCalling');
  }
  get isFailed() {
    return _.get(this.ctiCallService.getTelephonistListStatus(), 'isFailed');
  }
  get calledId() {
    return _.get(this.ctiCallService.getTelephonistListStatus(), 'calledId');
  }
  get callStartAt() {
    return _.get(this.ctiCallService.getTelephonistListStatus(), 'callStartAt');
  }
  get transferOverLeft() {
    return _.get(this.ctiCallService.getTelephonistListStatus(), 'transferOverLeft');
  }
}

import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { ARROW_DOWN, ARROW_UP, NUMBER_KEY_CODE_RANGE, TIME_PICKER_SUPPORTED_KEY_CODE } from '../../constants/common';

export interface TimePickerModel {
  hour: number;
  minute: number;
  second?: number;
}

const TIME_UNIT = {
  HOUR: 'hour',
  MINUTE: 'minute',
  SECOND: 'second',
};

const MAX_TIME_RANGE = {
  hour: 23,
  minute: 59,
  second: 59,
};

const supportKeyType = [ARROW_UP, ARROW_DOWN];

@Component({
  selector: 'app-time-picker',
  styleUrls: ['./time-picker.style.scss'],
  templateUrl: './time-picker.template.html',
  exportAs: 'timePicker',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() showSeconds = false;
  @Input() minTime: TimePickerModel;
  @Input() maxTime: TimePickerModel;
  @Input() errorMessages = { min: '不能早于开始时间', max: '' };
  @Input() minInvalidErrorMessage: string = '不能早于开始时间';
  @Input() maxInvalidErrorMessage: string = '';
  @Input() title;
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter();

  hour = '00';
  minute = '00';
  second = '00';

  timeUnit = TIME_UNIT;
  minDate: Date;
  maxDate: Date;

  disabled: boolean;
  private onChange = (e: TimePickerModel) => null;
  private onTouched = () => null;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  isLargerThanMin() {
    return this.getCurrentTimestamp() >= new Date().setHours(this.minTime.hour, this.minTime.minute, 0, 0);
  }

  isLowerThanMax() {
    return this.getCurrentTimestamp() <= new Date().setHours(this.maxTime.hour, this.maxTime.minute, 59, 999);
  }

  get errorMessage() {
    if (!this.isLargerThanMin()) {
      return this.errorMessages.min;
    }
    if (!this.isLowerThanMax()) {
      return this.errorMessages.max;
    }
    return '';
  }

  ngOnInit(): void {
    this.minTime = this.minTime || { hour: 0, minute: 0, second: 0 };
    this.maxTime = this.maxTime || { hour: 23, minute: 59, second: 59 };
    this.initConditionDate();
  }

  initConditionDate() {
    this.minDate = this.getDateByTime(this.minTime);
    this.maxDate = this.getDateByTime(this.maxTime);
  }

  cancel() {
    this.onCancel.emit();
  }

  save() {
    const value: any = { hours: parseInt(this.hour, 10), minutes: parseInt(this.minute, 10) };
    if (this.showSeconds) {
      value.seconds = parseInt(this.second, 10);
    }
    this.onSave.emit(value);
  }

  getDateByTime(time: TimePickerModel) {
    return new Date((new Date()).setHours(time.hour || 0, time.minute || 0, time.second || 0));
  }

  getValue(value, defaultValue) {
    if (value || value === 0) {
      return value;
    }
    return defaultValue;
  }

  getCurrentTimestamp(time?: { hour?: string, minute?: string, second?: string }): number {
    const hour = this.getValue(_.get(time, 'hour'), parseInt(this.hour, 10));
    const minute = this.getValue(_.get(time, 'minute'), parseInt(this.minute, 10));
    const second = this.getValue(_.get(time, 'second'), parseInt(this.second, 10));

    return (new Date()).setHours(hour, minute, second);
  }

  writeValue(value: TimePickerModel) {
    if (value) {
      this.hour = this.fillLeftZero(value.hour || 0);
      this.minute = this.fillLeftZero(value.minute || 0);
      this.second = this.fillLeftZero(value.second || 0);
      this.changeDetectorRef.detectChanges();
    } else {
      const currentTime = new Date();
      this.hour = this.fillLeftZero(currentTime.getHours());
      this.minute = this.fillLeftZero(currentTime.getMinutes());
      this.second = this.fillLeftZero(currentTime.getSeconds());
    }
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(disabled) {
    this.disabled = disabled;
    this.changeDetectorRef.markForCheck();
  }

  updateTimeByMargin(key: string, marginDate) {
    switch (key) {
      case TIME_UNIT.HOUR:
        this.hour = this.fillLeftZero(marginDate[TIME_UNIT.HOUR]);
        this.minute = this.fillLeftZero(marginDate[TIME_UNIT.MINUTE]);
        if (marginDate[TIME_UNIT.SECOND]) {
          this.second = this.fillLeftZero(marginDate[TIME_UNIT.SECOND]);
        }
        break;
      case TIME_UNIT.MINUTE:
        this.minute = this.fillLeftZero(marginDate[TIME_UNIT.MINUTE]);
        if (marginDate[TIME_UNIT.SECOND]) {
          this.second = this.fillLeftZero(marginDate[TIME_UNIT.SECOND]);
        }
        break;
      case TIME_UNIT.SECOND:
        this.second = this.fillLeftZero(marginDate[TIME_UNIT.SECOND]);
        break;
    }
  }

  modifyTimeByKey(value: number, key: string) {
    if (isNaN(value)) {
      if (this.getCurrentTimestamp({ [key]: 0 }) < this.minDate.getTime()) {
        this.updateTimeByMargin(key, this.minTime);
      } else if (this.getCurrentTimestamp({ [key]: 0 }) > this.maxDate.getTime()) {
        this.updateTimeByMargin(key, this.maxTime);
      } else {
        this.updateTimeByMargin(key, this.minTime);
      }
    } else if (value < 0) {
      this[key] = this.fillLeftZero(0) || this.fillLeftZero(this.minTime[key]);
    } else if (value > MAX_TIME_RANGE[key] || this.getCurrentTimestamp({ [key]: value }) > this.maxDate.getTime()) {
      this.updateTimeByMargin(key, this.maxTime);
    } else if (this.getCurrentTimestamp({ [key]: value }) < this.minDate.getTime()) {
      this.updateTimeByMargin(key, this.minTime);
    } else {
      this[key] = this.fillLeftZero(value);
    }
  }

  onTimeChange(key: string) {
    this.onTouched();
    this.modifyTimeByKey(parseInt(this[key], 10), key);
    this.onModelChange();
  }

  private fillLeftZero(value: number) {
    return _.padStart(value.toString(), 2, '0');
  }

  onModelChange() {
    this.onChange(this.getTimePickerModel());
  }

  private getTimePickerModel(): TimePickerModel {
    const model: TimePickerModel = { hour: parseInt(this.hour, 10), minute: parseInt(this.minute, 10) };
    if (this.showSeconds) {
      model.second = parseInt(this.second, 10);
    }
    return model;
  }

  // tslint:disable-next-line
  isSafetyKeyPress(keyType: any, target: string, key: string): boolean {
    const parsedTarget = parseInt(target, 10);
    return !(keyType === ARROW_DOWN && parsedTarget < 1) &&
      (
        (keyType === ARROW_DOWN && key === TIME_UNIT.HOUR && parsedTarget <= MAX_TIME_RANGE.hour) ||
        (keyType === ARROW_DOWN && (key === TIME_UNIT.MINUTE || key === TIME_UNIT.SECOND) && parsedTarget <= MAX_TIME_RANGE.minute) ||
        (keyType === ARROW_UP && key === TIME_UNIT.HOUR && parsedTarget < MAX_TIME_RANGE.hour) ||
        (keyType === ARROW_UP && (key === TIME_UNIT.MINUTE || key === TIME_UNIT.SECOND) && parsedTarget < MAX_TIME_RANGE.minute)
      );
  }

  isSupportedKeyPress(event): boolean {
    return (_.includes(TIME_PICKER_SUPPORTED_KEY_CODE, event.keyCode) &&
      !(event.shiftKey && _.includes(NUMBER_KEY_CODE_RANGE, event.keyCode))) ||
      /\d/.test(event.key);
  }

  handleKeyEvent(event, type) {
    if (!this.isSupportedKeyPress(event)) {
      event.preventDefault();
      return;
    }

    const keyEventType = event.code;
    if (supportKeyType.indexOf(keyEventType) === -1) {
      return;
    }
    this.modifyTimeByKeyPressEvent(type, keyEventType);
  }

  modifyTimeByKeyPress(key: string, type: string, step: number) {
    if (this.isSafetyKeyPress(type, this[key], key)) {
      this[key] = this.fillLeftZero(parseInt(this[key], 10) + step);
    }
  }

  modifyTimeByKeyPressEvent(type: string, keyEventType: string) {
    let step = 1;
    if (keyEventType === ARROW_DOWN) {
      step = -1;
    }
    switch (type) {
      case this.timeUnit.HOUR:
        this.modifyTimeByKeyPress(TIME_UNIT.HOUR, keyEventType, step);
        break;
      case this.timeUnit.MINUTE:
        this.modifyTimeByKeyPress(TIME_UNIT.MINUTE, keyEventType, step);
        break;
      case this.timeUnit.SECOND:
        this.modifyTimeByKeyPress(TIME_UNIT.SECOND, keyEventType, step);
        break;
    }
  }
}

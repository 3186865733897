import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-switch-tab',
  template: `
    <div [hidden]="!active" class="app-switch-tab-pane">
      <ng-content></ng-content>
    </div>
  `,
  styles: []
})
export class SwitchTabComponent {
  @Input() title: string;
  @Input() active = false;
}

import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CurrentUserService, DealerIntegrationToggleService, FeatureToggleService } from '@otr/website-common';

@Injectable()
export class HVBToggleGuard implements CanActivate, CanActivateChild {
  constructor(private featureToggleService: FeatureToggleService,
              private dealerIntegrationToggleService: DealerIntegrationToggleService,
              private currentUserService: CurrentUserService,
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    if (!_.isEmpty(this.currentUserService.getDealerId())) {
      return this.dealerIntegrationToggleService.isIntegrationToggleEnabled('HVB');
    }
    return this.featureToggleService.isFeatureEnable('HVB', false);
  }

  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Promise<any> | Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }
}

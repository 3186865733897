import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import { Component } from '@angular/core';
import { get } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { GroupedRowHelper } from './grouped-row-helper';

@Component({
  templateUrl: './grouped-row.template.html',
  styleUrls: ['./grouped-row.style.scss'],
})
export class GroupedRowComponent implements ICellRendererAngularComp {
  public isParent: boolean;
  public isChild: boolean;
  public isExpanded: (params: ICellRendererParams) => boolean;

  public disabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public checked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public indeterminate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private onExpand: (params: ICellRendererParams) => Observable<void>;
  private onCollapse: (params: ICellRendererParams) => Observable<void>;
  private isFreeze: (data: any) => boolean;
  private isSelected: (params: ICellRendererParams) => boolean;
  private rowSelect: (data: ICellRendererParams, selected?: boolean) => void;
  private helper: GroupedRowHelper;
  private expandClickable: boolean = true;

  public params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.onExpand = get(params.colDef, 'cellRendererParams.onExpand');
    this.onCollapse = get(params.colDef, 'cellRendererParams.onCollapse');
    this.isSelected = get(params.colDef, 'cellRendererParams.isSelected');
    this.rowSelect = get(params.colDef, 'cellRendererParams.rowSelect');
    this.isExpanded = get(params.colDef, 'cellRendererParams.isExpanded');
    this.helper = get(params.colDef, 'cellRendererParams.helper');

    const isParent = get(params.colDef, 'cellRendererParams.isParent');
    this.isParent = isParent(params);

    const isChild = get(params.colDef, 'cellRendererParams.isChild');
    this.isChild = isChild(params);

    this.isFreeze = get(params.colDef, 'cellRendererParams.isFreeze');

    this.updateCheckBoxStatus();
    this.helper.addListener(this.updateCheckBoxStatus);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public handleExpandClick(): void {
    if (!this.expandClickable) {
      return;
    }

    this.expandClickable = false;
    if (this.isExpanded(this.params)) {
      this.onCollapse(this.params).subscribe(() => this.expandClickable = true);
      return;
    }

    this.onExpand(this.params).subscribe(() => {
      this.updateCheckBoxStatus();
      this.expandClickable = true;
    });
  }

  public handleCheckboxClick(event): void {
    const selected = !!event.target.checked;
    this.rowSelect(this.params, selected);
    this.helper.trigger();
  }

  private updateCheckBoxStatus = () => {
    if (this.isFreeze(this.params.data)) {
      this.freeze();
      return;
    }
    const selected = this.isSelected(this.params);
    this.indeterminate$.next(selected === null);
    this.checked$.next(!!selected);
    this.disabled$.next(false);
  };

  private freeze(): void {
    this.disabled$.next(true);
    this.checked$.next(true);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DealerAllowanceApi } from '../../_common/api/dealer-allowance';

@Injectable()
export class DealerAllowanceService {
  constructor(private dealerAllowanceApi: DealerAllowanceApi) {}

  exportDealerAllowanceDetail(beginDate, endDate, rightType): Observable<any> {
    return this.dealerAllowanceApi.exportDealerAllowanceDetails(beginDate, endDate, rightType);
  }
}

import { Injectable } from '@angular/core';
import { TestDriveApi } from '../api/test-drive';

@Injectable()
export class TestDriveService {
  constructor(private testDriveApi: TestDriveApi) {
  }

  getTestDriveByLeadId(leadId: string, startTime: number, endTime?: number) {
    return this.testDriveApi.getTestDrivesByLeadId(leadId, startTime, endTime);
  }

  startTestDrive(showroomVisitId, testDrive) {
    return this.testDriveApi.startTestDrive({ showroomVisitId, testDrive });
  }

  finishTestDrive(showroomVisitId, testDrive) {
    return this.testDriveApi.finishTestDrive({ showroomVisitId, testDrive });
  }

  deleteTestDrive(id) {
    return this.testDriveApi.deleteTestDrive(id);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { BranchLocationDescription } from '../models/branch';

@Pipe({
  name: 'branchLocationDescription',
})
export class BranchLocationDescriptionPipe implements PipeTransform {

  transform(locationDescription: BranchLocationDescription, args?: any): string {
    if (_.isEmpty(locationDescription)) {
      return '';
    }
    if (_.isEmpty(locationDescription.description)) {
      return locationDescription.vehicleLocation || '';
    }
    return `${locationDescription.vehicleLocation} - ${locationDescription.description}`;
  }
}

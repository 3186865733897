<div bsModal #bsModal="bs-modal"
     [config]="modalConfig" (onHide)="handleModalHide()" [class.mask-show]="contentSink"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog" [ngStyle]="customStyle">
    <div class="modal-content">
      <div class="modal-header"
           [class.modal-title-show]="hideTitle">
        <h4 class="modal-title"
            [class.modal-title-big-font]="bigTileFontSize"
            [class.modal-title-show]="hideTitle"
        >
          {{modalTitle}}<span class="modal-mandatory-badge" *ngIf="showMandatoryBadge">*</span>
        </h4>
        <span class="modal-subtitle" *ngIf="modalSubtitle" [innerHTML]="modalSubtitle"></span>
        <button type="button" class="btn-close" aria-label="Close" (click)="cancel()" *ngIf="!hideCloseButton">
        </button>
      </div>
      <div class="modal-body">
        <div class="message-list">
          <div class="message-wrapper"
               [ngClass]="{'error-message': !message.isSuccess, 'success-message': message.isSuccess}"
               *ngFor="let message of messages" (click)="removeModalMessage(message)">
            <img *ngIf="!message.isSuccess" class="icon" src="/assets/common/circle-cross.svg" alt="close">
            <img *ngIf="message.isSuccess" src="/assets/common/success.svg" class="icon" alt="">
            {{getDisplayMessage(message)}}
            <span *ngIf="message.action" class="message-action-btn"
                  (click)="message.action.handler()">{{message?.action.text}}</span>
          </div>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import {
  ACCIDENT_CAR_LEAD_SOURCE_MULTI_INIT,
  ACCIDENT_CAR_LEAD_SOURCE_MULTI_OPTIONS,
  DROPDOWN_DATE_RANGE_OPTION_MONTH,
  DROPDOWN_DATE_RANGE_OPTION_YEAR,
  DROPDOWN_DATE_RANGE_OPTIONS_DAY,
  DROPDOWN_DATE_RANGE_OPTION_QUARTER,
  FILTER_INPUT_TYPES,
  REFRESH_TIME,
  MONTH_SEASON_AND_YEAR_REFRESH_TIME,
  VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import * as moment from 'moment';
import { DATE_FORMAT } from '../../_common/constants/common';
import {
  amountFormatter,
  getAccidentCarSettingByEnvironment,
  percentageFormatter,
} from '../utils/utils';
import {PrivilegesEnum} from '../../_common/services/user-privilege.service';

export const ACCIDENT_CAR_LEAD_SOURCES_ANALYSIS_CONFIG = {
  name: 'accident-car-lead-source-analyze-report',
  enablePagination: true,
  pageSize: 50,
  filterThreeColumn: true,
  filterFourColumn: true,
  exportedFileName: '线索渠道来源分析报表.xlsx',
  timeFormatter: 'YYYYMMDDHHmmss',
  isNameWithDate: true,
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  refreshTime: REFRESH_TIME,
  monthAndQuarterAndYearRefreshTime: MONTH_SEASON_AND_YEAR_REFRESH_TIME,
  defaultSearchWhenEnterReport: true,
  showSummaryLine: true,
  newLineOperation: true,
  isNameWithVehicleBusinessType: true,
  nowrap: true,
  vertical: true,
  scopeOfReport:
    '*统计原则为先到先得（举例：对于同一事故，如保险公司和外拓人员均推送一条线索且外拓人员的线索创建时间先于保险公司，则该线索量及对应产值信息等统计进外拓渠道）',
  column: [
    {
      headerName: '时间',
      field: 'reportDate',
      width: 220,
      pinned: true,
    },
    {
      headerName: '经销商代码',
      field: 'gsCode',
      width: 120,
      showByPrivilege: PrivilegesEnum.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT
    },
    {
      headerName: '经销商名称',
      field: 'dealerName',
      width: 150,
      showByPrivilege: PrivilegesEnum.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT
    },
    {
      headerName: '线索来源',
      field: 'leadSource',
      width: 230,
    },
    {
      headerName: '线索总量（去重后）',
      field: 'totalCount',
      width: 175,
    },
    {
      headerName: '线索总量（去重后）占比',
      field: 'totalCountRate',
      width: 175,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '留修量',
      field: 'totalArrivedCount',
      width: 170,
    },
    {
      headerName: '留修率',
      field: 'totalArrivedCountRate',
      width: 170,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: '产值（含税）',
      field: 'totalGrossAmount',
      width: 175,
      valueFormatter: amountFormatter,
    },
    {
      headerName: '产值占比',
      field: 'totalGrossAmountRate',
      width: 175,
      valueFormatter: percentageFormatter,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DROPDOWN_DATE_RANGE,
      title: '选择时间',
      optionName: 'dimension',
      options: {
        ...DROPDOWN_DATE_RANGE_OPTIONS_DAY,
        ...DROPDOWN_DATE_RANGE_OPTION_MONTH,
        ...DROPDOWN_DATE_RANGE_OPTION_QUARTER,
        ...DROPDOWN_DATE_RANGE_OPTION_YEAR,
      },
      defaultOptionValue: DROPDOWN_DATE_RANGE_OPTIONS_DAY.DAY.value,
      resetWhenChangeOption: true,
      name: 'createdTime',
      isRangeLimited: true,
      allRequired: true,
      limitValueRange: 60,
      startMinLimitValue: getAccidentCarSettingByEnvironment('startMinLimitValue'),
      startLimitValue: moment(),
      endLimitValue: moment(),
      endMinLimitValue: getAccidentCarSettingByEnvironment('endMinLimitValue'),
      endMaxLimitValue: moment(),
      itemWidth: 430,
      defaultBeginDate: moment().format(DATE_FORMAT),
      defaultEndDate: moment().format(DATE_FORMAT),
    },
    {
      type: FILTER_INPUT_TYPES.MULTI_DROPDOWN,
      name: 'leadSource',
      label: '线索来源',
      options: ACCIDENT_CAR_LEAD_SOURCE_MULTI_OPTIONS.values,
      defaultValue:  ACCIDENT_CAR_LEAD_SOURCE_MULTI_INIT,
      itemWidth: 205,
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleBusinessType',
      label: '车辆类型',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
      className: 'vehicle-business-type',
      visible: true,
    },
  ],
};

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TriggerHttpHeadersInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const subject$: Subject<HttpEvent<any>> = new Subject<HttpEvent<any>>();

    next.handle(request).subscribe(
      (response: any): void => {
        if (response instanceof HttpResponse) {
          // Trigger headers so the "lazyInit" is called, otherwise the headers won't be available later, don't know why
          response.headers.keys();
        }

        subject$.next(response);
      },
      (error: any): void => subject$.error(error),
      (): void => subject$.complete(),
    );

    return subject$.asObservable();
  }
}

<div class="select-filter-box">
  <div class="search-input-box" *ngIf="searchable">
    <input
       class="form-control"
       type="text"
       maxlength="20"
       [placeholder]="keywordPlaceholder"
       [formControl]="searchInput"
    />
  </div>
  <label class="select-label select-label-all">
    <input type="checkbox" (change)="onAllChange($event)" [checked]="getIsAllChecked()" />
    全部
  </label>
  <div class="select-filter-group" [ngClass]="{'select-filter-group-searchable': searchable}">
    <label *ngFor="let option of displayOptions | async" class="select-label">
      <input type="checkbox"
             [value]="option.value"
             (change)="onChange($event, option)"
             [checked]="isSelect(option)" />
      {{option.text | lowercaseBrand}}
    </label>
    <span class="text-muted" *ngIf="showNoData()">
      未找到匹配的结果
    </span>
  </div>
  <span class="btn-cancel" (click)="onCancel()">
    取消
  </span>
  <button class="btn-save select-btn" type="submit" (click)="onSubmit()">
    确定
  </button>
</div>

<select *ngIf="type === 'select'"
        [ngStyle]="selectorStyle"
        [title]="tooltip"
        (ngModelChange)="onChange($event)"
        [ngModel]="value"
        class="input-control"
        [disabled]="disabled"
        (keydown)="onKeyDown($event)"
        [ngClass]="{'input-error': errorMessage, 'value-color--empty': !value}">
  <option *ngFor="let option of options" [value]="option.value">{{option.text | lowercaseBrand}}</option>
</select>
<input class="form-control basic-input-editor"
       *ngIf="type === 'input'"
       [class.input-error]="errorMessage"
       [placeholder]="placeholder"
       [(ngModel)]="value"
       (keydown)="onKeyDown($event)"
       [maxlength]="maxLength"
       (ngModelChange)="onChange($event)"
       [disabled]="disabled">
<div *ngIf="errorMessage" class="error-message">
  {{errorMessage}}
</div>

import { Component } from '@angular/core';
import { BasicEditorComponent } from '../basic-editor.component';
import * as _ from 'lodash';
import { ICellEditorParams } from 'ag-grid';

@Component({
  selector: 'app-select-input-editor-cell',
  styleUrls: ['../editor.style.scss'],
  templateUrl: 'select-input-editor.template.html',
})
export class SelectInputEditorCellComponent extends BasicEditorComponent {
  options: Array<any>;
  type: string;

  agInit(params: ICellEditorParams): void {
    super.agInit(params);
    this.options = this.getOptions();
    this.type = this.getType();
    this.value = params.value;
    if (_.isNil(this.value) || _.isEmpty(this.value.toString())) {
      this.value = this.cellEditorParams.defaultValue || _.get(this.options[0], 'value');
      this.updateSourceValue();
    }
  }

  get disabled() {
    if (_.isFunction(this.cellEditorParams.disabled)) {
      return this.cellEditorParams.disabled(this.params);
    }
    return false;
  }

  get selectorStyle() {
    return _.get(this.cellEditorParams, 'styles', {});
  }

  get placeholder() {
    return this.cellEditorParams.placeholder;
  }

  get maxLength() {
    return this.cellEditorParams.maxLength;
  }

  onChange(event) {
    if (this.type === 'input') {
      this.value = event;
      this.updateSourceValue(event);
    }
    if (event !== this.value && this.type === 'select') {
      const oldValue = this.value;
      this.value = event;
      this.updateSourceValue(event);
      this.onChangeHandler(event, oldValue, this.options);
    }
  }

  onUpdateEvent() {
    this.options = this.getOptions();
    this.type = this.getType();
    this.value = '';
    this.updateSourceValue('');
  }

  private getOptions() {
    const options = this.cellEditorParams.options;
    return _.isFunction(options) ? options(this.params) : options;
  }

  private getType() {
    const type = this.cellEditorParams.type;
    return _.isFunction(type) ? type(this.params) : type;
  }
}

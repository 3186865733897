import { CUSTOMER_HOBBIES, CustomerHobbyEntity } from '../../shared/constant/customer-hobby';
import * as _ from 'lodash';

const getTypeBySubType = (subType: string): string | undefined => {
  return _.findKey(CUSTOMER_HOBBIES, subTypes => _.includes(subTypes, subType));
};

const getCustomerHobbiesByType = (type: string): CustomerHobbyEntity[] => {
  return _.map(_.get(CUSTOMER_HOBBIES, type), subType => ({ type, subType }));
};

export const buildCustomerHobbiesToTypeMapping = (customerHobbies: string[]): CustomerHobbyEntity[] =>
  _.chain(customerHobbies)
    .map(hobby => {
      const type: string | undefined = getTypeBySubType(hobby);
      if (type) {
        return [{ type, subType: hobby }];
      }
      return getCustomerHobbiesByType(hobby);
    })
    .flatten()
    .value();

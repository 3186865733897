import { toEnum } from '../_common/utils/enum';

export const NotificationRoleOptions = [
  {
    label: '服务顾问', value: 'ASSA',
  },
  {
    label: '售后经理', value: 'ASM',
  },
  {
    label: '销售顾问', value: 'SC',
  },
  {
    label: '销售行政', value: 'SA',
  },
  {
    label: '销售经理', value: 'SM',
  },
];

export const LPOServiceContractPriceConfigExcludeErrorCode = ['found_zero_price', 'invalid_dealer_price_from_lpo', 'validate_excel_failed', 'incorrect_sheet_name'];

export const LPOServiceContractPriceConfigErrorCode = {
  FOUND_ZERO_PRICE: 'found_zero_price',
  INVALID_DEALER_PRICE_FROM_LPO: 'invalid_dealer_price_from_lpo',
  VALIDATE_EXCEL_FAILED: 'validate_excel_failed',
  LPO_CLIENT_ERROR: 'LPO_CLIENT_ERROR',
  INCORRECT_SHEET_NAME: 'incorrect_sheet_name'
};

export const EVAErrorCode = {
  EVA_ACCESS_FAILURE: 'eva_access_failure',
};

export const SalesNotificationRoleOptions = [
  { text: '全部', value: 'ALL' },
  { text: '二手车经理', value: 'UM' },
  { text: '二手车评估师', value: 'UE' },
  { text: '高级配置管理员', value: 'DCM' },
  { text: '高级销售行政', value: 'SSA' },
  { text: '客户关系专员', value: 'CRM' },
  { text: '客户发展经理', value: 'CDM' },
  { text: '客户权益官', value: 'CO' },
  { text: '前台', value: 'RE' },
  { text: '售后客户发展专员', value: 'ASCDO' },
  { text: '售后市场专员', value: 'ASMO' },
  { text: '特殊业务管理员', value: 'SPECIAL_CASE_ADMIN' },
  { text: '体验专家', value: 'PC' },
  { text: '系统管理员', value: 'SYSA' },
  { text: '销售/售后市场专员', value: 'CMO' },
  { text: '销售主管', value: 'SS' },
  { text: '销售客户发展专员', value: 'SCDO' },
  { text: '销售市场专员', value: 'SMMO' },
  { text: '销售经理', value: 'SM' },
  { text: '销售行政', value: 'SA' },
  { text: '销售顾问', value: 'SC' },
  { text: '展厅经理', value: 'SRM' },
  { text: '总经理', value: 'GM' },
];

export const DEFAULT_SSSC_PACKAGE = {
  baumuster: '',
  packageName: '',
  productCode: '',
  packageValidFrom: null,
  packageValidTo: null,
  month: null,
  wholesalePrice: null,
  startingMileage: '',
  endingMileage: '',
  isMainProduct: '1',
};

export const DEFAULT_AUTO_ACTIVE_PROJECT = {
  baumuster: '',
  productCode: '',
  productActivateFrom: null,
  productActivateTo: null,
  validMonth: '0',
};

export const PACKAGE_STATUS_OPTIONS = toEnum({
  all: { text: '全部（只读）' },
  active: { text: '已生效' },
  inActive: { text: '未生效' },
});

export const OPERATOR_OPTIONS = toEnum({
  add: { text: '+' },
  subtract: { text: '-' },
});

export const UPLOAD_PACKAGE_RECORD_STATUS_MAPPING = {
  SUCCESS: '成功',
  FAILED: '失败',
  UPLOADING: '上传中',
};

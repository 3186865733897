import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-collapsible-section',
  templateUrl: './collapsible-section.component.html',
  styleUrls: ['./collapsible-section.component.scss'],
})
export class CollapsibleSectionComponent implements OnInit {
  @Input() isCollapsed: boolean = true;
  @Input() title: '';

  @Output() collapsedStatusChanged = new EventEmitter<boolean>();

  ngOnInit() {}

  changeCollapsedStatus() {
    this.isCollapsed = !this.isCollapsed;
    this.collapsedStatusChanged.emit(this.isCollapsed);
  }
}

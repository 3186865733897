import { Injectable } from '@angular/core';
import { Body, DELETE, GET, Headers, PATCH, Path, POST, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { SimpleModel } from '../models/variant-parameter';
import { HttpClient } from '@angular/common/http';
import { Brand, Dealer, DamageClaim } from '../models/vehicle';
import { IGetVehicleAccessoryPostBody, IUpdateVehicleAccessoryPostBody } from '../models/vehicle-accessory';

@Injectable()
export class VehicleApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/vehicles/get-reessCode-and-emotorNumber/:vin')
  refreshDataFromRTM(@Path('vin') vin: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/damage-claim/claim-exist')
  getClaimExist(@Query('vinNo') vinNo: string): Observable<any> {
    return null;
  }

  @GET('/api/vehicles/fields')
  getColumns(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/vehicles/:commission_id')
  getVehicle(@Path('commission_id') commissionId: number, @Query('isSA') isSA: boolean): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/vehicles/share-vehicle/:commission_id')
  getShareVehicle(@Path('commission_id') commissionId: number, @Query('isSA') isSA: boolean): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/vehicles/allocation')
  bookMbeCentralVehicle(@Query('quotation_id') quotationId: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/vehicles/search/pages/:page')
  paginateSearchVehicles(@Path('page') commissionNo: string,
                         @Query('page_size') pageSize: number,
                         @Query('cec_dealer_id') dealerId: string,
                         @Query('privilege_code') privilegeCode: string,
                         @Query('search_criteria') searchData,
                         @Query('filters') filters,
                         @Query('is_share_vehicle') isShareVehicle?: boolean): Observable<any> {
    return null;
  }

  @DELETE('/api/vehicles')
  deleteVehicle(@Body commissionIds: number[]): Observable<any> {
    return null;
  }

  @PATCH('/api/vehicles/:commission_id')
  updateVehicleByCommissionId(@Path('commission_id') commissionId: number,
                              @Body data: Object,
                              @Query('isSA') isSA: boolean): Observable<any> {
    return null;
  }

  @PATCH('/api/vehicles')
  updateVehicleVisibility(@Body data: Object): Observable<any> {
    return null;
  }

  @GET('/api/dms_import/conflicts')
  getDataSynchronizationByDealerId(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/dms_import/cache_vehicles')
  getDMSMatchDataByDealerId(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @POST('/api/dms_import/vehicles')
  importStockFromDMSManually(@Body data: string[]): Observable<any> {
    return null;
  }

  @GET('/api/vehicles/excel-report-token')
  getExcelReportToken(@Query('search_criteria') searchData,
                      @Query('filters') filters): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/vehicles/excel-report-token')
  @Headers({ isExportFile: true })
  getVehicleExcelReport(@Query('search_criteria') searchData,
                        @Query('filters') filters): Observable<any> {
    return null;
  }

  @PUT('/api/vehicles/location-descriptions')
  updateLocationDescriptions(@Body body: Object): Observable<any> {
    return null;
  }

  @GET('/api/interested-brands')
  getAllInterestedBrandsData(@Query('level') level: string): Observable<any> {
    return null;
  }

  @GET('/api/interested-brands/all')
  getExtendedReleasedBrands(): Observable<Array<Brand>> {
    return null;
  }

  @GET('/api/outlets/all-dealer-mapping')
  getAllDealerMappings(): Observable<Array<Dealer>> {
    return null;
  }

  @GET('/api/sales-website/damage-claim/logistics')
  getLogisticsInformation(@Query('vinNo') vinNo: string): Observable<any> {
    return null;
  }

  @GET('/api/released-brands/simple')
  getReleasedVehicleBrands(): Observable<Array<Brand>> {
    return null;
  }

  @GET('/api/released-brands/simple-without-variant-distinct')
  getReleasedVehicleBrandsWithoutVariantDistinct(): Observable<Array<Brand>> {
    return null;
  }

  @PUT('/api/vehicles/share-stock/default-share-range')
  setDefaultShareStatus(@Body body: Object): Observable<any> {
    return null;
  }

  @GET('/api/vehicles/share-stock/default-share-range')
  getDefaultShareRange(): Observable<any> {
    return null;
  }

  @PUT('/api/vehicles/share-stock/share-range')
  setShareRangeForSelectedVehicles(@Body body: Object): Observable<any> {
    return null;
  }

  @PUT('/api/vehicles/share-stock/default-share-visibility')
  setDefaultVisibleRoles(@Body body: Object): Observable<any> {
    return null;
  }

  @GET('/api/vehicles/share-stock/default-share-visibility')
  getDefaultVisibleRoles(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/vehicles/share-stock')
  paginateGetAllSharedVehicles(@Query('page') pageNumber: number,
                               @Query('size') pageSize: number,
                               @Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @PUT('/api/vehicles/share-stock')
  setSpecificShareVisibleRoles(@Body body: Object): Observable<any> {
    return null;
  }

  @PATCH('/api/vehicles/mbe-availability')
  setSalesChannel(@Body body: any): Observable<any> {
    return null;
  }

  @GET('/api/orders/vehicle/status/:commissionNo')
  getVehicleStatus(@Path('commissionNo') commissionNo: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/test-drive-vehicle/:commissionNo')
  findVehicleForTestDrive(@Path('commissionNo') commissionNo: string): Observable<any> {
    return null;
  }

  @PATCH('/api/vehicles/batch-update/actual-arrived-date')
  batchUpdateActualArrivedDate(@Body body: Object): Observable<any> {
    return null;
  }

  @GET('/api/vehicle_properties/models')
  getAllModels(): Observable<Array<SimpleModel>> {
    return null;
  }

  @GET('/api/vehicle-stock/vehicle-business-type/van-classes')
  getVanClasses(): Observable<any> {
    return null;
  }

  @PATCH('/api/cesar-vehicles')
  updateVehicle(@Query('commission_no') commissionNo: string,
                @Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @POST('/api/vehicles/exterior-images')
  getVehicleImageByModels(@Body models): Observable<any> {
    return null;
  }

  @GET('/api/variants/prices-for-finance')
  getFinancialVehicle(@Query('class_id') classId: string,
                      @Query('model_name') modelName: string,
                      @Query('extend_released') extendReleased: boolean,
                      @Query('business_model_type') businessModelType: string): Observable<any> {
    return null;
  }

  @GET(`/api/website/service-histories/detail`)
  getServiceHistories(@Query('fin') fin: string, @Query('dealerId') dealerId: string): Observable<any[]> {
    return null;
  }

  @POST('/api/sales-website/orders/accessories/filter')
  searchVehicleAccessoryList(@Query('keyword') keyword: string,
                             @Query('group_type') groupType: string,
                             @Query('product_type') productType: string[],
                             @Query('page_size') pageSize: number,
                             @Query('page_number') pageNumber: number,
                             @Body models: IGetVehicleAccessoryPostBody): Observable<any> {
    return null;
  }

  @PATCH('/api/vehicles/:commissionId/accessory')
  updateVehicleAccessory(@Path('commissionId') commissionId: number,
                         @Body data: IUpdateVehicleAccessoryPostBody): Observable<any> {
    return null;
  }

  @POST('/api/vehicles/specify-dealer-sharing-group/create')
  createShareScopeGroup(@Body params: Object): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/model-d-test-drive-vehicles/nev/:id')
  saveEvCarDetail(@Path('id') id: number,
                  @Body params: Object): Observable<any> {
    return null;
  }

  @GET('/api/vehicles/specify-dealer-sharing-group/list')
  getShareScopeGroups(): Observable<any> {
    return null;
  }

  @DELETE('/api/vehicles/specify-dealer-sharing-group/delete/:id')
  delShareScopeGroups(@Path('id') id: string): Observable<any> {
    return null;
  }

  @GET('/api/website/vehicle/download/template')
  @Headers({ isExportFile: true })
  getStockTemplate() {
    return null;
  }

  @GET('/api/website/vehicle/failed/download')
  @Headers({ isExportFile: true })
  getStockTemplateFailedData(@Query('failedId') failedId: string) {
    return null;
  }

  @GET('/api/sales-website/model-d-test-drive-vehicles/nev/:id')
  getEvCarDetail(@Path('id') id: any): Observable<any> {
    return null;
  }

  @GET('/api/v2/nev/config')
  fetchEvCarFieldConfig(): Observable<any> {
    return null;
  }

  @GET('/api/regions')
  fetchRegionsData(): Observable<any> {
    return null;
  }

  @GET('/api/customers/invoices/companies/search')
  searchCompanyInvoices(@Query('dealer_id') dealer_id: string, @Query('name') name: string) {
    return null;
  }

  @GET('/api/vehicle-stock/vehicle-business-type')
  getVehicleBusinessType(@Query('class') typeClass: string) {
    return null;
  }

  @POST('/api/sales-website/damage-claim/vehicle-damage/save')
  saveVehicleDamage(@Query('isSubmit') isSubmit: boolean, @Body damageClaim: DamageClaim) {
    return null;
  }

  @GET('/api/sales-website/damage-claim/damage-file/:fileId')
  @Headers({ isExportFile: true })
  downloadQualityClaimAttachment(@Path('fileId') fileId: string) {
    return null;
  }

  @DELETE('/api/sales-website/damage-claim/damage-files')
  deleteQualityClaimAttachment(@Query('fileIds') fileIds: Array<string>): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/damage-claim/damage-master-data')
  getDamageSelections(): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/damage-claim/damage-image')
  @Headers({ 'No-Content-Type': 1 })
  uploadDamageImageFile(@Body file: FormData, @Query('vinNo') vinNo: string): Observable<any> {
    return null;
  }

  @GET('/api/aftersales/file/media/:fileId')
  @Headers({ isExportFile: true })
  downloadDamageImageFile(@Path('fileId') fileId: string): Observable<any> {
    return null;
  }

  @DELETE('/api/sales-website/damage-claim/damage-images')
  deleteDamageImageFile(@Query('fileIds') fileIds:  string[]): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/damage-claim/claim-info')
  getSubmittedInfo(@Query('id') claimId: string, @Query('status') status: number) {
    return null;
  }

  @GET('/api/sales-website/damage-claim/claim-info')
  getNoSubmittedInfo(@Query('id') id: string, @Query('status') status: number) {
    return null;
  }

  @POST('/api/sales-website/damage-claim/vehicle-damage/fetch-list')
  getDamageClaimNoSubmitList(@Body params: Object): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/damage-claim/submited-list')
  getDamageClaimSubmittedList(@Body params: Object): Observable<any> {
    return null;
  }

  @DELETE('/api/sales-website/damage-claim/delete/:id')
  fetchDeleteDamageClaim(@Path('id') id: string): Observable<any> {
    return null;
  }

  @PUT('/api/sales-website/damage-claim/claim-info/close/:claimId')
  fetchCloseDamageClaim(@Path('claimId') claimId: string): Observable<any> {
    return null;
  }

  @GET('/api/outlets/dealerId')
  getDealerCode(@Query('dealerId') dealerId: string) {
    return null;
  }
}

/**
 * Description:
 * 代码参考: https://zhugeio.com/app/94329/plat/0/manage/dataAccess/guide, 需要登录才能访问
 * 环境变量的使用，参考方案: https://github.com/angular/angular-cli/issues/4451
 * 安全性: 需要在 index.html 的 Content-Security-Policy 追加: script-src: zgsdk.zhugeio.com; image-src: ubak.zhugeio.com u.zhugeapi.net
 * Troubleshooting:
 * 1. zhuge SDK 加载完成后再调用 zhuge._init(appKey, configs) 会报错，缺少第三个参数。不能先加载 zhuge.min.js 后，再调用 zhuge._init() 初始化。
 * 2. zhuge SDK 加载后会生成两个 cookie，会被 Azure 的 WAF 拦截。需要修改修改 WAF 规则。
 */
// tslint:disable
export function initZhugeSDK(zhugeAppKey?: string) {
  window['zhuge']._init(zhugeAppKey, {
    superProperty: {
      "应用名称": "One Touch Retail +"
    },
    adTrack: false,
    zgsee: false,
    autoTrack: true,
    singlePage: true,
    debug: false,
    storeMode: 'storage'
  }, function(){
    console.log('sdk初始化成功')
  })
  /*
  if (!zhugeAppKey || window['zhuge']) {
    return;
  }
  window['zhuge'] = [];
  window['zhuge'].methods = '_init identify track trackRevenue getDid getSid getKey setSuperProperty setUserProperties setWxProperties setPlatform'.split(' ');
  window['zhuge'].factory = function (b) {
    return function () {
      const a = Array.prototype.slice.call(arguments);
      a.unshift(b);
      window['zhuge'].push(a);
      return window['zhuge'];
    };
  };
  for (let i = 0; i < window['zhuge'].methods.length; i++) {
    const key = window['zhuge'].methods[i];
    window['zhuge'][key] = window['zhuge'].factory(key);
  }
  window['zhuge'].load = function (b, x) {
    if (!document.getElementById('zhuge-js')) {
      const a = document.createElement('script');
      const verDate = new Date();
      const verStr = verDate.getFullYear().toString() + verDate.getMonth().toString() + verDate.getDate().toString();

      a.type = 'text/javascript';
      a.id = 'zhuge-js';
      a.async = !0;
      a.src = 'zhuge.min.js';
      //a.src = 'https://zgsdk.zhugeio.com/zhuge.min.js?v=' + verStr;
      a.onerror = function () {
        window['zhuge'].identify = window['zhuge'].track = function (ename, props, callback) {
          if (callback && Object.prototype.toString.call(callback) === '[object Function]') {
            callback();
          } else if (Object.prototype.toString.call(props) === '[object Function]') {
            props();
          }
        };
      };
      const c = document.getElementsByTagName('script')[0];
      c.parentNode.insertBefore(a, c);
      window['zhuge']._init(b, x);
    }
  };
  window['zhuge'].load(zhugeAppKey, { // 配置应用的AppKey
    superProperty: { // 全局的事件属性(选填)
      应用名称: 'One Touch Retail +',
    },
    adTrack: false, // 广告监测开关，默认为false
    zgsee: false, // 视屏采集开关， 默认为false
    autoTrack: true, //启用全埋点采集（选填，默认false）
    singlePage: true, //是否是单页面应用（SPA），启用autoTrack后生效（选填，默认false）
    debug: false,
    storeMode: 'storage'
  });
  */
}

import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BasicEditorComponent } from '../basic-editor.component';
import { isKeyBackspace, isKeyNumeric } from '../../../../utils/common';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-cell-editor',
  templateUrl: 'basic-input-editor.template.html',
  styleUrls: ['../editor.style.scss'],
})
export class BasicInputEditorComponent extends BasicEditorComponent {
  form: FormGroup;
  useRegexp: boolean = false;
  inputStyle: any;
  errorMessageStyle: any;

  agInit(params: any): void {
    super.agInit(params);
    this.inputStyle = _.get(this.cellEditorParams, 'styles', {});
    this.inputStyle = _.isFunction(this.inputStyle) ? this.inputStyle(this.params) : this.inputStyle;
    this.errorMessageStyle = _.get(this.cellEditorParams, 'errorMessageStyles', {});
    this.initForm();
  }

  get formatters() {
    return this.cellEditorParams.formatters || [];
  }

  get autofocus() {
    return this.cellEditorParams.autofocus;
  }

  get placeholder() {
    return this.cellEditorParams.placeholder;
  }

  get maxLength() {
    const maxLength = this.cellEditorParams.maxLength;
    return _.isFunction(maxLength) ? maxLength(this.params) : maxLength;
  }

  get questionMessage() {
    return this.cellEditorParams.questionMessage;
  }

  get disabled() {
    const disabled = this.cellEditorParams.disabled;
    return _.isFunction(disabled) ? disabled(this.params) : disabled;
  }

  onUpdateEvent(value = '') {
    this.value = value;
    this.updateSourceValue();
  }

  onBeforeChangeUpdateInputValue() {
    if (this.cellEditorParams.onBeforeChange) {
      this.value = this.cellEditorParams.onBeforeChange(this.value);
    }
  }

  onChange({ target: { value } }) {
    this.value = _.reduce(this.formatters, (result, formatter: Function) => formatter(result), value);
    this.onBeforeChangeUpdateInputValue();
    this.updateSourceValue(this.value);
    this.onChangeHandler(this.value, this.params.rowIndex, this.params.column.getColId());
  }

  onBlur() {
    return this.onBlurHandler(this.value, _.get(this.params, 'node.rowIndex', -1));
  }

  onOtherKeyDown(event): void {
    if (this.cellEditorParams.onlyAllowedNumber) {
      if (!this.isKeyInputNumericOrBackspace(event) && event.preventDefault) {
        event.preventDefault();
      }
    }

    if (event.preventDefault && _.isFunction(this.cellEditorParams.onEnterHandler)) {
      const key = this.getKeyFromEvent(event);
      if (!this.cellEditorParams.onEnterHandler(key)) {
        event.preventDefault();
      }
    }
  }

  initForm(): void {
    const regexp = this.cellEditorParams.regexp;
    if (regexp) {
      this.useRegexp = true;
      this.form = new FormGroup({ formValue: new FormControl(this.value) });
      this.form.get('formValue').valueChanges.subscribe(newValue => {
        if (newValue.match(regexp)) {
          this.onChange({ target: { value: newValue } });
        } else {
          this.form.patchValue(this.form.value);
        }
      });
    }
  }

  private isKeyInputNumericOrBackspace(event): boolean {
    const key = this.getKeyFromEvent(event);
    return isKeyBackspace(key) || isKeyNumeric(key);
  }
}

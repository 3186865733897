<div *ngIf="!useRegexp" [title]="tooltip">
  <input class="form-control basic-input-editor"
         [ngClass]="{'input-error': errorMessage, 'basic-input-editor-width':questionMessage}"
         [placeholder]="placeholder"
         [(ngModel)]="value"
         (keydown)="onKeyDown($event)"
         (blur)="onBlur()"
         [autofocus]="autofocus"
         [maxlength]="maxLength"
         (change)="onChange($event)"
         [disabled]="disabled"
         [ngStyle]="inputStyle">
  <ng-container *ngTemplateOutlet="message"></ng-container>
</div>

<div *ngIf="useRegexp" [title]="tooltip" [formGroup]="form">
  <input class="form-control basic-input-editor"
         formControlName="formValue"
         [ngClass]="{'input-error': errorMessage, 'basic-input-editor-width':questionMessage}"
         [placeholder]="placeholder"
         (blur)="onBlur()"
         [autofocus]="autofocus"
         [maxlength]="maxLength"
         [disabled]="disabled"
         [ngStyle]="inputStyle">
    <ng-container *ngTemplateOutlet="message"></ng-container>
</div>

<ng-template #message>
  <span class="question-circle" *ngIf="questionMessage">
    <i class="fa antd-question-circle-filled" aria-hidden="true">
      <span class="question-circle-message">{{questionMessage}}</span>
    </i>
  </span>
  <div *ngIf="shouldShowErrorMessage && errorMessage" class="error-message" [ngStyle]="errorMessageStyle">
    {{errorMessage}}
  </div>
</ng-template>


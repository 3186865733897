
      <div class="activateStatusTooltip">
          <ng-template #tooltipTemplate>
              <span class="tooltipText">{{value}}</span>
          </ng-template>
          {{value.substr(0, 40)}}
          <span *ngIf="value!=='-'"
                [tooltip]="tooltipTemplate"
                placement="bottom">...</span>
      </div>
  
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';

import { TechnicianResourceManagementApi } from '../api/technician-resource-management';
import {
  TechnicianTeamWorkPlan,
  Technician,
  TechnicianTeam,
  TechnicianSinInInfo,
  TechnicianWorkPlan,
} from '../models/technician-team';
import { TimeUnit } from '../constants/date';
import { JobCardService } from './job-card.service';

const TECHNICIAN_CLOCKING_INFO = 'TECHNICIAN_CLOCKING_INFO';

@Injectable()
export class TechnicianResourceManagementService {
  technicianSkills: Observable<string[]>;

  constructor(private technicianResourceManagementApi: TechnicianResourceManagementApi,
              private jobCardService: JobCardService) {
  }

  set technicianClockingInfo(value: Technician) {
    sessionStorage.setItem(TECHNICIAN_CLOCKING_INFO, JSON.stringify(value));
    this.technicianSkills = null;
  }

  get technicianClockingInfo(): Technician {
    const technicianInfo = sessionStorage.getItem(TECHNICIAN_CLOCKING_INFO);
    return technicianInfo && JSON.parse(technicianInfo);
  }

  fetchTechnicianSkill(technicianId: number): Observable<string[]> {
    return this.technicianResourceManagementApi.getTechnician(technicianId).pipe(
      map(({ skills }) => _.map(skills, 'code')),
    );
  }

  initTechnicianSkills(): Observable<string[]> {
    return this.technicianSkills = this.fetchTechnicianSkill(this.technicianClockingInfo.id).pipe(
      tap(() => timer(TimeUnit.MINUTE).subscribe(() => this.technicianSkills = null)),
      catchError(error => {
        this.technicianSkills = null;
        return throwError(error);
      }),
      publishReplay(1),
      refCount(),
    );
  }

  clearTechnicianClockingInfo() {
    sessionStorage.removeItem(TECHNICIAN_CLOCKING_INFO);
    this.jobCardService.shouldClockOffIdleId = null;
    this.jobCardService.shouldClockOffLaborItemsData = null;
  }

  getAllTechnicianTeams(): Observable<Array<TechnicianTeam>> {
    return this.technicianResourceManagementApi.getAllTechnicianTeams();
  }

  getAllTechnicianTeamsWithMajor(): Observable<Array<TechnicianTeam>> {
    return this.technicianResourceManagementApi.getAllTechnicianTeamsWithMajor();
  }

  getSkillMatchedTechnicians(currentJobCardId: number, laborItemIds: number[]): Observable<Array<Technician>> {
    return this.technicianResourceManagementApi.getSkillMatchedTechnicians(currentJobCardId, laborItemIds);
  }

  getAllTechnicians(): Observable<Array<Technician>> {
    return this.technicianResourceManagementApi.getAllTechnicians();
  }

  getTechnicalAlertList(orderNumber: string): Observable<any> {
    return this.technicianResourceManagementApi.getTechnicalAlertList(orderNumber);
  }

  getInProgressTechnicianIds(technicianIds: number[]): Observable<number[]> {
    return this.technicianResourceManagementApi.getInProgressTechnicianIds(technicianIds);
  }

  getSkills(): Observable<string[]> {
    return this.technicianSkills || this.initTechnicianSkills();
  }

  getTeamWorkSummaries(): Observable<TechnicianTeamWorkPlan[]> {
    return this.technicianResourceManagementApi.getTeamWorkPlan();
  }

  getWorkPlanListByTechnician(orderByKey: string, searchKeywords: string): Observable<TechnicianWorkPlan[]> {
    return this.technicianResourceManagementApi.getWorkPlanListByTechnician(orderByKey, searchKeywords);
  }

  getTechnicianWorkPlan(teamId: any): Observable<TechnicianWorkPlan[]> {
    return this.technicianResourceManagementApi.getTechnicianWorkPlan(teamId);
  }

  getTechnicianSignInInfo(technicianId: number): Observable<TechnicianSinInInfo> {
    return this.technicianResourceManagementApi.getTechnicianSignInInfo(technicianId);
  }

  createSignInInfo(technicianId: number, signInInfo: any): Observable<any> {
    return this.technicianResourceManagementApi.createSignInInfo(technicianId, signInInfo);
  }

  updateSignInInfo(technicianId: number, signInInfo: any): Observable<any> {
    return this.technicianResourceManagementApi.updateSignInInfo(technicianId, signInInfo);
  }

  getTechnicianSignInInfoRecords(technicianId: number, startTime: any, endTime: any): Observable<TechnicianSinInInfo[]> {
    return this.technicianResourceManagementApi.getTechnicianSignInInfoRecords(
      technicianId,
      moment(startTime).startOf('day').toISOString(),
      moment(endTime).endOf('day').toISOString(),
    );
  }
}

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import {
  UPLOAD_PACKAGE_RECORD_STATUS, UploadPackageRecord,
} from '../../../../../service-contract/models/service-contract.model';
import * as _ from 'lodash';
import { ICellRendererParams } from 'ag-grid';
import { UPLOAD_PACKAGE_RECORD_STATUS_MAPPING } from '../../../../../service-contract/constant';

@Component({
  templateUrl: './upload-records-status.component.html',
  styleUrls: ['./upload-records-status.component.scss'],
})

export class UploadRecordsStatusComponent implements ICellRendererAngularComp {
  status: UPLOAD_PACKAGE_RECORD_STATUS;
  content: string;
  className: string;
  token: string;
  row: UploadPackageRecord = {} as UploadPackageRecord;
  params: ICellRendererParams;
  statusMapping: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const getContent = _.get(params.colDef, 'cellRendererParams.getContent');
    this.content = _.isFunction(getContent) ? getContent(params) : (params.valueFormatted || params.value);
    this.className = _.lowerCase(this.content);
    this.row = params.data || {};
    this.statusMapping = UPLOAD_PACKAGE_RECORD_STATUS_MAPPING[this.content];
  }

  download() {
    const { invalidFileId, fileName } = this.row;
    const download = _.get(this.params, 'colDef.cellRendererParams.download');
    if (_.isFunction(download)) {
      download(invalidFileId, fileName);
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}

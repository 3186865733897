import { Injectable } from '@angular/core';
import { Body, DELETE, GET, PATCH, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TestDriveApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/test-drive')
  getTestDrivesByLeadId(@Query('lead_id') leadId: string,
                        @Query('start_time') startTime: number,
                        @Query('end_time') endTime?: number): Observable<any> {
    return null;
  }

  @DELETE('/api/test-drive/:id')
  deleteTestDrive(@Path('id') id: string) {
    return null;
  }

  @POST('/api/test-drive')
  startTestDrive(@Body body): Observable<any> {
    return null;
  }

  @PATCH('/api/test-drive')
  finishTestDrive(@Body body): Observable<any> {
    return null;
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  template: `<span style="white-space: pre;">{{params.value}}</span>`,
})
export class PreSpaceRenderComponent implements ICellRendererAngularComp {
  params;

  agInit(params): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}

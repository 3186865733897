import { Body, DELETE, GET, POST, RebirthHttp } from 'rebirth-http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RolePrivilege } from '../../system-config/general/role-privilege/role-privilege';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PrivilegeConfigApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('api/privilege-config')
  getPrivilegeConfigs(): Observable<any> {
    return null;
  }

  @POST('api/privilege-config')
  save(@Body rolePrivileges: Array<RolePrivilege>): Observable<any> {
    return null;
  }

  @DELETE('/api/privilege-config/reset')
  resetRolePrivileges(): Observable<any> {
    return null;
  }
}

import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  selector: 'app-text-with-last-assign-icon',
  templateUrl: './text-with-last-assign-icon.component.html',
  styleUrls: ['./text-with-last-assign-icon.component.scss']
})
export class TextWithLastAssignIconComponent implements ICellRendererAngularComp {
  content: string;
  showIcon: boolean = false;
  @ViewChild('container') container: ElementRef;

  agInit(params: ICellRendererParams): void {
    this.content = (params.valueFormatted || params.value);
    this.showIcon = _.get(params, 'data.isLastAssigned', false);
  }

  refresh(params: any): boolean {
    return false;
  }
}

<div class="cell-container" (click)="isEditMode = disabled ? false : true">
  <div *ngIf="!isEditMode" class="text-wrapper">
    <span class="display-text"
          [ngStyle]="textStyle"
          [tooltip]="value"
          [placement]="left"
    >{{value}}</span>
    <em class="fa antd-edit-filled"></em>
  </div>
  <input *ngIf="isEditMode" autofocus placeholder="请输入" type="text" [ngModel]="value"
         (blur)="onBlur($event.target.value)" [ngStyle]="textStyle" [disabled]="disabled">
</div>

<div class="form-group follow-up-date row-container">
  <label class="required form-label">本次跟进日期</label>
  <p-calendar
    [locale]="chineseCalendarConfig"
    dataType="string"
    dateFormat="yy-mm-dd"
    placeholder="YYYY-MM-DD"
    [formControl]="followUpTime"
    [inputStyle]="followUpTime.valid ? {} : invalidStyle"
    [maxDate]="maxDate"
    [minDate]="minDate"
    showTime="true"
  >
  </p-calendar>
</div>
<div class="form-group row-container">
  <label>下一步计划</label>
  <app-radio [options]="followUpPlan" [control]="followUpPlanControl" (onChange)="handleFollowUpPlanChange()"></app-radio>
</div>

<app-keep-follow-up #nextPlan [leadId]="leadId" [leadStatus]="leadStatus" *ngIf="isVisible('keepFollowUp')"></app-keep-follow-up>

<app-assign-sc-modal #nextPlan [leadIds]="[leadId]"
                     [customerId]="customerId"
                     [leadStatus]="leadStatus"
                     [scGroupInfos]="scGroupInfos"
                     [enableSelectSC]="enableSelectSC"
                     [ownerSalesConsultantId]="ownerSalesConsultantId"
                     isFollowUp="true"
                     *ngIf="isVisible('appointment')"></app-assign-sc-modal>

<app-close-lead-modal #nextPlan *ngIf="isVisible('closeLead')" [leadIds]="[leadId]" [isUsedCarLeadDetailFlag]="isUsedCarLeadDetailFlag"></app-close-lead-modal>


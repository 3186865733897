<common-app-entrance>
  <div class="login-area">
    <div class="header">
      <span *ngIf="!(isInVerification | async)">重设密码</span>
      <span *ngIf="isInVerification | async">手机号验证</span>
    </div>
    <div class="line"></div>
    <div class="body">
      <div id="error-area" class="error-area">
        <span name="error" class="text-nowrap">{{errorMessage | async}}&nbsp;</span>
      </div>
      <app-password-reset-validate-form *ngIf="!(isInVerification | async)"></app-password-reset-validate-form>
      <app-password-reset-verify-form *ngIf="isInVerification | async"></app-password-reset-verify-form>
    </div>
  </div>
</common-app-entrance>

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

@Component({
  selector: 'app-sub-sales-type-action-cell',
  templateUrl: './sub-sales-type-action-cell.component.html',
  styleUrls: ['./sub-sales-type-action-cell.component.scss'],
})
export class SubSalesTypeActionCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onEditing() {
    this.cellRendererParams.onEditing(this.params);
  }

  onDeleting() {
    this.cellRendererParams.onDeleting(this.params);
  }

}

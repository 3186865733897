import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import { Component } from '@angular/core';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  selector: 'app-icon-tooltip',
  templateUrl: './icon-tooltip.component.html',
  styleUrls: ['./icon-tooltip.style.scss'],
})
export class IconTooltipComponent implements ICellRendererAngularComp {

  private params: ICellRendererParams;
  text: string;
  shouldShowIcon: boolean = false;
  showDefaultIcon: boolean = true;
  tooltipValues;
  imageSrc: string;
  placement: string;
  stopPropagation: boolean = false;

  agInit(params: ICellRendererParams): void {
    const { text, tooltipValues, imageSrc, placement,
            shouldShowIcon, showDefaultIcon, stopPropagation } = params.colDef.cellRendererParams;
    const entireText = getValue(text, params) || '';
    this.text = entireText;
    this.tooltipValues = getValue(tooltipValues, params) || entireText ;
    this.showDefaultIcon = this.getUnNilData(showDefaultIcon, true);
    this.imageSrc = getValue(imageSrc, params) || this.getDefaultImg();
    this.placement = getValue(placement, params) || 'bottom';
    this.shouldShowIcon = getValue(shouldShowIcon, params) && !_.isNil(this.imageSrc);
    this.stopPropagation = this.getUnNilData(stopPropagation, false);
  }

  refresh(params: any): boolean {
    return false;
  }

  getDefaultImg() {
    return this.showDefaultIcon ? '/assets/as-lead/tooltip-icon.svg' : null;
  }

  getUnNilData(data, defaultData) {
    return _.isNil(data) ? defaultData : data;
  }

  stopMousePropagation($event) {
    if (this.stopPropagation) {
      $event.stopPropagation();
    }
  }
}

import { Injectable } from '@angular/core';
import { Body, DELETE, GET, PATCH, Path, POST, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CecInfo } from '../model';

@Injectable()
export class CecConfigApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/cec')
  getCecInfo(): Observable<CecInfo[]> {
    return null;
  }

  @POST('/api/cec')
  createCec(@Body data): Observable<void> {
    return null;
  }

  @PATCH('/api/cec/:cecId')
  updateCec(@Body data, @Path('cecId') cecId: number): Observable<void> {
    return null;
  }

  @DELETE('/api/cec/:cecId')
  deleteCec(@Path('cecId') cecId: number): Observable<void> {
    return null;
  }

  @GET('/api/cec/:id')
  getCecConfig(@Path('id') id: number): Observable<CecInfo> {
    return null;
  }

  @GET('/api/cec/:cecId')
  getCecDealers(@Path('cecId') cecId: number): Observable<CecInfo> {
    return null;
  }
}

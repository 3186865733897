import { Injectable } from '@angular/core';
import { Body, GET, PATCH, Path, POST, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PrivilegeLevelAuthorizationApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/privilege-level-config')
  getAuthorization(@Query('dealergroup_groupid') dealerGroupGroupId: number): Observable<any> {
    return null;
  }

  @GET('/api/privilege-level-config/search')
  getDealerAuthorization(@Query('dealergroup_groupid') dealerGroupGroupId: number): Observable<any> {
    return null;
  }

  @POST('/api/privilege-level-config')
  setAuthorization(@Body data): Observable<any> {
    return null;
  }

  @PUT('/api/sales-website/accounting/masterdata/customer-type-nos/toggle-update-from-dealer-group/:toggleStatus')
  updateCustomerAccount(
    @Path('toggleStatus') toggleStatus,
  ): Observable<any> {
    return null;
  }

  @PUT('/api/sales-website/accounting/masterdata/supplier-nos/toggle-update-from-dealer-group/:toggleStatus')
  updateSupplierAccount(
    @Path('toggleStatus') toggleStatus,
  ): Observable<any> {
    return null;
  }

  @PATCH('/api/sales-website/aftermarket-product/labors/custom-labors/:groupId')
  updateCustomerLaborAccount (
    @Path('groupId') groupId,
    ): Observable<any> {
    return null;
  }
}

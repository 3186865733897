import {Injectable} from '@angular/core';
import {GET, Query, RebirthHttp} from 'rebirth-http';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PrivacyAgreementApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/privacy-agreement/list')
  getPrivacyAgreements(
    @Query('pageSize') pageSize: number,
    @Query('pageNumber') pageNumber: number,
  ): Observable<any> {
    return null;
  }
}

<div [class]="containerClass" >
  <span [ngSwitch]="level">
    <img src="/assets/common/success.svg" class="icon" *ngSwitchCase="successLevel" alt="">
    <img src="/assets/common/alert.svg" class="icon" *ngSwitchCase="infoLevel" alt="">
    <img src="/assets/common/alert.svg" class="icon" *ngSwitchCase="warningLevel" alt="">
    <img src="/assets/common/error.svg" class="icon" *ngSwitchCase="errorLevel" alt="">
    <img src="/assets/common/alert.svg" class="icon" *ngSwitchCase="emergencyLevel" alt="">
    {{content}}
  </span>
</div>

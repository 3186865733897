import { Injectable } from '@angular/core';
import { LuxuryTaxApi } from '../api/luxury-tax';

@Injectable()
export class LuxuryTaxService {

  constructor(private luxuryTaxApi: LuxuryTaxApi) {
  }

  getLuxuryTaxConfig() {
    return this.luxuryTaxApi.getLuxuryTaxConfig();
  }

  saveLuxuryTaxConfig(config) {
    return this.luxuryTaxApi.saveLuxuryTaxConfig(config);
  }

  checkLuxuryTax() {
    return this.luxuryTaxApi.checkLuxuryTax();
  }
}

import { Input, Component } from '@angular/core';

@Component({
  selector: 'app-empty-image',
  styleUrls: ['./empty-image.component.scss'],
  template: `
  <div class='empty-image d-flex justify-content-center flex-column align-items-center'>
    <img class='icon' [src]='icon' alt='' />
    <div class='text'>{{ emptyText }}</div>
  </div>
  `
})
export class EmptyImageComponent {
  @Input() emptyText = '无上传照片';
  @Input() icon = '/assets/common/empty-image-icon.svg';
}

import { Component, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { BasicEditorComponent } from '../basic-editor.component';
import { ICellEditorParams } from 'ag-grid';
import { ENTER_KEY_CODE } from '../../../../constants/common';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss', '../editor.style.scss'],
})
export class DropdownSelectComponent extends BasicEditorComponent {
  options: Array<any>;
  optionHtmlGenerator: Function;
  keyword: string;
  filteredOptions: Array<any>;
  displayText: string;
  isDropUp: boolean = false;
  isTips: boolean = false;
  @ViewChild('dropdownSelect') dropdownSelect;

  agInit(params: ICellEditorParams): void {
    super.agInit(params);
    this.options = this.getOptions();
    this.filteredOptions = this.options;
    this.optionHtmlGenerator = this.cellEditorParams.optionHtmlGenerator;
    this.displayText = this.getDisplayText();
    this.initIsDropUpStatus();

    if (!_.isEmpty(params.value) || typeof(params.value) === 'number') {
      this.displayText = this.getCurrentOptionHtml(
        _.find(this.options,
          optionValue => _.isEqual(optionValue.value, params.value)));
    }
  }

  public getDisplayText() {
    const displayText = _.get(this.cellEditorParams, 'displayText', '请选择');
    return _.isFunction(displayText) ? displayText(this.params) : displayText;
  }

  public get keywordPlaceholder() {
    return _.get(this.cellEditorParams, 'keywordPlaceholder', '请输入关键字搜索');
  }

  public get searchable() {
    return _.get(this.cellEditorParams, 'searchable', true);
  }

  public get style() {
    return _.get(this.cellEditorParams, 'styles', {});
  }

  public get errorMessageStyle() {
    return _.get(this.cellEditorParams, 'errorMessageStyles', {});
  }

  public get disabled() {
    return _.get(this.cellEditorParams, 'disabled', false);
  }

  public getShouldAlwaysDropDown() {
    const shouldAlwaysDropDown = this.cellEditorParams.shouldAlwaysDropDown;
    return _.isFunction(shouldAlwaysDropDown) ? shouldAlwaysDropDown(this.params) : shouldAlwaysDropDown;
  }

  public getOptions() {
    const options = this.cellEditorParams.options;
    return _.isFunction(options) ? options(this.params) : options;
  }

  handleFilter(event) {
    if (event.which === ENTER_KEY_CODE) {
      event.preventDefault();
      if (this.filteredOptions.length > 0) {
        this.handleClick(this.filteredOptions[0]);
        this.dropdownSelect.hide();
      }
    } else {
      this.filteredOptions = this.options.filter(option => _.includes(option.text, this.keyword));
    }
  }

  handleClick(option) {
    this.updateSourceValue(option.value);
    this.displayText = this.getCurrentOptionHtml(option);
    this.errorMessage = '';
    if (this.isTips) {
      this.onChangeHandler(option.value, this.params.rowIndex, this.params.column.getColId());
    } else {
      this.onChangeHandler(option);
    }
    this.onAfterChangedHandler(this.value, _.get(this.params, 'node.rowIndex', -1));
  }

  clearKeyword() {
    this.keyword = '';
    this.filteredOptions = this.options;
  }

  scrollBar(event) {
    event.stopPropagation();
    return true;
  }

  preventEmitEvent(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  get showCustomOption() {
    return !_.isNil(this.optionHtmlGenerator);
  }

  getCurrentOptionHtml(option) {
    const currentOptionHtml = this.cellEditorParams.currentOptionHtml;
    return _.isFunction(currentOptionHtml) ? currentOptionHtml(option) : option.text;
  }

  getOptionHtml(option) {
    if (this.showCustomOption) {
      return this.optionHtmlGenerator(option);
    }
    return `<span> ${option.text}</span>`;
  }

  private initIsDropUpStatus() {
    if (this.getShouldAlwaysDropDown()) {
      return;
    }
    const agRow = this.findParent(this.dropdownSelect._elementRef.nativeElement, 'ag-row');
    const agContainer = this.findParent(agRow, 'ag-body-container');
    const agBody = agContainer.offsetParent;

    if ((agContainer.offsetHeight >= agBody.offsetHeight
      && agRow.offsetTop >= (agContainer.offsetHeight - 230)) ||
      (agContainer.offsetHeight < agBody.offsetHeight
        && agRow.offsetTop > (agBody.offsetHeight - 220))) {
      this.isDropUp = true;
    }
  }

  private findParent(nativeElement: any, className: string) {
    const parentElement = _.get(nativeElement, 'offsetParent');
    if (_.isEmpty(parentElement) || _.isEmpty(parentElement.classList)) {
      throw new Error(`Can not found ${className}!`);
    }
    if (_.includes(parentElement.classList, className)) {
      return parentElement;
    }
    return this.findParent(parentElement, className);
  }
}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Body, DELETE, GET, Headers, PATCH, Path, POST, PUT, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Product } from '../component/product/model';
import { BatchFollowupRequest, CombineReservationRequest, DmpBatchResponse, Reservation } from '../component/followup/model';
import {
  AsLeadFollowUpHistoryRequest,
  AsVehicleBasicInfo,
  AsVehicleLifecycleInfo,
  BatchUpdateLeadsRequest,
  CustomerInfo,
  CustomerRemind,
  CustomerSearchRequest,
  Email,
  FollowUpHistory,
  FollowUpStrategy,
  KpiInfo,
  LeadsInOffer,
  PageableVehicle,
  SendSMSResponse,
  ServiceHistories,
  SmsStatusResponse,
  VehicleClassModel,
  VehicleInfo,
  VehicleReservationInfo,
  WriteLogRequest,
  Customer,
  Company,
  ValidCustomerRepeat,
  ValidCompanyRepeat,
  TaskMigrateModel,
} from '../model';
import { KpiType } from '../constant';
import {
  VehicleServiceMeasureMasterDataResponse
} from '../../../service-measure-and-recall/model/vehicle-service-measure-master-data.model';

export interface AsLeadCloseRequest {
  leadId: string;
  followUpComments: string;
  closeReason: string;
  closeDate: string;
  cdoAccount: string;
  channel: string;
}

export interface BatchCloseLeadsRequest {
  d8Account: string;
  leadId: Array<any>;
  followUpComments?: string;
  closeReason?: string;
  channel?: any;
  businessId?: Array<string>;
  sendTime?: Array<string>;
  dealerId: string;
}

export interface AsLeadKpiRequest {
  period: string;
  customerCategory: string;
  kpiType: KpiType;
  distributedType?: string;
  leadCreationType?: string;
  gsCode?: Array<string>;
  vehicleType?: string;
}

export interface CustomerExtension {
  customerId: string;
  preferredContactTime: any;
  preferredContactChannel: any;
}

export interface SearchRequest {
  dealer_id: string;
  full_name: string;
  mobile: string;
}

export interface ServiceItemRequest {
  name: string;
  vehicleClassModelId: Number;
}

export interface BatchCloseInfo {
  leadId: string;
  followUpComments?: string;
  closeReason?: string;
  cdoAccount?: string;
  channel?: string;
  success?: boolean;
  failedResult?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AsLeadApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @POST('/api/customers/lead/customer-extension/save')
  saveCustomerExtension(@Body customerExtension: CustomerExtension): Observable<any> {
    return null;
  }

  @POST('/api/customers/lead/customer-extension/search')
  searchCustomerExtension(@Body searchRequest: SearchRequest): Observable<CustomerExtension> {
    return null;
  }

  @GET(`/api/website/as-leads/:id`)
  getLeadById(@Path('id') id): Observable<any> {
    return null;
  }

  @PUT(`/api/website/as-leads/:id`)
  updateCustomerRemind(@Path('id') id, @Body customerRemind: CustomerRemind): Observable<any> {
    return null;
  }

  @GET(`/api/sales-website/vehicles/service-measure-master-data/:fin`)
  fetchUnfinishedServiceMeasure(@Path('fin') fin): Observable<VehicleServiceMeasureMasterDataResponse> {
    return null;
  }

  @GET(`/api/website/as-leads/vehicle-extension`)
  getVehicleExtensionByFin(@Query('fin') fin: string) {
    return null;
  }

  @GET(`/api/as-lead-vehicle/vehicle-class-model`)
  getVehicleClassModelConfig(): Observable<Array<VehicleClassModel>> {
    return null;
  }

  @POST('/api/as-lead-vehicle/search-vehicle')
  getLeadByVehicleInfo(@Body data: Object) {
    return null;
  }

  @GET(`/api/website/as-leads/vehicle`)
  getVehicleByFin(@Query('fin') fin: string): Observable<any> {
    return null;
  }

  @POST('/aftersales/website/as-leads/fetch')
  getLeads(@Body data: Object) {
    return null;
  }

  @POST('/api/as-lead-vehicle/fetch')
  getVehicles(@Body data: Object): Observable<PageableVehicle> {
    return null;
  }

  @GET('/api/as-lead-vehicle/basic-info')
  getVehicleBasicInfo(
    @Query('fin') fin: string,
    @Query('cecId') cecId?: number,
    @Query('dealerId') dealerId?: string,
  ): Observable<AsVehicleBasicInfo> {
    return null;
  }

  @POST('/api/website/as-leads/followup')
  continueToFollowUp(@Body data): Observable<any> {
    return null;
  }

  @POST('/api/as-lead-vehicle/batch-followup')
  batchFollowUp(@Body data: BatchFollowupRequest): Observable<DmpBatchResponse> {
    return null;
  }

  @GET('/api/as-lead-vehicle/follow-up-strategies')
  getFollowUpStrategies(@Query('getAll') getAll, @Query('gsCode') gsCode?): Observable<FollowUpStrategy[]> {
    return null;
  }

  @POST('/api/website/as-leads/status')
  closeLead(@Body asLeadCloseRequest: AsLeadCloseRequest): Observable<any> {
    return null;
  }

  @POST('/api/as-lead-vehicle/batch-close')
  batchCloseLeads(@Body asLeadCloseRequest: BatchCloseLeadsRequest): Observable<DmpBatchResponse> {
    return null;
  }

  @GET('/api/website/as-leads/vehicle-customer')
  getVehicleCustomerByPlateNumber(@Query('vehiclePlateNumber') vehiclePlateNumber: string): Observable<any> {
    return null;
  }

  @POST('/api/website/as-leads/sms')
  sendSMS(@Body data): Observable<SendSMSResponse> {
    return null;
  }

  @POST('/api/website/as-leads/emails')
  sendEmail(@Body data: Email): Observable<any> {
    return null;
  }

  @GET('/api/website/as-leads/template')
  @Headers({ isExportFile: true })
  downloadTemplate(@Query('cecId') cecId?: number): Observable<ArrayBuffer> {
    return null;
  }

  @GET('/api/as-cdo-vehicle/export')
  @Headers({ isExportFile: true })
  downloadCustomerVehicleFile(): Observable<ArrayBuffer> {
    return null;
  }
  // 导出空白模板
  @GET('/api/website/as-leads/comment/export-empty-template')
  @Headers({ isExportFile: true })
  downloadBlankTemplateFile(@Query('cecId') cecId?: number): Observable<ArrayBuffer> {
    return null;
  }
  // 导出当前备注
  @GET('/api/website/as-leads/comment/export-vehicle-comments')
  @Headers({ isExportFile: true })
  downloadCurrentNotesFile(@Query('dealerId') dealerId: string, @Query('cecId') cecId?: number): Observable<ArrayBuffer> {
    return null;
  }
  @POST('/api/as-lead-vehicle/kpi')
  getAsLeadKpi(@Body asLeadKpiRequest: AsLeadKpiRequest): Observable<KpiInfo> {
    return null;
  }

  @GET('/aftersales/website/as-leads/import-result/')
  asCdoLeadImportResult(@Query('importId') importId: string) {
    return null;
  }

  @GET('/aftersales/website/as-leads/import-failed-excel')
  @Headers({ isExportFile: true })
  downloadFailedIds(@Query('importId') importId: string, @Query('cecId') cecId?: number): Observable<any> {
    return null;
  }

  @GET('/aftersales/website/cdo-vehicle/download-failed-excel')
  @Headers({ isExportFile: true })
  downloadFailedVehicleFile(@Query('importId') importId: string): Observable<any> {
    return null;
  }

  @GET('/api/website/as-leads/import/template')
  @Headers({ isExportFile: true })
  cdoCRVDownloadTemplate(@Query('cecId') cecId: number): Observable<any> {
    return null;
  }

  @POST('/api/as-lead-vehicle/mb-collection')
  getMbCollection(@Body request: ServiceItemRequest): Observable<Array<Product>> {
    return null;
  }

  @POST('/api/as-lead-vehicle/service-contract')
  getServiceContract(@Body request: ServiceItemRequest): Observable<Array<Product>> {
    return null;
  }

  @POST('/api/as-lead-vehicle/spps')
  getSPPS(@Body request: ServiceItemRequest): Observable<Array<Product>> {
    return null;
  }

  @GET('/aftersales/website/as-leads/purchased-service-contacts')
  getPurchasedServiceContracts(@Query('fin') fin: string): Observable<Product[]> {
    return null;
  }

  @POST('/aftersales/website/as-leads/reservation')
  reservation(@Body reservation: Reservation): Observable<any> {
    return null;
  }

  @POST('/api/as-lead-vehicle/combine-reservation')
  combineReservation(@Body reservation: CombineReservationRequest): Observable<DmpBatchResponse> {
    return null;
  }

  @POST('/api/as-lead-vehicle/create')
  createAfterSalesLead(@Body lead): Observable<any> {
    return null;
  }

  @GET('/aftersales/website/reservations/bookable-dates')
  getReservableDates(@Query('startDate') startDate: string, @Query('endDate') endDate: string) {
    return null;
  }

  @GET('/aftersales/website/reservations/bookable-dates')
  getReservableDatesForDate(
  @Query('startDate') startDate: string,
  @Query('endDate') endDate: string,
  @Query('includeCurrentDay') includeCurrentDay: boolean) {
    return null;
  }

  @GET('/aftersales/website/reservations/bookable-timeslots')
  getReservableTimes(@Query('date') date: string, @Query('tag') tag: string) {
    return null;
  }

  @GET('/api/website/as-leads/dealer-phone-number')
  getDealerPhoneNumber(): Observable<{ phone: string }> {
    return null;
  }

  @GET('/api/website/as-leads/sms/status')
  getSmsStatus(@Query('logicId') logicId: string): Observable<SmsStatusResponse[]> {
    return null;
  }

  @DELETE('/api/as-lead-vehicle/follow-up-strategies')
  deleteStrategies(@Query('strategyIds') strategyIds: string[], @Query('gsCode') gsCode?: string[]): Observable<any> {
    return null;
  }

  @POST('/api/as-lead-vehicle/follow-up-strategy')
  saveStrategy(@Body strategy): Observable<any> {
    return null;
  }

  @GET('/api/as-lead-vehicle/websocket-token')
  getWebsocketToken(): Observable<{ token: string }> {
    return null;
  }

  @GET('/api/as-lead-vehicle/leads-offer')
  getVehicleLeadsByFin(@Query('fin') fin: string, @Query('d8Account') d8Account?: string): Observable<Map<string, LeadsInOffer>> {
    return null;
  }

  @POST('/api/as-lead-vehicle/follow-up-history')
  getFollowUpHistory(@Body data: AsLeadFollowUpHistoryRequest): Observable<FollowUpHistory> {
    return null;
  }

  @POST('/api/website/customers/search')
  getCustomerIdByFullNameAndMobile(@Body data: CustomerSearchRequest): Observable<CustomerInfo[]> {
    return null;
  }

  @GET('/api/sales-website/vehicles/reservations')
  getVehicleReservations(@Query('vehiclePlateNumber') vehiclePlateNumber: string, @Query('fin') fin: string)
    : Observable<Array<VehicleReservationInfo>> {
    return null;
  }
  @POST('/api/as-lead-vehicle/batch-update-lead')
  batchUpdateLeads(@Body data: BatchUpdateLeadsRequest): Observable<any> {
    return null;
  }

  @GET('/api/as-lead-vehicle/vehicle-life')
  getVehicleLifecycle(@Query('fin') fin: string, @Query('dealerId') dealerId: string)
    : Observable<AsVehicleLifecycleInfo> {
    return null;
  }

  @GET('/api/website/vehicles')
  getVehicleByVin(@Query('vin') vin: string): Observable<VehicleInfo> {
    return null;
  }

  @GET(`/api/website/service-histories/detail`)
  getServiceHistories(@Query('fin') fin: string, @Query('dealerId') dealerId: string): Observable<Array<ServiceHistories>> {
    return null;
  }

  @POST('/api/users/user-fullname-by-gems-ids')
  getSANamebyIds(@Body ids: any): any {
    return null;
  }

  @POST('/api/as-lead-vehicle/writeLog')
  writeLog(@Body data: WriteLogRequest): Observable<any> {
    return null;
  }

  @POST('/api/customers/lead/modify-history')
  getCustomerInfoHistory(@Body param: any): Observable<any> {
    return null;
  }

  @GET('/api/customers/web/searchCustomerByMobile')
  getCustomerData(@Query('mobile') param: string,
                  @Query('fin') fin: string,
                  @Query('dealerId') dealerId: string): Observable<any> {
    return null;
  }

  @POST('/api/companies/web/searchReplaceCompany')
  getCompnayData(@Query('company_name') param: any,
                 @Query('fin') fin: string,
                 @Query('dealerId') dealerId: string): Observable<any> {
    return null;
  }

  @POST('/api/companies/web/replaceCreateCompany')
  submitCreateCompany(@Body param: any): Observable<any> {
    return null;
  }

  @POST('/api/companies/web/replaceUpdateCompany')
  updateCompanyInfo(@Body param: any): Observable<any> {
    return null;
  }

  @POST('/api/customers/web/createCustomer')
  createCustomer (@Body data: Customer): Observable<any> {
    return null;
  }

  @POST('/api/companies/web/createCompany')
  createCompany (@Body data: Company): Observable<any> {
    return null;
  }

  @POST('/api/customers/web/modifyCustomer')
  modifyCustomer(@Body data: Customer): Observable<any> {
    return null;
  }

  @POST('/api/companies/web/modifyCompany')
  modifyCompany(@Body data: Company): Observable<any> {
    return null;
  }

  @POST('/api/customers/web/vaildateCustomerMobile')
  validCustomerRepeat(@Body data: ValidCustomerRepeat): Observable<any> {
    return null;
  }

  @POST('/api/companies/web/vaildateCompanyMobile')
  validCompanyRepeat(@Body data: ValidCompanyRepeat): Observable<any> {
    return null;
  }

  @GET('/api/customers/lead/base-info')
  getCustomerLeadInfo(@Query('fin') fin: string, @Query('dealerId') dealerId: string): Observable<any> {
    return null;
  }

  @POST('/api/customers/web/modifyCustomerVehicleRelation')
  modifyCustomerVehicleRelation(@Body data: any): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/vehicles/arrival/latest/:fin')
  getVehicleCurrentStatus(@Path('fin') fin: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/orders/dealers/:dealerId/fin/:fin')
  queryVehicleServiceOrder(@Path('dealerId') dealerId: string, @Path('fin') fin: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/orders/:orderId/dealers/:dealerId/detail')
  queryVehicleServiceOrderDetail(@Path('orderId') orderId: string, @Path('dealerId') dealerId: string): Observable<any> {
    return null;
  }

  @POST('/api/as-lead-vehicle/batch-read')
  batchFilterList(@Body data: Array<any>): Observable<any> {
    return null;
  }

  @GET('/api/aftersales/website/reservations/available-sa')
  getAvaibleSaList(@Query('bookedDay') bookedDate: string,
                   @Query('startTime') time: string): Observable<any> {
    return null;
  }

  @GET('/api/aftersales/website/reservations/available-time')
  getAvaiableTime(@Query('bookedDay') bookedDate: string,
                  @Query('userGemsId') sa: string,
                  @Query('tag') tag: string): Observable<any> {
    return null;
  }

  @GET('/api/website/cdo-vehicle/v2/download-failed-excel')
  @Headers({ isExportFile: true })
  downloadFailureDataList(@Query('fileName') fileName): Observable<ArrayBuffer> {
    return null;
  }

  @GET('/api/website/as-leads/cdo-vehicle')
  getImportReportStatusFromDMP(): Observable<any> {
    return null;
  }

  @PATCH(`/api/sales-website/vehicles/vehicle-info/:fin`)
  editVehiclePlateNumberByFin(@Path('fin') fin: string,
                              @Body params): Observable<any> {
    return null;
  }

  @GET('/api/cec/dealer-branch-ascdo-user-in-cec/:cecId')
  getDealerBranchAscdoUserInCec(@Path('cecId') cecId: number): Observable<any> {
    return null;
  }

  @GET('/api/customer-tasks/:customerId/opened-task')
  getTaskListByCustomerId(@Path('customerId')customerId: string, @Query('fin') fin: string): Observable<TaskMigrateModel[]> {
    return null;
  }

  @POST('/api/customer-tasks/migrate')
  taskMigrate(@Body data: any): Observable<any> {
    return null;
  }

  @GET('/api/cec/dealer-branch-in-cec/:cecId')
  getDealerBranchInCec(@Path('cecId') cecId: number): Observable<any> {
    return null;
  }

  @GET('/api/customer-tasks/users')
  getTaskOwnerList(): Observable<any> {
    return null;
  }

  @POST('/api/customer-tasks/tasks-reassign')
  updateTaskOwner(@Body data: any): Observable<any> {
    return null;
  }

  @GET('/api/as-lead-vehicle/vehicle-sync/info')
  getSynchronizedLead(@Query('fin') fin: string,
                      @Query('dealerId') dealerId: string,
                      @Query('cecId') cecId: number): Observable<any> {
    return null;
  }

  @POST('/api/as-lead-vehicle/vehicle-sync/batch-followup')
  synchronizedLead(@Body lead: any): Observable<any> {
    return null;
  }

  @GET('/api/customer-tasks/summary/type')
  getCustomerTasksReport(@Query('startTime') startTime: string,
                         @Query('endTime') endTime: string,
                         @Query('parentTaskTypes') parentTaskTypes: Array<any>,
                         @Query('taskTypes') taskTypes: Array<any>): Observable<any> {
    return null;
  }

  @GET('/api/customer-tasks/user/task-list')
  getCustomerOwnerReport(@Query('startDate') startDate: string,
                         @Query('endDate') endDate: string,
                         @Query('dealerId') dealerId: string,
                         @Query('userIds') userIds: Array<any>): Observable<any> {
    return null;
  }

  @GET('/api/users/users/sort')
  getCustomerTaskOwnerList(): Observable<any> {
    return null;
  }

  @POST('/api/cti-call-history/list')
  getCustomerCallHistory(@Body param: any): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/leads/download')
  @Headers({ isExportFile: true })
  exportCallHistoryRecord(@Body param: any): Observable<ArrayBuffer> {
    return null;
  }

  @GET('/api/sales-website/lpo-loyalty/detail')
  fetchLPOList(@Query('fin') fin: string,
               @Query('dealerId') dealerId: string,
               @Query('fetchFromLpo') fetchFromLpo: boolean): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/mmc/available-products')
  getAvailableProductByFinAndMobile(
    @Query('vin') vin: string,
    @Query('mobile') mobile: string,
    @Query('fin') fin: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/inspection/:serviceRecordId/tyre-info')
  getTyreInfoByServiceRecordId(@Path('serviceRecordId')serviceRecordId: string): Observable<any> {
    return null;
  }

  @GET('/api/aftersales/file/media/:fileId')
  @Headers({ isExportFile: true })
  getTyreImageFile(@Path('fileId') fileId: string): Observable<any> {
    return null;
  }

  @GET(`/api/aftersales/file/media/sas-uri:download?fileType=:fileType&fileNames=:fileNames`)
  getTyreVideoFile(@Path('fileType') fileType: string,
                   @Path('fileNames') fileNames: string): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/inspection/tyre-info')
  updateTyreInfo(
    @Body data: any,
    @Query('serviceRecordId') serviceRecordId: string,
    @Query('fin') fin: string
  ): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/all-staff/products')
  queryProductList(@Body object: object,
                   @Query('fetchFromLpo') fetchFromLpo: boolean,
                   @Query('fetchPurchased') fetchPurchased: boolean,
                   @Query('productType') productType: string): Observable<any> {
    return null;
  }
}

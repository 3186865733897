import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  template: `
    <div>
      <span *ngIf="isReadOnly">{{value}}</span>
      <a *ngIf="!isReadOnly" [routerLink]="linkPartials">{{value}}</a>
    </div>
  `,
  styles: [`
    .cesar-warning {
      text-align: center;
    }
  `],
})
export class CommissionNumberCellRenderComponent implements ICellRendererAngularComp {
  value: string;
  linkPartials: Array<string>;
  isReadOnly: boolean;

  agInit({ value }): void {
    this.value = value.value;
    this.linkPartials = value.linkPartials;
    this.isReadOnly = value.isReadOnly;
  }

  refresh(params: any): boolean {
    return false;
  }
}

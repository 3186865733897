
    <div #container class='text-ellipsis-tooltip-container'>
      <div
        class='text'
        nz-tooltip
        [nzPlacement]="placement"
        [nzTitle]='wrap'
        nzOverlayClassName='overlayStyle'
      >{{ ellipsisText }}</div>
      <ng-template #wrap>
        <div [innerHTML]="wrapText | safeHtml"></div>
      </ng-template>
    </div>
  
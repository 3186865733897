import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  template: `
    <div *ngIf="ifShow">
      <div *ngIf="!isShowDealer" (click)="onView()">
        <i class="fa antd-eye-outlined"></i>
        <span class="operated-text">查看已指定经销商</span>
      </div>
      <div *ngIf="isShowDealer" (click)="onUpdate()">
        <i class="fa antd-form-outlined edit-icon"></i>
        <span class="operated-text">指定经销商</span>
      </div>
    </div>
  `,
  styleUrls: ['./product-operation-cell.style.scss'],
})
export class ProductOperationCellComponent implements ICellRendererAngularComp {
  value: any;
  params: ICellRendererParams;
  isShowDealer: boolean;
  ifShow: boolean;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.params = params;
    this.ifShow = (params.data.packageType === 'subNode' || params.data.deprecated === true) ? false : true;
    this.isShowDealer = _.isEmpty(this.value);
  }

  refresh(params: any): boolean {
    return false;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onView() {
    this.cellRendererParams.onView(this.params.node.data);
  }

  onUpdate() {
    this.cellRendererParams.onUpdate(this.params.node.data);
  }
}

<section class="customize-modal">
  <div style="height: 100%;" (click)="cancel()"></div>
  <app-time-picker appAutoLocation
                   [style.left.px]="left"
                   [style.top.px]="top"
                   [title]="title"
                   [minTime]="minTime"
                   [maxTime]="maxTime"
                   [showSeconds]="showSeconds"
                   [(ngModel)]="initialTime"
                   [errorMessages]="errorMessages"
                   (onSave)="save($event)"
                   (onCancel)="cancel()"
                   #timePickerModal></app-time-picker>
</section>

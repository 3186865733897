import { Subject } from 'rxjs';
import * as _ from 'lodash';

// tslint:disable-next-line
export function AutoUnsubscribe(name: string = 'componentDestroy$') {
  return function(component: { new(...args): any; prototype: any }) {
    const onInit = component.prototype.ngOnInit;
    component.prototype.ngOnInit = function(...args: any[]) {
      if (!this[name]) {
        this[name] = new Subject();
      }
      (onInit || _.noop).apply(this, ...args);
    };

    const onDestroy = component.prototype.ngOnDestroy;
    component.prototype.ngOnDestroy = function(...args: any[]) {
      (onDestroy || _.noop).apply(this, ...args);
      if (this[name] && this[name].next) {
        this[name].next();
      }
      _.chain(this)
        .values()
        .filter(value => value instanceof Subject)
        .filter((value: Subject<any>) => !value.closed)
        .forEach((value: Subject<any>) => value.complete())
        .value();
    };
  };
}

import { ICellRendererParams } from 'ag-grid';
import { Component, ElementRef, HostBinding, HostListener, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICONS } from '../../../../constants/icons';
import * as _ from 'lodash';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  selector: 'app-button-text',
  templateUrl: './button.html',
  styleUrls: ['./button.style.scss'],
})
export class ButtonTextComponent implements ICellRendererAngularComp {
  text: string;
  classNames: any;
  btnWidth: any;
  width: any;
  disabled: boolean;
  showText: boolean;
  showContent: boolean = false;
  content: string;
  ellipsisAble: boolean = false;
  isDetailDisplay: boolean = true;
  isExportDisplay: boolean = true;
  showPrimaryBtn: boolean = false;

  @ViewChild('container') container: ElementRef;

  @HostListener('mouseenter')
  mouseEnter() {
    if (!this.ellipsisAble) {
      return;
    }
    const element = _.get(this.container, 'nativeElement') || '';
    if (element && element.offsetWidth > element.parentElement.offsetWidth) {
      this.container.nativeElement.setAttribute('title', this.content);
    }
  }

  @HostBinding('class') hostClassName: string;

  private params: ICellRendererParams;
  cellRendererParams: any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellRendererParams = params.colDef.cellRendererParams;
    this.showText = getValue(this.cellRendererParams.showText, params);
    this.isDetailDisplay = getValue(this.cellRendererParams.isDetailDisplay, params);
    this.isExportDisplay = getValue(this.cellRendererParams.isExportDisplay, params);
    this.classNames = getValue(this.cellRendererParams.classNames, params);
    this.showContent = getValue(this.cellRendererParams.showContent, params);
    this.disabled = getValue(this.cellRendererParams.disabled, params);
    this.btnWidth = getValue(this.cellRendererParams.btnWidth, this.params);
    this.showPrimaryBtn = getValue(this.cellRendererParams.showPrimaryBtn, params);
    if (this.btnWidth) {
      this.width = { width: `${this.btnWidth}px` };
    }
    const getContent = _.get(params.colDef, 'cellRendererParams.getContent');
    this.content = _.isFunction(getContent) ? getContent(params) : (params.valueFormatted || params.value);
    this.ellipsisAble = _.get(params.colDef, 'cellRendererParams.ellipsisAble', true);
  }

  refresh(params: any): boolean {
    return false;
  }

  onClick(event) {
    (this.cellRendererParams.onClick || _.noop)(this.params.data, event);
  }
}

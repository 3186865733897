import { Component } from '@angular/core';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid';
import { CHINESE_CALENDAR_CONFIG } from '../../../../../constants/calendar';
import { IFilterAngularComp } from 'ag-grid-angular/main';
import * as _ from 'lodash';
import { inDateRange } from '../../../../../utils/date';

@Component({
  selector: 'app-calender-range-filter-cell',
  templateUrl: 'calendar-range-filter.template.html',
  styleUrls: ['./calendar-range-filter.style.scss'],
})
export class CalenderRangeFilterComponent implements IFilterAngularComp {
  params: IFilterParams;
  filterTo: string | Date;
  filterFrom: string | Date;
  dataType: string;
  private valueGetter: (rowNode: RowNode) => any;
  debouncedChange: Function = _.debounce(() => this.onChange(), 100);

  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;

  agInit(params: any) {
    this.params = params;
    this.dataType = params.dataType || 'string';
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return !_.isEmpty(this.filterFrom) || !_.isEmpty(this.filterTo);
  }

  onChange(): void {
    this.params.filterChangedCallback();
  }

  getModel(): any {
    if (this.filterFrom || this.filterTo) {
      return {
        filterFrom: this.filterFrom,
        filterTo: this.filterTo,
      };
    }
    return;
  }

  setModel(model: any): void {
    this.filterFrom = _.get(model, 'filterFrom', undefined);
    this.filterTo = _.get(model, 'filterTo', undefined);
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const dates = this.valueGetter(params.node) || [];
    return _.some(dates, date => inDateRange(this.filterFrom, this.filterTo, date));
  }

  stopPropagation() {
    event.stopPropagation();
  }
}

import { Injectable } from '@angular/core';
import { GET, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class QuotationApi extends RebirthHttp {
  static QUOTATION_API_PREFIX: string = '/api/sales-website/quotations';
  static MOD_QUOTATION_API_PREFIX: string = '/api/sales-website/mod-order/quotations';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET(`${QuotationApi.QUOTATION_API_PREFIX}`)
  getQuotationsByLeadId(@Query('lead_id') id: string): Observable<any> {
    return null;
  }

  @GET(`${QuotationApi.MOD_QUOTATION_API_PREFIX}`)
  getQuotationsByLeadIdMod(@Query('lead_id') id: string): Observable<any> {
    return null;
  }
}

import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { SwitchTabComponent } from './switch-tab/switch-tab.component';

@Component({
  selector: 'app-switch-tabset',
  templateUrl: './switch-tabset.component.html',
  styleUrls: ['./switch-tabset.component.scss']
})
export class SwitchTabsetComponent implements AfterContentInit {
  @ContentChildren(SwitchTabComponent) tabs: QueryList<SwitchTabComponent>;

  ngAfterContentInit(): void {
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(currentTab: SwitchTabComponent): void {
    this.tabs.toArray().forEach(tab => tab.active = false);
    currentTab.active = true;
  }

}

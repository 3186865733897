import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd';
import { LabelTextComponent } from './components/label-text/label-text.component';
import { QuestionIconTooltipComponent } from './components/question-icon-tooltip/question-icon-tooltip.component';
import { LabeledComponent } from './components/labeled/labeled.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { MousedownOutsideDirective } from './directives/mousedown-outside.directive';
import { NumberFormatDirective } from './directives/numberFormat.directive';
import { PIPE_PROVIDERS } from './pipes/pipes';
import { CancelAndConfirmButtonsComponent } from './components/cancel-and-confirm-buttons/cancel-and-confirm-buttons.compontent';
import { ButtonsComponent } from './components/button/buttons.component';
import { CheckboxPopupComponent } from './components/checkbox-popup/checkbox-popup.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { ConfirmModalComponent } from './components/confirm/confirm-modal.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DownloadComponent } from './components/download/download.components';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownButtonComponent } from './components/dropdown-button/dropdown-button.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { DealerNodeComponent } from './components/mind-map-nodes/dealer-node/dealer-node.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalFrameworkComponent } from './components/modal/modal-framework/modal-framework.component';
import { ConfirmServiceModalComponent } from './components/modal/services/confirm-service-modal.component';
import { TimePickerServiceModalComponent } from './components/modal/services/time-picker-service-modal.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { MultipleDropdownComponent } from './components/multiple-dropdown/multiple-dropdown.component';
import { PromptBarComponent } from './components/prompt-bar/prompt-bar.component';
import { RadioComponent } from './components/radio/radio.component';
import { RadioDropdownComponent } from './components/radio-dropdown/radio-dropdown.component';
import { RegionSelectComponent } from './components/region-select/region-select.component';
import { RowPanelComponent } from './components/row-panel/row-panel.component';
import { ColumnCellsComponent } from './components/row-panel/column-cells';
import { SearchCheckboxComponent } from './components/search-checkbox/search-checkbox.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { SectionTabsComponent } from './components/section-tabs/section-tabs.component';
import { SimpleTabComponent } from './components/simple-tab/tab.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SwitchComponent } from './components/switch/switch.component';
import { SwitchTabsComponent } from './components/switch-tabs/switch-tabs.component';
import { TableComponent } from './components/table/table.component';
import { TableTemplateDirective } from './components/table/tableTemplate.component';
import { DummyRowsComponent } from './components/table/dummy-rows.component';
import { ColumnComponent } from './components/table/column.component';
import { TableFixedHeadComponent } from './components/table/table-fixed-head/table-fixed-head.component';
import { TableMainBodyComponent } from './components/table/table-main-body/table-main-body.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { TitleAppendedDashComponent } from './components/title/title-appended-dash.component';
import { TitleWithCollapseComponent } from './components/title/title-with-collapse/title-with-collapse.component';
import { CommonTitleComponent } from './components/title/common-title/common-title.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { UploadFormComponent } from './components/upload-form/upload-form.component';
import { UploadRequireFormComponent } from './components/upload-require-form/upload-require-form.component';
import { DIRECTIVE } from './directives/directives';
import { BsDropdownModule, CollapseModule, ModalModule, TooltipModule, PaginationModule } from 'ngx-bootstrap';
import { CalendarModule } from 'primeng/components/calendar/calendar';
import { DataTableModule } from 'primeng/components/datatable/datatable';
import { ChartModule } from 'primeng/components/chart/chart';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableTemplateLoaderComponent } from './components/table/tableTemplateLoader.component';
import { CollapsibleSectionComponent } from './components/collapsible-section/collapsible-section.component';
import { ProcessBarComponent } from './components/processBar/process-bar.component';
import { CollapsibleDetailComponent } from './components/collapsible-detail/collapsible-detail.component';
import { DateComponent } from './components/date/date.component';
import { VirtualScrollDropdownListComponent } from './components/virtual-scroll-dropdown-list/virtual-scroll-dropdown-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { TemplateConfigComponent } from '../system-config/template-config/template-config.component';
import { ListSelectComponent } from './components/list-select/list-select.component';
import { LayoutModule } from '../_layout/layout.module';
import { DragToUploadComponent } from './components/drag-to-upload/drag-to-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { IconFirstCollapseComponent } from './components/collapse-icon-first/icon-first-collapse.component';
import { CollapseTagsComponent } from './components/collapse-tags/collapse-tags.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { VehicleBusinessTypeSwitchTabComponent } from './components/vehicle-business-type-switch-tab/vehicle-business-type-switch-tab.component';
import { VehicleBusinessTypeFilterComponent } from './components/vehicle-business-type-filter/vehicle-business-type-filter.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DraggableModalFrameworkComponent } from './components/modal/draggable-modal-framework/draggable-modal-framework.component';
import { TableZorroComponent } from './components/table-zorro/table-zorro.component';
import { WebsiteCommonModule } from '@otr/website-common';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { DragToUploadWithTipComponent } from './components/drag-to-upload-with-tip/drag-to-upload-with-tip.component';
import { ConfirmWithIconModalComponent } from './components/confirm-with-icon/confirm-with-icon-modal.component';
import { ChartWithRateComponent } from './components/chart-with-usage/chart-with-usage';
import { TabComponent } from './components/tab/tab.component';
import {
  VirtualScrollMultiSelectDropdownListComponent
} from './components/virtual-scroll-multi-select-dropdown-list/virtual-scroll-multi-select-dropdown-list.component';
import { ShowStatusComponent } from './components/show-status/show-status.component';
import { ImageListComponent } from './components/image-list/image-list.component';
import { ImagePreviewerComponent } from './components/image-previewer/image-previewer.component';
import { OtrNgComponentsModule } from '@otr/ng-components';
import { DrawerComponent } from './components/drawer/drawer.component';
import { ImageZoomComponent } from './components/image-zoom/image-zoom.component';
import { ImageZoomModule } from 'angular2-image-zoom';
import { SwitchTabComponent } from './components/switch-tabset/switch-tab/switch-tab.component';
import { SwitchTabsetComponent } from './components/switch-tabset/switch-tabset.component';
import { TextWithTooltipComponent } from './components/text-with-tooltip/text-with-tooltip.component';
import { PDFDisplayComponent } from './components/pdf-display/paf-display.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EmptyImageComponent } from './components/image-list/empty-image.component';
import { DealersDropdownSelectComponent } from 'app/accessory-discount/components/dealers-select-dropdown/dealers-select-dropdown.component';
import { FileFormComponent } from './components/file-form/file-form.component';
import { CustomCheckboxRenderComponent } from './components/custom-checkbox/custom-checkbox.component';
import { VehicleCascaderSelectComponent } from './components/vehicle-select/vehicle-cascader-select.component';
import { EncryptedButtonComponent } from './components/encrypted-button/encrypted-button.component';
import { MapComponent } from './components/baidu-map/baidu-map.component';
import { LOADING_STATE, OtrScriptLoader } from './components/baidu-map/service/scriptLoader';
import { BMap } from './components/baidu-map/models/Map';
import { RecursiveMenuComponent } from './components/recursive-menu/recursive-menu.component';

const components = [
  CancelAndConfirmButtonsComponent,
  ButtonsComponent,
  ImageListComponent,
  EmptyImageComponent,
  CheckboxPopupComponent,
  ClickOutsideDirective,
  MousedownOutsideDirective,
  NumberFormatDirective,
  CollapseComponent,
  IconFirstCollapseComponent,
  ConfirmModalComponent,
  DateRangeComponent,
  DateComponent,
  FileFormComponent,
  DateTimePickerComponent,
  DownloadComponent,
  DropdownComponent,
  DropdownButtonComponent,
  IconButtonComponent,
  LabelTextComponent,
  ShowStatusComponent,
  LabeledComponent,
  DealerNodeComponent,
  ModalComponent,
  ModalFrameworkComponent,
  ConfirmServiceModalComponent,
  TimePickerServiceModalComponent,
  MultiSelectDropdownComponent,
  MultipleDropdownComponent,
  PromptBarComponent,
  QuestionIconTooltipComponent,
  RadioComponent,
  RadioDropdownComponent,
  RegionSelectComponent,
  RowPanelComponent,
  ColumnCellsComponent,
  SearchCheckboxComponent,
  InputSearchComponent,
  SectionTabsComponent,
  SimpleTabComponent,
  SpinnerComponent,
  SwitchComponent,
  SwitchTabsComponent,
  TableComponent,
  TableTemplateDirective,
  DummyRowsComponent,
  ColumnComponent,
  TableFixedHeadComponent,
  TableMainBodyComponent,
  TimePickerComponent,
  TitleAppendedDashComponent,
  CommonTitleComponent,
  TooltipComponent,
  TableTemplateLoaderComponent,
  UploadFormComponent,
  UploadRequireFormComponent,
  CollapsibleSectionComponent,
  ProcessBarComponent,
  CollapsibleDetailComponent,
  TitleWithCollapseComponent,
  VirtualScrollDropdownListComponent,
  VirtualScrollMultiSelectDropdownListComponent,
  TemplateConfigComponent,
  ListSelectComponent,
  ...PIPE_PROVIDERS,
  ...DIRECTIVE,
  DragToUploadComponent,
  DragToUploadWithTipComponent,
  CollapseTagsComponent,
  RadioGroupComponent,
  RadioButtonComponent,
  VehicleBusinessTypeSwitchTabComponent,
  VehicleBusinessTypeFilterComponent,
  DraggableModalFrameworkComponent,
  TableZorroComponent,
  AudioPlayerComponent,
  ConfirmWithIconModalComponent,
  ChartWithRateComponent,
  TabComponent,
  ImagePreviewerComponent,
  DrawerComponent,
  ImageZoomComponent,
  SwitchTabComponent,
  SwitchTabsetComponent,
  TextWithTooltipComponent,
  PDFDisplayComponent,
  DealersDropdownSelectComponent,
  CustomCheckboxRenderComponent,
  VehicleCascaderSelectComponent,
  EncryptedButtonComponent,
  MapComponent,
  RecursiveMenuComponent
];

@NgModule({
  imports: [
    CommonModule,
    CollapseModule,
    ModalModule,
    TooltipModule,
    ReactiveFormsModule,
    BsDropdownModule,
    FormsModule,
    DataTableModule,
    CalendarModule,
    ScrollingModule,
    LayoutModule,
    ChartModule,
    FileUploadModule,
    DragDropModule,
    NzTableModule,
    PaginationModule,
    WebsiteCommonModule,
    OtrNgComponentsModule,
    ImageZoomModule,
    PdfViewerModule,
  ],
  declarations: components,
  exports: components,
  providers: [...PIPE_PROVIDERS, OtrScriptLoader],
})

export class CommonComponentModule {
}

declare global {
  interface Window {
    _scriptLoadState: { [url: string]: LOADING_STATE };
    BMap: BMap;
    otrMapInit: () => void;
  }
}

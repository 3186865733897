import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FILTER_COLLAPSE_TEXT } from '../../constants/common';

@Component({
  selector: 'app-icon-first-collapse',
  templateUrl: 'icon-first-collapse.template.html',
  styleUrls: ['icon-first-collapse.style.scss'],
})
export class IconFirstCollapseComponent {
  operationText: string;

  @Input() isCollapsed: boolean = false;
  @Input() expandCollapseText = FILTER_COLLAPSE_TEXT;
  @Input() canCollapsed: boolean = true;
  @Output() onChange = new EventEmitter();

  collapsed(): void {
    this.onChange.emit();
    this.operationText = this.expandCollapseText.COLLAPSED;
  }

  expanded(): void {
    this.onChange.emit();
    this.operationText = this.expandCollapseText.EXPANDED;
  }

  setIsCollapsed(value): void {
    this.isCollapsed = value;
  }
}

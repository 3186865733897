import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';
import { IAfterGuiAttachedParams, IFilterParams } from 'ag-grid';
import { FormControl } from '@angular/forms';
import { CHINESE_CALENDAR_CONFIG } from '../../../../../constants/calendar';
import { isEmptyValue } from '../../../../../utils/common';
import { dateRegularParse } from '../../../../../utils/date-parse';

@Component({
  selector: 'app-pagination-range-filter-cell',
  templateUrl: 'pagination-calendar-minute-range.template.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationCalendarMinuteRangeComponent extends BasePaginationFilterComponent {
  startDateControl: FormControl = new FormControl('');
  endDateControl: FormControl = new FormControl('');
  savedStartValue: string = '';
  savedEndValue: string = '';
  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;
  showMinute: boolean = true;

  agInit(params: IFilterParams): void {
    super.agInit(params);
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    super.afterGuiAttached(params);
    this.startDateControl.setValue(this.savedStartValue);
    this.endDateControl.setValue(this.savedEndValue);
  }

  onSubmit() {
    super.hideFilterMenu();
    this.savedStartValue = this.startDateControl.value;
    this.savedEndValue = this.endDateControl.value;
    this.params.filterChangedCallback();
  }

  isFilterActive(): boolean {
    return !this.isEmptyFilter();
  }

  onCancel() {
    super.hideFilterMenu();
    this.startDateControl.setValue(this.savedStartValue);
    this.endDateControl.setValue(this.savedEndValue);
  }

  onCalendarBlur() {
    this.endDateControl.updateValueAndValidity();
    this.startDateControl.updateValueAndValidity();
  }

  public setDefaultValue() {
    super.setDefaultValue();
    this.startDateControl.setValue('');
    this.endDateControl.setValue('');
    this.savedStartValue = this.startDateControl.value;
    this.savedEndValue = this.endDateControl.value;
  }

  get filterValue() {
    const startTime = `${this.savedStartValue}:00`;
    const endTime = `${this.savedEndValue}:59`;
    return {
      from: isEmptyValue(this.savedStartValue) ? null : new Date(startTime).getTime() / 1000,
      to: isEmptyValue(this.savedEndValue) ? null : new Date(endTime).getTime() / 1000,
    };
  }

  isEmptyFilter() {
    return isEmptyValue(this.savedStartValue) && isEmptyValue(this.savedEndValue);
  }

  setModel(model: any): void {
    this.savedStartValue = dateRegularParse(_.get(model, 'from', ''));
    this.savedEndValue = dateRegularParse(_.get(model, 'to', ''));
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    const startTime = `${this.savedStartValue}:00`;
    const endTime = `${this.savedEndValue}:59`;
    const model = {} as { from?: number; to?: number; };
    if (this.savedStartValue) {
      model.from = new Date(startTime).getTime() / 1000;
    }
    if (this.savedEndValue) {
      model.to = new Date(endTime).getTime() / 1000;
    }
    return this.modelFormatter(model);
  }

  isFilterValid() {
    const startTime = `${this.savedStartValue}:00`;
    const endTime = `${this.savedEndValue}:59`;
    return isEmptyValue(this.savedStartValue) || isEmptyValue(this.savedEndValue)
      || new Date(startTime).getTime() / 1000 <= new Date(endTime).getTime() / 1000;
  }

  stopPropagation() {
    event.stopPropagation();
  }
}

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  template: `
    <img *ngIf="isEnd" src='../../../../../../assets/workshop/edit.svg' class="biddingIcon" [routerLink]="['/buyback-bidding-auction/detail/',params.data.id]"/>
    <img *ngIf="isDraft" src="../../../../../../assets/workshop/complete-edit.svg" class="biddingIcon" (click)="isDraftClick()"/>
    <img *ngIf="isDraft" src="../../../../../../assets/common/delete-blue.svg" class="waitBiddingIcon" (click)="isDeleteClick()"/>
    <img *ngIf="isProcess || isNotStart" style='cursor: pointer' src="../../../../../../assets/workshop/Function.svg" [routerLink]="['/buyback-bidding-auction/detail/',params.data.id]"/>
  `,
  styleUrls: ['./image-button.style.scss'],
})
export class ImageButtonComponent implements AgRendererComponent {
  params;
  isEnd: boolean = false;
  isDraft: boolean = false;
  isProcess: boolean = false;
  isNotStart: boolean = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const cellRendererParams = params.colDef.cellRendererParams;
    this.isEnd = getValue(cellRendererParams.isEnd, params);
    this.isDraft = getValue(cellRendererParams.isDraft, params);
    this.isProcess = getValue(cellRendererParams.isProcess, params);
    this.isNotStart = getValue(cellRendererParams.isNotStart, params);
  }

  isDraftClick() {
    'isDraftClick' in this.params.column.colDef.cellRendererParams ?
      this.params.column.colDef.cellRendererParams.isDraftClick(this.params)
      :
      ((): void => {})();
  }
  isDeleteClick() {
    'isDeleteClick' in this.params.column.colDef.cellRendererParams ?
      this.params.column.colDef.cellRendererParams.isDeleteClick(this.params)
      :
      ((): void => {})();
  }

  refresh(params: any): boolean {
    return false;
  }
}

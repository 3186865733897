import { Injectable } from '@angular/core';
import { Body, DELETE, GET, Headers, PATCH, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AsOrderApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET(`/api/website/orders/:order-id`)
  getOrderInfo(@Path('order-id') orderId: string, @Query('dealerId') dealerId: string): Observable<any> {
    return null;
  }

  // @GET('/api/website/orders')
  // getOrdersByVins(@Query('vins') vins: string): Observable<any> {
  //   return null;
  // }

  @POST('/api/website/orders/warranty/save-star-d')
  saveDocumentsWithOrder(@Body data: Observable<any>) {
    return null;
  }

  @GET('/api/website/orders/:order-id/order-document-item')
  getDocumentsListWithOrder(
    @Path('order-id') orderId: string,
    @Query('dealerId') dealerId: string,
    @Query('pageNumber') pageNumber: number,
    @Query('pageSize') pageSize: number): Observable<any> {
    return null;
  }

  @GET('/api/website/warranty/:order-id/query-order-document-items')
  getWarrantyDocumentsListWithOrder(
    @Path('order-id') orderId: string,
    @Query('docsTypes') docsTypes: Array<string>,
    @Query('pageNumber') pageNumber: number,
    @Query('pageSize') pageSize: number,
    @Query('dealerId') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/website/orders/warranty/star-d-upload-filename-rules')
  getStarDUploadFileNameRules() {
    return null;
  }

  @GET('/api/sales-website/aftersales/service-types')
  getServiceType(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftersales/orders/:dealerId/list')
  getOrdersByVinsAndDealerId(@Query('vins') vins: string, @Path('dealerId') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/website/orders/:order-id/pre-inspection-media-library')
  getMediaFileList(
    @Path('order-id') orderId: string,
    @Query('dealerId') dealerId: string,
    @Query('pageNumber') pageNumber: number,
    @Query('pageSize') pageSize: number): Observable<any> {
    return null;
  }

  @GET('/api/website/warranty-media/orders/:orderId')
  getMediaFileListForBA(
    @Path('orderId') orderId: string,
    @Query('pageNumber') pageNumber: number,
    @Query('pageSize') pageSize: number): Observable<any> {
    return null;
  }

  @GET('/api/website/dealers/vega-mapped-dealers')
  getAllDealerInfo(): Observable<any> {
    return null;
  }

  @DELETE('/api/website/orders/:order-id/delete-order-document')
  deleteOrderDocument(
    @Path('order-id') orderId: string,
    @Query('itemId') itemId: string): Observable<any> {
    return null;
  }

  @GET('/api/website/orders/warranty/order-document-history')
  getOrderListByFilter(
    @Query('pageNumber') pageNumber: number,
    @Query('pageSize') pageSize: number,
    @Query('filter') filter: string): Observable<any> {
    return null;
  }

  @GET('/api/website/orders')
  getNoClaimNumberListByFilter(
    @Query('claimSubmitted') claimSubmitted,
    @Query('pageNumber') pageNumber: number,
    @Query('pageSize') pageSize: number,
    @Query('dealerId') dealerId: string,
    @Query('filter') filter: string): Observable<any> {
    return null;
  }

  @POST('/api/aftersales/zuul/file/file/warranty/star-d/upload')
  @Headers({ 'No-Content-Type': 1 })
  saveStarDFile(@Body data: FormData): Observable<any> {
    return null;
  }

  @POST('/api/aftersales/zuul/file/file/warranty/other-type/upload')
  @Headers({ 'No-Content-Type': 1 })
  saveOtherFile(@Body data: FormData): Observable<any> {
    return null;
  }

  @POST('/api/website/warranty/parse-claim-number-excel')
  @Headers({ 'No-Content-Type': 1 })
  uploadOrderExcel(@Body data: FormData): Observable<any> {
    return null;
  }

  @PATCH('/api/website/orders/:order-id')
  @Headers({ 'X-OTR-AS-COMMAND': 'updateClaimNumber' })
  saveOrderClaimNumber(
    @Path('order-id') orderId: string,
    @Query('claimNumber') newClaimNumber: number): Observable<any> {
    return null;
  }

  @POST('/api/website/warranty-document/zip-tasks')
  zipByClaimNumbers(
    @Query('dealerId') dealerId: string,
    @Query('vegaCode') vegaCode: string,
    @Body claimNumbers: Array<string>): Observable<any> {
    return null;
  }

  @GET('/api/website/warranty-document/zip-tasks')
  getZipTaskStatus(@Query('ids') zipTaskIds: string): Observable<any> {
    return null;
  }

  @DELETE('/api/website/warranty-document/zip-tasks')
  terminateZipTask(@Query('ids') zipTaskIds: string): Observable<any> {
    return null;
  }

  @GET('/api/website/warranty-document/dealers/:dealerId/orders/download-validation')
  validateOrderExceedLimit(@Path('dealerId') dealerId: string,
                           @Query('claimNumbers') claimNumbers: string[]) {
    return null;
  }

  @POST('/api/website/warranty/other/save')
  uploadFileType(@Query('orderDealerId') dealerId: string,
                 @Body data) {
    return null;
  }

  @POST('/api/website/warranty/otr/save')
  uploadOTRFile(@Query('orderDealerId') dealerId: string,
                @Query('overWrite') overwrite: boolean,
                @Body data) {
    return null;
  }

  @POST('/api/aftersales/file/media')
  @Headers({ 'No-Content-Type': 1 })
  uploadMediaFile(@Body data: FormData): Observable<any> {
    return null;
  }

  @POST('/api/website/orders/:order-id/pre-inspection-media-library')
  uploadFileDescription(
    @Path('order-id') orderId: string,
    @Body data): Observable<any> {
    return null;
  }

  @PATCH('/api/website/orders/:order-id/pre-inspection-media-library')
  updateFileDescription(
    @Path('order-id') orderId: string,
    @Body data): Observable<any> {
    return null;
  }

  @DELETE('/api/website/orders/:order-id/pre-inspection-media-library/:id')
  deleteMediaFile(
    @Path('order-id') orderId: string,
    @Path('id') id: string): Observable<any> {
    return null;
  }

  @GET('/api/website/warranty/vehicle-invoice-document-items')
  getOrderVehicleInvoiceFile(@Query('orderNumber') orderNumber: string): Observable<any> {
    return null;
  }
}

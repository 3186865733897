import * as _ from 'lodash';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  template: `
    <div
      *ngIf="shouldRender"
      nz-tooltip
      nzPlacement="bottom"
      [nzTitle]="message"
      nzOverlayClassName="tooltip-container"
    >
      <span [innerHTML]="content | safeHtml"></span>
    </div>
  `,
  styleUrls: ['./grid-tooltip.style.scss'],
})
export class GridTooltipComponent implements ICellRendererAngularComp {
  content: string;
  message: string;
  shouldRender: any;

  agInit(params: ICellRendererParams): void {
    this.content = _.get(params.colDef, 'cellRendererParams.content', '');
    this.message = _.get(params.colDef, 'cellRendererParams.message', '');
    const renderFunction = _.get(params.colDef, 'cellRendererParams.shouldRender');
    this.shouldRender = _.isNil(renderFunction) ? true : renderFunction.apply(null, [params]);
  }

  refresh(params: any): boolean {
    return false;
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

const getValue = (value, params) =>
  _.isFunction(value) ? value.call(null, params) : value;

@Component({
  template: `
    <div (click)="onClick()">
      <span class="clickable-text">{{text}}</span>
    </div>
  `,
  styleUrls: ['custom-clickable-text-cell.component.scss'],
})
export class CustomClickableTextCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  text: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.text = getValue(this.cellRendererParams.text, params) || '';
  }

  refresh(params: any): boolean {
    return false;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onClick() {
    this.cellRendererParams.onClick(this.params.node.data);
  }
}

import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid';
import * as _ from 'lodash';

const getValue = (value, params) =>
  _.isFunction(value) ? value.call(null, params) : value;

@Component({
  template: `
    <div *ngIf="!disabled" class="cell-content-box" (click)="onClick()">
      <span *ngIf="svgPath" class="icon-suffix"><img [src]="svgPath"/></span><span *ngIf="text" class="clickable-text">{{text}}</span>
    </div>
    <div *ngIf="disabled">
      <span class="disabled-text">{{disabledText}}</span>
    </div>
  `,
  styleUrls: ['custom-clickable-icon-text-cell.style.scss'],
})

export class CustomClickableIconTextCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  text: string;
  disabledText: string;
  disabled: boolean;
  svgPath: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.disabled = getValue(this.cellRendererParams.isDisable, params) || false;
    this.text = getValue(this.cellRendererParams.text, params) || '';
    this.disabledText = getValue(this.cellRendererParams.disabledText, params) || this.text;
    this.svgPath = getValue(this.cellRendererParams.svgPath, params) || '';
  }

  refresh(params: any): boolean {
    return false;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onClick() {
    this.cellRendererParams.onClick(this.params.node.data);
  }
}

import { Component, ViewChild, ElementRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FormControl, Validators } from '@angular/forms';
import { ICellEditorParams } from 'ag-grid';

@Component({
  selector: 'app-stock-check-input-quantity-cell',
  templateUrl: './stock-check-input-quantity-cell.component.html',
  styleUrls: ['./stock-check-input-quantity-cell.component.scss'],
})
export class StockCheckInputQuantityCellRenderComponent implements ICellEditorAngularComp {

  @ViewChild('inputElement') inputElement: ElementRef;

  params;
  id;
  inputQuantity;
  isEdit: boolean = true;
  hasVariance: boolean = false;
  status;
  toFocus;
  index;
  QUANTITY_REGEX = '^([0-9]|[1-9][0-9]*)(\\.\\d{1,3})?$';
  quantityForm: FormControl = new FormControl('', [Validators.required, Validators.pattern(this.QUANTITY_REGEX)]);
  errorMessage = '输入格式错误';

  agInit(params: ICellEditorParams): void {
    this.params = params;
    const data = params.node.data;
    this.id = data.id;
    this.inputQuantity = data.inputQuantity;
    this.hasVariance = 'Y' === data.hasVariance;
    this.status = data.status;
    this.toFocus = data.toFocus;
    this.index = data.index;
    if (this.inputQuantity || this.inputQuantity === 0) {
      this.isEdit = false;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onEdit() {
    this.quantityForm.setValue(this.inputQuantity);
    this.isEdit = true;
  }

  onEnter() {
    if (this.quantityForm.invalid) {
      return;
    }
    this.params.onEnter(this.getIdAndInputQuantity);
  }

  onBlur() {
    if (this.quantityForm.invalid) {
      return;
    }
    this.params.onBlur(this.getIdAndInputQuantity);
  }

  onClick() {
    this.params.onClick(this.index);
  }

  getValue() {
    return;
  }

  get getIdAndInputQuantity() {
    return {
      id: this.id,
      inputQuantity: this.quantityForm.value,
      index: this.index,
    };
  }

  afterGuiAttached?(params?) {
    if (this.toFocus) {
      this.inputElement.nativeElement.focus();
    }
  }

  onKeyDownEnter(event) {
    event.stopPropagation();
  }
}

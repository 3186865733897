import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'vehicleAge',
})
export class VehicleAgePipe implements PipeTransform {

  transform(vehicleAgeString: string): string {
    if (_.isEmpty(vehicleAgeString)) {
      return '';
    }
    const vehicleAge: number = _.toNumber(vehicleAgeString.match(/\d+\.?\d*/g)[0]);
    const year: number = _.toInteger(vehicleAge);
    const month: number = _.round((vehicleAge - year) * 365 / 30);
    const yearString = _.isEqual(year, 0) ? '' : `${year}年`;
    const monthString = _.isEqual(month, 0) ? '' : `${month}个月`;
    return yearString + monthString;
  }
}

@Pipe({
  name: 'vehicleUnit',
})
export class VehicleUnitPipe implements PipeTransform {

  transform(vehicleValue: string, unit: string): string {
    if (_.isEmpty(vehicleValue)) {
      return '';
    }
    return vehicleValue + unit;
  }
}

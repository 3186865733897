import { Component } from '@angular/core';

import { ICellEditorAngularComp } from 'ag-grid-angular/main';

@Component({
  selector: 'app-delete-row',
  templateUrl: 'delete-row.template.html',
  styleUrls: ['delete-row.style.scss'],
})
export class DeleteRowComponent implements ICellEditorAngularComp {
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  handleRemoveRow() {
    this.params.api.removeItems([this.params.node]);
    this.params.column.colDef.cellEditorParams.onDelete(this.params);
  }

  getValue() {
    return this.params;
  }
}

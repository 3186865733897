import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

import { LABOUR_ITEM_STATUS } from '../../job-card-management/constant';

@Pipe({ name: 'jobCardStatus' })
export class JobCardStatusPipe implements PipeTransform {
  transform(status: string): string {
    return _.get(LABOUR_ITEM_STATUS, `${status}.description`, '');
  }
}

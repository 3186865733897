import { Component, Input, OnInit } from '@angular/core';
import { TitleSize } from 'app/_common/constants/title';

@Component({
  selector: 'app-common-title',
  templateUrl: './common-title.component.html',
  styleUrls: ['./common-title.component.scss']
})
export class CommonTitleComponent implements OnInit {
  @Input() title = '';
  @Input() size: TitleSize = TitleSize.LARGE;
  @Input() hasVerticalLine = false;
  @Input() tooltipText = '';
  className = '';

  // tslint:disable-next-line:no-empty
  constructor() {
  }

  ngOnInit(): void {
    this.className = this.size === TitleSize.LARGE ? 'large' : 'small';
  }
}

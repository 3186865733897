import { toEnum } from '../../../../../_common/utils/enum';

export const BOOLEAN_OPTIONS = toEnum({
  TRUE: { text: '是', value: true },
  FALSE: { text: '否', value: false },
});

export const VEHICLE_ACTIVENESS_OPTIONS = toEnum({
  LOYAL: { text: '忠诚', value: 'loyal' },
  ACTIVE: { text: '活跃', value: 'active' },
  SUSPECT_LOST: { text: '疑似流失', value: 'suspect lost' },
  NEARLY_LOST: { text: '准流失', value: 'nearly lost' },
  LOST: { text: '流失', value: 'lost' },
});

export const CONSUMER_GRADE_OPTION = toEnum({
  HIGH: { text: '一年内售后消费级别为高', value: 'high' },
  MEDIUM: { text: '一年内售后消费级别为中', value: 'medium' },
  LOW: { text: '一年内售后消费级别为低', value: 'low' },
});

export const MMC_TYPE_OPTION = toEnum({
  NONE: { text: '非MMC', value: 'NONE' },
  MMC_1: { text: 'MBC1.X', value: 'MMC_1' },
  MMC_2: { text: 'Mmc2.0', value: 'MMC_2' },
});

export const FINANCIAL_CONTRACT_STATUS_OPTION = toEnum({
  ZERO: { text: '金融合同即将结清', value: '0' },
  ONE: { text: '金融合同已结清', value: '1' },
});

export const VEHICLE_CLUE_TAGS = toEnum({
  PURCHASED_CONTRACT_OUR_DEALER: { text: '保养套餐本店可用', value: 'PURCHASED_CONTRACT_OUR_DEALER' },
  JOINED_TIRE_PACKAGE_IN_OUR_DEALER: { text: '轮胎套餐本店可用', value: 'JOINED_TIRE_PACKAGE_IN_OUR_DEALER' },
  JOINED_LOYALTY_PlAN_IN_OUR_DEALER: { text: '致友计划本店可用', value: 'JOINED_LOYALTY_PlAN_IN_OUR_DEALER' },
  PURCHASED_CONTRACT_IS_NOT_OUR_DEALER: { text: '保养套餐他店可用', value: 'PURCHASED_CONTRACT_IS_NOT_OUR_DEALER' },
  JOINED_TIRE_PACKAGE_IS_NOT_OUR_DEALER: { text: '轮胎套餐他店可用', value: 'JOINED_TIRE_PACKAGE_IS_NOT_OUR_DEALER' },
  JOINED_LOYALTY_PlAN_IS_NOT_OUR_DEALER: { text: '致友计划他店可用', value: 'JOINED_LOYALTY_PlAN_IS_NOT_OUR_DEALER' },
  WAITING_TO_JOIN_TIRE_PACKAGE: { text: '轮胎套餐可购买', value: 'WAITING_TO_JOIN_TIRE_PACKAGE' },
  PENDING_PURCHASE_CONTRACT: { text: '保养套餐可购买', value: 'PENDING_PURCHASE_CONTRACT' },
  WAITING_TO_JOIN_LOYALTY_PlAN: { text: '致友计划可购买', value: 'WAITING_TO_JOIN_LOYALTY_PlAN' },
});

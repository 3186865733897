import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { ColumnComponent } from './column.component';

interface RowConfig {
  getClass?(item: any): Object;
}

interface ColumnConfig {
  title: string;
  key: string | Function;
  renderer: string | Function;
  width: number;
  fixed: boolean;
}

const VISIBLE_DATA_BUFFER = 15;
const HEADER_HEIGHT = 40;
const RE_RENDER_MARGIN = 5;

@Component({
  selector: 'app-data-table',
  templateUrl: './table.template.html',
  styleUrls: ['./table.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  @Input() rows: RowConfig = {};
  @Input() data: Array<any> = [];
  @Input() columns: Array<any> = [];
  @Input() minLine: number;
  @Output() dataStartIndex = new EventEmitter();

  @ViewChild('scrollBarX') scrollBarX;
  @ViewChild('scrollBody') scrollBody;
  @ContentChildren(ColumnComponent) cols: QueryList<ColumnComponent>;

  tableColumns: ColumnConfig[] = [];
  fixedHeadTranslateStyle: Object;
  normalColumnWidth: number;
  fixedColumnsWidth: number;
  fixedColumns: ColumnConfig[];
  rowOffset: number = 0;
  cacheStartIndex: number = 0;
  rowHeight: number = 50;
  visibleDataList: Array<any> = [];
  realTableContainer: Element;
  private onWindowResize: EventListener = _.debounce(() => {
    this.updateVisibleRows();
  });

  constructor(private el: ElementRef) {
    this.fixedHeadTranslateStyle = this.getFixedHeadTranslateStyle(0);
    this.bodyScroll = _.debounce(this.bodyScroll.bind(this), 10);
    this.onXHelperScroll = _.throttle(this.onXHelperScroll.bind(this), 20);
    this.onDataStartIndexChange = _.debounce(this.onDataStartIndexChange.bind(this), 250);
  }

  private getFixedHeadTranslateStyle(translateX: number) {
    return {
      transform: `translate3D(${translateX}px,0,0)`,
      '-ms-transform': `translate3D(${translateX}px,0,0)`,
      '-webkit-transform': `translate3D(${translateX}px,0,0)`,
    };
  }

  ngOnInit() {
    window.addEventListener('resize', this.onWindowResize);
  }

  ngOnChanges() {
    this.cacheStartIndex = 0;
    this.updateVisibleRows();
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  ngAfterContentInit() {
    this.initColumns();
    this.realTableContainer = this.el.nativeElement.querySelector('div.table-y-scroll');
    this.cols.changes.subscribe(() => {
      this.initColumns();
      this.updateVisibleRows();
    });
  }

  initColumns(): void {
    this.tableColumns = _.reduce(this.cols.toArray(), (prev, col) => {
      const index = _.findIndex(prev, { key: col.key });
      if (index >= 0) {
        prev[index] = _.assign(prev[index], col);
      }
      return prev;
    }, this.columns.slice(0));
    this.normalColumnWidth = _.reduce(this.tableColumns, (pre, cur) => {
      if (cur.fixed) {
        return pre;
      }
      return pre + cur.width;
    }, 0);
    this.fixedColumns = _.filter(this.tableColumns, { fixed: true });
    this.fixedColumnsWidth = _.sumBy(this.fixedColumns, 'width');
  }

  getRowClass(item: any): Object {
    const rowClassMap = _.isFunction(this.rows.getClass) ?
      this.rows.getClass(item) : this.rows.getClass;
    return rowClassMap || {};
  }

  getEmptyLine() {
    if (!this.minLine) {
      return null;
    }
    return _.times(this.minLine - _.size(this.data));
  }

  renderTd(column, rowData, rowIndex) {
    if (column.render) {
      return _.isFunction(column.render) ? column.render(rowData, rowIndex, column.key) : column.render;
    }
    return _.get(rowData, column.key);

  }

  bodyScroll(event) {
    this.fixedHeadTranslateStyle = this.getFixedHeadTranslateStyle(-event.target.scrollLeft);
    this.scrollBarX.nativeElement.scrollLeft = event.target.scrollLeft;
  }

  containerScroll() {
    this.updateVisibleRows();
    this.onDataStartIndexChange(this.cacheStartIndex);
  }

  onXHelperScroll(event) {
    this.scrollBody.nativeElement.scrollLeft = event.target.scrollLeft;
    this.updateVisibleRows();
  }

  onDataStartIndexChange(index) {
    this.dataStartIndex.emit(index);
  }

  private updateVisibleRows(): void {
    if (_.isEmpty(this.data)) {
      this.visibleDataList = [];
      return;
    }

    const currentScrollTop: number = _.get(this.realTableContainer, 'scrollTop', 0) - HEADER_HEIGHT;
    const bodyHeight = _.get(this.realTableContainer, 'clientHeight', 0) - HEADER_HEIGHT;
    let startIndex: number = Math.floor(currentScrollTop / this.rowHeight - VISIBLE_DATA_BUFFER);
    startIndex = Math.max(0, startIndex);

    if (Math.abs(this.cacheStartIndex - startIndex) < RE_RENDER_MARGIN && this.cacheStartIndex !== 0 && startIndex !== 0) {
      return;
    }

    this.cacheStartIndex = startIndex;
    this.rowOffset = this.cacheStartIndex;
    let endIndex: number = Math.ceil((currentScrollTop + bodyHeight) / this.rowHeight + VISIBLE_DATA_BUFFER);
    endIndex = Math.min(this.data.length, endIndex);
    this.visibleDataList = this.data.slice(this.cacheStartIndex, endIndex);
  }
}

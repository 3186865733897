import { toEnum } from '../_common/utils/enum';

export const SERVICE_TYPE = toEnum({
  SERVICE_MAINTENANCE: { key: '01', text: '保养' },
  ELECTRICAL_JOBS: { key: '02', text: '机电维修' },
  ACCIDENT_CAR: { key: '03', text: '事故车维修' },
  EXPRESS_A: { key: '05', text: '快修A' },
  EXPRESS_B: { key: '06', text: '快修B' },
  WARRANTY: { key: '07', text: '索赔' },
  RECALL: { key: '08', text: '召回' },
  SERVICE_MEASURE: { key: '09', text: '服务措施' },
  REGULAR_REPAIR: { key: '10', text: '一般维修' },
  PAINTING: { key: '11', text: '喷漆工作' },
  EXPRESS_TIRE_REPAIR: { key: '12', text: '轮胎快修' },
  SERVICE_CONTRACT: { key: '14', text: '服务合约' },
  PWEI: { key: '13', text: '客户保留' },
  LOST_SALE: { key: '15', text: '失销' },
  OTHERS: { key: '90', text: '其他' },
});

export const PAYMENT_TYPE = [
  { value: 'C', text: '零售' },
  { value: 'P', text: '保险理赔' },
  { value: 'W', text: '保修' },
  { value: 'Wr', text: '三包' },
  { value: 'I', text: '内部' },
  { value: 'O', text: '其他' },
];

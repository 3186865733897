import { GET, Query, RebirthHttp } from 'rebirth-http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SalesTypeApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-type')
  getSalesTypes(@Query('orderId') id: number,
                @Query('checkPrivilege') checkPrivilege: boolean): Observable<any> {
    return null;
  }
}

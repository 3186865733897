import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { TabForPartsPurchaseService } from './tab.service';
import { Subject, timer } from 'rxjs';
import { VehicleBusinessTypeService } from '@otr/website-common';
import { debounce, takeUntil } from 'rxjs/operators';
import { isVanForSameDealer } from '../../_common/utils/vanEnable';

@Component({
  selector: 'app-tab-for-parts-purchase',
  templateUrl: './tab.template.html',
  styleUrls: ['./tab.style.scss'],
})

export class TabForPartsPurchaseComponent implements OnInit, OnDestroy {
  tabType: string;
  tabs;
  onDestroy$ = new Subject();
  isVan: boolean = isVanForSameDealer();

  constructor(
    public readonly tabForPartsPurchaseService: TabForPartsPurchaseService,
    public readonly vehicleBusinessTypeService: VehicleBusinessTypeService,
  ) {
  }

  ngOnInit(): void  {
    this.initData();
    this.tabForPartsPurchaseService.refreshTabs.subscribe(shouldRefresh => shouldRefresh && this.initData());
    this.onBusinessTypeChanged();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // 当在 van 和 pc 的导航栏切换时，因为 url 是相同的，无法触发路由的 resolve 重新获取最新的 tabs，所以需要手动监听 vehicleBusinessTypeChanged$$
  onBusinessTypeChanged(): void {
    this.vehicleBusinessTypeService.vehicleBusinessTypeChanged$$
      .pipe(
        takeUntil(this.onDestroy$),
        debounce(() => timer(300)),
      )
      .subscribe(() => {
        this.initData();
      });
  }

  initData(): void  {
    this.tabs = this.tabForPartsPurchaseService.getTabs();
    this.tabType = _.findKey(this.tabForPartsPurchaseService.tabIndex, tabs => tabs === this.tabs);
  }

  onCloseTab(event, index): void  {
    event.stopPropagation();
    event.preventDefault();
    this.tabForPartsPurchaseService.closeTab(this.tabType, index);
  }
}

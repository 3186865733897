import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridRenderDisabled } from '../meta/grid-render-disabled';
import * as _ from 'lodash';

@Component({
  selector: 'app-dropdown-selection-list',
  templateUrl: './dropdown-selection-list.component.html',
  styleUrls: ['./dropdown-selection-list.component.scss'],
})
export class DropdownSelectionListComponent implements ICellRendererAngularComp, GridRenderDisabled {
  errorMessage: string;
  value: any;
  selector;

  params;
  disabled: boolean = false;
  options: Array<any>;
  filteredOptions: Array<any>;
  displayText: string;

  refresh(params: any): boolean {
    return false;
  }

  getData() {
    return this.params.data;
  }

  getIsDisabledMethod() {
    return data => this.selector && this.selector.isDisabled && this.selector.isDisabled(data);
  }

  get api() {
    return this.params.api;
  }

  agInit(params): void {
    this.params = params;
    this.value = [null, undefined].indexOf(params.value) !== -1 ? '' : params.value;
    this.selector = this.params.colDef.selector;
    this.options = this.getOptions();
    this.filteredOptions = this.options;
    this.displayText = this.getDisplayText();

    if (!_.isNil(params.value)) {
      this.displayText = this.getCurrentOptionHtml(
        _.find(this.options,
          optionValue => _.isEqual(optionValue.value, params.value)));
    } else {
      this.displayText = this.options[0].text;
    }
  }

  public getDisplayText() {
    return _.get(this.selector, 'displayText', '请选择');
  }

  public getOptions() {
    const options = this.selector.options;
    return _.isFunction(options) ? options(this.params) : options;
  }

  getCurrentOptionHtml(option) {
    const currentOptionHtml = this.selector.currentOptionHtml;
    return _.isFunction(currentOptionHtml) ? currentOptionHtml(option) : option.text;
  }

  getOptionHtml(option) {
    return `<span> ${option.text}</span>`;
  }

  handleClick(option) {
    // this.updateSourceValue(option.value);
    this.displayText = this.getCurrentOptionHtml(option);
    this.errorMessage = '';
    this.onChangeHandler(option);
  }

  clearKeyword() {
    this.filteredOptions = this.options;
  }

  scrollBar(event) {
    event.stopPropagation();
    return true;
  }

  updateSourceValue(value = this.value) {
    _.set(this.params.node.data, this.params.column.getColId(), value);
  }

  get onChangeHandler() {
    return this.params.colDef.selector.onChange || _.noop;
  }

}

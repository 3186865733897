import { finalize, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { PasswordResetService } from '../services/password-reset.service';

@Component({
  selector: 'app-password-reset-verify-form',
  templateUrl: './verify-form.component.html',
  styleUrls: ['./verify-form.component.scss'],
})
export class PasswordResetVerifyFormComponent implements OnInit {
  form: FormGroup;
  resendTimer: any;
  resendTimeout: number;
  isSendingVerificationCode: boolean = true;

  constructor(private service: PasswordResetService) {
    this.form = service.form;
  }

  ngOnInit() {
    this.sendVerificationCode();
  }

  submit() {
    this.service.confirm()
      .subscribe();
  }

  sendVerificationCode() {
    this.isSendingVerificationCode = true;
    this.service
      .sendVerificationCode().pipe(
      tap(this.resendCountDown.bind(this)),
      finalize(() => this.isSendingVerificationCode = false))
      .subscribe();
  }

  resendCountDown() {
    this.resendTimeout = 60;
    clearInterval(this.resendTimer);
    this.resendTimer = setInterval(() => {
      this.resendTimeout > 0 ?
        this.resendTimeout = this.resendTimeout - 1 :
        clearInterval(this.resendTimer);
    }, 1000);
  }
}

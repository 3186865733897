export const environment: Record<string, any> = {
  production: false,
  envName: 'qa',
  piwikSiteId: 3,
  piwikUrl: '',
  isSSODisabled: false,
  hmr: false,
  // dosBaseUrl: 'https://digital-workshop-we-chat-cn.mercedes-benz.com.cn/dos-app-service/app-gems-approve/gemslogin?state=',
  dosBaseUrl: 'https://test-digital-service-drive-cn.mercedes-benz.com.cn/dsd-security/app-gems-approve/gemslogin?state=',
  dosBaseUrlForTest: '',
  dssUrl: 'https://dss-uat.mercedes-benz.com.cn/dss-frontend/web/',
  azureHost: 'https://uat.otrplus.mercedes-benz.com.cn/contents/',
  isWorkshopDisable: false,
  isMmcEmailDisabled: true,
  isStrategyManagementDisable: false,
  isPartsDisable: false,
  vdcWebSocketUrl: 'wss://qa.otr.mercedes-benz.com.cn/websocket/vdc-popup',
  isAsVehicleDisable: false,
  zhugeAppKey: '5db736fd63c8486791ead07ab7c9516c',
  caseRecordTypeKey: '202357D821FB3BFt14KD',
  orderChangeCancellationRecordTypeKey: '20236689299646AJfcs7',
  isVanEnable: true,
  insuranceSaasHomePageUrl: 'https://qa.otrplus-accident.mercedes-benz.com.cn',
  pushNotificationDisable: false,
  usePartModuleToCompile: '',
  tingyun: {
    domain: 'wkbrs2.tingyun.com',
    token: 'ac99f7b6308f46ea8003ed6d181e9971',
    key: 'Qytug1Zfnyk',
    id: 'swJmDTB5Hok',
    operation: {
      rageClickEnabled: false
    },
    replay: {
      sampleRate: 0.1
    }
  },
  showDevComponent: true,
};

<common-app-layout-header>
  <ng-content select="[name=header-logo]"></ng-content>
  <app-cti-login-status-dropdown name="cti-login"></app-cti-login-status-dropdown>
</common-app-layout-header>
<div class="content">
  <div class="nav-side no-print">
    <common-app-navigation>
      <ng-content select="[name=nav-bar]"></ng-content>
    </common-app-navigation>
  </div>
  <main appScrollControl>
    <common-app-tab class="app-tab" *ngIf="!isPartsPurchaseRoute" [isVanType]="isVanType"></common-app-tab>
    <app-tab-for-parts-purchase class="app-tab-for-parts-purchase" *ngIf="isPartsPurchaseRoute"></app-tab-for-parts-purchase>
    <div [ngClass]="isPartsPurchaseRoute ? 'app-content-for-purchase' : 'app-content'">
      <ng-content></ng-content>
    </div>
  </main>
  <div #addCustomerAndLeadModal></div>
</div>




import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ColumnComponent } from './column.component';

@Component({
  selector: 'app-table-template-loader',
  template: ``,
})
export class TableTemplateLoaderComponent implements OnInit {

  @Input() column: ColumnComponent;
  @Input() rowData: any;
  @Input() rowIndex: number;
  @Input() name: string;

  constructor(public viewContainer: ViewContainerRef) {
  }

  ngOnInit() {
    const templateName = this.name === 'header' ? 'headerTemplate' : 'bodyTemplate';
    this.viewContainer.createEmbeddedView(this.column[templateName], {
      '\$implicit': this.column,  // tslint:disable-line
      'rowData': this.rowData,  // tslint:disable-line
      'rowIndex': this.rowIndex,  // tslint:disable-line
    });
  }
}

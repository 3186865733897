import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { BrochureOrderingApi } from '../api/brochure-ordering.api';

@Injectable()
export class BrochureOrderingService {
  constructor(private brochureOrderingApi: BrochureOrderingApi) {
  }

  loadBrochure(yearlyId) {
    return this.brochureOrderingApi.loadBrochure(yearlyId);
  }

  submitBrochure(yearlyTargetId, brochureOrderAddress: Array<any>, brochureOrders: Array<any>) {
    return this.brochureOrderingApi.submitBrochure({
      yearlyTargetId,
      brochureOrderAddress,
      brochureOrders,
    });
  }

  loadBrochureOrderAddress() {
    return this.brochureOrderingApi.getAddress().pipe(
      map(address => _.isEmpty(address) ? null : address));
  }
}

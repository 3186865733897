import { ChangeDetectorRef, Component, forwardRef, Inject } from '@angular/core';
import { DealerGroupService } from '../../../../system-config/services/dealer-group.service';
import * as _ from 'lodash';
import { BMBS_DEALER_TYPE } from '../../../constants/option-management';

@Component({
  templateUrl: './dealer-node.component.html',
  styleUrls: ['./dealer-node.component.scss'],
})
export class DealerNodeComponent {

  nodeId: any;
  name: any;

  constructor(
    @Inject(forwardRef(() => DealerGroupService))
    private dealerGroupService: DealerGroupService,
    private changeDetectorRef: ChangeDetectorRef) {
    this.dealerGroupService.mindMapSubject$.subscribe(value => {
      const selectedNode = _.find(value.mind.nodes, { id: this.nodeId });
      if (!_.isEmpty(selectedNode)) {
        this.name = _.get(selectedNode, 'topic', []);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  handleClick() {
    this.dealerGroupService.triggerShowDealerSelect.next({ type: BMBS_DEALER_TYPE.DEALER.text, topic: this.name });
  }
}

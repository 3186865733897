import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-appended-dash',
  templateUrl: './title-appended-dash.template.html',
  styleUrls: ['./title-appended-dash.style.scss'],
})
export class TitleAppendedDashComponent {
  @Input() title: string;
  @Input() titleAreaStyle: Object;
  @Input() titleStyle: Object;
}

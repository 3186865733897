import * as _ from 'lodash';

export const FEES_TYPE = {
  COMMISSION_FEE: 'COMMISSION_FEE',
  SERVICE_FEE: 'SERVICE_FEE',
  NON_VEHICLE_SERVICE_FEE: 'NON_VEHICLE_SERVICE_FEE',
};

export const FEES_CATEGORY = {
  INSURANCE_PRODUCT: { value: 'INSURANCE_PRODUCT', text: '保险' },
  FINANCIAL_PRODUCT: { value: 'FINANCIAL_PRODUCT', text: '金融' },
  OTHERS: { value: 'OTHERS', text: '其他' },
};

export const INSURANCE_TYPES = {
  COMPULSORY: {
    value: 'COMPULSORY',
    text: '交强险',
  },
  COMMERCIAL: {
    value: 'COMMERCIAL',
    text: '商业险',
  },
};

export const INSURANCE_TYPES_VALUES = _.values(INSURANCE_TYPES);

export const FINANCIAL_TYPES_DISPLAY = [
  { value: 'SERVICE_FEE', text: '其他服务费1' },
  { value: 'NON_VEHICLE_SERVICE_FEE', text: '其他服务费2' },
];

export const parseServiceFeeText = serviceFee => {
  switch (serviceFee.fees_category) {
    case FEES_CATEGORY.INSURANCE_PRODUCT.value:
      return _.chain(INSURANCE_TYPES_VALUES)
        .find((item: any) => item.value === serviceFee.product_category)
        .get('text', '')
        .value();
    case FEES_CATEGORY.FINANCIAL_PRODUCT.value:
      return _.chain(FINANCIAL_TYPES_DISPLAY)
        .find((item: any) => item.value === serviceFee.fees_type)
        .get('text', '')
        .value();
    case FEES_CATEGORY.OTHERS.value:
    default:
      return serviceFee.product_category;
  }
};
export const parseAgentServiceFeeText = serviceFee => {
  switch (serviceFee.feesCategory) {
    case FEES_CATEGORY.INSURANCE_PRODUCT.value:
      return _.chain(INSURANCE_TYPES_VALUES)
        .find((item: any) => item.value === serviceFee.productCategory)
        .get('text', '')
        .value();
    case FEES_CATEGORY.FINANCIAL_PRODUCT.value:
      return _.chain(FINANCIAL_TYPES_DISPLAY)
        .find((item: any) => item.value === serviceFee.feesType)
        .get('text', '')
        .value();
    case FEES_CATEGORY.OTHERS.value:
    default:
      return serviceFee.productCategory;
  }
};
export const BMBS_DEALER_TYPE = {
  BMBS: {
    text: '厂家',
    value: 'BMBS',
  },
  DEALER: {
    text: '经销商',
    value: 'DEALER',
  },
  DEALER_GROUP: {
    text: '经销商集团',
    value: 'DEALER_GROUP',
  },
};

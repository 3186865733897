
    <div *ngIf="ifShow">
      <div *ngIf="!isShowDealer" (click)="onView()">
        <i class="fa antd-eye-outlined"></i>
        <span class="operated-text">查看已指定经销商</span>
      </div>
      <div *ngIf="isShowDealer" (click)="onUpdate()">
        <i class="fa antd-form-outlined edit-icon"></i>
        <span class="operated-text">指定经销商</span>
      </div>
    </div>
  
import { Injectable } from '@angular/core';
import { Body, GET, Path, PUT, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Customer } from '../model';

@Injectable()
export class CustomerApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/customers/:id')
  getCustomerById(@Path('id') id: string): Observable<any> {
    return null;
  }

  @PUT('/api/sales-website/customers')
  public updateCustomer(@Body customer: Customer): Observable<Customer> {
    return null;
  }

  @GET('api/customers/sync/lead-tags/:id')
  getIsSyncFeatureTags(@Path('id') id: string): Observable<any> {
    return null;
  }

}

import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';

import { combineLatest, map, startWith, takeUntil } from 'rxjs/operators';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { VehicleService } from '../../../_common/services/vehicle.service';
import { KeyValue } from '../../../_common/models/common';
import { AutoUnsubscribe } from 'app/_common/utils/autoUnsubscribe';
import { LeadApi } from '../../../_common/api/lead';
import { DealerIntegrationToggleService, FeatureToggleService, SpinnerService } from '@otr/website-common';
import {
  BOOLEAN_OPTIONS,
  CURRENT_VEHICLE_YEAR_OPTION,
} from '../../constants/lead';

function pickDescription(data) {
  return data ? _.pick(data, ['description_en', 'description_zh']) : null;
}

function pickLabelDescription(data) {
  // tslint:disable-next-line:max-line-length
  return data ? _.pick({
    ...data,
    description_en: data.labelEn,
    description_zh: data.labelCn,
  }, ['description_en', 'description_zh']) : null;
}

export enum BrandTypes {
  NORMAL,
  INTERESTED,
}

@AutoUnsubscribe()
@Component({
  selector: 'app-vehicle-select',
  templateUrl: './vehicle-select.template.html',
  styleUrls: ['./vehicle-select.style.scss'],
})
export class VehicleSelectComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  formGroup: FormGroup;

  @Input()
  brandType: BrandTypes = BrandTypes.NORMAL;

  @Input()
  editable: boolean = true;

  @Input()
  searchable: boolean = false;

  @Input()
  isTestDriveVehicle: boolean = false;

  @Input()
  isInterestVehicle: boolean = false;

  @Input()
  isAssessmentShow: boolean = true;

  @Input()
  isCompareVehicle: boolean = false;

  brandControl: AbstractControl;
  classControl: AbstractControl;
  modelControl: AbstractControl;
  currentVehicleYearControl: AbstractControl;
  colorControl: AbstractControl;
  optionPreferenceControl: AbstractControl;
  currentVehicleAssessmentControl: AbstractControl;
  baumusterNstControl: AbstractControl;
  // classes$: Observable<any>;
  // models$: Observable<any>;
  // brands$: Observable<any>;

  classes$: Observable<any>;
  models$: Observable<any>;
  brands$: BehaviorSubject<any> = new BehaviorSubject([]);

  colorOption$: BehaviorSubject<any> = new BehaviorSubject([]);
  optionalPreferenceOption$: BehaviorSubject<any> = new BehaviorSubject([]);
  classesByBrand: [];
  currentVehicleYearOption: any = CURRENT_VEHICLE_YEAR_OPTION.values;
  currentVehicleAssessmentOption: any = BOOLEAN_OPTIONS.values;
  selectorStyle: any = { width: '120px' };

  modelStyle: any = { width: '210px' };

  vehicleService: VehicleService;
  spinnerService: SpinnerService;
  featureToggleService: FeatureToggleService;
  dealerIntegrationToggleService: DealerIntegrationToggleService;
  private leadApi: LeadApi;

  private componentDestroy$: Observable<any>;

  getText = option => _.get(option, 'description_zh') || _.get(option, 'description_en') || '请选择';
  getBaumusterText = (option, text = '请选择') => this.formatModelWithBaumusterNst(option, text);
  getBaumusterControlText = (option, text) => this.formatModelWithBaumusterNstControl(option, text);
  get brands(): any {
    if (this.brandType === BrandTypes.NORMAL) {
      return observableOf(this.vehicleService.brandsData);
    }
    if (this.isTestDriveVehicle) {
      return observableOf(this.vehicleService.testDriveBrandsData);
    }

    const interestedBrandsData =
      this.vehicleService.interestedBrandsData;
    return observableOf(interestedBrandsData);
  }

  getEnglishValue: any = option => _.get(option, 'labelEn');
  getChineseText: any = option => _.get(option, 'labelCn');

  constructor(injector: Injector) {
    this.vehicleService = injector.get(VehicleService);
    this.spinnerService = injector.get(SpinnerService);
    this.spinnerService = injector.get(SpinnerService);
    this.leadApi = injector.get(LeadApi);
    this.featureToggleService = injector.get(FeatureToggleService);
    this.dealerIntegrationToggleService = injector.get(DealerIntegrationToggleService);
  }

  ngOnInit() {
    this.brandControl = this.formGroup.controls.brand;
    this.classControl = this.formGroup.controls.class;
    this.baumusterNstControl = this.formGroup.controls.baumusterNst;
    this.currentVehicleYearControl = this.formGroup.controls.current_vehicle_age;
    this.colorControl = this.formGroup.controls.interest_vehicle_colour;
    this.optionPreferenceControl = this.formGroup.controls.interest_vehicle_optional_preference;
    this.currentVehicleAssessmentControl = this.formGroup.controls.current_vehicle_evaluation;
    if (this.brandType === BrandTypes.NORMAL) {
      this.modelControl = this.formGroup.controls.model;
    } else {
      this.modelControl = this.formGroup.controls.variant;
    }
    this.initControl();
    this.getOptionList();
    setTimeout(() => {
      const brand = _.find(this.brands.value, {
        description_en: _.get(this.brandControl.value, 'description_en'),
        description_zh: _.get(this.brandControl.value, 'description_zh'),
      });

      if (brand && this.brandType === BrandTypes.NORMAL) {
        this.leadApi.getClassesByBrandId(_.get(brand, 'id'))
          .pipe(this.spinnerService.loading()).subscribe(res => {
            this.classesByBrand = res;
            this.brandControl.setValue(pickDescription(brand));
            this.classControl.setValue(pickDescription(this.formGroup.get('class').value));
            this.modelControl.setValue(pickDescription(this.formGroup.get('model').value));
            this.brandControl.valueChanges.pipe(takeUntil(this.componentDestroy$)).subscribe(() => {
              this.classControl.setValue(null);
              if (this.baumusterNstControl) {
                this.baumusterNstControl.setValue(null);
              }
              this.resetFormControl();
            });
            this.classControl.valueChanges.pipe(takeUntil(this.componentDestroy$)).subscribe(() => {
              this.modelControl.setValue(null);
              if (this.baumusterNstControl) {
                this.baumusterNstControl.setValue(null);
              }
              this.resetFormControl();
            });
            this.modelControl.valueChanges.pipe(takeUntil(this.componentDestroy$)).subscribe(() => {
              this.resetFormControl();
            });
          });
      } else {
        this.brandControl.valueChanges.pipe(takeUntil(this.componentDestroy$)).subscribe(() => {
          this.classControl.setValue(null);
          if (this.baumusterNstControl) {
            this.baumusterNstControl.setValue(null);
          }
          this.resetFormControl();
        });
        this.classControl.valueChanges.pipe(takeUntil(this.componentDestroy$)).subscribe(() => {
          this.modelControl.setValue(null);
          if (this.baumusterNstControl) {
            this.baumusterNstControl.setValue(null);
          }
          this.resetFormControl();
        });
        this.modelControl.valueChanges.pipe(takeUntil(this.componentDestroy$)).subscribe(() => {
          this.resetFormControl();
        });
      }
    }, 1500);
  }

  getOptionList() {
    this.leadApi.getOptions()
      .subscribe(options => {
        this.colorOption$.next(options.leadCommonDictMap.interest_vehicle_colour);
        this.optionalPreferenceOption$.next(options.leadCommonDictMap.interest_vehicle_optional_preference);
      });
  }

  getIsEmpty(value) {
    return _.isEmpty(_.get(value, 'description_en')) && _.isEmpty(_.get(value, 'description_zh'));
  }

  resetFormControl() {
    if (this.isInterestVehicle) {
      this.colorControl.setValue(null);
      this.optionPreferenceControl.setValue(null);
    }
    if (!this.isCompareVehicle && !this.isInterestVehicle) {
      this.currentVehicleYearControl.setValue(null);
      this.currentVehicleAssessmentControl.setValue(null);
    }
  }

  initControl() {
    this.brands$.next(this.brands.value);
    this.classes$ = this.brands$.pipe(
      combineLatest(this.brandControl.valueChanges.pipe(startWith(this.brandControl.value))),
      map(([brands, selectedBrand]) => {
        let classes = [];
        if (this.brandType === BrandTypes.NORMAL) {
          if (this.classesByBrand) {
            classes = _.concat(this.classesByBrand, [{
              description_en: 'other',
              description_zh: '其他',
            }]);
          }
        } else {
          classes = _.chain(brands)
            .find({
              description_en: _.get(selectedBrand, 'description_en'),
              description_zh: _.get(selectedBrand, 'description_zh'),
            })
            .get('classes', [])
            .value();
        }

        return classes;
      }));

    this.models$ = this.classes$.pipe(
      combineLatest(this.classControl.valueChanges.pipe(startWith(this.classControl.value))),
      map(([classes, selectedClass]) => {
        let classObj = {};
        if (this.brandType === BrandTypes.NORMAL) {
          classObj = _.find(this.classesByBrand, {
            description_en: _.get(selectedClass, 'description_en'),
            description_zh: _.get(selectedClass, 'description_zh'),
          });
          return _.concat(_.get(classObj, 'models', []), [{
            description_en: 'other',
            description_zh: '其他',
          }]);
        }

        classObj = _.find(classes, {
          description_en: _.get(selectedClass, 'description_en'),
          description_zh: _.get(selectedClass, 'description_zh'),
        });

        const modelArr = _.chain(classObj)
          .get('models', [])
          .flatMap('variants')
          .value();

        if (modelArr[0] && _.isNumber(modelArr[0].launchDateFrom)) {
          const arr = _.chain(modelArr).sortBy(['description_en']).reverse().sortBy(['launchDateFrom']).reverse().value();
          arr.push(arr.shift());
          return arr;
        }
        return modelArr;
      }));
  }

  formatModelWithBaumusterNst = (model: any, flag) => {
    let suffix = '';
    if (_.get(model, 'baumuster')) {
      suffix = `\t(${_.get(model, 'baumuster')}-${_.get(model, 'nst')})`;
    }
    if (_.get(model, 'description_zh') || _.get(model, 'description_en')) {
      return `${_.get(model, 'description_zh') || _.get(model, 'description_en')}${suffix}`;
    }
    return flag;
  };

  formatModelWithBaumusterNstControl = (model: any, flag) => {
    let suffix = '';
    const controlValue = _.get(this.baumusterNstControl, 'value');
    if (controlValue && _.get(model, 'description_zh') !== '其他') {
      suffix = `\t
        (${_.get(controlValue, 'baumuster')}-${_.get(controlValue, 'nst')})`;
    }
    if (_.get(model, 'description_zh') || _.get(model, 'description_en')) {
      return `${_.get(model, 'description_zh') || _.get(model, 'description_en')}${suffix}`;
    }
    return flag;
  };

  onBrandSelect(brandData) {
    if (this.brandType === BrandTypes.NORMAL) {
      this.leadApi.getClassesByBrandId(brandData.id)
        .pipe(this.spinnerService.loading()).subscribe(res => {
          this.classesByBrand = res;
          this.brandControl.setValue(pickDescription(brandData));
        });
    } else {
      this.brandControl.setValue(pickDescription(brandData));
    }
  }

  onCurrentVehicleYearSelect(currentVehicleYear) {
    this.currentVehicleYearControl.setValue(currentVehicleYear);
  }

  onColorSelect(color) {
    this.colorControl.setValue(pickLabelDescription(color));
  }

  onOptionPreference(optionPreference) {
    this.optionPreferenceControl.setValue(pickLabelDescription(optionPreference));
  }

  onClassSelect(classData) {
    this.classControl.setValue(pickDescription(classData));
  }

  onCurrentVehicleAssementSelect(vehicleAssessment) {
    this.currentVehicleAssessmentControl.setValue(vehicleAssessment);
  }

  onModelSelect(modelData) {
    if (this.baumusterNstControl) {
      this.baumusterNstControl.setValue(_.pick(modelData, ['baumuster', 'nst']));
    }
    this.modelControl.setValue(pickDescription(modelData));
  }

  generateOption(option): KeyValue {
    return {
      value: option,
      text: this.getText(option),
    };
  }

  generateBaumusterOption(option): KeyValue {
    return {
      value: option,
      text: this.getBaumusterControlText(option, '请选择'),
    };
  }

}

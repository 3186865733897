import { Injectable } from '@angular/core';
import { Body, DELETE, GET, Headers, PATCH, Path, POST, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TemplateApi extends RebirthHttp {
  public uploadUri = 'api/quotations/accessory/upload';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/quotations/accessory/template')
  @Headers({ isExportFile: true })
  getTemplate(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/quotations/accessory/download')
  @Headers({ isExportFile: true })
  getExistingData(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/quotations/accessory/update-time')
  getUpdateTime(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/documents')
  getDocumentTemplates(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/documents/template/url')
  getImportedDocument(@Query('dealer_id') dealerId: string,
                      @Query('template_id') templateId: number): Observable<any> {
    return null;
  }

  @PATCH('/api/documents/template/:id')
  updateDocument(@Path('id') id: number,
                 @Body updateData): Observable<any> {
    return null;
  }

  @GET('/api/documents/data/url')
  downloadTemplatePdf(@Query('dealer_id') dealerId: string,
                      @Query('template_id') templateId: number): Observable<any> {
    return null;
  }

  @GET('/api/documents/manual/url')
  downloadManual(): Observable<any> {
    return null;
  }

  @GET('/api/quotation-options/accessory')
  getDataList(@Query('filter') filter: string,
              @Query('size') size: string,
              @Query('page') page: string): Observable<any> {
    return null;
  }

  @DELETE('/api/quotation-options/accessory')
  deleteOptions(@Body data): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftermarket-config/accessory-config')
  getConfigData(): Observable<any> {
    return null;
  }

  @PUT('/api/quotation-options/accessory/:id')
  updateOption(@Path('id') id: string,
               @Body updateData): Observable<any> {
    return null;
  }

  @POST('/api/quotation-options/accessory')
  saveOption(@Body data): Observable<any> {
    return null;
  }
}

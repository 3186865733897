<aside class="followup-container">
  <header class="header">存在以下相同电话线索，本次跟进记录将同步至被勾选线索，无所属客户发展专员的线索将被自动分配给当前客户</header>
  <main *ngIf="persons.length" class="content">
    <div class="modal-row" *ngFor="let person of persons">
      <input type="checkbox" (click)="handleSelect(person.leads)"/>
      <div class="person">
        <div class="customer-info">
          <span class="column name">{{person.full_name || '未命名'}}</span>
          <div>
            <div class="phone-wrapper">
              <span class="column phone">{{person.mobile}}</span>
              <span class="column phone">{{person.work_phone}}</span>
              <span class="column phone">{{person.home_phone}}</span>
            </div>
            <div class="current-vehicles">
              <div class="current-vehicle" *ngFor="let currentVehicle of person.currentVehicleList">
              <span class="column vehicle-info">{{currentVehicle.carPlateNumber}}
                - {{currentVehicle.description}}</span>
              </div>
            </div>
            <span class="column dealer-name">{{person.dealer_name}}</span>
          </div>
        </div>
        <div *ngFor="let lead of person.leads" class="lead">
          <div class="lead-detail">
            <span class="column lead-status">{{lead.status | leadStatus}}</span>
            <span class="column interest-vehicle"
                  [title]="getLeadInterestedVehicle(lead)">{{getLeadInterestedVehicle(lead)}}</span>
            <span class="column channel">{{lead.channel | channel}}</span>
            <span class="column cdo">客户发展专员 - {{lead.cdoName || '无'}}</span>
            <span class="column sc">销售顾问 - {{getDisplaySc(lead)}}</span>
          </div>
          <span class="column create-time">创建时间 - {{lead.created_time | dateParse: 'yyyy-MM-dd HH:mm'}}</span>
        </div>
      </div>
    </div>
  </main>
  <footer class="button-panel">
    <button class="btn btn-outline-primary" (click)="handleCloseButton()">取消</button>
    <button class="btn btn-primary" [disabled]="selectedPersons.length === 0" (click)="handleSubmit()">确定</button>
  </footer>
</aside>

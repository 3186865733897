import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, scan, shareReplay } from 'rxjs/operators';
import * as _ from 'lodash';

export class CheckboxStrategy {

  private readonly selectedEvents$: BehaviorSubject<[string, boolean]>;
  private readonly clearEvent$: BehaviorSubject<boolean>;
  private readonly selectedRows$: Observable<Set<string>>;
  private readonly selectedRowSet: Set<string>;

  constructor() {
    this.selectedEvents$ = new BehaviorSubject<[string, boolean]>([null, false]);
    this.clearEvent$ = new BehaviorSubject<boolean>(false);
    this.selectedRowSet = new Set<string>();
    this.selectedRows$ = combineLatest(
      this.selectedEvents$,
      this.clearEvent$,
    ).pipe(
      map(([selectedEvents, clearEvent]) => {
        if (clearEvent) {
          this.selectedRowSet.clear();
          this.clearEvent$.next(false);
          return [null, false];
        }
        return selectedEvents;
      }),
      scan((set, [id, selected]: [string, boolean]) => {
        selected ? set.add(id) : set.delete(id);
        return set;
      }, this.selectedRowSet),
      debounceTime(10),
      shareReplay(1),
    );
  }

  public select(id: string): void {
    this.selectedEvents$.next([id, true]);
  }

  public unselect(id: string): void {
    this.selectedEvents$.next([id, false]);
  }

  public toggle(id: string, selected?: boolean): void {
    const status = _.isNil(selected) ? !this.selectedRowSet.has(id) : selected;
    this.selectedEvents$.next([id, status]);
  }

  public watch(id: string): Observable<boolean> {
    return this.selectedRows$.pipe(
      map(set => set.has(id)),
      distinctUntilChanged(),
    );
  }

  public watchAll(): Observable<Set<string>> {
    return this.selectedRows$;
  }

  public getSelectedRows(): Set<string> {
    return this.selectedRowSet;

  }

  public clearAll() {
    this.clearEvent$.next(true);
  }
}

import { forkJoin as observableForkJoin, Observable, of, of as observableOf } from 'rxjs';
import { catchError, finalize, flatMap, map, switchMap, tap } from 'rxjs/operators';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import {
  AcceptLeadRequest,
  AppointmentType,
  AssignScRequest,
  BatchAssignUsedCarRequest,
  CDOFollowUpRequest,
  CloseLeadRequest, IDealerQuote,
  IBatchFollowUpListRequest,
  ILeadsCount,
  ILeadsResponse,
  LeadApi,
} from '../../../../_common/api/lead';
import { LeadAddition, LeadSource } from '../../../../_common/models/lead';
import { SalesLeadModalComponent } from '../sales-lead-modal/sales-lead-modal.component';
import {
  isCustomerActivity,
  isCustomerTaskActivity,
  isDisableActivities,
  isOpenLead,
  isOpenLeadExisting,
} from '../../../../_common/utils/lead';
import { getFlattenedCurrentVehicle } from '../../../../_common/utils/vehicle';
import { SendOfferMsgApi } from '../../../../_common/api/send-offer-message';
import { AftermarketMasterDataApi } from '../../../../_common/api/aftermarket-master-data';
import { ComplainantApi } from '../../../../_common/api/complainant';
import { SendMessageModel } from '../../../../_common/models/offer-template';
import { formatGridFilters } from '../../../../_common/utils/filter';
import { DealerGroupInfo } from '../../../../_common/models/dealer';
import { WallBoxOrder } from '../../../../_common/models/order';
import { LeadManagementApi } from '../../shared/api/lead-management';
import { CecInfo } from '../../../cec-config/model';
import { CecConfigApi } from '../../../cec-config/api/cec-config';
import { OfferData } from '../customer-information/models/customer-information.view';
import { preferredContactChannelUtils } from '../utils/preferred-contact-channel-utils';
import {
  CurrentUserService,
  DealerIntegrationToggleService,
  FeatureToggleService,
  SpinnerService,
  StoreService,
} from '@otr/website-common';
import { splitServiceMore } from '../../../shared/service-more.util';
import { DropdownOption } from '../../../../_common/models/common';
import { LEAD_MERGE_TYPE } from '../shared/model/lead';
import { CustomerCriteriaConfigApi } from '../../../customer-criteria-config/customer-criteria-config.api';
import { AdvancedFilterPagingResponse } from '../../../customer-criteria-config/customer-criteria-config.model';
import { CtiCallService } from '../../../../_common/services/cti-call.service';
import { USER_ROLE } from '../../../../_common/constants/user-role.constant';

export interface ModalResponseHandler {
  handleResponseSuccess: (response) => void;
  handleResponseError: (response) => void;
}

export interface InstallmentOrDeliveryRequest {
  comments: string;
  wallBoxType: string;
  partNumber: string;
  partDescription: string;
}

export const LEAD_SOURCE_FILTER_TYPE = {
  ALL: 'all',
  FILTER: 'filter',
  OTR: 'otr',
};

export const FOLLOW_UP_TYPE = {
  keepFollowUp: 'FOLLOW_UP',
  appointment: 'APPOINTMENT',
  closeLead: 'CLOSE_LEAD'
};

const PAYLOAD_KEY = {
  keepFollowUp: {
    value: 'followUpRequest',
    format: (record) => {
      const isChangeToLead = record.isChangeToLead;
      return _.assign(_.omit(record, ['isChangeToLead', 'isChangeToLeadMod']), {isChangeToLead});
    }
  },
  appointment: {
    value: 'appointmentRequest',
    format: (record) => {
      const scGroupId =  record.scGroupId;
      const scUserId =  record.scUserId;
      const isOnlyAppointment =  record.isOnlyAppointment;
      return _.assign(
        _.omit(record, ['modScGroupId', 'modScUserId']),
        {scGroupId, scUserId, isOnlyAppointment},
        {actionType: AppointmentType.APPOINTMENT}
      );
    }
  },
  closeLead: {
    value: 'closeLeadRequest',
    format: (record) => {
      return _.omit(record, ['closeModLeadCategory', 'closeModLeadReason']);
    }
  }
};

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  leadSources: Array<LeadSource> = [];
  selectedScId: string;
  referrals: Array<any> = [];

  usedCarRequestMapping = [
    {
      value: 'keepFollowUp',
      onConfirm: (followUpRequest: CDOFollowUpRequest) => this.followUpUsedCarLead(followUpRequest),
    },
    {
      value: 'closeLead',
      onConfirm: (closeLeadRequest: CloseLeadRequest) => this.closeUsedCarLead(closeLeadRequest),
    },
  ];
  requestMapping = [
    {
      value: 'keepFollowUp',
      onConfirm: (followUpRequest: CDOFollowUpRequest) => this.followUp(followUpRequest),
    },
    {
      value: 'appointment',
      onConfirm: (assignScRequest: AssignScRequest) =>
        this.assignSc({
          ...assignScRequest,
          actionType: AppointmentType.APPOINTMENT,
        }),
    },
    {
      value: 'closeLead',
      onConfirm: (closeLeadRequest: CloseLeadRequest) => this.closeLead(closeLeadRequest),
    },
    {
      value: 'noPlan',
      onConfirm: (followUpRequest: CDOFollowUpRequest) => this.followUp(followUpRequest),
    },
  ];

  constructor(
    private injector: Injector,
    private leadApi: LeadApi,
    private cecConfigApi: CecConfigApi,
    private leadManagementApi: LeadManagementApi,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private storeService: StoreService,
    private spinnerService: SpinnerService,
    private sendOfferMsgApi: SendOfferMsgApi,
    private aftermarketMasterDataApi: AftermarketMasterDataApi,
    private currentUserService: CurrentUserService,
    private complainantApi: ComplainantApi,
    private customerCriteriaConfigApi: CustomerCriteriaConfigApi,
    private ctiCallService: CtiCallService,
  ) {}

  private regionData$: Observable<Array<any>>;
  private regionData: Array<any>;
  private usedCarExportStatus;
  private usedCarExportFollowedUp;
  private usedCarExportFilter;

  getAddFollowUpConfirmMethod(type, leadType: string = 'sales') {
    if (leadType === 'usedCar') {
      return _.find<any>(this.usedCarRequestMapping, { value: type }).onConfirm;
    }
    return _.find<any>(this.requestMapping, { value: type }).onConfirm;
  }

  usedCarFilter(filter) {
    if (_.head(filter.leadStatus) === 'Lead') {
      filter.leadStatus.push('Lead Candidate');
    }
    this.usedCarExportFilter = _.omit(filter, 'fastFilter', 'is_followed_up');
    this.usedCarExportStatus = _.toUpper(filter.fastFilter) || filter.leadStatusTab[0];
    this.usedCarExportFollowedUp = filter.is_followed_up;
    return {
      ...filter,
      usedCarLeadStatus: _.toUpper(filter.fastFilter) || filter.leadStatusTab[0],
      is_used_car_followed_up: filter.is_followed_up,
    };
  }
  fetchCecDealers(dealerId: string, cecId: number, userGroupName: string, isSCDO: boolean): Observable<CecInfo> {
    return _.isNil(cecId)
      ? observableOf({ dealerList: [{ dealerId, name: userGroupName }] } as CecInfo)
      : this.cecConfigApi.getCecDealers(cecId).pipe(catchError(() => observableOf({} as CecInfo)));
  }

  fetchLeadSources(type: string = LEAD_SOURCE_FILTER_TYPE.OTR) {
    if (type === LEAD_SOURCE_FILTER_TYPE.ALL) {
      return this.leadApi.getLeadSources(type);
    }
    return _.isEmpty(this.leadSources) && this.leadApi.getLeadSources(type);
  }

  getLead(id: string, type: string = 'B') {
    return this.leadApi.getLead(id);
  }

  addFollowUp(selectedLeads, followUpRecords, record, type, syncLeadIds = []) {
    const followUpType = FOLLOW_UP_TYPE[type];
    const syncLeadIdsMap = [];
    followUpRecords.forEach((followUpRecord) => {
      const { _id } = followUpRecord.leads[0];
      if (syncLeadIds.includes(_id)) {
        syncLeadIdsMap.push(_id);
      }
    });
    const data = [];
    selectedLeads.forEach((selectedLead) => {
      const keyStr = PAYLOAD_KEY[type].value;
      const payload = PAYLOAD_KEY[type].format(record);
      data.push({
        [keyStr]: {
          ...payload,
          leadId: selectedLead._id,
          leadIds: [selectedLead._id],
          syncLeadIds: syncLeadIdsMap
        }
      });
    });
    return this.leadApi.addFollowUp(followUpType, data);
  }

  getUsedCarLead(id: string) {
    return this.leadApi.getUsedCarLead(id);
  }

  initModalWith(
    modalContent,
    modalProperties,
    actionsModal,
    showDuplicateLeadModal,
    saveCurrentModal,
    responseHandlers: ModalResponseHandler,
  ) {
    const modalContentRef = this.createModalContent(modalContent);
    this.createModalContainer(modalContentRef, modalProperties, actionsModal, showDuplicateLeadModal, saveCurrentModal, responseHandlers);
  }

  private createModalContainer(modalContentRef, modalProperties, actionsModal, showDuplicateLeadModal, saveCurrentModal, responseHandlers) {
    const { confirm, modalTitle, modalSubtitle } = modalProperties;
    const modalFactory = this.componentFactoryResolver.resolveComponentFactory(SalesLeadModalComponent);
    const modalContainerRef = actionsModal.createComponent(modalFactory, 0, this.injector, [[modalContentRef.location.nativeElement]]);

    const modalInstance = modalContainerRef.instance;
    const contentInstance = modalContentRef.instance;
    modalInstance.modalTitle = modalTitle;
    modalInstance.modalSubtitle = modalSubtitle;
    modalInstance.close = () => {
      this.ctiCallService.setShouldShowAddCustomerBtn(true);
      modalContentRef.destroy();
      modalContainerRef.destroy();
    };
    contentInstance.isInvalid$.subscribe(modalInstance.isInvalid$);
    modalInstance.confirm = () => {
      if (contentInstance.submit) {
        contentInstance
          .submit()
          .pipe(
            tap((response: any) => {
              if (response.showDuplicateLeads) {
                showDuplicateLeadModal(response.persons);
                saveCurrentModal(modalInstance, contentInstance);
                modalInstance.hide();
                return;
              }
              modalInstance.close();
              responseHandlers.handleResponseSuccess(response);
            }),
          )
          .subscribe();
        return;
      }
      confirm(contentInstance.getFormValue())
        .pipe(
          this.spinnerService.loading(),
          finalize(() => modalInstance.close()),
        )
        .subscribe(
          (res) => responseHandlers.handleResponseSuccess(res),
          (error) => responseHandlers.handleResponseError(error),
        );
    };
  }

  private createModalContent(modalContent): ComponentRef<any> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modalContent.type);
    const modalContentRef = componentFactory.create(this.injector);
    Object.assign(modalContentRef.instance, modalContent.properties);
    this.applicationRef.attachView(modalContentRef.hostView);
    return modalContentRef;
  }

  searchPersons(mobile: string, dealerId: string) {
    return this.leadApi.getPersons(mobile, dealerId);
  }

  modifyLeads(persons) {
    return persons.map((person) => ({
      ...person,
      leads: _.filter(person.leads, isOpenLead),
      latestSuccessfulLead: this.getLatestSuccessfulLead(person.leads),
    }));
  }

  getLatestSuccessfulLead(leads) {
    const sortedSuccessfulLeads = _.chain(leads).filter({ status: 'Successful' }).orderBy('updated_time', 'desc').value();
    return isOpenLeadExisting(leads) || _.isEmpty(sortedSuccessfulLeads) ? {} : _.head(sortedSuccessfulLeads);
  }

  getPersons(phones: Array<string>, rejectPersonId, dealerId: string) {
    return this.leadApi
      .getPersons(phones.join(','), dealerId)
      .pipe(tap((persons) => this.storeService.update('persons', _.reject(persons, { id: rejectPersonId } as any))));
  }

  getUsedCarPersons(phones: Array<string>, rejectPersonId, dealerId: string) {
    return this.leadApi
      .getUsedCarPersons(phones.join(','), dealerId)
      .pipe(tap((persons) => this.storeService.update('persons', _.reject(persons, { id: rejectPersonId } as any))));
  }

  mergeLead(sourceLeadId, leadForm, showroomVisit, shouldMoveLead) {
    const { person } = leadForm;
    _.unset(leadForm, 'person');
    const mergeRequest = shouldMoveLead
      ? {
        lead: Object.assign({}, leadForm, { _id: sourceLeadId }),
        customer: _.omit(person, 'leads'),
        showroom_visit: showroomVisit,
        action: LEAD_MERGE_TYPE.MOVE.value,
      }
      : {
        lead: {
          _id: sourceLeadId,
          owner_sales_consultant_id: leadForm.owner_sales_consultant_id,
        },
        customer: Object.assign({}, _.omit(person, 'leads'), { lead: leadForm }),
        showroom_visit: showroomVisit,
        action: LEAD_MERGE_TYPE.MERGE.value,
      };
    return this.leadApi.reMergeLead(mergeRequest);
  }

  operateLeadAddition(leadAdditionModel: LeadAddition) {
    return this.leadApi.operateLeadAddition(leadAdditionModel);
  }

  cdoLeadImportRequest = (cdoLeadImportRequestId: string) => {
    return this.leadApi.cdoLeadImportRequest(cdoLeadImportRequestId);
  }

  getFollowUpList(pageNumber: number, pageSize: number, filter: Record<string, any>, cecId: number): Observable<ILeadsResponse> {
    const { es, ...realFilter } = filter;
    const payload = {
      pageNumber,
      pageSize,
      cecId,
      es,
      filter: JSON.stringify(realFilter),
    };

    return observableForkJoin(
      this.leadApi.getFollowUpList(payload),
      this.leadApi.getFollowUpListCount(payload)
    ).pipe(
      map(this.combineLeadsData)
    );
  }

  getBatchFollowUpListByIds(data: IBatchFollowUpListRequest) {
    return this.leadApi.getBatchFollowUpListByIds(data);
  }

  getUsedCarFollowUpList(pageNumber: number, pageSize: number, filter: any, cecId: number) {
    const usedCarFilter = this.usedCarFilter(filter);
    return this.leadApi.getUsedCarsFollowUpList({
      pageNumber,
      pageSize,
      cecId,
      filter: JSON.stringify(usedCarFilter),
    });
  }

  getOptions() {
    return this.leadApi.getOptions();
  }

  getUsedCarAllLeads(pageNumber: number, pageSize: number, filter: any, cecId: number) {
    const usedCarFilter = this.usedCarFilter(filter);
    return this.leadApi.getUsedCarsAllLeads({
      pageNumber,
      pageSize,
      cecId,
      filter: JSON.stringify(usedCarFilter),
    });
  }

  getAllLeads(pageNumber: number, pageSize: number, filter: Record<string, any>, cecId: number) {
    const { es, ...realFilter } = filter;
    const payload = {
      pageNumber,
      pageSize,
      cecId,
      es,
      filter: JSON.stringify(realFilter),
    };

    return observableForkJoin(
      this.leadApi.getAllLeads(payload),
      this.leadApi.getAllLeadsCount(payload)
    ).pipe(
      map(this.combineLeadsData)
    );
  }

  getImportLeadIds(id: string) {
    return this.leadApi.getImportLeadIds(id);
  }

  assignSc(assignScRequest: AssignScRequest): Observable<any> {
    return this.leadApi.assignSc(assignScRequest);
  }

  assignCdo(assignScRequest: AssignScRequest): Observable<any> {
    return this.leadApi.assignCdo(assignScRequest);
  }

  recallLead(recallParameters) {
    return this.leadApi.recallLead(recallParameters);
  }

  recallModelDLead(recallParameters) {
    return this.leadApi.recallModelDLead(recallParameters);
  }

  getPendingLeads(pageNumber: number, pageSize: number, filter: Record<string, any>, cecId: number): Observable<ILeadsResponse> {
    const { es, ...realFilter } = filter;
    const payload = {
      pageNumber,
      pageSize,
      cecId,
      es,
      filter: JSON.stringify(realFilter),
    };

    return observableForkJoin(
      this.leadApi.getPendingLeadList(payload),
      this.leadApi.getPendingLeadListCount(payload)
    ).pipe(
      map(this.combineLeadsData)
    );
  }

  getUsedCarPendingLeads(pageNumber: number, pageSize: number, filter: any, cecId: number) {
    const usedCarFilter = this.usedCarFilter(filter);
    return this.leadApi.getUsedCarsPendingLeadList({
      pageNumber,
      pageSize,
      cecId,
      filter: JSON.stringify(usedCarFilter),
    });
  }

  reactivateLead(reactivateParameters) {
    return this.leadApi.reactivateLead(reactivateParameters);
  }

  closeLead(closeLeadRequest: CloseLeadRequest): Observable<any> {
    return this.leadApi.closeLead(closeLeadRequest);
  }

  closeUsedCarLead(closeLeadRequest: CloseLeadRequest): Observable<any> {
    return this.leadApi.closeUsedCarLead(closeLeadRequest);
  }

  getLastCreatedDealer(leadId, cecId): Observable<DealerGroupInfo> {
    return this.leadApi.getLastCreatedDealer(leadId, cecId);
  }

  getUsedCarLeadOrCustomerById(id, type, cecId?: number): Observable<any> {
    const customerInfo = [
      'full_name',
      'gender',
      'mobile',
      'home_phone',
      'work_phone',
      'dealer_name',
      'certificate_type',
      'last_name',
      'first_name',
      'certificate_number',
      'province',
      'city',
      'district',
      'address',
      'birthday',
      'postcode',
      'current_vehicles',
      'currentVehicleList',
      'dmpTags',
      'preferredContactChannel',
      'preferredContactTime',
      'familyMemberCount',
      'retailCampaignActivityCount',
      'occupation',
      'customerHobbies',
      'tasks',
      'dealer_id',
      'feature_tags',
      'age',
      'isAgreeFollowUp',
      'comments',
      'is_sync_feature_tags',
      'charging_pile_or_parking_space',
      'kilometers_per_day'
    ];
    if (type === 'lead') {
      return this.leadApi.getUsedCarLeadWithOrderedVehicles(id).pipe(
        map((lead: any) => ({
          ...lead,
          ..._.pick(lead.person, customerInfo),
          customerId: lead.person.id,
        })),
      );
    }
    if (type === 'customer') {
      return this.leadApi.getCustomer(id, cecId).pipe(
        map((customer) => ({
          ..._.pick(customer, customerInfo),
          customerId: customer.id,
          customerHobbies: customer.customerHobbies,
        })),
      );
    }
    return observableOf();
  }

  getLeadOrCustomerById(id, type, cecId?: number): Observable<any> {
    const customerInfo = [
      'full_name',
      'gender',
      'mobile',
      'home_phone',
      'work_phone',
      'dealer_name',
      'certificate_type',
      'last_name',
      'first_name',
      'certificate_number',
      'province',
      'city',
      'district',
      'address',
      'birthday',
      'postcode',
      'current_vehicles',
      'currentVehicleList',
      'dmpTags',
      'preferredContactChannel',
      'preferredContactTime',
      'familyMemberCount',
      'retailCampaignActivityCount',
      'occupation',
      'customerHobbies',
      'tasks',
      'dealer_id',
      'feature_tags',
      'age',
      'isAgreeFollowUp',
      'comments',
      'is_sync_feature_tags',
      'charging_pile_or_parking_space',
      'kilometers_per_day'
    ];
    if (type === 'lead') {
      return this.leadApi.getLeadWithOrderedVehicles(id).pipe(
        map((lead: any) => ({
          ...lead,
          ..._.pick(lead.person, customerInfo),
          customerId: lead.person.id,
        })),
      );
    }
    if (type === 'customer') {
      return this.leadApi.getCustomer(id, cecId).pipe(
        map((customer) => ({
          ..._.pick(customer, customerInfo),
          customerId: customer.id,
          customerHobbies: customer.customerHobbies,
        })),
      );
    }
    return observableOf();
  }

  followUp(cDOFollowUpRequest: CDOFollowUpRequest): Observable<any> {
    return this.leadApi.followUp(cDOFollowUpRequest);
  }

  followUpUsedCarLead(cDOFollowUpRequest: CDOFollowUpRequest): Observable<any> {
    return this.leadApi.followUpUsedCarLead(cDOFollowUpRequest);
  }

  fetchRegionsData() {
    if (!_.isEmpty(this.regionData)) {
      return observableOf(this.regionData);
    }
    if (!this.regionData$) {
      this.regionData$ = this.leadApi.fetchRegionsData();
      this.regionData$.subscribe((regionData) => (this.regionData = regionData));
    }

    return this.regionData$;
  }

  cdoLeadCreate(leadRequest): Observable<any> {
    return this.leadApi.cdoLeadCreate(leadRequest);
  }

  usedCarLeadCreate(leadRequest, usedCarDate): Observable<any> {
    if (_.isEmpty(leadRequest.leadForCDORequest.body.interested_vehicles)) {
      leadRequest.leadForCDORequest.body.interested_vehicles[0] = { brand: null, class: null, model: null, variant: null };
    }
    _.assign(leadRequest.leadForCDORequest.body.interested_vehicles[0], usedCarDate);
    return this.leadApi.usedCarLeadCreate(leadRequest);
  }

  downloadTemplate = (): Observable<any> => {
    return this.leadApi.downloadTemplate();
  }

  getCustomerActivityAndRelatedUsedCarLeadList(customerId) {
    return observableForkJoin(
      this.leadApi.getCustomerRelatedUsedCarLeadList(customerId),
      this.leadApi.getCustomerUsedCarLeadActivity(customerId),
    ).pipe(
      switchMap(([leads, customerActivities]) => {
        const activities = {
          ALL: _.reject(customerActivities, isDisableActivities),
          SALES_CUSTOMER_FOLLOW_UP_HISTORY: _.filter(customerActivities, isCustomerTaskActivity),
        };
        const customerRelatedActivities = _.chain(customerActivities).filter(isCustomerActivity).reject(isDisableActivities).value();
        const leadActivities = _.reduce(
          leads,
          (formattedActivities, lead) => ({
            ...formattedActivities,
            [lead._id]: _.chain(lead).get('activities', []).concat(customerRelatedActivities).value(),
          }),
          {},
        );
        return observableOf([{ ...activities, ...leadActivities }, leads]);
      }),
    );
  }

  getAllCustomerActivityAndRelatedLeadList(customerId) {
    return observableForkJoin(
      this.leadApi.getCustomerRelatedLeadList(customerId),
      this.leadApi.getCustomerRelatedLeadListMod(customerId),
      this.leadApi.getCustomerActivity(customerId),
      this.leadApi.getCustomerActivityMod(customerId)).pipe(
      switchMap(([leads, mdLeads, customerActivities, mdCustomerActivities]) => {
        const allCustomerActivities = _.uniqWith([...customerActivities, ...mdCustomerActivities], _.isEqual);
        const allLeads = [...leads, ...mdLeads];
        const activities = {
          ALL: _.reject(allCustomerActivities, isDisableActivities),
          SALES_CUSTOMER_FOLLOW_UP_HISTORY: _.filter(allCustomerActivities, isCustomerTaskActivity),
        };
        const customerRelatedActivities = _.chain(allCustomerActivities).filter(isCustomerActivity).reject(isDisableActivities).value();
        const leadActivities = _.reduce(
          allLeads,
          (formattedActivities, lead) => ({
            ...formattedActivities,
            [lead._id]: _.chain(lead).get('activities', []).concat(customerRelatedActivities).value(),
          }),
          {},
        );
        const allActivities = { ...activities, ...leadActivities };
        return observableOf({leads, mdLeads, allActivities});
      }),
    );
  }

  getCustomerActivityAndRelatedLeadList(customerId) {
    return observableForkJoin(this.leadApi.getCustomerRelatedLeadList(customerId), this.leadApi.getCustomerActivity(customerId)).pipe(
      switchMap(([leads, customerActivities]) => {
        const activities = {
          ALL: _.reject(customerActivities, isDisableActivities),
          SALES_CUSTOMER_FOLLOW_UP_HISTORY: _.filter(customerActivities, isCustomerTaskActivity),
        };
        const customerRelatedActivities = _.chain(customerActivities).filter(isCustomerActivity).reject(isDisableActivities).value();
        const leadActivities = _.reduce(
          leads,
          (formattedActivities, lead) => ({
            ...formattedActivities,
            [lead._id]: _.chain(lead).get('activities', []).concat(customerRelatedActivities).value(),
          }),
          {},
        );
        return observableOf([{ ...activities, ...leadActivities }, leads]);
      }),
    );
  }

  getCustomerRelatedLeadListMod(customerId) {
    return this.leadApi.getCustomerRelatedLeadListMod(customerId);
  }

  getCustomerActivityAndRelatedLeadListMod(customerId) {
    return observableForkJoin(this.leadApi.getCustomerRelatedLeadListMod(customerId), this.leadApi.getCustomerActivityMod(customerId)).pipe(
      switchMap(([leads, customerActivities]) => {
        const activities = {
          ALL: _.reject(customerActivities, isDisableActivities),
          SALES_CUSTOMER_FOLLOW_UP_HISTORY: _.filter(customerActivities, isCustomerTaskActivity),
        };
        const customerRelatedActivities = _.chain(customerActivities).filter(isCustomerActivity).reject(isDisableActivities).value();
        const leadActivities = _.reduce(
          leads,
          (formattedActivities, lead) => ({
            ...formattedActivities,
            [lead._id]: _.chain(lead).get('activities', []).concat(customerRelatedActivities).value(),
          }),
          {},
        );
        return observableOf([{ ...activities, ...leadActivities }, leads]);
      }),
    );
  }

  getCustomerServedScs(customerId): Observable<any> {
    return this.leadApi.getCustomerServedScs(customerId);
  }

  cdoLeadMove(sourceLeadId, leadForm, tags, isSyncFeatureTags) {
    return this.leadApi.cdoMergeLead({
      lead: {
        _id: sourceLeadId,
        ..._.omit(leadForm, 'person'),
        serviceMore: _.join(splitServiceMore(leadForm.serviceMore), '|'),
        nextFollowUpDate: new Date(leadForm.nextFollowUpDate).getTime(),
        license_status_multiple: _.join(splitServiceMore(leadForm.license_status_multiple), '|'),
      },
      customer: {
        ...leadForm.person,
        preferredContactChannel: preferredContactChannelUtils.buildValueFromOptions(leadForm.person.preferredContactChannel),
        current_vehicles: [getFlattenedCurrentVehicle(leadForm.person.current_vehicle)],
        feature_tags: tags.toString(),
        is_sync_feature_tags: isSyncFeatureTags,
      },
      action: LEAD_MERGE_TYPE.MOVE.value,
    });
  }

  usedCarLeadMove(sourceLeadId, leadForm, tags, isSyncFeatureTags, usedCarDate, usedCarVin, usedCarRegDate, usedCarMileage) {
    const cloneVehicle = _.cloneDeep(leadForm.interested_vehicles);
    if (cloneVehicle.length > 0) {
      cloneVehicle.shift();
      cloneVehicle.unshift({
        ..._.head(leadForm.interested_vehicles),
        vin: usedCarVin === '-' ? '' : usedCarVin,
        customer_bid: usedCarDate.customer_bid,
        dealer_offer: usedCarDate.dealer_offer,
        current_mileage: usedCarMileage === '-' ? '' : usedCarMileage,
        registration_date: usedCarRegDate == '-' ? '' : usedCarRegDate,
      });
    }
    return this.leadApi.usedCarLeadMerge({
      lead: {
        _id: sourceLeadId,
        ..._.omit(leadForm, 'person'),
        interested_vehicles: cloneVehicle,
        serviceMore: _.join(splitServiceMore(leadForm.serviceMore), '|'),
        nextFollowUpDate: new Date(leadForm.nextFollowUpDate).getTime(),
        license_status_multiple: _.join(splitServiceMore(leadForm.license_status_multiple), '|'),
      },
      customer: {
        ...leadForm.person,
        preferredContactChannel: preferredContactChannelUtils.buildValueFromOptions(leadForm.person.preferredContactChannel),
        current_vehicles: [getFlattenedCurrentVehicle(leadForm.person.current_vehicle)],
        feature_tags: tags.toString(),
        is_sync_feature_tags: isSyncFeatureTags,
      },
      action: LEAD_MERGE_TYPE.MOVE.value,
    });
  }

  cdoLeadMerge(sourceLeadId, targetLeadId, leadForm, tags, isSyncFeatureTags) {
    return this.leadApi.cdoMergeLead({
      lead: {
        _id: sourceLeadId,
      },
      customer: {
        ...leadForm.person,
        current_vehicles: [getFlattenedCurrentVehicle(leadForm.person.current_vehicle)],
        preferredContactChannel: preferredContactChannelUtils.buildValueFromOptions(leadForm.person.preferredContactChannel),
        feature_tags: tags.toString(),
        is_sync_feature_tags: isSyncFeatureTags,
        lead: {
          ..._.omit(leadForm, 'person'),
          nextFollowUpDate: new Date(leadForm.nextFollowUpDate).getTime(),
          license_status_multiple: _.join(splitServiceMore(leadForm.license_status_multiple), '|'),
          serviceMore: _.chain(leadForm.serviceMore).split(',').join('|').value(),
          _id: targetLeadId,
        },
      },
      action: LEAD_MERGE_TYPE.MERGE.value,
    });
  }

  usedCarLeadMerge(sourceLeadId, targetLeadId, leadForm, tags, isSyncFeatureTags, usedCarDate, usedCarVin, usedCarRegDate, usedCarMileage) {
    const cloneVehicle = _.cloneDeep(leadForm.interested_vehicles);
    if (cloneVehicle.length > 0) {
      cloneVehicle.shift();
      cloneVehicle.unshift({
        ..._.head(leadForm.interested_vehicles),
        vin: usedCarVin === '-' ? '' : usedCarVin,
        customer_bid: usedCarDate.customer_bid,
        dealer_offer: usedCarDate.dealer_offer,
        current_mileage: usedCarMileage === '-' ? '' : usedCarMileage,
        registration_date: usedCarRegDate == '-' ? '' : usedCarRegDate,
      });
    }
    // @ts-ignore
    return this.leadApi.usedCarLeadMerge({
      lead: {
        _id: sourceLeadId,
      },
      customer: {
        ...leadForm.person,
        current_vehicles: [getFlattenedCurrentVehicle(leadForm.person.current_vehicle)],
        preferredContactChannel: preferredContactChannelUtils.buildValueFromOptions(leadForm.person.preferredContactChannel),
        feature_tags: tags.toString(),
        is_sync_feature_tags: isSyncFeatureTags,
        lead: {
          ..._.omit(leadForm, 'person'),
          interested_vehicles: cloneVehicle,
          nextFollowUpDate: new Date(leadForm.nextFollowUpDate).getTime(),
          license_status_multiple: _.join(splitServiceMore(leadForm.license_status_multiple), '|'),
          serviceMore: _.chain(leadForm.serviceMore).split(',').join('|').value(),
          _id: targetLeadId,
        },
      },
      action: LEAD_MERGE_TYPE.MERGE.value,
    });
  }

  usedCarLeadMergeCheck(sourceLeadId, targetLeadId) {
    return this.leadApi.usedCarLeadMergeCheck(sourceLeadId, targetLeadId);
  }

  exportLeadData = (cecId, tab, filters) => {
    return this.leadApi.exportLeadData({
      cecId,
      tab,
      filters: JSON.stringify(filters),
    });
  }

  exportUsedCarLeadData = (tab, usedCarLeadIds) => {
    // tslint:disable-next-line:variable-name
    const is_used_car_followed_up = this.usedCarExportFollowedUp;
    const usedCarLeadStatus = this.usedCarExportStatus;
    const filters = {
      ...this.usedCarExportFilter,
      is_used_car_followed_up,
      usedCarLeadStatus,
      usedCarLeadIds,
    };
    return this.leadApi.exportUsedCarLeadData({
      tab,
      filters: JSON.stringify(filters),
    });
  }

  getFailedExcel = (cdoLeadImportRequestId: number, dealerId: string) => {
    return this.leadApi.getFailedExcel(cdoLeadImportRequestId, dealerId);
  }

  acceptLeads(acceptLeadRequest: AcceptLeadRequest): Observable<any> {
    return this.leadApi.acceptLeads(acceptLeadRequest);
  }

  acceptModelDLeads(acceptLeadRequest: AcceptLeadRequest): Observable<any> {
    return this.leadApi.acceptModelDLeads(acceptLeadRequest);
  }

  receptionUsedCarLeads(acceptLeadRequest: AcceptLeadRequest): Observable<any> {
    return this.leadApi.receptionUsedCarLeads(acceptLeadRequest);
  }

  acceptUsedCarLeads(acceptLeadRequest: any): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const acceptLeadUsedCarRequest = {
      assignedUserId: acceptLeadRequest.assignedCdoId,
      leadIds: acceptLeadRequest.leadIds,
      nextFollowUpDate: acceptLeadRequest.nextFollowUpDate,
      roleCode: this.currentUserService.isRole(USER_ROLE.UM) ? 'UE' : 'SCDO',
    };
    return this.leadApi.acceptUsedCarLeads(acceptLeadUsedCarRequest);
  }

  getReferrals(keyword: string, leadType?: string): Observable<any> {
    const { dealerId } = this.currentUserService.getCurrentUser();
    return this.leadApi.getReferrals(keyword, dealerId, leadType);
  }

  getGeneratedOrdersNumber(campaignId: string) {
    return this.leadApi.getGeneratedOrdersNumber(campaignId);
  }

  getInsightOffers(customerId: string) {
    return this.leadApi.getInsightOffers(customerId);
  }

  getFsModelYear(otrModelYear: number | string): Observable<number> {
    return this.leadApi.getFsModelYear(otrModelYear);
  }

  fetchFinanceNew(requestBody: IDealerQuote): Observable<IDealerQuote> {
    return this.leadApi.fetchFinanceNew(requestBody).pipe(this.spinnerService.loading());
  }

  fetchFinance(nst, modelName, gsCode, gcCode, changeYear = '', modelYear = '') {
    return this.leadApi.fetchFinance({ baumusterNst: nst, modelName, gsCode, gcCode, changeYear, modelYear });
  }

  loadSmsTemplates(leadId: string, isUsedCarLead: boolean) {
    return this.leadApi.getSmsTemplates(leadId, isUsedCarLead);
  }

  sendMessage(selectedSmsTemplateType: string, leadId: string, customizedMobile: string, isUsedCarLead: boolean) {
    return this.leadApi.sendMessage(selectedSmsTemplateType, leadId, customizedMobile, isUsedCarLead);
  }

  saveRetentionSavedOffer(savedOffer) {
    return this.leadApi.saveRetentionSavedOffer(savedOffer);
  }

  getOfferImage(offer) {
    return _.get(offer, 'exteriorImages[0]', '../../../../assets/order/defaultCar.png');
  }

  sendOfferMessage(sendMessageModel: SendMessageModel): Observable<any> {
    return this.sendOfferMsgApi.sendOfferMessage(sendMessageModel);
  }

  getSendOfferModalInitData(
    customerId: string,
    offerId: string,
    isCustomOffer: boolean,
    tradeInEnabled: boolean): Observable<any> {
    return observableForkJoin([
      this.sendOfferMsgApi.getWechatSubscribeStatus(customerId).pipe(catchError(() => observableOf(false))),
      this.sendOfferMsgApi.getOfferTemplates(offerId, isCustomOffer, tradeInEnabled),
    ]);
  }

  searchCustomer(type: string, content: string, cecId: number, leadType?: string): Observable<any> {
    return this.leadApi.getSearchResult(type, content, cecId, leadType);
  }

  getCustomersOffers(customerId: string, fin: string): Observable<Array<OfferData>> {
    return this.leadApi.getCustomersOffers(customerId, fin);
  }

  loadArchiveActivity(leadIds) {
    return _.isEmpty(leadIds) ? observableOf([]) : this.leadApi.loadArchiveActivity(leadIds);
  }

  getWallBoxOrders(quotationIds: string): Observable<Array<WallBoxOrder>> {
    return this.leadManagementApi.getWallBoxOrders(quotationIds);
  }

  getWallBoxOrder(addressId: number): Observable<WallBoxOrder> {
    return this.leadManagementApi.getWallBoxOrder(addressId);
  }

  createWallBoxOrder(data: WallBoxOrder): Observable<any> {
    return this.leadManagementApi.createWallBoxOrder(data);
  }

  createWallBoxPreCheck(orderId: number, data: InstallmentOrDeliveryRequest): Observable<any> {
    return this.leadManagementApi.createWallBoxPreCheck(orderId, data);
  }

  createWorkBoxPending(orderId: number, date: Date, comments: string) {
    const followUpDate = date.getTime();
    return this.leadManagementApi.createWallBoxPending(orderId, { followUpDate, comments });
  }

  cancelWallBoxPreCheck(orderId: number, cancelReason: string): Observable<any> {
    return this.leadManagementApi.cancelWallBoxPreCheck(orderId, { cancelReason });
  }

  cancelWallBoxInstallment(orderId: number, cancelReason: string): Observable<any> {
    return this.leadManagementApi.cancelWallBoxInstallment(orderId, { cancelReason });
  }

  cancelWallBoxDelivery(orderId: number, cancelReason: string): Observable<any> {
    return this.leadManagementApi.cancelWallBoxDelivery(orderId, { cancelReason });
  }

  updateWallBoxOrder(orderId: number, data: WallBoxOrder): Observable<WallBoxOrder> {
    return this.leadManagementApi.updateWallBoxOrder(orderId, data);
  }

  createWallBoxInstallment(orderId: number, data: InstallmentOrDeliveryRequest): Observable<WallBoxOrder> {
    return this.leadManagementApi.createWallBoxInstallment(orderId, data);
  }

  createWallBoxDelivery(orderId: number, data: InstallmentOrDeliveryRequest): Observable<WallBoxOrder> {
    return this.leadManagementApi.createWallBoxDelivery(orderId, data);
  }

  loadLeadOffers(leadId: string, customerId: string): Observable<Array<OfferData>> {
    return this.leadApi.getLeadOffersByLeadId(leadId, customerId);
  }

  loadOccupationOption(): Observable<Array<DropdownOption>> {
    return this.leadApi.loadOccupationOption();
  }

  getStockStatus(baumusterNst: string): Observable<'期货' | '现货' | '无库存'> {
    return this.leadApi.getStockStatus(baumusterNst);
  }

  loadComplaints(complainantName: string, mobile: string, dealerId: string) {
    return this.complainantApi.getComplainsByNameOrMobile(complainantName, mobile, dealerId);
  }

  getCustomerCriteriaConfig(): Observable<AdvancedFilterPagingResponse> {
    return this.customerCriteriaConfigApi.getCustomerCriteriaConfig();
  }

  getCustomerFlagConfig(): Observable<AdvancedFilterPagingResponse> {
    return this.customerCriteriaConfigApi.getCustomerFlagConfig();
  }

  getRealModelYear(baumusterNst: string) {
    return this.leadApi.getRealModelYear(baumusterNst);
  }

  completeTask(completeTask: any) {
    return this.leadApi.completeTask(completeTask);
  }

  updatedTaskByTaskManagementPrivilege(updatedTask) {
    return this.leadApi.updatedTaskByTaskManagementPrivilege(updatedTask);
  }

  updatedTask(updatedTask) {
    return this.leadApi.updatedTask(updatedTask);
  }

  getHistoryTasks(customerId) {
    return this.leadApi.getHistoryTasks(customerId);
  }

  hasOpenLead(customerId, fin) {
    return this.leadApi.hasOpenLead(customerId, fin);
  }

  getTaskHistoryActivities(customerId): Observable<any> {
    return this.leadApi.getTaskHistoryActivities(customerId, 'web');
  }

  updateCustomerComments(comments) {
    return this.leadApi.updateCustomerComments(comments);
  }

  getWallBoxProducts() {
    return this.aftermarketMasterDataApi.getWallBoxProducts();
  }

  getWallBoxProduct(partNumber) {
    return this.aftermarketMasterDataApi.getWallBoxProduct(partNumber);
  }

  private combineLeadsData(res: [ILeadsResponse, ILeadsCount]): ILeadsResponse  {
    const [listRes, countRes] = res;
    return { ...listRes, total_number: listRes.es ? countRes.total_number : listRes.total_number };
  }
// tslint:disable-next-line:max-file-line-count
}

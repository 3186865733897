import { Events } from 'ag-grid';
import * as _ from 'lodash';

export interface GridRenderDisabled {
  getIsDisabledMethod: () => (data: any) => boolean;
  disabled: boolean;
  getData: () => any;
  api;
}

// tslint:disable-next-line
export function DisabledListener(target, property: string, descriptor: PropertyDescriptor) {
  const originFunction = descriptor.value;

  descriptor.value = function (...args) {
    const self = this as GridRenderDisabled;

    originFunction.apply(self, args);
    const isDisabled = self.getIsDisabledMethod();
    const data = self.getData();

    self.disabled = isDisabled(data);

    if (self.api) {
      self.api.eventService.addEventListener(Events.EVENT_CELL_VALUE_CHANGED, e => {
        if (e.data === data) {
          const row = Object.assign({}, e.data);
          _.set(row, e.colDef.field, e.newValue);
          self.disabled = isDisabled(row);
        }
      });
    }
  };
}

import * as _ from 'lodash';

export const ERROR_MESSAGES = {
  BAD_RESPONSE: '服务器或网络出错，请稍后再试！',
  VEHICLE_OR_ORDER_NOT_FOUND: '车辆或订单不存在，请核实。',
  VEHICLE_CAN_NOT_EDIT: '此车已确认，无法编辑。',
  MBE_ORDER_CANCELED_OR_TIMEOUT: '此单已在电商平台取消或超过48小时预留时间，请勿继续操作',
  ORDER_RESERVED_BY_MBE: '此车已被MBE预定，无法编辑。',
  VEHICLE_BEEN_CONFIRMED: '车辆已确认',
  OPTIONS_EXPIRED: '有一项或多项销售激励政策已失效',
  SERVICE_CONTRACT_IS_INVALID: '有一项或多项服务合同产品已失效',
  SERVICE_CONTRACT_MP_PRODUCT_LIMIT: '所选保养产品超过产品数量限制，请更新',
  SERVICE_CONTRACT_MP_CATEGORY_LIMIT: '所选保养产品超过品类数量限制，请更新',
  SERVICE_CONTRACT_MP_LIMIT: '所选保养产品超过数量限制，请更新',
  SERVICE_CONTRACT_CREATED_FAILED: '服务合同创建失败，请告知相关销售顾问',
  SERVICE_CONTRACT_CANCELLED_FAILED: '服务合同取消失败，请告知相关销售顾问',
  MOD_OPTIONS_EXPRIED: '有一项或多项附加赠送已失效，请修改',
  ORDER_IS_CANCELLED: '订单已取消，无法编辑。',
  DOWNLOAD_TIME_OUT: '连接超时，请重试。',
  DOCUMENT_NOT_FOUND: '找不到文件，请联系IT部门支持。',
  FAIL_TO_VIEW_ORDER: '查看订单失败，请稍后重试',
  FAIL_ALLOCATED_REQUEST_ORDER: '调车申请发送失败',
  FORBIDDEN_TO_VIEW_ORDER: '无权限查看此订单',
  UPDATE_FAILED: '系统异常，请重试',
  VEHICLE_DELETED: '车辆已被删除, 请更换车辆或取消订单',
  FAIL_TO_VIEW_ORDER_DOCUMENTS: '无法查看该订单下的文档信息',
  FAIL_TO_DOWNLOAD_DUCUMENT: '下载文档失败，请重试',
  FAIL_TO_DOWNLOAD_FILE: '下载失败，请重试',
  FAIL_TO_UPLOAD_DOCUMENT: '更新文档失败，请重试',
  FAIL_TO_DELETE_DUCUMENT: '删除文档失败，请重试',
  BAD_FILE_FORMAT: '文件格式不符，请重试',
  EXCEED_MAX_LIMIT: '已超出审计订单数量上限',
  ORDER_NOT_FOUND: '无法找到对应的订单',
  FORM_VALUE_INVALID: '请完善信息',
  FILE_UPLOADING: '文件正在打包，请稍候...',
  ACTIVATE_FAILED: '报价单已失效，请重新申请',
  RESOURCE_NOT_FOUND: '找不到文件，请联系IT部门支持。',
  UNKNOWN: '未知异常',
  FILE_TYPE_INVALID: '文件格式有误，请重试。',
  SEND_OFFER_ERROR: '发送失败，请稍后重试或选择其他联系方式',
  SEND_OFFER_ERROR_IT: '发送失败，请联系IT支持中心',
  ICON_BAD_RESPONSE: 'ICON数据接口连接失败，后台正在加紧处理，请稍后再试',
  DCS_BAD_RESPONSE: 'DCS数据接口连接失败，后台正在加紧处理，请稍后再试',
  LPO_FAIL_TO_GET_DATA: 'LPO数据接口连接失败，后台正在加紧处理，请稍后再试',
  EXPORT_DATA_LENGTH_LIMIT: '导出明细的数据量超过10万条，请缩小筛选时间范围重新导出',
  LPO_AND_ICON_BAD_RESPONSE: 'LPO和ICON数据接口连接失败，后台正在加紧处理，请稍后再试',
  UPLOAD_FILE_ERROR: '上传文件格式错误，请重新上传',
  WRONG_FILE_TYPE: '请选择正确的文件',
  LOCK_NOT_GOT: '操作冲突，请稍后重试',
  IMPORT_TEMPLATE_NOT_FOUND: '下载模板未找到',
  UPLOAD_FILE_SERVER_ERROR: '文件上传失败',
  FILE_EXPIRED: '记录已超出6个月，文件已被清理无法下载',
  LPO_SC_STATUS_ERROR: '订单状态已变更，请刷新页面',
  EXPORT_DATA_TOO_MANY: '导出数据超出系统限制5000条，请调整检索条件重新导出',
  LPO_PLAN_INFO_CANCEL_FAILED: '关联致友计划订单取消失败，请稍后重试',
  LPO_PLAN_INFO_CREATED_FAILED: '关联致友计划订单创建失败，请稍后重试',
  LPO_PLAN_INFO_ACTIVATE_FAILED: '关联致友计划订单激活失败，请稍后重试',
  LPO_PLAN_INFO_UPDATE_FAILED: '关联致友计划订单更新失败，请稍后重试',
  STATICAL_MONTH_EXISTS: '统计月份已存在',
  ITEM_HAS_BEEN_DELETED: '您正在操作的条目已被删除',
  APPLY_ITEM_STATUS_CHANGED: '申请条目状态发生变更，请刷新后重试',
  FILE_TYPE_NOT_MATCH: '上传文件不满足格式要求，请重试',
  DELETE_SUCCESS: '操作成功',
  EXPORT_APPLY_INFO_ERROR: '导出失败，最多支持导出5万条数据',
  ACCIDENT_CAR_STATUS_UPDATE: '操作失败，线索状态已更新',
  ACCIDENT_CAR_LEAD_UPDATE_FAIL: '线索更新失败，请重试',
  CUSTOMER_NOT_FOUND: '该客户已被删除',
  ACCIDENT_CAR_LEAD_OPERATION_FAIL: '操作失败，请重试',
  DISTRIBUTED_LOCK_ERROR: '处理中，请稍候',
  ACCIDENT_LEAD_DUPLICATE_ERROR: '已有重复线索，请重新保存',
  AFTER_SALES_SERVICE_INFO_ERROR: '售后服务产品数据查询失败，请稍后再试',
  SYSTEM_ERROR_FAILED: '系统异常，请稍后重试',
  FORECAST_FEE_GET_FAILED: '预估代驾费获取失败，请稍后再试',
  POLICY_CYCLE_CHANGED: '政策周期发生变更，请刷新后重试',
  NO_DOWNLOAD_MATERIALS: '暂无可下载材料',
  NO_DEALER_NAME_OR_POLICY_CYCLE: '请输入经销商名称、政策周期查询',
  NO_POLICY_CYCLE: '请输入政策周期查询',
  NEW_CAR_NEXT_YEAR_POLICY_CONFIG_CHANGED: '该新车次年政策支持数据已变更，不符合提交申诉条件，请刷新页面',
  COMPLAINT_STATUS_CHANGED: '该配件折扣申请数据已变更，不符合提交申诉条件，请刷新页面',
  DATA_HAS_BEEN_DELETED: '您正在编辑的数据已被删除',
  EXIST_OTHER_RULE: '生效时间内已启用其他规则',
  PART_OF_DATA_IMPORT_ERROR: '部分数据导入失败，请查看异常数据文件',
  IMPORT_ERROR: '导入失败，已生成异常数据文件',
  INCORRECT_SHEET_NAME: '导入失败，文件模板错误 - Sheet名称不正确，请重新下载服务合约零售价格配置表',
  CAN_NOT_PROCEED: '此中央库存车已被解锁或超过72小时预留时间，请勿继续操作',
  REQUIRE_FIELD_MUST_COMPLETED: '请完成必填项',
  RESERVATION_CHANNEL_DUPLICATED: '来源名称重复',
  PC_AUTHORIZATION_MISSING: '本店无乘用车授权，不可创建预约',
  VAN_AUTHORIZATION_MISSING: '本店无商务车授权，不可创建预约',
  FIN_NOT_FOUND: '该底盘号不存在',
  NO_MORE_RESERVATIONS_AVAILABLE: '该时间预约已满，不可预约！',
  RESERVATION_CANNOT_EDIT: '当前状态，不可编辑',
  SA_UNAVAILABLE: '此SA该时段已有预约或休假',
  D2D_ORDER_ESTIMATED_FEE: '获取预估费用及里程失败，请重试',
  D2D_ORDER_SAME_LOCATION: '出发地目的地重复，请重新确认地址',
  D2D_ORDER_TIME_NOT_SELECT: '当前时间不可选，请重新选择',
  ORDER_FAILED: '下单失败',
  D2D_ORDER_CANNOT_PAY: '当前状态，不可支付',
};

export const VALIDATE_SSSC_ERROR_CODE = {
  VEHICLE_NOT_FOUND: 'not_found',
  FIN_NOT_FOUND: 'fin_not_found',
  HAS_PREVIOUS_ACTIVATED_SSSC: 'validate_creation_sssc_quotation_failed',
  DUPLICATE_SSSC: 'duplicate_sssc_quotation_at_the_same_dealer',
};

export const DUPLICATE_CUSTOMER_ERROR_CODES = ['person_duplicate', 'customer_duplicate', 'customer_with_uncanceled_orders'];

export const BMBS_ERROR_MESSAGES = [
  {
    value: 'invalid_service_fee_operation',
    text: '所选项不存在，请刷新页面',
  },
  {
    value: 'service_fee_organization_exist',
    text: '产品名称已存在',
  },
  {
    value: 'service_fee_brand_exist',
    text: '品牌已存在',
  },
];

export const DEALER_GROUP_HIERARCHY_ERROR_MESSAGES = [
  {
    value: 'group_invalid',
    text: '更新失败。',
  },
  {
    value: 'dealer_not_found',
    text: '添加的经销商不存在。',
  },
  {
    value: 'group_not_deletable',
    text: '团队[<%= name %>]已有用户。如需删除，请先转移用户到其他团队',
    getText(name) {
      return _.template(this.text)({ name });
    },
  },
  {
    value: 'duplicate_team_name',
    text: '<%= name %>',
    getText(name) {
      return _.template(this.text)({ name });
    },
  },
];

export const USER_MANAGEMENT_ERROR_MESSAGE = [
  {
    value: 'division_not_selected',
    text: '请选择品牌',
  },
];

export const BOOK_IN_ERROR_MESSAGE = [
  {
    value: 'cannot_update_book_in_status',
    text: '包含无法操作的车辆, 请刷新后重新选择',
  },
  {
    value: 'cannot_update_book_in_date',
    text: '超过月报提交截止时间，请重新选择日期！',
  },
];

export const VISIT_ERROR_MESSAGES = [
  {
    value: 'can_not_end_reception_with_invalid_visit_activity_leave_time',
    text: '无效的结束时间。',
  },
  {
    value: 'can_not_end_reception_with_unfinished_test_drive',
    text: '试驾尚未结束，请先结束试驾。',
  },
  {
    value: 'invalid_visit_leave_time',
    text: '结束时间不能小于开始时间。',
  },
  {
    value: 'interested_vehicle_no_variant',
    text: '当到店目的为看车比价、订单或现定现交，需要输入完整的意向车信息。',
  },
  {
    value: 'interested_vehicle_no_baumuster_nst',
    text: '第一意向车信息错误，请重新输入',
  },
  {
    value: 'lead_duplicate',
    text: '重复线索',
  },
  {
    value: 'lead_division_duplicate',
    text: '重复线索，姓名和电话号码在同一品牌已存在',
  },
  {
    value: 'person_duplicate',
    text: '重复客户，姓名和电话号码已存在',
  },
  {
    value: 'customer_duplicate',
    text: '重复客户，姓名和电话号码已存在',
  },
  {
    value: 'hand_over_task_not_done',
    text: '有交车任务尚未完成，无法结束接待',
  },
];

export const RESEND_DMS_MESSAGES = {
  RESEND_DMS_MESSAGE_SUCCESS: '已成功发送DMS',
  RESEND_DMS_MESSAGE_FAIL: '发送失败，请重试',
};

export const ERROR_STATUS_CODE = {
  401: '用户名或密码错误，请重新输入',
  403: '你的帐号已无效',
  410: '经销商账户已无效',
  500: '网络连接失败，请检查网络配置',
  502: '服务器异常，请稍后再试',
  504: '网络连接失败，请检查网络配置',
};

export const SALES_REPORT_ERROR = {
  empty_name: {
    value: 'empty_name',
    text: '部门成员名字不能为空',
  },
  duplicate_user: {
    value: 'duplicate_user',
    text: '不能重复添加用户',
  },
};

export const FORM_REQUIRED_ERROR_MESSAGE = {
  message: ERROR_MESSAGES.FORM_VALUE_INVALID,
};

export const PAYMENT_COLLECTION_NAME_EMPTY = 'payment_collection_name_empty';
export const ORDER_DOWNLOAD_TIMES_OVERFLOW = 'order_download_times_overflow';
export const ORDER_PARSER_BAD_REQUEST = 'BadRequest';
export const RESOURCE_NOT_FOUND = 'resource_not_found';
export const NOT_FOUND = 'not_found';
export const PAYMENT_COLLECTION_ERROR_CODES = ['payment_config_name_existed', PAYMENT_COLLECTION_NAME_EMPTY];
export const POLICY_CYCLE_CHANGED = 'policy_cycle_changed';
export const BAD_REQUEST = 'bad_request';
export const NEW_CAR_NEXT_YEAR_POLICY_CONFIG_NOT_MATCH = 'new_car_next_year_policy_config_not_match';
export const COMPLAINT_STATUS_CHANGED = 'complaint_status_changed';
export const REPEATED_COMPLAINTS = 'repeated_complaints';

export const UPLOAD_DOCUMENT_ERROR = [
  {
    value: 'Document template context invalid',
    text: '导入失败，请检查文件内容是否正确',
  }
];

export const ACCIDENT_CAR_LEAD_ERROR = [
  { value: 'accident_status_updated_operate_failed', text: ERROR_MESSAGES.ACCIDENT_CAR_STATUS_UPDATE },
  { value: 'distributed_lock_error', text: ERROR_MESSAGES.DISTRIBUTED_LOCK_ERROR },
  { value: 'accident_lead_duplicate_error', text: ERROR_MESSAGES.ACCIDENT_LEAD_DUPLICATE_ERROR }
];

export const UPLOAD_ERROR_MESSAGE = {
  PRICE_NOT_FOUND: 'Price_NOT_FOUND',
  PRODUCT_CODE_NOT_FOUND: 'ProductCode_NOT_FOUND',
  DUPLICATE_SKU_BMST: 'duplicate_sku_bmst',
  SKU_BMST_NOT_FOUND: 'sku_bmst_not_found',
  SKU_NOT_FOUND: 'sku_not_found',
};

export const LPO_ERROR_MESSAGE = [
  {
    value: UPLOAD_ERROR_MESSAGE.PRICE_NOT_FOUND,
    text: '未找到车型编码'
  },
  {
    value: UPLOAD_ERROR_MESSAGE.PRODUCT_CODE_NOT_FOUND,
    text: '未找到产品代码'
  },
  {
    value: UPLOAD_ERROR_MESSAGE.DUPLICATE_SKU_BMST,
    text: '产品和车型条目重复'
  },
  {
    value: UPLOAD_ERROR_MESSAGE.SKU_BMST_NOT_FOUND,
    text: '未找到对应的产品和车型'
  },
  {
    value: UPLOAD_ERROR_MESSAGE.SKU_NOT_FOUND,
    text: '未找到产品代码'
  }
];

export const D2D_ERROR_CODE = {
  BUSINESS_TIME_INVALID: 'business time invalid',
  DEALER_HAS_NO_DIDI_DRIVING_ACCOUNT: 'dealer has no Didi driving account',
  DEALER_HAS_NO_E_DRIVING_ACCOUNT: 'dealer has no eDriving account',
  NOT_SUPPORT_PC: 'not support PC',
  NOT_SUPPORT_VAN: 'not support VAN',
  DIDI_INSUFFICIENT_BALANCE: 'DiDi insufficient balance',
  E_DRIVING_INSUFFICIENT_BALANCE: 'eDriving insufficient balance',
  BOOKED_DRIVING_SAME_TIME: 'booked driving same time',
  AGREEMENT_SENDING_FAILED: 'd2d_order_sms_helm_error',
};

export const D2D_ERROR_MESSAGE: {
  [key: string]: string
} = {
  [D2D_ERROR_CODE.DEALER_HAS_NO_DIDI_DRIVING_ACCOUNT]: '当前经销商未开通滴滴代驾号！',
  [D2D_ERROR_CODE.DEALER_HAS_NO_E_DRIVING_ACCOUNT]: '当前经销商未开通e代驾号！',
  [D2D_ERROR_CODE.NOT_SUPPORT_PC]: '该经销商不支持乘用车业务！',
  [D2D_ERROR_CODE.NOT_SUPPORT_VAN]: '该经销商不支持商务车业务！',
  [D2D_ERROR_CODE.DIDI_INSUFFICIENT_BALANCE]: '滴滴代驾余额不足！',
  [D2D_ERROR_CODE.E_DRIVING_INSUFFICIENT_BALANCE]: 'e代驾余额不足！',
  [D2D_ERROR_CODE.BOOKED_DRIVING_SAME_TIME]: '该车在相同时间已预约代驾，请勿重复预约！',
  [D2D_ERROR_CODE.AGREEMENT_SENDING_FAILED]: '协议发送失败，请稍后再试',
};

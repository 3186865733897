import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.template.html',
  styleUrls: ['./switch.style.scss'],
})
export class SwitchComponent implements OnInit, OnChanges {

  @Input() isChecked = true;
  @Input() isEnabled = true;
  @Input() isChangeable = true;
  @Input() isCheckedControl: FormControl = null;
  @Output() change = new EventEmitter();

  ngOnInit() {
    this.initCheckValue();
    this.isCheckedControl.valueChanges.subscribe(isChecked => {
      this.isChecked = isChecked;
      this.change.emit(this.isChecked);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isCheckedControl && changes.isCheckedControl.currentValue !== changes.isCheckedControl.previousValue) {
      this.initCheckValue();
    }
  }

  initCheckValue() {
    this.isCheckedControl = this.isCheckedControl || new FormControl(this.isChecked);
    this.isChecked = this.isCheckedControl.value;
  }

  toggle(event) {
    if (this.isEnabled) {
      event.stopPropagation();
      if (this.isChangeable) {
        this.isCheckedControl.setValue(!this.isChecked);
      } else {
        this.change.emit(this.isChecked);
      }
    }
  }
}

import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import * as _ from 'lodash';
import { DealerService } from '../../_common/services/dealer.service';
import {
  CurrentUserService, getVehicleBusinessType, PromptBarService,
  TokenService,
} from '@otr/website-common';
import { AuthCheck } from '../../_common/utils/authCheck';
import { PromptLevel } from '../../_common/constants/common';

@Component({
  selector: 'app-dealer-for-report-drag-bar',
  templateUrl: './dealer-for-report-drag-bar.component.html',
  styleUrls: ['./dealer-for-report-drag-bar.component.scss'],
  providers: [],
})
export class DealerForReportDragBarComponent implements OnInit {
  isDown = false;
  isMoved = false;
  disX = 0;
  disY = 0;
  private totalOffsetX = 0;
  private totalOffsetY = 0;

  current_dealer_name = ' ';

  private drag_tm = null;

  dealer_list = [];
  origin_list = [];

  select_area_options = [];
  select_area = null;

  @ViewChild('elem') elem: ElementRef;
  parent_elem = null;

  expand = false;

  search_text = '';
  select_dealer = null;

  constructor(
    private renderer: Renderer2,
    private dealerService: DealerService,
    private currentUserService: CurrentUserService,
    private tokenService: TokenService,
    private promptBarService: PromptBarService,
    private authCheck: AuthCheck,
  ) {

  }

  initDragElement() {
    this.parent_elem = this.elem.nativeElement.parentNode.parentNode.parentNode;
    _.delay(() => {
      this.totalOffsetX = this.parent_elem.offsetWidth - this.elem.nativeElement.offsetWidth;
      this.totalOffsetY = 65;
      this.setPosition(0, 0);
    }, 10);
  }

  onMouseout(event) {
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
      this.isDown = false;
    }
  }

  // bind darg and drop event
  onMousedown(e) {
    e.stopPropagation();
    if (e.button !== 0) { return false; }

    document.documentElement.onmouseup = this.onMouseup.bind(this);
    this.elem.nativeElement.onmouseup = this.onMouseup.bind(this);
    const loop = () => {
      this.isDown = true;
      e.preventDefault();
      this.disX = e.clientX;
      this.disY = e.clientY;

      document.documentElement.onmousemove = this.onMousemove.bind(this);
      this.elem.nativeElement.onmousemove = this.onMousemove.bind(this);

      this.renderer.setStyle(this.elem.nativeElement, 'cursor', 'move');
    };

    if (this.drag_tm) { clearTimeout(this.drag_tm); }
    this.drag_tm = setTimeout(loop, 100);
  }

  onMousemove(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isDown) {
      return false;
    }

    this.isMoved = true;
    this.setPosition(event.clientX, event.clientY);
  }

  onMouseup(event) {
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;

    }

    this.isDown = false;
    if (this.drag_tm) { clearTimeout(this.drag_tm); }
    document.documentElement.onmousemove = null;
    document.documentElement.onmouseup = null;
    this.elem.nativeElement.onmousemove = null;
    this.elem.nativeElement.onmouseup = null;

    this.renderer.setStyle(this.elem.nativeElement, 'cursor', 'pointer');
  }

  // calcaulte and set the drag bar position
  setPosition(clientX, clientY) {
    const pw = this.parent_elem.offsetWidth;

    let left = this.totalOffsetX + clientX - this.disX;
    if (left < 0) { left = 0; }

    let top = this.totalOffsetY + clientY - this.disY;
    if (top < 65) { top = 65; }

    const max_left = pw - this.elem.nativeElement.offsetWidth - 20;

    if (left > max_left) { left = max_left; }

    // TODO improve the calculate drag area efficiency.
    this.renderer.setStyle(this.elem.nativeElement, 'left', `${left}px`);
    this.renderer.setStyle(this.elem.nativeElement, 'top', `${top}px`);
  }

  async ngOnInit() {
    await this.dealerService.ready();
    this.origin_list = await this.dealerService.getAllDealerList();
    if (this.origin_list.length < 1) {
      this.renderer.setStyle(this.elem.nativeElement, 'display', `none`);
      return;
    }

    this.select_area_options = this.dealerService.getSelectOptionData();
    this.select_area = this.dealerService.getDealerBarSelectArea();
    this.dealer_list = this.dealerService.getDealerSelectList();
    sessionStorage.setItem('dealer_list', JSON.stringify(this.dealer_list));
    this.select_dealer = this.dealerService.getCurrentDealer();
    this.current_dealer_name = this.dealerService.getCurrentDealerName();

    this.initDragElement();
  }

  // fire when select area event triggered
  selectAreaChangeHandler(key) {
    this.select_area = key;
    this.dealerService.setDealerBarSelectArea(key);

    this.dealer_list = this.dealerService.getDealerSelectList();
    sessionStorage.setItem('dealer_list', JSON.stringify(this.dealer_list));
  }

  expandPanelHandler(e) {
    if (this.isMoved) {
      this.isMoved = false;
      return false;
    }
    this.expand = !this.expand;
    _.delay(() => {
      this.disX = e.clientX;
      this.disY = e.clientY;
      this.setPosition(e.clientX, e.clientY);
    }, 0);
  }

  selectDealerItem(item, push) {
    if (this.select_dealer && this.select_dealer.id === item.id) {
      return false;
    }

    this.select_dealer = item;
    this.dealerService.setCurrentDealer(item, push);
    this.current_dealer_name = item.name_dealer_branch;
  }

  selectDealerItemHandler(e, item) {
    this.selectDealerItem(item, true);
    this.expandPanelHandler(e);
    if (!_.isNil(getVehicleBusinessType()) &&
      !this.authCheck.isDealerGroupAuthorisedByBusinessType()) {
      this.promptBarService.show('当前选中经销商无此授权，请重新选择', PromptLevel.Error);
    }
  }

}

import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class FormUtilService {
  markAsTouched$: Subject<any> = new BehaviorSubject(false);

  formTraverseTree(control: AbstractControl, visitFn: (c: AbstractControl) => void = () => 0): void {
    visitFn(control);
    if (control instanceof FormGroup || control instanceof FormArray) {
      Object.keys(control.controls).forEach(key => {
        const subControl = control.controls[key];
        this.formTraverseTree(subControl, visitFn);
      });
    }
  }

  markFormAsUsed(form) {
    this.formTraverseTree(form, control => {
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  markFormAsTouched(form) {
    this.formTraverseTree(form, control => {
      control.markAsTouched();
    });
  }

  markFormAsUnTouched(form) {
    this.formTraverseTree(form, control => {
      control.markAsUntouched();
    });
  }

  updateValueAndValidityCompletely(form) {
    this.formTraverseTree(form, control => {
      control.updateValueAndValidity();
    });
  }
}

import { toEnum } from '../../_common/utils/enum';
import * as moment from 'moment';

export const ALL = { ALL: { text: '全部', value: 'ALL' } };
export const ALL_AGGREGATED = { ALL: { text: '全部 (加总)', value: 'ALL' } };

export const SERVICE_RECORD_CATEGORY_OPTIONS = {
  AFTERSALES: { text: '客户订单', value: 'AFTERSALES' },
  PRE_DELIVERY_INSPECTION: { text: 'PDI', value: 'PRE_DELIVERY_INSPECTION' },
  OPTION_COST: { text: '加装订单', value: 'OPTION_COST' },
};

export const DAILY_STATUS_OPTIONS = toEnum({
  DAILY_CREATED: { text: '当日创建', value: 'CREATED' },
  DAILY_CHECKED_IN: { text: '当日进车', value: 'CHECKED_IN' },
  DAILY_COMPLETED: { text: '当日完工', value: 'COMPLETED' },
  DAILY_CHECKED_OUT: { text: '当日出厂', value: 'CHECKED_OUT' },
  DAILY_INVOICED: { text: '当日结账', value: 'INVOICED' },
});

export const DEPARTMENT_CODE_OPTIONS = toEnum({
  ...ALL,
  REPAIR: { text: '维修部门', value: '维修部门' },
  METAL: { text: '钣金部门', value: '钣金部门' },
  PAINT: { text: '喷漆部门', value: '喷漆部门' },
  QUICK_REPAIR: { text: '快修部门', value: '快修部门' },
  FITTING: { text: '配件部门', value: '配件部门' },
});

export const SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS = toEnum({
  ...ALL,
  ...SERVICE_RECORD_CATEGORY_OPTIONS,
});

export const PARTS_RESERVATION_SERVICE_RECORD_CATEGORY_FOR_PC = toEnum({
  ...ALL,
  ...SERVICE_RECORD_CATEGORY_OPTIONS,
  OTC: { text: 'OTC', value: 'OTC' },
  ICT: { text: 'ICT', value: 'ICT' },
  DIMS: { text: 'DIMS', value: 'DIMS' },
  REMAN: { text: 'REMAN', value: 'REMAN' },
  WEBPARTS: { text: 'WEBPARTS', value: 'WEBPARTS' },
  PARTSgoal: { text: 'PARTSgoal', value: 'PARTSgoal' },
});

export const PARTS_RESERVATION_SERVICE_RECORD_CATEGORY_FOR_VAN = toEnum({
  ...ALL,
  ...SERVICE_RECORD_CATEGORY_OPTIONS,
  OTC: { text: 'OTC', value: 'OTC' },
  ICT: { text: 'ICT', value: 'ICT' },
  WEBPARTS: { text: 'WEBPARTS', value: 'WEBPARTS' },
});

export const SERVICE_RECORD_CATEGORY_WITH_ALL_AGGREGATED_OPTIONS = toEnum({
  ...ALL_AGGREGATED,
  ...SERVICE_RECORD_CATEGORY_OPTIONS,
});

export const SA_NAME_WITH_ALL_AGGREGATED_OPTIONS = toEnum({
  ...ALL_AGGREGATED,
});

export const SA_NAME_WITH_ALL_OPTIONS = toEnum({
  ...ALL,
});

export const FILTER_INPUT_TYPES = {
  TEXT_INPUT: 'TEXT_INPUT',
  DATE_RANGE: 'DATE_RANGE',
  DROPDOWN: 'DROPDOWN',
  MULTI_DROPDOWN: 'MULTI_DROPDOWN',
  SA_NAME_MULTI_DROPDOWN: 'SA_NAME_MULTI_DROPDOWN',
  DEALER_SA_NAME_MULTI_DROPDOWN: 'DEALER_SA_NAME_MULTI_DROPDOWN',
  CREATED_BY_MULTI_DROPDOWN: 'CREATED_BY_MULTI_DROPDOWN',
  SA_NAME_DROPDOWN: 'SA_NAME_DROPDOWN',
  SERVICE_TYPE_DROPDOWN: 'SERVICE_TYPE_DROPDOWN',
  LEAD_LOST_REASON_DROPDOWN: 'LEAD_LOST_REASON_DROPDOWN',
  GROUP_BUTTON: 'GROUP_BUTTON',
  VEHICLE_BUSINESS_TYPE_GROUP_BUTTON: 'VEHICLE_BUSINESS_TYPE_GROUP_BUTTON',
  TEXT: 'TEXT',
  LINE_STATUS_MULTI_DROPDOWN: 'LINE_STATUS_MULTI_DROPDOWN',
  DROPDOWN_INPUT: 'DROPDOWN_INPUT',
  DROPDOWN_DATE_RANGE: 'DROPDOWN_DATE_RANGE',
};

export const WAIT_FOR_PAYMENT_STATUS_OPTIONS = toEnum({
  IS_ALL: { text: '全部', value: '' },
  IS_ALL_UNPAID: { text: '全部未结清', value: 'IS_ALL_UNPAID' },
  IS_SOME_UNPAID: { text: '部分未结清', value: 'IS_SOME_UNPAID' },
});

export const VEHICLE_BUSINESS_TYPE_OPTION = toEnum({
  ALL: { text: '全部', value: 'ALL' },
  PC: { text: '乘用车', value: 'PC' },
  VAN: { text: '商务车', value: 'VAN' },
});

export const VEHICLE_BUSINESS_TYPE_OPTION_PC_VAN = toEnum({
  PC: { text: '乘用车', value: 'PC' },
  VAN: { text: '商务车', value: 'VAN' },
});

export const SHOULD_FETCH_REMOTE_DATA_TYPE = [
  FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
  FILTER_INPUT_TYPES.SERVICE_TYPE_DROPDOWN,
  FILTER_INPUT_TYPES.SA_NAME_MULTI_DROPDOWN,
  FILTER_INPUT_TYPES.DEALER_SA_NAME_MULTI_DROPDOWN,
  FILTER_INPUT_TYPES.CREATED_BY_MULTI_DROPDOWN,
  FILTER_INPUT_TYPES.LINE_STATUS_MULTI_DROPDOWN,
  FILTER_INPUT_TYPES.LEAD_LOST_REASON_DROPDOWN,
];

export const MONTHLY_PARTS_KPI = 'monthly-parts-kpi';
export const FFR_REPORT = 'ffr';
export const FFR_REPORT_DETAIL = 'ffr-detail';
export const FFR_REPORT_DATE_PARAM_PATTERN = 'YYYY-MM-DD';
export const FFR_REPORT_EXPORT_DATE_PARAM_PATTERN = 'YYYY.MM.DD';

export const PARTS_TYPE_COMMON_OPTIONS = [
  { description: 'ALL-包括所有零件类型', code: 'All' },
  { description: 'DRT-只包括DRT零件', code: 'DRT' },
  { description: 'ES2-只包括带ES2零件', code: 'ES2' },
  { description: 'Tool-只包括MB专用工具', code: 'TOOL' },
];

export const PARTS_TYPE_OPTIONS_FOR_PC = [
  { description: 'Other1-不包括DRT、带ES2零件和MB专用工具', code: 'Other1' },
  { description: 'Other2-不包括DRT和MB专用工具', code: 'Other2' },
];

export const PARTS_TYPE_OPTIONS_FOR_VAN = [{ description: 'Other-不包括DRT、带ES2零件和MB专用工具', code: 'Other' }];

export const SERVICE_TYPE_LIST_OPTIONS = [
  { description: 'ALL-全部', code: 'All' },
  { description: '服务类型为空', code: 'Empty' },
];
export const DEPARTMENT_CODE_LIST_OPTIONS = [
  { description: 'ALL-全部部门', code: 'All' },
  { description: 'W-维修部门', code: 'W' },
  { description: 'P-配件部门', code: 'P' },
];

export type FFRGroupCriteria =
  | 'DEPARTMENT_CODE'
  | 'SERVICE_TYPE'
  | 'VEHICLE_TYPE'
  | 'REORDER_CATEGORY'
  | 'ANALYSIS_CODE'
  | 'MARKETING_CODE'
  | 'PARTS_TYPE';

export interface PageDTO<T> {
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  numberOfElements: number;
  last: boolean;
  first: boolean;
  content: T[];
}

interface FFRResultDTO {
  supply: number;
  requirement: number;
  result: number;
}

export interface FFRReportDTO {
  dealerId: string;
  departmentCode: string;
  serviceType: string;
  vehicleType: string;
  reorderCategory: string;
  analysisCode: string;
  marketingCode: string;
  partsType: string;
  partialSuppliedInOneDay: FFRResultDTO;
  partialSuppliedInTwoDay: FFRResultDTO;
  fullySuppliedInOneDay: FFRResultDTO;
  fullySuppliedInTwoDay: FFRResultDTO;
  partialSuppliedInOneDayQuantity: FFRResultDTO;
  partialSuppliedInTwoDayQuantity: FFRResultDTO;
  fullySuppliedInOneDayQuantity: FFRResultDTO;
  fullySuppliedInTwoDayQuantity: FFRResultDTO;
}

export const RWO_REPORT_DATA_SIZE_EXCEEDS_LIST = [
  'monthly-revenue-statistics',
  'repair-sales-record-general-query',
  'non-checked-in-vehicle-statistics',
  'monthly-operating-statistics',
  'order-general-query',
  'checked-in-vehicle-statistics',
  'clocked-off-statistics',
  'wait-for-payment-order',
  'lost-sale-report',
  'service-measure-t1-completion-rate-report',
  'service-measure-completion-status-query',
  'parts-reservation-report',
  'warranty-order-status-query',
  'parts-sales-order-detail-report',
];

export const LINE_STATUS_OPTIONS = [
  { text: 'S', value: 'S' },
  { text: 'U', value: 'U' },
  { text: 'V', value: 'V' },
  { text: 'E', value: 'E' },
  { text: 'K', value: 'K' },
  { text: 'P', value: 'P' },
  { text: 'G', value: 'G' },
  { text: 'B', value: 'B' },
  { text: 'R', value: 'R' },
  { text: 'M', value: 'M' },
];

export enum ExportExceedMessage {
  DEFAULT = '报表数据量超出限制，无法导出，请缩小范围后重试',
  RESERVED = '导出数据不能超过15万条，请缩小范围',
}

export const MULTI_DROPDOWN_FIELD = [
  FILTER_INPUT_TYPES.MULTI_DROPDOWN,
  FILTER_INPUT_TYPES.SA_NAME_MULTI_DROPDOWN,
  FILTER_INPUT_TYPES.CREATED_BY_MULTI_DROPDOWN,
  FILTER_INPUT_TYPES.LINE_STATUS_MULTI_DROPDOWN,
];

export const ORDER_SOURCE_OPTIONS_FOR_PC = [
  { text: 'RWO', value: 'RWO' },
  { text: 'ICT', value: 'ICT' },
  { text: 'Webparts', value: 'Webparts' },
  { text: 'OTC', value: 'OTC' },
  { text: 'DIMS', value: 'DIMS' },
  { text: 'REMAN', value: 'REMAN' },
  { text: 'PARTSgoal', value: 'PARTSgoal' },
];

export const ORDER_SOURCE_OPTIONS_FOR_VAN = [
  { text: 'RWO', value: 'RWO' },
  { text: 'ICT', value: 'ICT' },
  { text: 'Webparts', value: 'Webparts' },
  { text: 'OTC', value: 'OTC' },
];

export const EXPORT_ERROR_CODE = {
  too_many_rows_be_exported: 'too_many_rows_be_exported',
  feign_goes_wrong: 'feign_goes_wrong',
};

export const FFR_EXPORT_ERROR_CODE = {
  too_many_rows_be_exported: 'too_many_rows_be_exported',
};

export const INSPECTION_ITEM_STATUS_DESCRIPTION = {
  HEALTHY: '绿',
  SUGGESTED: '黄',
  WARNING: '红',
};

export const TYRE_DEPTH_SOURCE_DESCRIPTION = {
  DEVICE: '自动',
  MANUAL: '手动',
};

export const TYRE_APPEARANCE_INSPECTION_DESCRIPTION = {
  APPEARANCE_NORMAL: '正常',
  TIRE_SURFACE_NAIL: '胎面扎钉',
  CRACKED_AGING: '龟裂老化',
  MINOR_TRAUMA: '轻微外伤',
  TIRE_SHOULDER_OR_TIRE_SIDE_NAIL: '胎肩/胎侧扎钉',
  CRACKING_AGING: '开裂老化',
  TIRE_BULGE: '轮胎鼓包',
  SEVERE_TRAUMA: '严重外伤',
};

export const TYRE_ECCENTRIC_WEAR_DESCRIPTION = {
  ECCENTRIC_WEAR_NORMAL: '正常',
  INSIDE: '内侧',
  OUTSIDE: '外侧',
  BOTH_SIDES: '两侧',
  BETWEEN: '中间',
};

export const ACCIDENT_CAR_LEAD_CREATE_TYPE = {
  AUTO_SMS: { text: '自动（短信）', value: 'auto_SMS' },
  AUTO_PING_AN_SERVICE_API: { text: '自动（保司API）', value: 'auto_PING_AN_SERVICE_API' },
  AUTO_HENG_XIN_SERVICE_API: { text: '自动（保司API）', value: 'auto_HENG_XIN_SERVICE_API' },
  AUTO_PICC_SERVICE_API: { text: '自动（保司API）', value: 'auto_PICC_SERVICE_API' },
  AUTO_CPIC_SERVICE_API: { text: '自动（保司API）', value: 'auto_CPIC_SERVICE_API' },
  AUTO_MY_STAR_SERVICE_API: { text: '自动（Mystar）', value: 'auto_MY_STAR_SERVICE_API' },
  MANUAL: { text: '手动', value: 'manual' },
};

export const ACCIDENT_CAR_LEAD_SOURCE = {
  INSURANCE_COMPANY: { text: '保险公司', value: 'INSURANCE_COMPANY' },
  ROADSIDE_SERVICE: { text: '400道路救援', value: 'ROADSIDE_SERVICE' },
  EXTERNAL_EXTENDING: { text: '外拓', value: 'EXTERNAL_EXTENDING' },
  INITIATIVE: { text: '主动送修', value: 'INITIATIVE' },
  INTERNAL_RECOMMEND: { text: '全员推修', value: 'INTERNAL_RECOMMEND' },
  CAR_OWNER_REPORT: { text: '车主上报', value: 'CAR_OWNER_REPORT' },
};

export const ACCIDENT_CAR_THIRD_TYPE = {
  TARGET_CAR: { text: '标的车', value: 'N' },
  THIRD_TYPE_CAR: { text: '三者车', value: 'Y' },
  TARGET_CAR_FOR_PICC: { text: '标的车', value: 'false' },
  THIRD_TYPE_CAR_FOR_PICC: { text: '三者车', value: 'true' },
};

export const ACCIDENT_CAR_SMS_SOURCE_OPTIONS = toEnum({
  ...ALL,
  INSURANCE_COMPANY: { text: '保险公司', value: 'INSURANCE_COMPANY' },
  ROADSIDE_SERVICE: { text: '400道路救援', value: 'ROADSIDE_SERVICE' },
});

export const HENGXIN_DEALER_LEAD_SOURCE_TIP = '针对恒信集团的经销商，线索来源“外拓”对应“一键报案”';

export const HENGXIN_DEALER_LEAD_CHANNEL_TIP = '针对恒信集团的经销商，线索渠道“外拓”对应“一键报案”';

export const TABLE_FIELD_ACCIDENT_CAR_LEAD_SOURCE = 'accidentCarLeadSource';

export const ACCIDENT_CAR_LEAD_SOURCE_OPTIONS = toEnum({
  ...ALL,
  ...ACCIDENT_CAR_LEAD_SOURCE,
});

export const ACCIDENT_CAR_LEAD_SOURCE_MULTI_OPTIONS = toEnum({
  ...ACCIDENT_CAR_LEAD_SOURCE,
});

export const ACCIDENT_CAR_LEAD_SOURCE_MULTI_INIT = [
  'SELECT_ALL',
  'INSURANCE_COMPANY',
  'ROADSIDE_SERVICE',
  'EXTERNAL_EXTENDING',
  'INTERNAL_TRANSMIT',
  'INITIATIVE',
  'INTERNAL_RECOMMEND',
  'CAR_OWNER_REPORT',
];

export const ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_OPTIONS = toEnum({
  ...ALL,
  PICC: { text: '人保', value: 'PICC' },
  PAIC: { text: '平安', value: 'PAIC' },
  CPIC: { text: '太保', value: 'CPIC' },
  OTHERS: { text: '其他保司', value: 'OTHERS' },
});

export const ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_IN_SMS_LIST_OPTIONS = toEnum({
  ...ALL,
  PICC: { text: '人保', value: 'PICC' },
  PAIC: { text: '平安', value: 'PINGAN' },
  CPIC: { text: '太保', value: 'CPIC' },
  OTHERS: { text: '其他保司', value: 'OTHERS' },
});

export const ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_BASE_MUl_OPTIONS = {
  CPIC: { text: '太保', value: 'CPIC' },
  PAIC: { text: '平安', value: 'PINGAN' },
  PICC: { text: '人保', value: 'PICC' },
  OTHERS: { text: '其他保司', value: 'OTHER' },
};

export const ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_MUl_OPTIONS = toEnum({
  ...ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_BASE_MUl_OPTIONS,
});

export const ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_MUl_OPTIONS_CONTAINS_EMPTY = toEnum({
  ...ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_BASE_MUl_OPTIONS,
  EMPTY: { text: '-', value: '-' },
});

export const ACCIDENT_CAR_LEAD_TYPE_MUl_OPTIONS = toEnum({
  MB_PRODUCT_LEAD: { text: '钣喷专享礼线索', value: 'MB_PRODUCT_LEAD' },
  CAR_INSURANCE_LEAD: { text: '车险线索（不含钣喷）', value: 'CAR_INSURANCE_LEAD' },
});

export const ACCIDENT_CAR_LEAD_TYPE_MUl_INIT = ['SELECT_ALL', 'MB_PRODUCT_LEAD', 'CAR_INSURANCE_LEAD'];

export const ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_MUl_INIT = ['SELECT_ALL', 'CPIC', 'PINGAN', 'PICC', 'OTHER'];

export const ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_MUl_INIT_CONTAINS_EMPTY = [...ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_MUl_INIT, '-'];

export const ACCIDENT_CAR_LEAD_SELF_INSURANCE = {
  IS_SELF_INSURANCE: { text: '自店', value: true },
  IS_NOT_SELF_INSURANCE: { text: '非自店', value: false },
};

export const ACCIDENT_CAR_LEAD_SELF_INSURANCE_OPTIONS = toEnum({
  ...ALL,
  ...ACCIDENT_CAR_LEAD_SELF_INSURANCE,
});

export const ACCIDENT_CAR_LEAD_CUSTOMER_TYPE_OPTIONS = toEnum({
  ...ALL,
});

export const ACCIDENT_CAR_LEAD_STATUS = {
  WAITING: { text: '待跟进', value: 'WAITING' },
  FOLLOWING: { text: '跟进中', value: 'FOLLOWING' },
  ASSIGNED: { text: '已分配外勤', value: 'ASSIGNED' },
  FIELDING: { text: '外勤中', value: 'FIELDING' },
  RESERVED: { text: '已预约', value: 'RESERVED' },
  ARRIVED: { text: '已到店', value: 'ARRIVED' },
  NOT_ARRIVED: { text: '未到店', value: 'NOT_ARRIVED' },
  REPAIRED: { text: '已维修', value: 'REPAIRED' },
  LOST: { text: '流失', value: 'LOST' },
  CLOSED: { text: '自动关闭', value: 'CLOSED' },
};

export const ACCIDENT_CAR_LEAD_STATUS_OPTIONS = toEnum({
  ...ALL,
  ...ACCIDENT_CAR_LEAD_STATUS,
});

export const ACCIDENT_CAR_LEAD_LOST_REASON_OPTIONS = toEnum({
  ...ALL,
});

export const ACCIDENT_CAR_LEAD_IS_TRUE_OR_FALSE = toEnum({
  ...ALL,
  IS_TRUE: { text: '是', value: true },
  IS_FALSE: { text: '否', value: false },
});

export const ACCIDENT_CAR_LEAD_MB_SMS_TAG = {
  IS_TRUE: { text: '有', value: true },
  IS_FALSE: { text: '无', value: false },
};

export const ACCIDENT_CAR_LEAD_MB_SMS_TAG_OPTIONS = toEnum({
  ...ALL,
  ...ACCIDENT_CAR_LEAD_MB_SMS_TAG,
});

export const ACCIDENT_CAR_LEAD_CONSOLIDATION_INIT = ['SELECT_ALL', 'NOT_DUPLICATE', 'DUPLICATE_UNDEAL', 'DUPLICATE_MERGED', 'DUPLICATE_NOT_MERGED'];

export const ACCIDENT_CAR_LEAD_CONSOLIDATION = {
  NOT_DUPLICATE: { text: '不重复', value: 'NOT_DUPLICATE' },
  DUPLICATE_UNDEAL: { text: '重复，待处理', value: 'DUPLICATE_UNDEAL' },
  DUPLICATE_MERGED: { text: '重复，已合并', value: 'DUPLICATE_MERGED' },
  DUPLICATE_NOT_MERGED: { text: '重复，不合并', value: 'DUPLICATE_NOT_MERGED' },
};

export const ACCIDENT_CAR_LEAD_CONSOLIDATION_OPTIONS = toEnum({
  ...ACCIDENT_CAR_LEAD_CONSOLIDATION,
});

export const ACCIDENT_CAR_LEAD_DISCARD = {
  WAITING_FOR_REVIEW: { text: '待审核线索', value: 'WAITING_FOR_REVIEW', abbreviation: '待审核' },
  REJECTED: { text: '驳回线索', value: 'REJECTED', abbreviation: '驳回' },
  APPROVED: { text: '异常流失线索', value: 'APPROVED', abbreviation: '异常流失' },
};

export const ACCIDENT_CAR_LEAD_DISCARD_OPTIONS = toEnum({
  ...ALL,
  ...ACCIDENT_CAR_LEAD_DISCARD,
});

export const DROPDOWN_DATE_RANGE_OPTIONS_DAY = {
  DAY: { text: '按日查找', value: 'day' },
};

export const DROPDOWN_DATE_RANGE_OPTION_MONTH = {
  MONTH: { text: '按月查找', value: 'month' },
};

export const DROPDOWN_DATE_RANGE_OPTION_HALF_YEAR = {
  HALF_YEAR: { text: '按半年查找', value: 'half-year' },
};

export const DROPDOWN_DATE_RANGE_OPTION_YEAR = {
  YEAR: { text: '按年查找', value: 'year' },
};

export const DROPDOWN_DATE_RANGE_OPTION_QUARTER = {
  QUARTER: { text: '按季查找', value: 'quarter' },
};

export const REFRESH_TIME = {
  text: '日报本次更新时间',
  key: 'refreshTime',
  format: 'YYYY.MM.DD HH:mm',
};

export const MONTH_AND_YEAR_REFRESH_TIME = {
  text: '月/年报本次更新时间',
  key: 'monthAndYearRefreshTime',
  format: 'YYYY.MM.DD HH:mm',
};

export const MONTH_SEASON_AND_YEAR_REFRESH_TIME = {
  text: '月/季/年报本次更新时间',
  key: 'monthAndQuarterAndYearRefreshTime',
  format: 'YYYY.MM.DD HH:mm',
};

export const MONTH_SEASON_AND_HALF_YEAR_REFRESH_TIME = {
  text: '月/季/半年/年报本次更新时间',
  key: 'monthAndQuarterAndYearRefreshTime',
  format: 'YYYY.MM.DD HH:mm',
};

export const MONTH_AND_HALF_YEAR_REFRESH_TIME = {
  text: '月/半年报本次更新时间',
  key: 'monthAndHalfYearRefreshTime',
  format: 'YYYY.MM.DD HH:mm',
};

export const ACCIDENT_CAR_REPORT_DROPDOWN_DATE_RANGE_SETTING_BY_ENVIRONMENT = {
  local: {
    startMinLimitValue: moment('2010-01-01'),
    endMinLimitValue: moment('2010-01-01'),
  },
  'fe-qa': {
    startMinLimitValue: moment('2010-01-01'),
    endMinLimitValue: moment('2010-01-01'),
  },
  qa: {
    startMinLimitValue: moment('2010-01-01'),
    endMinLimitValue: moment('2010-01-01'),
  },
  'fe-uat': {
    startMinLimitValue: moment('2022-04-24'),
    endMinLimitValue: moment('2022-04-24'),
  },
  uat: {
    startMinLimitValue: moment('2022-04-24'),
    endMinLimitValue: moment('2022-04-24'),
  },
  prod: {
    startMinLimitValue: moment('2022-05-17'),
    endMinLimitValue: moment('2022-05-17'),
  },
};

export const ACCIDENT_CAR_ABNORMAL_LEAD_DROPDOWN_DATE_RANGE_SETTING_BY_ENVIRONMENT = {
  local: {
    startMinLimitValue: moment('2010-01-01'),
    endMinLimitValue: moment('2010-01-01'),
  },
  'fe-qa': {
    startMinLimitValue: moment('2010-01-01'),
    endMinLimitValue: moment('2010-01-01'),
  },
  qa: {
    startMinLimitValue: moment('2010-01-01'),
    endMinLimitValue: moment('2010-01-01'),
  },
  'fe-uat': {
    startMinLimitValue: moment('2022-04-24'),
    endMinLimitValue: moment('2022-04-24'),
  },
  uat: {
    startMinLimitValue: moment('2022-04-24'),
    endMinLimitValue: moment('2022-04-24'),
  },
  prod: {
    startMinLimitValue: moment('2023-05-16'),
    endMinLimitValue: moment('2023-05-16'),
  },
};

export const ACCIDENT_CAR_INSURANCE_UPLOAD_DROPDOWN_DATE_RANGE_SETTING_BY_ENVIRONMENT = {
  local: {
    startMinLimitValue: moment('2010-01-01'),
    endMinLimitValue: moment('2010-01-01'),
  },
  'fe-qa': {
    startMinLimitValue: moment('2010-01-01'),
    endMinLimitValue: moment('2010-01-01'),
  },
  qa: {
    startMinLimitValue: moment('2010-01-01'),
    endMinLimitValue: moment('2010-01-01'),
  },
  'fe-uat': {
    startMinLimitValue: moment('2022-04-24'),
    endMinLimitValue: moment('2022-04-24'),
  },
  uat: {
    startMinLimitValue: moment('2022-04-24'),
    endMinLimitValue: moment('2022-04-24'),
  },
  prod: {
    startMinLimitValue: moment('2023-05-16'),
    endMinLimitValue: moment('2023-05-16'),
  },
};

export const ACCIDENT_CAR_SMS_LIST_REPORT_DROPDOWN_DATE_RANGE_SETTING_BY_ENVIRONMENT = {
  'fe-qa': {
    startMinLimitValue: moment('2020-10-09'),
    endMinLimitValue: moment('2020-10-09'),
  },
  qa: {
    startMinLimitValue: moment('2020-10-09'),
    endMinLimitValue: moment('2020-10-09'),
  },
  'fe-uat': {
    startMinLimitValue: moment('2022-11-21'),
    endMinLimitValue: moment('2022-11-21'),
  },
  uat: {
    startMinLimitValue: moment('2022-11-21'),
    endMinLimitValue: moment('2022-11-21'),
  },
  prod: {
    startMinLimitValue: moment('2022-12-13'),
    endMinLimitValue: moment('2022-12-13'),
  },
};

export const KPI_REPORT_HEAD_TIP_FOR_VAN = 'Other-不包括DRT、带ES2零件和MB专用工具';
export const KPI_REPORT_HEAD_TIP_FOR_PC = 'Other2-不包括DRT和MB专用工具';

export const ACCIDENT_CAR_SELF_INSURANCE = {
  SELF_INSURANCE: { text: '自店', value: 1 },
  NON_SELF_INSURANCE: { text: '非自店', value: 0 },
  EMPTY: { text: '-', value: 2 },
};

export const ACCIDENT_CAR_SELF_INSURANCE_NAME_OPTIONS = toEnum({
  ...ACCIDENT_CAR_SELF_INSURANCE,
});

export const ACCIDENT_CAR_SELF_INSURANCE_NAME = ['SELECT_ALL', '1', '0', '2'];

export const VEHICLE_TYPE_OPTIONS = toEnum({
  ALL: { text: '全部', value: '' },
  PC: { text: '乘用车', value: 'PC' },
  VAN: { text: '商务车', value: 'VAN' },
});

export const ADDON_STATUS_OPTIONS = ['提报', '成交', '失销'];

export const getVehicleAgeOptions = () =>
  Array.from(new Array(21).keys()).map((index) => ({
    text: `${index}年`,
    value: index,
  }));

export const ADDON_DIMENSION_STATUS_OPTIONS = toEnum({
  ALL: { text: '全部', value: 'ALL' },
  MARKETING_CODE: { text: '按市场代码排序', value: 'MARKETING_CODE' },
  VEHICLE_AGE: { text: '按车龄排序', value: 'VEHICLE_AGE' },
  CLASS_LEVEL: { text: '按车系排序', value: 'CLASS_LEVEL' },
});
export const VEHICLE_AGE_RANGE_OPTIONS = toEnum({
  WITHIN_ONE_YEAR: { text: '0～1年', value: 'WITHIN_ONE_YEAR' },
  ONE_TO_THREE_YEARS: { text: '1～3年', value: 'ONE_TO_THREE_YEARS' },
  THREE_TO_FIVE_YEARS: { text: '3～5年', value: 'THREE_TO_FIVE_YEARS' },
  FIVE_TO_EIGHT_YEARS: { text: '5～8年', value: 'FIVE_TO_EIGHT_YEARS' },
  EIGHT_TO_TWELVE_YEARS: { text: '8～12年', value: 'EIGHT_TO_TWELVE_YEARS' },
  MORE_THAN_TWELVE_YEARS: { text: '12年以上', value: 'MORE_THAN_TWELVE_YEARS' },
});
export const PROJECT_TYPE_OPTIONS = toEnum({
  ADDON: { text: '增项', value: '增项' },
  INSPECTION: { text: '预检项', value: '预检项' },
});
export const LOST_SALES_DIMENSION_STATUS_OPTIONS = toEnum({
  ALL: { text: '全部', value: 'ALL' },
  PROJECT_TYPE: { text: '按项目类型排序', value: 'PROJECT_TYPE' },
  MARKETING_CODE: { text: '按市场代码排序', value: 'MARKETING_CODE' },
  VEHICLE_AGE: { text: '按车龄排序', value: 'VEHICLE_AGE' },
  CLASS_LEVEL: { text: '按车系排序', value: 'CLASS_LEVEL' },
});

export const RESERVATION_TYPE = toEnum({
  RESERVED: { text: '预约到店', value: 'RESERVED' },
  WALK_IN: { text: '随机进店', value: 'WALK_IN' },
});

export const SIGN_CHANNEL = toEnum({
  WECHAT: { text: '微信', value: 'WECHAT' },
  SMS: { text: '短信', value: 'SMS' },
  TABLET: { text: '平板', value: 'TABLET' },
  OFFLINE: { text: '线下办理', value: 'OFFLINE' },
  SIGNATURE_FREE: { text: '免签', value: 'SIGNATURE_FREE' },
  APP: { text: 'APP', value: 'APP' },
});

export const SIGN_TYPE = toEnum({
  ONLINE: { text: '线上签署', value: 'ONLINE' },
  OFFLINE: { text: '线下办理', value: 'OFFLINE' },
  SIGNATURE_FREE: { text: '免签', value: 'SIGNATURE_FREE' },
});

export const DOCUMENT_TYPE = toEnum({
  PREORDER: { text: '准施工单', value: 'PREORDER' },
  JOB_CARD: { text: '施工单', value: 'JOB_CARD' },
  QUOTATION: { text: '估价单', value: 'QUOTATION' },
  EXISTING_INVOICE_PROFORMA: { text: '账单预览', value: 'EXISTING_INVOICE_PROFORMA' },
  EXISTING_REFUND_PROFORMA: { text: '退账单预览', value: 'EXISTING_REFUND_PROFORMA' },
  INVOICE_PROFORMA: { text: '账单', value: 'INVOICE_PROFORMA' },
  REFUND_PROFORMA: { text: '退账单', value: 'REFUND_PROFORMA' },
  SERVICE_CONTRACT: { text: '服务合约', value: 'SERVICE_CONTRACT' },
  OUT_SOURCED_LABOR: { text: '外加工项', value: 'OUT_SOURCED_LABOR' },
  CUSTOMER_SELF_TAKE_AWAY_CONFIRMATION_LETTER: { text: '客户自取动力蓄电池确认函', value: 'CUSTOMER_SELF_TAKE_AWAY_CONFIRMATION_LETTER' },
  CSCOC_A: { text: '客户服务服务公约履行确认书_A', value: 'CSCOC_A' },
  CSCOC_B: { text: '客户服务服务公约履行确认书_B', value: 'CSCOC_B' },
});

export const DOCUMENT_STATUS = toEnum({
  TO_BE_SIGNED: { text: '待签署', value: 'TO_BE_SIGNED' },
  INITIATED: { text: '已发起', value: 'INITIATED' },
  SIGNED: { text: '已签署', value: 'SIGNED' },
  CANCELLED: { text: '已取消', value: 'CANCELLED' },
  EXPIRED: { text: '已失效', value: 'EXPIRED' },
  OFFLINE_HANDLING: { text: '线下办理', value: 'OFFLINE_HANDLING' },
  COMPLETED: { text: '已完成', value: 'COMPLETED' },
});

export const ADDITIONAL_STATUS = toEnum({
  CHECK_IN_TO_BE_SIGNED_CHECK_OUT_TO_BE_SIGNED: { text: '入车待签，出车待签', value: 'CHECK_IN_TO_BE_SIGNED_CHECK_OUT_TO_BE_SIGNED' },
  CHECK_IN_INITIATED_CHECK_OUT_TO_BE_SIGNED: { text: '入车已发起，出车待签', value: 'CHECK_IN_INITIATED_CHECK_OUT_TO_BE_SIGNED' },
  CHECK_IN_SIGNED_CHECK_OUT_TO_BE_SIGNED: { text: '入车已签，出车待签', value: 'CHECK_IN_SIGNED_CHECK_OUT_TO_BE_SIGNED' },
  CHECK_IN_SIGNED_CHECK_OUT_INITIATED: { text: '入车已签，出车已发起', value: 'CHECK_IN_SIGNED_CHECK_OUT_INITIATED' },
  CHECK_IN_SIGNED_CHECK_OUT_SIGNED: { text: '入车已签，出车已签', value: 'CHECK_IN_SIGNED_CHECK_OUT_SIGNED' },
});

export const COMPLETION_STATUS = ['已完成', '进行中', '已拒绝', '未完成', '未进厂', '待进店'];

export const TREND_DATE_LIMIT_VALUE_RANGE_BY_MONTH = 12;
export const DATE_LIMIT_VALUE_RANGE_BY_MONTH = 60;
export const DATE_RANGE_LIMITE_IN_FIVE_YEARS = {
  [DROPDOWN_DATE_RANGE_OPTION_MONTH.MONTH.value]: DATE_LIMIT_VALUE_RANGE_BY_MONTH,
  [DROPDOWN_DATE_RANGE_OPTION_QUARTER.QUARTER.value]: DATE_LIMIT_VALUE_RANGE_BY_MONTH,
  [DROPDOWN_DATE_RANGE_OPTION_HALF_YEAR.HALF_YEAR.value]: DATE_LIMIT_VALUE_RANGE_BY_MONTH,
  [DROPDOWN_DATE_RANGE_OPTION_YEAR.YEAR.value]: 4,
};

import { Subject } from 'rxjs';

export class CustomEventEmitter {
  subjects = {};

  emit(eventName, event?) {
    this.subjects[eventName] = this.subjects[eventName] || new Subject();
    this.subjects[eventName].next(event);
  }

  on(eventName, listener) {
    this.subjects[eventName] = this.subjects[eventName] || new Subject();
    this.subjects[eventName].subscribe(listener);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TimeUnit } from '../constants/date';
import { dateParse } from '../utils/date-parse';
import * as _ from 'lodash';

@Pipe({ name: 'minutes' })
export class MinutesPipe implements PipeTransform {
  transform(millisecond: string): string {
    const minutes = Math.ceil(parseInt(millisecond, 10) / TimeUnit.MINUTE);
    return `${minutes}分钟`;
  }
}

interface DateFormatOption {
  format?: string;
  default?: string;
}

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
  transform(value: any, option: DateFormatOption = { default: '-', format: 'yyyy年MM月dd日' }): any {
    return _.isNil(value) ? option.default : dateParse(value, option.format);
  }
}

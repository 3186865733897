import { Component } from '@angular/core';
import { IHeaderGroupParams } from 'ag-grid';
import * as _ from 'lodash';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';

@Component({
  templateUrl: 'tooltips-header.template.html',
  styleUrls: ['tooltips-header.style.scss'],
})
export class GroupHeaderStringTooltipsComponent implements IHeaderGroupAngularComp {
  displayName: string;
  headerTooltip: string;

  agInit(params: IHeaderGroupParams): void {
    const colDef = params.columnGroup.getColGroupDef();
    this.displayName = params.displayName;
    this.headerTooltip = _.get(colDef, 'headerTooltip');
  }
}

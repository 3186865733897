import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';

const DEFAULT_DISPLAY_SEPARATOR = '+';

const TABLE_FOOTER_HEIGHT = 60;

export enum CheckBoxTheme {
  DEFAULT = 'DEFAULT',
  CUSTOMIZE = 'CUSTOMIZE',
}

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.html',
  styleUrls: ['./multi-select-dropdown.scss'],
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {
  selfClick: boolean;
  title: string = '';
  isVisible: boolean = false;
  VALUE_SEPARATOR: string = ',';
  shouldUpturned: boolean = false;
  selectedValues: string | any[] = [];
  @ViewChild('optionsEle') optionsEle: ElementRef;
  @Input() separator: string = DEFAULT_DISPLAY_SEPARATOR;
  @Input() styles: Object;
  @Input() textStyle: Object;
  @Input() placeholder: string;
  @Input() options: any[];
  @Input() shouldShowValueForTitle: boolean = false;
  @Input() optionStyle: Object;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() initialSelected: Array<any>;
  @Input() upturnedConfig: any = { canUpturned: false, optionsHeight: 0 };
  @Input() shouldJoinValue: boolean = true;
  @Input() checkBoxTheme: string = CheckBoxTheme.DEFAULT;
  @Input() initialSelectedAll: boolean = false;
  @Input() isLoadFailed: boolean = false;
  @Input() disabled: boolean = false;
  themes = CheckBoxTheme;

  ngOnInit(): void {
    this.initSelectedOptions();
    this.updateSelectedOption();
  }

  initSelectedOptionsOnce() {
    if (this.initialSelectedAll) {
      this.selectAll(true);
      return;
    }
    this.initSelectedOptions();
  }

  initSelectedOptions() {
    if (!_.isNil(this.initialSelected)) {
      this.options = _.forEach(this.options, option => {
        option.selected = _.includes(_.map(this.initialSelected, _.toString), option.value.toString());
      });
    } else {
      this.options = _.forEach(this.options, option => {
        option.selected = false;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.initSelectedOptionsOnce();
    }
    this.updateSelectedOption();
  }

  @HostListener('document:click')
  onClick() {
    if (!this.selfClick) {
      this.isVisible = false;
    }
    this.selfClick = false;
  }

  toggleDropdown() {
    if (!this.isVisible) {
      this.shouldUpturned = (window.innerHeight - this.optionsEle.nativeElement.getBoundingClientRect().top
        - this.optionsEle.nativeElement.getBoundingClientRect().height - TABLE_FOOTER_HEIGHT) <= this.upturnedConfig.optionsHeight;
    }
    this.isVisible = !this.isVisible;
  }

  isOptionSelected(option) {
    return option.selected;
  }

  setSelected(option) {
    option.selected = !option.selected;
    if (option.isSelectAllOption || this.isSelectAllExceptSelectAllOption()) {
      this.selectAll(option.selected);
    } else {
      this.uncheckSelectAllOnSomeOptionUnchecked();
    }
    this.updateSelectedOption();
  }

  isSelectAllExceptSelectAllOption(): boolean {
    const optionsExceptSelectAllOption = _.filter(this.options, option => !option.isSelectAllOption);
    return _.every(optionsExceptSelectAllOption, option => option.selected);
  }

  reset() {
    this.initSelectedOptions();
    this.updateSelectedOption();
  }

  onReset() {
    this.initSelectedOptionsOnce();
    this.updateSelectedOption();
  }

  selectAll(selectedValue = false) {
    this.options = _.map(this.options, option => ({ ...option, selected: selectedValue }));
  }

  getCheckedSelectAll() {
    return _.find(this.options, option => option.isSelectAllOption && option.selected);
  }

  uncheckSelectAllOnSomeOptionUnchecked() {
    this.options = _.map(this.options, option => option.isSelectAllOption ? ({ ...option, selected: false }) : option);
  }

  updateSelectedOption() {
    const selectedOptions = _.filter(this.options, option => option.selected);
    const selectedOption = _.reduce(selectedOptions, (result: any, option: any) => {
      return {
        value: result.value + option.value + this.VALUE_SEPARATOR,
        title: result.title + option.text + this.separator,
      };
    }, { title: '', value: '' });
    if (this.shouldShowValueForTitle) {
      this.title = this.getValueForTitle(selectedOption.value);
    } else {
      this.title = _.trimEnd(selectedOption.title, this.separator) || this.placeholder;
    }
    this.title = _.get(this.getCheckedSelectAll(), 'text', this.title);
    const changeValues = this.shouldJoinValue ? this.trimValue(selectedOption.value) : _.map(selectedOptions, 'value');
    this.selectedValues = changeValues;
    this.onChange.emit(changeValues);
  }

  onMouseClick() {
    this.selfClick = true;
  }

  getValueForTitle(value) {
    return this.trimValue(value).replace(new RegExp(this.VALUE_SEPARATOR, 'g'), this.separator) || this.placeholder;
  }

  trimValue(value) {
    return  _.trimEnd(value, this.VALUE_SEPARATOR);
  }

  selectOptionWithValue(value: string) {
    if (value) {
      const optionValues = value.split(this.VALUE_SEPARATOR);
      this.options = this.options.map((option) => {
        if (_.includes(optionValues, option.value)) {
          return {...option, selected: true};
        }
        return {...option, selected: false};
      });
      return;
    }
    this.options = this.options.map((option) => {
      return {...option, selected: false};
    });
  }
}

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  template: `
    <span *ngIf="isButton" class="text-button" (click)="onClick()">{{text}}</span>
    <div *ngIf="!isButton" class="text">{{text}}</div>
  `,
  styleUrls: ['./text-or-button.style.scss'],
})
export class TextOrButtonComponent implements AgRendererComponent {
  params;
  text: string = '';
  isButton: boolean = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const cellRendererParams = this.params.colDef.cellRendererParams;
    this.isButton = getValue(cellRendererParams.isButton, this.params);
    this.text = getValue(cellRendererParams.text, this.params);
  }

  onClick() {
    'onClick' in this.params.column.colDef.cellRendererParams ?
      this.params.column.colDef.cellRendererParams.onClick(this.params)
      :
      ((): void => {})();
  }

  refresh(params: any): boolean {
    return false;
  }
}

import * as _ from 'lodash';
import { getRangeOverlap, isEmptyValue } from './common';
import { getDateAtLastSecond, getDateAtZero } from './date';
import { FILTER_TYPE } from '../constants/common';
import * as moment from 'moment';

export const validateHeaderFilters = (columns, gridApi, filterField) => {
  return _.chain(columns)
    .filter(column => !column[filterField])
    .map(column =>
      gridApi
        .getFilterInstance(column.field)
        .getFrameworkComponentInstance()
        .isFilterValid(),
    )
    .every()
    .value();
};

export const clearHeaderFilter = (columns: Array<any>, gridApi, filterField) => {
  _.map(_.filter(columns, column => !column[filterField]), column =>
    gridApi
      .getFilterInstance(column.field)
      .getFrameworkComponentInstance()
      .setModel(null),
  );
};

interface FilterRawValue {
  type: string;
  from: string;
  to: string;
}

export const formatMultiFilter = filterValue =>
  _.reduce(
    filterValue,
    (previousFilterObject, filter: FilterRawValue) =>
      filter.from && filter.to
        ? {
          ...previousFilterObject,
          [filter.type]: {
            from: isEmptyValue(filter.from) ? null : getDateAtZero(new Date(filter.from)),
            to: isEmptyValue(filter.to) ? null : getDateAtLastSecond(new Date(filter.to)),
          },
        }
        : previousFilterObject,
    {},
  );

export const formatMultiOrderFilter = filterValues =>
  _.chain(filterValues)
    .omitBy(filterValue => isEmptyValue(filterValue) || _.every(filterValue, isEmptyValue))
    .mapValues((filterValue: any) => {
      if (_.isPlainObject(filterValue)) {
        return {
          from: isEmptyValue(filterValue.from) ? null : getDateAtZero(new Date(filterValue.from)),
          to: isEmptyValue(filterValue.to) ? null : getDateAtLastSecond(new Date(filterValue.to)),
        };
      }
      if (_.isArray(filterValue)) {
        return filterValue;
      }
      return _.split(filterValue, ';');
    })
    .value();

export const formatMultiSearch = (searchValues: Array<{ type: string; value: string }>) => {
  const searchObject = _.reduce(
    searchValues,
    (result, searchItem) => {
      if (isEmptyValue(searchItem.value)) {
        return result;
      }
      if (result[searchItem.type]) {
        return {
          ...result,
          [searchItem.type]: `${result[searchItem.type]};${searchItem.value}`,
        };
      }
      return {
        ...result,
        [searchItem.type]: searchItem.value,
      };
    },
    {},
  );
  return _.chain(searchObject)
    .mapValues((searchValue: string) => _.split(searchValue, ';'))
    .mapValues((searchValue: Array<string>) => _.map(searchValue, _.trim))
    .mapValues(_.compact)
    .mapValues(_.uniq)
    .value();
};

export const validateFilterValues = filterValues => _.every(filterValues, value => !_.isNil(value));

export const groupFilters = (allFilters, filterKeys, searchKeys) => ({
  filter: _.pick(allFilters, filterKeys),
  search: _.pick(allFilters, searchKeys),
});

export const groupFilter = (allFilters, filterKeys) => ({
  filter: _.pick(allFilters, filterKeys),
});

export const deleteNullValue = object => _.omitBy(object, _.isNil);

const getArrayFilterIntersection = (leftArray, rightArray) =>
  _.isEmpty(_.intersection(leftArray, rightArray)) ? null : _.intersection(leftArray, rightArray);

const mergeRule = (filterFormValue, headerFilterValue) => {
  if (!filterFormValue || !headerFilterValue) {
    return filterFormValue || headerFilterValue;
  }
  if (_.isArray(filterFormValue)) {
    return getArrayFilterIntersection(filterFormValue, headerFilterValue);
  }
  return getRangeOverlap(filterFormValue, headerFilterValue);
};

export const mergeFilters = (filterFormValues, headerFilterValues) =>
  _.mergeWith(_.cloneDeep(filterFormValues), headerFilterValues, mergeRule);

export const formatFilters = filterValues =>
  _.mapValues(filterValues, filterValue => (_.isArray(filterValue) ? _.join(filterValue, ';') : filterValue));

export const getSCGroupsWithNoEmptySC = (groupsInfos: Array<any>) =>
  _.reject(groupsInfos, group => _.isEmpty(group.scs));

export const formatFilter = (value, key) => {
  if (value.filterType) {
    return value.filterType === FILTER_TYPE.NONE ? { [`${key}Empty`]: 'true' } : { [key]: value.value };
  }
  if (!_.isUndefined(value.from) || !_.isUndefined(value.to)) {
    return { [key]: [moment(value.from).toISOString() || '', moment(value.to).toISOString() || ''] };
  }
  return { [key]: value };
};

export const formatGridFilters = filters =>
  _.reduce(
    filters,
    (formatted, value, key) => ({
      ...formatted,
      ...formatFilter(value, key),
    }),
    {},
  );

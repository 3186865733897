import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportCenterApi } from '../apis/report-center';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { REPORT_LIST } from '../constants/report-config-map';
import { DynamicColumnDTO, MonthlyKpiReportDto, OperatorDto } from '../models/parts-report';
import { FFRGroupCriteria, FFRReportDTO, PageDTO } from '../constants/report';
import { DealerAllowance } from '../models/dealer-allowance';
import { DashboardQueryParams, IDashboardLeadOverallInfo } from '../models/dashboard';

@Injectable()
export class ReportCenterService {

  constructor(private reportCenterApi: ReportCenterApi,
              private router: Router,
  ) {
  }

  fetchSaName(): Observable<any> {
    return this.reportCenterApi.fetchSaName();
  }

  fetchSaNameByDealer(): Observable<any> {
    return this.reportCenterApi.fetchSaNameByDealer();
  }

  fetchHengxinDealer(): Observable<any> {
    return this.reportCenterApi.fetchHengxinDealer();
  }

  fetchFounderData(dealerId): Observable<OperatorDto[]> {
    return this.reportCenterApi.fetchFounderData(dealerId, true);
  }

  fetchLeadLostReason(): Observable<any> {
    return this.reportCenterApi.fetchLeadLostReason();
  }

  fetchReportData<T>(name, filter, currentPage?, itemsPerPage?): Observable<T> {
    return this.reportCenterApi.fetchReportData(name, filter, currentPage, itemsPerPage);
  }

  getConfig() {
    return _.chain(REPORT_LIST)
      .flatMap('list')
      .find(router => router.link === this.router.url.replace('?key=-temp', ''))
      .get('config')
      .value();
  }

  downloadReportFile(name: string, filter: string): Observable<any>  {
    return this.reportCenterApi.downloadReportFile(name, filter, true, true);
  }

  fetchServiceType(): Observable<any> {
    return this.reportCenterApi.fetchServiceType();
  }

  getPartsKpiReport(
    reportName: string,
    startYear: string,
    startMonth: string,
    endYear: string,
    endMonth: string,
  ): Observable<MonthlyKpiReportDto[]> {
    return this.reportCenterApi.getPartsKpiReport(
      reportName,
      startYear,
      startMonth,
      endYear,
      endMonth,
      false,
    );
  }

  getMonthlyPartsKpiDynamicColumn(): Observable<DynamicColumnDTO[]> {
    return this.reportCenterApi.getMonthlyPartsKpiDynamicColumn();
  }

  downloadPartsKpiReport(
    reportName: string,
    startYear: string,
    startMonth: string,
    endYear: string,
    endMonth: string,
  ): Observable<any> {
    return this.reportCenterApi.downloadPartsKpiReport(
      reportName,
      startYear,
      startMonth,
      endYear,
      endMonth,
      true,
    );
  }

  getFFRReport(
    reportName: string,
    startDate: string,
    endDate: string,
    partsType: string,
    serviceType: string,
    departmentCode: string,
    criteria: FFRGroupCriteria[],
    page: number,
    size: number,
  ): Observable<PageDTO<FFRReportDTO>> {
    return this.reportCenterApi.getFFRReport(
      reportName,
      startDate,
      endDate,
      partsType,
      serviceType,
      departmentCode,
      criteria,
      page,
      size,
    );
  }

  downloadFFRReportExcel(
    reportName: string,
    startDate: string,
    endDate: string,
    partsType: string,
    serviceType: string,
    departmentCode: string,
    criteria: FFRGroupCriteria[],
  ): Observable<any> {
    return this.reportCenterApi.downloadFFRReportExcel(
      reportName,
      startDate,
      endDate,
      partsType,
      serviceType,
      departmentCode,
      criteria,
      true,
    );
  }

  getLineStatus(): Observable<any> {
    return this.reportCenterApi.getLineStatus();
  }

  getMdpReport(startDate: any, dateType: any, category: any): Observable<any> {
    return this.reportCenterApi.getMdpReport(startDate, dateType, category);
  }

  getIbgReportList(param): Observable<any> {
    return this.reportCenterApi.getIbgReportList(param);
  }

  exportMdpReportDetail(startDate: any, dateType: any): Observable<any> {
    return this.reportCenterApi.exportMdpReportDetail(startDate, dateType);
  }

  exportMdpReportData(startDate: any, dateType: any, category: any): Observable<any> {
    return this.reportCenterApi.exportMdpReportData(startDate, dateType, category);
  }

  downloadIbgReport(filter) {
    return this.reportCenterApi.getIbgReportFile(filter);
  }

  getLeadData(filter: DashboardQueryParams): Observable<any> {
    return this.reportCenterApi.getLeadData(filter);
  }

  getLeadOverallData(filter: DashboardQueryParams): Observable<IDashboardLeadOverallInfo> {
    return this.reportCenterApi.getLeadOverallData(filter);
  }

  getTrendAnalysisDashboard(filter: DashboardQueryParams): Observable<any> {
    return this.reportCenterApi.getTrendAnalysisDashboard(filter);
  }

  getInsuranceAnalysisDashboard(filter: DashboardQueryParams): Observable<any> {
    return this.reportCenterApi.getInsuranceAnalysisDashboard(filter);
  }

  getLeadAnalysisDashboard(filter: DashboardQueryParams): Observable<any> {
    return this.reportCenterApi.getLeadAnalysisDashboard(filter);
  }

  getAddonDetailFilter(): Observable<any> {
    return this.reportCenterApi.getAddonDetailFilter();
  }

  getAddonDetailTechniciansFilter(start: string, end: string): Observable<any> {
    return this.reportCenterApi.getAddonDetailTechniciansFilter(start, end);
  }

  getAddonLostSalesFilter(): Observable<any> {
    return this.reportCenterApi.getAddonLostSalesFilter();
  }

  getReportData(reportName: string, filter: any): Observable<any> {
    return this.reportCenterApi.getReportData(reportName, filter);
  }

  getReportsConfig(type: string): Observable<any> {
    return this.reportCenterApi.getReportsConfig(type);
  }

  downloadReportData(reportName: string, filter: any): Observable<any> {
    return this.reportCenterApi.downloadReportData(reportName, filter, true);
  }

  getAddOnSummaryFilter(): Observable<any> {
    return this.reportCenterApi.getAddonSummaryFilter();
  }

  getDealerAllowanceData(year: string, rightType: string): Observable<DealerAllowance> {
    return this.reportCenterApi.getDealerAllowanceData(year, rightType);
  }
}

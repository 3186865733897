import { Injectable } from '@angular/core';
import { GET, Headers, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AdditionProductApi extends RebirthHttp {

  public templateUploadUri = '/api/aftersales/website/service-contract/retail-prices/additional-product/template';
  public dealerPriceUploadUri = '/api/aftersales/website/service-contract/retail-prices/additional-product/dealerPrice';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/aftersales/website/service-contract/retail-prices/additional-product/template')
  @Headers({ isExportFile: true })
  getRetailPricesConfigTemplate(): Observable<any> {
    return null;
  }

  @GET('/api/aftersales/website/service-contract/retail-prices/additional-product/dealerPrice')
  @Headers({ isExportFile: true })
  getDealerRetailPrices(): Observable<any> {
    return null;
  }
}

import { Injectable } from '@angular/core';
import { QuotationApi } from '../../../../_common/api/quotation';
import { Observable } from 'rxjs';

@Injectable()
export class QuotationService {
  constructor(private quotationApi: QuotationApi) {
  }

  getQuotationsByLeadId(leadId: string): Observable<any> {
    return this.quotationApi.getQuotationsByLeadId(leadId);
  }

  getQuotationsByLeadIdMod(leadId: string): Observable<any> {
    return this.quotationApi.getQuotationsByLeadIdMod(leadId);
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CtiApi } from '../api/cti';
import { SpinnerService } from '@otr/website-common';
import { environment } from '../../../environments/environment';
import { SelectType } from 'app/_layout/cti-main/cti-main.component';
import { phoneNumFormat } from '../../_layout/cti-main/utils';

@Injectable()
export class CtiCallService {
  customerStatus: string = '';
  telephonistStatus: string = '';
  eventStatus: string = '';
  operatorInfo = {};
  ctiSubject: Subject<any> = new Subject<any>();
  ctiPhoneNumSubject: Subject<any> = new Subject<any>();
  addCustomerAndLeadSubject: Subject<any> = new Subject<any>();
  addAfterSalesSubject: Subject<any> = new Subject<any>();
  afterCallInfo = {};
  callOutPhoneNum: string = '';
  callInPhoneNum: string = '';
  callOutCustomerId: string = '';
  shouldShowInCallBar: boolean = false;
  shouldShowOutCallBar: boolean = false;
  shouldFoldCallOutPanel: boolean = true;
  shouldShowAddCustomerBtn: boolean = true;
  shouldShowAddCustomerConfirm: boolean = false;
  shouldShowAddAfterSalesConfirm: boolean = false;
  telephonists = [];
  inCallNotificationObj;
  isTransferOver = false;
  telephonistListStatus = {
    isDialing: false,
    isCalling: false,
    isFailed: false,
    calledId: '',
    callStartAt: null,
    transferOverLeft: 0,
  };
  shouldAddAfterSalesWithPhoneSubject: Subject<any> = new Subject<any>();
  shouldAddAfterSalesWithPhoneStatus: boolean = false;
  ctiIntelligentAssistantCurrentStatus: string = '';
  shouldShowSelectTypeModal = false;
  selectTypeModalSubject = new Subject<SelectType>();

  constructor(private ctiApi: CtiApi,
              private spinnerService: SpinnerService) {}

  setOperatorInfo(params) {
    this.operatorInfo = _.assign(this.operatorInfo, params);
  }

  getOperatorInfo() {
    return this.operatorInfo;
  }

  setCustomerStatus(status: string = '') {
    this.customerStatus = status;
  }

  getCustomerStatus() {
    return this.customerStatus;
  }

  setTelephonistStatus(status: string = '') {
    this.telephonistStatus = status;
  }

  getTelephonistStatus() {
    return this.telephonistStatus;
  }

  setEventStatus(status: string = '') {
    this.eventStatus = status;
  }

  getEventStatus() {
    return this.eventStatus;
  }

  changeStatus(status) {
    if (status === 'offline') {
      this.clearCtiInfo();
    }
    this.ctiSubject.next({
      isOnline: status === 'online',
      isBusy: status === 'busy',
      isOffline: status === 'offline',
    });
  }

  getCtiStatus(): Observable<any> {
    return this.ctiSubject.asObservable();
  }

  clearCtiInfo() {
    this.callOutPhoneNum = '';
    this.callInPhoneNum = '';
    this.callOutCustomerId = '';
    this.shouldShowInCallBar = false;
    this.shouldShowOutCallBar = false;
    this.shouldFoldCallOutPanel = true;
    this.shouldShowAddCustomerBtn = true;
    this.customerStatus = '';
    this.telephonistStatus = '';
    this.eventStatus = '';
  }

  addCtiCallHistory(obj, data): Observable<any> {
    let hrefUrl = `https://uatsoftphone.lshauto.com.cn//${_.get(obj, 'mediano')}?file=${_.get(obj, 'filename')}`;
    // if (environment.production || _.get(environment, 'envName') === 'uat') {
    if (environment.production) {
      hrefUrl = `https://softphone.lshauto.com.cn//${_.get(obj, 'mediano')}?file=${_.get(obj, 'filename')}`;
    }
    const params = {
      operator_id: _.get(obj, 'operator_id'),
      device_type: _.get(obj, 'devicetype'),
      call_id: _.get(obj, 'callid'),
      call_type: _.get(data, 'call_type') === 'CALL_OUT' ? 'CALL_OUT' : 'CALL_IN',
      call_in_time: _.get(obj, 'timestamp'),
      answer_call_time: moment(_.get(obj, 'agent_answer_time')).unix(),
      end_call_time: moment(_.get(obj, 'end_time')).unix(),
      call_duration_time: _.get(obj, 'duration'),
      call_record_url: _.get(obj, 'filename')
        // ? `${_.get(obj, 'recordDownloadUrl')}//${_.get(obj, 'mediano')}?file=${_.get(obj, 'filename')}`
        ? hrefUrl
        : '',
      call_way: _.get(data, 'callWay', 'CUSTOMER'),
      call_mobile: phoneNumFormat(_.get(data, 'called') || _.get(data, 'origcaller')),
    };
    return this.ctiApi.addCtiCallHistory(params).pipe((this.spinnerService.loading()));
  }

  updateAfterCallInfo(obj) {
    this.afterCallInfo = obj;
  }

  getAfterCallInfo() {
    return this.afterCallInfo;
  }

  getAddCustomerAndLeadSubject(): Observable<any> {
    return this.addCustomerAndLeadSubject.asObservable();
  }

  addCustomerAndLead(mobile) {
    this.addCustomerAndLeadSubject.next(mobile);
  }

  getAddAfterSalesAndLeadSubject(): Observable<any> {
    return this.addAfterSalesSubject.asObservable();
  }

  addAfterSales(mobile) {
    this.addAfterSalesSubject.next(mobile);
  }

  getShouldShowInCallBar() {
    return this.shouldShowInCallBar;
  }

  getShouldShowOutCallBar() {
    return this.shouldShowOutCallBar;
  }

  setShouldShowInCallBar(shouldShow) {
    this.shouldShowInCallBar = shouldShow;
  }

  setShouldShowOutCallBar(shouldShow) {
    this.shouldShowOutCallBar = shouldShow;
  }

  getShouldFoldCallOutPanel() {
    return this.shouldFoldCallOutPanel;
  }

  setShouldFoldCallOutPanel(shouldFold) {
    this.shouldFoldCallOutPanel = shouldFold;
  }

  getCallOutPhoneNum() {
    return this.callOutPhoneNum;
  }

  setCallOutPhoneNum(phoneNum) {
    this.callOutPhoneNum = phoneNum;
    this.ctiPhoneNumSubject.next();
  }

  getCallInPhoneNum() {
    return this.callInPhoneNum;
  }

  setCallInPhoneNum(phoneNum) {
    this.callInPhoneNum = phoneNum;
  }

  onPhoneNumChanged(): Observable<any> {
    return this.ctiPhoneNumSubject.asObservable();
  }

  clearPhoneNumChanged() {
    this.ctiPhoneNumSubject.observers.shift();
  }

  getCallOutCustomerId() {
    return this.callOutCustomerId;
  }

  setCallOutCustomerId(id) {
    this.callOutCustomerId = id;
  }

  getShouldShowAddCustomerBtn() {
    return this.shouldShowAddCustomerBtn;
  }

  setShouldShowAddCustomerBtn(shouldShow) {
    this.shouldShowAddCustomerBtn = shouldShow;
  }

  getShouldShowAddCustomerConfirm() {
    return this.shouldShowAddCustomerConfirm;
  }

  setShouldShowAddCustomerConfirm(shouldShow) {
    this.shouldShowAddCustomerConfirm = shouldShow;
  }

  getShouldShowAddAfterSalesConfirm() {
    return this.shouldShowAddAfterSalesConfirm;
  }

  setShouldShowAddAfterSalesConfirm(shouldShow) {
    this.shouldShowAddAfterSalesConfirm = shouldShow;
  }

  setAllTelephonist(param) {
    this.telephonists = param;
  }

  getAllTelephonist() {
    return this.telephonists;
  }

  setIsTransferOver(param) {
    this.isTransferOver = param;
  }

  getIsTransferOver() {
    return this.isTransferOver;
  }

  showInCallNotification(msg) {
    Notification.requestPermission().then(
      (permission) => {
        // tslint:disable-next-line:no-console
        console.log('Notification Permission: ', permission);
        if (permission === 'granted') {
          this.inCallNotificationObj = new Notification('来电提醒', { body: msg });
        }
      },
    );
  }

  hideInCallNotification() {
    if (this.inCallNotificationObj) {
      this.inCallNotificationObj.close();
    }
  }

  setTelephonistListStatus(param) {
    _.assign(this.telephonistListStatus, param);
  }

  getTelephonistListStatus() {
    return this.telephonistListStatus;
  }

  setShouldAddAfterSalesWithPhone(val: boolean) {
    this.shouldAddAfterSalesWithPhoneStatus = val;
    this.shouldAddAfterSalesWithPhoneSubject.next(this.shouldAddAfterSalesWithPhoneStatus);
  }

  getShouldAddAfterSalesWithPhone() {
    return this.shouldAddAfterSalesWithPhoneStatus;
  }

  getCtiIntelligentAssistantCurrentStatus() {
    return this.ctiIntelligentAssistantCurrentStatus;
  }

  setCtiIntelligentAssistantCurrentStatus(param) {
    this.ctiIntelligentAssistantCurrentStatus = param;
  }

  setShouldShowSelectTypeModal(value: boolean) {
    this.shouldShowSelectTypeModal = value;
  }

  getShouldShowSelectTypeModal() {
    return this.shouldShowSelectTypeModal;
  }
  addSelectType(type: SelectType) {
    this.selectTypeModalSubject.next(type);
  }

  getSelectTypeModalSubject(): Observable<SelectType> {
    return this.selectTypeModalSubject.asObservable();
  }
}

<form class="form-container">
  <div class="appointment-time-and-sc" [ngStyle]="isFollowUp && {'flex-direction': 'column-reverse'}">
    <div>
      <div class='lead-container' *ngFor="let lead of leads" [id]='lead.id'>
        <div class="section appointment-time-and-sc-selection" *ngIf="enableSelectSC">
          <div class="lead-label" *ngIf='leads.length > 1'>
            <span class='business-model-type'>
              经销商：
            </span>
              <span class='lead-desc'>
              {{leadLabel}}
            </span>
          </div>
          <span class="section-title" [class.required]="!isFollowUp">销售顾问</span>
          <span class="section-title" *ngIf="isExistingDefaultOwner">（默认为客户原销售顾问，可根据情况修改）</span>
          <div class="section-content consultant-selection">
            <div class="sc-group-dropdown-section">
              <app-dropdown-list
                [options]="(scGroupOptions$) | async"
                [selectedValue]="getSelectedScGroup()"
                (select)="onScGroupSelect(getScGroupValue($event))"
                [getText]="getScGroupDisplayText"
                [placeholder]="'请选择销售小组'"
                [error]="getSelectSCError(false)"
                required="true"
              ></app-dropdown-list>
            </div>
            <div class="sc-dropdown-section">
              <app-dropdown-list
                [options]="(scOptions$) | async"
                [selectedValue]="getSelectedSc(false)"
                (select)="onScSelect(getScValue($event))"
                [getText]="getScOptionDisplayText"
                [placeholder]="'请选择销售顾问'"
                [error]="!isFollowUp && getSelectSCError(false)"
              ></app-dropdown-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section appointment-time-and-sc-selection" [ngStyle]="isFollowUp && {'margin-top': '10px'}">
      <span class="section-title" [class.required]="!isAssignSC">预约进店时间</span>
      <div class="section-content">
        <p-calendar
          [locale]="chineseCalendarConfig"
          dateFormat="yy-mm-dd"
          placeholder="请选择"
          [formControl]="appointmentDateAndTime.controls.appointmentDate"
          [minDate]="minDate"
          [inputStyle]="appointmentDateAndTime.valid ? {} : invalidStyle"
        >
        </p-calendar>
        <app-dropdown
          [options]="getTimeOptions()"
          [selectedValue]="getSelectedTime()"
          [getText]="getTimeText"
          [getValue]="getTimeValue"
          [error]="!appointmentDateAndTime.valid"
          [style]="{ 'width': '120px', 'margin-left': '10px' }"
          [control]="appointmentDateAndTime.controls.appointmentTime"
        >
        </app-dropdown>
      </div>
    </div>
  </div>
  <div class="section">
    <span class="section-title">备注</span>
    <div class="section-content">
      <textarea
        class="customize-textarea comment"
        placeholder="有什么话要对销售顾问说"
        maxlength="200"
        [formControl]="assignScFormGroup.controls.comments">
      </textarea>
    </div>
  </div>
</form>

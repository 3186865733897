import { toEnum } from '../../../_common/utils/enum';
import { DateFormat } from '../../../_common/constants/date';

export const LIFE_CYCLE_TYPES = toEnum({
  EMERGENCY: { text: '紧急外呼', backgroundColor: '#fedad4', color: '#ff5230', fields: ['emergencyPart'] },
  TG: { text: '紧急外呼', backgroundColor: '#fedad4', color: '#ff5230', fields: ['emergencyPart'] },
  TCC: { text: 'TCC', backgroundColor: '#fde190', color: '#f9be05' },
  COMMON: { text: '绑定推送', backgroundColor: '#c5ecfc', color: '#02adf0' },
  NORMAL: { text: '常规保养', backgroundColor: '#c5ecfc', color: '#02adf0' },
  PWEI: { text: '客户保留', backgroundColor: '#c5ecfc', color: '#02adf0' },
  RECALL: { text: '召回', backgroundColor: '#eedef6', color: '#b370d5', fields: ['recallDamageCode'] },
  LOST_SALE: { text: '失销', backgroundColor: '#c5ecfc', color: '#04ADEF' },
  TYRE: { text: '轮胎', backgroundColor: '#c5ecfc', color: '#04ADEF' },
  CAMPAIGN: { text: '主动服务措施', backgroundColor: '#eedef6', color: '#b370d5', fields: ['campaignDamageCode'] },
  OEM_SPECIAL: { text: '厂家特殊线索', backgroundColor: '#c5ecfc', color: '#04ADEF' },
  SAN: { text: '改装产品线索', backgroundColor: '#c5ecfc', color: '#04ADEF' },
  DIGITAL_SERVICE: { text: '数字化服务', backgroundColor: '#c5ecfc', color: '#02adf0', fields: ['digitalServiceName'] },
  SERVICE_MEASURE: { text: '一般服务措施', backgroundColor: '#eedef6', color: '#b370d5', fields: ['serviceMeasureDamageCode'] },
  SERVICE_CONTRACT: { text: '服务合约', backgroundColor: '#c5ecfc', color: '#02adf0' },
  CUSTOMER_CARE: { text: '客户关怀', backgroundColor: '#c5ecfc', color: '#02adf0' },
  MANUAL: { text: '人工创建', backgroundColor: '#c5ecfc', color: '#02adf0' },
  TYRE_PREDICTION: { text: '轮胎预测', backgroundColor: '#c5ecfc', color: '#04ADEF' },
  TYRE_LOST_SALES: { text: '轮胎失销', backgroundColor: '#c5ecfc', color: '#04ADEF' },
  FIX_INTERVAL: { text: '固定周期配件', backgroundColor: '#c5ecfc', color: '#02adf0' },
});

export const LIFE_CYCLE_STATUS_FIELDS = toEnum({
  PENDING: { text: '待跟进', fields: ['nextFollowUpDate'] },
  ORDERED: { text: '已预约', fields: ['reservationDate', 'enteringStatus'] },
  CLOSED: { text: '已关闭', fields: ['closeTime', 'closeReason'] },
});

export const FIELD_OPTIONS = toEnum({
  leadGenerateTime: { text: '创建时间', format: DateFormat.TIME_HOUR_MINUTE },
  nextFollowUpDate: { text: '下次跟进', format: DateFormat.DATE },
  reservationDate: { text: '预约时间', format: DateFormat.DATE_TIME_MINUTE },
  closeTime: { text: '关闭时间', format: DateFormat.DATE_TIME_MINUTE },
  closeReason: { text: '关闭原因' },
  enteringStatus: { text: '进店状态' },
  recallDamageCode: { text: '故障代码' },
  campaignDamageCode: { text: '故障代码' },
  serviceMeasureDamageCode: { text: '故障代码' },
  emergencyPart: { text: '报警部件' },
  digitalServiceName: { text: '活动名称' },
  dmpId: { text: '线索编号' },
  followUpComments: { text: '跟进记录' },
  serviceType: { text: '服务类型' },
  dealerName: { text: '进店经销商' },
  repairDate: { text: '进店时间', format: DateFormat.DATE_TIME_MINUTE },
  wipNumber: { text: '订单号' },
});

export const ENTERING_STATUS_OPTIONS = toEnum({
  ARRIVED: { text: '已进店' },
  NOTARRIVAL: { text: '未进店' },
  NOINFO: { text: '无进店信息' },
});

import { Injectable } from '@angular/core';

export enum LOADING_STATE {
  NON = 0,
  LOADED = 1,
  LOADING = 2
}

@Injectable()
export class OtrScriptLoader {
  constructor() {
    window._scriptLoadState = {};
  }

  public loadMap(url: string, callback: () => void): void {
    const scriptKey = url;
    if (window._scriptLoadState[scriptKey] === LOADING_STATE.LOADED) {
      switchMapDisplay('otr-baidu-map .otr-baidu-map-instance', 'block');
      switchMapDisplay('otr-baidu-map .otr-baidu-map-offline', 'none');
      return callback();
    }
    if (window._scriptLoadState[scriptKey] === LOADING_STATE.LOADING) {
      return;
    }
    window._scriptLoadState[scriptKey] = LOADING_STATE.LOADING;

    window.otrMapInit = () => {
      window._scriptLoadState[scriptKey] = LOADING_STATE.LOADED;
      switchMapDisplay('otr-baidu-map .otr-baidu-map-instance', 'block');
      switchMapDisplay('otr-baidu-map .otr-baidu-map-offline', 'none');
      callback();
    };
    appendScriptTag(scriptKey);
  }
}

function appendScriptTag(url: string) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  script.onerror = () => {
    switchMapDisplay('otr-baidu-map .otr-baidu-map-offline', 'block');
    switchMapDisplay('otr-baidu-map .otr-baidu-map-instance', 'none');
    document.body.removeChild(script);

    setTimeout(() => {
      appendScriptTag(url);
    }, 30000);
  };
  document.body.appendChild(script);
}

function switchMapDisplay(selector: string, state: string) {
  Array.prototype.slice.call(document.querySelectorAll(selector)).forEach((node: HTMLElement) => {
    node.style.display = state;
  });
}

import { Component } from '@angular/core';
import { BasicEditorComponent } from '../basic-editor.component';
import { ICellEditorParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  selector: 'app-search-checkbox-editor-cell',
  styleUrls: ['./search-checkbox-cell.style.scss'],
  templateUrl: 'search-checkbox-cell.template.html',
})
export class SearchCheckboxEditorCellComponent extends BasicEditorComponent {
  params: ICellEditorParams;
  containerStyle = { width: '254px' };
  style = { width: '145px' };
  shouldDisableCheckbox: boolean = false;

  agInit(params: ICellEditorParams): void {
    super.agInit(params);
    this.params = params;
    this.shouldDisableCheckbox = _.has(params.node.data, 'sales_incentive_program_id');
    this.updateSourceValue(this.checkedOptions);
  }

  onSubmit($event) {
    this.updateSourceValue($event);
  }

  get initialOptions() {
    const options = this.cellEditorParams.options;
    return _.isFunction(options) ? options(this.params) : options;
  }

  get checkedOptions() {
    return _.isEmpty(this.params.node.data.dealer_range) ? this.allInitailOptionValues : this.params.node.data.dealer_range;
  }

  get allInitailOptionValues(): Array<string> {
    return _.map(this.initialOptions, option => _.get(option, 'dealer_id', ''));
  }
}

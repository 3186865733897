import { filter } from 'rxjs/operators';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

export const filterNonNumericValues = (formControl: AbstractControl) => {
  formControl.valueChanges.pipe(filter(value => !/^\d*$/.test(value))).subscribe(value => {
    formControl.patchValue(value.replace(/[^\d]/g, ''), { emitEvent: false });
  });
};

export const filterNonNumericOrNonAlphabetValues = (formControl: AbstractControl) => {
  formControl.valueChanges.pipe(filter(value => !/^[0-9a-zA-Z]*$/.test(value))).subscribe(value => {
    formControl.patchValue(value.replace(/[^0-9a-zA-Z]/g, ''), { emitEvent: false });
  });
};

export function clearFormArray(formArray: FormArray) {
  while (formArray.length > 0) {
    formArray.removeAt(0);
  }
}

export const markAllFieldsAsTouched = (formGroup: FormGroup) => {
  _.keys(formGroup.controls).forEach(field => {
    formGroup.controls[field].markAsTouched();

    if (_.get(formGroup.controls[field], 'controls')) {
      markAllFieldsAsTouched(formGroup.controls[field] as FormGroup);
    }
  });
};

export const resetFormStatus = (formGroup: FormGroup) => {
  _.keys(formGroup.controls).forEach(field => {
    formGroup.controls[field].markAsUntouched();
    formGroup.controls[field].markAsPristine();

    if (_.get(formGroup.controls[field], 'controls')) {
      resetFormStatus(formGroup.controls[field] as FormGroup);
    }
  });
};

export function getMultiSelectValue(formValue, formKey: string) {
  const selectValue = _.get(formValue, formKey, '') || '';
  const selectALLRegex = new RegExp(/SELECT_ALL|全部/gm);
  return (selectValue === '' || selectALLRegex.test(selectValue)) ? null : _.split(selectValue, ',');
}

import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular/main';
import * as _ from 'lodash';

@Component({
  selector: 'app-action-cell',
  templateUrl: 'action-cell.template.html',
  styleUrls: ['action-cell.style.scss'],
})
export class ActionCellEditorComponent implements ICellEditorAngularComp {
  private params: any;
  protected cellEditorParams;
  isReversed: boolean = false;

  agInit(params: any): void {
    this.params = params;
    this.cellEditorParams = this.getCellEditorParams();
    this.isReversed = !!params.isReversed;
  }

  onSave() {
    this.params.column.colDef.cellEditorParams.onSave(this.params);
  }

  onCancel() {
    this.params.column.colDef.cellEditorParams.onCancel(this.params.node);
  }

  onEnterKeyDown(event) {
    event.stopPropagation();
  }

  getValue(): any {
    return;
  }

  getCellEditorParams() {
    return this.params.column.getColDef().cellEditorParams || {};
  }

  get hideButton() {
    const hideButton = this.cellEditorParams.hideButton;
    return _.isFunction(hideButton) ? hideButton(this.params) : hideButton;
  }

}

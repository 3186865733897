<button *ngIf='showText&&!showContent&&isDetailDisplay' class='icon-text-cell-container' [disabled]='disabled'
        [ngStyle]='width'
        (click)='onClick($event)'>
  <img src='/assets/accounting/detail.png' [ngClass]='classNames'>
</button>

<button *ngIf='!showText&&!showContent&&isExportDisplay' class='icon-text-cell-container' [disabled]='disabled'
        [ngStyle]='width'
        (click)='onClick($event)'>
  <img src='/assets/accounting/export.svg' [ngClass]='classNames'>
</button>

<button *ngIf='showPrimaryBtn' class='text-cell-container primary' [ngStyle]='width'
        (click)='onClick($event)'>
  {{content}}
</button>

<div #container [title]='content' *ngIf='showContent'>{{ content }}</div>

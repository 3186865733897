import * as _ from 'lodash';
import { ActivityHistoryListItem } from '../../shared/model/activity-history-list-item';
import { Activity } from '../../shared/model/activity';

export const CUSTOMER_TASK_TYPE = {
  CAR_ANNIVERSARY_FIRST: {
    value: 'CAR_ANNIVERSARY_FIRST',
    text: '新车周年跟踪',
  },
  CAR_ANNIVERSARY_SECOND: {
    value: 'CAR_ANNIVERSARY_SECOND',
    text: '新车两周年跟踪',
  },
  CAR_ANNIVERSARY_THIRD: {
    value: 'CAR_ANNIVERSARY_THIRD',
    text: '新车三周年跟踪',
  },
  BIRTHDAY_CARD: {
    value: 'BIRTHDAY_CARD',
    text: '生日贺卡',
  },
  CUSTOMER_SATISFACTION_INVESTIGATION: {
    value: 'CUSTOMER_SATISFACTION_INVESTIGATION',
    text: '售后服务回访',
  },
  REQUALIFICATION_CALL: {
    value: 'REQUALIFICATION_CALL',
    text: '需求重估',
  },
  OUTGOING_TELEPHONE_CALL: {
    value: 'OUTGOING_TELEPHONE_CALL',
    text: '打出电话',
  },
  CUSTOMER_FOLLOW_UP: {
    value: 'CUSTOMER_FOLLOWUP',
    text: '跟进',
  },
  VEHICLE_DELIVERY_2_HOURS_FOLLOW_UP: {
    value: 'VEHICLE_DELIVERY_2_HOURS_FOLLOWUP',
    text: '交车2小时跟进',
  },
  VEHICLE_DELIVERY_3_DAYS_FOLLOW_UP: {
    value: 'VEHICLE_DELIVERY_3_DAYS_FOLLOWUP',
    text: '交车3天跟进',
  },
  INVOICE_NEW_VEHICLE: {
    value: 'INVOICE_NEW_VEHICLE',
    text: '新车开票',
  },
  SHOWROOM_VISIT_BY_CUSTOMER: {
    value: 'SHOWROOM_VISIT_BY_CUSTOMER',
    text: '展厅拜访',
  },
  TEST_DRIVE: {
    value: 'TEST_DRIVE',
    text: '试乘试驾',
  },
  VEHICLE_QUOTATION: {
    value: 'VEHICLE_QUOTATION',
    text: '车辆报价 - 新车',
  },
  VEHICLE_ORDER: {
    value: 'VEHICLE_ORDER',
    text: '车辆订单 - 新车',
  },
  INCOMING_TELEPHONE_CALL: {
    value: 'INCOMING_TELEPHONE_CALL',
    text: '打入电话',
  },
  VEHICLE_DELIVERY_ONE_WEEK_FOLLOW_UP: {
    value: 'VEHICLE_DELIVERY_1_WEEK_FOLLOW_UP',
    text: '交车1周跟进',
  },
  VEHICLE_DELIVERY_ONE_MONTH_FOLLOW_UP: {
    value: 'VEHICLE_DELIVERY_1_MONTH_FOLLOW_UP',
    text: '交车1个月跟进',
  },
  VEHICLE_DELIVERY_ONE_MONTH_FOLLOW_UP_CDO: {
    value: 'VEHICLE_DELIVERY_1_MONTH_FOLLOW_UP_CDO',
    text: '交车1个月回访',
  },
};

export const ACTIVITY_TYPE = {
  CUSTOMER_CREATION: 'CustomerCreation',
  CAR_VERTICAL_WEBSITE: 'Car Vertical Website',
  CUSTOMER_ASSIGNMENT: 'CustomerAssignment',
  CUSTOMER_REASSIGNMENT: 'CustomerReassignment',
  LEAD_CREATION: 'LeadCreation',
  LEAD_MERGE: 'LeadMerge',
  LEAD_SHARING: 'Lead Sharing',
  LEAD_SHARED: 'Lead Shared',
  SHOWROOM_VISIT: 'ShowroomVisit',
  FOLLOW_UP: 'FollowUp',
  FOLLOW_UP_3_DAYS: 'FollowUp3Days',
  APPOINTMENT: 'Appointment',
  COMMENT: 'Comment',
  CDM_UPDATE_CUSTOMER: 'CdmUpdateCustomer',
  OTR_UPDATE_CUSTOMER: 'OTRUpdateCustomer',
  SFDC_UPDATE_CUSTOMER: 'SFDCUpdateCustomer',
  OFFER: 'Offer',
  ORDER: 'Order',
  TEST_DRIVE: 'TestDrive',
  HAND_OVER: 'HandOver',
  INVOICE: 'Invoice',
  PAID: 'Paid',
  LOST_SALES: 'LostSales',
  SUCCESSFUL: 'Successful',
  EMAIL: 'Email',
  SMS_WECHAT: 'SMS/Wechat',
  ASSIGN_TO_SC: 'Assign to SC',
  ASSIGN_TO_PC: 'Assign to PC',
  LEAD_CLOSURE: 'Lead Closure',
  ESCALATION: 'Escalation',
  REASSIGN_SC: 'Reassign SC',
  REASSIGN_PC: 'Reassign PC',
  ASSIGN_TEST_DRIVE_PE: 'Assign Test Drive PE',
  ASSIGN_HANDOVER_PE: 'Assign Handover PE',
  HANDOVER_INTRODUCTION: 'Handover Introduction',
  HANDOVER_SUPPORT: 'Handover Support',
  OUTGOING_CALL: 'Outgoing Call',
  CDO_OUTGOING_CALL: 'CDO Outgoing Call',
  REACTIVATE: 'Reactivate',
  REASSIGN_CURRENT_SC: 'Reassign current SC',
  CONVERT_TO_LEAD: 'ConvertToLead',
  RETAIL_CAMPAIGN: 'Retail Campaign',
  FOLLOW_UP_ON_LEAD: 'Follow-up on Lead',
  ORDER_CREATION: 'OrderCreation',
  ORDER_CONFIRMATION: 'OrderConfirmation',
  ORDER_MODIFICATION: 'OrderModification',
  ORDER_CANCELLATION: 'OrderCancellation',
  MBE_CREATION: 'MBECreation',
  MBE_CANCELLATION: 'MBECancellation',
  ASSIGN_VEHICLE_TO_PRE_ORDER: 'AssignVehicleToPreOrder',
  RELEASE_VEHICLE_FROM_PRE_ORDER: 'ReleaseVehicleFromPreOrder',
  CDO_FOLLOW_UP: 'CDO Follow Up',
  RECALL: 'Recall',
  ACCEPT_LEAD: 'AcceptLeads',
  ASSIGN_CDO: 'AssignCDO',
  REASSIGN_CDO: 'ReassignCDO',
  RECOMMENDED_OFFER_UPDATE: 'Recommended Offer Update',
  RETENTION_VIEWED: 'RetentionViewed',
  RETENTION_SMS_VIEWED: 'Retention SMS Viewed',
  RETENTION_WECHAT_VIEWED: 'Retention Wechat Viewed',
  RETENTION_SMS_SENT: 'Retention SMS Sent',
  RETENTION_WECHAT_SENT: 'Retention Wechat Sent',
  RESERVATION: 'Reservation',
  AFTER_SALES_TRANSFORM_INTO_SALES_LEAD: 'After sales transform into sales lead',
  VEHICLE_DELIVERY_ONE_WEEK_FOLLOW_UP: 'Vehicle Delivery One Week Follow Up',
  VEHICLE_DELIVERY_ONE_MONTH_FOLLOW_UP: 'Vehicle Delivery One Month Follow Up',
  CTI_CALL_IN: 'Cti Call In',
  CTI_CALL_OUT: 'Cti Call Out',
  CTI_MANUAL_MATCH_CALL_IN: 'CtiManualMatchUserCallIn',
  CTI_MANUAL_MATCH_CALL_OUT: 'CtiManualMatchUserCallOut',
  ONLINE_BOOKING_LEAD_CREATION: 'Online Booking Lead Creation',
  ONLINE_BOOKING_LEAD_UPDATE: 'Online Booking Lead Update',
  ONLINE_BOOKING_LEAD_CANCELLATION: 'Online Booking Lead Cancellation',
  D2D_TEST_DRIVE_CREATED: 'D2D Test Drive Created',
  D2D_TEST_DRIVE_CONFIRMED: 'D2D Test Drive Confirmed',
  D2D_TEST_DRIVE_UPDATED: 'D2D Test Drive Updated',
  D2D_TEST_DRIVE_CANCELED: 'D2D Test Drive Canceled',
  D2D_TEST_DRIVE_COMPLETED: 'D2D Test Drive Completed',
  D2D_TEST_DRIVE: 'D2D Test Drive',
  IN_DEPTH_TEST_DRIVE: 'In-depth Test Drive',
  IN_DEPTH_TEST_DRIVE_COMPLETED: 'In Depth Test Drive Completed',
  IN_DEPTH_TEST_DRIVE_CANCELED: 'In Depth Test Drive Canceled',
  IN_DEPTH_TEST_DRIVE_UPDATED: 'In Depth Test Drive Updated',
  IN_DEPTH_TEST_DRIVE_CREATED: 'In Depth Test Drive Created',
  IN_DEPTH_TEST_DRIVE_TO_CREATED: 'In Depth Test Drive To Created',
  LEAD_FROM_METOUCH: 'meTouch',
  ASSIGN_UE: 'AssignUE',
  REASSIGN_UE: 'ReassignUE',
  REASSIGN_UE_FROM_CDO: 'ReassignUEFromCDO',
  REASSIGN_CDO_FROM_UE: 'ReassignCDOFromUE',
  UE_FOLLOW_UP: 'UE Follow Up',
  USED_CAR_LEAD_SUCCESSFUL_CLOSURE: 'Used Car Lead Successful Closure',
  USED_CAR_LEAD_UNSUCCESSFUL_CLOSURE: 'Used Car Lead Unsuccessful Closure',
  COMMITMENT_SIGN_SUCCEED: 'Commitment Sign Succeed',
};

export const ACTIVITY_TYPE_HISTORY = [
  { text: '线索创建', value: ACTIVITY_TYPE.LEAD_CREATION },
  { text: '线索合并', value: ACTIVITY_TYPE.LEAD_MERGE },
  { text: '线索分享', value: ACTIVITY_TYPE.LEAD_SHARING },
  { text: '线索分享', value: ACTIVITY_TYPE.LEAD_SHARED },
  { text: '首次到店', value: ACTIVITY_TYPE.SHOWROOM_VISIT, isFirstVisit: true },
  { text: '再次到店', value: ACTIVITY_TYPE.SHOWROOM_VISIT, isFirstVisit: false },
  { text: '跟进', value: ACTIVITY_TYPE.FOLLOW_UP },
  { text: '离店三天跟进', value: ACTIVITY_TYPE.FOLLOW_UP_3_DAYS },
  { text: '邀约', value: ACTIVITY_TYPE.APPOINTMENT },
  { text: '备注', value: ACTIVITY_TYPE.COMMENT },
  { text: '客户数据更新', value: ACTIVITY_TYPE.CDM_UPDATE_CUSTOMER },
  { text: '客户数据更新', value: ACTIVITY_TYPE.OTR_UPDATE_CUSTOMER },
  { text: '客户数据更新', value: ACTIVITY_TYPE.SFDC_UPDATE_CUSTOMER },
  { text: '客户创建', value: ACTIVITY_TYPE.CUSTOMER_CREATION },
  { text: '分配客户', value: ACTIVITY_TYPE.CUSTOMER_ASSIGNMENT },
  { text: '重新分配客户', value: ACTIVITY_TYPE.CUSTOMER_REASSIGNMENT },
  { text: '报价单', value: ACTIVITY_TYPE.OFFER },
  { text: '订单', value: ACTIVITY_TYPE.ORDER },
  { text: '试乘试驾', value: ACTIVITY_TYPE.TEST_DRIVE },
  { text: '交车', value: ACTIVITY_TYPE.HAND_OVER },
  { text: '开票', value: ACTIVITY_TYPE.INVOICE },
  { text: '付款', value: ACTIVITY_TYPE.PAID },
  { text: '失销', value: ACTIVITY_TYPE.LOST_SALES },
  { text: '交易成功', value: ACTIVITY_TYPE.SUCCESSFUL },
  { text: '邮件', value: ACTIVITY_TYPE.EMAIL },
  { text: '短信/微信', value: ACTIVITY_TYPE.SMS_WECHAT },
  { text: '分配销售顾问', value: ACTIVITY_TYPE.ASSIGN_TO_SC },
  { text: '汽车垂直网站', value: ACTIVITY_TYPE.CAR_VERTICAL_WEBSITE },
  { text: '分配体验专家', value: ACTIVITY_TYPE.ASSIGN_TO_PC },
  { text: '重新分配销售顾问', value: ACTIVITY_TYPE.REASSIGN_CURRENT_SC },
  { text: '关闭', value: ACTIVITY_TYPE.LEAD_CLOSURE },
  { text: '60分钟超时', value: ACTIVITY_TYPE.ESCALATION },
  { text: '重新分配销售顾问', value: ACTIVITY_TYPE.REASSIGN_SC },
  { text: '重新分配', value: ACTIVITY_TYPE.REASSIGN_PC },
  { text: '分配体验专家试驾', value: ACTIVITY_TYPE.ASSIGN_TEST_DRIVE_PE },
  { text: '分配体验专家交车讲解', value: ACTIVITY_TYPE.ASSIGN_HANDOVER_PE },
  { text: '交车介绍', value: ACTIVITY_TYPE.HANDOVER_INTRODUCTION },
  { text: '交车支持', value: ACTIVITY_TYPE.HANDOVER_SUPPORT },
  { text: '呼出电话', value: ACTIVITY_TYPE.OUTGOING_CALL },
  { text: '呼出电话', value: ACTIVITY_TYPE.CDO_OUTGOING_CALL },
  { text: '激活', value: ACTIVITY_TYPE.REACTIVATE },
  { text: '变更为线索', value: ACTIVITY_TYPE.CONVERT_TO_LEAD },
  { text: '市场活动', value: ACTIVITY_TYPE.RETAIL_CAMPAIGN },
  { text: '跟进', value: ACTIVITY_TYPE.FOLLOW_UP_ON_LEAD },
  { text: '订单创建', value: ACTIVITY_TYPE.ORDER_CREATION },
  { text: '订单确认', value: ACTIVITY_TYPE.ORDER_CONFIRMATION },
  { text: '订单修改', value: ACTIVITY_TYPE.ORDER_MODIFICATION },
  { text: '订单取消', value: ACTIVITY_TYPE.ORDER_CANCELLATION },
  { text: '电商订单', value: ACTIVITY_TYPE.MBE_CREATION },
  { text: '电商订单超时', value: ACTIVITY_TYPE.MBE_CANCELLATION },
  { text: '分配车辆', value: ACTIVITY_TYPE.ASSIGN_VEHICLE_TO_PRE_ORDER },
  { text: '释放车辆', value: ACTIVITY_TYPE.RELEASE_VEHICLE_FROM_PRE_ORDER },
  { text: 'CDO跟进', value: ACTIVITY_TYPE.CDO_FOLLOW_UP },
  { text: '撤回线索', value: ACTIVITY_TYPE.RECALL },
  { text: '线索接收', value: ACTIVITY_TYPE.ACCEPT_LEAD },
  { text: '分配客户发展专员', value: ACTIVITY_TYPE.ASSIGN_CDO },
  { text: '重新分配客户发展专员', value: ACTIVITY_TYPE.REASSIGN_CDO },
  { text: '推荐方案更新', value: ACTIVITY_TYPE.RECOMMENDED_OFFER_UPDATE },
  { text: '客户保留已读', value: ACTIVITY_TYPE.RETENTION_VIEWED },
  { text: '短信-客户保留方案已读', value: ACTIVITY_TYPE.RETENTION_SMS_VIEWED },
  { text: '微信-客户保留方案已读', value: ACTIVITY_TYPE.RETENTION_WECHAT_VIEWED },
  { text: '短信-客户保留方案已发送', value: ACTIVITY_TYPE.RETENTION_SMS_SENT },
  { text: '微信-客户保留方案已发送', value: ACTIVITY_TYPE.RETENTION_WECHAT_SENT },
  { text: '预约进店', value: ACTIVITY_TYPE.RESERVATION },
  { text: '售后转化', value: ACTIVITY_TYPE.AFTER_SALES_TRANSFORM_INTO_SALES_LEAD },
  { text: '交车1周跟进', value: ACTIVITY_TYPE.VEHICLE_DELIVERY_ONE_WEEK_FOLLOW_UP },
  { text: '交车1个月跟进', value: ACTIVITY_TYPE.VEHICLE_DELIVERY_ONE_MONTH_FOLLOW_UP },
  { text: '呼入电话', value: ACTIVITY_TYPE.CTI_CALL_IN },
  { text: '呼出电话', value: ACTIVITY_TYPE.CTI_CALL_OUT },
  { text: '匹配呼入电话', value: ACTIVITY_TYPE.CTI_MANUAL_MATCH_CALL_IN },
  { text: '匹配呼出电话', value: ACTIVITY_TYPE.CTI_MANUAL_MATCH_CALL_OUT },
  { text: '新增金融在线预约', value: ACTIVITY_TYPE.ONLINE_BOOKING_LEAD_CREATION },
  { text: '更新金融在线预约', value: ACTIVITY_TYPE.ONLINE_BOOKING_LEAD_UPDATE },
  { text: '取消金融在线预约', value: ACTIVITY_TYPE.ONLINE_BOOKING_LEAD_CANCELLATION },
  { text: '新建上门试驾', value: ACTIVITY_TYPE.D2D_TEST_DRIVE_CREATED },
  { text: '确认上门试驾', value: ACTIVITY_TYPE.D2D_TEST_DRIVE_CONFIRMED },
  { text: '修改上门试驾', value: ACTIVITY_TYPE.D2D_TEST_DRIVE_UPDATED },
  { text: '取消上门试驾', value: ACTIVITY_TYPE.D2D_TEST_DRIVE_CANCELED },
  { text: '完成上门试驾', value: ACTIVITY_TYPE.D2D_TEST_DRIVE_COMPLETED },
  { text: '上门试驾', value: ACTIVITY_TYPE.D2D_TEST_DRIVE },
  { text: '深度试驾', value: ACTIVITY_TYPE.IN_DEPTH_TEST_DRIVE_TO_CREATED },
  { text: '新建深度试驾', value: ACTIVITY_TYPE.IN_DEPTH_TEST_DRIVE_CREATED },
  { text: '修改深度试驾', value: ACTIVITY_TYPE.IN_DEPTH_TEST_DRIVE_UPDATED },
  { text: '取消深度试驾', value: ACTIVITY_TYPE.IN_DEPTH_TEST_DRIVE_CANCELED },
  { text: '完成深度试驾', value: ACTIVITY_TYPE.IN_DEPTH_TEST_DRIVE_COMPLETED },
  { text: '深度试驾', value: ACTIVITY_TYPE.IN_DEPTH_TEST_DRIVE },
  { text: 'meTouch', value: ACTIVITY_TYPE.LEAD_FROM_METOUCH },
  { text: '分配二手车评估师', value: ACTIVITY_TYPE.ASSIGN_UE },
  { text: '重新分配二手车评估师', value: ACTIVITY_TYPE.REASSIGN_UE },
  { text: '重新分配二手车评估师', value: ACTIVITY_TYPE.REASSIGN_UE_FROM_CDO },
  { text: '重新分配客户发展专员', value: ACTIVITY_TYPE.REASSIGN_CDO_FROM_UE },
  { text: 'UE跟进', value: ACTIVITY_TYPE.UE_FOLLOW_UP },
  { text: '成功关闭', value: ACTIVITY_TYPE.USED_CAR_LEAD_SUCCESSFUL_CLOSURE },
  { text: '战败关闭', value: ACTIVITY_TYPE.USED_CAR_LEAD_UNSUCCESSFUL_CLOSURE },
  { text: '电子试驾协议', value: ACTIVITY_TYPE.COMMITMENT_SIGN_SUCCEED },
];

export const CUSTOMER_ACTIVITY_TYPE_HISTORY = [
  { text: '客户创建', value: ACTIVITY_TYPE.CUSTOMER_CREATION },
  { text: '分配客户', value: ACTIVITY_TYPE.CUSTOMER_ASSIGNMENT },
  { text: '重新分配客户', value: ACTIVITY_TYPE.CUSTOMER_REASSIGNMENT },
  { text: '备注', value: ACTIVITY_TYPE.COMMENT },
  { text: '客户数据更新', value: ACTIVITY_TYPE.CDM_UPDATE_CUSTOMER },
  { text: '客户数据更新', value: ACTIVITY_TYPE.OTR_UPDATE_CUSTOMER },
  { text: '客户数据更新', value: ACTIVITY_TYPE.SFDC_UPDATE_CUSTOMER },
  { text: '呼出电话', value: ACTIVITY_TYPE.OUTGOING_CALL },
];

export const DISABLE_ACTIVITIES = [
  ACTIVITY_TYPE.SFDC_UPDATE_CUSTOMER,
];

const DELIVERY_TIME = [
  { value: 'LESS_THAN_FIFTEEN_MINUTES', text: '15分钟以内' },
  { value: 'FIFTEEN_TO_THIRTY_MINUTES', text: '15分钟-30分钟' },
  { value: 'THIRTY_TO_FORTY_FIVE_MINUTES', text: '30分钟-45分钟' },
  { value: 'GREATER_THAN_FORTY_FIVE_MINUTES', text: '45分钟以上' },
];

const taskActivityType = [
  ACTIVITY_TYPE.APPOINTMENT,
  ACTIVITY_TYPE.FOLLOW_UP,
  ACTIVITY_TYPE.FOLLOW_UP_3_DAYS,
  ACTIVITY_TYPE.FOLLOW_UP_ON_LEAD,
];

const updateOfferActivityType = [
  ACTIVITY_TYPE.RECOMMENDED_OFFER_UPDATE,
];

const isTaskActivityType = type => _.includes(taskActivityType, type);

const isUpdateOfferActivityType = type => _.includes(updateOfferActivityType, type);

const displayCommentActivities = [
  ACTIVITY_TYPE.SHOWROOM_VISIT,
  // ACTIVITY_TYPE.LEAD_CLOSURE,
  ACTIVITY_TYPE.ASSIGN_TO_SC,
  ACTIVITY_TYPE.RESERVATION,
  ACTIVITY_TYPE.AFTER_SALES_TRANSFORM_INTO_SALES_LEAD,
  ACTIVITY_TYPE.VEHICLE_DELIVERY_ONE_WEEK_FOLLOW_UP,
  ACTIVITY_TYPE.VEHICLE_DELIVERY_ONE_MONTH_FOLLOW_UP,
];

const hideDescriptionCustomerTasks = [
  CUSTOMER_TASK_TYPE.VEHICLE_DELIVERY_ONE_MONTH_FOLLOW_UP.value,
  CUSTOMER_TASK_TYPE.VEHICLE_DELIVERY_ONE_WEEK_FOLLOW_UP.value,
  CUSTOMER_TASK_TYPE.VEHICLE_DELIVERY_ONE_MONTH_FOLLOW_UP_CDO.value,
];

export const getCustomerActivitiesComments = activity => (_.includes(displayCommentActivities, activity.activity_type) ?
  _.replace(_.get(activity, 'comments', ''), /\n/g, ', ') : '');

const getLeadActivityContent = activity => {
  const activityType = activity.activity_type;
  if (activityType === ACTIVITY_TYPE.CDO_FOLLOW_UP || activityType === ACTIVITY_TYPE.UE_FOLLOW_UP) {
    return _.replace(activity.comments, '\n', '，');
  }
  if (isTaskActivityType(activityType) || isUpdateOfferActivityType(activityType)) {
    return activity.comments;
  }
  if (activityType === ACTIVITY_TYPE.HANDOVER_INTRODUCTION) {
    // tslint:disable-next-line:max-line-length
    return `${_.get(activity, 'description', '')}，讲解车型：${_.get(activity, 'vehicle_desc', '')}，交流记录：${_.get(activity, 'comments', '')}\n交车时长：${_.get(_.find(DELIVERY_TIME, { value: _.get(activity, 'handover_time_length') }), 'text', '')}`;
  }
  return _.replace(_.replace(activity.description, '\\n', '\n'), '***', ', ');
};

export const getLeadActivityTitle = activity => {
  const activityType = activity.activity_type;
  const filteredTypes = _.filter(ACTIVITY_TYPE_HISTORY, { value: activityType });
  if (activityType === ACTIVITY_TYPE.SHOWROOM_VISIT) {
    return _.find<any>(filteredTypes, { isFirstVisit: activity.is_first_visit }).text;
  }
  const activityTypeText = _.get(_.head(filteredTypes), 'text');
  if (isTaskActivityType(activityType)) {
    return activity.description ? `${activityTypeText}: ${activity.description}` : activityTypeText;
  }
  return activityTypeText;
};

export const getActivityTitle = activity => {
  const CTI_LIST = ['Cti Call In', 'Cti Call Out', 'CtiManualMatchUserCallIn', 'CtiManualMatchUserCallOut'];
  if (activity.task_type) {
    const taskText = _.get(_.find(CUSTOMER_TASK_TYPE, { value: activity.task_type }), 'text', '');
    return _.isEmpty(activity.description) || _.includes(hideDescriptionCustomerTasks, activity.task_type) ? taskText : `${taskText}：${activity.description}`;
  }
  if (activity.customer_id && !_.includes(CTI_LIST, activity.activity_type)) {
    return _.get(_.find(CUSTOMER_ACTIVITY_TYPE_HISTORY, { value: activity.activity_type }), 'text', '');
  }
  return getLeadActivityTitle(activity);
};

const getActivityContent = activity => {
  if (activity.task_type) {
    return _.isEmpty(activity.vehicle_description) ? activity.comments : `${activity.vehicle_description}\n${activity.comments}`;
  }
  if (activity.customer_id) {
    return activity.description;
  }
  return getLeadActivityContent(activity);
};

const getActivityDetailDescription = activity => {
  return _.filter([getActivityContent(activity), getCustomerActivitiesComments(activity)])
    .join(getDescriptionJoinCharacter(activity)) + getMergedDescription(activity);
};

const getExecutedUserDescription = activity => {
  const name = activity.executedUserName || '';
  return activity.executedUserRoleZH ? `${activity.executedUserRoleZH}：${name}` : name;
};

const getMergedDescription = activity => activity.is_merged ? '(已合并)' : '';

export const formatActivity = (activity: Activity): ActivityHistoryListItem => ({
  timestamp: activity.timestamp,
  title: getActivityTitle(activity),
  detailDescription: getActivityDetailDescription(activity),
  executedUserDescription: getExecutedUserDescription(activity),
});

export const getDescriptionJoinCharacter = activity => {
  const activityType = activity.activity_type;
  if (!_.isEmpty(activity.comments)
    && (activityType === ACTIVITY_TYPE.ASSIGN_TO_SC
      || activityType === ACTIVITY_TYPE.RESERVATION
      || activityType === ACTIVITY_TYPE.AFTER_SALES_TRANSFORM_INTO_SALES_LEAD
      || activityType === ACTIVITY_TYPE.VEHICLE_DELIVERY_ONE_WEEK_FOLLOW_UP
      || activityType === ACTIVITY_TYPE.VEHICLE_DELIVERY_ONE_MONTH_FOLLOW_UP)) {
    return '\n';
  }
  if (!_.isEmpty(activity.comments) && activityType === ACTIVITY_TYPE.SHOWROOM_VISIT) {
    return ', ';
  }
  return ' ';
};

<div class="select-wrapper" [ngStyle]="styles" [ngClass]="checkBoxTheme === themes.CUSTOMIZE && 'customize-theme'" (click)="onMouseClick()">
  <button [disabled]="disabled"  type="button" class="input-control input-group-select search-field form-control" #optionsEle
          [ngClass]="{'button--focused': isVisible, 'button-grey': selectedValues.length <= 0}"
          [ngStyle]="textStyle"
          [class.disabled]="disabled"
          (click)="toggleDropdown()">{{title | lowercaseBrand}}
  </button>
  <ul *ngIf="isVisible" class="select-options" [class.upturned]="upturnedConfig.canUpturned && shouldUpturned" [class.load-failed]="isLoadFailed">
    <li (click)="setSelected(option)" *ngFor=" let option of options" [ngStyle]="optionStyle" [class.disabled]="option.unchangeable">
      <input class='checkBox' type="checkbox" [checked]="isOptionSelected(option)" [disabled]="option.unchangeable">
      <span>
        {{shouldShowValueForTitle ? option.value + ' ' + option.text : option.text | lowercaseBrand}}
      </span>
    </li>
    <li *ngIf="isLoadFailed" class="load-failed-msg">
        系统异常，未获取到数据
    </li>
  </ul>
</div>

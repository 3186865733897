import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface PartPromptBarConfig {
  content?: string;
  level?: number;
  isPreserved?: boolean;
  containerClass?: string;
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PartPromptBarService {
  displayStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  configs: BehaviorSubject<PartPromptBarConfig[]> = new BehaviorSubject([]);

  show(partPromptBarConfig: PartPromptBarConfig[]) {
    this.configs.next(partPromptBarConfig);
    this.notify(true);
  }

  hide() {
    this.configs.next([]);
    this.notify(false);
  }

  private notify(status: boolean) {
    this.displayStatus.next(status);
  }
}

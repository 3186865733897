import { RebirthHttp, Query, GET, POST, Body, PATCH, Path, DELETE } from 'rebirth-http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentCollectionConfig } from '../models/payment-collection';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PaymentManagementApi extends RebirthHttp {

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/payment-management-config')
  getPaymentManagementConfig(@Query('type') type: string): Observable<PaymentCollectionConfig> {
    return null;
  }

  @POST('/api/payment-management-config/state')
  updatePaymentManagementState(@Body data): Observable<any> {
    return null;
  }

  @POST('/api/payment-management-config')
  addPaymentConfig(@Body data): Observable<any> {
    return null;
  }

  @PATCH('/api/payment-management-config/:id')
  updatePaymentConfig(@Path('id') id: number, @Query('type') type: string, @Query('name') name: string): Observable<any> {
    return null;
  }

  @DELETE('/api/payment-management-config/')
  deletePaymentConfig(@Query('ids') ids: number[]): Observable<any> {
    return null;
  }

  @GET('/api/payment-management-config/has-config-privilege')
  hasConfigPrivilege(): Observable<boolean> {
    return null;
  }
}

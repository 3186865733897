import { Component } from '@angular/core';
import * as _ from 'lodash';
import { IAfterGuiAttachedParams, IFilterParams } from 'ag-grid';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';
import { FILTER_TYPE } from '../../../../../constants/common';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-pagination-search-select-filter-cell',
  templateUrl: 'pagination-search-select-filter.template.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationSearchSelectFilterComponent extends BasePaginationFilterComponent {
  public radio = new FormControl(FILTER_TYPE.NORMAL);

  agInit(params: IFilterParams): void {
    super.agInit(params);
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    super.afterGuiAttached(params);
    this.radio.setValue(this.radio.value);
  }

  setDefaultValue() {
    super.setDefaultValue();
  }

  onSubmit() {
    this.text = this.radio.value === FILTER_TYPE.NONE ? '' : this.searchInput.value;
    this.params.filterChangedCallback();
    super.hideFilterMenu();
  }

  onCancel() {
    super.hideFilterMenu();
  }

  isFilterActive(): boolean {
    return this.radio.value !== FILTER_TYPE.NORMAL || !_.isEmpty(this.text);
  }

  setModel(model: any): void {
    const type = _.get(model, 'filterType', FILTER_TYPE.NORMAL);
    this.radio.setValue(type);
    this.text = _.get(model, 'value', '');
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }

    return {
      filterType: this.radio.value,
      value: this.text || '',
    };
  }
}

import { Component, Output, EventEmitter, Input, OnInit, HostBinding } from '@angular/core';
import { ICONS, IconType } from '../../constants/icons';

@Component({
  templateUrl: './icon-button.template.html',
  selector: 'app-icon-button',
  styleUrls: ['./icon-button.style.scss'],
})
export class IconButtonComponent implements OnInit {
  @Input() iconType = IconType.Plus;
  @Input() text: string = '';
  @Input() isEnable: boolean = true;
  @Output() onClick = new EventEmitter();
  @Input()
  @HostBinding('class')
  hostClassName: string;
  classNames: any;

  ngOnInit(): void {
    const iconClass = ICONS[this.iconType];
    this.classNames = { fa: true, [iconClass]: true };
  }

  buttonClick($event) {
    if (this.isEnable) {
      this.onClick.emit($event);
    }
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/src/interfaces';
import { ICellRendererParams } from 'ag-grid';

@Component({
  selector: 'app-authorisation-column-header',
  templateUrl: './authorisation-column-header.template.html',
  styleUrls: ['./authorisation-column-header.style.scss'],
})
export class AuthorisationColumnHeaderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  value: string;
  tips: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.tips = this.params.colDef.cellRendererParams.tips(this.params);
  }

  refresh(params: any): boolean {
    return false;
  }
}

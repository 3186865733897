import { SupplierNo } from '../models/supplier-no';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SupplierNoApi } from '../api/supplier-no';

@Injectable()
export class SupplierNoService {

  constructor(private supplierNoApi: SupplierNoApi) {
  }

  getSupplierNos(dealerId: string): Observable<SupplierNo[]> {
    return this.supplierNoApi.getSupplierNos(dealerId);
  }
}

import { Injectable } from '@angular/core';
import { ServerTimeApi } from '../api/server-time';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';
import { Cacheable } from '@otr/website-common';

@Injectable()
export class ServerTimeService {
  private timeSpan: number;

  constructor(private serveTimerApi: ServerTimeApi) {
  }

  get currentDate() {
    return new Date(Date.now() - (this.timeSpan || 0));
  }

  @Cacheable()
  setTimeIntervalWithServerTime() {
    return this.serveTimerApi.getServerTime().pipe(
      tap(serverTime => {
        const serverTimestamp = new Date(serverTime).getTime();
        if (!_.isNaN(serverTimestamp)) {
          this.timeSpan = Date.now() - serverTimestamp;
        }
      }),
    );
  }
}

import { Body, GET, POST, Query, RebirthHttp } from 'rebirth-http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SimilarVehicleApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/vehicles/similar-vehicles')
  getSimilarVehicles(@Query('order_id') orderId: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/vehicles/similar-vehicles')
  getSimilarVehiclesV2(@Query('order_id') orderId: string): Observable<any> {
    return null;
  }

  @POST('/api/v2/orders/change-similar-vehicle')
  changeSimilarVehicle(@Body body: Object): Observable<any> {
    return null;
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import { VehicleBusinessType } from '@otr/website-common';
import { LPO } from '../../../../../service-contract/constants/service-contract';

@Component({
  template: `
    <div class="activateStatusTooltip">
      <div class='status-tip-wrapper'>
        <span>{{statusCn}}</span>
        <ng-template #iconTooltipTemplate>
          <span class="tooltipText" *ngIf="!iconErrorMessage">该车已有同类礼包被激活，请在EVA中确认</span>
          <span class="tooltipText" *ngIf="iconErrorMessage">{{iconErrorMessage}}</span>
        </ng-template>
        <ng-template #dscTooltipTemplate>
          <span class="tooltipText" *ngIf="!iconErrorMessage">激活失败，请在DCS中确认</span>
          <span class="tooltipText" *ngIf="iconErrorMessage">{{iconErrorMessage}}</span>
        </ng-template>
        <ng-template #lpoTooltipTemplate>
          <span class="tooltipText" *ngIf="!lpoActivateFailedMessage">激活失败，请在LPO中确认</span>
          <span class="tooltipText" *ngIf="lpoActivateFailedMessage">{{lpoActivateFailedMessage}}</span>
        </ng-template>
        <i *ngIf="shouldDisplayTooltip"
           class="fa antd-exclamation-circle-filled"
           placement="bottom"
           [tooltip]="isLpo? lpoTooltipTemplate : isVan ? dscTooltipTemplate : iconTooltipTemplate"
           container="body">
        </i>
      </div>
    </div>
  `,
  styleUrls: ['./activate-status-cell.style.scss'],
})
export class ActivateStatusCellComponent implements ICellRendererAngularComp {
  public value: string;
  public statusCn: string;
  public isVan: boolean = false;
  public isLpo: boolean = false;
  public iconErrorMessage: string;
  public lpoActivateFailedMessage: string;
  params: ICellRendererParams;
  shouldDisplayTooltip: boolean = false;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.statusCn = params.node.data.statusCn;
    this.iconErrorMessage = params.node.data.iconActivateFailedMessage;
    this.lpoActivateFailedMessage = params.node.data.lpoActivateFailedMessage;
    this.isVan = (VehicleBusinessType.VAN.value === params.node.data.vehicleBusinessType);
    this.isLpo = params.node.data.source === LPO;
    this.shouldDisplayTooltip = (params.value === 'PREVIOUS_ACTIVATED' ||
      params.value === 'VALIDATE_FAILED' || (params.value === 'LPO_DUPLICATE' && this.isLpo));
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}

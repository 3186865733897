import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

import { ConfirmService } from '../../services/confirm.service';
import { CurrentUserService, TokenService } from '@otr/website-common';

import { SalesServiceContractApi } from '../../api/sales-service-contract';

@Component({
  selector: 'app-upload-require-form',
  templateUrl: 'upload-require-form.template.html',
  styleUrls: ['upload-require-form.style.scss'],
})
export class UploadRequireFormComponent implements OnDestroy {

  excelFile;
  isUploadSucceed: boolean | null = null;
  errorGemsId: string[] = [];
  inProgress: boolean = false;
  errorMessage: string[] = [];
  pollingId = null;
  isCompleteUpload: boolean = false;

  @Input() isDisabled: boolean = false;
  @Input() type: string = '';
  @Input() placeholder: string = '请选择上传的文件';
  @Input() uploadUrl: string;
  @Input() extensions: string[];
  @Input() updatedTime: string = '';
  @Input() contractType: string = '';
  @Input() description: string = '';
  @Input() errorMessagePrefix: string = '';
  @Input() headers: Object = {};
  @Input() confirmMessage: string = '';
  @Input() title: string = '导入';
  @Output() onSucceed: EventEmitter<any> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  @Output() onDocumentError: EventEmitter<any> = new EventEmitter();
  @ViewChild('uploadField') uploadField: ElementRef;

  constructor(private currentUserService: CurrentUserService,
              private tokenService: TokenService,
              private confirmService: ConfirmService,
              private salesServiceContractApi: SalesServiceContractApi) {
  }

  onExcelSelected(event) {
    const files = FileAPI.getFiles(event);
    const file = files[0];
    if (_.isUndefined(file)) {
      return;
    }
    const isValidFormat = _.some(this.extensions, extension => _.endsWith(file.name, extension));
    if (isValidFormat) {
      this.excelFile = file;
      this.onExcelUpload();
    } else {
      alert('请选择正确的文件');
    }
    this.uploadField.nativeElement.value = '';
  }

  onExcelUpload() {

    if (!this.excelFile) {
      return;
    }

    if (this.type === 'document' && !this.description) {
      this.onDocumentError.emit();
      return;
    }

    this.isUploadSucceed = null;
    // @ts-ignore
    this.headers.Authorization = `Bearer ${this.tokenService.accessToken}`;
    FileAPI.upload({
      url: this.uploadUrl,
      headers: this.headers,
      files: { file: this.excelFile },
      data: {
        date: new Date().setHours(0, 0, 0, 0),
        dealer_id: this.currentUserService.getCurrentUser().dealerId,
        type: this.contractType,
        description: this.description,
      },
      progress: () => {
        this.inProgress = true;
      },
      complete: (err, xhr) => {
        this.handleComplete(err, xhr);
      },
    });
  }

  private handleComplete(err, xhr) {
    this.errorMessage = [];
    // this.inProgress = false;
    this.isUploadSucceed = true;
    if (err) {
      this.isUploadSucceed = false;

      if (this.type === 'service contract') {
        try {
          this.errorMessage = [JSON.parse(xhr.response).message] || [];
        } catch (error) {
          this.errorMessage = [];
        }
      }

      this.onError.emit(this.getErrorMessage(err, xhr));
      return;
    }

    if (this.type === 'document') {
      this.onSucceed.emit(xhr.response);
      try {
        this.errorMessage = [JSON.parse(xhr.response).message] || [];
      } catch (error) {
        this.errorMessage = ['未知错误'];
      }
      return;
    }

    const result = JSON.parse(xhr.responseText);
    this.handleTemplateResponse(result);
  }

  private getErrorMessage(err, xhr) {
    let message;
    switch (err) {
      case 'Gateway Time-out':
        message = '系统超时，可能网络繁忙，请重试';
        break;
      case 'Bad Request':
        const error_code = JSON.parse(xhr.response).error_code;
        message = error_code === 'unexpect_column_value' ? JSON.parse(xhr.response).message : '导入失败，请检查文件格式是否正确或下载新模板';
        break;
      case 'Not Acceptable':
        message = '导入失败，请检查文件格式是否正确';
        break;
      case 'Unprocessable Entity':
        message = '经销商不可添加BMBS服务合同，仅可修改已有的BMBS服务合同数据';
        break;
      default:
        message = '未知错误';
        break;
    }
    return message;
  }

  handleTemplateResponse(result: any) {
    this.errorGemsId = [];
    if (!_.isEmpty(result.error_gems_id)) {
      this.errorGemsId = result.error_gems_id;
      return;
    }

    this.errorMessage = result.message || [];
    if (_.isEmpty(result.message)) {
      // this.onSucceed.emit(result);
      this.pollingRequireResult(result);
    } else {
      this.isUploadSucceed = false;
    }
  }

  onUploadClick() {
    if (this.confirmMessage) {
      this.confirmService.confirm({
        message: this.confirmMessage,
      }).subscribe(result => result &&
        setTimeout(() => {
          this.uploadField.nativeElement.click();
        }, 500));
      return;
    }
    this.uploadField.nativeElement.click();
  }

  pollingRequireResult(result) {
    const key = result.redis_key;
    this.pollingId = setInterval(() => {
      this.salesServiceContractApi.getUploadStatus(key).subscribe(
        res => {
          if (res.message[0] === '0') {
            clearInterval(this.pollingId);
            this.inProgress = false;
            this.isCompleteUpload = true;
            this.onSucceed.emit(result);
          }
        },
        err => {
          clearInterval(this.pollingId);
          this.inProgress = false;
          this.isCompleteUpload = false;
          this.isUploadSucceed = false;
        },
      );
    }, 5000);
  }

  ngOnDestroy() {
    clearInterval(this.pollingId);
  }
}

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';
import { Component } from '@angular/core';

@Component({
  template: `<div [title]="content" class="multiple-lines" [ngStyle]="style">{{ content }}</div>`,
  styleUrls: ['./multiple-lines-ellipsis.style.scss'],
})
export class MultipleLinesEllipsisRenderComponent implements ICellRendererAngularComp {

  content: string;
  style;

  agInit(params: ICellRendererParams): void {
    const getContent = _.get(params.colDef, 'cellRendererParams.getContent');
    this.content = _.isFunction(getContent) ? getContent(params) : (params.valueFormatted || params.value);
    const getCustomizedStyle = _.get(params.colDef, 'cellRendererParams.getCustomizedStyle');
    const customizedStyle = _.isFunction(getCustomizedStyle) ? getCustomizedStyle(params.data) : undefined;
    const lineClamp = _.get(params.colDef, 'cellRendererParams.lineClamp', 2);
    const maxHeight = _.get(params.colDef, 'cellRendererParams.maxHeight');
    this.style = {'-webkit-line-clamp': lineClamp, 'max-height': maxHeight };
    if (_.isObject(customizedStyle)) {
      this.style = {
        ...this.style,
        ...customizedStyle,
      };
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}

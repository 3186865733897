<div
  #elem
  (mousedown)="onMousedown($event)"
  id="component__inbound-call-drag-bar">
  <div class="inbound-call-menu" *ngIf="isExpandMenu; else collapsedMenu">
    <div class="inbound-call-title">
      <p>
        <span>{{isCalling ? '正在通话' : '来电提醒'}}</span>
        <span class="inbound-call-duration" *ngIf="isCalling">{{getCallDuration()}}</span>
      </p>
      <div>
        <img class="assistant" *ngIf="showAssistantIcon && isShowCTI" src="/assets/inbound-call/assistant-icon.png" alt="" (click)="foldAssistantPage('click')">
        <img src="/assets/inbound-call/menu-icon.png" alt="" (click)="handlechangeMenu()">
      </div>
    </div>
    <div class="inbound-call-operation" [ngStyle]="{'height': isCalling ? '156px' : ''}">
      <div>
        <p class="call-operation-phone">{{data.origcaller}}</p>
        <p class="call-company-name" *ngIf="companyName">{{companyName}}</p>
        <div class="call-operation-menu">
          <div class="incoming-call" *ngIf="!isCalling; else inCallBlock">
            <img src="/assets/inbound-call/incoming-call-actived-icon.gif" alt="">
            <p>{{data.inCallMessage}}</p>
            <img src="/assets/inbound-call/incoming-call-actived-icon.gif" alt="">
          </div>
          <ng-template #inCallBlock>
            <div class="in-call" [ngClass]="{'center-container': isTransfer || isConsult}">
              <button [ngClass]="{'active': isMute,'disabled': isHolding || telephonistStatus === 'CONSULT_CALL_IN'}" (click)="handleMuteChange()" *ngIf="!(isTransfer || isConsult)">
                <img *ngIf="telephonistStatus === 'CONSULT_CALL_IN'" src="/assets/inbound-call/new-mute-icon-disabled.png">
                <img *ngIf="telephonistStatus !== 'CONSULT_CALL_IN'" [src]="isMute ? '/assets/inbound-call/new-mute-icon-active.png' : '/assets/inbound-call/new-mute-icon-default.png'" alt="">
                <p>静音</p>
              </button>
              <button [ngClass]="{'active': isHolding,'disabled': isMute || telephonistStatus === 'CONSULT_CALL_IN'}" (click)="handleHoldChange()" *ngIf="!(isTransfer || isConsult)">
                <img *ngIf="telephonistStatus === 'CONSULT_CALL_IN'" src="/assets/inbound-call/new-mute-icon-disabled.png">
                <img *ngIf="telephonistStatus !== 'CONSULT_CALL_IN'" [src]="isHolding ? '/assets/inbound-call/new-hold-icon-active.png' : '/assets/inbound-call/new-hold-icon-default.png'" alt="">
                <p>保持</p>
              </button>
              <button [ngClass]="{'disabled': isMute || isHolding || isInTransferOrConsult}" (click)="handleTransferChange()" *ngIf="!isConsult">
                <img *ngIf="!(isMute || isHolding || isInTransferOrConsult)" [src]="isTransfer ? isAllowExit ? '/assets/inbound-call/new-exit-icon-default.png' : '/assets/inbound-call/new-exit-icon-disabled.png' : '/assets/inbound-call/new-transfer-icon-default.png'" alt="">
                <img *ngIf="isMute || isHolding || isInTransferOrConsult" src="/assets/inbound-call/new-transfer-icon-disable.png" alt="">
                <p>{{isTransfer ? '退出转接' : '转接'}}</p>
              </button>
              <button [ngClass]="{'disabled': isMute || isHolding || isInTransferOrConsult}" (click)="handleConsultAdviceChange()" *ngIf="!isTransfer">
                <img *ngIf="!(isMute || isHolding || isInTransferOrConsult)" [src]="isConsult ? isAllowExit ? '/assets/inbound-call/new-exit-icon-default.png' : '/assets/inbound-call/new-exit-icon-disabled.png' : '/assets/inbound-call/new-seek-icon-default.png'" alt="">
                <img *ngIf="isMute || isHolding || isInTransferOrConsult" src="/assets/inbound-call/new-seek-icon-disable.png" alt="">
                <p>{{isConsult ? '退出咨询' : '咨询'}}</p>
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div style="height: calc(30px + 38vh); overflow-y: auto;">
      <app-telephonist-status *ngIf="isConsult || isTransfer" [type]="currentTelephonistStatus" (setExitStatus)="setExitStatus($event)"></app-telephonist-status>
      <div class="inbound-call-customer" [ngStyle]="{'margin-top': isCalling ? '0px': ''}" *ngIf="customerList.length > 0; else noCustomer">
        <h2 class="inbound-call-contacts-title">相关联系人</h2>
        <div class="inbound-call-customer-list">
          <div class="customer-item" *ngFor="let customer of customerList; let index = index;">
            <div class="customer-item-header" [ngClass]="{'active-header': index === expandIndex}">
              <div class="customer-item-header-info">
                <div class="cusotmer-info-name" (click)="handleNameClick(customer.id)">
                  <p>{{customer.full_name}}</p>
                  <img src="/assets/inbound-call/female-icon.png" alt="" *ngIf="customer.gender === 'Female'">
                  <img src="/assets/inbound-call/male-icon.png" alt="" *ngIf="customer.gender !== 'Female'">
                </div>
                <p class="cusotmer-info-company" *ngIf="cecId">{{customer.dealer_name}}</p>
              </div>
              <img src="/assets/inbound-call/arrow-icon.png" alt="" (click)="handleClickSelection(index)" [ngClass]="{'customer-info-arrow': true, 'active-arrow': index === expandIndex}">
              <div class="customer-item-header-highlight" *ngIf="index === expandIndex"></div>
            </div>
            <div [ngClass]="{'customer-item-main': true, 'customer-item-main-collapsed': !(index === expandIndex)}">
              <div class="vehicle-list-container" *ngIf="customer.currentVehicleList.length > 0">
                <p class="vehicle-theme-title">现有车（{{customer.currentVehicleList.length}}台）</p>
                <ul>
                  <li class="vehicle-list" *ngFor="let currentVehicle of customer.currentVehicleList">
                    <div class="list-item">
                      <p class="list-item-label">车牌号</p>
                      <p class="list-item-value list-item-value-bold">{{currentVehicle.carPlateNumber}}</p>
                    </div>
                    <div class="list-item">
                      <p class="list-item-label">VIN</p>
                      <p class="list-item-value">{{currentVehicle.vin}}</p>
                    </div>
                    <div class="list-item">
                      <p class="list-item-label">车型</p>
                      <p class="list-item-value">{{currentVehicle.description}}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="vehicle-list-container" *ngIf="hasOpenLead(customer, 'interestedVehicles')">
                <p class="vehicle-theme-title">意向车</p>
                <ul>
                  <li class="vehicle-list">
                    <div class="list-item" *ngIf="hasOpenLead(customer, 'interestedVehicles')">
                      <p class="list-item-label">车型</p>
                      <p class="list-item-value">{{getInterestedVehicle(customer, 'interestedVehicles') || '暂无意向车'}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noCustomer>
        <div class="intbound-call-no-customer">
          <div>
            <h3>暂无匹配的联系人</h3>
            <p (click)="addCustomerAndLead()" *ngIf="shouldShowAddCustomerBtn">+新建客户/销售线索</p>
            <p style='margin-top: 10px;'(click)="addAfteSalesOnCall()">+新建售后线索</p>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="inbound-call-answer">
      <div class="answer-operation" (click)="handleRefuseButton()">
        <img src="/assets/inbound-call/refuse-icon.png" alt="">
        <p>{{isCalling ? '挂断' : '拒绝'}}</p>
      </div>
      <div class="answer-operation" *ngIf="!isCalling" (click)="handleApproveButton()">
        <img src="/assets/inbound-call/answer-icon.png" alt="">
        <p>接听</p>
      </div>
    </div>
  </div>
  <ng-template #collapsedMenu>
    <div class="inbound-call-collapsed-menu">
      <div class="collapsed-menu-title">
        <img class="assistant" *ngIf="showAssistantIcon && isShowCTI" src="/assets/inbound-call/assistant-icon.png" alt="" (click)="foldAssistantPage('click')">
        <img src="/assets/inbound-call/collapsed-menu-icon.png" alt="" (click)="handlechangeMenu()">
      </div>
      <div class="collapsed-menu-operation">
        <div class="collapsed-menu-call">
          <p class="phone-number">{{customerList.length > 0 ? currentSelectedCustomer().full_name : '新号码'}}</p>
          <p class="inbound-call-duration" *ngIf="isCalling">{{getCallDuration()}}</p>
          <div *ngIf="!isCalling">
            <p class="call-remind">正在呼叫您</p>
            <img src="/assets/inbound-call/incoming-call-actived-icon.gif" alt="">
          </div>
        </div>
      </div>
      <div class="collapsed-menu-answer">
        <div class="answer-operation" *ngIf="isCalling" (click)="handleMuteChange()">
          <img [src]="isMute ? '/assets/inbound-call/collapsed-mute-icon.png' : '/assets/inbound-call/collapsed-no-mute-icon.png'" [ngStyle]="{'opacity': telephonistStatus === 'CONSULT_CALL_IN' ? '0.3': '1'}">
          <p>{{isMute ? '取消静音' : '静音'}}</p>
        </div>
        <div class="answer-operation" *ngIf="isCalling" (click)="handleHoldChange()">
          <img [src]="isHolding ? '/assets/inbound-call/collapsed-hold-icon.png' : '/assets/inbound-call/collapsed-no-hold-icon.png'" [ngStyle]="{'opacity': telephonistStatus === 'CONSULT_CALL_IN' ? '0.3': '1'}">
          <p>{{isHolding ? '取消保持' : '通话保持'}}</p>
        </div>
        <div class="answer-operation" (click)="handleRefuseButton()">
          <img src="/assets/inbound-call/refuse-icon.png" alt="">
          <p>{{isCalling ? '挂断' : '拒绝'}}</p>
        </div>
        <div class="answer-operation" *ngIf="!isCalling" (click)="handleApproveButton()">
          <img src="/assets/inbound-call/answer-icon.png" alt="">
          <p>接听</p>
        </div>
      </div>
    </div>
  </ng-template>
</div>

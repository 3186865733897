import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../_layout/layout.module';
import { CommonComponentModule } from '../_common/common-component.module';
import { CalendarModule, ChartModule } from 'primeng/primeng';

import { BsDropdownModule, PaginationModule, TooltipModule } from 'ngx-bootstrap';

import { CustomerDetailComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/customer-detail/customer-detail.component';
import { CustomerCurrentVehicleComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/customer-current-vehicle/customer-current-vehicle.component';
import { CustomerProfileComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/customer-profile/customer-profile.component';
import { CustomerOfferComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/customer-offer/customer-offer.component';
import { ActivityHistoryComponent } from 'app/customer-lead-management/lead-management/sales-lead/lead-information/activity-history/activity-history.component';
import { SyncFollowUpRecordsComponent } from 'app/customer-lead-management/lead-management/sales-lead/lead-information/sync-follow-up-records/sync-follow-up-records.component';
import { DuplicateLeadComponent } from 'app/customer-lead-management/lead-management/sales-lead/duplicate-lead/duplicate-lead.component';
import { DropdownListComponent } from 'app/customer-lead-management/lead-management/sales-lead/component/dropdown-list/dropdown-list.component';
import { FilterPopoverComponent } from 'app/customer-lead-management/lead-management/sales-lead/filter/filter-popover/filter-popover.component';
import { ServiceHistoryTableComponent } from 'app/customer-lead-management/lead-management/sales-lead/lead-information/service-history-table/service-history-table.component';
import { ServiceContractModalComponent } from 'app/customer-lead-management/lead-management/sales-lead/lead-information/current-vehicle/service-contract-modal/service-contract-modal.component';
import { CreateCurrentAfterSalesLeadComponent } from 'app/customer-lead-management/lead-management/after-sales-lead/create/create.component';
import { OtrNgComponentsModule } from '@otr/ng-components';
import { ComplainHistoryComponent } from 'app/customer-lead-management/customer-management/components/complain-history/complain-history.component';
import { CustomerInfoComponent } from 'app/customer-lead-management/lead-management/sales-lead/lead-information/customer-info/customer-info.component';
import { EditCustomerComponent } from 'app/customer-lead-management/lead-management/sales-lead/edit-customer/edit-customer.component';
import { ActivityFilterComponent } from 'app/customer-lead-management/lead-management/sales-lead/lead-information/activity-filter/activity-filter.component';
import { CustomerInfoHistoryComponent } from 'app/customer-lead-management/customer-info-history/customer-info-history.component';
import { ActivityTableComponent } from 'app/customer-lead-management/lead-management/sales-lead/lead-information/activity-table/activity-table.component';
import { HistoryRowComponent } from 'app/customer-lead-management/as-lead/as-detail/service-history/history-row/history-row.component';
import { AppActivityServiceHistoryRowComponent } from 'app/customer-lead-management/lead-management/sales-lead/lead-information/service-history-table/service-history-row/history-row.component';
import { CustomerHobbySelectorTreeComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-hobby-selector-tree/customer-hobby-selector-tree.component';
import { CdoVehicleSelectComponent } from 'app/customer-lead-management/lead-management/sales-lead/vehicle-select/cdo-vehicle-select.component';
import { PaymentCodePipe } from 'app/customer-lead-management/pipes/payment-code.pipe';

import { RouterModule } from '@angular/router';
import { GridModule } from '../_common/components/grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomerProfileService } from 'app/customer-lead-management/lead-management/shared/services/customer-profile.service';
import { CustomerProfiletApi } from 'app/customer-lead-management/lead-management/shared/api/customer-profilet';
import { ActivityHistoryService } from 'app/customer-lead-management/lead-management/sales-lead/shared/activity-history.service';
import { CustomerCriteriaConfigService } from 'app/customer-lead-management/customer-criteria-config/customer-criteria-config.service';
import { CustomerCurrentTaskComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/customer-current-task/customer-current-task.component';
import { ArrangeIconCellComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/customer-current-task/arrange-icon-cell/arrange-icon-cell.component';
import { CustomerTaskFormComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/customer-task-form/customer-task-form.component';
import { CustomerCreateTaskFormComponent } from './lead-management/sales-lead/customer-information/customer-current-task/customer-create-task-form/customer-create-task-form.component';
import { ChangeCustomerFormComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/change-customer-form/change-customer-form.component';
import { DetailMobileSearchResultComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/detail-mobile-search-result/detail-mobile-search-result.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { TaskMigrateModalComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/task-migrate-modal/task-migrate-modal.component';
import { CompleteTaskFormComponent } from 'app/customer-lead-management/lead-management/sales-lead/customer-information/complete-task-form/complete-task-form.component';
import { CustomerService } from './lead-management/sales-lead/services/customer.service';
import { CustomerApi } from './lead-management/shared/api/customer';
import { CustomerManagementApi } from './customer-management/shared/customer-management.api';
import { CustomerManagementService } from './customer-management/shared/customer-management.service';
import { AddFollowUpModalComponent } from './lead-management/sales-lead/add-follow-up-modal/add-follow-up-modal.component';
import { KeepFollowUpComponent } from './lead-management/sales-lead/keep-follow-up/keep-follow-up.component';
import { AssignScModalComponent } from './lead-management/sales-lead/assign-sc-modal/assign-sc-modal.component';
import { CloseLeadModalComponent } from './lead-management/sales-lead/close-lead-modal/close-lead-modal.component';
import { SalesLeadModalComponent } from './lead-management/sales-lead/sales-lead-modal/sales-lead-modal.component';
import { CharacterComponent } from 'app/customer-lead-management/lead-management/sales-lead/component/character-component/character.component';
@NgModule({
  imports: [
    CommonModule,
    CalendarModule,
    LayoutModule,
    CommonComponentModule,
    OtrNgComponentsModule,
    RouterModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    InlineSVGModule,
  ],
  providers: [
    CustomerProfileService,
    CustomerProfiletApi,
    ActivityHistoryService,
    CustomerCriteriaConfigService,
    CustomerService,
    CustomerApi,
    CustomerManagementApi,
    CustomerManagementService,
  ],
  declarations: [
    CustomerDetailComponent,
    CustomerCurrentVehicleComponent,
    CustomerProfileComponent,
    CustomerOfferComponent,
    ActivityHistoryComponent,
    SyncFollowUpRecordsComponent,
    DuplicateLeadComponent,
    DropdownListComponent,
    CharacterComponent,
    FilterPopoverComponent,
    ServiceHistoryTableComponent,
    ServiceContractModalComponent,
    CreateCurrentAfterSalesLeadComponent,
    ComplainHistoryComponent,
    CustomerInfoComponent,
    EditCustomerComponent,
    ActivityFilterComponent,
    CustomerInfoHistoryComponent,
    ActivityTableComponent,
    HistoryRowComponent,
    AppActivityServiceHistoryRowComponent,
    CustomerHobbySelectorTreeComponent,
    CdoVehicleSelectComponent,
    PaymentCodePipe,
    CustomerCurrentTaskComponent,
    ArrangeIconCellComponent,
    CustomerTaskFormComponent,
    CustomerCreateTaskFormComponent,
    ChangeCustomerFormComponent,
    DetailMobileSearchResultComponent,
    TaskMigrateModalComponent,
    CompleteTaskFormComponent,
    AddFollowUpModalComponent,
    KeepFollowUpComponent,
    AssignScModalComponent,
    CloseLeadModalComponent,
    SalesLeadModalComponent
  ],
  exports: [
    CustomerInfoComponent,
    CustomerProfileComponent,
    CustomerInfoHistoryComponent,
    ComplainHistoryComponent,
    EditCustomerComponent,
    CdoVehicleSelectComponent,
    ServiceContractModalComponent,
    CustomerHobbySelectorTreeComponent,
    CustomerCurrentVehicleComponent,
    ActivityFilterComponent,
    ActivityTableComponent,
    ActivityHistoryComponent,
    ServiceHistoryTableComponent,
    DuplicateLeadComponent,
    SyncFollowUpRecordsComponent,
    CustomerDetailComponent,
    CustomerOfferComponent,
    DropdownListComponent,
    CharacterComponent,
    HistoryRowComponent,
    FilterPopoverComponent,
    OtrNgComponentsModule,
    CustomerCurrentTaskComponent,
    ArrangeIconCellComponent,
    CustomerTaskFormComponent,
    ChangeCustomerFormComponent,
    DetailMobileSearchResultComponent,
    TaskMigrateModalComponent,
    CompleteTaskFormComponent,
    KeepFollowUpComponent,
    AssignScModalComponent,
    // CloseLeadModalComponent,
  ],
  entryComponents: [
    ArrangeIconCellComponent,
    AddFollowUpModalComponent,
    AssignScModalComponent,
    CloseLeadModalComponent,
    SalesLeadModalComponent,
  ],
})
export class CustomerLeadManagementSharedModule {
}

import { Injectable } from '@angular/core';
import { Body, GET, PATCH, Path, POST, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IdlingType, IdleInfo, TechnicianTeamIdleInfo } from '../models/technician-team';

@Injectable()
export class TechnicianIdleApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/idles')
  getIdlingTypes(): Observable<IdlingType[]> {
    return null;
  }

  @POST('/api/sales-website/technician-idle-infos/clock-on')
  startIdle(@Body body: any): Observable<void> {
    return null;
  }

  @POST('/api/sales-website/technician-idle-infos/batch-clock-on')
  batchStartIdle(@Body body: any): Observable<void> {
    return null;
  }

  @GET('/api/sales-website/technician-idle-infos')
  getIdleInfo(@Query('technicianId') technicianId: number): Observable<IdleInfo> {
    return null;
  }

  @GET('/api/sales-website/technician-teams/idle-infos')
  getTeamIdleInfo(@Query('date') date: string): Observable<TechnicianTeamIdleInfo[]> {
    return null;
  }

  @POST('/api/sales-website/technician-idle-infos/:idleInfoId/clock-off/clock-on-idle')
  stopIdleAndStartIdle(@Path('idleInfoId') idleInfoId: number,
                       @Body body: any): Observable<void> {
    return null;
  }

  @PATCH('/api/sales-website/technician-idle-infos/:idleInfoId/clock-off/sign-out')
  stopIdleAndSignOut(@Path('idleInfoId') idleInfoId: number,
                     @Body body: any): Observable<void> {
    return null;
  }

  @PUT('/api/sales-website/technician-idle-infos/:idleInfoId')
  updateIdleInfo(@Path('idleInfoId') idleInfoId: number, @Body body: any): Observable<any> {
    return null;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  @Input() disabled = false;
  @Input() checked = false;
  @Input() name = '';
  @Input() id = '';
  @Input() control: FormControl = new FormControl();
  @Output() change = new EventEmitter();

  onChange(): void {
    if (this.disabled) { return; }
    const checked = !this.checked;
    this.control.setValue(checked);
    this.change.emit(checked);
  }
}

import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular/main';
import { ICellEditorParams } from 'ag-grid';
import { ExtendedGridEvents } from '../../event/events';
import * as _ from 'lodash';

@Component({
  selector: 'app-double-input-editor',
  templateUrl: 'model-year-change-year-editor.template.html',
  styleUrls: ['model-year-change-year-editor.style.scss', '../editor.style.scss'],
})
export class ModelYearChangeYearEditorComponent implements ICellEditorAngularComp {
  leftEditParams;
  rightEditParams;
  params: ICellEditorParams;
  errorMessage: string;
  value: any;
  leftPlaceholder;
  rightPlaceholder;
  leftValue: string = '';
  rightValue: string = '';
  leftMaxLength: number;
  rightMaxLength: number;
  leftInvalidMessage: '';
  rightInvalidMessage: '';

  agInit(params: any): void {
    this.params = params;
    this.leftEditParams = this.getCellEditorParams().leftEditParams;
    this.rightEditParams = this.getCellEditorParams().rightEditParams;
    this.initData();
    this.setErrorMessage();
  }

  initData() {
    this.value = _.isNil(this.params.value) ? '' : this.params.value;
    this.leftPlaceholder = this.leftEditParams.placeholder ||  '';
    this.rightPlaceholder = this.rightEditParams.placeholder ||  '';
    this.leftValue = this.params.node.data[this.leftEditParams.field];
    this.rightValue = this.params.node.data[this.rightEditParams.field];
    this.leftMaxLength = this.leftEditParams.maxLength;
    this.rightMaxLength = this.rightEditParams.maxLength;
  }

  setErrorMessage() {
    this.getErrorMessage();
    this.eventEmitter.on(ExtendedGridEvents.SHOW_ERROR_MESSAGE, () => {
      this.getErrorMessage();
    });
  }

  get colId() {
    return this.params.column.getColId();
  }

  get eventEmitter() {
    return this.params.context.eventEmitter;
  }

  getCellEditorParams() {
    return this.params.column.getColDef().cellEditorParams || {};
  }

  getErrorMessage() {
    this.leftInvalidMessage = this.leftEditParams.getErrorMessage(this.colId);
    this.rightInvalidMessage = this.rightEditParams.getErrorMessage(this.colId);
  }

  getValue(): any {
    return this.value;
  }

  onLeftInputChange(event) {
    this.leftValue = event.target.value;
    this.updateSourceValue(this.leftEditParams.field, event.target.value);
  }

  onRightInputChange(event) {
    this.rightValue = event.target.value;
    this.updateSourceValue(this.rightEditParams.field, event.target.value);
  }

  updateSourceValue(field, value) {
    _.set(this.params.node.data, field, value);
  }
}

import { Component, Input, OnInit, Output,  EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-zorro',
  templateUrl: './table-zorro.component.html',
  styleUrls: ['./table-zorro.component.scss'],
})
export class TableZorroComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() pagination = {
    pageIndex: 0,
    pageSize: 30,
    total: 0,
  };
  @Input() listOfData = [];
  @Input() nzWidthConfig = [];
  @Input() tableScroll = {};
  @Input() paginationDisabled: boolean = false;
  @Output() pageChanged = new EventEmitter();

  ngOnInit() {

  }

  onPageChanged(event) {
    if (this.pagination.pageIndex === event.page) {
      return;
    }
    this.pageChanged.emit(event);
  }

}

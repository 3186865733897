import * as moment from 'moment';

import { percentageValueFormatter, vehicleBusinessTypeFormatter } from '../utils/utils';
import { FILTER_INPUT_TYPES, VEHICLE_BUSINESS_TYPE_OPTION } from '../constants/report';

import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import { TooltipsHeaderComponent } from '../../_common/components/grid/header/tooltip/tooltips-header.component';

export const SERVICE_MEASURE_T1_COMPLETION_RATE_REPORT_CONFIG = {
  name: 'service-measure-t1-completion-rate-report',
  enablePagination: false,
  exportedFileName: '服务措施完成率T1报表.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: 'GS Code',
      field: 'gsCode',
      width: 225,
    }, {
      headerName: '车辆类型',
      field: 'vehicleBusinessType',
      width: 225,
      valueFormatter: vehicleBusinessTypeFormatter,
    }, {
      headerName: 'VEGA/DCS Code',
      field: 'vegaCode',
      width: 225,
    }, {
      headerName: '经销商名称',
      field: 'dealerName',
      width: 225,
    }, {
      headerName: 'T1-影响',
      field: 't1ImpactCount',
      headerTooltip: '车辆首次进店，并登记进厂',
      headerComponentFramework: TooltipsHeaderComponent,
      hideHeaderMenu: true,
      width: 225,
    }, {
      headerName: 'T1-完成',
      field: 't1CompletionCount',
      headerTooltip: '车辆首次进店，分配DC并完成结账',
      headerComponentFramework: TooltipsHeaderComponent,
      hideHeaderMenu: true,
      width: 225,
    }, {
      headerName: 'T1-完成率',
      field: 't1CompletionRate',
      headerTooltip: '=T1-完成/T1-影响*100%',
      headerComponentFramework: TooltipsHeaderComponent,
      hideHeaderMenu: true,
      width: 225,
      valueFormatter: data => `${percentageValueFormatter(data)}%`,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'recordCreatedDate',
      label: '登记进厂日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    }, {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型：',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
  ],
};

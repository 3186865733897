import { Injectable } from '@angular/core';
import { Body, GET, PATCH, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LeadManagementApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/after-sales/nev/wall-box-orders')
  getWallBoxOrders(@Query('quotationIds') quotationIds: string): Observable<any> {
    return null;
  }

  @GET('/api/after-sales/nev/wall-box-orders/:quotationId')
  getWallBoxOrder(@Path('quotationId') quotationId: number): Observable<any> {
    return null;
  }

  @POST('/api/sales/nev/wall-box-orders')
  createWallBoxOrder(@Body data): Observable<any> {
    return null;
  }

  @POST('/api/sales/nev/wall-box-orders/:orderId/pre-check')
  createWallBoxPreCheck(@Path('orderId') orderId: number, @Body body: object): Observable<any> {
    return null;
  }

  @POST('/api/sales/nev/wall-box-orders/:orderId/pending-request')
  createWallBoxPending(@Path('orderId') orderId: number, @Body body: object): Observable<any> {
    return null;
  }

  @PATCH('/api/sales/nev/wall-box-orders/:orderId/pre-check')
  cancelWallBoxPreCheck(@Path('orderId') orderId: number, @Body body: object): Observable<any> {
    return null;
  }

  @PATCH('/api/sales/nev/wall-box-orders/:orderId/installment-request')
  cancelWallBoxInstallment(@Path('orderId') orderId: number, @Body body: object): Observable<any> {
    return null;
  }

  @PATCH('/api/sales/nev/wall-box-orders/:orderId/delivery-request')
  cancelWallBoxDelivery(@Path('orderId') orderId: number, @Body body: object): Observable<any> {
    return null;
  }

  @PATCH('/api/sales/nev/wall-box-orders/:orderId')
  updateWallBoxOrder(@Path('orderId') orderId: number, @Body body: object): Observable<any> {
    return null;
  }

  @POST('/api/sales/nev/wall-box-orders/:orderId/installment-request')
  createWallBoxInstallment(@Path('orderId') orderId: number, @Body body: object): Observable<any> {
    return null;
  }

  @POST('/api/sales/nev/wall-box-orders/:orderId/delivery-request')
  createWallBoxDelivery(@Path('orderId') orderId: number, @Body body: object): Observable<any> {
    return null;
  }
}

import { Injectable } from '@angular/core';
import { Body, DELETE, GET, Header, Headers, PATCH, Path, POST, PUT, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AftermarketMasterDataApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/part-vehicle-accessories/dealers/:dealerId/descriptions')
  getDescriptionByPartNo(@Path('dealerId') dealerId: string,
                         @Query('partNo') partNo: string,
                         @Header('Vehicle-Business-Type') vehicleBusinessType: string): Observable<any> {
    return null;
  }

  @GET('/api/part-vehicle-accessories')
  getAccessories(@Query('filter') filter: string,
                 @Query('size') size: string,
                 @Query('page') page: string,
                 @Query('vehicleBusinessType') vehicleBusinessType: string,
                 @Header('Vehicle-Business-Type') vehicleBusinessTypeOfHeader: string): Observable<any> {
    return null;
  }

  @POST('/api/part-vehicle-accessories')
  saveAccessory(@Body data): Observable<any> {
    return null;
  }

  @PUT('/api/part-vehicle-accessories/:id')
  updateAccessory(@Path('id') id, @Body data): Observable<any> {
    return null;
  }

  @PATCH('/api/part-vehicle-accessories/batch')
  batchSaveAccessories(@Body data): Observable<any> {
    return null;
  }

  @DELETE('/api/part-vehicle-accessories')
  batchDeleteAccessories(@Body data): Observable<any> {
    return null;
  }

  @GET('/api/part-vehicle-accessories/exportation')
  @Headers({ isExportFile: true })
  exportAccessoriesData(@Query('vehicleBusinessType') vehicleBusinessType: string): Observable<any> {
    return null;
  }

  @GET('/api/part-vehicle-accessories/template')
  @Headers({ isExportFile: true })
  getTemplate(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/part-vehicle-accessories/update-time')
  getUpdateTime(@Query('dealer_id') dealerId: string,
                @Query('vehicleBusinessType') vehicleBusinessType: string,
                @Header('Vehicle-Business-Type') vehicleBusinessTypeOfHeader: string): Observable<any> {
    return null;
  }

  @GET('/api/wall-box/products')
  getWallBoxProducts(): Observable<any> {
    return null;
  }

  @GET('/api/wall-box/products/:partNumber')
  getWallBoxProduct(@Path('partNumber') partNumber): Observable<any> {
    return null;
  }
}

<div class="button-container">
  <span class="button" (click)="onEditing()">
    <img alt="" [src]="'/assets/common/edit-blue.svg'"
         placement="bottom"
    >
  </span>
  <span class="button delete-button" (click)="onDeleting()">
    <img alt="" [src]="'/assets/common/delete-blue.svg'"
         placement="bottom"
    >
  </span>
</div>

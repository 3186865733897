import * as _ from 'lodash';

export const MessageStatusMap = {
  READ: '已发送已读',
  SENT: '已发送未读',
  NOT_SEND: '未发送',
};

export const PRODUCT_TYPE = {
  NORMAL_FINANCE: 'NORMAL_FINANCE',
  LEASING: 'LEASING',
  NORMAL_FINANCE_AND_LEASING: 'NORMAL_FINANCE_AND_LEASING',
};

export const ProductTypeDescription = {
  [PRODUCT_TYPE.NORMAL_FINANCE]: '汽车金融',
  [PRODUCT_TYPE.LEASING]: '汽车租赁',
  [PRODUCT_TYPE.NORMAL_FINANCE_AND_LEASING]: '汽车金融/汽车租赁',
};

export const ContractEndDateTagMap = {
  WAIT_COMMUNICATION: '预沟通',
  APPOINTMENT: '邀约进店',
  DUE: '到期前提醒',
  WAIT_COMMUNICATION_AND_APPOINTMENT: '预沟通/邀约进店',
  WAIT_COMMUNICATION_AND_DUE: '预沟通/到期前提醒',
  APPOINTMENT_AND_DUE: '邀约进店/到期前提醒',
  WAIT_COMMUNICATION_AND_APPOINTMENT_AND_DUE: '预沟通/邀约进店/到期前提醒',
};

export const ContractEndDateTagFilterOptionsMap = {
  WAIT_COMMUNICATION: '预沟通',
  APPOINTMENT: '邀约进店',
  DUE: '到期前提醒',
};

export const getRetentionFilterOptions = (Map: any) =>
  _.chain(Map)
    .toPairs()
    .map(([value, text]) => ({ text, value }))
    .value();

export const parseRetentionCellValue = (Map: any, key: string) => Map[key] || '';

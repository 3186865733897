import * as _ from 'lodash';
import { getVehicleBusinessType } from '@otr/website-common';

export interface TabServiceTabType {
  title: string;
  href: string;
  params?: {[key: string]: string};
  isCloseable?: boolean;
  beforeClose?: Function;
  data?: any;
  originTab?: TabServiceTabType;
}

export const findTab = (tabs: TabServiceTabType[], url: string): TabServiceTabType|undefined => {
  const path = getPath(url);
  const params = getParams(url);

  return _.find(tabs, tab => (tab.href === path && _.isEqual(tab.params, params)));
};

export const findMatchingTabs = (tabs: TabServiceTabType[], url: string): TabServiceTabType[] => {
  const path = getPath(url);

  return _.filter(tabs, tab => _.startsWith(tab.href, path));
};

export const findTabIndex = (tabs: TabServiceTabType[], url: string): number => {
  const path = getPath(url);
  const params = getParams(url);

  return _.findIndex(tabs, (tab: TabServiceTabType) => (tab.href === path && _.isEqual(tab.params, params)));
};

export const getPath = (url: string): string => {
  const [href] = url.split('?');

  return !!href.match(/^\//) ? href : `/${href}`;
};

export const getParams = (url: string): {[key: string]: string} => {
  const [, queryString] = url.split('?');

  if (queryString) {
    return queryString.split('&')
      .filter(query => /=/.test(query))
      .reduce((map, query) => {
        const [key, value] = query.split('=');
        return { ...map, [key]: value };
      }, {});
  }
};

export function isTabGroupELSE(): boolean {
  return getVehicleBusinessType() === null;
}

export function isTabGroupPC(): boolean {
  return getVehicleBusinessType() === 'PC';
}

export function isTabGroupVAN(): boolean {
  return getVehicleBusinessType() === 'VAN';
}

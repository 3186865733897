import { AfterViewChecked, Component, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Events } from 'ag-grid';
import { GridDecorator } from '../../decorators/grid-decorator';
import { DestroyGridDecorator, GridRenderDecorator, InitGridDecorator } from '../meta/grid-render-decorator';
import { GridRenderValidator, GridValueValidator, Validate } from '../meta/grid-render-validator';
import { DisabledListener, GridRenderDisabled } from '../meta/grid-render-disabled';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  templateUrl: 'input-cell.template.html',
  styleUrls: ['input-cell.style.scss'],
})
export class InputCellRenderComponent implements ICellRendererAngularComp,
  GridRenderDecorator, AfterViewChecked, GridRenderValidator, GridRenderDisabled {
  originValue: any;
  value: any;
  errorMessage: string;
  decorated: boolean;
  isPercentage: boolean = false;
  decorators: Array<GridDecorator>;
  hideInput = false;

  @ViewChild('input') inputEle;

  private params;
  width: number;
  input;
  type: string = 'text';

  disabled: boolean = false;

  getIsDisabledMethod() {
    return data => this.input && this.input.isDisabled && this.input.isDisabled(data);
  }

  getData() {
    return this.params.data;
  }

  get api() {
    return this.params.api;
  }

  getDecoratorTarget(): HTMLElement {
    return this.inputEle ? this.inputEle.nativeElement : null;
  }

  refresh(params: any): boolean {
    return false;
  }

  getDecoratorConstructors(): Array<ObjectConstructor> {
    return this.input.decorators || [];
  }

  getValidators(): Array<GridValueValidator> {
    if (this.input.getValidators) {
      return this.input.getValidators(this.params.data);
    }
    return this.input.validators || [];
  }

  hasError(errorMessage: boolean): void {
    if (this.input.hasError) {
      this.input.hasError(errorMessage, this);
    }
  }

  focusInput(): void {
    this.inputEle.nativeElement.focus();
  }

  @DisabledListener
  agInit(params): void {
    this.params = params;
    this.value = _.isNil(params.value) ? '' : params.value;
    this.originValue = this.value;
    this.input = this.params.colDef.input;
    this.width = this.input.width || (params.colDef.width - 10);
    this.isPercentage = this.input.isPercentage;
    this.hideInput = !!_.invoke(this.params, 'colDef.cellRendererParams.hideInput', this.params.node.data);
  }

  @InitGridDecorator
  ngAfterViewChecked(): void {
  }

  @DestroyGridDecorator
  onDestroy() {
  }

  onFocus() {
    this.clearError();
    this.focusCallback();
  }

  @Validate()
  onChange(value) {
    const res = this.changeCallback(value, this.params.data, this.params);

    if (res instanceof Observable) {
      res.subscribe(() => {
        this.dispatchChangeEvent(value, this.originValue);
        this.originValue = value;
      }, error => {
        this.value = this.originValue;
        this.errorMessage = this.getErrorMessage(error);
      });
    } else {
      this.originValue = value;
    }
    _.set(this.params.node.data, this.params.column.colId, value);
  }

  @Validate()
  onInputChange(value): void {
  }

  getErrorMessage(error) {
    return error.status === 0 ? error.message : '保存失败';
  }

  onInput(value?) {
    this.clearError();
    this.inputCallback(value);
  }

  dispatchChangeEvent(newValue, oldValue) {
    const context = _.pick(this.params, ['api', 'node', 'context', 'data', 'colDef']);
    _.set(context.data, context.colDef.field, newValue);
    this.params.api.dispatchEvent(Events.EVENT_CELL_VALUE_CHANGED, Object.assign({ newValue, oldValue }, context));
  }

  get changeCallback() {
    return this.input.onChange || _.noop;
  }

  get inputCallback() {
    return this.input.onInput || _.noop;
  }

  get focusCallback() {
    return this.input.onFocus || _.noop;
  }

  getPlaceholder() {
    return (this.input && this.input.placeholder) || '';
  }

  private clearError() {
    if (this.errorMessage) {
      this.errorMessage = null;
    }
  }
}

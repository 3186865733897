export const HIERARCHY_RULES = {
  ROOT: {
    name: '利星⾏(北京)汽车有限公司',
    backgroundColor: '#7EC6E1',
    getChildren: () => [
      HIERARCHY_RULES.BRANCH,
      HIERARCHY_RULES.SALES_MANAGER,
      HIERARCHY_RULES.SHOW_ROOM,
      HIERARCHY_RULES.SALES_TEAM,
    ],
  },
  BRANCH: {
    name: '分公司',
    color: '#fff',
    backgroundColor: '#333333',
    getChildren: () => [
      HIERARCHY_RULES.SALES_MANAGER,
      HIERARCHY_RULES.SHOW_ROOM,
      HIERARCHY_RULES.SALES_TEAM,
    ],
  },
  SALES_MANAGER: {
    name: '销售经理',
    color: '#fff',
    backgroundColor: '#616161',
    getChildren: () => [
      HIERARCHY_RULES.SHOW_ROOM,
      HIERARCHY_RULES.SALES_TEAM,
    ],
  },
  SHOW_ROOM: {
    name: '展厅',
    color: '#fff',
    backgroundColor: '#989898',
    getChildren: () => [
      HIERARCHY_RULES.SALES_TEAM,
    ],
  },
  SALES_TEAM: {
    name: '销售小组',
    color: '#fff',
    backgroundColor: '#C6C6C6',
    getChildren: () => [],
  },
};

export const GROUP_TYPE = [
  {
    type: 'sales_team',
    typeForHierarchy: 'SALES_TEAM',
    text: '销售小组',
  },
  {
    type: 'showroom',
    typeForHierarchy: 'SHOW_ROOM',
    text: '展厅',
  },
  {
    type: 'sales_manage',
    typeForHierarchy: 'SALES_MANAGER',
    text: '销售经理',
  },
  {
    type: 'branch',
    typeForHierarchy: 'BRANCH',
    text: '分公司',
  },
  {
    type: 'dealer',
    typeForHierarchy: 'ROOT',
    text: false,
  },
];

export const MIND_MAP_FORMAT = 'nodeTree';

const BASED_MIND_MAP_OPTIONS = {
  container: 'jsmind_container',
  theme: 'normal',
  canRootNodeEditable: false,
  editable: true,
  supportHtml: false,
};

export const MIND_MAP_OPTIONS = Object.assign(
  {},
  BASED_MIND_MAP_OPTIONS,
  {
    depth: 5,
    hierarchyRule: HIERARCHY_RULES,
  },
);

export const DEALER_GROUP_HIERARCHY_RULE = {
  ROOT: {
    name: '总公司',
    backgroundColor: '#7EC6E1',
    getChildren: () => [
      DEALER_GROUP_HIERARCHY_RULE.REGION,
      DEALER_GROUP_HIERARCHY_RULE.SUB_REGION,
      DEALER_GROUP_HIERARCHY_RULE.PMA,
      DEALER_GROUP_HIERARCHY_RULE.DEALER,
    ],
  },
  REGION: {
    name: '地区',
    color: '#fff',
    backgroundColor: '#616161',
    getChildren: () => [
      DEALER_GROUP_HIERARCHY_RULE.SUB_REGION,
      DEALER_GROUP_HIERARCHY_RULE.PMA,
      DEALER_GROUP_HIERARCHY_RULE.DEALER,
    ],
  },
  SUB_REGION: {
    name: '小区',
    color: '#fff',
    backgroundColor: '#818181',
    getChildren: () => [
      DEALER_GROUP_HIERARCHY_RULE.PMA,
      DEALER_GROUP_HIERARCHY_RULE.DEALER,
    ],
  },
  PMA: {
    name: 'PMA',
    color: '#fff',
    backgroundColor: '#989898',
    getChildren: () => [
      DEALER_GROUP_HIERARCHY_RULE.DEALER,
    ],
  },
  DEALER: {
    name: '经销商',
    color: '#fff',
    backgroundColor: '#C6C6C6',
    getChildren: () => [],
  },
};

import { dateParse } from '../../_common/utils/date-parse';
import * as _ from 'lodash';
import {
  AS_LEAD_TYPE_ENUM,
  FOLLOWUP_CHANNEL,
  FOLLOWUP_TIMES,
  LEAD_CAN_FOLLOW_UP_STATUSES,
  LEAD_STATUS_ENUM,
  STRATEGY_STATUS,
  RECENT_ARRIVED_STATUS_ENUM,
} from './constant';
import { getMapKeys } from '../../_common/utils/common';
import { BOOLEAN_OPTIONS, REDIRECTION_URLS } from '../../_common/constants/common';
import { OptionPipe } from '../../_common/pipes/option.pipe';
import { OfferDto } from './model';
import { IconType } from '../../_common/constants/icons';
import * as moment from 'moment';
import { LEAD_BUDGET_RANGE } from 'app/customer-lead-management/lead-management/shared/constant/budget';

const OPTION_PIPE = new OptionPipe();

const dateRangeFilterFormatter = ({ from, to }) => ({
  dateFrom: dateParse(from, 'yyyy-MM-dd') || '',
  dateTo: dateParse(to, 'yyyy-MM-dd') || '',
});

const convertVehiclePlateNumber = lead => {
  const { vehiclePlateNumber } = lead;
  return vehiclePlateNumber ? `${vehiclePlateNumber.substring(0, 2)} ${vehiclePlateNumber.substring(2, vehiclePlateNumber.length)}` : null;
};

export const canBeFollowed = lead => {
  return _.includes(LEAD_CAN_FOLLOW_UP_STATUSES, _.get(lead, 'leadStatus'));
};

const convertLeadTypeOption = leadTypes => {
  if (_.some(leadTypes, leadType => _.includes([AS_LEAD_TYPE_ENUM.EMERGENCY.value, AS_LEAD_TYPE_ENUM.TG.value], leadType))) {
    return _.uniq([...leadTypes, AS_LEAD_TYPE_ENUM.EMERGENCY.value, AS_LEAD_TYPE_ENUM.TG.value]);
  }
  return leadTypes;
};

const formatLeadTypeFilter = (leadTypeFilters: Map<string, boolean>) => {
  const leadTypes = getMapKeys(leadTypeFilters);
  return convertLeadTypeOption(leadTypes);
};

const getLeadTypeOptions = () => _.uniqBy(AS_LEAD_TYPE_ENUM.values, 'text');

const getRecentStatus = () => _.uniqBy(RECENT_ARRIVED_STATUS_ENUM.values, 'text');

const recentStatusList = ['RESERVED_FAILED', 'CANCELLED_CLOSED', 'CANCELLED_PENDING'];
const getIconTypeByRecentStatus = ({ data: { reservedStatus } }) => {
  return _.includes(recentStatusList, reservedStatus)
    ? IconType.INFO_CIRCLE
    : null;
};

const getReservedStatus = status => {
  const statusType =  _.find(RECENT_ARRIVED_STATUS_ENUM.values, item => {
    return item.value === status;
  });
  return statusType ? statusType.text : null;
};

const getLastReservationDate = value => {
  return value ? moment(value).format('YYYY年MM月DD日 HH:mm') : '';
};

const mapBooleanToText = value => OPTION_PIPE.transform(value, BOOLEAN_OPTIONS);

export const getBudgetText = (budgetFrom, budgetTo) => {
  if (_.isNumber(budgetFrom) && _.isNumber(budgetTo)) {
    const numberRange = budgetFrom === budgetTo ? `已选择：${budgetFrom}` : `已选择：${budgetFrom}-${budgetTo}`;
    const budgetSuffix = `万${budgetTo === LEAD_BUDGET_RANGE.max ? '及以上' : ''}`;
    return `${numberRange}${budgetSuffix}`;
  }
  return '';
};

const mapLeadTypesToString = leadTypes => _.chain(leadTypes)
  .map(type => OPTION_PIPE.transform(type, AS_LEAD_TYPE_ENUM))
  .uniq()
  .join('，')
  .value();

// const mapFollowUpTimesToString = (times: number) => FOLLOWUP_TIMES[times - 1] || FOLLOWUP_TIMES[0];
const mapFollowUpTimesToString = (times: number) => {
  if (!times) {
    return '线索下发';
  }
  return FOLLOWUP_TIMES[times - 1] || FOLLOWUP_TIMES[0];
};

const trimSpaceInString = (input: string): string =>  _.replace(input, /\s+/g, '');

const isMobile = (input: string): boolean => new RegExp('^[0-9]{11}$').test(input);

const flattenDateFilter = filters => {
  const filter = {};
  _.forIn(filters, (value, key) => {
    if (_.isObject(value) && _.includes(_.keys(value), 'from') && _.includes(_.keys(value), 'to')) {
      filter[`${key}From`] = dateParse(_.get(value, 'from'));
      filter[`${key}To`] = dateParse(_.get(value, 'to'));
      return;
    }
    filter[key] = value;
  });
  return filter;
};

const formatLeadType = filters => ({ ...filters, leadType: filters.leadType || ['ALL'] });

const formatToFilterModel = (condition, types) => _.isEmpty(condition) ? _.map(types, 'value') : [condition];

const formatTableFilterToQuickFilter = tableFilterValue => _.size(tableFilterValue) > 1 ?
  'NONE' : _.get(tableFilterValue, '[0]', '');

const getStrategyStatus = value => {
  return _.chain(STRATEGY_STATUS)
    .find({ value })
    .get('text', '')
    .value();
};

const canShowCriteriaOffersColumn = (offer: OfferDto) => _.some(offer.leads, lead => !_.isEmpty(lead.criteriaOffers));

const getDealerCECOptions = getDealerEnum => {
  return _.uniqBy(getDealerEnum.values, 'text');
};

export const asLeadUtils = {
  dateRangeFilterFormatter,
  convertVehiclePlateNumber,
  canBeFollowed,
  formatLeadTypeFilter,
  getLeadTypeOptions,
  convertLeadTypeOption,
  mapBooleanToText,
  mapLeadTypesToString,
  trimSpaceInString,
  isMobile,
  mapFollowUpTimesToString,
  flattenDateFilter,
  formatToFilterModel,
  formatTableFilterToQuickFilter,
  getStrategyStatus,
  canShowCriteriaOffersColumn,
  formatLeadType,
  getRecentStatus,
  getIconTypeByRecentStatus,
  getReservedStatus,
  getLastReservationDate,
  getDealerCECOptions,
};

export const getRecordDesc = followUp => {
  const nextFollowupReason = followUp.isReservationCancelled ? `<br/> 下次跟进原因: ${followUp.nextFollowupReason ? followUp.nextFollowupReason : '无'}` : '' ;
  switch (followUp.leadStatus) {
    case LEAD_STATUS_ENUM.FOLLOWED_UP.value:
    case LEAD_STATUS_ENUM.PENDING.value:
      return followUp.nextFollowUpDate ? `下次跟进时间: ${followUp.nextFollowUpDate}${nextFollowupReason}` : null;
    case LEAD_STATUS_ENUM.ORDERED.value:
      return `预约到店时间: ${followUp.reservationDate} ${followUp.enteringStatus || ''}`;
    case LEAD_STATUS_ENUM.CLOSED.value:
      return `线索关闭: ${followUp.closeReason}`;
    default:
      return null;
  }
};

export const getDoctorSystemLink = followUp => {
  return _.includes([FOLLOWUP_CHANNEL.EMAIL.value, FOLLOWUP_CHANNEL.SMS.value], followUp.channel) && !followUp.success ?
    { ...REDIRECTION_URLS.DOCTOR_SYSTEM, tip: '发送失败: ' } : null;
};

export const getChannel = ({ channel }) => _.isNil(channel) ? null : `发送渠道: ${FOLLOWUP_CHANNEL.getTextByValue(channel)}`;

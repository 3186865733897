import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, ElementRef, ViewChild } from '@angular/core';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  template: `
    <div #container class='text-ellipsis-tooltip-container'>
      <div class='text' [title]="canShowTooltip ? wrapText : ''">{{ ellipsisText }}</div>
    </div>
  `,
  styleUrls: ['./text-ellipsis-tooltip.style.scss'],
})
export class TextEllipsisTooltipComponent implements ICellRendererAngularComp {
  content: string;
  ellipsisText: string;
  canShowTooltip: boolean = false;
  wrapText: string;
  hasNewLine: boolean = false;
  length: number = 50;

  private params: ICellRendererParams;
  cellRendererParams: any;

  @ViewChild('container') container: ElementRef;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellRendererParams = params.colDef.cellRendererParams;
    this.content = getValue(this.cellRendererParams.content, params);
    this.hasNewLine = getValue(this.cellRendererParams.hasNewLine, params) ?
      getValue(this.cellRendererParams.hasNewLine, params) : false;
    this.length = this.cellRendererParams.length ? this.cellRendererParams.length : 50;
    if (!this.hasNewLine) {
      this.canShowTooltip = this.content.length > this.length ? true : false;
      this.ellipsisText = this.canShowTooltip ? `${this.content.slice(0, this.length)}...` : this.content;
      if (this.content) {
        this.wrapText = this.content.match(/[\S]{1,10}/g).join('\n');
      } else {
        this.wrapText = '';
      }
    } else {
      this.canShowTooltip = this.content.length > this.length ? true : false;
      this.ellipsisText = this.canShowTooltip ? `${this.content.slice(0, this.length)}...` : this.content;
      if (this.content) {
        this.wrapText = this.content.replace(/[;]{1,10}/g, '\n');
      } else {
        this.wrapText = '';
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SalesLeadModalContent } from '../../../customer-lead-management/lead-management/sales-lead/sales-lead-modal/sales-lead-modal.component';

import { BehaviorSubject, Subject } from 'rxjs';
import { FormUtilService } from '../../../_common/services/form-util.service';
import { isOpenLead } from '../../../_common/utils/lead';
import {
  LOST_REASON,
  LOST_REASON_FOR_USED_CAR,
} from '../../../customer-lead-management/lead-management/shared/constant/lead';
import { scGroupAndScUserIdValidator } from '../assign-sc-modal/helper';

export const CLOSED_CATEGORIES = [
  { value: 'NO_INTENTION', text: '无购车意向'},
  { value: 'UNABLE_REACH', text: '无法有效触达'},
  { value: 'RESISTANCE_TO_PURCHASE', text: '购买存在阻力'},
];

export const CLOSED_REASONS = {
  NO_INTENTION: [
    '已在他店购买奔驰燃油车',
    '已在他店购买奔驰电动车',
    '已购买其他豪华品牌电动车',
    '已购买新势力电动车',
    '已购买传统车企电动车',
    '已购买其他品牌燃油车',
  ],
  UNABLE_REACH: [
    '客户误操作',
    '无效的客户数据',
    '开场白挂断',
    '多次未接通',
    '明确表示不需要被联系',
  ],
  RESISTANCE_TO_PURCHASE: [
    '价格不被接受',
    '产品和品牌相关顾虑',
    '未能成功取得融资',
    '置换报价未达预期',
    '等销售政策和新款车型',
    '充电桩无法安装',
  ]
};

@Component({
  selector: 'app-cti-close-lead-modal',
  styleUrls: ['./close-lead-modal.style.scss'],
  templateUrl: './close-lead-modal.template.html',
})
export class CTICloseLeadModalComponent implements SalesLeadModalContent, OnInit {
  @Input() leads: Array<any> = [];
  closeReasons: Array<any> = LOST_REASON;
  closeCategories = CLOSED_CATEGORIES;
  closeReasons$: BehaviorSubject<any> = new BehaviorSubject([]);
  closeReason: FormControl = new FormControl(null, (control) => {
    return this.leads ? control.value ? null : {} : null;
  });
  selectedCategory: FormControl = new FormControl(null, (control) => {
    return null;
  });
  selectedReason: FormControl = new FormControl(null, (control) => {
    return  null;
  });
  comments: FormControl = new FormControl(null);

  closeLeadForm: FormGroup = new FormGroup({
    closeReason: this.closeReason,
    selectedCategory: this.selectedCategory,
    selectedReason: this.selectedReason,
    comments: this.comments,
  });

  isInvalid$: Subject<any> = new BehaviorSubject(true);

  preSelectedCategory = '';

  constructor(private formUtilService: FormUtilService) {}

  get leadLabel() {
    const lead = this.leads;
    const interestedVehicle = _.get(lead, 'interested_vehicles[0]');
    const brand = _.get(interestedVehicle, 'brand.description_zh');
    const iClass = _.get(interestedVehicle, 'class.description_zh');
    const variant = _.get(interestedVehicle, 'variant.description_zh');
    const desc = `${brand ? brand : ''}${iClass ? ` ${iClass}` : ''}${variant ? ` ${variant}` : ''}`;
    return desc || '暂无意向车';
  }

  ngOnInit(): void {
    this.closeLeadForm.valueChanges.subscribe(() => this.isInvalid$.next(this.closeLeadForm.invalid || this.selectedReason.invalid));
    this.selectedCategory.valueChanges.subscribe(category => {
      if (this.preSelectedCategory === category) {
        return;
      }
      this.selectedReason.setValue(null);
      this.closeReasons$.next(CLOSED_REASONS[category]);
      this.preSelectedCategory = category;
    });
    this.formUtilService.markAsTouched$.subscribe((value => value ? this.markControlsAsTouched() : this.markControlsAsUnTouched()));
  }

  getFormValue() {
    const closeModLeadCategory = _.chain(CLOSED_CATEGORIES).find(['value', this.selectedCategory.value]).get('text').value();
    return {
      closeLeadReason: this.closeReason.value,
      closeModLeadCategory,
      closeModLeadReason: this.selectedReason.value,
      comments: this.comments.value,
    };
  }

  markControlsAsTouched() {
    this.selectedReason.markAsTouched();
    this.selectedCategory.markAsTouched();
  }

  markControlsAsUnTouched() {
    this.selectedReason.markAsUntouched();
    this.selectedCategory.markAsUntouched();
  }

  canCloseLead(lead) {
    return !_.get(lead, 'owner_sales_consultant_id') && isOpenLead(lead);
  }
}

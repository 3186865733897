<router-outlet></router-outlet>
<app-dealer-for-report-drag-bar *ngIf="showDealerDragBar"></app-dealer-for-report-drag-bar>
<app-cti-main *ngIf="showCtiMain"></app-cti-main>
<app-spinner></app-spinner>
<app-prompt-bar [config]="config" class="prompt-bar"></app-prompt-bar>
<app-part-prompt-bar [configs]="configs"></app-part-prompt-bar>
<app-confirm-modal #confirmModal
                   (onConfirm)="onConfirm()"
                   (onCancel)="onCancel()"
                   (onClose)="onClose()"
                   [message]="confirmOption.message"
                   [errorMessage]="confirmOption.errorMessage"
                   [subMessage]="confirmOption.subMessage"
                   [shouldHideSubMessage]="confirmOption.shouldHideSubMessage"
                   [shouldHideCancelButton]="confirmOption.shouldHideCancelButton"
                   [shouldShowCloseButton]="confirmOption.shouldShowCloseButton"
                   [confirmButtonText]="confirmOption.confirmButtonText || defaultConfirmButtonText"
                   [cancelButtonText]="confirmOption.cancelButtonText || defaultCancelButtonText"
                   [class]="confirmOption.cssClass"
                   [style]="confirmOption.cssStyle"
                   [theme]="confirmOption.theme"
                   [leftAlignBody]="confirmOption.leftAlignBody"
                   [modalTitle] ="confirmOption.modalTitle"
                   [htmlTemplate]="confirmOption.htmlTemplate">
</app-confirm-modal>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FILTER_COLLAPSE_TEXT } from '../../constants/common';

@Component({
  selector: 'app-collapse',
  templateUrl: 'collapse.template.html',
  styleUrls: ['collapse.style.scss'],
})
export class CollapseComponent {
  operationText: string;

  @Input() isCollapsed: boolean = false;
  @Input() expandCollapseText = FILTER_COLLAPSE_TEXT;
  @Input() canCollapsed: boolean = true;
  @Input() theme: 'white' | 'gray' = 'white';
  @Output() onChange = new EventEmitter();

  collapsed(): void {
    this.onChange.emit();
    this.operationText = this.expandCollapseText.COLLAPSED;
  }

  expanded(): void {
    this.onChange.emit();
    this.operationText = this.expandCollapseText.EXPANDED;
  }

  setIsCollapsed(value): void {
    this.isCollapsed = value;
  }

  getArrowDownIcon() {
    const icon = this.theme === 'gray' ? 'arrow-circle-down-gray.svg' : 'arrow-circle-down.svg';
    return `/assets/common/${icon}`;
  }

  getArrowUpIcon() {
    const icon = this.theme === 'gray' ? 'arrow-circle-up-gray.svg' : 'arrow-circle-up.svg';
    return `/assets/common/${icon}`;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface SwitchTab {
  key: any;
  text: string;
}

@Component({
  selector: 'app-switch-tabs',
  templateUrl: './switch-tabs.template.html',
  styleUrls: ['./switch-tabs.style.scss'],
})
export class SwitchTabsComponent implements OnInit {
  @Input() tabs: SwitchTab[];
  @Output() change = new EventEmitter();

  currentKey: string;

  ngOnInit() {
    this.currentKey = this.tabs[0].key;
  }

  onChange(key) {
    if (this.currentKey !== key) {
      this.currentKey = key;
      this.change.emit(key);
    }
  }
}

import * as _ from 'lodash';

export const sortChineseString = (array: Array<any>, valueGetter: (Function | string) = _.identity, order: string = 'asc') => {
  array.sort((a, b) => {
    const valueGetterWrapper = typeof valueGetter === 'string' ?
      value => _.get(value, valueGetter, '') : valueGetter;
    return (order === 'desc' || order === 'DESC') ?
      valueGetterWrapper(b).localeCompare(valueGetterWrapper(a), 'zh-Hans-CN') :
      valueGetterWrapper(a).localeCompare(valueGetterWrapper(b), 'zh-Hans-CN');
  });
  return array;
};

export const isArrayOfSameLength = (array1, array2) => (array1 || []).length === (array2 || []).length;

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.template.html',
  styleUrls: ['./download.style.scss'],

})
export class DownloadComponent {
  @Input() title;
  @Output() download = new EventEmitter();
}

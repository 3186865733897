<app-inbound-call-drag-bar *ngIf="shouldShowInboundCallDragBar" [data]="inboundCallData" (onRefuse)="hangUpInboundCall($event)" (foldAssistant)="getAssistantStatus($event)" [showAssistantIcon]="showAssistantIcon"></app-inbound-call-drag-bar>
<app-outbound-call-drag-bar *ngIf="shouldShowOutboundCallDragBar" (onRefuse)="hangUpOutboundCall($event)" (foldAssistant)="getAssistantStatus($event)" [showAssistantIcon]="showAssistantIcon"></app-outbound-call-drag-bar>
<app-modal *ngIf="showCallEndModal"
  [modalTitle]="'通话结束'"
  (click)="onMousedown()"
  (onCanceled)="handleCallEndModalCanceled()"
  class="call-end-modal">
  <app-call-end-modal [messageInfo]="callEndData" (change)="handleCallEndModalChange($event)" (close)="handleCallEndModalCanceled()"></app-call-end-modal>
</app-modal>

<app-modal *ngIf="shouldShowAddCustomerConfirmModal" modalTitle="提示"
           (onCanceled)="closeAddCustomerConfirmModal()" id="add-customer-confirm-modal">
  <div class="add-customer-confirm">
    <p>无新建客户/销售线索权限</p>
    <div class="button-container">
      <button class="btn btn-primary btn-lg" type="button" (click)="closeAddCustomerConfirmModal()">确定</button>
    </div>
  </div>
</app-modal>

<app-modal *ngIf="shouldShowAddAfterSalesConfirmModal" modalTitle="提示"
           (onCanceled)="closeAddAfterSalesConfirmModal()" id="add-after-sales-confirm-modal">
  <div class="add-customer-confirm">
    <p>无新建售后线索权限</p>
    <div class="button-container">
      <button class="btn btn-primary btn-lg" type="button" (click)="closeAddAfterSalesConfirmModal()">确定</button>
    </div>
  </div>
</app-modal>

<app-modal *ngIf="shouldShowJumpAfterSalesConfirmModal" modalTitle="提示"
           (onCanceled)="closeJumpAfterSalesConfirmModal()" id="jump-after-sales-confirm-modal">
  <div class="add-customer-confirm">
    <p>是否离开当前页面，跳转至售后线索列表页？</p>
    <div class="button-container">
      <button class="btn btn-outline-primary btn-lg" type="button" (click)="closeJumpAfterSalesConfirmModal()">否</button>
      <button class="btn btn-primary btn-lg" type="button" (click)="confirmJumpAddAfterSalesConfirmModal()">是</button>
    </div>
  </div>
</app-modal>

<div #salesLeadModal></div>

<app-modal modalTitle="同步跟进记录" *ngIf="shouldShowSyncFollowupRecordsMoal" (onCanceled)="onCloseRecordsModal()">
  <app-sync-follow-up-records [persons]="followUpRecords"
                              (submitPersons)="submitPersons($event)"
                              (closeModal)="onCloseRecordsModal()">
  </app-sync-follow-up-records>
</app-modal>

<app-intelligent-assistant
  #intelligentAssistant
  *ngIf="showIntelligentAssistant && isShowCTI"
  [currentStatus]="currentStatus"
  [isCallEnd]="showCallEndModal"
  (foldAssistantModal)="foldAssistantModal()"
></app-intelligent-assistant>


import {  BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { cloneDeep, findIndex, keys, includes } from 'lodash';
import { MultipleData } from '../models/multiple';
import { INIT_MULTIPLE_DATA } from '../constants/multiple';

@Injectable()
export class CustomCheckboxHeaderService {

  checked$: Subject<boolean> = new BehaviorSubject(false);

  changeChecked(isChecked: boolean) {
    this.checked$.next(isChecked);
  }

  rowSelectedChange(multiple: MultipleData<any>) {

    const { totalNumber, selectedCount, allRowsChecked } = multiple;
    if (!totalNumber) {
      return multiple;
    }
    if (allRowsChecked && selectedCount === 0) {
      return this.setDeselectedAllMultipleData(multiple);
    }
    if (!allRowsChecked && totalNumber === selectedCount) {
      return this.setSelectedAllMultipleData(multiple);
    }
    return multiple;
  }

  resetMultipleData() {
    return cloneDeep(INIT_MULTIPLE_DATA);
  }

  resetMultiple() {
    this.changeChecked(false);
    return this.resetMultipleData();
  }

  setSelectedAllMultipleData(multiple: MultipleData<any>) {
    this.changeChecked(true);

    const { totalNumber, changeRows } = multiple;

    return {
      ...this.resetMultipleData(),
      allRowsChecked: true,
      selectedCount: totalNumber,
      totalNumber,
      changeRows,
    };
  }

  setDeselectedAllMultipleData(multiple: MultipleData<any>) {
    this.changeChecked(false);
    const { totalNumber, changeRows } = multiple;

    return {
      ...this.resetMultipleData(),
      allRowsChecked: false,
      totalNumber,
      changeRows,
    };
  }

  setSelectedRows<T> (event: any, multiple: MultipleData<T>, idKey: string): MultipleData<T> {
    const isSelected = event.node.isSelected();
    const currentRow = event.data;
    const { selectedRows, allRowsChecked } = multiple;

    if (allRowsChecked) {
      return this.setSelectedRowsWhenSelectedAll<T>(isSelected, currentRow, multiple, idKey);
    }
    if (isSelected) {
      const hasSelected = selectedRows.find(hasSelectedRow => hasSelectedRow[idKey] === currentRow[idKey],
      );
      if (!hasSelected) {
        multiple.selectedRows.push(currentRow);
      }
    } else {
      const toDeleteRowIndex = findIndex(
        selectedRows,
        (row: T) => row[idKey] === currentRow[idKey],
      );
      if (toDeleteRowIndex > -1) {
        selectedRows.splice(toDeleteRowIndex, 1);
      }
    }
    multiple.selectedCount = multiple.selectedRows.length;
    return this.rowSelectedChange(multiple);
  }

  setSelectedRowsWhenSelectedAll<T> (isSelected: boolean, currentRow: T, multiple: MultipleData<T>, idKey: string): MultipleData<T> {
    const { rejectRows } = multiple;

    if (isSelected) {
      const toDeleteFromRejectRowIndex = findIndex(
        rejectRows,
        (row: T) => row[idKey] === currentRow[idKey],
      );

      if (toDeleteFromRejectRowIndex > -1) {
        rejectRows.splice(toDeleteFromRejectRowIndex, 1);
      }
    } else {
      const hasReject = rejectRows.find(hasRejectRow => hasRejectRow[idKey] === currentRow[idKey],
      );
      if (!hasReject) {
        multiple.rejectRows.push(currentRow);
      }
    }
    multiple.selectedCount =
      multiple.totalNumber - multiple.rejectRows.length;
    return this.rowSelectedChange(multiple);
  }

  markAsSelected<T> (part: T, multiple: MultipleData<T>, idKey: string) {
    const { allRowsChecked, rejectRows, selectedRows } = multiple;
    if (allRowsChecked) {
      return !rejectRows.find(i => i[idKey] === part[idKey]);
    }
    return !!selectedRows.find(i => i[idKey] === part[idKey]);
  }

  formatSubmitData<T>(submitData: T[], multiple: MultipleData<T>, idKey: string) {
    const changeRowsKeys = keys(multiple.changeRows);
    return submitData.map(data => {
      const isChanged = includes(changeRowsKeys, data[idKey]);
      return isChanged ? multiple.changeRows[data[idKey]] : data;
    });
  }

}

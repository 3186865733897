import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MODAL_CONFIG } from '../../../constants/common';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-draggable-modal-framework',
  templateUrl: './draggable-modal-framework.component.html',
  styleUrls: ['./draggable-modal-framework.component.scss'],
})
export class DraggableModalFrameworkComponent implements OnInit {
  modalConfig = MODAL_CONFIG;
  @Input() marginTop = '10%';
  @Input() dialogWidth;
  @Input() draggable = false;
  @ViewChild('bsModal') bsModal;
  @Input() header: TemplateRef<void> | null;

  constructor(public platform: Platform) {
  }

  ngOnInit(): void {
  }

  show(): void {
    this.bsModal.show();
  }

  hide(): void {
    this.bsModal.hide();
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { getDescriptionFromVehicle } from '../../../../../_common/utils/intrested-vehicle';
import { getSalesConsultantInfo } from '../../../../../_common/utils/lead';

import * as _ from 'lodash';
import { Person } from '../../../../../_common/models/lead';

@Component({
  selector: 'app-sync-follow-up-records',
  styleUrls: ['./sync-follow-up-records.style.scss'],
  templateUrl: './sync-follow-up-records.template.html',
})
export class SyncFollowUpRecordsComponent {
  @Input() persons: Array<Person> = [];
  @Output() submitPersons = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  selectedPersons: Array<string> = [];

  getLeadInterestedVehicle(lead) {
    return getDescriptionFromVehicle(_.get(lead, 'interested_vehicles.[0]', {}));
  }

  getDisplaySc(lead) {
    return getSalesConsultantInfo(lead, false);
  }

  handleSelect(leads) {
    this.selectedPersons = _.xor(this.selectedPersons, _.map(leads, lead => lead._id));
  }

  handleSubmit() {
    this.submitPersons.emit(this.selectedPersons);
  }

  handleCloseButton() {
    this.closeModal.emit(true);
  }
}

import { Injectable } from '@angular/core';
import { GET, Headers, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ASServiceContractApi extends RebirthHttp {
  public maintenanceUploadUri = '/api/aftersales/website/service-contract/retail-prices/bmbs-blue';
  public extendedUploadUri = '/api/aftersales/website/service-contract/retail-prices/bmbs-silver';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/aftersales/website/service-contract/retail-prices/bmbs-blue')
  @Headers({ isExportFile: true })
  getMaintenanceServiceContractData(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/aftersales/website/service-contract/retail-prices/bmbs-blue/template')
  @Headers({ isExportFile: true })
  getMaintenanceServiceContractTemplate(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/aftersales/website/service-contract/retail-prices/bmbs-silver')
  @Headers({ isExportFile: true })
  getExtendedServiceContractData(): Observable<any> {
    return null;
  }

  @GET('/api/aftersales/website/service-contract/retail-prices/bmbs-silver/template')
  @Headers({ isExportFile: true })
  getExtendedServiceContractTemplate(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }
}

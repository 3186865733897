import * as _ from 'lodash';
import { ComponentFactoryResolver, Injector } from '@angular/core';
import { DealerNodeComponent } from '../components/mind-map-nodes/dealer-node/dealer-node.component';
import { PositionService } from '../services/position.service';
import { BMBS_DEALER_TYPE } from '../constants/option-management';

export class MindMapDataConverter {
  hierarchyRules;
  groupType;
  private componentFactoryResolver: ComponentFactoryResolver;
  private injector: Injector;

  constructor(hierarchyRules,
              groupType,
              componentFactoryResolver: ComponentFactoryResolver,
              injector: Injector) {
    this.hierarchyRules = hierarchyRules;
    this.groupType = groupType;
    this.componentFactoryResolver = componentFactoryResolver;
    this.injector = injector;
  }

  convertForMindMap(nodeTree, isDealerGroup) {
    const children = !_.isEmpty(nodeTree.childGroups) ? nodeTree.childGroups.map(nt => this.convertForMindMap(nt, isDealerGroup)) : [];

    const currentType: any = _.find(this.groupType, { type: nodeTree.type });

    const node = {
      id: nodeTree.id,
      color: this.hierarchyRules[currentType.typeForHierarchy].color,
      topic: nodeTree.name,
      direction: currentType.text === false ? undefined : 'right',
      selectedType: currentType.text,
      backgroundColor: this.hierarchyRules[currentType.typeForHierarchy].backgroundColor,
      children,
    };

    if (!isDealerGroup) {
      return node;
    }

    const dealerNodeComponentFactory = this.componentFactoryResolver.resolveComponentFactory(DealerNodeComponent);
    const component = dealerNodeComponentFactory.create(this.injector);

    component.instance.nodeId = nodeTree.id;
    component.instance.name = nodeTree.name;
    component.changeDetectorRef.detectChanges();
    const componentRootNode = PositionService.getComponentRootNode(component);

    return _.assign({}, node, { component: currentType.text !== BMBS_DEALER_TYPE.DEALER.text ? undefined : componentRootNode });
  }

  convertForBackendInterface(nodeTree) {
    const childGroups = !_.isEmpty(nodeTree.children) ? nodeTree.children.map(node => this.convertForBackendInterface(node)) : [];
    const currentRule: any = _.find(this.groupType, { text: nodeTree.isroot ? false : nodeTree.selectedType });

    return {
      id: nodeTree.isCreated ? undefined : nodeTree.id,
      name: nodeTree.topic,
      type: currentRule.type,
      childGroups,
    };
  }
}

import * as _ from 'lodash';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IAfterGuiAttachedParams } from 'ag-grid';

import { isEmptyValue } from '../../../../../utils/common';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';
import { FILTER_TYPE } from '../../../../../constants/common';

@Component({
  selector: 'app-pagination-range-score-input',
  templateUrl: 'pagination-range-score-input.component.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationRangeScoreInputComponent extends BasePaginationFilterComponent {
  public radio = new FormControl(FILTER_TYPE.NORMAL);
  startInput = new FormControl('');
  endInput = new FormControl('');
  savedStartValue = '';
  savedEndValue = '';

  afterGuiAttached(params: IAfterGuiAttachedParams) {
    super.afterGuiAttached(params);
    this.startInput.setValue(this.savedStartValue);
    this.endInput.setValue(this.savedEndValue);
    this.radio.setValue(this.radio.value);
  }

  onSubmit() {
    super.hideFilterMenu();
    if (this.radio.value === FILTER_TYPE.NORMAL) {
      this.savedStartValue = this.startInput.value.trim();
      this.savedEndValue = this.endInput.value.trim();
    } else {
      this.savedStartValue = this.savedEndValue = '';
    }
    this.params.filterChangedCallback();
  }

  isFilterActive() {
    return !isEmptyValue(this.savedStartValue) || !isEmptyValue(this.savedEndValue);
  }

  onCancel() {
    super.hideFilterMenu();
    this.startInput.setValue(this.savedStartValue);
    this.endInput.setValue(this.savedEndValue);
  }

  setDefaultValue() {
    super.setDefaultValue();
    this.startInput.setValue('');
    this.endInput.setValue('');
    this.savedStartValue = '';
    this.savedEndValue = '';
  }

  isFilterValid() {
    const startInput = this.startInput.value.trim();
    const endInput = this.endInput.value.trim();
    const testRegExp = _.get(this.params.colDef.filterParams, 'regExp', new RegExp(''));
    return (!startInput || testRegExp.test(startInput)) && (!endInput || testRegExp.test(endInput));
  }

  setModel(model: any) {
    this.savedStartValue = _.get(model, 'from', '');
    this.savedEndValue = _.get(model, 'to', '');
  }

  getModel() {
    // if (!this.isFilterActive()) {
    //   return null;
    // }
    const model = {} as { from?: string; to?: string; };
    if (this.savedStartValue) {
      model.from = this.savedStartValue;
    }
    if (this.savedEndValue) {
      model.to = this.savedEndValue;
    }
    return this.modelFormatter({ ...model, filterType: this.radio.value });
  }
}

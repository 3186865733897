import { Injectable } from '@angular/core';
import { OtrBMapInstance, OtrMapOptions } from '../models/Map';

import { OtrScriptLoader } from './scriptLoader';
import * as _ from 'lodash';
import { OtrBPoint, OtrPoint } from '../models/Point';

@Injectable()
export class OtrMapService {

  // private ak = '9QRmrL42Aq2cHLuKUvI9ieyeqmuumVFi';
  private ak = 'U7eCvEtTArzsWGOz8H|4UGsySLNNjkSv';

  constructor(private _loader: OtrScriptLoader) {
  }

  public createOtrMap(el: HTMLElement, mapOptions: OtrMapOptions, callback: (OtrBMapInstance) => void) {
    const URL = `https://api.map.baidu.com/api?v=2.0&ak=${this.ak}&callback=otrMapInit`;
    this._loader.loadMap(URL, () => {
      const map = new window.BMap.Map(el, mapOptions);
      this.initMapOptions(mapOptions, map);
      callback(map);
    });
  }

  // tslint:disable-next-line:cyclomatic-complexity
  public initMapOptions(opts: OtrMapOptions, map: OtrBMapInstance): void {
    const {
      disableDragging,
      enableScrollWheelZoom,
      disableDoubleClickZoom,
      enableKeyboard,
      enableInertialDragging,
      enableAutoResize,
      enableContinuousZoom,
      disablePinchToZoom,
    } = opts;

    if (_.isBoolean(disableDragging)) {
      map[`${disableDragging ? 'enable' : 'disable'}Dragging`]();
    }
    if (_.isBoolean(enableScrollWheelZoom)) {
      map[`${enableScrollWheelZoom ? 'enable' : 'disable'}ScrollWheelZoom`]();
    }
    if (_.isBoolean(enableAutoResize)) {
      map[`${enableAutoResize ? 'enable' : 'disable'}AutoResize`]();
    }
    if (_.isBoolean(disableDoubleClickZoom)) {
      map[`${disableDoubleClickZoom ? 'enable' : 'disable'}DoubleClickZoom`]();
    }
    if (_.isBoolean(enableKeyboard)) {
      map[`${enableKeyboard ? 'enable' : 'disable'}Keyboard`]();
    }
    if (_.isBoolean(enableInertialDragging)) {
      map[`${enableInertialDragging ? 'enable' : 'disable'}InertialDragging`]();
    }
    if (_.isBoolean(enableContinuousZoom)) {
      map[`${enableContinuousZoom ? 'enable' : 'disable'}ContinuousZoom`]();
    }
    if (_.isBoolean(disablePinchToZoom)) {
      map[`${disablePinchToZoom ? 'disable' : 'enable'}PinchToZoom`]();
    }
    if (!_.isNil(opts.cursor)) {
      map.setDefaultCursor(opts.cursor);
    }
    if (!_.isNil(opts.draggingCursor)) {
      map.setDraggingCursor(opts.draggingCursor);
    }
    if (!_.isNil(opts.currentCity)) {
      map.setCurrentCity(opts.currentCity);
    }
    if (!_.isNil(opts.centerAndZoom)) {
      map.centerAndZoom(initPoint(opts.centerAndZoom), opts.centerAndZoom.zoom);
    }
  }
}

function initPoint(opts: OtrPoint): OtrBPoint {
  if (!opts) {
    return new window.BMap.Point();
  }
  return new window.BMap.Point(opts.lng, opts.lat);
}

import { GridDecorator } from '../../decorators/grid-decorator';

export interface GridRenderDecorator {
  decorated: boolean;
  decorators: Array<GridDecorator>;

  getDecoratorTarget(): HTMLElement;

  getDecoratorConstructors(): Array<ObjectConstructor>;
}

const getDecorators = (decoratorConstructors: Array<ObjectConstructor>, element: HTMLElement) => {
  return decoratorConstructors.map(constructor => {
    const decorator = new constructor() as GridDecorator;
    decorator.init(element);
    return decorator;
  });
};

// tslint:disable-next-line
export function InitGridDecorator(target, property: string, descriptor: PropertyDescriptor) {
  const originFunction = descriptor.value;
  descriptor.value = function (...args) {
    originFunction.apply(this, args);
    const self = this as GridRenderDecorator;
    const decoratorTarget = self.getDecoratorTarget();
    if (decoratorTarget && !self.decorated) {
      self.decorators = getDecorators(this.getDecoratorConstructors(), decoratorTarget);
      self.decorated = true;
    }
  };
}

// tslint:disable-next-line
export function DestroyGridDecorator(target, property: string, descriptor: PropertyDescriptor) {
  const originFunction = descriptor.value;
  descriptor.value = function (...args) {
    originFunction.apply(this, args);
    const self = this as GridRenderDecorator;
    self.decorators.forEach(decorator => decorator.destroy(this.getDecoratorTarget()));
  };
}

import { Component } from '@angular/core';
import * as _ from 'lodash';
import { IAfterGuiAttachedParams, IFilterParams } from 'ag-grid';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';

@Component({
  selector: 'app-pagination-single-select-filter-cell',
  templateUrl: 'pagination-single-select-filter.template.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationSingleSelectFilterComponent extends BasePaginationFilterComponent {
  public options: Array<Object>;
  public filter: string = '';
  private savedFilter: string = '';
  public hasSelectAll: boolean = true;
  public hasResetBtn: boolean = false;

  agInit(params: IFilterParams): void {
    super.agInit(params);
    const filterParams = this.params.colDef.filterParams;
    this.options = filterParams.options;
    this.hasSelectAll = _.get(params, 'colDef.filterParams.hasSelectAll', this.hasSelectAll);
    this.hasResetBtn = _.get(params, 'colDef.filterParams.hasResetBtn', this.hasResetBtn);
    this.setFilter(false);
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    super.afterGuiAttached(params);

    this.filter = this.savedFilter.length === 0 ? '' : this.savedFilter;
  }

  onSubmit() {
    super.hideFilterMenu();
    this.savedFilter = this.filter;
    this.params.filterChangedCallback();
  }

  onCancel() {
    super.hideFilterMenu();
    this.filter = this.savedFilter.length === 0 ? '' : this.savedFilter;
  }

  onReset() {
    super.hideFilterMenu();
    this.onAllChange();
    this.params.filterChangedCallback();
  }

  onChange(event, option) {
    this.setChecked(this.filter, option.value, event.target.checked);
  }

  isSelect(option) {
    return this.filter.length === 0 ? false : this.filter === option.value;
  }

  getIsAllChecked() {
    return this.filter && this.options ? this.filter.length === this.options.length : false;
  }

  onAllChange() {
    this.filter = '';
    this.savedFilter = '';
  }

  private setFilter(isChecked: boolean) {
    _.forEach(
      this.options,
      (option: any) => this.setChecked(this.filter, option.value, isChecked),
    );
  }

  setChecked (filter , key, checked) {
    this.filter =  checked ? key : '';
    return filter;
  }

  isEmptyFilter() {
    return this.savedFilter.length === 0;
  }

  setModel(model: any): void {
    const filter = (_.isEmpty(model) || model.size === 0) ? '' : model.map(i => [i, true]);
    this.filter = filter;
    this.savedFilter = filter;
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    return this.savedFilter;
  }

  isFilterActive(): boolean {
    return !this.getIsAllChecked() && this.filter.length !== 0;
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appIsEllipsisActive]',
})

export class IsEllipsisActiveDirective {

  constructor(private elementRef: ElementRef) {
  }

  @HostListener('mouseenter')
  mouseEnter(): void {
    const element = this.elementRef.nativeElement;
    if (element.offsetWidth >= element.scrollWidth) {
      element.classList.remove('tooltip-trigger-text');
    }
  }
}

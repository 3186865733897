import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid';
import { IFilterAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-search-filter-cell',
  templateUrl: 'search-filter.template.html',
  styleUrls: ['../grid-filter.style.scss'],
})
export class SearchFilterComponent implements IFilterAngularComp {
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public text: string = '';
  @ViewChild('input', { read: ViewContainerRef }) public input;

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const value = this.valueGetter(params.node) || '';
    return _.lowerCase(this.text)
      .split(' ')
      .every(filterWord => {
        return value.toString().toLowerCase().indexOf(filterWord) >= 0;
      });
  }

  getModel(): any {
    return { value: this.text };
  }

  setModel(model: any): void {
    this.text = _.get(model, 'value', '');
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    this.input.element.nativeElement.focus();
  }

  onChange(newValue): void {
    if (this.text !== newValue) {
      this.text = newValue;
      this.params.filterChangedCallback();
    }
  }
}

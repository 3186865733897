import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid';
import { SelectorCellRenderComponent } from '../cell-renders/selector/selector-cell.component';
import { AgGridNg2 } from 'ag-grid-angular';
import { BehaviorSubject, combineLatest, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { GridEvents } from '../event/events';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { isEmptyValue } from '../../../utils/common';
import { CustomEventEmitter } from '../event/custom-event-emitter';
import { AutoUnsubscribe } from 'app/_common/utils/autoUnsubscribe';
import { VehicleService } from 'app/_common/services/vehicle.service';

export const getDefaultGridOptions = (): GridOptions => ({
  animateRows: true,
  suppressCellSelection: true,
  defaultColDef: {
    cellClass: 'center-box',
    headerClass: 'center-box',
  },
  getRowHeight: () => 50,
  headerHeight: 40,
  context: {
    selectableRowCount: null,
    eventEmitter: new CustomEventEmitter(),
  },
  suppressMenuHide: true,
  suppressMovableColumns: true,
  overlayLoadingTemplate: '<span class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></span>',
  overlayNoRowsTemplate: '<span style="font-size: 18px;color: #999">找不到结果</span>',
  enableFilter: false,
  suppressClickEdit: true,
  editType: 'fullRow',
  suppressColumnVirtualisation: true,
  rowBuffer: 9999,
});

export interface OTRAgGridApi {
  api: GridApi;
  columnApi: ColumnApi;
  eventEmitter: CustomEventEmitter;

  loading<T>(): MonoTypeOperatorFunction<T>;
}

const loading =
  (api: GridApi, useTableLoading: boolean) =>
  <T>(): MonoTypeOperatorFunction<T> => {
    if (useTableLoading) {
      api.showLoadingOverlay();
    }
    return tap(() => api.hideOverlay());
  };

@AutoUnsubscribe()
@Component({
  selector: 'app-grid-component',
  templateUrl: 'grid.template.html',
  styleUrls: ['grid.style.scss'],
})
export class AppGridComponent implements OnInit, OnChanges {
  @Input() gridOptions: GridOptions;
  @Input() columns = [];
  @Input() frameworkComponents = {};
  @Input() rows = null;
  @Input() currentPage = 1;
  @Input() initialGridFilters;
  @Input() filters = {};
  @Input() enableColResize: boolean = false;
  @Input() removeDuplicateGridChanged = false; // to solve output event changed execute twice sometime
  @Input() itemsPerPage: number = 1;
  @Input() totalItems: number;
  @Input() enablePagination: boolean;
  @Input() deselectAllWhenPageChange: boolean = true;
  @Output() ready: EventEmitter<OTRAgGridApi> = new EventEmitter(true);
  @Output() pageChanged = new EventEmitter();
  @Output() changed = new EventEmitter<{ page: number; filter: any }>();
  @Output() rowDataChanged = new EventEmitter();
  @Input() useTableLoading: boolean = true;
  @Input() boundaryLinks: boolean = true;
  @Input() maxSize: number = 5;

  @Input() theme: 'black' | 'white' | 'middle-gray' | 'gray' | 'zebra' = 'black';
  @Input() paginationDisabled = false;

  @Input() enabledPageSizes: boolean = false;
  @Input() pageSizes = [];
  @Output() pageSizeChanged = new EventEmitter();

  @ViewChild('grid') grid: AgGridNg2;
  @ViewChild('wrapper') wrapper;

  @Output()
  rowSelectedBeforePageChanged = new EventEmitter<any>();
  className = {};

  // vehicleFilter = {};
  private componentDestroy$: Observable<any>;

  private currentPage$ = new FormControl();
  private filter$ = new BehaviorSubject({});
  private combineLastArgs: any;

  constructor(private vehicleService: VehicleService) {}

  ngOnInit() {
    this.className = {
      [this.theme]: true,
      'grid-wrapper': true,
      'with-pagination': this.enablePagination,
    };

    this.gridOptions = this.gridOptions || getDefaultGridOptions();

    combineLatest(this.filter$, this.currentPage$.valueChanges)
      .pipe(
        takeUntil(this.componentDestroy$),
        // todo should remove removeDuplicateGridChanged use distinctUntilChanged(_.isEqual) by default
        // but more than 10 list used, if your grid listen change event the refactor steps:
        // 1. need set removeDuplicateGridChanged true to solved data load twice issue
        // 2. all business component which listen change event added removeDuplicateGridChanged and tested
        // 3. the mission of removeDuplicateGridChanged is accomplished
        // then can remove changeDealer and removeDuplicateGridChanged input, set distinctUntilChanged(_.isEqual) by default
        this.removeDuplicateGridChanged ?
          distinctUntilChanged(_.isEqual) :
          distinctUntilChanged(this.changeDealer.bind(this.combineLastArgs)),
        debounceTime(1),
      )
      .subscribe(([filter, page]: [any, number]) => {
        return this.changed.emit({ page, filter });
      });
  }

  // @deprecated todo should removed, no vehicleService.dealerFlag used
  changeDealer = (args) => {
    if (this.vehicleService.dealerFlag) {
      return _.isEqual.bind(args)();
    }
    return false;
  };

  ngOnChanges(changes): void {
    if (changes.columns && changes.columns.currentValue instanceof Array) {
      this.makeOptionsVisibleForSelector(changes.columns.currentValue);
    }

    if (changes.currentPage) {
      this.currentPage$.setValue(changes.currentPage.currentValue);
    }
    if (changes.filters) {
      if (!isEmptyValue(this.filters) && this.grid && this.grid.api) {
        this.grid.api.setFilterModel(this.filters);
      }
    }
  }

  makeOptionsVisibleForSelector(columns) {
    columns
      .filter((column) => column.cellRendererFramework === SelectorCellRenderComponent)
      .forEach((column) => this.attachClassForColumn(column, 'center-box', 'grid-selector'));
  }

  attachClassForColumn(column, ...classes) {
    const classList = [];

    if (column.cellClass instanceof String) {
      classList.push(column.cellClass);
    }

    if (column.cellClass instanceof Array) {
      classList.push(...column.cellClass);
    }

    classList.push(...classes);

    column.cellClass = classList;
  }

  onPageChanged(event) {
    if (this.currentPage !== event.page) {
      if (this.useTableLoading) {
        this.grid.api.showLoadingOverlay();
      }
      this.rowSelectedBeforePageChanged.emit(this.grid.api.getSelectedRows());
      if (this.deselectAllWhenPageChange) {
        this.grid.api.deselectAll();
      }
      this.currentPage = event.page;
      this.pageChanged.emit(event);
    }
  }

  onGridReady({ api, columnApi }) {
    api.addEventListener(GridEvents.EVENT_FILTER_CHANGED, () => this.filter$.next(this.getFilterModel()));

    this.ready.subscribe(() => {
      if (!isEmptyValue(this.initialGridFilters)) {
        api.setFilterModel(this.initialGridFilters);
      }
      setTimeout(() => {
        this.filter$.pipe(takeUntil(this.componentDestroy$), distinctUntilChanged(_.isEqual)).subscribe((filter: any) => {
          if (!_.isEqual(this.initialGridFilters, filter)) {
            this.currentPage$.setValue(1);
          }
        });
        this.currentPage$.setValue(this.currentPage || 1);
      });
    });

    this.ready.emit({
      api,
      columnApi,
      loading: loading(api, this.useTableLoading),
      eventEmitter: this.gridOptions.context.eventEmitter,
    });
  }

  onRowDataChanged(event): void {
    this.rowDataChanged.emit(event);
  }

  private getFilterModel() {
    this.vehicleService.dealerFlag = false;
    const filterModel = this.grid.api.getFilterModel();
    const filterKeyMap = this.columns
      .filter((column) => _.get(column, 'filterParams.filterKey'))
      .reduce(
        (map, column) => ({
          ...map,
          [column.field]: _.get(column, 'filterParams.filterKey'),
        }),
        {},
      );
    return _.mapKeys(filterModel, (value, key) => filterKeyMap[key] || key);
  }

  onPageSizeChange(pageSize) {
    this.pageSizeChanged.emit(pageSize);
  }
}

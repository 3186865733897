import { Injectable } from '@angular/core';
import { GET, RebirthHttp, Query, POST, Body, DELETE, Path, PATCH } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomLaborManagementListItem } from '../models/custom-labor-management';
import { ResponsePage } from '../../_common/models/sssc-collection';

@Injectable()
export class CustomLaborManagementApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/aftermarket-product/labors/custom-labors')
  getCustomLabors(
    @Query('pageNumber') pageNumber: number,
    @Query('pageSize') pageSize: number): Observable<ResponsePage<CustomLaborManagementListItem>> {
    return null;
  }

  @GET('/api/sales-website/aftermarket-product/labors/vendor/custom-labors')
  getCustomLaborsManagement(
    @Query('pageNumber') pageNumber: number,
    @Query('pageSize') pageSize: number): Observable<ResponsePage<CustomLaborManagementListItem>> {
    return null;
  }

  @POST('api/sales-website/aftermarket-product/labors/dealer-group/custom-labors/fif')
  getCustomLaborsGroup(@Body data): Observable<ResponsePage<CustomLaborManagementListItem>> {
    return null;
  }

  @GET('/api/sales-website/aftermarket-product/labors/labor-groups')
  getLaborGroups(): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/aftermarket-product/labors/custom-labors')
  createOrUpdateCustomLabor(@Body data): Observable<CustomLaborManagementListItem> {
    return null;
  }

  @PATCH('/api/sales-website/aftermarket-product/labors/dealer-group/custom-labors')
  createOrEditCustomLabor(@Body data): Observable<CustomLaborManagementListItem> {
    return null;
  }

  @POST('/api/sales-website/aftermarket-product/labors/dealer-group/custom-labors')
  createOrUpdateCustomLaborGroup(@Body data): Observable<CustomLaborManagementListItem> {
    return null;
  }

  @DELETE('/api/sales-website/aftermarket-product/labors/custom-labors/:id')
  deleteCustomLabor(@Path('id') id: string): Observable<any> {
    return null;
  }

  @DELETE('/api/sales-website/aftermarket-product/labors/dealer-group/custom-labors/:id')
  deleteCustomLaborGroup(@Path('id') id: string): Observable<any> {
    return null;
  }

  @GET('/api/privilege-level-config/type/CUSTOM_LABORS_PERMISSION')
  getCustomerLaborsPermissionAccount(): Observable<any> {
    return  null;
  }

  @POST('/api/sales-website/aftermarket-product/labors/dealer-group/custom-labors/:groupId/allocation')
  customLaborAllocation(@Path('groupId') groupId: number, @Body data): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/aftermarket-product/labors/dealer-group/custom-labors/:groupId/cancellation_allocated')
  customLaborUnAllocation(@Path('groupId') groupId: number, @Body data): Observable<any> {
    return null;
  }
}

import { Directive, HostListener, Input } from '@angular/core';
import * as _ from 'lodash';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[appPattern]',
})
export class InputPatternDirective {
  @Input() appPattern: RegExp;

  constructor(private ngControl: NgControl) {
  }

  @HostListener('input', ['$event'])
  onInput(event) {
    if (!this.appPattern.global) {
      throw new Error('appPattern should have global flag');
    }
    const input = event.target as HTMLInputElement;
    const result: string = _.join(input.value.match(this.appPattern));
    this.ngControl.control.patchValue(result);
  }
}

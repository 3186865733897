import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { GroupColumnHeaderParameter, MyHeader } from '../../header/group-column-header/group-column-header';
import { isValidIntegerValue, valueFormat } from '../../../../utils/sales-strategy';
import { BasicEditorComponent } from '../../editors/basic-editor.component';
import { isKeyNumeric, isKeyBackspace } from '../../../../utils/common';

@Component({
  templateUrl: './group-column-cell.template.html',
  styleUrls: ['./group-column-cell.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupColumnCellComponent extends BasicEditorComponent {

  private columnParams: GroupColumnHeaderParameter;

  params;
  headers: Array<MyHeader>;
  data: any;
  width: number;
  isValidateNumber = isValidIntegerValue;
  disabled: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params): void {
    this.params = params;
    this.data = this.params.value || {};
    this.columnParams = (params.column.getColDef().headerComponentParams as GroupColumnHeaderParameter);
    this.headers =  this.notDeletedHeader;
    this.disabled = this.columnParams.disabled;
    if (this.columnParams.expanded) {
      this.expandedChanged(this.columnParams.expanded.getValue());
      this.columnParams.expanded.subscribe(expanded => this.expandedChanged(expanded));
    } else {
      this.expandedChanged(true);
    }

  }

  get notDeletedHeader(): Array<MyHeader> {
    return _.filter(this.columnParams.headerList, (header: MyHeader) => !_.get(header, 'delete', false));
  }

  isEditable(data, header, subTitle) {
    const isEditableFunc = _.get(this.params.colDef.cellRendererParams, 'isEditable', () => false) as Function;
    return isEditableFunc(data, header, subTitle);
  }

  expandedChanged(expanded: boolean) {
    this.headers = expanded ? this.notDeletedHeader : this.notDeletedHeader.slice(0, 1);
    this.width = this.headers.length * 180 + 20;
    this.changeDetectorRef.markForCheck();
  }

  formattedValue(value) {
    return valueFormat(value);
  }

  shouldShowPercentageFlag(data, header, subTitle) {
    return data[header.id].isPercentage
      && data[header.id][subTitle.field]
      && this.isValidateNumber(data[header.id][subTitle.field]);
  }

  onBlurChange(value, headerId, subTitleField) {
    const dataRefresh = _.get(this.params.colDef.cellRendererParams, 'dataRefresh', _.identity) as Function;
    dataRefresh(this.params.data.type, headerId, value, subTitleField, this.params.colDef.field);
  }

  onOtherKeyDown(event): void {
    if (!this.isKeyPressedNumericOrBackspace(event)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  private isKeyPressedNumericOrBackspace(event): boolean {
    const key = this.getKeyFromEvent(event);
    return isKeyBackspace(key) || isKeyNumeric(key);
  }

}

import { Injectable } from '@angular/core';
import { Body, GET, POST, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PrivilegeApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/privileges/sc/kpi-visibility')
  getScKpiVisibility(): Observable<any> {
    return null;
  }

  @POST('/api/privileges/sc/kpi-visibility')
  setScKpiVisibility(@Body scKpiVisibility: Object): Observable<any> {
    return null;
  }
}

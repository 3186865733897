import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Events, ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  templateUrl: './custom-radio-group.component.html',
  styleUrls: ['./custom-radio-group.component.scss']
})
export class CustomRadioGroupComponent implements ICellRendererAngularComp {
  private params;
  private selectValue;
  options: [{value: number, text: string}];
  needRemind = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.selectValue = _.get(params, 'value');
    this.options = _.get(params, 'colDef.cellRendererParams.options', []);
    const getNeedRemind = _.get(this.params, 'getNeedRemind', false);
    this.needRemind = _.isFunction(getNeedRemind) ? getNeedRemind(this.params) : getNeedRemind;
  }

  isSelect(index: number) {
    const { value } = this.options[index];
    return this.selectValue === value;
  }

  refresh(params): boolean {
    return false;
  }

  onSelect(value: number) {
    const oldValue = this.selectValue;
    const newValue = oldValue === value ? null : value;
    const selectCallback = _.get(this.params, 'colDef.cellRendererParams.selectCallback', _.noop);
    this.dispatchChangeEvent(newValue, oldValue);
    selectCallback(newValue, oldValue, this.params);
    this.selectValue = newValue;
  }

  dispatchChangeEvent(newValue, oldValue) {
    const context = _.pick(this.params, ['api', 'node', 'context', 'data', 'colDef']);
    _.set(context.data, context.colDef.field, newValue);
    this.params.api.dispatchEvent(Events.EVENT_CELL_VALUE_CHANGED, Object.assign({ newValue, oldValue }, context));
  }
}

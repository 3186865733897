import { Injectable } from '@angular/core';
import { SalesTypeApi } from '../api/sales-type';
import { Observable } from 'rxjs';

@Injectable()
export class SalesTypeService {

  constructor(private salesTypeApi: SalesTypeApi) { }

  getSalesType(data, checkPrivilege): Observable<any> {
    return this.salesTypeApi.getSalesTypes(data, checkPrivilege);
  }
}

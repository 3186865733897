import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { TabService } from '@otr/website-common';
import { Injectable } from '@angular/core';

@Injectable()
export class CanLoadOrderPage implements Resolve<any> {
  constructor(private router: Router, private tabService: TabService) {

  }

  resolve(route: ActivatedRouteSnapshot) {
    this.tabService.initializeTab();
    this.router.navigate(['/order/management']);
    return;
  }
}

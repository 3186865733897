export const LEAD_TYPES = {
  LEAD: 'lead',
  LOST_SALES: 'lost sales',
  LEAD_CANDIDATE: 'lead candidate',
  UNSUCCESSFUL: 'unsuccessful',
  SUCCESSFUL: 'Successful',
};

export const ACCEPTABLE_LEAD_TYPE = [LEAD_TYPES.LEAD, LEAD_TYPES.LOST_SALES, LEAD_TYPES.LEAD_CANDIDATE];

export const LEAD_SOURCE_SYSTEM = {
  insight: 'INSIGHT',
};

export const MMC_2 = 'MMC_2';

import { ERROR_MESSAGES } from '../../../_common/constants/error-messages';
import * as _ from 'lodash';

const ERROR_CODE_MAPPING = [
  {
    error_code: 'icon_error',
    error_msg: ERROR_MESSAGES.ICON_BAD_RESPONSE,
  },
  {
    error_code: 'LPO_CLIENT_ERROR',
    error_msg: ERROR_MESSAGES.LPO_FAIL_TO_GET_DATA,
  },
  {
    error_code: 'dcs_error',
    error_msg: ERROR_MESSAGES.DCS_BAD_RESPONSE,
  },
  {
    error_code: 'lpo_sales_record',
    error_msg: ERROR_MESSAGES.LPO_FAIL_TO_GET_DATA,
  },
  {
    error_code: 'LPO_SC_STATUS_ERROR',
    error_msg: ERROR_MESSAGES.LPO_SC_STATUS_ERROR,
  },
  {
    error_code: 'export_data_length_limit',
    error_msg: ERROR_MESSAGES.EXPORT_DATA_LENGTH_LIMIT,
  },
  {
    error_code: 'IMPORT_TEMPLATE_NOT_FOUND',
    error_msg: ERROR_MESSAGES.IMPORT_TEMPLATE_NOT_FOUND,
  },
  {
    error_code: 'upload_file_server_error',
    error_msg: ERROR_MESSAGES.UPLOAD_FILE_SERVER_ERROR,
  },
  {
    error_code: 'internal_error',
    error_msg: ERROR_MESSAGES.UPDATE_FAILED,
  },
  {
    error_code: 'file_expired',
    error_msg: ERROR_MESSAGES.FILE_EXPIRED,
  },
  {
    error_code: 'data_too_many',
    error_msg: ERROR_MESSAGES.EXPORT_DATA_TOO_MANY,
  },
  {
    error_code: 'LPO_PLAN_INFO_CREATED_FAILED',
    error_msg: ERROR_MESSAGES.LPO_PLAN_INFO_CREATED_FAILED,
  },
  {
    error_code: 'LPO_PLAN_INFO_CANCEL_FAILED',
    error_msg: ERROR_MESSAGES.LPO_PLAN_INFO_CANCEL_FAILED,
  },
  {
    error_code: 'LPO_PLAN_INFO_UPDATE_FAILED',
    error_msg: ERROR_MESSAGES.LPO_PLAN_INFO_UPDATE_FAILED,
  },
  {
    error_code: 'LPO_PLAN_INFO_ACTIVATE_FAILED',
    error_msg: ERROR_MESSAGES.LPO_PLAN_INFO_ACTIVATE_FAILED,
  },
];

export function getErrorMessage(error): string {
  const errorCode = _.get(error, 'error_code', undefined);

  if (_.eq(errorCode, '')) {
    return _.get(error, 'message', ERROR_MESSAGES.BAD_RESPONSE);
  }

  if (['LPO_EXTEND_WARRANTY_ORDER_STATUS_UPDATE_FAILED', 'LPO_VERIFY_FAILED'].includes(errorCode)) {
    return _.get(error, 'message', ERROR_MESSAGES.LPO_FAIL_TO_GET_DATA);
  }

  const errorMapping = _.find(ERROR_CODE_MAPPING, { error_code: errorCode });
  return _.get(errorMapping, 'error_msg', ERROR_MESSAGES.BAD_RESPONSE);
}

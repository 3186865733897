<div class="select-filter-box">
  <label class="select-label select-label-all" (click)="onSelect($event)">
    <input type="checkbox" [checked]="isChecked()">
    全部
  </label>
  <div class="select-filter-group">
    <ng-container *ngTemplateOutlet="superOption; context: { data: options }"></ng-container>
  </div>
  <span class="btn-cancel" (click)="onCancel()">取消</span>
  <button type="submit" class="btn-save select-btn" (click)="onSubmit()">确定</button>
</div>

<ng-template #superOption let-data="data">
  <ng-container *ngFor="let item of data">
    <ng-container *ngIf="!isArray(item.value)">
      <ng-container *ngTemplateOutlet="option; context: { data: item }"></ng-container>
    </ng-container>
    <details *ngIf="isArray(item.value)" class="details" [open]="autoOpen">
      <summary class="summary">
        <ng-container *ngTemplateOutlet="option; context: { data: item }"></ng-container>
      </summary>
      <div class="content">
        <ng-container *ngTemplateOutlet="superOption; context:{ data: item.value }"></ng-container>
      </div>
    </details>
  </ng-container>
</ng-template>

<ng-template #option let-data="data">
  <label [ngClass]="{ 'select-label': !isArray(data.value) }" (click)="onSelect($event, data.value)">
    <input type="checkbox" [checked]="isChecked(data.value)">
    {{data.text | lowercaseBrand}}
  </label>
</ng-template>

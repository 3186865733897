import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { UserManagementApi } from '../api/user-management';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { CurrentUserService, SpinnerService } from '@otr/website-common';

@Injectable()
export class UserManagementService {
  groupUsers: Array<any> = [];
  salesConsultants: Array<any> = [];

  constructor(
    private userManagementApi: UserManagementApi,
    private spinnerService: SpinnerService,
    private currentUserService: CurrentUserService,
  ) {}

  getAllUsers(filter: any) {
    const dealerId = this.currentUserService.getCurrentUser().dealerId;
    const cecId = this.currentUserService.getCurrentUser().cecId;
    return this.userManagementApi.getUsersByDealerId(dealerId, { ...filter, cecId });
  }

  validateUserFields(gemsUserId: string, dmsUserId: string, groups: string, dmsOperatorId?: string, dmsOperatorDealerId?: string) {
    return this.userManagementApi.validateUserFields(gemsUserId, dmsUserId, dmsOperatorId, dmsOperatorDealerId, groups);
  }

  createUser(user: any) {
    return this.userManagementApi.createUser(user).pipe(this.spinnerService.loading());
  }

  updateUser(user: any) {
    return this.userManagementApi.updateUser(user, user.user_id).pipe(this.spinnerService.loading());
  }

  getUsersByGroupId(groupId = this.currentUserService.getCurrentUser().groupId) {
    return this.userManagementApi.getUsersByGroupId(groupId).pipe(
      tap((response) => {
        this.groupUsers = response;
      }),
    );
  }

  getReadPeName(groupId = this.currentUserService.getCurrentUser().groupId) {
    return this.userManagementApi.getUsersByGroupId(groupId);
  }

  loadSalesConsultantsByGroupId(groupId = this.currentUserService.getCurrentUser().groupId) {
    if (_.isEmpty(this.salesConsultants)) {
      this.userManagementApi.getSalesConsultantsByGroupId(groupId).subscribe((scList) => {
        this.salesConsultants = scList;
      });
    }
  }

  getUsersByPrivileges(privileges: string[], dealerId?) {
    return this.userManagementApi.getUsersByPrivileges(privileges, dealerId).pipe(
      map((users) =>
        _.map(users, ({ user_id: id, full_name: fullName, gems_user_id: gemsUserId, roleCode }) => ({
          id,
          fullName,
          gemsUserId,
          roleCode,
        })),
      ),
      catchError(() => of([] as any)),
    );
  }

  getUsersModelDByPrivileges(privileges: string[], dealerId?) {
    return this.userManagementApi.getUsersModelDByPrivileges(privileges, dealerId).pipe(
      map((users) =>
        _.map(users, ({ user_id: id, full_name: fullName, gems_user_id: gemsUserId, roleCode }) => ({
          id,
          fullName,
          gemsUserId,
          roleCode,
        })),
      ),
      catchError(() => of([] as any)),
    );
  }
  getUsersByRoles(dealerId, isActive: boolean, roles: string[]) {
    return this.userManagementApi.getUsersByRoles(dealerId, isActive, roles).pipe(
      map((users) =>
        _.map(users, ({ user_id: id, full_name: fullName, gems_user_id: gemsUserId, roleCode }) => ({
          id,
          fullName,
          gemsUserId,
          roleCode,
        })),
      ),
      catchError(() => of([] as any)),
    );
  }
  getUsedCarUsersByPrivileges(privileges: string[], dealerId?) {
    return this.userManagementApi.getUsedCarUsersByPrivileges(privileges, dealerId).pipe(
      map((users) =>
        _.map(users, ({ user_id: id, full_name: fullName, gems_user_id: gemsUserId, roleCode }) => ({
          id,
          fullName,
          gemsUserId,
          roleCode,
        })),
      ),
      catchError(() => of([] as any)),
    );
  }

  getUserByGemsUserId(gemsUserId: string) {
    return this.userManagementApi.getUsersByGemsId(gemsUserId);
  }

  getUserCtiInfo() {
    return this.userManagementApi.getUserCtiInfo();
  }
}

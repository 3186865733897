import { Injectable } from '@angular/core';
import { CustomerCriteriaConfigApi } from './customer-criteria-config.api';
import { AdvancedFilterPagingResponse, ICriteriaApiBody, ICriteriaBody } from './customer-criteria-config.model';
import { Observable } from 'rxjs';
import { inDateRange } from '../../_common/utils/date';
import { ServerTimeService } from '../../_common/services/server-time.service';
import { AdvancedFilter } from '../customer-management/shared/customer-management.model';
import { SpinnerService } from '@otr/website-common';

@Injectable()
export class CustomerCriteriaConfigService {
  constructor(
    private customerCriteriaConfigApi: CustomerCriteriaConfigApi,
    private spinnerService: SpinnerService,
    private serverTimeService: ServerTimeService,
  ) {
  }

  getCustomerConfig() {
    return this.customerCriteriaConfigApi.getCustomerConfig();
  }

  getCustomerCriteriaConfig(page: number = 1): Observable<AdvancedFilterPagingResponse> {
    return this.customerCriteriaConfigApi.getCustomerCriteriaConfig(page - 1, 10);
  }

  softDeleteCustomerCriteriaConfig(id: number) {
    return this.customerCriteriaConfigApi.softDeleteCustomerCriteriaConfig(id).pipe((this.spinnerService.loading()));
  }

  getAdvancedFilterByType(type: string): Observable<Array<AdvancedFilter>> {
    return this.customerCriteriaConfigApi.getAdvancedFilterByType(type);
  }

  isCriteriaExpire({ expireDate }): boolean {
    return expireDate && !inDateRange(null, expireDate, this.serverTimeService.currentDate);
  }

  saveNewCriteria(params: ICriteriaBody) {
    const apiParams: ICriteriaApiBody = {
      name: params.name,
      type: params.type,
      description: params.description,
      expireDate: params.expireDate,
      filter: JSON.stringify(params.filter),
    };
    return this.customerCriteriaConfigApi.addCustomerCriteria(apiParams);
  }

  getCustomerCriteria(dealerId, type): Observable<any> {
    return this.customerCriteriaConfigApi.getCustomerCriteria(dealerId, type);
  }
  deleteFilterCerteria(dealerId: Array<any>): Observable<any> {
    return this.customerCriteriaConfigApi.deleteFilterCerteria(dealerId);
  }
}

import * as _ from 'lodash';
import { environment } from '../../../../../../environments/environment';

export enum BusinessRange {
  ALL = 'ALL',
  SALES = 'SALES',
  AFTER_SALES = 'AFTER_SALES',
  USED_CAR_HISTORY = 'USED_CAR_HISTORY',
}

export enum ActivityFilterType {
  FOLLOW_UP_HISTORY = 'FOLLOW_UP_HISTORY',
  ACTIVITY_HISTORY = 'ACTIVITY_HISTORY',
  SERVICE_HISTORY = 'SERVICE_HISTORY',
  CUSTOMER_HISTORY = 'CUSTOMER_HISTORY',
  TASK_HISTORY = 'TASK_HISTORY',
}

export const DEFAULT_ACTIVITY_FILTER_SUB_TYPE = 'ALL';

export const ACTIVITY_FILTER_CHANGE_EVENT = 'ACTIVITY_FILTER_CHANGE_EVENT';

export interface ActivityFilterOption {
  value: string;
  text: string;
}

export enum ActivityFilterKey {
  businessRange = 'businessRange',
  type = 'type',
  subType = 'subType',
}

export interface ActivityFilter {
  [ActivityFilterKey.businessRange]: string;
  [ActivityFilterKey.type]: string;
  [ActivityFilterKey.subType]?: string;
}

export const getBusinessRangeFilter = (): { title: string, key: ActivityFilterKey, options: ActivityFilterOption[] } => ({
  title: '业务领域',
  key: ActivityFilterKey.businessRange,
  options: [
    {
      text: '全部',
      value: BusinessRange.ALL,
    },
    {
      text: '销售',
      value: BusinessRange.SALES,
    },
    {
      text: '售后',
      value: BusinessRange.AFTER_SALES,
    },
  ],
});

export const getBusinessRangeFilterWithUsedCar = (): { title: string, key: ActivityFilterKey, options: ActivityFilterOption[] } => ({
  title: '业务领域',
  key: ActivityFilterKey.businessRange,
  options: [
    {
      text: '全部',
      value: BusinessRange.ALL,
    },
    {
      text: '销售',
      value: BusinessRange.SALES,
    },
    {
      text: '售后',
      value: BusinessRange.AFTER_SALES,
    },
    {
      text: '二手车',
      value: BusinessRange.USED_CAR_HISTORY,
    },
  ],
});

const AFTER_SALES_TYPES = environment.isAsVehicleDisable ? [
  {
    text: '维修历史',
    value: ActivityFilterType.SERVICE_HISTORY,
  },
] : [
  {
    text: '跟进记录',
    value: ActivityFilterType.FOLLOW_UP_HISTORY,
  },
  {
    text: '维修历史',
    value: ActivityFilterType.SERVICE_HISTORY,
  },
  {
    text: '客户/车辆信息更新历史',
    value: ActivityFilterType.CUSTOMER_HISTORY,
  },
];

export const getTypeFilter = (
  businessRange: BusinessRange,
): { title: string, key: ActivityFilterKey, options: ActivityFilterOption[] } => ({
  title: '类型',
  key: ActivityFilterKey.type,
  options: _.get({
    [BusinessRange.ALL]: [
      {
        text: '跟进记录',
        value: ActivityFilterType.FOLLOW_UP_HISTORY,
      },
      {
        text: '任务历史',
        value: ActivityFilterType.TASK_HISTORY,
      },
    ],
    [BusinessRange.SALES]: [
      {
        text: '线索/客户筛选',
        value: ActivityFilterType.ACTIVITY_HISTORY,
      },
    ],
    [BusinessRange.AFTER_SALES]: AFTER_SALES_TYPES,
    [BusinessRange.USED_CAR_HISTORY]: [],
  }, businessRange),
});

export const getSubTypeFilter = (
  businessRange: BusinessRange,
  type: ActivityFilterType,
  interestedVehicleOptions: ActivityFilterOption[],
  currentVehicleOptions: ActivityFilterOption[],
): { title: string, key: ActivityFilterKey, options: ActivityFilterOption[] } => _.get({
  SALES_ACTIVITY_HISTORY: {
    title: '线索/客户',
    key: ActivityFilterKey.subType,
    options: [
      {
        text: '全部',
        value: 'ALL',
      },
      {
        text: '成交后跟进',
        value: 'SALES_CUSTOMER_FOLLOW_UP_HISTORY',
      },
      ...interestedVehicleOptions,
    ],
  },
  AFTER_SALES_FOLLOW_UP_HISTORY: {
    title: '现有车',
    key: ActivityFilterKey.subType,
    options: [
      {
        text: '全部',
        value: 'ALL',
      },
      ...currentVehicleOptions,
    ],
  },
  AFTER_SALES_SERVICE_HISTORY: {
    title: '现有车',
    key: ActivityFilterKey.subType,
    options: [
      ...currentVehicleOptions,
    ],
  },
  AFTER_SALES_CUSTOMER_HISTORY: {
    title: '现有车',
    key: ActivityFilterKey.subType,
    options: [
      ...currentVehicleOptions,
    ],
  },
}, `${businessRange}_${type}`);

import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import { Component, ElementRef, ViewChild } from '@angular/core';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};
@Component({
  template: `
  <div class="container">
    <a  [routerLink]="link" [queryParams]="queryParams" class="link-button" (click)="operation()" [ngClass]="{'expense-settle-color': isCheckButton}">{{ text }}</a>
  </div>
  `,
  styleUrls: ['./check-router-button.style.scss'],
})
export class CheckRouterButtonComponent implements ICellRendererAngularComp {
  text: string = '';
  link: string;
  isCheckButton: boolean = false;
  params: ICellRendererParams;
  cellRendererParams: any;
  queryParams: Object;

  @ViewChild('container') container: ElementRef;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellRendererParams = params.colDef.cellRendererParams;
    this.text = getValue(this.cellRendererParams.text, params);
    this.link = getValue(this.cellRendererParams.link, params);
    this.isCheckButton = getValue(this.cellRendererParams.isCheckButton, params) || false;
    this.queryParams = getValue(this.cellRendererParams.queryParams, params) || {};
  }

  refresh(params: any): boolean {
    return false;
  }

  operation() {
    if (_.isFunction(this.cellRendererParams.operation)) {
      this.cellRendererParams.operation(this.params.data);
    }
  }
}

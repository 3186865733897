<div *ngIf="defaultLabel">
  <button *ngIf="hideLabel"
          class="flag"
          (click)="selectedItem()"
          [disabled]="disabled">
    <img alt="" src="/assets/warranty/flag.svg"/>
  </button>
  <button *ngIf="!hideLabel"
          class="flag"
          (click)="selectedItem()"
          [disabled]="disabled">
    <img alt="" class="empty-flag" src="/assets/warranty/shape.svg"/>
  </button>
</div>

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { ConfirmService } from '../../services/confirm.service';
import { CurrentUserService, TokenService } from '@otr/website-common';
import {
  LPOServiceContractPriceConfigErrorCode,
} from '../../../service-contract/constant';
import { findGet } from '../../../_common/utils/common';
import {
  UPLOAD_ERROR_MESSAGE,
  LPO_ERROR_MESSAGE,
  UPLOAD_DOCUMENT_ERROR, ERROR_MESSAGES,
} from '../../../_common/constants/error-messages';

@Component({
  selector: 'app-upload-form',
  templateUrl: 'upload-form.template.html',
  styleUrls: ['upload-form.style.scss'],
})
export class UploadFormComponent {

  excelFile;
  isUploadSucceed: boolean | null = null;
  errorGemsId: string[] = [];
  inProgress: boolean = false;
  errorMessage: string[] = [];
  showErrorBtn: boolean = false;
  shouldShowError: boolean = false;

  @Input() isDisabled: boolean = false;
  @Input() isErrorShow: boolean;
  @Input() type: string = '';
  @Input() placeholder: string = '请选择上传的文件';
  @Input() uploadUrl: string;
  @Input() extensions: string[];
  @Input() updatedTime: string = '';
  @Input() contractType: string = '';
  @Input() description: string = '';
  @Input() errorMessagePrefix: string = '';
  @Input() headers: Object = {};
  @Input() confirmMessage: string = '';
  @Input() title: string = '导入';
  @Input() isDownloadFailure: boolean = false;
  @Output() onErrorShowEvent: EventEmitter<any> = new EventEmitter();
  @Output() onSucceed: EventEmitter<any> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  @Output() downloadErrorMessage: EventEmitter<any> = new EventEmitter();
  @Output() onDocumentError: EventEmitter<any> = new EventEmitter();
  @ViewChild('uploadField') uploadField: ElementRef;

  constructor(private currentUserService: CurrentUserService,
              private tokenService: TokenService,
              private confirmService: ConfirmService) {
  }

  onExcelSelected(event) {
    const files = FileAPI.getFiles(event);
    const file = files[0];
    if (_.isUndefined(file)) {
      return;
    }
    const isValidFormat = _.some(this.extensions, extension => _.endsWith(file.name, extension));
    if (isValidFormat) {
      this.excelFile = file;
      this.onExcelUpload();
    } else {
      alert('请选择正确的文件');
    }
    this.uploadField.nativeElement.value = '';
  }

  onExcelUpload() {

    if (!this.excelFile) {
      return;
    }

    if (this.type === 'document' && !this.description) {
      this.onDocumentError.emit();
      return;
    }
    this.isUploadSucceed = null;
    // @ts-ignore
    this.headers.Authorization = `Bearer ${this.tokenService.accessToken}`;
    FileAPI.upload({
      url: this.uploadUrl,
      headers: this.headers,
      files: { file: this.excelFile },
      data: {
        date: new Date().setHours(0, 0, 0, 0),
        dealer_id: this.currentUserService.getCurrentUser().dealerId,
        type: this.contractType,
        description: this.description,
      },
      progress: () => {
        this.inProgress = true;
      },
      complete: (err, xhr) => {
        this.handleComplete(err, xhr);
      },
    });
  }

  private handleDocumentError(xhr) {
    try {
      const message = findGet(UPLOAD_DOCUMENT_ERROR, { value: JSON.parse(xhr.response).message }, 'text', '');
      if (message) {
        this.shouldShowError = false;
        this.errorMessage = [message] || [];
      }
    } catch (error) {
      this.errorMessage = ['未知错误'];
    }
  }

  // tslint:disable-next-line:cyclomatic-complexity
  private handleComplete(err, xhr) {
    this.errorMessage = [];
    this.inProgress = false;
    this.isUploadSucceed = true;
    this.shouldShowError = this.isErrorShow;

    if (err) {
      this.isUploadSucceed = false;

      if (this.type === 'service contract') {
        try {
          this.errorMessage = [JSON.parse(xhr.response).message] || [];
        } catch (error) {
          this.errorMessage = [];
        }
      }

      if (this.type === 'lpo service contract') {
        try {
          this.errorMessage = [];
          const response = JSON.parse(xhr.response);
          if (response.error_code === LPOServiceContractPriceConfigErrorCode.VALIDATE_EXCEL_FAILED) {
            this.errorMessage = [response.message];
          }

          if (response.error_code === LPOServiceContractPriceConfigErrorCode.INVALID_DEALER_PRICE_FROM_LPO &&
            !_.isNil(JSON.parse(response.message).invalid)) {
            const invalid = JSON.parse(response.message).invalid;
            this.errorMessage.push('错误原因：');
            this.getLPOErrorMessage(invalid);
          }
          this.onErrorShowEvent.emit(response);
        } catch (error) {
          this.errorMessage = [];
        }
      }

      if (this.type === 'document') {
        this.handleDocumentError(xhr);
      }

      this.onError.emit(this.getErrorMessage(err, xhr));
      return;
    }

    if (this.type === 'document') {
      this.onSucceed.emit(xhr.response);
      this.handleDocumentError(xhr);
      return;
    }

    const result = JSON.parse(xhr.responseText);
    this.handleTemplateResponse(result);
  }

  private getBadRequest(xhr) {
    const error_code = JSON.parse(xhr.response).error_code;
    if (error_code === 'unexpect_column_value') {
      return JSON.parse(xhr.response).message;
    }
    if (error_code === LPOServiceContractPriceConfigErrorCode.LPO_CLIENT_ERROR) {
      return '导入失败';
    }
    if (error_code === LPOServiceContractPriceConfigErrorCode.INCORRECT_SHEET_NAME) {
      return ERROR_MESSAGES.INCORRECT_SHEET_NAME;
    }
    if (error_code === 'found_zero_price') {
      this.isContinueUpload();
      return ' ';
    }
    return '导入失败，请检查文件格式是否正确或下载新模板';
  }

  private getErrorMessage(err, xhr) {
    let message;
    switch (err) {
      case 'Gateway Time-out':
        message = '系统超时，可能网络繁忙，请重试';
        break;
      case 'Bad Request':
        message = this.getBadRequest(xhr);
        break;
      case 'Not Acceptable':
        message = '导入失败，请检查文件格式是否正确';
        break;
      case 'Unprocessable Entity':
        message = '经销商不可添加BMBS服务合同，仅可修改已有的BMBS服务合同数据';
        break;
      default:
        message = '未知错误';
        break;
    }
    return message;
  }

  handleTemplateResponse(result: any) {
    this.errorGemsId = [];
    if (!_.isEmpty(result.error_gems_id)) {
      this.errorGemsId = result.error_gems_id;
      return;
    }
    this.showErrorBtn = false;
    this.errorMessage = result.message || [];
    if (_.isEmpty(result.message)) {
      if (this.isDownloadFailure) {
        this.updatedTime = result.update_time;
      }
      this.onSucceed.emit(result);
    } else {
      this.isUploadSucceed = false;
      if (result.message[0] === '导入失败') {
        this.showErrorBtn = true;
      }
    }
  }

  onUploadClick() {
    this.uploadUrl = this.uploadUrl.replace('&isContinueUpload=true', '');
    if (this.confirmMessage) {
      this.confirmService.confirm({
        message: this.confirmMessage,
      }).subscribe(result => result &&
        setTimeout(() => {
          this.uploadField.nativeElement.click();
        }, 500));
      return;
    }
    this.uploadField.nativeElement.click();
  }

  refresh() {
    this.isUploadSucceed = null;
    this.excelFile = null;
  }

  onClickDownload() {
    this.downloadErrorMessage.emit();
  }

  isContinueUpload(): void {
    this.confirmService.confirm({
      message: '存在价格为0的数据行，是否继续上传？',
      cancelButtonText: '取消',
      confirmButtonText: '继续上传',
    }).subscribe(result => {
      if (result) {
        this.uploadUrl += '&isContinueUpload=true';
        this.onExcelUpload();
      }
    });
  }

  getLPOErrorMessage(data) {
    _.forEach(LPO_ERROR_MESSAGE, errorType => {
      let errors = [];
      _.map(data, error => {
        if (error.errors === errorType.value) {
          if (error.errors === UPLOAD_ERROR_MESSAGE.PRICE_NOT_FOUND) {
            errors = [...errors, error.baumuster];
          }
          if (error.errors === UPLOAD_ERROR_MESSAGE.PRODUCT_CODE_NOT_FOUND
            || error.errors === UPLOAD_ERROR_MESSAGE.SKU_NOT_FOUND) {
            errors = [...errors, error.productCode];
          }
          if (error.errors === UPLOAD_ERROR_MESSAGE.DUPLICATE_SKU_BMST
            || error.errors === UPLOAD_ERROR_MESSAGE.SKU_BMST_NOT_FOUND) {
            errors = [...errors, `(${error.productCode} ${error.baumuster})`];
          }
        }
      });
      if (!_.isEmpty(errors)) {
        this.errorMessage = [...this.errorMessage, `· ${errorType.text}：${_.join(_.uniq(errors), ', ')}`];
      }
      errors = [];
    });
  }
}

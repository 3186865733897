import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  selector: 'app-text-select-switch-renderer',
  templateUrl: './text-icon-click-renderer.component.html',
  styleUrls: ['./text-icon-click-renderer.component.scss'],
})
export class TextIconClickRendererComponent implements ICellRendererAngularComp {
  value: string;
  params: ICellRendererParams;
  options: Array<any>;
  disabled: boolean;
  displayTextStyle: any;
  editIcon: any;
  valueFormatter: any;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.params = params;
    this.options = this.cellRendererParams.options;
    this.editIcon = this.cellRendererParams.editIcon;
    this.displayTextStyle = getValue(this.cellRendererParams.textStyle, params);
    this.disabled = getValue(this.cellRendererParams.disabled, params);
    this.valueFormatter = getValue(this.cellRendererParams.valueFormatter, params);
  }

  refresh(params: any): boolean {
    return false;
  }

  get displayText() {
    return this.valueFormatter || _.get(_.find(this.options, { value: this.value } as any), 'text', '');
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  clickMode() {
    this.cellRendererParams.onClickMode(this.params.node.data);
  }
}

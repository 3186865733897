<div class="filter-box">
  <div class="select-filter-group">
    <label class="select-label">
      <input type="radio"
             value="未评分"
             class="radio-button"
             name="search-or-select"
             [formControl]="radio"
      />
      {{'未评分'}}
    </label>
    <label class="select-label">
      <input type="radio"
             value=""
             class="radio-button"
             name="search-or-select"
             [formControl]="radio"
      />
      {{'空值'}}
    </label>
    <div class="filter-box padding-bottom-0" style="display: flex">
      <input
        id="input"
        value="NORMAL"
        type="radio"
        class="radio-button"
        name="search-or-select"
        [formControl]="radio"
      />
      <div class="input-container" style="display: flex">
        <input
          class="form-control range-form-control range-left"
          [formControl]="startInput"
          [placeholder]="placeholder"
          (focus)="radio.setValue('NORMAL')"
          style="width:44px;transform: translateY(-5px)"
        >
        <div style="margin-right: 3px;">分</div>
        <span class="range-line" style="transform: translateY(7px)"></span>
        <input
          class="form-control range-form-control range-right"
          [formControl]="endInput"
          [placeholder]="placeholder"
          (focus)="radio.setValue('NORMAL')"
          style="width:44px;transform: translateY(-5px)"
        >
        <div style="margin-left: 3px;">分</div>
      </div>
  </div>
  </div>
  <span class="btn-cancel" (click)="onCancel()">取消</span>
  <button
    type="submit"
    class="btn-save range-btn"
    [disabled]="!isFilterValid()"
    (click)="onSubmit()"
  >
    确定
  </button>
</div>

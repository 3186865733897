import { Injectable } from '@angular/core';
import { RebirthHttp, GET, Query, POST, Body, DELETE } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApprovalProcessApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/order_approval/template/url')
  getTemplate(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/order_approval/config/url')
  getExistingData(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/order_approval/config/update_time')
  getUpdateTime(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/v2/approval-flow-config/variant-with-custom-parameters')
  getVariantsWithCustomParameters(): Observable<any> {
    return null;
  }

  @POST('/api/v2/approval-flow-config/custom-parameters')
  saveCustomParameters(@Body customParameters) {
    return null;
  }

  @GET('/api/v2/approval-flow-config/variant-custom-parameter-lines')
  getVariantWithCustomParameterLine(): Observable<any> {
    return null;
  }

  @POST('/api/v2/approval-flow-config/variant-custom-parameter-lines')
  saveVariantWithCustomParameterLine(@Body customParameterHeaders): Observable<any> {
    return null;
  }

  @DELETE('/api/v2/approval-flow-config/variant-custom-parameter-lines')
  deleteVariantWithCustomParameterLines(@Body customParameterHeaders): Observable<any> {
    return null;
  }

  @POST('/api/v2/approval-flow-config/custom-parameter-headers')
  createCustomParameterHeaders(@Body customParameterHeaders): Observable<any> {
    return null;
  }

  @GET('/api/v2/approval-flow-config/custom-parameter-headers')
  getCustomParameterHeaders(): Observable<any> {
    return null;
  }

  @DELETE('/api/v2/approval-flow-config/custom-parameter-headers')
  deleteCustomParameterHeaders(@Body customParameterHeaders): Observable<any> {
    return null;
  }

  @GET('/api/v2/approval-flow-config/basic-parameters')
  getGroupParameters(@Query('dealer_group_group_id') dealerGroupGroupId: number,
                     @Query('region_group_id') regionGroupId: number,
                     @Query('dealer_group_id') dealerGroupId: number,
                     @Query('group_type') groupType: string): Observable<any> {
    return null;
  }

  @GET('/api/v2/approval-flow-config/export/url')
  downloadGroupParameters(@Query('dealer_group_ids') dealerGroupId: Array<number>): Observable<any> {
    return null;
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';
import { commercialInsuranceStatus, followUpStatusWordsOfNewCarCustomer } from 'app/insurance-saas/constant/insurance';

const statusStyleMap = {
  SUCCESSFUL_ORDER: 'status-success',
  DEFEATED: 'status-failed',
};

@Component({
  template: `
    <div class="commercial-insurance-status">
      <div>
        <label>商业</label><span [class]="commercialStyle">{{ commercialText }}</span>
      </div>
      <div>
        <label>交强</label><span [class]="statutoryAutomobileLiabilityStyle">{{ statutoryAutomobileLiabilityText }}</span>
        <div></div>
      </div>
    </div>
  `,
  styleUrls: ['./commercial-insurance-status-cell-component.style.scss'],
})
export class CommercialInsuranceStatusCellComponent implements ICellRendererAngularComp {
  commercialText: string;
  commercialStyle: string;
  statutoryAutomobileLiabilityText: string;
  statutoryAutomobileLiabilityStyle: string;

  agInit(params: ICellRendererParams): void {
    const commercialKey = _.get(params, 'data.commercialInsuranceStatus');
    this.commercialText = commercialInsuranceStatus[commercialKey];
    this.commercialStyle = statusStyleMap[commercialKey];

    const statutoryAutomobileLiabilityKey = _.get(params, 'data.statutoryAutomobileLiabilityInsuranceStatus');
    this.statutoryAutomobileLiabilityText = commercialInsuranceStatus[statutoryAutomobileLiabilityKey];
    this.statutoryAutomobileLiabilityStyle = statusStyleMap[statutoryAutomobileLiabilityKey];
  }

  refresh(): boolean {
    return false;
  }
}

import { Observable } from 'rxjs';
import { EXCEL_FILE_CONTENT_TYPE, PDF_FILE_CONTENT_TYPE } from '../constants/service-contract';
import * as _ from 'lodash';

export function download(href, fileName, extension = '') {
  const a = document.createElement('a');
  a.href = href;
  a.download = fileName + extension ;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const exportOnDifferentBrowsers = (arrayBuffer: ArrayBuffer, contentType: string, fileName: string, extension: string = '') => {
  const blob = new Blob([arrayBuffer], { type: contentType });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName + extension);
  } else {
    download(URL.createObjectURL(blob), fileName, extension);
  }
};

export const exportExcelOnDifferentBrowsers = (excelSource: Observable<any>, excelName: string, includeExcelNameSuffix?) => {
  const fileName = includeExcelNameSuffix ? excelName : `${excelName}.xlsx`;
  excelSource.subscribe(response => exportOnDifferentBrowsers(response, EXCEL_FILE_CONTENT_TYPE, fileName));
};

export const printPdfOnDifferentBrowsers = (arrayBuffer: ArrayBuffer, fileName: string) => {
  const blob = new Blob([arrayBuffer], { type: PDF_FILE_CONTENT_TYPE });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    window.open(URL.createObjectURL(blob));
  }
};

export const getDownloadErrorMessage = (err: any) => {
  let message = '导出失败，请重试';
  const error = err.error;
  if (_.isArrayBuffer(error) && error.byteLength !== 0) {
    const textDecoder = new TextDecoder('utf-8');
    try {
      message = _.get(JSON.parse(textDecoder.decode(error)), 'message');
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e);
    }
  }
  return message;
};

import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BasicEditorComponent } from '../basic-editor.component';
import { calculateOptionsRowHeight } from '../../../../../customer-lead-management/as-lead/util/calculate-height.util';

@Component({
  selector: 'app-multiple-select-editor-cell',
  styleUrls: ['../editor.style.scss'],
  templateUrl: 'multiple-select-editor.template.html',
})
export class MultipleSelectEditorCellComponent extends BasicEditorComponent {
  public options: Array<any>;
  public selectorStyle: Object;
  public placeholder: string;
  public display: boolean;
  public upturnedConfig: any;
  public key: any;

  errorStyle = { 'border-color': '#E84522' };

  agInit(params: any): void {
    super.agInit(params);
    this.selectorStyle = this.cellEditorParams.styles || {};
    this.placeholder = this.cellEditorParams.placeholder || '';
    this.options = this.getOptions();
    this.display = this.getDisplay();
    this.key = this.getKey();
    const optionsHeight = calculateOptionsRowHeight(_.map(this.options, 'text'), this.cellEditorParams.cellOptionWidth);
    const canUpturned = this.params.node.rowTop > optionsHeight;
    this.upturnedConfig = { canUpturned, optionsHeight };
  }

  get initialSelectedValues() {
    return _.compact(_.split(_.get(this.params, 'value', ''), /(,\s?)/));
  }

  get shouldJoinValue() {
    return _.isNil(this.cellEditorParams.shouldJoinValue) ? true : this.cellEditorParams.shouldJoinValue;
  }

  getOptions() {
    const optionData = this.cellEditorParams.options;
    return this.formattedOptions(_.isFunction(optionData) ? optionData() : optionData, this.initialSelectedValues);
  }

  formattedOptions(options, selectedValues) {
    return _.chain(options)
      .cloneDeep()
      .map(option => {
        const isSelected = _.includes(selectedValues, option.value);
        return Object.assign(option, { selected: isSelected });
      })
      .value();
  }

  onUpdateEvent() {
    this.value = '';
    this.options = this.formattedOptions(this.options, '');
    this.display = this.getDisplay();
    this.updateSourceValue('');
  }

  reRenderOptions() {
    this.updateSourceValue(this.value);
    this.options = this.formattedOptions(this.options, _.split(this.value, ','));
    this.onChangeHandler();
  }

  onChange(event) {
    if (this.key === 'freebie' && !_.isEqual(this.value, event)) {
      const arr = _.split(event, ',');
      const originArr = _.split(this.value, ',');
      if (!_.includes(originArr, 'NA') && _.includes(arr, 'NA')) {
        this.value = 'NA';
        this.reRenderOptions();
        return;
      }
      if (_.includes(originArr, 'NA') && arr.length > 1) {
        this.value = _.join(_.pull(arr, 'NA'), ',');
        this.reRenderOptions();
        return;
      }
      this.updateSourceValue(event);
      this.onChangeHandler();
      return;
    }
    this.value = event;
    this.updateSourceValue(event);
    this.onChangeHandler();
  }

  private getDisplay() {
    const display = _.get(this.cellEditorParams, 'display', true);
    return _.isFunction(display) ? display(this.params) : display;
  }

  private getKey() {
    const key = _.get(this.cellEditorParams, 'key', '');
    return _.isFunction(key) ? key(this.params) : key;
  }
}

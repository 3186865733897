import { Injectable } from '@angular/core';
import { CouponApi } from '../api/coupon';

@Injectable()
export class CouponService {
  constructor(private couponApi: CouponApi) {}

  couponVerify(plateNumber, couponCode) {
    return this.couponApi.couponVerify({
      couponCode,
      plateNumber,
    });
  }

  exportCsv() {
    return this.couponApi.exportCsv();
  }
}

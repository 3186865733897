<div class="button-container" *ngFor="let dealer of dealerList; let i = index">
  <span class="button" (click)="onEditing(i)">
    <img alt="" [src]="'/assets/common/edit-blue.svg'"
         placement="bottom"
    >
  </span>
  <span class="button delete-button" (click)="onDeleting(i)">
    <img alt="" [src]="'/assets/common/delete-blue.svg'"
         placement="bottom"
    >
  </span>
</div>

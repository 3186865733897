import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';

export interface MessageObject {
  isSuccess: boolean;
  text: string;
}

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnDestroy {

  @Output() close: EventEmitter<void> = new EventEmitter();
  visible: boolean = false;
  title: string = '';
  nzClosable: boolean = true;
  maskClosable: boolean = false;
  message: MessageObject;
  timer: NodeJS.Timer;

  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  onOpen(): void {
    this.visible = true;
  }

  onClose(): void {
    this.close.emit();
    this.visible = false;
  }

  putSuccessMessage(text: string, timeout?: number): void {
    this.putMessage(true , text , timeout);
  }

  putErrorMessage(text: string, timeout?: number): void {
    this.putMessage(false, text, timeout);
  }

  putMessage(isSuccess: boolean, text: string, timeout: number = 3000): void {
    this.message = {
      isSuccess,
      text,
    };
    this.resetTimeout(timeout);
  }

  putMessageWithMessageObject(message: MessageObject, timeout: number = 3000): void {
    this.message = message;
    this.resetTimeout(timeout);
  }

  resetTimeout(timeout: number) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.clearMessage();
    }, timeout);
  }

  clearMessage(): void {
    this.message = null;
  }
}

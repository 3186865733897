import { Injectable } from '@angular/core';
import { GET, Path, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OutletResponse } from '@otr/website-common';

@Injectable({
  providedIn: 'root',
})
export class UserApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/users/availability/:gemsUserId')
  checkUsername(@Path('gemsUserId') gemsUserId: string): Observable<any> {
    return null;
  }

  @GET('/api/users/me/detail')
  getCurrentUserDetail(): Observable<any> {
    return null;
  }

  /**
   * @deprecated
   *  Usage: should be moved to other api file; dealerId should not exist in api param
   */
  @GET('/api/outlets/authorization')
  fetchOutlet(@Query('dealer_id') dealerId: string): Observable<OutletResponse> {
    return null;
  }
}

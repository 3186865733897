import { OfferType, ServiceProduct } from '../product/model';
import { AfterSalesServiceProduct } from '../../model';

export interface EmailOrSmsSentStatus {
  leadId: string;
  success: boolean;
  failedResult?: string;
  businessId?: string;
  sendTime?: string;
}

export class Reservation {
  leadId: string;
  clientName: string;
  clientMobile: string;
  reservationDate: string;
  vehiclePlateNumber: string;
  reservationComments: string;
  serviceProducts: ServiceProduct[];
  leadProducts: number[];
}

export class BuildInServiceProduct extends ServiceProduct {
  priceIncludingVat: number;
  name: string;
}

export class BuildInProductDto {
  constructor(product: BuildInServiceProduct) {
    this.product = product;
  }

  product: BuildInServiceProduct;
  checked: boolean = true;

  static fromServiceProduct(product: AfterSalesServiceProduct): BuildInProductDto {
    const serviceProduct = new BuildInServiceProduct(
      product.leadProductId,
      product.offerType === 'BASIC' ? OfferType.BASIC : OfferType.OPTIONAL,
      product.isFree,
      null,
    );
    serviceProduct.priceIncludingVat = product.priceIncludingVat || 0;
    serviceProduct.name = product.name;

    return new BuildInProductDto(serviceProduct);
  }

  static from(product: AfterSalesServiceProduct) {
    return BuildInProductDto.fromServiceProduct({
      ...product,
      name: product.recommendProduct,
    });
  }
}

export interface CombineReservationRequest {
  leadId: Array<any>;
  d8Account: string;
  orderId?: string;
  clientName: string;
  clientMobile: string;
  vehiclePlateNumber?: string;
  reservationDate?: string;
  clientSalutation: string;
  serviceProducts?: Array<ServiceProductRequest>;
  reservationComments?: string;
  channel?: any;
  leadProducts?: Array<number>;
  serviceType?: string;
  businessId: Array<string>;
  sendTime?: Array<string>;
  userGemsId?: string;
  oabReservationId?: string;
  tag?: string;
  haveReservationChange?: any;
  dealerId: string;
  fin?: string;
  carModel?: string;
}

export interface BatchFollowupRequest {
  d8Account: string;
  followUps: Array<FollowupInfo>;
  dealerId: string;
}

export interface FollowupInfo {
  leadId: number;
  followUpComments?: string;
  nextFollowUpReason: string;
  nextFollowUpDate: string;
  d8Account: string;
  channel?: string;
  success?: boolean;
  failedResult?: string;
  nextFollowUpStrategyId?: string;
}

export interface DmpBatchResponse {
  data: boolean;
  code: string;
  msg: string;
  oabMsg: string;
  message: string;
}

export interface ServiceProductRequest {
  serviceProductId?: string;
  offerType?: string;
  serviceProductType ?: string;
  isFree?: boolean;
}

export const USER_GROUP_TYPES = {
  DEALER_GROUP: { value: 'DEALER_GROUP', text: '经销商集团' },
  REGION: { value: 'REGION', text: '区域' },
  DEALER: { value: 'DEALER', text: '经销商' },
};

export const USER_GROUP_TYPE_VALUES = [
  USER_GROUP_TYPES.DEALER_GROUP.value,
  USER_GROUP_TYPES.REGION.value,
  USER_GROUP_TYPES.DEALER.value,
];

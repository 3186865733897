import {Injectable} from '@angular/core';
import {PrivacyAgreementApi} from '../api/privacy-agreement';

@Injectable()
export class PrivacyAgreementService {
  constructor(private privacyAgreementApi: PrivacyAgreementApi) {}

  getPrivacyAgreements(pageSize, pageNumber) {
    return this.privacyAgreementApi.getPrivacyAgreements(pageSize, pageNumber);
  }
}

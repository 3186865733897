import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';

interface DealerSelectionOption {
  value: any;
  text: string;
  selected?: boolean;
}

@Component({
  selector: 'app-dealers-dropdown-select',
  templateUrl: './dealers-select-dropdown.template.html',
  styleUrls: ['./dealers-select-dropdown.style.scss'],
})
export class DealersDropdownSelectComponent implements OnInit, OnChanges {
  selfClick: boolean;
  title: string = '';
  isVisible: boolean = false;
  keyword: string = '';
  displayOptions: DealerSelectionOption[];
  originalOptions: DealerSelectionOption[];
  @Input() placeholder: string;
  @Input() returnValue = 'dealerId';
  @Input() options;
  @Input() defaultVisibleRoles?: any = [];
  @Input() parent?: string;
  @Input() formControl?: FormControl;
  @Input() disabled?: boolean;

  async ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.reloadSearchResult();
    this.updateSelectedOption();
    this.resetKeyword();
  }

  @HostListener('document:click')
  onClick() {
    if (!this.selfClick && this.isVisible) {
      this.toggleOffDropdown();
    }
    this.selfClick = false;
  }

  toggleDropdown() {
    if (this.isVisible) {
      this.toggleOffDropdown();
    } else {
      this.toggleOnDropdown();
    }
  }

  isOptionSelected(option) {
    return option.selected;
  }

  isAllOptionSelected(): boolean {
    return !_.find(this.displayOptions, (option) => !option.selected);
  }

  setSelected(option) {
    option.selected = !option.selected;
  }

  clickSelectAll() {
    const isChecked = this.isAllOptionSelected();
    _.forEach(this.displayOptions, (option) => {
      option.selected = !isChecked;
    });
  }

  updateSelectedOption() {
    const selectedOptions = _.filter(this.displayOptions, (option) => option.selected);
    if (selectedOptions.length > 0) {
      const dealerIds = _.map(selectedOptions, (option) => _.get(option, 'value.dealerId'));
      if (dealerIds.includes('NATIONWIDE')) {
        this.title = selectedOptions.length === 1 ? '全国' : `全国，${selectedOptions.length - 1}家经销商`;
      } else {
        this.title = `${selectedOptions.length}家经销商`;
      }
    } else {
      this.title = '请选择';
    }
    if (this.disabled) {
      this.title = '请选择';
      this.isVisible = false;
    }
    this.resetKeyword();
    this.updateSelectionToOptions(selectedOptions);
    // 处理formControl需要的数据
    this.formControl.setValue(_.map(selectedOptions, (option) => _.get(option, `value.${this.returnValue}`)).join(','));
  }

  onMouseClick() {
    this.selfClick = true;
  }

  confirmSelectDealer() {
    this.updateSelectedOption();
    this.isVisible = false;
  }

  reloadSearchResult() {
    const options = [
      ..._.filter(this.options, (option) => {
        return option.value.dealerId === 'NATIONWIDE';
      }),
      ..._.filter(this.options, (option) => {
        return (
          option.value.dealerId !== 'NATIONWIDE' &&
          option.selected &&
          option.value.dealerId &&
          (_.includes(option.value.dealerName, this.keyword) ||
          _.includes(option.value.dealerId, this.keyword) ||
          _.includes(option.value.gsCode, this.keyword))
        );
      }),
      ..._.filter(this.options, (option) => {
        return (
          option.value.dealerId !== 'NATIONWIDE' &&
          !option.selected &&
          option.value.dealerId &&
          (_.includes(option.value.dealerName, this.keyword) ||
          _.includes(option.value.dealerId, this.keyword) ||
          _.includes(option.value.gsCode, this.keyword))
        );
      }),
    ];

    this.displayOptions = _.map(options, (option) => {
      return {
        ...option,
        text: `${option.hiddenId ? '' : option.value.gsCode || option.value.dealerId} ${option.text}`
      };
    });
  }

  reset() {
    _.forEach(this.displayOptions, (option) => {
      option.selected = false;
    });
    this.updateSelectedOption();
  }

  private resetKeyword() {
    this.keyword = '';
  }

  private toggleOffDropdown() {
    this.options = _.cloneDeep(this.originalOptions);
    this.resetKeyword();
    this.isVisible = false;
  }

  private toggleOnDropdown() {
    this.reloadSearchResult();
    this.originalOptions = _.cloneDeep(this.options);
    this.isVisible = true;
  }

  private updateSelectionToOptions(selectedOptions: DealerSelectionOption[]) {
    return _.each(this.options, (option) => {
      option.selected = !_.isEmpty(
        _.find(selectedOptions, (selectedOption) => {
          return selectedOption.value.dealerId === option.value.dealerId;
        }),
      );
    });
  }
}

import { Body, GET, Headers, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OptionApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @PUT('/api/quotation-options/freebie-batch')
  batchSaveOption(@Body data): Observable<any> {
    return null;
  }

  @GET('/api/quotation-options/freebie/vin-or-commission-no/export')
  @Headers({ isExportFile: true })
  downloadCode(@Query('optionId') optionId: string,
               @Query('type') type: string): Observable<any> {
    return null;
  }

  @PUT('/api/quotation-options/accessory-batch')
  batchSaveAccessories(@Body data): Observable<any> {
    return null;
  }
}

<div class="header-container" [class.pull-tooltip-left]="pullTooltipLeft" [class.fixed-width-100]="useFixedWidthTooltip">
  <span class="header-content">
    <span class="header-content-text">{{headerParams.displayName}}</span>
  </span>
  <i class="fa antd-question-circle-filled"
     tooltip="{{headerTooltipText}}"
     placement="bottom">
  </i>
  <i #headerMenu class="header-menu" [ngClass]="{'header-menu-active': isFilterActive}"
     (click)="handleMenuIconClick()"
  >&nbsp;</i>
</div>

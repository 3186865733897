import { BehaviorSubject, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, combineLatest, switchMap } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as _ from 'lodash';

import { CHINESE_CALENDAR_CONFIG } from '../../../../_common/constants/calendar';
import { SalesLeadModalContent } from '../sales-lead-modal/sales-lead-modal.component';
import { dateParse } from '../../../../_common/utils/date-parse';
import { LEAD_ACTION_TYPE, LEAD_SERVICE, MOCK_DATA } from '../../shared/constant/lead';
import {
  CURRENT_VEHICLE_ASSESSMENT_OPTION,
  CDO_LEAD_CHANNELS_OPTIONS,
  CERTIFICATE_NUMBER_VALIDATOR,
  CERTIFICATE_TYPE,
  CHANNELS,
  E_COMMERCE_LEAD_TYPE,
  FAMILY_MEMBER_COUNT,
  LEAD_CHANNELS,
  PREFERRED_CONTACT_TIME, PREFERRED_CONTACT_CHANNEL,
} from '../../../../_common/constants/common';
import { getDateAtZero } from '../../../../_common/utils/date';
import { LEAD_SOURCE_FILTER_TYPE, LeadService } from '../services/lead.service';
import { loadLeadChannelsOptionByCurrentChannel } from '../../../../_common/utils/lead';
import { buildCustomerHobbiesToTypeMapping } from '../shared/customer-hobby.util';
import { preferredContactChannelUtils } from '../utils/preferred-contact-channel-utils';
import { Lead } from '../../shared/model';
import { getFormattedVehicleFieldForLoadForm } from '../../../../_common/utils/vehicle';
import { FormUtilService } from '../../../../_common/services/form-util.service';
import { ScScheduleService } from '../../../../_common/services/sc-schedule.service';
import { ShowroomVisitService } from '../../../../_common/services/showroom-visit.service';
import { TestDriveService } from '../../../../_common/services/test-drive.service';
import { InterestedVehicle, LeadAdditionItem } from '../../../../_common/models/lead';
import { deleteEmptyProperty, isEmptyValue } from '../../../../_common/utils/common';
import { DUPLICATE_CUSTOMER_ERROR_CODES } from '../../../../_common/constants/error-messages';
import { KeyValue } from '../../../../_common/models/common';
import {
  ADDITIONAL_PURCHASE, AGE_RANGE_OPTIONS,
  BOOLEAN_OPTIONS,
  GRADE, gradeAndPurchaseTimeMapping, LICENSE_STATUS,
  PAYMENT_METHOD,
  PURCHASE_TIME, purchaseTimeAndGradeMapping,
} from '../../../../lead/constants/lead';
import { BrandTypes } from '../../../../lead/create/vehicle-select/vehicle-select.component';
import {
  CurrentUserService,
  ModalMessageService,
  PromptBarService,
  SpinnerService,
} from '@otr/website-common';
import { splitServiceMore } from '../../../shared/service-more.util';
import { USER_ROLE } from '../../../../_common/constants/user-role.constant';
import { CustomerService } from '../services/customer.service';
import { getBudgetText } from '../../../as-lead/utils';

const phoneValidators = Validators.compose([
  Validators.pattern('(\\d)*(-)?(\\d)*'),
  Validators.maxLength(20),
]);

const getDefaultDuplicatedFields = () => ({
  firstName: false,
  lastName: false,
  mobile: false,
  homePhone: false,
  workPhone: false,
});

@Component({
  selector: 'app-edit-lead',
  templateUrl: './edit-lead.component.html',
  styleUrls: ['./edit-lead.component.scss'],
})
export class EditLeadComponent implements SalesLeadModalContent, OnInit {
  @Input() isLeadEditable: boolean = false;
  @Input() initialLeadId: string;
  @Input() selectedPerson: any;
  @Input() sharingLeadId: string = null;
  @Input() isLeadSelected: boolean = false;
  @Input() shouldCreatedLeadForRE: boolean = false;
  @Input() isReservation: boolean = false;
  @Input() defaultPurpose: String;
  @Input() isUsedCar: boolean;

  editingLead: Lead = null;
  canEditLeadSource: boolean = true;
  canEditLeadType: boolean = true;
  isEditModeForLead: boolean = false;
  originPersonKeyInfo: any;
  isCharacterActive: boolean = false;
  usedCarVin: string;
  usedCarDealerOffer: string;
  usedCarRegDate: string;
  usedCarMileage: string;
  usedCarBid: string;
  usedCarDate: any = {};
  hideIntentionCar: boolean = false;
  duplicateFields: any = {};
  brandTypes: any = BrandTypes;
  gradeOptions: any = GRADE;
  certificateTypeOptions: any = CERTIFICATE_TYPE;
  preferredContactTimeOptions: any = PREFERRED_CONTACT_TIME.values;
  preferredContactChannelOptions: any = PREFERRED_CONTACT_CHANNEL.values;
  familyMemberCountOptions: any = FAMILY_MEMBER_COUNT.values;
  licenseStatusOptions: any = LICENSE_STATUS.values;
  selectorStyle: any = { width: '160px' };
  agreeFollowUpOption: any = BOOLEAN_OPTIONS.values;
  purchaseTimeOptions: any = PURCHASE_TIME.values;
  additionalPurchaseOptions: any = ADDITIONAL_PURCHASE.values;
  purchasePurposeOptions$: BehaviorSubject<any> = new BehaviorSubject([]);
  colorOption$: BehaviorSubject<any> = new BehaviorSubject([]);
  optionalPreferenceOption$: BehaviorSubject<any> = new BehaviorSubject([]);
  drivingPreferenceOptions$: BehaviorSubject<any> = new BehaviorSubject([]);
  charging_pile_or_parking_space: FormControl = new FormControl(null);
  kilometers_per_day: FormControl = new FormControl(null);

  paymentMethodOptions: any = PAYMENT_METHOD.values;
  minDate: Date = new Date();
  showError: boolean = false;
  maxBirthdayDate: Date = this.minDate;
  maxBirthdayYear: number = this.maxBirthdayDate.getFullYear();
  tags: Array<any> = [];
  isSyncFeatureTags: boolean = true;
  ageRangeOption: any = AGE_RANGE_OPTIONS.values;

  kilometerPreDayOptions: any = {
    LESS_THAN_TEN: { value: 'LESS_THAN_TEN', text: '小于10公里' },
    BETWEEN_TEN_AND_TWENTY: { value: 'BETWEEN_TEN_AND_TWENTY', text: '10-20公里' },
    BETWEEN_TWENTY_AND_THIRTY: { value: 'BETWEEN_TWENTY_AND_THIRTY', text: '20-30公里' },
    GREATER_THAN_THIRTY: { value: 'GREATER_THAN_THIRTY', text: '大于30公里' },
  };
  chargingPileOrParkingSpaceOptions: any = {
    TRUE: { value: true, text: '有' },
    FALSE: { value: false, text: '无' },
  };

  expandCollapseText = {
    COLLAPSED: '补充更多信息',
    EXPANDED: '收起更多信息',
  };
  // tslint:disable-next-line:prefer-template
  format = value => (value.toFixed(2) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');

  showroomVisitForm: FormGroup = new FormGroup({
    is_appointed: new FormControl(this.isReservation, Validators.required),
    purpose: new FormControl(this.defaultPurpose || '看车比价', Validators.required),
    visitor: new FormControl('1', Validators.required),
    isFirstVisit: new FormControl(true, Validators.required),
    comments: new FormControl('', Validators.maxLength(500)),
    character: new FormControl('', Validators.maxLength(15)),
  });

  certificateType: FormControl = new FormControl('');
  province: FormControl = new FormControl('');
  city: FormControl = new FormControl('');
  district: FormControl = new FormControl('');

  regionForm: FormGroup = new FormGroup({
    province: this.province,
    city: this.city,
    district: this.district,
  });
  // isAgreeFollow: FormControl = new FormControl('', Validators.required);
  dealer: FormControl = new FormControl('');
  additionalPurchase: FormControl = new FormControl(null);
  purchaseTime: FormControl = new FormControl('six months');
  paymentMethod: FormControl = new FormControl(null);
  currentVehicle: FormGroup = new FormGroup({
    brand: new FormControl(null),
    class: new FormControl(null),
    model: new FormControl(null),
    current_vehicle_age: new FormControl(null),
    current_vehicle_evaluation: new FormControl(null),
  });
  interestVehicles: FormArray = new FormArray([]);
  comparingVehicles: FormArray = new FormArray([
    new FormGroup({
      brand: new FormControl(null),
      class: new FormControl(null),
      model: new FormControl(null),
    }),
  ]);
  person: FormGroup = new FormGroup({
    id: new FormControl(''),
    dealer_id: this.dealer,
    mobile: new FormControl('',
      Validators.compose([Validators.required, Validators.pattern('1(\\d){10}')]),
    ),
    home_phone: new FormControl('', phoneValidators),
    work_phone: new FormControl('', phoneValidators),
    first_name: new FormControl('', Validators.maxLength(25)),
    last_name: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(15)])),
    full_name: new FormControl(''),
    gender: new FormControl('Male'),
    version: new FormControl(),
    province: this.province,
    city: this.city,
    district: this.district,
    address: new FormControl(''),
    address2: new FormControl(''),
    certificate_type: this.certificateType,
    certificate_number: new FormControl('', this.certificateNumberValidator()),
    postcode: new FormControl('', Validators.pattern('^(\\d{6})$')),
    charging_pile_or_parking_space: this.charging_pile_or_parking_space,
    kilometers_per_day: this.kilometers_per_day,
    birthday: new FormControl(dateParse(new Date(), 'yyyy-MM-dd')),
    preferredContactTime: new FormControl(''),
    preferredContactChannel: new FormControl([]),
    familyMemberCount: new FormControl(''),
    customerHobbies: new FormControl([]),
    occupation: new FormControl(''),
    current_vehicle: this.currentVehicle,
    // isAgreeFollowUp: this.isAgreeFollow,
    age: new FormControl(''),
  });
  drivingPreference: FormControl = new FormControl(null);
  purchasePurpose: FormControl = new FormControl(null);
  grade: FormControl = new FormControl('D', Validators.required);
  role: FormControl = new FormControl({ value: 'purchaser', disabled: true });
  channel: FormControl = new FormControl('Inbound', Validators.required);
  leadSource: FormControl = new FormControl('销售热线', Validators.required);
  leadType: FormControl = new FormControl('经销商销售电话', Validators.required);
  leadComments: FormControl = new FormControl('', Validators.maxLength(500));
  referralId: FormControl = new FormControl(null, control =>
    this.channel.value === CHANNELS.REFERRAL.value && !control.value ? { referralId: false } : null,
  );
  nextFollowUpDate: FormControl = new FormControl(dateParse(new Date(), 'yyyy-MM-dd'), [
    Validators.required,
    control => (getDateAtZero(new Date(control.value)) < getDateAtZero(new Date()) ? { nextFollowUpDate: '' } : null),
  ]);
  serviceMoreOptions: any = LEAD_SERVICE.values;
  serviceMore: FormControl = new FormControl('');
  licenseStatus: FormControl = new FormControl(null);
  leadForm: FormGroup = new FormGroup({
    person: this.person,
    dealer_id: this.dealer,
    grade: this.grade,
    role: this.role,
    channel: this.channel,
    lead_source: this.leadSource,
    lead_type: this.leadType,
    comments: this.leadComments,
    referral_id: this.referralId,
    nextFollowUpDate: this.nextFollowUpDate,
    interested_vehicles: this.interestVehicles,
    comparing_vehicles: this.comparingVehicles,
    serviceMore: this.serviceMore,
    // licenseStatus: this.licenseStatus,
    budget: new FormControl([10, 50]),
    license_status_multiple: this.licenseStatus,
    estimated_purchase_time: new FormControl('six months'),
    payment_method: this.paymentMethod,
    additional_purchase_or_replacement: this.additionalPurchase,
    driving_preference: this.drivingPreference,
    purchase_purpose: this.purchasePurpose,
  });

  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;
  invalidStyle = { 'border-color': '#F00' };

  doLeadMove: boolean = false;
  doLeadMerge: boolean = false;
  isKeywordLengthValidated: boolean = false;
  sourceLeadId: string;
  referralOption: Array<any>;
  onSearchReferralDebounce = _.debounce(this.onSearchReferral, 300);

  getReferralOptionHtml: Function = ({ full_name, mobile }) =>
    `<span class='text left'>${full_name}</span>
     <span class='text right'>${mobile}</span>`;

  getCertificateTypeValue = option => option.value;
  getCertificateTypeText = option => {
    return !option.value ? '空' : _.get(option, 'text');
  };
  getCertificateTypeDisplayText = option => !option.value ? '请输入' : _.get(option, 'text');
  getCPOrPSValue = option => option.value;
  getCPOrPSText = option => option.text;

  getKilometerPreDayValue = option => option.value;
  getKilometerPreDayText = option => option.text;

  getReferralValue = option => option.id;
  getReferralText = option => option.full_name + option.mobile;
  getAccurateSearchText = option => option.mobile;
  getReferralDisplayText = option => option.full_name;
  getReferralData = option => _.pick(option, 'full_name', 'mobile');
  getLeadSource: any = option => _.get(option, 'label_cn');
  getLeadChannelValue: any = option => _.get(option, 'value');
  getLeadChannelText: any = option => _.get(option, 'text');
  getDealerText: any = option => _.get(option, 'name');
  getDealerValue: any = option => _.get(option, 'dealerId');
  getEnglishValue: any = option => _.get(option, 'labelEn');
  getChineseText: any = option => _.get(option, 'labelCn');

  leadSources$: BehaviorSubject<any> = new BehaviorSubject([]);
  leadTypes$: BehaviorSubject<any> = new BehaviorSubject([{indexOrder: 0, label_cn: '经销商销售电话', label_en: 'Dealer Sales Call'},
    {indexOrder: 1, label_cn: '114热线', label_en: '114 Hotline'}]);
  leadChannels$: BehaviorSubject<any> = new BehaviorSubject([]);
  dealer$: BehaviorSubject<any> = new BehaviorSubject([]);
  occupationOptions$: BehaviorSubject<any> = new BehaviorSubject([]);
  isInvalid$: Subject<any> = new BehaviorSubject(true);
  hasSelectDuplicateLeadOrPerson$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    protected leadService: LeadService,
    protected formUtilService: FormUtilService,
    private scScheduleService: ScScheduleService,
    private showroomVisitService: ShowroomVisitService,
    private testDriveService: TestDriveService,
    private promptBarService: PromptBarService,
    private modalMessageService: ModalMessageService,
    protected currentUserService: CurrentUserService,
    protected spinnerService: SpinnerService,
    private customerService: CustomerService,
  ) {
  }

  ngOnInit() {
    this.loadLeadSources();
    this.loadLeadChannelOptions();
    this.loadOccupationOptions();
    this.loadDealerList();
    this.setDealerValidator();
    this.getListData();
    this.getLoadFormData();
    this.initForm();
    this.initSubscribe();
  }

  loadOccupationOptions = () => this.leadService.loadOccupationOption().subscribe(options => this.occupationOptions$.next(options));

  getListData() {
    this.leadService.getOptions()
      .subscribe(options => {
        this.purchasePurposeOptions$.next(options.leadCommonDictMap.purchase_purpose);
        this.drivingPreferenceOptions$.next(options.leadCommonDictMap.driving_preference);
      });
  }

  loadLeadSources() {
    this.leadService
      .fetchLeadSources(this.initialLeadId ? LEAD_SOURCE_FILTER_TYPE.ALL : LEAD_SOURCE_FILTER_TYPE.OTR)
      .subscribe(leadSources => this.leadSources$.next(leadSources));
  }

  loadLeadChannelOptions() {
    this.leadChannels$.next(this.leadChannelsOption);
  }

  get leadChannelsOption() {
    const existingChannel = _.get(this.editingLead, 'channel', '');
    if (_.isEmpty(existingChannel)) {
      return CDO_LEAD_CHANNELS_OPTIONS;
    }
    return loadLeadChannelsOptionByCurrentChannel(existingChannel);
  }

  get isChannelEditable() {
    const existingChannel = _.get(this.editingLead, 'channel', '');
    return (
      this.isLeadEditable &&
      (_.isEmpty(existingChannel) || _.includes(_.map(CDO_LEAD_CHANNELS_OPTIONS, 'value'), existingChannel))
    );
  }

  initForm() {
    this.leadSource.valueChanges
      .subscribe(value => {
        this.leadTypes$.next(_.get(_.find(this.leadSources$.getValue(), { label_cn: value }), 'lead_type', []));
        if (this.canEditLeadType) {
          this.leadTypes$.next(_.reject(this.leadTypes$.getValue(), { label_en: E_COMMERCE_LEAD_TYPE.type }));
          this.leadType.setValue(_.get(this.leadTypes$.getValue()[0], 'label_cn', ''));
        }
      });
    this.channel.valueChanges
      .subscribe(value => {
        this.channel.setErrors(null);
        if (this.canEditLeadSource && this.isChannelEditable && _.isEqual(value, 'Repurchase')) {
          this.leadSource.setValue('客户关系维护(客户生命周期相关)');
          this.leadType.setValue('当前客户');
        }
        if (this.canEditLeadSource && this.isChannelEditable && _.isEqual(value, 'Inbound')) {
          this.leadForm.controls.lead_source.setValue('销售热线');
          this.leadForm.controls.lead_type.setValue('经销商销售电话');
        }
        if (this.canEditLeadSource && this.isChannelEditable && _.isEqual(value, 'Web')) {
          this.leadForm.controls.lead_source.setValue('其他汽车类网站');
          this.leadForm.controls.lead_type.setValue('汽车之家');
        }
      });
    this.grade.valueChanges
      .subscribe(grade => {
        this.leadForm.controls.estimated_purchase_time.setValue(gradeAndPurchaseTimeMapping[grade]);
      });
    this.resetDuplicatedFields();
  }

  resetDuplicatedFields() {
    this.duplicateFields = getDefaultDuplicatedFields();
  }

  loadFormData() {
    if (this.initialLeadId) {
      this.leadService.getLead(this.initialLeadId).pipe(
        (this.spinnerService.loading()))
        .subscribe(lead => {
          this.importExistedLead(lead);
          this.importPersonData(lead.person);
          this.referralOption = lead.referral_option;
          this.isLeadEditable = lead.status !== 'Successful';
        });
    } else if (this.selectedPerson) {
      this.resetInterestedVehicles(null);
      this.importPersonData(this.selectedPerson, false);
    }
  }

  loadUsedCarFormData() {
    if (this.initialLeadId) {
      this.leadService.getUsedCarLead(this.initialLeadId).pipe(
        (this.spinnerService.loading()))
        .subscribe(lead => {
          this.usedCarDate.vin = _.get(lead, 'interested_vehicles[0].vin');
          this.usedCarDate.dealer_offer = _.get(lead, 'interested_vehicles[0].dealer_offer');
          this.usedCarDate.registration_date = _.get(lead, 'interested_vehicles[0].registration_date');
          this.usedCarDate.current_mileage = _.get(lead, 'interested_vehicles[0].current_mileage');
          this.usedCarDate.customer_bid = _.get(lead, 'interested_vehicles[0].customer_bid', '');
          this.usedCarVin = _.get(lead, 'interested_vehicles[0].vin', '-');
          this.usedCarDealerOffer = this.getUsedCarDealerOffer(lead);
          this.usedCarRegDate = _.get(lead, 'interested_vehicles[0].registration_date', '-');
          this.usedCarMileage = _.get(lead, 'interested_vehicles[0].current_mileage', '-');
          this.hideIntentionCar = this.getUsedCarVin(_.get(lead, 'interested_vehicles[0].vin'));
          this.importExistedLead(lead);
          this.importPersonData(lead.person);
          this.referralOption = lead.referral_option;
          this.isLeadEditable = lead.status !== 'Successful';
        });
    } else if (this.selectedPerson) {
      this.resetInterestedVehicles(null);
      this.importPersonData(this.selectedPerson, false);
    }
  }

  getUsedCarDealerOffer(lead: any) {
    if (_.get(lead, 'interested_vehicles[0].dealer_offer')) {
      return `¥ ${this.format(_.get(lead, 'interested_vehicles[0].dealer_offer'))}`;
    }
    return '-';
  }

  loadDealerList() {
    const { dealerId, cecId } = this.currentUserService.getCurrentUser();
    this.leadService.fetchCecDealers(dealerId, cecId, this.currentUserService.userGroupName, false)
      .subscribe(dealer =>
        this.dealer$.next(_.reject(dealer.dealerList, dealerGroupInfo => this.isSharingLead && dealerGroupInfo.dealerId === dealerId)));
  }

  get isSharingLead() {
    return !_.isEmpty(this.sharingLeadId);
  }

  setDealerValidator() {
    if (this.currentUserService.isRoleIn([USER_ROLE.CDM, USER_ROLE.SCDO])) {
      this.dealer.setValidators(Validators.required);
    }
  }

  // tslint:disable-next-line
  importExistedLead(lead) {
    if (_.isEmpty(lead)) {
      return;
    }
    this.editingLead = lead;
    this.isEditModeForLead = true;
    this.grade.setValue(lead.grade || 'D');
    this.role.setValue(lead.role);
    this.channel.setValue(lead.channel);
    this.referralId.setValue(lead.referral_id || null);
    this.leadSource.setValue(this.isSharingLead ? '其他来源' : (lead.lead_source || ''));
    this.leadType.setValue(this.isSharingLead ? '第三方数据' : lead.lead_type);
    this.leadComments.setValue(lead.comments);
    this.setNextFollowUpDate(lead);
    this.canEditLeadType = this.isLeadEditable && (_.isEmpty(lead.lead_source) || _.isEmpty(lead.lead_type));
    this.canEditLeadSource = !this.isSharingLead && this.isLeadEditable && _.isEmpty(lead.lead_source);
    if (this.canEditLeadSource) {
      this.leadSource.enable();
    } else {
      this.leadSource.disable();
    }
    if (this.canEditLeadType) {
      this.leadType.enable();
    } else {
      this.leadType.disable();
    }
    this.resetInterestedVehicles(lead.interested_vehicles);
    this.resetComparingVehicles(lead.comparing_vehicles);
    this.serviceMore.setValue(splitServiceMore(lead.serviceMore));
    this.licenseStatus.setValue(splitServiceMore(_.get(lead, 'license_status_multiple')) || lead.licenseStatus);
    this.leadForm.patchValue({
      estimated_purchase_time: gradeAndPurchaseTimeMapping[lead.grade] || 'six months',
      purchase_purpose: _.get(lead, 'purchase_purpose'),
      payment_method: _.get(lead, 'payment_method'),
      additional_purchase_or_replacement: _.get(lead, 'additional_purchase_or_replacement'),
      driving_preference: _.get(lead, 'driving_preference'),
      tags: _.get(lead, 'feature_tags') || lead.tags || [],
    });
    const feature_tags = _.get(lead, 'person.feature_tags', '');
    const is_sync_feature_tags = _.get(lead, 'person.is_sync_feature_tags');
    if (feature_tags && is_sync_feature_tags) {
      this.isSyncFeatureTags = true;
      this.tags = _.split(feature_tags, ',');
    } else {
      this.customerService.getIsSyncFeatureTags(lead.customer_id)
        .subscribe(
          response => {
            this.isSyncFeatureTags = true;
            if (response.feature_tags !== '' && !_.isNil(response.feature_tags)) {
              this.tags = response.feature_tags.split(',');
            }
          },
          error => {
            this.isSyncFeatureTags = false;
            this.modalMessageService.putMessage({ message: '数据请求错误，请稍后再试' });
          });
    }
    if (_.isNumber(lead.budgetFrom) && _.isNumber(lead.budgetTo)) {
      this.leadForm.patchValue({ budget: [lead.budgetFrom, lead.budgetTo] });
    }
  }

  importPersonData(person, isEdit = true) {
    this.originPersonKeyInfo = this.getPersonKeyInfo();
    this.dealer.setValue(this.getInitialDealerId(person));
    this.province.setValue(person.province);
    this.city.setValue(person.city);
    this.district.setValue(person.district);
    this.person.patchValue({
      id: this.isSharingLead ? null : person.id || person.customerId,
      first_name: person.first_name,
      last_name: person.last_name,
      gender: person.gender || 'Male',
      mobile: person.mobile || null,
      home_phone: person.home_phone,
      work_phone: person.work_phone,
      preferredContactChannel: preferredContactChannelUtils.buildOptionsFromValue(person.preferredContactChannel),
      preferredContactTime: person.preferredContactTime,
      occupation: person.occupation,
      familyMemberCount: person.familyMemberCount,
      customerHobbies: _.map(person.customerHobbies, 'subType'),
      address: person.address,
      address2: person.address2,
      certificate_type: person.certificate_type,
      certificate_number: person.certificate_number,
      postcode: person.postcode,
      charging_pile_or_parking_space: person.charging_pile_or_parking_space,
      kilometers_per_day: person.kilometers_per_day,
      birthday: person.birthday,
      current_vehicle_evaluation: person.current_vehicle_evaluation,
      age: this.customerService.getCustomerAge(person.certificate_type,
        person.certificate_number, person.birthday, person.age),
      is_sync_feature_tags: person.is_sync_feature_tags,
      // isAgreeFollowUp: person.isAgreeFollowUp,
    });
    if (isEdit) {
      this.person.controls.version.setValue(person.version);
    }
    this.importCurrentVehicle(person);
  }

  getInitialDealerId(person) {
    return this.isSharingLead ? null : !_.isNil(person.dealer_id) ? person.dealer_id : this.currentUserService.getCurrentUser().dealerId;
  }

  importCurrentVehicle(person) {
    const firstCurrentVehicle = _.get(person, 'current_vehicles[0]') || _.get(person, 'current_vehicle');
    const currentVehicleWithoutOrder = _.has(firstCurrentVehicle, 'order_info') ? {} : firstCurrentVehicle;
    return _.isEmpty(currentVehicleWithoutOrder) ?
      this.currentVehicle.reset() :
      this.currentVehicle.patchValue({
        brand: getFormattedVehicleFieldForLoadForm(currentVehicleWithoutOrder, 'brand'),
        class: getFormattedVehicleFieldForLoadForm(currentVehicleWithoutOrder, 'class'),
        model: getFormattedVehicleFieldForLoadForm(currentVehicleWithoutOrder, 'model'),
        current_vehicle_age: _.get(currentVehicleWithoutOrder, 'current_vehicle_age'),
        current_vehicle_evaluation: _.get(currentVehicleWithoutOrder, 'current_vehicle_evaluation'),
      });
  }

  mergeResetInterestedVehicles(vehicles) {
    if (_.isEmpty(vehicles)) {
      this.setInterestVehicles(null);
    } else {
      vehicles.forEach(vehicle => this.setInterestVehicles(vehicle, true));
      const interestVehiclesValues = this.interestVehicles.value.filter((val, index) => index < 3);
      _.rangeRight(0, this.interestVehicles.length).forEach(index => this.interestVehicles.removeAt(index));
      interestVehiclesValues.forEach((vehicle) => this.setInterestVehicles(vehicle));
    }
  }

  setInterestVehicles = (vehicle, merge?) => {
    if (merge) {
      this.interestVehicles.insert(0, new FormGroup({
        brand: new FormControl(_.get(vehicle, 'brand', null)),
        class: new FormControl(_.get(vehicle, 'class', null)),
        model: new FormControl(_.get(vehicle, 'model', null)),
        variant: new FormControl(_.get(vehicle, 'variant', null)),
        baumusterNst: new FormControl(_.get(vehicle, 'baumusterNst', null)),
        interest_vehicle_colour: new FormControl(_.get(vehicle, 'interest_vehicle_colour', null)),
        interest_vehicle_optional_preference: new FormControl(_.get(vehicle, 'interest_vehicle_optional_preference', null)),
      }));
      return false;
    }
    this.interestVehicles.push(new FormGroup({
      brand: new FormControl(_.get(vehicle, 'brand', null)),
      class: new FormControl(_.get(vehicle, 'class', null)),
      model: new FormControl(_.get(vehicle, 'model', null)),
      variant: new FormControl(_.get(vehicle, 'variant', null)),
      baumusterNst: new FormControl(_.get(vehicle, 'baumusterNst', null)),
      interest_vehicle_colour: new FormControl(_.get(vehicle, 'interest_vehicle_colour', null)),
      interest_vehicle_optional_preference: new FormControl(_.get(vehicle, 'interest_vehicle_optional_preference', null)),
    }));
  }

  resetInterestedVehicles(vehicles?, merge?) {
    if (merge) {
      this.mergeResetInterestedVehicles(vehicles);
      return false;
    }
    _.rangeRight(0, this.interestVehicles.length).forEach(index => this.interestVehicles.removeAt(index));
    if (_.isEmpty(vehicles)) {
      this.setInterestVehicles(null);
    } else {
      vehicles.forEach((vehicle) => this.setInterestVehicles(vehicle));
    }
  }

  resetComparingVehicles(vehicles?) {
    _.rangeRight(0, this.comparingVehicles.length)
      .forEach(index => this.comparingVehicles.removeAt(index));
    if (_.isEmpty(vehicles)) {
      this.comparingVehicles.push(new FormGroup({
        brand: new FormControl(null),
        class: new FormControl(null),
        model: new FormControl(null),
      }));
    } else {
      vehicles.forEach(vehicle => {
        this.comparingVehicles.push(new FormGroup({
          brand: new FormControl(_.get(vehicle, 'brand', null)),
          class: new FormControl(_.get(vehicle, 'class', null)),
          model: new FormControl(_.get(vehicle, 'model', null)),
        }));
      });
    }
  }

  setAge() {
    const age = this.customerService.getCustomerAge(this.person.controls.certificate_type.value,
      this.person.controls.certificate_number.value, this.person.controls.birthday.value, this.person.controls.age.value);
    this.person.controls.age.setValue(age);
  }

  initSubscribe() {
    this.leadForm.valueChanges.pipe(combineLatest(this.interestVehicles.valueChanges)).subscribe(() => {
      this.isInvalid$.next(this.leadForm.invalid || this.isInterestVehiclesInValid());
    });
    this.channel.valueChanges.subscribe(value => {
      if (value !== CHANNELS.REFERRAL.value) {
        this.referralId.setValue(null);
      }
      this.referralId.updateValueAndValidity();
    });
    this.certificateType.valueChanges.subscribe((value) => {
      this.person.controls.certificate_number.setValue('');
      if (value) {
        this.person.controls.certificate_number.setValidators([Validators.required, this.certificateNumberValidator()]);
      } else {
        this.person.controls.certificate_number.setValidators(this.certificateNumberValidator());
      }
      this.person.controls.certificate_number.updateValueAndValidity();
      if (this.person.controls.certificate_type.value !== 'IDENTITY_CARD') {
        this.setAge();
      }
    });
    // calculate age according to id and birth
    this.person.controls.certificate_number.valueChanges.subscribe(() => {
      this.setAge();
    });
    this.person.controls.birthday.valueChanges.subscribe(() => {
      this.setAge();
    });
    this.formUtilService.markAsTouched$.subscribe(value => this.handleFormMarkTouched(value, this.leadForm));
    this.dealer.valueChanges.subscribe(value => {
      if (!this.isEditModeForLead) {
        if (!_.isEqual(_.get(this.selectedPerson, 'dealer_id'), value)) {
          this.person.controls.id.setValue(null);
          this.hasSelectDuplicateLeadOrPerson$.next(false);
        }
      }
      this.leadForm.controls.person.updateValueAndValidity();
    });
  }

  certificateNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const validator = _.get(CERTIFICATE_NUMBER_VALIDATOR, this.certificateType.value);
      const isValid = isEmptyValue(control.value) || _.isNil(validator) || validator(control.value);
      return isValid ? null : { certificate_number: false };
    };
  }

  setNextFollowUpDate(lead) {
    const nextFollowUpDate = this.isSharingLead && new Date() || lead.nextFollowUpDate || new Date();
    this.nextFollowUpDate.setValue(dateParse(nextFollowUpDate, 'yyyy-MM-dd'));
  }

  handleFormMarkTouched(isTouched: boolean, form: FormGroup) {
    isTouched ? this.formUtilService.markFormAsTouched(form) : this.formUtilService.markFormAsUnTouched(form);
  }

  prepareLeadData(formModel): LeadAdditionItem {
    const leadModel: Lead = {
      ..._.pick(formModel, [
        'grade', 'role', 'lead_source', 'lead_type', 'owner_sales_consultant_id',
        'channel', 'comments', 'budgetFrom', 'budgetTo', 'licenseStatus', 'license_status_multiple', 'estimated_purchase_time', 'payment_method',
        'additional_purchase_or_replacement', 'purchase_purpose', 'driving_preference',
      ]),
      person: {
        ..._.get(formModel, 'person', {}),
        preferredContactChannel: preferredContactChannelUtils.buildValueFromOptions(_.get(formModel, 'person.preferredContactChannel')),
        feature_tags: this.tags.toString(),
        is_sync_feature_tags: this.isSyncFeatureTags,
        // charging_pile_or_parking_space: _.get(this.editingLead , 'person.charging_pile_or_parking_space', ''),
        // kilometers_per_day: _.get(this.editingLead , 'person.kilometers_per_day', ''),
      },
      serviceMore: _.join(splitServiceMore(formModel.serviceMore), '|'),
      license_status_multiple: _.join(splitServiceMore(formModel.license_status_multiple), '|'),
      interested_vehicles: _.reject(formModel.interested_vehicles, ({ brand }) => _.isEmpty(brand)) as InterestedVehicle[],
      comparing_vehicles: _.reject(formModel.comparing_vehicles, ({ brand }) => _.isEmpty(brand)),
      nextFollowUpDate: (formModel.nextFollowUpDate ? getDateAtZero(new Date(formModel.nextFollowUpDate)) : null),
      referral_id: formModel.referral_id || null,
    };
    const body = this.isEditModeForLead && !this.isSharingLead ? _.assign({}, _.omit(this.editingLead, 'siteSalesConsultantName', 'siteSalesConsultantPhone', 'siteCustomerLink'), leadModel) : deleteEmptyProperty(leadModel);
    const action = this.isEditModeForLead && !this.isSharingLead ? LEAD_ACTION_TYPE.PUT_LEAD : LEAD_ACTION_TYPE.POST_LEAD;
    const id = this.isEditModeForLead && !this.isSharingLead ? this.editingLead._id : '';
    return {
      body,
      action,
      id,
    };
  }

  get isDealerEditable() {
    return this.currentUserService.getCurrentUser().cecId && (!this.isEditModeForLead || this.isSharingLead);
  }

  getFormValue() {
    const leadForCDORequest = this.prepareLeadData(this.formatLeadForm());
    return {
      leadForCDORequest,
      action: (this.isEditModeForLead && !this.isSharingLead) ? LEAD_ACTION_TYPE.PUT_LEAD : LEAD_ACTION_TYPE.POST_LEAD,
      sharingLeadId: this.sharingLeadId,
    };
  }

  shouldGetPerson() {
    const personKeyInfoChanged = !_.isEqual(this.originPersonKeyInfo, this.getPersonKeyInfo());
    return !this.isSharingLead && !this.isLeadSelected
      && personKeyInfoChanged && this.getPhones().length
      && (!this.hasSelectDuplicateLeadOrPerson$.value && !this.shouldCreatedLeadForRE);
  }

  getPhones() {
    return [this.person.value.mobile, this.person.value.homePhone, this.person.value.workPhone]
      .filter(phone => _.trim(phone));
  }

  getPersonKeyInfo() {
    return [
      this.person.value.first_name,
      this.person.value.last_name,
      this.person.value.mobile,
      this.person.value.home_phone,
      this.person.value.work_phone,
      this.person.value.dealer,
    ];
  }

  submit(): Observable<any> {
    if (!this.shouldGetPerson()) {
      return this.handleSubmit();
    }
    return this.getPersons().pipe(switchMap(persons => {
      if (this.hasDuplicatedPersons(persons)) {
        return observableOf({
          showDuplicateLeads: true,
          persons: _.reject(persons, { id: this.leadForm.getRawValue().person.id } as any),
        });
      }
      return this.handleSubmit();
    }));
  }

  getOriginPersonPhones() {
    return [
      _.get(this.editingLead.person, 'mobile'),
      _.get(this.editingLead.person, 'home_phone'),
      _.get(this.editingLead.person, 'work_phone'),
    ]
      .filter(phone => _.trim(phone));
  }

  getPersons(originPerson = false) {
    if (this.isUsedCar) {
      return this.leadService.getUsedCarPersons(this.getPhones(), this.leadForm.getRawValue().person.id,
        this.leadForm.getRawValue().person.dealer_id);
    }
    return this.leadService.getPersons(
      originPerson ? this.getOriginPersonPhones() : this.getPhones(),
      this.leadForm.getRawValue().person.id,
      this.leadForm.getRawValue().person.dealer_id);
  }

  hasDuplicatedPersons(persons) {
    const currentPerson = this.leadForm.getRawValue().person;
    return !_.isEmpty(_.reject(persons, person => person.id === currentPerson.id));
  }

  getSubmitHandlerConfig = () => ({
    LEAD_MOVE: {
      condition: this.doLeadMove,
      handler: () => this.getIsUsedCarMove(),
    },
    LEAD_MERGE: {
      condition: this.doLeadMerge,
      handler: () => this.getIsUsedCarMerge(this.sourceLeadId, this.editingLead._id, this.formatLeadForm(),
        this.tags, this.isSyncFeatureTags),
    },
    LEAD_EDIT: {
      condition: true,
      handler: () => this.getIsUsedCar(this.getFormValue()),
    },
  });

  getIsUsedCarMove() {
    if (this.isUsedCar) {
      return this.leadService.usedCarLeadMove(this.editingLead._id, this.formatLeadForm(), this.tags,
        this.isSyncFeatureTags, this.usedCarDate, this.usedCarVin, this.usedCarRegDate, this.usedCarMileage);
    }
    return this.leadService.cdoLeadMove(this.editingLead._id, this.formatLeadForm(), this.tags,
      this.isSyncFeatureTags);
  }

  getIsUsedCarMerge(sourceLeadId, editingLeadId, leadForm, tags, featureTags) {
    if (this.isUsedCar) {
      return this.leadService.usedCarLeadMerge(sourceLeadId, editingLeadId, leadForm, tags, featureTags,
        this.usedCarDate, this.usedCarVin, this.usedCarRegDate, this.usedCarMileage);
    }
    return this.leadService.cdoLeadMerge(sourceLeadId, editingLeadId, leadForm, tags, featureTags);
  }

  getIsUsedCar(item) {
    if (this.isUsedCar) {
      return this.leadService.usedCarLeadCreate(item, this.usedCarDate);
    }
    return this.leadService.cdoLeadCreate(item);
  }

  handleSubmit() {
    const handler = _.find(_.values(this.getSubmitHandlerConfig()), 'condition').handler;
    return handler()
      .pipe(this.spinnerService.loading(),
        catchError(this.handleSubmitError.bind(this)));
  }

  handleSubmitError(error) {
    const errorResponse = _.get(error, 'error', error);
    if (_.includes(DUPLICATE_CUSTOMER_ERROR_CODES, errorResponse.error_code)) {
      this.handleDuplicateFields(errorResponse.duplicated_fields);
    }
    if (errorResponse.error_code === 'channel_mismatch') {
      this.channel.setErrors({ invalid: true });
    }
    if (errorResponse.error_code === 'used_car_lead_can_not_merge_with_sales_lead') {
      return this.modalMessageService.putMessage({ message: '存在销售线索无法合并，请重新编辑' });
    }
    if (errorResponse.error_code === 'sales_lead_can_not_merge_with_used_car_lead') {
      return this.modalMessageService.putMessage({ message: '存在二手车线索无法合并，请重新编辑' });
    }
    this.modalMessageService.putMessage(errorResponse);
  }

  handleDuplicateFields(duplicatedPhones) {
    const duplicateFields: any = {};

    if (duplicatedPhones.length) {
      duplicateFields.firstName = true;
      duplicateFields.lastName = true;
      ['mobile', 'homePhone', 'workPhone'].forEach(field => {
        duplicateFields[field] = _.includes(duplicatedPhones, this[field].value);
      });
    }

    this.duplicateFields = duplicateFields;
  }

  private formatLeadForm() {
    const rawValue = this.leadForm.getRawValue();
    return {
      ...rawValue,
      nextFollowUpDate: rawValue.nextFollowUpDate ? getDateAtZero(new Date(rawValue.nextFollowUpDate)) : null,
      person: {
        ...rawValue.person,
        birthday: dateParse(rawValue.person.birthday, 'yyyy-MM-dd'),
        customerHobbies: buildCustomerHobbiesToTypeMapping(rawValue.person.customerHobbies),
      },
      budget: null,
      budgetFrom: rawValue.budget[0],
      budgetTo: rawValue.budget[1],
      estimated_purchase_time: rawValue.estimated_purchase_time,
    };
  }

  getSelectedCertificateType() {
    return _.find(this.certificateTypeOptions, { value: this.certificateType.value });
  }

  getSelectedPreferredContactTime() {
    return _.find(this.preferredContactTimeOptions, { value: this.person.value.preferredContactTime });
  }

  getSelectedCPOrPSValue() {
    return _.find(this.chargingPileOrParkingSpaceOptions, { value: this.charging_pile_or_parking_space.value });
  }

  getSelectedKilometerPreDay() {
    return _.find(this.kilometerPreDayOptions, { value: this.kilometers_per_day.value });
  }

  getSelectedFamilyMemberCount() {
    return _.find(this.familyMemberCountOptions, { value: this.person.value.familyMemberCount });
  }

  getSelectedReferral() {
    return this.referralId.value;
  }

  getDropDownControlError(formControl: FormControl) {
    return formControl.invalid && formControl.touched;
  }

  isInterestVehiclesInValid() {
    if (this.isUsedCar) {
      return false;
    }
    return _.every(this.interestVehicles.getRawValue(), vehicle => _.isEmpty(vehicle.brand));
  }

  onBudgetChange(value: [number, number]) {
    this.leadForm.patchValue({ budget: value });
  }

  onCustomerHobbiesChange(value: string[]) {
    this.leadForm.patchValue({ person: { customerHobbies: value } });
  }

  onServiceMoreChange(selectValue) {
    this.serviceMore.setValue(selectValue);
  }

  getSelectedChannel(): KeyValue {
    const text = _.chain(this.leadChannelsOption)
      .find(_.pick(this.channel, 'value'))
      .get('text', '')
      .value();
    return {
      value: this.channel.value,
      text,
    };
  }

  onRemoveInterestVehicle = (index, i) => {
    this.interestVehicles.removeAt(index);
    if (i === 0) {
      this.hideIntentionCar = false;
      this.usedCarDate = [];
    }
  };

  onAddInterestVehicle = () => {
    this.interestVehicles.push(new FormGroup({
      brand: new FormControl(null),
      class: new FormControl(null),
      model: new FormControl(null),
      variant: new FormControl(null),
      baumusterNst: new FormControl(null),
      interest_vehicle_colour: new FormControl(null),
      interest_vehicle_optional_preference: new FormControl(null),
    }));
  };

  onRemoveComparingVehicles = index => this.comparingVehicles.removeAt(index);

  onAddComparingVehicles = () => {
    this.comparingVehicles.push(new FormGroup({
      brand: new FormControl(null),
      class: new FormControl(null),
      model: new FormControl(null),
    }));
  };

  onSearchReferral(keyword: string) {
    this.isKeywordLengthValidated = keyword.length === 11;
    if (this.isKeywordLengthValidated) {
      this.leadService.getReferrals(keyword).subscribe(item => {
        this.referralOption = item;
      });
    }
  }

  getText() {
    return getBudgetText(this.leadForm.value.budget[0], this.leadForm.value.budget[1]);
  }

  onLicenseStatusChange(selectValue) {
    this.leadForm.controls.license_status_multiple.setValue(selectValue);
  }

  setCharacterStatus() {
    this.isCharacterActive = !this.isCharacterActive;
  }

  onTagChange(tag) {
    this.tags = tag;
  }

  onAgreeFollowUpSelect(isAgreeFollowUp) {
    this.person.controls.isAgreeFollowUp.setValue(isAgreeFollowUp);
  }

  onAgeSelect(age) {
    this.person.controls.age.setValue(age);
  }

  changeGrade(val: string) {
    this.grade.setValue(val);
    this.leadForm.controls.estimated_purchase_time.setValue(gradeAndPurchaseTimeMapping[val]);
  }

  changePurchaseTime(val: string) {
    this.leadForm.controls.estimated_purchase_time.setValue(val);
    this.grade.setValue(purchaseTimeAndGradeMapping[val]);
  }

  getLoadFormData() {
    if (this.isUsedCar) {
      return this.loadUsedCarFormData();
    }
    return this.loadFormData();
  }

  getUsedCarVin(vin) {
    return vin && this.isUsedCar;
  }

  getHideIntentionCar(number) {
    return number === 0 && this.hideIntentionCar;
  }

  onKilometersPerDayChange($event, control) {
    const value = $event.target.value.replace(/[^\d]/g, '');
    control.setValue(value);
  }
}

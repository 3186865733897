import { throwError as observableThrowError } from 'rxjs';

import { catchError, tap } from 'rxjs/operators';
import { ReportApi } from '../../_common/api/report';
import { Injectable } from '@angular/core';
import { download } from '../../_common/utils/download';
import { ERROR_MESSAGES } from '../../_common/constants/error-messages';

import { PromptLevel } from '../../_common/constants/common';
import * as pbi from 'powerbi-client';

import { CurrentUserService, PromptBarService } from '@otr/website-common';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable()
export class ReportService {
  pbiService: any;
  report: any;
  reportContainer: HTMLElement;
  reportConfig = {
    type: 'report',
    permissions: pbi.models.Permissions.Copy,
    viewMode: pbi.models.ViewMode.Edit,
    settings: {
      filterPaneEnabled: true,
      navContentPaneEnabled: true,
    },
  };

  partsReportConfig: pbi.IEmbedConfiguration = {
    type: 'report',
    permissions: pbi.models.Permissions.Copy,
    viewMode: pbi.models.ViewMode.Edit,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: true,
    },
  };

  constructor(
    private reportApi: ReportApi,
    private currentUserService: CurrentUserService,
    private promptBarService: PromptBarService) {
    this.pbiService = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
  }

  getReport(type) {
    return this.reportApi.getReport(type);
  }

  downloadReport(type) {
    return this.getReport(type).pipe(
      tap(data => {
        const href = `/api/outlets/reports?token=${data.token}`;
        const fileName = `${type}.csv`;
        download(href, fileName);
      }),
      catchError(error => {
        if (error.status === 404) {
          return observableThrowError(ERROR_MESSAGES.DOCUMENT_NOT_FOUND);
        }
      }));
  }

  getOrderBankReportTaskList() {
    return this.reportApi.getOrderBankReportTaskList();
  }

  getDownloadURL(blobName) {
    return this.reportApi.getDownloadURL(blobName);
  }

  downloadOrderReport(filter) {
    return this.reportApi.getOrderReport(filter);
  }

  downloadSurveyReport(filter) {
    return this.reportApi.getSurveyReport(filter);
  }

  downloadPerformanceReport(filter) {
    return this.reportApi.getPerformanceReport(filter);
  }

  downloadPePerformanceReport(filter) {
    return this.reportApi.getPePerformanceReport(filter);
  }

  downloadScPerformanceReport(filter) {
    return this.reportApi.getScPerformanceReport(filter);
  }

  downloadTestDriveReport(filter) {
    return this.reportApi.getTestDriveReport(filter);
  }

  downloadSoftPhoneReport(filter) {
    return this.reportApi.getSoftPhoneReport(filter);
  }

  fetchReportConfig(type) {
    return this.reportApi.fetchReportConfig(this.currentUserService.getCurrentUser().dealerId, type);
  }

  fetchPartsReportConfig(type) {
    return this.reportApi.fetchPartsReportConfig(type);
  }

  fetchPartsDetailReportConfig(type) {
    return this.reportApi.fetchPartsDetailReportConfig(type);
  }

  fetchAccidentCarReportConfig(type) {
    return this.reportApi.fetchAccidentCarReportConfig(this.currentUserService.getCurrentUser().dealerId, type);
  }

  createReportConfig(reportId: string) {
    return this.reportApi.createReportConfig({
      report_id: reportId,
      dealer_id: this.currentUserService.getCurrentUser().dealerId,
    });
  }

  createAccidentCarReportConfig(reportId: string) {
    const dealerId = this.currentUserService.getCurrentUser().dealerId;
    return this.reportApi.createAccidentCarReportConfig({ report_id: reportId, dealer_id: dealerId });
  }

  initReportContainer(elementId) {
    this.reportContainer = (document.getElementById(elementId) as HTMLElement);
  }

  loadReport(type = 'custom') {
    this.fetchReportConfig(type).subscribe(response => {
      this.report = this.pbiService.embed(this.reportContainer, { ...this.reportConfig, ...response });
      this.registerReportEvent();
    });
  }

  loadPartReport(reportType, isReset = false) {
    this.fetchPartsReportConfig(reportType).subscribe(response => {
      if (isReset) {
        this.pbiService.reset(this.reportContainer);
      }
      this.report = this.pbiService.embed(this.reportContainer, { ...this.partsReportConfig, ...response });
      this.registerPartReportEvent();
    });
  }

  loadPartDetailReport(reportType) {
    this.fetchPartsDetailReportConfig(reportType).subscribe(response => {
      this.report = this.pbiService.embed(this.reportContainer, { ...this.partsReportConfig, ...response });
      this.registerPartReportEvent();
    });
  }

  loadAccidentCarReport(type: string = 'custom') {
    this.fetchAccidentCarReportConfig(type).subscribe(response => {
      this.report = this.pbiService.embed(this.reportContainer, { ...this.reportConfig, ...response });
      this.registerAccidentCarReportEvent();
    });
  }

  registerReportEvent() {
    this.report.off('loaded');
    this.report.on('loaded', () => {
    });

    this.report.on('error', () => {
      this.report.off('error');
    });

    this.report.off('saved');
    this.report.on('saved', event => {
      if (event.detail.saveAs) {
        this.createReportConfig(event.detail.reportObjectId).subscribe(
          response => {
            this.promptBarService.show('保存成功', PromptLevel.Success);
            this.loadReport();
          },
        );
      } else {
        this.promptBarService.show('保存成功', PromptLevel.Success);
      }
    });
  }

  registerAccidentCarReportEvent() {
    this.report.off('loaded');
    this.report.on('loaded', () => {
    });

    this.report.on('error', () => {
      this.report.off('error');
    });

    this.report.off('saved');
    this.report.on('saved', event => {
      if (event.detail.saveAs) {
        this.createAccidentCarReportConfig(event.detail.reportObjectId).subscribe(
          response => {
            this.promptBarService.show('保存成功', PromptLevel.Success);
            this.loadAccidentCarReport();
          },
        );
      } else {
        this.promptBarService.show('保存成功', PromptLevel.Success);
      }
    });
  }

  registerPartReportEvent() {
    this.report.off('loaded');
    this.report.on('loaded', () => {
      this.filterPartReportByTodayDate();
    });
    this.report.on('pageChanged', () => {
      this.filterPartReportByTodayDate();
    });

    this.report.on('error', () => {
      this.report.off('error');
    });
  }

  filterPartReportByTodayDate() {
    this.report.getPages().then(pages => {
      const activePage = pages.filter(page => page.isActive)[0];
      const reportName = activePage.displayName.replace(/\s/g, '');
      if (this.shouldFilterPage(reportName)) {
        activePage.getVisuals().then(visuals => {
          const slicer = visuals.filter(visual => {
            return 'slicer' === visual.type && this.getVisualTitleByReportName(reportName) === visual.title;
          })[0];
          if (slicer) {
            const filter = this.getReportFilter(reportName);
            slicer.setSlicerState({ filters: [filter] }).then(() => {
            });
          }
        });
      }
    });
  }

  getReportFilter(reportName: string): any {

    let tableName = 'Dim_Date';
    let columnName = 'Date';
    const lastYearToday = moment().subtract(1, 'year').toISOString().split('T')[0];
    const startOfMonth = moment().startOf('month').add(1, 'day').toISOString().split('T')[0];

    const filterFields = [
      {
        name: '零件未结账详情报表',
        filter: {
          tableName: 'dm_parts_sales_detail_03',
          columnName: 'transaction_date',
        },
      },
      {
        name: '零件已结账详情报表',
        filter: {
          tableName: 'dm_parts_sales_detail',
          columnName: 'invoice_time',
        },
      },
      {
        name: '零件已出库详情报表',
        filter: {
          tableName: 'dm_detail_part_sales_first',
          columnName: 'transaction_date',
        },
      },
      {
        name: 'DIMS回购订单删除报表',
        filter: {
          tableName: 'dm_dims_buyback_07',
          columnName: 'created_time',
        },
      },
      {
        name: 'DIMS回购报表',
        filter: {
          tableName: 'dm_dims_buyback_07',
          columnName: 'created_time',
        },
      },
      {
        name: 'DIMS回购报表账单日期',
        filter: {
          tableName: 'dm_dims_buyback_07',
          columnName: 'Invoice_time',
        },
      },
      {
        name: '销售订单细节总览',
        filter: {
          tableName: 'dm_parts_order_detail_part1',
          columnName: 'created_time',
        },
      },
      {
        name: '销售订单细节总览的副本',
        filter: {
          tableName: 'dm_parts_order_detail_part2',
          columnName: 'created_at',
        },
      },
      {
        name: '销售分析',
        filter: {
          tableName: 'dm_parts_sales_analysis',
          columnName: 'invoice_time',
        },
      },
      {
        name: '销售分析账单明细',
        filter: {
          tableName: 'dm_parts_sales_analysis',
          columnName: 'invoice_time',
        },
      },
      {
        name: '销售分析流动成本总计',
        filter: {
          tableName: 'dm_parts_sales_analysis',
          columnName: 'invoice_time',
        },
      },
      {
        name: '销售分析流动成本明细',
        filter: {
          tableName: 'dm_parts_sales_analysis',
          columnName: 'invoice_time',
        },
      },
      {
        name: '销售分析报表（by市场代码）',
        filter: {
          tableName: 'dm_parts_sales_analysis',
          columnName: 'invoice_time',
        },
      },
      {
        name: '销售摘要',
        filter: {
          tableName: 'dm_parts_sales_analysis',
          columnName: 'invoice_time',
        },
      },
      {
        name: '账单摘要',
        filter: {
          tableName: 'dm_parts_sales_analysis',
          columnName: 'invoice_time',
        },
      },
      {
        name: '旧件返还月结报表',
        filter: {
          tableName: 'dm_remain_parts_06',
          columnName: 'invoice_time',
        },
      },
      {
        name: '惠选配件零售报表',
        filter: {
          tableName: 'dm_remain_parts_06',
          columnName: 'invoice_time',
        },
      },
      {
        name: '惠选配件零售报表销售类型',
        filter: {
          tableName: 'dm_remain_parts_06',
          columnName: 'invoice_time',
        },
      },
      {
        name: '零件流动报表（概要）',
        filter: {
          tableName: 'View_Part_Report_R01B_Part_Flow_Summary',
          columnName: 'Transaction_Date',
        },
      },
      {
        name: '零件流动报表（明细）',
        filter: {
          tableName: 'View_Part_Report_R01A_Part_Flow_Detail',
          columnName: 'Transaction_Date',
        },
      },
      {
        name: '失销报表',
        filter: {
          tableName: 'View_Part_Report_R12_Lost_Sales',
          columnName: 'created_date',
        },
      },
      {
        name: '到货分配清单',
        filter: {
          tableName: 'View_Part_Report_R09C_Arrival_Distribution_List',
          columnName: '收货日期',
        },
      },
      {
        name: '采购订单总览',
        filter: {
          tableName: 'View_Part_Report_R09A_PO_Overview',
          columnName: '零件行创建日期',
        },
        conditions: {
          greaterThanOrEqual: lastYearToday,
        },
      },
      {
        name: '订货单',
        filter: {
          tableName: 'View_Part_Report_R09E_Order_Form',
          columnName: '创建日期',
        },
        conditions: {
          greaterThanOrEqual: lastYearToday,
        },
      },
      {
        name: '日常运作统计表',
        filter: {
          tableName: 'dm_parts_sales_overall',
          columnName: 'tx_day',
        },
        conditions: {
          greaterThanOrEqual: startOfMonth,
        },
      },
    ];
    const reportFilter = _.find(filterFields, ['name', reportName]);
    const today = new Date().toISOString().split('T')[0];
    const todayPlusOne = new Date().addDays(1).toISOString().split('T')[0];
    let greaterThanOrEqual = today;

    if (reportFilter) {
      tableName = reportFilter.filter.tableName;
      columnName = reportFilter.filter.columnName;
      greaterThanOrEqual = _.get(reportFilter, 'conditions.greaterThanOrEqual', today);
    }
    const filter = {
      $schema: 'http://powerbi.com/product/schema#advanced',
      target: {
        table: tableName,
        column: columnName,
      },
      logicalOperator: 'And',
      conditions: [
        {
          operator: 'GreaterThanOrEqual',
          value: `${greaterThanOrEqual} 00:00:00`,
        },
        {
          operator: 'LessThan',
          value: `${todayPlusOne} 00:00:00`,
        },
      ],
    } as pbi.models.IAdvancedFilter;

    return filter;
  }

  getTableNameByReportName(reportName: string) {
    switch (reportName) {
      case '零件流动报表（概要）': {
        return 'View_Part_Report_R01B_Part_Flow_Summary';
      }
      case '零件流动报表（明细）': {
        return 'View_Part_Report_R01A_Part_Flow_Detail';
      }
      default: {
        return 'Dim_Date';
      }
    }
  }

  getColumnNameByReportName(reportName: string) {
    switch (reportName) {
      case '零件流动报表（概要）': {
        return 'Transaction_Date';
      }
      case '零件流动报表（明细）': {
        return 'Transaction_Date';
      }
      default: {
        return 'Date';
      }
    }
  }

  shouldFilterPage(reportName: string): boolean {
    const filterableReportName = [
      '采购订单总览',
      '发票检验报表',
      '到货分配清单',
      '收货报表',
      '惠选配件采购报表',
      '失销报表',
      '零件流动报表（明细）',
      '零件流动报表（概要）',
      '零件已出库详情报表',
      '零件已结账详情报表',
      '零件未结账详情报表',
      '日常运作统计表',
      '旧件返还月结报表',
      '销售订单细节总览',
      '销售订单细节总览的副本',
      '销售分析',
      '销售分析账单明细',
      '销售分析流动成本总计',
      '销售分析流动成本明细',
      '销售分析报表（by市场代码）',
      '销售摘要',
      '账单摘要',
      'DIMS回购订单删除报表',
      'DIMS回购报表',
      '惠选配件零售报表',
      '惠选配件零售报表销售类型',
      '订货单',
    ];
    return _.includes(filterableReportName, reportName);
  }

  getVisualTitleByReportName(reportName: string) {
    const fileds = [
      { name: '采购订单总览', title: '零件行创建日期（可输入时间范围）' },
      { name: '发票检验报表', title: '订单创建日期(可输入时间范围)' },
      { name: '到货分配清单', title: '收货日期(可输入时间范围)' },
      { name: '收货报表', title: '收货日期 (可输入时间范围)' },
      { name: '惠选配件采购报表', title: '收货日期 (可输入时间范围)' },
      { name: '失销报表', title: '创建日期' },
      { name: '零件流动报表（明细）', title: '交易日期 (可输入时间范围)' },
      { name: '零件流动报表（概要）', title: '交易日期(可输入时间范围)' },
      { name: '零件未结账详情报表', title: '出库日期（可输入时间范围）' },
      { name: '零件已结账详情报表', title: '结账日期（可输入时间范围）' },
      { name: '零件已出库详情报表', title: '出库日期（可输入时间范围）' },
      // { name: '旧件库存报表', title: '出库时间（可输入时间范围）' },
      { name: '日常运作统计表', title: '日期（可输入时间范围）' },
      { name: 'DIMS回购订单删除报表', title: '创建日期（可输入时间范围）' },
      { name: 'DIMS回购报表', title: '日期（可输入时间范围）' },
      { name: '销售订单细节总览', title: '创建日期（可输入时间范围）' },
      { name: '销售订单细节总览的副本', title: '创建日期（可输入时间范围）' },
      { name: '销售分析', title: '账单日期（可输入时间范围）' },
      { name: '销售分析账单明细', title: '账单日期（可输入时间范围）' },
      { name: '销售分析流动成本总计', title: '账单日期（可输入时间范围）' },
      { name: '销售分析流动成本明细', title: '账单日期（可输入时间范围）' },
      { name: '销售分析报表（by市场代码）', title: '账单日期（可输入时间范围）' },
      { name: '销售摘要', title: '账单日期（可输入时间范围）' },
      { name: '账单摘要', title: '账单日期（可输入时间范围）' },
      { name: '旧件返还月结报表', title: '结账日期（可输入时间范围）' },
      { name: '惠选配件零售报表', title: '结账日期（可输入时间范围）' },
      { name: '惠选配件零售报表销售类型', title: '结账日期（可输入时间范围）' },
      { name: '订货单', title: '创建日期' },
    ];
    const reportTitle = _.find(fileds, ['name', reportName]);

    return (reportTitle) ? reportTitle.title : '';
  }
}

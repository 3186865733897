import { map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { CurrentUserService } from '@otr/website-common';
import { UploadFormComponent } from '../../_common/components/upload-form/upload-form.component';

export interface TemplateConfigurationListener {
  download: (dealerId?) => Observable<{ url: string }>;
  downloadData?: (dealerId?) => Observable<{ url: string }>;
  getUploadTime?: (dealerId, vehicleBusinessType) => Observable<{ update_time: number }>;
  uploadUrl: string;
}

@Component({
  selector: 'app-template-config',
  styleUrls: ['template-config.style.scss'],
  templateUrl: 'template-config.template.html',
})
export class TemplateConfigComponent implements OnInit, OnChanges {
  datas: any[];
  extensions: string[] = ['.xlsx'];
  placeholder: string = '仅支持xlsx格式的文件';
  errorMessagePrefix: string = '导入失败，请检查文件格式是否正确';
  templateUpdateTime: number;

  @Input() canDownload: boolean = true;
  @Input() canUpload: boolean = true;
  @Input() isErrorShow: boolean = true;
  @Input() isTop: boolean;
  @Input() templateSectionTitle: string;
  @Input() hasIntegrationAftermarket: boolean;
  @Input() downloadDataTitle: string = null;
  @Input() downloadTemplateTitle: string = '下载模板';
  @Input() requirementTemplateListener: TemplateConfigurationListener;
  @Input() confirmMessage: string = '';
  @Input() comments: string = '';
  @Input() type: string = '';
  @Input() needPollingRequire: boolean = false;
  @Input() vehicleBusinessType:  string = 'PC';
  @Input() isDownloadFailure: boolean = false;
  @Input() isDownloading: boolean = false;

  @Output() onErrorShowEvent = new EventEmitter();
  @Output() succeedEvent = new EventEmitter();
  @Output() downloadErrorEvent = new EventEmitter();

  @ViewChild(UploadFormComponent) uploadFormComponent: UploadFormComponent;

  constructor(private currentUserService: CurrentUserService) {

  }

  onClickDownload() {
    this.requirementTemplateListener.download(this.currentUserService.getCurrentUser().dealerId).pipe(
      map(response => response.url))
      .subscribe(url => {
        if (!_.isEmpty(url)) {
          window.location.href = url;
        }
      });
  }

  onClickDownloadData() {
    this.requirementTemplateListener.downloadData(this.currentUserService.getCurrentUser().dealerId).pipe(
      map(response => response.url))
      .subscribe(url => {
        if (!_.isEmpty(url)) {
          window.location.href = url;
        }
      });
  }

  onImportSucceeded() {
    if (this.isDownloadFailure) {
      this.succeedEvent.emit();
      return ;
    }
    this.geTemplateLastUpdateTime();
    this.succeedEvent.emit();
  }

  downloadErrorMessage() {
    this.downloadErrorEvent.emit();
  }

  onImportError(errorMessage) {
    this.errorMessagePrefix = errorMessage || this.errorMessagePrefix;
  }

  onImportErrorShow(response) {
    this.onErrorShowEvent.emit(response);
  }

  ngOnInit() {
    this.geTemplateLastUpdateTime();
  }

  geTemplateLastUpdateTime() {
    const dealerId = this.currentUserService.getCurrentUser().dealerId;
    if (dealerId && _.get(this.requirementTemplateListener, 'getUploadTime')) {
      this.requirementTemplateListener.getUploadTime(dealerId, this.vehicleBusinessType).pipe(
        map(response => response.update_time))
        .subscribe(lastUpdateTime => {
          this.templateUpdateTime = lastUpdateTime;
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!_.isNil(changes.vehicleBusinessType)) {
      this.geTemplateLastUpdateTime();
      this.uploadFormComponent.refresh();
    }
  }
}

<div class="select-wrapper customer-dropdown">
  <button
    type="button"
    (click)="toggleStatus()"
    [class.disabled]="disabled"
    [class.error]="error"
    [class.required]="required"
    class="input-control input-control-middle">
    {{displayText}}
  </button>
  <ul class="customer-dropdown-menu" [class.open]="showing">
    <li *ngFor="let option of options"
        (click)="click(option)"
        class="customer-dropdown-item">
      <div>{{getText(option)}}</div>
    </li>
  </ul>
</div>

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'scoringFormat',
})
export class ScoringFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return `${value} 分`;
    }
    return value.toString();
  }
}

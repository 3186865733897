import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PDFDocumentProxy, PDFSource } from 'pdfjs-dist';

const ENLARGE_FACTOR = 1.1;
const SHRINK_FACTOR = 0.9;
@Component({
  selector: 'app-paf-display',
  templateUrl: './paf-display.component.html',
  styleUrls: ['./paf-display.component.scss']
})
export class PDFDisplayComponent {
  @Input() data: string | Uint8Array | PDFSource;
  @Output() downloadFile: EventEmitter<void> = new EventEmitter<void>();
  currentPage: number = 1;
  pageNumbers: number = 1;
  zoom: number = 1;

  onDownloadFile(): void {
    this.downloadFile.emit();
  }

  zoomIn(): void {
    const randomSeed = Math.random() * 0.001;
    this.zoom = ENLARGE_FACTOR + randomSeed;
  }

  zoomOut(): void {
    const randomSeed = Math.random() * 0.001;
    this.zoom =  SHRINK_FACTOR - randomSeed;
  }

  prevPage(): void {
    this.zoom = 1;
    if (this.currentPage === 1) {
      return;
    }
    this.currentPage = this.currentPage - 1;
  }

  nextPage(): void {
    this.zoom = 1;
    if (this.currentPage === this.pageNumbers) {
      return;
    }
    this.currentPage = this.currentPage + 1;
  }

  afterLoadComplete(pdf: PDFDocumentProxy): void {
    this.pageNumbers = pdf.numPages;
  }
}

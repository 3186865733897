import * as _ from 'lodash';
import { isArrayOfSameLength } from './collection';

export class MindMapCompareDeepUtil {
  equalDeep(latestData, originalData): boolean {
    return _.get(latestData, 'name') === _.get(originalData, 'name')
      && _.get(originalData, 'type') === _.get(latestData, 'type')
      && isArrayOfSameLength(originalData.childGroups, latestData.childGroups)
      && _.every(latestData.childGroups, (childGroup, index) => this.equalDeep(childGroup, originalData.childGroups[index]));
  }
}

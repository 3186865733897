import { DATE_TIME_DAY_FORMAT } from '../constants/service-contract';
import { toEnum } from './enum';
import {
  canDownloadAttendanceTemplate,
  canDownloadInvitationTemplate,
  canShowLeadAttendance,
  canUploadFiles,
  getFileLatestCreatedTime,
  getLeadAcquisitionStatus,
  getLeadAttendanceStatus,
  getLeadInvitationStatus,
  isLeadAcquisition,
  isLeadConversion,
} from '../../campaign/utils';
import moment = require('moment');

export const REGEX_NUMBER = /^\d+(\.\d*)?$/;
export const ALLOWED_KEYS_NUMBER = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
export const INT_ALLOWED_KEYS_NUMBER = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const SF_FAILED_ERROR_MAPPING = {
  APPROVER_NOT_ASSIGN: '未能成功分配审批人，请与区域沟通并等待处理',
  CLIENT_ERROR: '系统错误',
};

export const CUSTOMER_ACQUISITION = {
  LEAD_ACQUISITION: 'LEAD_ACQUISITION',
  LEAD_CONVERSION: 'LEAD_CONVERSION',
};

export const JOINT = 'Joint';

export const MEDIA_RETAIL_RECORD_TYPE = 'SM_MEDIA_RETAIL_CAMPAIGN';
export const EVENT_RETAIL_RECORD_TYPE = 'SM_EVENT_RETAIL_CAMPAIGN';
const MEDIA_MB_RECORD_TYPE = 'SM_MEDIA_BMBS_CAMPAIGN';
const EVENT_MB_RECORD_TYPE = 'SM_EVENT_BMBS_CAMPAIGN';
const AS_BMBS_CAMPAIGN = 'AS_BMBS_CAMPAIGN';

export const CAN_SHOW_MEMBER_TYPES = [EVENT_RETAIL_RECORD_TYPE];

export const RECORD_TYPE_PATH_MAPPING = {
  [MEDIA_MB_RECORD_TYPE]: '/campaign/retail/create/sm_media_retail_campaign',
  [EVENT_MB_RECORD_TYPE]: '/campaign/retail/create/sm_event_retail_campaign',
  [AS_BMBS_CAMPAIGN]: '/campaign/retail/as-create',
};

export const CAMPAIGN_TYPE_PATH_MAPPING = {
  [MEDIA_RETAIL_RECORD_TYPE]: '/campaign/retail/create/sm_media_retail_campaign',
  [EVENT_RETAIL_RECORD_TYPE]: '/campaign/retail/create/sm_event_retail_campaign',
};

export const RECORD_TYPE_MAPPING = {
  [MEDIA_RETAIL_RECORD_TYPE]: '线上媒体',
  [EVENT_RETAIL_RECORD_TYPE]: '线下活动',
  [MEDIA_MB_RECORD_TYPE]: '线上媒体',
  [EVENT_MB_RECORD_TYPE]: '线下活动',
};

export const MB_RETAIL_RECORD_TYPE_MAPPING = {
  [MEDIA_MB_RECORD_TYPE]: MEDIA_RETAIL_RECORD_TYPE,
  [EVENT_MB_RECORD_TYPE]: EVENT_RETAIL_RECORD_TYPE,
};

export const RETAIL_MB_RECORD_TYPE_MAPPING = {
  [MEDIA_RETAIL_RECORD_TYPE]: MEDIA_MB_RECORD_TYPE,
  [EVENT_RETAIL_RECORD_TYPE]: EVENT_MB_RECORD_TYPE,
};

export const CAMPAIGN_STATUS = toEnum({
  CREATED: { text: '计划创建' },
  APPLY_PLANNED: { text: '计划未提交' },
  APPLY_CONFIRMED: { text: '计划审批中' },
  APPLY_NEED_MODIFY: { text: '计划待修改' },
  APPLY_APPROVED: { text: '计划已批准' },
  CANCEL_APPLICATION: { text: '撤销审批中' },
  CANCEL_REJECTED: { text: '撤销驳回' },
  CANCELLED: { text: '计划已关闭' },
  FEEDBACK_PLANNED: { text: '反馈未提交' },
  FEEDBACK_CONFIRMED: { text: '反馈审批中' },
  FEEDBACK_NEED_MODIFY: { text: '反馈待修改' },
  FEEDBACK_APPROVED: { text: '反馈已批准' },
});

export const CampaignStatus = {
  CREATED: 'CREATED',
  APPLY_PLANNED: 'APPLY_PLANNED',
  APPLY_CONFIRMED: 'APPLY_CONFIRMED',
  APPLY_NEED_MODIFY: 'APPLY_NEED_MODIFY',
  APPLY_APPROVED: 'APPLY_APPROVED',
  CANCEL_APPLICATION: 'CANCEL_APPLICATION',
  CANCEL_REJECTED: 'CANCEL_REJECTED',
  CANCELLED: 'CANCELLED',
  FEEDBACK_PLANNED: 'FEEDBACK_PLANNED',
  FEEDBACK_CONFIRMED: 'FEEDBACK_CONFIRMED',
  FEEDBACK_NEED_MODIFY: 'FEEDBACK_NEED_MODIFY',
  FEEDBACK_APPROVED: 'FEEDBACK_APPROVED',
};

const {
  CREATED,
  APPLY_PLANNED,
  APPLY_CONFIRMED,
  APPLY_NEED_MODIFY,
  APPLY_APPROVED,
  CANCEL_APPLICATION,
  CANCEL_REJECTED,
  CANCELLED,
  FEEDBACK_PLANNED,
  FEEDBACK_CONFIRMED,
  FEEDBACK_NEED_MODIFY,
  FEEDBACK_APPROVED,
} = CampaignStatus;

export const CAMPAIGN_STATUS_MAPPING = {
  [CREATED]: '计划创建',
  [APPLY_PLANNED]: '计划未提交',
  [APPLY_CONFIRMED]: '计划审批中',
  [APPLY_NEED_MODIFY]: '计划待修改',
  [APPLY_APPROVED]: '计划已批准',
  [CANCEL_APPLICATION]: '撤销审批中',
  [CANCEL_REJECTED]: '撤销驳回',
  [CANCELLED]: '计划已关闭',
  [FEEDBACK_PLANNED]: '反馈未提交',
  [FEEDBACK_CONFIRMED]: '反馈审批中',
  [FEEDBACK_NEED_MODIFY]: '反馈待修改',
  [FEEDBACK_APPROVED]: '反馈已批准',
};

export const APPLY_STATUS = [
  CREATED,
  APPLY_PLANNED,
  APPLY_APPROVED,
  APPLY_NEED_MODIFY,
];

export const CAN_SHOW_MEMBER_STATUS = [APPLY_APPROVED, FEEDBACK_PLANNED, FEEDBACK_CONFIRMED, FEEDBACK_NEED_MODIFY, FEEDBACK_APPROVED];

export const HIDE_ACTUAL_ORDERS_STATUS = [APPLY_APPROVED, FEEDBACK_PLANNED];

export const RETAIL_CAMPAIGN_CAN_ADD_AND_DELETE_ATTACHMENT_STATUS_GROUP = [
  APPLY_PLANNED,
  APPLY_NEED_MODIFY,
  FEEDBACK_PLANNED,
  FEEDBACK_NEED_MODIFY,
];

export const RETAIL_CAMPAIGN_SHOULD_SHOW_ALREADY_APPROVE_LABEL_STATUS_GROUP = [
  FEEDBACK_PLANNED,
  FEEDBACK_CONFIRMED,
  FEEDBACK_NEED_MODIFY,
  CANCEL_APPLICATION,
  CANCELLED,
];

export const SALES_RETAIL_CAMPAIGN = 'SALES_RETAIL_CAMPAIGN';
export const AS_RETAIL_CAMPAIGN = 'AS_RETAIL_CAMPAIGN';
export const EVENT_REQUIRED_FIELDS = ['actualInviteCustomer', 'actualShowUpCustomer', 'actualNewCustomers'];
export const SM_EVENT_RETAIL_CAMPAIGN = 'SM_EVENT_RETAIL_CAMPAIGN';

export const CUSTOMER_ACQUISITION_TYPE = [
  {
    name: CUSTOMER_ACQUISITION.LEAD_ACQUISITION,
    description: '收集潜客',
  },
  {
    name: CUSTOMER_ACQUISITION.LEAD_CONVERSION,
    description: '线索转化',
  },
];

export const CAMPAIGN_FILE_STATUS = toEnum({
  UPLOAD_AVAILABLE: { text: '上传', url: '/assets/common/upload.svg' },
  MODIFY_AVAILABLE: { text: '修改', url: '/assets/common/modification.svg' },
  IN_PROGRESS: { text: '', url: '/assets/common/loading.svg' },
  NOT_AVAILABLE: { text: '', url: '' },
});

export interface FileInfo {
  text: string;
  value: string;
  templateType: string;
  templateDownloadName: string;
  canDownloadTemplate: boolean;
  confirmMessage: string;
  hoverMessage: string;
  status: string;
  isVisible: boolean;
  latestCreatedTime?: number;
  requestId?: number;
}

export const RETAIL_CAMPAIGN_FILE_INFOS = toEnum({
  LEAD_ACQUISITION: {
    text: '参加名单',
    templateType: CUSTOMER_ACQUISITION.LEAD_ACQUISITION,
    templateDownloadName: '参加名单模板',
    canDownloadTemplate: campaign => canUploadFiles(campaign),
    hoverMessage: '系统将会根据每次上传名单中新增的客户姓名和电话进行反馈数据统计',
    status: campaign => getLeadAcquisitionStatus(campaign),
    isVisible: campaign => isLeadAcquisition(campaign),
    latestCreatedTime: campaign => getFileLatestCreatedTime(campaign, RETAIL_CAMPAIGN_FILE_INFOS.LEAD_ACQUISITION.value),
  },
  LEAD_INVITATION: {
    text: '邀约名单',
    templateType: CUSTOMER_ACQUISITION.LEAD_CONVERSION,
    templateDownloadName: '邀约名单模板',
    canDownloadTemplate: campaign => canDownloadInvitationTemplate(campaign),
    hoverMessage: '最终邀约名单将以最后一次上传的名单为准',
    status: campaign => getLeadInvitationStatus(campaign),
    isVisible: campaign => isLeadConversion(campaign),
    latestCreatedTime: campaign => getFileLatestCreatedTime(campaign, RETAIL_CAMPAIGN_FILE_INFOS.LEAD_INVITATION.value),
  },
  LEAD_ATTENDANCE: {
    text: '参加名单',
    templateType: null,
    templateDownloadName: '参加名单模板',
    canDownloadTemplate: campaign => canDownloadAttendanceTemplate(campaign),
    confirmMessage: '请先下载当前邀约名单并更新参加状态和添加新成员',
    hoverMessage: '系统将会根据每次上传名单中新增的客户姓名和电话进行反馈数据统计',
    status: campaign => getLeadAttendanceStatus(campaign),
    isVisible: campaign => canShowLeadAttendance(campaign),
    latestCreatedTime: campaign => getFileLatestCreatedTime(campaign, RETAIL_CAMPAIGN_FILE_INFOS.LEAD_ATTENDANCE.value),
  },
});

export const EXCEL_FILE_CONTENT_TYPE = 'application/vnd.ms-excel';
export const DOCX_FILE_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const generateExcelFileNameWithDate = (campaignType): string =>
  `${campaignType}_${moment().format(DATE_TIME_DAY_FORMAT)}.xlsx`;

export const generateExcelFileNameWithDateAndDateType = (campaignType, dateType): string =>
  `${campaignType}_${moment().format(DATE_TIME_DAY_FORMAT)}_${dateType}.xlsx`;

export const generateExcelFileNameWithTitle = (campaignType, campaignTitle): string =>
`${campaignType}_${campaignTitle}.xlsx`;

export const PROCESS_STATUS = toEnum({
  Success: {
    text: '导入成功',
  },
  Failure: {
    text: '导入失败',
  },
  InProgress: {
    text: '导入中',
  },
});

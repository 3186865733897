import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-search',
  styleUrls: ['./input-search.style.scss'],
  templateUrl: './input-search.template.html',
})
export class InputSearchComponent implements OnInit {
  @Input()
  value: string;
  @Input()
  disabled: boolean = false;
  @Input()
  maxLength: number;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {

  }

  handleChange(event) {
    this.onChange.emit(event.target.value);
  }

  handleSearch() {
    this.onSearch.emit(this.value);
  }
}

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

@Component({
  template: `
    <img *ngIf="params.data.status === 'OPERATION_COMPLETE'" style='cursor: pointer' src="../../../../../../assets/td-agreement/td-agreement-file.svg" (click)="unfoldTdAgreementPDF()"/>
  `,
})
export class TdAgreementIconComponent implements AgRendererComponent {
  params;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  unfoldTdAgreementPDF() {
    'unfoldTdAgreementPDF' in this.params.column.colDef.cellRendererParams ?
      this.params.column.colDef.cellRendererParams.unfoldTdAgreementPDF(this.params)
      :
      ((): void => {})();
  }
  refresh(params: any): boolean {
    return false;
  }

}

import { Injectable } from '@angular/core';
import { VehicleCascaderSelectApi } from './vehicle-cascader-select-api.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleCascaderSelectService {
  constructor(private vehicleCascaderSelectApi: VehicleCascaderSelectApi) {
  }

  fetchAllBrands() {
    return this.vehicleCascaderSelectApi.fetchAllBrands();
  }
}

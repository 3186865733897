import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { dateParse } from '../utils/date-parse';
import { NUMBER_REGEXP } from '../constants/common';

@Pipe({ name: 'customizeDate' })
export class CustomizeDatePipe implements PipeTransform {

  transform(value: any, arg?: string, empty?: string) {
    const formattedValue = NUMBER_REGEXP.test(value) ? Number(value) : value;
    const date = new Date(formattedValue);
    if (_.isNil(value) || _.isNaN(date.getDate())) {
      return empty || '';
    }
    return dateParse(date, arg || 'yyyy-M-d');
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { DealerIntegrationToggleService } from '@otr/website-common';
import { CtiLoginType } from '../../../_common/constants/common';
import { CtiCallService } from '../../../_common/services/cti-call.service';

@Component({
  selector: 'app-cti-login-status-dropdown',
  templateUrl: './cti-login-status-dropdown.component.html',
  styleUrls: ['./cti-login-status-dropdown.component.scss'],
})

export class CtiLoginStatusDropdownComponent implements OnInit {
  shouldShowMenu: boolean = false;
  ctiStatus = [{
    icon: 'online.svg',
    buttonText: '上线',
    statusText: '在线',
    key: 'online',
  }, {
    icon: 'offline.svg',
    buttonText: '下线',
    statusText: '离线',
    key: 'offline',
  }, {
    icon: 'busy.svg',
    buttonText: '离开',
    statusText: '离开',
    key: 'busy',
  }];
  defaultCtiStatus = _.nth(this.ctiStatus, 1);
  currentCtiStatus = _.cloneDeep(this.defaultCtiStatus);

  constructor(
    private dealerIntegrationToggleService: DealerIntegrationToggleService,
    private ctiCallService: CtiCallService,
  ) {}

  ngOnInit() {
    this.ctiCallService.getCtiStatus().subscribe(res => {
      if (res.isOnline) {
        this.currentCtiStatus = _.head(this.ctiStatus);
      }
      if (res.isOffline) {
        this.currentCtiStatus = this.defaultCtiStatus;
      }
      if (res.isBusy) {
        this.currentCtiStatus = _.last(this.ctiStatus);
      }
    });
    if (CallCenter.getNowStatus() === 'agentidle') {
      this.currentCtiStatus = _.head(this.ctiStatus);
    } else if (CallCenter.getNowStatus() === 'agentbusy') {
      this.currentCtiStatus = _.last(this.ctiStatus);
    } else {
      this.currentCtiStatus = this.defaultCtiStatus;
    }
  }

  toggleMenuVisibility() {
    this.shouldShowMenu = !this.shouldShowMenu;
  }

  handleStatusItemClick(item) {
    this.toggleMenuVisibility();
    if (item.key === 'online') {
      if (CallCenter.isLogin()) {
        CallCenter.free();
      }
      let hrefUrl = 'https://uatsoftphone.lshauto.com.cn:8898/3cs';
      // if (environment.production || _.get(environment, 'envName') === 'uat') {
      if (environment.production) {
        hrefUrl = 'https://softphone.lshauto.com.cn:8898/3cs';
      }
      CallCenter.opLogin(
        _.get(this.ctiCallService.getOperatorInfo(), 'operatorId'),
        _.get(this.ctiCallService.getOperatorInfo(), 'accessSecret'),
        _.get(this.ctiCallService.getOperatorInfo(), 'ctiCompanyId'),
        // CtiLoginType.Sip, 0, '', 'https://softphone.lshauto.com.cn:8898/3cs', 1, 1, '');
        CtiLoginType.Sip, 0, '', hrefUrl, 1, 1, '');
    }
    if (item.key === 'busy') {
      CallCenter.busy();
    }
    if (item.key === 'offline') {
      CallCenter.cancelmakecall();
      CallCenter.logout();
    }
  }

  get menuItems() {
    if (this.currentCtiStatus.key === 'offline') {
      return _.dropRight(this.ctiStatus, 2);
    }
    if (this.currentCtiStatus.key === 'busy') {
      return _.chain(this.ctiStatus)
      .filter(item => item.key !== this.currentCtiStatus.key)
      .reverse()
      .value();
    }
    return _.filter(this.ctiStatus, item => item.key !== this.currentCtiStatus.key);
  }

  get shouldShowComponent() {
    return _.get(this.ctiCallService.getOperatorInfo(), 'operatorId')
    && _.get(this.ctiCallService.getOperatorInfo(), 'ctiCompanyId')
    && _.chain(this.dealerIntegrationToggleService)
      .get('integrationToggles')
      .filter({ name: 'CTI', enabled: true })
      .head()
      .get('enabled')
      .value();
  }
}

import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import * as _ from 'lodash';
import { CtiApi } from '../../../_common/api/cti';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { CtiCallService } from '../../../_common/services/cti-call.service';
import {CurrentUserService, FeatureToggleService, DealerIntegrationToggleService} from '@otr/website-common';
import {
  CUSTOMER_STATUS_OPTIONS,
  EVENT_STATUS_OPTIONS,
  TELEPHONIST_STATUS_OPTIONS
} from '../../../_common/constants/cti';
import { phoneNumFormat } from '../utils';

@Component({
  selector: 'app-inbound-call-drag-bar',
  templateUrl: './inbound-call-drag-bar.component.html',
  styleUrls: ['./inbound-call-drag-bar.component.scss'],
  providers: [],
})
export class InboundCallDragBarComponent implements OnInit, OnDestroy {
  @Output() onRefuse = new EventEmitter();
  @Input() data;
  @ViewChild('elem') elem: ElementRef;
  @Output() foldAssistant = new EventEmitter();
  isDown = false;
  isMoved = false;
  disX = 0;
  disY = 0;
  private totalOffsetX = 0;
  private totalOffsetY = 0;
  private dragTm;
  parentElem: any;
  isCalling: boolean = false;
  isHolding: boolean = false;
  isMute: boolean = false;
  isExpandMenu: boolean = true;
  isTransfer: boolean = false;
  isConsult: boolean = false;
  isConsultCalling: boolean = false;
  isAllowExit: boolean = true;
  expandIndex = '';
  customerList = [];
  showCallEndModal: boolean = false;
  callDuration: number = 0;
  callDurationId;
  inCallListenerId = '';
  transferInCallListenerId = '';
  consultInCallListenerId = '';
  afterListenerId: string = '';
  cecId;
  currentTelephonistStatus: string = '';
  companyName: string = '';
  @Input() showAssistantIcon;
  constructor(private renderer: Renderer2,
              private ctiApi: CtiApi,
              private ctiCallService: CtiCallService,
              private currentUserService: CurrentUserService,
              private featureToggleService: FeatureToggleService,
              private dealerIntegrationToggleService: DealerIntegrationToggleService,
              private router: Router) {}

  get isShowCTI() {
    return this.featureToggleService.isFeatureEnable('CTI_INTELLIGENT_ASSISTANT', false);
  }

  ngOnInit(): void {
    this.cecId = this.currentUserService.getCecGroupId();
    this.initDragElement();
    if (_.get(this.data, 'origcaller')) {
      this.getCustomerList(phoneNumFormat(_.get(this.data, 'origcaller')));
    }
    if (_.get(this.data, 'companyId')) {
      this.getCompanyName(_.get(this.data, 'companyId'));
    }
    this.inCallListenerId = CallCenter.addEventListener('incall', json => {
      // 客户呼入接听
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.CALLING.value);
      // this.ctiCallService.hideInCallNotification();
      this.isCalling = true;
      this.foldAssistantPage('incall');
      this.callDurationId = setInterval(() => {
        this.callDuration += 1;
      }, 1000);
    });
    this.transferInCallListenerId = CallCenter.addEventListener('transferincall', json => {
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.CALLING.value);
      this.ctiCallService.hideInCallNotification();
      this.isCalling = true;
      this.foldAssistantPage('transferincall');
      this.callDurationId = setInterval(() => {
        this.callDuration += 1;
      }, 1000);
    });
    this.consultInCallListenerId = CallCenter.addEventListener('consultincall', json => {
      this.isConsultCalling = true;
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.CALLING.value);
      this.ctiCallService.hideInCallNotification();
      this.isCalling = true;
      this.foldAssistantPage('consultincall');
      this.callDurationId = setInterval(() => {
        this.callDuration += 1;
      }, 1000);
    });
    this.afterListenerId = CallCenter.addEventListener('after', json => {
      const phoneNum = this.ctiCallService.getCallInPhoneNum();
      // tslint:disable-next-line:no-console
      console.log('Trigger CallCenter after event', json);
      this.ctiCallService.setTelephonistStatus('');
      this.ctiCallService.setEventStatus('');
      this.ctiCallService.hideInCallNotification();
      // tslint:disable-next-line:no-console
      console.log('CallCenter status is calling: ', this.isCalling);
      if (this.isCalling) {
        this.ctiApi.getCustomerListByMobile(phoneNumFormat(this.data.origcaller)).subscribe(response => {
          let callEndCustomerList = _.cloneDeep(response);
          if (this.expandIndex && callEndCustomerList) {
            let currentInfo = null;
            _.forEach(callEndCustomerList, (item, index) => {
              if (_.eq(parseInt(this.expandIndex), index)) {
                currentInfo = item;
                callEndCustomerList = _.pull(callEndCustomerList, item);
              }
            });
            if (currentInfo) {
              callEndCustomerList.unshift(currentInfo);
            }
          }
          if (this.ctiCallService.getIsTransferOver()) {
            setTimeout(() => {
              this.onRefuse.emit({
                allCustomerList: callEndCustomerList,
                cecId: this.cecId,
                isConsult: this.isConsultCalling,
                phoneNum
              });
              this.isConsultCalling = false;
              this.isCalling = false;
              this.ctiCallService.setIsTransferOver(false);
              CallCenter.free();
            }, 3000);
          } else {
            this.onRefuse.emit({
              allCustomerList: callEndCustomerList,
              cecId: this.cecId,
              isConsult: this.isConsultCalling,
              phoneNum
            });
            this.isConsultCalling = false;
            this.isCalling = false;
            const currentstatus = this.ctiCallService.getCtiIntelligentAssistantCurrentStatus();
            // 咨询中，客户挂断通话
            if (_.isEqual(currentstatus, 'consultincall')) {
              this.foldAssistantPage('afterconsult');
            }
            CallCenter.free();
          }
        });
      } else {
        CallCenter.free();
      }
      this.ctiCallService.addCtiCallHistory(json, this.data).subscribe(r => {
        if (this.afterListenerId) {
          CallCenter.removeEventListener('after', this.afterListenerId);
          this.afterListenerId = '';
        }
        if (r.id) {
          this.ctiCallService.updateAfterCallInfo(r);
        }
      });
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.callDurationId);
    if (this.afterListenerId) {
      CallCenter.removeEventListener('after', this.afterListenerId);
      this.afterListenerId = '';
    }
    if (this.inCallListenerId) {
      CallCenter.removeEventListener('incall', this.inCallListenerId);
      this.inCallListenerId = '';
    }
    if (this.transferInCallListenerId) {
      CallCenter.removeEventListener('transferincall', this.transferInCallListenerId);
      this.transferInCallListenerId = '';
    }
    if (this.consultInCallListenerId) {
      CallCenter.removeEventListener('consultincall', this.consultInCallListenerId);
      this.consultInCallListenerId = '';
    }
  }

  initDragElement(): void {
    this.parentElem = this.elem.nativeElement.parentNode.parentNode.parentNode.parentNode;
    _.delay(() => {
      this.totalOffsetX =
        this.parentElem.offsetWidth - this.elem.nativeElement.offsetWidth;
      this.totalOffsetY = 65 + 34;
      this.setPosition(0, 0);
    }, 10);
  }

  onMouseout(event): void {
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
      this.isDown = false;
    }
  }

  // bind darg and drop event
  onMousedown(e): void {
    e.stopPropagation();
    // if (e.button !== 0) { return false; }

    document.documentElement.onmouseup = this.onMouseup.bind(this);
    this.elem.nativeElement.onmouseup = this.onMouseup.bind(this);
    const loop = () => {
      this.isDown = true;
      e.preventDefault();
      this.disX = e.clientX;
      this.disY = e.clientY;

      document.documentElement.onmousemove = this.onMousemove.bind(this);
      this.elem.nativeElement.onmousemove = this.onMousemove.bind(this);

      // this.renderer.setStyle(this.elem.nativeElement, 'cursor', 'move');
    };

    if (this.dragTm) {
      clearTimeout(this.dragTm);
    }
    this.dragTm = setTimeout(loop, 10);
  }

  onMousemove(event): boolean {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isDown) {
      return false;
    }

    this.isMoved = true;
    this.setPosition(event.clientX, event.clientY);
  }

  onMouseup(event): void {
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
    }

    this.isDown = false;
    if (this.dragTm) {
      clearTimeout(this.dragTm);
    }
    document.documentElement.onmousemove = null;
    document.documentElement.onmouseup = null;
    this.elem.nativeElement.onmousemove = null;
    this.elem.nativeElement.onmouseup = null;

    // this.renderer.setStyle(this.elem.nativeElement, 'cursor', 'pointer');
    // this.renderer.setStyle(this.elem.nativeElement, 'cursor', 'default');
  }

  // calcaulte and set the drag bar position
  setPosition(clientX, clientY): void {
    const pw = this.parentElem.offsetWidth;

    let left = this.totalOffsetX + clientX - this.disX;
    if (left < 0) {
      left = 0;
    }

    let top = this.totalOffsetY + clientY - this.disY;
    if (top < 65) {
      top = 65;
    }

    const maxLeft = pw - this.elem.nativeElement.offsetWidth - 20;

    if (left > maxLeft) {
      left = maxLeft;
    }

    // TODO improve the calculate drag area efficiency.
    this.renderer.setStyle(this.elem.nativeElement, 'left', `${left}px`);
    this.renderer.setStyle(this.elem.nativeElement, 'top', `${top}px`);
  }

  handleHoldChange() {
    if (this.isMute || this.telephonistStatus === TELEPHONIST_STATUS_OPTIONS.CONSULT_CALL_IN.value) {
      return;
    }
    if (this.isHolding) {
      CallCenter.unmute();
    } else {
      CallCenter.closeOnlyMuteCustomer();
      CallCenter.mute();
    }
    this.isHolding = !this.isHolding;
  }

  handleMuteChange() {
    if (this.isHolding || this.telephonistStatus === TELEPHONIST_STATUS_OPTIONS.CONSULT_CALL_IN.value) {
      return;
    }
    if (this.isMute) {
      CallCenter.unmute();
    } else {
      CallCenter.openOnlyMuteCustomer();
      CallCenter.mute();
    }
    this.isMute = !this.isMute;
  }

  handleTransferChange() {
    if (!this.isAllowExit || this.isMute || this.isHolding || this.isInTransferOrConsult) {
      return;
    }
    this.isTransfer = !this.isTransfer;
    this.currentTelephonistStatus = 'transfer';
  }

  handleConsultAdviceChange() {
    if (!this.isAllowExit || this.isMute || this.isHolding || this.isInTransferOrConsult) {
      return;
    }
    this.isConsult = !this.isConsult;
    this.currentTelephonistStatus = 'seek';
  }

  handleClickSelection(index) {
    if (this.expandIndex === index) {
      this.expandIndex = '';
      return;
    }
    this.expandIndex = index;
  }

  handleNameClick(id) {
    this.router.navigate([`/customer-lead-management/customer-management/detail/customer/${id}/0`]);
  }

  handleRefuseButton() {
    CallCenter.cancelmakecall();
  }

  handleApproveButton() {
    CallCenter.acceptcall();
    this.foldAssistantPage('callin');
  }

  handlechangeMenu() {
    this.isExpandMenu = !this.isExpandMenu;
  }

  getCustomerList(phoneNumber) {
    const queryPhoneNum = phoneNumFormat(phoneNumber);
    this.ctiApi.getCustomerListByMobile(queryPhoneNum).subscribe(response => {
      this.customerList = response;
    });
  }

  getCompanyName(companyId) {
    this.ctiApi.getCompanyNameById(companyId).subscribe(res => {
      this.companyName = _.get(res, 'name');
    });
  }

  isOpenLead = status => status === 'Lead' || status === 'Lead Candidate' || status === 'Lost Sales';

  currentSelectedCustomer() {
    if (typeof this.expandIndex === 'string') {
      return this.customerList[0];
    }
    return this.customerList[this.expandIndex];
  }

  getCallDuration() {
    const time = moment.duration(this.callDuration, 'seconds');
    const hour = time.hours() < 10 ? `0${time.hours()}` : time.hours();
    const minute = time.minutes() < 10 ? `0${time.minutes()}` : time.minutes();
    const second = time.seconds() < 10 ? `0${time.seconds()}` : time.seconds();
    return `${hour}:${minute}:${second}`;
  }

  addCustomerAndLead() {
    this.ctiCallService.addCustomerAndLead(phoneNumFormat(this.data.origcaller));
  }

  addAfteSalesOnCall() {
    this.ctiCallService.addAfterSales({mobile: phoneNumFormat(this.data.origcaller), type: false});
  }

  get shouldShowAddCustomerBtn() {
    return this.ctiCallService.getShouldShowAddCustomerBtn();
  }

  openLeadVehicleList(customer) {
    let tempList = [];
    _.forEach(_.get(customer, 'leads'), lead => {
      if (this.isOpenLead(_.get(lead, 'status'))) {
        tempList = [...tempList, ...lead.interested_vehicles];
      }
    });
    return tempList;
  }

  hasOpenLead(customer, props = 'interestedVehicles') {
    return customer[props] != null;
  }

  getInterestedVehicle(customer, props = 'interestedVehicles'): string {
    if (customer[props] && customer[props].length) {
      return `${customer[props][0].brand.description_zh || ''} ${customer[props][0].class.description_zh || ''} ${customer[props][0].variant.description_zh || ''}`;
    }
    return '';
  }

  setExitStatus (status) {
    this.isAllowExit = status;
  }

  get isInTransferOrConsult() {
    return !_.isEmpty(this.ctiCallService.getTelephonistStatus());
  }

  get telephonistStatus() {
    return this.ctiCallService.getTelephonistStatus();
  }

  foldAssistantPage(event) {
    const statusList = ['consultincall', 'afterconsult', 'callin'];
    if (event === 'click') {
      this.showAssistantIcon = !this.showAssistantIcon;
    } else {
      this.showAssistantIcon = _.includes(statusList, event) ? true : false;
      this.ctiCallService.setCtiIntelligentAssistantCurrentStatus(event);
    }
    this.foldAssistant.emit(
      {
        expandAssistant: event,
        showAssistantIcon: this.showAssistantIcon,
      });
  }
}

import { Directive, ElementRef, OnInit } from '@angular/core';
import { canvasWatermark } from '../utils/watermark';
import { CurrentUserService } from '@otr/website-common';
import * as _ from 'lodash';

@Directive({
  selector: '[appWatermark]',
})
export class WatermarkDirective implements OnInit {
  private readonly elem: ElementRef;

  constructor(public element: ElementRef, private currentUserService: CurrentUserService) {
    this.elem = element;
  }

  oemRoles = ['OEM'];
  oemName = '梅赛德斯奔驰';

  ngOnInit(): void {
    const user = this.currentUserService.getCurrentUser();
    const groupName = _.includes(this.oemRoles, user.roleCode) ? this.oemName : user.currentGroupName;
    const watermarkDiv = canvasWatermark({
      content: `${user.gemsUserId} ${user.fullName} ${groupName}`,
    });
    this.elem.nativeElement.insertBefore(watermarkDiv, this.elem.nativeElement.firstChild);
  }
}

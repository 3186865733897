import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import * as _ from 'lodash';
import { CtiApi } from '../../../_common/api/cti';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { INT_ALLOWED_KEYS_NUMBER, REGEX_NUMBER } from '../../../_common/utils/campaign';
import {CurrentUserService, DealerIntegrationToggleService, FeatureToggleService} from '@otr/website-common';
import { CtiCallService } from '../../../_common/services/cti-call.service';
import { EVENT_STATUS_OPTIONS, TELEPHONIST_STATUS_OPTIONS, CUSTOMER_STATUS_OPTIONS } from '../../../_common/constants/cti';

@Component({
  selector: 'app-outbound-call-drag-bar',
  templateUrl: './outbound-call-drag-bar.component.html',
  styleUrls: ['./outbound-call-drag-bar.component.scss'],
  providers: [],
})
export class OutboundCallDragBarComponent implements OnInit, OnDestroy {
  @Output() onRefuse = new EventEmitter();
  @Output() foldAssistant = new EventEmitter();
  @Input() data;
  @Input() showAssistantIcon;
  @ViewChild('elem') elem: ElementRef;
  isDown = false;
  isMoved = false;
  disX = 0;
  disY = 0;
  private totalOffsetX = 0;
  private totalOffsetY = 0;
  private dragTm;
  parentElem: any;
  isCalling: boolean = false;
  isHolding: boolean = false;
  isMute: boolean = false;
  expandIndex: number = -1;
  customerList = [];
  showCallEndModal: boolean = false;
  callDuration: number = 0;
  callAnswerListener;

  areaCode: string = '9';
  isDialed: boolean = false;
  isCallFailed: boolean = false;
  hasFetchCustomerData: boolean = false;
  cecId;
  afterListenerId: string;
  transferInCallListenerId: string;
  consultInCallListenerId: string;
  collapsedName: string = '新用户';
  isTransfer: boolean = false;
  isConsult: boolean = false;
  isAllowExit: boolean = true;
  currentTelephonistStatus: string = '';
  answerListenerId: string = '';

  constructor(
    private renderer: Renderer2,
    private ctiApi: CtiApi,
    private router: Router,
    private ctiCallService: CtiCallService,
    private featureToggleService: FeatureToggleService,
    private currentUserService: CurrentUserService,
  ) {}

  get isShowCTI() {
    return this.featureToggleService.isFeatureEnable('CTI_INTELLIGENT_ASSISTANT', false);
  }

  ngOnInit(): void {
    this.ctiCallService.onPhoneNumChanged().subscribe(() => {
      if (this.isDisabledCall()) {
        return;
      }
      this.getCustomerList();
    });
    this.cecId = this.currentUserService.getCecGroupId();
    this.initDragElement();
    this.answerListenerId = CallCenter.addEventListener('answer', (json) => {
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.CALLING.value);
      this.isCalling = true;
      this.foldAssistantPage('answer');
      this.callAnswerListener = setInterval(() => {
        this.callDuration += 1;
      }, 1000);
    });

    this.afterListenerId = CallCenter.addEventListener('after', (json) => {
      if (_.get(json, 'cause') !== 0) {
        this.isCallFailed = true;
      }
      const phoneNum = this.ctiCallService.getCallOutPhoneNum();
      if (this.isCalling) {
        this.ctiApi.getCustomerListByMobile(this.phoneNumber).subscribe(response => {
          let callEndCustomerList = _.cloneDeep(response);
          if (this.expandIndex >= 0 && callEndCustomerList) {
            let currentInfo = null;
            _.forEach(callEndCustomerList, (item, index) => {
              if (_.eq(this.expandIndex, index)) {
                currentInfo = item;
                callEndCustomerList = _.pull(callEndCustomerList, item);
              }
            });
            if (currentInfo) {
              callEndCustomerList.unshift(currentInfo);
            }
          }
          if (this.ctiCallService.getIsTransferOver()) {
            setTimeout(() => {
              this.onRefuse.emit({
                allCustomerList: callEndCustomerList,
                cecId: this.cecId,
                phoneNum,
              });
              this.isCalling = false;
              this.isConsult = false;
              this.isTransfer = false;
              this.ctiCallService.setIsTransferOver(false);
            }, 3000);
          } else {
            this.onRefuse.emit({
              allCustomerList: callEndCustomerList,
              cecId: this.cecId,
              phoneNum,
            });
            this.isCalling = false;
            this.isConsult = false;
            this.isTransfer = false;
            const currentstatus = this.ctiCallService.getCtiIntelligentAssistantCurrentStatus();
            // 咨询中，客户挂断通话
            if (_.isEqual(currentstatus, 'consultincall')) {
              this.foldAssistantPage('afterconsult');
            }
          }
        });
      }
      this.ctiCallService.addCtiCallHistory(json, {
        called: phoneNum,
        call_type: 'CALL_OUT',
      }).subscribe(res => {
        if (res.id) {
          this.ctiCallService.updateAfterCallInfo(res);
        }
      });

      if (!this.isCallFailed) {
        this.collapsedName = '新用户';
        this.areaCode = '9';
        this.customerList = [];
        this.ctiCallService.setCallOutPhoneNum('');
        this.ctiCallService.setCallOutCustomerId('');
      }
      this.isDialed = false;
      clearInterval(this.callAnswerListener);
      CallCenter.free();
    });
  }

  addAfteSalesOnCall() {
    this.ctiCallService.addAfterSales({mobile: this.phoneNumber, type: false});
  }

  ngOnDestroy(): void {
    clearInterval(this.callAnswerListener);
    if (this.answerListenerId) {
      CallCenter.removeEventListener('answer', this.answerListenerId);
      this.answerListenerId = '';
    }
    if (this.afterListenerId) {
      CallCenter.removeEventListener('after', this.afterListenerId);
      this.afterListenerId = '';
    }
    this.ctiCallService.clearPhoneNumChanged();
  }

  get phoneNumber() {
    return this.ctiCallService.getCallOutPhoneNum();
  }

  set phoneNumber(value) {
    this.ctiCallService.setCallOutPhoneNum(value);
  }

  initDragElement(): void {
    this.parentElem = this.elem.nativeElement.parentNode.parentNode.parentNode.parentNode;
    _.delay(() => {
      this.totalOffsetX = this.parentElem.offsetWidth - this.elem.nativeElement.offsetWidth;
      this.totalOffsetY = 65 + 34;
      this.setPosition(0, 0);
    }, 10);
  }

  onMousedown(e): void {
    e.stopPropagation();
    if (e.button !== 0) { return; }

    document.documentElement.onmouseup = this.onMouseup.bind(this);
    this.elem.nativeElement.onmouseup = this.onMouseup.bind(this);
    const loop = () => {
      this.isDown = true;
      e.preventDefault();
      this.disX = e.clientX;
      this.disY = e.clientY;
      document.documentElement.onmousemove = this.onMousemove.bind(this);
      this.elem.nativeElement.onmousemove = this.onMousemove.bind(this);
    };

    if (this.dragTm) {
      clearTimeout(this.dragTm);
    }
    this.dragTm = setTimeout(loop, 10);
  }

  onMousemove(event): boolean {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isDown) {
      return false;
    }
    this.isMoved = true;
    this.setPosition(event.clientX, event.clientY);
  }

  onMouseup(event): void {
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
    }

    this.isDown = false;
    if (this.dragTm) {
      clearTimeout(this.dragTm);
    }
    document.documentElement.onmousemove = null;
    document.documentElement.onmouseup = null;
    this.elem.nativeElement.onmousemove = null;
    this.elem.nativeElement.onmouseup = null;
    if (!this.isMoved && !this.isDialed) {
      this.expandMenu();
    }
    this.isMoved = false;
  }

  setPosition(clientX, clientY, isCalling= false): void {
    let left = this.totalOffsetX + clientX - this.disX;
    if (left < 0) {
      left = 0;
    }

    let top = this.totalOffsetY + clientY - this.disY;
    if (top < 65) {
      top = 65;
    }

    const pw = this.parentElem.offsetWidth;
    const maxLeft = pw - this.elem.nativeElement.offsetWidth - 20;
    if ((left > maxLeft) && !isCalling) {
      left = maxLeft;
    }

    // TODO improve the calculate drag area efficiency.
    this.renderer.setStyle(this.elem.nativeElement, 'left', `${left}px`);
    this.renderer.setStyle(this.elem.nativeElement, 'top', `${top}px`);
  }

  handleHoldChange() {
    if (this.isMute) {
      return;
    }
    if (this.isHolding) {
      CallCenter.unmute();
    } else {
      CallCenter.closeOnlyMuteCustomer();
      CallCenter.mute();
    }
    this.isHolding = !this.isHolding;
  }

  handleMuteChange() {
    if (this.isHolding) {
      return;
    }
    if (this.isMute) {
      CallCenter.unmute();
    } else {
      CallCenter.openOnlyMuteCustomer();
      CallCenter.mute();
    }
    this.isMute = !this.isMute;
  }

  handleClickSelection(index: number, customer) {
    if (this.expandIndex === index) {
      this.expandIndex = -1;
      this.collapsedName = _.head(this.customerList).full_name;
      return;
    }
    this.expandIndex = index;
    this.collapsedName = customer.full_name;
  }

  handleNameClick(id) {
    this.router.navigate([`/customer-lead-management/customer-management/detail/customer/${id}/0`]);
  }

  handleRefuseButton() {
    CallCenter.cancelmakecall();
  }

  setUpperLeft() {
    this.setPosition(0, 0);
    this.totalOffsetX = 0;
    this.totalOffsetY = 69;
  }

  setUpperRight() {
    this.totalOffsetX = 0;
    this.disX = 0;
    this.setPosition(document.documentElement.clientWidth - 100, 65 + 34, true);
    this.totalOffsetX = document.documentElement.clientWidth - 100;
    this.totalOffsetY = 69;
  }

  handleApproveButton() {
    if (this.isDisabledCall()) {
      return;
    }
    this.getCustomerList();
    this.foldMenu();
    this.setUpperRight();
    const callNumber = this.areaCode + this.phoneNumber;
    CallCenter.callout(callNumber, null, '', null, null, {
      userData: { called: this.phoneNumber },
      uuiData: '',
    });
    this.isDialed = true;
    this.isCallFailed = false;
     // 拨出电话时，关闭弹框
    this.foldAssistantPage('callout');
  }

  expandMenu() {
    this.ctiCallService.setShouldFoldCallOutPanel(false);
    if (this.totalOffsetX > this.parentElem.offsetWidth - 300) {
      this.disX = this.totalOffsetX;
      this.setPosition(this.parentElem.offsetWidth - 300, this.totalOffsetY);
      _.delay(() => {
        this.totalOffsetX += this.parentElem.offsetWidth - 300 - this.disX;
      }, 100);
    }
  }

  foldMenu() {
    this.ctiCallService.setShouldFoldCallOutPanel(true);
  }

  get isFold() {
    return this.ctiCallService.getShouldFoldCallOutPanel();
  }

  getCustomerList() {
    this.ctiApi.getCustomerListByMobile(this.phoneNumber).subscribe(response => {
      this.hasFetchCustomerData = true;
      this.expandIndex = -1;
      this.customerList = _.cloneDeep(response);

      if (!_.isEmpty(response)) {
        this.collapsedName = _.head(this.customerList).full_name;
      } else {
        this.collapsedName = '新用户';
      }

      if (this.ctiCallService.getCallOutCustomerId() && response) {
        let currentInfo = null;
        const callOutCustomerId = this.ctiCallService.getCallOutCustomerId();
        _.forEach(response, (item, index) => {
          if (item.id === callOutCustomerId) {
            currentInfo = item;
            _.pullAt(this.customerList, parseInt(index));
            this.handleClickSelection(0, currentInfo);
          }
        });
        if (currentInfo) {
          this.customerList.unshift(currentInfo);
        }
      }
    }, () => {
      this.hasFetchCustomerData = true;
    });
  }

  openLeadVehicleList(customer) {
    let tempList = [];
    _.forEach(_.get(customer, 'leads'), lead => {
      if (this.isOpenLead(_.get(lead, 'status'))) {
        tempList = [...tempList, ...lead.interested_vehicles];
      }
    });
    return tempList;
  }

  hasOpenLead(customer, props = 'interestedVehicles') {
    return customer[props] != null;
  }

  getInterestedVehicle(customer, props = 'interestedVehicles'): string {
    if (customer[props] && customer[props].length) {
      return `${customer[props][0].brand.description_zh || ''} ${customer[props][0].class.description_zh || ''} ${customer[props][0].variant.description_zh || ''}`;
    }
    return '';
  }

  isOpenLead = status => status === 'Lead' || status === 'Lead Candidate' || status === 'Lost Sales';

  isDisabledCall() {
    return !(this.isPhoneAvailable(this.phoneNumber) || this.isTelAvailable(this.phoneNumber));
  }

  callingNumber = phoneNumber => phoneNumber ? `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 7)} ${phoneNumber.slice(7)}` : '';

  getCallDuration() {
    const time = moment.duration(this.callDuration, 'seconds');
    const hour = time.hours() < 10 ? `0${time.hours()}` : time.hours();
    const minute = time.minutes() < 10 ? `0${time.minutes()}` : time.minutes();
    const second = time.seconds() < 10 ? `0${time.seconds()}` : time.seconds();
    return `${hour}:${minute}:${second}`;
  }

  addCustomerAndLead() {
    this.ctiCallService.addCustomerAndLead(this.phoneNumber);
  }

  onNumberInputKeydown(event) {
    const key = event.key;
    if (key === 'Enter') {
      this.getCustomerList();
      return true;
    }
    if (key === 'Backspace' || key === 'Tab') {
      return true;
    }
    if (_.includes(INT_ALLOWED_KEYS_NUMBER, key)) {
      const value = event.target.value + key;
      return REGEX_NUMBER.test(value);
    }
    return false;
  }

  onAreaCodeKeydown(event) {
    const key = event.key;
    if (key === 'Backspace' || key === 'Tab') {
      return true;
    }
    if (_.includes(INT_ALLOWED_KEYS_NUMBER, key)) {
      const value = event.target.value + key;
      return REGEX_NUMBER.test(value);
    }
    return false;
  }

  isPhoneAvailable(tel) {
    const myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test(tel)) {
      return false;
    }
    return true;
  }

  isTelAvailable(tel) {
    const myreg = /^0\d{2,3}-?\d{7,8}$/;
    if (!myreg.test(tel)) {
      return false;
    }
    return true;
  }

  get shouldShowAddCustomerBtn() {
    return this.ctiCallService.getShouldShowAddCustomerBtn();
  }

  handleTransferChange() {
    if (!this.isAllowExit || this.isMute || this.isHolding) {
      return;
    }
    this.isTransfer = !this.isTransfer;
    this.currentTelephonistStatus = 'transfer';
  }

  handleConsultAdviceChange() {
    if (!this.isAllowExit || this.isMute || this.isHolding) {
      return;
    }
    this.isConsult = !this.isConsult;
    this.currentTelephonistStatus = 'seek';
  }

  setExitStatus (status) {
    this.isAllowExit = status;
  }

  get isInTransferOrConsult() {
    return !_.isEmpty(this.ctiCallService.getTelephonistStatus());
  }

  setCallStatus(param) {
    this.foldAssistantPage(param);
  }

  foldAssistantPage(event) {
    const statusList = ['consultincall', 'callout', 'afterconsult'];
    if (event === 'click') {
      this.showAssistantIcon = !this.showAssistantIcon;
    } else {
      this.showAssistantIcon = _.includes(statusList, event) ? true : false;
      this.ctiCallService.setCtiIntelligentAssistantCurrentStatus(event);
    }
    this.foldAssistant.emit(
      {
        expandAssistant: event,
        showAssistantIcon: this.showAssistantIcon,
      });
  }
}

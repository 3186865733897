import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ConfirmService {
  option: Subject<any> = new Subject();
  result: Subject<boolean | null> = new Subject();

  confirm(option) {
    this.option.next(option);
    return this.result.pipe(take(1));
  }

  onConfirm() {
    this.result.next(true);
  }

  onCancel() {
    this.result.next(false);
  }

  onClose() {
    this.result.next(null);
  }

}

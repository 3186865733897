import { Directive, HostListener, Input } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[appDecimalInput]',
})
export class DecimalInputDirective {
  @Input() maxDecimal: number = 2;

  constructor() {
  }

  @HostListener('input', ['$event'])
  onInput(event) {
    const inputElement = event.target;
    const value = inputElement.value.toString();
    const decimalCount = _.get(value.split('.'), '[1].length', 0);
    if (decimalCount > this.maxDecimal) {
      inputElement.value = inputElement.value.slice(0, value.length - (decimalCount - this.maxDecimal));
    }
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';

export interface Option {
  text: string;
  value: any;
}

@Component({
  selector: 'app-radio',
  templateUrl: 'radio.template.html',
  styleUrls: ['radio.template.scss'],
})
export class RadioComponent implements OnInit {

  @Input() options: Array<Option>;
  @Input() name: string = 'radioGroups';
  @Input() selectedOption: Option;
  @Input() control: FormControl = new FormControl();
  @Output() onChange = new EventEmitter();

  ngOnInit() {}

  handleRadioChange(option) {
    this.onChange.emit(option);
  }

  isChecked(option) {
    return _.get(this.selectedOption, 'value') === option.value || this.control.value === option.value;
  }

}

import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { IAfterGuiAttachedParams, RowNode, IDoesFilterPassParams, IFilterParams } from 'ag-grid';
import { IFilterAngularComp } from 'ag-grid-angular/main';
import * as _ from 'lodash';
import { isEmptyValue } from '../../../../../utils/common';

const defaultNumberRegexp = /^\d+(\.\d*)?$/;

@Component({
  templateUrl: 'number-range-filter.template.html',
  styleUrls: ['number-range-filter.style.scss'],
})
export class NumberRangeFilterComponent implements IFilterAngularComp {
  @ViewChild('filterFromInput', { read: ViewContainerRef }) public filterFromInput;

  params: IFilterParams;
  valueGetter: (rowNode: RowNode) => any;
  filterTo: string;
  filterFrom: string;
  debouncedChange: Function = _.debounce(() => this.onChange(), 500);
  numberRegexp: RegExp;

  agInit(params: any): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
    if (params['numberRegexp']) {
      this.numberRegexp = params['numberRegexp'];
    } else {
      this.numberRegexp = defaultNumberRegexp;
    }
  }

  isFilterActive(): boolean {
    return !(isEmptyValue(this.filterTo) && isEmptyValue(this.filterFrom));
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const value = this.valueGetter(params.node);
    if (isEmptyValue(value)) {
      return false;
    }
    const filterTo = isEmptyValue(this.filterTo) ? Number.POSITIVE_INFINITY : Number(this.filterTo);
    const filterFrom = isEmptyValue(this.filterFrom) ? Number.NEGATIVE_INFINITY : Number(this.filterFrom);
    return filterFrom <= Number(value) && filterTo >= Number(value);
  }

  getModel(): any {
    return {
      filterFrom: this.filterFrom,
      filterTo: this.filterTo,
    };
  }

  setModel(model: any): void {
    this.filterFrom = _.get(model, 'filterFrom', '');
    this.filterTo = _.get(model, 'filterTo', '');
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    this.filterFromInput.element.nativeElement.focus();
  }

  onChange(): void {
    this.params.filterChangedCallback();
  }

  onConstKeydown(event) {
    const key = event.key;
    if (key === 'Backspace') {
      return true;
    }
    return this.numberRegexp.test(event.target.value + key);
  }

}

<div [title]="tooltip" (keydown)="onKeyDown($event)" *ngIf="display">
  <div class="multiple-select-editor" [ngStyle]="selectorStyle.container">
    <app-multi-select-dropdown
      [options]="options"
      separator=","
      (onChange)="onChange($event)"
      [styles]="selectorStyle.selector"
      [placeholder]="placeholder"
      [textStyle]="errorMessage && errorStyle"
      [upturnedConfig]="upturnedConfig"
      [shouldJoinValue]="shouldJoinValue"
    >
    </app-multi-select-dropdown>
  </div>
  <div *ngIf="errorMessage" class="error-message">
    {{errorMessage}}
  </div>
</div>

import { Injectable } from '@angular/core';
import { GET, Headers, Query, RebirthHttp, RequestOptions } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DealerAllowanceApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/dealer-allowance/details:export')
  @Headers({ isExportFile: true })
  exportDealerAllowanceDetails(
    @Query('invoicedTimeBottom') invoicedTimeBottom: string,
    @Query('invoicedTimeTop') invoicedTimeTop: string,
    @Query('rightType') rightType: string,
  ): Observable<any> {
    return null;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent implements OnInit {
  @Input() options;
  @Input() checkedValue;
  @Input() name;
  @Input() id;
  @Input() control;
  @Output() onChange = new EventEmitter();

  ngOnInit() {}

  onClick(option) {
    if (option.isDisabled || this.control.value === option.value) {
      return;
    }
    this.control.setValue(option.value);
    this.onChange.emit(option);
  }
}

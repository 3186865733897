import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';

const getValue = (value, params) => {
  if (value instanceof Function) {
    return value.call(null, params);
  }
  return value;
};

@Component({
  template: ` <a (click)="linkClickedHandler($event)" href="javascript:void(0)">{{ text }}</a> `,
  styleUrls: ['./link-click-renderer.component.scss'],
})
export class LinkClickRendererComponent implements AgRendererComponent {
  params: any;
  text: string;

  agInit(params: ICellRendererParams): void {
    const cellRendererParams = params.colDef.cellRendererParams;
    this.params = params;
    this.text = getValue(cellRendererParams.text, params);
  }

  linkClickedHandler($event: MouseEvent): void {
    this.params.clicked(this.params.node.data);
  }

  refresh(params: any): boolean {
    return false;
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';
import { isObservable } from 'rxjs';

const getValue = (value, params) =>
  _.isFunction(value) ? value.call(null, params) : value;

@Component({
  template: `
    <div *ngIf="!disabled">
      <a
        [ngStyle]="linkStyle"
        [routerLink]="linkPartials"
        [queryParams]="queryParams"
        ><span (click)="handleClick($event)">{{ value }}</span></a
      >
    </div>
    <div *ngIf="disabled">
      <span>{{ value }}</span>
    </div>
  `,
  styles: [],
})
export class PartNumberRouteLinkComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  disabled: boolean;
  value: string;
  linkPartials: Array<string>;
  linkStyle: Record<string, string>;
  queryParams: Map<string, string>;
  preventDefaultEvent: boolean;

  agInit(params: ICellRendererParams): void {
    const cellRendererParams = params.colDef.cellRendererParams;
    this.params = params;
    this.value = getValue(cellRendererParams.value, params) || '';
    this.disabled = getValue(cellRendererParams.isDisable, params) || false;
    this.linkStyle = getValue(cellRendererParams.linkStyle, params);
    // this.linkPartials = this.value;
    this.preventDefaultEvent = cellRendererParams.preventDefaultEvent;
    this.queryParams = getValue(cellRendererParams.queryParams, params) || {};
    if (
      _.isFunction(cellRendererParams.linkPartials) &&
      isObservable(cellRendererParams.linkPartials(params))
    ) {
      cellRendererParams
        .linkPartials(params)
        .subscribe(value => (this.linkPartials = value));
    } else {
      this.linkPartials = cellRendererParams.linkPartials(params);
    }
  }

  handleClick($event) {
    if (this.preventDefaultEvent) {
      $event.preventDefault();
      $event.stopPropagation();
      this.params.colDef.cellRendererParams.clickLink(this.params.data);
      return;
    }
    if (this.params.colDef.cellRendererParams.tabService) {
      this.params.colDef.cellRendererParams.tabService.closeCurrentTab();
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}

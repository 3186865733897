import { toEnum } from '../../../../_common/utils/enum';

export const LOST_REASON = [
  '车型不喜欢',
  '价格不被接受',
  '存货不足',
  '已在其他地方购买了奔驰品牌汽车',
  '在同城其他店购买奔驰',
  '在同省其他店购买奔驰',
  '在外省购买奔驰',
  '已购买了其他品牌',
  '购买奥迪',
  '购买宝马',
  '购买非奔驰、奥迪或宝马',
  '地点不方便',
  '采购计划推迟',
  '无效的客户数据',
  '没能联系到客户',
  '客户没有回复',
  '未能成功取得融资',
  '未能成功以旧换新',
  '其他经销商询价',
  '重复的lead',
  '其他原因',
];

export const LOST_REASON_FOR_USED_CAR = [
  '已在本店购买奔驰二手车',
  '已在本店购买奔驰新车',
  '车型不喜欢',
  '价格不被接受',
  '存货不足',
  '已在其他地方购买了奔驰品牌汽车',
  '在同城其他店购买奔驰',
  '在同省其他店购买奔驰',
  '在外省购买奔驰',
  '已购买了其他品牌',
  '购买奥迪',
  '购买宝马',
  '购买非奔驰、奥迪或宝马',
  '地点不方便',
  '采购计划推迟',
  '无效的客户数据',
  '没能联系到客户',
  '客户没有回复',
  '未能成功取得融资',
  '未能成功以旧换新',
  '其他经销商询价',
  '重复的lead',
  '其他原因',
];

export const CUSTOMER_LATEST_INTENTION = {
  TRADE_IN: '客户有意向置换/增购',
  RETURN: '客户有意向将车辆返回至奔驰租赁',
  NEW_CAR_INTENTION: '客户有意向了解新车',
  BUY_OUT: '客户有意向付清月供/尾款购买车辆',
  BALLOON_REFINANCE: '客户有意向将尾款展期',
  NO_INTENTION: '客户暂无明确到期车辆处置意向',
  BUYOUT_PURCHASE: '客户有意向结清现车后再购置新车',
  RETURN_INTENTION: '客户有意向将车辆返还至奔驰租赁',
  RETURN_PURCHASE: '客户有意向将车辆返回至奔驰租赁并购置新车',
};

export const LEAD_SERVICE = toEnum({
  FINANCE: { value: 'Finance', text: '金融' },
  LEASE: { value: 'Lease', text: '租赁' },
  EXCHANGE_POLICY: { value: 'Exchange Policy', text: '置换政策' },
  MARKETING_ACTIVITY: { value: 'Marketing Activity', text: '市场活动' },
  SPECIAL_OFFER: { value: 'Special Offer', text: '特殊政策' },
  SIGNUP: { value: 'Signup', text: '上牌' },
  INSURANCE: { value: 'Insurance', text: '保险' },
  GUARANTEE: { value: 'Guarantee', text: '保修' },
  DECORATION: { value: 'Decoration', text: '美容' },
  EXTENDED_INSURANCE: { value: 'Extended Insurance', text: '延保' },
  MAINTENANCE: { value: 'Maintenance', text: '售后保养' },
  ACCESSORY: { value: 'Accessory', text: '精品' },
  MMC: { value: 'MMC', text: 'MMC' },
});

export const LEAD_ACTION_TYPE = {
  POST_LEAD: 'POST_LEAD',
  PUT_LEAD: 'PUT_LEAD',
  POST_SHOWROOM_VISIT: 'POST_SHOWROOM_VISIT',
  PATCH_SHOWROOM_VISIT: 'PATCH_SHOWROOM_VISIT',
};

export const PROCESS_STATUS = {
  Success: {
    text: '导入成功',
    value: 'Success',
  },
  Failure: {
    text: '导入失败',
    value: 'Failure',
  },
  InProcess: {
    text: '导入中',
    value: 'InProgress',
  },
};

export const LEAD_TYPES = {
  LEAD: 'lead',
  LOST_SALES: 'lost sales',
  LEAD_CANDIDATE: 'lead candidate',
  UNSUCCESSFUL: 'unsuccessful',
  SUCCESSFUL: 'Successful',
};

export const CURRENT_VEHICLE_ROLES = [
  { value: 'SALES', text: '购车人' },
  { value: 'AFTER_SALES', text: '售后联系人' },
  { value: 'DRIVER', text: '司机' },
];

export const CURRENT_VEHICLE_CONTACT_ROLES = toEnum({
  owners: { text: '购车人' },
  afterSales: { text: '售后联系人' },
  drivers: { text: '司机' },
  company: { text: '公司' },
});

export const LEAD_LICENSE_STATUS = toEnum({
  NEW_LICENSE: { text: '新牌照' },
  EXISTING_LICENSE: { text: '已有牌照' },
});

export const SCORE_CLASS = toEnum({
  HIGH: { value: 'HIGH', text: '高评分' },
  LOW: { value: 'LOW', text: '低评分' },
});

export const OFFER_GROUP = toEnum({
  RETENTION_CUSTOMER: { value: 'RETENTION_CUSTOMER', text: '汽车金融客户' },
  ACCIDENT_CAR: { value: 'ACCIDENT_CAR', text: '事故车' },
  LOYAL_CUSTOMER: { value: 'LOYAL_CUSTOMER', text: '忠诚客户' },
  LONG_CAR_AGE: { value: 'LONG_CAR_AGE', text: '长车龄' },
  OTHER: { value: 'OHTER', text: '其他' },
});

export const REPAYMENT_STATUS = toEnum({
  BR_ACTIVATED: { value: 'BR_ACTIVATED', text: '已展期' },
  ET_DONE: { value: 'ET_DONE', text: '已提前结清' },
  BR_ACTIVATED_AND_ET_DONE: { value: 'BR_ACTIVATED_AND_ET_DONE', text: '已展期/已提前结清' },
});

export const MOCK_DATA =  {
  driving_preference: [{
    id: 19,
    groupName: 'driving_preference',
    labelEn: 'city',
    labelCn: '城市',
    indexOrder: 1,
    isEnable: 1
  },
    {
      id: 20,
      groupName: 'driving_preference',
      labelEn: 'cross country',
      labelCn: '越野',
      indexOrder: 6,
      isEnable: 1
    },
    {
      id: 21,
      groupName: 'driving_preference',
      labelEn: 'jaunt',
      labelCn: '短途旅游',
      indexOrder: 11,
      isEnable: 1
    },
    {
      id: 22,
      groupName: 'driving_preference',
      labelEn: 'long-distance travel',
      labelCn: '长途旅游',
      indexOrder: 16,
      isEnable: 1
    },
    {
      id: 23,
      groupName: 'driving_preference',
      labelEn: 'other',
      labelCn: '其他',
      indexOrder: 21,
      isEnable: 1
    }
  ],
  purchase_purpose: [{
    id: 14,
    groupName: 'purchase_purpose',
    labelEn: 'help oneself',
    labelCn: '自用',
    indexOrder: 1,
    isEnable: 1
  },
    {
      id: 15,
      groupName: 'purchase_purpose',
      labelEn: 'home use',
      labelCn: '家用',
      indexOrder: 6,
      isEnable: 1
    },
    {
      id: 16,
      groupName: 'purchase_purpose',
      labelEn: 'commercial use',
      labelCn: '商用',
      indexOrder: 11,
      isEnable: 1
    },
    {
      id: 17,
      groupName: 'purchase_purpose',
      labelEn: 'donate',
      labelCn: '赠送',
      indexOrder: 16,
      isEnable: 1
    },
    {
      id: 18,
      groupName: 'purchase_purpose',
      labelEn: 'other',
      labelCn: '其他',
      indexOrder: 21,
      isEnable: 1
    }
  ],
  catering_preference: [{
    id: 24,
    groupName: 'catering_preference',
    labelEn: 'water',
    labelCn: '水',
    indexOrder: 1,
    isEnable: 1
  },
    {
      id: 25,
      groupName: 'catering_preference',
      labelEn: 'coffee',
      labelCn: '咖啡',
      indexOrder: 6,
      isEnable: 1
    },
    {
      id: 26,
      groupName: 'catering_preference',
      labelEn: 'tea',
      labelCn: '茶',
      indexOrder: 11,
      isEnable: 1
    },
    {
      id: 27,
      groupName: 'catering_preference',
      labelEn: 'soft drinks',
      labelCn: '软饮',
      indexOrder: 16,
      isEnable: 1
    },
    {
      id: 28,
      groupName: 'catering_preference',
      labelEn: 'special meals',
      labelCn: '特殊餐食',
      indexOrder: 21,
      isEnable: 1
    }
  ],
  interest_vehicle_colour: [{
    id: 1,
    groupName: 'interest_vehicle_colour',
    labelEn: 'black',
    labelCn: '黑色',
    indexOrder: 1,
    isEnable: 1
  },
    {
      id: 2,
      groupName: 'interest_vehicle_colour',
      labelEn: 'white',
      labelCn: '白色',
      indexOrder: 5,
      isEnable: 1
    },
    {
      id: 3,
      groupName: 'interest_vehicle_colour',
      labelEn: 'gray',
      labelCn: '灰色',
      indexOrder: 10,
      isEnable: 1
    },
    {
      id: 4,
      groupName: 'interest_vehicle_colour',
      labelEn: 'silver',
      labelCn: '银色',
      indexOrder: 15,
      isEnable: 1
    },
    {
      id: 5,
      groupName: 'interest_vehicle_colour',
      labelEn: 'suntan',
      labelCn: '棕色',
      indexOrder: 20,
      isEnable: 1
    },
    {
      id: 6,
      groupName: 'interest_vehicle_colour',
      labelEn: 'red',
      labelCn: '红色',
      indexOrder: 25,
      isEnable: 1
    },
    {
      id: 7,
      groupName: 'interest_vehicle_colour',
      labelEn: 'green',
      labelCn: '绿色',
      indexOrder: 30,
      isEnable: 1
    },
    {
      id: 8,
      groupName: 'interest_vehicle_colour',
      labelEn: 'customization',
      labelCn: '定制',
      indexOrder: 35,
      isEnable: 1
    },
    {
      id: 9,
      groupName: 'interest_vehicle_colour',
      labelEn: 'other',
      labelCn: '其他',
      indexOrder: 40,
      isEnable: 1
    }
  ],
  interest_vehicle_optional_preference: [{
    id: 10,
    groupName: 'interest_vehicle_optional_preference',
    labelEn: 'burmester',
    labelCn: '柏林之声',
    indexOrder: 1,
    isEnable: 1
  },
    {
      id: 11,
      groupName: 'interest_vehicle_optional_preference',
      labelEn: 'rear view camera',
      labelCn: '倒车影像',
      indexOrder: 5,
      isEnable: 1
    },
    {
      id: 12,
      groupName: 'interest_vehicle_optional_preference',
      labelEn: 'Head-up display',
      labelCn: '平视显示',
      indexOrder: 10,
      isEnable: 1
    },
    {
      id: 13,
      groupName: 'interest_vehicle_optional_preference',
      labelEn: 'other',
      labelCn: '其他',
      indexOrder: 15,
      isEnable: 1
    }
  ]
};

export const RESERVATION_TYPE_STATUS = toEnum({
  VISIT: { value: 'VISIT', text: '到店预约' },
  CALLBACK: { value: 'CALLBACK', text: '回电预约' },
});

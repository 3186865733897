import { Injectable } from '@angular/core';
import { Body, GET, POST, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DealerGroupInfo, IDealerGroupUserFilter } from '../models/dealer';

interface ISwitchTokenAfterChangeCurrentSelectDealerParam {
  userGroupId: number;
  branchDealerId: string;
  dealerId: string;
  clientType: string;
}

@Injectable()
export class DealerGroupApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/dealer-groups')
  getDealerGroups(): Observable<any> {
    return null;
  }

  @GET('/api/dealer-groups/dealers')
  getDealers(): Observable<DealerGroupInfo[]> {
    return null;
  }

  @GET('/api/dealer-groups/all-dealer-and-branch-not-in-share-center')
  getDealersForSSC(): Observable<DealerGroupInfo[]> {
    return null;
  }

  @GET('/api/dealer-groups/all-cec-dealers')
  getCecDealers(@Query('hasBranch') hasBranch: boolean): Observable<DealerGroupInfo[]> {
    return null;
  }

  @PUT('/api/dealer-groups')
  updateDealerGroups(@Body data: Object): Observable<any> {
    return null;
  }

  @GET('api/dealer-groups/names')
  getDealerGroupNames(): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/dealer-groups/users')
  getDealerGroupUsers(@Body data: IDealerGroupUserFilter): Observable<any> {
    return null;
  }

  @GET('/api/dealer-groups/roles')
  getDealerGroupRoles(): Observable<any> {
    return null;
  }

  @GET('/api/dealer-groups/sms/user-ids')
  getDealerGroupUserIdsWithSMS(): Observable<any> {
    return null;
  }

  @POST('/api/dealer-groups/sms')
  sendSMSToDealerGroupUsers(@Body userIds: Array<number>): Observable<any> {
    return null;
  }

  @GET('api/dealer-groups/dealers-by-higher-group')
  getDealerGroupForRegionPmaDealers(): Observable<any> {
    return null;
  }

  @POST('api/token/switch-dg-super-admin-user-group')
  switchTokenAfterChangeCurrentSelectDealer(@Body data: ISwitchTokenAfterChangeCurrentSelectDealerParam): Observable<any> {
    return null;
  }
}

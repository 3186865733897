import { Component } from '@angular/core';
import { BasicEditorComponent } from '../basic-editor.component';
import * as _ from 'lodash';
import { ICellEditorParams } from 'ag-grid';
import { isEmptyValue } from '../../../../utils/common';

@Component({
  selector: 'app-select-editor-cell',
  styleUrls: ['../editor.style.scss'],
  templateUrl: 'select-editor.template.html',
})
export class SelectEditorCellComponent extends BasicEditorComponent {
  public options: Array<any>;
  public display: boolean;

  agInit(params: ICellEditorParams): void {
    super.agInit(params);
    this.options = this.getOptions();
    this.display = this.getDisplay();
    this.value = params.value;
    this.initSelectedValue();
  }

  private initSelectedValue() {
    if (_.isNil(this.value) || _.isEmpty(this.value.toString())) {
      this.value = this.cellEditorParams.defaultValue || _.get(this.options[0], 'value');
      this.updateSourceValue();
      (this.cellEditorParams.onInit || _.noop)(this.value);
    }
  }

  get isSelectDisabled() {
    return _.isFunction(this.cellEditorParams.disabled) && this.cellEditorParams.disabled(this.params);
  }

  get selectorStyle() {
    return _.get(this.cellEditorParams, 'styles', {});
  }

  isEmptyValue() {
    return isEmptyValue(this.value);
  }

  onChange(event) {
    const oldValue = this.value;
    this.value = event;
    this.updateSourceValue(event);
    this.onChangeHandler(event, oldValue, this.options);
    this.onAfterChangedHandler(this.value, _.get(this.params, 'node.rowIndex', -1));
  }

  onUpdateEvent(value) {
    this.options = this.getOptions();
    this.display = this.getDisplay();
    this.value = _.isNil(value) ? '' : value;
    this.updateSourceValue(this.value);
  }

  private getOptions() {
    const options = this.cellEditorParams.options;
    return _.isFunction(options) ? options(this.params) : options;
  }

  private getDisplay() {
    const display = _.get(this.cellEditorParams, 'display', true);
    return _.isFunction(display) ? display(this.params) : display;
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/src/interfaces';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  selector: 'app-authorisation-column-sub-header',
  templateUrl: './authorisation-column-sub-header.template.html',
  styleUrls: ['./authorisation-column-sub-header.style.scss'],
})
export class AuthorisationColumnSubHeaderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  valueWithoutStar: string;
  showStar: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.showStar = _.includes(params.value, '*');
    this.valueWithoutStar = _.split(params.value, '*')[0];
  }

  refresh(params: any): boolean {
    return false;
  }
}

import { fromEvent as observableFromEvent, timer as observableTimer } from 'rxjs';

import { debounce, delay } from 'rxjs/operators';
import { AfterViewInit, Directive, ElementRef, forwardRef, Input, Renderer } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: '[appDebounceTime]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DebounceInputControlValueAccessorDirective),
      multi: true,
    },
  ],
})
export class DebounceInputControlValueAccessorDirective implements AfterViewInit, ControlValueAccessor {

  @Input() debounceTime: number = 800;
  @Input() delayTime: number = 200;

  onChange = () => null;
  onTouched = () => null;

  constructor(private elementRef: ElementRef, private renderer: Renderer) {

  }

  ngAfterViewInit() {
    observableFromEvent(this.elementRef.nativeElement, 'keyup').pipe(
      debounce(() => observableTimer(this.debounceTime)),
      delay(this.delayTime))
      .subscribe(() => {
        this.onChange();
      });
  }

  writeValue(value: any): void {
    const normalizedValue = _.isEmpty(value) ? '' : value;
    this.renderer.setElementProperty(this.elementRef.nativeElement, 'value', normalizedValue);
  }

  registerOnChange(fn: () => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }
}

import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import { FILTER_INPUT_TYPES, VEHICLE_BUSINESS_TYPE_OPTION_PC_VAN } from '../constants/report';
import * as moment from 'moment';
import * as _ from 'lodash';
import {
  dateFormatter,
  displayBooleanFormatter,
  displayBooleanWithNullFormatter,
  inspectionItemStatusFormatter,
  isFullDataRange,
  operatorInfoFormatter,
  tyreAgeFormatter,
  tyreAppearanceInspectionFormatter,
  tyreDepthSourceFormatter,
  tyreEccentricWearFormatter,
  vehicleAgeFormatter,
} from '../utils/utils';
import { DATE_FORMAT } from '../../_common/constants/common';
import { MultipleLinesEllipsisRenderComponent } from '../../_common/components/grid/cell-renders/multiple-lines-ellipsis/multiple-lines-ellipsis.component';

const getWidthByHeaderName = (headerName: string) => {
  const PADDING = 40;
  const SINGLE_CHAR_WIDTH = 11.4;
  return SINGLE_CHAR_WIDTH * headerName.length + PADDING;
};

export const PRE_INSPECTION_CHECK_RATE_REPORT_CONFIG = {
  name: 'pre-inspection-check-rate-report',
  enablePagination: true,
  exportedFileName: '客户到店检查率报告.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: _.map(
    [
      {
        headerName: '首次结账日期',
        field: 'firstInvoicedTime',
        width: 120,
        valueFormatter: dateFormatter,
      },
      {
        headerName: '登记进厂日期',
        field: 'checkedInDate',
        width: 120,
        valueFormatter: dateFormatter,
      },
      {
        headerName: '订单号',
        field: 'orderNumber',
        width: 180,
      },
      {
        headerName: 'SA ID',
        field: 'saId',
        width: 150,
      },
      {
        headerName: '车型',
        field: 'carModel',
        width: 120,
      },
      {
        headerName: '车龄（年）',
        field: 'vehicleAge',
        width: 150,
        valueFormatter: vehicleAgeFormatter,
      },
      {
        headerName: '里程（km）',
        field: 'mileage',
        width: 120,
      },
      {
        headerName: '检查信息录入人员',
        headerClass: 'center-box header-align-left',
        cellClass: 'center-box cell-align-left',
        field: 'operatorInfo',
        width: 140,
        cellRendererFramework: MultipleLinesEllipsisRenderComponent,
        cellRendererParams: {
          maxHeight: '36px',
          getContent: operatorInfoFormatter,
        },
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        headerName: '是否考核订单（合格预检率分母）',
        field: 'isCheckedOrder',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '是否完整',
        field: 'isComplete',
        width: 90,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '考核项是否检出（预检转化率分母）',
        field: 'isOrderCheckOut',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '检出项是否成交（预检转化率分子）',
        field: 'isOrderTraded',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '轮胎是否为必填项',
        field: 'isTyreRequiredFill',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '轮胎是否检出',
        field: 'isTyreCheckOut',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '检出轮胎是否成交',
        field: 'isTyreTraded',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '制动片是否检出',
        field: 'isBrakePieceCheckOut',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '检出制动片是否成交',
        field: 'isBrakePieceTraded',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '空调滤清器是否检出',
        field: 'isAirConditioningFilterCheckOut',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '检出空调滤清器是否成交',
        field: 'isAirConditioningFilterTraded',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '制动液是否检出',
        field: 'isBrakeFluidCheckOut',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '检出制动液是否成交',
        field: 'isBrakeFluidTraded',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '火花塞是否检出',
        field: 'isSparkPlugCheckOut',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '检出火花塞是否成交',
        field: 'isSparkPlugTraded',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '变速箱油是否检出',
        field: 'isTransmissionFluidCheckOut',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '检出变速箱油是否成交',
        field: 'isTransmissionFluidTraded',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '是否豁免',
        field: 'isRemission',
        width: 90,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '是否事故车',
        field: 'isAccidentCar',
        width: 110,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '是否参与审计',
        field: 'isJoinedAudit',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '审计结果',
        field: 'auditStatus',
        width: 100,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '审计不通过原因	',
        field: 'auditNotPassReason',
        isAutoWidth: true,
        valueFormatter: displayBooleanFormatter,
      },
      {
        headerName: '左前轮胎品牌',
        field: 'leftFrontTyreTyreBrand',
        width: 150,
      },
      {
        headerName: '左前轮胎型号',
        field: 'leftFrontTyreTyreModel',
        width: 150,
      },
      {
        headerName: '左前轮胎胎纹深度（mm）',
        field: 'leftFrontTyreTreadDepth',
        width: 200,
      },
      {
        headerName: '左前胎纹是否拍照',
        field: 'isLeftFrontTyreDepthTakenPhotos',
        width: 150,
        valueFormatter: displayBooleanWithNullFormatter,
      },
      {
        headerName: '左前轮胎生产日期',
        field: 'leftFrontTyreProductionDate',
        width: 140,
      },
      {
        headerName: '左前轮胎胎龄（年）',
        field: 'leftFrontTyreAge',
        width: 120,
        valueFormatter: tyreAgeFormatter,
      },
      {
        headerName: '左前轮胎外观检查',
        field: 'leftFrontTyreAppearanceInspection',
        width: 300,
        headerClass: 'center-box header-align-left',
        cellClass: 'center-box cell-align-left',
        cellRendererFramework: MultipleLinesEllipsisRenderComponent,
        cellRendererParams: {
          maxHeight: '36px',
          getContent: tyreAppearanceInspectionFormatter,
        },
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        headerName: '左前轮胎偏磨',
        field: 'leftFrontTyreEccentricWear',
        width: 120,
        headerClass: 'center-box header-align-left',
        cellClass: 'center-box cell-align-left',
        cellRendererFramework: MultipleLinesEllipsisRenderComponent,
        cellRendererParams: {
          maxHeight: '36px',
          getContent: tyreEccentricWearFormatter,
        },
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        headerName: '左前制动片',
        field: 'leftFrontTyreBrakePiece',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '左前制动盘',
        field: 'leftFrontTyreBrakeDisc',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '左前胎纹深度测量来源',
        field: 'leftFrontTyreTyreDepthUpdateSource',
        isAutoWidth: true,
        valueFormatter: tyreDepthSourceFormatter,
      },
      {
        headerName: '右前轮胎品牌',
        field: 'rightFrontTyreTyreBrand',
        width: 150,
      },
      {
        headerName: '右前轮胎型号',
        field: 'rightFrontTyreTyreModel',
        width: 150,
      },
      {
        headerName: '右前轮胎胎纹深度（mm）',
        field: 'rightFrontTyreTreadDepth',
        width: 200,
      },
      {
        headerName: '右前胎纹是否拍照',
        field: 'isRightFrontTyreDepthTakenPhotos',
        width: 150,
        valueFormatter: displayBooleanWithNullFormatter,
      },
      {
        headerName: '右前轮胎生产日期',
        field: 'rightFrontTyreProductionDate',
        width: 140,
      },
      {
        headerName: '右前轮胎胎龄（年）',
        field: 'rightFrontTyreAge',
        width: 120,
        valueFormatter: tyreAgeFormatter,
      },
      {
        headerName: '右前轮胎外观检查',
        field: 'rightFrontTyreAppearanceInspection',
        width: 300,
        headerClass: 'center-box header-align-left',
        cellClass: 'center-box cell-align-left',
        cellRendererFramework: MultipleLinesEllipsisRenderComponent,
        cellRendererParams: {
          maxHeight: '36px',
          getContent: tyreAppearanceInspectionFormatter,
        },
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        headerName: '右前轮胎偏磨',
        field: 'rightFrontTyreEccentricWear',
        width: 120,
        headerClass: 'center-box header-align-left',
        cellClass: 'center-box cell-align-left',
        cellRendererFramework: MultipleLinesEllipsisRenderComponent,
        cellRendererParams: {
          maxHeight: '36px',
          getContent: tyreEccentricWearFormatter,
        },
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        headerName: '右前制动片',
        field: 'rightFrontTyreBrakePiece',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '右前制动盘',
        field: 'rightFrontTyreBrakeDisc',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '右前胎纹深度测量来源	',
        field: 'rightFrontTyreTyreDepthUpdateSource',
        isAutoWidth: true,
        valueFormatter: tyreDepthSourceFormatter,
      },
      {
        headerName: '左后轮胎品牌',
        field: 'leftAfterTyreTyreBrand',
        width: 150,
      },
      {
        headerName: '左后轮胎型号',
        field: 'leftAfterTyreTyreModel',
        width: 150,
      },
      {
        headerName: '左后轮胎胎纹深度（mm）',
        field: 'leftAfterTyreTreadDepth',
        width: 200,
      },
      {
        headerName: '左后胎纹是否拍照',
        field: 'isLeftAfterTyreDepthTakenPhotos',
        width: 150,
        valueFormatter: displayBooleanWithNullFormatter,
      },
      {
        headerName: '左后轮胎生产日期',
        field: 'leftAfterTyreProductionDate',
        width: 140,
      },
      {
        headerName: '左后轮胎胎龄（年）',
        field: 'leftAfterTyreAge',
        width: 120,
        valueFormatter: tyreAgeFormatter,
      },
      {
        headerName: '左后轮胎外观检查',
        field: 'leftAfterTyreAppearanceInspection',
        width: 300,
        headerClass: 'center-box header-align-left',
        cellClass: 'center-box cell-align-left',
        cellRendererFramework: MultipleLinesEllipsisRenderComponent,
        cellRendererParams: {
          maxHeight: '36px',
          getContent: tyreAppearanceInspectionFormatter,
        },
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        headerName: '左后轮胎偏磨',
        field: 'leftAfterTyreEccentricWear',
        width: 120,
        headerClass: 'center-box header-align-left',
        cellClass: 'center-box cell-align-left',
        cellRendererFramework: MultipleLinesEllipsisRenderComponent,
        cellRendererParams: {
          maxHeight: '36px',
          getContent: tyreEccentricWearFormatter,
        },
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        headerName: '左后制动片',
        field: 'leftAfterTyreBrakePiece',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '左后制动盘',
        field: 'leftAfterTyreBrakeDisc',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '左后胎纹深度测量来源	',
        field: 'leftAfterTyreTyreDepthUpdateSource',
        isAutoWidth: true,
        valueFormatter: tyreDepthSourceFormatter,
      },
      {
        headerName: '右后轮胎品牌',
        field: 'rightAfterTyreTyreBrand',
        width: 150,
      },
      {
        headerName: '右后轮胎型号',
        field: 'rightAfterTyreTyreModel',
        width: 150,
      },
      {
        headerName: '右后轮胎胎纹深度（mm）',
        field: 'rightAfterTyreTreadDepth',
        width: 200,
      },
      {
        headerName: '右后胎纹是否拍照',
        field: 'isRightAfterTyreDepthTakenPhotos',
        width: 150,
        valueFormatter: displayBooleanWithNullFormatter,
      },
      {
        headerName: '右后轮胎生产日期',
        field: 'rightAfterTyreProductionDate',
        width: 140,
      },
      {
        headerName: '右后轮胎胎龄（年）',
        field: 'rightAfterTyreAge',
        width: 120,
        valueFormatter: tyreAgeFormatter,
      },
      {
        headerName: '右后轮胎外观检查',
        field: 'rightAfterTyreAppearanceInspection',
        width: 300,
        headerClass: 'center-box header-align-left',
        cellClass: 'center-box cell-align-left',
        cellRendererFramework: MultipleLinesEllipsisRenderComponent,
        cellRendererParams: {
          maxHeight: '36px',
          getContent: tyreAppearanceInspectionFormatter,
        },
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        headerName: '右后轮胎偏磨',
        field: 'rightAfterTyreEccentricWear',
        width: 120,
        headerClass: 'center-box header-align-left',
        cellClass: 'center-box cell-align-left',
        cellRendererFramework: MultipleLinesEllipsisRenderComponent,
        cellRendererParams: {
          maxHeight: '36px',
          getContent: tyreEccentricWearFormatter,
        },
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        headerName: '右后制动片',
        field: 'rightAfterTyreBrakePiece',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '右后制动盘',
        field: 'rightAfterTyreBrakeDisc',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '右后胎纹深度测量来源	',
        field: 'leftAfterTyreTyreDepthUpdateSource',
        isAutoWidth: true,
        valueFormatter: tyreDepthSourceFormatter,
      },
      {
        headerName: '空调滤清器',
        field: 'airConditioningFilter',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '制动液',
        field: 'brakeFluid',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '火花塞',
        field: 'sparkPlug',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '变速箱油',
        field: 'transmissionFluid',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '雨刮片',
        field: 'frontAndRearWiperBlades',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '启动蓄电池',
        field: 'vehicleMountedBattery12v',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
      {
        headerName: '发动机皮带状况',
        field: 'engineBeltOrTensionerStatus',
        width: 120,
        valueFormatter: inspectionItemStatusFormatter,
      },
    ],
    (item) => ({
      ...item,
      width: item.isAutoWidth ? getWidthByHeaderName(item.headerName) : item.width,
    }),
  ),
  filter: [
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'firstInvoicedTime',
      label: '首次结账日期',
      isRangeLimited: true,
      limitValueRange: 12,
      endLimitValue: moment(),
      defaultBeginDate: moment().startOf('month').format(DATE_FORMAT),
      defaultEndDate: moment().format(DATE_FORMAT),
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车辆类型:',
      options: VEHICLE_BUSINESS_TYPE_OPTION_PC_VAN.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION_PC_VAN.PC.value,
    },
  ],
  validators: [
    {
      fieldName: 'firstInvoicedTime',
      errorMsg: '请选择首次结账日期',
      isValid: isFullDataRange,
    },
  ],
};

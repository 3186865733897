<div>
  <div #dropdownSelect="bs-dropdown" class="select-wrapper" dropdown
       [isDisabled]="disabled"
       [class.disabled]="disabled"
       [ngStyle]="style"
       [dropup]="isDropUp"
       (onHidden)="clearKeyword()" >
    <button id="button-basic" dropdownToggle
            type="button"
            class="input-control input-control-middle"
            [class.disabled]="disabled"
            [class.error]="errorMessage">
      {{displayText}}
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
        (mousewheel)="scrollBar($event)">
      <li class="dropdown-item search-input" *ngIf="searchable">
        <input
          [(ngModel)]="keyword"
          [placeholder]="keywordPlaceholder"
          (keydown)="onKeyDown($event)"
          (keyup)="handleFilter($event)"
          (click)="preventEmitEvent($event)"
          (select)="preventEmitEvent($event)"
        />
      </li>
      <li
        *ngFor="let option of filteredOptions"
        class="dropdown-item "
        (click)="handleClick(option)"
      >
        <div [innerHtml]="getOptionHtml(option)"></div>
      </li>
    </ul>
  </div>
  <div *ngIf="errorMessage" class="error-message" [ngStyle]="errorMessageStyle">
    {{errorMessage}}
  </div>
</div>

import * as _ from 'lodash';
import * as moment from 'moment';
import { JobCardCollection, LaborItem, JobCardWithLaborItems, Technican } from '../_common/models/job-card';
import {
  CHANGE_STATUS_ACTION_CONFIG,
  DATE_TIME_FORMAT, LABOUR_ITEM_STATUS,
  ON_HOLD_LABOUR_ITEM_STATUS,
} from './constant';
const notOnHoldStatus = [LABOUR_ITEM_STATUS.COMPLETED.value];

const orderByStatus = labourItem => LABOUR_ITEM_STATUS[labourItem.status].sortNumber;

const isOtherTechnicianWorkingAndNotCanceled = (labourItem, currentTechnicianId) =>
  (!_.isNil(labourItem.technicianId) && currentTechnicianId !== labourItem.technicianId)
  || labourItem.status === LABOUR_ITEM_STATUS.CANCELED.value
  || labourItem.status === LABOUR_ITEM_STATUS.CONVERTED.value;

const getItemStatus = labourItems => _.map(labourItems, 'status');

export const sortLabourItems = (labourItems, currentTechnicianId = null) =>
  _.orderBy(labourItems, [(labourItem: LaborItem) =>
    isOtherTechnicianWorkingAndNotCanceled(labourItem, currentTechnicianId), orderByStatus]);

export const isItemWithStatusCanSelected = (selectedItems, status) => {
  const selectedStatus = getItemStatus(selectedItems);

  if (_.isEmpty(selectedStatus)) {
    return true;
  }

  switch (status) {
    case LABOUR_ITEM_STATUS.IN_PROGRESS.value:
      return _.includes(selectedStatus, LABOUR_ITEM_STATUS.IN_PROGRESS.value);
    // 原有
    case LABOUR_ITEM_STATUS.INITIATED.value:
    case LABOUR_ITEM_STATUS.ON_HOLD_FOR_TECH.value:
    case LABOUR_ITEM_STATUS.ON_HOLD_FOR_PART.value:
    case LABOUR_ITEM_STATUS.ON_HOLD_FOR_AUTH.value:
      return _.intersection(selectedStatus, [LABOUR_ITEM_STATUS.IN_PROGRESS.value, LABOUR_ITEM_STATUS.COMPLETED.value]).length === 0;
    case LABOUR_ITEM_STATUS.COMPLETED.value:
      return _.includes(selectedStatus, LABOUR_ITEM_STATUS.COMPLETED.value);
    default:
      return false;
  }
};

export const isValidActionForSelectItem = (selectedItems, status) => {
  const selectedStatus = getItemStatus(selectedItems);

  if (_.isEmpty(selectedStatus)) {
    return false;
  }

  switch (status) {
    case CHANGE_STATUS_ACTION_CONFIG.ON_HOLD.value:
      return _.intersection(selectedStatus, [LABOUR_ITEM_STATUS.IN_PROGRESS.value]).length > 0;
    case CHANGE_STATUS_ACTION_CONFIG.COMPLETED.value:
      return _.includes(selectedStatus, LABOUR_ITEM_STATUS.IN_PROGRESS.value);
    case CHANGE_STATUS_ACTION_CONFIG.IN_PROGRESS.value:
      return _.intersection(selectedStatus, notOnHoldStatus).length === 0;
    default:
      return false;
  }
};

export const isEmpty = (jobCardsCollection: JobCardCollection) =>
  jobCardsCollection.today.length === 0 && jobCardsCollection.past.length === 0;

export const getWorkJobCardIds = (labourItems, currentTechnicianId, workingOnJobCardIds, jobCardId) => {
  const workingOnLabourItem = _.find(labourItems, labourItem =>
    (labourItem.technicianId === currentTechnicianId
      && LABOUR_ITEM_STATUS.IN_PROGRESS.value === labourItem.status));
  const jobCardIds = [...workingOnJobCardIds];

  if (_.isEmpty(workingOnLabourItem)) {
    _.remove(jobCardIds, workingJobCardId => workingJobCardId === jobCardId);
  } else if (!_.includes(jobCardIds, jobCardId)) {
    jobCardIds.push(jobCardId);
  }

  return jobCardIds;
};

export const formatTime = (date, format = DATE_TIME_FORMAT) =>
  _.isEmpty(date) ? null : moment(date).format(format);

export const getStatusType = status => {
  if (LABOUR_ITEM_STATUS.IN_PROGRESS.value === status) {
    return '开钟';
  }

  if (isOnHold(status)) {
    return '中断';
  }

  return '关钟';
};

export const getOnHoldReason = status => {
  if (isOnHold(status)) {
    return ON_HOLD_LABOUR_ITEM_STATUS[status].text;
  }
  return '';
};

const isOnHold = status => {
  return _.includes(_.map(ON_HOLD_LABOUR_ITEM_STATUS, 'value'), status);
};

export const isSkillful = (labors: LaborItem[], technicianSkills: string[]) =>
  _.every(labors, labor => {
    return _.isEmpty(labor.skillCodes) || _.intersection(labor.skillCodes, technicianSkills).length > 0;
  });

export const getInProgressLaborIds = (jobCards: JobCardWithLaborItems[], technicianId: number): number[] =>
  _.chain(jobCards)
    .get('0.laborItems')
    .filter(item => item.status === LABOUR_ITEM_STATUS.IN_PROGRESS.value &&
      (item.technicianId === technicianId || _.includes(_.map(item.assistanceTechnicians, 'id'), technicianId)))
    .map('id')
    .value();

export const getInProgressLaborIdsBatch = (jobCards: JobCardWithLaborItems[], technicianId: any, technicianNameList = []): any => {
  const technicianIdList = typeof technicianId === 'number' ? [technicianId] : technicianId;
  let jobCardDetailResponses = [];
  let laborItems = [];
  if (technicianIdList.length > 1) {
    jobCardDetailResponses = _.flatMapDeep(_.map(jobCards, 'jobCardDetailResponses'));
    laborItems = _.flatMapDeep(_.map(jobCardDetailResponses, 'laborItems'));
  } else {
    laborItems = _.flatMapDeep(_.map(jobCards, 'laborItems'));
  }
  // 已选择的技师正在进行中的labor
  const inProgressLabor = laborItems.filter(element => element.status === LABOUR_ITEM_STATUS.IN_PROGRESS.value);
  // 作为主技师进行的laborId
  const inProgressLaborIdsMajor = [];
  // 作为辅助技师进行的laborId
  const inProgressLaborIdsAssist = [];
  // 以技师维度划分以主技师进行的labor和以辅助技师进行的labor
  const technicianAndLabors = [];
  technicianIdList.forEach((element, index) => {
    const current = { id: element, name: technicianNameList[index], majorLabors: [], assistLabors: [] };
    inProgressLabor.forEach((item: LaborItem) => {
      const ifAsMajor = _.includes(_.map(item.majorTechnician, 'id'), element);
      const ifAsAssist = _.includes(_.map(item.assistanceTechnicians, 'id'), element);
      if (ifAsMajor) {
        if (current.majorLabors.filter(i => i.id === item.id).length === 0) {
          current.majorLabors.push(item);
          inProgressLaborIdsMajor.push(item.id);
        }
      }
      if (ifAsAssist) {
        if (current.assistLabors.filter(i => i.id === item.id).length === 0) {
          current.assistLabors.push(item);
          inProgressLaborIdsAssist.push(item.id);
        }
      }
    });
    technicianAndLabors.push(current);
  });
  return {
    technicianAndLabors,
    inProgressLaborIdsMajor,
    inProgressLaborIdsAssist,
  };
};

export const tcmLastLaborTip = (jobCards, laborItemIds, technicianId, selectedItems) => {
  const selectedMajorTechnician = _.flatMapDeep(_.map(selectedItems, 'majorTechnician'));
  const selectedAssistTechnician = _.flatMapDeep(_.map(selectedItems, 'assistanceTechnicians'));
  // 是否是主技师关钟
  const clockOffByMaster = _.includes(_.flatMapDeep(_.map(selectedMajorTechnician, 'id')), technicianId);
  // 已选辅助 技师的id
  const assistTechnicianIds = _.map(selectedAssistTechnician, 'id');
  const hasAssistTechnician = assistTechnicianIds.length > 0;
  // 所有的labor
  const laborItems = _.flatMapDeep(_.map(jobCards, 'laborItems'))
    .filter(element => element.status === LABOUR_ITEM_STATUS.IN_PROGRESS.value);
  // 没有选择的labor, 以及没有选择的labor的辅助技师集合
  const notSelectedItems = laborItems.filter(element => !_.includes(laborItemIds, element.id) && element.status === 'IN_PROGRESS');
  const notSelectedMajorTechnicianId = _.map(_.flatMapDeep(_.map(notSelectedItems, 'majorTechnician')), 'id');
  const notSelectedAssistTechnicianId = _.map(_.flatMapDeep(_.map(notSelectedItems, 'assistanceTechnicians')), 'id');
  const ifLastLabor = assistTechnicianIds.some(element => !_.includes(notSelectedMajorTechnicianId, element) &&
    !_.includes(notSelectedAssistTechnicianId, element));
  // 主技师关钟，辅助技师被动关钟,且是辅助技师的最后一项，提示信息
  return clockOffByMaster && hasAssistTechnician && ifLastLabor;
};

export const contLastLaborTip = (selectedItems, updateStatus) => {
  const ifHaveMajorTechnician = !_.isEmpty(updateStatus.majorTechnicianIds);
  if (!ifHaveMajorTechnician) {
    return;
  }
  const ifHaveAssistTechnician = selectedItems.some(element => !_.isEmpty(element.assistanceTechnicians));
  let message = '';
  if (ifHaveAssistTechnician && _.includes(updateStatus.laborItemStatus, 'ON_HOLD')) {
    message = '中断操作会将该工项上的辅助技师同时关钟';
  }
  if (ifHaveAssistTechnician && updateStatus.laborItemStatus === 'COMPLETED') {
    message = '关钟操作会将该工项上的技师同时关钟';
  }
  return message;
};

export const lastLaborOption = (technicianAndLabors, laborItemIds) => {
  const majorNames: string[] = [];
  const assistNames: string[] = [];
  const majorIds: string[] = [];
  const assistIds: string[] = [];
  technicianAndLabors.forEach(element => {
    const itemMajorIds = _.map(element.majorLabors, 'id');
    const itemAssistIds = _.map(element.assistLabors, 'id');
    const lastMajor = !_.isEmpty(itemMajorIds) && _.isEmpty(_.difference(itemMajorIds, laborItemIds));
    const lastAssist = !_.isEmpty(itemAssistIds) && _.isEmpty(_.difference(itemAssistIds, laborItemIds));
    if (lastMajor && (lastAssist || _.isEmpty(itemAssistIds))) {
      majorNames.push(element.name);
      majorIds.push(element.id);
    }
    if (lastAssist && _.isEmpty(itemMajorIds)) {
      assistNames.push(element.name);
      assistIds.push(element.id);
    }
  });
  const flag = !_.isEmpty(majorNames) || !_.isEmpty(assistNames);
  const returnValue = {
    flag,
    majorNames,
    assistNames,
    majorIds,
    assistIds,
  };
  return returnValue;
};

export const getTechnicianList = (updateStatus, ifTCM, technicianId, technicianName) => {
  const technicianIdList = ifTCM ? technicianId :
    _.uniq([...updateStatus.majorTechnicianIds, ...updateStatus.assistanceTechniciansIds]);
  const technicianNameList = ifTCM ? technicianName :
    _.uniq([...updateStatus.majorTechnicianNames, ...updateStatus.assistanceTechniciansNames]);
  const technicianIdStr = technicianIdList.join(',');
  return {
    technicianIdList,
    technicianNameList,
    technicianIdStr,
  };
};

export const getClockOffParams = (updateStatus, technicianAndLabors, laborItemIds) => {
  const common = _.pick(updateStatus, [
    'laborItemIds', 'laborItemStatus', 'technicianId', 'technicianName',
  ]);
  const params = [];
  if (_.isEmpty(updateStatus.majorTechnicianIds)) {
    technicianAndLabors.forEach(item => {
      common.technicianId = item.id;
      common.technicianName = item.name;
      common.laborItemIds = _.intersection([..._.map(item.majorLabors, 'id'), ..._.map(item.assistLabors, 'id')], laborItemIds);
      params.push({ ...common });
    });
  } else {
    params.push({ ...common });
  }
  return params.length > 1 ? params : params[0];
};

export const showAssistMessage = (workingOnJobCard, technicianIds) => {
  let inProgressLabors = [];
  if (workingOnJobCard.laborItems) {
    inProgressLabors = workingOnJobCard.laborItems.filter(element => element.status === LABOUR_ITEM_STATUS.IN_PROGRESS.value);
  } else {
    const jobCardDetailResponses = _.flatMapDeep(_.map(workingOnJobCard, 'jobCardDetailResponses'));
    const laborItems = _.flatMapDeep(_.map(jobCardDetailResponses, 'laborItems'));
    inProgressLabors = laborItems.filter(element => element.status === LABOUR_ITEM_STATUS.IN_PROGRESS.value);
  }
  const assistTechnicians = _.map(inProgressLabors, 'assistanceTechnicians');
  const assistTechnicianIds = _.uniq(_.map(_.flatMapDeep(assistTechnicians), 'id'));
  const technicianList = technicianIds.toString().split(',');
  let message;
  if (technicianList.some(element => _.includes(assistTechnicianIds, Number(element)))) {
    message = '有进行中的辅助工项，选择”全部中断“会结束辅助工作';
  }
  return message;
};

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { encryptedCarPlateNumber, encryptedVin } from '../utils/encrypted';

@Pipe({
  name: 'stringDisplayFormat',
})
export class StringDisplayFormatPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return value ? (value.length === 11 ? value.replace(/(\d{3})(\d{4})/, '$1 $2 ') : value) : '—';
  }
}

@Pipe({
  name: 'fullNameCombiner',
})
export class FullNameCombiner implements PipeTransform {
  transform(value: any, arg?: any): any {
    return _.get(value, 'lastName', '') + _.get(value, 'firstName', '');
  }
}

@Pipe({
  name: 'stringEmptyToDash',
})
export class StringEmptyToDash implements PipeTransform {
  transform(value: string): string {
    return value || '-';
  }
}

@Pipe({
  name: 'encryptedVinOrCarPlateNumberPipe',
})
export class EncryptedVinOrCarPlateNumberPipe implements PipeTransform {
  transform(value: any, ...args): any {
    const [isEncrypted, objectKey] = args;

    switch (objectKey) {
      case 'vin':
        return isEncrypted ? encryptedVin(value) : value;
      case 'carPlateNumber':
        return isEncrypted ? encryptedCarPlateNumber(value) : value;
      default:
        return value;
    }
  }
}

<div class="inbound-call-telephonist">
  <h2 class="inbound-call-telephonist-title">坐席状态</h2>
  <div class="inbound-call-telephonist-active">
    <div class="inbound-call-telephonist-list-title">
      <img [src]="'/assets/inbound-call/telephonist-icon-active.png'" alt="">
      <p>空闲</p>
    </div>
    <div class="no-active" *ngIf="!availableList.length">
      <p>无空闲坐席</p>
    </div>
    <ul *ngIf="availableList.length">
      <li class="inbound-call-telephonist-list" *ngFor="let data of availableList;">
        <div class="list-left">
          <p>{{data.operatorid}}</p>
          <p>{{data.name}}</p>
        </div>
        <div class="list-right" *ngIf="type === 'seek'">
          <div class="consult" [ngClass]="{'disabled': isDialing || isCalling}" *ngIf="data.operatorid !== calledId">
            <img [src]="isDialing || isCalling ? '/assets/inbound-call/little-seek-icon-disabled.png' : '/assets/inbound-call/little-seek-icon-default.png'" alt="">
            <p class="button" (click)="handleConsultClicked(data)">咨询</p>
          </div>
          <div class="consulting" *ngIf="data.operatorid === calledId && isDialing">
            <p>等待接通…</p>
            <div (click)="handleCancelConsult()">取消</div>
          </div>
          <div class="consult-fault" *ngIf="data.operatorid === calledId && isFailed">
            <img class="fault-icon" src="/assets/inbound-call/telephonist-icon-fault.png" alt="">
            <p>呼叫失败</p>
            <div (click)="handleConsultClicked(data)">重试</div>
          </div>
          <div class="calling" *ngIf="data.operatorid === calledId && isCalling">
            <div class="calling-time">
              <p>已接通咨询</p>
              <p>{{getCallDuration}}</p>
            </div>
            <div class="button" (click)="handleCancelConsult()">挂断</div>
          </div>
        </div>
        <div class="list-right" *ngIf="type === 'transfer'">
          <div class="consult" [ngClass]="{'disabled': isCalling}" *ngIf="data.operatorid !== calledId">
            <img [src]="isCalling ? '/assets/inbound-call/little-transfer-cion-disabled.png' : '/assets/inbound-call/little-transfer-cion-default.png'" alt="">
            <p class="button" (click)="handleTransferClicked(data)">转接</p>
          </div>
          <div class="consulting" *ngIf="data.operatorid === calledId && isDialing">
            <p>等待接通…</p>
            <div (click)="handleCancelTransfer()">取消</div>
          </div>
          <div class="consult-fault" *ngIf="data.operatorid === calledId && isFailed">
            <img class="fault-icon" src="/assets/inbound-call/telephonist-icon-fault.png" alt="">
            <p>呼叫失败</p>
            <div (click)="handleTransferClicked(data)">重试</div>
          </div>
          <div class="consulting" *ngIf="data.operatorid === calledId && isCalling">
            <p>转接成功，即将挂断({{transferOverLeft}})</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="inbound-call-telephonist-busy">
    <div class="inbound-call-telephonist-list-title">
      <img [src]="'/assets/inbound-call/telephonist-icon-busy.png'" alt="">
      <p>忙线</p>
    </div>
    <div class="no-active" *ngIf="!busyList.length">
      <p>无忙线坐席</p>
    </div>
    <ul *ngIf="busyList.length">
      <li class="inbound-call-telephonist-list" *ngFor="let data of busyList">
        <div class="list-left">
          <p>{{data.operatorid}}</p>
          <p>{{data.name}}</p>
        </div>
      </li>
    </ul>
  </div>
</div>

import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-search-checkbox',
  styleUrls: ['./search-checkbox.style.scss'],
  templateUrl: 'search-checkbox.template.html',
})
export class SearchCheckboxComponent implements OnInit, OnChanges {
  options: Array<any>;
  searchWords: string;
  checkedOptions: Array<string>;
  filtedOptions: Array<any>;
  isVisible: boolean = false;
  selfClick: boolean;
  dealerRange: Array<string> = [];

  @Input() initialOptions: Array<any>;
  @Input() initialCheckedOptions: Array<string>;
  @Input() containerStyle: any;
  @Input() canEmpty: boolean;
  @Input() displayTextMode: string;
  @Input() style: any;
  @Input() shouldDisableCheckbox: boolean = false;

  @Output() submit = new EventEmitter();

  @HostListener('document:click')
  onClick() {
    if (!this.selfClick) {
      this.isVisible = false;
      this.ngOnInit();
    }
    this.selfClick = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!_.isEqual(
        _.get(changes, 'initialOptions.previousValue', []),
      _.get(changes, 'initialOptions.currentValue', []),
      )) {
      this.filtedOptions = changes.initialOptions.currentValue;
    }
    if (!_.isEqual(
        _.get(changes, 'initialCheckedOptions.previousValue', []),
      _.get(changes, 'initialCheckedOptions.currentValue', []),
      )) {
      this.checkedOptions = changes.initialCheckedOptions.currentValue;
      this.dealerRange = this.checkedOptions;
    }
  }

  ngOnInit() {
    this.filtedOptions = this.initialOptions;
    this.checkedOptions = this.initialCheckedOptions;
    this.dealerRange = this.checkedOptions;
    this.searchWords = '';
  }

  get allInitailOptionValues(): Array<string> {
    return _.map(this.initialOptions, option => _.get(option, 'dealer_id', ''));
  }

  get allFiltedOptionValues(): Array<string> {
    return _.map(this.filtedOptions, option => _.get(option, 'dealer_id', ''));
  }

  get checkedText() {
    return this.shouldShowAllCheckbox ? `已选择${this.checkedOptions.length}/${this.allInitailOptionValues.length}条` : '无搜索结果';
  }

  get isAllChecked() {
    return _.every(this.allFiltedOptionValues, value => _.includes(this.checkedOptions, value));
  }

  get shouldShowAllCheckbox() {
    return !_.isEmpty(this.filtedOptions);
  }

  get isFormInvalid() {
    return !this.canEmpty && this.checkedOptions.length === 0;
  }

  isContain(option) {
    return (new RegExp(this.searchWords)).test(option.dealer_name);
  }

  isChecked(value) {
    return _.includes(this.checkedOptions, value);
  }

  onAllClick() {
    this.checkedOptions = this.isAllChecked ?
      _.difference(this.checkedOptions, this.allFiltedOptionValues)
      : _.union(this.checkedOptions, this.allFiltedOptionValues);
  }

  onCheckboxClick(event) {
    const value = event.target.value;
    this.checkedOptions = this.isChecked(value) ? _.without(this.checkedOptions, value) : _.concat(this.checkedOptions, value);
  }

  search() {
    this.filtedOptions = _.filter(this.initialOptions, this.isContain.bind(this));
  }

  onConfirm() {
    this.isVisible = false;
    this.dealerRange = this.checkedOptions;
    this.submit.emit(this.dealerRange);
  }

  onEnterKeyDown(event) {
    this.search();
    event.preventDefault();
    event.stopPropagation();
  }

  toggleSearchCheckbox() {
    this.isVisible = !this.isVisible;
  }

  onMouseClick() {
    this.selfClick = true;
  }

  get displayText() {
    if (this.isEditdisplayTextMode()) {
      return this.dealerRange.length === this.initialOptions.length ? '全部' : `${this.dealerRange.length}家`;
    }
    return this.dealerRange.length ?
      `已选择${this.dealerRange.length === this.initialOptions.length ?
        '全部' : `${this.dealerRange.length}家`}`
      : '';
  }

  isEditdisplayTextMode() {
    return this.displayTextMode === 'edit';
  }
}

import { ICellRendererParams } from 'ag-grid';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import {
  DEALER_REMINDER_STATUS_FILTER,
  REMINDER_STATUS_TAG_STYLE,
} from '../../../../../dedicated-technical-reminder/constants/reminder-status';
import { OptionPipe } from '../../../../pipes/option.pipe';
import { findTextByValue } from '../../../../../report-center/utils/utils';

const optionPipe = new OptionPipe();

@Component({
  template: `
    <div [class]="hostClassName" *ngIf="visible">
      <span>{{ text }}</span>
    </div>
  `,
  styleUrls: ['./reminder-tag-renderer.component.scss'],
})
export class ReminderTagRendererComponent implements ICellRendererAngularComp {
  text: string;
  visible: boolean;
  hostClassName: string;

  agInit(params: ICellRendererParams): void {
    this.hostClassName = optionPipe.transform(params.value, REMINDER_STATUS_TAG_STYLE);
    this.text =  findTextByValue(DEALER_REMINDER_STATUS_FILTER)(params.value);
    this.visible = !_.isEmpty(this.text);
  }

  refresh(params: any): boolean {
    return false;
  }
}

<div class="select-filter-box">
  <label class="select-label select-label-all">
    <input type="checkbox" (change)="onAllChange($event)" [checked]="getIsAllChecked()" />
    全部
  </label>
  <div class="select-filter-group">
    <label *ngFor="let option of options | async" class="select-label">
      <input type="checkbox"
             [value]="option.value"
             (change)="onChange($event, option)"
             [checked]="isSelect(option)" />
      {{option.text | lowercaseBrand}}
    </label>
  </div>
  <div class="search-filter-group">
    <input
      type="text"
      class="search-input search-form"
      style="width: 110px; height: 26px;"
      [formControl]="searchInput"
      (focus)="emptyShareDealer()"
      placeholder="搜索"
      maxlength="255"
    >
  </div>
  <span class="btn-cancel" (click)="onCancel()">
    取消
  </span>
  <button class="btn-save select-btn" type="submit" (click)="onSubmit()">
    确定
  </button>
</div>

import { throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OrderPaymentCollectionApi } from '../api/order-payment-collection';
import * as _ from 'lodash';

import { ERROR_MESSAGES } from '../constants/error-messages';
import { CurrentUserService, DealerIntegrationToggleApi, PromptBarService, PromptLevel } from '@otr/website-common';

@Injectable()
export class OrderPaymentCollectionService {

  constructor(private dealerIntegrationToggleApi: DealerIntegrationToggleApi,
              private orderPaymentCollectionApi: OrderPaymentCollectionApi,
              private currentUserService: CurrentUserService,
              private promptBarService: PromptBarService) {
  }

  getPaymentIntegrationEnable() {
    return this.dealerIntegrationToggleApi.integrationToggles(this.currentUserService.getCurrentUser().dealerId).pipe(
    map(featureToggleList =>
      _.get(_.find(featureToggleList, ['name', 'RETAIL_PAYMENT_COLLECTION']), 'enabled', false)));
  }

  collectPayment(id) {
    return this.orderPaymentCollectionApi.changeCollectionStatus({ id, collection_status: 'COLLECTED' }).pipe(
      catchError(error => {
        if (error.status === 500) {
          this.promptBarService.show(ERROR_MESSAGES.UPDATE_FAILED);
          return observableThrowError(error);
        }
        if (error.status === 400) {
          this.promptBarService.show('电商车辆已被预订，无法收取订金!', PromptLevel.Error);
          return observableThrowError(error);
        }
      }));
  }

  notCollectPayment(id) {
    return this.orderPaymentCollectionApi.changeCollectionStatus({ id, collection_status: 'NOT_COLLECTED' });
  }
}

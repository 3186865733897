import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmedNotification, TipsInformationResponse } from '../accessory-discount';
import { defaultConfirmedNotification, defaultTipsInfo } from './config';

@Injectable({
  providedIn: 'root',
})
export class ApplyListComponentService {
  showConfirmedNotification$ = new BehaviorSubject<ConfirmedNotification>(defaultConfirmedNotification);
  showTipsInfo$ = new BehaviorSubject<TipsInformationResponse>(defaultTipsInfo);
}

import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer } from '@angular/core';

@Directive({
  selector: '[appAutoLocation]',
})
export class AutoLocationDirective implements OnChanges, AfterViewInit {

  @Input() clientX: number;
  @Input() clientY: number;

  constructor(public element: ElementRef, public renderer: Renderer) {
  }

  ngOnChanges() {
    this.reset();
  }

  ngAfterViewInit() {
    this.reset();
  }

  reset() {
    setTimeout(() => {
      const nativeElement = this.element.nativeElement;
      this.renderer.setElementStyle(nativeElement, 'position', 'absolute');
      this.renderer.setElementStyle(nativeElement, 'top', `${this.clientY - nativeElement.clientHeight}px`);
      this.renderer.setElementStyle(nativeElement, 'left', `${this.clientX}px`);
    });
  }
}

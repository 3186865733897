import { Injectable } from '@angular/core';

@Injectable()
export class TabSwitcherService {
  isPartsPurchaseRoute: boolean;
  isPartsRoute: boolean;
  private _isVanType: boolean = false;

  public get isVanType(): boolean {
    return this._isVanType;
  }
  public set isVanType(isVanType: boolean) {
    this._isVanType = isVanType;
  }
}

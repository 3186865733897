import { BehaviorSubject, combineLatest as observableCombineLatest, Subject } from 'rxjs';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

import { MODAL_CONFIG } from '../../../../_common/constants/common';
import { CHINESE_CALENDAR_CONFIG } from '../../../../_common/constants/calendar';
import { dateParse } from '../../../../_common/utils/date-parse';
import { getDateAtLastSecond } from '../../../../_common/utils/date';
import { SalesLeadModalContent } from '../sales-lead-modal/sales-lead-modal.component';

import { FormUtilService } from '../../../../_common/services/form-util.service';
import { ERROR_MESSAGES } from '../../../../_common/constants/error-messages';
import { isDormantLead, isOpenLead } from '../../../../_common/utils/lead';
import { ModalMessageService } from '@otr/website-common';

@Component({
  selector: 'app-add-follow-up-modal',
  templateUrl: './add-follow-up-modal.component.html',
  styleUrls: ['./add-follow-up-modal.component.scss'],
})
export class AddFollowUpModalComponent implements SalesLeadModalContent, OnInit {
  modalConfig: Object = {
    ...MODAL_CONFIG,
    keyboard: false,
  };
  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;

  followUpPlan: Array<any> = [
    { value: 'keepFollowUp', text: '继续跟进', isVisible: true },
    { value: 'appointment', text: '预约进店', isVisible: () => !this.isUsedCarLeadDetailFlag && this.canAppointment },
    { value: 'closeLead', text: '关闭线索', isVisible: () => this.isUsedCarLeadDetailFlag || this.canCloseLead },
  ];

  followUpPlanControl: FormControl = new FormControl(this.followUpPlan[0].value);
  maxDate = new Date(getDateAtLastSecond(new Date()));
  followUpTime: FormControl = new FormControl(dateParse(new Date(), 'yyyy-MM-dd HH:mm'), [
    Validators.required, control => (new Date(control.value).getTime() > Date.now() ? { followUpTime: false } : null),
  ]);
  _isInvalid$: Subject<any> = new BehaviorSubject(false);
  isInvalid$: Subject<any> = new BehaviorSubject(true);
  nextPlan = null;
  invalidStyle = { 'border-color': '#E84522' };
  enableSelectSC = true;
  minDate: Date;
  @Input() leadId = null;
  @Input() customerId = null;
  @Input() canAssignScAndCloseLead: boolean = false;
  @Input() scOptions: Array<any> = [];
  @Input() leadStatus = null;
  @Input() scName = null;
  @Input() leadCreateTime;
  @Input() currentChannel = null;
  @Input() scGroupInfos: Array<any> = [];
  @Input() ownerSalesConsultantId: string = null;
  @Input() isUsedCarLeadDetailFlag: boolean = false;

  @ViewChild('nextPlan') set setNextPlan(nextPlan) {
    if (!!nextPlan) {
      this.nextPlan = nextPlan;
      observableCombineLatest(this._isInvalid$, this.nextPlan.isInvalid$)
        .subscribe(([isInvalid, isChildInvalid]) => {
          this.isInvalid$.next(isInvalid || isChildInvalid);
        });
    }
  }

  constructor(private modalMessageService: ModalMessageService,
              private formUtilService: FormUtilService) {
  }

  ngOnInit(): void {
    this.minDate = new Date(this.leadCreateTime);
    this.followUpPlan = _.chain(this.followUpPlan)
      .filter(({ isVisible }) => (_.isFunction(isVisible) ? isVisible() : isVisible))
      .map(plan => _.omit(plan, 'isVisible'))
      .value();
    this.followUpTime
      .valueChanges
      .subscribe(() => this._isInvalid$.next(this.followUpTime.invalid));
    this.enableSelectSC = !this.isDormantLeadHasOwnerSC;
  }

  getFormValue() {
    return {
      type: this.followUpPlanControl.value,
      record: {
        ...this.nextPlan.getFormValue(),
        leadId: this.leadId,
        followUpTime: moment(this.followUpTime.value).valueOf(),
      },
    };
  }

  isVisible(type) {
    return this.followUpPlanControl.value === type;
  }

  handleFollowUpPlanChange() {
    this.modalMessageService.removeMessage({ message: ERROR_MESSAGES.FORM_VALUE_INVALID });
    this.formUtilService.markAsTouched$.next(false);
  }

  get isOpenLeadHasNoOwnerSC() {
    return !this.scName && isOpenLead({ status: this.leadStatus });
  }

  get isDormantLeadHasOwnerSC() {
    return this.scName && isDormantLead(this.currentChannel);
  }

  get canAppointment() {
    return this.isOpenLeadHasNoOwnerSC || this.isDormantLeadHasOwnerSC;
  }

  get canCloseLead() {
    return this.isOpenLeadHasNoOwnerSC;
  }

}

<div (click)="onMouseClick()">
  <select class="select" disabled="true" [class.placeholder]="isVisible && isEditdisplayTextMode()" [ngStyle]="style || {}">
    <option value="displayText" [selected]="!isVisible">{{displayText}}</option>
    <option value="placeholder" class="placeholder" [selected]="isVisible">{{ isEditdisplayTextMode() ? '请选择' : ''}}</option>
  </select>
  <div class="checkbox-switch" (click)="toggleSearchCheckbox()" [ngStyle]="style || {}"></div>
  <div class="search-checkbox-panel" [ngStyle]="containerStyle" *ngIf="isVisible">
    <div class="search-panel">
      <input class="search-input" [(ngModel)]="searchWords" (keydown.enter)="onEnterKeyDown($event)"/>
      <em class="fa antd-search-outlined search-icon" (click)="search()"></em>
    </div>
    <div class="checkbox-area">
      <div class="checkbox-header">
        <div class="checkbox" *ngIf="shouldShowAllCheckbox">
          <input type="checkbox" id="allChecked" [checked]="isAllChecked" (click)="onAllClick()" [disabled]="shouldDisableCheckbox">
          <label for="allChecked">全部</label>
        </div>
        <span>{{checkedText}}</span>
      </div>
      <div class="checkbox-group">
        <div class="checkbox margin-bottom-10" *ngFor="let option of filtedOptions">
          <input type="checkbox" [id]="option.dealer_id" [value]="option.dealer_id" (click)="onCheckboxClick($event)"
                 [checked]="isChecked(option.dealer_id)" [disabled]="shouldDisableCheckbox">
          <label [for]="option.dealer_id">{{option.dealer_name}}</label>
        </div>
      </div>
    </div>
    <div class="button-panel">
      <button class="btn btn-primary" (click)="onConfirm()" [disabled]="shouldDisableCheckbox || isFormInvalid">确定</button>
    </div>
  </div>
</div>

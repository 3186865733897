import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';
import { ACCEPTABLE_LEAD_TYPE } from '../../../../constants/lead';
import { CurrentUserService } from '@otr/website-common';

@Component({
  template: `
    <img src="../../../../../../assets/workshop/complete-edit.svg" style='width: 20px' class="biddingIcon"
         (click)="onShareLead()" [tooltip]="'编辑'" placement="bottom" *ngIf='isMyOpenLeadHasNoOwnerSC()'/>
    <img src="../../../../../../assets/common/calendar-blue.svg" class="waitBiddingIcon"
         (click)="addFollowUpWithLeadId()" [tooltip]="'跟进'" placement="bottom"  *ngIf='isMyOpenLeadHasNoOwnerSC()'/>
  `,
  styleUrls: ['../image-button/image-button.style.scss'],
})
export class UsedCarActionCellComponent implements AgRendererComponent {
  params;

  constructor(private currentUserService: CurrentUserService) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  onShareLead() {
    'onShareLead' in this.params.column.colDef.cellRendererParams ?
      this.params.column.colDef.cellRendererParams.onShareLead(this.params.data)
      :
      ((): void => {})();
  }
  addFollowUpWithLeadId() {
    'addFollowUpWithLeadId' in this.params.column.colDef.cellRendererParams ?
      this.params.column.colDef.cellRendererParams.addFollowUpWithLeadId(this.params.data)
      :
      ((): void => {})();
  }
  isMyOpenLeadHasNoOwnerSC() {
    return (this.isCDOOwner || this.isUEOwner) && this.isOpenLead;
  }
  get isCDOOwner() {
    return this.currentUserService.getCurrentUser().userId === this.params.data.cdoId;
  }
  get isOpenLead() {
    return _.includes(ACCEPTABLE_LEAD_TYPE, _.lowerCase(this.params.data.leadStatus));
  }
  get isUEOwner() {
    return this.currentUserService.getCurrentUser().userId === _.get(this.params.data, 'ownerUeumId', '');
  }
  refresh(params: any): boolean {
    return false;
  }
}

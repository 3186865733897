import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GET, POST, RebirthHttp, Body, Query } from 'rebirth-http';

@Injectable()
export class DealersHardwareApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/hardware-info/getAllDealer')
  getAllDealers(@Query('currentPage') current: number, @Query('pageSize') size: number): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/hardware-info/getDealers')
  getDealersByName(
    @Query('currentPage') current: number,
    @Query('pageSize') size: number,
    @Query('keyword') keyword: string,
  ): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/hardware-info/getDealerInfo')
  getDealerDetailById(@Query('dealerId') dealerId: string): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/hardware-info/synDealerInfo')
  setDealerHardware(@Body data): Observable<any> {
    return null;
  }
}

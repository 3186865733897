/* tslint:disable */
import './tingyun'
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment.qa';
import { hmrBootstrap } from './hmr';
import { initZhugeSDK } from './zhuge';

if (environment.production) {
  enableProdMode();
  window['_paq'].push(['setTrackerUrl', environment.piwikUrl]);
  window['_paq'].push(['setSiteId', environment.piwikSiteId]);
}

initZhugeSDK(environment.zhugeAppKey);

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: true });

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.warn('HMR is not enabled for dev-server!');
  }
} else {
  bootstrap();
}

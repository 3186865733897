import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { CHINESE_REGEXP, EMAIL_REGEXP, NEGATIVE_NUMBER_REGEXP, NUMBER_REGEXP } from '../constants/common';

const isBlank = value => _.isEmpty(_.trim(value));

export const requiredValidator: ValidatorFn = (control: AbstractControl) => {
  if (isBlank(control.value)) {
    return { message: '不允许为空' };
  }
  return null;
};

export const requiredInputValidator: ValidatorFn = (control: AbstractControl) => {
  if (isBlank(control.value)) {
    return { message: '请输入必填项' };
  }
  return null;
};

export const mobileValidator: ValidatorFn = (control: AbstractControl) => {
  if (!isBlank(control.value) && !/^1\d{10}$/.test(control.value)) {
    return { message: '手机号码格式错误' };
  }
  return null;
};

const isEmptyOrValidEmail = (emailAddress: string) =>
  isBlank(emailAddress) || !(!EMAIL_REGEXP.test(emailAddress) || CHINESE_REGEXP.test(emailAddress));

export const emailValidator: ValidatorFn = (control: AbstractControl) => {
  if (isEmptyOrValidEmail(control.value)) {
    return null;
  }
  return { message: '邮箱格式错误' };
};

export const multipleEmailValidator = (control: AbstractControl) =>
  (
    _.chain(control.value)
      .split(';')
      .every(isEmptyOrValidEmail)
      .value() ? null : { message: '邮箱格式错误' }
  );

export const postCodeValidator: ValidatorFn = ({ value }: AbstractControl) => {
  if (!isBlank(value) && !/^[0-9]{6}$/.test(value)) {
    return { message: '邮编格式错误' };
  }
  return null;
};

export const postCodeRequiredValidator: ValidatorFn = ({ value }: AbstractControl) => {
  if (isBlank(value)) {
    return { message: '请完善信息' };
  }
  return null;
};

export const numberValidator = (control: AbstractControl) => {
  if (!isBlank(control.value) && !NUMBER_REGEXP.test(control.value)) {
    return { message: '只能填入数字' };
  }
  return null;
};

export const negativeNumberValidator = (control: AbstractControl) => {
  if (!isBlank(control.value) && !NEGATIVE_NUMBER_REGEXP.test(control.value)) {
    return { message: '只能填入数字' };
  }
  return null;
};

export const numberGtZeroValidator = (control: AbstractControl) => {
  if (!isBlank(control.value) && (!NUMBER_REGEXP.test(control.value) || !_.gt(control.value, 0))) {
    return { message: '只能填入大于0的数字' };
  }
  return null;
};

export const vinValidator = (control: AbstractControl) => {
  if (!isBlank(control.value) && !/^[A-Za-z0-9]{17}$/.test(control.value)) {
    return { message: '只能填入17位数字或字母' };
  }
  return null;
};

export const finValidator = (control: AbstractControl) => {
  if (!isBlank(control.value) && !/^[A-Za-z0-9]{17,18}$/.test(control.value)) {
    return { message: '只能填入17位或18位数字或字母' };
  }
  return null;
};

export const nonBlankValidator = (control: AbstractControl) => {
  if (isBlank(control.value)) {
    return { message: '请完善必填信息' };
  }
  return null;
};

export const fieldLengthValidator = (control: AbstractControl, length = 10) => {
  if (!control.value || control.value.length < length) {
    return { message: '请输入至少10个字符' };
  }
  return null;
};

export const dropdownValidator = (control: AbstractControl) => {
  if (_.isNil(control.value)) {
    return { message: '请完善必填信息' };
  }
  return null;
};

export const calendarValidator = (control: AbstractControl) => {
  if (_.isNil(control.value)) {
    return { message: '请完善必填信息' };
  }
  return null;
};

export const rangeValidator = (control: AbstractControl) => {
  const { from, to } = control.value;
  if (_.toNumber(from) - _.toNumber(to) > 0
  || _.isNil(from)
  || _.isNil(to)) {
    return {
      error: true,
    };
  }
  return null;
};

export const rangeYearValidator = (control: AbstractControl) => {
  const { from, to } = control.value;
  if (_.toNumber(from) - _.toNumber(to) > 0
  || _.isNil(from)
  || _.isNil(to)) {
    return {
      error: true,
    };
  }
  return null;
};

export const optionalRangeValidator = (control: AbstractControl) => {
  const { from, to } = control.value;
  if ((_.isNumber(from) && _.isNumber(to) && _.toNumber(from) - _.toNumber(to) > 0)
    || (_.isNil(from) && _.isNil(to))) {
    return {
      error: true,
    };
  }
  return null;
};

export const courtesyCarRequiredValidator: ValidatorFn = (control: AbstractControl) => {
  if (isBlank(control.value)) {
    return { required: true };
  }
  return null;
};

export const courtesyCarNumberValidator: ValidatorFn = (control: AbstractControl) => {
  if (isBlank(control.value)) {
    return null;
  }
  if (!NUMBER_REGEXP.test(control.value)) {
    return { number: true };
  }
  return null;
};

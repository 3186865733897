<div
  #elem
  id="app-intelligent-assistant"
  [ngStyle]="{'min-width': assistantElem}"
  [ngClass]="foldMinFlag ? 'reset': ''"
  (mousedown)="onMousedown($event)"
>
  <div *ngIf="callStatus !== 'consultincall'" class="content" [ngStyle]="{'min-width': assistantElem}">
    <div class="header" [ngStyle]="showIntelligentModal ? { 'background-color': 'transparent' } : {}">
      <div class="icon-box" (click)="minAssistant()">
        <div class="min"></div>
      </div>
    </div>
    <div style="height:100%;width:100%;">
      <iframe [src]="ctiSafeUrl" frameborder="0" width="100%" height="100%" scrolling="no"></iframe>
    </div>
  </div>
  <div *ngIf="callStatus === 'consultincall'" class="content" [ngStyle]="{'min-width': assistantElem}">
    <div class="header">
      <div class="icon-box" (click)="minAssistant()">
        <div class="min"></div>
      </div>
    </div>
    <div class="warning">
      <p>内部咨询中，语音助手不可使用。</p>
    </div>
  </div>
</div>

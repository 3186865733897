import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import { GridEvents } from 'app/_common/components/grid/event/events';

@Component({
  templateUrl: './edit-row-with-flag.component.html',
  styleUrls: ['./edit-row-with-flag.component.scss'],
})
export class EditRowWithFlagComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  disabled: boolean;
  defaultLabel: boolean;
  markMainItemAction: any;
  hideLabel: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.markMainItemAction = params.colDef.cellRendererParams.markMainItemAction;
    this.disabled = params.colDef.cellRendererParams.isEditable;

    this.shouldNotDisableWhenOnEdit(params.colDef.cellRendererParams.isEditable);

    this.resetStatus();
    this.params.api.addEventListener(GridEvents.EVENT_ROW_DATA_UPDATED, () => this.resetStatus());
  }

  private resetStatus() {
    const { node: { data }, colDef } = this.params;
    this.hideLabel = data.isMarked;
    this.defaultLabel = colDef.cellRendererParams.shouldShowMarked(data);
  }

  refresh(params: any): boolean {
    return true;
  }

  selectedItem() {
    this.markMainItemAction(this.params.node.data);
  }

  shouldNotDisableWhenOnEdit(isEditor) {
    this.disabled = !isEditor.value;
    isEditor.subscribe(value => this.disabled = !value);
  }
}

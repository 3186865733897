import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GET, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { OutletInformationType } from 'app/_common/models/outlet.type';
// import { OutletInformationType } from '../../parts/setting/_common/models/outlet.type';

@Injectable()
export class DealerApi extends RebirthHttp {
  static PARTS_WEBSITE_PREFIX = '/api/parts-website';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/dealer-code')
  getDealerGSCode(@Query('dealer_id') dealerId): Observable<any> {
    return null;
  }

  @GET(`${DealerApi.PARTS_WEBSITE_PREFIX}/account-management/outlets`)
  public getDealers(): Observable<Array<OutletInformationType>> {
    return null;
  }

  @GET('api/cec/dealers-in-cec')
  getCecDealerList(@Query('dealerId') dealerId): Observable<any> {
    return null;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CtiMainComponent } from './cti-main.component';
import { TooltipModule } from 'ngx-bootstrap';
import { InboundCallDragBarComponent } from './inbound-call-drag-bar/inbound-call-drag-bar.component';
import { CallEndModalComponent } from './call-end-modal/call-end-modal.component';
import { CommonComponentModule } from '../../_common/common-component.module';
import { OutboundCallDragBarComponent } from './outbound-call-drag-bar/outbound-call-drag-bar.component';
import { TelephonistStatusComponent } from './telephonist-status/telephonist-status.component';
import { CalendarModule } from 'primeng/components/calendar/calendar';
import { IntelligentAssistantComponent } from './intelligent-assistant/intelligent-assistant.component';
import { CustomerLeadManagementSharedModule } from '../../customer-lead-management/customer-lead-management-shared.module';
import { CTIAddFollowUpModalComponent } from './add-follow-up-modal/add-follow-up-modal.component';
import { CTIAssignScModalComponent } from './assign-sc-modal/assign-sc-modal.component';
import { CTIKeepFollowUpComponent } from './keep-follow-up/keep-follow-up.component';
import { CTICloseLeadModalComponent } from './close-lead-modal/close-lead-modal.component';

@NgModule({
  entryComponents: [
    CTIAddFollowUpModalComponent,
    CTIKeepFollowUpComponent,
    CTIAssignScModalComponent,
    CTICloseLeadModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule.forRoot(),
    NgZorroAntdModule.forRoot(),
    CommonComponentModule,
    CalendarModule,
    ReactiveFormsModule,
    CustomerLeadManagementSharedModule,
  ],
  declarations: [
    CtiMainComponent,
    InboundCallDragBarComponent,
    CallEndModalComponent,
    OutboundCallDragBarComponent,
    TelephonistStatusComponent,
    IntelligentAssistantComponent,
    CTIAddFollowUpModalComponent,
    CTIKeepFollowUpComponent,
    CTIAssignScModalComponent,
    CTICloseLeadModalComponent
  ],
  exports: [
    CtiMainComponent,
    InboundCallDragBarComponent,
    CallEndModalComponent,
    OutboundCallDragBarComponent,
    TelephonistStatusComponent,
    IntelligentAssistantComponent,
    CTIAddFollowUpModalComponent,
    CTIKeepFollowUpComponent,
    CTIAssignScModalComponent,
    CTICloseLeadModalComponent
  ],
})
export class CtiMainModule {}

<div class="select-filter-box">
  <div class="select-filter-group">
    <label class="select-label">
      <input type="radio"
             value="NONE"
             class="radio-button"
             name="search-or-select"
             [formControl]="radio"
      />
      {{label || '未填写'}}
    </label>
    <div class="filter-box padding-bottom-0">
      <input
        id="input"
        value="NORMAL"
        type="radio"
        class="radio-button"
        name="search-or-select"
        [formControl]="radio"
      />
      <input
        *ngIf="!isNumber"
        type="text"
        class="search-input search-form"
        [formControl]="searchInput"
        (focus)="radio.setValue('NORMAL')"
        placeholder="请输入"
        maxlength="255"
      >
      <input
        *ngIf="isNumber"
        type="text"
        class="search-input search-form"
        [formControl]="searchInput"
        (focus)="radio.setValue('NORMAL')"
        maxlength="11" appNumberFormat
        placeholder="请输入"
      >
    </div>
  </div>
  <span class="btn-cancel" (click)="onCancel()">
    取消
  </span>
  <button class="btn-save select-btn" type="submit" (click)="onSubmit()">
    确定
  </button>
</div>

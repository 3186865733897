import * as _ from 'lodash';
import { dateParse } from '../../_common/utils/date-parse';
import { STOCK_ORDER_STATUS } from '../../_common/constants/vehicle';

export const YES_NO = {
  YES: { key: 'Yes', label: '是' },
  NO: { key: 'No', label: '否' },
};

export const confirmedStockOrderStatus = [
  STOCK_ORDER_STATUS.INVOICING.value,
  STOCK_ORDER_STATUS.CONFIRMED.value,
  STOCK_ORDER_STATUS.INVOICED_INVALIDATED.value,
  STOCK_ORDER_STATUS.INVOICED.value,
  STOCK_ORDER_STATUS.CANCEL_WAIT_CREDIT.value,
  STOCK_ORDER_STATUS.MODIFIED_WAIT_CREDIT.value,
  STOCK_ORDER_STATUS.DELIVERED.value,
];

export const STOCK_INVOICED_STATUSES = [
  STOCK_ORDER_STATUS.INVOICED.value,
  STOCK_ORDER_STATUS.CANCEL_WAIT_CREDIT.value,
  STOCK_ORDER_STATUS.MODIFIED_WAIT_CREDIT.value,
  STOCK_ORDER_STATUS.INVOICED_INVALIDATED.value,
];

export const ORDER_STATUS = {
  CREATED: { value: 'CREATED', text: '已创建' },
  DEPOSITED: { value: 'DEPOSITED', text: '已创建' },
  CONFIRMED: { value: 'CONFIRMED', text: '已确认' },
  INVOICING: { value: 'INVOICING', text: '已确认' },
  CONFIRMED_INVALIDATED: { value: 'CONFIRMED_INVALIDATED', text: '已确认' },
  INVOICED_INVALIDATED: { value: 'INVOICED_INVALIDATED', text: '已开票' },
  INVOICED: { value: 'INVOICED', text: '已开票' },
  MODIFIED_WAIT_CREDIT: { value: 'MODIFIED_WAIT_CREDIT', text: '已开票' },
  CANCEL_WAIT_CREDIT: { value: 'CANCEL_WAIT_CREDIT', text: '已开票' },
  DELIVERED: { value: 'DELIVERED', text: '已交车' },
  APPROVED: { value: 'APPROVED', text: '已审批' },
  CANCELED: { value: 'CANCELED', text: '已取消' },
};

export const INVOICED_STATUSES = [
  ORDER_STATUS.INVOICED.value,
  ORDER_STATUS.MODIFIED_WAIT_CREDIT.value,
  ORDER_STATUS.CANCEL_WAIT_CREDIT.value,
  ORDER_STATUS.INVOICED_INVALIDATED.value,
];

export const TABLE_FIELDS_FILTER = {
  visibility: value => _.get(_.find(YES_NO, { key: value }), 'label', null),
  order_status: (value, order) => getOrderStatusText(order),
  estimated_arrived_date: value => value && dateParse(value),
};

export function getOrderStatusText(order) {
  if (isDeliveredOrder(order)) {
    return STOCK_ORDER_STATUS.DELIVERED.text;
  }
  const result = _.find(STOCK_ORDER_STATUS, { value: order.order_status });
  return result ? result.text : null;
}

function isDeliveredOrder(order) {
  const orderDelivered = !_.isEmpty(order.actual_delivery_date);
  const deliveredNotInvoiced = orderDelivered && !_.includes(STOCK_INVOICED_STATUSES, order.order_status);
  const invoicedBeforeDelivery = orderDelivered
    && _.includes(STOCK_INVOICED_STATUSES, order.order_status)
    && !_.isEmpty(order.retail_invoice_date)
    && new Date(order.retail_invoice_date) > new Date(order.actual_delivery_date);
  return deliveredNotInvoiced || invoicedBeforeDelivery;
}

export function getSupplierAccountInfo(stock) {
  if (stock.supplier_account_name) {
    return { code: stock.supplier_account, name: stock.supplier_account_name };
  }
  return { code: stock.supplier_account };
}

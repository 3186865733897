import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-labeled',
  templateUrl: './labeled.component.html',
  styleUrls: ['./labeled.component.scss'],
})
export class LabeledComponent {

  @Input()
  label: string;

  @Input()
  comment: string;

  @Input()
  required: boolean = false;

  @Input()
  @HostBinding('class')
  className: string = '';

  @Input()
  id: any = '';

}

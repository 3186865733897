import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

@Component({
  selector: 'app-template-cell',
  templateUrl: './template-cell.component.html',
  styleUrls: ['./template-cell.component.scss'],
})
export class TemplateCellComponent implements AgRendererComponent {
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

}


    <div>
      <div *ngFor="let item of value;let index=index"
           [ngClass]="{
           'grey-text': index!==0,
           'is-ellipsis': isMultiRowCell(item) ? item?.isEllipsis: false
           }"
           [title]="isMultiRowCell(item) ? item?.value: item">
       {{isMultiRowCell(item) ? item?.value : item}}
      </div>
    </div>
  
<div class="filter-box">
  <div class="input-container">
    <input
      #filterFromInput
      class="form-control range-form-control range-left"
      (input)="debouncedChange($event)"
      (keydown)="onConstKeydown($event)"
      [(ngModel)]="filterFrom">
    <span class="range-line"></span>
    <input
      class="form-control range-form-control range-right"
      (input)="debouncedChange($event)"
      (keydown)="onConstKeydown($event)"
      [(ngModel)]="filterTo">
  </div>
</div>
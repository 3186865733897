import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, ElementRef, ViewChild } from '@angular/core';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};
@Component({
  template: `
    <div #container class='text-ellipsis-tooltip-container'>
      <div
        class='text'
        nz-tooltip
        [nzPlacement]="placement"
        [nzTitle]='wrap'
        nzOverlayClassName='overlayStyle'
      >{{ ellipsisText }}</div>
      <ng-template #wrap>
        <div [innerHTML]="wrapText | safeHtml"></div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./text-ellipsis-nz-tooltip.style.scss'],
})
export class TextEllipsisNzTooltipComponent implements ICellRendererAngularComp {
  content: string;
  ellipsisText: string;
  canShowTooltip: boolean = false;
  overlayStyle: object;
  wrapText: string;
  placement: string = 'bottomLeft';
  length: number = 50;

  private params: ICellRendererParams;
  cellRendererParams: any;

  @ViewChild('container') container: ElementRef;

  // tslint:disable-next-line:cyclomatic-complexity
  agInit(params): void {
    this.params = params;
    this.cellRendererParams = params.colDef.cellRendererParams;
    const content = getValue(this.cellRendererParams.content, params);
    this.placement = getValue(this.cellRendererParams.placement, params) ?
      getValue(this.cellRendererParams.placement, params) : this.placement;
    this.length = this.cellRendererParams.length ? this.cellRendererParams.length : 50;
    this.wrapText = content.replace(/\n/g, '</br>');
    this.ellipsisText = content.length > this.length ? `${content.slice(0, this.length)}...` : content;
  }

  refresh(params: any): boolean {
    return false;
  }
}

<div #modal class="confirm-modal" [style.left.px]="left" [style.top.px]="top"
  [class.no-description]="!description"
>
  <div class="info">
    <p class="title">{{ title }}</p>
    <p *ngIf="description" class="description">{{ description }}</p>
  </div>

  <div class="options">
    <button class="btn btn-reject" (click)="cancel()">{{ cancelText }}</button>
    <button class="btn btn-primary" (click)="confirm()">{{ confirmText }}</button>
  </div>
</div>


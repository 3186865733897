import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

@Component({
  template: `
      <div class="activateStatusTooltip">
          <ng-template #tooltipTemplate>
              <span class="tooltipText">{{value}}</span>
          </ng-template>
          {{value.substr(0, 40)}}
          <span *ngIf="value!=='-'"
                [tooltip]="tooltipTemplate"
                placement="bottom">...</span>
      </div>
  `,
  styleUrls: ['./advanced-text-eclipsis-cell-component.style.scss'],
})
export class AdvancedTextEclipsisCellComponent implements ICellRendererAngularComp {
  public value: string;
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}

import { Injectable } from '@angular/core';
import { DealerApi } from '../api/dealer';
import { DealerGroupApi } from '../api/dealer-group';
import { Observable } from 'rxjs';
import { Pubsub } from '../../_helper';
import { OutletInformationType } from 'app/_common/models/outlet.type';
import * as _ from 'lodash';
import { CurrentUserService, TokenService, DealerIntegrationToggleService } from '@otr/website-common';

const DEALER_BAR_SELECT_AREA = 'dealer-bar-select-area';

const WHITE_LIST = [
  {
    baseUrl: '/',
    link: '/report-center/front-desk',
    name: '前台管理',
  },
  {
    baseUrl: '/',
    link: '/customer-lead-management/customer-management/customer',
    name: '客户管理',
  },
  {
    baseUrl: '/',
    link: '/report-center/customer',
    name: '客户管理',
  },
  {
    baseUrl: '/',
    link: '/report-center/finance',
    name: '财务管理',
  },
  {
    baseUrl: '/',
    link: '/report-center/warranty',
    name: '保修管理',
  },
  {
    baseUrl: '/',
    link: '/report-center/parts',
    name: '配件管理',
  },
  {
    baseUrl: '/accounting',
    link: '/bmi3-management/bmi3-template-history',
    name: 'BMI3历史模版',
  },
  {
    baseUrl: '/accounting',
    link: '/bmi3-management/accounting-code-balance',
    name: '科目余额',
  },
  {
    baseUrl: '/accounting',
    link: '/bmi3-management/bmi3-txt-history',
    name: 'TXT记录',
  },
  {
    baseUrl: '/accounting',
    link: '/accounting/service-code/customer-type/list',
    name: '客户类型编号',
  },
  {
    baseUrl: '/',
    link: '/preorder/management',
    name: '名义订单',
  },
  {
    baseUrl: '/',
    link: '/test-drive-vehicle',
    name: '试驾车管理',
  },
  {
    baseUrl: '/',
    link: '/order/management',
    name: '订单管理',
  },
  {
    baseUrl: '/',
    link: '/report',
    name: '数据导出',
  },
  {
    baseUrl: '/',
    link: '/service-contract/transaction-history/list',
    name: '修养套餐销售历史查询',
  },
  {
    baseUrl: '/',
    link: '/service-contract/sssc-activation',
    name: '申报售后礼包',
  },
  {
    baseUrl: '/',
    link: '/service-contract/sssc-history/list',
    name: '售后增值礼包历史查询',
  },
  {
    baseUrl: '/',
    link: '/service-contract/sssc-sales-record/list',
    name: '卡券赠送记录',
  },
  {
    baseUrl: '/',
    link: '/intelligent-showroom',
    name: '智能报表',
  },
  {
    baseUrl: '/',
    link: '/sales-performance/sales-reports',
    name: '销售月报/周报',
  },
  {
    baseUrl: '/',
    link: '/vehicle/management',
    name: '车辆管理-库存表',
  },
  {
    baseUrl: '/',
    link: '/courtesy-car/borrow-return-report',
    name: '代步车使用报告',
  },
  {
    baseUrl: '/',
    link: '/workshop/order-list',
    name: '订单列表',
  },
  {
    baseUrl: '/',
    link: '/workshop/clocking-management',
    name: '开关钟管理',
  },
  {
    baseUrl: '/',
    link: '/workshop/clocking-management',
    name: '技师上下班',
  },
  {
    baseUrl: '/',
    link: '/workshop/clocking-management',
    name: '施工单',
  },
  {
    baseUrl: '/',
    link: '/workshop/report',
    name: '车间数据分析',
  },
  {
    baseUrl: '/',
    link: '/workshop/reservation',
    name: '预约信息',
  },
  {
    baseUrl: '/',
    link: '/workshop/resource-management',
    name: '资源管理',
  },
  {
    baseUrl: '/',
    link: '/workshop/resource-management',
    name: '技师工作安排',
  },
  {
    baseUrl: '/',
    link: '/allianz-warning',
    name: '新能源车辆报告',
  },
  {
    baseUrl: '/',
    link: '/pdi',
    name: '长存储车检查',
  },
  {
    baseUrl: '/',
    link: '/maintenance-history/search-list/list',
    name: '查询维修历史',
  },
  {
    baseUrl: '/',
    link: '/as-settings/labor-rate/configure-labor-rate',
    name: '每小时工时费率',
  },
  {
    baseUrl: '/',
    link: '/as-settings/content/order-cancel-comment',
    name: '结束服务原因',
  },
  {
    baseUrl: '/',
    link: '/service-contract/customized-spps-package-maintenance',
    name: '自定义保养套餐',
  },
  {
    baseUrl: '/',
    link: '/as-settings/labor/custom-labor-management',
    name: '自定义工时',
  },
  {
    baseUrl: '/',
    link: '/parts-sales/order/list',
    name: '零件销售-销售订单',
  },
  {
    baseUrl: '/',
    link: '/parts-sales/ict/list',
    name: '零件销售-ICT',
  },
  {
    baseUrl: '/',
    link: '/parts-sales/customers',
    name: '零件销售-客户信息',
  },
  {
    baseUrl: '/',
    link: '/parts-sales/spps/list',
    name: '零件销售-星徽保养菜单',
  },
  {
    baseUrl: '/',
    link: '/parts-stock/parts-profile/list',
    name: '库存管理-零件档案管理',
  },
  {
    baseUrl: '/',
    link: '/parts-stock/parts-profile/master-price-file/list',
    name: '库存管理-主价格文件搜索',
  },
  {
    baseUrl: '/',
    link: '/parts-stock/parts-profile/oem-supersession/list',
    name: '库存管理-厂商替换关系搜索',
  },
  {
    baseUrl: '/',
    link: '/parts-stock/parts-profile/dealer-supersession-management/list',
    name: '库存管理-经销商替换关系管理',
  },
  {
    baseUrl: '/',
    link: '/parts-stock/parts-profile/promotion-menu/search',
    name: '库存管理-促销/组合维护',
  },
  {
    baseUrl: '/',
    link: '/parts-stock/parts-profile/promotion-make-break',
    name: '库存管理-组装/拆分',
  },
  {
    baseUrl: '/',
    link: '/parts-purchase/order-item/search',
    name: '零件采购-查询采购订单行',
  },
  {
    baseUrl: '/',
    link: '/parts-purchase/purchase-order/list',
    name: '零件采购-采购订单',
  },
  {
    baseUrl: '/',
    link: '/parts-purchase/po-submission/list',
    name: '零件采购-提交订单',
  },
  {
    baseUrl: '/',
    link: '/parts-purchase/parts-receive',
    name: '零件采购-零件收货',
  },
  {
    baseUrl: '/',
    link: '/parts-purchase/confi-import',
    name: '零件采购-厂家零件收货',
  },
  {
    baseUrl: '/',
    link: '/parts-purchase/invoice-verification',
    name: '零件采购-发票校验',
  },
  {
    baseUrl: '/',
    link: '/parts-stock/stock-check/overview',
    name: '库存盘点-查看库存盘点',
  },
  {
    baseUrl: '/',
    link: '/parts-dealer-setting/dealer-white-list/settings',
    name: '经销商设置-库存共享设置',
  },
  {
    baseUrl: '/',
    link: '/parts-dealer-setting/dealer-parameter',
    name: '经销商设置-采购参数',
  },
  {
    baseUrl: '/',
    link: '/parts-dealer-setting/discount-code',
    name: '经销商设置-折扣代码',
  },
  {
    baseUrl: '/',
    link: '/parts-dealer-setting/discount',
    name: '经销商设置-折扣管理',
  },
  {
    baseUrl: '/',
    link: '/parts-dealer-setting/discount-range',
    name: '经销商设置-打折范围',
  },
];

const SUPER_ADMIN_DETAIL_WHITE_LIST = [
  {
    baseUrl: '/',
    link: '/parts-sales',
    name: '零件销售',
  },
  {
    baseUrl: '/',
    link: '/parts-stock',
    name: '库存管理/库存盘点',
  },
  {
    baseUrl: '/',
    link: '/parts-purchase',
    name: '零件采购',
  },
  {
    baseUrl: '/',
    link: '/parts-dealer-setting',
    name: '经销商设置',
  },
];

const NO_SHOW_BAR_LIST = [
  {
    baseUrl: '/',
    link: '/parts-dealer-setting/discount/dealer-group-discount',
    name: '经销商设置-折扣管理-查看/编辑集团数据',
  },
  {
    baseUrl: '/',
    link: '/report-center/front-desk/service-measure-recall-completion-rate-detail',
    name: '服务措施/召回完成率详情',
  },
];

const test_data = [];

@Injectable()
export class DealerService {

  private currentDealer = null;
  private dealerBarSelectArea = null;

  private dealerChangeCallbacks = null;

  private dealer_all_list = null;
  private dealer_select_list = [];

  private select_area_options = [];
  private isReady = false;

  constructor(
    private dealerApi: DealerApi,
    private dealerGroupApi: DealerGroupApi,
    private currentUserService: CurrentUserService,
    private tokenService: TokenService,
    private dealerIntegrationToggleService: DealerIntegrationToggleService,
  ) {

  }

  destory() {
    this.isReady = false;
    this.currentDealer = null;
    this.dealerChangeCallbacks = null;
    this.dealer_all_list = null;
    this.dealer_select_list = [];
    this.select_area_options = [];
  }

  async ready() {
    if (this.isReady) {
      return;
    }

    if (this.isRoleOfSuperAdminForDealer()) {
      await this.getAllDealerList();
      if (this.dealer_all_list.length > 0) {
        this.initSelectOptionData();
        await this.initCurrentDealer();
      }
      this.isReady = true;
    }

  }

  // sort by name from a-z
  sortByName(list, key) {
    return list.sort((a, b) => a[key].toString().localeCompare(b[key].toString(), 'zh-cn'));
  }

  getDealerGSCode(dealerId) {
    return this.dealerApi.getDealerGSCode(dealerId);
  }

  getDealers(): Observable<Array<OutletInformationType>> {
    return this.dealerApi.getDealers();
  }

  pubCurrentDealerKey() {
    return 'PUBSUB_SET_CURRENT_DEALER';
  }

  addDealerChangeSubscribe(key1, callback): void {
    const key = key1.replace(/\./g, '_');
    if (!this.dealerChangeCallbacks) {
      this.dealerChangeCallbacks = {};
      Pubsub.subscribe(this.pubCurrentDealerKey(), this.fireAllDealerChangeEvent.bind(this));
    }
    _.set(this.dealerChangeCallbacks, key, callback);
  }
  removeDealerChangeSubscribe(key1) {
    const key = key1.replace(/\./g, '_');
    if (this.dealerChangeCallbacks) {
      delete this.dealerChangeCallbacks[key];
    }
  }

  fireAllDealerChangeEvent() {
    _.each(this.dealerChangeCallbacks, callback => {
      callback(this.getCurrentDealer());
    });
  }

  async setCurrentDealer(dealer, push = false) {
    this.currentDealer = dealer;

    const info = this.currentUserService.getCurrentUser();
    info.dealerId = dealer.id_dealer;
    info.branchDealerId = dealer.id_branch;
    this.currentUserService.setCurrentUser(info);

    // TODO switch token
    try {
      const group = _.find(JSON.parse(info.groups), (x: any) => x.groupId === info.groupId && x.roleCode === info.roleCode);
      if (!group) { return; }
      const json = await this.dealerGroupApi.switchTokenAfterChangeCurrentSelectDealer({
        userGroupId: group.userGroupId,
        branchDealerId: dealer.id_branch,
        dealerId: dealer.id_dealer,
        clientType: 'web',
      }).toPromise().catch(e => {
        return {};
      });

      if (json.jwtToken && this.tokenService.accessToken !== json.jwtToken) {
        this.tokenService.accessToken = json.jwtToken;
        this.tokenService.superAdminToken = json.jwtToken;
      }

      this.dealerIntegrationToggleService.fetchDealerIntegrationToggles().subscribe(rs => {
        if (push) {
          Pubsub.publish(this.pubCurrentDealerKey(), {});
        }
      });
    } catch (e) {
      // handle thie error.
    }

  }

  getCurrentDealer() {
    return this.currentDealer;
  }
  getCurrentDealerName() {
    return this.currentDealer ? this.currentDealer.name_dealer_branch : '';
  }

  isRoleOfSuperAdminForDealer() {
    const info = this.currentUserService.getCurrentUser();
    const rolecode = _.get(info, 'roleCode');

    const roleList = [
      'DGCO', 'DGMM', 'DGSCM', 'DG_AFTERSALES', 'DG_FINANCE',
      'DGA', 'DGF', 'MD', 'PMA', 'RA', 'RCOO', 'RD', 'DG_SALES',
    ];
    return _.includes(roleList, rolecode);
  }

  isUnderSuperAdminPageForDealer(url) {
    const href = `${window.location.pathname}`;
    const x = _.find(WHITE_LIST, item => {
      return _.includes(url, item.link) && _.includes(href, item.baseUrl);
    });

    return !!x;
  }

  isUnderSuperAdminDetailPageForDealer(url) {
    const href = `${window.location.pathname}`;
    const x = _.find(SUPER_ADMIN_DETAIL_WHITE_LIST, item => {
      return _.includes(url, item.link) && _.includes(href, item.baseUrl);
    });

    return !!x;
  }

  isNoShowDealerDragBarUnderUrls(url) {
    const href = `${window.location.pathname}`;
    const x = _.find(NO_SHOW_BAR_LIST, item => {
      return _.includes(url, item.link) && _.includes(href, item.baseUrl);
    });
    return !!x;
  }

  setDealerBarSelectArea(area= '') {
    if (area) {
      this.dealerBarSelectArea = area;
      this.dealer_select_list = this.filterDataByArea();
      sessionStorage.setItem(DEALER_BAR_SELECT_AREA, area);
    } else {
      this.dealerBarSelectArea = null;
      this.dealer_select_list = [];
      sessionStorage.removeItem(DEALER_BAR_SELECT_AREA);
    }

  }
  getDealerBarSelectArea() {
    if (!this.dealerBarSelectArea) {
      this.dealerBarSelectArea = sessionStorage.getItem(DEALER_BAR_SELECT_AREA);
    }

    return this.dealerBarSelectArea;
  }

  getAllDealerForGroup(): Observable<any[]> {
    return this.dealerGroupApi.getDealerGroupForRegionPmaDealers();
  }

  async getAllDealerList() {
    if (!this.dealer_all_list) {
      this.dealer_all_list = await this.getAllDealerForGroup().toPromise().catch(e => {
        return [];
      });
    }

    return _.map(this.dealer_all_list, item => {
      item.id = `${item.id_dealer}-${item.id_branch || ''}`;
      return item;
    });
  }

  initSelectOptionData() {
    const name_dealer_map = {};
    const name_region_map = {};
    const name_sub_region_map = {};
    const name_pma_map = {};

    let a = [];
    let b = [];
    let c = [];
    let d = [];

    _.each(this.dealer_all_list, item => {
      if (item.id_dealer_group && !name_dealer_map[item.id_dealer_group]) {
        name_dealer_map[item.id_dealer_group] = item.id_dealer_group;

        a.push({
          id: item.id_dealer_group.toString(),
          name: item.name_dealer_group,
        });

      }
      if (item.id_region && !name_region_map[item.id_region]) {
        name_region_map[item.id_region] = item.id_region;

        b.push({
          id: item.id_region.toString(),
          name: item.name_region,
        });
      }
      if (item.id_sub_region && !name_sub_region_map[item.id_sub_region]) {
        name_sub_region_map[item.id_sub_region] = item.id_sub_region;
        d.push({
          id: item.id_sub_region.toString(),
          name: item.name_sub_region,
        });
      }

      if (item.id_pma && !name_pma_map[item.id_pma]) {
        name_pma_map[item.id_pma] = item.id_pma;

        c.push({
          id: item.id_pma.toString(),
          name: item.name_pma,
        });
      }
    });

    a = this.sortByName(a, 'name');
    b = this.sortByName(b, 'name');
    c = this.sortByName(c, 'name');
    d = this.sortByName(d, 'name');

    // const all = _.concat([], a, b, c);
    this.select_area_options = [
      {
        name: '',
        list: a,
      },
      {
        name: '',
        list:  b,
      },
      {
        name: '',
        list: d,
      },
      {
        name: '',
        list: c,
      },
    ];
    this.select_area_options = _.filter(this.select_area_options, x => {
      return x.list.length > 0;
    });
  }

  getSelectOptionData() {
    return this.select_area_options;
  }

  async initCurrentDealer() {
    const info = this.currentUserService.getCurrentUser();
    const uuid = `${info.dealerId}-${info.branchDealerId || ''}`;
    const dealer = _.find(this.dealer_all_list, item => item.id === uuid);
    const key = this.getDealerBarSelectArea();
    if (dealer && key) {
      // set all for exist
      this.setDealerBarSelectArea(key);
      await this.setCurrentDealer(dealer, false);
    } else {
      if (_.first(this.select_area_options) && _.first(this.select_area_options).list && _.first(this.select_area_options).list[0]) {
        this.setDealerBarSelectArea(_.first(this.select_area_options).list[0].id);

        await this.setCurrentDealer(_.first(this.dealer_select_list), false);
      }
    }
  }

  filterDataByArea() {
    const list = [];
    const key = this.dealerBarSelectArea;
    _.each(this.dealer_all_list, item => {
      const keys = _.map([item.id_pma, item.id_region, item.id_dealer_group, item.id_sub_region], x => {
        return x ? x.toString() : '';
      });
      if (_.includes(keys, key)) {
        list.push(item);
      }
    });
    return this.sortByName(list, 'name_dealer_branch');
  }

  getDealerSelectList() {
    return this.dealer_select_list;
  }

  getCecDealers(): Observable<any[]> {
    const { dealerId } = this.currentUserService.getCurrentUser();
    return this.dealerApi.getCecDealerList(dealerId);
  }

  isVanPc(squad: string) {
    const dealerInfo = this.getCurrentDealer();
    const dealerId = _.get(dealerInfo, 'id_dealer');
    const dealerList = _.find(JSON.parse(sessionStorage.getItem('dealer_list')), ['id_dealer', dealerId]);
    const temp = _.find(_.get(dealerList, 'authorization_types'), ['squad', squad]);
    const businessType = _.get(temp, 'business_type');
    return businessType === 'PC_VAN';
  }
}

import { AfterViewInit, Component, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import * as _ from 'lodash';
import {VEHICLE_CLUE_TAGS} from '../../../customer-lead-management/lead-management/sales-lead/customer-information/customer-current-vehicle/constant';

@Component({
  selector: 'app-collapse-tags',
  templateUrl: './collapse-tags.template.html',
  styleUrls: ['./collapse-tags.style.scss'],
})
export class CollapseTagsComponent  implements AfterViewInit {
  @ViewChild('collapseTags') collapseTagsRef: ElementRef;
  @Input() dataSource: Array<string> = [];
  @Input() clueTags: Array<string> = [];
  isCollapsed: boolean = false;
  isComputing: boolean = true;
  showOperation: boolean = false;

  @HostListener('window:resize')
  onResize(): void {
    this.updateOperation();
  }

  ngAfterViewInit() {
    this.updateOperation();
  }

  updateOperation () {
    this.isComputing = true;
    this.isCollapsed = false;
    setTimeout(() => {
      const SINGLE_ROW_HEIGHT = 34;
      this.showOperation = this.collapseTagsRef.nativeElement.clientHeight !== SINGLE_ROW_HEIGHT;
      this.isCollapsed = true;
      this.isComputing = false;
    });
  }

  getClueTagClass(value) {
    if (_.includes([
      VEHICLE_CLUE_TAGS.WAITING_TO_JOIN_LOYALTY_PlAN.text,
      VEHICLE_CLUE_TAGS.WAITING_TO_JOIN_TIRE_PACKAGE.text,
      VEHICLE_CLUE_TAGS.PENDING_PURCHASE_CONTRACT.text,
    ], value)) {
      return 'dark-blue';
    }
    return 'light-blue';
  }
}

import * as moment from 'moment';
import { FILTER_INPUT_TYPES, SA_NAME_WITH_ALL_OPTIONS, VEHICLE_BUSINESS_TYPE_OPTION } from '../constants/report';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import { finValidator } from '../../_common/utils/form-validator';

export const SERVICE_MEASURE_COMPLETION_STATUS_QUERY_CONFIG = {
  name: 'service-measure-completion-status-query',
  enablePagination: true,
  exportedFileName: '服务措施完成情况查询.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '订单号',
      field: 'orderNumber',
      pinned: 'left',
      width: 150,
    },
    {
      headerName: '车牌号',
      field: 'carPlateNumber',
      pinned: 'left',
      width: 150,
    },
    {
      headerName: '车型',
      field: 'carModel',
      pinned: 'left',
      width: 150,
    },
    {
      headerName: '底盘号',
      field: 'fin',
      width: 200,
    },
    {
      headerName: '客户姓名',
      field: 'customerName',
      width: 200,
    },
    {
      headerName: '故障识别码',
      field: 'damageCode',
      width: 150,
    },
    {
      headerName: '描述',
      field: 'description',
      width: 200,
    },
    {
      headerName: '类型',
      field: 'kdmType',
      width: 150,
    },
    {
      headerName: '发布日期',
      field: 'damageCodeStartDate',
      width: 200,
    },
    {
      headerName: '豁免截止日期',
      field: 'bufferTimeEndDate',
      width: 200,
    },
    {
      headerName: '登记进厂日期',
      field: 'checkedInDate',
      width: 200,
    },
    {
      headerName: '登记出厂日期',
      field: 'checkedOutDate',
      width: 200,
    },
    {
      headerName: '结账日期',
      field: 'paymentDate',
      width: 200,
    },
    {
      headerName: '是否首次到店',
      field: 'whetherFirstTimeArrived',
      width: 200,
    },
    {
      headerName: '服务人员',
      field: 'assaFullName',
      width: 150,
    },
    {
      headerName: '状态',
      field: 'serviceMeasureStatus',
      width: 150,
    },
    {
      headerName: '拒绝原因',
      field: 'rejectedReason',
      width: 300,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'fin',
      label: '底盘号',
      placeholder: '请输入完整的底盘号',
      validator: finValidator,
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'damageCode',
      label: '故障识别码',
      placeholder: '请输入完整的故障识别码',
    },
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '服务人员',
      options: SA_NAME_WITH_ALL_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'orderCreatedDate',
      label: '创建日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedInDate',
      label: '登记进厂日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'invoicedDate',
      label: '结账日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型:',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
  ],
};

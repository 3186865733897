import * as moment from 'moment';
import {
  FILTER_INPUT_TYPES,
  SA_NAME_WITH_ALL_OPTIONS,
  SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS,
  VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import { priceFormatter } from '../utils/utils';

export const LOST_SALE_REPORT_CONFIG = {
  name: 'lost-sale-report',
  enablePagination: true,
  exportedFileName: '维修失销报告.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '订单号',
      field: 'orderNumber',
      width: 160,
    },
    {
      headerName: '订单类型',
      field: 'serviceRecordCategory',
      width: 100,
    },
    {
      headerName: '部门代码',
      field: 'departmentCode',
      width: 150,
    },
    {
      headerName: '客户类型编号',
      field: 'customerTypeNumber',
      width: 150,
    },
    {
      headerName: '工时/配件',
      field: 'productCode',
      width: 150,
    },
    {
      headerName: '标准工时/数量',
      field: 'quantity',
      width: 100,
    },
    {
      headerName: '总计 (¥)',
      field: 'amountWithoutTax',
      width: 130,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '登记进厂日期',
      field: 'checkedInDate',
      width: 120,
    },
    {
      headerName: '登记出厂日期',
      field: 'checkedOutDate',
      width: 120,
    },
    {
      headerName: '服务人员',
      field: 'assaFullName',
      width: 150,
    },
    {
      headerName: '失销原因',
      field: 'lostSaleReason',
      width: 150,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'orderNumber',
      label: '订单号',
      placeholder: '请输入...',
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedInDate',
      label: '登记进厂日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedOutDate',
      label: '登记出厂日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '服务人员',
      options: SA_NAME_WITH_ALL_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'serviceRecordCategory',
      label: '订单类型',
      options: SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS.values,
      defaultValue: SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS.get('ALL'),
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型：',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
  ],
};

import { BehaviorSubject, Observable, of as observableOf, Subject } from 'rxjs';

import { combineLatest, map, take, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid';
import * as _ from 'lodash';
import { IFilterAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-select-filter-cell',
  templateUrl: 'select-filter.template.html',
  styleUrls: ['../grid-filter.style.scss'],
})
export class SelectFilterComponent implements IFilterAngularComp, OnDestroy {
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => string;
  public options$: Observable<Array<any>>;
  public filter$ = new BehaviorSubject({});
  public isAllSelected: boolean = true;
  private destroy$ = new Subject();
  private initAllChecked: boolean = true;

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
    const filterParams = this.params.colDef.filterParams;
    if (filterParams.optionsObservable) {
      this.options$ = filterParams.optionsObservable;
    } else {
      const options = filterParams.options;
      const formatOptions = _.isFunction(options) ? options(this.params) : options;
      this.options$ = observableOf(formatOptions);
    }
    if (params['initAllChecked'] !== undefined) {
      this.initAllChecked = params['initAllChecked'];
    }
    this.setAllFilterEnable(this.initAllChecked);
    this.initSubscription();
  }

  private initSubscription() {
    this.options$.pipe(
      combineLatest(this.filter$),
      map(([options, filters]) => _.every(options, option => filters[option.value])),
      takeUntil(this.destroy$))
      .subscribe(isAllSelected => this.isAllSelected = isAllSelected);

    this.filter$.pipe(takeUntil(this.destroy$)).subscribe(this.params.filterChangedCallback);
  }

  isFilterActive(): boolean {
    if (this.initAllChecked) {
      return !this.isAllSelected;
    }

    let key;
    for (key in this.filter$.value) {
      if (this.filter$.value[key]) {
        return true;
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const nodeValue = this.valueGetter(params.node);
    const value = !_.isNil(nodeValue) ? this.valueGetter(params.node) : '';
    const valueArray = value.toString().toLowerCase().split(/,\s*/);

    return _.map(this.filter$.value, (filterValue, filterKey) => {
      if (filterValue) {
        return filterKey;
      }
    }).filter(filterKey => filterKey)
      .some(filterKey => _.includes(valueArray, filterKey.toString().toLowerCase()));
  }

  getModel(): any {
    return this.filter$.value;
  }

  setModel(model: any): void {
    this.filter$.next(model);
  }

  onChange(event, option) {
    this.filter$.next({ ...this.filter$.value, [option.value]: event.target.checked });
  }

  onAllChange(event) {
    this.setAllFilterEnable(event.target.checked);
  }

  private setAllFilterEnable(isChecked: boolean) {
    this.options$.pipe(take(1), map(options => {
      return options.reduce((filters, { value }) => ({ ...filters, [value]: isChecked }), {});
    })).subscribe(filter => this.filter$.next(filter));
  }
}

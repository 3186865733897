import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MODAL_CONFIG } from '../../../constants/common';

@Component({
  selector: 'app-modal-framework',
  templateUrl: './modal-framework.component.html',
  styleUrls: ['./modal-framework.component.scss'],
})
export class ModalFrameworkComponent implements OnInit {
  modalConfig = MODAL_CONFIG;
  @Input() marginTop = '10%';
  @Input() dialogWidth;
  @ViewChild('bsModal') bsModal;

  ngOnInit() {
  }

  show() {
    this.bsModal.show();
  }

  hide() {
    this.bsModal.hide();
  }
}

import * as _ from 'lodash';
import { isEmptyValue } from './common';
import { EXPORT_FILE_TIME, REGULAR_DATE_FORMAT } from '../constants/service-contract';
import * as moment from 'moment';
import { DateFormat } from '../constants/date';

export const dateParse = (parseDate: any, format: string = DateFormat.DATE) => {
  if (_.isUndefined(parseDate) || _.isNull(parseDate)) {
    return null;
  }
  const date = moment(parseDate || moment());
  const weekMap = {
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
  };
  const hour = date.hour();
  const formatMap = {
    'y+': matched => `${date.year()}`.substr(4 - matched.length),
    'M+': date.month() + 1,
    'd+': date.date(),
    'H+': hour,
    'h+': hour % 12 === 0 ? 12 : hour % 12,
    'm+': date.minute(),
    's+': date.second(),
    'q+': Math.floor((date.month() + 3) / 3),
    'a+': () => (date.hour() >= 12 ? '下午' : '上午'),
    'e+': date.day(),
    'E+': matched => `${matched.length === 1 ? '周' : '星期'}${weekMap[date.day()]}`,
  };
  return _.reduce(
    formatMap,
    (prev, value, key) => {
      const match = prev.match(new RegExp(`(${key})`));
      if (match) {
        const matched = match[0];
        const option = formatMap[key];
        return prev.replace(matched, _.isFunction(option) ? option(matched) : _.padStart(option, matched.length, '0'));
      }
      return prev;
    },
    format,
  );
};

export const getHourMinute = parseDate => {
  const date = new Date(parseDate);
  return { hour: date.getHours(), minute: date.getMinutes() };
};

export const formatHourMinuteSeconds = ({ hour, minute, second }) =>
  `${_.padStart(hour, 2, '0')}:${_.padStart(minute, 2, '0')}:${_.padStart(second, 2, '0')}`;

export const dateRegularParse = timestamp => {
  if (isEmptyValue(timestamp)) {
    return '';
  }
  return dateParse(timestamp, REGULAR_DATE_FORMAT);
};

export const dateExportFileParse = timestamp => {
  if (isEmptyValue(timestamp)) {
    return '';
  }
  return dateParse(timestamp, EXPORT_FILE_TIME);
};

export const correctTimeValue = (value: any, range: number) => {
  if (value <= 0) {
    return 0;
  }
  if (value >= range) {
    return range;
  }
  return value;
};

export const parseDateTime = (date: Date, time: string) => {
  const [hour, minute, second] = time.split(':');
  const dateTime = moment(date);
  dateTime.set({
    hour: parseInt(hour, 10),
    minute: parseInt(minute, 10),
    second: parseInt(second, 10),
    millisecond: 0,
  });
  return dateTime;
};

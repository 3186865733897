<div *ngIf="tabs" class="tab-container no-print">
  <div *ngFor="let tab of tabs; let i = index" class="tab-item" [ngStyle]="tabForPartsPurchaseService.tabStyleCSS">
    <a class="tab-link" [class.closeable]="tab.isCloseable" [routerLink]="tab.href"
       [queryParams]="tab.params"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: tabForPartsPurchaseService.useExactUrlMatch}">
      <div *ngIf="isVan" class="icon-div">
        <img src="/assets/common/vanLogo.png" class="icon-for-van">
      </div>
      <span>{{tab.title}}</span>
      <i *ngIf="tab.isCloseable" (click)="onCloseTab($event, i)" class="fa antd-close-outlined"></i>
    </a>
  </div>
</div>

export const SHOWROOM_VISIT = {
  LIST: 'showroomVisitList',
  COUNTS_FOR_SELECTED_DATE: 'showroomVisitsCountsForToday',
  COUNTS_FOR_UNFINISHED: 'showroomVisitsCountsForUnfinished',
};

export const LEAD_SOURCE_FILTER_TYPE = {
  ALL: 'all',
  FILTER: 'filter',
  OTR: 'otr',
};

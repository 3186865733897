import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  template: `
    <div *ngIf="ifShow" (click)="onView()">
        <i class="fa antd-eye-outlined"></i>
        <span class="operated-text">查看已指定经销商</span>
    </div>
  `,
  styleUrls: ['./customer-labor-setting-operation-cell.style.scss'],
})
export class CustomerLaborSettingOperationComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  ifShow: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.ifShow = (!_.isEmpty(params.data.distributionDealerIds)) ? true : false;
  }

  refresh(params: any): boolean {
    return false;
  }
  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onView() {
    this.cellRendererParams.onView(this.params.node.data);
  }
}

import { Injectable } from '@angular/core';
import { RebirthHttp, GET, POST, Body } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LuxuryTaxApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/luxury-tax')
  getLuxuryTaxConfig(): Observable<any> {
    return null;
  }

  @POST('/api/luxury-tax')
  saveLuxuryTaxConfig(@Body data): Observable<any> {
    return null;
  }

  @GET('/api/luxury-tax/check/kpi')
  checkLuxuryTax(): Observable<any> {
    return null;
  }
}

import * as moment from 'moment';
import {
  ExportExceedMessage,
  FILTER_INPUT_TYPES, LINE_STATUS_OPTIONS,
  PARTS_RESERVATION_SERVICE_RECORD_CATEGORY_FOR_PC,
} from '../constants/report';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import { priceFormatter } from '../utils/utils';
import { DATE_FORMAT } from 'app/_common/constants/common';

export const PARTS_RESERVATION_REPORT_CONFIG = {
  name: 'parts-reservation-report',
  enablePagination: true,
  exportedFileName: '零件预留报表',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  isRealTimeReport: true,
  timeFormatter: 'YYYYMMDDHHmm',
  exceedMessage: ExportExceedMessage.RESERVED,
  maxItems: 150000,
  paginationWithLastFormParams: true,
  isNameWithDate: true,
  column: [
    {
      headerName: '订单号',
      field: 'orderNumber',
      width: 160,
    },
    {
      headerName: '订单类型',
      field: 'orderType',
      width: 140,
    },
    {
      headerName: '服务顾问',
      field: 'assaFullName',
      width: 100,
    },
    {
      headerName: '车牌号',
      field: 'carPlateNumber',
      width: 120,
    },
    {
      headerName: '底盘号',
      field: 'fin',
      width: 200,
    },
    {
      headerName: '创建日期',
      field: 'orderCreatedTime',
      width: 120,
    },
    {
      headerName: '创建人',
      field: 'creatorFullname',
      width: 160,
    },
    {
      headerName: '零件号码',
      field: 'partNumber',
      width: 180,
    },
    {
      headerName: '说明',
      field: 'description',
      width: 120,
    },
    {
      headerName: '市场代码',
      field: 'marketingCode',
      width: 120,
    },
    {
      headerName: '订购数量',
      field: 'quantity',
      width: 100,
    },
    {
      headerName: '加入日期',
      field: 'createdTime',
      width: 120,
    },
    {
      headerName: '客户类型编号',
      field: 'customerTypeNumber',
      width: 120,
    },
    {
      headerName: '零售价值',
      field: 'amountBeforeTax',
      width: 120,
      valueFormatter: priceFormatter,
    },
    {
      headerName: 'VAT',
      field: 'taxAmount',
      width: 120,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '行状态',
      field: 'lineStatus',
      width: 120,
    },
    {
      headerName: '零件行说明',
      field: 'lineStatusDescription',
      width: 120,
    },
    {
      headerName: '实际到货日期',
      field: 'receivedDate',
      width: 120,
    },
    {
      headerName: '车辆状态',
      field: 'vehicleStatus',
      width: 120,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedInDate',
      label: '创建日期',
      isRangeLimited: false,
      endLimitValue: moment(),
      defaultBeginDate: moment().subtract(2, 'years').format(DATE_FORMAT),
      defaultEndDate: moment().format(DATE_FORMAT),
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'fin',
      label: '底盘号',
      placeholder: '请输入底盘号后6位进行搜索',
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'partNumber',
      label: '零件号码',
      placeholder: '请输入完整的零件号码进行搜索',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'serviceRecordCategory',
      label: '订单类型',
      options: PARTS_RESERVATION_SERVICE_RECORD_CATEGORY_FOR_PC.values,
      defaultValue: PARTS_RESERVATION_SERVICE_RECORD_CATEGORY_FOR_PC.get('ALL'),
    },
    {
      type: FILTER_INPUT_TYPES.DEALER_SA_NAME_MULTI_DROPDOWN,
      name: 'assaUsername',
      label: '服务顾问',
      options: [],
      defaultValue: null,
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
      itemWidth: 270,
      placeholder: '请选择',
      initialSelectedAll: true,
    },
    {
      type: FILTER_INPUT_TYPES.CREATED_BY_MULTI_DROPDOWN,
      name: 'creator',
      label: '创建人',
      options: [],
      keyForOptionText: 'full_name',
      keyForOptionValue: 'gems_user_id',
      defaultValue: null,
      itemWidth: 230,
      placeholder: '请选择',
      initialSelectedAll: true,
    },
    {
      type: FILTER_INPUT_TYPES.MULTI_DROPDOWN,
      name: 'lineStatus',
      label: '行状态',
      options: LINE_STATUS_OPTIONS,
      defaultValue: null,
      placeholder: '请选择',
      initialSelectedAll: true,
    },
  ],
  floatRight: true,
};

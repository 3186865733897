import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { Stock } from '../models/stock';

@Pipe({
  name: 'vehicleLocationDescription',
})
export class VehicleLocationDescriptionPipe implements PipeTransform {

  transform(stock: Stock, args?: any): string {
    if (_.isEmpty(stock)) {
      return '';
    }
    if (_.isEmpty(stock.location_description)) {
      return stock.location || '';
    }
    return `${stock.location} - ${stock.location_description}`;
  }
}

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { VehicleCascaderSelectService } from './vehicle-cascader-select.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-vehicle-ascader-select',
  templateUrl: './vehicle-cascader-select.component.html',
  styleUrls: ['./vehicle-cascader-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VehicleCascaderSelectComponent),
      multi: true
    }
  ]
})
export class VehicleCascaderSelectComponent implements OnInit, ControlValueAccessor {
  @Input() disabled: boolean = false;
  options;
  selectedValue: any;
  onChange: any = _.noop;
  onTouched: any = _.noop;
  @Output() errorEmitter = new EventEmitter();

  constructor(
    private vehicleSelectService: VehicleCascaderSelectService
  ) {
  }

  ngOnInit() {
    this.vehicleSelectService.fetchAllBrands().subscribe((res) => {
      this.options = this.convertVehicleValue(res);
    },
      () => this.errorEmitter.emit()
    );
  }

  writeValue(value: any): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onCascaderChange(value: any) {
    this.selectedValue = value;
    this.onChange(value[2]);
    this.onTouched();
  }

  private convertVehicleValue(arr) {
    return arr.map(({ description_zh, classes, models }) => {
      const newItem = { value: description_zh, label: description_zh, children: [] };

      if (classes && classes.length > 0) {
        newItem.children = this.convertVehicleValue(classes);
      }

      if (models && models.length > 0) {
        newItem.children = [];
        models.map((item) => {
          item.variants.map((variant) => {
            newItem.children.push({
              value: variant.description_zh,
              label: variant.description_zh,
              isLeaf: true
            });
          });
        });
      }
      return newItem;
    });
  }
}

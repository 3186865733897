<div class="filter-box">
  <label class="select-label" style="padding: 0;">
    <input type="radio"
           value="NO_VALUE"
           class="radio-button"
           name="search-or-select"
           [formControl]="inputType"
           (click)="onNoValueClick()"
    />未填写
  </label>
  <div class="input-container">
    <input
      value="NORMAL"
      type="radio"
      class="radio-button"
      name="search-or-select"
      [formControl]="inputType"
    />
    <input
      class="form-control range-form-control range-left"
      [formControl]="startInput"
      [placeholder]="placeholder"
      (click)="onNormalClick()"
    >
    <span class="range-line"></span>
    <input
      class="form-control range-form-control range-right"
      [formControl]="endInput"
      [placeholder]="placeholder"
      (click)="onNormalClick()"
    >
  </div>
  <span class="btn-cancel" (click)="onCancel()">取消</span>
  <button
    type="submit"
    class="btn-save range-btn"
    [disabled]="!isSubmitEnabled()"
    (click)="onSubmit()"
  >
    确定
  </button>
</div>

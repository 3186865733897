import * as _ from 'lodash';
import { APPROVAL_CANCEL_TYPES, APPROVAL_TYPES, DATE_FORMAT, NUMBER_REGEXP } from '../constants/common';
import { SimpleChange } from '@angular/core/src/change_detection/change_detection_util';
import { Enum, EnumInput, EnumItem, InputValue } from './enum';
import moment = require('moment');
import { EMPTY_PLACEHOLDER } from 'app/_common/constants/warranty';

const getCompactedNewValue = value => {
  if (Array.isArray(value)) {
    return deleteEmptyItem(value);
  }
  if (typeof value === 'object') {
    return deleteEmptyProperty(value);
  }
  return value;
};

export const deleteEmptyItem = array => _.reduce(array, (result, value) => {
  const newValue = getCompactedNewValue(value);
  return isEmptyValue(newValue) ? result : [...result, newValue];
}, []);

export const deleteEmptyProperty = json => _.reduce(json, (result, value, key) => {
  const newValue = getCompactedNewValue(value);
  return isEmptyValue(newValue) ? result : { ...result, [key]: newValue };
}, {});

export function mergeData(targetData, sourceData) {
  return { ...sourceData, _id: targetData.id , ...deleteEmptyProperty(targetData) };
}

export const getErrorMessage = (errorCollection, error, defaultMessage = '') =>
  findGet(errorCollection, { value: _.get(error, 'error_code', '') }, 'text', defaultMessage);

export const getTemplateErrorMessage = (errorCollection, error, defaultMessage = '') => {
  const matchedPattern = _.find(errorCollection, { value: _.get(error, 'error_code', '') }) as any;
  if (_.isEmpty(matchedPattern)) {
    return defaultMessage;
  }
  if (_.isFunction(matchedPattern.getText)) {
    return matchedPattern.getText(error.message);
  }
  return _.get(matchedPattern, 'text', defaultMessage);
};

export const compact = (inputValue: Array<any> | object) =>
  _.isArray(inputValue)
    ? _.compact(inputValue)
    : _.omitBy(inputValue, item => _.isNil(item) || item === '');

export function getApprovalTypeText(approvalType) {
  if (_.includes(APPROVAL_CANCEL_TYPES, approvalType)) {
    return '取消';
  }
  return _.get(APPROVAL_TYPES[approvalType], 'text', '');
}

export function floatMultiply(arg1, arg2) {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  try {
    m += s1.split('.')[1].length;
  } catch (e) {
  }
  try {
    m += s2.split('.')[1].length;
  } catch (e) {
  }
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
}

export function isEmptyValue(value) {
  return !_.isNumber(value) && !_.isBoolean(value) && _.isEmpty(value);
}

export function isTrueString(target: any) {
  return target.toString() === 'true';
}

/* tslint:disable */
export function getByteLength(str: string) {
  return _.reduce(
    str,
    (length, char) => {
      const charLength = (char.charCodeAt(0) & 0xff00) > 0 ? 2 : 1;
      return length + charLength;
    },
    0,
  );
}

/* tslint:enable */

export function flatTree(node, key, shouldExtend?) {
  return _.flatMap(node[key], child => {
    if (shouldExtend && !shouldExtend(child)) {
      return [];
    }
    return _.isEmpty(child[key]) ? [child] : [child].concat(flatTree(child, key));
  });
}

export function isNumber(value, includeString = false) {
  return includeString ? NUMBER_REGEXP.test(value) : _.isNumber(value);
}

export const joinIfNotEmpty = (array: string[], splitter: string) =>
  _.isEmpty(_.reject(array, isEmptyValue)) ? '' : _.join(array, splitter);

export function isValidName(name: string) {
  const allowedGroup = '\\u4e00-\\u9fa5a-zA-Z()（）.&';
  const regExp = new RegExp(`^[${allowedGroup}]+(\\s+[${allowedGroup}]+)*$`);
  return regExp.test(name) && name.length <= 20;
}

export const BACKSPACE = 'Backspace';

export const isKeyBackspace = (key: any) => key === BACKSPACE;

export const isKeyNumeric = (key): boolean => /\d/.test(key);

export const isKeyLetter = (key): boolean => /[A-Za-z]/.test(key);

export const isKeyDecimalPoint = (key): boolean => key === '.';

export const isKeyNumericOrLetter = (key): boolean => isKeyNumeric(key) || isKeyLetter(key);

export const caseIgnoredIncludes = (string, substring) => _.includes(_.toLower(string), _.toLower(substring));

const getMinOrMaxValue = (first, second, processor) => (!first || !second ? first || second : processor(first, second));

export const getRangeOverlap = (leftRange, rightRange) => {
  const start = getMinOrMaxValue(leftRange.from, rightRange.from, Math.max);
  const end = getMinOrMaxValue(leftRange.to, rightRange.to, Math.min);
  if (!_.isNil(end) && start > end) {
    return null;
  }
  return {
    from: start,
    to: end,
  };
};

export const isNumericOrEmptyString = value => _.isNumber(value) || (_.isString(value) && !_.isNaN(Number(value)));

export const deleteHashCharacter = object =>
  _.mapValues(object, value => {
    if (_.isArray(value)) {
      return _.map(value, v => (_.isString(v) ? v.replace(/#/g, '') : v));
    }
    if (_.isString(value)) {
      return value.replace(/#/g, '');
    }
    return value;
  });

export const silentParseJSON = json => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

export const getMapKeys = (map: Map<string, any>): Array<string> => ([...map.keys()]);

export const mapToJson = (map: Map<any, any>) =>
  getMapKeys(map).reduce(
    (result, key) => ({
      ...result,
      [key]: map.get(key),
    }),
    {},
  );

export const parseInteger = value => {
  const number = _.parseInt(value);
  return isNaN(number) ? null : number;
};

export const findGet = (mapArray: Array<Object>, findPredicate, getPath: string, defaultValue?) =>
  _.get(_.find(mapArray, findPredicate), getPath, defaultValue);

export const isValueChanged = (changedValue: SimpleChange) =>
  changedValue && !_.isEqual(changedValue.currentValue, changedValue.previousValue);

export const wrapCurrentInactiveOption =
  <T extends EnumInput>(
    optionEnum: Enum<T> | Array<{ text: string, value: string }>, currentValue?: InputValue): Array<any> => {
    const optionList = _.isArray(optionEnum) ? optionEnum : optionEnum.values;
    const currentOption = _.find(optionList, { value: currentValue });

    const filteredOptions = _.filter(optionList,
      (optionItem: EnumItem<T> & { isInactive: boolean; }) =>
        !optionItem.isInactive || optionItem.value === currentValue);
    return isEmptyValue(currentValue) || currentOption ? filteredOptions : [...filteredOptions, {
      text: currentValue,
      value: currentValue,
      isInactive: true,
    }];
  };

export const removeMobileCountryCode = mobileNumber => {
  if (isEmptyValue(mobileNumber)) {
    return mobileNumber;
  }
  return mobileNumber.replace(/^\+\s*86\s*/, '');
};

export const dateFormatter = date => date ?  moment(date).format(DATE_FORMAT) : EMPTY_PLACEHOLDER;

export const hasNilValueInList = (list: Array<any>) => _.some(list, value => _.isNil(value));

export const getOrFormat = (value, formatOption, defaultValue = '-') => {
  if (_.isNil(value)) {
    return defaultValue;
  }
  return _.isFunction(formatOption) ? formatOption(value) : value + formatOption;
};

export const defaultTo = ({ value, defaultValue = '-' }) => {
  return _.defaultTo(value, defaultValue);
};

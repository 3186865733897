import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-play-download-cell',
  templateUrl: './play-download-cell.component.html',
  styleUrls: ['./play-download-cell.component.scss'],
})
export class PlayDownloadCellRenderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  disabled: boolean = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.disabled = this.getDisabled();
  }

  refresh(params: any): boolean {
    return false;
  }

  getDisabled() {
    if (this.cellRendererParams.disabled instanceof Subject) {
      return this.cellRendererParams.disabled.getValue();
    }

    return _.isFunction(this.cellRendererParams.disabled) ?
      this.cellRendererParams.disabled(this.params) : this.cellRendererParams.disabled;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  onPlaying() {
    this.cellRendererParams.onPlaying(this.params);
  }

  onDownloading() {
    this.cellRendererParams.onDownloading(this.params);
  }

  onHiddenDownloading(): boolean {
    return _.isFunction(this.cellRendererParams.onHiddenDownloading) ?
    this.cellRendererParams.onHiddenDownloading(this.params) : false;
  }
}

import { filter } from 'rxjs/operators';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { IconType } from '../../../../constants/icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DEPARTMENT } from '../../../../constants/department';
import { PositionService } from '../../../../services/position.service';
import { SalesPlanDetailModalComponent } from '../../../../../sales-performance/sales-strategy/plan-modal/detail-modal/sales-plan-detail-modal.component';
import { SalesPlanEditModalComponent } from '../../../../../sales-performance/sales-strategy/plan-modal/edit-modal/sales-plan-edit-modal.component';
import { ConfirmService } from '../../../../services/confirm.service';

@Component({
  templateUrl: './sales-strategy-plan.template.html',
  styleUrls: ['sales-strategy-plan.style.scss'],
})
export class SalesStrategyPlanComponent implements ICellRendererAngularComp {
  params;
  minusIconType = IconType.Minus;
  plusIconType = IconType.Plus;
  departments = _.values(DEPARTMENT);

  displayedText = '添加责任部门';
  plansFormArray = new FormArray([]);
  shouldShowModal = false;
  salesPlanDetailModal;
  salesPlans = [];

  constructor(private positionService: PositionService,
              private formBuilder: FormBuilder,
              private confirmService: ConfirmService) {

  }

  agInit(params): void {
    this.params = params;
    this.salesPlans = this.params.value || [];
    this.initPlansFormControl();
    this.emitColumnWidth();
    this.subscribePlanChange();
  }

  refresh(params: any): boolean {
    return false;
  }

  subscribePlanChange() {
    this.plansFormArray
      .valueChanges
      .subscribe(value => {
        this.updateChange(value);
        this.emitColumnWidth();
      });
  }

  get column() {
    return this.params.column;
  }

  get colId() {
    return this.column.getColId();
  }

  isPlanSummaryEmpty(plan) {
    const summaryFields = [
      'description',
      'first_week',
      'second_week',
      'third_week',
      'fourth_week',
      'fifth_week',
    ];
    return _.chain(plan.value)
      .pick(summaryFields)
      .values()
      .compact()
      .isEmpty()
      .value();
  }

  updateChange(value) {
    _.set(this.params.node.data, this.colId, value);
    _.set(this.params.context, 'isEdited', true);
  }

  initPlansFormControl() {
    this.plansFormArray = new FormArray(_.map(this.salesPlans, plan => this.formBuilder.group(plan)));
  }

  emitColumnWidth() {
    const rowIndex = this.params.node.rowIndex;
    this.column.colDef.columnNumber$.next({
      [rowIndex]: this.plansFormArray.length,
    });
  }

  addPlan() {
    this.plansFormArray.push(this.formBuilder.group(
      {
        department: DEPARTMENT.SALES_DEPARTMENT.value,
        person: '',
        category: _.get(this.params, 'data.category'),
      },
    ));
  }

  minusPlan(index) {
    this.confirmService
      .confirm({ message: '确定删除该计划' })
      .subscribe(result => result && this.plansFormArray.removeAt(index));
  }

  showModal(event, index, plan: FormGroup) {
    this.positionService.run(event.target, SalesPlanEditModalComponent, { plan: plan.getRawValue() }).pipe(
      filter(planValue => planValue))
      .subscribe(planValue => {
        this.plansFormArray.setControl(index, this.formBuilder.group(planValue));
      });
  }

  showPlanDetail(event, plan: FormGroup) {
    if (this.isPlanSummaryEmpty(plan)) {
      return;
    }
    this.salesPlanDetailModal = this.positionService.run(event.target, SalesPlanDetailModalComponent, {
      plan: plan.getRawValue(),
    });
  }

  hidePlanDetail() {
    if (this.salesPlanDetailModal) {
      this.salesPlanDetailModal.next(null);
      this.salesPlanDetailModal = null;
    }
  }

}

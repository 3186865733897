import { Injectable } from '@angular/core';
import { DELETE, GET, Path, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CustomerProfiletApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @DELETE('/api/sales-website/customers/softDelete/:id')
  deleteCustomer(@Path('id') fin: string): Observable<any> {
    return null;
  }
}

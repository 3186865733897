import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MODAL_CONFIG } from '../../constants/common';
import { ModalDirective } from 'ngx-bootstrap';
import * as _ from 'lodash';

export interface ConfirmOption {
  title: string;
  message?: string;
  okText: string;
  okAction?: () => void;
  hideOtherBtn?: boolean;
  otherText?: string | null;
  otherAction?: () => void | null;
  closeAction?: () => void;
  showClose?: boolean;
  maskSetter?: (mask: boolean) => void;
}

@Component({
  selector: 'app-confirm-with-icon-modal',
  styleUrls: ['confirm-with-icon-modal.style.scss'],
  templateUrl: 'confirm-with-icon-modal.template.html',
})
export class ConfirmWithIconModalComponent {
  modalConfig = Object.assign({}, MODAL_CONFIG, { keyboard: false });
  @ViewChild('bsModal') bsModal: ModalDirective;
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() message;
  @Input() errorMessage;
  @Input() shouldHideCancelButton: boolean = false;
  @Input() confirmButtonText = '确定';
  @Input() cancelButtonText = '取消';
  @Input() shouldShowCloseButton: boolean = false;
  @Input() leftAlignBody: boolean = false;
  @Input() centerAlignBody: boolean = false;
  @Input() modalTitle;
  @Input() shouldConfirmButtonDisabled: boolean = false;
  @Input() theme = 'primary';
  @Input() htmlTemplate;
  @Input() tipTitle;
  @Input() imgUrl;
  @Input() promptWording;
  @Input() data;

  protected _cssClass: {[key: string]: string} = {};
  @Input('class') public set cssClass(value: {[key: string]: string}) {
    this._cssClass = value;
  }
  public get cssClass(): {[key: string]: string} {
    return this._cssClass;
  }
  protected _cssStyle: {[key: string]: string} = {};
  @Input('style') public set cssStyle(value: {[key: string]: string}) {
    this._cssStyle = value;
  }
  public get cssStyle(): {[key: string]: string} {
    return this._cssStyle;
  }

  show() {
    this.bsModal.show();
  }

  hide() {
    this.bsModal.hide();
  }

  handleCancel() {
    this.hide();
    this.onCancel.emit();
  }

  handleConfirm() {
    this.hide();
    this.onConfirm.emit(this.data);
  }

  handleClose() {
    this.hide();
    this.onClose.emit();
  }

  showConfirmModal(option: ConfirmOption): void {
    const maskSetter = _.isNil(option.maskSetter) ? () => {} : option.maskSetter;

    this.tipTitle = option.title;
    this.message = option.message;

    this.confirmButtonText = option.okText;
    this.onConfirm = this.wrapperAction(option.okAction, maskSetter);

    this.shouldHideCancelButton = _.isNil(option.otherText);
    if (!this.shouldHideCancelButton) {
      this.cancelButtonText = option.otherText;
      this.onCancel = this.wrapperAction(option.otherAction, maskSetter);
    }

    this.shouldShowCloseButton = _.isNil(option.showClose) ? true : option.showClose;
    if (this.shouldShowCloseButton) {
      this.onClose = this.wrapperAction(option.closeAction, maskSetter);
    }

    maskSetter(true);
    this.show();
  }

  wrapperAction(action: () => void, masterSetter: (mask) => void): EventEmitter<{}> {
    const eventEmitter = new EventEmitter();
    eventEmitter.subscribe(() => {
      masterSetter(false);
      if (!_.isNil(action)) {
        action();
      }
    });
    return eventEmitter;
  }
}

import {
  empty as observableEmpty,
  from as observableFrom,
  Observable,
  of,
  throwError as observableThrowError,
} from 'rxjs';

import { catchError, map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {
  APPROVAL_TYPES,
  FINANCIAL_PRODUCT_PURPOSE,
  FINANCIAL_PRODUCT_TYPE,
  INSURANCE_TYPE,
  INTEGRATION_FINANCIAL_STATUS,
  INVOICE_TYPE,
  YEAR_VALUE,
} from '../constants/common';
import { OrderApi } from '../api/order';
import { Option, PackageOption } from '../models/order';

import { deleteHashCharacter, findGet, isEmptyValue } from '../utils/common';
import { BRANDS } from '../constants/vehicle';
import { fixNumber } from '../utils/format-string';
import { ERROR_MESSAGES } from '../constants/error-messages';
import { FEES_TYPE, parseServiceFeeText, parseAgentServiceFeeText } from '../constants/option-management';
import { dateParse } from '../utils/date-parse';
import { TemplateApi } from '../api/template';
import { SWAP_CAR_SALES_TYPES, VEHICLE_LICENSE_STATUS, SERVICE_CONTRACT_CATEGORY} from '../constants/order';
import { isNull } from 'util';
import { Brand, FlattenVariant } from '../models/vehicle';
import { isPreorder } from '../utils/order';
import { orderUtils } from '../../order/utils/utils';
import { VehicleApi } from '../api/vehicle';
import { CurrentUserService, StoreService, SpinnerService, FeatureToggleService } from '@otr/website-common';
import { ORDER_STATUS } from '../../vehicle/list/fields-config';

const handbagIcon = require('../../../assets/order/handbag.svg');
const shieldIcon = require('../../../assets/order/shield.svg');
const cakeIcon = require('../../../assets/order/cake.svg');
const carIcon = require('../../../assets/order/car.svg');
const EW = require('../../../assets/order/EW.svg');
const MP = require('../../../assets/order/MP.svg');
const serviceContractIcon = require('../../../assets/order/service-contract.svg');
const circleCnyIcon = require('../../../assets/order/circle-cny.svg');

function getPackageDataTitle({ code, description_zh }) {
  const packageCodeLength = 3;
  const codeSuffix = _.isNil(code) ? '' : code.slice(-packageCodeLength);
  return `${codeSuffix} ${description_zh}（经销商报价）`;
}

const getIsChangeVehicleOrder = (order) => {
  return !!_.get(order, 'auto_cancelled_order_id');
};
const getPackageData = order =>
  isPreorder(order) ? [
    {
      title: '标准经销商报价',
      text: _.get(order, 'preorder_vehicle.standard_dealer_proposal_price', 0),
      isCurrency: true,
    },
  ] : [
    {
      title: '标准经销商报价',
      text: _.get(order, 'vehicle.dealer_proposal_price.vehicle_price', 0),
      isCurrency: true,
    },
    ..._.map(order.vehicle.packages.option_packages, packageEntity => ({
      title: getPackageDataTitle(packageEntity),
      text: packageEntity.optional_option_package_dealer_proposal_price,
      isCurrency: true,
    })),
    {
      title: '实际经销商报价',
      text: _.get(order, 'vehicle.dealer_proposal_price.total_price', 0),
      isCurrency: true,
    },
  ];

function getPriceGap(order) {
  const path = isPreorder(order)
    ? 'preorder_vehicle.standard_dealer_proposal_price'
    : 'vehicle.dealer_proposal_price.total_price';
  const dealerProposalPrice = _.get(order, path, 0);
  const priceDifference = dealerProposalPrice - order.total_vehicle_price;

  return priceDifference === 0 ? [] : [
    {
      title: priceDifference < 0 ? '新车其他' : '折扣',
      text: Math.abs(priceDifference),
      isCurrency: true,
    },
  ];
}
const getFreebies = orderFreebies => _.map(orderFreebies, freebie => ({
  title: freebie.text,
  amount: freebie.amount,
  text: freebie.typedCost,
  customer_choice: freebie.customer_choice,
  isCurrency: !_.isNil(freebie.typedCost),
  isExpired: freebie.isInvalid,
  bmbs_dealer: freebie.bmbs_dealer,
  customerAttribute: freebie.customerAttribute,
  department: freebie.department,
  marketReferPrice: _.get(freebie, 'marketReferPrice'),
  sicode: _.get(freebie, 'sicode')
}));

const getLuxuryTaxAndFinalVehiclePriceData = (order, isLuxuryTaxActive) =>
  !_.includes(SWAP_CAR_SALES_TYPES, order.sales_type) && isLuxuryTaxActive
  ? [
    {
      title: '消费税及附加',
      text: _.add(order.consumptionTax, order.additionalTax),
      isCurrency: true,
    },
    {
      title: '车辆成交价',
      text: order.final_vehicle_price || 0,
      isCurrency: true,
    },
  ]
  : [
    {
      title: '车辆成交价',
      text: order.final_vehicle_price || 0,
      isCurrency: true,
    },
  ];

const getVehicleData = (order, isLuxuryTaxActive) => [
  ...getPriceGap(order),
  {
    title: '车辆总价',
    text: order.total_vehicle_price || 0,
    isCurrency: true,
  },
  ...getLuxuryTaxAndFinalVehiclePriceData(order, isLuxuryTaxActive),
];

function getAccessoryData(order) {
  const accessories = order.accessories || [];
  const packages = order.accessory_packages || [];
  const accessoriesAndPackages = _.concat(accessories, packages);
  if (_.isEmpty(accessoriesAndPackages)) {
    return null;
  }

  const accessoryData = _.map(accessories, (accessory: Option) => ({
    title: accessory.productName,
    text: accessory.totalPrice,
    isCurrency: true,
    amount: accessory.amount,
    isExpired: accessory.isInvalid,
    labelOut: accessory.isInvalid,
    aftermarketProductId: accessory.aftermarketProductId,
  }));
  const packageData = _.map(packages, (pkg: PackageOption) =>
    ({
      text: pkg.totalPrice,
      isCurrency: true,
      labelOut: pkg.isInvalid,
      items: pkg.accessories,
      isInvalid: pkg.isInvalid,
      title: pkg.packageName,
      amount: pkg.amount,
      isExpired: false,
    }),
  );

  return [
    {
      icon: handbagIcon,
      title: '精品及配件',
      text: _.sumBy(accessoriesAndPackages, 'totalPrice'),
      isCurrency: true,
    },
    ...accessoryData,
    ...packageData,
  ];
}

const getInsuranceTypeValue = insuranceType => _.get(insuranceType, 'value');

const getInsuranceByType = (insurances, insuranceType) =>
  _.find(insurances, { type: getInsuranceTypeValue(insuranceType) });

const getInsuranceTotalPrice = prices => {
  return _.chain(prices)
    .map(({ price }) => price)
    .reduce((sum, price) => sum + price, 0)
    .value();
};

const getInsurancePriceListByType = (insurances, insuranceType) =>
  _.get(getInsuranceByType(insurances, insuranceType), 'prices', []);

const getIsCompanyChangedByType = (insurances, insuranceType) =>
  _.get(getInsuranceByType(insurances, insuranceType), 'isCompanyChanged', false);

const getIsExtensionChangedByType = (insurances, insuranceType) =>
  _.get(getInsuranceByType(insurances, insuranceType), 'isExtensionChanged', false);

const generateInsuranceSectionDataByType = (insurances, type) => {
  const priceList = getInsurancePriceListByType(insurances, type);
  const pricesDetailSection = _.map(priceList, ({ price, extensionYear }) => {
    return {
      title: `第${extensionYear}年`,
      text: price,
      isCurrency: true,
    };
  });

  const priceType = _.get(getInsuranceByType(insurances, type), 'text', '');
  const company = _.get(getInsuranceByType(insurances, type), 'company', '');
  const title = _.isEmpty(company) ? `${priceType}` : `${priceType} - ${company}`;

  const insuranceTotalPriceSection = [
    {
      title,
      text: `${getInsuranceTotalPrice(priceList)}`,
      isCurrency: true,
      isChanged: getIsCompanyChangedByType(insurances, type) || getIsExtensionChangedByType(insurances, type),
    },
  ];
  return _.concat([], insuranceTotalPriceSection, pricesDetailSection);
};

function getInsuranceData(order) {
  const insurances = _.values(order.insurances).filter(insurance => !_.isEmpty(insurance));
  const compulsoryPriceList = getInsurancePriceListByType(insurances, INSURANCE_TYPE.COMPULSORY);
  const commercialPriceList = getInsurancePriceListByType(insurances, INSURANCE_TYPE.COMMERCIAL);
  if (_.isEmpty(compulsoryPriceList) && _.isEmpty(commercialPriceList)) {
    return null;
  }

  const dealerCollectedStatus = _.get(order, 'dealerCollectedStatus', false);
  const tag = dealerCollectedStatus ? '(代收)' : '';

  const compulsoryTotalPrice = getInsuranceTotalPrice(compulsoryPriceList);
  const commercialTotalPrice = getInsuranceTotalPrice(commercialPriceList);
  const insuranceTitleSection = [
    {
      icon: shieldIcon,
      title: '预估保险金额（如有）',
      text: `${compulsoryTotalPrice + commercialTotalPrice}`,
      tag,
      isCurrency: true,
    },
  ];

  const compulsorySection = _.isEmpty(compulsoryPriceList)
    ? []
    : generateInsuranceSectionDataByType(insurances, INSURANCE_TYPE.COMPULSORY);
  const commercialSection = _.isEmpty(commercialPriceList)
    ? []
    : generateInsuranceSectionDataByType(insurances, INSURANCE_TYPE.COMMERCIAL);

  return _.concat(insuranceTitleSection, compulsorySection, commercialSection);
}

const getSummaryData = order =>
  [
    {
      title: '总价',
      text: order.total_price || 0,
      isCurrency: true,
    },
    {
      title: '付款方式',
      text: _.isEmpty(order.order_financial_product) ? '全款' : '贷款',
    },
    ...getMonthlyPayment(order),
  ];

const getRegistryData = order => [
  {
    title: '上牌城市',
    text: order.registration_city || '',
  },
  {
    title: '牌照状态',
    text: _.chain(VEHICLE_LICENSE_STATUS)
      .find((item: any) => item.value === order.license_plate_status)
      .get('text', '')
      .value(),
  },
];

const getSCRemarkData = order => [
  {
    title: '备注',
    text: order.remark || '',
  },
];

const getIsBankExpired = financialInfo => {
  if (FINANCIAL_PRODUCT_TYPE.BANKING.value === _.get(financialInfo, 'type')) {
    return _.get(financialInfo, 'isBankExpired', false);
  }
  return false;
};

function getMonthlyPayment(order) {
  const financialProducts = order.order_financial_product;

  if (_.isEmpty(financialProducts)) {
    return [];
  }

  const financialProductByPurpose = _.groupBy(financialProducts, 'purpose');
  const vehicleFinanceList = _.get(financialProductByPurpose, FINANCIAL_PRODUCT_PURPOSE.VEHICLE.value);
  const nonVehicleFinanceList = _.get(financialProductByPurpose, FINANCIAL_PRODUCT_PURPOSE.NON_VEHICLE.value);

  const isVehicle = true;
  const monthlyPaymentForVehicle = generateMonthlyPayment(vehicleFinanceList, isVehicle);
  const monthlyPaymentForNonVehicle = generateMonthlyPayment(nonVehicleFinanceList, !isVehicle);

  return _.concat(monthlyPaymentForVehicle, monthlyPaymentForNonVehicle);
}

const generateMonthlyPayment = (financialProducts, isVehicle) => _.isEmpty(financialProducts) ? [] : [
  {
    title: isVehicle ? '月还款（车）' : '月还款（非车）',
    text: _.sumBy(financialProducts, 'monthlyPayment') || 0,
    isCurrency: true,
  },
];

export function generateSubTitle(financeProduct) {
  const bankName =
    _.get(financeProduct, 'bankName') || _.get(financeProduct, 'productName')
      ? _.get(financeProduct, 'bankName') || '汽车金融'
      : '';

  const marketingName =
    financeProduct.type === FINANCIAL_PRODUCT_TYPE.BANKING.value
      ? financeProduct.productName
      : financeProduct.marketingName;
  return isNull(marketingName) ? bankName : `${bankName} - ${marketingName}`;
}

function generateStatus(vehicleInfo) {
  return {
    status: findGet(INTEGRATION_FINANCIAL_STATUS, { value: _.get(vehicleInfo, 'status') }, 'text', ''),
    data: dateParse(vehicleInfo.signatureDate, 'yyyy-MM-dd'),
  };
}

function generateVehicleList(financeProduct) {
  return [
    {
      label: '首付款',
      currency: financeProduct.downpayment,
    },
    {
      label: '首付比例',
      percentage: financeProduct.downpaymentPercentage,
    },
  ];
}

function getFinancialProductData(order) {
  const financialProducts = order.order_financial_product;
  if (_.isEmpty(financialProducts)) {
    return null;
  }

  const accessories = order.accessories;
  const insurances = order.insurances;
  const serviceFees = order.service_fee;
  const serviceContracts = order.service_contract;

  return {
    icon: circleCnyIcon,
    title: '金融产品（如有）',
    content: getFinancialProductContent(financialProducts, accessories, insurances, serviceFees, serviceContracts),
  };
}

function getFinancialProductContent(financialProducts, accessories, insurances, serviceFees, serviceContracts) {
  const financialProductByPurpose = _.groupBy(financialProducts, 'purpose');
  const vehicleFinanceList = _.get(financialProductByPurpose, FINANCIAL_PRODUCT_PURPOSE.VEHICLE.value);
  const nonVehicleFinanceList = _.get(financialProductByPurpose, FINANCIAL_PRODUCT_PURPOSE.NON_VEHICLE.value);

  const vehicleData = _.isEmpty(vehicleFinanceList) ? [] : generateVehicleData(vehicleFinanceList);
  const nonVehicleData = _.isEmpty(nonVehicleFinanceList)
    ? []
    : generateNonVehicleData(nonVehicleFinanceList, accessories, insurances, serviceFees, serviceContracts);

  return _.concat(vehicleData, nonVehicleData);
}

function generateVehicleData(vehicleFinanceList) {
  return [
    {
      purpose: FINANCIAL_PRODUCT_PURPOSE.VEHICLE.text,
      section: getVehicleSection(vehicleFinanceList),
    },
  ];
}

function generateNonVehicleData(nonVehicleFinanceList, accessories, insurances, serviceFees, serviceContracts) {
  const accessoriesData = _.groupBy(accessories, 'financialProductId');
  const serviceFeesData = _.groupBy(serviceFees, 'financialProductId');
  const serviceContract = _.groupBy(serviceContracts, 'financialProductId');
  const insurancesData = getInsurancesData(insurances);
  const nonVehicleFinanceInfo = _.map(nonVehicleFinanceList, financialProduct => {
    const productId = _.get(financialProduct, 'id');
    financialProduct.accessories = _.get(accessoriesData, productId);
    financialProduct.insurances = _.get(insurancesData, productId);
    financialProduct.serviceFee = _.get(serviceFeesData, productId);
    financialProduct.service_contract = _.get(serviceContract, productId);
    return financialProduct;
  });
  return [
    {
      purpose: FINANCIAL_PRODUCT_PURPOSE.NON_VEHICLE.text,
      section: getNonVehicleSection(nonVehicleFinanceInfo),
    },
  ];
}

function getVehicleSection(vehicleFinanceList) {
  return _.map(vehicleFinanceList, financeProduct => {
    return {
      subTitle: generateSubTitle(financeProduct),
      subText: generateStatus(financeProduct),
      isChanged: getIsBankExpired(financeProduct),
      list: generateVehicleList(financeProduct),
    };
  });
}

function getNonVehicleSection(nonVehicleFinanceList) {
  return _.map(nonVehicleFinanceList, financeProduct => {
    return {
      subTitle: generateSubTitle(financeProduct),
      subText: generateStatus(financeProduct),
      isChanged: getIsBankExpired(financeProduct),
      list: generateNonVehicleList(financeProduct),
    };
  });
}

function generateNonVehicleList(financeProduct) {
  const accessoryList = _.map(financeProduct.accessories, accessory => {
    return {
      label: _.get(accessory, 'productName'),
      text: `x${_.get(accessory, 'amount')}`,
    };
  });

  const insuranceList = _.map(financeProduct.insurances, insurance => {
    const extensionYear = _.get(insurance, 'extensionYear');
    return {
      label: _.get(insurance, 'company'),
      text: _.find(YEAR_VALUE, year => year.extensionYear === extensionYear).text,
    };
  });
  const purchaseTax = financeProduct.serviceFee ? { label: '购置税' } : [];

  const serviceContractList = _.map(financeProduct.service_contract, serviceContract => {
    return {
      label: _.get(serviceContract, 'serviceName'),
      text: `x${_.get(serviceContract, 'amount')}`,
    };
  });

  return _.concat(accessoryList, insuranceList, purchaseTax, serviceContractList);
}

function getInsurancesData(insurances) {
  const insuranceList = insurances.map(insurance => {
    const company = insurance.company;
    const prices = insurance.prices;
    return prices.map(priceItem => {
      return {
        ...priceItem,
        company,
      };
    });
  });
  const insuranceDate = _.flatMap(insuranceList);

  return _.groupBy(insuranceDate, 'financialProductId');
}

function getServiceFeeData(order) {
  const serviceFees = _.get(order, 'service_fee', []);
  const filteredServiceFees = _.filter(serviceFees, item => {
    return item.fees_type === FEES_TYPE.SERVICE_FEE || item.fees_type === FEES_TYPE.NON_VEHICLE_SERVICE_FEE;
  });
  if (_.isEmpty(filteredServiceFees)) {
    return null;
  }

  const serviceFeeData = _.map(filteredServiceFees, serviceFee => ({
    title: parseServiceFeeText(serviceFee),
    text: serviceFee.fee,
    isCurrency: true,
  }));
  return [
    {
      title: '服务费用',
      icon: cakeIcon,
    },
    ...serviceFeeData,
  ];
}

function getServiceContractData(order) {
  const contracts = order.service_contract;
  if (_.isEmpty(contracts)) {
    return null;
  }
  const totalPrice = contracts.reduce((total, contract) => total + contract.price * (contract.amount || 1), 0);
  const contractsData = _.map(contracts, (contract: any) => {
    const showCircleIconFlag = !!contract.applyType;

    return {
      title: _.get(contract, 'serviceName'),
      text: contract.price * contract.amount,
      amount: contract.amount,
      isCurrency: true,
      isInvalid: contract.isInvalid,
      isExpired: false,
      icon: contract.productType ? contract.productType === SERVICE_CONTRACT_CATEGORY.EW ? EW : MP : null,
      tips: showCircleIconFlag,
      tipContent: `适用范围: ${contract.applyType}`
    };
  });

  return [
    {
      title: '服务合同',
      icon: serviceContractIcon,
      isCurrency: true,
      text: totalPrice,
    },
    ...contractsData,
  ];
}

function getTradeInData(order) {
  const tradeInObj = JSON.parse(_.get(order, 'trade_in', JSON.stringify({})));
  if (_.isEmpty(tradeInObj)) {
    return null;
  }
  const tradeInEnable = _.get(tradeInObj, 'enable', true);
  const tag = tradeInEnable ? '(抵减)' : '';
  return [
    {
      title: '置换',
      icon: carIcon,
      text: tradeInObj.price,
      isCurrency: true,
      tag,
    },
    {
      title: '置换车',
      text: tradeInObj.vehicle,
    },
    {
      title: '置换价格',
      text: tradeInObj.price,
      isCurrency: true,
    },
  ];
}

@Injectable()
export class OrdersService {
  constructor(
    private orderApi: OrderApi,
    private templateApi: TemplateApi,
    private storeService: StoreService,
    private spinnerService: SpinnerService,
    private currentUserService: CurrentUserService,
    private vehicleApi: VehicleApi,
    private featureToggleService: FeatureToggleService,
  ) {
  }

  private preOrderBrand: Array<FlattenVariant> = [];

  getLead(id: string) {
    return this.orderApi.getLead(id);
  }

  getDataListByOrderId(data) {
    return this.orderApi.getListData(data);
  }

  getOrderById(id) {
    return this.getOrder(id).pipe(
      tap(order => {
        this.storeService.update('order', order);
      }),
      catchError(error => {
        if (error.status === 403) {
          return observableThrowError(ERROR_MESSAGES.FORBIDDEN_TO_VIEW_ORDER);
        }
        this.storeService.update('order', null);
        return observableEmpty();
      }),
    );
  }

  getOrderDocumentByOrderIdAndTemplateId(id: string, documentTemplateId: number, docType: string) {
    const body = JSON.stringify({ docType, id, documentContractType: 'ORDER' });
    return this.orderApi
      .getOrderContractDocument(documentTemplateId, body)
      .pipe(map(document => ({ id: documentTemplateId, url: document.url })));
  }

  getContractTypesByDealerId() {
    return this.templateApi.getDocumentTemplates(this.currentUserService.getCurrentUser().dealerId).pipe(
      mergeMap(contract => observableFrom(contract.documentTemplates)),
      map((contract: any) => ({
        id: contract.id,
        type: contract.type,
        text: contract.description,
      })),
      toArray(),
    );
  }

  getOrder(id: string) {
    return this.orderApi.getOrder(id);
  }

  updateOrderUseProfile(id: string, data: Object) {
    const thinData = {
      ..._.omit(data, 'vehicle', 'freebie'),
      vehicle: _.pick(_.get(data, 'vehicle'), 'bmbs_retail_date'),
    };
    return this.orderApi.updateOrderById(id, thinData).pipe(
      switchMap(() => {
        return this.getOrder(id).pipe(
          tap(order => this.storeService.update('order', order)),
          this.spinnerService.loading(),
        );
      }),
    );
  }

  getInvoiceAlertMessage(orderId): Observable<any> {
    return this.orderApi.getInvoiceAlertMessage(orderId);
  }

  get isMBEPaymentType(): boolean {
    return this.featureToggleService.isMBEPaymentTypeEnable;
  }

  getCustomizeDataStructure(order, isLuxuryTaxActive) {
    return {
      packageData: getPackageData(order),
      summaryData: getSummaryData(order),
      registryData: getRegistryData(order),
      freebies: getFreebies(order.freebies),
      vehicleData: getVehicleData(order, isLuxuryTaxActive),
      accessoryData: getAccessoryData(order),
      insuranceData: getInsuranceData(order),
      sCRemarkData: getSCRemarkData(order),
      financialProductData: getFinancialProductData(order),
      serviceFeeData: getServiceFeeData(order),
      tradeInData: getTradeInData(order),
      contractData: getServiceContractData(order),
      mbeInfoData: orderUtils.getMBEInfoData(order.mbeInfo, false, getIsChangeVehicleOrder(order)),
    };
  }

  hasExpiredOption(order) {
    return _.chain(order)
      .get('freebies', '[]')
      .some({ isInvalid: true } as any)
      .value();
  }

  hasExpiredOptionForServiceContract(order) {
    return _.chain(order)
      .get('service_contract', '[]')
      .some({ isInvalid: true } as any)
      .value();
  }

  hasServiceContractProductLimitFailed(order) {
    return _.chain(order)
      .get('service_contract', '[]')
      .some({ isProductLimitFailed: true } as any)
      .value();
  }

  hasServiceContractCategoryFailed(order) {
    return _.chain(order)
      .get('service_contract', '[]')
      .some({ isCategoryLimitFailed: true } as any)
      .value();
  }

  saveOrderApproval(orderId: string, orderData) {
    return this.orderApi.updateOrder(orderId, orderData).pipe(
      switchMap(() => {
        return this.getOrder(orderId).pipe(
          tap(order => this.storeService.update('order', order)),
        );
      }),
    );
  }

  validateCommissionNo(orderId: string, commissionNo: string) {
    return this.orderApi.validateCommissionNo(orderId, commissionNo);
  }

  assignVehicleToPreorder(orderId: string, commissionNo: string) {
    return this.orderApi.assignVehicle(orderId, {
      commission_no: commissionNo,
    }).pipe(
      switchMap(() => {
        return this.getOrder(orderId);
      }),
    );
  }

  getCustomizeDataStructureForPreorder(order, isLuxuryTaxActive) {
    return {
      packageData: getPackageData(order),
      summaryData: getSummaryData(order),
      registryData: getRegistryData(order),
      freebies: getFreebies(order.freebies),
      vehicleData: getVehicleData(order, isLuxuryTaxActive),
      accessoryData: getAccessoryData(order),
      insuranceData: getInsuranceData(order),
      financialProductData: getFinancialProductData(order),
      sCRemarkData: getSCRemarkData(order),
      serviceFeeData: getServiceFeeData(order),
      tradeInData: getTradeInData(order),
      contractData: getServiceContractData(order),
      mbeInfoData: orderUtils.getMBEInfoData(order.mbeInfo, this.isMBEPaymentType, getIsChangeVehicleOrder(order)),
    };
  }

  getOrdersByPageNumber(searchData, filter, page) {
    return this.orderApi.searchOrders(
      this.currentUserService.getCurrentUser().dealerId,
      JSON.stringify(deleteHashCharacter(searchData)),
      JSON.stringify(deleteHashCharacter(filter)),
      page,
    );
  }

  getPreOrdersByPageNumber(searchData, filter, page) {
    return this.orderApi.searchPreOrders(
      this.currentUserService.getCurrentUser().dealerId,
      JSON.stringify(searchData),
      JSON.stringify(filter),
      page,
    );
  }

  getShouldShowPromptWithConflictVehicleLocation(order) {
    const activeFlows = _.filter(order.approval_flows, { type: APPROVAL_TYPES.INVOICE.value });
    const isLastApprovalFlow =
      !_.isNil(_.get(order, 'active_approval_step')) && order.active_approval_step.step_no === activeFlows.length;
    return isLastApprovalFlow && this.getIsVehicleShowroomDifferentWithOrderShowroom(order);
  }

  getIsVehicleShowroomDifferentWithOrderShowroom(order) {
    return (
      !_.isNil(_.get(order, 'vehicle_location')) &&
      !_.isNil(_.get(order, 'vehicle.stock.location')) &&
      order.vehicle_location !== order.vehicle.stock.location
    );
  }

  getPreOrderModels(): Observable<Array<FlattenVariant>> {
    if (!isEmptyValue(this.preOrderBrand)) {
      return of(this.preOrderBrand);
    }
    return this.vehicleApi.getExtendedReleasedBrands()
      .pipe(map((brands: Array<Brand>) => {
        this.preOrderBrand = _.chain(brands)
          .flatMap('classes')
          .flatMap(this.getVariantFromClass)
          .orderBy('description')
          .value();
        return this.preOrderBrand;
      }));
  }

  getVariantFromClass(vehicleClass) {
    return _.chain(vehicleClass)
      .get('models')
      .flatMap('variants')
      .map(variant => ({
        ...variant,
        description: variant.description_zh || variant.description_en || '',
        classId: vehicleClass.class_id,
      }))
      .value();
  }

  releaseVehicle(order) {
    return this.orderApi.releaseVehicle(order.id);
  }

  getSwapCarInfo(order) {
    return [
      {
        label: 'GS编码',
        text: order.gs_code,
        comment: order.outlet_name,
      },
      {
        label: '发送状态',
        text: order.actual_swap_date == null ? '车辆未发送' : '车辆已发送',
      },
    ];
  }

  getVehicleBasicInfo(order) {
    return [
      {
        label: '生产号',
        text: order.vehicle.detail.commission_no,
      },
      {
        label: 'VIN',
        text: order.vehicle.detail.vehicle_id_no_vin,
      },
      {
        label: 'NST',
        text: order.vehicle.detail.variant_baumuster_nst,
      },
      {
        label: '库存类型',
        text: '中央库存',
        hide: _.get(order, 'mbeInfo.stockType') !== 'central',
      },
    ];
  }

  getInvoiceName(order) {
    const invoice: any = _.get(order, 'invoice', {});
    return invoice.type === INVOICE_TYPE.PERSON.value ? invoice.invoiceRecipientName : invoice.company_name;
  }

  getInvoiceMobile(order) {
    const invoice: any = _.get(order, 'invoice', {});
    return (
      ((invoice.type === INVOICE_TYPE.PERSON.value || _.isEmpty(order.lead_id)) && invoice.mobile) ||
      invoice.company_tel ||
      ''
    );
  }

  getInvoiceDmsId(order) {
    const invoice: any = _.get(order, 'invoice', {});
    return invoice.type === INVOICE_TYPE.PERSON.value ? invoice.dms_customer_magic : invoice.dms_company_magic;
  }

  getOrderCreatorAndCustomerInfo(order, lead) {
    return [
      {
        label: '创建人',
        text: order.owner_name,
      },
      {
        label: '订购客户',
        text: order.lead_name,
      },
      {
        label: '订购客户手机号码',
        text: _.get(order, 'lead_mobile', ''),
      },
      {
        label: '发票客户',
        text: this.getInvoiceName(order),
      },
      {
        label: '发票客户手机号码',
        text: this.getInvoiceMobile(order),
      },
    ];
  }

  isSmartBrand(brand) {
    return _.isEqual(BRANDS.SMART, brand);
  }

  getVehicleColors(order) {
    return _.reject(
      [
        {
          label: `${this.isSmartBrand(order.vehicle.detail.brand) ? '车体结构颜色' : '颜色'}`,
          text: order.vehicle.detail.paint_code,
          comment: order.vehicle.detail.paint || '',
          shouldHiddenContent: _.isEmpty(order.vehicle.detail.paint_code),
        },
        {
          label: `经销商报价${this.isSmartBrand(order.vehicle.detail.brand) ? '（车体结构颜色）' : '（颜色）'}`,
          text: fixNumber(order.vehicle.dealer_proposal_price.paint_price),
          shouldHiddenContent: _.isEmpty(order.vehicle.detail.paint_code),
        },
        {
          label: '车身面板颜色',
          text: order.vehicle.detail.upper_paint_code,
          comment: order.vehicle.detail.upper_paint || '',
          hidden: !this.isSmartBrand(order.vehicle.detail.brand),
          shouldHiddenContent: _.isEmpty(order.vehicle.detail.upper_paint_code),
        },
        {
          label: '经销商报价（车身面板颜色）',
          text: fixNumber(order.vehicle.dealer_proposal_price.upper_paint_price),
          hidden: !this.isSmartBrand(order.vehicle.detail.brand),
          shouldHiddenContent: _.isEmpty(order.vehicle.detail.upper_paint_code),
        },
        {
          label: '内饰',
          text: order.vehicle.detail.trim_code,
          comment: order.vehicle.detail.trim || '',
          shouldHiddenContent: _.isEmpty(order.vehicle.detail.trim_code),
        },
        {
          label: '经销商报价（内饰）',
          text: fixNumber(order.vehicle.dealer_proposal_price.trim_price),
          shouldHiddenContent: _.isEmpty(order.vehicle.detail.trim_code),
        },
      ],
      { hidden: true },
    );
  }

  getVehicleDescription(order: any) {
    return {
      label: '车辆描述',
      text: order.vehicle.detail.description,
    };
  }

  getPaginateOrdersByLeadId(leadId: string, page: number, size: number) {
    return this.orderApi.getPaginationOrdersByLeadId(leadId, page, size);
  }

  getAutoActiveQuotationById(id: string) {
    return this.orderApi.getAutoActiveQuotationById(id);
  }

  downloadPdfByQuotationId(id, printContentType): Observable<any> {
    return this.orderApi.downloadPdfByQuotationId(id, printContentType);
  }
  
  downloadPdfByCommissionId(commissionId): Observable<any> {
    return this.orderApi.downloadPdfByCommissionId(commissionId);
  }

  fetchOrderFromOccAsInvalidStatus(orderIds): Observable<any> {
    return this.orderApi.fetchOrderFromOccAsInvalidStatus(orderIds);
  }

  downloadPdfByBrand(data, brand): Observable<any> {
    return this.orderApi.downloadPdfByBrand(data, brand);
  }
  fetchDocumentById(data): Observable<any> {
    return this.orderApi.fetchDocumentById(data);
  }

  retrigger(obj, id): Observable<any> {
    const newObj = { quotationOrderAndVehicleDTO: obj, id };
    return this.orderApi.retrigger(newObj);
  }
}

<div *ngIf="hideLabel" [tooltip]="(disabled$ | async) ? contentTemplate: null" placement="top" container="body" >
       <input type="checkbox"
              [checked]="checked$ | async"
              [disabled]="disabled$ | async"
              (click)="toggle($event)"
              [ngClass]="{'can-pointer-events': (disabled$ | async)}"
       />
</div>
<span *ngIf="!hideLabel">{{params.data.defaultLabel}}</span>
<ng-template #contentTemplate>
<div>
       <p class="main-notice">提示：</p>
       <p class="main-content">当前车辆暂无车主和联系人，请先维护车辆对应的车主或联系人后，再新建任务</p>
</div>
</ng-template>

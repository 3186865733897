import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CHINESE_CALENDAR_CONFIG } from '../../../../../constants/calendar';
import { IAfterGuiAttachedParams, IFilterParams } from 'ag-grid';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';
import { isEmptyValue } from '../../../../../utils/common';
import { getDateAtZero } from '../../../../../utils/date';
import { dateParse, dateRegularParse } from '../../../../../utils/date-parse';
import * as _ from 'lodash';
import { ServerTimeService } from '../../../../../services/server-time.service';
import { DateFormat } from '../../../../../constants/date';
import * as moment from 'moment';

@Component({
  selector: 'app-pagination-calendar-filter-cell',
  templateUrl: 'pagination-calendar-filter-cell.component.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationCalendarFilterCellComponent extends BasePaginationFilterComponent {
  dateControl: FormControl = new FormControl('');
  savedDateValue: string = '';
  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;
  minDate: Date;

  constructor(private serverTimeService: ServerTimeService) {
    super();
  }

  agInit(params: IFilterParams): void {
    super.agInit(params);
    this.minDate = _.get(params, 'colDef.filterParams.minDate', this.serverTimeService.currentDate);
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    super.afterGuiAttached(params);
    this.dateControl.setValue(this.savedDateValue);
  }

  onSubmit() {
    super.hideFilterMenu();
    this.savedDateValue = this.dateControl.value;
    this.params.filterChangedCallback();
  }

  isFilterActive(): boolean {
    return !this.isEmptyFilter();
  }

  onCancel() {
    super.hideFilterMenu();
    this.dateControl.setValue(this.savedDateValue);
  }

  onCalendarBlur() {
    this.dateControl.updateValueAndValidity();
  }

  public setDefaultValue() {
    super.setDefaultValue();
    this.dateControl.setValue('');
    this.savedDateValue = this.dateControl.value;
  }

  get filterValue() {
    return isEmptyValue(this.savedDateValue) ? null : getDateAtZero(new Date(this.savedDateValue));
  }

  isEmptyFilter() {
    return isEmptyValue(this.savedDateValue);
  }

  isFilterValid() {
    return isEmptyValue(this.savedDateValue) || moment(this.minDate).isSameOrBefore(this.savedDateValue, 'day');
  }

  stopPropagation() {
    event.stopPropagation();
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    return this.modelFormatter(isEmptyValue(this.savedDateValue) ? null
      : dateParse(new Date(this.savedDateValue), DateFormat.DATE));
  }

  setModel(model: string): void {
    this.savedDateValue = dateRegularParse(model);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../../shared/model';
import { CustomerApi } from '../../shared/api/customer';
import * as _ from 'lodash';
import { AGE_RANGE_OPTIONS } from 'app/lead/constants/lead';
import { dateParse } from 'app/_common/utils/date-parse';

@Injectable()
export class CustomerService {
  constructor(private customerApi: CustomerApi) {}
  tagList = {isSyncFeatureTags: false, tags: []};

  getCustomerById(customerId: string): Observable<any> {
    return this.customerApi.getCustomerById(customerId);
  }

  updateCustomer(customer: Customer): Observable<Customer> {
    return this.customerApi.updateCustomer(customer);
  }

  getIsSyncFeatureTags(customerId: string): Observable<any> {
    return this.customerApi.getIsSyncFeatureTags(customerId);
  }

  getFeatureTags(featureTags, isSyncTags, customerId) {
    if ((featureTags && isSyncTags)) {
      this.tagList = {isSyncFeatureTags: true, tags: featureTags.split(',')};
    }
    this.customerApi.getIsSyncFeatureTags(customerId)
      .subscribe(
        response => {
          if (response.feature_tags !== '' && !_.isNil(response.feature_tags)) {
            this.tagList = {isSyncFeatureTags: true, tags: response.feature_tags.split(',')};
          } else {
            this.tagList = {isSyncFeatureTags: true, tags: []};
          }
        },
        () => {
          this.tagList = {isSyncFeatureTags: false, tags: []};
        });
    return this.tagList;
  }

  // tslint:disable-next-line:cyclomatic-complexity
  getCustomerAge(cardType, cardNumber, birthday, detailAge) {
    const date = dateParse(birthday, 'yyyy-MM-dd');
    const reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
    if (cardType === 'IDENTITY_CARD' && cardNumber && reg.test(cardNumber) && cardNumber.length === 18) {
      const age = this.calculateAge(cardNumber.substring(6, 10), cardNumber.substring(10, 12), cardNumber.substring(12, 14));
      if (age) {
        return age;
      }
    }
    if (birthday) {
      const birArr = date.split('-');
      const age = this.calculateAge(birArr[0], birArr[1], birArr[2]);
      if (age) {
        return age;
      }
    }
    if (detailAge) {
      return detailAge;
    }
    return null;
  }

  calculateAge(yearBirth, monthBirth, dayBirth) {
    const myDate = new Date();
    const monthNow = myDate.getMonth() + 1;
    const dayNow = myDate.getDate();
    let age = myDate.getFullYear() - yearBirth;
    if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
      age--;
    }
    const ageRangeOptions = AGE_RANGE_OPTIONS.values;
    if (age >= 18) {
      const ageValue = _.get(_.find(ageRangeOptions, item => item.from <= age && item.to >= age), 'value');
      return ageValue;
    }
    return null;
  }
}

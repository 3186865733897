<div bsModal #bsModal="bs-modal"
     class="modal fade modal-container">
  <div class="modal-content">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">方案</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
      </div>
      <div class="modal-body">
        <div class="content-container">
          <span class="title">提升行动方案
            <i class="fa antd-question-circle-filled"
               tooltip="加入数据收集流程"
            ></i>
          </span>
          <textarea
            cols="20" rows="5"
            maxlength="1000"
            class="customize-textarea"
            [(ngModel)]="plan.description">
          </textarea>
        </div>

        <div class="content-container">
          <span class="title">时间</span>
          <app-date-range
            [startDateControl]="from_date"
            [endDateControl]="to_date">
          </app-date-range>
        </div>

        <div class="content-container">
          <div *ngFor="let week of weeklyPlanTitle" class="week-plan">
            <span class="title">{{week.text}}</span>
            <textarea
              cols="20" rows="5"
              maxlength="1000"
              class="customize-textarea"
              [(ngModel)]="plan[week.value]"
            >
            </textarea>
          </div>
        </div>

        <div class="operation">
          <button class="btn btn-outline-primary" (click)="cancel()">取消</button>
          <button class="btn btn-primary" (click)="confirm()">确认</button>
        </div>
      </div>
    </div>
  </div>
</div>

import * as _ from 'lodash';
import { isEmptyValue } from './common';

export const isValidIntegerValue = value => {
  if (isEmptyValue(value)) {
    return true;
  }
  return _.isFinite(Number(value));
};

export const valueFormat = value => {
  if (!isValidIntegerValue(value)) {
    return '-';
  }
  if (isEmptyValue(value)) {
    return '';
  }
  return value;
};

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

import { LEAD_STATUS } from '../constants/common';

@Pipe({ name: 'leadStatus' })
export class LeadStatusPipe implements PipeTransform {
  transform(status: string): string {
    return _.chain(LEAD_STATUS.values)
      .find((leadStatus: any) => leadStatus.value === status)
      .get('text', '未定义')
      .value();
  }
}

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { ModalPosition, PositionedModal } from '../../../../_common/components/modal/services/position';
import { WEEKLY_PLAN_TITLE } from '../../../constants/sales-strategy';
import { getFirstDayOfMonth, getLastDayOfMonth } from '../../../../_common/utils/date';
import { MODAL_CONFIG } from '../../../../_common/constants/common';

@Component({
  selector: 'app-sales-plan-edit-modal',
  templateUrl: './sales-plan-edit-modal.html',
  styleUrls: ['./sales-plan-edit-modal.scss'],
})
export class SalesPlanEditModalComponent implements PositionedModal, AfterViewInit {
  @ViewChild('bsModal') bsModal;

  subject: Subject<any>;
  plan;
  from_date = new FormControl();
  to_date = new FormControl();
  isEdit: boolean = false;
  weeklyPlanTitle = WEEKLY_PLAN_TITLE;
  modalConfig = MODAL_CONFIG;

  constructor() {
    this.subject = new Subject();
  }

  ngAfterViewInit(): void {
    this.bsModal.show();
  }

  getSubject(): Subject<any> {
    return this.subject;
  }

  cancel() {
    this.subject.next(null);
  }

  confirm() {
    this.subject.next({
      ...this.plan,
      from_date: this.from_date.value,
      to_date: this.to_date.value,
    });
  }

  setProperties(options: any): void {
    this.plan = options.plan;
    const fromDate = options.plan.from_date;
    const toDate = options.plan.to_date;
    this.from_date.setValue(fromDate ? new Date(fromDate) : getFirstDayOfMonth());
    this.to_date.setValue(toDate ? new Date(toDate) : getLastDayOfMonth());
  }

  getClientHeight(): number {
    return undefined;
  }

  getClientWidth(): number {
    return undefined;
  }

  setPosition(position: ModalPosition): void {
  }

}

import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { Subject } from 'rxjs';
import { PositionedModal } from '../components/modal/services/position';
import { auditTime } from 'rxjs/operators';

@Injectable()
export class PositionService {
  private static calcDirection(total, position, target, self) {
    return total > position + target + self ? position + target : position - self;
  }

  private static calcCross(total, position, target, self) {
    return total > position + self ? position : position + target - self;
  }

  public static getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
  }

  private createComponent<T>(componentClass: Type<T>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    return componentFactory.create(this.injector);
  }

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private applicationRef: ApplicationRef,
              private injector: Injector) {
  }

  run(target: HTMLElement, componentClass: Type<PositionedModal>, options: any = null): Subject<any> {
    const { left, top, width, height } = target.getBoundingClientRect();
    const component = this.createComponent(componentClass);
    const componentInstance = component.instance;

    componentInstance.setProperties(options);
    component.changeDetectorRef.detectChanges();
    this.attachToDocument(component);

    componentInstance.setPosition({
      left: PositionService.calcCross(window.document.body.getBoundingClientRect().width, left, width,
        componentInstance.getClientWidth()),
      top: PositionService.calcDirection(
        window.innerHeight ||
        window.document.documentElement.clientHeight ||
        window.document.body.getBoundingClientRect().height,
        top, height,
        componentInstance.getClientHeight()),
    });

    this.destroyComponent(component);
    return componentInstance.getSubject();
  }

  attachToDocument(componentRef: ComponentRef<any>) {
    const element = PositionService.getComponentRootNode(componentRef);
    document.body.appendChild(element);
    this.applicationRef.attachView(componentRef.hostView);
  }

  private destroyComponent(component: ComponentRef<any>) {
    const subject = component.instance.getSubject();
    subject.pipe(auditTime(10)).subscribe(() => {
      this.applicationRef.detachView(component.hostView);
      subject.complete();
      component.destroy();
    });
  }
}

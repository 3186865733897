import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ServiceContractApi } from '../api/service-contract';
import {
  BasicCarInfo,
  HistoryOrderItem,
  HistoryOrdersQueryFilter,
  HistoryOrdersResponse,
  LpoSalesRecordResponse,
  SalesRecordQueryFilter,
  ServiceContractProductFilter,
  ServiceContractProductResponse,
} from '../models/quotation-collection';
import {
  ActivateSsscResponse,
  AutoActiveProducts,
  LpoSsscProductInfo,
  ProductResponse,
  ProductWithDealersResponse,
  QuotationHistory,
  ResponsePage,
  SsscPackage,
  SsscProduct,
  SsscQuotationDetail,
  SsscQuotationsResponse,
  SsscRequest,
} from '../models/sssc-collection';
import { ServiceContractDocumentRequest } from '../models/service-contract-documents';
import * as _ from 'lodash';
import * as moment from 'moment';
import { OemExportDataStatusResponse } from '../models/oem-export-data-collection';
import {
  LpoOrderLinkRequest,
  UpdateProductInfoWithDealersDto, UsedCarRelatedDate,
  UsedCarRelatedDateRequest,
} from '../models/service-contract-product';
import { isNumericOrEmptyString } from '../utils/common';
import { LocalQuotation, LocalQuotationWithCoupon } from '../models/service-contract';
import { HttpResponse } from '@angular/common/http';
import { Role } from '../../system-config/dealer/model';
import { FeatureToggleService, TokenService } from '@otr/website-common';
import { FeatureToggle } from '../constants/feature-toggle';

@Injectable()
export class ServiceContractService {
  constructor(
    private serviceContractApi: ServiceContractApi,
    private featureToggleService: FeatureToggleService,
    private tokenService: TokenService) {}

  public serviceContractPriceUploadUri = '/api/sales-website/lpo-service-contract/dealer-price-excel';
  public extendServiceContractPriceUploadUri = '/api/sales-website/lpo-service-contract/dealer-price-excel/extend';

  saveQuotationInfo(data): Observable<any> {
    return this.serviceContractApi.saveQuotationInfo(data);
  }

  saveSsscQuotationInfo(vehicleBusinessType: string, data: SsscRequest[]): Observable<any> {
    return this.serviceContractApi.saveSsscQuotationInfo(vehicleBusinessType, data);
  }

  updateSsscQuotationInfo(vehicleBusinessType: string, data: SsscRequest[]): Observable<any> {
    return this.serviceContractApi.updateSsscQuotationInfo(vehicleBusinessType, data);
  }

  getQuotationsBySa(pageNumber: number, pageSize: number, filter: HistoryOrdersQueryFilter): Observable<HistoryOrdersResponse> {
    return this.serviceContractApi.getQuotationsBySa(JSON.stringify(filter), pageSize, pageNumber);
  }

  getQuotationsForOem(pageNumber: number, pageSize: number, filter: HistoryOrdersQueryFilter): Observable<HistoryOrdersResponse> {
    return this.serviceContractApi.getQuotationsForOem(JSON.stringify(filter), pageSize, pageNumber);
  }

  getGeneratedQuotationsByFinOrVin(fin: string, vin: string): Observable<HistoryOrderItem[]> {
    return this.serviceContractApi.getGeneratedQuotationsByFinOrVin(fin, vin);
  }

  getSsscQuotations(pageNumber: number, pageSize: number, filter: any): Observable<SsscQuotationsResponse> {
    return this.serviceContractApi.getSsscQuotations(JSON.stringify(filter), pageSize, pageNumber);
  }

  getSsscQuotationDetailsByFin(fin: string): Observable<SsscQuotationDetail[]> {
    return this.serviceContractApi.getSsscQuotationDetailsByFin(fin);
  }
  getOemSsscQuotationDetailsByFin(fin: string): Observable<SsscQuotationDetail[]> {
    return this.serviceContractApi.getOemSsscQuotationDetailsByFin(fin);
  }

  getHistorySsscQuotations(pageNumber: number, pageSize: number, filter: any): Observable<any> {
    return this.serviceContractApi.getHistorySsscQuotations(JSON.stringify(filter), pageSize, pageNumber);
  }

  getSsscQuotationsForOem(pageNumber: number, pageSize: number, filter: any): Observable<any> {
    return this.serviceContractApi.getSsscQuotationsForOem(JSON.stringify(filter), pageSize, pageNumber);
  }

  getQuotation(id): Observable<any> {
    return this.serviceContractApi.getQuotation(id);
  }

  getLpoOrderDetail(gsCode: string, lpoOrderNumber: string, isExtend: boolean) {
    return this.serviceContractApi.getLpoOrderDetail(gsCode, lpoOrderNumber, isExtend);
  }

  getSsscSalesRecordDetail(queryDto) {
    return this.serviceContractApi.getSsscSalesRecordDetail(queryDto);
  }

  getSsscQuotationById(id): Observable<SsscProduct> {
    return this.serviceContractApi.getSsscQuotationById(id);
  }

  getQuotationForOem(id): Observable<any> {
    return this.serviceContractApi.getQuotationForOem(id);
  }

  activateQuotationInfo(id): Observable<any> {
    return this.serviceContractApi.activateQuotation(id);
  }

  printQuotation(id, printContentType): Observable<any> {
    return this.serviceContractApi.printQuotation(id, printContentType);
  }

  printLpoQuotation(otrQuotationNumber): Observable<any> {
    return this.serviceContractApi.printLpoQuotation(otrQuotationNumber);
  }

  getVehicleServiceProducts(serviceContractProductFilter: ServiceContractProductFilter): Observable<ServiceContractProductResponse> {
    return this.serviceContractApi.getVehicleServiceProducts(
      serviceContractProductFilter.fin,
      serviceContractProductFilter.vin,
      serviceContractProductFilter.currentMile,
      serviceContractProductFilter.dealerId,
      serviceContractProductFilter.gsCode,
      serviceContractProductFilter.guaranteeStartDate,
      serviceContractProductFilter.firstRegistrationDate
    );
  }

  getVehicleServiceBiWarrantyProducts(fin, firstRegistrationDate, mileage, dealerId): Observable<any> {
    return this.serviceContractApi.getVehicleServiceBiWarrantyProducts(fin, firstRegistrationDate, mileage, dealerId);
  }

  getSsscProducts(vehicleBusinessType: string, fin, firstRegistrationDate): Observable<Array<SsscProduct>> {
    return this.serviceContractApi.getSsscProducts(vehicleBusinessType, fin, firstRegistrationDate);
  }

  getLpoSsscProducts(vehicleBusinessType: string, fin: string): Observable<LpoSsscProductInfo> {
    return this.serviceContractApi.getLpoSsscProducts(vehicleBusinessType, fin);
  }

  deleteSsscQuotations(ids: Array<string>): Observable<any> {
    return this.serviceContractApi.deleteSsscQuotations(ids);
  }

  deleteSsscQuotation(ssscQuotationId: number): Observable<any> {
    return this.serviceContractApi.deleteSsscQuotation(ssscQuotationId);
  }

  activateSsscQuotations(ids: Array<string>): Observable<ActivateSsscResponse> {
    return this.serviceContractApi.activateSsscQuotations(ids);
  }

  validateSsscQuotation(vehicleBusinessType: string, data: SsscRequest[]): Observable<any> {
    return this.serviceContractApi.validateSsscQuotation(vehicleBusinessType, data);
  }

  getSilverQuotationHistory(fin): Observable<QuotationHistory> {
    return this.serviceContractApi.getSilverQuotationHistory(fin);
  }

  getExtendQuotationHistory(fin): Observable<QuotationHistory> {
    return this.serviceContractApi.getExtendQuotationHistory(fin);
  }

  exportQuotationsByCurrentFilter(filter: HistoryOrdersQueryFilter): Observable<any> {
    return this.serviceContractApi.exportQuotationExcel(JSON.stringify(filter));
  }

  exportSsscQuotationsByCurrentFilter(filter: HistoryOrdersQueryFilter): Observable<any> {
    return this.serviceContractApi.exportSsscQuotationExcel(JSON.stringify(filter));
  }

  exportQuotationsForOem(filter: HistoryOrdersQueryFilter): Observable<any> {
    return this.serviceContractApi.exportQuotationsForOem(filter);
  }

  getExportQuotationsTaskStatus(taskId: string): Observable<OemExportDataStatusResponse> {
    return this.serviceContractApi.getExportQuotationsTaskStatus(taskId);
  }

  getServiceContractDocument(name: string): Observable<any> {
    return this.serviceContractApi.getServiceContractDocument(name);
  }

  updateAndCreateServiceContractDocuments(documents: Array<ServiceContractDocumentRequest>): Observable<any> {
    return this.serviceContractApi.updateAndCreateServiceContractDocument(documents);
  }

  getAllServiceContractDocuments(): Observable<any> {
    return this.serviceContractApi.getAllServiceContractDocuments();
  }

  getAllServiceContractProducts(): Observable<Array<ProductResponse>> {
    return this.serviceContractApi.getAllServiceContractProducts();
  }

  getNonBiWarrantyProductInfos(): Observable<any> {
    return this.serviceContractApi.getNonBiWarrantyProductInfos();
  }

  postInactiveBiWarrantyProduct(productCode: string): Observable<any> {
    return this.serviceContractApi.postInactiveBiWarrantyProduct(productCode);
  }
  postAddWarrantyProductInfos(data: any): Observable<any> {
    return this.serviceContractApi.postAddWarrantyProductInfos(data);
  }

  deleteServiceContractDocument(id: number): Observable<any> {
    return this.serviceContractApi.deleteServiceContractDocument(id);
  }

  getAutoActiveProduct(filter: any, pageNumber: number, pageSize: number): Observable<any> {
    return this.serviceContractApi.getAutoActiveProduct(JSON.stringify(filter), pageSize, pageNumber);
  }

  getSsscPackages(filter: any, pageNumber: number, pageSize: number): Observable<any> {
    return this.serviceContractApi.getSsscPackages(JSON.stringify(filter), pageSize, pageNumber);
  }

  getStandardProductInfos(): Observable<any> {
    return this.serviceContractApi.getStandardProductInfos();
  }

  getStandardLpoProductInfos(): Observable<any> {
    return this.serviceContractApi.getStandardLpoProductInfos();
  }

  createSsscPackages(ssscPackage: SsscPackage[]): Observable<SsscPackage> {
    return this.serviceContractApi.createSsscPackages(ssscPackage);
  }

  createAutoActiveProducts(autoActiveProducts: AutoActiveProducts[]): Observable<AutoActiveProducts> {
    return this.serviceContractApi.createAutoActiveProducts(autoActiveProducts[0]);
  }

  updateSsscPackages(packageId: string, data: SsscPackage[]): Observable<any> {
    return this.serviceContractApi.updateSsscPackages(packageId, data);
  }
  updateAutoActiveProducts(id: number, data: AutoActiveProducts[]): Observable<any> {
    return this.serviceContractApi.updateAutoActiveProducts(id, data[0]);
  }

  deleteAutoActiveProduct(id: any): Observable<any> {
    return this.serviceContractApi.deleteAutoActiveProduct(id);
  }

  getStandaloneQuotationStatus(): Observable<any> {
    return this.serviceContractApi.getStandaloneQuotationStatus();
  }

  getStandaloneQuotationStatusForOem(): Observable<any> {
    return this.serviceContractApi.getStandaloneQuotationStatusForOem();
  }

  getSsscQuotationStatus(): Observable<any> {
    return this.serviceContractApi.getSsscQuotationStatus();
  }

  getSsscQuotationStatusForOem(): Observable<any> {
    return this.serviceContractApi.getSsscQuotationStatusForOem();
  }

  getServiceContractProductsByProductName(
    productName: string,
    pageNumber: number,
    pageSize: number,
  ): Observable<ResponsePage<ProductWithDealersResponse>> {
    return this.serviceContractApi.getServiceContractProductsByProductName(encodeURIComponent(productName), pageNumber, pageSize);
  }

  syncProduct(): Observable<any> {
    return this.serviceContractApi.syncProducts();
  }

  exportQuotationsFile(excelBlobId: any): Observable<any> {
    return this.serviceContractApi.exportQuotationsFile(excelBlobId);
  }

  exportSsscQuotationForOem(filterCondition: any) {
    return this.serviceContractApi.exportSsscQuotationForOem(filterCondition);
  }

  updateProductDealerInfo(updateData: UpdateProductInfoWithDealersDto): Observable<any> {
    return this.serviceContractApi.updateProductInfoWithDealers(updateData);
  }

  // tslint:disable-next-line
  validation(data: SsscPackage[]) {
    const errorMessages = [];
    const invalidMessage = '请输入有效值';
    const mapPackageNames = _.map(data, (item) => item.packageName);
    const fieldsMap = {
      baumuster: 'Baumuster',
      packageName: '礼包代码',
      productCode: '产品代码（iCON）',
      packageValidFrom: '生效开始日期',
      packageValidTo: '生效结束日期',
      month: '合同有效期',
      wholesalePrice: '成本价',
      startingMileage: '开始里程',
      endingMileage: '结束里程',
    };

    // tslint:disable-next-line:cyclomatic-complexity
    _.forEach(data, (item, index) => {
      const errorMessage: any = {};
      const { packageName, wholesalePrice, baumuster, packageValidFrom, packageValidTo, startingMileage, endingMileage } = item;
      const startingMileageNumber = Number(startingMileage.toString().replace(/,/g, ''));
      const endingMileageNumber = Number(endingMileage.toString().replace(/,/g, ''));
      _.forIn(item, (value, key) => {
        if (!value && value !== 0) {
          errorMessage[key] = `请完成${fieldsMap[key]}`;
        }
      });

      if (
        packageName &&
        data.length > 1 &&
        _.findIndex(mapPackageNames, (o) => o === packageName) !== _.findLastIndex(mapPackageNames, (o) => o === packageName)
      ) {
        errorMessage.packageName = '礼包代码不可重复';
      }
      if (baumuster && (!isNumericOrEmptyString(_.trim(baumuster)) || _.trim(baumuster).length !== 6)) {
        errorMessage.baumuster = invalidMessage;
      }
      if (wholesalePrice && wholesalePrice >= 1000000) {
        errorMessage.wholesalePrice = invalidMessage;
      }
      if (packageValidFrom === 'INVALID_DATE') {
        errorMessage.packageValidFrom = invalidMessage;
      }
      if (packageValidTo === 'INVALID_DATE') {
        errorMessage.packageValidTo = invalidMessage;
      }
      if (!!packageValidFrom && !!packageValidTo && moment(packageValidFrom).isAfter(moment(packageValidTo))) {
        errorMessage.packageValidFrom = invalidMessage;
        errorMessage.packageValidTo = invalidMessage;
      }
      if (startingMileage && endingMileage && startingMileageNumber >= endingMileageNumber) {
        errorMessage.startingMileage = invalidMessage;
        errorMessage.endingMileage = invalidMessage;
      }
      errorMessages.push(errorMessage);
    });

    return errorMessages;
  }

  validationAutoActiveProduct(data: AutoActiveProducts[]) {
    const errorMessages = [];
    const invalidMessage = '请输入有效值';
    const fieldsMap = {
      baumuster: 'Baumuster',
      productCode: '产品代码',
      productActivateFrom: '生效开始日期',
      productActivateTo: '生效结束日期',
      validMonth: '合同有效期',
    };

    // tslint:disable-next-line:cyclomatic-complexity
    _.forEach(data, (item) => {
      const errorMessage: any = {};
      const { baumuster, productActivateFrom } = item;
      _.forIn(item, (value, key) => {
        if (!value && value !== 0) {
          if (key !== 'productActivateTo' && key !== 'validMonth') {
            errorMessage[key] = `请完成${fieldsMap[key]}`;
          }
        }
      });
      if (baumuster && (!isNumericOrEmptyString(_.trim(baumuster)) || _.trim(baumuster).length !== 6)) {
        errorMessage.baumuster = invalidMessage;
      }
      if (productActivateFrom === 'INVALID_DATE') {
        errorMessage.packageValidFrom = invalidMessage;
      }
      errorMessages.push(errorMessage);
    });

    return errorMessages;
  }

  updateCustomerInfo(id, customerData): Observable<any> {
    return this.serviceContractApi.updateCustomerInfo(id, customerData);
  }

  updateQuotationRetailPrice(id: number, retailPriceInfo): Observable<any> {
    return this.serviceContractApi.updateQuotationRetailPrice(id, retailPriceInfo);
  }

  updateQuotationSalesComment(id: number, salesCommentInfo): Observable<any> {
    return this.serviceContractApi.updateQuotationSalesComment(id, salesCommentInfo);
  }

  getStandaloneQuotationSalesChannels(): Observable<any> {
    return this.serviceContractApi.getStandaloneQuotationSalesChannels();
  }

  getServiceContractPriceConfigFile(): Observable<any> {
    if (this.featureToggleService.isFeatureEnable(FeatureToggle.SERVICE_CONTRACT_ENHANCEMENT)) {
      return this.serviceContractApi.getServiceContractRetailPriceConfigFile();
    }
    return this.serviceContractApi.getServiceContractPriceConfigFile();
  }

  getExtendServiceContractPriceConfigFile(): Observable<any> {
    return this.serviceContractApi.getExtendServiceContractPriceConfigFile();
  }

  getLatestUploadServiceContractPriceFile(): Observable<HttpResponse<any>> {
    return this.serviceContractApi.getLatestUploadServiceContractPriceFile();
  }

  getLpoQrcode(carInfo: BasicCarInfo): Observable<any> {
    return this.serviceContractApi.getLpoQrcodeWithCarInfo(carInfo);
  }

  getLpoSalesRecords(pageNumber: number, pageSize: number, filter: SalesRecordQueryFilter): Observable<LpoSalesRecordResponse> {
    return this.serviceContractApi.getLpoSalesRecords(filter, pageSize, pageNumber);
  }

  getRolesByPrivilegeCode(privilegeCode: string): Observable<Array<Role>> {
    return this.serviceContractApi.getRolesByPrivilegeCode(privilegeCode);
  }

  exportCouponSalesHistoryByCurrentFilter(salesRecordQueryDTO: SalesRecordQueryFilter): Observable<any> {
    return this.serviceContractApi.exportCouponSalesHistoryExcel(salesRecordQueryDTO);
  }

  createLpoOrderLink(lpoOrderLinkRequest: LpoOrderLinkRequest): Observable<any> {
    return this.serviceContractApi.createLpoOrderLink(lpoOrderLinkRequest);
  }

  getLpoOrderLink(lpoOrderNumber: string): Observable<any> {
    return this.serviceContractApi.getLpoOrderLink(lpoOrderNumber);
  }

  saveLocalQuotationInfo(data: LocalQuotation): Observable<any> {
    return this.serviceContractApi.saveLocalQuotationInfo(data);
  }

  fetchLocalQuotationInfo(otrQuotationNumber: string): Observable<LocalQuotationWithCoupon> {
    return this.serviceContractApi.fetchLocalQuotationInfo(otrQuotationNumber);
  }

  createLpoOrder(otrQuotationNumber: string): Observable<any> {
    return this.serviceContractApi.createLpoOrder(otrQuotationNumber);
  }

  activeLpoOrder(otrQuotationNumber: string): Observable<any> {
    return this.serviceContractApi.activeLpoOrder(otrQuotationNumber);
  }

  cancelLpoOrder(otrQuotationNumber: string): Observable<any> {
    return this.serviceContractApi.cancelLpoOrder(otrQuotationNumber);
  }

  cancelOnlineOrder(lpoOrderNumber: string, isExtend?: boolean): Observable<any> {
    return this.serviceContractApi.cancelOnlineOrder(lpoOrderNumber, isExtend);
  }

  updateLocalQuotationCustomerInfo(otrQuotationNumber: string, customerData: object): Observable<any> {
    return this.serviceContractApi.updateLocalQuotationCustomerInfo(otrQuotationNumber, customerData);
  }

  updatePriceTaxFreeAndFinancialChannel(otrQuotationNumber: string, priceTaxFreeAndFinancialChannelInfo: object): Observable<any> {
    return this.serviceContractApi.updatePriceTaxFreeAndFinancialChannel(otrQuotationNumber, priceTaxFreeAndFinancialChannelInfo);
  }

  updateLocalQuotationSalesComment(otrQuotationNumber: string, salesCommentInfo: object): Observable<any> {
    return this.serviceContractApi.updateLocalQuotationSalesComment(otrQuotationNumber, salesCommentInfo);
  }

  getRefundEWQuotation(fin: string): Observable<any> {
    return this.serviceContractApi.retrieveVanQuotationByFin(fin);
  }

  getQuotationByExternalId(fin: string, externalId: string) {
    return this.serviceContractApi.retrieveVanQuotationsByExternalId(fin, externalId);
  }

  downloadCustomizePackageContractTemplate(filename: string): Observable<any> {
    return this.serviceContractApi.downloadCustomizePackageContractTemplate(filename);
  }

  createCustomizedGiftPackage(createRefundPackageRequest: FormData) {
    return this.serviceContractApi.createCustomizedGiftPackage(createRefundPackageRequest);
  }

  searchCustomizedGiftPackage(page, size, filter) {
    return this.serviceContractApi.searchCustomizedGiftPackage(page, size, encodeURI(JSON.stringify(filter)));
  }

  downloadCustomizePackageContract(packageContractFileId: string, packageContractFileName: string) {
    return this.serviceContractApi.downloadCustomizePackageContract(packageContractFileId, packageContractFileName);
  }

  updateCustomizedGiftPackage(packageNumber, createRefundPackageRequest: FormData) {
    return this.serviceContractApi.updateCustomizedGiftPackage(packageNumber, createRefundPackageRequest);
  }

  getOnlineOrderLink(orderNumber: string): Observable<any> {
    return this.serviceContractApi.getOnlineOrderLink(orderNumber);
  }

  getUsedCarRelatedDate(usedCarRelatedDateRequest: UsedCarRelatedDateRequest): Observable<UsedCarRelatedDate> {
    return this.serviceContractApi.getUsedCarRelatedDate(
      usedCarRelatedDateRequest.categoryId,
      usedCarRelatedDateRequest.productId,
      usedCarRelatedDateRequest.usedCarInvoiceDate,
      usedCarRelatedDateRequest.firstRegistrationDate,
      usedCarRelatedDateRequest.fin);
  }

  uploadFileApiUrl: string = '/api/website/service-contract/local-quotations/used-car/certificate-file';

  removeUploadFile(fileName: string): Observable<any> {
    return this.serviceContractApi.removeUploadFile(fileName);
  }

  previewUploadFile(fileName: string): Observable<any> {
    return this.serviceContractApi.previewUploadFile(fileName);
  }

  uploadUsedCarCertificateFile(file: File, fileName: string): Observable<any> {
    return new Observable<any>(observer => {
      FileAPI.upload({
        url: this.uploadFileApiUrl,
        headers: {
          Authorization: `Bearer ${this.tokenService.accessToken}`,
          'Vehicle-Business-Type': this.tokenService.vehicleBusinessType,
        },
        files: {
          file,
        },
        data: {
          fileName
        },
        complete(err, xhr) {
          if (err) {
            observer.error(xhr);
          } else {
            observer.next(xhr.response);
          }
          observer.complete();
        },
      });
    });
  }
}

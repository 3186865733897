import * as _ from 'lodash';
import * as moment from 'moment';
import { PromptLevel } from 'app/_common/constants/common';
import { Campaign } from '../_common/models/Campaign';
import {
  CAMPAIGN_FILE_STATUS,
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_MAPPING,
  CUSTOMER_ACQUISITION,
  EVENT_RETAIL_RECORD_TYPE,
  RETAIL_CAMPAIGN_FILE_INFOS,
  HIDE_ACTUAL_ORDERS_STATUS,
  RECORD_TYPE_MAPPING,
  FileInfo,
  PROCESS_STATUS,
} from '../_common/utils/campaign';
import { DATE_TIME_SECOND_FORMAT } from '../_common/constants/service-contract';

export const getDefaultYearTarget = (options) => {
  const currentYear = new Date().getFullYear().toString();
  const option = _.find(options, (it) => it.year === currentYear);
  return option ? option : _.get(options, '[0]', null);
};

const QUARTER_MAP = {
  Q1: '第一季度车书订购',
  Q2: '第二季度车书订购',
  Q3: '第三季度车书订购',
  Q4: '第四季度车书订购',
};

export const formatQuarterMessage = (currQuarter) => {
  const startData = moment(currQuarter.startDate).format('YYYY-MM-DD');
  const endData = moment(currQuarter.endDate).format('YYYY-MM-DD');
  return `${QUARTER_MAP[currQuarter.name]}: ${startData} ～ ${endData}`;
};

export const getPromptConfigByYearTarget = (yearlyTarget) => {
  const currQuarter = _.get(yearlyTarget, 'brochureOrderingQuarter.current');

  if (currQuarter) {
    const promptMessage = formatQuarterMessage(currQuarter);
    return { content: promptMessage, level: PromptLevel.Info, isPreserved: true };
  }
  return {};
};

export function getActualCampaignStatus(campaign: Campaign) {
  if (campaign.status !== CAMPAIGN_STATUS.FEEDBACK_PLANNED.value) {
    return campaign.status;
  }

  return campaign.actualStartDate ? CAMPAIGN_STATUS.FEEDBACK_PLANNED.value : CAMPAIGN_STATUS.APPLY_APPROVED.value;
}

export function mapCampaignAppendSalesBrandAndSeriesDescription(
  campaign: {
    brand: string;
    firstPromotionSeries: string;
    secondPromotionSeries: string;
    thirdPromotionSeries: string;
  },
  campaignConfig: {
    salesBrand: Array<any>;
  },
) {
  const brand = getConfigByFirstType(campaignConfig, campaign, 'brand', 'salesBrand');
  return {
    ...campaign,
    brandDescription: brand && brand.description,
    firstPromotionSeriesDescription: getPromotionSeriesDescription(brand, campaign.firstPromotionSeries),
    secondPromotionSeriesDescription: getPromotionSeriesDescription(brand, campaign.secondPromotionSeries),
    thirdPromotionSeriesDescription: getPromotionSeriesDescription(brand, campaign.thirdPromotionSeries),
  };
}
export const getNameByType = (config, campaign, type, configFieldName = '') => {
  const matched = getConfigByFirstType(config, campaign, type, configFieldName);
  return matched ? matched.description : '';
};

export const getBrandDescriptionByBrandName = (brandName, campaignConfigs) => {
  const toolkitBrand = _.find(_.get(campaignConfigs, 'toolkitBrand', []), (_toolkitBrand) => _toolkitBrand.name === brandName);
  return _.get(toolkitBrand, 'description', brandName);
};

export const getConfigByFirstType = (config, campaign, type, configFieldName = '') => {
  const wrappedConfigFieldName = configFieldName || type;
  const typeConfigs = config[wrappedConfigFieldName];

  if (typeConfigs) {
    return typeConfigs.find((tc) => tc.name === campaign[type]);
  }
};
export const getPromotionSeriesDescription = (currentBrand, promotionSeriesName): string => {
  const brandSeries = _.get(currentBrand, 'children', []);
  const foundSeriesByName = _.find(brandSeries, { name: promotionSeriesName });
  return _.get(foundSeriesByName, 'description', '');
};

export const convertPromotionSeries = (campaign) => {
  const { firstPromotionSeries, secondPromotionSeries, thirdPromotionSeries } = campaign;
  return [firstPromotionSeries, secondPromotionSeries, thirdPromotionSeries]
    .filter((series) => !_.isUndefined(series) && series !== '' && series !== 'G-Class')
    .join(' / ');
};

export const convertRecordType = (campaign) => {
  return RECORD_TYPE_MAPPING[campaign.recordType];
};

export const convertCampaignStatus = (campaign) => {
  return CAMPAIGN_STATUS_MAPPING[campaign.status] || '';
};

const EVENT_STATUS = {
  Published: '已发布',
  Closed: '已关闭',
};

export const convertEventStatus = (campaign) => {
  return EVENT_STATUS[campaign.status];
};

export const isEventRecordType = (campaign) => {
  return campaign.recordType === EVENT_RETAIL_RECORD_TYPE;
};

export const isNotBrochureOrderCampaign = (campaign) => {
  return !_.get(campaign, 'brochureOrderCampaign');
};

export const inFeedbackStage = (campaign) => {
  return _.includes(campaign.status, 'FEEDBACK');
};

export const getOptionsFromConfig = (config, key) => {
  return _.get(config, key, []).map(({ name, description, active }) =>
    ({ value: name, text: description, active })).filter(({active}) => Boolean(active));
};

export const getChildDescByName = (config, childName) => {
  if (!config) {
    return '';
  }
  const matched: any = _.find(_.get(config, 'children', []), { name: childName });
  return matched ? matched.description : '';
};

export const isLeadConversion = (campaign: Campaign) => _.get(campaign, 'customerAcquisitionType') === CUSTOMER_ACQUISITION.LEAD_CONVERSION;

export const isLeadAcquisition = (campaign: Campaign) =>
  _.get(campaign, 'customerAcquisitionType') === CUSTOMER_ACQUISITION.LEAD_ACQUISITION;

export const hasLeadAcquisitionType = (campaign: Campaign) => isLeadAcquisition(campaign) || isLeadConversion(campaign);

export const isActualAttendance = (file) => file.fileType === RETAIL_CAMPAIGN_FILE_INFOS.LEAD_ATTENDANCE.value;

export const getMembersUploadableFile = (campaign: Campaign) => {
  return null;
};

export const getActivityDescription = (campaign, eventTypeConfig) =>
  JSON.stringify({
    ..._.pick(campaign, [
      'id',
      'brand',
      'firstPromotionSeries',
      'secondPromotionSeries',
      'thirdPromotionSeries',
      'name',
      'planStartTime',
      'planEndTime',
      'customerAcquisitionType',
      'campaignCode',
    ]),
    eventType: getNameByType(eventTypeConfig, campaign, 'eventType', 'salesEventType'),
  });
export const generateExcelFileName = (campaignType, timeFormat = DATE_TIME_SECOND_FORMAT): string =>
  `${campaignType}_${moment().format(timeFormat)}.xlsx`;
export const shouldShowActualOrders = (campaign) =>
  !campaign.customerAcquisitionType || !_.includes(HIDE_ACTUAL_ORDERS_STATUS, campaign.status);

export const getBrandDescription = (campaign) => campaign.brandDescription || campaign.brand;

export const canUploadFile = (status) =>
  _.includes([CAMPAIGN_FILE_STATUS.UPLOAD_AVAILABLE.value, CAMPAIGN_FILE_STATUS.MODIFY_AVAILABLE.value], status);

export const canUploadFiles = (campaign) =>
  _.includes(
    [CAMPAIGN_STATUS.APPLY_APPROVED.value, CAMPAIGN_STATUS.FEEDBACK_PLANNED.value, CAMPAIGN_STATUS.FEEDBACK_NEED_MODIFY.value],
    campaign.status,
  );

export const canUploadLeadInvitation = (campaign) => canUploadFiles(campaign) && uploadedAttendanceTimes(campaign) <= 0;

const getCampaignHistoriesInType = (campaign, type) =>
  _.filter(_.get(campaign, 'campaignUploadHistories', []), (history) => history.fileCode === type);

export const uploadedAcquisitionTimes = (campaign) =>
  _.size(
    _.filter(
      _.get(campaign, 'campaignUploadHistories', []),
      (history) => history.fileCode === RETAIL_CAMPAIGN_FILE_INFOS.LEAD_ACQUISITION.value,
    ),
  );

export const uploadedInvitationTimes = (campaign) =>
  _.size(getCampaignHistoriesInType(campaign, RETAIL_CAMPAIGN_FILE_INFOS.LEAD_INVITATION.value));

export const uploadedAttendanceTimes = (campaign) =>
  _.size(getCampaignHistoriesInType(campaign, RETAIL_CAMPAIGN_FILE_INFOS.LEAD_ATTENDANCE.value));

export const transferUploadTimes = (count) =>
  count === 0
    ? CAMPAIGN_FILE_STATUS.UPLOAD_AVAILABLE.value
    : count >= 1
    ? CAMPAIGN_FILE_STATUS.MODIFY_AVAILABLE.value
    : CAMPAIGN_FILE_STATUS.NOT_AVAILABLE.value;

export const getLeadAcquisitionStatus = (campaign) => {
  if (!canUploadFiles(campaign)) {
    return CAMPAIGN_FILE_STATUS.NOT_AVAILABLE.value;
  }
  return transferUploadTimes(uploadedAcquisitionTimes(campaign));
};

export const getLeadInvitationStatus = (campaign) => {
  if (!canUploadLeadInvitation(campaign)) {
    return CAMPAIGN_FILE_STATUS.NOT_AVAILABLE.value;
  }
  return transferUploadTimes(uploadedInvitationTimes(campaign));
};

export const getLeadAttendanceStatus = (campaign) => {
  if (!canUploadFiles(campaign)) {
    return CAMPAIGN_FILE_STATUS.NOT_AVAILABLE.value;
  }
  return transferUploadTimes(uploadedAttendanceTimes(campaign));
};

export const getFileRequestId = (campaign, info) => {
  const histories = _.filter(
    getCampaignHistoriesInType(campaign, info.value),
    (history) => history.status === PROCESS_STATUS.InProgress.value,
  );
  if (_.isEmpty(histories)) {
    return null;
  }
  return _.chain(histories).orderBy(['createdTime'], ['desc']).head().get('requestId').value();
};

export const getRetailCampaignFileInfos = (campaign) => {
  const infos = RETAIL_CAMPAIGN_FILE_INFOS.values;
  return _.chain(infos)
    .map((info) => ({
      ...info,
      status: info.status(campaign),
      isVisible: info.isVisible(campaign),
      latestCreatedTime: info.latestCreatedTime(campaign),
      canDownloadTemplate: info.canDownloadTemplate(campaign),
      requestId: getFileRequestId(campaign, info),
    }))
    .filter('isVisible')
    .value();
};

export const canShowLeadAttendance = (campaign) => isLeadConversion(campaign) && uploadedInvitationTimes(campaign) >= 1;

export const getFileLatestCreatedTime = (campaign, type) =>
  _.chain(getCampaignHistoriesInType(campaign, type)).orderBy(['createdTime'], ['desc']).head().get('createdTime').value();

export const canDownloadInvitationTemplate = (campaign) => uploadedInvitationTimes(campaign) < 1 && canUploadFiles(campaign);

export const canDownloadAcquisitionTemplate = (campaign) => uploadedAcquisitionTimes(campaign) < 1;

export const canDownloadAttendanceTemplate = (campaign) => uploadedInvitationTimes(campaign) >= 1 && canUploadFiles(campaign);

export const setFileStatus = (availableFiles: Array<FileInfo>, value, status) => {
  _.set(availableFiles, `[${_.findIndex(availableFiles, ['value', value])}].status`, status);
};

export const setFileRequestId = (availableFiles: Array<FileInfo>, selectedFile, requestId) => {
  _.set(availableFiles, `[${_.findIndex(availableFiles, ['value', selectedFile.value])}].requestId`, requestId);
  _.set(selectedFile, `requestId`, requestId);
};

export const parseCampaignRequestId = (res) => _.get(JSON.parse(res.response), 'campaignRequestId', '');

export const getCampaignLatestInvitationRequestId = (campaign) => {
  const histories = _.filter(
    getCampaignHistoriesInType(campaign, RETAIL_CAMPAIGN_FILE_INFOS.LEAD_INVITATION.value),
    (history) => history.status === PROCESS_STATUS.Success.value,
  );
  if (_.isEmpty(histories)) {
    return null;
  }
  return _.chain(histories).orderBy(['createdTime'], ['desc']).head().get('requestId').value();
};

export const isFirstTimeUploadAttendanceFile = (campaign, selectedFile) =>
  selectedFile.value === RETAIL_CAMPAIGN_FILE_INFOS.LEAD_ATTENDANCE.value && uploadedAttendanceTimes(campaign) === 0;

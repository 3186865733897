import { Injectable } from '@angular/core';
import { Body, GET, RebirthHttp, POST } from 'rebirth-http';
import { Observable } from 'rxjs';
import { SalesIncentiveProgram } from '../models/sales-incentive-programs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SalesIncentiveProgramsApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-incentive-programs')
  getPrograms(): Observable<SalesIncentiveProgram[]> {
    return null;
  }

  @POST('/api/sales-incentive-programs/freebies')
  updatePrograms(@Body data): Observable<SalesIncentiveProgram[]> {
    return null;
  }
}

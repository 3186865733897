import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { DealerInfoManagementApi } from '../api/dealer-info-management';
import { DealerInfo } from '../models/dealerInfo';
import { DealerWithDealerGroup } from '../models/dealer';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';

@Injectable()
export class DealerInfoManagementService {
  dealersInfo: DealerWithDealerGroup[] = null;

  constructor(private dealerInfoManagementApi: DealerInfoManagementApi) {

  }

  getDealerInfo(): Observable<DealerInfo> {
    return this.dealerInfoManagementApi.getDealerInfo();
  }

  updateDealerInfo(data: DealerInfo) {
    return this.dealerInfoManagementApi.updateDealerInfo(data);
  }

  updateOrderPrintPdf(dealerId: string): Observable<any> {
    return combineLatest(
      this.dealerInfoManagementApi.updateOrderPrintPdf(dealerId, true),
      this.dealerInfoManagementApi.updateOrderPrintPdf(dealerId, false),
    );
  }

  getDealersWithDealerGroup(): Observable<DealerWithDealerGroup[]> {
    if (!_.isEmpty(this.dealersInfo)) {
      return observableOf(this.dealersInfo);
    }
    return this.dealerInfoManagementApi.getDealersWithDealerGroup()
      .pipe(tap((data: any) => {
        this.dealersInfo = data;
      }));
  }

  getDealerGroup(): Observable<DealerWithDealerGroup[]> {
    if (!_.isEmpty(this.dealersInfo)) {
      return observableOf(this.dealersInfo);
    }
    return this.dealerInfoManagementApi.getDealerGroup()
      .pipe(tap((data: any) => {
        this.dealersInfo = data;
      }));
  }

  getAllDealersMapping(): Observable<DealerWithDealerGroup[]> {
    return this.dealerInfoManagementApi.getAllDealersMapping();
  }
}

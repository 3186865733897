export interface GridValueValidator {
  message: string;

  validate(value: any): boolean;
}

export interface GridRenderValidator {
  originValue: any;
  value: any;
  errorMessage: string;

  getValidators(): Array<GridValueValidator>;
  hasError(errorMessage): void;
}

// tslint:disable-next-line
export function Validate() {
  return (target, property: string, descriptor: PropertyDescriptor) => {
    const originFunction = descriptor.value;

    descriptor.value = function (...args) {
      const [value] = args;
      const self = this as GridRenderValidator;

      const failedValidator = self.getValidators().find(validator => !validator.validate(value));
      self.errorMessage = failedValidator ? failedValidator.message : null;
      self.hasError(!!self.errorMessage);
      if (!failedValidator && value !== self.originValue) {
        originFunction.apply(self, args);
      }
    };
  };
}

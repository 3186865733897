import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

import { ORDER_STATUS, INVOICED_STATUSES } from '../../vehicle/list/fields-config';

@Pipe({ name: 'orderStatus' })
export class OrderStatusPipe implements PipeTransform {
  transform(order: any): any {
    if (order.status === ORDER_STATUS.CANCELED.value) {
      return ORDER_STATUS.CANCELED.text;
    }

    if (_.isNumber(order.actual_delivery_date) || !_.isEmpty(order.actual_delivery_date)) {
      if (_.includes(INVOICED_STATUSES, order.status) &&
        new Date(order.retail_invoice_date) > new Date(order.actual_delivery_date)) {
        return ORDER_STATUS.INVOICED.text;
      }
      return ORDER_STATUS.DELIVERED.text;
    }

    return _.find<any>(_.values(ORDER_STATUS), { value: order.status }).text;
  }
}

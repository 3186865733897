import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '@otr/website-common';

@Injectable()
export class PasswordResetService {
  form: FormGroup;
  private smsToken: string;
  private errorMessageTerm: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private isInVerificationTerm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  errorMessage: Observable<string> = this.errorMessageTerm.asObservable();
  isInVerification: Observable<boolean> = this.isInVerificationTerm.asObservable();
  private setErrorMessage: any = errorMessage => this.errorMessageTerm.next(errorMessage);

  constructor(private auth: AuthService, formBuilder: FormBuilder) {
    this.form = formBuilder.group({
      username: ['', Validators.required],
      new_password: ['', Validators.required],
      client_type: ['web', Validators.required],
      phone_number: ['', Validators.required],
      verification_code: [{ value: '', disabled: true }, Validators.required],
    });
  }

  reset() {
    this.form.setValue({
      username: '',
      new_password: '',
      client_type: 'web',
      phone_number: '',
      verification_code: '',
    });
    this.errorMessageTerm.next(null);
    this.isInVerificationTerm.next(false);
    this.form.controls['verification_code'].disable();
  }

  validate() {
    this.errorMessageTerm.next(null);
    // todo move password reset from auth service to password reset service
    return this.auth
      .validatePasswordReset(this.form.value).pipe(
      tap(res => {
        this.smsToken = res.sms_token;
        this.isInVerificationTerm.next(true);
        this.form.controls['verification_code'].enable();
      }),
        catchError(this.setErrorMessage));
  }

  sendVerificationCode() {
    const data = {
      client_type: 'web',
      phone_number: this.form.value.phone_number,
      sms_token: this.smsToken,
    };
    this.errorMessageTerm.next(null);
    return this.auth
      .verifyPasswordReset(data).pipe(
      tap(res => this.smsToken = res.sms_token),
        catchError(this.setErrorMessage));
  }

  confirm() {
    this.errorMessageTerm.next(null);
    return this.auth
      .passwordReset(this.form.value).pipe(
      catchError(this.setErrorMessage));
  }
}

<div class="select-calendar-range-filter-box">
  <div class="select-calendar-range-filter-group">
    <div class="padding-bottom-0 flex-container">
      <input
        value="NORMAL"
        type="radio"
        class="radio-button"
        name="search-or-calendar-range"
        [formControl]="radio"
      />
      <input
        type="number"
        class="search-input search-form margin-bottom-0 padding-left-10"
        [formControl]="searchInput"
        placeholder="搜索次数"
        maxlength="255"
        (focus)="changeRatio('NORMAL')"
      />
    </div>
    <div class="padding-bottom-0 flex-container">
      <input
        value="CALENDAR_RANGE"
        type="radio"
        class="radio-button"
        name="search-or-calendar-range"
        [formControl]="radio"
      />
      <span class="calendar-range margin-bottom-0">
        <span>
          <p-calendar
            [inputStyle]="{width: '118px', height: '24px'}"
            [formControl]="startDateControl"
            [locale]="chineseCalendarConfig"
            dataType="string"
            dateFormat="yy-mm-dd"
            placeholder="跟进日期"
            (onBlur)="onCalendarBlur(startDateControl)"
            [maxDate]="endDateControl.value | dateObject"
            (onMonthChange)="stopPropagation()"
            (onFocus)="changeRatio('CALENDAR_RANGE')"
          >
          </p-calendar>
        </span>
        <span class="range-line calendar-line"></span>
        <span>
          <p-calendar
            [inputStyle]="{width: '118px', height: '24px'}"
            [formControl]="endDateControl"
            [locale]="chineseCalendarConfig"
            dataType="string"
            dateFormat="yy-mm-dd"
            placeholder="跟进日期"
            (onBlur)="onCalendarBlur(endDateControl)"
            [minDate]="startDateControl.value | dateObject"
            (onMonthChange)="stopPropagation()"
            (onFocus)="changeRatio('CALENDAR_RANGE')"
          >
          </p-calendar>
        </span>
      </span>
    </div>
  </div>
  <span class="btn-cancel" (click)="onCancel()">
    取消
  </span>
  <button class="btn-save select-btn" type="submit" (click)="onSubmit()">
    确定
  </button>
</div>

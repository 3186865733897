import * as _ from 'lodash';
import {
  AS_LEAD_SOURCE_TYPE,
  AS_LEAD_TYPE_ENUM,
  AsLeadTopic,
  LEAD_STATUS_ENUM,
  RESERVATION_STYLE,
  RESERVATION_SUCCESS_STATUS,
} from '../constant';
import { getEmergencyNames, getDigitalServiceName, getRcCampaign } from './detail/table-config.utils';
import { AsLifecycle, AsLifeCycleOffer, LeadInOffer } from '../model';
import * as moment from 'moment';
import { dateParse } from '../../../_common/utils/date-parse';
import { ENTERING_STATUS_OPTIONS, FIELD_OPTIONS } from '../life-cycle/constant';
import { OPTION_PIPE } from '../../../case/case-detail/case-settlement-list/settlement-table';
import { SERVICE_TYPE } from '../../../service-order/constant';
import { findGet } from '../../../_common/utils/common';

const EMERGENCY_LEAD_TYPE = [AS_LEAD_TYPE_ENUM.EMERGENCY.value, AS_LEAD_TYPE_ENUM.TG.value];
export const shouldShowLeadsTag = leadType => _.includes([AS_LEAD_TYPE_ENUM.NORMAL.value,
  AS_LEAD_TYPE_ENUM.FIX_INTERVAL.value, AS_LEAD_TYPE_ENUM.TYRE_PREDICTION.value], leadType);
export const isOrderedLead = lead => _.isEqual(LEAD_STATUS_ENUM.ORDERED.value, lead.leadStatus);
export const isNotEmergencyOfferLeadType = leadType => !_.includes(EMERGENCY_LEAD_TYPE, leadType);
export const isNotPweiOfferLeadType = leadType => !_.isEqual(leadType, AS_LEAD_TYPE_ENUM.PWEI.value);
export const isNotDigitalServiceOfferLeadType = leadType => !_.isEqual(leadType, AS_LEAD_TYPE_ENUM.DIGITAL_SERVICE.value);
export const isNotRecallOfferLeadType = leadType => !_.includes(RECALL_OFFER_TYPE, leadType);
export const isNotRecallOfferWithLostLeadType = leadType => !_.includes(RECALL_OFFER_WITH_LOST_TYPE, leadType);
export const isNotTyreOfferLeadType = leadType => !_.includes(TYRE_OFFER_TYPE, leadType);
export const isNotOemSpecialLeadType = leadType => !_.includes(AS_LEAD_TYPE_ENUM.OEM_SPECIAL.value, leadType);
export const isNotSanLeadType = leadType => !_.includes(AS_LEAD_TYPE_ENUM.SAN.value, leadType);
export const isNormalOfferLeadType = leadType => _.isEqual(leadType, AS_LEAD_TYPE_ENUM.NORMAL.value);
export const isNotServiceType = leadType =>
  _.isEqual(leadType, AS_LEAD_TYPE_ENUM.OEM_SPECIAL.value) || _.isEqual(leadType, AS_LEAD_TYPE_ENUM.SAN.value);
export const isLostSaleType = leadType => _.isEqual(leadType, AS_LEAD_TYPE_ENUM.LOST_SALE.value);
export const shouldShowTyreConfig = leadType => _.includes(
  [AS_LEAD_TYPE_ENUM.TYRE_PREDICTION.value, AS_LEAD_TYPE_ENUM.TYRE_LOST_SALES.value], leadType);

export const isNotOrderedAndManualLead = lead =>
 ! _.isMatch(lead, { leadType: AS_LEAD_TYPE_ENUM.MANUAL.value, leadStatus: LEAD_STATUS_ENUM.PENDING.value });

const RECALL_OFFER_TYPE = [AS_LEAD_TYPE_ENUM.RECALL.value, AS_LEAD_TYPE_ENUM.CAMPAIGN.value, AS_LEAD_TYPE_ENUM.SERVICE_MEASURE.value];

const RECALL_OFFER_WITH_LOST_TYPE = [AS_LEAD_TYPE_ENUM.RECALL.value, AS_LEAD_TYPE_ENUM.CAMPAIGN.value,
  AS_LEAD_TYPE_ENUM.SERVICE_MEASURE.value, AS_LEAD_TYPE_ENUM.LOST_SALE.value];

const TYRE_OFFER_TYPE = [RECALL_OFFER_TYPE, AS_LEAD_TYPE_ENUM.TYRE.value, AS_LEAD_TYPE_ENUM.NORMAL.value,
  AS_LEAD_TYPE_ENUM.FIX_INTERVAL.value, AS_LEAD_TYPE_ENUM.TYRE_PREDICTION.value, AS_LEAD_TYPE_ENUM.TYRE_LOST_SALES.value,
  AS_LEAD_TYPE_ENUM.OEM_SPECIAL.value, AS_LEAD_TYPE_ENUM.LOST_SALE.value, AS_LEAD_TYPE_ENUM.SAN.value];

const LABEL_TYPE = [AS_LEAD_TYPE_ENUM.NORMAL.value, AS_LEAD_TYPE_ENUM.TG.value];

export const canShowNotDMPLabel = lead => _.includes(LABEL_TYPE, lead.leadType)
  && !_.isMatch(lead, { sourceType: AS_LEAD_SOURCE_TYPE.DMP });

export const showWarningLable = warn => true;

const SHOW_EMERGENCY_NAME_TYPE = [AS_LEAD_TYPE_ENUM.TCC.value, AS_LEAD_TYPE_ENUM.RECALL.value, AS_LEAD_TYPE_ENUM.MANUAL.value,
  AS_LEAD_TYPE_ENUM.PWEI.value, AS_LEAD_TYPE_ENUM.CAMPAIGN.value, AS_LEAD_TYPE_ENUM.SERVICE_MEASURE.value];

export const notShowEmergencyMessages = ({ leadType }) => _.includes(SHOW_EMERGENCY_NAME_TYPE, leadType);

export const getLeadStrategiesTitle = (lead: LeadInOffer) => {
  const leadType = lead.leadType;
  const title = _.get(_.find(AS_LEAD_TYPE_ENUM, { value: leadType }), 'text');
  if (_.includes([AS_LEAD_TYPE_ENUM.EMERGENCY.value, AS_LEAD_TYPE_ENUM.TG.value], leadType)) {
    return _.join(getEmergencyNames({ data: lead }), ',');
  }
  if (_.isEqual(AS_LEAD_TYPE_ENUM.DIGITAL_SERVICE.value, leadType)) {
    return getDigitalServiceName({ data: lead });
  }
  if (_.includes([AS_LEAD_TYPE_ENUM.CAMPAIGN.value, AS_LEAD_TYPE_ENUM.RECALL.value, AS_LEAD_TYPE_ENUM.SERVICE_MEASURE.value], leadType)) {
    return `${title}${getRcCampaign({ data: lead })}`;
  }

  return title;
};

export const getLeadCloseReasonTitle = (lead: LeadInOffer) => {
  const leadType = lead.leadType;
  const title = _.get(_.find(AS_LEAD_TYPE_ENUM, { value: leadType }), 'text');
  if (_.includes([AS_LEAD_TYPE_ENUM.EMERGENCY.value, AS_LEAD_TYPE_ENUM.TG.value], leadType)) {
    return _.join(getEmergencyNames({ data: lead }), ',');
  }
  if (_.isEqual(AS_LEAD_TYPE_ENUM.DIGITAL_SERVICE.value, leadType)) {
    return getDigitalServiceName({ data: lead });
  }
  if (_.includes([AS_LEAD_TYPE_ENUM.CAMPAIGN.value, AS_LEAD_TYPE_ENUM.RECALL.value, AS_LEAD_TYPE_ENUM.SERVICE_MEASURE.value], leadType)) {
    return `${title} ${getRcCampaign({ data: lead })}`;
  }
  return title;
};

export const getCampaignLeads = leads => _.filter(leads, lead => lead.leadType === AS_LEAD_TYPE_ENUM.CAMPAIGN.value);

export const combineReservations = (reservedLeadIds, selectedLeads) => {
  const leadId = _.concat(reservedLeadIds, _.map(selectedLeads, 'leadId'));
  const businessId = fillNullValue(reservedLeadIds, selectedLeads, 'businessId');
  const sendTime = fillNullValue(reservedLeadIds, selectedLeads, 'sendTime');
  const channel = getChannel(selectedLeads);
  return { leadId, businessId, sendTime, channel };
};

export const getValueWithDefault = (selectedLeads, path) => _.map(selectedLeads, lead => _.get(lead, path, 'NA'));

export const fillNullValue = (reservedLeadIds, selectedLeads, path) => _.concat(_.fill(Array(_.size(reservedLeadIds)), 'NA'),
  getValueWithDefault(selectedLeads, path));

export const getChannel = selectedLeads => _.chain(selectedLeads).map('channel').compact().head().value();

export const combineFollowups = (leads, nextFollowUpReason, followUpComments, nextFollowUpDate, nextFollowUpStrategyIds, d8Account) => {
  return _.map(leads, lead => {
    const strategyId = _.get(lead, 'nextFollowUpStrategy.strategyId');
    const leadId = lead.leadId;
    const nextFollowUpStrategyId = findGet(nextFollowUpStrategyIds, { leadId }, 'nextFollowUpStrategyId');
    return {
      ...leadToSentStatus(lead), followUpComments, nextFollowUpReason,
      nextFollowUpDate, nextFollowUpStrategyId, d8Account, strategyId };
  });
};

export const combineClosedLeads = leads => {
  const leadId = _.map(leads, 'leadId');
  const businessId = getValueWithDefault(leads, 'businessId');
  const sendTime = getValueWithDefault(leads, 'sendTime');
  const channel = getChannel(leads);
  return { leadId, businessId, channel, sendTime };
};

export const leadToSentStatus = ({ leadId, channel, success, failedResult, businessId, sendTime }) =>
  ({ leadId: [leadId], channel, success, failedResult, businessId, sendTime });

export const isLeadAvailableForEmailFollowUp = ({ leadType }) =>
  !_.includes([
    AS_LEAD_TYPE_ENUM.COMMON.value,
    AS_LEAD_TYPE_ENUM.MANUAL.value,
    AS_LEAD_TYPE_ENUM.PWEI.value,
    AS_LEAD_TYPE_ENUM.SERVICE_MEASURE.value,
    AS_LEAD_TYPE_ENUM.CAMPAIGN.value,
    AS_LEAD_TYPE_ENUM.RECALL.value,
  ], leadType);

export const isLeadAvailableForSmsFollowUp = (lead, isMMCLead) =>
  isMMCLead && (lead.leadType === AS_LEAD_TYPE_ENUM.NORMAL.value || lead.topicStr === AsLeadTopic.TG);

export const filterOverlayReservations = reservations =>
  _.filter(reservations, reservation => {
    return !moment(_.get(reservation, 'reservationDate'), 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(moment());
  });
export const getUniqServiceProducts = selectedLeads =>
  _.chain(selectedLeads).map(_.property('serviceProducts')).flatten().filter(product => !_.isNil(product)).uniqBy('leadProductId').value();

export const formatNullString = string => string === 'null' ? null : string;

export const getLifeCycleFieldText = (fieldName, leadType) => {
  const mappedEnum = _.find(FIELD_OPTIONS, ['value', fieldName]);
  const mappedText = _.get(mappedEnum, 'text', '');
  if (_.isEmpty(mappedText)) {
    const getDisplayedText = _.get(mappedEnum, 'displayedText', () => '') as Function;
    return getDisplayedText(leadType);
  }
  return mappedText;
};

const needFormattedFields = ['closeTime', 'nextFollowUpDate', 'reservationDate', 'repairDate'];

export const getLifeCycleFieldValue = (lead, fieldName) => {
  const value = _.get(lead, fieldName);
  if (_.includes(needFormattedFields, fieldName)) {
    return dateParse(value, _.get(_.find(FIELD_OPTIONS, ['value', fieldName]), 'format')) || '';
  }
  return value || '';
};

const isOrderFailed = lead => isOrderedLead(lead) && isReservationFailed(lead.isReservationSuccess);

const isOrderPending = lead => isOrderedLead(lead) && isReservationPending(lead.isReservationSuccess);

const isReservationPending = reservationStatus => reservationStatus === RESERVATION_SUCCESS_STATUS.PENDING.text;

const isReservationFailed = reservationStatus => reservationStatus === RESERVATION_SUCCESS_STATUS.FALSE.text;

export const getLeadStatusImage = lead => {
  if (isOrderedLead(lead)) {
    return getReservationStatusImage(lead, 'isReservationSuccess');
  }
  return null;
};

export const getReservationStatusImage = (data, path) => {
  const imgUrl = RESERVATION_SUCCESS_STATUS.getValueByText(_.get(data, path));
  return _.isEmpty(imgUrl) ? null : imgUrl;
};

export const getLeadOrderedStatusValue = lead => {
  if (isOrderFailed(lead)) {
    return {
      title: '预约状态',
      value: '预约失败',
      styleClass: 'error',
    };
  }
  if (isOrderPending(lead)) {
    return  {
      title: '预约状态',
      value: '预约待确认',
      styleClass: 'warning',
    };
  }
  return null;
};

const buildFailedReasonValue = lead => {
  if (_.isEmpty(lead.oabErrorMsg)) {
    return {
      title: '失败原因',
      value: '暂无',
      styleClass: 'info',
    };
  }

  return {
    title: '失败原因',
    value: lead.oabErrorMsg,
    styleClass: 'info',
  };
};

export const getLeadFailedReasonValue = lead => {
  if (!isOrderFailed(lead)) {
    return null;
  }

  return buildFailedReasonValue(lead);
};

export const getReservationFailedReasonValue = reservation => {
  if (!isReservationFailed(reservation.status)) {
    return null;
  }

  return buildFailedReasonValue(reservation);
};

export const getReservationStatusValue = reservation => {
  if (isReservationFailed(reservation.status)) {
    return RESERVATION_STYLE.FALSE;
  }
  if (isReservationPending(reservation.status)) {
    return RESERVATION_STYLE.PENDING;
  }
  return null;
};

export const hasChosenServiceType = () => (formGroup => {
  return _.isEmpty(formGroup.value) ? { serviceType: true } : null;
});

export const filterServiceType = (serviceType: Array<string>) =>
  _.chain(serviceType).uniq().filter(type => _.includes(_.map(SERVICE_TYPE.values, 'key'), type)).value();

export const deserializeServiceType = (serviceType: string) => filterServiceType(splitServiceType(serviceType));

export const canBeDeselected = (key, serviceOptions, serviceChange) =>
  _.includes(_.difference(
    _.chain(serviceOptions).filter(option => option.disabled).map(_.property('key')).value(),
    serviceChange.currentValue), key);

export const splitServiceType = serviceType => _.split(serviceType, /[\s,.，]+/);

export const fillLead = lead => ({ ...lead, contactSalutation: lead.contactSalutation || '先生' });

export const initLifeCycleOffers = (lifeCycleData: Array<AsLifecycle>): Array<AsLifeCycleOffer> =>
  _.map(lifeCycleData, (data, index) => ({ ...data, isFold: index !== 0 }));

export const showRegisteredLetterModal = lead => {
  const isRecallLead = _.includes([AS_LEAD_TYPE_ENUM.RECALL.value], lead.lead.leadType);
  return isRecallLead && (lead.lead.followUpCount === 0 || _.isNil(lead.lead.followUpCount))
    && _.isNil(_.get(lead.lead.extendedInfo, 'customerRemind'));
};

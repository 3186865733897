import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dealerRangeText' })
export class DealerRangePipe implements PipeTransform {
  transform(dealerRange, maxLength) {
    if (!dealerRange) {
      return '';
    }
    if (dealerRange.length === maxLength) {
      return '全部';
    }
    return `${dealerRange.length}家`;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Body, GET, Headers, POST, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';

@Injectable()
export class CouponApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @POST('/api/website/coupon/verify')
  couponVerify(@Body data): Observable<any> {
    return null;
  }

  @GET('/api/website/coupon/csv')
  @Headers({ isExportFile: true })
  exportCsv(): Observable<any> {
    return null;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { CERTIFICATE_TYPE } from '../constants/common';

@Pipe({ name: 'certificateType' })
export class CertificateTypePipe implements PipeTransform {
  transform(type: string): string {
    return _.chain(CERTIFICATE_TYPE)
      .find((certificateType: any) => certificateType.value === type)
      .get('text', '')
      .value();
  }
}

import { ONE_DAY_MILLISECONDS } from '../constants/common';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment'; // tslint:disable-line
import { dateParse, getHourMinute } from './date-parse';
import { formatDate } from '@angular/common';
export const formateDataWithMinutes = 'yyyyMMddHHmmss';
export const getDateAtZero = (date: Date = new Date()): number => date.setHours(0, 0, 0, 0);

export const getFirstDayOfMonth = (date: Date = new Date()): Date => {
  date.setDate(1);
  return date;
};

export const convertTimeStampToDateString = timestamp => _.isNil(timestamp) ? '' : moment(timestamp).format('YYYY-MM-DD') ;

export const convertTimeStampObjectToDateString = ({value}: { value: string }) => _.isNil(value) ? '' : moment(value).format('YYYY-MM-DD');

export const convertGMTTimeStampToDateString = timestamp => _.isNil(timestamp) ? '' : formatDate(timestamp, 'yyyy-MM-dd', 'en-US');

export const dateValueFormatter = (timestamp: Date, nullValue: string = '-') => {
  return _.isNil(timestamp) ? nullValue : moment(timestamp).format('YYYY/MM/DD HH:mm');
};

export const getLastDayOfMonth = (date: Date = new Date()): Date => {
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  return date;
};

export const getPreMonthDate = (date: Date = new Date(), preSpan = 1): Date => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() - preSpan);
  return newDate;
};

export const getDateAtLastSecond = (date: Date = new Date()): number => getDateAtZero(date) + ONE_DAY_MILLISECONDS - 1;

export const isLongerThan40Days = (from: number, to: number): boolean => (to - from > 40 * ONE_DAY_MILLISECONDS);

export const dateInterval = (baseTime, length, interval) => (_.reduce(_.range(length), (timeArray, current) =>
  _.union(timeArray, [baseTime + interval * current]), []));

export const isToday = date => moment(date).isSame(moment(), 'day');

export const isSameDay = (date1, date2) => moment(date1).isSame(moment(date2), 'day');

export const inDateRange = (from, to, currentDate) => {
  if (_.isNil(from)) {
    return moment(currentDate).isSameOrBefore(to, 'day');
  }
  return _.isNil(to) ?
    moment(currentDate).isSameOrAfter(from, 'day') :
    moment(currentDate).isBetween(from, to, 'day', '[]');
};

export const getDatePeriod = (startTime: Date, endTime: Date) => {
  if (_.isNil(startTime) || _.isNil(endTime)) {
    return '';
  }
  return `${moment(startTime).format('YYYY-MM-DD')} 到 ${moment(endTime).format('YYYY-MM-DD')}`;
};

export const addMinutes = (date, minutes = 1) => moment(date).add(minutes, 'm').valueOf();

export const getDateAtZeroOfNextDay = (date: Date) => getDateAtZero(date) + ONE_DAY_MILLISECONDS;

export const formatMoment = (date: Moment) => date.format('YYYY-MM-DD');

export const getStartOfDayToDate = (date: Moment) => date.startOf('day').toDate();

export const getEndOfDayToDate = (date: Moment) => date.endOf('day').toDate();

export const getStartOfDay = (date: Moment) => date.startOf('day').valueOf();

export const getEndOfDay = (date: Moment) => date.endOf('day').valueOf();

export const getStartOfMonth = (date: Moment) => date.startOf('month').valueOf();

export const getEndOfMonth = (date: Moment) => date.endOf('month').valueOf();

export const getAllWeeks = (startTime: Moment, endTime: Moment) => {
  const data = [];
  const firstDayOfWeek = startTime.clone().startOf('isoWeek');
  while (firstDayOfWeek.isSameOrBefore(endTime)) {
    const endOfWeek = firstDayOfWeek.clone().endOf('isoWeek');
    data.push({
      text: `${formatMoment(firstDayOfWeek)} - ${formatMoment(endOfWeek)}`,
      start: getStartOfDay(firstDayOfWeek),
      end: getEndOfDay(endOfWeek),
    });
    firstDayOfWeek.add(1, 'weeks');
  }
  return data.reverse();
};

export const getAllMonths = (startTime: Moment, endTime: Moment) => {
  const data = [];
  const monthOfYear = startTime.clone();
  while (monthOfYear.isSameOrBefore(endTime.clone().endOf('month'))) {
    data.push({
      text: monthOfYear.format('YYYY-MM'),
      start: getStartOfMonth(monthOfYear),
      end: getEndOfMonth(monthOfYear),
    });
    monthOfYear.add(1, 'month');
  }
  return data.reverse();
};

export const getMaxTime = time => (
  getHourMinute(isToday(time) ? new Date() : getDateAtLastSecond(new Date(time)))
);

export const generateTimeOption = () => {
  const timeValues = dateInterval(new Date().setHours(0, 0, 0, 0), 49, 1800000);
  return _.map(timeValues, (value, index) =>  (index === 48 ?
    { text: '24:00', value: '23:59:00' }
    : { text: dateParse(value, 'HH:mm'), value: `${dateParse(value, 'HH:mm')}:00` }));
};

/*input timezone must match with that in the local environment variables*/
export function formatLocalTimeToStartOfDayTime(startOfDay, formatString?: string) {
  return moment(startOfDay).toISOString();
}

export function formatLocalTimeToEndOfDayTime(endDate) {
  return moment(endDate).endOf('day').toISOString();
}

export function formatMomentByFormatString(date, formatString: string) {
  return date ? moment(date).format(formatString).toString() : '';
}

export function formatToStartOfDayTime(date) {
  return moment(date).startOf('day').toISOString();
}

export function formatToStartOfDayTimeWithOutMilliseconds(date): string {
  return moment(date).startOf('day').milliseconds(0).toISOString();
}

export function formatToEndOfDayTimeWithOutMilliseconds(date): string {
  return moment(date).endOf('day').milliseconds(0).toISOString();
}

// moment的add year方法有 bug，如2020年2月29号加一年会变成2021年2月28号，期望的结果是2021年3月1号
export const addYearsWithoutMoment = (date, years) => {
  const endDate = new Date(date);
  endDate.setFullYear(endDate.getFullYear() + years);
  return endDate;
};

export const dateStringFormat = (dateString: string, format: string = 'YYYY-MM-DD HH:mm:ss') => {
  return _.isEmpty(dateString) ? '' : moment(dateString).format(format);
};

export const formatDurationTime = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return [h, m > 9 ? m : `0${m}`, s > 9 ? s : `0${s}`].filter(Boolean).join(':');
};

export const addDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

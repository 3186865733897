import { Injectable } from '@angular/core';
import { RebirthHttp, GET, Query } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ParameterConfigApi extends RebirthHttp {
  public uploadUri = '/api/customer-parameter/upload';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/customer-parameter/config/url')
  getTemplate(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  getExistingData(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/customer-parameter/update-time')
  getUpdateTime(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }
}

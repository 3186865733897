import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { JobCardApi } from '../api/job-card';
import {
  ClockOffLaborItemsData,
  JobCard,
  JobCardCollection,
  JobCardWithLaborItems,
  LaborItem,
  LaborItemStatusOperateHistory,
  RepairSummary,
  ReportCount,
} from '../models/job-card';
import { getInProgressLaborIdsBatch, tcmLastLaborTip, contLastLaborTip, lastLaborOption, getTechnicianList, getClockOffParams } from '../../job-card-management/job-card-helper';
import { ClockOffIdleData, Technician } from '../models/technician-team';
import { TechnicianIdleApi } from '../api/technician-idle';
import { GlobalEvent, GlobalEventService, SpinnerService, PromptBarService, CurrentUserService } from '@otr/website-common';
import { AsOrderService } from './as-order.service';
import { USER_ROLE } from '../../_common/constants/user-role.constant';
import { PromptLevel } from '../constants/common';

@Injectable()
export class JobCardService {
  shouldClockOffIdleId: number;
  shouldClockOffTechnicianData: Technician;
  shouldClockOffLaborItemsData: ClockOffLaborItemsData;
  showClockOffModal: Subject<ClockOffLaborItemsData> = new Subject();
  showClockOffIdleModal: Subject<ClockOffIdleData> = new Subject();

  constructor(private jobCardApi: JobCardApi,
              private technicianIdleApi: TechnicianIdleApi,
              private asOrderService: AsOrderService,
              private spinnerService: SpinnerService,
              private promptBarService: PromptBarService,
              private globalEventService: GlobalEventService,
              private currentUserService: CurrentUserService) {
    globalEventService.register(GlobalEvent.LOGOUT, () => {
      this.clear();
    });
  }

  clear(): void {
    this.shouldClockOffIdleId = null;
    this.shouldClockOffLaborItemsData = null;
  }

  getJobCardLabourItems(id: number): Observable<Array<LaborItem>> {
    return this.jobCardApi.getJobCardLabourItems(id);
  }

  getTechnicianAssignedJobCards(teamId: number, technicianId: number): Observable<Array<JobCard>> {
    return this.jobCardApi.getTechnicianAssignedJobCards(teamId, technicianId);
  }

  getTechnicianClockedInJobCards(technicianId: number): Observable<Array<JobCard>> {
    return this.jobCardApi.getTechnicianClockedInJobCards(technicianId);
  }

  getMyJobCards(technicianId: number): Observable<Array<JobCard>> {
    return this.jobCardApi.getMyJobCards(technicianId);
  }

  getMyTeamJobCards(technicianId: number, teamId: number): Observable<Array<JobCard>> {
    return this.jobCardApi.getMyTeamJobCards(technicianId, teamId);
  }

  getTechnicianInProgressJobCards(technicianId: number): Observable<Array<JobCardWithLaborItems>> {
    return this.jobCardApi.getTechnicianInProgressJobCards(technicianId);
  }

  getTechniciansInProgressJobCards(technicianIds: string): Observable<Array<JobCardWithLaborItems>> {
    return this.jobCardApi.getTechniciansInProgressJobCards(technicianIds);
  }

  searchJobCards(keyword: string): Observable<JobCardCollection> {
    return this.jobCardApi.searchJobCards(keyword);
  }

  getWorkshopComment(jobCardId: number): Observable<Record<string, string>> {
    return this.jobCardApi.getWorkshopComment(jobCardId);
  }

  clockOnLabourItems(id: number, updateStatus: any): Observable<Array<LaborItem>> {
    if (this.shouldClockOffIdleId) {
      return this.jobCardApi
        .stopIdleAndClockOnLabour(this.shouldClockOffIdleId, { ...updateStatus, jobCardId: id })
        .pipe(tap(() => {
          this.shouldClockOffIdleId = null;
          this.shouldClockOffTechnicianData = null;
        }));
    }

    if (this.shouldClockOffLaborItemsData) {
      return this.jobCardApi
        .clockOffLaborAndClockOnLabor(this.shouldClockOffLaborItemsData.jobCardId, {
          clockOnJobCardId: id,
          clockOnLaborItemIds: updateStatus.laborItemIds,
          laborItemIds: this.shouldClockOffLaborItemsData.laborItemIds,
          laborItemStatus: this.shouldClockOffLaborItemsData.laborItemStatus,
          technicianId: updateStatus.technicianId,
          technicianName: updateStatus.technicianName,
        })
        .pipe(tap(() => {
          this.shouldClockOffLaborItemsData = null;
          this.shouldClockOffTechnicianData = null;
        }));
    }

    return this.technicianIdleApi.getIdleInfo(updateStatus.technicianId).pipe(
      mergeMap(idleInfo => {
        if (idleInfo.inIdle) {
          this.spinnerService.stop();
          const confirmSubject: Subject<number> = new Subject();
          this.showClockOffIdleModal.next({
            title: '开钟',
            idleInfo,
            confirmSubject,
          });
          return confirmSubject.pipe(
            tap(() => this.spinnerService.run()),
            mergeMap(idleId => this.jobCardApi
              .stopIdleAndClockOnLabour(idleId, { ...updateStatus, jobCardId: id })),
            catchError(() => throwError('error')),
          );
        }

        return this.jobCardApi.clockOnLabourItems(id, updateStatus);
      }),
    );
  }

  clockOffLabourItems(id: number, updateStatus: any, selectedItems): Observable<Array<LaborItem>> {
    const technicianId = [...updateStatus.technicianId];
    const technicianName = [].concat(updateStatus.technicianName);
    const ifTCM = this.currentUserService.isRole(USER_ROLE.TCM);
    const { technicianIdList, technicianNameList, technicianIdStr } = getTechnicianList(updateStatus, ifTCM, technicianId, technicianName);
    const operator = technicianIdList.length > 1 ? this.getTechniciansInProgressJobCards(technicianIdStr) :
      this.getTechnicianInProgressJobCards(Number(technicianIdStr));

    return operator.pipe(
      mergeMap(jobCards => {
        if (_.isEmpty(jobCards)) {
          return throwError({
            error: 'labour_has_been_closed_by_others',
          });
        }
        const { inProgressLaborIdsMajor, inProgressLaborIdsAssist, technicianAndLabors }: any =
          getInProgressLaborIdsBatch(jobCards, technicianIdList, technicianNameList);
        const laborItemIds = _.get(updateStatus, 'laborItemIds', []);
        let returnValue = {};
        let ifLast = false;

        if (ifTCM) {
          if (tcmLastLaborTip(jobCards, laborItemIds, Number(technicianIdStr), selectedItems)) {
            const message = `该工项上的辅助技师会自动设置为待工`;
            this.promptBarService.show(message, PromptLevel.Emergency, false, false, 5000);
          }
          const isEmptyForMajor = _.isEmpty(_.difference(inProgressLaborIdsMajor, laborItemIds));
          const isEmptyForAssist = _.isEmpty(_.difference(inProgressLaborIdsAssist, laborItemIds));
          ifLast = isEmptyForMajor && isEmptyForAssist;
        } else {
          const message = contLastLaborTip(selectedItems, updateStatus);
          if (message) {
            this.promptBarService.show(message, PromptLevel.Emergency, false, false, 5000);
          }
          returnValue = lastLaborOption(technicianAndLabors, laborItemIds);
          ifLast = _.get(returnValue, 'flag');
        }
        if (ifLast) {
          this.spinnerService.stop();
          this.showClockOffModal.next({
            jobCardId: id,
            laborItemStatus: updateStatus.laborItemStatus,
            laborItemIds,
            technicianId: updateStatus.technicianId,
            technicianName: updateStatus.technicianName,
            majorNames: _.get(returnValue, 'majorNames'),
            majorIds: _.get(returnValue, 'majorIds'),
            assistNames: _.get(returnValue, 'assistNames'),
            assistIds: _.get(returnValue, 'assistIds'),
            technicianAndLabors,
          });
          return EMPTY;
        }

        const params = getClockOffParams(updateStatus, technicianAndLabors, laborItemIds);
        return _.isArray(params)
          ? this.jobCardApi.clockOffLabourItemsBatch(id, params)
          : this.jobCardApi.clockOffLabourItems(id, params);
      }),
    );
  }

  clockOffLabourItemsAndClockOnIdle(id: number, updateStatusWithIdle: Object): Observable<void> {
    return this.jobCardApi.clockOffLabourItemsAndClockOnIdle(id, updateStatusWithIdle);
  }

  clockOffLabourItemsAndClockOnIdleBatch(id: number, updateStatusWithIdle: Object): Observable<void> {
    return this.jobCardApi.clockOffLabourItemsAndClockOnIdleBatch(id, updateStatusWithIdle);
  }

  verifyLabourItemsStatus(id: number, updateStatus): Observable<any> {
    return this.jobCardApi.verifyLabourItemsStatus(id, updateStatus);
  }

  getAvailableTechnicians(currentJobCardId: number, laborItemIds: number[]): Observable<object[]> {
    return this.jobCardApi.getAvailableTechnicians(currentJobCardId, laborItemIds);
  }

  getAvailableAssistTechnicians(currentJobCardId: number, laborItemIds: number[]): Observable<object[]> {
    return this.jobCardApi.getAvailableAssistTechnicians(currentJobCardId, laborItemIds);
  }

  getJobCardLabourItemStatusOperateHistories(jobCardId: number): Observable<Array<LaborItemStatusOperateHistory>> {
    return this.jobCardApi.getJobCardLabourItemStatusOperateHistories(jobCardId);
  }

  supplementLabourItemStatusHistories(jobCardId: number, updateStatusHistories): Observable<void> {
    return this.jobCardApi.supplementLabourItemStatusHistories(jobCardId, updateStatusHistories);
  }

  clockOffLaborItemsAndSignOut(jobCardId: number, body: any) {
    return this.jobCardApi.clockOffLaborItemsAndSignOut(jobCardId, body);
  }

  clockOffLaborItemsAndSignOutBatch(jobCardId: number, body: any) {
    return this.jobCardApi.clockOffLaborItemsAndSignOutBatch(jobCardId, body);
  }

  clockOffLabourItemsSingle(jobCardId: number, body: any) {
    return this.jobCardApi.clockOffLabourItems(jobCardId, body);
  }

  clockOffLabourItemsBatch(jobCardId: number, body: any) {
    return this.jobCardApi.clockOffLabourItemsBatch(jobCardId, body);
  }

  getJobCardCount(startTime: string, endTime: string): Observable<ReportCount> {
    return this.jobCardApi.getJobCardCount(startTime, endTime);
  }

  downloadReport(startTime: string, endTime: string): Observable<any> {
    return this.jobCardApi.downloadReport(startTime, endTime);
  }

  getTechnicianCount(startTime: string, endTime: string): Observable<ReportCount> {
    return this.jobCardApi.getTechnicianCount(startTime, endTime);
  }

  downloadTechnicianEfficiencyReport(startTime: string, endTime: string): Observable<any> {
    return this.jobCardApi.downloadTechnicianEfficiencyReport(startTime, endTime);
  }

  getRepairSummary(orderId: string): Observable<RepairSummary> {
    return this.jobCardApi.getRepairSummary(orderId);
  }

  updateRepairSummary(repairSummary: RepairSummary, dealerId: string): Observable<any> {
    return this.jobCardApi.updateRepairSummary(repairSummary)
      .pipe(
        switchMap(value  => {
          return this.asOrderService.uploadOTRFile(dealerId, repairSummary.orderNumber, [{
            name: '维修施工说明单',
            savedName: value.id,
            fileType: 'repairSummary',
          }], true);
        }),
      );
  }
}

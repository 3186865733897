import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICONS } from '../../../../constants/icons';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  template: `
    <div class="btn icon-text-cell-container" [class.icon-right]="iconRight">
      <div #container class="display-text" [title]="iconType ? content : ''">
        {{ text }}
        <i [ngClass]="classNames"></i>
      </div>
    </div>
  `,
  styleUrls: ['./icon.style.scss'],
})
export class IconTootipleComponent implements ICellRendererAngularComp {
  classNames: any;
  text: string;
  iconType: any;
  iconRight: boolean;
  showToolTip: boolean;
  content: string;

  private params: ICellRendererParams;
  cellRendererParams: any;

  get title() {
    return this.showToolTip ? this.text : '';
  }

  @ViewChild('container') container: ElementRef;

  @HostListener('mouseenter')
  mouseEnter() {
    const element = this.container.nativeElement;
    if (element.offsetWidth > element.parentElement.offsetWidth) {
      this.container.nativeElement.setAttribute('title', this.content);
    }
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellRendererParams = params.colDef.cellRendererParams;
    this.iconType = getValue(this.cellRendererParams.iconType, params);
    const iconClass = ICONS[this.iconType];
    this.classNames = { fa: true, [iconClass]: true };
    this.text = getValue(this.cellRendererParams.text, params);
    this.iconRight = getValue(this.cellRendererParams.iconRight, this.params);
    this.showToolTip = getValue(this.cellRendererParams.showToolTip, this.params);
    this.content = getValue(this.cellRendererParams.content, params);
  }

  refresh(params: any): boolean {
    return false;
  }

}

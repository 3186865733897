import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AsOrderApi } from '../api/as-order';

@Injectable()
export class AsOrderService {
  constructor(private asOrderApi: AsOrderApi) {
  }

  getOrderInfo(orderId, dealerId): Observable<any> {
    return this.asOrderApi.getOrderInfo(orderId, dealerId);
  }

  // getOrdersByVins(vins): Observable<any> {
  //   return this.asOrderApi.getOrdersByVins(vins);
  // }

  getOrderListByFilter(pageSize, pageNumber, filter) {
    return this.asOrderApi.getOrderListByFilter(pageSize, pageNumber, encodeURI(JSON.stringify(filter)));
  }

  getDocumentsListWithOrder(orderId, dealerId, pageNumber, pageSize): Observable<any> {
    return this.asOrderApi.getDocumentsListWithOrder(orderId, dealerId, pageNumber, pageSize);
  }

  getWarrantyDocumentsListWithOrder(orderId, docsTypes, pageNumber, pageSize, dealerId): Observable<any> {
    return this.asOrderApi.getWarrantyDocumentsListWithOrder(orderId, docsTypes, pageNumber, pageSize, dealerId);
  }

  getStarDUploadFileNameRules(): Observable<any> {
    return this.asOrderApi.getStarDUploadFileNameRules();
  }

  getServiceType(): Observable<any> {
    return this.asOrderApi.getServiceType();
  }

  getOrdersByVinsAndDealerId(vins, dealerId): Observable<any> {
    return this.asOrderApi.getOrdersByVinsAndDealerId(vins, dealerId);
  }

  getMediaFileList(dealerId, orderId, pageNumber, pageSize): Observable<any> {
    return this.asOrderApi.getMediaFileList(orderId, dealerId, pageNumber, pageSize);
  }

  getMediaFileListForBA(orderId, pageNumber, pageSize): Observable<any> {
    return this.asOrderApi.getMediaFileListForBA(orderId, pageNumber, pageSize);
  }

  getAllDealerInfo(): Observable<any> {
    return this.asOrderApi.getAllDealerInfo();
  }

  deleteOrderDocument(orderId, documentId): Observable<any> {
    return this.asOrderApi.deleteOrderDocument(orderId, documentId);
  }

  saveDocumentsWithOrder(uploadList): Observable<any> {
    return this.asOrderApi.saveDocumentsWithOrder(uploadList);
  }

  saveStarDFile(data: FormData): Observable<any> {
    return this.asOrderApi.saveStarDFile(data);
  }

  saveOtherFile(data: FormData): Observable<any> {
    return this.asOrderApi.saveOtherFile(data);
  }

  uploadOrderExcel(data): Observable<any> {
    return this.asOrderApi.uploadOrderExcel(data);
  }

  getNoClaimNumberListByFilter(claimSubmitted, pageSize, pageNumber, dealerId, filter) {
    return this.asOrderApi.getNoClaimNumberListByFilter(claimSubmitted, pageSize, pageNumber, dealerId, encodeURI(JSON.stringify(filter)));
  }

  saveOrderClaimNumber(orderId, newClaimNumber): Observable<any> {
    return this.asOrderApi.saveOrderClaimNumber(orderId, newClaimNumber);
  }

  zipByClaimNumbers(dealerId, vegaCode, claimNumbers): Observable<any> {
    return this.asOrderApi.zipByClaimNumbers(dealerId, vegaCode, claimNumbers);
  }

  getZipTaskStatus(zipTaskIds): Observable<any> {
    return this.asOrderApi.getZipTaskStatus(zipTaskIds);
  }

  terminateZipTask(zipTaskIds): Observable<any> {
    return this.asOrderApi.terminateZipTask(zipTaskIds);
  }

  validateOrderExceedLimit(dealerId: string, claimNumbers: string[]): Observable<any> {
    return this.asOrderApi.validateOrderExceedLimit(dealerId, claimNumbers);
  }

  uploadFileType(dealerId: string, orderNumber: string, files: Array<any>): Observable<any> {
    const data = { orderNumber, files };
    return this.asOrderApi.uploadFileType(dealerId, data);
  }

  uploadMediaFile(data): Observable<any> {
    return this.asOrderApi.uploadMediaFile(data);
  }

  uploadOTRFile(dealerId: string, orderNumber: string, files: Array<any>, overwrite = false): Observable<any> {
    const data = { orderNumber, files };
    return this.asOrderApi.uploadOTRFile(dealerId, overwrite, data);
  }

  uploadFileDescription(orderId, category, mediaId, comment, picTakenTime): Observable<any> {
    const data = { orderId, category, mediaId, comment, picTakenTime };
    return this.asOrderApi.uploadFileDescription(orderId, data);
  }

  updateFileDescription(orderId, category, id, mediaId, comment): Observable<any> {
    const data = { orderId, category, id, mediaId, comment };
    return this.asOrderApi.updateFileDescription(orderId, data);
  }

  deleteMediaFile(orderId, id): Observable<any> {
    return this.asOrderApi.deleteMediaFile(orderId, id);
  }

  getOrderVehicleInvoiceFile(orderNumber: string): Observable<any> {
    return this.asOrderApi.getOrderVehicleInvoiceFile(orderNumber);
  }
}

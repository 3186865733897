import { Component, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { fixNumber } from '../../utils/format-string';

const defaultBackgroundColor = '#ebedf2';

const beforeDatasetsDraw = (instance) => {
  const backgroundColor = _.get(instance, 'config.options.elements.donut.background', '#ebedf2');
  const x = instance.chart.canvas.clientWidth / 2;
  const y = instance.chart.canvas.clientHeight / 2;
  const ctx = instance.chart.ctx;

  ctx.beginPath();
  ctx.arc(x, y, instance.outerRadius - instance.radiusLength / 2, 0, 2 * Math.PI);
  ctx.lineWidth = instance.radiusLength;
  ctx.strokeStyle = backgroundColor;
  ctx.stroke();
};

@Component({
  selector: 'app-chart-with-usage',
  styleUrls: ['./chart-with-usage.style.scss'],
  templateUrl: './chart-with-usage.template.html',
})
export class ChartWithRateComponent implements OnChanges {
  @Input() name: string;
  @Input() type: string = 'doughnut';
  @Input() width: string = '100px';
  @Input() height: string = '100px';
  @Input() remark: string;
  @Input() usageRate: number;
  @Input() options: object = {};

  data;
  mergeOptions: object = {};
  plugins = [{ beforeDatasetsDraw }];
  defaultTheme: object = {
    borderWidth: 0,
    backgroundColor: [defaultBackgroundColor],
  };
  defaultOptions = {
    cutoutPercentage: 83,
    legend: { display: false },
    tooltips: { enabled: false },
    elements: {
      donut: { background: defaultBackgroundColor },
    },
  };

  ngOnChanges(changes): void {
    this.paintChart();
  }

  paintChart(): void {
    this.data = {
      datasets: [
        {
          ...this.defaultTheme,
          data: [fixNumber(this.usageRate), 1 - fixNumber(this.usageRate)],
          backgroundColor: ['#0B81CD', defaultBackgroundColor],
        },
      ],
    };
    this.mergeOptions = { ...this.defaultOptions, ...this.options };
  }

  isValueNil = (value: number) => {
    return _.isNil(value);
  };

  formatValue = (value: number) => {
    return fixNumber(100 * value, 2);
  };
}

<form class="form-container">
  <div class="form-group">
    <label class="required form-label">本次跟进记录</label>
    <textarea class="form-control comment"
              placeholder="本次跟进的情况"
              maxlength="200"
              [formControl]="followUpComments">
    </textarea>
  </div>
  <div class="form-group">
    <label class="required form-label">下次跟进日期</label>
    <p-calendar
      [locale]="chineseCalendarConfig"
      dataType="string"
      dateFormat="yy-mm-dd"
      placeholder="跟进日期"
      [formControl]="nextFollowUpDate"
      [minDate]="minDate"
      [inputStyle]="nextFollowUpDate.invalid && nextFollowUpDate.touched ? invalidStyle : {}"
    >
    </p-calendar>
  </div>
  <div class="form-group" *ngIf="shouldShowToBeLead()">
    <label>{{getLabelTitle}}</label>
      <div class="radio-control-group" *ngFor="let option of changeStatus">
        <span class="radio-control">
          <input type="radio" [formControl]="changeStatusControl" [value]="option.value"/>{{option.text}}
        </span>
      </div>
  </div>
</form>

import { CurrentUserService, getVehicleBusinessType, hasAuthorisedBySquadAndBusinessType } from '@otr/website-common';
import * as _ from 'lodash';
import { USER_GROUP_TYPES } from '../constants/user-group.constant';
import { TabSwitcherService } from '../services/tab-switcher.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthCheck {
  constructor(private tabSwitcherService: TabSwitcherService,
              private currentUserService: CurrentUserService) {
  }

  isDealerGroupAuthorisedByBusinessType(): boolean {
    if (!this.tabSwitcherService.isVanType
      || !this.currentUserService.getCurrentUser()) {
      return true;
    }
    const { dealerId, groupSource }  = this.currentUserService.getCurrentUser();
    if (!_.isNil(dealerId) && !_.isNil(groupSource) && _.eq(groupSource, USER_GROUP_TYPES.DEALER_GROUP.value)) {
      const dealer_info = _.find(JSON.parse(sessionStorage.getItem('dealer_list')), ['id_dealer', dealerId]);
      if (!_.isNil(dealer_info) && !_.isEmpty(dealer_info) && !_.isNil(dealer_info.authorization_types)) {
        return hasAuthorisedBySquadAndBusinessType(dealer_info.authorization_types, 'AS', getVehicleBusinessType());
      }
    }
    return true;
  }
}

import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { FeatureToggleService } from '@otr/website-common';

@Injectable()
export class ComplaintGuard implements CanActivate, CanActivateChild {
  constructor(private featureToggleService: FeatureToggleService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Promise<any> | Observable<boolean> | boolean {
    const isComplaintEnabled = this.featureToggleService.isComplaintEnabled;

    if (!isComplaintEnabled) {
      this.router.navigate(['case', 'coming_soon']);
    }

    return isComplaintEnabled;
  }

  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Promise<any> | Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }
}

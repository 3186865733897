import * as JsEncryptModule from 'jsencrypt';
import * as _ from 'lodash';
import * as moment from 'moment';

export function encryptPassword(publicKey, value) {
  const rsa = new JsEncryptModule.JSEncrypt();
  rsa.setPublicKey(publicKey);
  return rsa.encrypt(value);
}

export function validateCompanyPasswordLength(companyPassword) {
  if (companyPassword.value && companyPassword.value.length < 6) {
    companyPassword.setErrors({ message: '长度不足6位' });
  }
}

export function validateRepeatCompanyPassword(companyPassword, repeatCompanyPassword) {
  if (!_.isEqual(companyPassword.value, repeatCompanyPassword.value)) {
    repeatCompanyPassword.setErrors({ message: '两次密码不一致，请重新输入' });
  }
}

export function getMotParameterFailureMessage(registerFailedInfo) {
  return `参数保存成功，连接MOT失败！\n\n失败原因：${registerFailedInfo}。\n\n请稍后尝试获取唯一识别码。`;
}

export function getMotParameterSuccessMessage(companyUniqueCode) {
  return `维修企业注册成功！\n\n您可以前往电子档案接口，进行工单传送。\n\n维修企业唯一标识：${companyUniqueCode}`;
}

export const formatDate = (value, format = 'YYYY-MM-DD') => _.isEmpty(value) ? '' : moment(value).format(format);

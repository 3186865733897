import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid';
import * as _ from 'lodash';
import { ExtendedGridEvents } from '../../event/events';

@Component({
  templateUrl: 'removable-click-edit-header.template.html',
  styleUrls: ['removable-click-edit-header.style.scss'],
})
export class RemovableClickEditHeaderComponent implements IHeaderAngularComp {
  params: IHeaderParams;
  headerEditorParams: any;
  isRemovable: boolean = false;
  isEditing: boolean = false;
  isDisplayMenu: boolean = false;
  isEditable: boolean = true;
  value: string;
  options: Array<any>;
  selectedOption: any;
  colDef: any;

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.value = this.params.displayName;
    this.colDef = this.params.column.getColDef();

    this.headerEditorParams = _.get(this.colDef, 'headerEditorParams');
    this.isEditable = this.headerEditorParams.isEditable;
    if (this.isEditable) {
      this.eventEmitter.on(ExtendedGridEvents.COLUMN_REMOVABLE, removable => this.isRemovable = removable);
    }
    this.options = this.colDef.filterParams.options;
    this.selectedOption = _.find(this.options, { value: this.colDef.cellRendererParams.numeric_data_type });
  }

  enableEditMode() {
    if (this.isEditable && this.headerEditorParams.canEditHeaderName) {
      this.isEditing = true;
      this.dblClickFallback();
    }
  }

  onChange(event) {
    if (this.isEditable) {
      this.isEditing = false;
      this.params.displayName = event.target.value;
      this.value = event.target.value;
      this.headerEditorParams.onEditColumn(event, this.colDef.cellRendererParams);
    }
  }

  handleBlur() {
    this.isEditing = false;
  }

  get eventEmitter() {
    return this.params.context.eventEmitter;
  }

  removeColumn() {
    if (this.isEditable) {
      this.params.columnApi.setColumnVisible(this.params.column, false);
      this.headerEditorParams.onRemoveColumn(this.colDef.cellRendererParams);
    }
  }

  handleDisplayDataTypeGroups(event) {
    this.isDisplayMenu = true;
  }

  handleHiddenDataTypeGroups() {
    this.isDisplayMenu = false;
  }

  handleRadioButtonChange(option) {
    this.headerEditorParams.onRadioChange(option, this.colDef.cellRendererParams);
  }

  private get dblClickFallback() {
    return this.headerEditorParams.onDblClick || _.noop;
  }
}

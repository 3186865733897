import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';

import { isToday } from '../../utils/date';
import { CHINESE_CALENDAR_CONFIG } from '../../constants/calendar';
import { correctTimeValue, formatHourMinuteSeconds, parseDateTime } from '../../utils/date-parse';

export const maxDateTimeValidator = control => {
  const { date, time } = control.value;
  const dateTime = parseDateTime(date, time);
  const invalid = isToday(date) ? dateTime.toDate() > new Date() : null;
  return invalid ? { max: true } : null;
};

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DateTimePickerComponent,
    multi: true,
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DateTimePickerComponent),
    multi: true,
  }],
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor {
  @ViewChild('date') dateCalendar;

  @Input() maxDate?: Date;
  @Input() formatter: any = _.identity;

  dateTime: FormGroup;
  onChange: any = _.noop;

  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.dateTime = this.formBuilder.group({
      date: [null, Validators.required],
      time: ['', Validators.compose([Validators.required, Validators.pattern(/^\d\d:\d\d:\d\d$/)])],
    }, {
      validator: this.maxDate && isToday(this.maxDate) ? maxDateTimeValidator : null,
    });
    this.dateTime.valueChanges.subscribe(({ date, time }) => {
      const dateTime = parseDateTime(date, time);
      this.onChange(this.formatter(dateTime.toDate()));
    });
  }

  autoCompleteTime() {
    const time = this.dateTime.value.time;
    if (time.match(/\d+:\d+/)) {
      const [hour, minute, second] = time.split(':');
      this.dateTime.patchValue({
        time: formatHourMinuteSeconds({
          hour: correctTimeValue(hour, 23),
          minute: correctTimeValue(minute, 59),
          second: correctTimeValue(second || 0, 59),
        }),
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.dateTime.disable() : this.dateTime.enable();
  }

  writeValue(value: any): void {
    if (value) {
      const date = moment(value);
      this.dateTime.setValue({ date: date.toDate(), time: date.format('HH:mm:ss') });
    } else {
      this.dateTime.setValue({ date: new Date(), time: '' });
    }
  }

  validate() {
    return this.dateTime.errors || this.dateTime.controls.time.errors;
  }
}

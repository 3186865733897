import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';
import { IAfterGuiAttachedParams, IFilterParams } from 'ag-grid';
import { FormControl } from '@angular/forms';
import { CHINESE_CALENDAR_CONFIG } from '../../../../../constants/calendar';
import { isEmptyValue } from '../../../../../utils/common';
import { getDateAtLastSecond, getDateAtZero } from '../../../../../utils/date';
import { dateRegularParse } from '../../../../../utils/date-parse';

@Component({
  selector: 'app-pagination-range-filter-cell',
  templateUrl: 'pagination-calendar-range.template.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationCalendarRangeComponent extends BasePaginationFilterComponent {
  startDateControl: FormControl = new FormControl('');
  endDateControl: FormControl = new FormControl('');
  savedStartValue: string = '';
  savedEndValue: string = '';
  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;

  agInit(params: IFilterParams): void {
    super.agInit(params);
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    super.afterGuiAttached(params);
    this.startDateControl.setValue(this.savedStartValue);
    this.endDateControl.setValue(this.savedEndValue);
  }

  onSubmit() {
    super.hideFilterMenu();
    this.savedStartValue = this.startDateControl.value;
    this.savedEndValue = this.endDateControl.value;
    this.params.filterChangedCallback();
  }

  isFilterActive(): boolean {
    return !this.isEmptyFilter();
  }

  onCancel() {
    super.hideFilterMenu();
    this.startDateControl.setValue(this.savedStartValue);
    this.endDateControl.setValue(this.savedEndValue);
  }

  onCalendarBlur() {
    this.endDateControl.updateValueAndValidity();
    this.startDateControl.updateValueAndValidity();
  }

  public setDefaultValue() {
    super.setDefaultValue();
    this.startDateControl.setValue('');
    this.endDateControl.setValue('');
    this.savedStartValue = this.startDateControl.value;
    this.savedEndValue = this.endDateControl.value;
  }

  get filterValue() {
    return {
      from: isEmptyValue(this.savedStartValue) ? null : getDateAtZero(new Date(this.savedStartValue)),
      to: isEmptyValue(this.savedEndValue) ? null : getDateAtLastSecond(new Date(this.savedEndValue)),
    };
  }

  isEmptyFilter() {
    return isEmptyValue(this.savedStartValue) && isEmptyValue(this.savedEndValue);
  }

  setModel(model: any): void {
    this.savedStartValue = dateRegularParse(_.get(model, 'from', ''));
    this.savedEndValue = dateRegularParse(_.get(model, 'to', ''));
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    return this.modelFormatter(
      {
        from: isEmptyValue(this.savedStartValue) ? null : getDateAtZero(new Date(this.savedStartValue)),
        to: isEmptyValue(this.savedEndValue) ? null : getDateAtLastSecond(new Date(this.savedEndValue)),
      });
  }

  isFilterValid() {
    return isEmptyValue(this.savedStartValue) || isEmptyValue(this.savedEndValue)
      || getDateAtZero(new Date(this.savedStartValue)) <= getDateAtLastSecond(new Date(this.savedEndValue));
  }

  stopPropagation() {
    event.stopPropagation();
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-with-collapse',
  templateUrl: './title-with-collapse.template.html',
  styleUrls: ['./title-with-collapse.style.scss'],
})
export class TitleWithCollapseComponent {
  @Input() isCollapse: boolean = false;
  @Input() title: string = '';
}

export enum IconType {
  Plus,
  Minus,
  File,
  CARET_UP,
  PENCIL_SQUARE,
  CHECK_SQUARE,
  THUMBS_UP,
  CAR,
  CHECK_CIRCLE,
  TRASH,
  PENCIL,
  FLAG_CIRCLE,
  ARROW_CIRCLE_RIGHT,
  INFO_CIRCLE,
  MEDIA_PLAY,
  INFO_CIRCLE_OUTLINED,
}

export const ICONS = {
  [IconType.Plus]: 'antd-plus-circle-filled',
  [IconType.Minus]: 'antd-minus-circle-filled',
  [IconType.File]: 'antd-file-text-outlined',
  [IconType.CARET_UP]: 'antd-caret-up-outlined',
  [IconType.PENCIL_SQUARE]: 'antd-form-outlined',
  [IconType.CHECK_SQUARE]: 'antd-check-square-outlined',
  [IconType.THUMBS_UP]: 'antd-like-outlined',
  [IconType.CAR]: 'antd-car-filled',
  [IconType.CHECK_CIRCLE]: 'antd-check-circle-filled',
  [IconType.TRASH]: 'antd-delete-filled',
  [IconType.PENCIL]: 'antd-edit-filled',
  [IconType.FLAG_CIRCLE]: 'antd-flag-outlined',
  [IconType.ARROW_CIRCLE_RIGHT]: 'antd-right-circle-filled',
  [IconType.INFO_CIRCLE]: 'antd-info-circle-filled',
  [IconType.INFO_CIRCLE_OUTLINED]: 'antd-info-circle-outlined',
  [IconType.MEDIA_PLAY]: 'antd-play-circle-outlined',
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'postfix' })
export class PostfixPipe implements PipeTransform {
  transform(value: string, postfix: string) {
    if (postfix) {
      return `${value}${postfix}`;
    }
    return value;
  }
}

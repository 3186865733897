<i class='fa'
   *ngIf='isParent'
   [ngClass]='isExpanded(params) ? "antd-caret-up-outlined" : "antd-caret-down-outlined"'
   (click)='handleExpandClick()'></i>
<span class='checkbox-box' (click)='handleCheckboxClick($event)'>
  <input type='checkbox'
         *ngIf='isParent || isChild'
         [ngClass]='isChild ? "child-checkbox" : ""'
         [checked]='checked$ | async'
         [indeterminate]='indeterminate$ | async'
         [disabled]='disabled$ | async' />
  <span class='checkbox-inner' *ngIf='indeterminate$ | async'></span>
</span>


import { EXCEL_FILE_CONTENT_TYPE, EXPORT_EXCEL_FOR_OEM_CONTENT_TYPE } from '../../_common/constants/service-contract';
import {
  formatBooleanValueWithAll,
  formatCustomerType,
  formatDateString,
  formatEnumString, formatTextValueString,
  IDENTIFY_TYPE
} from '../utils/insurance';
import * as _ from 'lodash';
import { FollowUpStatus } from '../../_common/components/show-status/show-status.component';

export const MAX_ASSIGN_COUNT = 5000;

export const customerDetailLinkPrefix: string = '/insurance/customer-detail';

export const policyEditLinkPrefix: string = '/insurance/policy-edit';

export enum PolicyBusinessType {
  POLICY_EDIT = 'POLICY_EDIT',
  UNDERWRITING_REGISTRATION = 'UNDERWRITING_REGISTRATION',
}

export const policyBusinessTypeWords = {
  [PolicyBusinessType.POLICY_EDIT]: '编辑登记记录',
  [PolicyBusinessType.UNDERWRITING_REGISTRATION]: '承保登记',
};

export enum ImportInsuranceStatuses {
  PROCESSING = '处理中',
  SUCCESS = '全部成功',
  FAILURE = '全部失败',
  PARTIAL_SUCCESS = '部分成功',
}

export enum InsuranceRoles {
  OEM_ACCIDENT = 'OEM_ACCIDENT',
  IM = 'IM',
  ASM = 'ASM',
  OEM_ACCIDENT_3RD_PARTY = 'OEM_ACCIDENT_3RD_PARTY',
}

export enum FileType {
  'xls' = EXCEL_FILE_CONTENT_TYPE as unknown as FileType,
  'xlsx' = EXPORT_EXCEL_FOR_OEM_CONTENT_TYPE as unknown as FileType,
}

export enum IdentityType {
  IDENTITY_CARD = 'IDENTITY_CARD',
  PASSPORT = 'PASSPORT',
  UNIFORM_SOCIAL_CREDIT_CODE = 'UNIFORM_SOCIAL_CREDIT_CODE',
  BUSINESS_LICENSE = 'BUSINESS_LICENSE',
}

export enum CustomerSource {
  DEALER_IMPORT = 'DEALER_IMPORT',
  MANUALLY_CREATE = 'MANUALLY_CREATE',
  ARRIVED_CUSTOMER = 'ARRIVED_CUSTOMER',
  NEW_CAR_SALES = 'NEW_CAR_SALES',
}

export const identityOptions = [
  { text: '身份证', value: IdentityType.IDENTITY_CARD },
  { text: '护照', value: IdentityType.PASSPORT },
  { text: '统一社会信用代码/营业执照', value: IdentityType.UNIFORM_SOCIAL_CREDIT_CODE },
];

export const identityWords = {
  [IdentityType.IDENTITY_CARD]: '身份证',
  [IdentityType.PASSPORT]: '护照',
  [IdentityType.UNIFORM_SOCIAL_CREDIT_CODE]: '统一社会信用代码/营业执照',
};

export const customerSourceOptions = [
  { text: '经销商导入', value: CustomerSource.DEALER_IMPORT },
  { text: '手动创建', value: CustomerSource.MANUALLY_CREATE },
  { text: '进店客户', value: CustomerSource.ARRIVED_CUSTOMER },
  { text: '新车销售', value: CustomerSource.NEW_CAR_SALES },
];

export const ALL = 'ALL';

export enum CustomerRange {
  FULL_CUSTOMER = ALL as unknown as CustomerRange,
  RENEWAL_CUSTOMER = 'RENEWAL_PERIOD_CUSTOMER' as unknown as CustomerRange,
}

export enum InsuranceCustomerType {
  NOT_INSURED_CUSTOMER = 'NOT_INSURED_CUSTOMER',
  RENEWAL_TO_RENEWAL_CUSTOMER = 'RENEWAL_TO_RENEWAL_CUSTOMER',
  IN_REPAIR_NOT_INSURED_CUSTOMER = 'IN_REPAIR_NOT_INSURED_CUSTOMER',
  NEW_TO_RENEWAL_CUSTOMER = 'NEW_TO_RENEWAL_CUSTOMER',
  OTHER_CUSTOMER = 'OTHER_CUSTOMER',
}

export const customerRangeOptions = [
  { text: '全量客户', value: CustomerRange.FULL_CUSTOMER },
  { text: '续保期客户', value: CustomerRange.RENEWAL_CUSTOMER },
];

export const customerTypeOptions = [
  { text: '不在保客户', value: InsuranceCustomerType.NOT_INSURED_CUSTOMER },
  { text: '续转续客户', value: InsuranceCustomerType.RENEWAL_TO_RENEWAL_CUSTOMER },
  { text: '在修不在保客户', value: InsuranceCustomerType.IN_REPAIR_NOT_INSURED_CUSTOMER },
  { text: '新转续客户', value: InsuranceCustomerType.NEW_TO_RENEWAL_CUSTOMER },
  { text: '其他客户', value: InsuranceCustomerType.OTHER_CUSTOMER },
];

export const insuranceIssueTypeSelectOptions = [
  { text: '承保登记', value: 'UNDERWRITING_REGISTRATION' },
  { text: '经销商上报', value: 'DEALER_REPORT' },
];

export const SELECT_ALL_STATE_IS_TRUE_FOR_LABOR = {
  laborOptionCode: 'SELECT_ALL',
  laborOptionDescription: '全部',
  selected: true,
  isSelectAllOption: true,
};

export enum ExportInsuranceStatuses {
  PROCESSING = '处理中',
  SUCCESS = '成功',
  FAILURE = '失败',
}

export const getCustomerListConditions = (isInExport = true) => [
  { text: '车牌号', value: 'carPlateNumber', format: (item) => _.get(item, 'carPlateNumber') },
  { text: '车辆识别号（VIN）', value: 'vin', format: (item) => _.get(item, 'vin') },
  { text: '客户名称', value: 'contactName', format: (item) => _.get(item, 'contactName') },
  { text: '客户范围', value: 'customerType', format: (item) => formatCustomerType(_.get(item, 'customerType')) },
  { text: '客户类型', value: 'insuranceCustomerTypes',
    format: (item) => formatTextValueString(item, 'insuranceCustomerTypes', renewalCustomerTypeOptions) },
  { text: '客户来源', value: 'customerSource', format: (item) => formatEnumString(item, 'customerSource', CUSTOMER_SOURCE_TYPE) },
  { text: '保险到期天数', value: 'dueDays', format: (item) => !_.isNil(_.get(item, 'daysToInsuranceExpiryMin')) ? `${_.get(item, 'daysToInsuranceExpiryMin')}-${_.get(item, 'daysToInsuranceExpiryMax')}` : '全部' },
  { text: '今日任务', value: 'todayTask', format: (item) => formatTextValueString(item, 'todayTask', TODAY_TASKS) },
  {
    text: '商业险到期日期起始日期',
    value: 'cipExpiryDateStart',
    format: (item) => formatDateString(_.get(item, 'cipExpiryDateStart'), isInExport),
  },
  {
    text: '商业险到期日期结束日期',
    value: 'cipExpiryDateEnd',
    format: (item) => formatDateString(_.get(item, 'cipExpiryDateEnd'), isInExport),
  },
  {
    text: '交强险到期日期起始日期',
    value: 'salipExpiryDateStart',
    format: (item) => formatDateString(_.get(item, 'salipExpiryDateStart'), isInExport),
  },
  {
    text: '交强险到期日期结束日期',
    value: 'salipExpiryDateEnd',
    format: (item) => formatDateString(_.get(item, 'salipExpiryDateEnd'), isInExport),
  },
  {
    text: '首登日期起始日期',
    value: 'firstRegistrationDateStart',
    format: (item) => formatDateString(_.get(item, 'firstRegistrationDateStart'), isInExport),
  },
  {
    text: '首登日期结束日期',
    value: 'firstRegistrationDateEnd',
    format: (item) => formatDateString(_.get(item, 'firstRegistrationDateEnd'), isInExport),
  },
  {
    text: '最近跟进时间起始日期',
    value: 'lastFollowUpDateStart',
    format: (item) => formatDateString(_.get(item, 'lastFollowUpDateStart'), isInExport),
  },
  {
    text: '最近跟进时间结束日期',
    value: 'lastFollowUpDateEnd',
    format: (item) => formatDateString(_.get(item, 'lastFollowUpDateEnd'), isInExport),
  },
  {
    text: '最近进店日期起始日期',
    value: 'lastArrivedDateStart',
    format: (item) => formatDateString(_.get(item, 'lastArrivedDateStart'), isInExport),
  },
  {
    text: '最近进店日期结束日期',
    value: 'lastArrivedDateEnd',
    format: (item) => formatDateString(_.get(item, 'lastArrivedDateEnd'), isInExport),
  },
  { text: '客户证件类型', value: 'identityType', format: (item) => formatEnumString(item, 'identityType', IDENTIFY_TYPE) },
  { text: '客户是否有微信', value: 'isWechat', format: (item) => formatBooleanValueWithAll(_.get(item, 'isWechat')) },
  { text: '是否为无效数据', value: 'isAvailable', format: (item) => formatBooleanValueWithAll(_.get(item, 'isAvailable')) },
  {
    text: '是否有销售保险支持',
    value: 'isSupport',
    format: (item) => formatBooleanValueWithAll(_.get(item, 'isSupport')),
  },
  { text: '是否有钣喷专享礼', value: 'isMb', format: (item) => formatBooleanValueWithAll(_.get(item, 'isMb')) },
  { text: '是否已分配', value: 'isAssign', format: (item) => formatBooleanValueWithAll(_.get(item, 'isAssign')) },
  {
    text: '任务状态',
    value: 'followUpStatus',
    format: (item) => formatEnumString(item, 'followUpStatus', followUpStatusWordsOfNewCarCustomer),
  },
  { text: '跟进人员', value: 'followUpStaffName', format: (item) => _.get(item, 'followUpStaffName') },
  { text: '跟进标签/战败原因', value: 'tagReason', format: (item) => formatTextValueString(item, 'tagReason', tagReasonOptions)  },
  {
    text: '保险公司',
    value: 'insuranceCompany',
    format: (item) => _.get(item, 'insuranceCompany') ? _.get(item, 'insuranceCompany').toString().split(',').join('，') : '全部',
  },
  { text: '登记类型', value: 'issueType', format: (item) => formatEnumString(item, 'issueType', insuranceIssueTypeWords) },
];

export const incomingListConditions = [
  { text: '车牌号', value: 'carPlateNumber', format: (item) => _.get(item, 'carPlateNumber') },
  { text: '车辆识别号（VIN）', value: 'vin', format: (item) => _.get(item, 'vin') },
  { text: '客户范围', value: 'customerType', format: (item) => formatCustomerType(_.get(item, 'customerType')) },
  { text: '客户类型', value: 'insuranceCustomerTypes',
    format: (item) => formatTextValueString(item, 'insuranceCustomerTypes', renewalCustomerTypeOptions) },
  { text: '客户来源', value: 'customerSources', format: (item) => formatTextValueString(item, 'customerSources', customerSourceOptions) },
  { text: '送修人名称', value: 'repairOwnerContactName', format: (item) => _.get(item, 'repairOwnerContactName') },
  {
    text: '商业险到期日期起始日期',
    value: 'commercialInsurancePolicyExpiryDateStart',
    format: (item) => formatDateString(_.get(item, 'commercialInsurancePolicyExpiryDateStart'), false),
  },
  {
    text: '商业险到期日期结束日期',
    value: 'commercialInsurancePolicyExpiryDateEnd',
    format: (item) => formatDateString(_.get(item, 'commercialInsurancePolicyExpiryDateEnd'), false),
  },
  {
    text: '交强险到期日期起始日期',
    value: 'statutoryAutomobileLiabilityInsurancePolicyExpiryDateStart',
    format: (item) => formatDateString(_.get(item, 'statutoryAutomobileLiabilityInsurancePolicyExpiryDateStart'), false),
  },
  {
    text: '交强险到期日期结束日期',
    value: 'statutoryAutomobileLiabilityInsurancePolicyExpiryDateEnd',
    format: (item) => formatDateString(_.get(item, 'statutoryAutomobileLiabilityInsurancePolicyExpiryDateEnd'), false),
  },
  {
    text: '进店时间起始日期',
    value: 'arrivedDateStart',
    format: (item) => formatDateString(_.get(item, 'arrivedDateStart'), false),
  },
  {
    text: '进店时间结束日期',
    value: 'arrivedDateEnd',
    format: (item) => formatDateString(_.get(item, 'arrivedDateEnd'), false),
  },
  { text: '服务顾问', value: 'serviceConsultantFullName', format: (item) => _.get(item, 'serviceConsultantFullName') },
  { text: '跟进人员', value: 'followUpStaffFullName', format: (item) => _.get(item, 'followUpStaffFullName') },
  { text: '跟进人员D8', value: 'followUpStaffUsername', format: (item) => _.get(item, 'followUpStaffUsername') },
  {
    text: '保险公司',
    value: 'insuranceCompany',
    format: (item) => _.get(item, 'insuranceCompany') ? _.get(item, 'insuranceCompany').toString().split(',').join('，') : '全部',
  },
  { text: '是否已分配', value: 'isAssign', format: (item) => formatBooleanValueWithAll(_.get(item, 'isAssign')) },
  {
    text: '任务状态',
    value: 'followUpStatus',
    format: (item) => formatEnumString(item, 'followUpStatus', followUpStatusWordsOfNewCarCustomer),
  },
  { text: '出单类型', value: 'insuranceIssueTypes',
    format: (item) => formatTextValueString(item, 'insuranceIssueTypes', insuranceIssueTypeSelectOptions) },
];

export const newCarCustomerListConditions = [
  { text: '车辆识别号（VIN）', value: 'vin', format: (item) => _.get(item, 'vin') },
  { text: '厂牌型号', value: 'description', format: (item) => _.get(item, 'description') },
  {
    text: '是否已新转续任务分配',
    value: 'isNewContinueAssign',
    format: (item) => formatBooleanValueWithAll(_.get(item, 'isNewContinueAssign')),
  },
  { text: '新转续跟进人员', value: 'followUpStaffFullName', format: (item) => _.get(item, 'followUpStaffFullName') },
  {
    text: '新转续跟进人员D8账号',
    value: 'followUpStaffUsername',
    format: (item) => _.get(item, 'followUpStaffUsername'),
  },
  {
    text: '新车开票人证件类型',
    value: 'identityTypeList',
    format: (item) => formatEnumString(item, 'identityTypeList', IDENTIFY_TYPE),
  },
  {
    text: '新车开票人名称',
    value: 'newCarInvoiceUserFullName',
    format: (item) => _.get(item, 'newCarInvoiceUserFullName'),
  },
  {
    text: '创建时间起始日期',
    value: 'startCreateTime',
    format: (item) => formatDateString(_.get(item, 'startCreateTime'), false),
  },
  {
    text: '创建时间结束日期',
    value: 'endCreateTime',
    format: (item) => formatDateString(_.get(item, 'endCreateTime'), false),
  },
  { text: '销售顾问', value: 'salesConsultantFullName', format: (item) => _.get(item, 'salesConsultantFullName') },
  {
    text: '任务状态',
    value: 'followUpStatus',
    format: (item) => formatEnumString(item, 'followUpStatus', followUpStatusWordsOfNewCarCustomer),
  },
  {
    text: '出单类型',
    value: 'insuranceIssueType',
    format: (item) => formatTextValueString(item, 'insuranceIssueType', issueTypeOptions),
  },
  { text: '报价状态', value: 'isQuoted', format: (item) => _.get(item, 'isQuoted') },
];

export const insurancePolicyListConditions = [
  [
    { text: '车牌号', value: 'carPlateNumber', format: (item) => _.get(item, 'carPlateNumber') },
    { text: '车辆识别号（VIN）', value: 'vin', format: (item) => _.get(item, 'vin') },
    {
      text: '险种类型',
      value: 'insuranceType',
      format: (item) => formatTextValueString(item, 'insuranceType', insuranceTypeOptions),
    },
  ],
  [
    {
      text: '商业险保险公司',
      value: 'commercialInsuranceCompany',
      format: (item) => _.get(item, 'commercialInsuranceCompany')
        ? _.get(item, 'commercialInsuranceCompany').toString().split(',').join('，') : '全部',
    },
    {
      text: '商业险编号',
      value: 'commercialInsuranceNumber',
      format: (item) => _.get(item, 'commercialInsuranceNumber'),
    },
    {
      text: '商业险签单日期起始日期',
      value: 'commercialInsurancePolicySigningDateStart',
      format: (item) => formatDateString(_.get(item, 'commercialInsurancePolicySigningDateStart'), true),
    },
    {
      text: '商业险签单日期结束日期',
      value: 'commercialInsurancePolicySigningDateEnd',
      format: (item) => formatDateString(_.get(item, 'commercialInsurancePolicySigningDateEnd'), true),
    },
    {
      text: '商业险生效日期起始日期',
      value: 'commercialInsurancePolicyEffectiveDateStart',
      format: (item) => formatDateString(_.get(item, 'commercialInsurancePolicyEffectiveDateStart'), true),
    },
    {
      text: '商业险生效日期结束日期',
      value: 'commercialInsurancePolicyEffectiveDateEnd',
      format: (item) => formatDateString(_.get(item, 'commercialInsurancePolicyEffectiveDateEnd'), true),
    },
    {
      text: '商业险登记类型',
      value: 'commercialInsuranceIssueType',
      format: (item) => formatTextValueString(item, 'commercialInsuranceIssueType', issueTypeOptions),
    },
  ],
  [
    {
      text: '交强险保险公司',
      value: 'statutoryAutomobileLiabilityInsuranceCompany',
      format: (item) => _.get(item, 'statutoryAutomobileLiabilityInsuranceCompany')
        ? _.get(item, 'statutoryAutomobileLiabilityInsuranceCompany').toString().split(',').join('，') : '全部',
    },
    {
      text: '交强险编号',
      value: 'statutoryAutomobileLiabilityInsuranceNumber',
      format: (item) => _.get(item, 'statutoryAutomobileLiabilityInsuranceNumber'),
    },
    {
      text: '交强险签单日期起始日期',
      value: 'statutoryAutomobileLiabilityInsurancePolicySigningDateStart',
      format: (item) => formatDateString(_.get(item, 'statutoryAutomobileLiabilityInsurancePolicySigningDateStart'), true),
    },
    {
      text: '交强险签单日期结束日期',
      value: 'statutoryAutomobileLiabilityInsurancePolicySigningDateEnd',
      format: (item) => formatDateString(_.get(item, 'statutoryAutomobileLiabilityInsurancePolicySigningDateEnd'), true),
    },
    {
      text: '交强险生效日期起始日期',
      value: 'statutoryAutomobileLiabilityInsurancePolicyEffectiveDateStart',
      format: (item) => formatDateString(_.get(item, 'statutoryAutomobileLiabilityInsurancePolicyEffectiveDateStart'), true),
    },
    {
      text: '交强险生效日期结束日期',
      value: 'statutoryAutomobileLiabilityInsurancePolicyEffectiveDateEnd',
      format: (item) => formatDateString(_.get(item, 'statutoryAutomobileLiabilityInsurancePolicyEffectiveDateEnd'), true),
    },
    {
      text: '交强险登记类型',
      value: 'statutoryAutomobileLiabilityInsurancePolicyIssueType',
      format: (item) => formatTextValueString(item, 'statutoryAutomobileLiabilityInsurancePolicyIssueType', issueTypeOptions),
    },
  ],
];

export const booleanOptions = [
  { text: '全部', value: ALL },
  { text: '是', value: true },
  { text: '否', value: false },
];

export const CUSTOMER_SOURCE_TYPE = {
  ARRIVED_CUSTOMER: '进店客户',
  DEALER_IMPORT: '经销商导入',
  MANUALLY_CREATE: '手动创建',
};

export const taskTypeOptions = [
  { text: '继续跟进', value: 'FOLLOWING_UP' },
  { text: '预约到店', value: 'APPOINTMENT' },
  { text: '战败', value: 'DEFEAT' },
];

export const insuranceTypeOptions = [
  { text: '商业险', value: 'COMMERCIAL_INSURANCE' },
  { text: '交强险', value: 'STATUTORY_AUTOMOBILE_LIABILITY_INSURANCE' },
  { text: '商业险+交强险', value: 'COMMERCIAL_AND_STATUTORY_AUTOMOBILE_LIABILITY_INSURANCE' },
];

export const insuranceTypeWords = {
  COMMERCIAL_INSURANCE: '商业险',
  STATUTORY_AUTOMOBILE_LIABILITY_INSURANCE: '交强险',
  COMMERCIAL_AND_STATUTORY_AUTOMOBILE_LIABILITY_INSURANCE: '商业险+交强险',
};

export enum insuranceTypeEnum {
  COMMERCIAL_INSURANCE = 'COMMERCIAL_INSURANCE',
  STATUTORY_AUTOMOBILE_LIABILITY_INSURANCE = 'STATUTORY_AUTOMOBILE_LIABILITY_INSURANCE',
  COMMERCIAL_AND_STATUTORY_AUTOMOBILE_LIABILITY_INSURANCE = 'COMMERCIAL_AND_STATUTORY_AUTOMOBILE_LIABILITY_INSURANCE',
}

export const contactTypeOptions = [
  { text: '电话', value: 'PHONE' },
  { text: '微信/企业微信', value: 'WECHAT_ENTERPRISE_WECHAT' },
  { text: '短信', value: 'MESSAGE' },
  { text: '现场', value: 'ON_THE_SCENE' },
];

export const  contactTypeWords = {
  PHONE: '电话',
  WECHAT_ENTERPRISE_WECHAT: '微信/企业微信',
  MESSAGE: '短信',
  ON_THE_SCENE: '现场',
  OTHER: '其他',
};

export const DEFAULT_PAGE_SIZE = 20;

export const PAGE_SIZES = [
  {
    label: '20条/页',
    value: 20,
  },
  {
    label: '50条/页',
    value: 50,
  },
  {
    label: '100条/页',
    value: 100,
  },
];

export enum CommercialInsuranceStatusKey {
  SUCCESSFUL_ORDER = 'SUCCESSFUL_ORDER',
  DEFEATED = 'DEFEATED',
}

export const  commercialInsuranceStatus = {
  [CommercialInsuranceStatusKey.SUCCESSFUL_ORDER]: '成功出单',
  [CommercialInsuranceStatusKey.DEFEATED]: '战败',
};

export enum FollowUpStatusOfNewCarCustomerKey {
  INITIALIZATION = 'INITIALIZATION',
  WAIT_FOLLOW_UP = 'WAITING_FOR_FOLLOW_UP',
  CONTINUE_FOLLOW_UP = 'FOLLOWING_UP',
  RESERVATION_IN_STORE = 'APPOINTMENT',
  SUCCESS_ISSUE = 'SUCCESS',
  DEFEAT = 'DEFEAT',
}

export const  followUpStatusWordsOfNewCarCustomer = {
  [FollowUpStatusOfNewCarCustomerKey.INITIALIZATION]: '初始化',
  [FollowUpStatusOfNewCarCustomerKey.WAIT_FOLLOW_UP]: '待跟进',
  [FollowUpStatusOfNewCarCustomerKey.CONTINUE_FOLLOW_UP]: '继续跟进',
  [FollowUpStatusOfNewCarCustomerKey.RESERVATION_IN_STORE]: '预约到店',
  [FollowUpStatusOfNewCarCustomerKey.SUCCESS_ISSUE]: '成功出单',
  [FollowUpStatusOfNewCarCustomerKey.DEFEAT]: '战败',
};

export const followUpStatusOptions = [
  { text: '初始化', value: FollowUpStatusOfNewCarCustomerKey.INITIALIZATION },
  { text: '待跟进', value: FollowUpStatusOfNewCarCustomerKey.WAIT_FOLLOW_UP },
  { text: '继续跟进', value: FollowUpStatusOfNewCarCustomerKey.CONTINUE_FOLLOW_UP },
  { text: '预约到店', value: FollowUpStatusOfNewCarCustomerKey.RESERVATION_IN_STORE },
  { text: '成功出单', value: FollowUpStatusOfNewCarCustomerKey.SUCCESS_ISSUE },
  { text: '战败', value: FollowUpStatusOfNewCarCustomerKey.DEFEAT },
];

export const followUpStatuses: { [key: string]: FollowUpStatus } = {
  [FollowUpStatusOfNewCarCustomerKey.INITIALIZATION]: { value: '初始化', className: 'disabled' },
  [FollowUpStatusOfNewCarCustomerKey.WAIT_FOLLOW_UP]: { value: '待跟进', className: 'warring' },
  [FollowUpStatusOfNewCarCustomerKey.CONTINUE_FOLLOW_UP]: { value: '继续跟进', className: 'info' },
  [FollowUpStatusOfNewCarCustomerKey.RESERVATION_IN_STORE]: { value: '预约到店', className: 'info' },
  [FollowUpStatusOfNewCarCustomerKey.SUCCESS_ISSUE]: { value: '成功出单', className: 'success' },
  [FollowUpStatusOfNewCarCustomerKey.DEFEAT]: { value: '战败', className: 'error' },
};

export enum RenewalCustomerType {
  NEW_TO_RENEWAL_CUSTOMER = 'NEW_TO_RENEWAL_CUSTOMER',
  RENEWAL_TO_RENEWAL_CUSTOMER = 'RENEWAL_TO_RENEWAL_CUSTOMER',
  IN_REPAIR_NOT_INSURED_CUSTOMER = 'IN_REPAIR_NOT_INSURED_CUSTOMER',
  OTHER_CUSTOMER = 'OTHER_CUSTOMER',
}

export const renewalCustomerTypeOptions = [
  { text: '新转续', value: RenewalCustomerType.NEW_TO_RENEWAL_CUSTOMER },
  { text: '续转续', value: RenewalCustomerType.RENEWAL_TO_RENEWAL_CUSTOMER },
  { text: '在修不在保', value: RenewalCustomerType.IN_REPAIR_NOT_INSURED_CUSTOMER },
  { text: '其他客户', value: RenewalCustomerType.OTHER_CUSTOMER },
];

export enum TagReasonEnum {
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  NO_ANSWER_CALL_IN_PROGRESS_POWER_OFF = 'NO_ANSWER_CALL_IN_PROGRESS_POWER_OFF',
  CUSTOMER_INCONVENIENT = 'CUSTOMER_INCONVENIENT',
  CUSTOMER_UNDER_CONSIDERATION = 'CUSTOMER_UNDER_CONSIDERATION',
  QUOTATION_BEING_FOLLOWED_UP = 'QUOTATION_BEING_FOLLOWED_UP',
  ORDER_BOOKED = 'ORDER_BOOKED',
  HIGH_PRICE = 'HIGH_PRICE',
  LESS_VALUE_ADDED_SERVICES = 'LESS_VALUE_ADDED_SERVICES',
  POOR_CLAIMS_SERVICE = 'POOR_CLAIMS_SERVICE',
  LOCATION_FAR_FROM_CONVENIENT = 'LOCATION_FAR_FROM_CONVENIENT',
  VEHICLE_USED_IN_OTHER_PLACES = 'VEHICLE_USED_IN_OTHER_PLACES',
  FOREIGN_VEHICLE_OR_TRANSFER_VEHICLE = 'FOREIGN_VEHICLE_OR_TRANSFER_VEHICLE',
  INSURANCE_FOR_RELATIVES_AND_FRIENDS = 'INSURANCE_FOR_RELATIVES_AND_FRIENDS',
  INSURED_THROUGH_OTHER_CHANNELS = 'INSURED_THROUGH_OTHER_CHANNELS',
  INVALID_DATA = 'INVALID_DATA',
  CUSTOMER_OUT_OF_INSURANCE_AUTOMATICALLY_DEFEATED = 'CUSTOMER_OUT_OF_INSURANCE_AUTOMATICALLY_DEFEATED',
  OTHER = 'OTHER',
  FOLLOW_UP_TAG_OTHER = 'FOLLOW_UP_TAG_OTHER',
  DEFEAT_TAG_OTHER = 'DEFEAT_TAG_OTHER',
}

export const tagReasonByFollowUpOptions = [
  { text: '无效电话号码(停机、空号)', value: TagReasonEnum.INVALID_PHONE_NUMBER },
  { text: '电话无人接、通话中、关机', value: TagReasonEnum.NO_ANSWER_CALL_IN_PROGRESS_POWER_OFF },
  { text: '电话已接通，客户不方便', value: TagReasonEnum.CUSTOMER_INCONVENIENT },
  { text: '客户考虑中，后续再联系', value: TagReasonEnum.CUSTOMER_UNDER_CONSIDERATION },
  { text: '已报价跟进中', value: TagReasonEnum.QUOTATION_BEING_FOLLOWED_UP },
  { text: '已预约出单', value: TagReasonEnum.ORDER_BOOKED },
  { text: '跟进标签-其他', value: TagReasonEnum.FOLLOW_UP_TAG_OTHER },
];

export const tagReasonByFollowUpWords = {
  [TagReasonEnum.INVALID_PHONE_NUMBER]: '无效电话号码(停机、空号)',
  [TagReasonEnum.NO_ANSWER_CALL_IN_PROGRESS_POWER_OFF]: '电话无人接、通话中、关机',
  [TagReasonEnum.CUSTOMER_INCONVENIENT]: '电话已接通，客户不方便',
  [TagReasonEnum.CUSTOMER_UNDER_CONSIDERATION]: '客户考虑中，后续再联系',
  [TagReasonEnum.QUOTATION_BEING_FOLLOWED_UP]: '已报价跟进中',
  [TagReasonEnum.ORDER_BOOKED]: '已预约出单',
  [TagReasonEnum.OTHER]: '其他(跟进标签)',
};

export const tagReasonByFailOptions = [
  { text: '价格高', value: TagReasonEnum.HIGH_PRICE },
  { text: '增值服务少', value: TagReasonEnum.LESS_VALUE_ADDED_SERVICES },
  { text: '理赔服务差', value: TagReasonEnum.POOR_CLAIMS_SERVICE },
  { text: '位置远不方便', value: TagReasonEnum.LOCATION_FAR_FROM_CONVENIENT },
  { text: '车在外地使用', value: TagReasonEnum.VEHICLE_USED_IN_OTHER_PLACES },
  { text: '外地车或过户车', value: TagReasonEnum.FOREIGN_VEHICLE_OR_TRANSFER_VEHICLE },
  { text: '亲戚朋友关系投保', value: TagReasonEnum.INSURANCE_FOR_RELATIVES_AND_FRIENDS },
  { text: '已在其他渠道投保(网销、电销等)', value: TagReasonEnum.INSURED_THROUGH_OTHER_CHANNELS },
  { text: '无效数据', value: TagReasonEnum.INVALID_DATA },
  { text: '客户已脱保自动战败', value: TagReasonEnum.CUSTOMER_OUT_OF_INSURANCE_AUTOMATICALLY_DEFEATED },
  { text: '战败原因-其他', value: TagReasonEnum.DEFEAT_TAG_OTHER },
];

export const tagReasonByFailForNewCarOptions = [
  { text: '价格高', value: TagReasonEnum.HIGH_PRICE },
  { text: '增值服务少', value: TagReasonEnum.LESS_VALUE_ADDED_SERVICES },
  { text: '亲戚朋友关系投保', value: TagReasonEnum.INSURANCE_FOR_RELATIVES_AND_FRIENDS },
  { text: '已在其他渠道投保(网销、电销等)', value: TagReasonEnum.INSURED_THROUGH_OTHER_CHANNELS },
];

export const tagReasonByFailWords = {
  [TagReasonEnum.HIGH_PRICE]: '价格高',
  [TagReasonEnum.LESS_VALUE_ADDED_SERVICES]: '增值服务少',
  [TagReasonEnum.POOR_CLAIMS_SERVICE]: '理赔服务差',
  [TagReasonEnum.LOCATION_FAR_FROM_CONVENIENT]: '位置远不方便',
  [TagReasonEnum.VEHICLE_USED_IN_OTHER_PLACES]: '车在外地使用',
  [TagReasonEnum.FOREIGN_VEHICLE_OR_TRANSFER_VEHICLE]: '外地车或过户车',
  [TagReasonEnum.INSURANCE_FOR_RELATIVES_AND_FRIENDS]: '亲戚朋友关系投保',
  [TagReasonEnum.INSURED_THROUGH_OTHER_CHANNELS]: '已在其他渠道投保(网销、电销等)',
  [TagReasonEnum.INVALID_DATA]: '无效数据',
  [TagReasonEnum.CUSTOMER_OUT_OF_INSURANCE_AUTOMATICALLY_DEFEATED]: '客户已脱保自动战败',
  [TagReasonEnum.OTHER]: '其他(战败原因)',
};

export const tagReasonWords = {
  ...tagReasonByFollowUpWords,
  ...tagReasonByFailWords,
};

export const tagReasonOptions = [...tagReasonByFollowUpOptions, ...tagReasonByFailOptions];

export enum InsuranceIssueType {
  DEALER_REPORT = 'DEALER_REPORT',
  SYSTEM_ISSUE = 'SYSTEM_ISSUE',
  UNDERWRITING_REGISTRATION = 'UNDERWRITING_REGISTRATION',
}

export enum FollowUpTypeEnum {
  SYSTEM_FOLLOW_UP = 'SYSTEM_FOLLOW_UP',
  MANUALLY_FOLLOW_UP = 'MANUALLY_FOLLOW_UP',
}

export const insuranceIssueTypeWords = {
  [InsuranceIssueType.DEALER_REPORT]: '经销商上报',
  [InsuranceIssueType.SYSTEM_ISSUE]: '系统出单',
  [InsuranceIssueType.UNDERWRITING_REGISTRATION]: '承保登记',
};

export const followUpTypeWords = {
  [FollowUpTypeEnum.SYSTEM_FOLLOW_UP]: '系统自动',
  [FollowUpTypeEnum.MANUALLY_FOLLOW_UP]: '人工跟进',
};

export enum customerResourceEnum {
  DEALER_IMPORT = 'DEALER_IMPORT',
  MANUALLY_CREATE = 'MANUALLY_CREATE',
  ARRIVED_CUSTOMER = 'ARRIVED_CUSTOMER',
  NEW_CAR_SALES = 'NEW_CAR_SALES',
}

export const customerResourceMapping = {
  [customerResourceEnum.DEALER_IMPORT]: '经销商导入',
  [customerResourceEnum.MANUALLY_CREATE]: '手动创建',
  [customerResourceEnum.ARRIVED_CUSTOMER]: '进店客户',
  [customerResourceEnum.NEW_CAR_SALES]: '新车销售',
};

export const issueTypeOptions = [
  { text: '承保登记', value: InsuranceIssueType.UNDERWRITING_REGISTRATION },
  { text: '经销商上报', value: InsuranceIssueType.DEALER_REPORT },
];

export const TODAY_TASKS = [
  {
    text: '全部',
    value: null
  },
  {
    text: '首日可续保客户',
    value: 'FIRST_DAY_RENEWABLE_CUSTOMER',
  },
  {
    text: '续保期三日内客户',
    value: 'THREE_DAY_RENEWABLE_CUSTOMER',
  },
  {
    text: '今日到期客户',
    value: 'DUE_DAY_CUSTOMER',
  },
  {
    text: '今日待跟进客户',
    value: 'TO_BE_FOLLOWED_CUSTOMER',
  },
  {
    text: '今日预约到店客户',
    value: 'APPOINT_TO_ENTER_CUSTOMER',
  },
  {
    text: '今日已跟进客户',
    value: 'ALREADY_FOLLOWED_CUSTOMER',
  },
];

export enum InsuranceCustomerQueryEnum {
  INSURANCE_CUSTOMER = 'INSURANCE_CUSTOMER',
  ARRIVED_CUSTOMER = 'ARRIVED_CUSTOMER',
  NEW_CAR_CUSTOMER = 'NEW_CAR_CUSTOMER'
}

export enum FileUploadType {
  E_INSURANCE_POLICY = 'E_INSURANCE_POLICY'
}

export enum ExportRecordType {
  CUSTOMER_BASE = 'CUSTOMER_BASE',
  INSURANCE_POLICY_RECORD = 'INSURANCE_POLICY_RECORD'
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

import { LEAD_CHANNELS } from '../constants/common';

@Pipe({ name: 'channel' })
export class LeadChannelPipe implements PipeTransform {
  transform(channelType: string): string {
    return _.chain(LEAD_CHANNELS)
      .find((channel: any) => channel.value === channelType)
      .get('text')
      .value();
  }
}

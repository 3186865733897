import { Injectable } from '@angular/core';
import { Body, GET, RebirthHttp, POST, PATCH, Path, DELETE, Query } from 'rebirth-http';
import { Observable } from 'rxjs';
import { FinancialBank } from '../models/financial-bank';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FinancialBankApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/financial-banks')
  getFinancialBanks(@Query('is_active') isActive: boolean): Observable<FinancialBank[]> {
    return null;
  }

  @POST('/api/financial-banks')
  createFinancialBank(@Body data): Observable<FinancialBank> {
    return null;
  }

  @PATCH('/api/financial-banks/:id')
  updateFinancialBank(@Path('id') id: number,
                      @Body data) {
    return null;
  }

  @DELETE('/api/financial-banks')
  deleteFinancialBanks(@Body ids: number[]): Observable<any> {
    return null;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-table-main-body',
  templateUrl: './table-main-body.component.html',
  styleUrls: ['./table-main-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableMainBodyComponent implements OnInit {
  @Input() visibleDataList;
  @Input() fixedColumns;
  @Input() rows;
  @Input() cacheStartIndex;

  ngOnInit() {
  }

  getRowClass(item: any): Object {
    const rowClassMap = _.isFunction(this.rows.getClass) ?
      this.rows.getClass(item) : this.rows.getClass;
    return rowClassMap || {};
  }

  renderTd(column, rowData, rowIndex) {
    if (column.render) {
      return _.isFunction(column.render) ? column.render(rowData, this.cacheStartIndex + rowIndex, column.key) : column.render;
    }
    return _.get(rowData, column.key);

  }
}

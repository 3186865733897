<div
  class="select-wrapper"
  dropdown
  [container]="dropDownContainer"
  [dropup]="isDropUp"
  [isDisabled]="disabled"
  [class.disabled]="disabled"
  [ngStyle]="style"
  (onShown)="handleFocus($event)"
  (onHidden)="hidePopup()"
>
  <button
    id="button-basic"
    dropdownToggle
    type="button"
    class="input-control input-control-middle"
    [class.disabled]="disabled"
    [class.error]="error"
    [class.gray]="isEmpty"
    [class.placeholder]="isPlaceHolderStyle"
  >
    {{ displayText }}
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"  [class.long-container]="isShowLongContainer">
    <li class="dropdown-item search-input" *ngIf="searchable">
      <input
        class="form-control"
        [formControl]="keyword"
        [placeholder]="keywordPlaceholder"
        (click)="preventEmitEvent($event)"
        (select)="preventEmitEvent($event)"
        (input)="handleFilter($event)"
      />
    </li>
    <li
      *ngFor="let option of filteredOptions"
      class="dropdown-item"
      (click)="handleClick(option.value)"
      [class.option-disabled]="isOptionDisabled(option.value)"
    >
      <div [innerHtml]="getOptionHtml(option)"></div>
    </li>
    <ng-container *ngIf="showNoData">
      <li class="text-muted" *ngIf="(!filteredOptions || filteredOptions.length == 0) && !isLoadDateError">{{emptyOptionText || '未找到匹配的结果'}}</li>
      <li class="text-muted" *ngIf="isLoadDateError">系统异常，未获取到数据</li>
    </ng-container>
  </ul>
</div>

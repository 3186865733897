import { GET, Path, RebirthHttp, Query, PATCH, Body, POST, Headers } from 'rebirth-http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  JobCard,
  LaborItem,
  LaborItemStatusOperateHistory,
  JobCardWithLaborItems,
  ReportCount,
  RepairSummary,
} from '../models/job-card';

@Injectable()
export class JobCardApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/job-cards/:jobCardId/labour-items')
  getJobCardLabourItems(@Path('jobCardId') id: number): Observable<Array<LaborItem>> {
    return null;
  }

  @GET('/api/sales-website/job-cards')
  getTechnicianAssignedJobCards(@Query('teamId') teamId: number,
                                @Query('technicianId') technicianId: number): Observable<Array<JobCard>> {
    return null;
  }

  @GET('/api/sales-website/job-cards/technician-clocked-in')
  getTechnicianClockedInJobCards(@Query('technicianId') technicianId: number): Observable<Array<JobCard>> {
    return null;
  }

  @GET('/api/sales-website/job-cards/assigned-to-technician')
  getMyJobCards(@Query('technicianId') technicianId: number): Observable<Array<JobCard>>  {
    return null;
  }

  @GET('/api/sales-website/job-cards/assigned-to-team')
  getMyTeamJobCards(@Query('technicianId') technicianId: number,
                    @Query('teamId') teamId: number): Observable<Array<JobCard>> {
    return null;
  }

  @GET('/api/sales-website/job-cards/technician-in-progress')
  getTechnicianInProgressJobCards(@Query('technicianId') technicianId: number): Observable<Array<JobCardWithLaborItems>> {
    return null;
  }

  @GET('/api/sales-website/job-cards/technicians-in-progress')
  getTechniciansInProgressJobCards(@Query('technicianIds') technicianIds: string): Observable<Array<JobCardWithLaborItems>> {
    return null;
  }

  @GET('/api/sales-website/job-cards')
  searchJobCards(@Query('keyword') keyword: string) {
    return null;
  }

  @GET('/api/sales-website/job-cards/:jobCardId/workshop-comment')
  getWorkshopComment(@Path('jobCardId') jobCardId: number): Observable<Record<string, string>> {
    return null;
  }

  @PATCH('/api/sales-website/job-cards/:jobCardId/labor-items/clock-on')
  clockOnLabourItems(@Path('jobCardId') id: number,
                     @Body updateStatus: Object): Observable<Array<LaborItem>> {
    return null;
  }

  @PATCH('/api/sales-website/job-cards/:jobCardId/labor-items/clock-off')
  clockOffLabourItems(@Path('jobCardId') id: number,
                      @Body updateStatus: Object): Observable<Array<LaborItem>> {
    return null;
  }

  @PATCH('/api/sales-website/job-cards/:jobCardId/labor-items/batch/clock-off')
  clockOffLabourItemsBatch(@Path('jobCardId') id: number,
                           @Body updateStatus: Object): Observable<Array<LaborItem>> {
    return null;
  }

  @POST('/api/sales-website/job-cards/:jobCardId/labor-items/clock-off/clock-on-idle')
  clockOffLabourItemsAndClockOnIdle(@Path('jobCardId') id: number,
                                    @Body updateStatusWithIdle: Object): Observable<void> {
    return null;
  }

  @POST('/api/sales-website/job-cards/:jobCardId/labor-items/batch/clock-off/clock-on-idle')
  clockOffLabourItemsAndClockOnIdleBatch(@Path('jobCardId') id: number,
                                         @Body updateStatusWithIdle: Object): Observable<void> {
    return null;
  }

  @GET('/api/sales-website/technicians/available')
  getAvailableTechnicians(@Query('currentJobCardId') currentJobCardId: number,
                          @Query('laborItemIds') laborItemIds: number[]): Observable<object[]> {
    return null;
  }

  @GET('/api/sales-website/job-cards/:currentJobCardId/labor-items/commonAssistTechnicians')
  getAvailableAssistTechnicians(@Path('currentJobCardId') currentJobCardId: number,
                                @Query('laborItemIds') laborItemIds: number[]): Observable<object[]> {
    return null;
  }

  @POST('/api/sales-website/job-cards/:jobCardId/labour-items/labour-item-status-histories/validation')
  verifyLabourItemsStatus(@Path('jobCardId') id: number,
                          @Body updateStatus: Object): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/job-cards/:jobCardId/labor-item-status-operate-histories')
  getJobCardLabourItemStatusOperateHistories(
    @Path('jobCardId') id: number): Observable<Array<LaborItemStatusOperateHistory>> {
    return null;
  }

  @POST('/api/sales-website/job-cards/:jobCardId/labour-items/labour-item-status-histories')
  supplementLabourItemStatusHistories(
    @Path('jobCardId') id: number, @Body updateStatusHistories: Object): Observable<void> {
    return null;
  }

  @PATCH('/api/sales-website/technician-idle-infos/:idleInfoId/clock-off/clock-on-labor-items')
  stopIdleAndClockOnLabour(@Path('idleInfoId') idleInfoId: number,
                           @Body body: any): Observable<Array<LaborItem>> {
    return null;
  }

  @PATCH('/api/sales-website/job-cards/:jobCardId/labor-items/clock-off/sign-out')
  clockOffLaborItemsAndSignOut(@Path('jobCardId') jobCardId: number,
                               @Body body: any): Observable<void> {
    return null;
  }

  @PATCH('/api/sales-website/job-cards/:jobCardId/labor-items/batch/clock-off/sign-out')
  clockOffLaborItemsAndSignOutBatch(@Path('jobCardId') jobCardId: number,
                                    @Body body: any): Observable<void> {
    return null;
  }

  @PATCH('/api/sales-website/job-cards/:jobCardId/labor-items/clock-off/clock-on-labor-items')
  clockOffLaborAndClockOnLabor(@Path('jobCardId') jobCardId: number,
                               @Body body: any): Observable<Array<LaborItem>> {
    return null;
  }

  @GET('/api/sales-website/job-cards/report/order-efficiency/count')
  getJobCardCount(@Query('startTime') startTime: string,
                  @Query('endTime') endTime: string): Observable<ReportCount> {
    return null;
  }

  @GET('/api/sales-website/job-cards/report/order-efficiency/download')
  @Headers({ isExportFile: true })
  downloadReport(@Query('startTime') startTime: string,
                 @Query('endTime') endTime: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/job-cards/report/technician-efficiency/count')
  getTechnicianCount(@Query('startTime') startTime: string,
                     @Query('endTime') endTime: string): Observable<ReportCount> {
    return null;
  }

  @GET('/api/sales-website/job-cards/report/technician-efficiency/download')
  @Headers({ isExportFile: true })
  downloadTechnicianEfficiencyReport(@Query('startTime') startTime: string,
                                     @Query('endTime') endTime: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/job-card-directions/:orderNumber')
  getRepairSummary(@Path('orderNumber') id: string): Observable<RepairSummary> {
    return null;
  }

  @POST('api/sales-website/job-card-directions')
  updateRepairSummary(@Body body: RepairSummary): Observable<any> {
    return null;
  }
}

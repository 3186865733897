import { Component } from '@angular/core';

@Component({
  selector: 'app-question-icon-tooltip',
  template: `
    <span class="icon-container">
      <i class="fa antd-question-circle-filled" aria-hidden="true">
        <span class="message">
        <ng-content></ng-content>
        </span>
      </i>
    </span>
  `,
  styles: [`
    :host {
      display: inline-block;
    }
    .message {
      width: 140px;
      background-color: #fff;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .23);
      border: solid 0.5px #ccc;
      display: none;
      position: absolute;
      z-index: 999;
      margin-left: 5px;
      font-size: 11px;
      color: #666;
      padding: 5px;
      margin-top: 14px;
      line-height: 14px;
    }

    .icon-container {
      color: #979797;
      cursor: pointer;
      margin-left: 4px;
      display: flex;
      align-items: center;
    }

    i {
      font-size: 14px;
    }

    .icon-container:hover .message {
      display: inline-block;
    }
  `],
})
export class QuestionIconTooltipComponent {
}

import { toEnum } from '../_common/utils/enum';

export const PLATE_NUMBER = 'plateNumber';
export const FIN = 'fin';
export const VIN = 'vin';
export const OIL_OR_ELECTRICITY = 'oilOrElectricity';
export const COMMISSION_DATE =  'commissionDate';
export const COMMISSION_MILEAGE =  'commissionMileage';
export const COMMENTS =  'comments';
export const BLOCK_START_DATE = 'blockStartDate';
export const BLOCK_END_DATE = 'blockEndDate';
export const TERMINATION_DATE = 'terminationDate';
export const TERMINATION_MILEAGE = 'terminationMileage';
export const LOCATION = 'location';

export const VEHICLE_FILTER_TYPE = toEnum({
  VIN: { text: 'VIN', value: 'vin' },
  FIN: { text: 'FIN', value: 'fin' },
  PLATE_NUMBER: { text: 'PLATE_NUMBER', value: 'plateNumber' },
});

export const COURTESY_CAR_ERROR_CODES = {
  COURTESY_CAR_IS_EXIST: {
    value: 'courtesy_car_is_exist', text: '该车辆已被创建为代步车，不可重复添加',
  },
  CAN_NOT_FOUND_VEHICLE: {
    value: 'can_not_found_vehicle', text: '该车辆不存在，请确认后重新输入！',
  },
};

export const COURTESY_CAR_ERROR_FILED_MAP = {
  [PLATE_NUMBER]: 'isShowInPlateNumber',
  [FIN]: 'isShowInFin',
  [VIN]: 'isShowInVin',
  [OIL_OR_ELECTRICITY]: 'isShowInOilOrElectricity',
  [COMMISSION_DATE]: 'isShowInCommissionDate',
  [COMMISSION_MILEAGE]: 'isShowInCommissionMileage',
  [TERMINATION_DATE]: 'isShowInTerminationDate',
};

import { Injectable } from '@angular/core';
import { GET, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceFeeApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/quotations/servicefee/template')
  getTemplate(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/quotations/servicefee/download')
  getExistingData(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/quotations/servicefee/update-time')
  getUpdateTime(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { MessageAction, ModalMessage, ModalMessageService } from '@otr/website-common';
import { MODAL_CONFIG } from '../../constants/common';
import { ErrorMessagesPipe } from '../../pipes/error-message.pipe';
import { filter } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';

const errorMessagePipe = new ErrorMessagesPipe();

@Component({
  selector: 'app-modal',
  templateUrl: './modal.template.html',
  styleUrls: ['./modal.style.scss'],
  providers: [ModalService],
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  properties: Object;

  @Input() modalConfig = Object.assign({ backdrop: true }, MODAL_CONFIG);
  @Input() modalTitle: string = 'BS Model';
  @Input() hideCloseButton: boolean = false;
  @Input() modalSubtitle: string = '';
  @Output() onCanceled = new EventEmitter();
  @Output() onHide = new EventEmitter();
  @ViewChild('bsModal') bsModal: ModalDirective;
  @Input() customStyle: any;
  @Input() bigTileFontSize: boolean = false;
  @Input() showMandatoryBadge: boolean = false;
  @Input() hideTitle: boolean = false;
  @Input() set showMask(isShow: boolean) {
    this.contentSink = isShow;
    if (this.backdrop) {
      this.backdrop.style.display = isShow ? 'none' : 'block';
    }
  }
  contentSink: boolean = false;
  backdrop: HTMLElement = null;

  constructor(private modalMessageService: ModalMessageService, private modalService: ModalService) {
    this.modalMessageService.messagesSubject
      .pipe(filter((messageAction: MessageAction) => messageAction === 'PUT'))
      .subscribe(() => {
        (this.bsModal as any)._element.nativeElement.scrollTop = 0;
      });
    this.modalService.hideCloseButtonStatus
      .subscribe(buttonStatus => this.hideCloseButton = buttonStatus);
  }

  get messages(): ModalMessage[] {
    return this.modalConfig && this.modalConfig.ignoreMessage ? null : this.modalMessageService.messages;
  }

  removeModalMessage(modalMessage) {
    this.modalMessageService.removeMessage(modalMessage);
  }

  ngAfterViewInit() {
    if (!this.modalConfig.backdrop) {
      this.appendBackdrop();
    }
    this.bsModal.show();
    this.modalMessageService.clearMessages();
  }

  cancel() {
    this.onCanceled.emit();
  }

  hideModal() {
    this.bsModal.hide();
    this.modalMessageService.clearMessages();
  }

  showModal() {
    this.bsModal.show();
    this.modalMessageService.clearMessages();
  }

  handleModalHide() {
    this.onHide.emit();
  }

  getDisplayMessage(modalMessage: ModalMessage) {
    if (modalMessage.isSuccess) {
      return modalMessage.message;
    }
    return errorMessagePipe.transform(modalMessage);
  }

  appendBackdrop(): void {
    this.backdrop = document.createElement('bs-modal-backdrop');
    this.backdrop.className = 'modal-backdrop fade in show';
    document.body.appendChild(this.backdrop);
  }

  removeBackdrop(): void {
    if (this.backdrop) {
      this.backdrop.remove();
    }
  }

  ngOnDestroy(): void {
    this.removeBackdrop();
  }
}

import { Injectable } from '@angular/core';
import { DownloadFileParams, InsuranceApi, RecordsQuery } from '../api/insurance';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { exportOnDifferentBrowsers } from '../../_common/utils/download';
import {
  AssignFollowUpUsersRequest,
  FollowUpUser, HistoryOrderListResponse,
  InsuranceCompanyDTO,
  InsuranceCustomerContactInfoDTO,
  InsuranceCustomerDetailPlateSprayDTO,
  InsuranceCustomerDTO,
  NewCarCustomerListRequest,
  NewCarCustomerListResponse,
  QueryDealerCustomersRequestDTO,
} from '../model/insuranceModel';
import { UploadFileService } from '../../_common/services/upload-file.service';
import { getErrorMessage } from '../../service-contract/coupon-sales-history/util/errorHandler';
import { getTableIndex } from '../utils/insurance';
import {
  contactTypeWords,
  FileType, FileUploadType, followUpStatusWordsOfNewCarCustomer,
  FollowUpTypeEnum,
  followUpTypeWords, insuranceIssueTypeWords,
  InsuranceRoles, insuranceTypeWords, tagReasonByFailWords, tagReasonByFollowUpWords, TagReasonEnum,
} from '../constant/insurance';
import { InsuranceCustomer, InsuranceCustomerPageResponse } from '../insurance-saas';
import { CurrentUserService, PromptBarService } from '@otr/website-common';
import { ERROR_MESSAGES } from '../../_common/constants/error-messages';
import { PageResponse } from '../../_common/models/page';
import { USER_ROLE } from '../../_common/constants/user-role.constant';
import * as _ from 'lodash';
import { formatDate } from '../../mot/utils';
import * as moment from 'moment';

export interface SelectionOption {
  text: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class InsuranceService {
  constructor(
    private insuranceApi: InsuranceApi,
    private uploadFileService: UploadFileService,
    private currentUserService: CurrentUserService,
    private promptBarService: PromptBarService,
  ) {
  }

  get isThirdParty(): boolean {
    return this.currentUserService.isRoleCode(USER_ROLE.OEM_ACCIDENT_3RD_PARTY.value);
  }

  getInsuranceRecords(page: number, pageSize: number, params: RecordsQuery): Observable<any> {
    const dataIndex = getTableIndex(page, pageSize);
    return this.insuranceApi.getInsuranceRecords(page - 1 < 0 ? 0 : page - 1, pageSize, params).pipe(
      switchMap((res) =>
        of({
          ...res,
          content: res.content.map((item, index) => ({
            ...item,
            rowIndex: index + dataIndex,
          })),
        }),
      ),
      catchError(({ message }) => throwError(message)),
    );
  }

  getOemOperationUsers(): Observable<SelectionOption[]> {
    return this.getOperationUsers([InsuranceRoles.OEM_ACCIDENT]);
  }

  getExportOperationUsers(): Observable<SelectionOption[]> {
    const dealerId = this.currentUserService.getBranchDealerIdOrDealerId();
    return this.getOperationUsers([InsuranceRoles.ASM, InsuranceRoles.IM], dealerId);
  }

  getOperationUsers(roles: string[], dealerId?: string): Observable<SelectionOption[]> {
    return this.insuranceApi.getOperationUsers(roles, dealerId).pipe(
      switchMap((users) =>
        of(
          users.map((user) => ({
            text: user.full_name,
            value: user.username,
          })),
        ),
      ),
    );
  }

  downloadImportRecordTemplate(fileName: string, templateType: string): Observable<any> {
    return this.insuranceApi.getImportRecordsDownloadTemplate(templateType).pipe(
      tap((res) => exportOnDifferentBrowsers(res, FileType.xlsx as unknown as string, fileName)),
      catchError(this.getDownloadError),
    );
  }

  downloadImportRecordFile(fileName: string, params: DownloadFileParams): Observable<any> {
    const fileSuffix = fileName.substr(fileName.lastIndexOf('.') + 1);
    return this.insuranceApi.downloadImportRecordFile(params).pipe(
      tap((res) => exportOnDifferentBrowsers(res, FileType[fileSuffix] as unknown as string, fileName)),
      catchError(this.getDownloadError),
    );
  }

  getDownloadError(err): Observable<string> {
    if (err.status === 0) {
      return throwError('系统异常，请重试');
    }
    const error = JSON.parse(decodeURIComponent(escape(String.fromCharCode.apply(null, new Uint8Array(err.error)))));
    return throwError(getErrorMessage(error));
  }

  getInsuranceCompany(): Observable<Array<InsuranceCompanyDTO>> {
    return this.insuranceApi.getInsuranceCompany();
  }

  getInsuranceCustomers(
    page: number,
    size: number,
    sort: string,
    queryDealerCustomersRequestDTO: QueryDealerCustomersRequestDTO,
  ): Observable<InsuranceCustomerPageResponse<InsuranceCustomer>> {
    return this.insuranceApi.getInsuranceCustomers(page, size, sort, queryDealerCustomersRequestDTO);
  }

  uploadInsuranceFile(uploadFile: any, type: string): Observable<any> {
    return this.uploadFileService.uploadExcelFile(`/api/insurance-sales/import?type=${type}`, { file: uploadFile }).pipe(
      catchError((err) => {
        if (err.status === 0) {
          return throwError(ERROR_MESSAGES.UPDATE_FAILED);
        }
        try {
          const error = JSON.parse(err.response);
          return throwError(getErrorMessage(error));
        } catch {
          return throwError(ERROR_MESSAGES.UPDATE_FAILED);
        }
      }),
    );
  }

  findCustomerByVin(vin: string): Observable<InsuranceCustomerDTO> {
    return this.insuranceApi.findCustomerByVin(vin);
  }

  createCustomerAndRecordConfirmation(insuranceCustomerDTO: InsuranceCustomerDTO): Observable<any> {
    return this.insuranceApi.createCustomerAndRecordConfirmation(insuranceCustomerDTO);
  }

  getPlateSpray(customerId: string): Observable<InsuranceCustomerDetailPlateSprayDTO> {
    return this.insuranceApi.getPlateSpray(customerId);
  }

  getCustomerBasicInfo(customerId: string): Observable<any> {
    return this.insuranceApi.getCustomerBasicInfo(customerId);
  }

  getSalesInsuranceSupports(customerId: string): Observable<Array<any>> {
    return this.insuranceApi.getSalesInsuranceSupports(customerId);
  }

  getCustomerContactInfo(customerId: string): Observable<InsuranceCustomerContactInfoDTO[]> {
    return this.insuranceApi.getCustomerContactInfo(customerId);
  }

  /**
   * 获取历史保单信息接口
   * @param customerId
   * @param page 由于需求的不确定性，后端以支持分页的形式实现接口，但现阶段实现不需要分页，故，暂时传递默认值
   * @param size 同上，且由于此业务需求客观上不会存在大量数据，因此默认值9999可以认为是安全的
   */
  getHistoryOrderList(customerId: string, page = 0, size = 9999): Observable<HistoryOrderListResponse> {
    return this.insuranceApi.getHistoryOrderList(page, size, customerId);
  }

  getVehicleUnderwriting(customerId: string): Observable<any> {
    return this.insuranceApi.getVehicleUnderwriting(customerId);
  }

  getPolicyDetail(policyId: string): Observable<any> {
    return this.insuranceApi.getPolicyDetail(policyId);
  }

  getVehicleInfo(customerId: string): Observable<any> {
    return this.insuranceApi.getVehicleInfo(customerId);
  }

  updateVehicleInfo(customerId: string, vehicleInfo): Observable<any> {
    return this.insuranceApi.updateVehicleInfo(customerId, vehicleInfo);
  }

  updateCustomerContactInfo(customerId: string, customerContactInfo: any): Observable<any> {
    return this.insuranceApi.updateCustomerContactInfo(customerId, customerContactInfo);
  }

  updateCustomerBasicInfo(customerId: string, basicInfo): Observable<any> {
    return this.insuranceApi.updateCustomerBasicInfo(customerId, basicInfo);
  }

  getIncomingCustomerList(page: number, pageSize: number, params: any): Observable<any> {
    return this.insuranceApi.getIncomingCustomerList(page - 1 < 0 ? 0 : page - 1, pageSize, params);
  }

  getExportRecords(page: number, size: number, params: any): Observable<any> {
    const dataIndex = getTableIndex(page, size);
    return this.insuranceApi.getExportRecords(page - 1 < 0 ? 0 : page - 1, size, params).pipe(
      switchMap((res) =>
        of({
          ...res,
          content: res.content.map((item, index) => ({
            ...item,
            rowIndex: index + dataIndex,
          })),
        }),
      ),
      catchError(({ message }) => throwError(message)),
    );
  }

  updateCustomerContacts(customerId: string, customerContacts: any): Observable<any> {
    return this.insuranceApi.updateCustomerContacts(customerId, customerContacts);
  }

  refreshCustomerList(vins): Observable<any> {
    return this.insuranceApi.refreshCustomerList(vins);
  }

  getCustomizeGridHeaders(): Observable<any> {
    return this.insuranceApi.getCustomizeGridHeaders();
  }

  updateCustomizeGridHeaders(headers: any): Observable<any> {
    return this.insuranceApi.updateCustomizeGridHeaders(headers);
  }

  downloadExportRecordsFile(params): Observable<any> {
    const fileSuffix = params.fileName.substr(params.fileName.lastIndexOf('.') + 1);
    return this.insuranceApi.downloadExportRecordsFile(params).pipe(
      tap((res) => {
        exportOnDifferentBrowsers(res,
          FileType[fileSuffix] as unknown as string,
          `${params.fileName}`,
        );
      }),
      catchError(this.getDownloadError),
    );
  }

  exportCustomers(queryParams: any): Observable<any> {
    return this.insuranceApi.exportCustomers(queryParams).pipe(catchError((err) => {
      if (err.status === 0) {
        return throwError(ERROR_MESSAGES.UPDATE_FAILED);
      }
      return throwError(getErrorMessage(err.error));
    }));
  }

  saveQuotationConfirmation(confirmationRequest: any): Observable<any> {
    return this.insuranceApi.saveQuotationConfirmation(confirmationRequest);
  }

  deleteCustomer(vins: string[]): Observable<any> {
    return this.insuranceApi.deleteCustomer(vins);
  }

  async getInformedWording(triggerFrom): Promise<any> {
    try {
      const result = await this.insuranceApi.getInformedWording().toPromise();
      return result[triggerFrom] || {};
    } catch (error) {
      this.promptBarService.show(ERROR_MESSAGES.UPDATE_FAILED);
      return null;
    }
  }

  getNewCarCustomerList(
    page: number,
    pageSize: number,
    params: NewCarCustomerListRequest,
  ): Observable<PageResponse<NewCarCustomerListResponse>> {
    return this.insuranceApi.getNewCarCustomerList(this.getPageNumberAdapter(page), pageSize, params);
  }

  getPageNumberAdapter(page: number): number {
    return page - 1 < 0 ? 0 : page - 1;
  }

  saveFollowUp(followUpRequest): Observable<any> {
    return this.insuranceApi.saveFollowUp(followUpRequest);
  }

  searchCustomerFollowUpWithInThreeMonths(customerId): Observable<any> {
    return this.insuranceApi.searchCustomerFollowUpWithInThreeMonths(customerId, 3);
  }

  isIMorASM(): boolean {
    return this.currentUserService.isRoleCodeIn([USER_ROLE.IM.value, USER_ROLE.ASM.value]);
  }

  getFollowUpUsers(): Observable<FollowUpUser[]> {
    return this.insuranceApi.getFollowUpUsers().pipe(
      switchMap(followUpUsers => {
        return of(followUpUsers.sort((a, b) =>
          _.get(a, 'fullName', '').localeCompare(_.get(b, 'fullName', ''))));
      }),
    );
  }

  getFollowUps(customerId: string, page: number): Observable<any> {
    return this.insuranceApi.getFollowUps(customerId, this.getPageNumberAdapter(page), { month_range: 24 }).pipe(
      switchMap(({ content, totalElements }) =>
        of({
          totalElements,
          content: _.map(content, (config, index) => {
            return {
              ...config,
              rowIndex: index + getTableIndex(page, 5),
              createdTime: formatDate(config.createdTime, 'YYYY-MM-DD HH:mm:ss'),
              followUpType: followUpTypeWords[config.followUpType],
              createdBy: config.followUpType === FollowUpTypeEnum.SYSTEM_FOLLOW_UP ? '系统' : `${config.createdByFullName || ''} ${config.createdBy || ''}`,
              followUpStaffFullName: `${config.followUpStaffFullName || ''} ${config.followUpStaffUserName || ''}`,
              followUpStatus: followUpStatusWordsOfNewCarCustomer[config.followUpStatus],
              contactInformation: 'OTHER' === config.contactInformation ?
                config.customizationContactInformation : contactTypeWords[config.contactInformation],
              tagReason: TagReasonEnum.FOLLOW_UP_TAG_OTHER === config.tagReason  || TagReasonEnum.DEFEAT_TAG_OTHER === config.tagReason ?
                config.customizationTagReason : { ...tagReasonByFollowUpWords, ...tagReasonByFailWords }[config.tagReason],
              followUpReservationInStoreDate: formatDate(config.followUpReservationInStoreDate, 'YYYY-MM-DD HH:mm:ss'),
              insuranceType: insuranceTypeWords[config.insuranceType],
              insuranceIssueType: insuranceIssueTypeWords[config.insuranceIssueType],
            };
          }),
        }),
      ),
    );
  }

  assignFollowUpUsers(params: AssignFollowUpUsersRequest): Observable<boolean> {
    return this.insuranceApi.assignFollowUpUsers(params);
  }

  saveInsurancePolicyRecords(policy): Observable<any> {
    return this.insuranceApi.saveInsurancePolicyRecords(policy);
  }

  updateInsurancePolicyRecords(policy): Observable<any> {
    return this.insuranceApi.updateInsurancePolicyRecords(policy);
  }

  getInsurancePolicyRecords(page, size, policy): Observable<any> {
    return this.insuranceApi.getInsurancePolicyRecords(this.getPageNumberAdapter(page), size, policy);
  }

  validateInsurancePolicyRecords(needDoubleCheck, policy, id?: string): Observable<any> {
    return this.insuranceApi.validateInsurancePolicyRecords(needDoubleCheck, policy, id);
  }

  downloadFilesByFileUploadType(fileUploadType: FileUploadType, displayName: string, fileName: string): Observable<any> {
    return this.insuranceApi.downloadFilesByFileUploadType(fileUploadType, fileName).pipe(
      tap(res => {
        exportOnDifferentBrowsers(res, '', displayName);
      }),
      catchError(this.getDownloadError),
    );
  }

  exportInsurancePolicyRecords(params: any): Observable<any> {
    return this.insuranceApi.exportInsurancePolicyRecords(params);
  }

  getFollowUpReport(isMonthTab, monthlyParams, dailyParams): Observable<any> {
    if (isMonthTab) {
      return this.insuranceApi.getMonthlyReport(monthlyParams);
    }
    return this.insuranceApi.getDailyReport(dailyParams);
  }

  downloadExportFollowUpReport(isMonthTab, monthlyParams, dailyParams): Observable<any> {
    if (isMonthTab) {
      return this.insuranceApi.downloadExportFollowUpReport({
        followUpReportType: 'FOLLOW_UP_REPORT_MONTHLY',
        followUpReportRequestDTO: monthlyParams,
      })
        .pipe(tap((res) => exportOnDifferentBrowsers(res, FileType.xlsx as unknown as string, `跟进报表-当月过程指标${moment().format('YYYYMMDDHHmmss')}.xlsx`)));
    }
    return this.insuranceApi.downloadExportFollowUpReport({
      followUpReportType: 'FOLLOW_UP_REPORT_DAILY',
      followUpReportRequestDTO: dailyParams,
    })
      .pipe(tap((res) => exportOnDifferentBrowsers(res, FileType.xlsx as unknown as string, `跟进报表-当日过程指标${moment().format('YYYYMMDDHHmmss')}.xlsx`)));
  }

  getAnnualStatisticIndicator(year, type, gsCode, dealerId?): Observable<any> {
    return this.insuranceApi.getAnnualStatisticIndicator(year, type, gsCode, dealerId);
  }

  getDealerName(): Observable<any> {
    return this.insuranceApi.getDealerName();
  }

  getDealerGroup(year: string): Observable<any> {
    return this.insuranceApi.getDealerGroup(year);
  }

  getDealerGroupFromThirdParty(dealer: string, year: string): Observable<any> {
    return this.insuranceApi.getDealerGroupFromThirdParty(dealer, year);
  }

  getFollowingYearRenewalReport(year: string): Observable<any> {
    if (this.isThirdParty) {
      return this.insuranceApi.getFollowingYearRenewalReportThirdParty(year);
    }
    return this.insuranceApi.getFollowingYearRenewalReport(year);
  }

  downloadFollowingYearRenewalReport(year: string): Observable<any> {
    const observable = this.isThirdParty ?
      this.insuranceApi.downloadFollowingYearRenewalReportThirdParty(year, true) :
      this.insuranceApi.downloadFollowingYearRenewalReport(year, true);
    return observable.pipe(tap((res) => exportOnDifferentBrowsers(res, FileType.xlsx as unknown as string, `次年续保支持达成表现报告_${year}_${moment().format('YYYYMMDDHHmmss')}.xlsx`)));
  }
}

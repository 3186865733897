import { ModalPosition, PositionedModal } from '../../../../_common/components/modal/services/position';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { dateParse } from '../../../../_common/utils/date-parse';
import { WEEKLY_PLAN_TITLE } from '../../../constants/sales-strategy';
import { isEmptyValue } from '../../../../_common/utils/common';

@Component({
  selector: 'app-sales-plan-detail-modal',
  templateUrl: './sales-plan-detail-modal.template.html',
  styleUrls: ['./sales-plan-detail-modal.style.scss'],
})
export class SalesPlanDetailModalComponent implements PositionedModal {
  @ViewChild('planDetailModal', { read: ElementRef }) planDetailModal: ElementRef;
  subject: Subject<any>;
  left: number = 0;
  top: number = 0;
  salesPlan;
  weeklyPlanTitle = WEEKLY_PLAN_TITLE;

  constructor() {
    this.subject = new Subject();
  }

  getClientHeight(): number {
    const modal = <HTMLElement>this.planDetailModal.nativeElement;
    const { height } = modal.getBoundingClientRect();
    return height;
  }

  getClientWidth(): number {
    const modal = <HTMLElement>this.planDetailModal.nativeElement;
    const { width } = modal.getBoundingClientRect();
    return width;
  }

  setProperties(options: any): void {
    this.salesPlan = options.plan;
  }

  setPosition(position: ModalPosition): void {
    this.left = position.left;
    this.top = position.top;
  }

  getSubject(): Subject<any> {
    return this.subject;
  }

  get planDateSpan() {
    const dateFormat = date => _.isDate(new Date(date)) ? dateParse(date, 'yyyy-MM-dd') : '';
    const { to_date, from_date } = this.salesPlan;
    const formattedFromDate = dateFormat(from_date);
    const formattedToDate = dateFormat(to_date);
    if (isEmptyValue(formattedFromDate) && isEmptyValue(formattedToDate)) {
      return '';
    }
    return `${formattedFromDate} - ${formattedToDate}`;
  }

}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { PartsImportApi } from '../api/parts-import';
import { GlobalDeltaDatetimeRequest } from '../interface/global-delta-date';

@Injectable()
export class PartsImportService {
  constructor(private partsImportApi: PartsImportApi) {}

  getConfig(): Observable<any> {
    return this.partsImportApi.getConfig();
  }

  getPreloadControl(): Observable<any> {
    return this.partsImportApi.getPreloadControl();
  }

  getDealers(): Observable<any> {
    return this.partsImportApi.getDealers();
  }

  executeIdRangeCalculation(): Observable<any> {
    return this.partsImportApi.executeIdRangeCalculation();
  }

  executeMigrateAll(onlyMigration: Boolean): Observable<any> {
    return this.partsImportApi.executeMigrateAll(onlyMigration);
  }

  executeValidateAll(): Observable<any> {
    return this.partsImportApi.executeValidateAll();
  }

  executeStopAll(): Observable<any> {
    return this.partsImportApi.executeStopAll();
  }

  executeMigrateByDealerAndModule(dealerId, module): Observable<any> {
    return this.partsImportApi.executeMigrateByDealerAndModule(dealerId, module);
  }

  executeValidateByDealerAndModule(dealerId, module): Observable<any> {
    return this.partsImportApi.executeValidateByDealerAndModule(dealerId, module);
  }

  executeToOtrByDealerAndModule(dealerId, module): Observable<any> {
    return this.partsImportApi.executeToOtrByDealerAndModule(dealerId, module);
  }

  executeViewLogByDealerAndModule(dealerId, module): Observable<any> {
    return this.partsImportApi.executeViewLogByDealerAndModule(dealerId, module);
  }

  executeResetByDealerAndModule(dealerId, module): Observable<any> {
    return this.partsImportApi.executeResetByDealerAndModule(dealerId, module);
  }

  executeStopByDealerAndModule(dealerId, module): Observable<any> {
    return this.partsImportApi.executeStopByDealerAndModule(dealerId, module);
  }

  executeUpdateOtrByDealerAndModule(dealerId, module): Observable<any> {
    return this.partsImportApi.executeUpdateOtrByDealerAndModule(dealerId, module);
  }

  executeResetAllPartsMovementByDealer(dealerId): Observable<any> {
    return this.partsImportApi.executeResetAllPartsMovementByDealer(dealerId);
  }

  migrateModuleListByDealerAndModule(dealerId?, module?, page?, size?): Observable<any> {
    return this.partsImportApi.migrateModuleListByDealerAndModule(dealerId, module, page, size);
  }

  updatePreloadControl(preloadMode): Observable<any> {
    return this.partsImportApi.updatePreloadControl(preloadMode);
  }

  getModules(): Observable<any> {
    return this.partsImportApi.getModules();
  }

  getActionStatus(): Observable<any> {
    return this.partsImportApi.getActionStatus();
  }

  getImportParamStatus(): Observable<any> {
    return this.partsImportApi.getImportParamStatus();
  }

  importDealerList(dealerIdList): Observable<any> {
    return this.partsImportApi.importDealerList(dealerIdList);
  }

  updateGoLiveDealerList(dealerIdList): Observable<any> {
    return this.partsImportApi.updateGoLiveDealerList(dealerIdList);
  }

  getGlobalDeltaDatetime(): Observable<any> {
    return this.partsImportApi.getGlobalDeltaDatetime();
  }

  updateGlobalDeltaDatetime(globalDeltaDatetimeRequest: GlobalDeltaDatetimeRequest): Observable<any> {
    return this.partsImportApi.updateGlobalDeltaDatetime(globalDeltaDatetimeRequest);
  }

  importDealerParameter(): Observable<any> {
    return this.partsImportApi.importDealerParameter();
  }

  getNoOfRunningJob(): Observable<any> {
    return this.partsImportApi.getNoOfRunningJob();
  }

  transformAction(action: string): string {
    switch (action) {
      case 'NO_ACTION':
        return 'No Action';
      case 'TO_STAG':
        return 'To Staging';
      case 'RESET':
        return 'Reset';
      case 'VALIDATE':
        return 'Validate';
      case 'UPATE_STAG':
        return 'Update Staging';
      case 'TO_OTR':
        return 'To Otr';
      case 'STOP':
        return 'Stop';
      case 'UPDATE_OTR':
        return 'Update Otr';
      default:
        return action;
    }
  }

  transformJobModule(jobModule: string): string {
    switch (jobModule) {
      case 'STOCK_CARD':
        return 'Stock Card';
      case 'PURCHASE_ORDER':
        return 'Purchase Order';
      case 'MULTIPLE_ADDRESS':
        return 'Multiple Address';
      case 'BIN_LOCATION':
        return 'Bin Location';
      case 'PARTS_MOVEMENT':
        return 'Parts Movement';
      case 'STOCK':
        return 'Stock';
      case 'MOVEMENT_HISTORY':
        return 'Parts Movement History';
      case 'MOVEMENT_PRELOAD':
        return 'Parts Movement Preload';
      case 'MOVEMENT_DELTA':
        return 'Parts Movement Delta';
      default:
        return jobModule;
    }
  }

  resetImportDealerParameterStatus(): Observable<any> {
    return this.partsImportApi.resetImportDealerParameterStatus();
  }

  executeViewReportByDealerAndAction(dealerId?, action?): Observable<any> {
    return this.partsImportApi.executeViewReportByDealerAndAction(dealerId, action);
  }

  executeAllToOtr(): Observable<any> {
    return this.partsImportApi.executeAllToOtr();
  }

  executeViewLogByDealerAndModuleAndLoglevel(dealerId, module, loglevel): Observable<any> {
    return this.partsImportApi.executeDownloadLogByDealerAndModuleAndLoglevel(dealerId, module, loglevel);
  }
}

<div [class.error]="errorMessage"
     [class.percentage]="isPercentage">
  <input *ngIf="!hideInput"
         #input [type]="type"
         [placeholder]="getPlaceholder()"
         [style.width.px]="width"
         class="input-control"
         [value]="value"
         (input)="onInput()"
         (focus)="onFocus()"
         [disabled]="disabled"
         (change)="onInputChange(input.value)"
         (blur)="onChange(input.value)">
  <div *ngIf="hideInput">{{value}}</div>
  <p class="error-message">{{errorMessage}}</p>
</div>

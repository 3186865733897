import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';
import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  templateUrl: './image.template.html',
  styleUrls: ['./image.style.scss'],
})
export class ImageRenderComponent implements ICellRendererAngularComp {
  params: any;
  cellRendererParams: any;
  url: string;
  fileName: string;
  alt: string = '';
  isVideo: boolean = false;
  handleClick: any = () => {};
  horizontalCenter: boolean = false;
  showDefaultCursor: boolean = false;

  @ViewChild('container') container: ElementRef;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellRendererParams = _.get(params.colDef, 'cellRendererParams', {});
    this.url = params.getValue();

    if (this.cellRendererParams.getAlt) {
      this.alt = this.cellRendererParams.getAlt(params);
    }
    if (this.cellRendererParams.getCustomUrl) {
      this.url = this.cellRendererParams.getCustomUrl(params);
    }
    if (this.cellRendererParams.checkIfVideo) {
      this.isVideo = this.cellRendererParams.checkIfVideo(params);
    }
    if (this.cellRendererParams.getFileName) {
      this.fileName = this.cellRendererParams.getFileName(params);
    }
    if (this.cellRendererParams.downloadMedia) {
      this.handleClick = () => this.cellRendererParams.downloadMedia(params);
    }
    if (this.cellRendererParams.horizontalCenter) {
      this.horizontalCenter = this.cellRendererParams.horizontalCenter(params);
    }
    if (this.cellRendererParams.showDefaultCursor) {
      this.showDefaultCursor = this.cellRendererParams.showDefaultCursor(params);
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}

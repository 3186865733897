<div class="image-holder" *ngIf="!data || unconfigable">
  <img alt="" src="../../../../../../assets/role-privilege/diagonal-line.png"/>
</div>
<div class="cell-container" *ngIf="data"
     [ngClass]="{'border-blue': shouldShowHoverStyle, 'cursor-pointer': !disabled && canEdit}"
     (click)="!disabled && onCellClick()"
     (mouseover)="!disabled && changeCellStyle($event)"
     (mouseout)="!disabled && changeCellStyle($event)"
>
  <img alt="" class="icon-button" *ngIf="checked" src="../../../../../../assets/role-privilege/selected.svg">
  <img alt="" class="icon-button" *ngIf="unchecked" src="../../../../../../assets/role-privilege/unselected.svg">
  <img alt="" class="icon-button" *ngIf="disabled" src="../../../../../../assets/role-privilege/select-disabled.svg">
</div>

<div bsModal #bsModal="bs-modal"
     [config]="modalConfig"
     class="modal fade confirm-modal"
     tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
     [ngClass]="cssClass"
     [ngStyle]="cssStyle">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" [ngClass]="headerTheme">
        <span class="modal-title">{{modalTitle}}</span>
        <button *ngIf="shouldShowCloseButton" type="button" class="btn-close" aria-label="Close" (click)="handleClose()">
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="message" [ngClass]="leftAlignBody ? 'custom-body': ''">{{message}}</p>
        <p *ngIf="errorMessage" class="error-message" [ngClass]="{'start-align':leftAlignBody, 'sub-message':!!message}">
          {{errorMessage}}
        </p>
        <div *ngIf="htmlTemplate" [innerHtml]="htmlTemplate" class="template-container"></div>
        <div>
          <button *ngIf="!shouldHideCancelButton" class="btn btn-reject btn-primary" (click)="handleCancel()">{{cancelButtonText}}</button>
          <button [disabled]="shouldConfirmButtonDisabled" class="btn btn-resolve" [ngClass]="theme ? 'btn-'+theme : 'btn-primary'" (click)="handleConfirm()">{{confirmButtonText}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div [title]="tooltip" *ngIf="display">
  <span [ngClass]="{'percentage-input':isPercentage}">
      <input class="form-control basic-input-editor"
             [ngClass]="{'input-error': errorMessage, 'basic-input-editor-width':questionMessage}"
             [placeholder]="placeholder"
             [(ngModel)]="value"
             (keydown)="onKeyDown($event)"
             [autofocus]="autofocus"
             [maxlength]="maxLength"
             (change)="onChange($event)"
             [disabled]="disabled">
    <span *ngIf="isPercentage" class="percentage">%</span>
  </span>
  <span class="question-circle" *ngIf="questionMessage">
    <i class="fa antd-question-circle-filled" aria-hidden="true">
      <span class="question-circle-message circle-massage">{{questionMessage}}</span>
    </i>
  </span>
  <div *ngIf="errorMessage" class="error-message">
    {{errorMessage}}
  </div>
</div>

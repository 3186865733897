<div class="approval-process">
  <div *ngIf="approvalUsers.length">
    <div class="header">
      {{order.current_approval_application | approvalText}}
       -
      {{order.current_approval_application.approval_status}}
    </div>
    <div class="body">
      <div class="approval-row" *ngFor="let history of approvalUsers">
        <div class="gray-line"></div>
        <div class="approval-content"> {{history}}</div>
      </div>
    </div>
  </div>
  <div *ngIf="!approvalUsers.length" class="no-approval">
    无后续审批流
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

export const ERROR_MESSAGES = {
  version_not_found: '页面信息已过期，请刷新重试',
  unknown_error: '未知错误',
  lead_duplicate: '重复线索',
  lead_division_duplicate: '重复线索，姓名和电话号码在同一品牌已存在',
  person_duplicate: '重复客户，姓名和电话号码已存在',
  customer_duplicate: '重复客户，姓名和电话号码已存在',
  customer_with_uncanceled_orders: '重复客户，姓名和电话号码已存在',
  duplicated_interested_vehicle: '重复添加意向车',
  reselect_non_match_interested_vehicle: '请重新选择匹配的意向车型',
  phone_number_not_allow_to_delete: '电话号码不能为空',
  cannot_merge_lead_with_two_unfinished_visit: '有未完成的接待，请结束之前的接待',
  duplicated_customer_can_not_reassign_current_sc: '重复客户，重新分配失败',
  invalid_file_type: '文件格式有误，请重试。',
  file_too_large: '文件过大，超过最大限制50M。',
  gateway_timeout: '请求超时，请重试',
  labor_item_supplement_overlap: '技师已开钟其它工项',
  idle_operation_conflict: '技师状态已改变，请刷新页面',
  sign_in_info_conflict: '技师上班时间要晚于上次下班时间',
  sign_out_info_conflict: '技师下班时间要早于下次上班时间',
  labor_item_supplement_conflict: '技师状态已改变，请刷新页面',
  last_sign_in_info_uncompleted: '请完善上一次上下班记录',
  sign_in_info_uncompleted: '请完善上下班时间',
  technician_not_sign_out: '当前技师尚未下班',
  sign_in_time_in_in_progress_labor: '技师在该时间有正在进行的工项',
  labor_conflict_after_sign_in: '技师上班后有已完成的工项',
  sign_out_time_in_in_progress_labor: '技师在该时间有正在进行的工项',
  labor_conflict_before_sign_out: '技师下班前有已完成的工项',
  illegal_labor_status_change: '工项状态已改变，请刷新重试',
};

interface Error {
  error_code?: string;
  message: string;
}

@Pipe({ name: 'errorMessage' })
export class ErrorMessagesPipe implements PipeTransform {
  transform(error: Error): string {
    const errorCode = error.error_code || '';
    return ERROR_MESSAGES[errorCode.toLowerCase()] || error.message || ERROR_MESSAGES.unknown_error;
  }
}

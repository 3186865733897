import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-process-bar',
  styleUrls: ['./process-bar.component.scss'],
  templateUrl: './process-bar.component.html',
})
export class ProcessBarComponent {
  @Input() threshold: number = 0;
  @Input() process: number = 0;
}

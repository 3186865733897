import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener, Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

import * as _ from 'lodash';

@Directive({
  selector: '[appScrollControl]',
})
export class ScrollControlDirective implements AfterViewInit, AfterViewChecked, OnInit, OnChanges {

  @Input('appScrollControl') elSelector: string;

  scrollTop: number = 0;
  scrollLeft: number = 0;
  elem: HTMLElement;
  private optimizedGetScrollPosition: () => void = _.debounce(this.getScrollPosition.bind(this), 200);
  private scrollToValue: { x: number; y: number; } = null;

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    if (_.isEmpty(this.elSelector)) {
      this.elem = this.element.nativeElement;
      this.listenScroll();
    }
  }

  listenScroll(): void {
    this.renderer.listen(this.elem, 'scroll', this.optimizedGetScrollPosition);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.optimizedGetScrollPosition();
  }

  ngAfterViewInit(): void {
    this.optimizedGetScrollPosition();
  }

  getScrollPosition(): void {
    if (_.isNil(this.elem)) {
      return;
    }

    this.scrollTop = this.elem.scrollTop;
    this.scrollLeft = this.elem.scrollLeft;
  }

  scrollTo(x: number, y: number): void {
    this.scrollToValue = { x, y };
    this.tryScroll();
  }

  tryScroll(): void {
    if (this.scrollToValue === null) {
      return;
    }
    const { x, y } = this.scrollToValue;
    if (this.elem.scrollLeft === x && this.elem.scrollTop === y) {
      this.scrollToValue = null;
      return;
    }

    this.elem.scrollTo(x, y);
  }

  tryGetElement(): void {
    if (!_.isNil(this.elem)) {
      return;
    }

    this.elem = this.element.nativeElement.querySelector(this.elSelector);
    if (!_.isNil(this.elem)) {
      this.listenScroll();
    }
  }

  ngAfterViewChecked(): void {
    this.tryScroll();
    this.tryGetElement();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '../models/lead';

@Pipe({ name: 'stayTime' })
export class StayTimePipe implements PipeTransform {
  transform(activity: Activity): number {
    const arrivalTime = +activity.arrival_time;
    const leaveTime = +activity.leave_time;
    return leaveTime - arrivalTime;
  }
}

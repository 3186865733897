<div class="filter-box">
  <div class="input-container">
    <input
      class="form-control range-form-control range-left"
      [formControl]="startInput"
      [placeholder]="placeholder"
    >
    <span class="range-line"></span>
    <input
      class="form-control range-form-control range-right"
      [formControl]="endInput"
      [placeholder]="placeholder"
    >
  </div>
  <span class="btn-cancel" (click)="onCancel()">取消</span>
  <button
    type="submit"
    class="btn-save range-btn"
    [disabled]="!isFilterValid()"
    (click)="onSubmit()"
  >
    确定
  </button>
</div>

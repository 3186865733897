import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/src/interfaces';
import { ICellRendererParams } from 'ag-grid';

@Component({
  selector: 'app-authorisation-cell',
  templateUrl: './authorisation-cell.template.html',
  styleUrls: ['./authorisation-cell.style.scss'],
})
export class AuthorisationCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  data: any;
  groupName: string;
  value: string;
  configLevel: string;
  isDealerAllowed: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = params.value;
    this.groupName = params.data.authorisationValue;
    this.configLevel = params.data.configLevel;
    this.value = this.params.colDef.field;
    this.isDealerAllowed = params.data.isDealerAllowed;
  }

  refresh(params: any): boolean {
    return false;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  get radioChecked() {
    return this.params.data.configLevel === this.value;
  }

  get checkboxChecked() {
    return this.isDealerAllowed;
  }

  onRadioClick() {
    if (!this.radioChecked) {
      this.params.data.configLevel = this.value;
      this.cellRendererParams.onCellClick(this.params.data, this.value);
    }
  }

  onCheckBoxClick() {
    this.params.data.isDealerAllowed = !this.checkboxChecked;
    this.cellRendererParams.onCellClick(this.params.data, this.value);
  }
}

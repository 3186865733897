import * as moment from 'moment';
import {
  FILTER_INPUT_TYPES,
  SA_NAME_WITH_ALL_OPTIONS,
  SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS, VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import { priceFormatter } from '../utils/utils';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';

export const CHECKEDIN_VEHICLE_STATISTICS_CONFIG = {
  name: 'checked-in-vehicle-statistics',
  enablePagination: true,
  exportedFileName: '进厂车辆统计.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '订单号',
      field: 'orderNumber',
      width: 160,
    }, {
      headerName: '订单类型',
      field: 'category',
      width: 100,
    }, {
      headerName: '服务人员',
      field: 'assaFullName',
      width: 150,
    }, {
      headerName: '牌照号',
      field: 'carPlateNumber',
      width: 120,
    }, {
      headerName: '底盘号',
      field: 'fin',
      width: 200,
    }, {
      headerName: '客户姓名',
      field: 'customerName',
      width: 150,
    }, {
      headerName: '电话号码',
      field: 'customerPhone',
      width: 150,
    }, {
      headerName: '进厂日期',
      field: 'checkedInDate',
      width: 120,
    }, {
      headerName: '出厂日期',
      field: 'checkedOutDate',
      width: 120,
    }, {
      headerName: '结账日期',
      field: 'invoicedDate',
      width: 120,
    },
    {
      headerName: '账单金额 (¥)',
      field: 'amountBeforeTax',
      width: 130,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedInDate',
      label: '登记进厂日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '服务人员',
      options: SA_NAME_WITH_ALL_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'serviceRecordCategory',
      label: '订单类型',
      options: SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS.values,
      defaultValue: SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS.get('ALL'),
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型:',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
  ],
};

import { Injectable } from '@angular/core';
import { LeadManagementSalesServiceContractApi } from '../api/sales-service-contract';
import { Observable } from 'rxjs';
import { PurchasedServiceContract } from '../model';
import { SpinnerService } from '@otr/website-common';
import {Body, POST, Query} from 'rebirth-http';

@Injectable()
export class PurchasedServiceContractService {
  constructor(private salesServiceContractApi: LeadManagementSalesServiceContractApi,
              private spinnerService: SpinnerService) {
  }

  getServiceContract(fin, dealerId: string): Observable<Array<PurchasedServiceContract>> {
    return this.salesServiceContractApi.getServiceContract(fin, dealerId).pipe(
      (this.spinnerService.loading()));
  }

  queryProductList(fin: string, vin: string, gsCode: string, threeGuaranteesDate: string, firstRegistrationDate: string,
                   currentMile: number, fetchFromLpo: boolean): Observable<any> {
    const allStaffProductRequest = {
      fin: fin,
      vin: vin,
      gsCode: gsCode,
      threeGuaranteesDate: threeGuaranteesDate ? threeGuaranteesDate : '',
      firstRegistrationDate: firstRegistrationDate ? firstRegistrationDate : '',
      currentMile: currentMile,
    };
    return this.salesServiceContractApi.queryProductList(allStaffProductRequest, fetchFromLpo, true, '');
  }
  queryProductListFromOrderOnCustomer(fin: string, vin: string, gsCode: string, threeGuaranteesDate: string, firstRegistrationDate: string,
                                      currentMile: number, fetchFromLpo: boolean, productType: string): Observable<any> {
    const allStaffProductRequest = {
      fin: fin,
      vin: vin,
      gsCode: gsCode,
      threeGuaranteesDate: threeGuaranteesDate ? threeGuaranteesDate : '',
      firstRegistrationDate: firstRegistrationDate ? firstRegistrationDate : '',
      currentMile: currentMile,
    };
    return this.salesServiceContractApi.queryProductList(allStaffProductRequest, fetchFromLpo, false, productType);
  }
  getTyre(fin: string, gsCode: string, loyaltyId: string, spec: string, brand: string): Observable<any> {
    return this.salesServiceContractApi.getTyre(fin, gsCode, spec, brand, loyaltyId).pipe(
      (this.spinnerService.loading()));
  }

  queryQRCode(allStaffQRCodeRequest: Object): Observable<any> {
    return this.salesServiceContractApi.queryQRCode(allStaffQRCodeRequest);
  }
}

import { Body, GET, PATCH, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Complainant } from '../models/complainant';
import { HistoryComplaint } from '../models/HistoryComplaint';

@Injectable()
export class ComplainantApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/complainants')
  searchComplainantsByKeyword(@Query('keyword') keyword: string): Observable<Array<Complainant>> {
    return null;
  }

  @GET(`/api/complainants/:id`)
  getComplainantById(@Path('id') id: string): Observable<Complainant> {
    return null;
  }

  @GET('/api/complainants/:id/complaints')
  getHistoryComplaintsByComplainantId(@Path('id') id: string): Observable<Array<HistoryComplaint>> {
    return null;
  }
  @GET('/api/complainants/:id/case')
  getHistoryCaseById(@Path('id') id: string): Observable<Array<HistoryComplaint>> {
    return null;
  }

  @POST('/api/complainants')
  createComplainant(@Body complainant: Complainant): Observable<Complainant> {
    return null;
  }

  @PATCH('/api/complainants/:id')
  updateComplainant(@Body complainant: Complainant, @Path('id') id: string): Observable<Complainant> {
    return null;
  }

  @GET('/api/complaints')
  getComplainsByNameOrMobile(
    @Query('complainantName') complainantName: string,
    @Query('mobile') mobile: string,
    @Query('dealerId') dealerId: string,
  ): Observable<Array<HistoryComplaint>> {
    return null;
  }
}

import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer } from '@angular/core';

import * as _ from 'lodash';

function getPosition(element) {
  let currentElement = element;
  const position = {
    left: currentElement.offsetLeft,
    top: currentElement.offsetTop,
  };
  while (currentElement.offsetParent) {
    currentElement = currentElement.offsetParent;
    position.left += currentElement.offsetLeft;
    position.top += currentElement.offsetTop;
  }
  return position;
}

// todo seems not needed @tianxi
@Directive({
  selector: '[appTableHeight]',
})
export class AutoHeightDirective implements AfterViewInit, OnInit, OnChanges {

  @Input() minHeight: number;
  @Input() footerHeight: number = 0;
  @Input() step: number = 1;

  private readonly elem: ElementRef;
  private optimizedCalculateHeight: Function = _.debounce(this.calculateHeight.bind(this), 200);

  constructor(public element: ElementRef, public renderer: Renderer) {
    this.elem = element.nativeElement;
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize')
  onResize(): void {
    this.optimizedCalculateHeight();
  }

  ngOnChanges(): void {
    this.optimizedCalculateHeight();
  }

  ngAfterViewInit(): void {
    this.optimizedCalculateHeight();
  }

  calculateHeight() {
    const position = getPosition(this.elem);
    const maxHeight = document.body.clientHeight - position.top - this.footerHeight - 1;
    const count = 3;
    const height = Math.max(
      Math.floor(maxHeight / this.step) * this.step,
      this.minHeight || this.step * count,
    );
    this.renderer.setElementStyle(
      this.elem, 'height', `${height}px`,
    );
    return height;
  }
}

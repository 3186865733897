import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  template: '<div #container [title]="content">{{ content }}</div>',
  styleUrls: ['./text-ellipsis.style.scss'],
})
export class TextEllipsisRenderComponent implements ICellRendererAngularComp {

  content: string;
  ellipsisAble: boolean = true;

  @ViewChild('container') container: ElementRef;

  @HostListener('mouseenter')
  mouseEnter() {
    if (!this.ellipsisAble) {
      return;
    }
    const element = this.container.nativeElement;
    if (element.offsetWidth > element.parentElement.offsetWidth) {
      this.container.nativeElement.setAttribute('title', this.content);
    }
  }

  agInit(params: ICellRendererParams): void {
    const getContent = _.get(params.colDef, 'cellRendererParams.getContent');
    this.content = _.isFunction(getContent) ? getContent(params) : (params.valueFormatted || params.value);
    this.ellipsisAble = _.get(params.colDef, 'cellRendererParams.ellipsisAble', true);
  }

  refresh(params: any): boolean {
    return false;
  }
}

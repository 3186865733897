import * as moment from 'moment';
import {
  DAILY_STATUS_OPTIONS,
  FILTER_INPUT_TYPES,
  SA_NAME_WITH_ALL_AGGREGATED_OPTIONS,
  SERVICE_RECORD_CATEGORY_WITH_ALL_AGGREGATED_OPTIONS, VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import { priceFormatter, rateFormatter } from '../utils/utils';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';

export const DAILY_ORDER_DETAIL_STATISTICS_CONFIG = {
  name: 'daily-order-detail-statistics',
  enablePagination: true,
  exportedFileName: `当日订单明细统计报表（实时）`,
  isRealTimeReport: true,
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '订单号',
      field: 'orderNumber',
      width: 180,
    },
    {
      headerName: '订单类型',
      field: 'serviceRecordCategory',
      width: 150,
    },
    {
      headerName: '创建日期',
      field: 'orderCreatedDate',
      width: 150,
    },
    {
      headerName: '底盘号',
      field: 'fin',
      width: 200,
    },
    {
      headerName: '车牌号',
      field: 'carPlateNumber',
      width: 150,
    },
    {
      headerName: 'Baumuster',
      field: 'baumuster',
      width: 150,
    },
    {
      headerName: '车型',
      field: 'carModel',
      width: 200,
    },
    {
      headerName: '首登日期',
      field: 'firstRegistrationDate',
      width: 150,
    },
    {
      headerName: '车龄',
      field: 'vehicleAge',
      width: 150,
    },
    {
      headerName: '服务人员',
      field: 'assaFullName',
      width: 150,
    },
    {
      headerName: '登记进厂日期',
      field: 'checkedInDate',
      width: 150,
    },
    {
      headerName: '完工日期',
      field: 'completedDate',
      width: 150,
    },
    {
      headerName: '结账日期',
      field: 'invoicedDate',
      width: 150,
    },
    {
      headerName: '登记出厂日期',
      field: 'checkedOutDate',
      width: 150,
    },
    {
      headerName: '服务类型',
      field: 'serviceTypes',
      width: 200,
    },
    {
      headerName: '客户类型编号',
      field: 'customerTypeNumber',
      width: 150,
    },
    {
      headerName: '账单编号',
      field: 'invoiceNumber',
      width: 150,
    },
    {
      headerName: '工时 (¥)',
      field: 'laborAmountBeforeTax',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '标准工时数',
      field: 'laborQuantity',
      width: 150,
    },
    {
      headerName: '配件 (¥)',
      field: 'partAmountBeforeTax',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '外加工 (¥)',
      field: 'outsourcedAmountBeforeTax',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '杂费 (¥)',
      field: 'sundryAmountBeforeTax',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: 'VAT (¥)',
      field: 'taxAmount',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '总计 (含税¥)',
      field: 'totalGrossPrice',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '配件成本 (¥)',
      field: 'partsCost',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '外加工成本 (¥)',
      field: 'outsourcedCost',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '工时利润 (¥)',
      field: 'laborProfit',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '配件利润 (¥)',
      field: 'partsProfit',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '平均折扣',
      field: 'averageDiscount',
      width: 150,
    },
    {
      headerName: '利润率',
      field: 'profitRate',
      width: 150,
      valueFormatter: rateFormatter,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'dailyStatus',
      label: '当日状态',
      options: DAILY_STATUS_OPTIONS.values,
      defaultValue: DAILY_STATUS_OPTIONS.get('DAILY_INVOICED'),
    },
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '服务人员',
      options: SA_NAME_WITH_ALL_AGGREGATED_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_AGGREGATED_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.TEXT,
      name: 'date',
      label: '查询日期',
      content: () => moment(new Date()).format('YYYY-MM-DD HH:mm'),
      tip: '*仅显示当日实时数据，如需其他信息，请前往其他报表',
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型 :',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'serviceRecordCategory',
      label: '订单类型',
      options: SERVICE_RECORD_CATEGORY_WITH_ALL_AGGREGATED_OPTIONS.values,
      defaultValue: SERVICE_RECORD_CATEGORY_WITH_ALL_AGGREGATED_OPTIONS.get('ALL'),
    },
  ],
};

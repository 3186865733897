<form (ngSubmit)="submit()" [formGroup]="form" novalidate>
  <div class="input-area">
    <p>
      <label for="verification-code">验证码</label>
    </p>
    <input id="verification-code" name="verification-code" type="text" formControlName="verification_code">
    <div class="resend">
      <span class="resend-link-disable" *ngIf="isSendingVerificationCode">发送中...</span>
      <span class="resend-link-disable" *ngIf="resendTimeout">{{resendTimeout}}秒</span>
      <span class="resend-link" *ngIf="!isSendingVerificationCode && !resendTimeout" (click)="sendVerificationCode()">重新发送</span>
    </div>
  </div>
  <button id="submit" class="btn btn-block"
          [class.btn-primary]="form.valid"
          [class.btn-default]="form.invalid"
          [disabled]="form.invalid || isSendingVerificationCode">完 成
  </button>
</form>

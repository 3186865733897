import { Component } from '@angular/core';
import { CHINESE_CALENDAR_CONFIG } from '../../../../constants/calendar';
import { BasicEditorComponent } from '../basic-editor.component';
import * as _ from 'lodash';
import { dateParse } from '../../../../utils/date-parse';

@Component({
  templateUrl: './date-calendar-editor.template.html',
  styleUrls: ['../editor.style.scss'],
})
export class DateCalendarEditorComponent extends BasicEditorComponent {
  public chineseCalendarConfig;
  public minDate: Date;
  public maxDate: Date;
  public isTimePicker: boolean = false;
  public placeholder: string = 'YYYY-MM-DD';
  public errorMessageStyle: any;

  agInit(params: any): void {
    super.agInit(params);
    this.errorMessageStyle = _.get(this.cellEditorParams, 'errorMessageStyles', {});
    if (_.get(this.cellEditorParams, 'isTimePicker', false)) {
      this.initTimePicker();
    } else {
      this.value = dateParse(this.params.value);
    }
    this.chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;
    this.initDateRange();
  }

  get isSelectDisabled() {
    return _.isFunction(this.cellEditorParams.disabled) && this.cellEditorParams.disabled(this.params);
  }

  onChange() {
    const data = this.getValue();
    this.updateSourceValue(data);
    this.onChangeHandler(data, this.params.rowIndex, this.params.column.getColId());
  }

  onUpdateEvent() {
    this.initDateRange();
  }

  private initDateRange() {
    this.minDate = this.getMinDate();
    this.maxDate = this.getMaxDate();
  }

  getMinDate() {
    return this.getRestrictDate('minDate');
  }

  getMaxDate() {
    return this.getRestrictDate('maxDate');
  }

  getRestrictDate(restrictName) {
    const dateGenerator = this.cellEditorParams[restrictName];
    const restrictDate = _.isFunction(dateGenerator) ? dateGenerator(this.params) : dateGenerator;
    return restrictDate ? new Date(restrictDate) : null;
  }

  private initTimePicker() {
    this.isTimePicker = true;
    this.value = new Date(this.params.value);
    this.placeholder = 'YYYY-MM-DD HH:mm';
  }
}

import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-label-text',
  templateUrl: './label-text.template.html',
  styleUrls: ['./label-text.style.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LabelTextComponent),
    },
  ],
})
export class LabelTextComponent implements ControlValueAccessor {
  @Input() label = '';
  @Input() text = '';
  @Input() textClass = '';
  @Input() isVertical: boolean = true;
  @Input() tooltipText = '';
  @Input() required = false;
  @ViewChild('input') input: ElementRef;
  @Input()
  className: string = '';

  private onChange = _.noop;
  private onFocus = _.noop;
  value: string = '';
  disabled: boolean = false;

  setValue(value) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (...args: any[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (...args: any[]) => void): void {
    this.onFocus = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: Object): void {
    this.input.nativeElement.value = obj;
    this.setValue(obj);
  }
}

import * as moment from 'moment';
import {
  FILTER_INPUT_TYPES,
  SA_NAME_WITH_ALL_OPTIONS,
  SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS, VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';

export const WARRANTY_ORDER_STATUS_QUERY_CONFIG = {
  name: 'warranty-order-status-query',
  enablePagination: true,
  exportedFileName: '保修订单状态查询.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '保修单号',
      field: 'claimNumber',
      width: 120,
    },
    {
      headerName: '订单号',
      field: 'orderNumber',
      width: 180,
    },
    {
      headerName: '订单类型',
      field: 'serviceRecordCategory',
      width: 120,
    },
    {
      headerName: '服务人员',
      field: 'assaFullName',
      width: 120,
    },
    {
      headerName: '牌照号',
      field: 'carPlateNumber',
      width: 120,
    },
    {
      headerName: '底盘号',
      field: 'fin',
      width: 200,
    },
    {
      headerName: '故障识别码',
      field: 'damageCode',
      width: 120,
    },
    {
      headerName: '客户类型编号',
      field: 'customerTypeNumber',
      width: 120,
    },
    {
      headerName: '保修状态',
      field: 'status',
      width: 120,
    },
    {
      headerName: '登记进厂日期',
      field: 'checkedInDate',
      width: 120,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedInDate',
      label: '登记进厂日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'fin',
      label: '底盘号',
      placeholder: '请输入底盘号后6位进行搜索',
    },
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '服务人员',
      options: SA_NAME_WITH_ALL_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'serviceRecordCategory',
      label: '订单类型',
      options: SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS.values,
      defaultValue: SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS.get('ALL'),
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型 :',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
  ],
};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const REGULAR_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_DAY_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_SECOND_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const EXPORT_FILE_TIME = 'yyyyMMdd';

export const BRAND_OPTIONS = [
  { value: 'AMG', code: 'AMG' },
  { value: '梅赛德斯-奔驰乘用车', code: 'MB-PKW' },
  { value: 'smart', code: 'SMART' },
];

export const ALL_BRAND_OPTIONS = [
  ...BRAND_OPTIONS,
  { value: '梅赛德斯-奔驰乘用车', code: 'MB' },
  { value: '腾势', code: 'DENZA', },
];

export const EXCEL_FILE_CONTENT_TYPE = 'application/vnd.ms-excel';
export const EXPORT_EXCEL_FOR_OEM_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const PDF_FILE_CONTENT_TYPE = 'application/pdf';
export const ZIP_FILE_CONTENT_TYPE = 'application/octet-stream';
export const TEXT_PLAIN = 'text/plain';
export const AUDIO_X_WAV = 'audio/x-wav';

export const QUOTATION_STATUS = {
  GENERATED_QUOTATION: '报价单已创建',
  READY_FOR_ACTIVATE: '等待激活',
  QUOTATION_CANCELLED: '已取消',
  WAIT_FOR_ACTIVATE_BY_MANUAL: '等待手动激活',
  PROPOSAL_CANCELED: '合同已拒绝',
  CONTRACT_SUSPENDED_INOPERATIVE: '合同已挂起',
};

export const USED_CARD_WARRANTY_PRODUCT_CODE = 'ADV_P_USED_C';

import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { GroupApi } from '../api/group';
import { GROUP_TYPE, HIERARCHY_RULES, MIND_MAP_FORMAT, MIND_MAP_OPTIONS } from '../constants/mind-map';
import { MindMapDataConverter } from '../utils/mind-map-data-converter';
import { MindMapCompareDeepUtil } from '../utils/mind-map-compare-deep';
import * as _ from 'lodash';
import { DEALER_GROUP } from '../constants/common';
import { CurrentUserService } from '@otr/website-common';

@Injectable()
export class GroupService extends MindMapCompareDeepUtil {
  groups;
  mindMapDataConvertor = new MindMapDataConverter(HIERARCHY_RULES, GROUP_TYPE, null, null);
  initialMindMapConfig = new Subject();
  groupsLastUpdateTime = new Subject();

  constructor(private currentUserService: CurrentUserService, private groupApi: GroupApi) {
    super();
  }

  getGroupTree(): Observable<any> {
    return this.groupApi.getGroupTree(this.currentUserService.getCurrentUser().dealerId).pipe(
      tap((response) => {
        this.groups = response;
        this.groupsLastUpdateTime.next(response.updatedAt);
      }),
    );
  }

  updateGroups(data): Observable<any> {
    const convertedData = this.mindMapDataConvertor.convertForBackendInterface(data);

    return this.groupApi.updateGroups(convertedData.id, convertedData);
  }

  getGroupsByDealerId(): Observable<any> {
    return this.groupApi.getGroupsByDealerId(this.currentUserService.getCurrentUser().dealerId);
  }

  initialMindMap(isDealerGroup): void {
    this.getGroupTree().subscribe((response) => {
      const mind = this.getMind(isDealerGroup, response);
      this.initialMindMapConfig.next({ options: MIND_MAP_OPTIONS, mind });
    });
  }

  getMind(isDealerGroup, groups?) {
    return {
      format: MIND_MAP_FORMAT,
      data: this.mindMapDataConvertor.convertForMindMap(groups || this.groups, isDealerGroup),
    };
  }

  getIsPristineData(groups) {
    const lastGroups = this.mindMapDataConvertor.convertForBackendInterface(groups);
    return this.equalDeep(lastGroups, this.groups);
  }

  updateLocalCacheGroups(groups) {
    this.groups = this.mindMapDataConvertor.convertForBackendInterface(groups);
  }

  formatGroupOptions(groups: Array<any>) {
    return _.reduce(
      groups.map((group) => group.name),
      (result, value) => {
        result.push({ text: value, value });
        return result;
      },
      [],
    );
  }

  formatGroupOptionsWithId(groups: Array<any>) {
    return _.chain(groups)
      .map((group) => ({ text: group.name, value: group.id }))
      .value();
  }

  formatDealerGroupOptions(groups: Array<any>) {
    return this.formatGroupOptions(groups.filter((group) => _.includes(DEALER_GROUP, group.type)));
  }

  formatGroupNameDealerIdMap(groups: Array<any>) {
    return _.reduce(
      groups,
      (result, group) => {
        result.push({ [group.name]: group.dealerId });
        return result;
      },
      [],
    );
  }

  formatGroupNameIdMap(groups: Array<any>) {
    return _.reduce(
      groups,
      (result, group) => {
        result.push({ text: group.name, value: group.id });
        return result;
      },
      [],
    );
  }

  getSalesTeamAndSC() {
    return this.groupApi.getSalesTeam();
  }

  getSalesTeamAndSCByDealerId(dealerId: string) {
    return this.groupApi.getSalesTeamByDealerId(dealerId);
  }

  getModelDSalesTeamByDealerId(dealerId: string) {
    return this.groupApi.getModelDSalesTeamByDealerId(dealerId);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'dateObject' })
export class DateObjectPipe implements PipeTransform {
  transform(input: any): Date {
    if (input instanceof Date) {
      return input;
    }
    return _.isEmpty(input) ? null : new Date(input);
  }
}

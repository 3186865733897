import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Body, GET, POST, Query, RebirthHttp, Headers, PATCH } from 'rebirth-http';
import { Observable } from 'rxjs';

import {
  AdvancedFilterParams,
  AssignCdoResponse,
  BatchAssignCdoParams,
  CdoInDealer,
  CdoInfo,
  CodeWithMessageResponse,
  ICustomerConfig,
  ICustomers,
  IQueryParams,
  ReportParams,
} from './customer-management.model';

@Injectable()
export class CustomerManagementApi extends RebirthHttp {

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/customer-info/config')
  getCustomerConfig(): Observable<ICustomerConfig> {
    return null;
  }

  @POST('/api/customer-info')
  getCustomers(@Body params: IQueryParams): Observable<ICustomers> {
    return null;
  }

  @POST('/api/customer-info/count')
  getCustomersInfoCount(@Body params: IQueryParams): Observable<ICustomers> {
    return null;
  }

  @POST('/api/customer-info/customer-info-filter/count')
  getCustomersByAppliedCriteriaAndDateCount(@Body advancedFilterParams: AdvancedFilterParams): Observable<any> {
    return null;
  }

  @GET('/api/users/cec-dealer-cdo')
  getAllCdoInDealers(@Query('cecId') cecId: number): Observable<Array<CdoInDealer>> {
    return null;
  }

  @GET('/api/sales-website/customer/cdo-info')
  getAssignCdoName(@Query('customerIds') customerIds: Array<string>): Observable<CodeWithMessageResponse> {
    return null;
  }

  @POST('/api/sales-website/customer/assign-cdo')
  batchAssignCdo(@Body params: BatchAssignCdoParams): Observable<AssignCdoResponse> {
    return null;
  }

  @POST('/api/customer-info/advanced-filter')
  getCustomersByAppliedCriteria(@Body advancedFilterParams: AdvancedFilterParams): Observable<any> {
    return null;
  }

  @POST('/api/customer-info/customer-info-filter')
  getCustomersByAppliedCriteriaAndDate(@Body advancedFilterParams: AdvancedFilterParams): Observable<any> {
    return null;
  }

  @POST('/api/customer-info/data-quality-filter')
  getCustomersDataQuality(@Body advancedFilterParams): Observable<any> {
    return null;
  }

  @GET('/api/users/dealer-ascdo')
  getDealerAscdos(): Observable<Array<CdoInfo>> {
    return null;
  }

  @POST('/api/customer-info/data-quality-export')
  @Headers({ isExportFile: true })
  public dataQualityExport(@Body params: any): Observable<any> {
    return null;
  }

  @POST('/api/customer-info/export-by-search')
  @Headers({ isExportFile: true })
  public export(@Body params: any): Observable<any> {
    return null;
  }

  @POST('/api/customer-info/data-export-report')
  getExportReportTableData(@Body params: ReportParams): Observable<any> {
    return null;
  }

  @POST('/api/customer-info/export-by-advanced-filter')
  @Headers({ isExportFile: true })
  public exportByAdvanced(@Body params: any): Observable<any> {
    return null;
  }

  @GET('/api/customer-tasks/users')
  getTaskAllPerson(): Observable<Array<any>> {
    return null;
  }

  @POST('/api/customer-tasks/after-sales')
  assignCustomerTask(@Body params: any): Observable<any> {
    return null;
  }

  @POST('/api/customer-tasks/v2/after-sales')
  createCustomerTask(@Body params: any): Observable<any> {
    return null;
  }

  @POST('/api/customer-info/data-quality-filter/count')
  getCustomerDataQualityCount(@Body params: any): Observable<any> {
    return null;
  }
}

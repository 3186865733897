import {
  DIGIT_NUMBER_RULE,
  NON_NUMERIC_RULE,
  NON_NUMERIC_WITH_POINT_RULE,
  NUMBER_WITH_POINT_RULE
} from '../constants/rules';
import * as _ from 'lodash';

export const formatNumberWithCommas = (number) => number.toString().replace(NON_NUMERIC_RULE, '').replace(DIGIT_NUMBER_RULE, ',');
export const removeNonNumericChar = (number) => _.replace(number, NON_NUMERIC_RULE, '');
export const removeCommas = (number) => number.toString().replace(NON_NUMERIC_WITH_POINT_RULE, '');
export const removeCommasWithHandleNull = (number) => _.isNil(number) ? NaN : number.toString().replace(NON_NUMERIC_WITH_POINT_RULE, '');
export const formatNumberWithDigits = (number, fractionDigits: number) => _.round(Number(number), fractionDigits).toFixed(fractionDigits);
export const formatCommasToNumber = (commasNumber: string) => commasNumber.replace(',', '');
export const formatPointNumberWithCommas = (number, fractionDigits) =>
  _.isNil(number) ? '' : _.round(number, fractionDigits).toFixed(fractionDigits).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

export const formatFloatingNumberWithCommas = (number) => {
  const splitNumArr = _.toString(number).split('.');
  const integerPart = splitNumArr[0] ? splitNumArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
  const decimalsPart = _.isUndefined(splitNumArr[1]) ? '' : `.${splitNumArr[1]}`;
  return `${integerPart}${decimalsPart}`;
};

export const validateNumberWithPoint = (value): boolean => new RegExp(NUMBER_WITH_POINT_RULE).test(value);

import { priceFormatter } from '../utils/utils';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import {
  FILTER_INPUT_TYPES,
  SA_NAME_WITH_ALL_AGGREGATED_OPTIONS,
  SERVICE_RECORD_CATEGORY_OPTIONS,
  VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import * as moment from 'moment';
import * as _ from 'lodash';
import { toEnum } from '../../_common/utils/enum';

const privatePriceFormatter = data => {
  if (data.value === '-') {
    return '-';
  }
  return priceFormatter(data);
};

export const TODAY_REVENUE_STATISTICS_CONFIG = {
  name: 'today-revenue-statistics',
  enablePagination: false,
  exportedFileName: `当日营业统计（实时）`,
  isRealTimeReport: true,
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  getRowClass: params => _.startsWith(params.data.saId, '总计') ? 'total-row' : '',
  column: [
    {
      headerName: '订单创建人',
      headerClass: 'center-box',
      children: [
        {
          headerName: 'D8账号',
          field: 'saId',
          width: 120,
        },
        {
          headerName: '姓名',
          field: 'saName',
          width: 100,
          headerClass: 'center-box header-border-right',
        },
      ],
    },
    {
      headerName: '进车统计',
      headerClass: 'center-box',
      children: [
        {
          headerName: '当日数量',
          field: 'dayCountOfCheckIn',
          width: 100,
        },
        {
          headerName: '本月数量',
          field: 'monthlyCountOfCheckIn',
          width: 100,
          headerClass: 'center-box header-border-right',
        },
      ],
    },
    {
      headerName: '完工统计',
      headerClass: 'center-box',
      children: [
        {
          headerName: '当日数量',
          field: 'dayCountOfCompleted',
          width: 100,
        },
        {
          headerName: '本月数量',
          field: 'monthlyCountOfCompleted',
          width: 100,
          headerClass: 'center-box header-border-right',
        },
      ],
    },
    {
      headerName: '结账统计',
      headerClass: 'center-box',
      children: [
        {
          headerName: '当日数量',
          field: 'dayCountOfInvoiced',
          width: 100,
        },
        {
          headerName: '本月数量',
          field: 'monthlyCountOfInvoiced',
          width: 100,
        },
        {
          headerName: '当日金额-零售 (¥)',
          field: 'dayAmountOfRetail',
          width: 150,
          valueFormatter: privatePriceFormatter,
          cellClass: 'cell-align-right',
        },
        {
          headerName: '当日金额-保修 (¥)',
          field: 'dayAmountOfClaim',
          width: 150,
          valueFormatter: privatePriceFormatter,
          cellClass: 'cell-align-right',
        },
        {
          headerName: '当日金额-保险 (¥)',
          field: 'dayAmountOfInsurance',
          width: 150,
          valueFormatter: privatePriceFormatter,
          cellClass: 'cell-align-right',
        },
        {
          headerName: '当日金额-其他 (¥)',
          field: 'dayAmountOfOther',
          width: 150,
          valueFormatter: privatePriceFormatter,
          cellClass: 'cell-align-right',
        },
        {
          headerName: '当日金额-总计 (¥)',
          field: 'dayTotalAmount',
          width: 150,
          valueFormatter: privatePriceFormatter,
          cellClass: 'cell-align-right',
        },

        {
          headerName: '本月金额-零售 (¥)',
          field: 'monthlyAmountOfRetail',
          width: 150,
          valueFormatter: privatePriceFormatter,
          cellClass: 'cell-align-right',
        },
        {
          headerName: '本月金额-保修 (¥)',
          field: 'monthlyAmountOfClaim',
          width: 150,
          valueFormatter: privatePriceFormatter,
          cellClass: 'cell-align-right',
        },
        {
          headerName: '本月金额-保险 (¥)',
          field: 'monthlyAmountOfInsurance',
          width: 150,
          valueFormatter: privatePriceFormatter,
          cellClass: 'cell-align-right',
        },
        {
          headerName: '本月金额-其他 (¥)',
          field: 'monthlyAmountOfOther',
          width: 150,
          valueFormatter: privatePriceFormatter,
          cellClass: 'cell-align-right',
        },
        {
          headerName: '本月金额-总计 (¥)',
          field: 'monthlyTotalAmount',
          width: 150,
          valueFormatter: privatePriceFormatter,
          cellClass: 'cell-align-right',
          headerClass: 'center-box header-border-right',
        },
      ],
    },
    {
      headerName: '出车统计',
      headerClass: 'center-box',
      children: [
        {
          headerName: '当日数量',
          field: 'dayCountOfCheckout',
          width: 100,
        },
        {
          headerName: '本月数量',
          field: 'monthlyCountOfCheckout',
          width: 100,
          headerClass: 'center-box header-border-right',
        },
      ],
    },
    {
      headerName: '未完工出车',
      headerClass: 'center-box',
      children: [
        {
          headerName: '当日数量',
          field: 'dayCountOfCheckoutWithoutCompleted',
          width: 100,
        },
        {
          headerName: '本月数量',
          field: 'monthlyCountOfCheckoutWithoutCompleted',
          width: 100,
          headerClass: 'center-box header-border-right',
        },
      ],
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '订单创建人',
      options: SA_NAME_WITH_ALL_AGGREGATED_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_AGGREGATED_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.MULTI_DROPDOWN,
      name: 'categories',
      label: '订单类型',
      options: toEnum(SERVICE_RECORD_CATEGORY_OPTIONS).values,
      defaultValue: null,
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型 :',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
    {
      type: FILTER_INPUT_TYPES.TEXT,
      name: 'date',
      label: '查询日期',
      content: () => moment(new Date()).format('YYYY-MM-DD HH:mm'),
      tip: '*仅显示当日实时数据，如需其他信息，请前往其他报表',
    },
  ],
};

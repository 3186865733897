import { Injectable } from '@angular/core';
import { Body, GET, Headers, POST, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { DealerInfo } from '../models/dealerInfo';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DealerInfoManagementApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/website/dealers/settings/dealer-info')
  getDealerInfo(): Observable<DealerInfo> {
    return null;
  }

  @POST('/api/website/dealers/settings/dealer-info')
  updateDealerInfo(@Body data): Observable<DealerInfo> {
    return null;
  }

  @GET('/api/dealers')
  @Headers({ 'X-OTR-AS-COMMAND': 'findAllDealerInfoWithDealerGroup' })
  getDealersWithDealerGroup() {
    return null;
  }

  @GET('/api/dealers/findAllDealerInfoWithDealerGroup')
  @Headers({ 'X-OTR-AS-COMMAND': 'findAllDealerInfoWithDealerGroup' })
  getDealerGroup() {
    return null;
  }

  @GET('/api/outlets/all-dealer-mapping')
  getAllDealersMapping() {
    return null;
  }

  @GET('/aftersales/website/print/order/terms-and-conditions-init')
  updateOrderPrintPdf(@Query('dealerId') dealerId: string, @Query('isSmart') isSmart: boolean): Observable<any> {
    return null;
  }
}

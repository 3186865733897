import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';
import { IAfterGuiAttachedParams, IFilterParams } from 'ag-grid';
import { FormControl } from '@angular/forms';
import { CHINESE_CALENDAR_CONFIG } from '../../../../../constants/calendar';
import { isEmptyValue } from '../../../../../utils/common';
import { getDateAtLastSecond, getDateAtZero } from '../../../../../utils/date';
import { dateRegularParse } from '../../../../../utils/date-parse';

@Component({
  selector: 'app-pagination-calendar-range-null',
  templateUrl: 'pagination-calendar-range-null.template.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationCalendarRangeNullComponent extends BasePaginationFilterComponent {
  startDateControl: FormControl = new FormControl('');
  endDateControl: FormControl = new FormControl('');
  inputType: FormControl = new FormControl('');
  savedStartValue: string = '';
  savedEndValue: string = '';
  inputTypeValue: string = 'NORMAL';
  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;

  agInit(params: IFilterParams): void {
    super.agInit(params);
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    super.afterGuiAttached(params);
    this.startDateControl.setValue(this.savedStartValue);
    this.endDateControl.setValue(this.savedEndValue);
    this.inputType.setValue(this.inputTypeValue);
  }

  setModel(model: any): void {
    this.savedStartValue = dateRegularParse(_.get(model, 'from', ''));
    this.savedEndValue = dateRegularParse(_.get(model, 'to', ''));
    this.inputTypeValue = _.get(model, 'inputType', 'NORMAL');
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    const model = {} as { from?: any; to?: any; };
    if (this.savedStartValue) {
      model.from = getDateAtZero(new Date(this.savedStartValue));
    }
    if (this.savedEndValue) {
      model.to = getDateAtLastSecond(new Date(this.savedEndValue));
    }
    if (this.inputType.value === 'NO_VALUE') {
      model.from = 'NO_VALUE';
      model.to = 'NO_VALUE';
    }
    return this.modelFormatter(model);
  }

  isFilterActive(): boolean {
    return !isEmptyValue(this.savedStartValue) || !isEmptyValue(this.savedEndValue) || this.inputTypeValue === 'NO_VALUE';
  }

  stopPropagation() {
    event.stopPropagation();
  }

  onNoValueClick() {
    this.startDateControl.setValue('');
    this.endDateControl.setValue('');
  }

  onSubmit() {
    super.hideFilterMenu();
    this.inputTypeValue = this.inputType.value;
    this.savedStartValue = this.startDateControl.value;
    this.savedEndValue = this.endDateControl.value;
    this.params.filterChangedCallback();
  }

  onCancel() {
    super.hideFilterMenu();
    this.startDateControl.setValue(this.savedStartValue);
    this.endDateControl.setValue(this.savedEndValue);
    this.inputType.setValue(this.inputTypeValue);
  }

  onCalendarBlur() {
    this.endDateControl.updateValueAndValidity();
    this.startDateControl.updateValueAndValidity();
    this.inputType.setValue('NORMAL');
  }
}

/**
 * Deprecated, use PaginationSelectComponent instead.
 */
import { Component } from '@angular/core';
import * as _ from 'lodash';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from 'ag-grid';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';
import { getMapKeys } from '../../../../../utils/common';
import { BehaviorSubject, isObservable } from 'rxjs';
import { FormControl } from '@angular/forms';

const setChecked = (map: Map<any, boolean>, key, checked) =>
  checked ? map.set(key, checked) : map.has(key) ? map.delete(key) : null;

@Component({
  selector: 'app-pagination-select-and-fuzzy-search-filter-cell',
  templateUrl: 'pagination-select-and-fuzzy-search-filter.template.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationSelectAndFuzzySearchFilterComponent extends BasePaginationFilterComponent {
  public options: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public filter: Map<any, boolean> = new Map();
  private savedFilter: Map<any, boolean> = new Map();
  private filterParams;
  searchable: boolean = false;
  keywordPlaceholder: string = '';
  public searchInput: FormControl = new FormControl('');
  public text: string = '';
  modelFormatter = getMapKeys;
  displayOptions: BehaviorSubject<any[]> = new BehaviorSubject([]);

  agInit(params: IFilterParams): void {
    super.agInit(params);
    this.filterParams = this.params.colDef.filterParams;
    this.keywordPlaceholder = _.get(this.filterParams, 'keywordPlaceholder', '');
    this.searchable = _.get(this.filterParams, 'searchable', false);

    if (isObservable(this.filterParams.options)) {
      this.filterParams.options.subscribe(this.options);
    } else {
      const options = _.isFunction(this.filterParams.options) ? this.filterParams.options() : this.filterParams.options;
      this.options.next(options);
      this.displayOptions.next(options);
    }
    // fuzzy search
    this.searchInput.valueChanges.subscribe(value => {
      const results = _.filter(this.options.getValue(), item => item.text.indexOf(value) !== -1);
      this.displayOptions.next(results);
    });
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    super.afterGuiAttached(params);
    this.searchInput.setValue(this.text);
    this.filter = new Map(this.savedFilter.entries());
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (this.filterParams.filter) {
      return this.filterParams.filter(params, this.savedFilter);
    }
    return true;
  }

  setDefaultValue() {
    super.setDefaultValue();
    this.searchInput.setValue('');
    this.text = '';
    this.setFilter(false);
  }

  onSubmit() {
    super.hideFilterMenu();
    this.searchable ?
      this.onFuzzySubmit() :
      this.onNormalSubmit();
    this.onFilterCallback();
  }

  onFilterCallback() {
    this.params.filterChangedCallback();
    const filterParams = this.params.colDef.filterParams;
    if (filterParams.handleFilterChange) {
      filterParams.handleFilterChange(this.savedFilter);
    }
  }

  onNormalSubmit() {
    const checkedOptions = [...new Map(this.filter.entries()).keys()];
    const displayOptions = _.map(this.displayOptions.getValue(), 'value');
    const intersection = _.intersection(checkedOptions, displayOptions);
    this.text = !_.isEmpty(intersection) ? this.searchInput.value : '';
    // @ts-ignore
    this.savedFilter = new Map(intersection.map(i => [i, true]));
  }

  onFuzzySubmit() {
    const checkedOptions = [...new Map(this.filter.entries()).keys()];
    // @ts-ignore
    this.savedFilter = new Map(checkedOptions.map(i => [i, true]));
  }

  onCancel() {
    this.searchInput.setValue(this.text);
    super.hideFilterMenu();
    this.filter = new Map(this.savedFilter.entries());
  }

  isFilterActive(): boolean {
    return !this.isAll() && this.filter.size !== 0;
  }

  onChange(event, option) {
    setChecked(this.filter, option.value, event.target.checked);
  }

  isAll() {
    const checkedOptions = [...new Map(this.filter.entries()).keys()];
    return checkedOptions.length === this.options.getValue().length;
  }

  getIsAllChecked() {
    const checkedOptions = [...new Map(this.filter.entries()).keys()];
    const displayOptions = _.map(this.displayOptions.getValue(), 'value');
    const intersection = _.intersection(checkedOptions, displayOptions);
    displayOptions.sort();
    intersection.sort();
    return _.isEqual(displayOptions, intersection) && !_.isEmpty(intersection);
  }

  onAllChange(event) {
    this.setFilter(event.target.checked);
  }

  private setFilter(isChecked: boolean) {
    _.forEach(
      this.displayOptions.getValue(),
      (option: any) => setChecked(this.filter, option.value, isChecked),
    );
  }

  isSelect(option) {
    return this.filter.size === 0 ? false : this.filter.get(option.value);
  }

  showNoData() {
    return this.searchable && _.isEmpty(this.displayOptions.getValue());
  }

  setModel(model: any): void {
    const filter = (_.isEmpty(model) || model.size === 0) ? new Map() : new Map(model.map(i => [i, true]));
    this.filter = filter;
    this.savedFilter = filter;
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    return this.modelFormatter(this.savedFilter);
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-show-status',
  templateUrl: './show-status.template.html',
  styleUrls: ['./show-status.style.scss'],
})
export class ShowStatusComponent {
  @Input() followUpStatus: FollowUpStatus;
}

export interface FollowUpStatus {
  value: string;
  className: string;
}

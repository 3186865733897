import * as _ from 'lodash';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IAfterGuiAttachedParams } from 'ag-grid';

import { isEmptyValue } from '../../../../../utils/common';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';

@Component({
  selector: 'app-pagination-range-null-input',
  templateUrl: 'pagination-range-null-input.component.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationRangeNullInputComponent extends BasePaginationFilterComponent {
  startInput = new FormControl('');
  endInput = new FormControl('');
  inputType: FormControl = new FormControl('');
  savedStartValue = '';
  savedEndValue = '';
  inputTypeValue: string = 'NORMAL';

  afterGuiAttached(params: IAfterGuiAttachedParams) {
    super.afterGuiAttached(params);
    this.startInput.setValue(this.savedStartValue);
    this.endInput.setValue(this.savedEndValue);
    this.inputType.setValue(this.inputTypeValue);
  }

  setModel(model: any) {
    this.savedStartValue = _.get(model, 'from', '');
    this.savedEndValue = _.get(model, 'to', '');
    this.inputTypeValue = _.get(model, 'inputType', 'NORMAL');
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    const model = {} as { from?: string; to?: string; };
    if (this.savedStartValue) {
      model.from = this.savedStartValue;
    }
    if (this.savedEndValue) {
      model.to = this.savedEndValue;
    }
    if (this.inputType.value === 'NO_VALUE') {
      model.from = 'NO_VALUE';
      model.to = 'NO_VALUE';
    }
    return this.modelFormatter(model);
  }

  isFilterActive() {
    return !isEmptyValue(this.savedStartValue) || !isEmptyValue(this.savedEndValue) || this.inputTypeValue === 'NO_VALUE';
  }

  isSubmitEnabled() {
    const startInput = this.startInput.value.trim();
    const endInput = this.endInput.value.trim();
    const testRegExp = _.get(this.params.colDef.filterParams, 'regExp', new RegExp(''));
    return (!startInput || testRegExp.test(startInput)) && (!endInput || testRegExp.test(endInput));
  }

  onCancel() {
    super.hideFilterMenu();
    this.startInput.setValue(this.savedStartValue);
    this.endInput.setValue(this.savedEndValue);
    this.inputType.setValue(this.inputTypeValue);
  }

  onSubmit() {
    super.hideFilterMenu();
    this.savedStartValue = this.startInput.value.trim();
    this.savedEndValue = this.endInput.value.trim();
    this.inputTypeValue = this.inputType.value;
    this.params.filterChangedCallback();
  }

  onNoValueClick() {
    this.startInput.setValue('');
    this.endInput.setValue('');
  }

  onNormalClick() {
    this.inputType.setValue('NORMAL');
  }
}

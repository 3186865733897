import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpinnerService } from '@otr/website-common';
import { CustomerProfiletApi } from '../api/customer-profilet';

@Injectable()
export class CustomerProfileService {
  constructor(private customerProfiletApi: CustomerProfiletApi,
              private spinnerService: SpinnerService) {
  }

  deleteCustomer(id): Observable<any> {
    return this.customerProfiletApi.deleteCustomer(id);
  }
}

import { Injectable } from '@angular/core';
import { Body, GET, Headers, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CourtesyCarDetail } from '../../courtesy-car/model/courtesy-car.model';

@Injectable()
export class ReportApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/outlets/reports/:type')
  getReport(@Path('type') type: string): Observable<any> {
    return null;
  }

  @GET('/api/reports/intelligence-report')
  fetchReportConfig(@Query('dealer_id') dealerId: string, @Query('type') type: string) {
    return null;
  }

  @GET('/api/reports/parts')
  fetchPartsReportConfig(@Query('type') type: string) {
    return null;
  }

  @GET('/api/reports/detailPartSales')
  fetchPartsDetailReportConfig(@Query('type') type: string) {
    return null;
  }

  @POST('/api/reports/intelligence-report')
  createReportConfig(@Body data) {
    return null;
  }

  @GET('/api/reports/realtime_order')
  @Headers({ isExportFile: true })
  getOrderReport(@Query('filter') filter: any) {
    return null;
  }

  @GET('/api/reports/survey')
  @Headers({ isExportFile: true })
  getSurveyReport(@Query('filter') filter: any) {
    return null;
  }

  @GET('/api/reports/v2/sales-performance/export')
  @Headers({ isExportFile: true })
  getPerformanceReport(@Query('filter') filter: any) {
    return null;
  }

  @GET('/api/reports/v2/pe-performance/export')
  @Headers({ isExportFile: true })
  getPePerformanceReport(@Query('filter') filter: any) {
    return null;
  }

  @GET('/api/reports/v2/sc-performance/export')
  @Headers({ isExportFile: true })
  getScPerformanceReport(@Query('filter') filter: any) {
    return null;
  }

  @GET('/api/reports/test-driver/export')
  @Headers({ isExportFile: true })
  getTestDriveReport(@Query('filter') filter: any) {
    return null;
  }

  @GET('/api/reports/accident-car-report')
  fetchAccidentCarReportConfig(@Query('dealer_id') dealerId: string, @Query('type') type: string) {
    return null;
  }

  @POST('/api/reports/accident-car-report')
  createAccidentCarReportConfig(@Body data) {
    return null;
  }

  @GET('/api/cti-call-history/soft-phone-report/download')
  @Headers({ isExportFile: true })
  getSoftPhoneReport(@Query('filter') filter: any) {
    return null;
  }

  @GET('/api/sales-website/order-bank-report-tasks')
  getOrderBankReportTaskList(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/order-bank-report-tasks/download/:id')
  getDownloadURL(@Path('id') id: any): Observable<any> {
    return null;
  }

}

<div class="select-filter-box">
  <label class="select-label select-label-all">
    <input class="select-checkbox" type="checkbox" (change)="onAllChange($event)" [checked]="isAllSelected"/>
    全部
  </label>
  <div class="select-filter-group">
    <label *ngFor="let option of options$ | async" class="select-label">
      <input class="select-checkbox" type="checkbox" [value]="option.value" (change)="onChange($event, option)"
             [checked]="(filter$ | async)[option.value]"/>
      {{option.text | lowercaseBrand}}
    </label>
  </div>
</div>

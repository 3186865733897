import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/src/interfaces';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  selector: 'app-multi-row-header',
  templateUrl: './tooltip-renderer.template.html',
  styleUrls: ['./tooltip-renderer.style.scss'],
})
export class TooltipRendererComponent implements ICellRendererAngularComp {
  public value: string;
  public tooltip: Array<string>;
  public templateHtml: string;
  public placement: string;
  public showIndicator: boolean;
  public textStyle: Object = {};

  agInit(params: ICellRendererParams): void {
    this.value = _.isNil(params.valueFormatted) ? params.value : params.valueFormatted;
    if (this.value) {
      params.eGridCell.classList.add('tooltip-cell');
    }
    this.tooltip = (params.colDef.cellRendererParams.tooltipGetter || _.noop)(params.node.data, params) || '';
    this.placement = _.get(params.colDef.cellRendererParams, 'tooltipPlacement', 'right');
    this.showIndicator = _.get(params.colDef.cellRendererParams, 'showIndicator', true);
    this.templateHtml = this.generateHtml();
    this.textStyle = _.get(params.colDef.cellRendererParams, 'textStyle', {});
  }

  refresh(params: any): boolean {
    return false;
  }

  generateHtml() {
    return _.reduce(this.tooltip, (previous, current) => {
      const idx = _.indexOf(current, ':');
      const title = current.substring(0, idx);
      const text = current.substring(idx + 1);
      return `${previous}<p class='inner-content'>${title ? `<span class='inner-title'>${title}:</span>` : ''}${text}</p>`;
    }, '');
  }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, takeUntil, tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { KeyValue } from '../../models/common';
import { isEmptyValue } from '../../utils/common';
import { AutoUnsubscribe } from '../../utils/autoUnsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-virtual-scroll-dropdown-list',
  templateUrl: './virtual-scroll-dropdown-list.component.html',
  styleUrls: ['./virtual-scroll-dropdown-list.component.scss'],
})
export class VirtualScrollDropdownListComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() optionSubject: BehaviorSubject<Array<KeyValue>>;
  @Input() control: FormControl;
  @Input() otherControl: FormControl = new FormControl(null);
  @Input() searchable: boolean = false;
  @Input() placeholder: string = '请选择';
  @Input() keywordPlaceholder: string = '';
  @Input() canInputOther: boolean = false;
  @Input() otherPlaceholder: string = '';
  @Input() otherMaxLength: number = 20;
  @ViewChild('viewport', { read: ElementRef }) viewport: ElementRef;

  keyword$: BehaviorSubject<string> = new BehaviorSubject('');
  filteredOptions: Array<KeyValue>;
  dropDownOpened: boolean = false;
  displayText: string = '';
  otherInputControl: FormControl = new FormControl();

  private componentDestroy$: Observable<any>;

  ngOnInit() {
    combineLatest(this.keyword$, this.optionSubject)
      .pipe(
        map(([keyword, options]) => this.filterOptions(options, keyword)),
        tap(filteredOptions => {
          if (this.viewport) {
            this.viewport.nativeElement.style.height = `${_.min([filteredOptions.length * 34 + 40, 250])}px`;
          }
        }),
        takeUntil(this.componentDestroy$),
      )
      .subscribe(filteredOptions => (this.filteredOptions = filteredOptions));
    this.control.valueChanges.subscribe(() => this.displayText = this.getDisplayText());
    this.otherControl.valueChanges.subscribe(() => this.displayText = this.getDisplayText());
    this.displayText = this.getDisplayText();
  }

  get isEmpty() {
    return isEmptyValue(this.control.value);
  }

  closeDropDownList(event) {
    this.otherInputControl.setValue(this.otherControl.value);
    event.stopPropagation();
    this.dropDownOpened = false;
    this.keyword$.next('');
  }

  getDisplayText() {
    if (this.canInputOther && this.otherControl.value) {
      return this.otherControl.value;
    }
    return _.chain(this.optionSubject.value)
      .find({ value: this.control.value })
      .get('text', this.placeholder)
      .value();
  }

  filterOptions(options: Array<KeyValue>, keyword: string) {
    return _.filter(options, ({ text }) => _.includes(_.lowerCase(text), _.lowerCase(keyword)));
  }

  handleFilter(event) {
    event.stopPropagation();
    this.keyword$.next(event.target.value);
  }

  handleClick($event, selectedOption: KeyValue) {
    this.otherControl.setValue(null);
    this.otherInputControl.setValue(null);
    this.closeDropDownList($event);
    this.control.setValue(selectedOption.value);
  }

  toggleDropDownList() {
    if (!_.isEmpty(this.filteredOptions)) {
      this.dropDownOpened = !this.dropDownOpened;
      this.keyword$.next('');
    }
  }

  preventEmitEvent(event: MouseEvent) {
    event.stopPropagation();
  }

  handleConfirm($event) {
    if (_.isEmpty(this.otherInputControl.value)) {
      this.otherControl.setValue(null);
      this.control.setValue(null);
    } else {
      this.otherControl.setValue(this.otherInputControl.value);
      this.control.setValue('OTHER');
    }
    this.closeDropDownList($event);
  }

  handleCancel($event) {
    this.otherInputControl.setValue(this.otherControl.value);
    this.closeDropDownList($event);
  }
}

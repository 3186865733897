import * as _ from 'lodash';

export const SWAP_CAR_SALES_TYPE_OPTIONS = [
  { text: '网络内-集团内', value: 'Within MB Network-I', abbreviation: 'I' },
  { text: '网络内-集团外', value: 'Within MB Network-O', abbreviation: 'O' },
  { text: '经销商分公司', value: 'Dealership', abbreviation: 'B' },
];

export const SWAP_CAR_SALES_TYPES = _.map(SWAP_CAR_SALES_TYPE_OPTIONS, 'value');

export const VEHICLE_LICENSE_STATUS = {
  NEW: {
    value: false,
    text: '新牌照',
  },
  OLD: {
    value: true,
    text: '已有牌照',
  },
};

export const SERVICE_CONTRACT_CATEGORY = {
  EW: 'EXTENDED_WARRANTY',
  MP: 'MAINTENANCE',
};

export const ALLOCATION_VEHICLE_ERROR_MAPPING = {
  VEHICLE_ALLOCATED: '订单车辆已被分配至其他经销商，申请待确认，会有专人与您线下沟通，请勿重复申请调拨。',
  INCONSISTENT_VEHICLE: '订单车辆申请待确认，车辆信息不一致，会有专人与您线下沟通，请勿重复申请调拨。',
  INCONSISTENT_DEALER: '订单车辆申请待确认，经销商信息不一致，会有专人与您线下沟通，请勿重复申请调拨。',
};

import { toEnum } from 'app/_common/utils/enum';

export const GRADE = ['A', 'B', 'C', 'D', 'E'];

export const LEAD_ACTION_TYPE = {
  POST_LEAD: 'POST_LEAD',
  PUT_LEAD: 'PUT_LEAD',
  POST_SHOWROOM_VISIT: 'POST_SHOWROOM_VISIT',
  PATCH_SHOWROOM_VISIT: 'PATCH_SHOWROOM_VISIT',
};

export const LEAD_TYPES = {
  LEAD: 'lead',
  LOST_SALES: 'lost sales',
  LEAD_CANDIDATE: 'lead candidate',
  UNSUCCESSFUL: 'unsuccessful',
  SUCCESSFUL: 'Successful',
};

export const PURPOSES = ['看车比价', '订单', '交车', '现订现交', '退订', '办手续', '其它', '交车后退车'];

export const VISITOR_NUMBER = toEnum({
  ONE: { value: 'one', text: '1位' },
  TWO: { value: 'two', text: '2位' },
  THREE: { value: 'three', text: '3位' },
  FOUR: { value: 'four', text: '4位' },
  FIVE: { value: 'five', text: '5位' },
  FIVE_MORE: { value: 'five more', text: '5位以上' },
});

export const LICENSE_STATUS = toEnum({
  NEW_LICENSE: { value: 'NEW_LICENSE', text: '新牌照' },
  EXISTING_LICENSE: { value: 'EXISTING_LICENSE', text: '已有牌照' },
  FUEL_VEHICLE_LICENSE: { value: 'FUEL_VEHICLE_LICENSE', text: '燃油车牌照' },
  NEW_ENERGY_LICENSE: { value: 'NEW_ENERGY_LICENSE', text: '新能源牌照' },
});

export const VISITOR_RELATIONSHIP = toEnum({
  CHILDREN: { value: 'children', text: '子女' },
  SPOUSE: { value: 'spouse', text: '配偶' },
  RELATIVES: { value: 'relatives', text: '亲属' },
  FRIEND: { value: 'friend', text: '朋友' },
  COLLEAGUE: { value: 'colleague', text: '同事' },
});

export const PURCHASE_TIME = toEnum({
  ONE_WEEK: { value: 'one week', text: '1周' },
  ONE_MONTH: { value: 'one month', text: '1个月' },
  THREE_MONTHS: { value: 'three months', text: '3个月' },
  SIX_MONTHS: { value: 'six months', text: '6个月' },
  MORE_THAN_SIX_MONTHS: { value: 'more than six months', text: '6个月以上' },
});

export const purchaseTimeAndGradeMapping = {
  'one week': 'A',
  'one month': 'B',
  'three months': 'C',
  'six months': 'D',
  'more than six months': 'E',
};

export const gradeAndPurchaseTimeMapping = {
  A: 'one week',
  B: 'one month',
  C: 'three months',
  D: 'six months',
  E: 'more than six months',
};

export const ADDITIONAL_PURCHASE = toEnum({
  ADDITIONAL_PURCHASE: { value: 'additional purchase', text: '增购' },
  REPLACEMENT: { value: 'replacement', text: '置换' },
});

export const PAYMENT_METHOD = toEnum({
  CASH: { value: 'cash', text: '现金' },
  FINANCIAL_PRODUCTS: { value: 'financial products', text: '金融产品' },
  SAVINGS_CARD: { value: 'savings card', text: '储蓄卡' },
  CREDIT_CARD: { value: 'credit card', text: '信用卡' },
  WECHAT_ALIPAY: { value: 'wechat alipay', text: '微信支付宝' },
});

export const CURRENT_VEHICLE_YEAR_OPTION = toEnum({
  ONE_YEAR: { value: 'one year', text: '1年' },
  TWO_YEARS: { value: 'two years', text: '2年' },
  THREE_YEARS: { value: 'three years', text: '3年' },
  FOUR_YEARS: { value: 'four years', text: '4年' },
  FIVE_YEARS: { value: 'five years', text: '5年' },
  MORE_THAN_FIVE_YEARS: { value: 'more than five years', text: '5年以上' },
});

export const BOOLEAN_OPTIONS = toEnum({
  TRUE: { value: true, text: '是' },
  FALSE: { value: false, text: '否' },
});

export const AGE_RANGE_OPTIONS = toEnum({
  FROM_18_TO_25_YEARS_OLD: { value: '18-25', text: '18-25岁', from: 18, to: 25 },
  FROM_25_TO_30_YEARS_OLD: { value: '26-30', text: '26-30岁', from: 26, to: 30 },
  FROM_31_TO_40_YEARS_OLD: { value: '31-40', text: '31-40岁', from: 31, to: 40 },
  FROM_41_TO_50_YEARS_OLD: { value: '41-50', text: '41-50岁', from: 41, to: 50 },
  FROM_51_TO_60_YEARS_OLD: { value: '51-60', text: '51-60岁', from: 51, to: 60 },
  MORE_THAN_60_YEARS_OLD: { value: '60+', text: '60岁以上', from: 60, to: 10000 },
});

export const CHARGING_PILE_OR_PARKING_SPACE_OPTIONS = toEnum({
  TRUE: { value: true, text: '有'},
  FALSE: { value: false, text: '无'},
});

export const KILOMETERS_PER_DAY_OPTIONS = toEnum({
  LESS_THAN_TEN: { value: 'LESS_THAN_TEN', text: '小于10公里'},
  BETWEEN_TEN_AND_TWENTY: { value: 'BETWEEN_TEN_AND_TWENTY', text: '10-20公里' },
  BETWEEN_TWENTY_AND_THIRTY: { value: 'BETWEEN_TWENTY_AND_THIRTY', text: '20-30公里'},
  GREATER_THAN_THIRTY: { value: 'GREATER_THAN_THIRTY', text: '大于30公里'},
});

export const LEAD_SEARCH_ERROR_MESSAGE = {
  EMPTY_PHONE: '请输入电话号码。',
  NO_MATCHED_PHONE: '无电话号码匹配的已有客户，请新建客户。',
};

import { Component, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  templateUrl: 'header-with-tooltip-and-filter.template.html',
  styleUrls: ['header-with-tooltip-and-filter.style.scss'],
})
export class HeaderWithTooltipAndFilterComponent implements IHeaderAngularComp {
  headerParams: IHeaderParams;
  headerTooltipText: string;
  pullTooltipLeft: boolean;
  useFixedWidthTooltip: boolean;
  isFilterActive: boolean = false;

  @ViewChild('headerMenu') headerMenu;

  agInit(params: IHeaderParams): void {
    this.headerParams = params;
    const colDef = params.column.getColDef();
    this.headerTooltipText =  _.get(colDef, 'headerTooltipText');
    this.pullTooltipLeft = _.get(colDef, 'pullTooltipLeft');
    this.useFixedWidthTooltip = _.get(colDef, 'useFixedWidthTooltip');
    // listen to the column for filter events
    params.column.addEventListener('filterChanged', () => {
      this.isFilterActive = params.column.isFilterActive();
    });
  }

  handleMenuIconClick() {
    this.headerParams.showColumnMenu(this.headerMenu.nativeElement);
  }
}

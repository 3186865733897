import * as moment from 'moment';
import {
  FILTER_INPUT_TYPES,
  ORDER_SOURCE_OPTIONS_FOR_PC,
  ExportExceedMessage,
} from '../constants/report';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import { priceFormatter, defaultFormatter } from '../utils/utils';

export const PARTS_SALES_ORDER_REPORT_CONFIG = {
  name: 'parts-sales-order-detail-report',
  enablePagination: true,
  exportedFileName: '销售订单细节总览',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  isRealTimeReport: true,
  timeFormatter: 'YYYYMMDDHHmm',
  exceedMessage: ExportExceedMessage.RESERVED,
  maxItems: 150000,
  isNameWithDate: true,
  column: [
    {
      headerName: '销售订单号',
      field: 'orderNumber',
      width: 160,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '订单来源',
      field: 'orderSource',
      width: 140,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '创建人',
      field: 'creatorName',
      width: 160,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '车牌',
      field: 'carPlateNumber',
      width: 120,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '底盘号',
      field: 'vin',
      width: 200,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '订单创建日期',
      field: 'orderCreatedTime',
      width: 190,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '零件号码',
      field: 'partNumber',
      width: 180,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '说明',
      field: 'description',
      width: 160,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '行号',
      field: 'lineNumber',
      width: 100,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '行状态',
      field: 'lineStatus',
      width: 100,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '结账状态',
      field: 'checkoutStatus',
      width: 100,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '客户类型编号',
      field: 'customerTypeCode',
      width: 120,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '厂家建议零售价',
      field: 'vendorSuggestedRetailPrice',
      width: 120,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '销售价格',
      field: 'sellingPrice',
      width: 120,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '成本',
      field: 'cost',
      width: 120,
      valueFormatter: ({ value }) => priceFormatter({ value }, 4),
    },
    {
      headerName: '数量',
      field: 'quantity',
      width: 120,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '税率',
      field: 'taxRate',
      width: 120,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '折扣率',
      field: 'discountRate',
      width: 120,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '分担比例',
      field: 'shareRate',
      width: 120,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '套餐占比',
      field: 'packageProportion',
      width: 120,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '总计（含折扣不含税）',
      field: 'totalAmountByDiscountWithoutTax',
      width: 130,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '税额',
      field: 'taxAmount',
      width: 120,
      valueFormatter: priceFormatter,
    },
    {
      headerName: '市场代码',
      field: 'marketingCode',
      width: 120,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '产品组',
      field: 'productGroup',
      width: 120,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '成本流动类型',
      field: 'costFlowCategory',
      width: 120,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '采购订单号',
      field: 'purchaseOrderNumber',
      width: 160,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '插入时间',
      field: 'addedTime',
      width: 190,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '出库时间',
      field: 'shippingTime',
      width: 190,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '结账时间',
      field: 'checkoutTime',
      width: 190,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '入库时间',
      field: 'receivedTime',
      width: 190,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '车辆入厂时间',
      field: 'vehicleCheckInTime',
      width: 150,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '车辆出厂时间',
      field: 'vehicleCheckOutTime',
      width: 150,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '三包截止日期',
      field: 'thirdMaintenanceDueDate',
      width: 150,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '部门代码',
      field: 'departmentCode',
      width: 120,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '公里数',
      field: 'mileage',
      width: 120,
      valueFormatter:  ({ value }) => value === '-' ? value : priceFormatter({ value }, 0),
    },
    {
      headerName: '车型',
      field: 'carModel',
      width: 180,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '客户ID',
      field: 'customerId',
      width: 240,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '客户姓名',
      field: 'customerName',
      width: 240,
      valueFormatter: defaultFormatter,
    },
    {
      headerName: '客户地址',
      field: 'customerAddress',
      width: 240,
      valueFormatter: defaultFormatter,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'createdDate',
      label: '创建日期',
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'shippingDate',
      label: '出库日期',
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkoutDate',
      label: '结账日期',
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'vin',
      label: '底盘号',
      placeholder: '请输入底盘号后6位进行搜索',
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'partNumber',
      label: '零件号码',
      placeholder: '请输入完整的零件号码进行搜索',
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'orderNumber',
      label: '订单号',
      placeholder: '请输入订单号进行搜索',
      maxLength: 14,
    },
    {
      type: FILTER_INPUT_TYPES.MULTI_DROPDOWN,
      name: 'orderSource',
      label: '订单来源',
      options: ORDER_SOURCE_OPTIONS_FOR_PC,
      defaultValue: null,
      placeholder: '请选择',
      initialSelectedAll: true,
    },
    {
      type: FILTER_INPUT_TYPES.CREATED_BY_MULTI_DROPDOWN,
      name: 'creator',
      label: '创建人',
      options: [],
      keyForOptionText: 'full_name',
      keyForOptionValue: 'gems_user_id',
      defaultValue: null,
      placeholder: '请选择',
      initialSelectedAll: true,
    },
    {
      type: FILTER_INPUT_TYPES.LINE_STATUS_MULTI_DROPDOWN,
      name: 'lineStatus',
      label: '行状态',
      options: [],
      defaultValue: null,
      keyForOptionText: 'textOption',
      keyForOptionValue: 'code',
      placeholder: '请选择',
      initialSelectedAll: true,
    },
  ],
  floatRight: true,
  filterThreeColumn: true,
  paginationWithLastFormParams: true,
};

<div class="arrange-icon-cell">
  <button nz-button nzType="link" nzBlock class="operation-button" (click)="editTask()">    
    <i nz-icon nzType="form" nzTheme="outline"></i>
    <span>更新</span>
  </button>
  <button nz-button nzType="link" nzBlock class="operation-button" 
          (click)="completeTask()" nz-popover [nzTitle]="''"
          [nzContent]="completeButtonDisabled ? contentTemplate : null"
          [nzPlacement]="'topRight'"
          [class.disabled] = "completeButtonDisabled">    
    <i nz-icon nzType="check-square" nzTheme="outline"></i>
    <span>完成</span>
  </button>
  <ng-template #contentTemplate>
    <p class="arrange_content_p">提示：</p>
    <span class="arrange_content_span">不可完成不属于自己的任务</span>
  </ng-template>
</div>
export const  INIT_MULTIPLE_DATA = {
  totalNumber: 0,
  changeRows: {},
  allRowsChecked: false,
  selectedCount: 0,
  rejectRows: [],
  submitRows: [],
  selectedRows: [],
  hasCheckedPartsCount: 0,
};

import { Injectable } from '@angular/core';
import { YearlyTargetApi } from '../api/yearly-target';
import { Cacheable, CacheClear } from '@otr/website-common';

@Injectable()
export class YearlyTargetService {
  currYearlyTargetTab: string = '';

  constructor(
    private yearlyTargetApi: YearlyTargetApi) {
  }

  @Cacheable()
  getSalesYearlyTarget() {
    return this.yearlyTargetApi.getSalesYearlyTarget();
  }

  @Cacheable()
  getAfterSalesYearlyTarget() {
    return this.yearlyTargetApi.getAfterSalesYearlyTarget();
  }

  getAfterSalesYearlyTargetDetail(id) {
    return this.yearlyTargetApi.getAfterSalesYearlyTargetDetail(id);
  }

  getSalesYearlyTargetDetail(id) {
    return this.yearlyTargetApi.getSalesYearlyTargetDetail(id);
  }

  @CacheClear()
  resetYearlyTargets() {
  }
}

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

interface Option {
  text: string;
  value: string;
}

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
})
export class DropdownButtonComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Input() options = [] as Option[] | string[];
  @Input() selectedValue = '';
  @Output() select = new EventEmitter<string>();

  open = false;

  private isSelfClick = false;

  close = () => {
    if (this.isSelfClick) {
      this.isSelfClick = false;
      return;
    }
    this.open = false;
  }

  ngOnInit() {
    document.addEventListener('click', this.close);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.close);
  }

  get text() {
    return typeof this.options[0] === 'string' ?
      this.selectedValue :
      _.find(this.options as Option[], ({ value }) => value === this.selectedValue).text;
  }

  toggle() {
    this.open = !this.open;
    this.isSelfClick = true;
  }

  onSelect(value: string) {
    this.select.emit(value);
  }
}

<div class="filter-box">
  <input
    *ngIf="isMobile || isNumber"
    [ngStyle]="{width:inputWidth+'px'}"
    class="form-control search-form"
    [maxIntegerDigits]="11" appNumberFormat
    [formControl]="searchInput"
    [placeholder]="placeholder"
  >
  <input
    *ngIf="!isMobile && !isNumber"
    class="form-control search-form"
    [ngStyle]="{width:inputWidth+'px'}"
    [formControl]="searchInput"
    [placeholder]="placeholder"
    maxlength="255"
  >
  <span class="btn-cancel" (click)="onCancel()" *ngIf="!hasResetBtn">
    取消
  </span>
  <button class="filter-btn-reset" (click)="onReset()" *ngIf="hasResetBtn">
    重置
  </button>
  <button class="btn-save" type="submit" (click)="onSubmit()" [disabled]="!isFilterValid()">
    确定
  </button>
</div>

<div [ngClass]="{
      'group-column-header': true,
      'addable': columnParams.addable,
      'expandable': columnParams.expandable,
      'expanded': columnParams.expanded | async,
      'extra-padding-right': notDeletedHeader.length > 1,
      'first-layer-divider': columnParams.firstLayerDivider
    }">
  <div class="header" *ngFor="let header of headers; let i = index">
    <div class="header-row">
      <input class="title-input" *ngIf="header.titleEditable" [(ngModel)]="header.title" (ngModelChange)="onHeaderChange($event)"/>
      <div *ngIf="!header.titleEditable" class="title">{{ header.title }}</div>
    </div>
    <div class="header-row">
      <div class="sub-title" *ngFor="let subTitle of header.subTitles">{{subTitle.headerName}}</div>
    </div>
    <div *ngIf="header.removable" class="minus" (click)="removeHeader(header)">
      <i class="fa antd-minus-circle-filled"></i>
    </div>

    <div *ngIf="showExpandingIcon(i)" class="expand-icon" (click)="toggleExpanded()">
      <img class="folding-icon" src="/assets/common/folding.svg" alt="folding-icon">
      <img class="expanding-icon" src="/assets/common/expanding.svg" alt="expanding-icon">
    </div>
  </div>
  <div *ngIf="shouldShowPlus" class="plus" (click)="addHeader()">
    <i class="fa antd-plus-circle-filled"></i>
  </div>
</div>

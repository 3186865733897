import { Injectable } from '@angular/core';
import { Body, GET, PATCH, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  Technician,
  TechnicianSinInInfo,
  TechnicianTeam,
  TechnicianTeamWorkPlan,
  TechnicianWorkPlan,
} from '../models/technician-team';

@Injectable()
export class TechnicianResourceManagementApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/technician-teams')
  getAllTechnicianTeams(): Observable<TechnicianTeam[]> {
    return null;
  }

  @GET('/api/sales-website/job-cards/order-list/technician-team/list')
  getAllTechnicianTeamsWithMajor(): Observable<TechnicianTeam[]> {
    return null;
  }

  @GET('/api/sales-website/technicians')
  getSkillMatchedTechnicians(@Query('currentJobCardId') currentJobCardId: number,
                             @Query('laborItemIds') laborItemIds: number[]): Observable<Technician[]> {
    return null;
  }

  @GET('/api/sales-website/technicians')
  getAllTechnicians(): Observable<Technician[]> {
    return null;
  }

  @GET('/api/sales-website/technicians/in-progress')
  getInProgressTechnicianIds(@Query('technicianIds') technicianIds: number[]): Observable<number[]> {
    return null;
  }

  @GET('/api/sales-website/job-cards/technical-reminders')
  getTechnicalAlertList(@Query('orderNumber') orderNumber: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/technicians/:technicianId')
  getTechnician(@Path('technicianId') technicianId: number): Observable<Technician> {
    return null;
  }

  @GET('/api/sales-website/technician-teams/work-plan')
  getTeamWorkPlan(): Observable<TechnicianTeamWorkPlan[]> {
    return null;
  }
  @GET('/api/sales-website/technicians/work-plan-all')
  getWorkPlanListByTechnician(@Query('orderByKey') orderByKey: string,
                              @Query('searchKeywords') searchKeywords: string): Observable<TechnicianWorkPlan[]> {
    return null;
  }

  @GET('/api/sales-website/technicians/work-plan')
  getTechnicianWorkPlan(@Query('teamId') teamId: any): Observable<TechnicianWorkPlan[]> {
    return null;
  }

  @GET('/api/sales-website/technician-sign-in-infos')
  getTechnicianSignInInfo(@Query('technicianId') technicianId: number): Observable<TechnicianSinInInfo> {
    return null;
  }

  @POST('/api/sales-website/technician-sign-in-infos')
  createSignInInfo(@Query('technicianId') technicianId: number,
                   @Body body: any): Observable<any> {
    return null;
  }

  @PATCH('/api/sales-website/technician-sign-in-infos')
  updateSignInInfo(@Query('technicianSignInInfoId') technicianSignInInfoId: number,
                   @Body body: any): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/technician-sign-in-infos/query-by-time-range')
  getTechnicianSignInInfoRecords(@Query('technicianId') technicianId: number,
                                 @Query('startTime') startTime: string,
                                 @Query('endTime') endTime: string): Observable<TechnicianSinInInfo[]> {
    return null;
  }
}

import { toEnum } from '../../../_common/utils/enum';

export enum D2DOrderPlatformType {
  DiDi = 1,
  EDriving = 2,
}

export enum D2DOrderType {
  pickUpAtHome = 1,
  deliverToHome = 2
}

export enum RoleCode {
  ASSA = 'ASSA',
  SR = 'SR'
}

export enum Operation {
  reOrder = '重新下单',
  confirm = '确认送车',
  assignSa = '分配SA',
  resendContract = '重发协议',
  payment = '付款',
  evaluate = '评价',
}

export const VEHICLE_TYPE_OPTIONS = toEnum({
  ALL: { text: '全部', value: '' },
  PC: { text: '乘用车', value: 'PC' },
  VAN: { text: '商务车', value: 'VAN' },
});

export const SOURCE_OPTIONS = toEnum({
  OTR: { text: 'OTR+', value: 'OTR' },
  WECHAT: { text: '微信', value: 'Wechat' },
  MYSTAR: { text: 'MYSTAR', value: 'MYSTAR' },
  DEALER: { text: 'OAB', value: 'Dealer' },
  DROP_OFF: { text: '星辉送修点', value: 'DropOff' },
  APPOINTMENT: { text: '历史订单', value: 'Appointment' },
});

export const ORDER_TYPE = [
  { text: '取车', value: '上门取车' },
  { text: '送车', value: '送车上门' },
];

const OAB_STATUS_OBJECT = {
  WAIT_CONFIRM_SENT: { text: '等待确认送车', value: '0_c_wait_Confirm_sent' },
  WAIT_ASSIGN_SA: { text: '等待分配SA', value: '0_c_wait_assign_sa' },
  CFM_AGRT: { text: '等待客户确认', value: '0_cfmAgrt' },
  NO_CONFIRM: { text: '客户超时未确认', value: '0_d_NoConfirm' },
  NO_CONFIRM_SENT: { text: '超时未确认送车', value: '0_d_no_Confirm_sent' },
  NO_ASSIGN_SA: { text: '超时未分配SA', value: '0_d_no_assign_sa' },
  E_DRIVE_ORDERED: { text: '已下单', value: '0_eDrive_ordered' },
  E_DISAGREE: { text: '客户拒绝协议', value: '0_e_disagree' },
  E_DRIVE_TO_OPEN: { text: '等待司机接单', value: '11_eDrive_to_open' },
  E_DRIVE_RECEIVED: { text: '司机已接单', value: '12_eDrive_received' },
  E_DRIVE_OPENED: { text: '司机已开单', value: '13_eDrive_opened' },
  E_DRIVE_READY: { text: '司机已就位', value: '14_eDrive_ready' },
  E_DRIVE_DRIVING: { text: '司机开车中', value: '15_eDrive_driving' },
  E_DRIVE_ARRIVED: { text: '司机到达目的地', value: '16_eDrive_arrived' },
  E_DRIVE_SETTLEMENT: { text: '待付款', value: '16_eDrive_settlement' },
  E_DRIVE_PICKED: { text: '完成（等待评价）', value: '17_eDrive_picked' },
  E_DRIVE_PICKED_Y: { text: '完成（评价完成）', value: '17_eDrive_picked(Y)' },
  E_DRIVE_PICKED_N: { text: '完成（无评价）', value: '17_eDrive_picked(N)' },
  E_DRIVE_CANCELLED: { text: '代驾-订单取消', value: '21_eDrive_cancelled' },
  CANCEL_BY_CUS: { text: '客户取消订单', value: '22cancel_by_cus' },
  ORDER_BY_OTR: { text: '经销商取消订单', value: '22order _by_otr' },
  E_DRIVE_ORDER_FAIL: { text: '下单失败', value: 'eDrive_ordere_fail' },
};
export const OAB_STATUS = toEnum({
  ...OAB_STATUS_OBJECT,
});

export const SA_FOCUS_ON_STATUS = [OAB_STATUS.WAIT_CONFIRM_SENT, OAB_STATUS.NO_CONFIRM_SENT];

export const SR_FOCUS_ON_STATUS = [OAB_STATUS.WAIT_CONFIRM_SENT, OAB_STATUS.WAIT_ASSIGN_SA,
  OAB_STATUS.NO_CONFIRM_SENT, OAB_STATUS.NO_ASSIGN_SA];

export const OAB_DETAIL_STATUS = toEnum({
  ...OAB_STATUS_OBJECT,
  CANCEL_BY_OAB: { text: '经销商取消订单', value: '22cancel_by_oab' },
  CANCEL_SUCCESS: { text: '代驾单取消成功', value: '23cancelled_success' },
  CANCEL_FAIL: { text: '代驾单取消失败', value: '24cancelled_fail' },
  FAILED_ORDER_BY_OAB: { text: 'OAB同步失败', value: '40_failed_order_create_oab' },
  FAILED_PAYMENT: { text: '付款失败', value: '40_failed_payment_oab' },
  AWAIT_PAYMENT_OAB: { text: '系统正在同步付款状态', value: '40_await_payment_oab' },
});

export const RESENT_CONTRACT_STATUS = toEnum({
  D2D_ORDER_STATUS_INVALID: { text: '当前状态，不可重发协议！', value: 'd2d_order_status_invalid' },
  D2D_ORDER_TIME_OUT: { text: '订单已超时，请重新下单！', value: 'd2d_order_time_out' },
  D2D_ORDER_SMS_HELM_ERROR: { text: '协议发送失败，请稍后再试', value: 'd2d_order_sms_helm_error' },
  UNKNOWN_ERROR: { text: '系统异常，请稍后再试！', value: 'unknown_error' },
  D2D_ORDER_CANCELED_ERROR: { text: '当前状态，不可取消', value: 'd2d_order_canceled_error' },
});

export const REORDER_LIST = [OAB_STATUS.NO_CONFIRM.value, OAB_STATUS.NO_CONFIRM_SENT.value,
  OAB_STATUS.NO_ASSIGN_SA.value, OAB_STATUS.E_DISAGREE.value, OAB_STATUS.E_DRIVE_CANCELLED.value,
  OAB_STATUS.ORDER_BY_OTR.value, OAB_STATUS.E_DRIVE_ORDER_FAIL.value];

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import { RolePrivilege } from '../../../../../system-config/general/role-privilege/role-privilege';
import * as _ from 'lodash';
import { CustomEventEmitter } from '../../event/custom-event-emitter';

@Component({
  selector: 'app-privilege-config-cell',
  templateUrl: './privilege-config-cell.template.html',
  styleUrls: ['./privilege-config-cell.style.scss'],
})
export class PrivilegeConfigCellComponent implements ICellRendererAngularComp {
  public data: RolePrivilege;
  public shouldShowHoverStyle: boolean;
  private params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = params.value;
  }

  refresh(params: any): boolean {
    return false;
  }

  get eventEmitter(): CustomEventEmitter {
    return this.params.context.eventEmitter;
  }

  get cellRendererParams() {
    return this.params.colDef.cellRendererParams;
  }

  get unchecked() {
    return this.data && this.data.configurable && !this.data.enabled;
  }

  get checked() {
    return this.data && this.data.configurable && this.data.enabled;
  }

  get disabled() {
    return this.data && !this.data.configurable && this.data.enabled;
  }

  get unconfigable() {
    return this.data && !this.data.configurable && !this.data.enabled;
  }

  get canEdit() {
    return _.get(this.data, 'editable', true) && !this.unconfigable;
  }

  onCellClick() {
    if (!this.disabled && this.canEdit) {
      this.data.enabled = !this.data.enabled;
      this.cellRendererParams.onCellClick(this.data);
    }
  }

  changeCellStyle(event) {
    this.shouldShowHoverStyle = event.type === 'mouseover' && this.canEdit;
  }
}

import * as _ from 'lodash';

import {
  FILTER_INPUT_TYPES, SA_NAME_WITH_ALL_AGGREGATED_OPTIONS,
  SERVICE_RECORD_CATEGORY_WITH_ALL_AGGREGATED_OPTIONS,
  VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';

import { getMonthListByYearsFromNow, priceFormatter } from '../utils/utils';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';

export const MONTHLY_ORDER_OVERVIEW_CONFIG = {
  name: 'monthly-operating-statistics',
  enablePagination: false,
  exportedFileName: '月度工单状态总览.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '日期',
      field: 'dayOfMonth',
      width: 150,
      valueFormatter: ({ value }) => `${value}号`,
    },
    {
      headerName: '进车统计',
      field: 'checkedInCount',
      width: 150,
    },
    {
      headerName: '完工统计',
      field: 'clockOffCount',
      width: 150,
    },
    {
      headerName: '结账统计',
      field: 'invoicedCount',
      width: 150,
    },
    {
      headerName: '出车统计',
      field: 'checkedOutCount',
      width: 150,
    },
    {
      headerName: '未完工出车统计',
      field: 'nonCompletionCheckedOutCount',
      width: 150,
    },
    {
      headerName: '结账金额 (¥)',
      field: 'invoicedAmountBeforeTax',
      width: 150,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'month',
      label: '月份',
      options: getMonthListByYearsFromNow(3),
      defaultValue: _.head(getMonthListByYearsFromNow(3)),
    },
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '服务人员',
      options: SA_NAME_WITH_ALL_AGGREGATED_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_AGGREGATED_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'serviceRecordCategory',
      label: '订单类型',
      options: SERVICE_RECORD_CATEGORY_WITH_ALL_AGGREGATED_OPTIONS.values,
      defaultValue: SERVICE_RECORD_CATEGORY_WITH_ALL_AGGREGATED_OPTIONS.get('ALL'),
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型 :',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
  ],
};

'use strict';
import { IHeaderParams } from 'ag-grid';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

export interface SubTitle {
  headerName: string;
  field: string;
}

export interface MyHeader {
  id: string | number;
  title: string;
  subTitles: Array<SubTitle>;
  removable?: boolean;
  titleEditable?: boolean;
}

export interface GroupColumnHeaderParameter {
  addable?: boolean;
  expandable?: boolean;
  expanded?: BehaviorSubject<boolean>;
  add: () => MyHeader;
  remove?: (header: MyHeader) => void;
  headerList: Array<MyHeader>;
  delete?: (id) => void;
  expandCallback?: (expand) => void;
  isFirstLayer?: boolean;
  firstLayerDivider?: boolean;
  disabled?: boolean;
}

@Component({
  templateUrl: './group-column-header.html',
  styleUrls: ['./group-column-header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupColumnHeaderComponent implements IHeaderAngularComp {

  private params: IHeaderParams;

  headerList: Array<MyHeader>;
  columnParams: GroupColumnHeaderParameter;
  shouldShowPlus: boolean;
  disabled: boolean;

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.columnParams = (params.column.getColDef().headerComponentParams as GroupColumnHeaderParameter);
    this.headerList = this.columnParams.headerList;
    this.disabled = this.columnParams.disabled;
    if (this.columnParams.expanded === undefined) {
      this.columnParams.expanded = new BehaviorSubject(false);
    }

    this.columnParams.expanded.subscribe(expanded => this.setWidth(expanded));
    this.setShouldShowPlus(this.expanded);
  }

  get expanded() {
    return this.columnParams.expanded.getValue();
  }

  get notDeletedHeader() {
    return _.filter(this.headerList, header => !_.get(header, 'delete', false));
  }

  get headers() {
    return this.expanded ? this.notDeletedHeader : this.notDeletedHeader.slice(0, 1);
  }

  showExpandingIcon(index) {
    return (index === 0 && this.notDeletedHeader.length > 1) || this.columnParams.isFirstLayer;
  }

  toggleExpanded() {
    this.setShouldShowPlus(!this.expanded);
    this.columnParams.expanded.next(!this.expanded);
    if (this.columnParams.expandCallback) {
      this.columnParams.expandCallback(!this.expanded);
    }
  }

  addHeader() {
    this.columnParams.expanded.next(true);
    this.headerList.push(this.columnParams.add());
    this.setWidth(this.expanded);
  }

  removeHeader(removedHeader) {
    _.set(removedHeader, 'delete', true);
    if (this.columnParams.remove instanceof Function) {
      this.columnParams.remove(removedHeader);
    }
    this.columnParams.delete(removedHeader.id);
    this.setWidth(this.expanded);
  }

  setShouldShowPlus(expanded: boolean): void {
    this.shouldShowPlus = this.disabled ? false :
    (this.notDeletedHeader.length === 1 || (this.notDeletedHeader.length > 1 && expanded));
  }

  private setWidth(expanded: boolean) {
    const expandedPadding = this.columnParams.expandable ? 20 : 0;
    let actualWidth = this.headers.length * 180 + expandedPadding;

    if (this.columnParams.addable) {
      const addableExpandedPadding = this.headers.length > 1 ? 30 : 0;
      actualWidth = actualWidth + (this.columnParams.expandable && expanded ? addableExpandedPadding : 0);
    }

    this.params.columnApi.setColumnWidth(this.params.column, actualWidth, true);
  }

  onHeaderChange() {
    _.set(this.params.context, 'isEdited', true);
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/src/interfaces';

@Component({
  template: `
    <span>{{text}}</span>
    <i class="fa antd-question-circle-filled" *ngIf="message"
       [tooltip]="message"
       placement="bottom"
    ></i>
  `,
  styles: [
    `
      i {
        font-size: 14px;
        color: #979797;
        margin-left: 4px;
      }
    `,
  ],
})
export class SimpleTooltipRendererComponent implements ICellRendererAngularComp {
  public text: string;
  public message: string;
  public placement: string;

  agInit({ value }): void {
    this.text = value.text;
    this.message = value.message;
    this.placement = value.placement || 'bottom';
  }

  refresh(params: any): boolean {
    return false;
  }
}

import { AfterSalesLead, AfterSalesServiceProduct } from '../../model';

export enum ProductType {
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  MB_COLLECTION = 'MB_COLLECTION',
  SPPS = 'SPPS',
}

export enum OfferType {
  BASIC = 'BASIC',
  OPTIONAL = 'OPTIONAL',
}

export class ProductDto {
  product: Product;
  serviceProduct: ServiceProduct;

  constructor(product: Product, serviceProduct: ServiceProduct) {
    this.product = product;
    this.serviceProduct = serviceProduct;
  }

  static of(product: Product, serviceProduct: ServiceProduct) {
    return new ProductDto(product, serviceProduct);
  }
}

// for api get
export class Product {
  id: number;
  name: string;
  price: number;
  status: string;

  constructor(id: number, name: string, price: number) {
    this.id = id;
    this.name = name;
    this.price = price;
  }
}

// for api create
export class ServiceProduct {
  serviceProductId?: number;
  offerType: OfferType;
  isFree: boolean;
  serviceProductType: ProductType;

  constructor(serviceProductId: number, offerType: OfferType, isFree: boolean, serviceProductType: ProductType) {
    this.serviceProductId = serviceProductId;
    this.offerType = offerType;
    this.isFree = isFree;
    this.serviceProductType = serviceProductType;
  }

  static from(product: Product, type: ProductType): ServiceProduct {
    return new ServiceProduct(product.id, OfferType.BASIC, false, type);
  }

  static fromServiceProduct(product: AfterSalesServiceProduct): ServiceProduct {
    return new ServiceProduct(
      product.leadProductId,
      product.offerType === 'BASIC' ? OfferType.BASIC : OfferType.OPTIONAL,
      product.isFree,
      null,
    );
  }
}

// for api create
export class Lead {
  leadId?: string;
  leadType: string;
  vehiclePlateNumber: string;
  vehicleClassModelId: number;
  fin: string;
  vin: string;
  contactName: string;
  contactSalutation: string;
  contactMobile: string;
  followUpComments: string;
  followUpList: any[];
  nextFollowUpReason: string;
  nextFollowUpDate: string;
  isReserved: boolean;
  reservationDate: string;
  reservationComments: string;
  clientMobile: string;
  clientName: string;
  gsCode: string;
  cdoAccount: string;
  vehicleCategory: string;
  serviceProducts: ServiceProduct[];

  static from(lead: AfterSalesLead) {
    return Object.assign(new Lead(), lead);
  }
}

import { Injectable } from '@angular/core';
import { GET, RebirthHttp, POST, Body, Query } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class KPIFormulaApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/v2/approval-flow-config/parameter-list')
  getVariables(): Observable<any> {
    return null;
  }

  @GET('/api/v2/approval-flow-config/formula')
  getFormula(@Query('type') type): Observable<any> {
    return null;
  }

  @POST('/api/v2/approval-flow-config/formula')
  saveFormula(@Body data: any): Observable<any> {
    return null;
  }
}

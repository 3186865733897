import { Component } from '@angular/core';
import * as _ from 'lodash';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BehaviorSubject, combineLatest, isObservable, Observable } from 'rxjs';
import { Events, RowNode } from 'ag-grid';
import { filter, map } from 'rxjs/operators';

@Component({
  templateUrl: './checkbox-disabled-tooltiple.component.html',
  styleUrls: ['./checkbox-disabled-tooltiple.component.scss'],
})
export class CheckboxDisabledTooltipleComponent implements ICellRendererAngularComp {
  disabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  checked$: Observable<boolean>;
  selected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hideLabel: boolean;
  private params;

  agInit(params): void {
    this.params = params;

    params.api.addEventListener(Events.EVENT_ROW_SELECTED, ({ node }: { node: RowNode }) => {
      if (node.id === params.node.id) {
        this.selected$.next(node.isSelected());
      }
    });

    this.checked$ = combineLatest(this.disabled$, this.selected$).pipe(
      map(([disabled, selected]) => !disabled && selected),
    );

    combineLatest(this.disabled$, this.selected$).pipe(
      filter(([disabled, selected]) => disabled && selected),
    ).subscribe(() => params.node.setSelected(false));

    const isDisabled = _.get(params.colDef, 'isDisabled') || _.get(params.colDef, 'cellRendererParams.isDisabled');
    const disabledValue = _.isFunction(isDisabled) && isDisabled.apply(null, [params.data]);
    if (isObservable(disabledValue)) {
      disabledValue.subscribe(this.disabled$);
    } else {
      this.disabled$.next(disabledValue);
    }
    this.hideLabel = _.isNil(params.colDef.display) || (params.colDef.display && params.colDef.display(params.data));
    if (this.params.node) {
      this.params.node.selectThisNode(false);
    }
    if (params.colDef.defaultChecked && params.colDef.defaultChecked(params.data)) {
      this.params.node.selectThisNode(true);
    }
  }

  refresh(params): boolean {
    return false;
  }

  toggle(event) {
    this.params.node.selectThisNode(event.target.checked);
    if (this.params && this.params.colDef && this.params.colDef.toggle) {
      this.params.colDef.toggle(this.params, event.target.checked);
    }
  }
}

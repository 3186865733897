import { Injectable } from '@angular/core';
import { GET, POST, Path, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { VAN_DMS_STATUS } from '../constant/order';

interface VanBrandOrder {
  status: VAN_DMS_STATUS;
}

@Injectable()
export class VanBrandOrderApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/v2/orders/:orderId/van-dms-status')
  getVanDmsStatus(@Path('orderId') orderId: string): Observable<VanBrandOrder> {
    return null;
  }

  @POST('/api/v2/orders/:orderId/van-dms-invoice')
  updateVanDmsStatus(@Path('orderId') orderId: string): Observable<void> {
    return null;
  }
}

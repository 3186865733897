import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cancel-and-confirm-buttons',
  styleUrls: ['./cancel-and-confirm-buttons.style.scss'],
  templateUrl: './cancel-and-confirm-buttons.template.html',
})

export class CancelAndConfirmButtonsComponent {
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Input() cancelBtnText: string = '取消';
  @Input() submitBtnText: string = '确定';
  @Input() submitButtonType: string = 'button';

  handleCancel() {
    this.onCancel.emit();
  }

  handleSubmit() {
    this.onSubmit.emit();
  }
}

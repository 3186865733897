import { Pipe, PipeTransform } from '@angular/core';
import { dateParse } from '../utils/date-parse';

@Pipe({ name: 'dateParse' })
export class DateParserPipe implements PipeTransform {

  transform(value: any, format?: string) {
    return dateParse(value, format);
  }

}

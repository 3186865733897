import { Injectable } from '@angular/core';
import { Body, DELETE, GET, PATCH, Path, POST, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { ServiceCommissionFee } from '../models/service-commission-fee';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceCommissionFeeApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/service-fees')
  getServiceCommissionFees(): Observable<ServiceCommissionFee[]> {
    return null;
  }

  @POST('/api/service-fees')
  createServiceCommissionFee(@Body data): Observable<ServiceCommissionFee> {
    return null;
  }

  @DELETE('/api/service-fees')
  deleteServiceFee(@Body serviceFeeIds: number[]) {
    return null;
  }

  @PATCH('/api/service-fees/:id')
  updateServiceCommissionFee(@Path('id') id: number,
                             @Body data) {
    return null;
  }

  @GET('/api/sales-website/sale-config/freebie-config')
  getConfigData(): Observable<any> {
    return null;
  }
}

const CHINESE_MONTH_NAMES = [
  '一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月',
  '九月', '十月', '十一月', '十二月',
];

export const CHINESE_CALENDAR_CONFIG = {
  firstDayOfWeek: 1,
  dayNamesMin: ['日', '一', '二', '三', '四', '五', '六'],
  monthNames: CHINESE_MONTH_NAMES,
  monthNamesShort: CHINESE_MONTH_NAMES,
};

import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, takeUntil, tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { AutoUnsubscribe } from '../../utils/autoUnsubscribe';
import { ReminderLaborCode } from '../../../technical-and-maintenance/model/dedicatedTechnicalReminder';

@AutoUnsubscribe()
@Component({
  selector: 'app-virtual-scroll-multi-select-dropdown-list',
  templateUrl: './virtual-scroll-multi-select-dropdown-list.component.html',
  styleUrls: ['./virtual-scroll-multi-select-dropdown-list.component.scss'],
})
export class VirtualScrollMultiSelectDropdownListComponent implements OnInit, OnChanges {
  @Input() disabled: boolean = false;
  @Input() optionSubject: BehaviorSubject<Array<ReminderLaborCode>>;
  @Input() control: FormControl;
  @Input() searchable: boolean = false;
  @Input() isLoadFailed: boolean = false;
  @Input() placeholder: string = '';
  @Input() keywordPlaceholder: string = '';
  @Input() initialSelected: Array<string>;
  @Input() hasSelectAll: boolean = false;
  @Input() separator = ',';
  @ViewChild('viewport', { read: ElementRef }) viewport: ElementRef;
  SELECT_ALL: string = 'SELECT_ALL';

  keyword$: BehaviorSubject<string> = new BehaviorSubject('');
  filteredOptions: Array<ReminderLaborCode>;
  dropDownOpened: boolean = false;
  displayText: string = '';

  private componentDestroy$: Observable<any>;

  ngOnInit() {
    combineLatest(this.keyword$, this.optionSubject)
      .pipe(
        map(([keyword, options]) => this.filterOptions(options, keyword)),
        tap((filteredOptions) => {
          if (this.viewport) {
            this.viewport.nativeElement.style.height = `${_.min([filteredOptions.length * 34 + 50, 250])}px`;
          }
        }),
        takeUntil(this.componentDestroy$),
      )
      .subscribe((filteredOptions) => {
        this.filteredOptions = filteredOptions;
        this.initSelectedOptions();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initSelectedOptions();
  }

  closeDropDownList(event): void {
    event.stopPropagation();
    this.dropDownOpened = false;
    this.keyword$.next('');
  }

  get isEmpty(): boolean {
    return _.isEmpty(this.control.value);
  }

  getDisplayText(): void {
    const filteredOptions = _.filter(this.optionSubject.value, { selected: true });
    const text = this.hasSelectAll && _.some(filteredOptions, { laborOptionCode: this.SELECT_ALL })
      ? '全部' :
      _.chain(filteredOptions).map((optionValue: ReminderLaborCode) => optionValue.laborOptionDescription).join(this.separator).value();
    this.displayText = _.isEmpty(text) ? this.placeholder : text;
  }

  private getDefaultOptionText(filteredOptions: unknown[]) {
    return _.chain(filteredOptions).map((optionValue: ReminderLaborCode) => optionValue.laborOptionCode).join(this.separator).value();
  }

  filterOptions(options: Array<ReminderLaborCode>, keyword: string): Array<ReminderLaborCode> {
    return _.filter(options, (option) => _.includes(_.lowerCase(option.laborOptionDescription), _.lowerCase(keyword)));
  }

  handleFilter(event): void {
    event.stopPropagation();
    this.keyword$.next(event.target.value);
  }

  initSelectedOptions(): void {
    if (!_.isNil(this.initialSelected)) {
      _.forEach(this.filteredOptions, (option) => {
        option.selected = _.includes(this.initialSelected, option.laborOptionCode);
      });
      this.getDisplayText();
      this.setControlValue();
    }
  }

  handleClick($event, selectedOption: ReminderLaborCode): void {
    selectedOption.selected = !selectedOption.selected;
    this.updateSelectedOption(selectedOption);
    this.getDisplayText();
    this.setControlValue();
    this.initialSelected = null;
  }

  setControlValue(): void {
    const filteredOptions = _.filter(this.optionSubject.value, { selected: true });
    const controlText = this.hasSelectAll && _.some(filteredOptions, { laborOptionCode: this.SELECT_ALL })
      ? '全部' : this.getDefaultOptionText(filteredOptions);
    const controlValue = _.isEmpty(controlText) ? this.placeholder : controlText;
    this.control.setValue(controlValue === this.placeholder ? '' : controlValue.split(this.separator).join(','));
  }

  updateSelectedOption(selectedOption: ReminderLaborCode): void {
    const { laborOptionCode, laborOptionDescription, selected } = selectedOption;
    if (selectedOption.laborOptionCode === this.SELECT_ALL && this.hasSelectAll) {
      _.map(this.optionSubject.value, (option) => (option.selected = selected || false));
    } else {
      _.find(this.optionSubject.value, { laborOptionCode, laborOptionDescription }).selected = selected || false;
      if (this.hasSelectAll) {
        this.updateSelectAll();
      }
    }
  }

  updateSelectAll(): void {
    _.find(this.optionSubject.value, { laborOptionCode: this.SELECT_ALL }).selected = !_.some(
      this.optionSubject.value,
      (option) => option.laborOptionCode != this.SELECT_ALL && !option.selected,
    );
  }

  toggleDropDownList(): void {
    if (!_.isEmpty(this.filteredOptions) || this.isLoadFailed) {
      this.dropDownOpened = !this.dropDownOpened;
      this.keyword$.next('');
    }
  }

  preventEmitEvent(event: MouseEvent): void {
    event.stopPropagation();
  }

  isOptionSelected(option): void {
    return option.selected;
  }
}

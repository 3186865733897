import { empty as observableEmpty } from 'rxjs';

import { finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { DutyApi } from '../api/duty';
import { SalesConsultantModel } from '../models/sales-consultant';
import * as _ from 'lodash';
import { DUTY_STATUS } from '../constants/duty';
import { getDateAtZero } from '../utils/date';
import { PromptLevel } from '../constants/common';
import { DutyService } from './duty.service';

import { UserManagementService } from './user-management.service';
import { formatSalesConsultantDivisions } from '../utils/lead';
import {
  PromptBarService,
  StoreService,
  SpinnerService,
  FeatureToggleService,
  DealerIntegrationToggleService,
} from '@otr/website-common';
import { USER_ROLE } from '../constants/user-role.constant';

export const HALL_SC_SCHEDULE_LIST = 'hallScScheduleList';

@Injectable()
export class ScScheduleService {
  scScheduleList: SalesConsultantModel[];

  constructor(private storeService: StoreService,
              private dutyApi: DutyApi,
              private userManagementService: UserManagementService,
              private dutyService: DutyService,
              private promptBarService: PromptBarService,
              private featureToggleService: FeatureToggleService,
              private dealerIntegrationToggleService: DealerIntegrationToggleService,
              private spinnerService: SpinnerService) {
  }

  get toggleOn() {
    return this.featureToggleService.isModelDFeatureToggleEnable && this.dealerIntegrationToggleService.isModelDEnabled();
  }

  get onDutyScList() {
    return _.chain(this.scScheduleList)
      .filter({ duty_status: DUTY_STATUS.ON_DUTY.value })
      .reject({ role_code: USER_ROLE.PC.value })
      .value();
  }

  get offDutyScList() {
    return _.chain(this.scScheduleList)
      .filter({ duty_status: DUTY_STATUS.OFF_DUTY.value })
      .reject({ role_code: USER_ROLE.PC.value })
      .value();
  }

  fetchScSchedules(date?: Date) {
    this.userManagementService.getUsersByGroupId().subscribe();
    return this.dutyApi.getAllDutySchedule(getDateAtZero(date)).pipe(
      (this.spinnerService.loading()))
      .subscribe((dealerScScheduleList: SalesConsultantModel[]) => {
        this.scScheduleList = _.map(dealerScScheduleList, sc => ({
          ...sc,
          division: formatSalesConsultantDivisions(_.get(sc, 'division')),
        }));
        this.storeService.update(HALL_SC_SCHEDULE_LIST, this.scScheduleList);
      });
  }

  moveDutyStatus(date, userId, from, to) {
    if (from === to) {
      return observableEmpty();
    }
    const scSchedule = _.find(this.scScheduleList, { user_id: Number(userId) });
    scSchedule.duty_status = to;
    return this.dutyService
      .updateDuty(userId, getDateAtZero(date), to).pipe(
        tap(null, () => {
          this.promptBarService.show('更新失败', PromptLevel.Error);
          scSchedule.duty_status = from;
        }), finalize(() => {
          this.storeService.update(HALL_SC_SCHEDULE_LIST, this.scScheduleList);
        }));
  }

  getScOptions(currentShowroomVisitScId: string) {
    return _.isEmpty(currentShowroomVisitScId) ? [
      ...this.onDutyScList,
      ...this.offDutyScList,
    ] : _.unionBy(_.filter([
      _.find(this.scScheduleList, { gems_user_id: currentShowroomVisitScId }),
      ...this.onDutyScList,
      ...this.offDutyScList,
    ]), 'gems_user_id');
  }
}

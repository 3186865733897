import * as _pubsub from 'pubsub-js';

export const Pubsub = {
  subscribe(key, callback) {
    _pubsub.unsubscribe(key);
    return _pubsub.subscribe(key, callback);
  },

  publish(key, data) {
    _pubsub.publishSync(key, data);
  },

  unsubscribe(key) {
    _pubsub.unsubscribe(key);
  },
};

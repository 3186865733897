import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appDebounceClick]',
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input() debounceTime: number = 800;
  @Output() debounceClick = new EventEmitter();

  clicks = new Subject();
  ngDestroy = new Subject();

  ngOnInit() {
    this.clicks.pipe(
      debounceTime(this.debounceTime),
      takeUntil(this.ngDestroy),
    ).subscribe(event => this.debounceClick.emit(event));
  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}

import { Injectable } from '@angular/core';
import { Body, DELETE, GET, PATCH, Path, POST, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GetBmbsServiceFeeDTO } from '../model/get-bmbs-service-fee-DTO';

@Injectable()
export class BmbsServiceFeeApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/bmbs-service-fees')
  getBmbsServiceFees(): Observable<GetBmbsServiceFeeDTO[]> {
    return null;
  }

  @POST('/api/bmbs-service-fees')
  createBmbsServiceFee(@Body data): Observable<GetBmbsServiceFeeDTO> {
    return null;
  }

  @DELETE('/api/bmbs-service-fees')
  deleteBmbsServiceFee(@Body serviceFeeIds: number[]) {
    return null;
  }

  @PATCH('/api/bmbs-service-fees/:id')
  updateBmbsServiceFee(@Path('id') id: number,
                       @Body data) {
    return null;
  }
}

<div class="filter-box">
  <div class="input-container">
    <input
      class="form-control range-form-control range-left range-filter-width"
      [formControl]="startInput"
      [placeholder]="placeholder"
      appNumberFormat
      maxlength="255"
    >
    <span class="range-line"></span>
    <input
      class="form-control range-form-control range-right range-filter-width"
      [formControl]="endInput"
      [placeholder]="placeholder"
      appNumberFormat
      maxlength="255"
    >
  </div>
  <span class="btn-cancel" (click)="onCancel()">
    取消
  </span>
  <button
    class="btn-save range-btn"
    type="submit"
    (click)="onSubmit()"
    [disabled]="!isFilterValid()"
  >
    确定
  </button>
</div>

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

@Component({
  template: `
    <div class="text-wrapper">
      <p>{{ value }}</p>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
      }

      .text-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        padding: 0 10px;
        align-items: center;
      }

      p {
        color: #4a4a4a;
        margin: 0;
      }
    `,
  ],
})
export class PrivilegeSubHeaderRendererComponent implements ICellRendererAngularComp {
  public value: string;
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}

import {XLSX_EXCEL_2007_CONTENT_TYPE} from '../../_common/constants/export_content_type';
import {
  DROPDOWN_DATE_RANGE_OPTION_MONTH, DROPDOWN_DATE_RANGE_OPTION_QUARTER, DROPDOWN_DATE_RANGE_OPTION_YEAR,
  DROPDOWN_DATE_RANGE_OPTIONS_DAY,
  FILTER_INPUT_TYPES, MONTH_SEASON_AND_YEAR_REFRESH_TIME, REFRESH_TIME, VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import * as moment from 'moment';
import {DATE_FORMAT} from '../../_common/constants/common';
import { getAccidentCarSettingByEnvironment, percentageFormatter } from '../utils/utils';

export const ACCIDENT_CAR_RETENTION_SPECIALIST_OWNER_MANAGEMENT_CONFIG = {
  name: 'accident-car-retention-specialist-owner-management',
  enablePagination: true,
  pageSize: 50,
  filterThreeColumn: true,
  filterFourColumn: true,
  exportedFileName: '留修专员管理报表.xlsx',
  timeFormatter: 'YYYYMMDDHHmmss',
  isNameWithDate: true,
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  refreshTime: REFRESH_TIME,
  monthAndQuarterAndYearRefreshTime: MONTH_SEASON_AND_YEAR_REFRESH_TIME,
  defaultSearchWhenEnterReport: true,
  showSummaryLine: true,
  newLineOperation: true,
  isNameWithVehicleBusinessType: true,
  vertical: true,
  column: [
    {
      headerName: '时间',
      field: 'reportDate',
      width: 220,
      pinned: true,
    },
    {
      headerName: '留修专员',
      field: 'retentionSpecialistOwner',
      width: 220,
    },
    {
      headerName: '线索总量（去重后）',
      field: 'totalCount',
      width: 200,
    },
    {
      headerName: '3分钟内及时跟进量',
      field: 'followCountInThreeMinutes',
      width: 185,
    },
    {
      headerName: '3分钟内及时跟进率',
      field: 'followRateInThreeMinutes',
      width: 185,
      valueFormatter: percentageFormatter
    },
    {
      headerName: '跟进量',
      field: 'followCount',
      width: 120,
    },
    {
      headerName: '跟进率',
      field: 'followRate',
      width: 120,
      valueFormatter: percentageFormatter
    },
    {
      headerName: '留修量',
      field: 'arrivedCount',
      width: 120,
    },
    {
      headerName: '留修率',
      field: 'arrivedRate',
      width: 120,
      valueFormatter: percentageFormatter
    },
    {
      headerName: '24小时留修量',
      field: 'arrivedCountInTwentyFourHours',
      width: 160,
    },
    {
      headerName: '48小时留修量',
      field: 'arrivedCountInFortyEightHours',
      width: 160,
    },
    {
      headerName: '72小时留修量',
      field: 'arrivedCountInSeventyTwoHours',
      width: 160,
    },
    {
      headerName: '30天留修量',
      field: 'arrivedCountInThirtyDays',
      width: 160,
    },
    {
      headerName: '60天留修量',
      field: 'arrivedCountInSixtyDays',
      width: 160,
    },
    {
      headerName: '24小时留修率',
      field: 'arrivedRateInTwentyFourHours',
      width: 140,
      valueFormatter: ({ value }) => value == '-'  ? value : percentageFormatter({value}),
    },
    {
      headerName: '48小时留修率',
      field: 'arrivedRateInFortyEightHours',
      width: 140,
      valueFormatter: ({ value }) => value == '-'  ? value : percentageFormatter({value}),
    },
    {
      headerName: '72小时留修率',
      field: 'arrivedRateInSeventyTwoHours',
      width: 140,
      valueFormatter: ({ value }) => value == '-'  ? value : percentageFormatter({value}),
    },
    {
      headerName: '30天留修率',
      field: 'arrivedRateInThirtyDays',
      width: 140,
      valueFormatter: ({ value }) => value == '-'  ? value : percentageFormatter({value}),
    },
    {
      headerName: '60天留修率',
      field: 'arrivedRateInSixtyDays',
      width: 140,
      valueFormatter: ({ value }) => value == '-'  ? value : percentageFormatter({value}),
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DROPDOWN_DATE_RANGE,
      title: '选择时间',
      optionName: 'dimension',
      options: {
        ...DROPDOWN_DATE_RANGE_OPTIONS_DAY,
        ...DROPDOWN_DATE_RANGE_OPTION_MONTH,
        ...DROPDOWN_DATE_RANGE_OPTION_QUARTER,
        ...DROPDOWN_DATE_RANGE_OPTION_YEAR,
      },
      defaultOptionValue: DROPDOWN_DATE_RANGE_OPTIONS_DAY.DAY.value,
      resetWhenChangeOption: true,
      name: 'createdTime',
      isRangeLimited: true,
      allRequired: true,
      limitValueRange: 60,
      startMinLimitValue: getAccidentCarSettingByEnvironment('startMinLimitValue'),
      startLimitValue: moment(),
      endLimitValue: moment(),
      endMinLimitValue: getAccidentCarSettingByEnvironment('endMinLimitValue'),
      endMaxLimitValue: moment(),
      defaultBeginDate: moment().format(DATE_FORMAT),
      defaultEndDate: moment().format(DATE_FORMAT),
      itemWidth: 430,
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleBusinessType',
      label: '车辆类型',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
      className: 'vehicle-business-type',
      visible: true,
    },
  ],
};

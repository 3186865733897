import { toEnum } from 'app/_common/utils/enum';

export const TELEPHONIST_STATUS_OPTIONS = toEnum({
  CONSULT_CALL_IN: { text: 'CONSULT_CALL_IN' },
  CONSULT_CALL_OUT: { text: 'CONSULT_CALL_OUT' },
  TRANSFER_CALL_IN: { text: 'TRANSFER_CALL_IN' },
  TRANSFER_CALL_OUT: { text: 'TRANSFER_CALL_OUT' },
});

export const CUSTOMER_STATUS_OPTIONS = toEnum({
  CUSTOMER_CALL_IN: { text: 'CUSTOMER_CALL_IN' },
  CUSTOMER_CALL_OUT: { text: 'CUSTOMER_CALL_OUT' },
});

export const EVENT_STATUS_OPTIONS = toEnum({
  CALL: { text: 'CALL' },
  RINGING: { text: 'RINGING' },
  ANSWER: { text: 'ANSWER' },
  CALLING: { text: 'CALLING' },
});

import { Injectable } from '@angular/core';
import { GET, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleCascaderSelectApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/interested-brands?level=VARIANT')
  public fetchAllBrands(): Observable<any> {
    return null;
  }
}

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-invoice-allocation-row',
  templateUrl: './edit-delete-invoice-allocation.html',
  styleUrls: ['./edit-delete-invoice-allocation.component.scss'],
})
export class EditDeleteInvoiceAllocationRenderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onStartDeleting() {
    this.params.colDef.cellRendererParams.onStartDeleting(this.params);
  }
}

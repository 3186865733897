import { AgGridModule } from 'ag-grid-angular/main';
import { CheckboxCellRenderComponent } from './cell-renders/checkbox/checkbox-cell.component';
import { CustomCheckboxCellRenderComponent } from './cell-renders/custom-checkbox/custom-checkbox-cell.component';
import { IconRenderComponent } from '../icon/icon.component';
import { SvgIconRenderComponent } from '../icon/svg-icon.component';
import { CommissionNumberCellRenderComponent } from './cell-renders/commission-number/commission-number-cell.component';
import { InputCellRenderComponent } from './cell-renders/input/input-cell.component';
import { TextOrButtonComponent } from './cell-renders/text-or-button/text-or-button.component';
import { AppGridComponent } from './pagination-grid/grid.component';
import { ExtendedGridEvents } from './event/events';
import { SearchFilterComponent } from './filters/auto-filter/search-filter/search-filter.component';
import { SelectFilterComponent } from './filters/auto-filter/select-filter/select-filter.component';
import { SelectEditorCellComponent } from './editors/select/select-editor.component';
import { MultipleSelectEditorCellComponent } from './editors/multiple-select/multiple-select-editor.component';
import { BasePaginationFilterComponent } from './filters/pagination-filter/base-pagination-filter.component';
import { PaginationSelectComponent } from './filters/pagination-filter/pagination-select/pagination-select.component';
import {
  PaginationSelectFilterComponent,
} from './filters/pagination-filter/pagination-select-filter/pagination-select-filter.component';
import {
  PaginationSelectAndFuzzySearchFilterComponent,
} from './filters/pagination-filter/pagination-select-and-fuzzy-search-filter/pagination-select-and-fuzzy-search-filter.component';
import {
  PaginationSelectAndSearchFilterComponent,
} from './filters/pagination-filter/pagination-select-and-search-filter/pagination-select-and-search-filter.component';
import {
  PaginationSingleSelectFilterComponent,
} from './filters/pagination-filter/pagination-single-select-filter/pagination-single-select-filter.component';
import {
  PaginationSearchSelectFilterComponent,
} from './filters/pagination-filter/pagination-search-select-filter/pagination-search-select-filter.component';
import {
  PaginationRangeFilterComponent,
} from './filters/pagination-filter/pagination-range-filter/pagination-range-filter.component';
import {
  PaginationRangeNullFilterComponent,
} from './filters/pagination-filter/pagination-range-null-filter/pagination-range-null-filter.component';
import {
  PaginationRangeInputComponent,
} from './filters/pagination-filter/pagination-range-input/pagination-range-input.component';
import {
  PaginationRangeNullInputComponent,
} from './filters/pagination-filter/pagination-range-null-input/pagination-range-null-input.component';
import {
  PaginationRangeScoreInputComponent,
} from './filters/pagination-filter/pagination-range-score-input/pagination-range-score-input.component';
import {
  PaginationCalendarRangeComponent,
} from './filters/pagination-filter/pagination-calendar-range/pagination-calendar-range.component';
import {
  PaginationCalendarRangeNullComponent,
} from './filters/pagination-filter/pagination-calendar-range-null/pagination-calendar-range-null.component';
import { ActionCellEditorComponent } from './editors/action-cell/action-cell-component';
import { NumericEditorComponent } from './editors/number/numeric-editor.component';
import { EditRowRenderComponent } from './cell-renders/edit-row/edit-row.components';
import { DeleteRowRenderComponent } from './cell-renders/delete-row/delete-row.component';
import { TextButtonComponent } from './cell-renders/text-button/text-button.component';
import { HeaderEditorComponent } from './header/header-editor/header-editor.component';
import { RemovableClickEditHeaderComponent } from './header/removable-click-edit/removable-click-edit-header.component';
import { DeleteRowComponent } from './editors/delete-row/delete-row.component';
import { ActionCellRenderComponent } from './cell-renders/user-management-action-cell/action-cell-render.component';
import { TooltipsHeaderComponent } from './header/tooltip/tooltips-header.component';
import { NumberRangeFilterComponent } from './filters/auto-filter/number-range-filter/number-range-filter.component';
import { SelectorCellRenderComponent } from './cell-renders/selector/selector-cell.component';
import { CheckBoxHeaderComponent } from './header/checkbox/checkbox-header.component';
import {
  HeaderWithTooltipAndFilterComponent,
} from './header/header-with-tooltip-and-filter/header-with-tooltip-and-filter.component';
import { BasicInputEditorComponent } from './editors/basic-input/basic-input-editor.component';
import { DateCalendarEditorComponent } from './editors/date-calendar/date-calendar-editor.component';
import { PreSpaceRenderComponent } from './cell-renders/pre-space/pre-space.component';
import { SelectInputEditorCellComponent } from './editors/select-input/select-input-editor.component';
import { FullWidthRowRendererComponent } from './cell-renders/full-width-row/full-width-row-renderer.component';
import { PrivilegeConfigCellComponent } from './cell-renders/privilege-config-cell/privilege-config-cell.component';
import { TooltipRendererComponent } from './cell-renders/tooltip/tooltip-renderer.component';
import { GroupColumnHeaderComponent } from './header/group-column-header/group-column-header';
import { GroupColumnCellComponent } from './cell-renders/group-column-cell/group-column-cell.component';
import { MultiHeaderComponent } from './header/multi-header/multi-header.component';
import { SalesStrategyPlanComponent } from './cell-renders/sales-strategy-plan/sales-strategy-plan.component';
import { IconTextComponent } from './cell-renders/icon/icon.component';
import { TextEllipsisRenderComponent } from './cell-renders/text-ellipsis/text-ellipsis.component';
import { ImageRenderComponent } from './cell-renders/image/image.component';
import { FormulaCellRendererComponent } from './cell-renders/formula-cell/formula-cell-renderer.component';
import {
  PrivilegeSubHeaderRendererComponent,
} from './cell-renders/privilege-sub-header/privilege-sub-header-renderer.component';
import { LinkComponent } from './cell-renders/link/link.component';
import { LinkClickRendererComponent } from './cell-renders/link-click-renderer/link-click-renderer.component';
import { AuthorisationCellComponent } from './cell-renders/authorisation-cell/authorisation-cell.component';
import {
  AuthorisationColumnHeaderComponent,
} from './cell-renders/authorisation-column-header/authorisation-column-header.component';
import {
  AuthorisationColumnSubHeaderComponent,
} from './cell-renders/authorisation-column-sub-header/authorisation-column-sub-header.component';
import { SearchCheckboxEditorCellComponent } from './editors/search-checkbox/search-checkbox-cell.component';
import {
  SubSalesTypeActionCellComponent,
} from './cell-renders/sub-sales-type-action-cell/sub-sales-type-action-cell.component';
import { ActivateStatusCellComponent } from './cell-renders/activate-status-cell/activate-status-cell.component';
import {
  CalenderRangeFilterComponent,
} from './filters/auto-filter/calendar-range-filter/calendar-range-filter.component';
import {
  ModelYearChangeYearEditorComponent,
} from './editors/model-year-change-year-input/model-year-change-year-editor.component';
import { TagRendererComponent } from './cell-renders/tag/tag-renderer.component';
import {
  TextSelectSwitchRendererComponent,
} from './cell-renders/text-select-switch-renderer/text-select-switch-renderer.component';
import {
  TextIconClickRendererComponent,
} from './cell-renders/text-icon-click-renderer/text-icon-click-renderer.component';
import {
  TextInputSwitchRendererComponent,
} from './cell-renders/text-input-switch-renderer/text-input-switch-renderer.component';
import { ProductOperationCellComponent } from './cell-renders/product-operation-cell/product-operation-cell';
import { CustomLinkCellComponent } from './cell-renders/custom-link-cell/custom-link-cell';
import { SimpleTooltipRendererComponent } from './cell-renders/tooltip/simple-tooltip-renderer.component';
import {
  SalesLeadListActionCellRenderComponent,
} from './cell-renders/follow-up-list-action-cell/sales-lead-list-action-cell-render.component';
import {
  AgentLeadListActionCellRenderComponent,
} from './cell-renders/follow-up-list-action-cell/agent-lead-list-action-cell-render.component';
import { HoverFollowUpsComponent } from './cell-renders/hover-follow-ups/hover-follow-ups.component';
import { DropdownSelectComponent } from './editors/dropdown-select/dropdown-select.component';
import { NgModule } from '@angular/core';
import { BsDropdownModule, PaginationModule, TooltipModule } from 'ngx-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/primeng';
import { CommonComponentModule } from '../../common-component.module';
import { InlineSVGModule } from 'ng-inline-svg';
import {
  FollowupSearchCalendarRangeFilterComponent,
} from './filters/pagination-filter/followup-search-calendar-range-filter/followup-search-calendar-range-filter.component';
import {
  ManufacturerPartNumberCellRenderComponent,
} from './cell-renders/manufacturer-part-number/manufacturer-part-number-cell.component';
import { PartNumberRouteLinkComponent } from './cell-renders/part-number-route-link/part-number-route-link.component';
import {
  TrainingMaterialManagementActionCellComponent,
} from './cell-renders/training-material-management-action-cell/training-material-management-action-cell.component';
import { GroupHeaderTooltipsComponent } from './header/group-header-tooltip/group-header-tooltips.component';
import { CollapsibleRowCellComponent } from './cell-renders/collapsible-row-cell/collapsible-row-cell.component';
import { EditRowWithFlagComponent } from './cell-renders/edit-row-with-flag/edit-row-with-flag.component';
import { RadioButtonCellRendererComponent } from './cell-renders/radio-button/radio-button.component';
import { CustomCheckBoxHeaderComponent } from './header/checkbox/custom-checkbox-header.component';
import { EditDeleteRowRenderComponent } from './cell-renders/edit-delete-row/edit-delete-row.component';
import { CheckboxComponent } from './editors/checkbox/checkbox.component';
import {
  DropdownSelectionListComponent,
} from './cell-renders/dropdown-selection-list/dropdown-selection-list.component';
import {
  CustomizedActionCellEditorComponent,
} from './editors/customized-action-cell-editor/customized-action-cell-editor.component';
import {
  GoodsReceiveActionLinkComponent,
} from './cell-renders/goods-receive-action-link/goods-receive-action-link.component';
import { DeleteWithDisableComponent } from './cell-renders/delete-with-disable/delete-with-disable.component';
import {
  StockCheckInputQuantityCellRenderComponent,
} from './cell-renders/stock-check-input-quantity-cell/stock-check-input-quantity-cell.component';
import {
  EditDeleteInvoiceAllocationRenderComponent,
} from './cell-renders/edit-delete-invoice-allocation/edit-delete-invoice-allocation.component';
import { NgTemplateModule } from '@qlee/ng-template';
import { TemplateCellComponent } from 'app/_common/components/grid/cell-renders/template-cell/template-cell.component';
import {
  PaginationSearchSelectNullFilterComponent,
} from './filters/pagination-filter/pagination-search-select-null-filter/pagination-search-select-null-filter.component';
import {
  CustomClickableTextCellComponent,
} from './cell-renders/custom-clickable-text-cell/custom-clickable-text-cell.component';
import {
  PaginationCalendarFilterCellComponent,
} from './filters/pagination-filter/pagination-calendar-filter/pagination-calendar-filter-cell.component';
import {
  AdvancedTextEclipsisCellComponent,
} from '../../../courtesy-car/compoonent/advanced-text-eclipsis-cell-component/advanced-text-eclipsis-cell-component';
import {
  GroupHeaderStringTooltipsComponent,
} from './header/group-header-string-tooltip/group-header-string-tooltips-component.component';
import { CheckboxCell2RenderComponent } from './cell-renders/checkbox/checkbox-cell2.component';
import { CheckBoxHeader2Component } from './header/checkbox/checkbox-header2.component';
import { IconTootipleComponent } from './cell-renders/icon-tootiple/icon.component';
import { TextEllipsisTooltipComponent } from './cell-renders/text-ellipsis-tooltip/text-ellipsis-tooltip.component';
import {
  MultipleLinesEllipsisRenderComponent,
} from './cell-renders/multiple-lines-ellipsis/multiple-lines-ellipsis.component';
import { NewLineTooltipComponent } from './cell-renders/new-line-tooltip/new-line-tooltip.component';
import { IconTooltipComponent } from './cell-renders/icon-tooltip/icon-tooltip.component';
import { CheckRouterButtonComponent } from './cell-renders/check-router-button/check-router-button.component';
import {
  CustomCheckboxHeaderRenderComponent,
} from './cell-renders/custom-checkbox-header/custom-checkbox-header.component';
import {
  CheckboxDisabledTooltipleComponent,
} from './cell-renders/checkbox-disabled-tooltiple/checkbox-disabled-tooltiple.component';
import { UploadRecordsStatusComponent } from './cell-renders/upload-records-status/upload-records-status.component';
import { NzDatePickerModule, NzDropDownModule, NzToolTipModule } from 'ng-zorro-antd';
import { GridTooltipComponent } from './cell-renders/grid-tooltip/grid-tooltip.component';
import { SafeHtmlPipe } from 'app/_common/pipes/safe-html.pipe';
import {
  CustomerLaborSettingOperationComponent,
} from './cell-renders/customer-labor-setting-operation-cell/customer-labor-setting-operation-cell';
import { CheckboxInnerCellComponent } from './editors/checkboxInnerLine/checkbox.component';
import {
  PaginationCalendarMinuteRangeComponent,
} from './filters/pagination-filter/pagination-calendar-minute-range/pagination-calendar-minute-range.component';
import { AppFormModule } from '../form/app.form.module';
import { HistoryCallButtonComponent } from './cell-renders/history-call-button/history-call-button.component';
import { PlayDownloadCellRenderComponent } from './cell-renders/play-download-cell/play-download-cell.component';
import { ButtonTextComponent } from './cell-renders/button/button.component';
import {
  CustomClickableIconTextCellComponent,
} from './cell-renders/custom-clickabe-icon-text-cell/custom-clickable-icon-text-cell.component';
import {
  ApproveTagRendererComponent,
} from './cell-renders/vehicle-release-approve-tag-cell/approve-tag-renderer.component';
import { GroupedRowComponent } from './cell-renders/grouped-row/grouped-row.component';
import { ImageIconComponent } from './cell-renders/icon/image-icon.component';
import { ImageButtonComponent } from 'app/_common/components/grid/cell-renders/image-button/image-button.component';
import { NzDateCalendarComponent } from './editors/nz-date-calender/nz-date-calendar';
import { ReminderTagRendererComponent } from './cell-renders/reminder-status-cell/reminder-tag-renderer.component';
import { CellDropdownComponent } from './cell-renders/cell-dropdown/cell-dropdown.component';
import { UsedCarActionCellComponent } from './cell-renders/used-car-action-cell/UsedCarActionCellComponent';
import {
  CustomLabelCellComponent,
} from 'app/_common/components/grid/cell-renders/custom-label-cell/custom-label-cell.component';
import { TdAgreementIconComponent } from './cell-renders/td-agreement-icon/td-agreement-icon';
import { CustomRadioGroupComponent } from './cell-renders/custom-radio-group/custom-radio-group.component';
import {
  PrivacyAgreementCustomCellComponent,
} from './cell-renders/privacy-agreement-custom-cell/privacy-agreement-custom-cell.component';
import {
  CommercialInsuranceStatusCellComponent,
} from './../../../insurance-saas/insurance-customer/insurance-customer-list/components/commercial-insurance-status-cell-component/commercial-insurance-status-cell-component';
import { CustomLinkListCellComponent } from './cell-renders/custom-link-list-cell/custom-link-list-cell';
import { NewVehicleCellRenderComponent } from './cell-renders/new-vehicle/new-vehicle-cell-render.component';
import {
  TextEllipsisNzTooltipComponent,
} from './cell-renders/text-ellipsis-nz-tooltip/text-ellipsis-nz-tooltip.component';
import {
  ServiceSheetActionCellComponent,
} from './cell-renders/service-sheet-action-cell/service-sheet-action-cell.component';
import { DateRangeEditorComponent } from './editors/date-range-editor/date-range-editor.component';
import {
  TextWithLastAssignIconComponent,
} from '../../../oab/aftersales-reserve/component/text-with-last-assign-icon/text-with-last-assign-icon.component';

import {
  SccLeadAutoTransferActionCellComponent,
} from './cell-renders/ssc-lead-auto-transfer-action-cell/ssc-lead-auto-transfer-action-cell.component';
import { OabOrderStatusCellComponent } from './cell-renders/oab-order-status-cell/oab-order-status-cell.component';
import { MultiRowCellComponent } from './cell-renders/multi-row-cell/multi-row-cell.component';
import { DrivingServiceComponent } from './cell-renders/driving-service-cell/driving-service.component';

export const gridEvents = ExtendedGridEvents;

export const cellRenders = {
  CustomCheckboxHeaderRenderComponent,
  CustomCheckBoxHeaderComponent,
  CustomCheckboxCellRenderComponent,
  SimpleTooltipRendererComponent,
  CheckboxCellRenderComponent,
  CheckboxInnerCellComponent,
  CheckboxCell2RenderComponent,
  EditRowWithFlagComponent,
  IconRenderComponent,
  SvgIconRenderComponent,
  CommissionNumberCellRenderComponent,
  InputCellRenderComponent,
  SelectorCellRenderComponent,
  CheckBoxHeaderComponent,
  HeaderWithTooltipAndFilterComponent,
  CheckBoxHeader2Component,
  BasicInputEditorComponent,
  SelectEditorCellComponent,
  MultipleSelectEditorCellComponent,
  ActionCellEditorComponent,
  ActionCellRenderComponent,
  ServiceSheetActionCellComponent,
  CustomizedActionCellEditorComponent,
  NumericEditorComponent,
  EditRowRenderComponent,
  CellDropdownComponent,
  DeleteRowRenderComponent,
  DeleteWithDisableComponent,
  EditDeleteRowRenderComponent,
  CheckboxComponent,
  TextButtonComponent,
  TemplateCellComponent,
  TextOrButtonComponent,
  ImageButtonComponent,
  DateCalendarEditorComponent,
  DateRangeEditorComponent,
  NzDateCalendarComponent,
  DeleteRowComponent,
  PreSpaceRenderComponent,
  SelectInputEditorCellComponent,
  TooltipRendererComponent,
  FullWidthRowRendererComponent,
  PrivilegeConfigCellComponent,
  PrivilegeSubHeaderRendererComponent,
  GroupColumnHeaderComponent,
  GroupColumnCellComponent,
  SalesStrategyPlanComponent,
  FormulaCellRendererComponent,
  IconTextComponent,
  ButtonTextComponent,
  TextEllipsisRenderComponent,
  UploadRecordsStatusComponent,
  ImageRenderComponent,
  LinkComponent,
  LinkClickRendererComponent,
  AuthorisationCellComponent,
  AuthorisationColumnHeaderComponent,
  AuthorisationColumnSubHeaderComponent,
  SearchCheckboxEditorCellComponent,
  DropdownSelectComponent,
  ManufacturerPartNumberCellRenderComponent,
  PartNumberRouteLinkComponent,
  GridTooltipComponent,
  RadioButtonCellRendererComponent,
  DropdownSelectionListComponent,
  GoodsReceiveActionLinkComponent,
  StockCheckInputQuantityCellRenderComponent,
  EditDeleteInvoiceAllocationRenderComponent,
  IconTootipleComponent,
  TextEllipsisTooltipComponent,
  TextEllipsisNzTooltipComponent,
  NewLineTooltipComponent,
  IconTooltipComponent,
  CheckRouterButtonComponent,
  CheckboxDisabledTooltipleComponent,
  MultipleLinesEllipsisRenderComponent,
  HistoryCallButtonComponent,
  PlayDownloadCellRenderComponent,
  GroupedRowComponent,
  ImageIconComponent,
  UsedCarActionCellComponent,
  CustomLabelCellComponent,
  TdAgreementIconComponent,
  CustomRadioGroupComponent,
  TextWithLastAssignIconComponent,
  MultiRowCellComponent,
  DrivingServiceComponent,
};

export const filterRenders = {
  BasePaginationFilterComponent,
  SearchFilterComponent,
  SelectFilterComponent,
  PaginationSelectComponent,
  PaginationSelectFilterComponent,
  PaginationSelectAndFuzzySearchFilterComponent,
  PaginationSelectAndSearchFilterComponent,
  PaginationSingleSelectFilterComponent,
  PaginationRangeFilterComponent,
  PaginationRangeNullFilterComponent,
  PaginationRangeInputComponent,
  PaginationRangeNullInputComponent,
  PaginationRangeScoreInputComponent,
  PaginationSearchSelectNullFilterComponent,
  PaginationCalendarRangeComponent,
  PaginationCalendarRangeNullComponent,
  PaginationCalendarFilterCellComponent,
  NumberRangeFilterComponent,
  CalenderRangeFilterComponent,
  PaginationSearchSelectFilterComponent,
  FollowupSearchCalendarRangeFilterComponent,
  PaginationCalendarMinuteRangeComponent,
};

export const agGridComponents = [
  CustomCheckboxHeaderRenderComponent,
  CustomCheckBoxHeaderComponent,
  CustomCheckboxCellRenderComponent,
  SimpleTooltipRendererComponent,
  CheckboxCellRenderComponent,
  CheckboxInnerCellComponent,
  CheckboxCell2RenderComponent,
  EditRowWithFlagComponent,
  IconRenderComponent,
  SvgIconRenderComponent,
  CommissionNumberCellRenderComponent,
  InputCellRenderComponent,
  SelectorCellRenderComponent,
  BasePaginationFilterComponent,
  SearchFilterComponent,
  PaginationSelectComponent,
  PaginationSelectFilterComponent,
  PaginationSelectAndFuzzySearchFilterComponent,
  PaginationSelectAndSearchFilterComponent,
  PaginationSearchSelectNullFilterComponent,
  PaginationSingleSelectFilterComponent,
  PaginationRangeFilterComponent,
  PaginationRangeNullFilterComponent,
  PaginationRangeInputComponent,
  PaginationRangeNullInputComponent,
  PaginationRangeScoreInputComponent,
  PaginationCalendarRangeComponent,
  PaginationCalendarRangeNullComponent,
  PaginationCalendarFilterCellComponent,
  BasicInputEditorComponent,
  SelectFilterComponent,
  SelectEditorCellComponent,
  MultipleSelectEditorCellComponent,
  ActionCellEditorComponent,
  ServiceSheetActionCellComponent,
  CustomizedActionCellEditorComponent,
  NumericEditorComponent,
  CheckBoxHeaderComponent,
  HeaderWithTooltipAndFilterComponent,
  CheckBoxHeader2Component,
  EditRowRenderComponent,
  CellDropdownComponent,
  EditDeleteRowRenderComponent,
  CheckboxComponent,
  ActionCellRenderComponent,
  DeleteRowRenderComponent,
  DeleteWithDisableComponent,
  TextButtonComponent,
  TemplateCellComponent,
  TextOrButtonComponent,
  ImageButtonComponent,
  DateCalendarEditorComponent,
  DateRangeEditorComponent,
  NzDateCalendarComponent,
  HeaderEditorComponent,
  RemovableClickEditHeaderComponent,
  DeleteRowComponent,
  TooltipsHeaderComponent,
  NumberRangeFilterComponent,
  PreSpaceRenderComponent,
  SelectInputEditorCellComponent,
  TooltipRendererComponent,
  FullWidthRowRendererComponent,
  PrivilegeConfigCellComponent,
  PrivilegeSubHeaderRendererComponent,
  GroupColumnHeaderComponent,
  GroupColumnCellComponent,
  MultiHeaderComponent,
  SalesStrategyPlanComponent,
  IconTextComponent,
  ButtonTextComponent,
  IconTootipleComponent,
  TextEllipsisTooltipComponent,
  TextEllipsisNzTooltipComponent,
  MultipleLinesEllipsisRenderComponent,
  NewLineTooltipComponent,
  IconTooltipComponent,
  CheckRouterButtonComponent,
  TextEllipsisRenderComponent,
  UploadRecordsStatusComponent,
  ImageRenderComponent,
  FormulaCellRendererComponent,
  LinkComponent,
  LinkClickRendererComponent,
  AuthorisationCellComponent,
  AuthorisationColumnHeaderComponent,
  AuthorisationColumnSubHeaderComponent,
  SearchCheckboxEditorCellComponent,
  SubSalesTypeActionCellComponent,
  TrainingMaterialManagementActionCellComponent,
  SccLeadAutoTransferActionCellComponent,
  ActivateStatusCellComponent,
  AdvancedTextEclipsisCellComponent,
  CalenderRangeFilterComponent,
  ModelYearChangeYearEditorComponent,
  TagRendererComponent,
  ApproveTagRendererComponent,
  TextSelectSwitchRendererComponent,
  TextIconClickRendererComponent,
  TextInputSwitchRendererComponent,
  ProductOperationCellComponent,
  CustomLinkCellComponent,
  CustomLinkListCellComponent,
  CustomClickableTextCellComponent,
  CustomClickableIconTextCellComponent,
  SalesLeadListActionCellRenderComponent,
  AgentLeadListActionCellRenderComponent,
  HoverFollowUpsComponent,
  DropdownSelectComponent,
  PaginationSearchSelectFilterComponent,
  FollowupSearchCalendarRangeFilterComponent,
  ManufacturerPartNumberCellRenderComponent,
  PartNumberRouteLinkComponent,
  GridTooltipComponent,
  GroupHeaderTooltipsComponent,
  CollapsibleRowCellComponent,
  RadioButtonCellRendererComponent,
  DropdownSelectionListComponent,
  GoodsReceiveActionLinkComponent,
  StockCheckInputQuantityCellRenderComponent,
  EditDeleteInvoiceAllocationRenderComponent,
  GroupHeaderStringTooltipsComponent,
  CheckboxDisabledTooltipleComponent,
  CustomerLaborSettingOperationComponent,
  PaginationCalendarMinuteRangeComponent,
  HistoryCallButtonComponent,
  PlayDownloadCellRenderComponent,
  GroupedRowComponent,
  ImageIconComponent,
  ReminderTagRendererComponent,
  UsedCarActionCellComponent,
  CustomLabelCellComponent,
  TdAgreementIconComponent,
  CustomRadioGroupComponent,
  PrivacyAgreementCustomCellComponent,
  CommercialInsuranceStatusCellComponent,
  NewVehicleCellRenderComponent,
  TextWithLastAssignIconComponent,
  OabOrderStatusCellComponent,
  MultiRowCellComponent,
  DrivingServiceComponent,
];

export const GridComponentProviders = [AppGridComponent, ...agGridComponents];

export const GridComponentModule = AgGridModule.withComponents(agGridComponents);

@NgModule({
  imports: [
    NgTemplateModule,
    CommonModule,
    CommonComponentModule,
    PaginationModule,
    FormsModule,
    RouterModule,
    TooltipModule,
    BsDropdownModule,
    ReactiveFormsModule,
    CalendarModule,
    InlineSVGModule,
    GridComponentModule,
    NzToolTipModule,
    AppFormModule,
    NzDatePickerModule,
    NzDropDownModule,
  ],
  declarations: [GridComponentProviders, SafeHtmlPipe],
  exports: GridComponentProviders,
})
export class GridModule {
}

import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { CHINESE_CALENDAR_CONFIG } from '../../constants/calendar';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range',
  templateUrl: 'date-range.template.html',
  styleUrls: ['date-range.style.scss'],
})
export class DateRangeComponent implements OnInit, OnDestroy {
  chineseCalendarConfig = CHINESE_CALENDAR_CONFIG;

  @Input()
  view?: string = 'date';

  @Input()
  tooltipValue?: string;

  @Input()
  isRangeLimited?: boolean = false;

  @Input()
  title?: string;
  @Input()
  isUnitDays?: boolean = false;

  @Input()
  isLimitByYear?: boolean = false;

  @Input()
  startDateControl: FormControl;

  @Input()
  endDateControl: FormControl;

  @Input()
  limitValueRange?: number = 3;

  @ViewChild('startDate') startDateCalendar;
  @ViewChild('endDate') endDateCalendar;
  calendarConfig: any = {};

  @Input()
  endLimitValue?: Date;

  @Input()
  startLimitValue?: Date;

  @Input()
  startMinLimitValue?: Date;

  @Input()
  endMinLimitValue?: Date;

  @Input()
  endMaxLimitValue?: Date;

  @Input()
  startTimeRequired?: boolean = true;

  @Input()
  endTimeRequired?: boolean = true;

  @Input()
  allRequired?: boolean = false;

  @Input()
  readonlyInput?: boolean = false;

  @Input()
  limitEvenIfEmpty?: boolean = false;

  @Input()
  invalidStyle = {
    'border-color': '#ff3267',
  };

  dateFormat: string = 'yy-mm-dd';

  placeholder: string = 'YYYY-MM-DD';

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnInit() {
    this.calendarConfig = {
      start: {
        defaultValue: null,
        element: this.startDateCalendar,
        limitValue: this.startMinLimitValue,
        limitValueRange: -this.limitValueRange,
      },
      end: {
        defaultValue: null,
        element: this.endDateCalendar,
        limitValue: this.endLimitValue,
        limitValueRange: this.limitValueRange,
      },
    };

    this.startDateControl.setValidators(() => {
      if (this.startTimeRequired && !this.startDateControl.value && !!this.endDateControl.value) {
        return { required: true };
      }

      if (this.allRequired && !this.startDateControl.value) {
        return { required: true };
      }

      const startCalendar = this.calendarConfig.start;
      if (this.isDateOutOfRange(this.startDateControl.value, startCalendar.defaultValue, startCalendar.limitValue)) {
        return { outOfDateRange: true };
      }
    });
    this.endDateControl.setValidators(() => {
      if (this.endTimeRequired && !!this.startDateControl.value && !this.endDateControl.value) {
        return { required: true };
      }

      if (this.allRequired && !this.endDateControl.value) {
        return { required: true };
      }

      const endCalendar = this.calendarConfig.end;
      if (this.isDateOutOfRange(this.endDateControl.value, endCalendar.limitValue, endCalendar.defaultValue)) {
        return { outOfDateRange: true };
      }
    });
    this.initSubscriber();
    this.initDateFormatAndPlaceholder();
  }

  reset() {
    this.startDateCalendar.el.nativeElement.childNodes[1].children[0].value = '';
    this.endDateCalendar.el.nativeElement.childNodes[1].children[0].value = '';
    this.startDateCalendar._isValid = true;
    this.endDateCalendar._isValid = true;
    this.startDateControl.setValue(null);
    this.endDateControl.setValue(null);
  }

  initSubscriber() {
    this.startDateControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      if (value === '') {
        this.startDateControl.setValue(null);
      }
      const isDateInRange = !this.isDateOutOfRange(value, this.calendarConfig.start.defaultValue, this.calendarConfig.start.limitValue);
      this.updateWhenRangeLimited(value, this.calendarConfig.end, isDateInRange);
      this.endDateControl.updateValueAndValidity({ emitEvent: false });
    });

    this.endDateControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      if (value === '') {
        this.endDateControl.setValue(null);
      }
      const isDateInRange = !this.isDateOutOfRange(value, this.calendarConfig.end.limitValue, this.calendarConfig.end.defaultValue);
      this.updateWhenRangeLimited(value, this.calendarConfig.start, isDateInRange);
      this.startDateControl.updateValueAndValidity({ emitEvent: false });
    });
  }

  isValidDateRange() {
    return !(this.startDateControl && this.endDateControl) || (this.startDateControl.valid && this.endDateControl.valid);
  }

  shouldShowOutOfDateRangeMessage(control): boolean {
    return !!control.validator() && control.validator().outOfDateRange;
  }

  onStartDateBlur(event) {
    if (_.isEmpty(event.target.value) && this.startDateCalendar._isValid === false) {
      this.startDateCalendar._isValid = true;
      this.startDateControl.setValue(null);
    }
  }

  onEndDateBlur(event) {
    if (_.isEmpty(event.target.value) && this.startDateCalendar._isValid === false) {
      this.endDateCalendar._isValid = true;
      this.endDateControl.setValue(null);
    }
  }

  initDateFormatAndPlaceholder() {
    switch (this.view) {
      case 'month': {
        this.dateFormat = 'yy-mm';
        this.placeholder = 'YYYY-MM';
        return;
      }
      case 'date': {
        this.dateFormat = 'yy-mm-dd';
        this.placeholder = 'YYYY-MM-DD';
        return;
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private isDateOutOfRange(dateValue, maxDate, minDate): boolean {
    return (
      this.isRangeLimited &&
      !!dateValue &&
      this.isDateType(moment(dateValue).format(this.placeholder)) &&
      ((!!minDate && minDate.isAfter(dateValue, this.view === 'date' ? 'day' : this.view)) ||
        (!!maxDate && maxDate.isBefore(dateValue, this.view === 'date' ? 'day' : this.view)))
    );
  }

  private updateWhenRangeLimited(value, calendar, isDateInRange) {
    if (!this.isRangeLimited) {
      return;
    }
    if (value === '' || value === null) {
      calendar.defaultValue = null;
      if (!this.limitEvenIfEmpty) {
        calendar.limitValue = null;
      }
    } else if (isDateInRange) {
      calendar.defaultValue = moment(value);
      calendar.limitValue = moment(value).add(calendar.limitValueRange, this.isLimitByYear ? 'years' : 'months');

      if (this.isUnitDays) {
        calendar.limitValue = moment(value).add(calendar.limitValueRange, 'days');
      }
    }
    calendar.element.updateUI();
  }

  private isDateType(value: string): boolean {
    return !!value.match('^\\d{4}\\-(0?[1-9]|1[012])\\-?(0?[1-9]|[12][0-9]|3[01])?$');
  }
}

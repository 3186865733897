import { ICellEditorAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import { ExtendedGridEvents } from '../event/events';
import { ICellEditorParams } from 'ag-grid';

const NAVIGATION_KEYS = [13, 37, 38, 39, 40];

export abstract class BasicEditorComponent implements ICellEditorAngularComp {
  protected params: ICellEditorParams;
  protected cellEditorParams;
  public errorMessage: string;
  public value: any;
  public shouldShowErrorMessage = true;

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.cellEditorParams = this.getCellEditorParams();
    this.value = _.isNil(this.params.value) ? '' : this.params.value;
    if (this.cellEditorParams.shouldShowErrorMessage === false) {
      this.shouldShowErrorMessage = false;
    }

    this.errorMessage = this.getErrorMessage();
    this.eventEmitter.on(ExtendedGridEvents.SHOW_ERROR_MESSAGE, () => {
      this.errorMessage = this.getErrorMessage();
    });
    this.eventEmitter.on(ExtendedGridEvents.UPDATE_CELL_EDITOR, event => {
      if (_.has(event, this.colId)) {
        const eventValue: any = _.get(event, this.colId, {});
        this.cellEditorParams = { ...this.cellEditorParams, ...eventValue };
        this.onUpdateEvent(eventValue.value);
      }
    });
  }

  get tooltip() {
    return this.cellEditorParams.tooltip || '';
  }

  get colId() {
    return this.params.column.getColId();
  }

  get rowIndex() {
    return this.params.rowIndex;
  }

  onUpdateEvent(value?) {
  }

  getCellEditorParams() {
    return this.params.column.getColDef().cellEditorParams || {};
  }

  getValue(): any {
    return this.value;
  }

  onChange(event) {
    this.updateSourceValue(event);
  }

  getErrorMessage() {
    return (this.cellEditorParams.getErrorMessage || _.noop)(this.colId, this.rowIndex);
  }

  get onChangeHandler() {
    return this.cellEditorParams.onChange || _.noop;
  }

  get onBlurHandler() {
    return this.cellEditorParams.onBlur || _.noop;
  }

  get onAfterChangedHandler() {
    return this.cellEditorParams.onAfterChanged || _.noop;
  }

  get eventEmitter() {
    return this.params.context.eventEmitter;
  }

  protected updateSourceValue(value = this.value) {
    _.set(this.params.node.data, this.colId, value);
  }

  onKeyDown(event) {
    if (_.includes(NAVIGATION_KEYS, this.getCharCodeFromEvent(event))) {
      event.stopPropagation();
    } else {
      this.onOtherKeyDown(event);
    }
  }

  protected getCharCodeFromEvent(event): any {
    const wrappedEvent = event || window.event;
    return (wrappedEvent.which === undefined) ? wrappedEvent.keyCode : wrappedEvent.which;
  }

  protected getKeyFromEvent(event): any {
    return (event || window.event).key;
  }

  protected onOtherKeyDown(event) {
  }
}

import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  selector: 'app-service-sheet-action-cell',
  templateUrl: './service-sheet-action-cell.component.html',
  styleUrls: ['./service-sheet-action-cell.component.scss']
})
export class ServiceSheetActionCellComponent implements ICellRendererAngularComp {

  constructor() { }
  params: any;
  agInit(params): void {
    this.params = params;
  }

  onPreview(): void {
    const onPreview = _.get(this.params.colDef, 'cellRendererParams.onPreview');
    const data = _.get(this.params, 'data');
    return onPreview(data);
  }
  onEdit(): void {
    const onEdit = _.get(this.params.colDef, 'cellRendererParams.onEdit');
    const data = _.get(this.params, 'data');
    return onEdit(data);
  }

  onDelete(): void {
    const onDelete = _.get(this.params.colDef, 'cellRendererParams.onDelete');
    const data = _.get(this.params, 'data');
    return onDelete(data);
  }
  refresh(params: any): boolean {
    return false;
  }
}

import { Injectable } from '@angular/core';
import { TabGroup } from '../../_layout/tab-for-parts-purchase/tab.service';
import { isVanForSameDealer } from '../utils/vanEnable';
import { CurrentUserService, DealerIntegrationToggleService, FeatureToggleService } from '@otr/website-common';
import * as _ from 'lodash';

export interface TabServiceTabType {
  title: string;
  href: string;
  params?: {
    [key: string]: string;
  };
  isCloseable?: boolean;
  beforeClose?: Function;
  data?: any;
}

export enum TabTypes {
  ACCOUNTING_BOOK_IN_LIST,
  ACCOUNTING_INVOICE_OPTION_INVOICE,
  ACCOUNTING_INVOICE_PARTS_INVOICE,
  ACCOUNTING_MASTERDATA_SUPPLIER_NO,
  ACCOUNTING_MASTERDATA_CUSTOMER_TYPE,
  ACCOUNTING_SALES_INVOICE,
  ACCOUNTING_SALES_INVOICED,
  ACCOUNTING_VEHICLE_INVOICE,
  ACCOUNTING_AFTERSALES_INVOICE,
  ACCOUNTING_AFTERSALES_CLOSED_INVOICE,
  ACCOUNTING_BMI3_TEMPLATE,
  ACCOUNTING_BMI3_TXT,
  AFTER_SALES_LEAD,
  AS_LEAD_CONFIG,
  BMBS_SERVICE_CONTRACT,
  CAMPAIGN_TOOLKIT,
  CASE_LIST,
  CLAIM_DEALER_MANAGEMENT,
  CLAIM_DISCOUNT,
  DEALER_TYPE,
  COUPON,
  PDI,
  DATA_EXPORT,
  DEALER_PRICE_PROPOSAL,
  EXPENSE_SETTLE,
  LEAD_LIST,
  MB_CAMPAIGN,
  MESSAGE_CENTER,
  OEM_PRODUCT_CONFIG,
  OEM_SERVICE_CONTRACT_HISTORY,
  OEM_SSSC_HISTORY,
  ORDER_AUDIT,
  ORDER_MANAGEMENT,
  PARTS_MASTER_PRICE,
  PARTS_MASTER_PRICE_VAN,
  PARTS_PURCHASE_ADDRESS_MAINTENANCE,
  PARTS_PURCHASE_DEALER_PARAMETER,
  PARTS_PURCHASE_DEALER_PARAMETER_VAN,
  PARTS_PURCHASE_GOODS_RECEIVE,
  PARTS_PURCHASE_PARTS_BIN_TRANSFER,
  PARTS_PURCHASE_PARTS_BIN_TRANSFER_VAN,
  PARTS_PURCHASE_PARTS_RECEIVE,
  PARTS_PURCHASE_PARTS_RECEIVE_VAN,
  PARTS_PURCHASE_PURCHASE_ORDER,
  PARTS_PURCHASE_PURCHASE_ORDER_VAN,
  PARTS_PURCHASE_PURCHASE_ORDER_LINE,
  PARTS_PURCHASE_PURCHASE_ORDER_LINE_VAN,
  PARTS_PURCHASE_PURCHASE_ORDER_SUBMISSION,
  PARTS_PURCHASE_PURCHASE_ORDER_SUBMISSION_VAN,
  PARTS_SALES,
  PARTS_SALES_VAN,
  PARTS_SALES_CUSTOMER,
  PARTS_SALES_CUSTOMER_VAN,
  PARTS_SALES_SEARCH_CUSTOMER,
  PARTS_SALES_SEARCH_CUSTOMER_VAN,
  PARTS_SETTINGS_PRICE_MOVEMENT_TYPE,
  PARTS_SETTINGS_ADDRESS_MAINTENANCE,
  PARTS_SETTINGS_SUBMISSION_PARAMETER,
  PARTS_SETTINGS_CONFI_PARAMETER,
  PARTS_SETTINGS_ICT_MAPPING,
  PARTS_SETTINGS_DIMS_MANUAL_EXPORT,
  PARTS_SETTINGS_MASTER_PRICE_PARAM,
  PARTS_SETTINGS_MARGIN_CODE_MAINTENANCE,
  PARTS_SETTINGS_BUYBACK_PARAMETER,
  PARTS_SETTINGS_PARTS_PROFILE_UPDATE_PARAM,
  PARTS_SETTING_DISCOUNT,
  PARTS_SETTING_DISCOUNT_CODE,
  PARTS_SETTINGS_BATCH_PROCESSING_FILES,
  PARTS_PROFILES,
  PARTS_PROFILES_VAN,
  PRE_ORDER_MANAGEMENT,
  RETAIL_CAMPAIGN,
  SERVICE_CONTRACT_PRICE_CONFIG,
  SERVICE_CONTRACT_HISTORY,
  SSSC_HISTORY,
  SSSC_SALES_RECORD,
  STAR_D_HISTORY,
  VEHICLE_DATA_SYNCHRONIZATION,
  VEHICLE_DMS_MATCH,
  VEHICLE_MANAGEMENT,
  DIGITAL_SALES_CAMPAIGN,
  VEHICLE_SHARE,
  STOCK_TAKING,
  WARRANTY_CLAIM_NUMBER_LIST,
  WARRANTY_CLAIM_RECORD_LIST,
  WARRANTY_REPLACED_PARTS,
  WARRANTY_NO_CLAIM_NUMBER_LIST,
  WARRANTY_CLAIM_ORDER_LIST,
  MOT_UPLOAD_SERVICE_HISTORY,
  MOT_UPLOAD_RECORD,
  MOT_SERVICE_HISTORY_FUEL_TYPE_MAINTENANCE,
  SECOND_MOT_UPLOAD_LIST,
  PARTS_PURCHASE_CONFI_IMPORT,
  PARTS_PURCHASE_CONFI_IMPORT_VAN,
  PARTS_STOCK_CHECK,
  PARTS_STOCK_CHECK_VAN,
  ALLIANZ_WARNING,
  PARTS_PURCHASE_INVOICE_VERIFICATION,
  PARTS_PURCHASE_INVOICE_VERIFICATION_VAN,
  CUSTOMER_MANAGEMENT,
  CUSTOMER_PHONE_CALL_HISTORY,
  SALES_LEAD_CONFIG,
  GROUP_COMPLAINT_REPORT,
  GROUP_CUSTOM_LABOR,
  CUSTOMER_CRITERIA_CONFIG,
  PARTS_DEALER_SETTING,
  PARTS_DEALER_SETTING_VAN,
  PARTS_DEALER_SETTING_DISCOUNT,
  PARTS_DEALER_SETTING_DISCOUNT_VAN,
  PARTS_DEALER_SETTING_DISCOUNT_CODE,
  PARTS_DEALER_SETTING_DISCOUNT_CODE_VAN,
  PARTS_DEALER_SETTING_DISCOUNT_RIGHT,
  PARTS_DEALER_SETTING_DISCOUNT_RIGHT_VAN,
  PARTS_DEALER_SETTING_DISCOUNT_RANGE,
  PARTS_DEALER_SETTING_DISCOUNT_RANGE_VAN,
  SERVICE_ORDER,
  MAINTENANCE_HISTORY,
  PDI_CHECK_HISTORY,
  HVB_RECORD,
  ICT_LIST,
  ICT_LIST_VAN,
  FINANCE_MANAGEMENT_REPORTING_OVERVIEW,
  CUSTOMER_MANAGEMENT_REPORTING_OVERVIEW,
  ACCIDENT_CAR_REPORTING_OVERVIEW,
  FRONT_DESK_MANAGEMENT_REPORTING_OVERVIEW,
  OTHERS_MANAGEMENT_REPORTING_OVERVIEW,
  WARRANTY_MANAGEMENT_REPORTING_OVERVIEW,
  PARTS_MANAGEMENT_REPORTING_OVERVIEW,
  WORKSHOP_MANAGEMENT_REPORTING_OVERVIEW,
  PAPERLESS_MANAGEMENT_REPORTING_OVERVIEW,
  INSURANCE_MANAGEMENT_REPORTING_OVERVIEW,
  COURTESY_CAR,
  CLINCH_CUSTOMER_TASKS,
  ACCIDENT_CAR_EXPORT,
  ACCIDENT_CAR_ABNORMAL_LEAD,
  ACCIDENT_CAR_UPLOAD_INSURANCE_FILE,
  ACCIDENT_CAR_UPLOAD_MB_PRODUCT_FILE,
  ACCIDENT_CAR_UPLOAD_LEDGER_FILE,
  ACCIDENT_CAR_SMS_TEMPLATE,
  CUSTOMER_EXPORT_DATA_REPORT,
  PARTS_SPPS_MAINTENANCE,
  PARTS_SPPS_MAINTENANCE_VAN,
  PARTS_REMAN_MAPPING,
  CUSTOMER_TASKS,
  CUSTOMER_TASKS_REPORTING,
  PARTS_SALES_ORDER_UNLOCK,
  PRE_WARNING_VIEW_PROCESS,
  PRE_WARNING_VIEW,
  GROUP_PRE_WARNING_REPORT,
  WORKSHOP_KEY_MANAGEMENT,
  WORKSHOP_ORDER_LIST,
  WORKSHOP_RETAINED_ORDER_LIST,
  WORKSHOP_BP_ORDER_LIST,
  WORKSHOP_FINAL_INSPECTION,
  WORKSHOP_TECHNICIAN_WORK_PLAN,
  VEHICLE_RELEASE_LIST,
  VEHICLE_RELEASE_RECORD,
  COUPON_SALES_HISTORY,
  BUY_BACK_BIDDING_AUCTION,
  VEHICLE_HISTORY_RECALL,
  ANNUAL_TARGET_CONFIGURATION_VAN,
  INSPECTION_RATE_REMISSION_VAN,
  USED_CAR_LEAD,
  WORKSHOP_EFFICIENCY_ANALYSIS,
  DAMAGE_CLAIM_CREATED,
  DAMAGE_CLAIM_MANAGEMENT,
  DAMAGE_CLAIM_DETAIL,
  ACCIDENT_CAR_INSURANCE_COMPANY_NOTIFICATION_CHANNEL,
  ACCIDENT_CAR_OEM_INSURANCE_COMPANY_NOTIFICATION_CHANNEL,
  ACCIDENT_CAR_PICC_OUTBOUND_CALL_ACCOUNT_SETTING,
  DAMAGE_CLAIM_EDIT,
  SOFT_PHONE_MANAGEMENT,
  IMPORT_INSURANCE,
  INSURANCE_CUSTOMER_LIST,
  IMPORT_SALES_INSURANCE,
  PAPERLESS_OEM_REPORT,
  IMPORT_DEALER_CUSTOMER,
  INCOMING_CUSTOMER_LIST,
  OEM_PART_CHANGE_LOG,
  ACCESSORY_DISCOUNT_APPLY_LIST,
  ACCESSORY_DISCOUNT_APPLY_INFO_SEARCH,
  ACCIDENT_CAR_MASSAGE_TEMPLATE_CONFI,
  ACCESSORY_DISCOUNT_APPLY_INFO_EXPORT_RECORD,
  ACCESSORY_DISCOUNT_THIRD_PARTY_APPLY_LIST,
  ACCESSORY_DISCOUNT_NOTIFICATION_CONFIG_LIST,
  ACCESSORY_DISCOUNT_THIRD_PARTY_APPLY_INFO_SEARCH,
  BASE_DATA_IMPORT,
  ACCESSORY_DISCOUNT_THIRD_PART_APPLY_INFO_EXPORT_RECORD,
  THIRD_PARTY_FEATURE_CONFIGURATION_LIST,
  ACCIDENT_CAR_LEAD_LIST,
  POLICY_CODE_LIST,
  NEW_CAR_FIRST_YEAR_INSURANCE_SUPPORT_LIST,
  NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_LIST,
  IMPORT_RECORDS,
  LABOR_RATE_CONFIGURE,
  MAINTAIN_LOG,
  EXPORT_RECORDS,
  WORKSHOP_SERVICE_TEMPLATE_MANAGEMENT,
  APPEAL_CONFIGURATION_LIST,
  SERVICE_SHEET_RECORDS,
  MY_SUBMITTED,
  MY_PENDING_APPROVAL,
  ABNORMAL_ACCESS_RECORD,
  TAR_PARAMS_CONFIG,
  SETTING_NOT_EVALUATED,
  OAB_RESERVE,
  OAB_D2D,
  OAB_SHOP_MAINTENANCE,
  OAB_RESERVE_LIST,
  OAB_D2D_LIST
}

@Injectable({
  providedIn: 'root',
})
export class TabConfigService {
  tabTypes = TabTypes;

  constructor(private dealerIntegrationToggleService: DealerIntegrationToggleService,
              private featureToggleService: FeatureToggleService,
              private currentUserService: CurrentUserService,
  ) {
  }

  get isModelD() {
    return this.featureToggleService.isModelDFeatureToggleEnable && this.dealerIntegrationToggleService.isModelDEnabled();
  }

  bmbsTabServiceTabType(): TabServiceTabType[] {
    if (this.dealerIntegrationToggleService.isIntegrationToggleEnabled('ICON_SERVICE_CONTRACT_DM')) {
      return [{ title: '原厂延保套餐', href: '/system-config/after-sales/service-contract/bmbs/extended' }];
    }
    return [
      { title: '原厂保养套餐', href: '/system-config/after-sales/service-contract/bmbs/maintenance' },
      { title: '原厂延保套餐', href: '/system-config/after-sales/service-contract/bmbs/extended' },
      { title: '模块化产品', href: '/system-config/after-sales/service-contract/bmbs/additional-product' },
    ];
  }

  serviceContractPriceConfigTab(): TabServiceTabType[] {
    if (this.dealerIntegrationToggleService.isIntegrationToggleEnabled('LOCAL_EXTEND_WARRANTY')) {
      return [
        { title: '原厂保养套餐', href: '/service-contract/price-config/maintenance' },
        { title: '原厂延保服务', href: '/service-contract/price-config/extend' },
      ];

    }
    return [{ title: '原厂保养套餐', href: '/service-contract/price-config/maintenance' }];
  }

  dealerLeadTabServiceTabType(): TabServiceTabType[] {
    if (this.isModelD) {
      return [{ title: '经销商线索列表', href: '/customer-lead-management/lead-management/sales/management' }];
    }
    return [
      {
        title: '销售线索列表',
        href: '/customer-lead-management/lead-management/sales/management',
      },
    ];
  }

  getOABAfterSalesType(): TabServiceTabType[] {
    const currentUserPrivilege = _.get(this.currentUserService, 'userPrivileges', []);
    const commonSetting = [{ title: '服务时间设置', href: '/oab-aftersales-reserve/shop-maintenance/work-time-setting' },
      { title: '可预约数设置', href: '/oab-aftersales-reserve/shop-maintenance/appointment-setting' },
      { title: 'SA休假时间表', href: '/oab-aftersales-reserve/shop-maintenance/vacation-schedule' },
      { title: '来源维护', href: '/oab-aftersales-reserve/shop-maintenance/source-maintenance' },
      { title: '闲时优享预约', href: '/oab-aftersales-reserve/shop-maintenance/peak-cut' }];

    const addressSetting = [{ title: '经纬度&电话配置', href: '/oab-aftersales-reserve/shop-maintenance/address-setting' }];
    const resultType = [];

    if (_.includes(currentUserPrivilege, 'ROLE_RESERVATION_DEALER_MANAGEMENT')) {
      resultType.push(...commonSetting);
    }

    if (_.includes(currentUserPrivilege, 'ROLE_RESERVATION_CONFIG_MASTER_DATA')) {
      resultType.push(...addressSetting);
    }

    return resultType;
  }

  getInitTabIndex(): TabGroup {
    return {
      [TabTypes.ACCOUNTING_BOOK_IN_LIST]: [
        {
          title: '出入库管理',
          href: '/accounting/vehicle-stock-management/book-in-list',
        },
      ],
      [TabTypes.ACCOUNTING_INVOICE_OPTION_INVOICE]: [
        {
          title: '选装件发票',
          href: '/accounting/invoice-management/option-invoice/list',
        },
      ],
      [TabTypes.ACCOUNTING_INVOICE_PARTS_INVOICE]: [
        {
          title: '配件发票',
          href: '/accounting/invoice-management/parts-invoice/list',
        },
      ],
      [TabTypes.ACCOUNTING_MASTERDATA_SUPPLIER_NO]: [
        {
          title: '供应商编号',
          href: '/accounting/service-code/supplier-no/list',
        },
      ],
      [TabTypes.ACCOUNTING_MASTERDATA_CUSTOMER_TYPE]: [
        {
          title: '客户类型编号',
          href: '/accounting/service-code/customer-type/list',
        },
      ],
      [TabTypes.ACCOUNTING_SALES_INVOICE]: [
        {
          title: '待结订单',
          href: '/accounting/sales-invoicing-management/sales-invoicing/list',
        },
      ],
      [TabTypes.ACCOUNTING_SALES_INVOICED]: [
        {
          title: '已结订单',
          href: '/accounting/sales-invoicing-management/sales-invoiced/list',
        },
      ],
      [TabTypes.ACCOUNTING_VEHICLE_INVOICE]: [
        {
          title: '车辆订单',
          href: '/accounting/vehicle-invoicing-management/vehicle-order-invoicing/list',
        },
      ],
      [TabTypes.ACCOUNTING_AFTERSALES_INVOICE]: [
        { title: '待结订单', href: '/accounting/after-sales-invoicing-management/after-sales-invoicing/list' },
      ],
      [TabTypes.ACCOUNTING_BMI3_TEMPLATE]: [
        {
          title: 'BMI3模板管理',
          href: '/accounting/bmi3-management/bmi3-template/list',
        },
      ],
      [TabTypes.ACCOUNTING_BMI3_TXT]: [{ title: 'TXT记录', href: '/accounting/bmi3-management/bmi3-txt-history/list' }],
      [TabTypes.GROUP_COMPLAINT_REPORT]: [{ title: '集团投诉报表', href: '/case/group/complaint-report' }],
      [TabTypes.GROUP_CUSTOM_LABOR]: [
        {
          title: '集团自定义工时',
          href: '/as-settings/labor/custom-labor-management-info',
        },
      ],
      [TabTypes.ACCOUNTING_AFTERSALES_CLOSED_INVOICE]: [
        { title: '已结账单', href: '/accounting/after-sales-invoicing-management/after-sales-closed-invoiced/list' },
      ],
      [TabTypes.AFTER_SALES_LEAD]: [
        {
          title: '售后线索列表',
          href: '/customer-lead-management/lead-management/after-sales/management',
        },
      ],
      [TabTypes.AS_LEAD_CONFIG]: [
        {
          title: '售后线索配置',
          href: '/customer-lead-management/config/after-sales',
        },
      ],
      [TabTypes.SALES_LEAD_CONFIG]: [{ title: '销售线索配置', href: '/customer-lead-management/config' }],
      [TabTypes.VEHICLE_HISTORY_RECALL]: [
        { title: 'SAMR-DC', href: '/service-measure-and-recall/vehicle-history-recall/samr-dc' },
        { title: 'SAMR-VIN', href: '/service-measure-and-recall/vehicle-history-recall/samr-vin' },
        { title: 'AQUA File', href: '/service-measure-and-recall/vehicle-history-recall/aqua' },
      ],
      [TabTypes.TAR_PARAMS_CONFIG]: [
        { title: '车龄-年度目标完成率', href: '/service-measure-and-recall/tar-params-config/annual-target-completion-rate-upload' },
        { title: '月度完成率系数配置', href: '/service-measure-and-recall/tar-params-config/monthly-completion-rate-config' },
        { title: '设置重点DC', href: '/service-measure-and-recall/tar-params-config/set-focus-dc' },
        { title: '年度目标完成率设置', href: '/service-measure-and-recall/tar-params-config/annual-target-completion-rate-config' },
      ],
      [TabTypes.SETTING_NOT_EVALUATED]: [
        { title: '不考核T1', href: '/service-measure-and-recall/setting-not-evaluated/t1' },
        { title: '不考核TAR', href: '/service-measure-and-recall/setting-not-evaluated/tar' },
      ],
      [TabTypes.BMBS_SERVICE_CONTRACT]: this.bmbsTabServiceTabType(),
      [TabTypes.CAMPAIGN_TOOLKIT]: [{ title: '工具箱', href: '/campaign/toolkit/toolkit-list' }],
      [TabTypes.CASE_LIST]: [{ title: '投诉管理', href: '/case/management' }],
      [TabTypes.CLAIM_DEALER_MANAGEMENT]: [{ title: '保修经销商管理', href: '/claim-parameter-configuration/list' }],
      [TabTypes.CLAIM_DISCOUNT]: [
        {
          title: '索赔折扣',
          href: '/service-contract-parameter-configuration/claim-discount',
        },
      ],
      [TabTypes.DEALER_TYPE]: [{ title: '经销商类型', href: '/service-contract-parameter-configuration/dealer-type' }],
      [TabTypes.COUPON]: [{ title: '优惠券', href: '/coupon/verify-and-export' }],
      [TabTypes.DATA_EXPORT]: [{ title: '数据导出', href: '/report/data-export' }],
      [TabTypes.PDI]: [{ title: '长存储车检查', href: '/pdi' }],
      [TabTypes.DEALER_PRICE_PROPOSAL]: [{ title: '经销商报价配置表', href: '/vehicle/dealer-price-proposal' }],
      [TabTypes.EXPENSE_SETTLE]: [{ title: '费用结算', href: '/digital-service/expense-settle/list' }],
      [TabTypes.LEAD_LIST]: this.dealerLeadTabServiceTabType(),
      [TabTypes.MB_CAMPAIGN]: [{ title: '厂家市场活动', href: '/campaign/mb/mb-list' }],
      [TabTypes.MESSAGE_CENTER]: [{ title: '消息中心', href: '/message/list' }],
      [TabTypes.OEM_PRODUCT_CONFIG]: [{ title: '产品配置', href: '/service-contract/master-data/oem-product-config' }],
      [TabTypes.OEM_SERVICE_CONTRACT_HISTORY]: [
        {
          title: '销售历史',
          href: '/service-contract/oem-service-contract/transaction-history/list',
        },
      ],
      [TabTypes.OEM_SSSC_HISTORY]: [
        {
          title: '礼包历史',
          href: '/service-contract/oem-service-contract/sssc-history/list',
        },
      ],
      [TabTypes.ORDER_AUDIT]: [{ title: '查找订单', href: '/claim-document-management/ba/search-order-list' }],
      [TabTypes.ORDER_MANAGEMENT]: [{ title: '订单管理', href: '/order/management' }],
      [TabTypes.PARTS_MASTER_PRICE]: [{ title: '零件列表', href: '/parts-stock/master-price-file/list' }],
      [TabTypes.PARTS_MASTER_PRICE_VAN]: [{ title: '零件列表', href: '/parts-stock/van/master-price-file/list' }],
      [TabTypes.PARTS_PURCHASE_ADDRESS_MAINTENANCE]: [
        {
          title: '多地址管理',
          href: '/parts-purchase/address-maintenance',
        },
      ],
      [TabTypes.PARTS_PURCHASE_DEALER_PARAMETER]: [
        {
          title: '采购参数',
          href: '/parts-dealer-setting/dealer-parameter',
        },
      ],
      [TabTypes.PARTS_PURCHASE_DEALER_PARAMETER_VAN]: [
        {
          title: '采购参数',
          href: '/parts-dealer-setting/van/dealer-parameter',
        },
      ],
      [TabTypes.PARTS_PURCHASE_GOODS_RECEIVE]: [{ title: '收货', href: '/parts-purchase/goods-receive' }],
      [TabTypes.PARTS_PURCHASE_PARTS_BIN_TRANSFER]: [{ title: '零件转仓', href: '/parts-purchase/parts-bin-transfer' }],
      [TabTypes.PARTS_PURCHASE_PARTS_BIN_TRANSFER_VAN]: [
        {
          title: '零件转仓',
          href: '/parts-purchase/van/parts-bin-transfer',
        },
      ],
      [TabTypes.PARTS_PURCHASE_PARTS_RECEIVE]: [{ title: '零件收货', href: '/parts-purchase/parts-receive' }],
      [TabTypes.PARTS_PURCHASE_PARTS_RECEIVE_VAN]: [{ title: '零件收货', href: '/parts-purchase/van/parts-receive' }],
      [TabTypes.PARTS_PURCHASE_PURCHASE_ORDER]: [{ title: '零件采购', href: '/parts-purchase/purchase-order/list' }],
      [TabTypes.PARTS_PURCHASE_PURCHASE_ORDER_VAN]: [
        {
          title: '零件采购',
          href: '/parts-purchase/van/purchase-order/list',
        },
      ],
      [TabTypes.PARTS_PURCHASE_PURCHASE_ORDER_LINE]: [
        {
          title: '查询采购订单行',
          href: '/parts-purchase/order-item/search',
        },
      ],
      [TabTypes.PARTS_PURCHASE_PURCHASE_ORDER_LINE_VAN]: [
        {
          title: '查询采购订单行',
          href: '/parts-purchase/van/order-item/search',
        },
      ],
      [TabTypes.PARTS_PURCHASE_PURCHASE_ORDER_SUBMISSION]: [
        {
          title: '提交订单',
          href: '/parts-purchase/po-submission/list',
        },
      ],
      [TabTypes.PARTS_PURCHASE_PURCHASE_ORDER_SUBMISSION_VAN]: [
        {
          title: '提交订单',
          href: '/parts-purchase/van/po-submission/list',
        },
      ],
      [TabTypes.PARTS_SALES]: [{ title: '订单列表', href: '/parts-sales/order/list' }],
      [TabTypes.PARTS_SALES_SEARCH_CUSTOMER]: [
        {
          title: '搜索客户',
          href: '/parts-sales/order/detail/search-customer',
        },
      ],
      [TabTypes.PARTS_SALES_ORDER_UNLOCK]: [{ title: '解锁订单', href: '/parts-sales/order-unlock/list' }],
      [TabTypes.MAINTENANCE_HISTORY]: [{ title: '查询维修历史', href: '/maintenance-history/search-list/list' }],
      [TabTypes.PDI_CHECK_HISTORY]: [{ title: '查询PDI历史', href: '/pdi-check-history/pdi-check/list' }],
      [TabTypes.HVB_RECORD]: [{ title: '电池去向信息', href: '/hvb/hvb-record/list' }],
      [TabTypes.ICT_LIST]: [{ title: 'ICT列表', href: '/parts-sales/ict/list' }],
      [TabTypes.PARTS_SALES_CUSTOMER]: [{ title: '客户信息', href: '/parts-sales/customers' }],
      [TabTypes.PARTS_SALES_VAN]: [{ title: '订单列表', href: '/parts-sales/van/order/list' }],
      [TabTypes.PARTS_SALES_SEARCH_CUSTOMER_VAN]: [
        {
          title: '搜索客户',
          href: '/parts-sales/van/order/detail/search-customer',
        },
      ],
      [TabTypes.ICT_LIST_VAN]: [{ title: 'ICT列表', href: '/parts-sales/van/ict/list' }],
      [TabTypes.PARTS_SALES_CUSTOMER_VAN]: [{ title: '客户信息', href: '/parts-sales/van/customers' }],
      [TabTypes.PARTS_SETTINGS_MASTER_PRICE_PARAM]: [
        {
          title: '主价格集成设置',
          href: '/parts-setting/master-price-param',
        },
      ],
      [TabTypes.PARTS_SETTINGS_MARGIN_CODE_MAINTENANCE]: [
        {
          title: '维护利润率表',
          href: '/parts-setting/margin-table-maintenance',
        },
      ],
      [TabTypes.PARTS_SETTINGS_PRICE_MOVEMENT_TYPE]: [
        {
          title: '成本流动类型设置',
          href: '/parts-setting/price-movement-type',
        },
      ],
      [TabTypes.PARTS_SETTINGS_ADDRESS_MAINTENANCE]: [
        {
          title: '多地址管理',
          href: '/parts-setting/address-maintenance/list',
        },
      ],
      [TabTypes.PARTS_SETTINGS_SUBMISSION_PARAMETER]: [
        {
          title: '零件采购提交参数',
          href: '/parts-setting/submission-parameter/settings',
        },
      ],
      [TabTypes.PARTS_SETTINGS_CONFI_PARAMETER]: [{ title: '零件采购参数', href: '/parts-setting/confi-parameter' }],
      [TabTypes.PARTS_SETTINGS_ICT_MAPPING]: [{ title: 'ICT配置', href: '/parts-setting/ict-mapping' }],
      [TabTypes.PARTS_SETTINGS_DIMS_MANUAL_EXPORT]: [
        {
          title: '手动导出DIMS文件',
          href: '/parts-setting/dims-manual-export',
        },
      ],
      [TabTypes.PARTS_SETTINGS_BUYBACK_PARAMETER]: [
        {
          title: '回购订单参数表',
          href: '/parts-setting/buyback-parameter',
        },
      ],
      [TabTypes.PARTS_SETTINGS_PARTS_PROFILE_UPDATE_PARAM]: [
        {
          title: '设置更新条件',
          href: '/parts-setting/parts-profile-update-param',
        },
      ],
      [TabTypes.PARTS_SETTINGS_BATCH_PROCESSING_FILES]: [
        {
          title: '批量处理零件档案',
          href: '/parts-setting/batch-processing-files',
        },
      ],
      [TabTypes.PARTS_SETTING_DISCOUNT]: [{ title: '折扣管理', href: '/parts-setting/discount' }],
      [TabTypes.PARTS_SETTING_DISCOUNT_CODE]: [{ title: '折扣代码', href: '/parts-setting/discount-code' }],
      [TabTypes.PARTS_PROFILES]: [{ title: '零件列表', href: '/parts-stock/parts-profile/list' }],
      [TabTypes.PARTS_PROFILES_VAN]: [{ title: '零件列表', href: '/parts-stock/van/parts-profile/list' }],
      [TabTypes.PRE_ORDER_MANAGEMENT]: [{ title: '名义订单', href: '/preorder/management' }],
      [TabTypes.RETAIL_CAMPAIGN]: [{ title: '经销商市场活动', href: '/campaign/retail/retail-list' }],
      [TabTypes.SERVICE_CONTRACT_PRICE_CONFIG]: this.serviceContractPriceConfigTab(),
      [TabTypes.SERVICE_CONTRACT_HISTORY]: [{ title: '销售历史', href: '/service-contract/transaction-history/list' }],
      [TabTypes.COUPON_SALES_HISTORY]: [{ title: '销售历史', href: '/service-contract/coupon-sales-history/list' }],
      [TabTypes.SSSC_HISTORY]: [{ title: '礼包历史', href: '/service-contract/sssc-history/list' }],
      [TabTypes.SSSC_SALES_RECORD]: [{ title: '赠送历史', href: '/service-contract/sssc-sales-record/list' }],
      [TabTypes.STAR_D_HISTORY]: [{ title: '订单列表', href: '/claim-document-management/tech/list' }],
      [TabTypes.VEHICLE_DATA_SYNCHRONIZATION]: [{ title: '数据同步表', href: '/vehicle/data-sync' }],
      [TabTypes.VEHICLE_DMS_MATCH]: [{ title: 'DMS匹配表', href: '/vehicle/dms-map' }],
      [TabTypes.VEHICLE_MANAGEMENT]: [{ title: '库存表', href: '/vehicle/management' }],
      [TabTypes.DIGITAL_SALES_CAMPAIGN]: [{ title: '线上销售专属活动', href: '/vehicle/digital-sales-campaign' }],
      [TabTypes.VEHICLE_SHARE]: [{ title: '共享库存', href: '/vehicle/share' }],
      [TabTypes.STOCK_TAKING]: [{ title: '库存盘点', href: '/vehicle/stock-taking' }],
      [TabTypes.WARRANTY_CLAIM_NUMBER_LIST]: [
        {
          title: '订单列表',
          href: '/claim-document-management/wc/claim-number-list/list',
        },
      ],
      [TabTypes.SERVICE_ORDER]: [
        {
          title: '订单列表',
          href: '/service-order/service-order-list',
        },
      ],
      [TabTypes.WARRANTY_CLAIM_RECORD_LIST]: [{ title: '保修申请', href: '/warranty-claim-management/wc/claims/list' }],
      [TabTypes.WARRANTY_REPLACED_PARTS]: [
        {
          title: '旧件标签列表',
          href: '/warranty-destruction-management/replaced-parts/list',
        },
      ],
      [TabTypes.WARRANTY_NO_CLAIM_NUMBER_LIST]: [
        {
          title: '订单列表',
          href: '/claim-document-management/wc/no-claim-number-list/list',
        },
      ],
      [TabTypes.WARRANTY_CLAIM_ORDER_LIST]: [
        {
          title: '订单列表',
          href: '/warranty-order-management/wc/order-list/list',
        },
      ],
      [TabTypes.MOT_UPLOAD_SERVICE_HISTORY]: [{ title: '上传工单列表', href: '/mot/service-history/upload-list' }],
      [TabTypes.MOT_UPLOAD_RECORD]: [{ title: '历史记录', href: '/mot/service-history/upload-history' }],
      [TabTypes.MOT_SERVICE_HISTORY_FUEL_TYPE_MAINTENANCE]: [
        {
          title: '维护燃料类型',
          href: '/mot/service-history/fuel-type-maintenance',
        },
      ],
      [TabTypes.SECOND_MOT_UPLOAD_LIST]: [{ title: '二次上传列表', href: '/mot/service-history/second-upload-list' }],
      [TabTypes.PARTS_PURCHASE_CONFI_IMPORT]: [{ title: '厂家零件收货', href: '/parts-purchase/confi-import' }],
      [TabTypes.PARTS_PURCHASE_CONFI_IMPORT_VAN]: [{ title: '厂家零件收货', href: '/parts-purchase/van/confi-import' }],
      [TabTypes.PARTS_STOCK_CHECK]: [{ title: '查看库存盘点', href: '/parts-stock/stock-check/overview' }],
      [TabTypes.PARTS_STOCK_CHECK_VAN]: [{ title: '查看库存盘点', href: '/parts-stock/van/stock-check/overview' }],
      [TabTypes.ALLIANZ_WARNING]: [{ title: '新能源车辆报警', href: '/allianz-warning' }],
      [TabTypes.PARTS_PURCHASE_INVOICE_VERIFICATION]: [
        {
          title: '发票校验',
          href: '/parts-purchase/invoice-verification',
        },
      ],
      [TabTypes.PARTS_PURCHASE_INVOICE_VERIFICATION_VAN]: [
        {
          title: '发票校验',
          href: '/parts-purchase/van/invoice-verification',
        },
      ],
      [TabTypes.CUSTOMER_MANAGEMENT]: [
        {
          title: '客户管理',
          href: '/customer-lead-management/customer-management/customer',
        },
      ],
      [TabTypes.CUSTOMER_CRITERIA_CONFIG]: [
        {
          title: '客户推荐配置',
          href: '/customer-lead-management/customer-management/criteria-config',
        },
      ],
      [TabTypes.CUSTOMER_PHONE_CALL_HISTORY]: [
        {
          title: '客户通话记录',
          href: '/customer-lead-management/customer-management/customer-call-history',
        },
      ],
      [TabTypes.CUSTOMER_EXPORT_DATA_REPORT]: [
        {
          title: '数据导出报告',
          href: '/customer-lead-management/data-export-report',
        },
      ],
      [TabTypes.PARTS_DEALER_SETTING]: [
        {
          title: '库存共享设置',
          href: '/parts-dealer-setting/dealer-white-list/settings',
        },
      ],
      [TabTypes.PARTS_DEALER_SETTING_VAN]: [
        {
          title: '库存共享设置',
          href: '/parts-dealer-setting/van/dealer-white-list/settings',
        },
      ],
      [TabTypes.PARTS_DEALER_SETTING_DISCOUNT]: [
        {
          title: '折扣管理',
          href: '/parts-dealer-setting/discount/discount',
        },
      ],
      [TabTypes.PARTS_DEALER_SETTING_DISCOUNT_VAN]: [{ title: '折扣管理', href: '/parts-dealer-setting/van/discount' }],
      [TabTypes.PARTS_DEALER_SETTING_DISCOUNT_CODE]: [
        {
          title: '折扣代码',
          href: '/parts-dealer-setting/discount-code',
        },
      ],
      [TabTypes.PARTS_DEALER_SETTING_DISCOUNT_CODE_VAN]: [
        {
          title: '折扣代码',
          href: '/parts-dealer-setting/van/discount-code',
        },
      ],
      [TabTypes.PARTS_DEALER_SETTING_DISCOUNT_RIGHT]: [
        {
          title: '折扣权限管理',
          href: '/parts-dealer-setting/discount-right',
        },
      ],
      [TabTypes.PARTS_DEALER_SETTING_DISCOUNT_RIGHT_VAN]: [
        {
          title: '折扣权限管理',
          href: '/parts-dealer-setting/van/discount-right',
        },
      ],
      [TabTypes.PARTS_DEALER_SETTING_DISCOUNT_RANGE]: [
        {
          title: '打折范围',
          href: '/parts-dealer-setting/discount-range',
        },
      ],
      [TabTypes.PARTS_DEALER_SETTING_DISCOUNT_RANGE_VAN]: [
        {
          title: '打折范围',
          href: '/parts-dealer-setting/van/discount-range',
        },
      ],
      [TabTypes.FINANCE_MANAGEMENT_REPORTING_OVERVIEW]: [
        {
          title: '财务管理报表总览',
          href: '/report-center/finance/list',
        },
      ],
      [TabTypes.CUSTOMER_MANAGEMENT_REPORTING_OVERVIEW]: [
        {
          title: '客户管理报表总览',
          href: '/report-center/customer/list',
        },
      ],
      [TabTypes.ACCIDENT_CAR_REPORTING_OVERVIEW]: [
        {
          title: '事故车线索报表总览',
          href: '/report-center/accident-car-lead/list',
        },
      ],
      [TabTypes.FRONT_DESK_MANAGEMENT_REPORTING_OVERVIEW]: [
        {
          title: '前台报表总览',
          href: '/report-center/front-desk/list',
        },
      ],
      [TabTypes.PAPERLESS_MANAGEMENT_REPORTING_OVERVIEW]: [
        {
          title: '无纸化管理',
          href: '/report-center/paperless/list',
        },
      ],
      [TabTypes.INSURANCE_MANAGEMENT_REPORTING_OVERVIEW]: [
        {
          title: '报表管理',
          href: '/insurance-report/report-list',
        },
      ],
      [TabTypes.OTHERS_MANAGEMENT_REPORTING_OVERVIEW]: [{ title: '其它报表总览', href: '/report-center/others' }],
      [TabTypes.PARTS_MANAGEMENT_REPORTING_OVERVIEW]: [
        { title: isVanForSameDealer() ? '商务车配件报表总览' : '配件报表总览', href: '/report-center/parts/list' },
      ],
      [TabTypes.WARRANTY_MANAGEMENT_REPORTING_OVERVIEW]: [
        {
          title: '保修报表总览',
          href: '/report-center/warranty/list',
        },
      ],
      [TabTypes.WORKSHOP_MANAGEMENT_REPORTING_OVERVIEW]: [
        {
          title: '车间报表总览',
          href: '/report-center/workshop/list',
        },
      ],
      [TabTypes.PAPERLESS_OEM_REPORT]: [{ title: '电子签使用率月报表统计', href: '/paperless/report/oem' }],
      [TabTypes.COURTESY_CAR]: [{ title: '代步车列表', href: '/courtesy-car/courtesy-car-list' }],
      [TabTypes.CLINCH_CUSTOMER_TASKS]: [{ title: '成交客户任务', href: '/task-management/clinch-customer-tasks' }],
      [TabTypes.ACCIDENT_CAR_EXPORT]: [{ title: '事故车线索导出', href: '/accident-car/report' }],
      [TabTypes.ACCIDENT_CAR_SMS_TEMPLATE]: [{ title: '事故车短信模板', href: '/accident-car/templates' }],
      [TabTypes.ACCIDENT_CAR_ABNORMAL_LEAD]: [{ title: '异常线索审核', href: '/accident-car/abnormal-lead' }],
      [TabTypes.ACCIDENT_CAR_MASSAGE_TEMPLATE_CONFI]: [{
        title: '事故车短信模版配置',
        href: '/accident-car/sms-template-config',
      }],
      [TabTypes.ACCIDENT_CAR_UPLOAD_INSURANCE_FILE]: [{
        title: '上传保险数据',
        href: '/accident-car/upload-insurance-file',
      }],
      [TabTypes.ACCIDENT_CAR_UPLOAD_MB_PRODUCT_FILE]: [{
        title: '上传钣喷数据',
        href: '/accident-car/upload-mb-product-file',
      }],
      [TabTypes.ACCIDENT_CAR_UPLOAD_LEDGER_FILE]: [{ title: '事故车线索批量补录', href: '/accident-car/ledger-file-upload' }],
      [TabTypes.ACCIDENT_CAR_INSURANCE_COMPANY_NOTIFICATION_CHANNEL]: [
        { title: '事故车保司推送渠道管理', href: '/accident-car/insurance-company-notification' },
      ],
      [TabTypes.ACCIDENT_CAR_OEM_INSURANCE_COMPANY_NOTIFICATION_CHANNEL]: [
        { title: '全网经销商保司推送渠道管理', href: '/accident-car/oem-insurance-company-notification' },
      ],
      [TabTypes.ACCIDENT_CAR_PICC_OUTBOUND_CALL_ACCOUNT_SETTING]: [
        { title: '人保外呼账号设置', href: '/accident-car/picc-outbound-call-account-setting' },
      ],
      [TabTypes.ACCIDENT_CAR_LEAD_LIST]: [
        { title: '事故车线索', href: '/accident-car/leads/leads-home' },
      ],
      [TabTypes.PARTS_SPPS_MAINTENANCE]: [{ title: '星辉保养菜单', href: '/parts-sales/spps/list' }],
      [TabTypes.PARTS_SPPS_MAINTENANCE_VAN]: [{ title: '星辉保养菜单', href: '/parts-sales/van/spps/list' }],
      [TabTypes.PARTS_REMAN_MAPPING]: [{ title: '再制造零件号上传', href: '/parts-setting/reman-mapping' }],
      [TabTypes.CUSTOMER_TASKS]: [{ title: '客户任务', href: '/task-management/customer-tasks' }],
      [TabTypes.CUSTOMER_TASKS_REPORTING]: [{ title: '客户任务报表', href: '/task-management/customer-tasks-report' }],
      [TabTypes.PRE_WARNING_VIEW_PROCESS]: [{ title: '预警管理', href: '/pre-warning/management' }],
      [TabTypes.PRE_WARNING_VIEW]: [{ title: '预警管理', href: '/pre-warning/management' }],
      [TabTypes.GROUP_PRE_WARNING_REPORT]: [{ title: '集团预警报表', href: '/pre-warning/group/pre-warning-report' }],
      [TabTypes.WORKSHOP_ORDER_LIST]: [{ title: '订单列表', href: '/workshop/order-list/order' }],
      [TabTypes.WORKSHOP_BP_ORDER_LIST]: [{ title: '钣喷列表', href: '/workshop/order-list/body-paint' }],
      [TabTypes.WORKSHOP_RETAINED_ORDER_LIST]: [
        {
          title: '滞留列表',
          href: '/workshop/order-list/retained-order-list',
        },
      ],
      [TabTypes.WORKSHOP_KEY_MANAGEMENT]: [{ title: '钥匙管理', href: '/workshop/resource-management/key-management' }],
      [TabTypes.WORKSHOP_FINAL_INSPECTION]: [{ title: '查看终检历史', href: '/workshop/final-inspection/history' }],
      [TabTypes.WORKSHOP_TECHNICIAN_WORK_PLAN]: [
        {
          title: '技师工作安排',
          href: '/workshop/resource-management/technician-work-plan',
        },
      ],
      [TabTypes.VEHICLE_RELEASE_LIST]: [
        {
          title: '车辆放行列表',
          href: '/as-vehicle-release-management/vehicle-release-list',
        },
      ],
      [TabTypes.VEHICLE_RELEASE_RECORD]: [
        {
          title: '车辆放行日志',
          href: '/as-vehicle-release-management/vehicle-release-record',
        },
      ],
      [TabTypes.BUY_BACK_BIDDING_AUCTION]: [{ title: '竞拍列表', href: '/buyback-bidding-auction/list' }],
      [TabTypes.ANNUAL_TARGET_CONFIGURATION_VAN]: [
        { title: '预检年化目标配置', href: '/part-sales-management/van-oem/annual-target-configuration' },
      ],
      [TabTypes.INSPECTION_RATE_REMISSION_VAN]: [
        { title: '预检率数据豁免', href: '/part-sales-management/van-oem/inspection-rate-remission' },
      ],
      [TabTypes.USED_CAR_LEAD]: [
        {
          title: '二手车线索列表',
          href: '/customer-lead-management/lead-management/used-car-sales/management',
        },
      ],
      [TabTypes.WORKSHOP_EFFICIENCY_ANALYSIS]: [
        {
          title: '车间效率分析',
          href: '/workshop/report/process-efficiency-analysis/list',
        },
      ],
      [TabTypes.DAMAGE_CLAIM_CREATED]: [{ title: '新增运损', href: '/damage-claim-created' }],
      [TabTypes.DAMAGE_CLAIM_MANAGEMENT]: [{ title: '运损管理', href: '/damage-claim-list' }],
      [TabTypes.DAMAGE_CLAIM_DETAIL]: [{ title: '新增运损', href: '/damage-claim-detail' }],
      [TabTypes.DAMAGE_CLAIM_EDIT]: [{ title: '编辑运损', href: '/damage-claim-edit' }],
      [TabTypes.SOFT_PHONE_MANAGEMENT]: [{ title: '软电话管理', href: '/soft-phone' }],
      [TabTypes.IMPORT_INSURANCE]: [
        {
          title: '导入保险数据',
          href: '/insurance/accident-car-management/import-insurance',
        },
      ],
      [TabTypes.INSURANCE_CUSTOMER_LIST]: [{ title: '全量客户列表', href: '/insurance/customer-list' }],
      [TabTypes.IMPORT_SALES_INSURANCE]: [
        {
          title: '导入销售保险支持数据',
          href: '/insurance/accident-car-management/import-sales-insurance',
        },
      ],
      [TabTypes.IMPORT_DEALER_CUSTOMER]: [
        {
          title: '客户导入',
          href: '/insurance/import-dealer-customer',
        },
      ],
      [TabTypes.ACCESSORY_DISCOUNT_APPLY_LIST]: [
        {
          title: '配件折扣申请列表',
          href: '/accessory-discount/apply-list',
        },
      ],
      [TabTypes.ACCESSORY_DISCOUNT_THIRD_PARTY_APPLY_LIST]: [
        {
          title: '配件折扣申请列表',
          href: '/accessory-discount/apply-list/3rd-party',
        },
      ],
      [TabTypes.ACCESSORY_DISCOUNT_APPLY_INFO_EXPORT_RECORD]: [
        {
          title: '申请信息导出记录',
          href: '/accessory-discount/apply-export-list',
        },
      ],
      [TabTypes.ACCESSORY_DISCOUNT_THIRD_PART_APPLY_INFO_EXPORT_RECORD]: [
        {
          title: '申请信息导出记录',
          href: '/accessory-discount/apply-export-list/3rd-party',
        },
      ],
      [TabTypes.ACCESSORY_DISCOUNT_APPLY_INFO_SEARCH]: [
        {
          title: '配件折扣申请信息查询',
          href: '/accessory-discount/apply-info-search',
        },
      ],
      [TabTypes.ACCESSORY_DISCOUNT_THIRD_PARTY_APPLY_INFO_SEARCH]: [
        {
          title: '配件折扣申请信息查询',
          href: '/accessory-discount/apply-info-search/3rd-party',
        },
      ],
      [TabTypes.THIRD_PARTY_FEATURE_CONFIGURATION_LIST]: [
        {
          title: '功能配置列表',
          href: '/accessory-discount/feature-configuration-list/3rd-party',
        },
      ],
      [TabTypes.OEM_PART_CHANGE_LOG]: [
        {
          title: '主价格文件日志',
          href: '/service-contract/oem-part-change-log',
        },
      ],
      [TabTypes.ACCESSORY_DISCOUNT_NOTIFICATION_CONFIG_LIST]: [
        {
          title: '提示信息配置',
          href: '/notification-configuration-list',
        },
      ],
      [TabTypes.BASE_DATA_IMPORT]: [
        {
          title: '基数上传',
          href: '/accessory-discount/base-data-import/3rd-party',
        },
      ],
      [TabTypes.INCOMING_CUSTOMER_LIST]: [
        {
          title: '',
          href: '',
        },
      ],
      [TabTypes.IMPORT_RECORDS]: [
        {
          title: '导入记录',
          href: '/import-records',
        },
      ],
      [TabTypes.LABOR_RATE_CONFIGURE]: [
        {
          title: '每小时工时费率',
          href: '/as-settings/labor-rate/configure-labor-rate',
        },
      ],
      [TabTypes.MAINTAIN_LOG]: [
        {
          title: '每小时工时费率修改记录',
          href: '/as-settings/labor-rate/maintain-log',
        },
      ],
      [TabTypes.POLICY_CODE_LIST]: [
        {
          title: '政策代码列表',
          href: '/policy-support/policy-code-list',
        },
      ],
      [TabTypes.NEW_CAR_FIRST_YEAR_INSURANCE_SUPPORT_LIST]: [
        {
          title: '新车首年政策支持清单',
          href: '/policy-support/new-car-first-year-insurance-support-list',
        },
      ],
      [TabTypes.NEW_CAR_NEXT_YEAR_INSURANCE_SUPPORT_LIST]: [
        {
          title: '新车次年政策支持清单',
          href: '/policy-support/new-car-next-year-insurance-support-list',
        },
      ],
      [TabTypes.EXPORT_RECORDS]: [
        {
          title: '导出记录',
          href: '/export-records',
        },
      ],
      [TabTypes.WORKSHOP_SERVICE_TEMPLATE_MANAGEMENT]: [
        {
          title: '保养单概述',
          href: '/workshop/service-sheet-template-management',
        },
      ],
      [TabTypes.APPEAL_CONFIGURATION_LIST]: [
        {
          title: '新车次年政策支持申诉配置',
          href: '/policy-support/appeal-configuration-list',
        },
      ],
      [TabTypes.SERVICE_SHEET_RECORDS]: [
        {
          title: '查看保养历史',
          href: '/service-sheet/service-sheet-records',
        },
      ],
      [TabTypes.MY_SUBMITTED]: [
        {
          title: '我的提交',
          href: '/intelligent-barrier-gate/my-submitted',
        },
      ],
      [TabTypes.MY_PENDING_APPROVAL]: [
        {
          title: '我的待审批',
          href: '/intelligent-barrier-gate/pending',
        },
      ],
      [TabTypes.ABNORMAL_ACCESS_RECORD]: [
        {
          title: '维修异常查询监控记录',
          href: '/abnormal-access-report/abnormal-access-record',
        },
      ],
      [TabTypes.OAB_RESERVE]: [
        {
          title: '经销商配置',
          href: '/oab/reserve',
        },
      ],
      [TabTypes.OAB_D2D]: [
        {
          title: '经销商配置',
          href: '/oab/d2d',
        },
      ],
      [TabTypes.OAB_SHOP_MAINTENANCE]: this.getOABAfterSalesType(),
      [TabTypes.OAB_RESERVE_LIST]: [
        {
          title: '预约列表',
          href: '/oab-aftersales-reserve/list'
        }
      ],
      [TabTypes.OAB_D2D_LIST]: [
        {
          title: 'D2D列表',
          href: '/oab/d2d/list'
        }
      ],
    };
  }
}

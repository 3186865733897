import * as _ from 'lodash';
import { SHARE_RANGE, SHARE_VISIBLE_ROLES, VAN_CLASS_DISPLAY_MAPPING } from '../constants/vehicle';
import { isEmptyValue } from './common';
import * as moment from 'moment';

export function getVisibleRoleNames(visibleRoles) {
  return visibleRoles
    .map(visibleRole => _.get(_.find(SHARE_VISIBLE_ROLES, { value: visibleRole }), 'text', ''))
    .join('，');
}

export function getDefaultShareRange() {
  return _.find(SHARE_RANGE, 'default');
}

export function getShareRangeText(shareRange) {
  const filteredShareRange = _.find(SHARE_RANGE, { value: shareRange });
  return _.get(filteredShareRange, 'text', '');
}

export function getKeyFromCurrentVehicle(currentVehicle, key) {
  return {
    description_en: _.get(currentVehicle, `${key}_en`, ''),
    description_zh: _.get(currentVehicle, `${key}_zh`, ''),
  };
}

export const getFormattedVehicleFieldForLoadForm = (vehicle, key) =>
  _.has(vehicle, key) ? _.get(vehicle, key) : getKeyFromCurrentVehicle(vehicle, key);

export const getFlattenedCurrentVehicle = (vehicleSelect, isUsedCarOrAgent= false) => {
  const currentVehicle = {};
  _.forEach(vehicleSelect, (value, key) => {
    if ((key === 'current_vehicle_age' || key === 'current_vehicle_evaluation')) {
      currentVehicle[key] = _.get(vehicleSelect, key, '');
      currentVehicle[`${key}_en`] = _.get(vehicleSelect, `${key}.description_en`, '');
      currentVehicle[`${key}_zh`] = _.get(vehicleSelect, `${key}.description_zh`, '');
    }
    currentVehicle[`${key}_en`] = _.get(vehicleSelect, `${key}.description_en`, '');
    currentVehicle[`${key}_zh`] = _.get(vehicleSelect, `${key}.description_zh`, '');
  });
  return currentVehicle;
};

export const formatClassLevel = vehicleData => {
  if (!isEmptyValue(vehicleData)) {
    const { fin, brand, classLevel } = vehicleData;
    const baumaster = _.isNil(fin) ? '' : fin.substring(3, 10);
    return `${_.isNil(classLevel) ? '' : classLevel} ${brand === 'smart' ? '' : '级'} ${baumaster.substring(0, 3)}`;
  }
};

export const formatVanClassLevel = vehicleData => {
  if (!isEmptyValue(vehicleData)) {
    const { classLevel } = vehicleData;
    return _.get(VAN_CLASS_DISPLAY_MAPPING, classLevel, classLevel);
  }
};

export const mapOptionCodeAndDescriptionToArray = (codes, descriptions) => {
  const optionCodes = _.split(codes, '/^/');
  const optionDescriptions = _.split(descriptions, '/^/');
  return _.map(optionCodes, (code, index) => ({
    code,
    description: optionDescriptions[index],
  }));
};

export const getModelOptionsFromClass = (classOption): Array<any> =>
  _.flatMap(_.get(classOption, 'models', []), 'variants');

export const calculateVehicleAge = firstRegistrationDate => {
  const leftMonths = moment().diff(moment(firstRegistrationDate), 'months');
  const monthRemainder = leftMonths % 12;
  const leftYears = _.floor(leftMonths / 12);
  return (leftYears ? `${leftYears}年 ` : '') + (monthRemainder ? `${monthRemainder}个月` : '');
};

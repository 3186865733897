import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalPosition, PositionedModal } from './position';
import { TimePickerModel } from '../../time-picker/time-picker.component';
import { Subject } from 'rxjs';

export interface TimePickerServiceModalOptions {
  minTime?: TimePickerModel;
  maxTime?: TimePickerModel;
  initialTime?: TimePickerModel;
  showSeconds?: boolean;
  errorMessages?: any;
  title: string;
}

@Component({
  styleUrls: ['time-picker-service-modal.style.scss'],
  templateUrl: 'time-picker-service-modal.template.html',
})
export class TimePickerServiceModalComponent implements PositionedModal {

  left: number = 0;
  top: number = 0;
  minTime: TimePickerModel;
  maxTime: TimePickerModel;
  initialTime: TimePickerModel;
  showSeconds: boolean = false;
  title: string;
  errorMessages = { min: '不能早于开始时间', max: '' };

  private subject: Subject<any>;

  @ViewChild('timePickerModal', { read: ElementRef }) timePickerModal: ElementRef;

  constructor() {
    this.subject = new Subject();
  }

  getSubject(): Subject<any> {
    return this.subject;
  }

  setProperties(options: TimePickerServiceModalOptions): void {
    if (options.showSeconds) {
      this.showSeconds = options.showSeconds;
    }
    if (options.initialTime) {
      this.initialTime = options.initialTime;
    }
    if (options.minTime) {
      this.minTime = options.minTime;
    }
    if (options.maxTime) {
      this.maxTime = options.maxTime;
    }
    if (options.errorMessages) {
      this.errorMessages = options.errorMessages;
    }
    this.title = options.title;
  }

  setPosition(position: ModalPosition): void {
    this.left = position.left;
    this.top = position.top;
  }

  getClientHeight(): number {
    const timePicker = <HTMLElement> this.timePickerModal.nativeElement;
    const { height } = timePicker.getBoundingClientRect();
    return height;
  }

  getClientWidth(): number {
    const timePicker = <HTMLElement> this.timePickerModal.nativeElement;
    const { width } = timePicker.getBoundingClientRect();
    return width;
  }

  save(time) {
    this.subject.next(time);
  }

  cancel() {
    this.subject.next(null);
  }
}

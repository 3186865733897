import { ICellRendererParams } from 'ag-grid';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  template: `
    <div class="new-vehicle" *ngIf="visible">
      <img src="../../../../../assets/accident-car/new-vehicle.svg" alt=""/>
      <span class="new-vehicle-text">新车</span>
    </div>

  `,
  styleUrls: ['./new-vehicle-cell-render.component.scss'],
})
export class NewVehicleCellRenderComponent implements ICellRendererAngularComp {
  visible: boolean;

  agInit(params: ICellRendererParams): void {
    this.visible = params.data.isNewVehicle;
  }

  refresh(params: any): boolean {
    return false;
  }
}

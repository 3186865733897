import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distanceFormat'
})
export class DistanceFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 1000) {
      return `${value.toLocaleString()}km`;
    }
    return `${value.toString()}km`;
  }
}

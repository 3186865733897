import { Injectable } from '@angular/core';
import { Body, DELETE, GET, Headers, Path, POST, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FreebiesApi extends RebirthHttp {
  public uploadUri = 'api/quotations/freebie/upload';
  public unAddUploadUri = '/api/sales-incentive-programs/freebies/upload';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/quotations/freebie/template')
  @Headers({ isExportFile: true })
  getTemplate(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/quotations/program-freebie/download')
  @Headers({ isExportFile: true })
  getUnAddFreebieTemplate(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }
  @GET('/api/sales-incentive-programs/freebies/error/download')
  @Headers({ isExportFile: true })
  getErrorMessage(): Observable<any> {
    return null;
  }

  @GET('/api/quotations/freebie/download')
  @Headers({ isExportFile: true })
  getExistingData(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/quotations/freebie/update-time')
  getUpdateTime(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/quotation-options/freebie')
  getDataList(@Query('filter') filter: string,
              @Query('size') size: string,
              @Query('page') page: string): Observable<any> {
    return null;
  }

  @DELETE('/api/quotation-options/freebie')
  deleteOptions(@Body data): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/sale-config/freebie-config')
  getConfigData(): Observable<any> {
    return null;
  }

  @PUT('/api/quotation-options/freebie/:id')
  updateOption(@Path('id') id: string,
               @Body updateData): Observable<any> {
    return null;
  }

  @POST('/api/quotation-options/freebie')
  saveOption(@Body data): Observable<any> {
    return null;
  }

}

<div class="form-group follow-up-date row-container">
  <label class="required form-label">本次跟进日期</label>
  <p-calendar
    [locale]="chineseCalendarConfig"
    dataType="string"
    dateFormat="yy-mm-dd"
    placeholder="YYYY-MM-DD"
    [formControl]="followUpTime"
    [inputStyle]="followUpTime.valid ? {} : invalidStyle"
    [maxDate]="maxDate"
    [minDate]="minDate"
    showTime="true"
  >
  </p-calendar>
</div>
<div class="form-group row-container">
  <label>下一步计划</label>
  <app-radio [options]="followUpPlan" [control]="followUpPlanControl" (onChange)="handleFollowUpPlanChange()"></app-radio>
</div>

<app-cti-keep-follow-up #nextPlan [leads]="leads" *ngIf="isVisible('keepFollowUp')"></app-cti-keep-follow-up>

<app-cti-assign-sc-modal #nextPlan [leads]="leads"
                     [customerId]="customerId"
                     [scGroupInfos]="scGroupInfos"
                     [modScGroupInfos]="modScGroupInfos"
                     isFollowUp='true'
                     *ngIf="isVisible('appointment')"></app-cti-assign-sc-modal>

<app-cti-close-lead-modal #nextPlan *ngIf="isVisible('closeLead')" [leads]="leads"></app-cti-close-lead-modal>


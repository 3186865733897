import {
  Component,
  ElementRef,
  OnInit,
  OnChanges,
  ViewChild,
  Renderer2,
  Output,
  Input,
  EventEmitter,
} from '@angular/core';
import * as _ from 'lodash';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { CtiCallService } from '../../../_common/services/cti-call.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-intelligent-assistant',
  templateUrl: './intelligent-assistant.component.html',
  styleUrls: ['./intelligent-assistant.component.scss'],
  providers: [],
})
export class IntelligentAssistantComponent implements OnInit, OnChanges  {
  @ViewChild('elem') elem: ElementRef;
  @Output() foldAssistantModal = new EventEmitter();
  parentElem: any;
  private totalOffsetX = 0;
  private totalOffsetY = 0;
  private dragTm;
  disX = 0;
  disY = 0;
  isDown = false;
  isMoved = false;
  assistantElem: any;
  ctiSafeUrl: SafeUrl;
  showSpinner: Observable<any> = of({});
  timer: number = null;
  showIntelligentModal: boolean = false;
  maxTop: string = '';
  foldMinFlag: boolean = false;
  callStatus: string = '';
  @Input() currentStatus;
  @Input() isCallEnd;
  constructor(
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private ctiCallService: CtiCallService,
  ) {}
  ngOnInit(): void {
    this.initDragElement();
  }

  ngOnChanges(changes): void {
    if (changes.currentStatus && changes.currentStatus.currentValue) {
      this.initIntelligentElement();
    }
  }

  get assistantCallStatus() {
    return this.ctiCallService.getCtiIntelligentAssistantCurrentStatus();
  }

  initIntelligentElement() {
    this.callStatus = _.cloneDeep(this.assistantCallStatus);
    if (_.isEqual(this.callStatus, 'consultincall') && _.includes(this.currentStatus, 'click')) {
      this.foldMinFlag = false;
      return;
    }
    if (_.isEqual(this.callStatus, 'consultincall')) {
      this.foldMinFlag = true;
      return;
    }
    if (_.isEqual(this.callStatus, 'agentconsultback')) {
      this.foldMinFlag = false;
      return;
    }
    this.foldMinFlag = false;
    this.jumpToIntelligentUrl();
  }

  isValidParam(param: string): boolean {
    // 允许包含字母、数字、下划线、连字符和空格
    const validPattern = /^[a-zA-Z0-9_-\s]+$/;
    return param ? validPattern.test(param) : true;
  }

  getSecurityParams() {
    const operatorInfo: any = this.ctiCallService.getOperatorInfo();
    const { companyShortName, operatorId } = operatorInfo;

    if (this.isValidParam(companyShortName) && this.isValidParam(operatorId)) {
      return {
        companyShortName: encodeURIComponent(companyShortName),
        operatorId: encodeURIComponent(operatorId),
      };
    }
    throw new Error(`invalid companyShortName ${companyShortName}, or operatorId ${operatorId}`);
  }

  jumpToIntelligentUrl() {
    this.showIntelligentModal = true;
    try {
      const { companyShortName, operatorId } = this.getSecurityParams();
      const isProductionOrUat = environment.production || _.get(environment, 'envName') === 'uat';
      const productionOrUatUrl = `https://ai.lshauto.com.cn/csa/entryPoint/#/?agentId=1&enterpriseId=101&entId=bbf10e08ad784d0fb0509e2952f82d72&phone=${companyShortName}_${operatorId}`;
      const otherUrl = `https://uatai.lshauto.com.cn/csa/entryPoint/#/?agentId=1&enterpriseId=103&entId=b386ba0b9d4548899129337727b50f50&phone=${companyShortName}_${operatorId}`;
      const hrefUrl = isProductionOrUat ? productionOrUatUrl : otherUrl;
      this.ctiSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(hrefUrl);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  }

  initDragElement(): void {
    this.parentElem = this.elem.nativeElement.parentNode.parentNode.parentNode.parentNode;
    const offsetWidth = this.parentElem.offsetWidth / 4;
    this.assistantElem = `${this.parentElem.offsetWidth - offsetWidth}px`;
    _.delay(() => {
      this.totalOffsetX = this.parentElem.offsetWidth - this.elem.nativeElement.offsetWidth - 100;
      this.totalOffsetY = 65;
      this.setPosition(0, 0);
    }, 10);
  }

  setPosition(clientX, clientY): void {
    let left = this.totalOffsetX + clientX - this.disX;
    if (left < 0) {
      left = 0;
    }

    let top = this.totalOffsetY + clientY - this.disY;
    if (top < 65) {
      top = 65;
    }

    const pw = this.parentElem.offsetWidth;
    const maxLeft = pw - this.elem.nativeElement.offsetWidth - 20;
    if (left > maxLeft) {
      left = maxLeft;
    }
    this.maxTop = `${top}px`;
    // TODO improve the calculate drag area efficiency.
    this.renderer.setStyle(this.elem.nativeElement, 'left', `${left}px`);
    this.renderer.setStyle(this.elem.nativeElement, 'top', `${top}px`);
  }

  onMousedown(e): void {
    e.stopPropagation();
    if (e.button !== 0) { return; }
    document.documentElement.onmouseup = this.onMouseup.bind(this);
    this.elem.nativeElement.onmouseup = this.onMouseup.bind(this);
    const loop = () => {
      this.isDown = true;
      e.preventDefault();
      this.disX = e.clientX;
      this.disY = e.clientY;
      document.documentElement.onmousemove = this.onMousemove.bind(this);
      this.elem.nativeElement.onmousemove = this.onMousemove.bind(this);
    };

    if (this.dragTm) {
      clearTimeout(this.dragTm);
    }
    this.dragTm = setTimeout(loop, 10);
    this.changezIndex(true);
  }
  onMousemove(event): boolean {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isDown) {
      return false;
    }
    this.isMoved = true;
    this.setPosition(event.clientX, event.clientY);
  }
  onMouseup(event): void {
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
    }

    this.isDown = false;
    if (this.dragTm) {
      clearTimeout(this.dragTm);
    }
    document.documentElement.onmousemove = null;
    document.documentElement.onmouseup = null;
    this.elem.nativeElement.onmousemove = null;
    this.elem.nativeElement.onmouseup = null;
    this.isMoved = false;
  }

  minAssistant() {
    this.foldAssistantModal.emit();
    this.foldMinFlag = true;
  }

  changezIndex(value) {
    const className: any = document.getElementById('app-intelligent-assistant');
    if (value && this.isCallEnd) {
      className.style.zIndex = 1056;
    } else {
      className.style.zIndex = 1054;
    }
  }

}

import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import {
  ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_MUl_INIT,
  ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_MUl_OPTIONS,
  DROPDOWN_DATE_RANGE_OPTION_MONTH,
  DROPDOWN_DATE_RANGE_OPTION_QUARTER,
  DROPDOWN_DATE_RANGE_OPTION_YEAR,
  DROPDOWN_DATE_RANGE_OPTIONS_DAY,
  FILTER_INPUT_TYPES,
  MONTH_SEASON_AND_YEAR_REFRESH_TIME,
  REFRESH_TIME, VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import * as moment from 'moment';
import { DATE_FORMAT } from '../../_common/constants/common';
import { amountFormatter, getAccidentCarSettingByEnvironment, percentageFormatter } from '../utils/utils';
import { PrivilegesEnum } from '../../_common/services/user-privilege.service';

export const ACCIDENT_CAR_INSURANCE_LEADS_ANALYZE_CONFIG = {
  name: 'accident-car-insurance-leads-analyze-report',
  enablePagination: true,
  pageSize: 50,
  filterThreeColumn: true,
  filterFourColumn: true,
  exportedFileName: '保险公司车险线索分析报表.xlsx',
  timeFormatter: 'YYYYMMDDHHmmss',
  isNameWithDate: true,
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  refreshTime: REFRESH_TIME,
  monthAndQuarterAndYearRefreshTime: MONTH_SEASON_AND_YEAR_REFRESH_TIME,
  defaultSearchWhenEnterReport: true,
  showSummaryLine: true,
  nowrap:  true,
  vertical: true,
  newLineOperation: true,
  isNameWithVehicleBusinessType: true,
  scopeOfReport: '*本报表仅统计车险线索，不含钣喷专享礼线索及车主上报线索。其中车险线索包含三类：保险公司发送的车险短信、保险公司API推送的线索、经销商手动创建且线索来源为保险公司的线索。此外，保险公司发送的车险短信中因48小时内短信内容完全一致而未生成的无效线索，也会统计在内。',
  column: [
    {
      headerName: '时间',
      field: 'reportDate',
      width: 230,
      pinned: true,
    },
    {
      headerName: '经销商代码',
      field: 'gsCode',
      width: 120,
      showByPrivilege: PrivilegesEnum.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT
    },
    {
      headerName: '经销商名称',
      field: 'dealerName',
      width: 150,
      showByPrivilege: PrivilegesEnum.ROLE_AS_GROUP_VIEW_ACCIDENT_CAR_LEAD_MANAGEMENT_REPORT
    },
    {
      headerName: '保险公司',
      field: 'insuranceCompanyName',
      width: 100,
    },
    {
      headerName: '线索总量（不去重）',
      field: 'totalCount',
      width: 140,
    },
    {
      headerName: '线索总量（不去重）占比',
      field: 'totalCountRate',
      width: 160,
      valueFormatter: percentageFormatter
    },
    {
      headerName: '有效线索量',
      field: 'validAccidentCount',
      width: 140,
    },
    {
      headerName: '有效线索占比',
      field: 'validAccidentCountRate',
      width: 140,
      valueFormatter: percentageFormatter
    },
    {
      headerName: '产值（含税）',
      field: 'totalGrossAmount',
      width: 140,
      valueFormatter: amountFormatter
    },
    {
      headerName: '产值占比',
      field: 'totalGrossAmountRate',
      width: 100,
      valueFormatter: percentageFormatter
    },
    {
      headerName: '无效线索量',
      field: 'invalidAccidentCount',
      width: 140,
    },
    {
      headerName: '无效线索占比',
      field: 'invalidAccidentCountRate',
      width: 140,
      valueFormatter: percentageFormatter
    },
    {
      headerName: '\"重复推送线索\"量',
      field: 'repeatPushCount',
      width: 140,
    },
    {
      headerName: '\"修理厂报案\"量',
      field: 'repairShopReportCount',
      width: 140,
    },
    {
      headerName: '\"4S店报案\"量',
      field: 'automobileSalesServiceShop4SReportCount',
      width: 140,
    },
    {
      headerName: '\"当地车外地出险外地维修\"量',
      field: 'localMaintenanceCount',
      width: 200,
    },
    {
      headerName: '\"非奔驰车\"量',
      field: 'notBenzCount',
      width: 150,
    },
  ],

  filter: [
    {
      type: FILTER_INPUT_TYPES.DROPDOWN_DATE_RANGE,
      title: '选择时间',
      optionName: 'dimension',
      options: {
        ...DROPDOWN_DATE_RANGE_OPTIONS_DAY,
        ...DROPDOWN_DATE_RANGE_OPTION_MONTH,
        ...DROPDOWN_DATE_RANGE_OPTION_QUARTER,
        ...DROPDOWN_DATE_RANGE_OPTION_YEAR,
      },
      defaultOptionValue: DROPDOWN_DATE_RANGE_OPTIONS_DAY.DAY.value,
      resetWhenChangeOption: true,
      name: 'createdTime',
      isRangeLimited: true,
      allRequired: true,
      limitValueRange: 60,
      startMinLimitValue: getAccidentCarSettingByEnvironment('startMinLimitValue'),
      startLimitValue: moment(),
      endLimitValue: moment(),
      endMinLimitValue: getAccidentCarSettingByEnvironment('endMinLimitValue'),
      endMaxLimitValue: moment(),
      itemWidth: 430,
      defaultBeginDate: moment().format(DATE_FORMAT),
      defaultEndDate: moment().format(DATE_FORMAT),
    },
    {
      type: FILTER_INPUT_TYPES.MULTI_DROPDOWN,
      name: 'insuranceCompanyCodes',
      label: '保险公司',
      itemWidth: 205,
      options: ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_MUl_OPTIONS.values,
      defaultValue: ACCIDENT_CAR_LEAD_INSURANCE_COMPANY_NAME_MUl_INIT,
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleBusinessType',
      label: '车辆类型',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
      className: 'vehicle-business-type',
      visible: true,
    },
  ],
};

import { NgModule } from '@angular/core';
import { ScrollControlDirective } from './scroll-control/scroll-control.directive';
import { MiniComponent } from './mini/mini.component';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';
import { RouterModule } from '@angular/router';
import { TabForPartsPurchaseComponent } from './tab-for-parts-purchase/tab.component';
import { WebsiteCommonModule } from '@otr/website-common';
import { DealerForReportDragBarModule } from './dealer-for-report-drag-bar/dealer-for-report-drag-bar.module';
import { CtiLoginStatusDropdownComponent } from './mini/cti-login-status-dropdown/cti-login-status-dropdown.component';
import { LeadService } from '../customer-lead-management/lead-management/sales-lead/services/lead.service';

@NgModule({
  imports: [
    CommonModule,
    InlineSVGModule,
    RouterModule,
    WebsiteCommonModule,
    // DealerForReportDragBarModule,
  ],
  declarations: [
    MiniComponent,
    ScrollControlDirective,
    TabForPartsPurchaseComponent,
    CtiLoginStatusDropdownComponent,
  ],
  providers: [
    LeadService,
  ],
  exports: [
    MiniComponent,
    ScrollControlDirective,
  ],
})
export class LayoutModule {}

<div
  #container
  class="follow-ups-container"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <span #countBox>
    {{ followUpCount }}
  </span>
  <ul
    *ngIf='isHoverIn && followUpList.length > 0'
    class="follow-ups"
    [class.pop-to-top]="popToTop"
  >
    <li *ngFor="let followUp of followUpList; let i = index" class="follow-up">
      <span *ngIf="followUp.type === 'followup'" class="follow-up-index">跟进 {{i + 1}} </span>
      <span *ngIf="followUp.type === 'reservation'" class="follow-up-index">已预约 </span>
      <span *ngIf="followUp.type === 'close'" class="follow-up-index">已关闭 </span>
      <div class="follow-up-detail">
        <p>{{followUp.date}}</p>
        <span>{{followUp.reason}}</span>
      </div>
    </li>
  </ul>
</div>

export enum FeatureToggle {
  INSTANT_MESSAGE = 'INSTANT_MESSAGE',
  OEM_ICON_DISUSE = 'OEM_ICON_DISUSE',
  SERVICE_CONTRACT_ENHANCEMENT = 'SERVICE_CONTRACT_ENHANCEMENT',
  PARTS_REQUEST = 'PARTS_REQUEST',
  INSURANCE_ACCIDENT_APPEAL = 'INSURANCE_ACCIDENT_APPEAL',
  INSURANCE_SALES = 'INSURANCE_SALES',
  APPEAL_RESULT_AND_REPORT_THIRD_PARTY = 'APPEAL_RESULT_AND_REPORT_THIRD_PARTY',
  INSURANCE_ACCIDENT_APPEAL_THIRD_PARTY = 'INSURANCE_ACCIDENT_APPEAL_THIRD_PARTY',
  INSURANCE_ACCIDENT_APPEAL_RESULT_AND_REPORT = 'INSURANCE_ACCIDENT_APPEAL_RESULT_AND_REPORT',
  INSURANCE_GU_YU = 'INSURANCE_GU_YU',
}

export enum IntegrationFeature {
  ICON_SERVICE_CONTRACT_DM = 'ICON_SERVICE_CONTRACT_DM',
  NEW_EXTEND_TOGGLE = 'NEW_EXTEND_ENABLED',
  LOCAL_EXTEND_WARRANTY = 'LOCAL_EXTEND_WARRANTY'
}

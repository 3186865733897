import { Component } from '@angular/core';
import * as _ from 'lodash';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  templateUrl: './custom-checkbox-cell.component.html',
  styleUrls: ['./custom-checkbox-cell.component.scss'],
})
export class CustomCheckboxCellRenderComponent implements ICellRendererAngularComp {
  disabled: boolean;
  hideLabel: boolean;
  private params;

  agInit(params): void {
    this.params = params;
    this.disabled = params.colDef.isDisabled && params.colDef.isDisabled(params.data);
    this.hideLabel = _.isNil(params.colDef.display) || (params.colDef.display && params.colDef.display(params.data));
    if (params.colDef.defaultChecked && params.colDef.defaultChecked(params.data)) {
      this.params.node.selectThisNode(true);
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  get checked() {
    return !!this.selectedRows[this.params.node.id];
  }

  toggle(event) {
    this.params.node.selectThisNode(event.target.checked);
    if (this.params && this.params.colDef && this.params.colDef.toggle) {
      this.params.colDef.toggle(this.params, event.target.checked);
    }
  }

  private get selectedRows() {
    return this.params.node.selectionController.selectedNodes;
  }
}

<div>
  <div class="input-panel">
    <input class="form-control double-input"
           [placeholder]="leftPlaceholder"
           [(ngModel)]="leftValue"
           (change)="onLeftInputChange($event)"
           [maxLength]="leftMaxLength"
           [ngClass]="{'input-error': leftInvalidMessage}"
    >
    <span class="character">+</span>
    <input class="form-control double-input"
           [placeholder]="rightPlaceholder"
           [(ngModel)]="rightValue"
           (change)="onRightInputChange($event)"
           [maxLength]="rightMaxLength"
           [ngClass]="{'input-error': rightInvalidMessage}"
    >
  </div>
  <div *ngIf="leftInvalidMessage || rightInvalidMessage" class="error-message">
    {{leftInvalidMessage || rightInvalidMessage}}
  </div>
</div>

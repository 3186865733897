import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { Enum } from 'app/_common/utils/enum';
import { findGet, isEmptyValue } from '../utils/common';

@Pipe({ name: 'option' })
export class OptionPipe implements PipeTransform {
  transform<T>(optionValue: any, optionList: Array<{ value: any, text: any }> | Enum<T>): any {
    if (isEmptyValue(optionList)) {
      return optionValue;
    }
    const textFound = findGet(_.isArray(optionList) ? optionList : optionList.values,
      { value: optionValue }, 'text', '');
    return isEmptyValue(textFound) && !isEmptyValue(optionValue) ? optionValue : textFound;
  }
}

export enum RolePrivilege {
  ROLE_CUSTOMER_SIGNATURE = 'ROLE_CUSTOMER_SIGNATURE',
  ROLE_AS_YEARLY_TARGET_MANAGEMENT = 'ROLE_AS_YEARLY_TARGET_MANAGEMENT',
  ROLE_SALES_YEARLY_TARGET_MANAGEMENT = 'ROLE_SALES_YEARLY_TARGET_MANAGEMENT',
  ROLE_CREATE_PROCESS_CASE = 'ROLE_CREATE_PROCESS_CASE',
  ROLE_GROUP_SALES_LEADS = 'ROLE_GROUP_SALES_LEADS',
  ROLE_MY_SALES_LEADS = 'ROLE_MY_SALES_LEADS',
  ROLE_PERSONAL_LEAD_CUSTOMER = 'ROLE_PERSONAL_LEAD_CUSTOMER',
  ROLE_SELL_SERVICE_CONTRACT_UNDER_MSRP = 'ROLE_SELL_SERVICE_CONTRACT_UNDER_MSRP',
  ROLE_DUTY_SCHEDULE_MANAGEMENT = 'ROLE_DUTY_SCHEDULE_MANAGEMENT',
  ROLE_SHOWROOM_VISIT_MANAGEMENT = 'ROLE_SHOWROOM_VISIT_MANAGEMENT',
  ROLE_TEST_DRIVE_MANAGEMENT = 'ROLE_TEST_DRIVE_MANAGEMENT',
  ROLE_ORDER_KPI_FORMULA = 'ROLE_ORDER_KPI_FORMULA',
  ROLE_SERVICE_CONTRACT = 'ROLE_SERVICE_CONTRACT',
  ROLE_APPROVE_CASE = 'ROLE_APPROVE_CASE',
  ROLE_SALES_SUPPORT_SERVICE_CONTRACT = 'ROLE_SALES_SUPPORT_SERVICE_CONTRACT',
  ROLE_ORDER_APPROVAL_PARAMETER = 'ROLE_ORDER_APPROVAL_PARAMETER',
  ROLE_VARIANT_PARAMETER_COLUMN_EDIT = 'ROLE_VARIANT_PARAMETER_COLUMN_EDIT',
  ROLE_PURCHASED_CUSTOMERS_LIST = 'ROLE_PURCHASED_CUSTOMERS_LIST',
  ROLE_SALES_CAMPAIGN_MANAGEMENT = 'ROLE_SALES_CAMPAIGN_MANAGEMENT',
  ROLE_AS_CAMPAIGN_MANAGEMENT = 'ROLE_AS_CAMPAIGN_MANAGEMENT',
  ROLE_DEALER_GROUP_LEAD_REPORT = 'ROLE_DEALER_GROUP_LEAD_REPORT',
  ROLE_MY_LEAD_REPORT = 'ROLE_MY_LEAD_REPORT',
  ROLE_AFTER_SALES_LEAD_CONFIG = 'ROLE_AFTER_SALES_LEAD_CONFIG',
  ROLE_DEALER_GROUP_COMPLAINT_REPORT = 'ROLE_DEALER_GROUP_COMPLAINT_REPORT',
  ROLE_WORKSHOP_BOARD_CUSTOMER = 'ROLE_WORKSHOP_BOARD_CUSTOMER',
  ROLE_AS_VALET_ORDER = 'ROLE_AS_VALET_ORDER',
  ROLE_SERVICE_RECORD_BY_INSURANCE_LEAD_ID_IN_ALL_DEALER = 'ROLE_SERVICE_RECORD_BY_INSURANCE_LEAD_ID_IN_ALL_DEALER',
  ROLE_BUYBACK_BIDDING_AUCTION = 'ROLE_BUYBACK_BIDDING_AUCTION',
  ROLE_USED_CAR_LEAD_MANAGEMENT = 'ROLE_USED_CAR_LEAD_MANAGEMENT',
  ROLE_WEBSITE_USED_CAR_LEAD_MANAGEMENT = 'ROLE_WEBSITE_USED_CAR_LEAD_MANAGEMENT',
  ROLE_AGENT_PERSONAL_LEAD_CUSTOMER = 'ROLE_AGENT_PERSONAL_LEAD_CUSTOMER',
  ROLE_AGENT_GROUP_SALES_LEADS = 'ROLE_AGENT_GROUP_SALES_LEADS',
  ROLE_AGENT_MY_SALES_LEADS = 'ROLE_AGENT_MY_SALES_LEADS',
  ROLE_INSURANCE_QUERY_MAINTENANCE_HISTORY_BY_CUSTOMER_ID_MANAGEMENT = 'ROLE_INSURANCE_QUERY_MAINTENANCE_HISTORY_BY_CUSTOMER_ID_MANAGEMENT',
  ROLE_VIEW_INSURANCE_POLICY = 'ROLE_VIEW_INSURANCE_POLICY',
  ROLE_AS_OEM_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT = 'ROLE_AS_OEM_VIEW_ACCIDENT_CAR_GROUP_LEAD_REPORT',
  ROLE_DEALER_NOTIFICATION_FOR_NEW_CAR_NEXT_YEAR_APPEAL_RESULT = 'ROLE_DEALER_NOTIFICATION_FOR_NEW_CAR_NEXT_YEAR_APPEAL_RESULT',
  ROLE_DEALER_NEW_CAR_POLICY_SUPPORT_EXPORT_RECORD_LIST_MANAGEMENT = 'ROLE_DEALER_NEW_CAR_POLICY_SUPPORT_EXPORT_RECORD_LIST_MANAGEMENT',
  ROLE_DEALER_NEW_CAR_NEXT_YEAR_POLICY_SUPPORT_EXPORT_RECORD_LIST_MANAGEMENT = 'ROLE_DEALER_NEW_CAR_NEXT_YEAR_POLICY_SUPPORT_EXPORT_RECORD_LIST_MANAGEMENT',
  ROLE_OEM_THIRD_PARTY_NEW_CAR_POLICY_SUPPORT_EXPORT_RECORD_LIST_MANAGEMENT = 'ROLE_OEM_THIRD_PARTY_NEW_CAR_POLICY_SUPPORT_EXPORT_RECORD_LIST_MANAGEMENT',
  ROLE_OEM_THIRD_PARTY_NEW_CAR_NEXT_YEAR_POLICY_SUPPORT_EXPORT_RECORD_LIST_MANAGEMENT = 'ROLE_OEM_THIRD_PARTY_NEW_CAR_NEXT_YEAR_POLICY_SUPPORT_EXPORT_RECORD_LIST_MANAGEMENT',
  ROLE_OEM_THIRD_PARTY_NEW_CAR_POLICY_SUPPORT_IMPORT_RECORD_LIST_MANAGEMENT = 'ROLE_OEM_THIRD_PARTY_NEW_CAR_POLICY_SUPPORT_IMPORT_RECORD_LIST_MANAGEMENT',
  ROLE_OEM_THIRD_PARTY_NEW_CAR_NEXT_YEAR_POLICY_SUPPORT_IMPORT_RECORD_LIST_MANAGEMENT = 'ROLE_OEM_THIRD_PARTY_NEW_CAR_NEXT_YEAR_POLICY_SUPPORT_IMPORT_RECORD_LIST_MANAGEMENT',
  ROLE_OEM_THIRD_PARTY_ACCIDENT_CAR_PARTS_PURCHASE = 'ROLE_OEM_THIRD_PARTY_ACCIDENT_CAR_PARTS_PURCHASE',
  ROLE_OEM_THIRD_PARTY_APPEAL_PROCESS_REPORT_EXPORT = 'ROLE_OEM_THIRD_PARTY_APPEAL_PROCESS_REPORT_EXPORT',
  ACCESSORY_DISCOUNT_CHART_RESULT = 'ACCESSORY_DISCOUNT_CHART_RESULT',
  ROLE_OEM_THIRD_PARTY_ACCESSORY_DISCOUNT_CHART_RESULT = 'ROLE_OEM_THIRD_PARTY_ACCESSORY_DISCOUNT_CHART_RESULT',
  ROLE_DEALER_NEXT_YEAR_RENEWAL_ACCESS_CHART_RESULT = 'ROLE_DEALER_NEXT_YEAR_RENEWAL_ACCESS_CHART_RESULT',
  ROLE_OEM_THIRD_PARTY_NEXT_YEAR_RENEWAL_ACCESS_CHART_RESULT = 'ROLE_OEM_THIRD_PARTY_NEXT_YEAR_RENEWAL_ACCESS_CHART_RESULT',
  ROLE_OEM_THIRD_PARTY_NEW_CAR_NEXT_YEAR_POLICY_SUPPORT_TIPS_MANAGEMENT = 'ROLE_OEM_THIRD_PARTY_NEW_CAR_NEXT_YEAR_POLICY_SUPPORT_TIPS_MANAGEMENT',
  ROLE_OEM_THIRD_PARTY_APPLY_INFO_CHECK_ADD_VIN_AND_UUID_ENHANCE = 'ROLE_OEM_THIRD_PARTY_APPLY_INFO_CHECK_ADD_VIN_AND_UUID_ENHANCE',
  ROLE_DEALER_APPLY_INFO_CHECK_ADD_VIN_AND_UUID_ENHANCE = 'ROLE_DEALER_APPLY_INFO_CHECK_ADD_VIN_AND_UUID_ENHANCE',
  ROLE_OEM_THIRD_PARTY_OF_REPORT = 'ROLE_OEM_THIRD_PARTY_OF_REPORT',
  ROLE_VIEW_FOLLOW_UP_REPORT = 'ROLE_VIEW_FOLLOW_UP_REPORT',
  ROLE_WEBSITE_VEHICLE_RELEASE_APPROVAL = 'ROLE_WEBSITE_VEHICLE_RELEASE_APPROVAL',
}

export const SERVICE_CONTRACT_PRIVILEGES = [
  RolePrivilege.ROLE_SERVICE_CONTRACT,
  RolePrivilege.ROLE_SELL_SERVICE_CONTRACT_UNDER_MSRP,
  RolePrivilege.ROLE_SALES_SUPPORT_SERVICE_CONTRACT,
];

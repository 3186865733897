import { OptionPipe } from '../../../../_common/pipes/option.pipe';
import * as _ from 'lodash';
import { fixNumberComma } from '../../../../_common/utils/format-string';
import {
  canShowNotDMPLabel,
  filterServiceType, getLeadFailedReasonValue,
  getLeadOrderedStatusValue,
  getLeadStatusImage, getReservationFailedReasonValue,
  getReservationStatusImage,
  getReservationStatusValue,
  isNormalOfferLeadType,
  isNotEmergencyOfferLeadType,
  isNotOrderedAndManualLead,
  isNotDigitalServiceOfferLeadType,
  isNotPweiOfferLeadType,
  isNotRecallOfferLeadType,
  splitServiceType,
} from '../as-lead';
import { AS_LEAD_TYPE_ENUM, CUSTOMER_REMIND, LEAD_STATUS_ENUM } from '../../constant';
import { findGet } from '../../../../_common/utils/common';
import { SERVICE_TYPE } from '../../../../service-order/constant';

const optionPipe = new OptionPipe();

const formatPrice = value => _.isNil(value) || _.isEqual(value, 'N/A') ? 'N/A' : `￥${fixNumberComma(value)}`;

const combineDescs = (descs: Array<any>) => _.chain(descs).filter(text => !_.isEmpty(text)).join(';').value();

export const getEmergencyNames = ({ data: lead }) => _.map(lead.emergencyMessages, 'name');

export const getDigitalServiceName = ({ data: lead }) => _.get(lead.digitalService, 'name');

export const getEmergencyDescs = ({ data: lead }) => _.map(lead.emergencyMessages, 'description');

export const getPweiOrDigitalServiceDesc = ({ data: lead }) =>
  isNotDigitalServiceOfferLeadType(lead.leadType) ?
    (isNotPweiOfferLeadType(lead.leadType) ? '' : _.get(lead, 'pweiMessages.description'))
    : _.get(lead, 'digitalService.description');

const formatService = services => {
  return _.map(services, service => {
    if (!service.recommendProduct) {
      return { ...service, priceIncludingVat: null };
    }
    if (!service.priceIncludingVat) {
      return { ...service, priceIncludingVat: 'N/A' };
    }
    return service;
  });
};

const getNormalOfferServiceProducts = lead => {
  const originalServices = _.get(lead, 'serviceProducts', []);
  const addedServices = _.map(lead.emergencyMessages, em => {
    return { recommendProduct: em.name, backupProduct: em.description };
  });
  return formatService(_.concat(originalServices, addedServices));
};

export const getRecommendProducts = ({ data: lead }) => {
  if (isNotOrderedAndManualLead(lead) && isNotRecallOfferLeadType(lead.leadType)) {
    return getAllRecommendProducts(lead);
  }
  return [];
};

export const getRecommendProductsLostSale = ({ data: lead }) => {
  if (isNotOrderedAndManualLead(lead) && isNotRecallOfferLeadType(lead.leadType)) {
    return getAllRecommendProductsString(lead);
  }
  return '';
};

export const getParts = ({ data: lead }) => {
  const parts = _.reduce(
    _.get(lead, 'serviceProducts', []),
    (results, item) => {
      const part = _.get(item, 'part') || [];
      return [...results, ...part];
    },
    [],
  );
  if (!_.isEmpty(parts)) {
    return _.map(parts, 'partName').join(',') || '';
  }
  return '';
};

const getAllRecommendProducts = lead => {
  const services = isNormalOfferLeadType(lead.leadType) ? getNormalOfferServiceProducts(lead)
    : formatService(_.get(lead, 'serviceProducts', []));
  return _.chain(services).filter(service => !_.isEmpty(service))
    .map('recommendProduct').value();
};

const getAllRecommendProductsString = lead => {
  const services = isNormalOfferLeadType(lead.leadType) ? getNormalOfferServiceProducts(lead)
    : formatService(_.get(lead, 'serviceProducts', []));
  return _.chain(services).filter(service => !_.isEmpty(service))
    .map('recommendProduct').value().toString();
};

export const getPrices = ({ data: lead }) => {
  if (isNotOrderedAndManualLead(lead) && isNotRecallOfferLeadType(lead.leadType)) {
    return getAllPrices(lead);
  }
  return [];
};

export const getPriceList = value => ({ textList: getPrices(value) });

const getAllPrices = lead => {
  const services = isNormalOfferLeadType(lead.leadType) ? getNormalOfferServiceProducts(lead)
    : formatService(_.get(lead, 'serviceProducts', []));
  return _.chain(services).filter(service => !_.isEmpty(service) && !_.isNil(service.recommendProduct))
    .map(service => formatPrice(_.get(service, 'priceIncludingVat'))).value();
};

export const joinBackupProducts = ({ data: lead }) => {
  if (isNotRecallOfferLeadType(lead.leadType)) {
    const backupProducts = _.map(_.get(lead, 'serviceProducts', []), 'backupProduct');
    return combineDescs(backupProducts);
  }
  return '';
};

export const getRegisteredLetter = value => value ? '是' : '否';

export const getClientStatus = ({ data: lead }) => _.get(lead, 'clientPushStatus', '');

export const getRecallCustomerRemind = ({ data: lead }) => (_.isEqual(lead.leadType, AS_LEAD_TYPE_ENUM.RECALL.value)
  && lead.followUpCount > 0)
  ? getRegisteredLetter(_.get(lead, 'extendedInfo.customerRemind')) : null;

export const getRecallFailedResult = ({ data: lead }) => (_.isEqual(lead.leadType, AS_LEAD_TYPE_ENUM.RECALL.value)
  && lead.followUpCount > 0) && !_.get(lead, 'extendedInfo.customerRemind') ?
  _.get(lead, 'extendedInfo.failedResult') : '';

export const getServiceType = ({ data: lead }) => {
  if (isNotOrderedAndManualLead(lead)) {
    return getAllServiceType(lead);
  }
  return null;
};

export const getRisk = ({ data: lead }) => {
  if (_.get(_.get(lead, 'lostSale'), 'riskLevel')) {
    return _.get(_.get(lead, 'lostSale'), 'riskLevel');
  }
  return '';
};

const getAllServiceType = lead => getServiceTypeText({ data: lead });

export const formatFollowUpTooltipValue = (followupStrategy = {}) => _.map(
  Object.keys(strategyTooltipMapper),
  field => ({ title: strategyTooltipMapper[field], value: _.get(followupStrategy, field, 'N/A') }));

const strategyTooltipMapper = {
  strategyDesc: '策略摘要',
  strategyScript: '提示话术',
};

export const formatLeadStatusTooltipValue =  ({ data: lead }) =>
  (_.compact([
    getLeadOrderedStatusValue(lead),
    {
      title: leadStatusTooltipMapper[lead.leadStatus],
      value: lead.leadStatus === 'ORDERED' ? lead.reservationDate : lead.nextFollowUpDate,
    },
    getLeadFailedReasonValue(lead)]));

export const formatReservationTooltipValue =  ({ data: reservation }) =>
  (_.compact([getReservationStatusValue(reservation), getReservationFailedReasonValue(reservation)]));

export const getLeadStatusIcon = ({ data: lead }) => getLeadStatusImage(lead) || '/assets/as-lead/calendar.svg';

export const getReservationStatusIcon = ({ data: reservation }) => getReservationStatusImage(reservation, 'status');

export const disableReservation = ({ data: reservation }) => !_.isNull(getReservationStatusValue(reservation));

const leadStatusTooltipMapper = {
  PENDING: '下次跟进时间',
  ORDERED: '预约时间',
};

export const getNotes = ({ data: lead }) => _.get(lead, 'notes', '');
export const getCreatedAt = ({ data: lead }) => _.get(lead, 'createdAt');
export const getOemSpecialName = ({ data: lead }) => _.get(lead, 'oemSpecial.name');
export const getSanName = ({ data: lead }) => _.get(lead, 'san.name');
export const getEmergencyDescList = value => ({ textList: getEmergencyDescs(value) });
export const getPweiDueDate = ({ data }) => _.get(data, 'pweiMessages.dueDate');
export const getPweiLeftDate = ({ data }) => _.get(data, 'pweiMessages.leftDate');
export const getPweiItemCode = ({ data }) => _.get(data, 'pweiMessages.itemCode', 'PWEI');

export const getRcCampId = ({ data }) => _.get(data, 'extendedInfo.rcCampId');
export const getRcId = ({ data }) => _.get(data, 'extendedInfo.rcId');
export const getRcCampaign = ({ data: lead }) => _.get(lead, 'extendedInfo.rcCampaign', '');
export const getRcDesc = ({ data }) => _.get(data, 'extendedInfo.rcDesc');
export const getRcStartDate = ({ data }) => _.get(data, 'extendedInfo.rcStartDate');
export const getRcEndDate = ({ data }) => _.get(data, 'extendedInfo.rcEndDate');

export const canShowNotDMPLabelInRecommendProduct = lead => isNotEmergencyOfferLeadType(lead.leadType) && canShowNotDMPLabel(lead);

export const formatText = text => _.defaultTo(text, '');
export const offerShouldShowIcon = entireText => formatText(entireText).length > 20;
export const partsShouldShowIcon = entireText => formatText(entireText).length > 15;
// export const offerSplitText = entireText => formatText(entireText).slice(0, 20);
export const offerSplitText = entireText => formatText(entireText).replace(/\n/g, '；').slice(0, 20);

export const strategySplitText = entireText => formatText(entireText).slice(0, 50);
export const partsSplitText = entireText => formatText(entireText).slice(0, 15);

export const formatLeadStatus = ({ data: lead }) => optionPipe.transform(lead.leadStatus, LEAD_STATUS_ENUM);

export const getTooltipValues = ({ data: lead }) => formatFollowUpTooltipValue(_.get(lead, 'nextFollowUpStrategy', {}));

export const getReservationComments = ({ data: reservation }) => _.truncate(reservation.reservationComments, { length: 20 });

export const getReservationProducts = data =>
  _.join(getReservationProductsArray(data), ',');

export const getReservationProductsArray = ({ data: reservation }) =>
  _.chain(_.get(reservation, 'bookedServiceProducts')).map('name').compact().uniq().value();

export const getLabels = ({ data: reservation }) => {
  return _.chain(_.get(reservation, 'leads', []))
    .reduce((result, lead) => _.concat(result, [_.get(lead, 'leadTypeLabel'), _.get(lead, 'rcCampaign')]), [])
    .compact().uniq().join(',').value();
};

export const getServiceTypeText = ({ data: reservation }) =>
  _.chain(filterServiceType(splitServiceType(reservation.serviceType)))
    .map(type => findGet(SERVICE_TYPE.values, ['key', type], 'text'))
    .join(',').value();

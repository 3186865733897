import { Injectable } from '@angular/core';
import { TokenApi } from '../api/token';

@Injectable()
export class OtherTokenService {
  constructor(private tokenApi: TokenApi) {}

  getOneTimeToken(expirationTime = 60) {
    return this.tokenApi.getOneTimeToken(expirationTime);
  }
}

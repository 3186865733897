import { Injectable } from '@angular/core';
import { RebirthHttp, GET, Query, Body, PUT } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApprovalRoleApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/customer-parameter/role')
  getCustomParameterRoles(@Query('group_id') groupId: number): Observable<any> {
    return null;
  }

  @PUT('/api/customer-parameter/role')
  updateCustomParameterRoles(@Body parameterRoleList): Observable<any> {
    return null;
  }
}

<div class="cell-container" [style.width.px]="width">
  <div class="cell-row-container" *ngFor="let header of headers">
    <div class="cell-row" *ngFor="let subTitle of header.subTitles">
      <span *ngIf="data[header.id]">
        <ng-template [ngIf]="!isEditable(data, header, subTitle)">
          {{formattedValue(data[header.id][subTitle.field])}}
          <span *ngIf="shouldShowPercentageFlag(data,header,subTitle)">%</span>
        </ng-template>
        <ng-template [ngIf]="isEditable(data, header, subTitle)">
          <div [class.percentage]="shouldShowPercentageFlag(data,header,subTitle)">
            <input #input
                   (blur)="onBlurChange(input.value, header.id, subTitle.field)"
                   (keydown)="onKeyDown($event)"
                   class="input-control" [value]="formattedValue(data[header.id][subTitle.field])"
                   [disabled]="disabled">
          </div>
        </ng-template>
      </span>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { KeyValue } from '../../models/common';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from 'app/_common/utils/autoUnsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnDestroy, OnChanges {
  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() onInput = new EventEmitter();
  @Input() optionSubject: Observable<Array<KeyValue>>;
  @Input() options: KeyValue[] = [];
  /**
   * @deprecated use control instead. FORBIDDEN to use selectedValue and control at the same time
   */
  @Input() selectedValue;
  @Input() dropDownContainer = null;
  @Input() keywordPlaceholder: string = '';
  @Input() searchable: boolean = false;
  @Input() accurateSearch: boolean = false;
  @Input() disabled: boolean = false;
  @Input() optionDisabled: boolean = false;
  @Input() emptyOptionText: string = '';
  @Input() conditionValue: any = '';
  @Input() error: boolean = false;
  @Input() hasPlaceHolderStyle: boolean = false;
  @Input() style: any;
  @Input() placeholder: string;
  @Input() control: FormControl;
  @Input() optionHtmlGenerator: Function;
  @Input() getDisplayText: Function;
  @Input() isDropUp: boolean = false;
  @Input() showNoData: boolean = true;
  @Input() isLoadDateError: boolean = false;
  @Input() isShowLongContainer: boolean = false;

  keyword$: BehaviorSubject<string> = new BehaviorSubject('');

  filteredOptions: Array<KeyValue>;

  private componentDestroy$: Observable<any>;

  @Input() getValue: any = (option) => _.get(option, 'value', option);
  @Input() getText: any = (option) => _.get(option, 'text', option);
  @Input() getAccurateSearchText: any = (option) => _.get(option, 'text', option);
  @Input() getFormatData: any = () => ({});
  keyword: FormControl = new FormControl('');
  ngOnInit() {
    if (this.optionSubject) {
      this.optionSubject.pipe(takeUntil(this.componentDestroy$)).subscribe((options) => (this.options = options));
    } else {
      this.optionSubject = of(this.options);
    }

    combineLatest(this.keyword$, this.optionSubject)
      .pipe(
        map(([keyword, options]) => this.filterOptions(options, keyword)),
        takeUntil(this.componentDestroy$),
      )
      .subscribe((filteredOptions) => (this.filteredOptions = filteredOptions));

    this.keyword.valueChanges.subscribe((value) => {
      this.handleFilter({ target: { value } });
    });
  }
  isOptionDisabled(value) {
    return this.optionDisabled && this.conditionValue.includes(value);
  }

  get controlValue() {
    return _.get(this.control, 'value');
  }

  public filterOptions(options: Array<KeyValue>, keyword: string) {
    if (this.accurateSearch && keyword) {
      return _.chain(options)
        .map((option) => ({
          text: this.getText(option),
          accurateSearchText: this.getAccurateSearchText(option),
          value: this.getValue(option),
          data: this.getFormatData(option),
        }))
        .filter(({ accurateSearchText }) => _.eq(_.lowerCase(accurateSearchText), _.lowerCase(keyword)))
        .value();
    }
    return _.chain(options)
      .map((option) => ({
        text: this.getText(option),
        value: this.getValue(option),
        data: this.getFormatData(option),
      }))
      .filter(({ text }) => _.includes(_.lowerCase(text), _.lowerCase(keyword)))
      .value();
  }

  get displayText() {
    if (!_.isEmpty(_.get(this.selectedValue, 'text'))) {
      return this.selectedValue.text;
    }
    const optionSelected = _.find(
      this.options,
      (option) => _.isEqual(this.getValue(option), this.selectedValue) || _.isEqual(this.getValue(option), this.controlValue),
    );
    if (!optionSelected) {
      return this.placeholder || '请选择';
    }
    return !_.isNil(this.getDisplayText) ? this.getDisplayText(optionSelected) : this.getText(optionSelected);
  }

  handleFilter({ target: { value } }) {
    this.keyword$.next(value);
    this.onInput.emit(this.keyword$.value);
  }

  handleClick(optionValue) {
    this.select.emit(optionValue);
    if (this.control) {
      this.control.setValue(optionValue);
    }
  }

  handleFocus(event) {
    this.focus.emit(event);
    const dropdownEl: HTMLDivElement = document.querySelector('bs-dropdown-container');
    if (dropdownEl) {
      dropdownEl.style.zIndex = '1060';
      dropdownEl.style.minWidth = '80px';
    }
  }

  hidePopup() {
    this.focus.next(false);
    this.keyword$.next('');
    this.keyword.setValue('');
  }

  preventEmitEvent(event: Event) {
    event.stopImmediatePropagation();
  }

  ngOnDestroy(): void {
    this.keyword$.complete();
  }

  get isEmpty() {
    return (
      (_.isNil(this.selectedValue) || (_.isPlainObject(this.selectedValue) && _.isEmpty(_.get(this.selectedValue, 'text')))) &&
      _.isEmpty(this.controlValue)
    );
  }

  get isPlaceHolderStyle(): boolean {
    return this.hasPlaceHolderStyle && this.displayText === this.placeholder;
  }

  get showCustomOption() {
    return !_.isNil(this.optionHtmlGenerator);
  }

  getOptionHtml(option) {
    if (this.showCustomOption) {
      return this.optionHtmlGenerator(option.data);
    }
    return `<span>${option.text}</span>`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const optionsChange: SimpleChange = _.get(changes, 'options');
    if (optionsChange && optionsChange.currentValue !== optionsChange.previousValue) {
      this.filteredOptions = this.filterOptions(optionsChange.currentValue, this.keyword$.getValue());
    }
  }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MODAL_CONFIG } from '../../constants/common';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-image-previewer',
  templateUrl: './image-previewer.component.html',
  styleUrls: ['./image-previewer.component.scss']
})
export class ImagePreviewerComponent implements OnInit {
  modalConfig = Object.assign({}, MODAL_CONFIG, { keyboard: false });
  @ViewChild('bsModal') bsModal: ModalDirective;
  @ViewChild('imageContent') imageContent: ElementRef;
  @ViewChild('imageContainer') imageContainer: ElementRef;
  @Input() photos: string[] = [];
  @Input() activeIndex: number = 0;
  shouldShowPageInfo = false;
  timeoutMS = 1000;
  pageInfoTimer;
  imageUpdateTimer;
  isLoading = false;

  scaleParams = {
    scale: 1,
    prevScrollLeft: 0,
    prevScrollTop: 0,
    prevWidth: 0,
    prevHeight: 0,
  };

  constructor() { }

  get currentImage(): string {
    return this.photos[this.activeIndex] || '';
  }

  get pageInfo(): string {
    return `${this.activeIndex + 1}/${this.photos.length}`;
  }

  get pageInfoStyle(): object {
    return this.shouldShowPageInfo ? {display: 'block'} : {display: 'none'};
  }

  get isPreviousEnable(): boolean {
    return this.activeIndex > 0;
  }

  get isNextEnable(): boolean {
    return this.activeIndex < this.photos.length - 1;
  }

  ngOnInit(): void {
  }

  show(index): void {
    this.activeIndex = index;
    this.bsModal.show();
    this.showImage();
  }

  hide(): void {
    this.bsModal.hide();
    this.resetImageStyle();
  }

  onPrevious(): void {
    if (this.isPreviousEnable) {
      this.activeIndex -= 1;
      this.showImage();
    }
  }

  onNext(): void {
    if (this.isNextEnable) {
      this.activeIndex += 1;
      this.showImage();
    }
  }

  zoomIn(): void {
    if (this.scaleParams.scale < 2) {
      this.scaleParams.scale += 0.1;
      this.updateImageStyle();
    }
  }

  zoomOut(): void {
    if (this.scaleParams.scale > 1) {
      this.scaleParams.scale -= 0.1;
      this.updateImageStyle();
    }
  }

  countingForPageInfoDisplay(): void {
    this.shouldShowPageInfo = true;
    clearInterval(this.pageInfoTimer);
    this.pageInfoTimer = setInterval(() => {
      this.shouldShowPageInfo = false;
      clearInterval(this.pageInfoTimer);
    }, 3000);
  }

  onImageContainerScroll($event): void {
    const { scrollLeft, scrollTop } = $event.target;
    this.scaleParams.prevScrollLeft = scrollLeft;
    this.scaleParams.prevScrollTop = scrollTop;
  }

  updateImageStyle(): void {
    const container = this.imageContainer.nativeElement;
    const content = this.imageContent.nativeElement;
    const size = this.imageDisplaySize(container, content);
    if (this.scaleParams.prevWidth === 0) {
      this.scaleParams.prevWidth = size.width;
    }
    if (this.scaleParams.prevHeight === 0) {
      this.scaleParams.prevHeight = size.height;
    }
    this.scaleParams.prevScrollLeft =
      (this.scaleParams.scale * size.width - this.scaleParams.prevWidth) / 2 + this.scaleParams.prevScrollLeft;
    this.scaleParams.prevScrollTop =
      (this.scaleParams.scale * size.height - this.scaleParams.prevHeight) / 2 + this.scaleParams.prevScrollTop;
    this.scaleParams.prevWidth = this.scaleParams.scale * size.width;
    this.scaleParams.prevHeight = this.scaleParams.scale * size.height;

    this.imageContainer.nativeElement.scrollLeft = Math.abs(this.scaleParams.prevScrollLeft);
    this.imageContainer.nativeElement.scrollTop = Math.abs(this.scaleParams.prevScrollTop);
    this.imageContent.nativeElement.style.height = `${this.scaleParams.prevHeight}px`;
  }

  imageDisplaySize(container, content): any {
    const height = content.naturalHeight > container.clientHeight ? container.clientHeight : content.naturalHeight;
    const width = height * (content.naturalWidth / content.naturalHeight);
    return { height, width };
  }

  showImage(): void {
    this.isLoading = true;
    clearInterval(this.pageInfoTimer);
    clearInterval(this.imageUpdateTimer);
    this.scaleParams.scale = 1;
    this.imageContent.nativeElement.style.visibility = 'hidden';
    this.imageUpdateTimer = setInterval(() => {
      this.updateImageStyle();
      this.imageContent.nativeElement.style.visibility = 'visible';
      this.countingForPageInfoDisplay();
      this.isLoading = false;
      clearInterval(this.imageUpdateTimer);
    }, this.timeoutMS);
  }

  resetImageStyle(): void {
    this.imageContent.nativeElement.style.visibility = 'hidden';
    setTimeout(() => {
      this.imageContainer.nativeElement.scrollTop = 0;
      this.imageContainer.nativeElement.scrollLeft = 0;
      this.scaleParams.scale = 1;
      this.scaleParams.prevScrollLeft = 0;
      this.scaleParams.prevScrollTop = 0;
      this.scaleParams.prevWidth = 0;
      this.scaleParams.prevHeight = 0;
      this.imageContent.nativeElement.style.visibility = 'visible';
    }, this.timeoutMS);
  }
}


    <div *ngIf="!disabled">
      <a
        [ngStyle]="linkStyle"
        [routerLink]="linkPartials"
        [queryParams]="queryParams"
        ><span (click)="handleClick($event)">{{ value }}</span></a
      >
    </div>
    <div *ngIf="disabled">
      <span>{{ value }}</span>
    </div>
  
import { Component, OnInit, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { CtiCallService } from '../../_common/services/cti-call.service';
import { TELEPHONIST_STATUS_OPTIONS, EVENT_STATUS_OPTIONS, CUSTOMER_STATUS_OPTIONS } from '../../_common/constants/cti';
import * as _ from 'lodash';
import { VehicleService } from '../../_common/services/vehicle.service';
import { UserManagementService } from '../../_common/services/user-management.service';
import { PromptLevel, LEAD_STATUS } from '../../_common/constants/common';
import { CtiApi } from '../../_common/api/cti';
import {
  GlobalEvent,
  GlobalEventService,
  PromptBarService,
  ModalMessageService,
  CurrentUserService,
  SpinnerService, FeatureToggleService,
} from '@otr/website-common';
import { AddFollowUpModalComponent } from '../../customer-lead-management/lead-management/sales-lead/add-follow-up-modal/add-follow-up-modal.component';
import { parseErrorMessage } from '../../_common/utils/http';
import { forkJoin, Observable, of as observableOf } from 'rxjs';
import { LEAD_SOURCE_FILTER_TYPE, LeadService } from '../../customer-lead-management/lead-management/sales-lead/services/lead.service';
import { Person } from '../../_common/models/lead';

import { GroupService } from '../../_common/services/group.service';
import { getSCGroupsWithNoEmptySC } from '../../_common/utils/filter';
import { phoneNumFormat } from './utils';
import { CTIAddFollowUpModalComponent } from './add-follow-up-modal/add-follow-up-modal.component';
import { isOpenLead } from '../../_common/utils/lead';

export type SelectType = 'B' | 'D';
@Component({
  selector: 'app-cti-main',
  templateUrl: './cti-main.component.html',
  styleUrls: ['./cti-main.component.scss'],
  providers: [],
})
export class CtiMainComponent implements OnInit, OnDestroy {
  inboundCallData: any = {};
  showCallEndModal: boolean = false;
  callEndData: any = {};
  callEndModal: boolean = false;
  timer;
  countDown = new Date().getTime();

  logonListenerId: string = '';
  agentidleListenerId: string = '';
  agentbusyListenerId: string = '';
  inringingListenerId: string = '';
  transferinringingListenerId: string = '';
  consultinringingListenerId: string = '';
  logoutListenerId: string = '';
  cancelmakecallListenerId: string = '';
  monitoragentListenerId: string = '';
  outringingListenerId: string = '';
  shouldShowSyncFollowupRecordsMoal: boolean = false;
  followUpRecords: Array<Person> = [];
  followUpFormValue;
  customerId;
  shouldShowJumpAfterSalesConfirmModal: boolean = false;
  phoneNumber;
  scGroupInfos;
  @ViewChild('salesLeadModal', { read: ViewContainerRef }) actionsModal: ViewContainerRef;
  showAssistantIcon: boolean = true;
  showIntelligentAssistant: boolean = false;
  @ViewChild('intelligentAssistant') intelligentAssistant;
  currentStatus: string;
  currentCustomerLeads = [];
  constructor(
    private ctiCallService: CtiCallService,
    private userManagementService: UserManagementService,
    private promptBarService: PromptBarService,
    private globalEventService: GlobalEventService,
    private ctiApi: CtiApi,
    private leadService: LeadService,
    private modalMessageService: ModalMessageService,
    private vehicleService: VehicleService,
    private currentUserService: CurrentUserService,
    private groupService: GroupService,
    private spinnerService: SpinnerService,
    private featureToggleService: FeatureToggleService,
  ) {
    globalEventService.register(GlobalEvent.LOGOUT, () => {
      CallCenter.logout();
    });
  }

  get isShowCTI() {
    return this.featureToggleService.isFeatureEnable('CTI_INTELLIGENT_ASSISTANT', false);
  }

  ngOnInit(): void {
    this.initCTIStatus();
    this.initCTIEventListener();
    this.timer = setInterval(() => {
      this.countDown = new Date().getTime();
    }, 1000);
    this.vehicleService.getModelData();
  }

  // tslint:disable-next-line:cyclomatic-complexity
  ngOnDestroy() {
    clearInterval(this.timer);
    if (this.logonListenerId) {
      CallCenter.removeEventListener('logon', this.logonListenerId);
      this.logonListenerId = '';
    }
    if (this.agentidleListenerId) {
      CallCenter.removeEventListener('agentidle', this.agentidleListenerId);
      this.agentidleListenerId = '';
    }
    if (this.agentbusyListenerId) {
      CallCenter.removeEventListener('agentbusy', this.agentbusyListenerId);
      this.agentbusyListenerId = '';
    }
    if (this.inringingListenerId) {
      CallCenter.removeEventListener('inringing', this.inringingListenerId);
      this.inringingListenerId = '';
    }
    if (this.transferinringingListenerId) {
      CallCenter.removeEventListener('transferinringing', this.transferinringingListenerId);
      this.transferinringingListenerId = '';
    }
    if (this.consultinringingListenerId) {
      CallCenter.removeEventListener('consultinringing', this.consultinringingListenerId);
      this.consultinringingListenerId = '';
    }
    if (this.logoutListenerId) {
      CallCenter.removeEventListener('logout', this.logoutListenerId);
      this.logoutListenerId = '';
    }
    if (this.cancelmakecallListenerId) {
      CallCenter.removeEventListener('cancelmakecall', this.cancelmakecallListenerId);
      this.cancelmakecallListenerId = '';
    }
    if (this.monitoragentListenerId) {
      CallCenter.removeEventListener('monitoragent', this.monitoragentListenerId);
      this.monitoragentListenerId = '';
    }
    if (this.outringingListenerId) {
      CallCenter.removeEventListener('outringing', this.outringingListenerId);
      this.outringingListenerId = '';
    }
  }

  initCTIEventListener() {
    this.logonListenerId = CallCenter.addEventListener('logon', (json) => {
      if (json.status === 0) {
        this.ctiCallService.changeStatus('busy');
        CallCenter.monitoragent();
        CallCenter.free();
      }
      if (json.status === 2) {
        this.ctiCallService.changeStatus('offline');
        return this.promptBarService.show('该坐席已在另一页面登录，当前页面已登出', PromptLevel.Warning);
      }
    });
    // 空闲
    this.agentidleListenerId = CallCenter.addEventListener('agentidle', (json) => {
      this.ctiCallService.changeStatus('online');
      this.ctiCallService.setShouldShowInCallBar(false);
      this.ctiCallService.setShouldShowOutCallBar(true);
      this.ctiCallService.setTelephonistStatus('');
      this.ctiCallService.setEventStatus('');
      this.ctiCallService.setCustomerStatus('');
    });

    this.agentbusyListenerId = CallCenter.addEventListener('agentbusy', (json) => {
      this.ctiCallService.changeStatus('busy');
      this.ctiCallService.setShouldShowOutCallBar(false);
      this.showIntelligentAssistant = false;
      this.showAssistantIcon = true;
      this.ctiCallService.setCtiIntelligentAssistantCurrentStatus('');
    });

    CallCenter.opLogin_callback = (err) => {
      this.ctiCallService.changeStatus('offline');
      return this.promptBarService.show('坐席暂不可用，请稍后重试', PromptLevel.Warning);
    };
    // 客户呼入振铃
    this.inringingListenerId = CallCenter.addEventListener('inringing', (json) => {
      this.ctiCallService.setCustomerStatus(CUSTOMER_STATUS_OPTIONS.CUSTOMER_CALL_IN.value);
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.RINGING.value);
      this.inboundCallData = _.cloneDeep(json);
      _.assign(
        this.inboundCallData,
        { origcaller: json.caller },
        { callWay: 'CUSTOMER' },
        { inCallMessage: '正在呼叫您' },
        { called: '' },
        { caller: '' },
        { companyId: _.get(json, 'userdata.companyId') },
      );
      this.ctiCallService.showInCallNotification(`${this.inboundCallData.origcaller}正在呼叫您`);
      this.ctiCallService.setShouldShowOutCallBar(false);
      this.ctiCallService.setShouldShowInCallBar(true);
      this.ctiCallService.setCallInPhoneNum(phoneNumFormat(json.caller));
    });
    // 转接呼入振铃
    this.transferinringingListenerId = CallCenter.addEventListener('transferinringing', (json) => {
      this.ctiCallService.setTelephonistStatus(TELEPHONIST_STATUS_OPTIONS.TRANSFER_CALL_IN.value);
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.RINGING.value);
      this.inboundCallData = _.cloneDeep(json.followData);
      _.assign(
        this.inboundCallData,
        { origcaller: this.inboundCallData.origcaller },
        { callWay: 'CUSTOMER' },
        { inCallMessage: `来自${this.inboundCallData.caller}${json.followData.name ? ' ' : ''}${json.followData.name}的转接` },
        { called: '' },
        { caller: '' },
        { companyId: _.get(json, 'userdata.companyId') },
      );
      this.ctiCallService.showInCallNotification(this.inboundCallData.inCallMessage);
      this.ctiCallService.setShouldShowOutCallBar(false);
      this.ctiCallService.setShouldShowInCallBar(true);
    });
    // 咨询呼入振铃
    this.consultinringingListenerId = CallCenter.addEventListener('consultinringing', (json) => {
      this.ctiCallService.setTelephonistStatus(TELEPHONIST_STATUS_OPTIONS.CONSULT_CALL_IN.value);
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.RINGING.value);
      this.inboundCallData = _.cloneDeep(json.followData);
      _.assign(
        this.inboundCallData,
        { origcaller: this.inboundCallData.origcaller },
        { callWay: 'CONSULT' },
        { inCallMessage: `来自${this.inboundCallData.caller}${json.followData.name ? ' ' : ''}${json.followData.name}的咨询` },
        { caller: this.inboundCallData.caller },
        { called: '' },
        { companyId: _.get(json, 'userdata.companyId') },
      );
      this.ctiCallService.showInCallNotification(
        `来自${json.followData.caller}${json.followData.name ? ' ' : ''}${json.followData.name}的咨询`,
      );
      this.ctiCallService.setShouldShowOutCallBar(false);
      this.ctiCallService.setShouldShowInCallBar(true);
    });
    this.logoutListenerId = CallCenter.addEventListener('logout', (json) => {
      this.ctiCallService.clearCtiInfo();
      this.ctiCallService.changeStatus('offline');
      this.showIntelligentAssistant = false;
      this.showAssistantIcon = true;
      this.ctiCallService.setCtiIntelligentAssistantCurrentStatus('');
    });

    this.cancelmakecallListenerId = CallCenter.addEventListener('cancelmakecall', (json) => {
      CallCenter.free();
    });

    this.monitoragentListenerId = CallCenter.addEventListener('monitoragent', (json) => {
      let telephonists = this.ctiCallService.getAllTelephonist();
      const res = _.get(json, 'data');
      if (!_.isEmpty(telephonists)) {
        telephonists = _.chain(telephonists)
          .map((m) => {
            let v = m;
            _.map(res, (n) => {
              if (m.operatorid === n.operatorid) {
                v = n;
              }
            });
            return v;
          })
          .value();
      } else {
        const operator = _.find(res, (item) => item.operatorid === _.get(this.ctiCallService.getOperatorInfo(), 'operatorId'));
        this.ctiCallService.setOperatorInfo({
          name: _.get(operator, 'name'),
        });
        telephonists = res;
      }
      this.ctiCallService.setAllTelephonist(telephonists);
    });

    // 呼出客户振铃
    this.outringingListenerId = CallCenter.addEventListener('outringing', (json) => {
      this.ctiCallService.setCustomerStatus(CUSTOMER_STATUS_OPTIONS.CUSTOMER_CALL_OUT.value);
      this.ctiCallService.setEventStatus(EVENT_STATUS_OPTIONS.RINGING.value);
    });
  }

  initCTIStatus() {
    this.userManagementService.getUserCtiInfo().subscribe((res) => {
      if (_.get(res, 'operatorId')) {
        this.ctiCallService.setOperatorInfo(res);
      }
    });
    this.ctiCallService.getAddAfterSalesAndLeadSubject().subscribe((res) => {
      this.phoneNumber = res.mobile;
      if (!res.type && !this.shouldShowAddAfterSalesConfirmModal) {
        this.shouldShowJumpAfterSalesConfirmModal = true;
      }
    });
  }

  hangUpInboundCall(param) {
    // tslint:disable-next-line:no-console
    console.log('handle hangUpInboundCall', param);
    this.ctiCallService.setShouldShowInCallBar(false);
    if (_.isEmpty(param.allCustomerList) || param.isConsult) {
      return;
    }
    this.showCallEndModal = true;
    this.callEndData = param;
  }

  hangUpOutboundCall(param) {
    // tslint:disable-next-line:no-console
    console.log('handle hangUpOutboundCall', param);
    if (!_.isEmpty(param.allCustomerList)) {
      this.showCallEndModal = true;
      this.callEndData = param;
    }
  }

  handleCallEndModalCanceled(): void {
    this.showCallEndModal = false;
  }
  handleCallEndModalChange(e) {
    if (!e.customer_id) {
      return;
    }
    this.currentCustomerLeads = e.currentCustomerLeads;
    this.customerId = e.customer_id;
    this.initFollowupModle(e.dealer_id);
  }

  get shouldShowInboundCallDragBar() {
    return this.ctiCallService.getShouldShowInCallBar();
  }

  get shouldShowOutboundCallDragBar() {
    return this.ctiCallService.getShouldShowOutCallBar();
  }

  get shouldShowAddCustomerConfirmModal() {
    return this.ctiCallService.getShouldShowAddCustomerConfirm();
  }

  get shouldShowAddAfterSalesConfirmModal() {
    return this.ctiCallService.getShouldShowAddAfterSalesConfirm();
  }

  closeAddCustomerConfirmModal() {
    this.ctiCallService.setShouldShowAddCustomerConfirm(false);
  }

  closeAddAfterSalesConfirmModal() {
    this.ctiCallService.setShouldShowAddAfterSalesConfirm(false);
  }

  closeJumpAfterSalesConfirmModal() {
    this.shouldShowJumpAfterSalesConfirmModal = false;
  }

  confirmJumpAddAfterSalesConfirmModal() {
    this.ctiCallService.addAfterSales({mobile: this.phoneNumber, type: true});
    this.closeJumpAfterSalesConfirmModal();
  }

  addFollowUpWithLeadId({ leads, customerId }) {
    const modalHandler = {
      handleResponseSuccess: () => !_.isEmpty(this.followUpRecords) ? _.noop : this.handleResponseSuccess(),
      handleResponseError: (e) => !_.isEmpty(this.followUpRecords) ? _.noop : this.handleResponseError(e),
    };
    const modalProperties = {
      modalTitle: '添加跟进',
      confirm: ({ type, record }): Observable<any> => {
        _.assign(record, { followUpTime : parseInt(_.get(record, 'followUpTime', 0)) + 59999 });
        const earlyLead = _.find(leads, (leadItem) => _.get(leadItem, 'created_time') > _.get(record, 'followUpTime'));
        if (earlyLead) {
          this.modalMessageService.putMessage({ message: '请选择线索创建之后的时间' });
          return;
        }
        if (!_.isEmpty(this.followUpRecords)) {
          this.shouldShowSyncFollowupRecordsMoal = true;
          this.followUpFormValue = { type, record };
          return observableOf({});
        }
        const checkLeads = this.currentCustomerLeads.filter(currentCustomerLead => currentCustomerLead.checked);
        const lead = _.head(checkLeads);
        const onConfirm = this.leadService.getAddFollowUpConfirmMethod(type) as Function;
        return onConfirm({
          ...record,
          leadId: _.get(lead, '_id'),
          leadIds: [_.get(lead, '_id')],
        });
      },
    };

    const contentProperties = {
      leads,
      customerId,
      scGroupInfos: this.scGroupInfos,
    };
    this.initModalWith(CTIAddFollowUpModalComponent, contentProperties, modalProperties, modalHandler);
  }
  handleResponseError(error) {
    this.promptBarService.show(parseErrorMessage(error) || '更新失败', PromptLevel.Error);
  }
  handleResponseSuccess(message: string = '更新成功') {
    this.promptBarService.show(message, PromptLevel.Success);
  }
  private initModalWith(
    modalContentType,
    contentProperties,
    modalProperties,
    modalHandler = {
      handleResponseSuccess: () => this.handleResponseSuccess('更新成功'),
      handleResponseError: this.handleResponseError,
    },
  ) {
    this.leadService.initModalWith(
      { type: modalContentType, properties: contentProperties },
      modalProperties,
      this.actionsModal,
      // this.showDuplicateLeadModal,
      // this.saveCurrentModal,
      null,
      null,
      modalHandler,
    );
  }
  handleSubmit(persons) {
    const leads = this.currentCustomerLeads.filter(currentCustomerLead => currentCustomerLead.checked);
    const lead = _.head(leads);
    return this.leadService
      .getAddFollowUpConfirmMethod(this.followUpFormValue.type)({
        ...this.followUpFormValue.record,
        leadId: _.get(lead, '_id'),
        leadIds: [_.get(lead, '_id')],
        syncLeadIds: persons,
      });
  }

  submitPersons(persons) {
    this.handleSubmit(persons)
      .subscribe(() => {
        setTimeout(() => {
          this.onCloseRecordsModal();
        }, 0);
        this.promptBarService.show('同步线索成功！', PromptLevel.Info);
      }, err => {
        setTimeout(() => {
          this.onCloseRecordsModal();
        }, 0);
        this.promptBarService.show(parseErrorMessage(err) || '同步线索失败！', PromptLevel.Error);
      });
  }

  onCloseRecordsModal() {
    this.shouldShowSyncFollowupRecordsMoal = false;
  }

  getFollowUpRecords(followUpRecords) {
    const followUpRecordsEnhance = [];
    followUpRecords.forEach((followUpRecord) => {
      const getOpenLeads = followUpRecord.leads.filter(lead => isOpenLead(lead));
      followUpRecordsEnhance.push(...getOpenLeads.map(info => ({...followUpRecord, leads: [info]})));

    });
    return [...followUpRecordsEnhance];
  }

  initFollowupModle(dealerId) {
    this.leadService
      .searchCustomer(
        'PHONE_NUMBER',
        phoneNumFormat(this.ctiCallService.getCallInPhoneNum() || this.ctiCallService.getCallOutPhoneNum() || this.callEndData.phoneNum),
        this.currentUserService.getCurrentUser().cecId,
      )
      .pipe(this.spinnerService.loading())
      .subscribe((response) => {
        const canSyncFollowupLeadStatus = [LEAD_STATUS.LEAD_CANDIDATE.value, LEAD_STATUS.LEAD.value, LEAD_STATUS.LOST_SALES.value];
        const persons = _.reject(response, (item) => item.id === this.customerId);
        const filterPersons = _.map(persons, (person) => {
          const leads = _.filter(person.leads, (leadItem) => _.includes(canSyncFollowupLeadStatus, _.get(leadItem, 'status')));
          return { ...person, leads };
        });
        const followUpRecords = _.reject(filterPersons, (person) => _.isEmpty(person.leads));
        this.followUpRecords = this.getFollowUpRecords(followUpRecords);
        const groups = _.chain(this.currentCustomerLeads)
          .find((leadItem) => leadItem.checked).value();
        forkJoin(
          groups ? this.groupService.getSalesTeamAndSCByDealerId(dealerId) : observableOf([]),
        ).pipe(this.spinnerService.loading()).subscribe(([scGroupInfos]) => {
          this.scGroupInfos =  getSCGroupsWithNoEmptySC(scGroupInfos);
          const leads = _.filter(this.currentCustomerLeads, (lead) => lead.checked);
          this.addFollowUpWithLeadId(
            {
              leads,
              customerId: this.customerId,
            }
          );
        });
      });
  }

  getAssistantStatus(param) {
    this.showIntelligentAssistant = true;
    this.showAssistantIcon = param.showAssistantIcon;
    this.currentStatus = param.expandAssistant;
    if (_.isEqual(param.expandAssistant, 'click')) {
      this.currentStatus = `click${Math.random()}`;
    }
    const statusList = ['callout', 'afterconsult', 'callin'];
    if (_.includes(statusList, this.currentStatus)) {
      this.showIntelligentAssistant = false;
    }
  }

  foldAssistantModal() {
    this.showAssistantIcon = true;
  }

  onMousedown() {
    this.intelligentAssistant.changezIndex(false);
  }
}

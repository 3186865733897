import { Component } from '@angular/core';
import { ICellEditorParams } from 'ag-grid';
import { fixNumber } from '../../../../utils/format-string';
import { BasicInputEditorComponent } from '../basic-input/basic-input-editor.component';
import { isNumber, isKeyBackspace, isKeyNumeric, isKeyDecimalPoint } from '../../../../utils/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-numeric-cell',
  templateUrl: 'numeric-editor.template.html',
  styleUrls: ['../editor.style.scss'],
})
export class NumericEditorComponent extends BasicInputEditorComponent {
  public display: boolean;

  agInit(params: ICellEditorParams): void {
    super.agInit(params);
    this.display = this.getDisplay();
    if (this.isPercentage) {
      this.value = isNumber(this.value, true) ? fixNumber(this.value * 100) : '';
    }
  }

  get isPercentage() {
    return this.cellEditorParams.isPercentage;
  }

  get precision() {
    return this.cellEditorParams.precision || 0;
  }

  get allowDecimal() {
    return this.precision > 0;
  }

  getValue(): number {
    if (this.isPercentage && isNumber(this.value, true)) {
      return Number(this.value) / 100;
    }
    return this.value;
  }

  onChange(event) {
    this.value = fixNumber(event.target.value, this.isPercentage ? (this.precision - 2) : this.precision);
    this.updateSourceValue(this.getValue());
    this.onChangeHandler(this.getValue(), this.params.rowIndex, this.params.column.getColId());
  }

  onOtherKeyDown(event): void {
    if (!this.isKeyPressedNumericOrBackspace(event)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  onUpdateEvent() {
    this.value = '';
    this.updateSourceValue('');
    this.display = this.getDisplay();
  }

  private isKeyPressedNumericOrBackspace(event): boolean {
    const key = this.getKeyFromEvent(event);
    const allowDecimalPoint = this.allowDecimal && isKeyDecimalPoint(key) && !_.includes(this.value, '.');
    return isKeyBackspace(key) || isKeyNumeric(key) || allowDecimalPoint;
  }

  private getDisplay() {
    const display = _.get(this.cellEditorParams, 'display', true);
    return _.isFunction(display) ? display(this.params) : display;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { MESSAGE_TYPE } from '../constants/message';
import * as _ from 'lodash';

@Pipe({ name: 'messageType' })
export class MessageTypePipe implements PipeTransform {
  transform(messageType: string): string {
    return _.get(_.find(_.values(MESSAGE_TYPE), { value: messageType }), 'text', '');
  }
}

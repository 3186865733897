<div class="select-date-range" [ngClass]="{'input-error': errorMessage}" [formGroup]="form">
  <nz-date-picker
    [formControl]="form.controls.startTime"
    nzPlaceHolder='YYYY-MM-DD'
    [nzShowToday]="false"
    [nzDisabledDate]="startTimeDisabledDates"
    (ngModelChange)="onLeftInputChange($event)">
  </nz-date-picker>
  <span class="separator">—</span>
  <nz-date-picker
    [formControl]="form.controls.endTime"
    nzPlaceHolder='YYYY-MM-DD'
    [nzShowToday]="false"
    [nzDisabledDate]="endTimeDisabledDates"
    (ngModelChange)="onRightInputChange($event)">
  </nz-date-picker>
  <div *ngIf="errorMessage" class="error-message" [ngStyle]="errorMessageStyle">
    {{errorMessage}}
  </div>
</div>

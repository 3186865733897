import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from './fileds/text-input/text-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownFieldComponent } from './fileds/app-dropdown-field/dropdown-field.component';
import { CommonComponentModule } from '../../common-component.module';
import { AppCalendarFieldComponent } from './fileds/app-calendar-field/app-calendar-field.component';
import { CalendarModule } from 'primeng/primeng';
import { GroupButtonComponent } from './fileds/group-button/single-select/group-button.component';
import { MultipleSelectGroupButtonComponent } from './fileds/group-button/multiple-select/multiple-select-group-button.component';
import { MultiDropdownFieldComponent } from './fileds/app-multi-dropdown-field/multi-dropdown-field.component';
import { AppDateFieldComponent } from './fileds/app-date-field/app-date-field.component';
import { TextareaComponent } from './fileds/app-textarea-field/textarea.component';
import { InputRangeFieldComponent } from './fileds/app-input-range-field/input-range-field.component';
import { TextFieldComponent } from './fileds/app-text-field/text-field.component';
import { AppVehicleFilterFieldComponent } from './fileds/app-vehicle-filter-field/app-vehicle-filter-field.component';
import { AppDropdownInputFieldComponent } from './fileds/app-dropdown-input-field/app-dropdown-input-field.component';
import { MultiSelectComponent } from './fileds/multi-select/multi-select.component';
import { ReportDateRangeComponent } from './fileds/report-date-range/report-date-range.component';
import { NzDatePickerModule, NzIconModule } from 'ng-zorro-antd';
import { TooltipModule } from 'ngx-bootstrap';
import { TextareaWithRemainValuesComponent } from './fileds/app-textarea-with-remain-values/textarea-with-remain-values.component';
import { DatePeriodRangeComponent } from './fileds/date-period-range/date-period-range.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CalendarModule,
    CommonComponentModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    TooltipModule,
    NzIconModule
  ],
  declarations: [
    TextInputComponent,
    DropdownFieldComponent,
    AppCalendarFieldComponent,
    GroupButtonComponent,
    MultipleSelectGroupButtonComponent,
    MultiDropdownFieldComponent,
    AppDateFieldComponent,
    TextareaComponent,
    InputRangeFieldComponent,
    TextFieldComponent,
    AppVehicleFilterFieldComponent,
    AppDropdownInputFieldComponent,
    MultiSelectComponent,
    ReportDateRangeComponent,
    TextareaWithRemainValuesComponent,
    DatePeriodRangeComponent
  ],
  exports: [
    DropdownFieldComponent,
    TextInputComponent,
    AppCalendarFieldComponent,
    FormsModule,
    GroupButtonComponent,
    MultipleSelectGroupButtonComponent,
    MultiDropdownFieldComponent,
    AppDateFieldComponent,
    TextareaComponent,
    InputRangeFieldComponent,
    TextFieldComponent,
    AppVehicleFilterFieldComponent,
    AppDropdownInputFieldComponent,
    MultiSelectComponent,
    ReportDateRangeComponent,
    TextareaWithRemainValuesComponent,
    DatePeriodRangeComponent
  ],
})
export class AppFormModule {
}

<div *ngIf="isEdit" (keydown.enter)="onKeyDownEnter($event)">
  <input #inputElement class="form-control input-control input-value" [formControl]="quantityForm" 
  (keyup.enter)="onEnter()" (keydown.tab)="onEnter(); false" (blur)="onBlur()" (click)="onClick()">
</div>
<div *ngIf="!isEdit">
  <span *ngIf="hasVariance" class="quantity red-text">
    {{ inputQuantity }}
  </span>
  <span *ngIf="!hasVariance" class="quantity">
    {{ inputQuantity }}
  </span>
  <span *ngIf="'ADJUSTED' !== status" class="clickable">
    <img src="./assets/parts/icon-edit.svg" alt="edit" (click)="onEdit()" />
  </span>
</div>
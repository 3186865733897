import { Injectable } from '@angular/core';
import { Body, DELETE, GET, Headers, PATCH, Path, POST, PUT, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AccessoryDiscountApplication,
  AccessoryDiscountApplicationDetailCreateRequest,
  AccessoryDiscountApplicationDetailCycleConfigRequest,
  AccessoryDiscountApplicationDetails,
  AccessoryDiscountApplicationDetailUpdateRequest,
  AccessoryDiscountApplicationExportRecordRequest,
  AccessoryDiscountCycleConfigResponse,
  AccessoryDiscountDetail,
  AccessoryDiscountPage,
  AccessoryDiscountResult,
  AccessoryDiscountRuleConfigResponse,
  AccessoryDiscountRuleConfigsAvailableResponse,
  AccessoryDiscountRuleDefaultConfigRequest,
  AccessoryDiscountRuleDefaultConfigResponse,
  AccessoryDiscountTotalStatisticsResponse,
  AccessoryDiscountUsers,
  AppealBlobUrl,
  ApplicationDetailAppealDetail,
  ApplicationDetailsSearchParams,
  ApplicationDetailStatisticsCountReportResponse,
  ApplicationDetailVerificationResult,
  BusinessManagementContact,
  BusinessManagementSummary,
  ConfirmedNotification,
  CreateOrUpdateTipInformationConfigRequest,
  CreateOrUpdateTipInformationConfigResponse,
  GetImportRecordsRequest,
  GetImportRecordsResponse,
  HandleAppealRequest,
  ImportRecordsOperator,
  InsuranceNumberValidateRequest,
  InsuranceNumberValidateResponse,
  Operator,
  PolicyCycle,
  RegionGroupGsCodeMappingResponse,
  TipsInformationResponse,
  VerificationResultsRequest,
  ZipBlobResponse,
} from '../accessory-discount';
import { PageResponse } from '../../_common/models/page';
import { ExcelTemplateType } from '../constant';

export const ACCIDENT_BASIC_URL = '/api/insurance-accident';
export const INSURANCE_SALES_BASIC_URL = '/api/insurance-sales';
export const THIRD_PARTY = '/api/3rd-party/insurance-accident';
const ACCESSORY_DISCOUNT_APPLICATION_DETAIL_URL = `${ACCIDENT_BASIC_URL}/accessory-discount-application-detail-verification-results`;
const THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_DETAIL_URL = `${THIRD_PARTY}/accessory-discount-application-detail-verification-results`;

@Injectable({
  providedIn: 'root',
})
export class InsuranceAccidentApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details`)
  getAccessoryDiscountRecords(
    @Query('page') page,
    @Query('size') size,
    @Query('statisticalMonth') statisticalMonth,
  ): Observable<AccessoryDiscountPage<AccessoryDiscountDetail>> {
    return null;
  }

  @GET(`${THIRD_PARTY}/accessory-discount-application-details`)
  getAccessoryDiscountRecordsFromThirdParty(
    @Query('page') page,
    @Query('size') size,
    @Query('statisticalMonth') statisticalMonth,
    @Query('dealerId') dealerId,
  ): Observable<AccessoryDiscountPage<AccessoryDiscountDetail>> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-applications/:id`)
  getAccessoryDiscountApplication(@Path('id') id: number): Observable<AccessoryDiscountApplication> {
    return null;
  }

  @GET(`${THIRD_PARTY}/accessory-discount-applications/:id`)
  getAccessoryDiscountApplicationFromThirdParty(@Path('id') id: number): Observable<AccessoryDiscountApplication> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-applications`)
  getAccessoryDiscountApplications(
    @Query('page') page: number,
    @Query('size') size: number,
    @Query('params') params: any,
  ): Observable<AccessoryDiscountPage<AccessoryDiscountApplication>> {
    return null;
  }

  @GET(`${THIRD_PARTY}/tip-information-configs/pageable`)
  getAccessoryDiscountTipInfoConfigs(
    @Query('page') page: number,
    @Query('size') size: number,
    @Query('params') params: any,
  ): Observable<AccessoryDiscountPage<any>> {
    return null;
  }

  @GET(`${THIRD_PARTY}/tip-information-configs/operators`)
  getAccessoryDiscountTipInfoConfigsOperators(): Observable<any> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/conditions`)
  getAccessoryDiscountApplicationsDetails(
    @Query('page') page: number,
    @Query('size') size: number,
    @Query('params') params: ApplicationDetailsSearchParams,
  ): Observable<AccessoryDiscountPage<AccessoryDiscountApplicationDetails>> {
    return null;
  }

  @GET(`${ACCESSORY_DISCOUNT_APPLICATION_DETAIL_URL}`)
  getAccessoryDiscountApplicationDetailAppealDetail(
    @Query('uuid') uuid: string,
  ): Observable<ApplicationDetailAppealDetail> {
    return null;
  }

  @GET(`${ACCESSORY_DISCOUNT_APPLICATION_DETAIL_URL}/appeal-record-blob-url`)
  getAppealFileBlobDirectUrlByAppealRecordId(@Query('appealId') appealId: number): Observable<AppealBlobUrl> {
    return null;
  }

  @POST(`${ACCESSORY_DISCOUNT_APPLICATION_DETAIL_URL}/pageable`)
  getAccessoryDiscountApplicationDetailVerificationResults(
    @Query('page') page: number,
    @Query('size') size: number,
    @Body params: any,
  ): Observable<AccessoryDiscountPage<ApplicationDetailVerificationResult>> {
    return null;
  }

  @POST(`${THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_DETAIL_URL}/pageable`)
  getThirdPartyAccessoryDiscountApplicationDetailVerificationResults(
    @Query('dealerId') dealerId: number,
    @Query('page') page: number,
    @Query('size') size: number,
    @Body params: any,
  ): Observable<AccessoryDiscountPage<ApplicationDetailVerificationResult>> {
    return null;
  }

  @GET(`${ACCESSORY_DISCOUNT_APPLICATION_DETAIL_URL}/statistics-count`)
  getAccessoryDiscountApplicationDetailVerificationResultsStatistics(
    @Query('policyStartDate') policyStartDate: string,
    @Query('policyEndDate') policyEndDate: string,
  ): Observable<any> {
    return null;
  }

  @GET(`${THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_DETAIL_URL}/statistics-count`)
  getThirdPartyAccessoryDiscountApplicationDetailVerificationResultsStatistics(
    @Query('policyStartDate') policyStartDate: string,
    @Query('policyEndDate') policyEndDate: string,
    @Query('dealerId') dealerId: string
  ): Observable<any> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-applications`)
  createAccessoryDiscountApplication(@Body createRequest: any): Observable<void> {
    return null;
  }

  @PATCH(`${ACCIDENT_BASIC_URL}/accessory-discount-applications`)
  updateAccessoryDiscountApplication(@Body createRequest: any, @Query('status') status): Observable<void> {
    return null;
  }

  @DELETE(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/:detailId`)
  deleteAccessoryDiscountDetail(@Path('detailId') detailId, @Query('status') status): Observable<void> {
    return null;
  }

  @DELETE(`${THIRD_PARTY}/accessory-discount-application-details/:detailId`)
  deleteAccessoryDiscountDetailFromThirdParty(@Path('detailId') detailId, @Query('status') status): Observable<void> {
    return null;
  }

  @DELETE(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/all`)
  deleteAccessoryDiscountDetails(
    @Query('accessoryDiscountApplicationId') accessoryDiscountApplicationId,
    @Query('status') status,
  ): Observable<void> {
    return null;
  }

  @DELETE(`${THIRD_PARTY}/accessory-discount-application-details/all`)
  deleteAccessoryDiscountDetailsFromThirdParty(
    @Query('accessoryDiscountApplicationId') accessoryDiscountApplicationId,
    @Query('status') status,
  ): Observable<void> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/download`)
  @Headers({isExportFile: true})
  downloadExceptionFile(@Query('fileName') fileName, @Query('type') type) {
    return null;
  }

  @GET(``)
  @Headers({isExportFile: true})
  downloadApplyInfoByVin(): Observable<any> {
    return null;
  }

  @GET(`${THIRD_PARTY}/accessory-discount-application-details/download`)
  @Headers({isExportFile: true})
  downloadExceptionFileFromThirdParty(@Query('fileName') fileName, @Query('type') type) {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/:statisticalMonth/excel-import`)
  uploadFile(
    // tslint:disable-next-line:max-line-length
    @Body uploadFile: any,
    @Query('type') type: string,
    @Path('statisticalMonth') statisticalMonth: string,
    @Query('id') id: number,
  ): Observable<void> {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-application-details/:statisticalMonth/excel-import`)
  uploadFileFromThirdParty(
    // tslint:disable-next-line:max-line-length
    @Body uploadFile: any,
    @Query('type') type: string,
    @Query('dealerId') dealerId: string,
    @Path('statisticalMonth') statisticalMonth: string,
    @Query('id') id: number,
  ): Observable<void> {
    return null;
  }

  @DELETE(`${ACCIDENT_BASIC_URL}/accessory-discount-applications`)
  deleteAccessoryDiscountApplication(@Body updateRequest: any) {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/business-ops/contact`)
  getBusinessManagementContact(@Query('applicationId') page: number): Observable<BusinessManagementContact> {
    return null;
  }

  @GET(`${THIRD_PARTY}/business-ops/contact`)
  getBusinessManagementContactFromThirdParty(
    @Query('applicationId') page: number,
    @Query('dealerId') dealerId: string,
  ): Observable<BusinessManagementContact> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/business-ops/accessory-discount-application-details/summary`)
  getBusinessManagementApplyInfoSummary(@Query('statisticalMonth') statisticalMonth: string): Observable<any> {
    return null;
  }

  @GET(`${THIRD_PARTY}/business-ops/accessory-discount-application-details/summary`)
  getBusinessManagementApplyInfoSummaryFromThirdParty(
    @Query('statisticalMonth') statisticalMonth: string,
    @Query('dealerId') dealerId: string,
  ): Observable<any> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-applications/confirmed-notification`)
  getConfirmedNotification(): Observable<ConfirmedNotification> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-applications/confirmation`)
  setConfirmedNotification(): Observable<void> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/business-ops/summary`)
  getBusinessManagementSummary(@Query('applicationId') page: number): Observable<BusinessManagementSummary> {
    return null;
  }

  @GET(`${THIRD_PARTY}/business-ops/summary`)
  getBusinessManagementSummaryFromThirdParty(
    @Query('applicationId') page: number,
    @Query('dealerId') dealerId: string,
  ): Observable<BusinessManagementSummary> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details`)
  createAccessoryDiscountApplicationDetail(@Body params: AccessoryDiscountApplicationDetailCreateRequest): Observable<void> {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-application-details`)
  createAccessoryDiscountApplicationDetailFromThirdParty(
    @Query('dealerId') dealerId: string,
    @Body params: AccessoryDiscountApplicationDetailCreateRequest,
  ): Observable<void> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-applications/:id/details/export`)
  exportApplicationDetails(@Path('id') id): Observable<any> {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-applications/:id/details/export`)
  exportApplicationDetailsFromThirdParty(@Path('id') id, @Query('dealerId') dealerId: string): Observable<any> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/business-ops/latest-contact`)
  getBusinessManagementContactLatest(@Query('month') page: number): Observable<BusinessManagementContact> {
    return null;
  }

  @GET(`${THIRD_PARTY}/business-ops/latest-contact`)
  getBusinessManagementContactLatestFromThirdParty(
    @Query('month') page: number,
    @Query('dealerId') dealerId: string,
  ): Observable<BusinessManagementContact> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/business-ops`)
  updateBusinessManagementContact(@Body payload: any): Observable<void> {
    return null;
  }

  @POST(`${THIRD_PARTY}/business-ops`)
  updateBusinessManagementContactFromThirdParty(@Query('dealerId') dealerId: string, @Body payload: any): Observable<void> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/insurance-number-validate`)
  validateInsuranceNumber(@Body params: InsuranceNumberValidateRequest): Observable<InsuranceNumberValidateResponse> {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-application-details/insurance-number-validate`)
  validateInsuranceNumberFromThirdParty(
    @Query('dealerId') dealerId: string,
    @Body params: InsuranceNumberValidateRequest,
  ): Observable<InsuranceNumberValidateResponse> {
    return null;
  }

  @DELETE(`${ACCIDENT_BASIC_URL}/business-ops`)
  deleteBusinessManagement(
    @Query('accessoryDiscountApplicationId') accessoryDiscountApplicationId,
    @Query('status') status,
  ): Observable<void> {
    return null;
  }

  @DELETE(`${THIRD_PARTY}/business-ops`)
  deleteBusinessManagementFromThirdParty(
    @Query('accessoryDiscountApplicationId') accessoryDiscountApplicationId,
    @Query('status') status,
  ): Observable<void> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/export`)
  exportApplicationDetailsByParams(@Body searchParams) {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/verifications/export`)
  exportApplicationDetailVerificationsByParams(@Body searchParams) {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-application-details/export`)
  exportApplicationDetailsByParamsFromThirdParty(@Body searchParams) {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-application-details/verifications/export`)
  exportThirdPartyApplicationDetailVerificationsByParams(@Body searchParams) {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/:detailId`)
  queryAccessoryDiscountApplicationDetailById(@Path('detailId') detailId: number): Observable<AccessoryDiscountApplicationDetails> {
    return null;
  }

  @GET(`${THIRD_PARTY}/accessory-discount-application-details/:detailId`)
  queryAccessoryDiscountApplicationDetailByIdFromThirdParty(
    @Path('detailId') detailId: number,
  ): Observable<AccessoryDiscountApplicationDetails> {
    return null;
  }

  @PUT(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/:detailId`)
  updateAccessoryDiscountApplicationDetailById(
    @Path('detailId') detailId: number,
    @Body params: AccessoryDiscountApplicationDetailUpdateRequest,
  ): Observable<void> {
    return null;
  }

  @PUT(`${THIRD_PARTY}/accessory-discount-application-details/:detailId`)
  updateAccessoryDiscountApplicationDetailByIdFromThirdParty(
    @Path('detailId') detailId: number,
    @Query('dealerId') dealerId: string,
    @Body params: AccessoryDiscountApplicationDetailUpdateRequest,
  ): Observable<void> {
    return null;
  }

  @POST(`${INSURANCE_SALES_BASIC_URL}/export-records`)
  getExportRecords(
    @Query('page') page,
    @Query('size') size,
    @Body queryParams: AccessoryDiscountApplicationExportRecordRequest,
  ): Observable<any> {
    return null;
  }

  @GET('/api/insurance-integration/users')
  getOperationUsers(@Query('roles') roles: string[], @Query('dealerId') dealerId?: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/insurance/outlets')
  getDealerName(): Observable<any> {
    return null;
  }

  @GET(`${THIRD_PARTY}/accessory-discount-rule-configs/operators`)
  getThirdOperationUsers(): Observable<AccessoryDiscountUsers[]> {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-rule-configs/pageable`)
  getAccessoryDiscountRuleConfigs(
    @Query('page') page,
    @Query('size') size,
    @Body filter,
  ): Observable<AccessoryDiscountPage<AccessoryDiscountRuleConfigResponse>> {
    return null;
  }

  @DELETE(`${THIRD_PARTY}/accessory-discount-rule-configs`)
  deleteAccessoryDiscountRuleConfigs(@Query('ids') ids): Observable<void> {
    return null;
  }

  @DELETE(`${THIRD_PARTY}/tip-information-configs`)
  deleteTipsInformationConfigs(@Body ids): Observable<void> {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-applications/search`)
  getThirdPartyAccessoryDiscountApplications(@Query('page') page: number, @Query('size') size: number, @Body params: any) {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-application-details/conditions`)
  getThirdPartyAccessoryDiscountApplicationsDetails(
    @Query('page') page: number,
    @Query('size') size: number,
    @Body params: ApplicationDetailsSearchParams,
  ): Observable<AccessoryDiscountPage<AccessoryDiscountApplicationDetails>> {
    return null;
  }

  @GET(`${THIRD_PARTY}/accessory-discount-rule-configs/default`)
  getAccessoryDiscountRuleDefaultConfig(): Observable<AccessoryDiscountRuleDefaultConfigResponse> {
    return null;
  }

  @PUT(`${THIRD_PARTY}/accessory-discount-rule-configs/default`)
  updateAccessoryDiscountRuleDefaultConfig(@Body params: AccessoryDiscountRuleDefaultConfigRequest): Observable<void> {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-rule-configs`)
  createAccessoryDiscountRuleConfig(@Body params): Observable<void> {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-rule-configs/:id`)
  updateAccessoryDiscountRuleConfig(@Path('id') id, @Body params): Observable<void> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-rule-configs/available`)
  getAccessoryDiscountRuleConfigsAvailable(): Observable<AccessoryDiscountRuleConfigsAvailableResponse> {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-application-details/export`)
  exportThirdPartyApplicationDetailsByParams(@Body searchParams) {
    return null;
  }

  @GET(`${INSURANCE_SALES_BASIC_URL}/export-records/operators`)
  getAllOperationUsers(@Query('type') type: string): Observable<any> {
    return null;
  }

  @POST(`${THIRD_PARTY}/accessory-discount-applications`)
  createAccessoryDiscountApplicationFromThirdParty(@Body createRequest: any) {
    return null;
  }

  @PATCH(`${THIRD_PARTY}/accessory-discount-applications`)
  updateAccessoryDiscountApplicationFromThirdParty(@Body createRequest: any, @Query('status') status): Observable<void> {
    return null;
  }

  @DELETE(`${THIRD_PARTY}/accessory-discount-applications`)
  deleteAccessoryDiscountApplicationFromThirdParty(@Body deleteRequest) {
    return null;
  }

  @POST(`${THIRD_PARTY}/tip-information-configs`)
  createTipInformationConfig(
    @Body params: CreateOrUpdateTipInformationConfigRequest,
  ): Observable<CreateOrUpdateTipInformationConfigResponse> {
    return null;
  }

  @PUT(`${THIRD_PARTY}/tip-information-configs/:id`)
  updateTipInformationConfig(
    @Path('id') id: number,
    @Body params: CreateOrUpdateTipInformationConfigRequest): Observable<CreateOrUpdateTipInformationConfigResponse> {
    return null;
  }

  @GET(`${THIRD_PARTY}/tip-information-configs/available`)
  getTipsInformationFromThirdParty(@Query('position') position): Observable<TipsInformationResponse> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-applications/tip-information-configs/available`)
  getTipsInformation(@Query('position') position): Observable<TipsInformationResponse> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-cycle-config/search`)
  getAccessoryDiscountCycleConfigs(@Query('page') page, @Query('size') size,
                                   @Body params): Observable<AccessoryDiscountPage<AccessoryDiscountCycleConfigResponse>> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-cycle-config/policy-cycles`)
  getPolicyCycles(): Observable<PolicyCycle[]> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-result/policy-cycles`)
  getUploadedPolicyCycles(): Observable<PolicyCycle[]> {
    return null;
  }

  @GET(`${THIRD_PARTY}/accessory-discount-result/policy-cycles`)
  getUploadedPolicyCyclesFromThirdParty(): Observable<PolicyCycle[]> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-cycle-config/operators`)
  getCycleConfigOperators(): Observable<Operator[]> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/import-records`)
  getImportRecords(
    @Query('page') page: number,
    @Query('size') size: number,
    @Body params: GetImportRecordsRequest,
  ): Observable<PageResponse<GetImportRecordsResponse>> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-application-details/region-group-gscode-mapping`)
  getRegionGroupGsCodeMapping(): Observable<RegionGroupGsCodeMappingResponse[]> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/import-records/operators`)
  getImportRecordsOperators(): Observable<ImportRecordsOperator[]> {
    return null;
  }

  @GET(`${THIRD_PARTY}/accessory-discount-application-details/template/download`)
  @Headers({isExportFile: true})
  downloadTemplate(@Query('templateType') templateType: ExcelTemplateType): Observable<ArrayBuffer> {
    return null;
  }

  @POST('/api/reports/insurance/accessory-discount-application-detail-statistics-count-report')
  @Headers({isExportFile: true})
  exportCheckFile(@Body params, @Query('export') shouldExport: boolean): Observable<ArrayBuffer> {
    return null;
  }

  @GET('/api/reports/insurance/dm-application-verification-insurance-company-statistics')
  @Headers({isExportFile: true})
  downloadStatisticsReport(@Query('cycleConfigId') cycleConfigId, @Query('export') shouldExport: boolean): Observable<ArrayBuffer> {
    return null;
  }

  @POST(`${INSURANCE_SALES_BASIC_URL}/accessory-discount-application-detail-verification-result/appeal-record`)
  @Headers({'No-Content-Type': 1})
  submitCheckAppeal(@Body params): Observable<void> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-cycle-config`)
  createCycleConfig(@Body createRequest: AccessoryDiscountApplicationDetailCycleConfigRequest): Observable<void> {
    return null;
  }

  @PATCH(`${ACCIDENT_BASIC_URL}/accessory-discount-cycle-config/:id`)
  updateCycleConfig(@Path('id') id: number, @Body createRequest: AccessoryDiscountApplicationDetailCycleConfigRequest): Observable<void> {
    return null;
  }

  @DELETE(`${ACCIDENT_BASIC_URL}/accessory-discount-cycle-config`)
  deleteCycleConfig(@Body ids: string[]): Observable<void> {
    return null;
  }

  @POST('api/appeal-record/download')
  @Headers({isExportFile: true})
  downloadAppealFile(@Query('appealId') id: number,
                     @Query('appealType') appealType: string): Observable<ArrayBuffer> {
    return null;
  }

  @POST(`${ACCESSORY_DISCOUNT_APPLICATION_DETAIL_URL}/:id/approval`)
  handleAppeal(@Path('id') id: number, @Body request: HandleAppealRequest): Observable<void> {
    return null;
  }

  @POST(`${ACCIDENT_BASIC_URL}/accessory-discount-applications/appeal-download`)
  batchDownloadAppeal(@Body uuids: string[]): Observable<void> {
    return null;
  }

  @POST(`${INSURANCE_SALES_BASIC_URL}/export-records/appeal-blob-url`)
  downloadZipFile(@Body params: {id: number; fileName: string}): Observable<ZipBlobResponse> {
    return null;
  }

  @POST('/api/reports/insurance/accessory-discount-application-detail-statistics-count-report')
  getThirdApplyInfoCheck(
    @Query('page') page: number,
    @Query('size') size: number,
    @Body params: VerificationResultsRequest,
  ): Observable<AccessoryDiscountTotalStatisticsResponse<ApplicationDetailStatisticsCountReportResponse>> {
    return null;
  }

  @GET(`${THIRD_PARTY}/accessory-discount-result`)
  getAccessoryDiscountResultFromThirdParty(
    @Query('dealerId') dealerId: string,
    @Query('policyCycleId') policyCycleId: string,
  ): Observable<AccessoryDiscountResult> {
    return null;
  }

  @GET(`${ACCIDENT_BASIC_URL}/accessory-discount-result`)
  getAccessoryDiscountResult(
    @Query('policyCycleId') policyCycleId: string,
  ): Observable<AccessoryDiscountResult> {
    return null;
  }
}

export const WEEKLY_PLAN_TITLE = [
  {
    value: 'first_week',
    title: '第一周',
    text: '第一周落实结果',
  }, {
    value: 'second_week',
    title: '第二周',
    text: '第二周落实结果',
  }, {
    value: 'third_week',
    title: '第三周',
    text: '第三周落实结果',
  }, {
    value: 'fourth_week',
    title: '第四周',
    text: '第四周落实结果',
  }, {
    value: 'fifth_week',
    title: '第五周',
    text: '第五周落实结果',
  },
];

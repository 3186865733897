import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { DealerForReportDragBarComponent } from './dealer-for-report-drag-bar.component';
import { TooltipModule } from 'ngx-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule.forRoot(),
    NgZorroAntdModule.forRoot(),
  ],
  declarations: [
    DealerForReportDragBarComponent,
  ],
  exports: [
    DealerForReportDragBarComponent,
  ],
})
export class DealerForReportDragBarModule {}

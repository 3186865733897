<section class="customize-modal">
  <div class="modal-container" #finishTestDriveModal [style.left.px]="left" [style.top.px]="top">
    <div class="form-inline">
      <section class="finish-time-section">
        <span class="mandatory-title">试驾结束时间</span>
          <div class="form-container">
            <div class="form-group">
              <input type="text"
                     #hours
                     name="hours"
                     class="form-control"
                     autocomplete="off"
                     [formControl]="finishTimeFormGroup.controls.hour">
            </div>
            :
            <div class="form-group">
              <input type="text"
                     #minutes
                     name="minutes"
                     class="form-control"
                     autocomplete="off"
                     [formControl]="finishTimeFormGroup.controls.minute">
            </div>
          </div>
        <div class="prompt-area" [ngClass]="{'show': isFinishTimeInvalid()}">
          {{getErrorMessage()}}
        </div>
      </section>

      <section class="mileage-section">
        <span class="mandatory-title">试驾里程（公里）</span>
        <div class="form-container">
          <div class="form-group">
            <input type="number"
                   #mileages
                   name="mileages"
                   class="form-control"
                   autocomplete="off"
                   [formControl]="testDriveFormGroup.controls.mileage">
          </div>
        </div>
        <div class="prompt-area" [ngClass]="{'show': testDriveFormGroup.controls.mileage.invalid}">
          <p>请输入正确格式</p>
        </div>
      </section>
    </div>

    <section class="control-btns">
      <button class="btn btn-secondary" (click)="cancel()">取消</button>
      <button class="btn btn-primary" (click)="save()">完成试驾</button>
    </section>
  </div>
</section>

import { Injectable } from '@angular/core';
import { Body, GET, RebirthHttp, POST, DELETE, PATCH, Path, Query } from 'rebirth-http';
import { Observable } from 'rxjs';
import { Insurance } from '../models/insurance';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InsuranceApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/insurances')
  getInsurances(@Query('is_active') isActive: boolean): Observable<Insurance[]> {
    return null;
  }

  @POST('/api/insurances')
  createInsurance(@Body data): Observable<Insurance> {
    return null;
  }

  @PATCH('/api/insurances/:id')
  updateInsurance(@Path('id') id: number,
                  @Body data) {
    return null;
  }

  @DELETE('/api/insurances')
  deleteInsurance(@Body insuranceIds: number[]): Observable<any> {
    return null;
  }
}

import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';
import * as _ from 'lodash';

@Component({
  template: `
    <div class="cell-container" (click)="toggle()">
      <span #content class="cell-content" [ngClass]="{'child-cell': !hasChildren}" [title]="value">{{value}}</span>
      <i *ngIf="hasChildren" [ngClass]="iconClass"></i>
    </div>
  `,
  styleUrls: ['./collapsible-row-cell.scss'],
})
export class CollapsibleRowCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  value: string;
  ellipsisAble: boolean = true;

  @ViewChild('content') content: ElementRef;

  @HostListener('mouseenter')
  mouseEnter() {
    if (!this.ellipsisAble) {
      return;
    }
    const element = this.content.nativeElement;
    if (element.offsetWidth > element.parentElement.offsetWidth) {
      this.content.nativeElement.setAttribute('title', this.value);
    }
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.ellipsisAble = _.get(params.colDef, 'cellRendererParams.ellipsisAble', true);
  }

  get hasChildren() {
    return this.params.node.hasChildren();
  }

  get isExpanded() {
    return this.params.node.expanded;
  }

  get iconClass() {
    return `fa ${(this.isExpanded ? 'antd-caret-up-outlined' : 'antd-caret-down-outlined')} cell-icon`;
  }

  refresh(params: any): boolean {
    return false;
  }

  toggle() {
    this.params.node.setExpanded(!this.params.node.expanded);
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid';

interface IMultiRowCell {
  value: string;
  isEllipsis: boolean;
}

@Component({
  template: `
    <div>
      <div *ngFor="let item of value;let index=index"
           [ngClass]="{
           'grey-text': index!==0,
           'is-ellipsis': isMultiRowCell(item) ? item?.isEllipsis: false
           }"
           [title]="isMultiRowCell(item) ? item?.value: item">
       {{isMultiRowCell(item) ? item?.value : item}}
      </div>
    </div>
  `,
  styleUrls: ['./multi-row-cell.style.scss'],
})
export class MultiRowCellComponent implements ICellRendererAngularComp {
  public value: IMultiRowCell[] | string[];
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  isMultiRowCell(value) {
    return typeof value !== 'string';
  }
}

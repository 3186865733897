import { Directive, ElementRef, HostListener, Input, OnInit, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { changeRouterForVan } from '../utils/urlReplace';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appAutoChangeHrefRoute]',
})
export class AutoChangeHrefRouteDirective implements OnInit {

  @Input('appAutoChangeHrefRoute') hrefRoute: string;
  @Input() param: string;
  @Input() waitTime: number =  0;
  clicks = new Subject();

  constructor(public element: ElementRef, public renderer: Renderer, private router: Router) {
  }

  redirect(url, param?) {
    this.router.navigate([url], param);
  }
  changeUrlToAdaptVan(url, param) {
    this.redirect(changeRouterForVan(this.router.url, url), param);
  }

  @HostListener('click')
  onClickToChangeUrl() {
    this.clicks.next();
  }

  ngOnInit(): void {
    const nativeElement = this.element.nativeElement;
    this.renderer.setElementStyle(nativeElement, 'color', '#00ADEF');
    this.clicks.pipe(throttleTime(this.waitTime))
      .subscribe(() => this.redirect(changeRouterForVan(this.router.url, this.hrefRoute), this.param));
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import {SpinnerService} from '@otr/website-common';

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomComponent implements OnInit {

  @Input() zoomSrc: string = null;
  @Output() downloadFile: EventEmitter<void> = new EventEmitter<void>();
  originalReadonly: Rectangle;

  onRotate(): void {
    this.rotateImg(this.zoomSrc);
  }

  constructor(private spinnerService: SpinnerService) {
  }

  /* istanbul ignore next */
  rotateImg(base64: string): void {
    const img = this.getImgWidthHeight(base64);
    img.onload = () => {
      this.showLoadingIndicator();
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const { naturalWidth, naturalHeight } = img;
      canvas.width =  _.cloneDeep(naturalHeight);
      canvas.height =  _.cloneDeep(naturalWidth);
      const ctx  = canvas.getContext('2d') as CanvasRenderingContext2D;
      // 以图片中心点旋转90度，然后从左上角开始绘制
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(Math.PI / 2);
      ctx.drawImage(img, -naturalWidth / 2, -naturalHeight / 2);
      this.zoomSrc =  canvas.toDataURL();

      const { width, height } = this.originalReadonly;
      this.setElementWidthHeight(width, height);
      this.hideLoadingIndicator();
    };
  }

  ngOnInit(): void {
    const img = document.getElementsByClassName('image-zoom')[0] as HTMLElement;
    const offsetWidth = img.offsetWidth;
    let { width, height } = this.getImgWidthHeight(this.zoomSrc);
    if (height > offsetWidth) {
      const temp = height;
      height = offsetWidth;
      width = width * offsetWidth / temp;
      this.originalReadonly = { width, height };
      this.setElementWidthHeight(height, width);
    }
  }

  getSize(event: any): void {
    this.originalReadonly = {
      width: event.target.width,
      height: event.target.height,
    };
  }

  getImgWidthHeight(base64: string): HTMLImageElement {
    const img: HTMLImageElement = new Image();
    img.setAttribute('crossOrigin', 'Anonymous');
    img.src = base64;
    return img;
  }

  setElementWidthHeight(width: number, height: number): void {
    const img = document.getElementsByClassName('image-zoom')[0] as HTMLElement;
    img.style.width = `${height}px`;
    img.style.height = `${width}px`;
  }

  onDownloadFile(): void {
    this.downloadFile.emit();
  }

  // 显示加载指示器
  showLoadingIndicator = () => {
    this.spinnerService.loading();
  }

// 隐藏加载指示器
  hideLoadingIndicator = () =>  {
    this.spinnerService.stop();
  }
}

interface Rectangle {
  width: number;
  height: number;
}

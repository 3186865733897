<div class="form-container">
  <div class="section close-reason">
    <span class="section-title required">关闭原因</span>
    <div class="section-content">
      <app-dropdown
        [options]="closeReasons"
        [selectedValue]="selectedReason.value"
        [control]="selectedReason"
        [style]="{ 'width': '290px' }"
        [error]="selectedReason.invalid && selectedReason.touched"
      >
      </app-dropdown>
    </div>
  </div>
  <div class="section">
    <span class="section-title">备注</span>
    <div class="section-content">
      <textarea class="customize-textarea comment" maxlength="200" placeholder="还有什么补充信息" [formControl]="comments"></textarea>
    </div>
  </div>
</div>

import * as moment from 'moment';
import {
  FILTER_INPUT_TYPES,
  SA_NAME_WITH_ALL_OPTIONS,
  SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS,
  VEHICLE_BUSINESS_TYPE_OPTION,
} from '../constants/report';
import { XLSX_EXCEL_2007_CONTENT_TYPE } from '../../_common/constants/export_content_type';
import { booleanFormatter, priceFormatter } from '../utils/utils';

export const SERVICE_ORDER_GENERAL_QUERY_CONFIG = {
  name: 'order-general-query',
  enablePagination: true,
  exportedFileName: '订单综合查询.xlsx',
  exportedContentType: XLSX_EXCEL_2007_CONTENT_TYPE,
  column: [
    {
      headerName: '订单号',
      field: 'orderNumber',
      width: 180,
    },
    {
      headerName: '订单类型',
      field: 'serviceRecordCategory',
      width: 120,
    },
    {
      headerName: '服务人员',
      field: 'assaFullName',
      width: 120,
    },
    {
      headerName: '车牌号',
      field: 'carPlateNumber',
      width: 120,
    },
    {
      headerName: '客户',
      field: 'customerName',
      width: 120,
    },
    {
      headerName: '底盘号',
      field: 'fin',
      width: 200,
    },
    {
      headerName: '品牌',
      field: 'brand',
      width: 120,
    },
    {
      headerName: '车型',
      field: 'carModel',
      width: 200,
    },
    {
      headerName: '首登日期',
      field: 'firstRegistrationDate',
      width: 120,
    },
    {
      headerName: '创建日期',
      field: 'orderCreatedTime',
      width: 120,
    },
    {
      headerName: '预约到店时间',
      field: 'reservedDate',
      width: 120,
    },
    {
      headerName: '实际到店时间',
      field: 'arrivalDate',
      width: 120,
    },
    {
      headerName: '登记进厂日期',
      field: 'checkedInDate',
      width: 120,
    },
    {
      headerName: '登记进厂里程 (km)',
      field: 'checkedInMileage',
      width: 120,
    },
    {
      headerName: '登记出厂日期',
      field: 'checkedOutDate',
      width: 120,
    },
    {
      headerName: '完工日期',
      field: 'clockedOffDate',
      width: 120,
    },
    {
      headerName: '结账日期',
      field: 'invoicedTime',
      width: 120,
    },
    {
      headerName: '服务类型',
      field: 'serviceTypes',
      width: 200,
    },
    {
      headerName: '车顶号',
      field: 'roofNumber',
      width: 120,
    },
    {
      headerName: '工时 (¥)',
      field: 'laborBeforeTax',
      width: 120,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '配件 (¥)',
      field: 'partBeforeTax',
      width: 120,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '杂项 (¥)',
      field: 'sundryBeforeTax',
      width: 120,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: 'VAT (¥)',
      field: 'vat',
      width: 120,
      valueFormatter: priceFormatter,
      cellClass: 'cell-align-right',
      headerClass: 'cell-align-right',
    },
    {
      headerName: '客户要求',
      field: 'customerRequirement',
      width: 200,
    },
    {
      headerName: '服务备注',
      field: 'serviceNotes',
      width: 200,
    },
    {
      headerName: '终检',
      field: 'finalInspectionEnd',
      width: 120,
      valueFormatter: booleanFormatter,
    },
  ],
  filter: [
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedInDate',
      label: '登记进厂日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'checkedOutDate',
      label: '登记出厂日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.DATE_RANGE,
      name: 'createDate',
      label: '创建日期',
      startMinLimitValue: moment().subtract(3, 'years'),
      startLimitValue: moment(),
      endLimitValue: moment(),
    },
    {
      type: FILTER_INPUT_TYPES.TEXT_INPUT,
      name: 'carModel',
      label: '车型',
      placeholder: '请输入车型进行搜索',
    },
    {
      type: FILTER_INPUT_TYPES.SA_NAME_DROPDOWN,
      name: 'assaUsername',
      label: '服务人员',
      options: SA_NAME_WITH_ALL_OPTIONS.values,
      defaultValue: SA_NAME_WITH_ALL_OPTIONS.get('ALL'),
      keyForOptionText: 'full_name',
      keyForOptionValue: 'username',
    },
    {
      type: FILTER_INPUT_TYPES.DROPDOWN,
      name: 'serviceRecordCategory',
      label: '订单类型',
      options: SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS.values,
      defaultValue: SERVICE_RECORD_CATEGORY_WITH_ALL_OPTIONS.get('ALL'),
    },
    {
      type: FILTER_INPUT_TYPES.SERVICE_TYPE_DROPDOWN,
      name: 'serviceType',
      label: '服务类型',
      options: [],
      defaultValue: null,
      keyForOptionText: 'text',
      keyForOptionValue: 'id',
    },
    {
      type: FILTER_INPUT_TYPES.VEHICLE_BUSINESS_TYPE_GROUP_BUTTON,
      name: 'vehicleType',
      label: '车型：',
      options: VEHICLE_BUSINESS_TYPE_OPTION.values,
      defaultValue: VEHICLE_BUSINESS_TYPE_OPTION.get('ALL').value,
    },
  ],
};

import { Injectable } from '@angular/core';
import { Body, GET, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { SendMessageModel } from '../models/offer-template';

@Injectable()
export class SendOfferMsgApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/customers/wechat/subscription/:customerId')
  getWechatSubscribeStatus(@Path('customerId') customerId: string) {
    return null;
  }

  @GET('/api/sales-website/retention-lead-message/templates')
  getOfferTemplates(@Query('offer_id') offerId: string, @Query('is_custom_offer') isCustomOffer: boolean,
                    @Query('trade_in_enabled') tradeInEnabled: boolean) {
    return null;
  }

  @POST('/api/sales-website/retention-lead-message')
  sendOfferMessage(@Body data: SendMessageModel) {
    return null;
  }

}

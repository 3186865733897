import { Injectable } from '@angular/core';
import { CurrentUserService } from '@otr/website-common';
import * as _ from 'lodash';
@Injectable()
export class RoleCodeService {

  constructor(
    private currentUserService: CurrentUserService,
  ) {}

  isRoleOfDG() {
    const info = this.currentUserService.getCurrentUser();
    const rolecode = info.roleCode;
    return _.includes([
      'DGCO',
      'DGMM',
      'DGSCM',
      'DG_AFTERSALES',
      'DG_FINANCE',
      'DGA',
      'DGF',
      'MD',
      'PMA',
      'RA',
      'RCOO',
      'RD',
      'DG_SALES',
    ], rolecode);
  }
}

<div>
  <div class="selector" [class.selecting]="selecting" [class.error]="errorMessage">
    <select [(ngModel)]="value" [disabled]="disabled" (change)="optionValueChange($event.target.value)">
      <option *ngFor="let option of options" [value]="option.value">
        {{option.text}}
      </option>
    </select>
    <div class="error-message">{{errorMessage}}</div>
  </div>
</div>

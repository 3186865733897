import { ICellRendererParams } from 'ag-grid';
import { Component, HostBinding } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import { getValue } from './icon.component';

@Component({
  template: `
    <button class="btn icon-text-cell-container" [disabled]="disabled" [ngStyle]="width"
            (click)="onClick($event)">
      <span class="display-text"
            [isDisabled]="!showToolTip"
            [tooltip]="text"
            placement="right"
      ><img [src]="getImageUrl(params)" alt=''/>{{ text }}</span>
    </button>
  `,
  styleUrls: ['./icon.style.scss', './image-icon.component.scss'],
})
export class ImageIconComponent implements ICellRendererAngularComp {
  text: string;
  spanStyle: any;
  width: any;
  showToolTip: boolean;
  disabled: boolean;
  params: ICellRendererParams;
  getImageUrl: (params: ICellRendererParams) => string;
  getHostClassName: (params: ICellRendererParams) => string;

  @HostBinding('class') get hostClassName(): string {
    return this.getHostClassName(this.params);
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const cellRendererParams = params.colDef.cellRendererParams;
    this.getImageUrl = cellRendererParams.getImageUrl;
    this.getHostClassName = cellRendererParams.getHostClassName;
    this.text = getValue(cellRendererParams.text, params);
    this.disabled = getValue(cellRendererParams.disabled, params);
    this.showToolTip = getValue(cellRendererParams.showToolTip, this.params);

    const btnWidth = getValue(cellRendererParams.btnWidth, this.params);
    if (btnWidth) {
      this.width = { width: `${btnWidth}px` };
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onClick(event): void {
    (this.params.colDef.cellRendererParams.onClick || _.noop)(this.params.data, event);
  }
}

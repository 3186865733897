import { combineLatest, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ServerTimeService } from '../../_common/services/server-time.service';
import { DealerService } from '../../_common/services/dealer.service';
import { map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  AuthService,
  CurrentUserService, FeatureToggleService,
  GlobalEvent,
  GlobalEventService,
  LoginService,
} from '@otr/website-common';
import { VehicleService } from '../../_common/services/vehicle.service';
import { TabSwitcherService } from '../../_common/services/tab-switcher.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService,
              private loginService: LoginService,
              private currentUserService: CurrentUserService,
              private serverTimeService: ServerTimeService,
              private tabSwitcherService: TabSwitcherService,
              private globalEventService: GlobalEventService,
              private dealerService: DealerService,
              private vehicleService: VehicleService,
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    const data: { privilege?, role? } = route.data;
    return combineLatest(
      this.loginService.fetchConfigurationsAfterLogin(),
      this.serverTimeService.setTimeIntervalWithServerTime(),
      this.dealerService.ready(),
      this.vehicleService.setVanClasses(),
    ).pipe(
      map(() => {
        return this.currentUserService.canAccessRoute(data.privilege, data.role);
      }),
      tap(authed => {
        if (authed) {
          this.globalEventService.emit(GlobalEvent.NAVIGATION_GROUP_AUTHORIZED, state.url);
          this.tabSwitcherService.isPartsPurchaseRoute = _.includes(state.url, '/parts-purchase');
          this.tabSwitcherService.isVanType = _.get(route.data, 'isVanType', false);
        } else {
          alert(`auth guard failed, user do not have any of privileges:${JSON.stringify(data.privilege)}`);
          this.authService.logout();
        }
      }),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Promise<any> | Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }
}

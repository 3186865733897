export interface CustomerHobbyEntity {
  type: string;
  subType: string;
}

export const CUSTOMER_HOBBIES = {
  运动: ['高尔夫', '攀岩', '健身', '骑马', '球类运动', '瑜伽', '冰雪项目', '游泳', '钓鱼', '帆船', '保龄球', '赛车', '运动-其他'],
  旅游: ['自驾游', '海外游', '周边游', '国内游', '旅游-其他'],
  艺术: ['音乐', '美术', '园艺插花', '茶艺', '时尚', '影视', '艺术-其他'],
  美食烹饪: ['西餐', '中餐', '东南亚美食', '日本料理', '烧烤', '美食烹饪-其他'],
  社交活动: ['美容纤体', '温泉SPA', '亲子活动', 'DIY', '摄影', '阅读', '社交活动-其他'],
};

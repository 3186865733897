import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {TokenService} from '@otr/website-common';

@Injectable()
export class UploadFileService {
  constructor(private tokenService: TokenService) {
  }

  uploadExcelFile(uploadUrl: string, uploadFile: any, data: any = null): Observable<any> {
    return new Observable<any>(observer => {
      FileAPI.upload({
        url: uploadUrl,
        headers: {
          Authorization: `Bearer ${this.tokenService.accessToken}`,
        },
        files: uploadFile,
        data,
        complete(err, xhr) {
          if (err) {
            observer.error(xhr);
          } else {
            observer.next(xhr.response);
          }
          observer.complete();
        },
      });
    });
  }
}

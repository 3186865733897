import { Injectable } from '@angular/core';
import { Body, DELETE, GET, Headers, Path, POST, RebirthHttp, RequestOptions } from 'rebirth-http';
import { Observable } from 'rxjs';
import { QRCode } from '../models/qrcode';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class QRCodeApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/website/qrcodes')
  getQRCodes(): Observable<QRCode[]> {
    return null;
  }

  @GET('/api/website/qrcodes/icons')
  getQRCodeIcons(): Observable<any[]> {
    return null;
  }

  @GET('/api/website/qrcodes/:filePath?base64=true')
  @RequestOptions({ responseType: 'text' })
  getQRCodeFile(@Path('filePath') filePath: string): Observable<any> {
    return null;
  }

  @POST('/api/website/qrcodes')
  @Headers({ 'No-Content-Type': 1 })
  postQRCode(@Body formData: FormData): Observable<any> {
    return null;
  }

  @DELETE('/api/website/qrcodes/:id')
  removeQRCode(@Path('id') id: number): Observable<any> {
    return null;
  }
}

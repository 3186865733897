<div
  #elem
  (mousedown)="onMousedown($event)"
  id="outbound-call-drag-bar">
  <div class="outbound-call-menu" *ngIf="!isFold; else collapsedMenu">
    <div class="outbound-call-title">
      <p>
        <span>{{isCalling ? '正在通话' : '拨打电话'}}</span>
        <span class="outbound-call-duration" *ngIf="isCalling">{{getCallDuration()}}</span>
      </p>
      <div>
        <img class="assistant" *ngIf="showAssistantIcon && isShowCTI" src="/assets/inbound-call/assistant-icon.png" alt="" (click)="foldAssistantPage('click')">
        <img src="/assets/inbound-call/menu-icon.png" alt="" (click)="foldMenu()">
      </div>
    </div>
    <div class="outbound-call-operation" *ngIf="isCalling">
      <div class="call-operation-phone">
        <p *ngIf="isDialed">+</p>
        <span *ngIf="isDialed">{{areaCode}}</span>
        <span *ngIf="isDialed">{{phoneNumber}}</span>
      </div>
      <div class="call-operation-menu">
        <div class="in-call" [ngClass]="{'center-container': isTransfer || isConsult}">
          <button [ngClass]="{'active': isMute,'disabled': isHolding}" (click)="handleMuteChange()" *ngIf="!(isTransfer || isConsult)">
            <img [src]="isMute ? '/assets/inbound-call/new-mute-icon-active.png' : '/assets/inbound-call/new-mute-icon-default.png'" alt="">
            <p>静音</p>
          </button>
          <button [ngClass]="{'active': isHolding,'disabled': isMute}" (click)="handleHoldChange()" *ngIf="!(isTransfer || isConsult)">
            <img [src]="isHolding ? '/assets/inbound-call/new-hold-icon-active.png' : '/assets/inbound-call/new-hold-icon-default.png'" alt="">
            <p>保持</p>
          </button>
          <button [ngClass]="{'disabled': isMute || isHolding}" (click)="handleTransferChange()" *ngIf="!isConsult">
            <img *ngIf="!(isMute || isHolding)" [src]="isTransfer ? isAllowExit ? '/assets/inbound-call/new-exit-icon-default.png' : '/assets/inbound-call/new-exit-icon-disabled.png' : '/assets/inbound-call/new-transfer-icon-default.png'" alt="">
            <img *ngIf="isMute || isHolding" src="/assets/inbound-call/new-transfer-icon-disable.png" alt="">
            <p>{{isTransfer ? '退出转接' : '转接'}}</p>
          </button>
          <button [ngClass]="{'disabled': isMute || isHolding}" (click)="handleConsultAdviceChange()" *ngIf="!isTransfer">
            <img *ngIf="!(isMute || isHolding)"  [src]="isConsult ? isAllowExit ? '/assets/inbound-call/new-exit-icon-default.png' : '/assets/inbound-call/new-exit-icon-disabled.png' : '/assets/inbound-call/new-seek-icon-default.png'" alt="">
            <img *ngIf="isMute || isHolding" src="/assets/inbound-call/new-seek-icon-disable.png" alt="">
            <p>{{isConsult ? '退出咨询' : '咨询'}}</p>
          </button>
        </div>
      </div>
    </div>
    <div class="outbound-call-input-region" *ngIf="!isCalling">
      <p>+</p>
      <input type="text"
        [(ngModel)]="areaCode"
        class="area-code-input"
        nz-popover
        [nzContent]="areaCodeTemplate"
        (keydown)="onAreaCodeKeydown($event)"
        *ngIf="!isDialed"
        nzPlacement="bottom">
      <input type="text"
        [(ngModel)]="phoneNumber"
        [ngClass]="{'area-phone-number-input': true, 'area-phone-number-input-small-font': phoneNumber === ''}"
        placeholder="请输入电话号码"
        *ngIf="!isDialed"
        nz-popover
        [nzContent]="phoneNumberTemplate"
        nzPlacement="bottom"
        (keydown)="onNumberInputKeydown($event)">
      <span *ngIf="isDialed">{{areaCode}}</span>
      <span *ngIf="isDialed">{{phoneNumber}}</span>
    </div>
    <div class="call-out-status" *ngIf="isDialed || isCallFailed">
      <div class="call-out-status-failed" *ngIf="isCallFailed">
        <img src="/assets/inbound-call/call-out-failed-point-icon.png" alt="">
        <p>呼叫失败</p>
      </div>
      <div class="call-out-status-call-out" *ngIf="isDialed && !isCalling">
        <img src="/assets/inbound-call/incoming-call-actived-icon.gif" alt="">
          <p>正在拨号</p>
          <img src="/assets/inbound-call/incoming-call-actived-icon.gif" alt="">
      </div>
    </div>
    <div style="height: calc(30px + 38vh); overflow-y: auto;" *ngIf="hasFetchCustomerData">
      <app-telephonist-status *ngIf="isConsult || isTransfer" [type]="currentTelephonistStatus" (setExitStatus)="setExitStatus($event)" (setCallStatus)="setCallStatus($event)"></app-telephonist-status>
      <div class="outbound-call-customer"[ngStyle]="{'margin-top': isCalling ? '0px': ''}" *ngIf="customerList.length > 0; else noCustomer">
        <h2 class="outbound-call-contacts-title">相关联系人</h2>
        <div class="outbound-call-customer-list">
          <div class="customer-item" *ngFor="let customer of customerList; let index = index;">
            <div class="customer-item-header" [ngClass]="{'active-header': index === expandIndex}">
              <div class="customer-item-header-info">
                <div class="cusotmer-info-name" (click)="handleNameClick(customer.id)">
                  <p>{{customer.full_name}}</p>
                  <img src="/assets/inbound-call/female-icon.png" alt="" *ngIf="customer.gender === 'Female'">
                  <img src="/assets/inbound-call/male-icon.png" alt="" *ngIf="customer.gender !== 'Female'">
                </div>
                <p class="cusotmer-info-company" *ngIf="cecId">{{customer.dealer_name}}</p>
              </div>
              <img src="/assets/inbound-call/arrow-icon.png" alt="" (click)="handleClickSelection(index, customer)" [ngClass]="{'customer-info-arrow': true, 'active-arrow': index === expandIndex}">
              <div class="customer-item-header-highlight" *ngIf="index === expandIndex"></div>
            </div>
            <div class="customer-item-main" [ngClass]="{'customer-item-main-collapsed': index !== expandIndex}">
              <div class="vehicle-list-container" *ngIf="customer.currentVehicleList.length > 0">
                <p class="vehicle-theme-title">现有车（{{customer.currentVehicleList.length}}台）</p>
                <ul>
                  <li class="vehicle-list" *ngFor="let currentVehicle of customer.currentVehicleList">
                    <div class="list-item">
                      <p class="list-item-label">车牌号</p>
                      <p class="list-item-value list-item-value-bold">{{currentVehicle.carPlateNumber}}</p>
                    </div>
                    <div class="list-item">
                      <p class="list-item-label">VIN</p>
                      <p class="list-item-value">{{currentVehicle.vin}}</p>
                    </div>
                    <div class="list-item">
                      <p class="list-item-label">车型</p>
                      <p class="list-item-value">{{currentVehicle.description}}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="vehicle-list-container" *ngIf="hasOpenLead(customer, 'interestedVehicles')">
                <p class="vehicle-theme-title">意向车</p>
                <ul>
                  <li class="vehicle-list">
                    <div class="list-item" *ngIf="hasOpenLead(customer, 'interestedVehicles')">
                      <p class="list-item-label">车型</p>
                      <p class="list-item-value">{{getInterestedVehicle(customer, 'interestedVehicles') || '暂无意向车'}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noCustomer>
        <div class="outbound-call-no-customer">
          <div>
            <h3>暂无匹配的联系人</h3>
            <p (click)="addCustomerAndLead()" *ngIf="shouldShowAddCustomerBtn">+新建客户/销售线索</p>
            <p style='margin-top: 10px;' (click)="addAfteSalesOnCall()">+新建售后线索</p>
          </div>
        </div>
      </ng-template>
    </div>
    <div *ngIf="!hasFetchCustomerData" class="has-no-data-container" style="height: calc(30px + 38vh); overflow-y: auto;" >

    </div>
    <div class="outbound-call-answer">
      <div class="answer-operation" *ngIf="isDialed" (click)="handleRefuseButton()">
        <img src="/assets/inbound-call/refuse-icon.png" alt="">
        <p>挂断</p>
      </div>
      <div class="answer-operation" *ngIf="!isDialed" (click)="handleApproveButton()">
        <img src="/assets/inbound-call/answer-icon-disabled.png" alt="" *ngIf="isDisabledCall()">
        <img src="/assets/inbound-call/answer-icon.png" alt="" *ngIf="!isDisabledCall()">
        <p>拨出</p>
      </div>
    </div>
  </div>
  <ng-template #collapsedMenu>
    <div class="outbound-call-collapsed-menu" *ngIf="isDialed; else iconMenu">
      <div class="collapsed-menu-title">
        <img class="assistant" *ngIf="showAssistantIcon && isShowCTI" src="/assets/inbound-call/assistant-icon.png" alt="" (click)="foldAssistantPage('click')">
        <img src="/assets/inbound-call/collapsed-menu-icon.png" alt="" (click)="expandMenu()">
      </div>
      <div class="collapsed-menu-operation">
        <div class="collapsed-menu-call">
          <p class="phone-number">{{collapsedName}}</p>
          <p class="inbound-call-duration" *ngIf="isCalling">{{getCallDuration()}}</p>
          <div *ngIf="!isCalling">
            <p class="call-remind">正在拨号</p>
            <img src="/assets/inbound-call/incoming-call-actived-icon.gif" alt="">
          </div>
        </div>
      </div>
      <div class="collapsed-menu-answer">
        <div class="answer-operation" *ngIf="isCalling" (click)="handleMuteChange()">
          <img [src]="isMute ? '/assets/inbound-call/collapsed-mute-icon.png' : '/assets/inbound-call/collapsed-no-mute-icon.png'" alt="">
          <p>{{isMute ? '取消静音' : '静音'}}</p>
        </div>
        <div class="answer-operation" *ngIf="isCalling" (click)="handleHoldChange()">
          <img [src]="isHolding ? '/assets/inbound-call/collapsed-hold-icon.png' : '/assets/inbound-call/collapsed-no-hold-icon.png'" alt="">
          <p>{{isHolding ? '取消保持' : '通话保持'}}</p>
        </div>
        <div class="answer-operation" (click)="handleRefuseButton()">
          <img src="/assets/inbound-call/refuse-icon.png" alt="">
          <p>挂断</p>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #iconMenu>
    <div class="outbound-call-icon-menu">
      <img src="/assets/inbound-call/floating-call-icon.png" alt="">
    </div>
  </ng-template>
</div>
<ng-template #areaCodeTemplate>
  <div class="popover-template">
    <h3>提示：</h3>
    <p>外线电话加拨9，外地电话加拨90</p>
  </div>
</ng-template>
<ng-template #phoneNumberTemplate>
  <div class="popover-template">
    <h3>提示：</h3>
    <p>输入11位手机号，如：18810888888；</p>
    <p>或带区号的座机号，如：02168886888</p>
  </div>
</ng-template>

<div [class.percentage-editable]="isPercentage"
     *ngIf="!isTextCell">
  <input #input
         class="input-control"
         [value]="value"
         (blur)="onChange(input.value)"
         (keydown)="onKeyDown($event)">
</div>
<div *ngIf="isTextCell"
     [class.percentage-text]="isPercentage && isValidateNumber(value)"
     [ngClass]="className">
  <span>
      {{value}}
  </span>
</div>

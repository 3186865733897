import { STOCK_ORDER_STATUS } from '../../_common/constants/vehicle';

export const SALES_PLATFORM = { MBE: '电商平台' };

export const SHARE_RANGE = [
  { value: 'NO_SHARE', text: '不共享', default: true },
  { value: 'SHARE_DEALER', text: '经销商' },
  { value: 'DEALER_GROUP_DEMO', text: '集团' },
  { value: 'WHOLE_COUNTRY', text: '全国' },
  { value: 'DEALER', text: '总公司/分公司' },
];

export const DEALER_SHARE_RANGE = [
  { value: 'DEALER_GROUP', text: '所属集团' },
  { value: 'PMA', text: '所属PMA' },
  { value: 'REGION', text: '所属区域' },
];

export const ALL_SHARE_RANGE = [
  { value: 'NO_SHARE', text: '不共享', default: true },
  { value: 'SHARE_DEALER', text: '经销商' },
  { value: 'PMA', text: '所属PMA' },
  { value: 'REGION', text: '所属区域' },
  { value: 'DEALER_GROUP', text: '所属集团' },
  { value: 'WHOLE_COUNTRY', text: '全国' },
  { value: 'DEALER', text: '总公司/分公司' },
];

export const BRANDS = {
  SMART: 'SMART',
  AMG: 'AMG',
  MB: 'MB',
  DENZA: 'DENZA',
};

export const VAN_TYPE_CLASS: string[] = ['V', 'Vito'];

export const SELECTOR_OPTIONS = [
  { value: '', text: '请选择', hidden: true },
  { value: 'Yes', text: '是' },
  { value: 'No', text: '否' },
];

export const VEHICLE_CHANNEL_STATUS = {
  IS_AVAILABLE: '已上架',
  IS_UNAVAILABLE: '未上架',
  PROCESSING_AVAILABLE: '上架中',
  PROCESSING_UNAVAILABLE: '下架中',
  FAIL_OF_AVAILABLE: '上架失败',
  FAIL_OF_UNAVAILABLE: '下架失败',
};

export const ORDER_STATUS_WITH_EDITABLE_TEMPORARY_RESERVED = [
  STOCK_ORDER_STATUS.AVAILABLE.value.toLowerCase(),
  STOCK_ORDER_STATUS.OFFERED.value.toLowerCase(),
];

export const SHARE_VISIBLE_ROLES = [
  { value: 'SC', text: '销售顾问' },
  { value: 'SS', text: '销售主管' },
  { value: 'SRM', text: '展厅经理' },
  { value: 'SM', text: '销售经理' },
  { value: 'GM', text: '总经理' },
];

export const SHARE_AREAS = [
  { value: 'SHARE_DEALER', text: '全国' },
  { value: 'DEALER_GROUP_DEMO', text: '集团' },
];

export const UPLOAD_ERROR = {
  TEMPLATE_ERROR: '模板错误，请检查后重新上传',
};

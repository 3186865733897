import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import { Component } from '@angular/core';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  selector: 'app-new-line-tooltip',
  templateUrl: './new-line-tooltip.component.html',
  styleUrls: ['./new-line-tooltip.style.scss'],
})
export class NewLineTooltipComponent implements ICellRendererAngularComp {

  private params: ICellRendererParams;
  text: string;
  shouldShowIcon: boolean = false;
  showDefaultIcon: boolean = true;
  shouldShowText: boolean = true;
  tooltipValues;
  imageSrc: string;
  placement: string;
  stopPropagation: boolean = false;
  toolTipArray = [];

  agInit(params: ICellRendererParams): void {
    const { splitText, text, tooltipValues, imageSrc, placement, shouldShowText = true,
            shouldShowIcon, showDefaultIcon, stopPropagation } = params.colDef.cellRendererParams;
    const entireText = _.isNil(text) ? (params.value || '') : getValue(text, params);
    this.text = _.isFunction(splitText)
    ? getValue(splitText, entireText)
    : entireText;
    this.shouldShowText = shouldShowText;
    this.tooltipValues = _.isArray(tooltipValues)
    ? getValue(tooltipValues, params)
    : getValue(tooltipValues, params) || entireText;
    this.showDefaultIcon = this.getUnNilData(showDefaultIcon, true);
    this.imageSrc = getValue(imageSrc, params) || this.getDefaultImg();
    this.placement = getValue(placement, params) || 'bottom';
    this.shouldShowIcon = getValue(shouldShowIcon, entireText) && !_.isNil(this.imageSrc);
    this.stopPropagation = this.getUnNilData(stopPropagation, false);
    if (!this.isTooltipValuesArray()) {
      this.caculateToolTipArray();
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  isTooltipValuesArray() {
    return _.isArray(this.tooltipValues);
  }

  getDefaultImg() {
    return this.showDefaultIcon ? '/assets/as-lead/tooltip-icon.svg' : null;
  }

  getUnNilData(data, defaultData) {
    return _.isNil(data) ? defaultData : data;
  }

  stopMousePropagation($event) {
    if (this.stopPropagation) {
      $event.stopPropagation();
    }
  }

  caculateToolTipArray() {
    this.toolTipArray = this.tooltipValues ? this.tooltipValues.split(/\n/) : [];
  }
}

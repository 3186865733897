import { Injectable } from '@angular/core';
import { GET, Headers, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PurchasedServiceContract } from '../../customer-lead-management/lead-management/shared/model';

@Injectable()
export class SalesServiceContractApi extends RebirthHttp {
  public uploadUri = '/api/quotation-options/service-contracts/upload';
  public uploadBMBSUri = '/api/quotation-options/service-contracts/upload-bmbs';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/quotation-options/service-contracts/download')
  @Headers({ isExportFile: true })
  getXLSXServiceContract(@Query('dealer_id') dealerId: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/purchased-service-contracts')
  getServiceContract(@Query('fin') fin: string, @Query('dealer_id') dealerId: string): Observable<Array<PurchasedServiceContract>> {
    return null;
  }

  @GET('/api/quotation-options/service-contracts/download-bmbs')
  @Headers({ isExportFile: true })
  getBMBSXLSXServiceContract(): Observable<any> {
    return null;
  }

  @GET('/api/quotation-options/service-contracts/progress-bmbs')
  getUploadStatus(@Query('redis_key') key: string): Observable<any> {
    return null;
  }
}

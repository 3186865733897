import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BasicEditorComponent } from '../../editors/basic-editor.component';
import { isValidIntegerValue, valueFormat } from '../../../../utils/sales-strategy';

const BACKSPACE = 'Backspace';

const isKeyBackspace = (key: any) => key === BACKSPACE;

const isKeyNumeric = (key): boolean => /\d/.test(key);

@Component({
  selector: 'app-formula-cell-render',
  templateUrl: './formula-cell-renderer.template.html',
  styleUrls: ['./formula-cell-renderer.style.scss'],
})
export class FormulaCellRendererComponent extends BasicEditorComponent {
  isTextCell = false;
  params;
  value: any;
  isPercentage: boolean = false;
  className;
  dataRefresh;
  isValidateNumber = isValidIntegerValue;

  agInit(params): void {
    this.params = params;
    this.isPercentage = this.getCellParamsValueFor('isPercentage');
    this.isTextCell = this.getCellParamsValueFor('isTextCell');
    this.dataRefresh = _.get(this.params.colDef, 'cellRendererParams.dataRefresh', _.identity);
    this.value = this.getValue();
    this.className = this.getCellParamsValueFor('className');
  }

  getValue() {
    return valueFormat(this.params.value);
  }

  getCellParamsValueFor(key) {
    const valueGenerator = _.get(this.params.colDef, `cellRendererParams.${key}`);
    if (valueGenerator) {
      return _.isFunction(valueGenerator) ? valueGenerator(this.params, this.value) : valueGenerator;
    }
  }

  onChange(value) {
    if (!_.isEqual(value, _.toString(this.value))) {
      _.set(this.params.context, 'isEdited', true);
      _.set(this.params.node.data, this.params.column.colId, value);
      this.dataRefresh();
    }
  }

  onOtherKeyDown(event): void {
    if (!this.isKeyPressedNumericOrBackspace(event)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  private isKeyPressedNumericOrBackspace(event): boolean {
    const key = this.getKeyFromEvent(event);
    return isKeyBackspace(key) || isKeyNumeric(key);
  }

}

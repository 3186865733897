import { Injectable } from '@angular/core';
import { Body, GET, PUT, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApprovalFlowApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }
  @GET('/api/v2/approval-flow-config/baselines')
  getApprovalBaselinesByCurrentUser(): Observable<any> {
    return null;
  }

  @PUT('/api/v2/approval-flow-config/baselines')
  updateApprovalBaselines(@Body data: Object): Observable<any> {
    return null;
  }

  @GET('/api/v2/approval-flow-config/baselines')
  getApprovalBaselines(@Query('dealer_group_group_id') dealerGroupGroupId: number,
                       @Query('region_group_id') regionGroupId: number,
                       @Query('dealer_group_id') dealerGroupId: number,
                       @Query('group_type') groupType: string): Observable<any> {
    return null;
  }

  @GET('/api/v2/approval-flow-config/allocations')
  getApprovalFlows(): Observable<any> {
    return null;
  }

  @GET('/api/v2/approval-flow-config/allocations/supervisor')
  getSuperiorApprovalFlows(): Observable<any> {
    return null;
  }

  @PUT('/api/v2/approval-flow-config/allocations')
  updateApprovalFlows(@Body data: Object): Observable<any> {
    return null;
  }

  @GET('/api/v2/approval-flow-config/allocations')
  getApprovalFlowsByGroupType(@Query('dealer_group_group_id') dealerGroupGroupId: number,
                              @Query('region_group_id') regionGroupId: number,
                              @Query('dealer_group_id') dealerGroupId: number,
                              @Query('group_type') groupType: string): Observable<any> {
    return null;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FILTER_COLLAPSE_TEXT } from 'app/_common/constants/common';

@Component({
  selector: 'app-collapsible-detail',
  templateUrl: 'collapsible-detail.component.html',
  styleUrls: ['collapsible-detail.component.scss'],
})
export class CollapsibleDetailComponent {
  operationText: string;

  @Input() isCollapsed: boolean = false;
  @Input() expandCollapseText = FILTER_COLLAPSE_TEXT;
  @Input() headerTitle;

  @Output() onChange = new EventEmitter();

  collapsed(): void {
    this.onChange.emit();
    this.operationText = this.expandCollapseText.COLLAPSED;
  }

  expanded(): void {
    this.onChange.emit();
    this.operationText = this.expandCollapseText.EXPANDED;
  }
}

import { toEnum } from '../../_common/utils/enum';

export const DEALER_REMINDER_STATUS_FILTER = toEnum({
  ENABLED: { text: '已启用', value: 'ENABLED'},
  DISABLED: { text: '已停用', value: 'DISABLED' },
  EXPIRED: { text: '已过期', value: 'EXPIRED'},
});

export const REMINDER_STATUS_TAG_STYLE = toEnum({
  ENABLED: { text: 'tag-active', value: 'ENABLED'},
  DISABLED: { text: 'tag-ineffective', value: 'DISABLED'},
  EXPIRED: { text: 'tag-ineffective', value: 'EXPIRED'},
});

export enum DealerReminderStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  EXPIRED = 'EXPIRED',
}

import { Pipe, PipeTransform } from '@angular/core';
import { MESSAGE_TYPE } from '../constants/message';
import * as _ from 'lodash';

@Pipe({ name: 'messagePreLink' })
export class MessagePreLinkPipe implements PipeTransform {
  transform(messageType: string): string {
    return _.find(_.values(MESSAGE_TYPE), { value: messageType }).preLink;
  }
}

import * as moment from 'moment';
import * as _ from 'lodash';
import { ReminderLaborCode } from '../technical-and-maintenance/model/dedicatedTechnicalReminder';
import { InsuranceCompanyDTO } from '../insurance-saas/model/insuranceModel';
import { ExportStatuses } from './constant';
import { DATE_FORMAT, STRING_START_WITH_CHINESE_REGEXP } from 'app/_common/constants/common';

export const dateFormatter = ({ value }: {
  value: string
}) => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '');

export const monthFormatter = ({ value }: { value: string }) => (value ? moment(value).format('YYYY-MM') : '');

export const dayFormatter = ({ value }: {
  value: string
}) => (value ? moment(value).format('YYYY-MM-DD') : '');

export const formatBooleanValue = ({ value }) => _.isNull(value) ? '' : value ? '是' : '否';

export const formatValidityValue = ({ value }) => _.isNull(value) ? '' : value ? '有效' : '无效';

export const getMonthBeforeYear = (year) => moment().subtract(year, 'year').add(1, 'month');

export const getDayBeforeYear = (year) => moment().subtract(year, 'year');

export const mapInsuranceCompanyToLabor = (insuranceCompanies: Array<InsuranceCompanyDTO>, selected: boolean): ReminderLaborCode[] => {
  return _.map(insuranceCompanies, (company) => ({
    laborOptionCode: company.insuranceCompanyName,
    laborOptionDescription: company.insuranceCompanyName,
    selected,
  }));
};

export const getSelectedOptions = (selectedOptions: string): string[] => {
  if (!selectedOptions || _.isEmpty(selectedOptions)) {
    return [];
  }
  return selectedOptions.includes('全部') ? [] : _.split(selectedOptions, ',');
};

export const getCellValue = ({ value }) => value;

export const exportStatusFormatter = ({ value }) => ExportStatuses[value];

export function getCurrentDateString(): string {
  return moment().format(DATE_FORMAT);
}

export function formatDateRangeToString(startDateString, endDateString): string {
  const startDate = moment(startDateString).format('YYYY年MM月DD日');
  const endDate = moment(endDateString).format('YYYY年MM月DD日');
  return `${startDate} 至 ${endDate}`;
}

export function getAfterYears(year = 1): string {
  return moment().add(year, 'year').format(DATE_FORMAT);
}

export function getCurrentDateMonthsBefore(month: number): string {
  return moment().subtract(month, 'month').format(DATE_FORMAT);
}

export function getFirstDayOfCurrentYear(): string {
  return moment().startOf('year').format(DATE_FORMAT);
}

export function shouldShowExportQueryConditionEmpty(str, queryCondition): boolean {
  const regex = /(\d{4}-\d{2})|批量VIN/g;
  return regex.test(str) || !queryCondition;
}

export const formatterNumberWithPercent = (value: string) => _.isNil(value) ? '' : `${((Number(value) || 0) * 100).toFixed(2)}%`;

export const trimAllSpace = (str: string) => str.replace(/\s/g, '');

export const getFilePrefix = (fileName: string): string => {
  return fileName.substr(0, fileName.lastIndexOf('_'));
};

export const getOptionDisplayText = option => _.chain(option)
  .map((optionValue: ReminderLaborCode) => optionValue.laborOptionDescription).join('，').value();

export const orderAlphabetically = options => options.sort(customCompare);

export const sortByDealerNameAlphabetically = (a, b) => {
  const nameA = a.name;
  const nameB = b.name;
  if ((!_.isEmpty(nameA) &&
      _.isEmpty(nameB))  ||
    (STRING_START_WITH_CHINESE_REGEXP.test(nameA) &&
      !STRING_START_WITH_CHINESE_REGEXP.test(nameB))) {
    return -1;
  }
  if ((_.isEmpty(nameA) &&
      !_.isEmpty(nameB)) ||
    (!STRING_START_WITH_CHINESE_REGEXP.test(nameA) &&
      STRING_START_WITH_CHINESE_REGEXP.test(nameB))) {
    return 1;
  }
  return nameA.localeCompare(nameB);
};

const customCompare = (a1, b1) => {
  const a = _.get(a1, 'text[0]').toLowerCase();
  const b = _.get(b1, 'text[0]').toLowerCase();

  if (a === '-' && b !== '-') {
    return 1;
  }
  if (a !== '-' && b === '-') {
    return -1;
  }
  return a.localeCompare(b);
};

export const isCurrentDateBetween = (startDate, endDate) => {
  if (_.isNil(startDate) || _.isNil(endDate)) {
    return false;
  }
  return moment().isBetween(moment(startDate), moment(endDate), null, '[]');
};

export const formatMultiSelectValueToString = (item, path) => _.get(item, path) ?  _.get(item, path).join('，') : '全部';

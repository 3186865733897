import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CurrentUserService } from '@otr/website-common';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private currentUserService: CurrentUserService) {
  }

  canDeactivate(component: CanComponentDeactivate) {
    if (this.currentUserService.getCurrentUser()) {
      return component.canDeactivate ? component.canDeactivate() : true;
    }
    return true;
  }
}

<div>
  <div #dropdownSelect class="select-wrapper" dropdown
       [isDisabled]="disabled"
       [class.disabled]="disabled"
       (onHidden)="clearKeyword()" >
    <button id="button-basic" dropdownToggle
            type="button"
            class="input-control input-control-middle"
            [class.disabled]="disabled"
            [class.error]="errorMessage">
      {{displayText}}
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
        (mousewheel)="scrollBar($event)">
      <li
        *ngFor="let option of filteredOptions"
        class="dropdown-item "
        (click)="handleClick(option)"
      >
      <div [innerHtml]="getOptionHtml(option)"></div>
      </li>
    </ul>
  </div>
</div>

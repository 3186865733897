import { ICellRendererParams } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import { Component, ElementRef, ViewChild } from '@angular/core';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};
@Component({
  template: `
  <div class="container">
    <a [routerLink]="link" class="link-button" (click)="operation()" *ngIf="link">{{ text }}</a>
    <span class="text-button" (click)="operation()" *ngIf="!link">{{ text }}</span>
  </div>
  `,
  styleUrls: ['./history-call-button.style.scss'],
})
export class HistoryCallButtonComponent implements ICellRendererAngularComp {
  text: string = '';
  link: string;
  params: ICellRendererParams;
  cellRendererParams: any;

  @ViewChild('container') container: ElementRef;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellRendererParams = params.colDef.cellRendererParams;
    this.text = getValue(this.cellRendererParams.text, params);
    this.link = getValue(this.cellRendererParams.link, params);
  }

  refresh(params: any): boolean {
    return false;
  }

  operation() {
    if (_.isFunction(this.cellRendererParams.operation)) {
      this.cellRendererParams.operation(this.params.data);
    }
  }
}

import { Observable, of as observableOf, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApplicationRef, ComponentFactoryResolver, EventEmitter, Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import {
  DEALER_GROUP_HIERARCHY_RULE,
  DEALER_GROUP_MIND_MAP_OPTIONS,
  DEALER_GROUP_TYPE,
  MIND_MAP_FORMAT,
} from '../constants/mind-map';
import { MindMapDataConverter } from '../../_common/utils/mind-map-data-converter';
import { DealerGroupApi } from '../../_common/api/dealer-group';
import { MindMapCompareDeepUtil } from '../../_common/utils/mind-map-compare-deep';
import { IDealerGroupUserFilter } from '../../_common/models/dealer';
import { SpinnerService } from '@otr/website-common';

@Injectable()
export class DealerGroupService extends MindMapCompareDeepUtil {
  groups;
  dealers;
  mindMapDataConvertor;
  initialMindMapConfig = new Subject();
  groupsLastUpdateTime = new Subject();
  mindMapSubject$: EventEmitter<any> = new EventEmitter<any>();
  public triggerShowDealerSelect = new Subject();

  constructor(private dealerGroupApi: DealerGroupApi,
              private spinnerService: SpinnerService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private applicationRef: ApplicationRef,
              private injector: Injector) {
    super();
    this.mindMapDataConvertor =
      new MindMapDataConverter(DEALER_GROUP_HIERARCHY_RULE, DEALER_GROUP_TYPE, componentFactoryResolver, injector);
  }

  getDealers() {
    if (this.dealers) {
      return observableOf(this.dealers);
    }
    return this.dealerGroupApi.getDealers();
  }

  getCecDealers(hasBranch) {
    return this.dealerGroupApi.getCecDealers(hasBranch);
  }

  getGroups(): Observable<any> {
    return this.dealerGroupApi
      .getDealerGroups().pipe(
        tap(response => {
          this.groups = response;
          this.groupsLastUpdateTime.next(response.updatedAt);
        }));
  }

  initialMindMap(isDealerGroup): void {
    this.getGroups()
      .subscribe(response => {
        this.mergeDealers(response);
        const mind = this.getMind(isDealerGroup, response);
        this.initialMindMapConfig.next({ options: DEALER_GROUP_MIND_MAP_OPTIONS, mind });
      });
  }

  updateLocalCacheGroups(groups) {
    this.groups = this.mindMapDataConvertor.convertForBackendInterface(groups);
  }

  getDealersName(groups?): string[] {
    const convertedGroups = groups && this.mindMapDataConvertor.convertForBackendInterface(groups);
    return this.initDealersName(convertedGroups || this.groups);
  }

  initDealersName(nodeTree) {
    if (nodeTree.type === 'dealer') {
      return nodeTree.name;
    }

    return _.chain(nodeTree)
      .get('childGroups', [])
      .map(node => this.initDealersName(node))
      .flattenDeep()
      .value();
  }

  mergeDealers(groups) {
    if (groups.type !== 'dealer') {
      const dealerGroups = groups.childGroups
        .filter(item => item.type === 'dealer');

      const dealerNames = dealerGroups.map(item => item.name);

      const otherGroups = groups.childGroups.filter(childGroup => childGroup.type !== 'dealer');
      if (!_.isEmpty(dealerNames)) {
        otherGroups.push({ ..._.head(dealerGroups), name: dealerNames });
      }
      Object.assign(groups, { childGroups: [...otherGroups] });
    }

    if (_.isEmpty(groups.childGroups) && !_.isArray(groups.childGroups)) {
      return;
    }
    _.forEach(groups.childGroups, node => {
      this.mergeDealers(node);
    });
  }

  getMind(isDealerGroup, groups?) {
    return {
      format: MIND_MAP_FORMAT,
      data: this.mindMapDataConvertor.convertForMindMap(groups || this.groups, isDealerGroup),
    };
  }

  updateGroups(data) {
    const convertedData = this.mindMapDataConvertor.convertForBackendInterface(data);
    return this.dealerGroupApi.updateDealerGroups(convertedData).pipe((this.spinnerService.loading()));
  }

  getDealerGroupNames(): Observable<any> {
    return this.dealerGroupApi.getDealerGroupNames();
  }

  getDealerGroupUsers(params: IDealerGroupUserFilter): Observable<any> {
    return this.dealerGroupApi.getDealerGroupUsers(params);
  }

  getDealerGroupUserIdsWithSMSStatus() {
    return this.dealerGroupApi.getDealerGroupUserIdsWithSMS();
  }

  sendSMSToSelectedUser(userIds: Array<number>) {
    return this.dealerGroupApi.sendSMSToDealerGroupUsers(userIds).pipe((this.spinnerService.loading()));
  }

  getDealerGroupRoleOptions() {
    return this.dealerGroupApi.getDealerGroupRoles();
  }

  getIsPristineData(groups) {
    const lastGroups = this.mindMapDataConvertor.convertForBackendInterface(groups);
    return this.equalDeep(lastGroups, this.groups);
  }

  formatUsers(users) {
    return _.chain(users)
      .orderBy(['is_active'], ['desc'])
      .map((user: any) => {
        return this.formatGroups(user.groups).map(group => ({ ...user, ...group }));
      }).flatten().value();
  }

  formatGroups(groups) {
    return _.chain(groups)
      .groupBy('role_code')
      .values()
      .map(formattedGroups => {
        return _.chain(formattedGroups)
          .map(group => ({ ...group, formattedGroupNames: group.name }))
          .reduce((pre: any, next: any) => {
            const formattedGroupNames = `${pre.formattedGroupNames},${next.formattedGroupNames}`;
            return { ...pre, formattedGroupNames };
          }).value();
      }).value();
  }

  formatSelectedGroupOption(editingUser, groupNameOptions) {
    const selectedGroupNames = editingUser.formattedGroupNames.split(',');
    return _.chain(groupNameOptions)
      .filter(dealerGroup => _.includes(selectedGroupNames, dealerGroup.name))
      .map(dealerGroup => ({ ...dealerGroup, role_code: editingUser.role_code }))
      .value();
  }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ModalComponent } from '../../../../_common/components/modal/modal.component';
import { FormUtilService } from '../../../../_common/services/form-util.service';

import { ERROR_MESSAGES } from '../../../../_common/constants/error-messages';
import { ModalMessageService } from '@otr/website-common';

export interface SalesLeadModalContent {
  getFormValue: Function;
}

@Component({
  selector: 'app-sales-lead-modal',
  templateUrl: './sales-lead-modal.component.html',
  styleUrls: ['./sales-lead-modal.component.scss'],
})
export class SalesLeadModalComponent implements OnDestroy, OnInit {
  @Input() modalTitle: string = '';
  @Input() modalSubtitle: string = '';
  @ViewChild(ModalComponent) modal: ModalComponent;

  confirm: Function = _.noop;
  close: Function = _.noop;
  isInvalid$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  hide = () => this.modal.hideModal();
  show = () => this.modal.showModal();

  constructor(private formUtilService: FormUtilService,
              private modalMessageService: ModalMessageService) {
    this.onConfirmClick = _.debounce(this.onConfirmClick.bind(this), 500);
  }

  ngOnInit(): void {
    this.initSubscribe();
  }

  onConfirmClick() {
    if (!this.isInvalid$.value) {
      this.confirm();
      this.modalMessageService.clearMessages();
      return;
    }
    this.modalMessageService.putMessage({ message: ERROR_MESSAGES.FORM_VALUE_INVALID });
    this.formUtilService.markAsTouched$.next(true);
  }

  ngOnDestroy(): void {
    this.formUtilService.markAsTouched$.next(false);
  }

  initSubscribe() {
    this.isInvalid$.subscribe(value => {
      if (!value) {
        this.modalMessageService.removeMessage({ message: ERROR_MESSAGES.FORM_VALUE_INVALID });
      }
    });
  }
}

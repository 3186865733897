import { Injectable } from '@angular/core';
import { ACCIDENT_BASIC_URL, INSURANCE_SALES_BASIC_URL, InsuranceAccidentApi, THIRD_PARTY } from '../api/accessory-discount';
import { Observable, of, throwError } from 'rxjs';
import {
  AccessoryDiscountApplication,
  AccessoryDiscountApplicationDetailCreateRequest,
  AccessoryDiscountApplicationDetails,
  AccessoryDiscountDetail,
  AccessoryDiscountPage,
  BusinessManagementContact,
  ConfirmedNotification,
  BusinessManagementSummary,
  InsuranceNumberValidateRequest,
  InsuranceNumberValidateResponse,
  AccessoryDiscountApplicationDetailUpdateRequest,
  AccessoryDiscountApplicationExportRecordRequest,
  AccessoryDiscountRuleConfigResponse,
  AccessoryDiscountRuleDefaultConfigResponse,
  AccessoryDiscountRuleDefaultConfigRequest,
  AccessoryDiscountRuleConfigsAvailableResponse,
  CreateOrUpdateTipInformationConfigRequest,
  CreateOrUpdateTipInformationConfigResponse,
  PolicyCycle,
  Operator,
  TipsInformationResponse,
  AccessoryDiscountCycleConfigResponse,
  GetImportRecordsRequest,
  ImportRecords,
  RegionGroupGsCodeMappingResponse,
  ImportRecordsOperator,
  ApplicationDetailVerificationResult,
  AccessoryDiscountApplicationDetailCycleConfigRequest,
  ZipBlobResponse,
  ApplicationDetailAppealDetail,
  AppealBlobUrl,
  ApplicationDetailStatisticsCountReportResponse,
  VerificationResultsRequest,
  AccessoryDiscountTotalStatisticsResponse,
} from '../accessory-discount';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { UploadFileService } from '../../_common/services/upload-file.service';
import * as _ from 'lodash';
import { ERROR_MESSAGES } from '../../_common/constants/error-messages';
import { PromptLevel } from '../../_common/constants/common';
import { InsuranceService, SelectionOption } from '../../insurance-saas/service/insurance.service';
import { InsuranceCompanyDTO } from '../../insurance-saas/model/insuranceModel';
import { getTableIndex } from '../../insurance-saas/utils/insurance';
import { CurrentUserService } from '@otr/website-common';
import { FileType, InsuranceRoles } from '../../insurance-saas/constant/insurance';
import { ReminderLaborCode } from '../../technical-and-maintenance/model/dedicatedTechnicalReminder';
import { SOURCES_TYPES } from '../feature-configuration-list/config';
import { USER_ROLE } from 'app/_common/constants/user-role.constant';
import { formatDateRangeToString, orderAlphabetically, sortByDealerNameAlphabetically } from '../utils';
import { PageResponse } from '../../_common/models/page';
import { exportOnDifferentBrowsers } from '../../_common/utils/download';
import { getErrorMessage } from '../../service-contract/coupon-sales-history/util/errorHandler';
import { ExcelTemplateType, ThirdPartyExportTypes } from '../constant';
import * as moment from 'moment';
import { HTTP_NOT_FOUND } from 'app/_common/constants/error-status';

@Injectable({
  providedIn: 'root',
})
export class AccessoryDiscountService {
  constructor(
    private insuranceAccidentApi: InsuranceAccidentApi,
    private uploadFileService: UploadFileService,
    private insuranceService: InsuranceService,
    private currentUserService: CurrentUserService,
  ) {
  }

  get isThirdParty() {
    return _.get(this.currentUserService.getCurrentUser(), 'roleCode') === USER_ROLE.OEM_ACCIDENT_3RD_PARTY.value;
  }

  get dealerId() {
    const [, queryParams] = window.location.href.split('#')[1].split('?');
    const params = new URLSearchParams(queryParams);
    return params.get('dealerId');
  }

  getAccessoryDiscountRecords(currentPage, pageSize, statisticalMonth): Observable<AccessoryDiscountPage<AccessoryDiscountDetail>> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getAccessoryDiscountRecordsFromThirdParty(
        currentPage - 1 < 0 ? 0 : currentPage - 1,
        pageSize,
        statisticalMonth,
        this.dealerId,
      );
    }
    return this.insuranceAccidentApi.getAccessoryDiscountRecords(currentPage - 1 < 0 ? 0 : currentPage - 1, pageSize, statisticalMonth);
  }

  getAccessoryDiscountApplications(
    currentPage,
    pageSize,
    params,
    isThirdParty,
  ): Observable<AccessoryDiscountPage<AccessoryDiscountApplication>> {
    const page = currentPage - 1 < 0 ? 0 : currentPage - 1;
    if (isThirdParty) {
      return this.insuranceAccidentApi.getThirdPartyAccessoryDiscountApplications(page, pageSize, params);
    }
    return this.insuranceAccidentApi.getAccessoryDiscountApplications(page, pageSize, params);
  }

  getAccessoryDiscountTipInfoConfigs(currentPage, pageSize, params): Observable<AccessoryDiscountPage<AccessoryDiscountApplication>> {
    return this.insuranceAccidentApi.getAccessoryDiscountTipInfoConfigs(currentPage - 1 < 0 ? 0 : currentPage - 1, pageSize, params);
  }

  getAccessoryDiscountTipInfoConfigsOperators(): Observable<any> {
    return this.insuranceAccidentApi.getAccessoryDiscountTipInfoConfigsOperators();
  }

  getAccessoryDiscountApplicationsDetails(
    currentPage,
    pageSize,
    params,
    isThirdParty,
  ): Observable<AccessoryDiscountPage<AccessoryDiscountApplicationDetails>> {
    const page = currentPage - 1 < 0 ? 0 : currentPage - 1;
    if (isThirdParty) {
      return this.insuranceAccidentApi.getThirdPartyAccessoryDiscountApplicationsDetails(page, pageSize, params);
    }
    return this.insuranceAccidentApi.getAccessoryDiscountApplicationsDetails(page, pageSize, params);
  }

  getAccessoryDiscountApplicationDetailAppealDetail(
    uuid,
  ): Observable<ApplicationDetailAppealDetail> {
    return this.insuranceAccidentApi.getAccessoryDiscountApplicationDetailAppealDetail(uuid);
  }

  getAppealFileBlobDirectUrlByAppealRecordId(appealRecordId: number): Observable<AppealBlobUrl> {
    return this.insuranceAccidentApi.getAppealFileBlobDirectUrlByAppealRecordId(appealRecordId);
  }

  getAccessoryDiscountApplicationDetailVerificationResults(
    dealerId,
    currentPage,
    pageSize,
    params,
  ): Observable<AccessoryDiscountPage<ApplicationDetailVerificationResult>> {
    const page = currentPage - 1 < 0 ? 0 : currentPage - 1;
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getThirdPartyAccessoryDiscountApplicationDetailVerificationResults(dealerId, page, pageSize, params);
    }
    return this.insuranceAccidentApi.getAccessoryDiscountApplicationDetailVerificationResults(page, pageSize, params);
  }

  getAccessoryDiscountApplicationDetailVerificationResultsStatistics(
    policyStartDate,
    policyEndDate,
    dealerId,
  ): Observable<any> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getThirdPartyAccessoryDiscountApplicationDetailVerificationResultsStatistics(
        policyStartDate,
        policyEndDate,
        dealerId,
      );
    }
    return this.insuranceAccidentApi.getAccessoryDiscountApplicationDetailVerificationResultsStatistics(
      policyStartDate,
      policyEndDate,
    );
  }

  createAccessoryDiscountApplication(createRequest: any): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.createAccessoryDiscountApplicationFromThirdParty(createRequest);
    }
    return this.insuranceAccidentApi.createAccessoryDiscountApplication(createRequest);
  }

  getAccessoryDiscountApplication(id): Observable<AccessoryDiscountApplication> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getAccessoryDiscountApplicationFromThirdParty(id);
    }
    return this.insuranceAccidentApi.getAccessoryDiscountApplication(id);
  }

  deleteAccessoryDiscountDetail(detailId, status): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.deleteAccessoryDiscountDetailFromThirdParty(detailId, status);
    }
    return this.insuranceAccidentApi.deleteAccessoryDiscountDetail(detailId, status);
  }

  deleteAccessoryDiscountDetails(accessoryDiscountApplicationId, status): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.deleteAccessoryDiscountDetailsFromThirdParty(accessoryDiscountApplicationId, status);
    }
    return this.insuranceAccidentApi.deleteAccessoryDiscountDetails(accessoryDiscountApplicationId, status);
  }

  uploadAccessoryDiscountDetailsFile(uploadFile: any, type: string, status: string, id: number): Observable<any> {
    if (this.isThirdParty) {
      return this.uploadFileService.uploadExcelFile(
        `${THIRD_PARTY}/accessory-discount-application-details/${id}/excel-import?type=${type}&status=${status}&dealerId=${this.dealerId}`,
        {
          file: uploadFile,
        },
      );
    }
    return this.uploadFileService.uploadExcelFile(
      `${ACCIDENT_BASIC_URL}/accessory-discount-application-details/${id}/excel-import?type=${type}&status=${status}`,
      {
        file: uploadFile,
      },
    );
  }

  uploadRenewalBaseDataFile(uploadFile): Observable<any> {
    return this.uploadFileService.uploadExcelFile(`/api/3rd-party/insurance-accident/accident-business-renewal-base-data/excel-import`, {
      file: uploadFile,
    });
  }

  uploadApplyInfoNyVin(uploadFile): Observable<any> {
    return this.uploadFileService.uploadExcelFile(`/api/3rd-party/insurance-accident/accessory-discount-application-details/vins`, {
      file: uploadFile,
    });
  }

  downloadExceptionFile(fileName: string, type: string) {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.downloadExceptionFileFromThirdParty(fileName, type);
    }
    return this.insuranceAccidentApi.downloadExceptionFile(fileName, type);
  }

  downloadApplyInfoByVin() {
    return this.insuranceAccidentApi.downloadApplyInfoByVin();
  }

  handleStatusNotMathError(errorResponse, confirmService, promptBarService, refresh): void {
    const ERROR_KEY = {
      NOT_FOUND: 'not_found',
      NOT_IN_CONFIG_RULE_CYCLE: 'accessory_discount_application_not_in_config_rule_cycle'
    };
    const ERROR_MAP = {
      [ERROR_KEY.NOT_FOUND]: ERROR_MESSAGES.ITEM_HAS_BEEN_DELETED,
      [ERROR_KEY.NOT_IN_CONFIG_RULE_CYCLE]: '当前数据不在提报周期内，请刷新页面。'
    };
    const errorCode = _.get(errorResponse, 'error.error_code');
    if ('accessory_discount_application_status_not_match' === errorCode) {
      confirmService
        .confirm({
          message: ERROR_MESSAGES.APPLY_ITEM_STATUS_CHANGED,
          confirmButtonText: '确认',
          shouldHideCancelButton: true,
          shouldShowCloseButton: true,
        })
        .subscribe(refresh);
    } else if (_.includes(_.values(ERROR_KEY), errorCode)) {
      promptBarService.show(ERROR_MAP[errorCode], PromptLevel.Error);
    } else {
      promptBarService.show(ERROR_MESSAGES.UPDATE_FAILED, PromptLevel.Error);
    }
  }

  updateAccessoryDiscountApplication(updateRequest, status): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.updateAccessoryDiscountApplicationFromThirdParty(updateRequest, status);
    }
    return this.insuranceAccidentApi.updateAccessoryDiscountApplication(updateRequest, status);
  }

  deleteAccessoryDiscountApplication(deleteRequest): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.deleteAccessoryDiscountApplicationFromThirdParty(deleteRequest);
    }
    return this.insuranceAccidentApi.deleteAccessoryDiscountApplication(deleteRequest);
  }

  getBusinessManagementContact(applicationId): Observable<BusinessManagementContact> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getBusinessManagementContactFromThirdParty(applicationId, this.dealerId);
    }
    return this.insuranceAccidentApi.getBusinessManagementContact(applicationId);
  }

  getBusinessManagementApplyInfoSummary(statisticalMonth): Observable<BusinessManagementContact> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getBusinessManagementApplyInfoSummaryFromThirdParty(statisticalMonth, this.dealerId);
    }
    return this.insuranceAccidentApi.getBusinessManagementApplyInfoSummary(statisticalMonth);
  }

  getConfirmedNotification(): Observable<ConfirmedNotification> {
    return this.insuranceAccidentApi.getConfirmedNotification();
  }

  setConfirmedNotification(): Observable<void> {
    return this.insuranceAccidentApi.setConfirmedNotification();
  }

  exportApplicationDetails(id): Observable<any> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.exportApplicationDetailsFromThirdParty(id, this.dealerId);
    }
    return this.insuranceAccidentApi.exportApplicationDetails(id);
  }

  getBusinessManagementSummary(applicationId): Observable<BusinessManagementSummary> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getBusinessManagementSummaryFromThirdParty(applicationId, this.dealerId);
    }
    return this.insuranceAccidentApi.getBusinessManagementSummary(applicationId);
  }

  createAccessoryDiscountApplicationDetail(params: AccessoryDiscountApplicationDetailCreateRequest): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.createAccessoryDiscountApplicationDetailFromThirdParty(this.dealerId, params);
    }
    return this.insuranceAccidentApi.createAccessoryDiscountApplicationDetail(params);
  }

  getBusinessManagementContactLatest(month): Observable<BusinessManagementContact> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getBusinessManagementContactLatestFromThirdParty(month, this.dealerId);
    }
    return this.insuranceAccidentApi.getBusinessManagementContactLatest(month);
  }

  updateBusinessManagementContact(payload): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.updateBusinessManagementContactFromThirdParty(this.dealerId, payload);
    }
    return this.insuranceAccidentApi.updateBusinessManagementContact(payload);
  }

  validateInsuranceNumber(params: InsuranceNumberValidateRequest): Observable<InsuranceNumberValidateResponse> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.validateInsuranceNumberFromThirdParty(this.dealerId, params);
    }
    return this.insuranceAccidentApi.validateInsuranceNumber(params);
  }

  exportApplicationDetailsByParams(searchParams): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.exportThirdPartyApplicationDetailsByParams(searchParams);
    }
    return this.insuranceAccidentApi.exportApplicationDetailsByParams(searchParams);
  }

  exportApplicationDetailVerificationsByParams(searchParams): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.exportThirdPartyApplicationDetailVerificationsByParams(searchParams);
    }
    return this.insuranceAccidentApi.exportApplicationDetailVerificationsByParams(searchParams);
  }

  deleteBusinessManagement(applicationId, status): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.deleteBusinessManagementFromThirdParty(applicationId, status);
    }
    return this.insuranceAccidentApi.deleteBusinessManagement(applicationId, status);
  }

  queryAccessoryDiscountApplicationDetailById(detailId: number): Observable<AccessoryDiscountApplicationDetails> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.queryAccessoryDiscountApplicationDetailByIdFromThirdParty(detailId);
    }
    return this.insuranceAccidentApi.queryAccessoryDiscountApplicationDetailById(detailId);
  }

  updateAccessoryDiscountApplicationDetailById(
    detailId: number,
    params: AccessoryDiscountApplicationDetailUpdateRequest,
  ): Observable<void> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.updateAccessoryDiscountApplicationDetailByIdFromThirdParty(detailId, this.dealerId, params);
    }
    return this.insuranceAccidentApi.updateAccessoryDiscountApplicationDetailById(detailId, params);
  }

  getInsuranceCompany(): Observable<Array<InsuranceCompanyDTO>> {
    return this.insuranceService.getInsuranceCompany();
  }

  getThirdDealers(selected = true): Observable<ReminderLaborCode[]> {
    return this.insuranceAccidentApi.getDealerName().pipe(switchMap(dealers => of(
      _.map(dealers, dealer => ({
        laborOptionCode: dealer.dealer_id,
        laborOptionDescription: `${dealer.gs_code} ${dealer.name}`,
        selected,
        name: dealer.name,
        gs_code: dealer.gs_code,
      })))));
  }

  getThirdOperationUsers(): Observable<SelectionOption[]> {
    return this.insuranceAccidentApi.getThirdOperationUsers().pipe(
      switchMap((users) =>
        of(
          _.map(users, (user) => ({
            value: user.username,
            text: user.fullName,
          })),
        ),
      ),
    );
  }

  getAccessoryDiscountRuleConfigs(pageNumber, pageSize, filter): Observable<AccessoryDiscountPage<AccessoryDiscountRuleConfigResponse>> {
    return this.insuranceAccidentApi.getAccessoryDiscountRuleConfigs(pageNumber - 1 < 0 ? 0 : pageNumber - 1, pageSize, filter).pipe(
      switchMap(({ content, totalElements }) =>
        of({
          totalElements,
          content: _.map(content, (config, index) => {
            const dealerIds = _.keys(config.dealerIdNameMap);
            const dealerNames = _.values(config.dealerIdNameMap);
            return {
              ...config,
              rowIndex: index + getTableIndex(pageNumber, pageSize),
              scope: config.scope === 'DEALERS' ? _.join(dealerNames, '，') : '全国',
              source: _.get(_.find(SOURCES_TYPES, { value: config.source }), 'text'),
              dealerIds,
            };
          }),
        }),
      ),
    );
  }

  deleteAccessoryDiscountRuleConfigs(ids: Array<number>): Observable<any> {
    return this.insuranceAccidentApi.deleteAccessoryDiscountRuleConfigs(ids);
  }

  deleteTipsInformationConfigs(ids: Array<number>): Observable<any> {
    return this.insuranceAccidentApi.deleteTipsInformationConfigs(ids);
  }

  getExportRecords(page: number, size: number, params: AccessoryDiscountApplicationExportRecordRequest): Observable<any> {
    const dataIndex = getTableIndex(page, size);
    return this.insuranceAccidentApi.getExportRecords(page - 1 < 0 ? 0 : page - 1, size, params).pipe(
      switchMap((res) =>
        of({
          ...res,
          content: res.content.map((item, index) => ({
            ...item,
            rowIndex: index + dataIndex,
          })),
        }),
      ),
      catchError(({ message }) => throwError(message)),
    );
  }

  getExportOperationUsers(isThirdParty, isApplyExportListPage): Observable<SelectionOption[]> {
    // 申请信息导出记录的三方操作人调用接口
    if (isThirdParty && isApplyExportListPage) {
      return this.getAllOperationUsers(ThirdPartyExportTypes.THIRD_PARTY_ACCESSORY_DISCOUNT_APPLICATION_DETAIL);
    }
    // 导出记录操作人和申请信息导出记录的经销商调用接口
    const roles = isThirdParty ? [InsuranceRoles.OEM_ACCIDENT_3RD_PARTY] : [InsuranceRoles.ASM, InsuranceRoles.IM];
    const dealerId = isThirdParty ? null : this.currentUserService.getDealerId();
    return this.getOperationUsers(roles, dealerId);
  }

  getOperationUsers(roles: string[], dealerId?: string): Observable<SelectionOption[]> {
    return this.insuranceAccidentApi.getOperationUsers(roles, dealerId).pipe(
      switchMap((users) =>
        of(
          users.map((user) => ({
            text: user.full_name,
            value: user.username,
          })),
        ),
      ),
    );
  }

  getAllOperationUsers(type: string): Observable<SelectionOption[]> {
    return this.insuranceAccidentApi.getAllOperationUsers(type).pipe(
      switchMap((users) =>
        of(
          users.map((user) => ({
            text: user.fullName,
            value: user.createdBy,
          })),
        ),
      ),
    );
  }

  getDealerName(): Observable<any> {
    return this.insuranceAccidentApi.getDealerName();
  }

  getDealerNameSortByDealerName(): Observable<any> {
    return this.insuranceAccidentApi.getDealerName().pipe(
      switchMap(dealerName => {
        return of(dealerName.sort(sortByDealerNameAlphabetically));
      }),
    );
  }

  uploadRichTextFile(file: any) {
    return this.uploadFileService.uploadExcelFile(`${ACCIDENT_BASIC_URL}/files/upload`, { file });
  }

  getAccessoryDiscountRuleDefaultConfig(): Observable<AccessoryDiscountRuleDefaultConfigResponse> {
    return this.insuranceAccidentApi.getAccessoryDiscountRuleDefaultConfig();
  }

  updateAccessoryDiscountRuleDefaultConfig(params: AccessoryDiscountRuleDefaultConfigRequest): Observable<void> {
    return this.insuranceAccidentApi.updateAccessoryDiscountRuleDefaultConfig(params);
  }

  createOrUpdateAccessoryDiscountRuleConfig(id, params): Observable<void> {
    if (!_.isNil(id)) {
      return this.insuranceAccidentApi.updateAccessoryDiscountRuleConfig(id, params);
    }
    return this.insuranceAccidentApi.createAccessoryDiscountRuleConfig(params);
  }

  getAccessoryDiscountRuleConfigsAvailable(): Observable<AccessoryDiscountRuleConfigsAvailableResponse> {
    return this.insuranceAccidentApi.getAccessoryDiscountRuleConfigsAvailable();
  }

  createTipInformationConfig(params: CreateOrUpdateTipInformationConfigRequest): Observable<CreateOrUpdateTipInformationConfigResponse> {
    return this.insuranceAccidentApi.createTipInformationConfig(params);
  }

  updateTipInformationConfig(
    id: number,
    params: CreateOrUpdateTipInformationConfigRequest,
  ): Observable<CreateOrUpdateTipInformationConfigResponse> {
    return this.insuranceAccidentApi.updateTipInformationConfig(id, params);
  }

  getTipsInformation(position: string): Observable<TipsInformationResponse> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getTipsInformationFromThirdParty(position);
    }
    return this.insuranceAccidentApi.getTipsInformation(position);
  }

  getAccessoryDiscountCycleConfigs(page, size, params): Observable<AccessoryDiscountPage<AccessoryDiscountCycleConfigResponse>> {
    const dataIndex = getTableIndex(page, size);
    return this.insuranceAccidentApi.getAccessoryDiscountCycleConfigs(this.getPageNumberAdapter(page), size, params).pipe(
      switchMap((res) =>
        of({
          ...res,
          content: res.content.map((item, index) => ({
            ...item,
            policyCycle: formatDateRangeToString(item.detailEffectiveStartTime, item.detailEffectiveEndTime),
            rowIndex: index + dataIndex,
          })),
        }),
      ),
    );
  }

  getPolicyCycles(): Observable<PolicyCycle[]> {
    return this.insuranceAccidentApi.getPolicyCycles();
  }

  getUploadedPolicyCycles(): Observable<PolicyCycle[]> {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getUploadedPolicyCyclesFromThirdParty();
    }
    return this.insuranceAccidentApi.getUploadedPolicyCycles();
  }

  getCycleConfigOperators(): Observable<Operator[]> {
    return this.insuranceAccidentApi.getCycleConfigOperators().pipe(
      switchMap(followUpUsers => {
        return of(followUpUsers.sort((a, b) =>
          _.get(a, 'fullName', '').localeCompare(_.get(b, 'fullName', ''))));
      }),
    );
  }

  getImportRecords(page: number, pageSize: number, params: GetImportRecordsRequest): Observable<PageResponse<ImportRecords>> {
    const dataIndex = getTableIndex(page, pageSize);
    return this.insuranceAccidentApi.getImportRecords(this.getPageNumberAdapter(page), pageSize, params).pipe(
      switchMap((res) =>
        of({
          ...res,
          content: res.content.map((item, index) => ({
            ...item,
            rowIndex: index + dataIndex,
          })),
        }),
      ),
      catchError(({ message }) => throwError(message)),
    );
  }

  getPageNumberAdapter(page: number): number {
    return page - 1 < 0 ? 0 : page - 1;
  }

  getThirdApplyInfoCheck(page: number, size: number, params: VerificationResultsRequest)
    : Observable<AccessoryDiscountTotalStatisticsResponse<ApplicationDetailStatisticsCountReportResponse>> {
    return this.insuranceAccidentApi.getThirdApplyInfoCheck(this.getPageNumberAdapter(page), size, params);
  }

  getRegionGroupGsCodeMapping(): Observable<RegionGroupGsCodeMappingResponse[]> {
    return this.insuranceAccidentApi.getRegionGroupGsCodeMapping();
  }

  getImportRecordsOperator(): Observable<ImportRecordsOperator[]> {
    return this.insuranceAccidentApi.getImportRecordsOperators();
  }

  exportCheckFile(params, fileName: string): Observable<ArrayBuffer> {
    return this.insuranceAccidentApi.exportCheckFile(params, true).pipe(
      tap((res) => exportOnDifferentBrowsers(res, FileType.xlsx as unknown as string, fileName)),
    );
  }

  downloadStatisticsReport(cycleConfigId, fileName: string): Observable<ArrayBuffer> {
    return this.insuranceAccidentApi.downloadStatisticsReport(cycleConfigId, true).pipe(
      tap((res) => exportOnDifferentBrowsers(res, FileType.xlsx as unknown as string, fileName)),
    );
  }

  downloadTemplate(fileName: string, templateType: ExcelTemplateType): Observable<ArrayBuffer> {
    return this.insuranceAccidentApi.downloadTemplate(templateType).pipe(
      tap((res) => exportOnDifferentBrowsers(res, FileType.xlsx as unknown as string, fileName)),
      catchError(() => throwError(ERROR_MESSAGES.UPDATE_FAILED))
    );
  }

  submitCheckAppeal(params): Observable<void> {
    return this.insuranceAccidentApi.submitCheckAppeal(params);
  }

  getUpdateDealerOptions(regionGroupGsCodeMapping) {
    return _.flatMap(regionGroupGsCodeMapping, (region) => {
      return _.flatMap(region.groups, group => {
        return _.flatMap(group.dealer, dealer => {
          return {
            value: { gsCode: dealer.gsCode, dealerId: dealer.dealerId, dealerName: dealer.dealerName },
            text: dealer.dealerName,
            selected: false,
          };
        });
      });
    });
  }

  getUpdateGroupsOptions(regionGroupGsCodeMapping) {
    const isEqual = (obj1, obj2) => obj1.text === obj2.text && obj1.value === obj2.value;
    return orderAlphabetically(_.uniqWith(_.flatMap(regionGroupGsCodeMapping, (region) => {
      return _.flatMap(region.groups, group => {
        return { text: group.group, value: group.group };
      });
    }), isEqual));
  }

  createCycleConfig(request: AccessoryDiscountApplicationDetailCycleConfigRequest): Observable<void> {
    return this.insuranceAccidentApi.createCycleConfig(request);
  }

  updateCycleConfig(id: number, request: AccessoryDiscountApplicationDetailCycleConfigRequest): Observable<void> {
    return this.insuranceAccidentApi.updateCycleConfig(id, request);
  }

  deleteCycleConfig(ids: string[]): Observable<void> {
    return this.insuranceAccidentApi.deleteCycleConfig(ids);
  }

  updateAppealResults(file: File, importRecordType: string, policyCircleId: number) {
    return this.uploadFileService.uploadExcelFile(
      `${ACCIDENT_BASIC_URL}/accessory-discount-application-details-motivation/import?importRecordType=${importRecordType}&policyCircleId=${policyCircleId}`,
      { file },
    );
  }

  downloadAppealFile(id, uuid, fileType): Observable<ArrayBuffer> {
    return this.insuranceAccidentApi.downloadAppealFile(id, 'INSURANCE_ACCESSORY_COMMERCIAL').pipe(
      tap(res => {
        exportOnDifferentBrowsers(res, '', `${uuid}_${moment().format('YYYYMMDDHHmmss')}.${fileType}`);
      }),
      catchError(err => {
        if (err.status === HTTP_NOT_FOUND) {
          return throwError(ERROR_MESSAGES.NO_DOWNLOAD_MATERIALS);
        }
        return throwError(ERROR_MESSAGES.UPDATE_FAILED);
      })
    );
  }

  downloadFile(id: number): Observable<ArrayBuffer> {
    return this.insuranceAccidentApi.downloadAppealFile(id, 'INSURANCE_ACCESSORY_COMMERCIAL');
  }

  batchDownloadAppeal(uuids: string[]): Observable<void> {
    return this.insuranceAccidentApi.batchDownloadAppeal(uuids);
  }

  handleAppeal(id: number, request: any): Observable<void> {
    return this.insuranceAccidentApi.handleAppeal(id, request);
  }

  downloadZipFile(params: { id: number; fileName: string }): Observable<ZipBlobResponse> {
    return this.insuranceAccidentApi.downloadZipFile(params).pipe(
      tap((res) => window.open(res.appealBlobUrl)),
      catchError((err) => throwError(getErrorMessage(err.error))),
    );
  }

  getAccessoryDiscountResult({ dealerId, policyCycleId }) {
    if (this.isThirdParty) {
      return this.insuranceAccidentApi.getAccessoryDiscountResultFromThirdParty(dealerId, policyCycleId);
    }
    return this.insuranceAccidentApi.getAccessoryDiscountResult(policyCycleId);
  }

  uploadApplyResultFile(file: File, importRecordType: string): Observable<any> {
    return this.uploadFileService.uploadExcelFile(`${INSURANCE_SALES_BASIC_URL}/import/sync?type=${importRecordType}`, { file });
  }

  uploadAccidentVehiclePartsPurchases(file: File): Observable<any> {
    return this.uploadFileService.uploadExcelFile(`${THIRD_PARTY}/accident-vehicle-parts-purchases/excel-import`, { file });
  }
}

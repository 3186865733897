import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { findGet, getOrFormat } from '../utils/common';

@Pipe({ name: 'pick' })
export class PickPipe implements PipeTransform {
  transform(object: Object, properties: string[]) {
    return _.pick(object, properties);
  }
}

interface LodashGetOption {
  path: string;
  defaultValue?: string | number;
}

@Pipe({ name: 'get' })
export class GetPipe implements PipeTransform {
  transform(object: Object, option: LodashGetOption): any {
    return _.get(object, option.path, option.defaultValue);
  }
}

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(string: string, length: number): any {
    return _.truncate(string, { length });
  }
}

@Pipe({ name: 'every' })
export class EveryPipe implements PipeTransform {
  transform(collection: any[], predicate: any): boolean {
    return _.every(collection, predicate);
  }
}

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(object: any): Array<string> {
    return _.keys(object);
  }
}

interface LodashFindOption {
  key: string;
  value: string | number;
  path?: string;
}

@Pipe({ name: 'find' })
export class FindPipe implements PipeTransform {
  transform(object: any, option: LodashFindOption): any {
    return _.find(object, [option.key, option.value]);
  }
}

@Pipe({ name: 'findAndGet' })
export class FindAndGetPipe implements PipeTransform {
  transform(object: any, option: LodashFindOption): any {
    return findGet(object, [option.key, option.value], option.path, '');
  }
}

interface LodashDefaultToOption {
  default?: string;
  format: string | Function;
}

@Pipe({ name: 'defaultTo' })
export class DefaultToPipe implements PipeTransform {
  transform(object: any, option: LodashDefaultToOption = { default: '-', format: '' }): any {
    return _.isNil(object) ? (option.default || '-') : getOrFormat(object, option.format);
  }
}

@Pipe({ name: 'isEmpty' })
export class IsEmptyPipe implements PipeTransform {
  transform(object: any): any {
    return _.isEmpty(object);
  }
}

@Pipe({ name: 'isNil' })
export class IsNilPipe implements PipeTransform {
  transform(object: any): any {
    return _.isNil(object);
  }
}

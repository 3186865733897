import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { KeyValue } from '../../../../../_common/models/common';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownListComponent {

  @Input() options: KeyValue[] = [];
  @Input() selectedValue;
  @Input() getText: Function;
  @Input() placeholder: string = '请选择';
  @Output() select = new EventEmitter();
  @Input() disabled: boolean = false;
  @Input() error: boolean = false;
  @Input() required: boolean = false;

  showing: Boolean = false;
  toggleStatus = _.debounce(() => this.showing = !this.showing, 500);

  constructor(private _eref: ElementRef) {
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this._eref.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showing = false;
    }
  }

  click(option) {
    if (!this.disabled) {
      this.select.emit(option);
      this.showing = false;
    }
  }

  get displayText() {
    return _.get(this.selectedValue, 'text') || this.placeholder;
  }

}

import { Component, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Events } from 'ag-grid';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridRenderValidator, GridValueValidator, Validate } from '../meta/grid-render-validator';
import { ERROR_MESSAGES } from '../../../../constants/error-messages';
import { DisabledListener, GridRenderDisabled } from '../meta/grid-render-disabled';

interface SelectorOption {
  value: any;
  text: string;
}

@Component({
  templateUrl: 'selector-cell.template.html',
  styleUrls: ['selector-cell.style.scss'],
})
export class SelectorCellRenderComponent implements ICellRendererAngularComp, GridRenderValidator, GridRenderDisabled {

  originValue: any;
  errorMessage: string;
  value: any;
  selector;

  params;
  options: Array<SelectorOption> = [];
  selectedOption: SelectorOption;
  selecting: boolean = false;
  @ViewChild('title') titleElement;

  disabled: boolean = false;

  refresh(params: any): boolean {
    return false;
  }

  getValidators(): Array<GridValueValidator> {
    return [];
  }

  getData() {
    return this.params.data;
  }

  getIsDisabledMethod() {
    return data => this.selector && this.selector.isDisabled && this.selector.isDisabled(data);
  }

  get api() {
    return this.params.api;
  }

  @DisabledListener
  agInit(params): void {
    this.params = params;
    this.value = [null, undefined].indexOf(params.value) !== -1 ? '' : params.value;
    this.options = _.filter(params.colDef.selector.options, option => !option.hidden);
    this.selectedOption = this.options.find(op => op.value === this.value);
    this.originValue = this.value;
    this.selector = this.params.colDef.selector;
  }

  @Validate()
  change(value, selectedOption) {
    const res = this.onChange(value, this.params.data);
    if (res instanceof Observable) {
      res.subscribe(() => {
        this.dispatchChangeEvent(value, this.value);
        this.value = value;
        this.originValue = value;
        this.selectedOption = selectedOption;
      }, error => {
        this.value = this.originValue;
        this.errorMessage = this.getErrorMessage(error);
      });
    }
  }

  optionValueChange(nextValue) {
    const nextSelectedOption = this.options.find(op => op.value === nextValue);
    this.change(nextValue, nextSelectedOption);
  }

  getErrorMessage(error) {
    switch (error.status) {
      case 0:
        return error.message;
      case 406:
        return ERROR_MESSAGES.VEHICLE_BEEN_CONFIRMED;
      default:
        return '保存失败';
    }
  }

  toggle() {
    if (!this.disabled) {
      this.errorMessage = null;
      this.selecting = !this.selecting;
    }
  }

  dispatchChangeEvent(newValue, oldValue) {
    const context = _.pick(this.params, ['api', 'node', 'context', 'data', 'colDef']);
    _.set(context.data, context.colDef.field, newValue);
    this.params.api.dispatchEvent(Events.EVENT_CELL_VALUE_CHANGED, Object.assign({ newValue, oldValue }, context));
  }

  private get onChange() {
    return this.params.colDef.selector.onChange || _.noop;
  }

  hasError(errorMessage): void {
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dummy-rows',
  templateUrl: './dummy-rows.template.html',
  styleUrls: ['./dummy-rows.style.scss'],
})
export class DummyRowsComponent {
  @Input() rowHeight: number;
  @Input() rowCount: number;
  @Input() columnCount: number;
}

export const ANALYSIS_REPORT_CENTER_SUB_TAB_ROUTER = {
  EFFICIENCY_ANALYSIS_REPORT: 'report/report',
  THROUGHPUT_ANALYSIS_REPORT: 'report/throughput',
  ADDON_DETAIL_REPORT: '/workshop/report/process-efficiency-analysis/addon-detail',
  ADDON_SUMMARY_REPORT: '/workshop/report/process-efficiency-analysis/add-on-summary-report',
  ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT: '/workshop/report/process-efficiency-analysis/lost-sales-detail',
  ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT: '/workshop/report/process-efficiency-analysis/lost-sales-win-back',
  PARTS_MAINTENANCE_RATE_REPORT: '/workshop/report/process-efficiency-analysis/parts_maintenance_rate_report',
};
const REPORT_PRIVILEGE = {
  ROLE_WORKSHOP_DATA_ANALYSIS_PROCESS_EFFICIENCY: 'ROLE_WORKSHOP_DATA_ANALYSIS_PROCESS_EFFICIENCY',
  ROLE_WORKSHOP_DATA_ANALYSIS_TP: 'ROLE_WORKSHOP_DATA_ANALYSIS_TP',
  ROLE_WORKSHOP_ADDON_DETAIL_REPORT: 'ROLE_WORKSHOP_ADDON_DETAIL_REPORT',
  ROLE_WORKSHOP_ADDON_SUMMARY_REPORT: 'ROLE_WORKSHOP_ADDON_SUMMARY_REPORT',
  ROLE_WORKSHOP_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT: 'ROLE_WORKSHOP_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT',
  ROLE_WORKSHOP_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT: 'ROLE_WORKSHOP_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT',
  ROLE_PARTS_MAINTENANCE_RATE_REPORT: 'ROLE_PARTS_MAINTENANCE_RATE_REPORT',
};

const OPEN_TYPE = {
  BLANK: '_blank',
};

export const ANALYSIS_REPORT_LIST = {
  WORKSHOP_EFFICIENCY_REPORTING_OVERVIEW: {
    title: '车间效率分析',
    list: [
      {
        sequence: 1,
        name: '流程效率分析',
        openType: OPEN_TYPE.BLANK,
        link: ANALYSIS_REPORT_CENTER_SUB_TAB_ROUTER.EFFICIENCY_ANALYSIS_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_WORKSHOP_DATA_ANALYSIS_PROCESS_EFFICIENCY,
      },
      {
        sequence: 2,
        name: '增项明细报表',
        link: ANALYSIS_REPORT_CENTER_SUB_TAB_ROUTER.ADDON_DETAIL_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_WORKSHOP_ADDON_DETAIL_REPORT,
      },
      {
        sequence: 3,
        name: '增项概览报表',
        link: ANALYSIS_REPORT_CENTER_SUB_TAB_ROUTER.ADDON_SUMMARY_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_WORKSHOP_ADDON_SUMMARY_REPORT,
      },
      {
        sequence: 4,
        name: '失销明细报表（预检、增项）',
        link: ANALYSIS_REPORT_CENTER_SUB_TAB_ROUTER.ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_WORKSHOP_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_DETAIL_REPORT,
      },
      {
        sequence: 5,
        name: '失销赢回报表（预检、增项）',
        link: ANALYSIS_REPORT_CENTER_SUB_TAB_ROUTER.ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_WORKSHOP_ADDON_AND_INSPECTION_LOST_SALES_WIN_BACK_SUMMARY_REPORT,
      },
      {
        sequence: 6,
        name: '机修保持率报表',
        link: ANALYSIS_REPORT_CENTER_SUB_TAB_ROUTER.PARTS_MAINTENANCE_RATE_REPORT,
        privilege: REPORT_PRIVILEGE.ROLE_PARTS_MAINTENANCE_RATE_REPORT,
      },
    ],
  },
};

import * as _ from 'lodash';
import {
  getClientStatus,
  getEmergencyDescs,
  getEmergencyNames,
  getLabels,
  getNotes,
  getDigitalServiceName,
  getPweiDueDate,
  getPweiItemCode,
  getPweiLeftDate,
  getPweiOrDigitalServiceDesc,
  getRcCampaign,
  getRcCampId,
  getRcDesc,
  getRcEndDate,
  getRcId,
  getRcStartDate,
  getRecallFailedResult,
  getRecommendProducts,
  getReservationComments,
  getReservationProducts,
  joinBackupProducts,
  offerShouldShowIcon,
  offerSplitText, getParts, partsSplitText, getRecommendProductsLostSale,
} from './detail/table-config.utils';
import { getByteLength } from '../../../_common/utils/common';
import {
  canShowNotDMPLabel,
  isNotEmergencyOfferLeadType,
  isNotDigitalServiceOfferLeadType,
  isNotPweiOfferLeadType,
  isNotRecallOfferLeadType,
  notShowEmergencyMessages, isLostSaleType,
} from './as-lead';

const FONT_SIZE = 12;
const BYTE_LENGTH = FONT_SIZE / 2;
const LABEL_LINES = 1.2;
const PADDING = 16;
const HEIGHT_PER_LINE = 18;
const DEFAULT_HEIGHT = PADDING + HEIGHT_PER_LINE;

const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');
context.font = 'normal 12px "微软雅黑", "Microsoft Yahei", "Hiragino Sans GB", Helvetica, Arial, sans-serif';

export const getTextWidth = text => {
  const metrics = context.measureText(text);
  return metrics.width;
};

export const noMoreThan = (value, leastValue, labelValue = 0) => value >= leastValue ? leastValue + labelValue : value;

export const getRecommendProductsLines = (widthInfos, params: any) => {
  if (isLostSaleType(params.data.leadType)) {
    return calcArrayLinesWithWidthInfo(widthInfos, 'recommendProduct_1', [partsSplitText(getRecommendProductsLostSale(params))]);
  }
  return calcArrayLinesWithWidthInfo(widthInfos, 'recommendProduct', getRecommendProducts(params)) +
  (isNotEmergencyOfferLeadType(params.data.leadType) && canShowNotDMPLabel(params.data) ? LABEL_LINES : 0);
};

// export const getEmergencyMessagesLines = (widthInfos, params: any) => notShowEmergencyMessages(params.data) ? 0 :
//   calcArrayLinesWithWidthInfo(widthInfos, 'name', getEmergencyNames(params)) +
//   (canShowNotDMPLabel(params.data) ? LABEL_LINES : 0);
export const getEmergencyMessagesLines = (widthInfos, params: any) => notShowEmergencyMessages(params.data) ? 0 :
  calcArrayLinesWithWidthInfo(widthInfos, 'name', getEmergencyNames(params)) + LABEL_LINES;

export const getEmergencyDescsLines = (widthInfos, params: any) => notShowEmergencyMessages(params.data) ? 0 :
  calcArrayLinesWithWidthInfo(widthInfos, 'description', getEmergencyDescs(params));

export const calcLinesWithString = (lineWidth, str = '') => {
  let lines = 0;
  if (_.isEmpty(str)) {
    return lines;
  }
  lines += 1;
  let startIndex = 0;
  const length = str.length;
  _.chain(length).range().forEach(charIndex => {
    if (lineWidth < getTextWidth(str.slice(startIndex, charIndex + 1))) {
      lines += 1;
      startIndex = charIndex;
    }
  }).value();
  return lines;
};

export const calcLinesWithStringByCanvas = (widthInfos, text, colName) => {
  const width = getWidthFromInfos(widthInfos, colName, PADDING);
  return width <= 0 ? 0 : calcLinesWithString(width, text);
};

export const calcLinesWithStringAndLabel = (widthInfos, text, colName) => {
  const formattedText = offerShouldShowIcon(text) ? `${offerSplitText(text)}省略` : text;
  return calcLinesWithStringByCanvas(widthInfos, formattedText, colName);
};

export const calcRecallLines = (lead, widthInfos) => {
  if (isNotRecallOfferLeadType(lead.leadType)) {
    return 0;
  }
  const rcCampIdLines = calcLinesWithStringByCanvas(widthInfos, getRcCampId({ data: lead }), 'rcCampId');
  const rcIdLines = calcLinesWithStringByCanvas(widthInfos, getRcId({ data: lead }), 'rcId');
  const rcCampaignLines = calcLinesWithStringByCanvas(widthInfos, getRcCampaign({ data: lead }), 'rcCampaign');
  const rcDescLines = calcLinesWithStringByCanvas(widthInfos, getRcDesc({ data: lead }), 'rcDesc');
  const rcStartDateLines = calcLinesWithStringByCanvas(widthInfos, getRcStartDate({ data: lead }), 'rcStartDate');
  const rcEndDateLines = calcLinesWithStringByCanvas(widthInfos, getRcEndDate({ data: lead }), 'rcEndDate');
  const failedResultLines = calcLinesWithStringByCanvas(widthInfos, getRecallFailedResult({ data: lead }), 'failedResult');
  return _.max([rcCampIdLines, rcIdLines, rcCampaignLines, rcDescLines, rcStartDateLines, rcEndDateLines, failedResultLines]);
};

export const calcPweiLines = (lead, widthInfos) => {
  if (isNotPweiOfferLeadType(lead.leadType)) {
    return 0;
  }
  const descLines = calcLinesWithStringByCanvas(widthInfos, getPweiOrDigitalServiceDesc({ data: lead }), 'pweiOrDigitalServiceDescs');
  const dueDateLines = calcLinesWithStringByCanvas(widthInfos, getPweiDueDate({ data: lead }), 'dueDate');
  const leftDateLines = calcLinesWithStringByCanvas(widthInfos, getPweiLeftDate({ data: lead }), 'leftDate');
  const pweiItemCodeLines = calcLinesWithStringByCanvas(widthInfos, getPweiItemCode({ data: lead }), 'itemCode');
  return _.max([descLines, dueDateLines, leftDateLines, pweiItemCodeLines]);
};

export const calcDigitalServiceLines = (lead, widthInfos) => {
  if (isNotDigitalServiceOfferLeadType(lead.leadType)) {
    return 0;
  }
  const nameLines = calcLinesWithStringByCanvas(widthInfos, getDigitalServiceName({ data: lead }), 'digitalServiceName');
  const descLines = calcLinesWithStringByCanvas(widthInfos, getPweiOrDigitalServiceDesc({ data: lead }), 'pweiOrDigitalServiceDescs');
  return _.max([nameLines, descLines]);
};

export const calcCommonLines = (lead, widthInfos) => {
  const recommendProductLines = getRecommendProductsLines(widthInfos, { data: lead });
  const emergencyMessageLines = getEmergencyMessagesLines(widthInfos, { data: lead });
  const emergencyDecsLines = getEmergencyDescsLines(widthInfos, { data: lead });
  const notesLines = calcLinesWithStringAndLabel(widthInfos, getNotes({ data: lead }), 'notes');
  const backupProductLines = calcLinesWithStringAndLabel(widthInfos, joinBackupProducts({ data: lead }), 'backupProduct');
  const clientPushStatusLines = calcLinesWithStringAndLabel(widthInfos, getClientStatus({ data: lead }), 'clientPushStatus');
  const mmpMsgCodeLines = calcLinesWithWidthInfo(getByteLength(_.get(lead, 'mmpMsgCode', '')), widthInfos, 'mmpMsgCode');
  return _.max([recommendProductLines, emergencyMessageLines, emergencyDecsLines, notesLines, backupProductLines,
    clientPushStatusLines, mmpMsgCodeLines]);
};

export const calcOfferTableRowHeight = (lead, widthInfos) => {
  const commonOfferLines = calcCommonLines(lead, widthInfos);
  const digitalServiceOfferLines = calcDigitalServiceLines(lead, widthInfos);
  const recallOfferLines = calcRecallLines(lead, widthInfos);
  const pweiOfferLines = calcPweiLines(lead, widthInfos);
  const lostSalesOfferLines = getPartsLines(widthInfos, { data: lead });
  const maxLines = _.max([commonOfferLines, digitalServiceOfferLines, recallOfferLines, pweiOfferLines, lostSalesOfferLines]);
  return calcRowHeight(maxLines);
};

export const getPartsLines = (widthInfos, params: any) => {
  if (!isLostSaleType(params.data.leadType)) {
    return 0;
  }
  return calcArrayLinesWithWidthInfo(widthInfos, 'parts', [partsSplitText(getParts(params))]);
};

export const getWidthFromInfos = (widthInfos, colName, padding) =>
  _.get(_.find(widthInfos, { colId: colName }), 'width', 0) - padding;

export const calcArrayLinesWithWidthInfo = (widthInfos, colName: string, array) => {
  const width = getWidthFromInfos(widthInfos, colName, PADDING);
  return width <= 0 ? 0 : calcArrayLines(width, array);
};

export const calcArrayLines = (width, array) => {
  let lines = 0;
  _.forEach(array, item => {
    lines += calcLinesWithString(width, item);
  });
  return lines;
};

export const calcRowHeight = (lines: number) => lines > 0 ? PADDING + HEIGHT_PER_LINE * lines : DEFAULT_HEIGHT;

export const calcReservationTableRowHeight = () => PADDING + HEIGHT_PER_LINE * 2;

export const calcLinesWithWidthInfo = (size: number, widthInfos, colName: string) => {
  const width = getWidthFromInfos(widthInfos, colName, PADDING);
  return width <= 0 ? 0 : calcLines(size, width);
};

export const calcLines = (byteSize, width) => _.ceil((byteSize * BYTE_LENGTH) / width);

export const isStrategyOver2Lines = value => calcLinesWithString(380, value) > 2;

export const isOverLines = (value, maxRow = 2, rowWidth = 380) => context.measureText(value).width > (maxRow * rowWidth);

export const getDisplayText = (values, maxRow = 2, width = 380, iconWidth = 20) => {
  let displayedText = '';
  Array.from(values).some((value, index, array) => {
    const tempValue = _.slice(array, 0, index + 1).join('');
    if (context.measureText(tempValue).width + iconWidth > maxRow * width) {
      displayedText = values.slice(0, index);
      return true;
    }
  });
  return displayedText;
};

export const calculateOptionsRowHeight = (options: Array<string>, width) =>
  _.chain(options).map(option => 18 * calcLinesWithString(width, option) + 12).sum().value();

<div class="filter-box">
  <label class="select-label" style="padding: 0;">
    <input type="radio"
           value="NO_VALUE"
           class="radio-button"
           name="search-or-select"
           [formControl]="inputType"
           (click)="onNoValueClick()"
    />未填写
  </label>
  <div class="calendar-range">
    <input
      value="NORMAL"
      type="radio"
      class="radio-button"
      name="search-or-select"
      [formControl]="inputType"
    />
    <span>
      <p-calendar
        [inputStyle]="{width: '118px', height: '24px'}"
        [formControl]="startDateControl"
        [locale]="chineseCalendarConfig"
        dataType="string"
        dateFormat="yy-mm-dd"
        placeholder="YYYY-MM-DD"
        (onBlur)="onCalendarBlur()"
        [maxDate]="endDateControl.value | dateObject"
        (onMonthChange)="stopPropagation()"
      >
      </p-calendar>
    </span>
    <span class="range-line calendar-line"></span>
    <span>
      <p-calendar
        [inputStyle]="{width: '118px', height: '24px'}"
        [formControl]="endDateControl"
        [locale]="chineseCalendarConfig"
        dataType="string"
        dateFormat="yy-mm-dd"
        placeholder="YYYY-MM-DD"
        (onBlur)="onCalendarBlur()"
        [minDate]="startDateControl.value | dateObject"
        (onMonthChange)="stopPropagation()"
      >
      </p-calendar>
    </span>
  </div>
  <span class="btn-cancel" (click)="onCancel()">
    取消
  </span>
  <button
    class="btn-save btn-calendar"
    type="submit"
    (click)="onSubmit()"
    [disabled]="!isFilterValid()"
  >
    确定
  </button>
</div>

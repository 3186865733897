import { Component } from '@angular/core';

@Component({
  template: `
    <span [attr.data-hidden]="isDisable">
      <i [class]="type" aria-hidden="true"></i>
    </span>
  `,
})
export class IconRenderComponent {
  type: string;
  isDisable: boolean;

  agInit({ value }): void {
    this.type = value.type;
    this.isDisable = value.isDisable;
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {DealerIntegrationToggleService, FeatureToggleService} from '@otr/website-common';

@Injectable()
export class DmAuthGuard implements CanActivate {
  constructor(
    private featureToggleService: FeatureToggleService,
    private dealerIntegrationToggleService: DealerIntegrationToggleService,
    private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const isServiceContractDMToggleEnabled = this.dealerIntegrationToggleService.isIntegrationToggleEnabled('ICON_SERVICE_CONTRACT_DM');
    if (isServiceContractDMToggleEnabled) {
      this.router.navigate(['system-config/after-sales/service-contract/bmbs/extended']);
    } else {
      this.router.navigate(['system-config/after-sales/service-contract/bmbs/maintenance']);
    }
    return true;
  }
}

<div class='service-sheet-action-cell'>
  <div class='icon' (click)='onPreview()'>
    <img src='/assets/aftersales/eye.png'>
  </div>
  <div class='icon' (click)='onEdit()'>
    <img src='/assets/workshop/complete-edit.svg'>
  </div>
  <div class='icon' (click)='onDelete()'>
    <img src='/assets/common/delete-blue@3x.png'>
  </div>
</div>

<div class="filter-box">
  <div class="calendar-range">
    <span>
      <p-calendar
        [(ngModel)]="filterFrom"
        [inputStyle]="{width: '118px', height: '24px'}"
        [locale]="chineseCalendarConfig"
        [dataType]="dataType"
        dateFormat="yy-mm-dd"
        placeholder="YYYY-MM-DD"
        (onSelect)="debouncedChange()"
        (onBlur)="debouncedChange()"
        [maxDate]="filterTo | dateObject"
        (onMonthChange)="stopPropagation()"
      >
      </p-calendar>
    </span>
    <span class="range-line"></span>
    <span>
      <p-calendar
        [(ngModel)]="filterTo"
        [inputStyle]="{width: '118px', height: '24px'}"
        [locale]="chineseCalendarConfig"
        [dataType]="dataType"
        dateFormat="yy-mm-dd"
        placeholder="YYYY-MM-DD"
        (onSelect)="debouncedChange()"
        (onBlur)="debouncedChange()"
        [minDate]="filterFrom | dateObject"
        (onMonthChange)="stopPropagation()"
      >
      </p-calendar>
    </span>
  </div>
</div>

import { Injectable } from '@angular/core';
import { TestDriveVehicleApi } from '../api/test-drive-vehicle';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { VehicleApi } from '../api/vehicle';
import { SpinnerService } from '@otr/website-common';
import { GET } from 'rebirth-http';

const optionFilter = ({ optionCode, optionDescription }) => _.isEmpty(optionCode) && _.isEmpty(optionDescription);
const optionSeparator = '/^/';

@Injectable()
export class TestDriveVehicleService {

  constructor(private testDriveVehicleApi: TestDriveVehicleApi,
              private vehicleApi: VehicleApi,
              private spinnerService: SpinnerService) {
  }

  getTestDriveVehicles() {
    return this.testDriveVehicleApi.getTestDriveVehicles();
  }
  getModelDTestDriveVehicles(filter, pageNumber) {
    const searchCriteria = _.isNil(filter) ? null : JSON.stringify((filter));
    return this.testDriveVehicleApi.getModelDTestDriveVehicles(searchCriteria, pageNumber);
  }

  getServingTestDriveVehicles() {
    return this.testDriveVehicleApi.getServingTestDriveVehicles();
  }

  getThirdPartyVehicleStatus(): Observable<boolean> {
    return this.testDriveVehicleApi.getThirdPartyVehicleStatus();
  }

  findVehicleForTestDrive(commissionNo: string): Observable<any> {
    return this.vehicleApi.findVehicleForTestDrive(commissionNo);
  }

  patchTestDriveVehicle(vehicleDetail: any, patch: any): Observable<any> {
    return this.testDriveVehicleApi.updateTestDriveVehicle({
      ...vehicleDetail,
      ...patch,
    }).pipe((this.spinnerService.loading()));
  }

  updateModelDTestDriveVehicle(vehicleDetail: any, vehicleId: any): Observable<any> {
    return this.testDriveVehicleApi.updateModelDTestDriveVehicle(vehicleId, vehicleDetail)
      .pipe((this.spinnerService.loading()));
  }

  createOrUpdateTestDriveVehicle(vehicleDetail: any,
                                 vehicleForm: any,
                                 testDriveForm: any,
                                 commissionNo,
                                 forbiddenDates: Array<Date>,
                                 isStockVehicle: boolean): Observable<any> {
    const vehicleBody = {
      ...this.getVehicleDetailRequestBodyForCreate(vehicleDetail, vehicleForm, commissionNo, isStockVehicle),
      ...testDriveForm,
      forbiddenDates: _.uniq(forbiddenDates),
    };

    const vehicleBodyWithSplitOptions = this.splitOptions(vehicleBody);

    return _.has(vehicleDetail, 'id') ?
      this.testDriveVehicleApi.updateTestDriveVehicle(vehicleBodyWithSplitOptions) :
      this.testDriveVehicleApi.createTestDriveVehicle(vehicleBodyWithSplitOptions);
  }

  private splitOptions(vehicleBody) {
    const joinedOptionProps = [
      'standardFactoryOptionsCode', 'standardFactoryOptionsDescription',
      'optionalFactoryOptionsCode', 'optionalFactoryOptionsDescription'];
    const factoryOptionDetail = _.chain(vehicleBody)
      .pick(joinedOptionProps)
      .map((value, key) => ({ [key]: _.split(value, optionSeparator) }))
      .reduce((r, v) => ({ ...r, ...v }))
      .value();
    return {
      ..._.omit(vehicleBody, joinedOptionProps),
      standardFactoryOptions: _.chain(factoryOptionDetail)
        .get('standardFactoryOptionsCode', [])
        .map((code, idx) => ({
          code,
          description: _.get(factoryOptionDetail, `standardFactoryOptionsDescription[${idx}]`, ''),
        }))
        .reject(option => _.isEmpty(option.code))
        .value(),
      optionalFactoryOptions: _.chain(factoryOptionDetail)
        .get('optionalFactoryOptionsCode', [])
        .map((code, idx) => ({
          code,
          description: _.get(factoryOptionDetail, `optionalFactoryOptionsDescription[${idx}]`, ''),
        }))
        .reject(option => _.isEmpty(option.code))
        .value(),
    };
  }

  private getVehicleDetailRequestBodyForCreate(vehicleDetail, vehicleForm, commissionNo, isStockVehicle) {
    if (isStockVehicle) {
      return { isStockVehicle: true, ...vehicleDetail };
    }
    const standardOptions = _.reject(vehicleForm.standardOptions, optionFilter);
    const optionalOptions = _.reject(vehicleForm.optionalOptions, optionFilter);
    return {
      id: _.get(vehicleDetail, 'id', null),
      ..._.omit(vehicleForm, ['brand', 'class', 'model', 'standardOptions', 'optionalOptions']),
      commissionNo,
      brandDescriptionZh: vehicleForm.brand.description_zh,
      brandDescriptionEn: vehicleForm.brand.description_en,
      classDescriptionZh: vehicleForm.class.description_zh,
      classDescriptionEn: vehicleForm.class.description_en,
      modelDescriptionZh: vehicleForm.model.description_zh,
      modelDescriptionEn: vehicleForm.model.description_en,
      standardFactoryOptionsCode: _.map(standardOptions, 'optionCode').join(optionSeparator),
      standardFactoryOptionsDescription: _.map(standardOptions, 'optionDescription').join(optionSeparator),
      optionalFactoryOptionsCode: _.map(optionalOptions, 'optionCode').join(optionSeparator),
      optionalFactoryOptionsDescription: _.map(optionalOptions, 'optionDescription').join(optionSeparator),
      isStockVehicle: false,
    };
  }

  toggleThirdPartyVehicleStatus(thirdPartyVehicleStatus: boolean) {
    return this.testDriveVehicleApi.toggleThirdPartyVehicleStatus(thirdPartyVehicleStatus);
  }

  deleteVehicle(vehicle: any): Observable<any> {
    return this.testDriveVehicleApi.deleteVehicle(vehicle.id).pipe(this.spinnerService.loading());
  }

  getDealerInfo(): Observable<any> {
    return this.testDriveVehicleApi.getDealerInfo();
  }

  getDigitalSalesCampaignList(vehicle: any): Observable<any> {
    return this.testDriveVehicleApi.getDigitalSalesCampaignList(vehicle);
  }

  exportCampaignDataReport(vehicle: any): Observable<any> {
    return this.testDriveVehicleApi.exportCampaignDataReport(vehicle);
  }

  deleteTestDrive(vehicle: any): Observable<any> {
    return this.testDriveVehicleApi.deleteTestDrive(vehicle.id);
  }

  deleteModelDVehicle(vehicle: any): Observable<any> {
    return this.testDriveVehicleApi.deleteModelDVehicle(vehicle.id).pipe(this.spinnerService.loading());
  }

  getEvCarDetail(id: any): Observable<any> {
    return this.vehicleApi.getEvCarDetail(id);
  }

  fetchEvCarFieldConfig(): Observable<any> {
    return this.vehicleApi.fetchEvCarFieldConfig();
  }

  fetchRegionsData(): Observable<any> {
    return this.vehicleApi.fetchRegionsData();
  }

  searchCompanyInvoices(dealer_id, name): Observable<any> {
    return this.vehicleApi.searchCompanyInvoices(dealer_id, name);
  }

  saveEvCarDetail(id, params): Observable<any> {
    return this.vehicleApi.saveEvCarDetail(id, params);
  }
}

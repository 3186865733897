import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationError, NavigationExtras, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import {
  findMatchingTabs,
  findTab,
  findTabIndex,
  getParams,
  getPath,
  isTabGroupPC,
  isTabGroupVAN,
  TabServiceTabType,
} from 'app/_layout/tab-for-parts-purchase/tab-utils';
import { filter, take } from 'rxjs/operators';
import {
  getOriginVehicleBusinessType,
  getVehicleBusinessType,
  GlobalEvent,
  GlobalEventService,
  RouteCacheService, setOriginVehicleBusinessType,
  VehicleBusinessTypeService,
} from '@otr/website-common';
import { TabConfigService, TabTypes } from '../../_common/constants/tab-config';
import { TabModule } from '@otr/website-common/lib/_layout/tab/tab.component';

export type TabGroup = Record<TabTypes, TabServiceTabType[]>;
export type TabGroupType = 'PC'|'VAN'|'ELSE'; //

@Injectable()
export class TabForPartsPurchaseService {
  private tabGroupVAN: TabGroup;
  private tabGroupPC: TabGroup;
  private tabGroupELSE: TabGroup;

  public get tabIndex(): TabGroup {
    if (isTabGroupVAN()) {
      return this.tabGroupVAN;
    }
    if (isTabGroupPC()) {
      return this.tabGroupPC;
    }
    return this.tabGroupELSE;
  }

  public set tabIndex(val: TabGroup) {
    if (isTabGroupVAN()) {
      this.tabGroupVAN = val;
    }
    if (isTabGroupPC()) {
      this.tabGroupPC = val;
    }
    this.tabGroupELSE = val;
  }

  public get originTabIndex(): TabModule {
    const originVehicleBusinessType = getOriginVehicleBusinessType();
    if (!originVehicleBusinessType) {
      setOriginVehicleBusinessType(getVehicleBusinessType());
    }
    if (getOriginVehicleBusinessType() === 'VAN') {
      setOriginVehicleBusinessType(getVehicleBusinessType());
      return this.tabGroupVAN;
    }
    setOriginVehicleBusinessType(getVehicleBusinessType());
    return this.tabGroupPC;
  }

  refreshTabs: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isCloseCalled: boolean = false;
  protected previousTab: TabServiceTabType = null;
  // tslint:disable-next-line:variable-name
  protected _useExactUrlMatch: boolean = false;
  public get useExactUrlMatch(): boolean {
    return this._useExactUrlMatch;
  }

  public set useExactUrlMatch(exactMatch: boolean) {
    this._useExactUrlMatch = !!exactMatch;
  }

  // tslint:disable-next-line:variable-name
  protected _tabStyleCSS: { [key: string]: string } = {};
  public get tabStyleCSS(): { [key: string]: string } {
    return this._tabStyleCSS;
  }

  public set tabStyleCSS(style: { [key: string]: string }) {
    this._tabStyleCSS = style;
  }

  private initTabGroup(): void {
    this.tabGroupVAN = this.tabConfigService.getInitTabIndex();
    this.tabGroupPC = this.tabConfigService.getInitTabIndex();
    this.tabGroupELSE = this.tabConfigService.getInitTabIndex();
  }

  constructor(private router: Router,
              private routeCacheService: RouteCacheService,
              private globalEventService: GlobalEventService,
              private vehicleBusinessTypeService: VehicleBusinessTypeService,
              private tabConfigService: TabConfigService) {
    this.initTabGroup();
    globalEventService.register(GlobalEvent.NAVIGATE_TO_HOME_PAGE, () => {
      this.initializeTab();
    });

    globalEventService.register(GlobalEvent.LOGOUT, () => {
      this.initializeTab();
    });

    // Fix required for tests as the real router is mocked and not all mocks provide events
    ('events' in this.router ? this.router.events : EMPTY)
      .pipe(
        filter(event => event instanceof NavigationError),
      )
      .subscribe((event: NavigationError) => {
        const tabs: TabServiceTabType[] = this.getTabs() || [];
        const failedTabIndex: number = findTabIndex(tabs, event.url);

        if (-1 < failedTabIndex) {
          this.close(tabs, failedTabIndex, (): void => {
          });
        }
      });
  }

  get path(): string {
    return getPath(this.router.url);
  }

  initializeTab(): void {
    this.initTabGroup();
    this.routeCacheService.cleanAll();
  }

  getTabs(): TabServiceTabType[] {
    return _.chain(this.tabIndex)
      .values()
      .find((tabs: TabServiceTabType[]) => _.some(tabs, { href: this.path }))
      .value();
  }

  addTab(type, title: string, url: string | string[], isCloseable: boolean = true): TabServiceTabType {
    const href: string = _.isArray(url) ? `/${_.join(url, '/')}` : (!!url.match(/^\//) ? url : `/${url}`);
    const tab = { title, isCloseable, href: getPath(href), params: getParams(href), originTab: this.getCurrentTab() } as TabServiceTabType;

    this.tabIndex[type].push(tab);

    return tab;
  }

  findTab(href: string | string[]): TabServiceTabType {
    const url: string = _.isString(href) && href.length > 0 ?
      href
      :
      (
        _.isArray(href) && href.length > 0 ? `/${href.join('/')}`
          :
          ''
      );
    return 0 < url.length ? findTab(this.getTabs() || [], url) : undefined;
  }

  activateTab(): void {
    this.previousTab = this.currentTab;
  }

  closeCurrentTab(callback = _.noop): void {
    const tabs: TabServiceTabType[] = this.getTabs() || [];
    const index: number = findTabIndex(tabs, this.router.url);

    this.close(tabs, index, callback);
  }

  closeTab(type, index: number, callback = _.noop): void {
    const tabs: TabServiceTabType[] = this.tabIndex[type];

    this.close(tabs, index, callback);
  }

  /**
   * Close tabs starting with either the URL of the current tab or the given href
   * The exact matching tab isn't closed as a tab configured with "tabCloseMatching: true" would be closed as soon as it is opened
   *
   * @param {string} href
   */
  closeMatchingTabs(href?: string | string[]): void {
    const url: string = _.isString(href) && href.length > 0 ?
      href
      :
      (
        _.isArray(href) && href.length > 0 ? `/${href.join('/')}`
          :
          this.currentTab.href
      );

    let tabsClosed: boolean = false;
    const tabs = this.getTabs() || [];
    findMatchingTabs(tabs, url).forEach((tab: TabServiceTabType): void => {
      if (this.currentTab.href !== tab.href) {
        tabsClosed = true;
        const index = findTabIndex(tabs, tab.href);

        this.close(tabs, index, _.noop);
      }
    });

    // Re-navigate to the current tab as by closing others the current tab isn't active anymore
    if (tabsClosed) {
      setTimeout((): void => {
        this.router.navigateByUrl(this.currentTab.href).then(
          (): void => {
          },
          (): void => {
            const index: number = tabs.length;
            const nextTab: TabServiceTabType = tabs[0];

            this.router.navigateByUrl(nextTab.href, !!nextTab.params ? { queryParams: nextTab.params } : undefined).then(
              () => {
                setTimeout((): void => {
                  tabs.splice(index, 1);
                }, 0);
              },
              (): void => {
              },
            );
          },
        );
      }, 0);
    }
  }

  private close(tabs: TabServiceTabType[], index: number, callback: Function): void {
    const tab: TabServiceTabType = tabs[index];
    if (!tab.isCloseable) {
      return;
    }

    const onClose = () => {
      this.isCloseCalled = true;
      this.routeCacheService.clean(tab.href);

      if (_.isFunction(callback) && callback !== _.noop) {
        tabs.splice(index, 1);

        setTimeout((): void => {
          callback();
        }, 0);
      } else {
        const nextTab: TabServiceTabType = this.getNextTab(tab, tabs, index);

        setTimeout((): void => {
          this.router.navigate([nextTab.href], !!nextTab.params ? { queryParams: nextTab.params } : undefined).then(
            () => {
              tabs.splice(index, 1);

              setTimeout((): void => {
                callback();
              }, 0);
            },
            (): void => {
              const _tabs = this.getTabs() || [];
              const targetTabIndex = findTabIndex(_tabs, nextTab.href);

              if (-1 < targetTabIndex) {
                this.close(_tabs, targetTabIndex, (): void => {
                });
              }
            },
          );
        }, 0);
      }
    };

    if (tab.beforeClose && tab.beforeClose !== _.noop) {
      const returned = tab.beforeClose();

      if (returned instanceof Observable) {
        returned
          .pipe(take(1))
          .subscribe((result: boolean) => {
            if (!!result) {
              onClose();
            }
          });
      } else if (returned instanceof Promise) {
        returned.then(
          (result: boolean): void => {
            if (!!result) {
              onClose();
            }
          },
        );
      } else {
        if (!!returned) {
          onClose();
        }
      }
    } else {
      onClose();
    }
  }

  getNextTab(tab: TabServiceTabType, tabs: Array<TabServiceTabType>, index: number) {
    if (this.currentTab !== tab) {
      return this.currentTab;
    }

    if (_.includes(this.getTabs(), tab.originTab)) {
      return tab.originTab;
    }

    let nextTab: TabServiceTabType;
    if (null !== this.previousTab && 'object' === typeof this.previousTab && 'href' in this.previousTab &&
      this.previousTab.href !== tab.href) {
      nextTab = findTab(tabs, this.previousTab.href);
    }

    if (!nextTab) {
      nextTab = tabs[index - 1];
    }
    return nextTab;
  }

  changeTab(patchValue): void {
    this.tabIndex = _.mapValues(
      this.tabIndex,
      (tabs: TabServiceTabType[]): Object => {
        return _.find(tabs, { href: this.path })
          ? _.map(tabs, (tab: TabServiceTabType): TabServiceTabType => (tab.href === this.path ? { ...tab, ...patchValue } : tab))
          : tabs;
      },
    ) as TabGroup;
    this.refreshTabs.next(true);
  }

  removeTab(type, index: number): void {
    const tabs: TabServiceTabType[] = this.tabIndex[type];
    const tab: TabServiceTabType = tabs[index];
    if (tab.isCloseable) {
      if (this.previousTab === tab) {
        this.previousTab = null;
      }

      tabs.splice(index, 1);
    }
  }

  resetIsCloseCalled(): void {
    this.isCloseCalled = false;
  }

  // Replaced to match method signature from app/_layout/tab/tab.service.ts; unused
  replaceTab(url): void {
    const href = _.join(url, '/');

    if (href === this.router.url) {
      return;
    }
    const tabs = this.getTabs();
    const targetTabIndex = findTabIndex(tabs, href);
    if (targetTabIndex !== -1) {
      tabs.splice(targetTabIndex, 1);
    }
    const sourceTabIndex = findTabIndex(tabs, this.router.url);
    tabs.splice(sourceTabIndex, 1, { ...tabs[sourceTabIndex], href: getPath(href), params: getParams(href) });
    this.router.navigate(url);
  }

  jumpToTab(type, url: string | string[], queryParams?: { [key: string]: string }, navigationExtras?: NavigationExtras): void {
    const currentUrl: string = this.router.url;
    const tabs: TabServiceTabType[] = this.getTabs() || [];
    const index: number = findTabIndex(tabs, currentUrl);
    const href: string = _.isArray(url) ? `/${_.join(url, '/')}` : (!!url.match(/^\//) ? url : `/${url}`);

    this.closeTab(type, index, (): void => {
      setTimeout((): void => {
        this.router.navigateByUrl(href, { queryParams, ...('object' === typeof navigationExtras ? navigationExtras : {}) }).then(
          (): void => {
          },
          (): void => {
            if (href === currentUrl) {
              return;
            }
            const _tabs = this.getTabs() || [];
            const targetTabIndex = findTabIndex(_tabs, href);

            if (-1 < targetTabIndex) {
              this.close(_tabs, targetTabIndex, (): void => {
              });
            }
          },
        );
      }, 0);
    });
  }

  /**
   * Open a new tab by URL without closing the current one, when specified the new tab is positioned directly after the current tab
   * @param url
   * @param {any} queryParams
   * @param {boolean} afterCurrent
   * @param navigationExtras (NavigationExtras)
   */
  openTab(
    url: string | string[],
    queryParams?: { [key: string]: string },
    afterCurrent: boolean = false,
    navigationExtras?: NavigationExtras,
  ) {
    const currentUrl: string = this.router.url;
    const href: string = _.isArray(url) ? `/${_.join(url, '/')}` : (!!url.match(/^\//) ? url : `/${url}`);

    this.router.navigateByUrl(
      href,
      {
        queryParams,
        ...('object' === typeof navigationExtras ? navigationExtras : {}),
      },
    ).then(
      (): void => {
        if (!!afterCurrent) {
          setTimeout(
            (): void => {
              if (href === currentUrl) {
                return;
              }

              const tabs: TabServiceTabType[] = this.getTabs() || [];
              const currentTabIndex: number = findTabIndex(tabs, currentUrl);
              const targetTabIndex: number = findTabIndex(tabs, href);

              tabs.splice(currentTabIndex + 1, 0, tabs.splice(targetTabIndex, 1)[0]);
            },
            0,
          );
        }
      },
      (): void => {
        if (href === currentUrl) {
          return;
        }

        const tabs: TabServiceTabType[] = this.getTabs() || [];
        const targetTabIndex: number = findTabIndex(tabs, href);

        if (-1 < targetTabIndex) {
          this.close(tabs, targetTabIndex, (): void => {
          });
        }
      },
    );
  }

  get currentTab(): TabServiceTabType {
    const tabs: TabServiceTabType[] = this.getTabs() || [];

    return findTab(tabs, this.router.url);
  }

  getCurrentTab(): TabServiceTabType {
    const tabs: TabServiceTabType[] = this.getTabs() || [];

    return findTab(tabs, this.router.url);
  }

  // Changed parameter type to match method signature from app/_layout/tab/tab.service.ts
  updateTabTitle(tabIndex: any, title: string): void {
    const tabs: TabServiceTabType[] = this.getTabs() || [];
    const index: number = findTabIndex(tabs, this.router.url);

    if (tabIndex in this.tabIndex && index in this.tabIndex[tabIndex]) {
      this.tabIndex[tabIndex][index].title = title;
    }
  }
}

export interface Suspendable {
  onSuspend: Function;
  onRecovery: Function;
}

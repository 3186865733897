export const ON_HOLD_LABOUR_ITEM_STATUS = {
  ON_HOLD_FOR_TECH: {
    value: 'ON_HOLD_FOR_TECH',
    text: '待人员',
    description: '已中断',
    sortNumber: 2,
  },
  ON_HOLD_FOR_PART: {
    value: 'ON_HOLD_FOR_PART',
    text: '待配件',
    description: '已中断',
    sortNumber: 2,
  },
  ON_HOLD_FOR_AUTH: {
    value: 'ON_HOLD_FOR_AUTH',
    text: '待授权',
    description: '已中断',
    sortNumber: 2,
  },
};

export const LABOUR_ITEM_STATUS = {
  INITIATED: {
    value: 'INITIATED',
    description: '未开钟',
    sortNumber: 3,
  },
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    description: '已开钟',
    sortNumber: 1,
  },
  COMPLETED: {
    value: 'COMPLETED',
    description: '已关钟',
    sortNumber: 4,
  },
  CANCELED: {
    value: 'CANCELED',
    description: '已取消',
    sortNumber: 5,
  },
  CLOSED: {
    value: 'CLOSED',
    description: '已关闭',
    sortNumber: 5,
  },
  CONVERTED: {
    value: 'CONVERTED',
    description: '已转换',
    sortNumber: 6,
  },
  ...ON_HOLD_LABOUR_ITEM_STATUS,
};

export const CHANGE_STATUS_ACTION_CONFIG = {
  ON_HOLD: {
    value: 'ON_HOLD',
    text: '中断',
  },
  COMPLETED: {
    value: LABOUR_ITEM_STATUS.COMPLETED.value,
    text: '关钟',
  },
  IN_PROGRESS: {
    value: LABOUR_ITEM_STATUS.IN_PROGRESS.value,
    text: '开钟',
  },
};

export const OPERATION_TYPE = {
  CREATE: '新增',
  UPDATE: '修改',
  REMOVE: '删除',
};
export const DATE_TIME_FORMAT = 'YY-MM-DD-HH:mm:ss';
export const SHORT_DATE_TIME_FORMAT = 'YY-MM-DD-HH:mm';

/**
 * Deprecated, use PaginationSelectComponent instead.
 */
import { Component } from '@angular/core';
import * as _ from 'lodash';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from 'ag-grid';
import { BasePaginationFilterComponent } from '../base-pagination-filter.component';
import { getMapKeys } from '../../../../../utils/common';
import { BehaviorSubject, isObservable } from 'rxjs';

const setChecked = (map: Map<any, boolean>, key, checked) =>
  checked ? map.set(key, checked) : map.has(key) ? map.delete(key) : null;

@Component({
  selector: 'app-pagination-select-and-search-filter-cell',
  templateUrl: 'pagination-select-and-search-filter.template.html',
  styleUrls: ['../pagination-grid-filter.style.scss'],
})
export class PaginationSelectAndSearchFilterComponent extends BasePaginationFilterComponent {
  public options: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public optionValueArr: any;
  public filter: Map<any, boolean> = new Map();
  private savedFilter: Map<any, boolean> = new Map();
  private filterParams;
  modelFormatter = getMapKeys;

  agInit(params: IFilterParams): void {
    super.agInit(params);
    this.filterParams = this.params.colDef.filterParams;
    if (isObservable(this.filterParams.options)) {
      this.filterParams.options.subscribe(this.options);
    } else {
      const options = _.isFunction(this.filterParams.options) ? this.filterParams.options() : this.filterParams.options;
      this.options.next(options);
    }
    this.setFilter(false);
    this.optionValueArr = _.map(this.options.value, option => option.value);
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    super.afterGuiAttached(params);
    this.filter = new Map(this.savedFilter.entries());
    const savedFilter = _.map([...this.filter], itemArr => itemArr[0]);
    const input = _.difference(savedFilter, this.optionValueArr);
    if (savedFilter && input && input[0]) {
      this.searchInput.setValue(input[0]);
    }
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (this.filterParams.filter) {
      return this.filterParams.filter(params, this.savedFilter);
    }
    return true;
  }

  setDefaultValue() {
    super.setDefaultValue();
    this.setFilter(false);
  }

  onSubmit() {
    super.hideFilterMenu();
    const filterClone = _.cloneDeep(this.filter);
    filterClone.forEach((value, key) => !_.includes(this.optionValueArr, key) && filterClone.delete(key));
    this.filter = _.cloneDeep(filterClone);

    this.savedFilter = new Map(this.filter.entries());
    const sharedDealerFuzzyKeyword = this.searchInput.value;
    sharedDealerFuzzyKeyword ? this.savedFilter.set(sharedDealerFuzzyKeyword, true) : _.noop();
    this.params.filterChangedCallback();

    const filterParams = this.params.colDef.filterParams;
    if (filterParams.handleFilterChange) {
      filterParams.handleFilterChange(this.savedFilter);
    }
  }

  onCancel() {
    super.hideFilterMenu();
    this.filter = new Map(this.savedFilter.entries());
  }

  isFilterActive(): boolean {
    // @ts-ignore
    const arr = this.filter.size !== 0 ?  _.difference(this.savedFilter, this.filter) : this.savedFilter;
    return (!this.getIsAllChecked() && this.filter.size !== 0) || (_.isArray(arr) ? arr.length !== 0 : arr.size !== 0);
  }

  onChange(event, option) {
    if (option.value === 'SHARE_DEALER' && event.target.checked) {
      this.searchInput.setValue('');
    }
    setChecked(this.filter, option.value, event.target.checked);
  }

  emptyShareDealer() {
    setChecked(this.filter, 'SHARE_DEALER', false);
  }

  getIsAllChecked() {
    return this.filter.size === (this.options.getValue() || []).length;
  }

  onAllChange(event) {
    this.setFilter(event.target.checked);
  }

  private setFilter(isChecked: boolean) {
    _.forEach(
      this.options.getValue(),
      (option: any) => setChecked(this.filter, option.value, isChecked),
    );
  }

  isSelect(option) {
    return this.filter.size === 0 ? false : this.filter.get(option.value);
  }

  isEmptyFilter() {
    return this.savedFilter.size === 0;
  }

  setModel(model: any): void {
    const filter = (_.isEmpty(model) || model.size === 0) ? new Map() : new Map(model.map(i => [i, true]));
    this.filter = filter;
    this.savedFilter = filter;
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    return this.modelFormatter(this.savedFilter);
  }
}

import { Component } from '@angular/core';

@Component({
  template: `
    <span>
      <img style="width: 16px;" [src]="url" class="navigation-icon">
    </span>
  `,
})
export class SvgIconRenderComponent {
  url: string;

  agInit({ value }): void {
    this.url = value.url;
  }
}

export const USER_ROLE = {
  SA: { value: 'SA', text: '销售行政' },
  SC: { value: 'SC', text: '销售顾问' },
  RE: { value: 'RE', text: '接待员' },
  CM: { value: 'CM', text: '用户主管' },
  SYSA: { value: 'SYSA', text: '系统管理员' },
  DGA: { value: 'DGA', text: '经销商组管理员' },
  RA: { value: 'RA', text: '区域管理员' },
  DCM: { value: 'DCM', text: '高级配置管理员' },
  GM: { value: 'GM', text: '总经理' },
  SM: { value: 'SM', text: '销售经理' },
  SSA: { value: 'SSA', text: '高级销售行政' },
  ASSYSA: { value: 'ASSYSA', text: '售后系统管理员' },
  ASSA: { value: 'ASSA', text: '服务顾问' },
  NON_OTR: { value: 'Non-OTR', text: '非OTR用户' },
  OEM: { value: 'OEM', text: '厂商' },
  VANOEM: { value: 'VANOEM', text: '厂商' },
  ASM: { value: 'ASM', text: '售后经理' },
  PC: { value: 'PC', text: '体验专家' },
  TECH: { value: 'TECH', text: '车间技师' },
  UE: { value: 'UE', text: '二手车评估师' },
  UM: { value: 'UM', text: '二手车经理' },
  ASCDO: { value: 'ASCDO', text: '车主管家' },
  SCDO: { value: 'SCDO', text: '客户伙伴' },
  CDM: { value: 'CDM', text: '客户交互中心经理' },
  SMMO: { value: 'SMMO', text: '销售市场专员' },
  ASMO: { value: 'ASMO', text: '售后市场专员' },
  CMO: { value: 'CMO', text: '销售/售后市场专员' },
  CO: { value: 'CO', text: '客户权益官' },
  WC: { value: 'WC', text: '保修员' },
  BA: { value: 'BA', text: '审计员' },
  VANDCM: { value: 'VANDCM', text: 'VAN高级配置管理员' },
  VANCM: { value: 'VANCM', text: 'VAN用户主管' },
  SRM: { value: 'SRM', text: '展厅经理' },
  SS: { value: 'SS', text: '销售主管' },
  CC: { value: 'CC', text: '车辆预检技师' },
  CONT: { value: 'CONT', text: '调度员' },
  TCM: { value: 'TCM', text: '技师打卡管理员' },
  CRM: { value: 'CRM', text: '客户关系专员' },
  ADMIN: { value: 'ADMIN', text: '服务运维' },
  ACCO: { value: 'ACCO', text: '厂商财务专员' },
  DA: { value: 'DA', text: '经销商会计' },
  SERVICE_OPERATIONS: { value: 'SERVICE_OPERATIONS', text: '服务运维' },
  DC: { value: 'DC', text: '经销商收银员' },
  PART_CLERK: { value: 'PART_CLERK', text: '配件员' },
  PART_ASSISTANT: { value: 'PART_ASSISTANT', text: '配件助理' },
  PART_MANAGER: { value: 'PART_MANAGER', text: '配件经理' },
  PLANNER: { value: 'PLANNER', text: '计划员' },
  PART_SPECIALIST: { value: 'PART_SPECIALIST', text: '仓管管理员' },
  BMBSBU: { value: 'BMBSBU', text: 'BMBS用户' },
  PART_IT_ADMIN: { value: 'PART_IT_ADMIN', text: '配件IT管理员' },
  PTSR: { value: 'PTSR', text: '配件外销专员' },
  ASO: { value: 'ASO', text: '精品销售员' },
  DGSCM: { value: 'DGSCM', text: '销售客户管理-集团' },
  DGMM: { value: 'DGMM', text: '市场活动管理-集团' },
  DGCO: { value: 'DGCO', text: '投诉管理-集团' },
  MOT_CLERK: { value: 'MOT_CLERK', text: 'MOT专员' },
  SPECIAL_CASE_ADMIN: { value: 'SPECIAL_CASE_ADMIN', text: '特殊业务管理员' },
  IK: { value: 'IK', text: '信息采集员' },
  SAM: { value: 'SAM', text: '业务经理' },
  AC_ADMIN: { value: 'AC_ADMIN', text: '事故车管理员' },
  DG_AFTERSALES: { value: 'DG_AFTERSALES', text: '集团售后' },
  DG_FINANCE: { value: 'DG_FINANCE', text: '集团财务' },
  FM: { value: 'FM', text: '财务经理' },
  SR: { value: 'SR', text: '服务接待专员' },
  OEM_ACCIDENT_LEADS: { value: 'OEM_ACCIDENT_LEADS', text: '事故车团队' },
  OEM_ACCIDENT_LEADS_3RD_PARTY: { value: 'OEM_ACCIDENT_LEADS_3RD_PARTY', text: '事故车第三方团队' },
  VAN_OEM_ACCIDENT_LEADS: { value: 'VAN_OEM_ACCIDENT_LEADS', text: '商务车事故车团队' },
  IM: { value: 'IM', text: '保险经理' },
  IS: { value: 'IS', text: '保险专员' },
  OEM_ACCIDENT_3RD_PARTY: { value: 'OEM_ACCIDENT_3RD_PARTY', text: '经销商事故车业务端到端服务管理工具管理员（三方）' },
  ARS: { value: 'ARS', text: '留修专员' },
  AM: { value: 'AM', text: '事故经理' },
  AFS: { value: 'AFS', text: '外勤专员' },
  REGION_ACCIDENT_LEADS: { value: 'REGION_ACCIDENT_LEADS', text: '事故车区域经理' },
  PARTS_SALES_MANAGEMENT: { value: 'PARTS_SALES_MANAGEMENT', text: '配件销售管理团队' },
};

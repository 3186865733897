<div class="toolTip-icon" (mouseover)="stopMousePropagation($event)">
    <span class="text">
      <span>{{text || ''}}</span>
      <span class="icon"
            nzOverlayClassName="tip-container"
            *ngIf="shouldShowIcon"
            [nzTitle]="tooltipTemplate"
            [nzPlacement]="placement"
            nz-tooltip
      >
        <img [src]="imageSrc" alt="info">
      </span>
    </span>
</div>

<ng-template #tooltipTemplate>
  <div>
   {{ tooltipValues }}
  </div>
</ng-template>

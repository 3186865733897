import { Injectable } from '@angular/core';
import { Body, PATCH, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OrderPaymentCollectionApi extends RebirthHttp {
  static API_PREFIX: string = '/api/order-payment-detail';

  constructor(http: HttpClient) {
    super(http);
  }

  @PATCH(`${OrderPaymentCollectionApi.API_PREFIX}`)
  changeCollectionStatus(@Body data: Object): Observable<any> {
    return null;
  }
}

import { Injectable } from '@angular/core';
import { Body, GET, RebirthHttp, POST, Query, PUT, PATCH, RequestOptions, Headers } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PartsImportApi extends RebirthHttp {
  static PARTS_IMPORT_PREFIX = '/api/parts-import';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/config`)
  getConfig(): Observable<any> {
    return null;
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/mig-modules/count-status`)
  getNoOfRunningJob(): Observable<any> {
    return null;
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/preload`)
  getPreloadControl(): Observable<any> {
    return null;
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/dealers`)
  getDealers(): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/id-range/calc`)
  executeIdRangeCalculation(): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/migrate-all-to-stag`)
  executeMigrateAll(@Query('onlyMigration') onlyMigration: Boolean): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/validate-all`)
  executeValidateAll(): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/stop-all`)
  executeStopAll(): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/to-stag`)
  executeMigrateByDealerAndModule(
    @Query('dealer') dealerId: string,
    @Query('module') module: string): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/validate`)
  executeValidateByDealerAndModule(
    @Query('dealer') dealerId: string,
    @Query('module') module: string): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/to-otr`)
  executeToOtrByDealerAndModule(
    @Query('dealer') dealerId: string,
    @Query('module') module: string): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/update-otr`)
  executeUpdateOtrByDealerAndModule(
    @Query('dealer') dealerId: string,
    @Query('module') module: string): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/reset-all-parts-movement`)
  executeResetAllPartsMovementByDealer(
    @Query('dealer') dealerId: string): Observable<any> {
    return null;
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/view-log/download`)
  executeViewLogByDealerAndModule(
    @Query('dealer') dealerId: string,
    @Query('module') module: string): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/reset`)
  executeResetByDealerAndModule(
    @Query('dealer') dealerId: string,
    @Query('module') module: string): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/stop`)
  @RequestOptions({ responseType: 'text' })
  executeStopByDealerAndModule(
    @Query('dealer') dealerId: string,
    @Query('module') module: string): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/mig-modules`)
  migrateModuleListByDealerAndModule(
    @Query('dealer') dealerId?: string,
    @Query('module') module?: string,
    @Query('page') page?: number,
    @Query('size') size?: number): Observable<any> {
    return null;
  }

  @PUT(`${PartsImportApi.PARTS_IMPORT_PREFIX}/preload`)
  updatePreloadControl(@Query('flag') preload?: boolean): Observable<any> {
    return null;
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/modules`)
  getModules(): Observable<any> {
    return null;
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/mig-modules/action-status`)
  getActionStatus(): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/init`)
  importDealerList(@Body data): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/update-go-live`)
  updateGoLiveDealerList(@Body data): Observable<any> {
    return null;
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/delta-datetime`)
  getGlobalDeltaDatetime(): Observable<any> {
    return null;
  }

  @PUT(`${PartsImportApi.PARTS_IMPORT_PREFIX}/delta-datetime`)
  updateGlobalDeltaDatetime(@Body data): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/migrate-param`)
  importDealerParameter(): Observable<any> {
    return null;
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/dealers/param/status`)
  getImportParamStatus(): Observable<any> {
    return null;
  }

  @PATCH(`${PartsImportApi.PARTS_IMPORT_PREFIX}/dealers/param/reset-status`)
  resetImportDealerParameterStatus(): Observable<any> {
    return null;
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/validation/report/print/csv`)
  @RequestOptions({ responseType: 'blob' })
  executeViewReportByDealerAndAction(
    @Query('dealer') dealerId?: string,
    @Query('jobAction') jobAction?: string): Observable<any> {
    return null;
  }

  @POST(`${PartsImportApi.PARTS_IMPORT_PREFIX}/migrate-all-to-otr`)
  executeAllToOtr(): Observable<any> {
    return null;
  }

  @GET(`${PartsImportApi.PARTS_IMPORT_PREFIX}/view-log/link/data`)
  @RequestOptions({ responseType: 'blob' })
  executeDownloadLogByDealerAndModuleAndLoglevel(
    @Query('dealer') dealerId: string,
    @Query('module') module: string,
    @Query('loglevel') loglevel: string): Observable<any> {
    return null;
  }
}

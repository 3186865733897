import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';

@Component({
  template: `<div *ngIf="hideLabel" class="circle" [ngClass]="{'active': checked, 'disabled': disabled}" (click)="toggle()"></div>`,
  styleUrls: ['../../../radio-button/radio-button.component.scss'],
})
export class RadioButtonCellRendererComponent implements ICellRendererAngularComp {
  disabled: boolean;
  hideLabel: boolean;
  defaultChecked: boolean;
  params;

  agInit(params) {
    this.params = params;
    this.disabled = params.colDef.isDisabled && params.colDef.isDisabled(params.data);
    this.hideLabel = _.isNil(params.colDef.display) || (params.colDef.display && params.colDef.display(params.data));
    this.defaultChecked = params.colDef.defaultChecked && params.colDef.defaultChecked(params.data);
    if (this.defaultChecked) {
      this.params.node.selectThisNode(true);
    }
  }

  refresh(params): boolean {
    return false;
  }

  get checked() {
    return !!this.selectedRows()[this.params.node.id] && !this.disabled;
  }

  toggle() {
    const checked = !this.checked;
    if (_.isFunction(this.params.node.setSelected)) {
      this.params.node.setSelected(checked, true);
    } else if (_.isFunction(this.params.node.selectThisNode)) {
      this.params.node.selectThisNode(checked);
    }
  }

  private selectedRows() {
    return this.params.node.selectionController.selectedNodes;
  }
}

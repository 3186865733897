import { filter, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from 'app/_common/utils/autoUnsubscribe';
import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PromptLevel } from 'app/_common/constants/common';
import { TabVehicleBusinessTypeService } from '@otr/website-common';
import { NavigationStart, Router } from '@angular/router';
import { Observable, queueScheduler, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { PartPromptBarConfig } from '../../models/PartPromptBarConfig';

const containerClassMap = {
  [PromptLevel.Success]: 'normal',
  [PromptLevel.Info]: 'normal',
  [PromptLevel.Warning]: 'error',
  [PromptLevel.Error]: 'error',
};

@AutoUnsubscribe()
@Component({
  selector: 'app-part-prompt-bar',
  templateUrl: './prompt-bar.component.html',
  styleUrls: ['./prompt-bar.component.scss'],
})
export class PartPromptBarComponent implements OnInit, OnChanges {
  @Input() configs: PartPromptBarConfig[];

  successLevel: number = PromptLevel.Success;
  infoLevel: number = PromptLevel.Info;
  warningLevel: number = PromptLevel.Warning;
  errorLevel: number = PromptLevel.Error;
  private componentDestroy$: Observable<any>;
  @HostBinding('class.shown')
  private shown: boolean = false;
  private subscription: Subscription = Subscription.EMPTY;

  constructor(private tabVehicleBusinessTypeService: TabVehicleBusinessTypeService, private router: Router) { }

  @HostBinding('class.has-tab')
  get hasTab(): boolean {
    return !_.isEmpty(this.tabVehicleBusinessTypeService.getTabs());
  }

  ngOnInit() {
    this.router.events.pipe(
      takeUntil(this.componentDestroy$),
      filter(e => e instanceof NavigationStart))
      .subscribe(() => this.hide());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const configs = _.get(changes, 'configs.currentValue', null) as PartPromptBarConfig[];
    if (configs && configs.length > 0) {
      this.configMessage(configs);
    }
  }

  private configMessage(configs: PartPromptBarConfig[]) {
    if (configs.length > 0) {
      configs.forEach(config => {
        config.containerClass = containerClassMap[config.level];
      });
      this.show(configs);
    }
  }

  private hide() {
    this.shown = false;
  }

  private show(configs) {
    this.shown = true;
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
    configs.forEach(config => {
      if (!config.isPreserved) {
        this.subscription = queueScheduler.schedule(() => this.hide(), config.duration || 4000);
      }
    });
  }

}

<div class="modal-body" (click)="clickModalBody()">
  <div [ngClass]="!isHaveCecId ? 'modal-row' : ''">
    <div [ngClass]="isHaveCecId ? 'modal-message-cec' : 'modal-message'">请确认本次通话记录的联系人</div>
    <div [ngClass]="{'modal-row-dropdown':!isHaveCecId}">
      <div class="dropdown" [ngStyle]="isHaveCecId && {'width':'100%','margin-top':'10px','margin-left':'0'}">
        <div (click)="showDropDownList($event)" class='customer' [nz-tooltip]="currentCustomer.full_name + (isHaveCecId ? ' ' + currentCustomer.dealer_name : '')" >
          <span class="drop-down-span">{{ currentCustomer.full_name }}</span>
          <span *ngIf="isHaveCecId" class="drop-down-span dealer">{{ currentCustomer.dealer_name }}</span>
          <i nz-icon nzType="caret-down" nzTheme="outline" class="icon"></i>
        </div>
        <section class="drop-down-list section"
                id="drop-down"
                *ngIf="dropDownToggle"
        >
          <div *ngFor="let customer of customerList"
              class="drop-down-list-row customer"
              [ngClass]="currentCustomer.id === customer.id ? 'drop-down-list-check-row' : ''"
              (click)="chooseCurrentCustomer(customer)"
               [nz-tooltip]="currentCustomer.full_name + (isHaveCecId ? ' ' + currentCustomer.dealer_name : '')"
          >
            <span class="drop-down-span">{{customer.full_name}}</span>
            <span *ngIf="isHaveCecId" class="drop-down-span dealer">{{ customer.dealer_name }}</span>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div>
    <textarea class="comment" placeholder="本次通话记录备注" [(ngModel)]="comments" maxlength="200"></textarea>
  </div>
  <div>
    <div class="tips" *ngIf="0 !== currentCustomerLeads.length">
      <div>当前联系人有未关闭销售线索，是否跟进</div>
      <div class="form-check form-check-inline">
        <input id="Reserve" class="form-check-input" type="radio" [(ngModel)]="trackSalesLeadsType" [value]="true"
               (change)="resetCurrentCustomerLeadsChecked()">
        <label class="form-check-label" for="Reserve">是</label>
      </div>
      <div class="form-check form-check-inline">
        <input id="Followup" class="form-check-input" type="radio" [(ngModel)]="trackSalesLeadsType" [value]="false"
               (change)="resetCurrentCustomerLeadsChecked()">
        <label class="form-check-label" for="Followup">否</label>
      </div>
    </div>
  </div>

  <div class="button-group">
    <button style="margin-right: 10px;" class="btn btn-outline-primary" (click)="closeModal()">
      保存记录，稍后匹配
    </button>
    <button [disabled]="confirmBtnDisable" class="btn btn-primary" (click)="saveAndBindMessage()">
      确定
    </button>
  </div>
</div>


    <div class="commercial-insurance-status">
      <div>
        <label>商业</label><span [class]="commercialStyle">{{ commercialText }}</span>
      </div>
      <div>
        <label>交强</label><span [class]="statutoryAutomobileLiabilityStyle">{{ statutoryAutomobileLiabilityText }}</span>
        <div></div>
      </div>
    </div>
  
import { ChainedRebirthInterceptor } from './_common/utils/chained-rebirth-interceptor';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { RebirthHttpProvider } from 'rebirth-http';
import { Router, NavigationEnd } from '@angular/router';
import { ConfirmService } from './_common/services/confirm.service';
import {
  ModalMessageService,
  PromptBarConfig,
  PromptBarService,
  RebirthInterceptor,
  TokenService,
  GlobalEvent,
  GlobalEventService,
  CurrentUserService, getVehicleBusinessType,
} from '@otr/website-common';
import * as _ from 'lodash';
import { DealerService } from './_common/services/dealer.service';
import { AuthCheck } from './_common/utils/authCheck';
import { PromptLevel } from './_common/constants/common';
import { PartPromptBarConfig } from './_common/models/PartPromptBarConfig';
import { PartPromptBarService } from './_common/services/prompt-bar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  shouldShowPromptBar = false;
  shouldShowPartPromptBar = false;
  config: PromptBarConfig;
  configs: PartPromptBarConfig[];

  confirmOption: any = {};
  deletePartReasonOption: any = {};
  defaultConfirmButtonText: string = '确定';
  defaultCancelButtonText: string = '取消';
  @ViewChild('confirmModal') confirmModal;

  showDealerDragBar: boolean = false;
  showCtiMain: boolean = false;

  constructor(private viewContainerRef: ViewContainerRef,
              private rebirthHttpProvider: RebirthHttpProvider,
              private tokenService: TokenService,
              private promptBarService: PromptBarService,
              private partPromptBarService: PartPromptBarService,
              private router: Router,
              private confirmService: ConfirmService,
              private modalMessageService: ModalMessageService,
              private chainedRebirthInterceptor: ChainedRebirthInterceptor,
              private rebirthInterceptor: RebirthInterceptor,
              private globalEventService: GlobalEventService,
              private currentUserService: CurrentUserService,
              private dealerSerivice: DealerService,
              private authCheck: AuthCheck,
  ) {
    this.viewContainerRef = viewContainerRef;
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    rebirthHttpProvider
      .addInterceptor({
        request: this.rebirthInterceptor.request(tokenService),
        response: this.rebirthInterceptor.response(null, tokenService, router, modalMessageService),
      });
    rebirthHttpProvider
      .addInterceptor({
        request: this.chainedRebirthInterceptor.request.bind(this.chainedRebirthInterceptor),
        response: this.chainedRebirthInterceptor.response.bind(this.chainedRebirthInterceptor),
      });

    this.confirmService.option.subscribe(option => {
      this.confirmOption = option;
      this.confirmModal.show();
    });
  }

  async ngOnInit() {
    this.promptBarService
      .displayStatus
      .subscribe(displayStatus => {
        this.shouldShowPromptBar = !!displayStatus;
      });

    this.promptBarService.config.subscribe(config => (this.config = config));

    this.partPromptBarService.displayStatus.subscribe(displayStatus => {
      this.shouldShowPartPromptBar = !!displayStatus;
    });

    this.partPromptBarService.configs.subscribe(configs => (this.configs = configs));
    await this.initDragBar();
  }

  onConfirm() {
    this.confirmService.onConfirm();
  }

  onCancel() {
    this.confirmService.onCancel();
  }

  onClose() {
    this.confirmService.onClose();
  }

  async initDragBar() {
    this.globalEventService.register(GlobalEvent.LOGOUT, () => {
      this.showDealerDragBar = false;
      this.showCtiMain = false;
      // this.dealerSerivice.setDealerBarSelectArea();
      this.dealerSerivice.destory();
    });

    this.router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {
        await this.checkShowDragBarOrNot(100, event.urlAfterRedirects || event.url);
        if (!_.isNil(getVehicleBusinessType()) &&
          !this.authCheck.isDealerGroupAuthorisedByBusinessType()) {
          this.promptBarService.show('当前选中经销商无此授权，请重新选择', PromptLevel.Error);
        }
      }
      // @ts-ignore
      if (event.url === '/') {
        // @ts-ignore
        window.sessionStorage.setItem('showBroadcasting', 'true');
      }
    });
  }

  async checkShowDragBarOrNot(timeout, currentUrl= '') {
    const user = this.currentUserService.getCurrentUser();
    if (!user) { return; }
    _.delay(() => {
      // tslint:disable-next-line:cyclomatic-complexity
      this.showCtiMain = true;
      const isSuperAdminRole = this.dealerSerivice.isRoleOfSuperAdminForDealer();
      const isSuperAdminUrl = currentUrl ? this.dealerSerivice.isUnderSuperAdminPageForDealer(currentUrl) : true;
      const isSuperAdminDetailUrl = currentUrl ? this.dealerSerivice.isUnderSuperAdminDetailPageForDealer(currentUrl) : true;
      const noShowDragBarUnderUrls = currentUrl ? this.dealerSerivice.isNoShowDealerDragBarUnderUrls(currentUrl) : true;
      this.showDealerDragBar = isSuperAdminRole && isSuperAdminUrl && !noShowDragBarUnderUrls;
      if (isSuperAdminRole && (isSuperAdminUrl || isSuperAdminDetailUrl)) {
        if (!_.isNil(this.tokenService.superAdminToken)) {
          this.tokenService.accessToken = this.tokenService.superAdminToken;
        }
      } else if (!_.isNil(this.tokenService.normalToken)) {
        this.tokenService.accessToken = this.tokenService.normalToken;
      }
    }, timeout);
  }
}

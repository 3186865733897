import { Component } from '@angular/core';
import * as _ from 'lodash';
import { ICellEditorParams } from 'ag-grid';
import { FormControl, FormGroup } from '@angular/forms';
import { BasicEditorComponent } from '../basic-editor.component';
import {
  formatToEndOfDayTimeWithOutMilliseconds,
  formatToStartOfDayTimeWithOutMilliseconds,
} from '../../../../utils/date';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-editor',
  templateUrl: './date-range-editor.component.html',
  styleUrls: ['./date-range-editor.component.scss']
})
export class DateRangeEditorComponent extends BasicEditorComponent {
  form;
  leftEditParams;
  rightEditParams;
  params: ICellEditorParams;
  leftValue: any;
  rightValue: any;
  errorMessageStyle;
  disableDates = [];

  agInit(params: any): void {
    super.agInit(params);
    this.params = params;
    this.errorMessageStyle = _.get(this.getCellEditorParams(), 'errorMessageStyles', {});
    this.leftEditParams = this.getCellEditorParams().leftEditParams;
    this.rightEditParams = this.getCellEditorParams().rightEditParams;
    this.disableDates = _.get(this.params, 'context.tableData', []);
    if (this.params.node.data.id) {
      this.disableDates = _.remove(this.disableDates, date => date.id !== this.params.node.data.id);
    }
    this.leftValue = this.params.node.data[this.leftEditParams.field];
    this.rightValue = this.params.node.data[this.rightEditParams.field];
    this.form = new FormGroup({
      startTime: new FormControl(this.leftValue),
      endTime: new FormControl(this.rightValue) },
    );
  }

  getCellEditorParams() {
    return this.params.column.getColDef().cellEditorParams || {};
  }

  onLeftInputChange(event) {
    const time = formatToStartOfDayTimeWithOutMilliseconds(event);
    this.leftValue = time;
    this.updateValue(this.leftEditParams.field, time);
    if (this.rightValue) {
      this.onChangeHandler(this.leftValue, this.rightValue);
    }
  }

  onRightInputChange(event) {
    const time = formatToEndOfDayTimeWithOutMilliseconds(event);
    this.rightValue = time;
    this.updateValue(this.rightEditParams.field, time);
    this.onChangeHandler(this.leftValue, this.rightValue);
  }

  updateValue(field, value) {
    _.set(this.params.node.data, field, value);
  }

  endTimeDisabledDates = (date: Date) => {
    if (!date) {
      return false;
    }
    const isDisabled = _.some(this.disableDates, disabledDate => {
      return moment(date).isBetween(moment(disabledDate.startDate), moment(disabledDate.endDate), 'd') ||
        moment(date).isSame(moment(disabledDate.startDate), 'd') ||
        moment(date).isSame(moment(disabledDate.endDate), 'd');
    });
    const isPast = moment(date).isBefore(moment(), 'd');
    const timeRangeStart = this.form.value.startTime;
    if (timeRangeStart) {
      return moment(date).isBefore(moment(timeRangeStart), 'd') || isPast || isDisabled;
    }
    return isPast || isDisabled;
  };

  startTimeDisabledDates = (date: Date) => {
    if (!date) {
      return false;
    }
    const isDisabled = _.some(this.disableDates, disabledDate => {
      return moment(date).isBetween(moment(disabledDate.startDate), moment(disabledDate.endDate), 'd') ||
        moment(date).isSame(moment(disabledDate.startDate), 'd') ||
        moment(date).isSame(moment(disabledDate.endDate), 'd');
    });
    const isPast = moment(date).isBefore(moment(), 'd');
    return isPast || isDisabled;
  };
}

import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-row-panel',
  styleUrls: ['./row-panel.style.scss'],
  templateUrl: './row-panel.template.html',
})
export class RowPanelComponent {
  @Input() rowData: Array<Object>;
  @Input() subArrayLength: Array<number>|number;

  get formattedData() {
    if (_.isArray(this.subArrayLength)) {
      const result = [];
      let startIndex = 0;
      for (const subLength of this.subArrayLength) {
        result.push(_.slice(
          _.filter(this.rowData, row => !(_.isBoolean(_.get(row, 'hide')) && _.get(row, 'hide'))),
          startIndex, (startIndex += Number(subLength))));
      }
      return result;
    }
    return _.chunk(_.filter(this.rowData,
        row => !(_.isBoolean(_.get(row, 'hide')) && _.get(row, 'hide'))), this.subArrayLength);
  }

  isNewRow(index) {
    return index % 2 === 0;
  }
}

import { Component } from '@angular/core';
import * as _ from 'lodash';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { combineLatest, isObservable, Observable, of } from 'rxjs';
import { ICellRendererParams } from 'ag-grid';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { CheckboxStrategy } from 'app/_common/components/grid/header/checkbox/checkbox-strategy';

@Component({
  template: ` <input type="checkbox"
                     *ngIf="displayed"
                     [checked]="checked$ | async"
                     [disabled]="disabled$ | async"
                     (click)="toggle($event)"/>
                     <span class="custom-checkbox-label">&nbsp;</span>
                     `,
  styleUrls: ['../custom-checkbox/custom-checkbox-cell.component.scss'],
})
export class CheckboxCell2RenderComponent implements ICellRendererAngularComp {
  disabled$: Observable<boolean>;
  checked$: Observable<boolean>;
  selected$: Observable<boolean>;
  displayed: boolean;

  private params;
  private strategy: CheckboxStrategy;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const colDef = params.column.getColDef();
    const isDisplayed = _.get(colDef, 'cellRendererParams.isDisplayed');
    this.displayed = _.isNil(isDisplayed) ? true : isDisplayed.apply(null, [params.data]);

    const rendererParams = colDef.cellRendererParams;

    this.strategy = _.get(rendererParams, 'strategy') || new CheckboxStrategy();
    if (_.get(rendererParams, 'strategy') === undefined) {
      _.set(rendererParams, 'strategy', this.strategy);
    }

    if (!this.displayed) {
      this.strategy.unselect(params.node.id);
      return;
    }

    this.selected$ = this.strategy.watch(params.node.id);

    const isDisabled = _.get(colDef, 'cellRendererParams.isDisabled');
    const globalDisabled$ = isObservable(rendererParams.globalDisabled$) ? rendererParams.globalDisabled$ : of(false);

    this.disabled$ = globalDisabled$.pipe(
      map(disable => {
        if (disable) {
          return true;
        }
        return _.isFunction(isDisabled) ? isDisabled.apply(null, [params.data]) : isDisabled;
      }),
    );

    this.checked$ = combineLatest(this.disabled$, this.selected$).pipe(
      tap(([disabled, selected]) => {
        if (disabled && selected) {
          this.strategy.unselect(params.node.id);
        }
      }),
      map(([disabled, selected]) => !disabled && selected),
      distinctUntilChanged(),
    );

  }

  refresh(params): boolean {
    return false;
  }

  toggle(event) {
    event.target.checked
      ? this.strategy.select(this.params.node.id)
      : this.strategy.unselect(this.params.node.id);
  }
}

<div class="filter-box">
  <div class="calendar-range">
    <span>
      <p-calendar
        [inputStyle]="{width: '118px', height: '24px'}"
        [formControl]="dateControl"
        [locale]="chineseCalendarConfig"
        dataType="string"
        dateFormat="yy-mm-dd"
        placeholder="YYYY-MM-DD"
        (onBlur)="onCalendarBlur()"
        [minDate]="minDate"
        (onMonthChange)="stopPropagation()"
      ></p-calendar>
    </span>
  </div>
  <span class="btn-cancel" (click)="onCancel()">
    取消
  </span>
  <button
    class="btn-save btn-calendar"
    type="submit"
    (click)="onSubmit()"
    [disabled]="!isFilterValid()"
  >
    确定
  </button>
</div>

import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { CAMPAIGN_STATUS_MAPPING, CampaignStatus } from '../utils/campaign';

const {
  APPLY_APPROVED,
  APPLY_CONFIRMED,
  APPLY_PLANNED,
  CANCEL_APPLICATION,
  CANCELLED,
  FEEDBACK_CONFIRMED,
  FEEDBACK_PLANNED,
} = CampaignStatus;
const campaignKeys = Object.keys(CAMPAIGN_STATUS_MAPPING);
const defaultDeactivateStatus: Array<string> = [APPLY_CONFIRMED, FEEDBACK_CONFIRMED, CANCEL_APPLICATION, CANCELLED];
const defaultAllowStatus = campaignKeys.filter(key => defaultDeactivateStatus.indexOf(key) === -1);

const statusOperation = {
  save: defaultAllowStatus,
  edit: defaultAllowStatus,
  editFeedback: [APPLY_APPROVED],
  submit: defaultAllowStatus,
  cancel: [APPLY_APPROVED, FEEDBACK_PLANNED],
  delete: [APPLY_PLANNED],
};

@Directive({
  selector: '[appIsVisibleForCampaignActions]',
})
export class IsVisibleForCampaignActionsDirective implements OnChanges {
  @Input() appIsVisibleForCampaignActions: string;
  @Input() status: string;
  @Input() histories?: Array<any> = [];

  constructor(
    private elementRef: ElementRef,
  ) {
  }

  ngOnChanges(changes): void {
    this.checkElementCanBeVisible(changes);
  }

  private checkElementCanBeVisible(changes) {
    const currActions = _.get(changes, 'appIsVisibleForCampaignActions.currentValue', this.appIsVisibleForCampaignActions);
    const currStatus = _.get(changes, 'status.currentValue', this.status);

    const currOperation = statusOperation[currActions] || [];

    if (currStatus && !_.includes(currOperation, currStatus)) {
      this.elementRef.nativeElement.hidden = true;
    } else {
      this.elementRef.nativeElement.hidden = false;
    }

    if (currStatus === FEEDBACK_PLANNED && this.historiesContainsFeedbackConfirmedStatus()) {
      this.elementRef.nativeElement.hidden = true;
    }
  }

  private historiesContainsFeedbackConfirmedStatus() {
    return _.includes(_.map(this.histories, 'status'), FEEDBACK_CONFIRMED);
  }
}

<div class="form-container">
  <div *ngFor='let lead of leads'>
    <div class="lead-label" *ngIf='leads.length > 1'>
      <span class='business-model-type'>
        经销商：
      </span>
      <span class='lead-desc'>
        {{leadLabel}}
      </span>
    </div>
    <div>
      <div class="section close-reason">
        <div class="dropdown-section">
          <span class="section-title required">关闭原因</span>
          <div class="section-content">
            <app-dropdown
              [options]="closeReasons"
              [selectedValue]="closeReason.value"
              [control]="closeReason"
              [style]="{ 'width': '290px' }"
              [error]="closeReason.invalid && closeReason.touched"
            >
            </app-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <span class="section-title">备注</span>
    <div class="section-content">
      <textarea class="customize-textarea comment" maxlength="200" placeholder="还有什么补充信息" [formControl]="comments"></textarea>
    </div>
  </div>
</div>

import { debounceTime } from 'rxjs/operators';
import { Component, HostBinding, Input } from '@angular/core';
import { SpinnerService } from '@otr/website-common';

@Component({
  selector: 'app-spinner',
  templateUrl: 'spinner.template.html',
  styleUrls: ['spinner.style.scss'],
})
export class SpinnerComponent {
  isDelayedRunning: boolean = false;

  @Input() delay: number = 200;

  constructor(private spinnerService: SpinnerService) {
    this.spinnerService.isRunning.pipe(debounceTime(this.delay)).subscribe(isRunning => this.isDelayedRunning = isRunning);
  }

  @HostBinding('class.active') get active() {
    return this.isDelayedRunning;
  }
}

<section [class.show-seconds]="showSeconds" (click)="$event.stopPropagation();">
  <p>{{title}}</p>
  <form class="form-inline time-picker">
    <div class="form-group">
      <input type="text"
             #hours
             [disabled]="disabled"
             name="hours"
             class="form-control"
             maxlength="2"
             max="23"
             min="0"
             autocomplete="off"
             [(ngModel)]="hour"
             (keypress)="handleKeyEvent($event, timeUnit.HOUR)"
             (blur)="onTimeChange(timeUnit.HOUR)">
    </div>
    <span>:</span>
    <div class="form-group">
      <input type="text"
             #minutes
             [disabled]="disabled"
             name="minutes"
             class="form-control"
             maxlength="2"
             max="59"
             min="0"
             autocomplete="off"
             [(ngModel)]="minute"
             (keypress)="handleKeyEvent($event, timeUnit.MINUTE)"
             (blur)="onTimeChange(timeUnit.MINUTE)">
    </div>
    <ng-container *ngIf="showSeconds">
      <span>:</span>
      <div class="form-group">
        <input type="text"
               #seconds
               [disabled]="disabled"
               name="seconds"
               class="form-control"
               max="59"
               min="0"
               maxlength="2"
               autocomplete="off"
               [(ngModel)]="second"
               (keypress)="handleKeyEvent($event, timeUnit.SECOND)"
               (blur)="onTimeChange(timeUnit.SECOND)">
      </div>
    </ng-container>
  </form>

  <div class="prompt-area">
    <p>{{errorMessage}}</p>
  </div>

  <button class="btn btn-reject" (click)="cancel()">取消</button>
  <button class="btn btn-primary" [disabled]="errorMessage" (click)="save()">保存</button>
</section>

import { Injectable } from '@angular/core';
import { GET, POST, RebirthHttp, Query, Headers, Body, Path } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DynamicColumnDTO, MonthlyKpiReportDto, OperatorDto } from '../models/parts-report';
import { FFRGroupCriteria, FFRReportDTO, PageDTO } from '../constants/report';
import { DealerAllowance } from '../models/dealer-allowance';
import { DashboardQueryParams, IDashboardLeadOverallInfo } from '../models/dashboard';
import { ISummaryCompletionRateReport, ITarRecallReportDetail } from '../models/service-recall';

@Injectable()
export class ReportCenterApi extends RebirthHttp {

  static PARTS_WEBSITE_PREFIX = '/api/parts-website';
  static PART_DELIVERY_PREFIX = '/api/part-delivery';
  static PART_REPORT_V2_PREFIX = '/api/reports/v2';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/dealer-groups/sa-users')
  fetchSaName(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/dealer-groups/sa-users/by-dealer')
  fetchSaNameByDealer(): Observable<any> {
    return null;
  }

  @GET('/api/accident-car/hengxin-mapping/exists')
  fetchHengxinDealer() {
    return null;
  }

  @GET('/api/users/cec')
  fetchFounderData(@Query('dealer_id') dealerId: string, @Query('excludeBranch') excludeBranch: boolean): Observable<OperatorDto[]> {
    return null;
  }

  @GET('/api/accident-car/leads/lost-reason')
  fetchLeadLostReason(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftersales/reports')
  fetchReportData(@Query('reportName') name,
                  @Query('filter') filter,
                  @Query('pageNumber') currentPage,
                  @Query('pageSize') itemsPerPage,
                  ): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftersales/reports')
  @Headers({ isExportFile: true })
  downloadReportFile(@Query('reportName') name: string,
                     @Query('filter') filter: string,
                     @Query('export') shouldExport: boolean,
                     @Query('isVanEnabled')isVanEnabled: boolean): Observable<any>  {
    return null;
  }

  @GET('/api/sales-website/aftersales/service-types')
  fetchServiceType(): Observable<any>  {
    return null;
  }

  @GET(`${ReportCenterApi.PARTS_WEBSITE_PREFIX}/report`)
  @Headers({ 'x-show-loading': true })
  getPartsKpiReport(
      @Query('reportName') reportName: string,
      @Query('startYear') startYear: string,
      @Query('startMonth') startMonth: string,
      @Query('endYear') endYear: string,
      @Query('endMonth') endMonth: string,
      @Query('export') isExport: boolean,

  ): Observable<MonthlyKpiReportDto[]> {
    return null;
  }

  @GET(`${ReportCenterApi.PARTS_WEBSITE_PREFIX}/report/monthly-parts-kpi/dynamic-column`)
  @Headers({ 'x-show-loading': true })
  getMonthlyPartsKpiDynamicColumn(): Observable<DynamicColumnDTO[]> {
    return null;
  }

  @GET(`${ReportCenterApi.PARTS_WEBSITE_PREFIX}/report`)
  @Headers({ isExportFile: true, 'x-show-loading': true })
  downloadPartsKpiReport(
      @Query('reportName') reportName: string,
      @Query('startYear') startYear: string,
      @Query('startMonth') startMonth: string,
      @Query('endYear') endYear: string,
      @Query('endMonth') endMonth: string,
      @Query('export') isExport: boolean,
  ): Observable<any> {
    return null;
  }

  @GET(`${ReportCenterApi.PARTS_WEBSITE_PREFIX}/report`)
  @Headers({ 'x-show-loading': true })
  getFFRReport(
    @Query('reportName')  reportName: string,
    @Query('startDate')  startDate: string,
    @Query('endDate')  endDate: string,
    @Query('partsType')  partsType: string,
    @Query('serviceType')  serviceType: string,
    @Query('departmentCode')  departmentCode: string,
    @Query('criteria')  criteria: FFRGroupCriteria[],
    @Query('page') page: number,
    @Query('size') size: number,
  ): Observable < PageDTO<FFRReportDTO> > {
    return null;
  }

  @GET(`${ReportCenterApi.PARTS_WEBSITE_PREFIX}/report`)
  @Headers({ isExportFile: true, 'x-show-loading': true })
  downloadFFRReportExcel(
    @Query('reportName')  reportName: string,
    @Query('startDate')  startDate: string,
    @Query('endDate')  endDate: string,
    @Query('partsType')  partsType: string,
    @Query('serviceType')  serviceType: string,
    @Query('departmentCode')  departmentCode: string,
    @Query('criteria')  criteria: FFRGroupCriteria[],
    @Query('export') isExport: boolean,
  ): Observable < any > {
    return null;
  }

  @GET(`${ReportCenterApi.PART_DELIVERY_PREFIX}/line-status`)
  @Headers({ 'x-show-loading': true })
  getLineStatus(): Observable<any> {
    return null;
  }

  @GET(`${ReportCenterApi.PART_REPORT_V2_PREFIX}/mdp-report/list`)
  @Headers({ 'x-show-loading': true })
  getMdpReport(
    @Query('start_date')  startDate: string,
    @Query('date_type')  dateType: string,
    @Query('category')  category: string,
  ): Observable<any> {
    return null;
  }

  @GET(`${ReportCenterApi.PART_REPORT_V2_PREFIX}/mdp-report/export`)
  @Headers({ isExportFile: true, 'x-show-loading': true })
  exportMdpReportData(
    @Query('start_date')  startDate: string,
    @Query('date_type')  dateType: string,
    @Query('category')  category: string,
  ): Observable<any> {
    return null;
  }

  @GET(`/api/aftersales-website/accident-car/dashboards/insurance-car-lead-analysis`)
  getInsuranceAnalysisDashboard(@Query('filter') filter: DashboardQueryParams): Observable<any> {
    return null;
  }

  @GET(`${ReportCenterApi.PART_REPORT_V2_PREFIX}/mdp-report/details/export`)
  @Headers({ isExportFile: true, 'x-show-loading': true })
  exportMdpReportDetail(
    @Query('start_date')  startDate: string,
    @Query('date_type')  dateType: string,
  ): Observable<any> {
    return null;
  }

  @POST(`/api/sales-website/ibg/access/summary/list`)
  getIbgReportList(@Body param: Object): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/ibg/access/summary/download')
  @Headers({ isExportFile: true })
  getIbgReportFile(@Body filter: any) {
    return null;
  }

  @GET(`/api/aftersales-website/accident-car/dashboards/leads-operation`)
  getLeadData(@Query('filter') filter: DashboardQueryParams): Observable<any> {
    return null;
  }

  @GET(`/api/aftersales-website/accident-car/dashboards/leads`)
  getLeadOverallData(@Query('filter') filter: DashboardQueryParams): Observable<IDashboardLeadOverallInfo> {
    return null;
  }
  @GET('/api/aftersales-website/accident-car/dashboards/trend-analysis')
  getTrendAnalysisDashboard(
    @Query('filter')  filter: DashboardQueryParams
  ): Observable<any> {
    return null;
  }

  @GET('/api/aftersales-website/accident-car/dashboards/leads-count-analysis')
  getLeadAnalysisDashboard(
    @Query('filter') filter: DashboardQueryParams
  ): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftersales/reports/add-on-detail-report-filter')
  getAddonDetailFilter(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftersales/reports/add-on-and-inspection-lost-sales-win-back-report-filter')
  getAddonLostSalesFilter(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftersales/reports/add-on-detail-report-technician')
  getAddonDetailTechniciansFilter(
    @Query('start') start: string,
    @Query('end') end: string,
  ): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftersales/reports')
  getReportData(
    @Query('reportName') name: string,
    @Query('filter') filter: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftersales/reports/assessment-period-config/:type')
  getReportsConfig(
    @Path('type') type: string): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftersales/reports')
  @Headers({ isExportFile: true, 'x-show-loading': true })
  downloadReportData(@Query('reportName') name: string,
                     @Query('filter') filter: string,
                     @Query('export') shouldExport: boolean
  ): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/aftersales/reports/add-on-summary-report-filter')
  getAddonSummaryFilter(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/dealer-allowance')
  getDealerAllowanceData(@Query('year') year: string,
                         @Query('rightType') rightType: string): Observable<DealerAllowance> {
    return null;
  }

}

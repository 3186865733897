import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';

const getValue = (value, params) => {
  let result = value;
  if (value instanceof Function) {
    result = value.call(null, params);
  }
  return result;
};

@Component({
  template: `
    <span class="text-button" (click)="onClick()" *ngIf="isVisible" >{{text}}</span>
  `,
  styleUrls: ['./text-button.style.scss'],
})
export class TextButtonComponent implements AgRendererComponent {
  params;
  text: string = '';

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const cellRendererParams = params.colDef.cellRendererParams;
    this.text = getValue(cellRendererParams.text, params);
  }

  get isVisible() {
    return this.params.colDef.cellRendererParams.isVisible(this.params);
  }

  onClick() {
    this.params.column.colDef.cellRendererParams.onClick(this.params);
  }

  refresh(params: any): boolean {
    return false;
  }
}

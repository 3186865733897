import { Pipe, PipeTransform } from '@angular/core';
import { createGetPercent } from '../../_common/utils/number';

const getPercent = createGetPercent(2);

@Pipe({ name: 'decimalToPercent' })
export class DecimalToPercentPipe implements PipeTransform {
  transform(decimal: number): string {
    return getPercent(decimal);
  }
}

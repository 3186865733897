import { Injectable } from '@angular/core';
import { Body, GET, POST, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';

import { ApprovalAuthorization } from '../models/authorization';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApprovalAuthorizationApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/v2/approval-flow-config/authorization')
  getAuthorization(): Observable<ApprovalAuthorization> {
    return null;
  }

  @GET('/api/v2/approval-flow-config/group-authorization')
  getGroupAuthorization(@Query('dealer_group_group_id') dealerGroupGroupId: number,
                        @Query('region_group_id') regionGroupId: number,
                        @Query('dealer_group_id') dealerGroupId: number): Observable<ApprovalAuthorization> {
    return null;
  }

  @POST('/api/v2/approval-flow-config/authorization')
  setAuthorization(@Body data): Observable<any> {
    return null;
  }
}

import { DecimalPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { fixNumber } from '../utils/format-string';

@Pipe({ name: 'toNumber' })
export class NumberPipe implements PipeTransform {
  transform(input: any): number {
    return Number(input) || 0;
  }
}

@Pipe({ name: 'percentage' })
export class PercentagePipe implements PipeTransform {
  transform(input: any): string {
    return `${((Number(input) || 0) * 100).toFixed(2)}%`;
  }
}

@Pipe({ name: 'calcPercentage' })
export class CalcPercentagePipe implements PipeTransform {
  transform(dividend: any, divisor: any): string {
    const percentage = _.isNumber(dividend) && _.isNumber(divisor) && dividend && divisor ?
      Math.round(dividend / divisor * 10000) / 100 : '--';
    return `${percentage}%`;
  }
}

@Pipe({ name: 'divide' })
export class DividePipe implements PipeTransform {
  transform(input: any, dividend: number): string {
    return ((Number(input) || 0) / dividend).toString();
  }
}

@Pipe({ name: 'rounding' })
export class RoundingPipe implements PipeTransform {
  transform(input: any, dividend: number): string {
    return ((Number(input) || 0) / dividend).toFixed().toString();
  }
}

@Pipe({ name: 'absolute' })
export class AbsolutePipe implements PipeTransform {
  transform(input: number): number {
    return Math.abs(input);
  }
}

@Pipe({ name: 'price' })
@Injectable()
export class PricePipe implements PipeTransform {
  constructor(
    protected decimalPipe: DecimalPipe,
  ) {
  }

  transform(input: number, precision: number = 2, locale: string = 'en-US', prefix: string = '', suffix: string = ''): string {
    return `${prefix}` +
      `${this.decimalPipe.transform((Number(input) || 0).toFixed(precision), `1.${precision}-${precision}`, locale)}` +
      `${suffix}`;
  }
}

@Pipe({ name: 'cost' })
@Injectable()
export class CostPipe implements PipeTransform {
  constructor(
    protected decimalPipe: DecimalPipe,
  ) {
  }

  transform(input: number, precision: number = 4, locale: string = 'en-US', prefix: string = '', suffix: string = ''): string {
    return `${prefix}` +
      `${this.decimalPipe.transform((Number(input) || 0).toFixed(precision), `1.${precision}-${precision}`, locale)}` +
      `${suffix}`;
  }
}

@Pipe({ name: 'emptyOrZero' })
export class EmptyOrZeroPipe implements PipeTransform {
  transform(input: any): number {
    return (_.isNil(input) || input === 0) ? 0 : input;
  }
}

@Pipe({ name: 'emptyToDash' })
export class EmptyToDashPipe implements PipeTransform {
  transform(input: any): number {
    return (_.isNil(input) || _.isEmpty(input) || input === 0) ? '-' : input;
  }
}

@Pipe({
  name: 'percentageOrEmpty',
})
export class PercentageOrEmptyPipe implements PipeTransform {

  transform(value: any, type: string): string {
    const v = value * 100;
    if (_.isNaN(v) || type !== '%') {
      return value;
    }
    return `${fixNumber(v, 2)}%`;
  }
}

@Pipe({
  name: 'priceOrEmpty',
})
export class PriceOrEmptyPipe implements PipeTransform {
  constructor(
    protected decimalPipe: DecimalPipe,
  ) {
  }

  transform(value: any): string {
    if (_.isNaN(Number(value))) {
      return value;
    }
    return this.decimalPipe.transform((Number(value) || 0).toFixed(2), `1.${2}-${2}`);
  }
}

@Pipe({ name: 'fixNumber' })
export class FixNumberPipe implements PipeTransform {
  transform(input: any, precision: number): number {
    return fixNumber(input, precision);
  }
}

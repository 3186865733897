import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SalesLeadModalContent } from '../sales-lead-modal/sales-lead-modal.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormUtilService } from '../../../../_common/services/form-util.service';
import { LOST_REASON, LOST_REASON_FOR_USED_CAR } from '../../shared/constant/lead';

@Component({
  selector: 'app-close-lead-modal',
  styleUrls: ['./close-lead-modal.style.scss'],
  templateUrl: './close-lead-modal.template.html',
})
export class CloseLeadModalComponent implements SalesLeadModalContent, OnInit {
  @Input() leadIds = [];
  @Input() isUsedCarLeadDetailFlag = false;

  closeReasons = [];
  selectedReason: FormControl = new FormControl(null, Validators.required);
  comments: FormControl = new FormControl(null);
  isInvalid$: Subject<any> = new BehaviorSubject(true);

  constructor(private formUtilService: FormUtilService) {}

  ngOnInit(): void {
    this.selectedReason
      .valueChanges
      .subscribe(() => this.isInvalid$.next(this.selectedReason.invalid));
    this.formUtilService.markAsTouched$.subscribe((value => value ? this.markControlsAsTouched() : this.markControlsAsUnTouched()));
    this.closeReasons = this.isUsedCarLeadDetailFlag ? LOST_REASON_FOR_USED_CAR : LOST_REASON;
  }

  getFormValue() {
    return {
      closeLeadReason: this.selectedReason.value,
      comments: this.comments.value,
      leadIds: this.leadIds,
    };
  }

  markControlsAsTouched() {
    this.selectedReason.markAsTouched();
  }

  markControlsAsUnTouched() {
    this.selectedReason.markAsUntouched();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SHARE_RANGE, STOCK_ORDER_STATUS } from '../constants/vehicle';
import { VEHICLE_CHANNEL_STATUS } from '../../vehicle/constants/vehicle';
import { CHANNELS, LEAD_STATUS } from '../constants/common';
import {
  ProductTypeDescription,
  MessageStatusMap,
  ContractEndDateTagFilterOptionsMap,
} from 'app/customer-lead-management/lead-management/sales-lead/list/retentionUtils';
import { formatFilterValue, formatFilterText } from 'app/vehicle/utils';
import { REPAYMENT_STATUS } from '../../customer-lead-management/lead-management/shared/constant/lead';

const repaymentStatusMap = _.reduce(REPAYMENT_STATUS.values, (result, item) => {
  return {
    ...result,
    [item.value]: item.text,
  };
}, {});

@Pipe({
  name: 'vehicleFilter',
})
// tslint:disable-next-line:class-name
export class vehicleFilterPipe implements PipeTransform {
  transform(filter: any, args?: any): string {
    const arr = [];
    _.forIn(filter, (value, key) => {
      arr.push({ key, value });
    });

    const data = _.sortBy(arr, o => {
      return !_.isEmpty(MAPPING[o.key]) && !_.isNil(MAPPING[o.key]) && !_.isNil(_.get(MAPPING[o.key], 'order'))
        ? MAPPING[o.key].order : '';
    });

    const result = [];
    _.forEach(data, item => {
      const target = !_.isNil(MAPPING[item.key]) && !_.isEmpty(MAPPING[item.key]) ? MAPPING[item.key] : {};
      if (!_.isEmpty(target)) {
        result.push(`${target.text} 【${target.value ? target.value(item.value) : item.value}】`);
      }
    });
    const str = result.join(' +') || '未选择';
    return args ? str.replace('实际到达日期', '物流到达日期') : result.join(' +') || '未选择';
  }
}

const handleDate = (v: { from?: number, to?: number }) => {
  if (!v.from) {
    return `  - ${moment(v.to).format('YYYY-MM-DD')}`;
  }
  if (!v.to) {
    return `${moment(v.from).format('YYYY-MM-DD')} -  `;
  }
  return `${moment(v.from).format('YYYY-MM-DD')} - ${moment(v.to).format('YYYY-MM-DD')}`;
};
const handleRange = (v: { from?: number, to?: number }) => {
  if (!v.from) {
    return `  - ${(v.to)}`;
  }
  if (!v.to) {
    return `${(v.from)} -  `;
  }
  return `${(v.from)} - ${(v.to)}`;
};
const MAPPING = {
  origin: {
    order: 1,
    text: '来源',
    value: (v: string[]) => v.join(','),
  },
  cesar_status: {
    order: 2,
    text: '生产号',
    value: (v: string[]) => {
      const object = {
        notFound: 'CESAR问题车辆',
        others: '其他车辆',
      };
      return formatFilterText(v, object);
    },
  },
  order_status: {
    order: 3,
    text: '车辆状态',
    value: (v: string[]) => {
      return formatFilterValue(v, STOCK_ORDER_STATUS);
    },
  },
  brand: {
    text: '品牌',
    value: (v: string[]) => v.join(','),
    order: 4,
  },
  type_class: {
    text: '车系',
    value: (v: string[]) => v.join(','),
    order: 5,
  },
  model_type: {
    text: '车型',
    value: (v: string[]) => v.join(','),
    order: 6,
  },
  type_class_cn: {
    text: 'BM4',
    value: (v: string[]) => v.join(','),
    order: 7,
  },
  variant_baumuster_nst: {
    order: 8,
    text: 'NST',
  },
  nst_cn: {
    text: '车款细分',
    value: (v: string[]) => v.join(','),
    order: 9,
  },
  productType: {
    text: '汽车金融/汽车租赁',
    value: (v: string[]) => {
      return formatFilterText(v, ProductTypeDescription);
    },
  },
  offerMessageStatus: {
    text: '信息发送状态',
    value: (v: string[]) => {
      return formatFilterText(v, MessageStatusMap);
    },
  },
  repaymentStatus: {
    text: '展期/提前结清',
    value: (v: string[]) => formatFilterText(v, repaymentStatusMap),
  },
  contractEndDateTag: {
    text: '跟进提醒',
    value: (v: string[]) => {
      return formatFilterText(v, ContractEndDateTagFilterOptionsMap);
    },
  },

  description: {
    text: '车辆说明',
    order: 10,
  },
  model_year: {
    text: '车型年份',
    order: 11,
  },
  change_year: {
    text: '变更年份',
    order: 12,
  },
  vehicle_id_no_vin: {
    text: '美版底盘号（VIN）',
    order: 13,
  },
  paint_code: {
    text: '颜色代码',
    order: 14,
  },
  paint: {
    text: '颜色',
    order: 15,
  },
  paint_dpp: {
    text: '经销商报价（颜色）',
    value: handleRange,
    order: 16,
  },
  trim_code: {
    text: '内饰代码',
    order: 17,
  },
  trim: {
    text: '内饰',
    order: 18,
  },
  trim_dpp: {
    text: '经销商报价（内饰）',
    value: handleRange,
    order: 19,
  },
  standard_dpp: {
    text: '标准经销商报价',
    value: handleRange,
    order: 20,
  },
  optional_options_price: {
    text: '原厂选装件经销商报价',
    order: 21,
  },
  optional_factory_options_code: {
    text: '原厂选装件代码',
    order: 22,
  },
  optional_factory_options_code_description: {
    text: '原厂选装件描述',
    order: 23,
  },
  total_dpp: {
    order: 24,
    text: '实际经销商报价',
    value: handleRange,
  },
  confirmed_production_date: {
    order: 25,
    text: '预计生产日期',
    value: handleDate,
  },
  expected_arrival_date_at_local_port: {
    order: 26,
    text: '预计到达本地港口日期',
    value: handleDate,
  },
  actual_arrival_date_at_local_port: {
    order: 27,
    text: '实际到达本地港口日期',
    value: handleDate,
  },
  stock_age: {
    order: 28,
    text: '库龄（天）',
  },
  out_bound_invoice_date_status: {
    order: 29,
    text: '批发开票日期',
    value: (v: string[]) => {
      const options = [
        { text: '已批发开票', value: 'found' },
        { text: '未批发开票', value: 'notFound' },
      ];
      return formatFilterValue(v, options);
    },
  },
  actual_arrived_date: {
    order: 30,
    text: '实际到达日期',
    value: handleDate,
  },
  share_range: {
    order: 31,
    text: '共享状态',
    value: (v: string[]) => {
      return formatFilterValue(v, SHARE_RANGE);
    },
  },
  mbe_available: {
    order: 32,
    text: '电商销售',
    value: (v: string[]) => {
      return formatFilterText(v, VEHICLE_CHANNEL_STATUS);
    },
  },
  logistic_status: {
    order: 33,
    text: '物流状态',
  },
  logistic_status_definition: {
    order: 34,
    text: '物流状态描述',
  },

  dealer_pdi_ok_date: {
    order: 35,
    text: 'PDI日期（最近一次）',
    value: handleDate,
  },
  temporary_reserved: {
    order: 36,
    text: '暂时预留',
    value: (v: string[]) => {
      const options = [
        { text: '是', value: 'reserved' },
        { text: '否', value: 'notReserved' },
      ];
      return formatFilterValue(v, options);
    },
  },
  temporary_reserved_sales_consultant: {
    order: 37,
    text: '预留请求来源',
  },
  temporary_reserved_date: {
    order: 38,
    text: '暂时预留日期',
    value: handleDate,
  },
  remarks_by_sales_admin: {
    order: 39,
    text: '销售行政备注',
  },
  display_vehicle: {
    order: 40,
    text: '展车',
    value: (v: string[]) => {
      const options = [
        { text: '是', value: 'Yes' },
        { text: '否', value: 'No' },
      ];
      return formatFilterValue(v, options);
    },
  },
  comment: {
    text: '备注',
    order: 41,
  },
  vip: {
    order: 42,
    text: 'VIP',
    value: (v: boolean) => {
      return v ? 'VIP' : '其他';
    },
  },
  fullName: {
    order: 43,
    text: '姓名',
    value: v => {
      return v.filterType === 'NORMAL' ? v.value : '未填写';
    },
  },
  mobile: {
    order: 44,
    text: '手机号码',
    value: v => {
      return v.filterType === 'NONE' ? '未填写' : v.value;
    },
  },
  brandDescriptionZH: {
    order: 45,
    text: '品牌',
    value: (v: string[]) => v.join(','),
  },
  vehicleClassDescriptionZH: {
    order: 46,
    text: '车系',
    value: (v: string[]) => v.join(','),
  },
  interestedVehicle: {
    order: 47,
    text: '意向车',
    value: v => {
      return v.filterType === 'NONE' ? '未填写' : v.value;
    },
  },
  grade: {
    order: 48,
    text: '级别',
    value: (v: string[]) => v.join(','),
  },
  leadStatus: {
    order: 49,
    text: '状态',
    value: (v: string[]) => {
      return formatFilterValue(v, LEAD_STATUS);
    },
  },
  channel: {
    order: 50,
    text: '初始渠道',
    value: (v: string[]) => {
      return formatFilterValue(v, CHANNELS);
    },
  },
  leadSource: {
    order: 51,
    text: '来源',
    value: (v: string[]) => v.join(','),
  },
  leadType: {
    order: 52,
    text: '来源细分',
    value: (v: string[]) => v.join(','),
  },
  nextFollowUpDate: {
    order: 53,
    text: '下次跟进',
    value: handleDate,
  },
  followUps: {
    order: 54,
    text: '跟进次数',
    value: v => {
      let followUps = '';
      if (_.includes(v, ',')) {
        let followUpDate = [];
        const formatDate = [];
        followUpDate = v.split(',');
        followUpDate.forEach(item => {
          let a = item;
          if (_.includes(item, 'Range:')) {
            a = _.trimStart(item, 'Range:');
          }
          formatDate.push(moment(Number(a)).format('YYYY-MM-DD'));
        });
        if (formatDate[1] === '1970-01-01') {
          followUps = `${(formatDate[0])} —— `;
        } else if (formatDate[0] === '1970-01-01') {
          followUps = ` —— ${(formatDate[1])}`;
        } else {
          followUps = `${(formatDate[0])} —— ${(formatDate[1])}`;
        }
      } else {
        followUps = v;
      }
      return followUps;
    },
  },
  nextAppointmentTime: {
    order: 55,
    text: '预约进店',
    value: handleDate,
  },
  nextAppointmentStatus: {
    order: 56,
    text: '进店状态',
    value: (v: string[]) => {
      const object = {
        WAITING: '待进店',
        ARRIVED: '已进店',
        OVERDUE: '未进店',
        Delivered: '无',
        null: '无',
      };
      return formatFilterText(v, object);
    },
  },
  lastShowroomVisitDate: {
    order: 57,
    text: '上次到店',
    value: handleDate,
  },
  leadCreateTime: {
    order: 58,
    text: '线索创建',
    value: handleDate,
  },
  cdoValue: {
    order: 59,
    text: '客户发展专员',
    value: (v: string[]) => v.join(','),
  },
  ScNames: {
    order: 60,
    text: '销售顾问',
    value: (v: string[]) => v.join(','),
  },
  variantDescription: {
    order: 61,
    text: '现有车型',
    value: v => {
      return v.filterType === 'NONE' ? '未填写' : v.value;
    },
  },
  carAge: {
    text: '车龄',
    value: (v: { from?: number, to?: number }) => `${(v.from)}个月 - ${(v.to)}个月`,
    order: 62,
  },
  cecValue: {
    order: 63,
    text: '所属经销商',
    value: (v: string[]) => v.join(','),
  },
  comments: {
    text: '备注',
    order: 64,
  },
  topping: {
    text: '置顶',
    order: 65,
    value: (v: string[]) => {
      const object = {
        1: '已置顶',
        0: '未置顶',
      };
      return formatFilterText(v, object);
    },
  },
  vehicle_usage: {
    text: '车辆用途',
    order: 66,
    value: (v: string[]) => {
      const object = {
        SPECIAL_VEHICLE: '特殊车辆',
        IN_STORE_VEHICLE: '店内车辆',
        ORDER_VEHICLE: '订单车辆',
        EXHIBIT_VEHICLE: '展车',
      };
      return formatFilterText(v, object);
    },
  },
  sales_status: {
    text: '销售状态',
    order: 67,
    value: (v: string[]) => {
      const object = {
        SOLD: '订单',
        UN_SOLD: '未售',
      };
      return formatFilterText(v, object);
    },
  },
  dealer_actual_arrived_date: {
    order: 68,
    text: '到店日期',
    value: handleDate,
  },
  pdi_status: {
    order: 69,
    text: '最新PDI状态',
    value: (v: string[]) => {
      const object = {
        APPROVED: '合格',
        REJECTED: '不合格',
        NO_RESULT: '未完成',
      };
      return formatFilterText(v, object);
    },
  },
  estimated_arrived_date: {
    order: 70,
    text: '预计到店日期',
    value: handleDate,
  },

};

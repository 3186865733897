<div *ngIf="!data" class="image-holder">
  <img alt="" src="../../../../../../assets/role-privilege/diagonal-line.png"/>
</div>
<input
  *ngIf="data && data.type === 'radio'"
  type="radio"
  class="app-authorisation-cell_radio"
  [name]="groupName"
  [value]="value"
  [disabled]="data.disable"
  [checked]="radioChecked"
  (click)="onRadioClick()"
/>
<input
  *ngIf="data && data.type === 'checkbox'"
  type="checkbox"
  class="app-authorisation-cell_checkbox"
  [name]="groupName"
  [value]="value"
  [checked]="checkboxChecked"
  [disabled]="data.disable"
  (click)="onCheckBoxClick()"
/>
<p *ngIf="data && data.tips" class="tips">{{ data.tips }}</p>

import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { FeatureToggleService, DealerIntegrationToggleService } from '@otr/website-common';
import * as _ from 'lodash';

@Injectable()
export class PartsReportGuard implements CanActivate, CanActivateChild {
  constructor(
    private featureToggleService: FeatureToggleService,
    private dealerIntegrationToggleService: DealerIntegrationToggleService,
    private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<any> | Observable<boolean> | boolean {
    const toggles = this.dealerIntegrationToggleService.integrationToggles;
    const reportToggles = _.find(toggles, { name: 'CAP_POWERBI_REPORT_ENABLE' });
    if (reportToggles) {
      if (!reportToggles.enabled) {
        this.router.navigate(['report-center/parts/report-maintenance']);
      } else {
        return true;
      }
    } else {
      return true;
    }

  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<any> | Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }
}

import {GET, Headers, Query, RebirthHttp, RequestOptions} from 'rebirth-http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class TokenApi extends RebirthHttp {

  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/one-time-token')
  @RequestOptions({ responseType: 'text' })
  public getOneTimeToken(
    @Query('expirationTime') expirationTime: number,
  ): Observable<any> {
    return null;
  }

}

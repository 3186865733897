import { toEnum } from '../../_common/utils/enum';

export const deafaultSelection = ['启用', '停用', '作废'];
export const redWaringUrl = '../../../../assets/common/red-warning.svg';

export enum DedicatedTechnicalReminderStatus {
  INITIAL = 'INITIAL',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  EXPIRED = 'EXPIRED',
  SUPERSEDED = 'SUPERSEDED'
}

export const BRAND_OPTIONS = [
  { text: 'AMG', value: 'AMG' },
  { text: '梅赛德斯-奔驰乘用车', value: 'MB-PKW' },
  { text: 'smart', value: 'SMART' },
];

export const dedicatedTechnicalReminderStatusOptions = [
  {
    text: '未启用',
    value: DedicatedTechnicalReminderStatus.INITIAL,
  },
  {
    text: '已启用',
    value: DedicatedTechnicalReminderStatus.ENABLED,
  },
  {
    text: '已停用',
    value: DedicatedTechnicalReminderStatus.DISABLED,
  },
  {
    text: '已过期',
    value: DedicatedTechnicalReminderStatus.EXPIRED,
  },
  {
    text: '已作废',
    value: DedicatedTechnicalReminderStatus.SUPERSEDED,
  },
];

export const statusStyleMapping = [
  {
    value: DedicatedTechnicalReminderStatus.INITIAL,
    className: 'inactive-class',
  },
  {
    value: DedicatedTechnicalReminderStatus.ENABLED,
    className: 'active-class',
  },
  {
    value: DedicatedTechnicalReminderStatus.DISABLED,
    className: 'disabled-class',
  },
  {
    value: DedicatedTechnicalReminderStatus.EXPIRED,
    className: 'expired-class',
  },
  {
    value: DedicatedTechnicalReminderStatus.SUPERSEDED,
    className: 'canceled-class',
  },
];

export const eventOrderMapping = [
  {
    status: DedicatedTechnicalReminderStatus.INITIAL,
    next: [false, true, true],
  },
  {
    status: DedicatedTechnicalReminderStatus.ENABLED,
    next: [true, false, false],
  },
  {
    status: DedicatedTechnicalReminderStatus.DISABLED,
    next: [true, true, false],
  },
  {
    status: DedicatedTechnicalReminderStatus.EXPIRED,
    next: [true, true, false],
  }
];

export const operationAlertMapping = [
  {
    status: DedicatedTechnicalReminderStatus.ENABLED,
    title: '启用提醒',
    message: '确定启用此提醒吗？启用后不可再编辑，且经销商可在列表看到此提醒。',
    icon: '../../../../assets/common/attention.svg',
    confirmButtonText: '启用',
    promptWording: '*提醒命中规则取所有条件的交集'
  },
  {
    status: DedicatedTechnicalReminderStatus.DISABLED,
    title: '停用提醒',
    message: '确定要停用此提醒吗？停用后不可再启用，且经销商在列表可看到此提醒。',
    icon: redWaringUrl,
    confirmButtonText: '停用',
  },
  {
    status: DedicatedTechnicalReminderStatus.SUPERSEDED,
    title: '作废提醒',
    message: '确定作废此提醒吗？作废后不可再启用，且经销商在列表看不到此提醒。',
    icon: redWaringUrl,
    confirmButtonText: '作废',
  },
];

export const operationStatusMapping = toEnum({
  ENABLED: { text: '启用' },
  DISABLED: { text: '停用' },
  SUPERSEDED: { text: '作废' },
});

export const finOrVinTip = `*VIN/FIN长度必须为17位，允许上传的格式包括：\n1.完整VIN/FIN号:LB1WG3E18J8031474\n2.第4位到第9位填写baumuster，其余部分用*代替：***WG3E18********\n（VIN/FIN错误示例：\nL**WG3E18******74，格式错误将导致无法解析）`;
export const engineTip = `*发动机号长度必须为14位，允许上传的格式包括：\n1.完整发动机号:12345678901234\n2.填写前六位，其余部分用*代替：123456********\n（发动机号错误示例：123456******34，格式错误将导致无法解析）`;
export const splitText = ' 到 ';

import * as _ from 'lodash';

export const OTHER_DESCRIPTION = {
  description_en: 'other',
  description_zh: '其他',
};

const OTHER_MODEL = {
  ...OTHER_DESCRIPTION,
  variants: [{
    description_en: 'other',
    description_zh: '其他',
  }],
};

const wrapClass = classType => {
  const models = [...classType.models, OTHER_MODEL];
  return { ...classType, models };
};

const wrapBrand = brand => {
  if (_.get(brand, 'classes')) {
    const classes = [...brand.classes.map(wrapClass), {
      ...OTHER_DESCRIPTION,
      models: [OTHER_MODEL],
    }];
    return { ...brand, classes };
  }
  return brand;
};

export const wrapBrandsWithOther = brands => brands.map(wrapBrand);

export const wrapBrandsWithOtherForCurrentVehicles = brands => [
  ...brands.map(wrapBrand),
  {
    ...OTHER_DESCRIPTION,
    classes: [
      {
        ...OTHER_DESCRIPTION,
        models: [OTHER_DESCRIPTION],
      },
    ],
  },
];

const getDescription = infoItem => {
  const description = _.get(infoItem, 'description_zh') || _.get(infoItem, 'description_en');
  return description === OTHER_DESCRIPTION.description_zh ? null : description;
};

const getDescriptionWithBaumusterNst = (infoItem, queryParam) => {
  const queryItem = _.get(infoItem, queryParam);
  let description = _.get(queryItem, 'description_zh') || _.get(queryItem, 'description_en');
  if (_.get(infoItem, 'baumusterNst.baumuster')) {
    description = formatDescriptionWithBaumusterNst({ ..._.get(infoItem, 'baumusterNst'), ...queryItem });
  }
  return description === OTHER_DESCRIPTION.description_zh ? null : description;
};

export const formatDescriptionWithBaumusterNst = model =>
  `${_.get(model, 'description_zh') || _.get(model, 'description_en')}\t(${_.get(model, 'baumuster')}-${_.get(model, 'nst')})`;

export const getDescriptionFromVehicle = vehicle => vehicle && (
  getDescription(vehicle.variant) ||
  _.compact([
    getDescription(vehicle.brand),
    getDescription(vehicle.class),
    getDescription(vehicle.model),
  ]).join(' ')
);

export const getDescriptionFromVehicleWithBaumusterNst = vehicle => vehicle && (
  getDescriptionWithBaumusterNst(vehicle, 'variant') ||
  _.compact([
    getDescription(vehicle.brand),
    getDescription(vehicle.class),
    getDescriptionWithBaumusterNst(vehicle, 'model'),
  ]).join(' ')
);

export const getIconType = (value) => {
  if (value.test_drive_re_status === 'PRE_ASSIGN') {
    return '/assets/hall/assign.svg';
  }
  if (value.test_drive_re_status === 'ASSIGNED') {
    return '/assets/hall/re-assign.svg';
  }
  return '';
};

export const getClickAbleText = (value) => {
  const iconStatus = value.test_drive_re_status === 'ASSIGNED' && value.pe_name === '';
  if (iconStatus || value.test_drive_re_status === 'PRE_ASSIGN') {
    return '分配';
  }
  // const clickText = value.test_drive_re_status === 'ASSIGNED' && value.pe_name !== '';
  // const showText = ['TEST_DRIVING', 'TEST_DRIVE_DONE', 'TEST_DRIVE_CANCELED'];
  // if (clickText || showText.includes(value.test_drive_re_status)) {
  //   return value.pe_name;
  // }
  return value.pe_name;
};
export const getDisableText = (value) => {
  const showText = ['TEST_DRIVING', 'TEST_DRIVE_DONE', 'TEST_DRIVE_CANCELED'];
  return showText.includes(value.test_drive_re_status);
};

const isOther = description => description === OTHER_DESCRIPTION.description_zh
  || description === OTHER_DESCRIPTION.description_en;

const joinDescription = (brand, klass, model) => _.compact([
  brand,
  !isOther(brand) && klass,
  !isOther(klass) && model,
]).join(' ');

export const getDescriptionFromCurrentVehicle = currentVehicle => {
  const brand = currentVehicle.brand_zh || currentVehicle.brand_en;
  const klass = currentVehicle.class_zh || currentVehicle.class_en;
  const model = currentVehicle.model_zh || currentVehicle.model_en;
  return joinDescription(brand, klass, model);
};

export const getDescriptionFromComparingVehicle = comparingVehicle => {
  const brand = comparingVehicle.brand.description_zh || comparingVehicle.brand.description_en;
  const klass = comparingVehicle.class.description_zh || comparingVehicle.class.description_en;
  const model = comparingVehicle.model.description_zh || comparingVehicle.model.description_en;
  return joinDescription(brand, klass, model);
};

const getDescriptionByField = (vehicle, field) => {
  const description = _.get(vehicle, `${field}DescriptionZH`) || _.get(vehicle, `${field}DescriptionEN`);
  return description === OTHER_DESCRIPTION.description_zh ? null : description;
};

const getDescriptionByFieldWithBaumuster = (vehicle, field, isWithBaumuster = false) => {
  let description = _.get(vehicle, `${field}DescriptionZH`) || _.get(vehicle, `${field}DescriptionEN`);
  if (isWithBaumuster && _.get(vehicle, 'baumuster')) {
    description = `${_.get(vehicle, `${field}DescriptionZH`) || _.get(vehicle, `${field}DescriptionEN`)}
    \t(${_.get(vehicle, 'baumuster')}-${_.get(vehicle, 'nst')})`;
  }
  return description === OTHER_DESCRIPTION.description_zh ? null : description;
};

export const getDescriptionFromFlattenVehicle = (vehicle, isWithBaumuster = false) => vehicle && (
  getDescriptionByFieldWithBaumuster(vehicle, 'variant', isWithBaumuster) ||
  _.compact([
    getDescriptionByField(vehicle, 'brand'),
    getDescriptionByField(vehicle, 'vehicleClass'),
    getDescriptionByFieldWithBaumuster(vehicle, 'model', isWithBaumuster),
  ]).join(' ')
);

export function getFirstInterestedVehicleDescription(interestedVehicles, isBaumusterNstShow = false) {
  const firstInterestedVehicle = _.find(interestedVehicles,
    interestedVehicle => !_.isEmpty(interestedVehicle));
  const formatVehicle = isBaumusterNstShow
    ? getDescriptionFromVehicleWithBaumusterNst(firstInterestedVehicle) : getDescriptionFromVehicle(firstInterestedVehicle);
  return firstInterestedVehicle
    ? formatVehicle : '暂无意向车';
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { CtiApi } from '../../../_common/api/cti';
import { ModalMessageService, CurrentUserService, SpinnerService } from '@otr/website-common';
import { CtiCallService } from '../../../_common/services/cti-call.service';
import { LeadService } from 'app/customer-lead-management/lead-management/sales-lead/services/lead.service';
import { isDormantLead, isOpenLead } from '../../../_common/utils/lead';
@Component({
  selector: 'app-call-end-modal',
  templateUrl: './call-end-modal.component.html',
  styleUrls: ['./call-end-modal.component.scss'],
  providers: [],
})
export class CallEndModalComponent implements OnInit {
  currentCustomer: any = { full_name: '', dealer_name: '', id: '' };
  dropDownToggle: boolean = false;
  @Input() messageInfo;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  customerList = [];
  comments: null;
  isHaveCecId: boolean = false;
  showHaveUnclosedSalesLeads: boolean = false;
  trackSalesLeadsType = false;
  currentCustomerLeads = [];
  mobile;
  get confirmBtnDisable() {
    return this.trackSalesLeadsType && !this.currentCustomerLeads.some(currentCustomerLead => currentCustomerLead.checked);
  }
  get cecId() {
    return this.currentUserService.getCecGroupId();
  }

  get leadDesc() {
    const lead = this.currentCustomerLeads;
    const interestedVehicle = _.get(lead, 'interested_vehicles[0]');
    const brand = _.get(interestedVehicle, 'brand.description_zh');
    const iClass = _.get(interestedVehicle, 'class.description_zh');
    const variant = _.get(interestedVehicle, 'variant.description_zh');
    const desc = `${brand ? brand : ''}${iClass ? ` ${iClass}` : ''}${variant ? ` ${variant}` : ''}`;
    return {
      B: desc || '暂无意向车',
    };
  }

  cdoCheck: boolean = false;
  originOptions: [];
  constructor(
    private ctiApi: CtiApi,
    private ctiCallService: CtiCallService,
    private modalMessageService: ModalMessageService,
    private currentUserService: CurrentUserService,
    private spinnerService: SpinnerService,
    public leadService: LeadService
) {}

  ngOnInit(): void {
    this.mobile = this.messageInfo.phoneNum;
    this.isHaveCecId = _.get(this.messageInfo, 'cecId') ? true : false;
    this.customerList = _.get(this.messageInfo, 'allCustomerList');
    this.currentCustomer = _.head(this.customerList);
    this.getCurrentCustomer();
  }

  getCurrentCustomer() {
    this.leadService.searchCustomer('PHONE_NUMBER', this.mobile, this.cecId)
      .pipe(this.spinnerService.loading())
      .subscribe((res) => {
        this.originOptions = res;
        this.getCurrentCustomerLeads();
      });
  }

  getCurrentCustomerLeads() {
    this.trackSalesLeadsType = false;
    const currentCustomer = (this.originOptions as any).filter(customer => this.currentCustomer.id === customer.id)[0];
    if (currentCustomer) {
      const fn = (arr = []) => {
        const lead =  _.find(arr, (leadItem) => {
          return (
            isOpenLead(leadItem) &&
            (!leadItem.cdoId || (this.currentUserService.getCurrentUser().userId === leadItem.cdoId)) &&
            (
              (
                isDormantLead(_.get(leadItem, 'current_channel')) &&
                !_.isEmpty(_.get(leadItem, 'owner_sales_consultant_id', ''))
              ) ||
              _.isEmpty(_.get(leadItem, 'owner_sales_consultant_id', ''))
            )
          );
        });
        if (lead) {
          return [lead];
        }
        return [];
      };
      this.currentCustomerLeads = [...fn(currentCustomer.leads)];
      this.resetCurrentCustomerLeadsChecked();
    }
  }

  resetCurrentCustomerLeadsChecked() {
    this.currentCustomerLeads = this.currentCustomerLeads.map(currentCustomerLead => {
      return {
        ...currentCustomerLead,
        // model_d时 选中 需清空选中的checkbox
        // model_b时 选是的时候 因隐藏了下方的lead线索checkbox 需默认置为true
        checked: this.trackSalesLeadsType
      };
    });
  }

  toggleChecked(lead) {
    this.currentCustomerLeads = this.currentCustomerLeads.map(currentCustomerLead => {
      if (lead._id === currentCustomerLead._id) {
        currentCustomerLead.checked = !currentCustomerLead.checked;
      }
      return currentCustomerLead;
    });
  }

  showDropDownList(event) {
    event.stopPropagation();
    this.dropDownToggle = !this.dropDownToggle;
  }

  chooseCurrentCustomer(param) {
    this.currentCustomer = param;
    this.dropDownToggle = false;
    this.getCurrentCustomerLeads();
  }

  saveAndBindMessage() {
    const callInfo = this.ctiCallService.getAfterCallInfo();
    const param = {
      customer_id: _.get(this.currentCustomer, 'id'),
      call_comment: this.comments,
      id: _.get(callInfo, 'id'),
      operator_id: _.get(callInfo, 'operator_id'),
      device_type: _.get(callInfo, 'device_type'),
      end_call_time: _.get(callInfo, 'end_call_time'),
      call_id: _.get(callInfo, 'call_id'),
      dealer_id: _.get(this.currentCustomer, 'dealer_id'),
    };
    this.ctiApi.saveCustomerOrPhoneNumber(param).subscribe(() => {
      if (this.trackSalesLeadsType) {
        this.change.emit({customer_id: param.customer_id, dealer_id: param.dealer_id, currentCustomerLeads: this.currentCustomerLeads});
      }
      this.close.emit();
    });
  }

  clickModalBody() {
    this.dropDownToggle = false;
  }

  closeModal() {
    this.close.emit();
  }
}

import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';

export type RequestListener = (request: HttpRequest<any>) => HttpRequest<any>;
export type ResponseListener = (
  request: HttpRequest<any>,
  response: HttpResponse<any> | HttpErrorResponse,
) => HttpResponse<any> | HttpErrorResponse;
export type Listener = RequestListener | ResponseListener;

export class ChainedRebirthInterceptor {
  protected listeners: {request: Array<any>, response: Array<any>} = {
    request: [],
    response: [],
  };

  /**
   * Register a listener
   *
   * @param  {"request" | "response"} type
   * @param {(request: any, response: any) => any} callback
   * @returns {number}
   */
  public register(
    type: 'request' | 'response',
    callback: Listener,
  ): void {
    this.listeners[type].push(callback);
  }

  /**
   * Unregister a listener
   *
   * @param {"request" | "response"} type
   * @param {(request: HttpRequest<any>, response?: (HttpResponse<any> | HttpErrorResponse)) =>
   *   (HttpRequest<any> | HttpResponse<any> | HttpErrorResponse)} callback
   */
  public unregister(
    type: 'request' | 'response',
    callback: Listener,
  ): void {
    const index: number = this.listeners[type].findIndex((listener: any): boolean => listener === callback);

    if (index > -1) {
      this.listeners[type] = this.listeners[type].filter((item, itemIndex) => itemIndex !== index);
    }
  }

  /**
   * Get all registered listeners
   *
   * @param {"request" | "response"} type
   * @returns {Array<any> | {request: Array<any>; response: Array<any>}}
   */
  public getListeners(type?: 'request' | 'response'): Array<Listener> | {request: Array<Listener>, response: Array<Listener>} {
    return _.isString(type) ? this.listeners[type] : this.listeners;
  }

  /**
   * Clear listeners
   *
   * @param {"request" | "response"} type
   */
  public clearListeners(type?: 'request' | 'response'): void {
    _.isString(type) ? (this.listeners[type] = []) : (this.listeners = { request: [], response: [] });
  }

  /**
   * Intercept a request and pass it through the listener chain
   *
   * @param {HttpRequest<any>} request
   * @returns {HttpRequest<any>}
   */
  public request(request: HttpRequest<any>): HttpRequest<any> {
    if (0 === this.listeners.request.length) {
      return request;
    }

    let newRequest: HttpRequest<any> = _.cloneDeep(request);
    this.listeners.request.forEach((callback: RequestListener): void => {
      newRequest = callback(newRequest);
      if (!(newRequest instanceof HttpRequest)) {
        throw new Error('Request listener failed to return a valid request of type "HttpRequest" to ChainedRebirthInterceptor.');
      }
    });

    return newRequest;
  }

  /**
   * Intercept a response and pass it through the listener chain
   *
   * @param {HttpResponse<any> | HttpErrorResponse} response
   * @param {HttpRequest<any>} request
   * @returns {HttpResponse<any> | HttpErrorResponse}
   */
  public response(response: HttpResponse<any> | HttpErrorResponse, request: HttpRequest<any>): HttpResponse<any> | HttpErrorResponse {
    if (0 === this.listeners.response.length) {
      return response;
    }

    let newResponse: HttpResponse<any> | HttpErrorResponse = _.cloneDeep(response);
    this.listeners.response.forEach((callback: ResponseListener): void => {
      newResponse = callback(request, newResponse);
      if (!(newResponse instanceof HttpResponse || newResponse instanceof HttpErrorResponse)) {
        throw new Error(
          'Response listener failed to return a valid response of type "HttpResponse | HttpErrorResponse" to ChainedRebirthInterceptor.',
        );
      }
    });

    return newResponse;
  }
}

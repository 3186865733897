import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  template: `
    <div class="tooltip-area">
      <i class="fa antd-question-circle-filled"
         [tooltip]="tooltipValue"
         placement="bottom">
      </i>
    </div>
  `,
  styleUrls: ['./tooltip.style.scss'],
})

export class TooltipComponent implements OnInit {
  @Input() tooltipValue: string;

  ngOnInit() {}
}

import { Injectable } from '@angular/core';
import { Body, GET, PATCH, Path, POST, Query, RebirthHttp, Headers } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Brand } from '../models/vehicle';

@Injectable()
export class OrderApi extends RebirthHttp {
  static ORDER_API_PREFIX: string = '/api/v2';

  constructor(http: HttpClient) {
    super(http);
  }

  @GET(`/api/sales-website/orders/:id`)
  getOrder(@Path('id') id: string): Observable<any> {
    return null;
  }

  @PATCH(`${OrderApi.ORDER_API_PREFIX}/orders/:id?profile`)
  updateOrderById(@Path('id') id: string,
                  @Body data: any): Observable<any> {
    return null;
  }

  @GET(`${OrderApi.ORDER_API_PREFIX}/orders/:orderId/invoice/alerts`)
  getInvoiceAlertMessage(@Path('orderId') orderId): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/orders')
  searchOrders(@Query('dealer_id') dealerId,
               @Query('criteria') criteria,
               @Query('filters') filters,
               @Query('index') index): Observable<any> {
    return null;
  }

  @GET(`${OrderApi.ORDER_API_PREFIX}/pre_orders`)
  searchPreOrders(@Query('dealer_id') dealerId,
                  @Query('criteria') criteria,
                  @Query('filters') filters,
                  @Query('index') index): Observable<any> {
    return null;
  }

  @PATCH(`${OrderApi.ORDER_API_PREFIX}/orders/:id`)
  updateOrder(@Path('id') id: string,
              @Body data: Object): Observable<any> {
    return null;
  }

  @POST('api/document/v2/generate/:template_id')
  getOrderContractDocument(@Path('template_id') templateId: number,
                           @Body body): Observable<any> {
    return null;
  }

  @GET(`${OrderApi.ORDER_API_PREFIX}/orders/commission_no_validation`)
  validateCommissionNo(@Query('order_id') orderId: string,
                       @Query('commission_no') commissionNo: string): Observable<any> {
    return null;
  }

  @PATCH(`${OrderApi.ORDER_API_PREFIX}/orders/:id/commission_no`)
  assignVehicle(@Path('id') orderId: string,
                @Body data: Object): Observable<any> {
    return null;
  }

  @GET('/api/pre_orders/brand')
  getPreorderBrands(): Observable<Array<Brand>> {
    return null;
  }

  @PATCH(`${OrderApi.ORDER_API_PREFIX}/orders/:order_id/to/preorder`)
  releaseVehicle(@Path('order_id') orderId: string): Observable<any> {
    return null;
  }

  @GET(`${OrderApi.ORDER_API_PREFIX}/orders/pagination`)
  getPaginationOrdersByLeadId(@Query('lead_id') leadId: string,
                              @Query('page') page: number,
                              @Query('size') size: number): Observable<any> {
    return null;
  }

  @GET(`/api/sales-website/mod-order/pagination`)
  getPaginationOrdersByLeadIdMod(@Query('lead_id') leadId: string,
                                 @Query('page') page: number,
                                 @Query('size') size: number): Observable<any> {
    return null;
  }

  @GET('/api/leads/:leadId')
  getLead(@Path('leadId') leadId): Observable<any> {
    return null;
  }

  @GET('/api/website/orders/status/:id')
  getAutoActiveQuotationById(@Path('id') id): Observable<any[]> {
    return null;
  }

  @GET('api/website/service-contract/quotations/:quotation_id/pdf')
  @Headers({ isExportFile: true })
  downloadPdfByQuotationId(@Path('quotation_id') quotationId: number, @Query('type') type: string): Observable<any> {
    return null;
  }

  @GET('api/document/v2/vehiclePdf/:commissionId')
  downloadPdfByCommissionId(@Path('commissionId') commissionId: string): Observable<any> {
    return null;
  }

  @POST('/api/document/v2/generate')
  downloadPdfByBrand(@Body data, @Query('brand') brand: any): Observable<any> {
    return null;
  }

  @POST('/api/document/v2/generate')
  fetchDocumentById(@Body data): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/orders/occ-as/invalid')
  fetchOrderFromOccAsInvalidStatus(@Body orderIds): Observable<any> {
    return null;
  }

  @POST('api/website/service-contract/auto-active-quotation/retrigger')
  retrigger(@Body data): Observable<any> {
    return null;
  }

  @POST('api/mbe-central-stock/batch-allocation')
  getListData(@Body data): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/mod-order/pages')
  searchAgentOrders(@Body body): Observable<any> {
    return null;
  }

  @POST(`/api/sales-website/mod-order/:orderId/open-invoice-signal`)
  uploadModelDOrderInvoice(@Path('orderId') orderId: string): Observable<any> {
    return null;
  }

  @GET(`/api/sales-website/mod-order/detail/:id`)
  getAgentOrderDetail(@Path('id') id: string): Observable<any> {
    return null;
  }

  @GET(`/api/sales-website/mod-order/incentive/:id`)
  getOrderCouponsDetail(@Path('id') id: string): Observable<any> {
    return null;
  }

  @GET(`api/sales-website/mod-order/detail/state/journey`)
  getAgentOrderHistory(@Query('mod_quotation_order_id') orderId: string): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/mod-wall-box-order/page')
  searchAgentWallBoxOrders(@Body body): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/mod-wall-box-order/export')
  @Headers({
    isExportFile: true,
  })
  downLoadAgentWallBoxOrders(@Body body): Observable<any> {
    return null;
  }

  @GET(`/api/sales-website/mod-order/oem-cancel/detail-id/:id`)
  getOrderOemCancelDetail(@Path('id') id: string): Observable<any> {
    return null;
  }
}

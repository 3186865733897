<ng-template [ngIf]="display">
  <select [ngStyle]="selectorStyle"
          [title]="tooltip"
          (ngModelChange)="onChange($event)"
          [ngModel]="value"
          class="input-control"
          [disabled]="isSelectDisabled"
          (keydown)="onKeyDown($event)"
          [ngClass]="{'input-error': errorMessage, 'value-color--empty': isEmptyValue()}">
    <option *ngFor="let option of options" [value]="option.value">{{option.text | lowercaseBrand}}</option>
  </select>
  <div *ngIf="errorMessage" class="error-message">
    {{errorMessage}}
  </div>
</ng-template>

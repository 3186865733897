<div class="header-container">
  <span (dblclick)="enableEditMode()" *ngIf="!isEditing" class="header-label">
    {{params.displayName}}
    <i class="fa antd-bars-outlined change-type"
       (click)="handleDisplayDataTypeGroups($event)"
       *ngIf="isEditable && !isEditing && !isRemovable">
    </i>
  </span>
  <input
    [value]="value"
    class="header-input"
    (change)="onChange($event)"
    *ngIf="isEditing"
    (blur)="handleBlur()"
  >
  <i class="fa antd-minus-circle-filled remove-column" *ngIf="isRemovable" (click)="removeColumn()"></i>
  <div *ngIf="isDisplayMenu" class="radio-buttons">
    <div class="radio-cover" (click)="handleHiddenDataTypeGroups()"></div>
    <app-radio [options]="options" (onChange)="handleRadioButtonChange($event)"
               [selectedOption]="selectedOption"></app-radio>
  </div>
</div>

import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { isEmptyValue } from '../../utils/common';

@Component({
  selector: 'app-column-cells',
  styleUrls: ['./row-panel.style.scss'],
  templateUrl: './column-cells.template.html',
})
export class ColumnCellsComponent {
  @Input() data: any;

  getClass(data) {
    return `col-md-${12 / (data.length)}`;
  }

  wrapWithParentheses(comment) {
    const trimValue = _.trim(comment);
    return (isEmptyValue(trimValue) || _.startsWith(trimValue, '(')) ? trimValue : `(${trimValue})`;
  }
}

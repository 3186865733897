import { Injectable } from '@angular/core';
import {Body, GET, POST, Query, RebirthHttp} from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PurchasedServiceContract } from '../model';
import {PdiConfirm} from '../../../../pdi/pdi';
import {WriteLogRequest} from '../../../as-lead/model';

@Injectable()
export class LeadManagementSalesServiceContractApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/sales-website/purchased-service-contracts')
  getServiceContract(@Query('fin') fin: string, @Query('dealer_id') dealerId: string): Observable<Array<PurchasedServiceContract>> {
    return null;
  }
  @POST('/api/sales-website/all-staff/products')
  queryProductList(@Body allStaffProductRequest: Object,
                   @Query('fetchFromLpo') fetchFromLpo: boolean,
                   @Query('fetchPurchased') fetchPurchased: boolean,
                   @Query('productType') productType: string): Observable<any> {
    return null;
  }
  @GET('/api/sales-website/all-staff/tyre')
  getTyre(@Query('fin') fin: string,
          @Query('gsCode') gsCode: string,
          @Query('spec') spec: string,
          @Query('brand') brand: string,
          @Query('loyaltyId') loyaltyId: string): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/all-staff/qr-code')
  queryQRCode(@Body allStaffQRCodeRequest: Object): Observable<any> {
    return null;
  }
}

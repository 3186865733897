import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

const GENDERS = [
  { text: '先生', value: 'Male' },
  { text: '女士', value: 'Female' },
];

@Pipe({ name: 'gender' })
export class GenderPipe implements PipeTransform {
  transform(genderType: string): number {
    return _.chain(GENDERS)
      .find((gender: any) => gender.value === genderType)
      .get('text', '')
      .value();
  }
}

<div class="date-range-container">
  <div class="title-area" *ngIf="!!title">
    <span class="title">{{title}}</span>
    <div class="tooltip-area" *ngIf="!!tooltipValue">
      <app-tooltip [tooltipValue]="tooltipValue"></app-tooltip>
    </div>
  </div>

  <span class="date start-date">
    <p-calendar
      #startDate
      [view]="view"
      dataType="string"
      [dateFormat]="dateFormat"
      [placeholder]="placeholder"
      [locale]="chineseCalendarConfig"
      [formControl]="startDateControl"
      [inputStyle]="startDateControl.invalid ? invalidStyle : {}"
      [maxDate]="(endDateControl.value ? endDateControl.value : startLimitValue) | dateObject"
      [minDate]="(startMinLimitValue? startMinLimitValue : calendarConfig.start.limitValue) | dateObject"
      [defaultDate]="calendarConfig.start.defaultValue | dateObject"
      [readonlyInput]='readonlyInput'
      (onBlur)="onStartDateBlur($event)"
    >
    </p-calendar>
    <div *ngIf="shouldShowOutOfDateRangeMessage(startDateControl)" class="formula-error">日期不在可选范围</div>
  </span>

  <span class="splinter"></span>

  <span class="date end-date">
    <p-calendar
      #endDate
      [view]="view"
      dataType="string"
      [dateFormat]="dateFormat"
      [placeholder]="placeholder"
      [locale]="chineseCalendarConfig"
      [formControl]="endDateControl"
      [inputStyle]="endDateControl.invalid ? invalidStyle : {}"
      [minDate]="(endMinLimitValue ? endMinLimitValue : startDateControl.value) | dateObject"
      [maxDate]="(endMaxLimitValue && endMaxLimitValue < calendarConfig.end.limitValue ? endMaxLimitValue : calendarConfig.end.limitValue) | dateObject"
      [defaultDate]="calendarConfig.end.defaultValue | dateObject"
      [readonlyInput]='readonlyInput'
      (onBlur)="onEndDateBlur($event)"
    >
    </p-calendar>
    <div *ngIf="shouldShowOutOfDateRangeMessage(endDateControl)" class="formula-error">日期不在可选范围</div>
  </span>
</div>

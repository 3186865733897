import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import * as _ from 'lodash';

@Component({
  selector: 'app-common-drag-to-upload',
  templateUrl: './drag-to-upload.component.html',
  styleUrls: ['./drag-to-upload.component.scss'],
})
export class DragToUploadComponent implements OnInit {

  @Input()
  fileTypes: string[] = ['csv'];
  @Input()
  uploadTip: string;
  @Input()
  uploadBottomTip: string;
  @Input()
  acceptFiletype: string = '*';
  @Input() htmlTemplate;

  @Output()
  selectedFile: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectingWrongFile: EventEmitter<any> = new EventEmitter<any>();

  uploader: FileUploader;
  hasDropZoneOver: boolean = false;

  get displayUploadTip() {
    return this.uploadTip || `请将要上传的${this.fileTypes.join('、')}文件拖动至这里`;
  }

  ngOnInit() {
    this.uploader = new FileUploader({ allowedFileType: this.fileTypes });
  }

  onFileDrop(files: File[]) {
    if (!this.isValidFile(files)) {
      this.selectingWrongFile.emit();
      return;
    }
    this.parseFile(files[0]);
  }

  fileOver(): void {
    this.hasDropZoneOver = true;
  }

  onChange(event) {
    const files = event.target.files;
    if (!this.isValidFile(files)) {
      this.selectingWrongFile.emit();
    } else {
      this.parseFile(files[0]);
    }
    event.target.value = '';
  }

  private isValidFile(files: File[]) {
    return files.length && _.includes(this.fileTypes, files[0].name.split('.').pop());
  }

  private parseFile(file: File) {
    this.selectedFile.emit(file);
  }
}

export enum DateFormat {
  DATE_TIME_MINUTE = 'yyyy-MM-dd HH:mm',
  SINGLE_DIGIT_HOUR = 'yyyy-MM-dd H:mm',
  MONTH_TIME_MINUTE = 'MM-dd HH:mm',
  MONTH_DAY = 'MM-dd',
  DATE = 'yyyy-MM-dd',
  DATE_DAY_FIRST = 'dd/MM/yyyy',
  DATE_TIME_SECOND = 'yyyy-MM-dd HH:mm:ss',
  DATE_NO_SPLIT = 'yyyyMMdd',
  TIME_HOUR_MINUTE = 'HH:mm',
}

export enum TimeUnit {
  SECOND = 1000,
  MINUTE = 1000 * 60,
  HOUR = 1000 * 60 * 60,
  DAY = 1000 * 60 * 60 * 24,
  WEEK = 1000 * 60 * 60 * 24 * 7,
}

export enum DateFormatType {
  DATE_TIME_SECOND = 'YYYY-MM-DD HH:mm:ss',
  DATE_TIME_MINUTE = 'YYYY-MM-DD HH:mm',
  DATE_TIME = 'YYYY-MM-DD',
  DATE_TIME_MINUTE_SPLIT = 'YYYY.MM.DD HH:mm',
}

export const TIME_LIST = [
  '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30',
  '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
  '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30',
  '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30',
  '24:00'
];

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import * as _ from 'lodash';

@Component({
  selector: 'app-common-drag-to-upload-with-tip',
  templateUrl: './drag-to-upload-with-tip.component.html',
  styleUrls: ['./drag-to-upload-with-tip.component.scss'],
})
export class DragToUploadWithTipComponent implements OnInit {

  @Input()
  fileTypes: string[] = ['csv'];
  @Input()
  uploadTip: string;
  @Input()
  multiple: boolean = false;
  @Input()
  uploadAccept: string = '*';

  @Input()
  statisticalMonthNotification: string;

  @Output()
  selectedFile: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectingWrongFile: EventEmitter<any> = new EventEmitter<any>();

  uploader: FileUploader;
  hasDropZoneOver: boolean = false;

  get displayUploadTip() {
    return this.uploadTip || '';
  }

  ngOnInit() {
    this.uploader = new FileUploader({ allowedFileType: this.fileTypes });
  }

  onFileDrop(files: File[]) {
    if (!this.isValidFile(files)) {
      this.selectingWrongFile.emit();
      return;
    }
    this.multiple ? this.parseFile(files) : this.parseFile(files[0]);
  }

  fileOver(): void {
    this.hasDropZoneOver = true;
  }

  onChange(event) {
    const files = event.target.files;
    if (!this.isValidFile(files)) {
      this.selectingWrongFile.emit();
    } else {
      this.multiple ? this.parseFile(files) : this.parseFile(files[0]);
    }
    event.target.value = '';
  }

  private isValidFile(files: File[]): boolean {
    return files.length && (this.multiple ?
      _.every(files, (file) => _.includes(this.fileTypes, file.name.split('.').pop())) :
      _.includes(this.fileTypes, files[0].name.split('.').pop()));
  }

  private parseFile(file): void {
    this.selectedFile.emit(file);
  }
}

import { Injectable } from '@angular/core';
import { Body, DELETE, GET, Headers, PATCH, Path, POST, Query, RebirthHttp } from 'rebirth-http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TestDriveVehicleApi extends RebirthHttp {
  constructor(http: HttpClient) {
    super(http);
  }

  @GET('/api/test-drive-vehicles')
  getTestDriveVehicles(): Observable<any> {
    return null;
  }

  @GET('/api/sales-website/model-d-test-drive-vehicles')
  getModelDTestDriveVehicles(@Query('search_criteria') searchCriteria: string,
                             @Query('page_number') pageNumber: number,
  ): Observable<any> {
    return null;
  }

  @GET('/api/test-drive-vehicles?isServing=true')
  getServingTestDriveVehicles(): Observable<any> {
    return null;
  }

  @POST('/api/test-drive-vehicles')
  createTestDriveVehicle(@Body vehicle): Observable<any> {
    return null;
  }

  @PATCH('/api/test-drive-vehicles')
  updateTestDriveVehicle(@Body vehicle): Observable<any> {
    return null;
  }

  @PATCH(`/api/sales-website/model-d-test-drive-vehicles/:id`)
  updateModelDTestDriveVehicle(@Path('id') id: number, @Body vehicle): Observable<any> {
    return null;
  }

  @GET('/api/dealers/third-party-vehicle')
  getThirdPartyVehicleStatus(): Observable<boolean> {
    return null;
  }

  @GET('/api/website/dealers/current-dealer-and-branch-info')
  getDealerInfo(): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/digital-sales-campaigns/list')
  getDigitalSalesCampaignList(@Body body): Observable<any> {
    return null;
  }

  @POST('/api/sales-website/digital-sales-campaigns/export')
  @Headers({ isExportFile: true })
  exportCampaignDataReport(@Body vehicle): Observable<any> {
    return null;
  }

  @POST('/api/dealers/third-party-vehicle')
  toggleThirdPartyVehicleStatus(@Body thirdPartyVehicleStatus: boolean): Observable<any> {
    return null;
  }

  @POST('/api/test-drive')
  startTestDrive(@Body body): Observable<any> {
    return null;
  }

  @DELETE('/api/test-drive-vehicles/:id')
  deleteVehicle(@Path('id') vehicleId: number) {
    return null;
  }

  @GET('/api/sales-website/model-d-test-drive-vehicles/be-replaced-dealer-data/:id')
  deleteTestDrive(@Path('id') vehicleId: number) {
    return null;
  }

  @DELETE('/api/sales-website/model-d-test-drive-vehicles/:id')
  deleteModelDVehicle(@Path('id') vehicleId: number) {
    return null;
  }

  @GET('/api/sales-website/model-d-test-drive-vehicles/get-reess-code-and-emotor-number/:vin')
  getReessCodeAndEmotorNumber(@Path('vin') vin: string): Observable<any> {
    return null;
  }
}

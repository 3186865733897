import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '@otr/website-common';

@Injectable()
export class ShareAuthGuard implements CanActivate {
  constructor(private navigationService: NavigationService) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    if(this.navigationService.shareNavCheckResult) {
      return of(true);
    }
    alert(`您无权访问该页面，请联系operation`);
  }
}

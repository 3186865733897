import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ActivityHistoryListItem } from './model/activity-history-list-item';
import {
  ActivityFilter,
  ActivityFilterKey,
  ActivityFilterOption,
  BusinessRange,
} from '../lead-information/activity-filter/activity-filter-helper';
import { AsLeadApi } from '../../../as-lead/api/as-lead';
import * as _ from 'lodash';
import { catchError, map } from 'rxjs/operators';
import { AsLeadFollowUp, FollowUpHistory } from '../../../as-lead/model';
import { OptionPipe } from '../../../../_common/pipes/option.pipe';
import { AS_LEAD_TYPE_ENUM, LEAD_STATUS_ENUM } from '../../../as-lead/constant';
import { asLeadUtils } from '../../../as-lead/utils';
import { PromptBarService, PromptLevel } from '@otr/website-common';
import { RebirthHttpProvider } from 'rebirth-http';

@Injectable()
export class ActivityHistoryService {
  constructor(private asLeadApi: AsLeadApi,
              private rebirthHttpProvider: RebirthHttpProvider,
              private promptBarService: PromptBarService) {
    this.rebirthHttpProvider.addInterceptor({
      response: (response: any) => {
        if (response.error) {
          const { error: { error_code } } = response;
          if (error_code === 'DMP_UNAVAILABLE') {
            this.promptBarService.show('DMP数据接口连接失败，后台正在加紧处理，请稍后再试', PromptLevel.Error);
            response.error.message = 'DMP数据接口连接失败，后台正在加紧处理，请稍后再试';
          }
        }
        return response;
      },
    });
  }

  getAsLeadFollowUpActivities(
    activityFilter: ActivityFilter,
    currentVehicleOptions: Array<ActivityFilterOption>,
    dealerId: string,
    customerDealerId?: string,
  ): Observable<Array<ActivityHistoryListItem>> {
    // tslint:disable-next-line:max-line-length
    if (activityFilter[ActivityFilterKey.businessRange] === BusinessRange.SALES || activityFilter[ActivityFilterKey.businessRange] === BusinessRange.USED_CAR_HISTORY) {
      return of([]);
    }
    const fins = this.getSelectedFins(activityFilter, currentVehicleOptions);
    if (_.isEmpty(fins)) {
      return of([]);
    }
    return forkJoin(_.map(fins, fin => this.getFollowUpHistoryByFin(fin, (customerDealerId || dealerId))))
      // tslint:disable-next-line:max-line-length
    // return forkJoin(_.map(fins, fin => this.getFollowUpHistoryByFin(fin, _.get(_.find(currentVehicleOptions, currentVehicleOption => _.get(currentVehicleOption, 'fin') === fin), 'relatedCustomerAndCompany.owners[0].dealer_id', dealerId))))
      .pipe(map(followUpHistoryResponseList =>
        _.chain(followUpHistoryResponseList)
          .flatMap('followUpList')
          .map(followUpActivity => this.mapFollowUpActivityToView(followUpActivity, currentVehicleOptions))
          .value()),
      );
  }

  private mapFollowUpActivityToView(followUpActivity, currentVehicleOptions: Array<ActivityFilterOption>) {
    return {
      timestamp: new Date(followUpActivity.handleDate).getTime(),
      title: new OptionPipe().transform(followUpActivity.leadStatus, LEAD_STATUS_ENUM),
      detailDescription: this.getFollowUpDescription(currentVehicleOptions, followUpActivity),
      executedUserDescription: followUpActivity.cdoName,
    };
  }

  private getFollowUpDescription(currentVehicleOptions, followUpActivity) {
    const vehicleModel = _.get(_.find(currentVehicleOptions, { value: followUpActivity.fin }), 'text', '');
    const suffix = this.getAsLeadFollowUpDescriptionSuffix(followUpActivity);
    const leadTypeDescription = new OptionPipe().transform(followUpActivity.leadType, AS_LEAD_TYPE_ENUM);
    return `${vehicleModel}\n${asLeadUtils.mapFollowUpTimesToString(followUpActivity.followUpTimes)};${leadTypeDescription}\n${suffix}`;
  }

  private getSelectedFins(activityFilter: ActivityFilter, currentVehicleOptions: Array<ActivityFilterOption>) {
    return activityFilter[ActivityFilterKey.subType] === 'ALL'
    || _.isEmpty(activityFilter[ActivityFilterKey.subType])
      ? _.chain(currentVehicleOptions).map('value').uniq().value()
      : [activityFilter[ActivityFilterKey.subType]];
  }

  private getFollowUpHistoryByFin(fin: string, dealerId?: string): Observable<FollowUpHistory> {
    return this.asLeadApi.getFollowUpHistory({
      pageNumber: 1,
      pageSize: 100,
      fin,
      dealerId,
    }).pipe(catchError(() => of({ followUpList: [] } as FollowUpHistory)), map(followUpHistory => ({
      ...followUpHistory,
      followUpList: _.map(followUpHistory.followUpList, followUp => ({
        ...followUp,
        fin,
      })),
    })));
  }

  private getAsLeadFollowUpDescriptionSuffix(followUpActivity: AsLeadFollowUp) {
    if (followUpActivity.leadStatus === LEAD_STATUS_ENUM.CLOSED.value) {
      return `关闭原因: ${followUpActivity.closeReason}`;
    }
    if (followUpActivity.leadStatus === LEAD_STATUS_ENUM.ORDERED.value) {
      return `预约时间: ${followUpActivity.reservationDate}`;
    }
    return `下次跟进时间: ${followUpActivity.nextFollowUpDate}`;
  }
}
